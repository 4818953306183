import { NetworkResponseMapper } from 'data/http/mapper';
import { User } from 'core/entity/user';
import { parseDate, optional } from 'utils/index';
import { UserAccountMapper } from 'data/http/mapper/user/account';
import { UserPreferenceMapper } from 'data/http/mapper/user/preference';
import { UserSexMapper } from 'data/http/mapper/user/sex';

export class UserMapper implements NetworkResponseMapper<User> {
    fromJson(json: any): User {
        return new User(
            json.id,
            json.name,
            new UserSexMapper().fromJson(json.sex),
            parseDate(json.created_at),
            json.authenticated,
            new UserAccountMapper().fromJson(json.account),
            new UserPreferenceMapper().fromJson(json.preference),
            optional(json.birthday, it => parseDate(it)),
            optional(json.total_income)
        )
    }

}
