import { List, OrderedMap } from 'immutable';
import { Entity } from 'core/entity';
import { CardBrand } from 'core/entity/card/brand';

export class CardConfiguration extends Entity {
    brands: List<CardBrand>;
    companies: OrderedMap<string, string>;
    specialBenefits: List<CardConfiguration.SpecialBenefit>;

    constructor(
        brands: List<CardBrand>,
        companies: OrderedMap<string, string>,
        specialBenefits: List<CardConfiguration.SpecialBenefit>
    ) {
        super();
        this.brands = brands;
        this.companies = companies;
        this.specialBenefits = specialBenefits;
    }
}

export module CardConfiguration {
    export class SpecialBenefit extends Entity {
        title: string;
        items: OrderedMap<number, string>;

        constructor(
            title: string,
            items: OrderedMap<number, string>
        ) {
            super();
            this.title = title;
            this.items = items;
        }
    }
}
