import { UseCase } from 'core/use-case';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';
import { Observable } from 'rxjs/Rx';
import { apply } from 'utils/index';

export class CacheLoanDesiredAmount extends UseCase<void> {
    desiredAmount: number;
    private repository: LoanRecommendRepositoryType;

    constructor(repository: LoanRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<void> {
        return this.repository.getCachedSpec()
            .flatMap(spec =>
                this.repository.cacheDesiredAmount(apply(spec, it => it.desiredAmount = this.desiredAmount))
            );
    }

    protected validate(): boolean {
        return !!this.desiredAmount;
    }
}
