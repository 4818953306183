import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreOverdueConfiguration } from 'core/entity/personal-credit/overdue-configuration';
import { UserCreditScoreOrganizationMapper } from 'data/http/mapper/personal-credit/organization';
import { AmountMapper } from 'data/http/mapper/amount';
import { parseDate } from 'utils/index';

export class UserCreditScoreOverdueConfigurationMapper implements NetworkResponseMapper<UserCreditScoreOverdueConfiguration> {
    fromJson(json: any): UserCreditScoreOverdueConfiguration {
        return new UserCreditScoreOverdueConfiguration(
            new UserCreditScoreOrganizationMapper().fromJson(json.organization),
            new AmountMapper().fromJson(json.principal),
            new AmountMapper().fromJson(json.balance),
            new AmountMapper().fromJson(json.repayment_amount),
            parseDate(json.created_at)
        )
    }

}
