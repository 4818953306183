import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { AdviceNetAssetValuationDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/net-asset-valuation';
import { AdviceBudgetDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/budget';
import { AdviceCashFlowDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/cash-flow';
import { AdviceLoanDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/loan';
import { AdviceInvestmentValuationDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/investment-value';
import { AdviceCardExpenseDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/card-expense';
import { AdviceExpenseSummaryDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/expense-summary';
import { AdviceTopExpenseDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/top-expense';
import { AdviceCardInstallmentSummaryDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/card-installment-summary';
import { AdviceExcessiveSpendingAlertDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/excessive-spending-alert';
import { AdviceReducedSpendingAlcoholDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/reduced-alcohol-spending';
import { AdviceLateNightSpendingAlcoholDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/late-night-alcohol-spending';
import { AdviceExcessiveSpendingTaxiDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/excessive_spending_taxi';
import { AdviceReducedTaxiSpendingDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/reduced-spending-taxi';
import { AdviceLateNightInternetShoppingDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/late_night_internet_shopping';
import { AdviceExcessiveSpendingCafeDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/excessive-spending-cafe';
import { AdviceCustomDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/custom';
import { AdviceMonthlyCardProfitSummaryDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/monthly-card-profit-summary';
import { AdviceWelcomeDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/welcome';
import { AdviceCreditScoreInquirySuggestionDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/credit-score-inquiry-suggestion';
import { AdviceCardExpirationDataMapper } from 'data/http/mapper/personal-finance/advice/content-data/card-expiration';

export class AdviceContentDataMapper implements NetworkResponseMapper<AdviceContentData> {
    fromJson(json: any): AdviceContentData {
        switch (json.type) {
            case 'net_asset_valuation':
                return new AdviceNetAssetValuationDataMapper().fromJson(json);
            case 'budget':
                return new AdviceBudgetDataMapper().fromJson(json);
            case 'cash_flow':
                return new AdviceCashFlowDataMapper().fromJson(json);
            case 'loan':
                return new AdviceLoanDataMapper().fromJson(json);
            case 'investment_valuation':
                return new AdviceInvestmentValuationDataMapper().fromJson(json);
            case 'card_expense':
                return new AdviceCardExpenseDataMapper().fromJson(json);
            case 'expense_summary':
                return new AdviceExpenseSummaryDataMapper().fromJson(json);
            case 'top_expense':
                return new AdviceTopExpenseDataMapper().fromJson(json);
            case 'card_installment_summary':
                return new AdviceCardInstallmentSummaryDataMapper().fromJson(json);
            case 'excessive_consumption_alert': // TODO: delete legacy type after migration
            case 'excessive_spending_alert':
                return new AdviceExcessiveSpendingAlertDataMapper().fromJson(json);
            case 'reduced_alcohol_consumption': // TODO: delete legacy type after migration
            case 'reduced_spending_alcohol':
                return new AdviceReducedSpendingAlcoholDataMapper().fromJson(json);
            case 'late_night_alcohol_consumption': // TODO: delete legacy type after migration
            case 'late_night_spending_alcohol':
                return new AdviceLateNightSpendingAlcoholDataMapper().fromJson(json);
            case 'excessive_spending_taxi':
                return new AdviceExcessiveSpendingTaxiDataMapper().fromJson(json);
            case 'reduced_spending_taxi':
                return new AdviceReducedTaxiSpendingDataMapper().fromJson(json);
            case 'late_night_internet_shopping':
                return new AdviceLateNightInternetShoppingDataMapper().fromJson(json);
            case 'excessive_spending_cafe':
                return new AdviceExcessiveSpendingCafeDataMapper().fromJson(json);
            case 'custom':
                return new AdviceCustomDataMapper().fromJson(json);
            case 'monthly_card_profit_summary':
                return new AdviceMonthlyCardProfitSummaryDataMapper().fromJson(json);
            case 'welcome':
                return new AdviceWelcomeDataMapper().fromJson(json);
            case 'credit_score_inquiry_suggestion':
                return new AdviceCreditScoreInquirySuggestionDataMapper().fromJson(json);
            case 'card_expiration':
                return new AdviceCardExpirationDataMapper().fromJson(json);
            default:
                throw new MappingError();
        }
    };
}
