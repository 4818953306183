import { Service } from 'core/service';
import { PersonalizedLoanRepositoryType } from 'core/repository/personalized-recommend/loan';
import { PersonalizedLoanRecommendedResult } from 'core/entity/personalized-recommend/loan/recommended-result';
import { Observable } from 'rxjs/Observable';
import { PersonalizedLoanRecommendSpec } from 'core/entity/personalized-recommend/loan/recommend-spec';
import { PersonalizedLoanCategory } from 'core/entity/personalized-recommend/loan/category';
import { List } from 'immutable';

export class PersonalizedLoanService implements Service {
    private repository: PersonalizedLoanRepositoryType;

    constructor(
        repository: PersonalizedLoanRepositoryType
    ) {
        this.repository = repository;
    }

    recommend = (
        recommendSpec: PersonalizedLoanRecommendSpec
    ): Observable<PersonalizedLoanRecommendedResult> => (
        this.repository.recommend(recommendSpec)
    );

    getCategories = (): Observable<List<PersonalizedLoanCategory>> => (
        this.repository.getCategories()
    );
}
