import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanRangeInterest } from 'core/entity/loan/interest-value/range-interest';
import { LoanInterestRangeMapper } from 'data/http/mapper/loan/interest-range';

export class LoanRangeInterestMapper implements NetworkResponseMapper<LoanRangeInterest> {
    fromJson(json: any): LoanRangeInterest {
        return new LoanRangeInterest(
            new LoanInterestRangeMapper().fromJson(json.value)
        );
    }
}
