import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreSubstitutedPaymentConfiguration } from 'core/entity/personal-credit/substituted-payment-configuration';
import { AmountMapper } from 'data/http/mapper/amount';
import { optional, parseDate } from 'utils/index';

export class UserCreditScoreSubstitutedPaymentConfigurationMapper implements NetworkResponseMapper<UserCreditScoreSubstitutedPaymentConfiguration> {
    fromJson(json: any): UserCreditScoreSubstitutedPaymentConfiguration {
        return new UserCreditScoreSubstitutedPaymentConfiguration(
            json.organization,
            new AmountMapper().fromJson(json.balance),
            new AmountMapper().fromJson(json.repayment_amount),
            parseDate(json.created_at),
            optional(parseDate(json.repaid_at))
        );
    }
}
