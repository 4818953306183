export const BanksaladFinanceSectors = {
  "data": [
    {
      "name": "카드",
      "sub1": {
        "name": "카드 추천",
        "items": [
          {
            "name": "카드 이벤트",
            "url": "/cards/promotions/best-credit-cards",
            "badge": "new",
            "type": "url",
          },
          {
            "name": "나의 BEST 카드 찾기 ",
            "url": "/cards/questions",
            "badge": "best",
            "type": "url",
          },
          {
            "name": "인기 카드 TOP 10",
            "url": "/cards/ranking",
            "type": "url",
          },
          {
            "name": "연회비 지원 상품",
            "url": "/cards/promotion/annual-fee",
            "type": "url",
          },
          {
            "name": "테마별 카드추천",
            "url": "/cards/themes",
            "type": "url",
          },
          {
            "name": "신용카드 추천",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/articles/%ED%98%9C%ED%83%9D-%EC%A2%8B%EC%9D%80-%EC%8B%A0%EC%9A%A9%EC%B9%B4%EB%93%9C-%EC%B6%94%EC%B2%9C-7%EC%A2%85-%EC%88%9C%EC%9C%84-2022",
              "description": "나에게 딱 맞는 신용카드는?",
              "published": true,
              "label": "BEST 인기글",
              "id": "혜택-좋은-신용카드-추천-7종-순위-2022",
              "created_at": "2023-02-08",
              "hot": false,
              "title": "혜택 좋은 신용카드 추천 7종 순위 2023",
              "type": "card-comparison",
              "thumbnail_image_url": "https://financialshopping-cdn.banksalad.com/large-png_2023_TOP_7_850e6299f6.png",
            },
            "type": "content",
          },
        ],
      },
      "sub4": {
        "name": "카드 상식",
        "items": [
          {
            "name": "최고의 신용카드를 고르는 법",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/최고의-신용카드를-고르는-방법-8d71",
              "description": "대부분의 소비자는 자신의 소비 행태에 가장 맞는 카드가 무엇인지 모른 채 카드를 사용합니다. 뱅크샐러드는 이러한 갈증을 해소하고 싶었습니다.  4년 동안 카드 상품 분석, 추천을 하면서 결국 카드를 선택할 때에는 다음 4가지 사항을 기준으로 해야 한다는 것을 알게 되었습니다",
              "published": true,
              "id": "최고의-신용카드를-고르는-방법-8d71",
              "created_at": "2017-03-26",
              "title": "최고의 신용카드를 고르는 방법",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550656784933-cards-416960_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "신용카드 기본기",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/신용카드-기본기-신용카드의-장단점부터-발급방법까지-45b5",
              "description": "생활 속 편리함을 주는 신용카드는 잘 사용하면 득이지만 남용하게 되면 독이 됩니다. 무분별한 소비를 불러일으킬수 있다는 이유로 신용카드 대신 체크카드 사용을 권하는 경우도 있지만, 조금만 신경써서 사용한다면 체크카드 사용보다 많은 이점이 있습니다.",
              "published": true,
              "id": "신용카드-기본기-신용카드의-장단점부터-발급방법까지-45b5",
              "created_at": "2017-04-28",
              "title": "신용카드 기본기 : 신용카드의 장단점부터, 발급방법까지",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551077631346-credit-card-2439141_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "신용카드 할부결제 취소하려면?",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/할부-취소의-테크닉-1cd5",
              "description": "# 이슬이는 자전거로 출퇴근하기 위해 120만 원짜리 자전거를 12개월 할부로 샀습니다. 그런데 ‘날이 덥다’, ‘비가 온다’며 자전거 타기를 미루다가 결국 자전거를 환불하기로 결심했습니다. 이슬이는 할부 취소를 할 수 있을까요?",
              "published": true,
              "id": "할부-취소의-테크닉-1cd5",
              "created_at": "2016-10-09",
              "title": "할부 취소의 테크닉",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550656404338-ecommerce-2140603_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "카드 분실 시 대처법",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/카드-분실-시-어떻게-해야-할까-ef3f",
              "description": "# 직장인 지훈씨는 카드를 잃어버렸다는 사실을 알았으나, 설마 누군가가 사용할 것이라고는 생각하지 못하고 이틀 후에야 분실신고를 하였습니다. 그 사이 지훈씨의 카드를 습득한 사람이 420만원을 사용하였습니다. 생각도 하기 싫은 난감한 상황이지만, 누구에게나 쉽게 일어날 수 있는 일이지요.",
              "published": true,
              "id": "카드-분실-시-어떻게-해야-할까-ef3f",
              "created_at": "2016-10-31",
              "title": "카드 분실 시, 어떻게 해야 할까?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551086732177-purse-3548021_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "PP카드 없이 공항라운지 이용하는법",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/PP카드-없어도-공항-라운지-이용할-수-있는-법-라운지-키-Lounge-Key-서비스-LnBDF",
              "description": "해외여행에서 공항은 가장 설레는 공간이자, 가장 피곤하고 지루한 공간이기도 합니다. 장시간의 비행과 대기로 지칠 때 혹은 남는 시간을 편하고 알차게 보내고 싶을 때, 공항 라운지를 이용해보시는 것은 어떠신가요? 라운지마다 차이가 있겠지만, 무료 와이파이, 인터넷, 뷔페, 샤워실 등을 제공하고 있어 편하고 쾌적하게 휴식을 즐길 수 있습니다.   공항 라운지는 특정 클래스를 이용하거나 입장권을 끊으면 이용할 수 있는데요. 입장권 구매 가격이 저렴한 편은 아니죠. 그렇다면, 무료로 이용할 수 있는 방법은 없을까요?",
              "published": true,
              "id": "PP카드-없어도-공항-라운지-이용할-수-있는-법-라운지-키-Lounge-Key-서비스-LnBDF",
              "created_at": "2018-05-31",
              "title": "PP카드 없어도 공항 라운지 이용할 수 있는 법! 라운지 키(Lounge Key) 서비스",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552908549744-airport-1659008_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "sub3": {
        "name": "테마별 추천카드",
        "items": [
          {
            "name": "디자인이 이쁜 카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/디자인이-예쁜-카드-HqUFc",
              "description": "예쁜 카드가 혜택도 좋다",
              "published": true,
              "id": "디자인이-예쁜-카드-HqUFc",
              "created_at": "2017-11-07",
              "hot": false,
              "title": "디자인이 예쁜 카드",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-card-comparison/1524055506186-%EB%94%94%EC%9E%90%EC%9D%B8%EC%98%88%EC%81%9C%EC%B9%B4%EB%93%9C.JPG",
            },
            "type": "content",
          },
          {
            "name": "항공 마일리지 카드 ",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/잘-쌓이는-항공마일리지-신용카드-2a5b",
              "description": "차곡차곡 쌓아 여행가자! ",
              "published": true,
              "id": "잘-쌓이는-항공마일리지-신용카드-2a5b",
              "created_at": "2016-10-18",
              "hot": false,
              "title": "최고의 항공 마일리지 카드",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F4_%ED%95%AD%EA%B3%B5%EB%A7%88%EC%9D%BC%EB%A6%AC%EC%A7%80%EC%B9%B4%EB%93%9C.jpg",
            },
            "type": "content",
          },
          {
            "name": "후불 하이패스 카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/후불-하이패스-카드-b7d0",
              "description": "운전자 필수템! ",
              "published": true,
              "label": "30대 인기",
              "id": "후불-하이패스-카드-b7d0",
              "created_at": "2016-10-25",
              "hot": false,
              "title": "후불 하이패스 카드",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F11_%ED%9B%84%EB%B6%88%ED%95%98%EC%9D%B4%ED%8C%A8%EC%8A%A4%EC%B9%B4%EB%93%9C.jpg",
            },
            "type": "content",
          },
          {
            "name": "KTX 할인카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/KTX-할인카드-76e5",
              "description": "고향 내려갈 때마다 기차값이 부담이라면",
              "published": true,
              "label": "클릭률 BEST",
              "id": "KTX-할인카드-76e5",
              "created_at": "2016-10-18",
              "hot": false,
              "title": "KTX 할인카드",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F6_ktx%ED%95%A0%EC%9D%B8%EC%B9%B4%EB%93%9C.jpg",
            },
            "type": "content",
          },
        ],
      },
      "id": "card",
      "sub2": {
        "name": "상황별 추천카드",
        "items": [
          {
            "name": "자취족, 싱글족을 위한 카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/자취족-싱글족-카드-추천-Dyp3s",
              "description": "나홀로 당당하게, 1코노미 시대의 ",
              "published": true,
              "id": "자취족-싱글족-카드-추천-Dyp3s",
              "created_at": "2017-11-07",
              "hot": false,
              "title": "자취족/싱글족 카드 추천",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-card-comparison/1510286157232-%EC%8B%B1%EA%B8%80%EC%A1%B1.jpg",
            },
            "type": "content",
          },
          {
            "name": "주유 할인 카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/주유-할인-카드-0cef",
              "description": "기름값 아껴 소고기 사먹자! ",
              "published": true,
              "label": "30대 인기",
              "id": "주유-할인-카드-0cef",
              "created_at": "2016-10-18",
              "hot": false,
              "title": "주유 할인 카드",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F1_%EC%A0%84%EA%B5%AD%EC%A3%BC%EC%9C%A0%EC%86%8C%ED%95%A0%EC%9D%B8%EC%B9%B4%EB%93%9C.jpg",
            },
            "type": "content",
          },
          {
            "name": "온라인쇼핑 할인카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/온라인쇼핑-할인카드-f98e",
              "description": "오픈마켓부터 소셜커머스까지",
              "published": true,
              "label": "20대 여성 인기",
              "id": "온라인쇼핑-할인카드-f98e",
              "created_at": "2016-12-08",
              "hot": false,
              "title": "온라인쇼핑 할인카드",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F21_%EC%98%A8%EB%9D%BC%EC%9D%B8%EC%87%BC%ED%95%91%EB%AA%B0%ED%95%A0%EC%9D%B8%EC%B9%B4%EB%93%9C.jpg",
            },
            "type": "content",
          },
          {
            "name": "주부를 위한 카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/주부-신용카드-추천-1mHDA",
              "description": "육아, 가사 스트레스를 날려버릴 수 있는!",
              "published": true,
              "id": "주부-신용카드-추천-1mHDA",
              "created_at": "2017-11-07",
              "hot": false,
              "title": " 주부 신용카드 추천",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-card-comparison/1510066418485-%EC%A3%BC%EB%B6%80%ED%95%A0%EC%9D%B8%EC%B9%B4%EB%93%9C%EC%BB%A4%EB%B2%84.JPG",
            },
            "type": "content",
          },
          {
            "name": "편의점 할인카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/편의점-할인-카드-3f98",
              "description": "편리하게 사고, 똑똑하게 할인 받는",
              "published": true,
              "label": "직장인인기",
              "id": "편의점-할인-카드-3f98",
              "created_at": "2017-03-08",
              "hot": false,
              "title": "편의점 할인 카드",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/content/upload/images/content-upload-images-3784529486.jpg",
            },
            "type": "content",
          },
        ],
      },
    },
    {
      "name": "은행",
      "sub1": {
        "name": "상품추천",
        "items": [
          {
            "name": "BEST 입출금통장",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/2017-수시입출금-계좌-BEST-ad75",
              "description": "수시입출금 계좌는 자유롭게 입출금이 가능한 금융상품입니다. 흔히 은행에서 “통장 만들어 주세요”라고 하면 개설해주는 계좌가 대부분 수시입출금 계좌입니다. 워낙 입출금이 잦은 계좌라 혜택이나 금리를 다른 상품에 비해 상대적으로 신경을 덜 쓰는 경우가 많습니다. 그래서 뱅크샐러드는 소비자에게 더 좋은 상품이 없는지 직접 조사, 2017 BEST를 선정했습니다.",
              "published": true,
              "id": "2017-수시입출금-계좌-BEST-ad75",
              "created_at": "2017-09-11",
              "title": "2017 수시입출금 계좌 BEST",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fbookkeeping-615384_1920.jpg",
            },
            "type": "content",
          },
          {
            "name": "BEST 자유적금",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/best-자유적금-r7OLz",
              "description": "목돈을 모으기 위해 가장 먼저 고려해보는 상품은 아마 ‘적금’일 것입니다. 하지만, 매달 고정비처럼 나가는 금액이 부담스럽거나, 갑작스러운 사고로 적금을 해지하는 경우가 생기기도 하죠. 이런 문제를 해결하고자 등장한 상품이 바로 “자유적금” 입니다. 자유적금은 만기까지 본인이 원하는 금액을 자유롭게 납입하는 형태로, 본인의 경제력이나 상황에 맞게 적립할 수 있다는 장점을 가지고 있습니다. 정액 적립식보다는 금리가 낮지만, 자유롭게 입금할 수 있다는 점에서 만기유지율이 높습니다. 효율적인 자유적금 선택을 위해, 뱅크샐러드에서 BEST 자유적금 상품을 선정해보았습니다. ",
              "published": true,
              "id": "best-자유적금-r7OLz",
              "created_at": "2017-09-20",
              "title": "BEST 자유적금",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-tags1505890518445-coins-548949_1920.jpg",
            },
            "type": "content",
          },
          {
            "name": "쉽게 돈모으는 BEST 잔돈저축",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/귀차니스트도-쉽게-돈-모으는-잔돈저축-BEST3-lZgxy",
              "description": "이번 달에도 돈을 모아야겠다고 다짐한 당신. 하지만 돈 모으기는, 마음처럼 쉽지 않습니다.  사실은 매달 강제저축을 하는 것이 가장 이상적이라는 것은 머리로 잘 알고 있습니다. 하지만 적금에 꾸준히 돈을 넣는 게 얼마나 어려운 일인지…",
              "published": true,
              "id": "귀차니스트도-쉽게-돈-모으는-잔돈저축-BEST3-lZgxy",
              "created_at": "2018-06-01",
              "title": "귀차니스트도 쉽게 돈 모으는 잔돈저축 BEST3",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552907839719-save-3402476_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "sub3": {
        "name": "매거진",
        "items": [
          {
            "name": "지출관리 고수가 되는 통장활용법",
            "content": {
              "author": {
                "id": "한국금융투자자보호재단-2cc2",
                "profile": {
                  "name": "한국금융투자자보호재단",
                  "summary": "투자자의 권익향상을 위한 비영리 공익재단",
                  "image_url": "https://cdn.banksalad.com/entities/content-authors1505888031799-kici.png",
                },
              },
              "url": "https://banksalad.com/contents/생활금융-100단-지출관리-고수가-되는-통장-활용법-c8f0",
              "description": "‘지출 계획 지키기가 왜 이렇게 어렵지?’ 우리가 매월 지출 내용을 파악하며 하는 불평이다. 가계부를 정리할 때는 왜 이렇게 많이 썼나 머리카락이 쭈뼛하지만, 고민도 그때 뿐이다.",
              "published": true,
              "id": "생활금융-100단-지출관리-고수가-되는-통장-활용법-c8f0",
              "created_at": "2017-06-14",
              "title": "지출관리 고수가 되는 법",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550740006400-learn-3069053_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "재테크의 시작, 통장쪼개기 하는 법",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/재테크-전문가들이-통장쪼개기를-부르짖는-이유-1156",
              "description": "공부 혹은 일을 하다가 12시가 되어 '밥 먹으러 가자'는 소릴 들으면 나도 모르게 배가 고픕니다. 점심시간이라고 학교에서, 직장에서 정했기 때문입니다. '12시=밥 먹는 시간'이라는 연상회로가 뇌에서 형성된 덕분입니다.",
              "published": true,
              "id": "재테크-전문가들이-통장쪼개기를-부르짖는-이유-1156",
              "created_at": "2016-08-09",
              "title": "재테크 전문가들이 '통장쪼개기'를 부르짖는 이유",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552636914899-dollar-499481_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "은행거래 혜택 100% 활용하는 법",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/은행거래-혜택-100-활용하는-법-cb60",
              "description": "# 직장인 민우씨는 회사에서 단체로 여행을 가게 되어, 직장 후배 은주씨와 함께 급여가 이체되는 은행에 환전을 하러 갔습니다. 그런데, 본인보다 직급도 낮은 은주씨가 환율우대를 더 많이 받는것이 아니겠어요? 민우씨는 이것을 보고 영 기분이 좋지 않았습니다.",
              "published": true,
              "id": "은행거래-혜택-100-활용하는-법-cb60",
              "created_at": "2016-11-06",
              "title": "은행거래 혜택 100% 활용하는 법!",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551063633778-london-by-night-735085_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "예적금 수익률을 높이는 8가지 팁",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/예적금-수익률을-높일-수-있는-8가지-팁-ff70",
              "description": "사회초년생 정훈씨는 목돈마련을 위해 저축을 결심했습니다. 요즘은 저금리시대라 은행의 예·적금 이자율이 워낙 낮기 때문에 은행이나 예·적금 상품을 고른다는 것이 큰 의미가 없다고 보고 점심시간에 직장에서 가까운 A은행 점포에 들러 월 50만원씩 납입하는 만기 3년짜리 정기 적금에 가입하였습니다.   3년 후, 정훈씨의 적금만기일이 되어 적금을 찾고 보니, 비슷한 시기에 비슷한 금액을 저축한 직장동료 준원씨에 비해 이자수익이 20만원 이상 차이가 났습니다.",
              "published": true,
              "id": "예적금-수익률을-높일-수-있는-8가지-팁-ff70",
              "created_at": "2017-01-10",
              "title": "예·적금 수익률을 높일 수 있는 8가지 팁",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552636803521-euro-870757_1920.jpg",
            },
            "type": "content",
          },
          {
            "name": "예적금 관리를 도와주는 서비스 총정리",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/예적금-가입자가-모르면-후회하는-6가지-금융서비스-6fbf",
              "description": "예⋅적금 상품을 관리하다 보면 가끔 고민이 되는 순간이 찾아옵니다.   '만기일에 일이 생겨서 예금을 찾으러 가지 못 할 것 같은데, 어떡하지?'  '목돈이 조금 필요한데, 내가 갖고있는 예금을 해지해야 할까?'",
              "published": true,
              "id": "예적금-가입자가-모르면-후회하는-6가지-금융서비스-6fbf",
              "created_at": "2017-03-01",
              "title": "예·적금 가입자가 모르면 후회하는 6가지 금융서비스!",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550650432018-coins-1726618_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "나에게 맞는 예적금을 고르는 법",
            "content": {
              "author": {
                "id": "윤성애-3e27",
                "profile": {
                  "name": "윤성애",
                  "summary": "<돈 없어도 나는 재테크를 한다> 저자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2FProfile.png",
                },
              },
              "url": "https://banksalad.com/contents/예적금-선택-시-고려사항-f3f9",
              "description": "결혼, 전세자금, 내집마련, 자녀육아, 노후설계 의 공통점은 바로 ‘목돈’ 입니다. 살면서 목돈은 중요한 역할을 하기 때문에 우리는 목돈을 준비하려고 합니다. 그리고 이런 목돈이라는 공통된 목적을 갖고 있는 가장 대표적인 금융 상품이 바로 예·적금 이죠. 적금으로 목돈을 만들고 예금으로 목돈을 운용해서 더 큰 목돈을 만드는 것 입니다. 그렇다면 예·적금을 고를 때 고려해야 될 점은 무엇일까요? 뱅크샐러드가 예·적금 선택 시 고려사항에 대해 알아봤습니다.",
              "published": true,
              "id": "예적금-선택-시-고려사항-f3f9",
              "created_at": "2017-09-14",
              "title": "예·적금 선택 시 고려사항",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552615930937-laptop-3196481_1920.jpg",
            },
            "type": "content",
          },
          {
            "name": "아차! 돈을 잘못보낸 순간 대처법",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/아차-돈을-잘못-보낸-순간-대처법-0487",
              "description": "# 휴가철을 맞아 가족들과 여행을 떠난 지훈이. 신나는 마음으로 가족들과 휴가지로 출발하였는데, 갑자기 예약한 숙박업체로부터 전화가왔습니다. 숙박비가 입금되지 않았다는 것입니다. 숙박비를 엉뚱한 곳에 잘못 보냈다는 사실을 알게 된 지훈이는, 잘못 보낸 돈을 어떻게 돌려받을 수 있을지 막막한 상황입니다.",
              "published": true,
              "id": "아차-돈을-잘못-보낸-순간-대처법-0487",
              "created_at": "2016-10-24",
              "title": "아차! 돈을 잘못 보낸 순간 대처법",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552637513484-vintage-1950s-887273_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "주택청약종합저축 A to Z",
            "content": {
              "author": {
                "id": "김현우-8bad",
                "profile": {
                  "name": "김현우",
                  "summary": "행복자산관리연구소 소장",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_kimhyunwoo.png",
                },
              },
              "url": "https://banksalad.com/contents/주택청약종합저축-AtoZ-66fb",
              "description": "2016년 8월 말 기준, 전체 가입자 2,092만 명.  1순위 1,217만 명.  바로 청약통장 가입자 수이다.  얼추 대한민국 국민의 절반 정도는 갖고 있다는 뜻이다. 이렇게 많은 사람이 가입했다면 당연히 꼭 필요하다는 의미일 수도 있는데, 이 많은 사람들이 과연 청약통장에 대해 얼마나 알고 있을까?",
              "published": true,
              "id": "주택청약종합저축-AtoZ-66fb",
              "created_at": "2016-11-09",
              "title": "주택청약종합저축 AtoZ",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551072251171-exterior-1597098_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "id": "bank",
      "sub2": {
        "name": "상품리뷰",
        "items": [
          {
            "name": "기본금리 높은 적금",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/기본금리가-높은-적금-BEST-yA5H0",
              "description": "적금 상품을 선택할 때 가장 먼저 고려하는 점은 아마도 금리일 것입니다. 적금 상품의 금리는 기본금리와 우대금리로 구성되어 있는데, 우대금리 조건을 많이 만족할수록 높은 금리가 적용되어 만기액도 늘어나게 됩니다. (금리에 대해 더 알아보고 싶다면 여기를 클릭해 주세요.)  그러나 우대금리 조건이 까다롭거나 다양한 우대금리 조건에 하나도 해당하지 않는다면 어떻게 해야 할까요? 바로 기본금리가 높은 상품을 선택하시면 됩니다. 특별한 조건이 없어도 기본금리가 높아 많은 혜택을 받을 수 있는 BEST 적금 상품을 뱅크샐러드가 알아봤습니다. ",
              "published": true,
              "id": "기본금리가-높은-적금-BEST-yA5H0",
              "created_at": "2017-09-22",
              "title": "기본금리가 높은 적금 BEST",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-tags1506042657372-money-2696233_1920.jpg",
            },
            "type": "content",
          },
          {
            "name": "기본금리 높은 예금",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/기본금리-BEST-예금-wKZUd",
              "description": "투자의 기본은 유휴자금의 최소화라고 합니다. 실제로 많은 직장인들이나 주부들이 그냥 통장에 방치해둔 돈도 꽤 많다고 하죠. 하지만 이런 자금을 계속 운용하면서 수익을 창출해 나가는 것이 투자의 기본 입니다. 그리고 이런 방치된 돈을 가장 대표적이고 안정적으로 활용하는 것이 바로 ‘예금’ 입니다.  그렇다면 어떤 예금 상품을 선택해서 어떻게 활용해야 할까요? 2017년 BEST예금과 예,적금 선택시 고려사항을 참고해 주시기 바랍니다! 이번에는 예금의 기본 중의 기본 금리에 따른 BEST 예금을 소개하겠습니다.",
              "published": true,
              "id": "기본금리-BEST-예금-wKZUd",
              "created_at": "2017-09-21",
              "title": "기본금리 BEST 예금",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-tags1505977992394-wallet-2668463_1920.jpg",
            },
            "type": "content",
          },
        ],
      },
    },
    {
      "name": "투자",
      "sub1": {
        "name": "상품추천",
        "items": [
          {
            "name": "투자 성향별 BEST 로보-어드바이저",
            "content": {
              "author": {
                "id": "이코노마드-6ea9",
                "profile": {
                  "name": "이코노마드",
                  "summary": "돈과 행복의 지혜창고",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%9D%B4%EC%BD%94%EB%85%B8%EB%A7%88%EB%93%9C.JPG",
                },
              },
              "url": "https://banksalad.com/contents/투자-성향별-BEST-로보어드바이저-801a",
              "description": "로보어드바이저는 사람이 아닌 컴퓨터 알고리즘이 개인의 성향과 상황에 따라 맞춤 투자 포트폴리오를 제공하는 서비스입니다. 대면 서비스보다 수수료 및 최소 투자금액이 대폭 줄어들어 고액자산가에게만 열려 있던 자산관리서비스를 대중화에 큰 기여를 했다는 평가를 받고 있습니다.",
              "published": true,
              "id": "투자-성향별-BEST-로보어드바이저-801a",
              "created_at": "2017-09-06",
              "title": "투자 성향별 BEST 로보-어드바이저",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550647851678-code-1839406_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "증권사 CMA 체크카드",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/cards/themes/증권사-CMA-체크카드-5a4b",
              "description": "진정한 재테크 고수들의 선택,",
              "published": true,
              "label": "직장인 인기",
              "id": "증권사-CMA-체크카드-5a4b",
              "created_at": "2016-11-02",
              "hot": false,
              "title": "증권사 CMA 체크카드",
              "type": "card-comparison",
              "thumbnail_image_url": "https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F15_CMA%EC%B6%94%EC%B2%9C.jpg",
            },
            "type": "content",
          },
        ],
      },
      "sub3": {
        "name": "매거진",
        "items": [
          {
            "name": "CMA란 무엇인가?",
            "content": {
              "author": {
                "id": "이종헌-c319",
                "profile": {
                  "name": "이종헌",
                  "summary": "삼성자산운용 스마트마케팅팀",
                  "image_url": "https://cdn.banksalad.com/content/upload/images/content-upload-images-2751568456.jpg",
                },
              },
              "url": "https://banksalad.com/contents/1분만에-정리하는-CMA-febc",
              "description": "CMA, 이제 모르는 사람이 없을 만큼 익숙한 상품입니다. 그런데, 길 가다가 아무나 잡고 CMA 있냐고 물어보면 10명 중 2명 정도만 그렇다고 대답합니다.",
              "published": true,
              "id": "1분만에-정리하는-CMA-febc",
              "created_at": "2017-07-05",
              "title": "1분만에 정리하는 CMA",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550731245382-adult-3327336_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "최고의 CMA를 고르는 법",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/최고의-CMA를-고르는-법-3f83",
              "description": "재테크에 관심을 갖기 시작하신 분들이라면, 아마 CMA라는 이름을 한번쯤 들어보셨을 것입니다.  CMA는 간단히 말하면 '증권사/종합금융회사에서 제공하는 수시입출금 통장'이라고 이해하시면 됩니다. CMA에 가입을 해야겠다고 마음먹었다면, ‘CMA통장은 어떻게 선택해야 할까?’ 에 대한 고민이 따라오기 마련.",
              "published": true,
              "id": "최고의-CMA를-고르는-법-3f83",
              "created_at": "2017-06-22",
              "title": "최고의 CMA를 고르는 법",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2FCash-Coins-Banking-Investment-Currency-Insurance-1523383.jpg",
            },
            "type": "content",
          },
          {
            "name": "투자의 시작, 증권계좌 이해하기",
            "content": {
              "author": {
                "id": "이코노마드-6ea9",
                "profile": {
                  "name": "이코노마드",
                  "summary": "돈과 행복의 지혜창고",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%9D%B4%EC%BD%94%EB%85%B8%EB%A7%88%EB%93%9C.JPG",
                },
              },
              "url": "https://banksalad.com/contents/투자의-시작-증권계좌-이해하기-31fa",
              "description": "'계좌'라는 말을 듣고 일반적으로 가장 먼저 떠올리게 되는 것은 '은행 예금' 입니다.",
              "published": true,
              "id": "투자의-시작-증권계좌-이해하기-31fa",
              "created_at": "2017-07-09",
              "title": "투자의 시작, '증권계좌' 이해하기",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551060618101-money-549161_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "증권계좌 선택 시 고려할 포인트",
            "content": {
              "author": {
                "id": "이코노마드-6ea9",
                "profile": {
                  "name": "이코노마드",
                  "summary": "돈과 행복의 지혜창고",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%9D%B4%EC%BD%94%EB%85%B8%EB%A7%88%EB%93%9C.JPG",
                },
              },
              "url": "https://banksalad.com/contents/증권계좌-선택-시-고려할-포인트-8c62",
              "description": "증권계좌를 막상 개설하려 하면, 어느 곳을 선택해야 할지 고민입니다. 최근에는 인터넷에서만 거래하는 인터넷 전업 증권에서 대기업 증권까지 많은 증권사가 있기 때문에",
              "published": true,
              "id": "증권계좌-선택-시-고려할-포인트-8c62",
              "created_at": "2017-07-11",
              "title": "증권계좌 선택 시 고려할 포인트",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551144030716-businessman-1492563_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "코스닥벤처펀드 투자시 고려할 3가지",
            "content": {
              "author": {
                "id": "투자야-놀자-bOZAG",
                "profile": {
                  "name": "투자야 놀자",
                  "summary": "금융투자 전문 블로거",
                  "image_url": "https://cdn.banksalad.com/graphic/color/logo/original/banksalad.png",
                },
              },
              "url": "https://banksalad.com/contents/코스닥-벤처펀드-선택-시-고려할-3가지-E2ctj",
              "description": "요즘 펀드시장에서는 코스닥벤처펀드가 단연 대세입니다. 소득공제가 가능하고, 공모주 30%를 우선배정 받을 수 있다는 등의 메리트에 힘입어 출시한 지 두 달도 안돼 3조 원에 가까운 자금을 끌어모았죠. 공모펀드에 불리했던 요건들이 개선된 지금.  이 같은 제도를 등에 업고 새로운 코스닥벤처펀드가 생겨나고 있는데요. 기존 코스닥 벤처 공모펀드가 사모펀드 대비 조건이 불리해서 가입을 망설였던 분들이 계시다면 시중에 가입 가능한 코스닥벤처펀드 가운데 펀드를 골라보심이 어떠실까요?",
              "published": true,
              "id": "코스닥-벤처펀드-선택-시-고려할-3가지-E2ctj",
              "created_at": "2018-06-11",
              "title": "코스닥 벤처펀드 선택 시 고려할 3가지",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552907358140-people-3295555_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "id": "investment",
      "sub2": {
        "name": "상품비교",
        "items": [
          {
            "name": "연금저축펀드, TDF 비교분석!",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/세액공제-대표상품-연금저축펀드-TDF는-어떨까-EQUj5",
              "description": "올해도 벌써 한 달밖에 남지 않았습니다. 연말정산, 그리고 가까워지는 연말이 찬바람과 함께 피부로 느껴집니다. 이맘때쯤이면 자신의 금융자산을 한 번 점검해보는 분들이 많아집니다.  하지만 급격히 추워진 날씨만큼이나 요즘의 금융시장도 다소 ‘싸늘하게’ 느껴집니다. 세제 혜택은 그대로 가져가면서, 내 수익률을 안정적으로 지켜줄 수 있는 상품이 생각나는 시기입니다.   연금저축펀드를 세제혜택뿐만 아니라, 노후준비를 위한 ‘연금’ 준비용으로 톡톡히 활용하기를 원하시는 분들께 최근 인기를 끌고 있는 상품이 바로 ‘TDF’입니다.",
              "published": true,
              "id": "세액공제-대표상품-연금저축펀드-TDF는-어떨까-EQUj5",
              "created_at": "2018-12-04",
              "title": "세액공제 대표상품 '연금저축펀드', TDF는 어떨까?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552788993610-senior-3336451_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "주식거래를 위한 HTS/MTS",
            "content": {
              "author": {
                "id": "이코노마드-6ea9",
                "profile": {
                  "name": "이코노마드",
                  "summary": "돈과 행복의 지혜창고",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%9D%B4%EC%BD%94%EB%85%B8%EB%A7%88%EB%93%9C.JPG",
                },
              },
              "url": "https://banksalad.com/contents/2017-BEST-HTSMTS-831b",
              "description": "주식거래를 직접 하는 개인 투자자에게 홈트레이딩 시스템(HTS), 모바일 트레이딩 시스템(MTS)은 굉장히 중요합니다. 어떤 프로그램을 사용하는가에 따라 정보의 질과 양이 달라집니다. 당연히 수익에도 영향을 미치죠. 그렇다면 어떤 HTS와 MTS가 좋을까요? 뱅크샐러드는 이코노마드와 함께 각 증권사별 HTS와 MTS를 살펴봤습니다.",
              "published": true,
              "id": "2017-BEST-HTSMTS-831b",
              "created_at": "2017-09-14",
              "title": "2017 BEST HTS/MTS",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552615902655-calculator-385506_1920.jpg",
            },
            "type": "content",
          },
        ],
      },
    },
    {
      "name": "연금",
      "sub1": {
        "name": "상품추천",
        "items": [
          {
            "name": "연금저축, 어디서 가입할까?",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/연말정산-세액공제-필수템-연금저축-어디서-가입하는-게-좋을까-EeBoE",
              "description": "날씨가 쌀쌀해지니 직장인들의 머리를 스쳐지나가는 한 단어가 있습니다. 바로 ‘연말정산’ 입니다. 그 동안 신청하지 않았던 현금영수증, 가입하지 않았던 절세상품 등이 조금씩 아쉬워지는 순간이기도 하죠. 다가오는 연말정산, 잘 준비하고 계신가요?   돈을 ‘쓰면서’ 받는 카드/현금영수증 소득공제는 어쩔 수 없다고 해도, 돈을 ‘모으면서’ 받는 세액공제는 아직 늦지 않았습니다. 매년 66만원은 확실히 공제받을 수 있는 방법으로 가장 사랑받는 것이 바로 연금저축입니다.   절세와 노후준비의 두 마리 토끼를 잡는 상품 연금저축, 세액공제 효과를 최대한으로 받는 법과 어디서 가입하는 것이 좋을지 알아보겠습니다. ",
              "published": true,
              "id": "연말정산-세액공제-필수템-연금저축-어디서-가입하는-게-좋을까-EeBoE",
              "created_at": "2018-11-12",
              "title": "연말정산 세액공제 필수템 연금저축, 어디서 가입하는 게 좋을까?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552789202508-money-2724241_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "연금저축 로보어드바이저 추천",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/내-연금-누가-관리해주지-연금저축-로보어드바이저가-도와줄게요-f83FU",
              "description": "지난 해 국내 연금저축 가입자가 560만명이 넘어섰다고 합니다. 경제활동을 하는 분들의 1/4 이상이 가입한 셈인데요, 그만큼 많은 소비자에게 친숙한 상품입니다.  하지만 자신의 연금저축 수익률이 얼마나 나오고 있는지  잘 챙기고 계신지요?  연금저축계좌를 갖고 계신 분들도 가입만 하고 수익률 관리가 잘 안 되는 경우가 대부분입니다.",
              "published": true,
              "id": "내-연금-누가-관리해주지-연금저축-로보어드바이저가-도와줄게요-f83FU",
              "created_at": "2018-10-04",
              "title": "내 연금, 누가 관리해주지? : 연금저축 로보어드바이저가 도와줄게요!",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552790058640-robot-2937861_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "sub3": {
        "name": "매거진",
        "items": [
          {
            "name": "연금저축, 절세혜택만 알고 계신가요?",
            "content": {
              "author": {
                "id": "김영일-1a36",
                "profile": {
                  "name": "김영일",
                  "summary": "포트윈 교육팀장",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_kimyoungil.png",
                },
              },
              "url": "https://banksalad.com/contents/연금저축계좌-절세혜택만-알고-계신가요-832d",
              "description": "직장인들의 연말정산 시즌이 다가온다. 이에 맞춰 세금 환급은 근로자들의 특명과도 같다. 세금 환급에 가장 큰 위력을 발휘하는 것은, 역시 '연금저축' 등 연금계좌와 관련된 항목이다. 그런데, 연금계좌에 대한 포커스가 '절세'에 맞춰져 있어 정작 이 상품의 또 다른 가치를 알고 있는 사람은 많지 않다.",
              "published": true,
              "id": "연금저축계좌-절세혜택만-알고-계신가요-832d",
              "created_at": "2016-11-17",
              "title": "연금저축계좌, 절세혜택만 알고 계신가요?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550711685717-old-age-957492_1280.png",
            },
            "type": "content",
          },
          {
            "name": "연금 계좌에서 돈 꺼내는 순서",
            "content": {
              "author": {
                "id": "김영일-1a36",
                "profile": {
                  "name": "김영일",
                  "summary": "포트윈 교육팀장",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_kimyoungil.png",
                },
              },
              "url": "https://banksalad.com/contents/연금-계좌에서-돈-꺼내는-순서는-f532",
              "description": "노후설계(Retirement Planning)는 노후 때 필요한 적정 생활비를 산출하고 그 자금을 만들어내는 데 필요한 총 자금을 계산 후, 부족자금이 얼마인지를 알아보는 과정이 일반적입니다. 그런데 목전에 노후생활을 둔'예비은퇴자'들의 노후설계는 이와는 다릅니다. 대개 보유한 자산을 구분하고, 어떤 자산을 ‘연금화’시킬 것인지를 결정합니다. 동시에 어떤 순서로 얼마씩을 받도록 설계할 것인지를 정하는 것이 상당히 중요합니다.",
              "published": true,
              "id": "연금-계좌에서-돈-꺼내는-순서는-f532",
              "created_at": "2017-01-30",
              "title": "연금 계좌에서 돈 꺼내는 순서는?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550737574857-pair-2914879_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "목돈이 필요할 때 연금저축을 해지해야 할까?",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/목돈이-필요한-순간-매달-내던-연금저축을-해지해야-할까-1395",
              "description": "노후를 대비해, 또는 세제 혜택을 위해 매달 꼬박꼬박 붓던 연금저축. 꾸준히 부으면 세제혜택을 받을 수 있지만, 중도해지하는 경우 적지 않은 세금이 부과되어 갑자기 돈이 필요한 상황에 고민하시는 분들이 많습니다.",
              "published": true,
              "id": "목돈이-필요한-순간-매달-내던-연금저축을-해지해야-할까-1395",
              "created_at": "2017-01-02",
              "title": "목돈이 필요한 순간, 매달 내던 연금저축을 해지해야 할까?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551080150747-old-people-1555705_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "주택연금 A to Z",
            "content": {
              "author": {
                "id": "나원식-53ca",
                "profile": {
                  "name": "나원식",
                  "summary": "비즈니스워치 금융부 기자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_nawonsik.png",
                },
              },
              "url": "https://banksalad.com/contents/내-집-넘기기-꺼림칙-주택연금-A-to-Z-c75c",
              "description": "서울에서 작은 가게를 운영하는 최영자(가명) 씨는 얼마 전 아들의 전화를 받았습니다. 3년 전에 결혼한 아들은 최 씨에게 주택연금에 대해 아느냐고 물었습니다. 집을 담보로 평생 연금을 받는 정부의 정책 상품인데, 최 씨에게 집이 한 채 있으니 가입해보라는 거였습니다. 최 씨는 평생 돈을 모아 겨우 마련한 집을 나라에 맡기는 것도 꺼림칙 한 데다가, 자녀들에게 집을 물려주려고 생각해온 터라 '말도 안 되는 소리'라며 단번에 거절했습니다.",
              "published": true,
              "id": "내-집-넘기기-꺼림칙-주택연금-A-to-Z-c75c",
              "created_at": "2017-01-11",
              "title": "내 집 넘기기 꺼림칙? 주택연금 A to Z",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551074108421-house-3966469_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "id": "annuity",
      "sub2": {
        "name": "연금가입 전 읽어야할 글",
        "items": [
          {
            "name": "국민연금 이해하기",
            "content": {
              "author": {
                "id": "조진환-d40f",
                "profile": {
                  "name": "조진환",
                  "summary": "<7일 만에 끝내는 돈 공부>,<경제습관을 상속하라>저자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%A1%B0%EC%A7%84%ED%99%98%EB%8B%98.JPG",
                },
              },
              "url": "https://banksalad.com/contents/행복한-노년을-준비하는-법-3-노년준비의-기본-국민연금의-6가지-특징-9f2e",
              "description": "지난 칼럼에서는 국민연금은 무엇인지, 그리고 국민연금에 대해 많은 사람들이 잘못 생각하고 있는 부분들은 무엇인지 알아보았습니다. 이번에는, 국민연금의 6가지 특징에 대해 구체적으로 살펴보겠습니다.",
              "published": true,
              "id": "행복한-노년을-준비하는-법-3-노년준비의-기본-국민연금의-6가지-특징-9f2e",
              "created_at": "2017-05-27",
              "title": "행복한 노년을 준비하는 법 (3) 노년준비의 기본 '국민연금'의 6가지 특징",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550647668833-grandparents-3436463_1280.png",
            },
            "type": "content",
          },
          {
            "name": "퇴직연금의 개념과 종류",
            "content": {
              "author": {
                "id": "조진환-d40f",
                "profile": {
                  "name": "조진환",
                  "summary": "<7일 만에 끝내는 돈 공부>,<경제습관을 상속하라>저자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%A1%B0%EC%A7%84%ED%99%98%EB%8B%98.JPG",
                },
              },
              "url": "https://banksalad.com/contents/퇴직연금의-개념과-종류-acb3",
              "description": "초고령화 사회로의 진입을 앞두고 있는 지금, 노후준비는 더 이상 선택이 아닌 필수라고 하는데요. 특히 퇴직급여는 은퇴 이후의 안정적인 생활에 큰 역할을 하기에 눈 여겨 봐야하는 제도입니다. 빠르면 빠를수록 좋은 노후준비를 위해, 오늘은 뱅크샐러드에서 기존 퇴직금제도의 보완책인 '퇴직연금' 대해 정리해보았습니다.",
              "published": true,
              "id": "퇴직연금의-개념과-종류-acb3",
              "created_at": "2017-09-13",
              "title": "퇴직연금의 개념과 종류",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1553060453339-pair-2914879_1920.jpg",
            },
            "type": "content",
          },
          {
            "name": "개인연금의 종류는?",
            "content": {
              "author": {
                "id": "조진환-d40f",
                "profile": {
                  "name": "조진환",
                  "summary": "<7일 만에 끝내는 돈 공부>,<경제습관을 상속하라>저자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%A1%B0%EC%A7%84%ED%99%98%EB%8B%98.JPG",
                },
              },
              "url": "https://banksalad.com/contents/행복한-노년을-준비하는-법-5-개인연금의-종류는-2b83",
              "description": "노년의 재무적 준비를 위한 3층보장제도의 마지막 단계인 개인연금에 대해 살펴보죠.   개인연금은 노년을 준비하는 과정에서나 노년에 연금을 수령하는 과정에서 매우 중요한 부분임을 잊지 말고 꼼꼼하게 공부해야 합니다.",
              "published": true,
              "id": "행복한-노년을-준비하는-법-5-개인연금의-종류는-2b83",
              "created_at": "2017-07-20",
              "title": "행복한 노년을 준비하는 법 (5) 개인연금의 종류는?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550737926778-person-3553814_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "연금저축의 개념과 종류",
            "content": {
              "author": {
                "id": "김영일-1a36",
                "profile": {
                  "name": "김영일",
                  "summary": "포트윈 교육팀장",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_kimyoungil.png",
                },
              },
              "url": "https://banksalad.com/contents/연금저축의-개념과-종류-b0e1",
              "description": "연금저축은 은퇴 후 여유로운 생활을 위한 개인연금의 한 종류로 납입할 때는 세액공제 혜택을 받을 수 있고, 연금수령 시에는 상대적으로 낮은 연금소득세가 적용되는 장기 저축상품을 의미합니다. 노후 준비를 하는 동시에 세금 절약까지 가능하기 때문에 재테크 수단으로도 매력적인 상품이죠.",
              "published": true,
              "id": "연금저축의-개념과-종류-b0e1",
              "created_at": "2017-09-13",
              "title": "연금저축의 개념과 종류",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550655341275-generation-453136_1920.png",
            },
            "type": "content",
          },
        ],
      },
    },
    {
      "name": "핀테크",
      "sub1": {
        "name": "상품비교",
        "items": [
          {
            "name": "케이뱅크VS카카오뱅크 비교분석",
            "content": {
              "author": {
                "id": "나원식-53ca",
                "profile": {
                  "name": "나원식",
                  "summary": "비즈니스워치 금융부 기자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_nawonsik.png",
                },
              },
              "url": "https://banksalad.com/contents/케이뱅크-VS-카카오뱅크-어디가-더-나을까-8f00",
              "description": "최근 은행업계에 도전장을 내민 두 은행이 있습니다. 우리나라에서 처음으로 탄생한 인터넷전문은행인 케이뱅크와 카카오뱅크인데요. 딱딱하고 천편일률적이었던 기존 은행과 달리 귀여운 캐릭터가 가득한 체크카드, 24시간 거래가능한 접근성, 수수료 면제 등 차별화된 서비스를 내세워 금융권에 돌풍을 일으켰습니다.",
              "published": true,
              "id": "케이뱅크-VS-카카오뱅크-어디가-더-나을까-8f00",
              "created_at": "2017-08-23",
              "title": "케이뱅크 VS 카카오뱅크 어디가 더 나을까?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551088106390-experience-3239623_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "가상화폐 거래소 비교분석",
            "content": {
              "author": {
                "id": "maa-TLiop",
                "profile": {
                  "name": "maa",
                  "summary": "암호화폐 관련 블로거",
                  "image_url": "https://cdn.banksalad.com/entities/content-authors1506391574300-%EC%95%94%ED%98%B8%ED%99%94%ED%8F%90.JPG",
                },
              },
              "url": "https://banksalad.com/contents/가상화폐-거래소-비교분석-7BYW3",
              "description": "가상화폐가 새로운 투자처로 떠오르고 있습니다. 가상화폐는 한정된 발행량과 사용처가 늘어나면서 화폐 가치가 높아지고 있습니다. 이에 따라 가상화폐 시장은 주식, 외환 시장에 이어 새로운 가치에 장기투자를 통해 수익을 확보할 수 있는 기회의 시장으로 부상하고 있죠. 하지만 무한한 자유도와 위험성이 공존하는 만큼 가상화폐 거래소를 잘 선택해야 합니다. 이에 뱅크샐러드는 가상화폐 거래소별 특징을 비교 분석 했습니다.",
              "published": true,
              "id": "가상화폐-거래소-비교분석-7BYW3",
              "created_at": "2017-09-28",
              "title": "가상화폐 거래소 비교분석",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-tags1506590141526-Main_%E1%84%80%E1%85%A1%E1%84%89%E1%85%A1%E1%86%BC%E1%84%92%E1%85%AA%E1%84%91%E1%85%A8%20%E1%84%80%E1%85%A5%E1%84%85%E1%85%A2%E1%84%89%E1%85%A9%20%E1%84%87%E1%85%B5%E1%84%80%E1%85%AD%E1%84%87%E1%85%AE%E1%86%AB%E1%84%89%E1%85%A5%E1%86%A8%20%281%29.jpg",
            },
            "type": "content",
          },
          {
            "name": "4대 크라우드 펀딩 플랫폼 비교",
            "content": {
              "author": {
                "id": "글쓰는핀테크-7c11",
                "profile": {
                  "name": "글쓰는핀테크",
                  "summary": "생생한 핀테크 이야기를 전해드립니다",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EA%B8%80%EC%93%B0%EB%8A%94%ED%95%80%ED%85%8C%ED%81%AC.JPG",
                },
              },
              "url": "https://banksalad.com/contents/크라우드펀딩-플랫폼-비교-텀블벅-vs-유캔스타트-vs-와디즈-vs-오픈트레이드-wbejU",
              "description": "영화, 프로젝트, 스타트업 등에 보다 쉽게 투자할 수 있는 길이 열렸습니다. 시중에서 흔히 보기 어려운 아이디어 상품도 여러 사람들의 자금을 모아 제작할 수도 있게 됐죠. 바로 크라우드펀딩 덕분인데요.   크라우드 펀딩이란 소셜 네트워크 서비스를 이용해 소규모후원을 받거나 투자 등의 목적으로 인터넷과 같은 플랫폼을 통해 다수의 개인들로부터 자금을 모으는 행위를 말합니다. 크라우드 펀딩은 크게 후원형과 증권형으로 나눌 수 있는데요. 그 특징은 다음과 같습니다.",
              "published": true,
              "id": "크라우드펀딩-플랫폼-비교-텀블벅-vs-유캔스타트-vs-와디즈-vs-오픈트레이드-wbejU",
              "created_at": "2017-12-21",
              "title": "크라우드펀딩 플랫폼 비교: 텀블벅 vs 유캔스타트 vs 와디즈 vs 오픈트레이드",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1513847496971-%E1%84%8F%E1%85%B3%E1%84%85%E1%85%A1%E1%84%8B%E1%85%AE%E1%84%83%E1%85%B3%E1%84%91%E1%85%A5%E1%86%AB%E1%84%83%E1%85%B5%E1%86%BC%20%E1%84%91%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A2%E1%86%BA%E1%84%91%E1%85%A9%E1%86%B7%20%E1%84%87%E1%85%B5%E1%84%80%E1%85%AD_main.jpeg",
            },
            "type": "content",
          },
        ],
      },
      "sub3": {
        "name": "매거진",
        "items": [
          {
            "name": "핀테크 개념 이해하기",
            "content": {
              "author": {
                "id": "글쓰는핀테크-7c11",
                "profile": {
                  "name": "글쓰는핀테크",
                  "summary": "생생한 핀테크 이야기를 전해드립니다",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EA%B8%80%EC%93%B0%EB%8A%94%ED%95%80%ED%85%8C%ED%81%AC.JPG",
                },
              },
              "url": "https://banksalad.com/contents/핀테크-개념-완벽-정리-9752",
              "description": "핀테크(Fintech)란 금융(Finance)에 IT기술(Technology)을 접목하여 복잡하고 어려웠던 금융을 효율적으로 편리하게 서비스하는 것을 뜻합니다.",
              "published": true,
              "id": "핀테크-개념-완벽-정리-9752",
              "created_at": "2017-09-05",
              "title": "핀테크 개념 완벽 정리",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550740543583-laptop-3190194_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "블록체인 개념 이해하기",
            "content": {
              "author": {
                "id": "글쓰는핀테크-7c11",
                "profile": {
                  "name": "글쓰는핀테크",
                  "summary": "생생한 핀테크 이야기를 전해드립니다",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EA%B8%80%EC%93%B0%EB%8A%94%ED%95%80%ED%85%8C%ED%81%AC.JPG",
                },
              },
              "url": "https://banksalad.com/contents/블록체인-개념-완벽-정리-dh1do",
              "description": "가상화폐 비트코인이 떠오르면서 블록체인이란 용어가 많이 보이고 있습니다. 비트코인과 블록체인은 혼동되어 사용되기도 하지만 같은 의미는 아닙니다. 블록체인은 ‘공공 거래 장부’로 불리는 데이터 분산 처리 기술을 말합니다. 그렇다면 왜 블록체인이 떠오르고 있을까요. 뱅크샐러드가 블록체인을 완벽 정리 했습니다.",
              "published": true,
              "id": "블록체인-개념-완벽-정리-dh1do",
              "created_at": "2017-09-20",
              "title": "블록체인 개념 완벽 정리",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1553063050627-bitcoin-2643159_1920.jpg",
            },
            "type": "content",
          },
          {
            "name": "비트코인, 블록체인이란?",
            "content": {
              "author": {
                "id": "easyblockchain-sNLH9",
                "profile": {
                  "name": "easyblockchain",
                  "summary": "블록체인 분야 블로거",
                  "image_url": "https://cdn.banksalad.com/entities/content-authors/1520621538996-%ED%94%84%EB%A1%9C%ED%95%84%EC%82%AC%EC%A7%84.jpg",
                },
              },
              "url": "https://banksalad.com/contents/쉽게-이해하는-블록체인-비트코인-블록체인이란-무엇인가-0vLh5",
              "description": "개념을 정리하기에 앞서 한 가지 질문을 해보자.  “비트코인이 미래의 화폐가 될 것인가?”",
              "published": true,
              "id": "쉽게-이해하는-블록체인-비트코인-블록체인이란-무엇인가-0vLh5",
              "created_at": "2018-03-10",
              "title": "쉽게 이해하는 블록체인 : 비트코인, 블록체인이란 무엇인가",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1553062409258-block-chain-3055701_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "id": "fintech",
      "sub2": {
        "name": "상품리뷰",
        "items": [
          {
            "name": "앱 하나로 다 해 주는 금융서비스 모음",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/앱-하나로-끝-앱택트-Apptact-금융서비스-모음-igvnv",
              "description": "‘상담 직원과 직접 마주치는 건 좀 불편해.  무슨 얘기를 해야 할지도 모르겠고…  내가 알아서 할 테니 그냥 비대면으로 처리하고 싶어;;’  혹시 이런 생각 한 번쯤 해 보신 분 계신가요? 그렇다면 당신도 어쩌면 ‘언택트’ 를 선호하시는 것일지 모릅니다. ",
              "published": true,
              "id": "앱-하나로-끝-앱택트-Apptact-금융서비스-모음-igvnv",
              "created_at": "2018-10-06",
              "title": "앱 하나로 끝! ‘앱택트(Apptact)’ 금융서비스 모음",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552789675581-agreement-2548138_1280.jpg",
            },
            "type": "content",
          },

          {
            "name": "[사용자리뷰] 가상화폐 투자 플랫폼 Coinone",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/핀테크-서비스-소비자-체험기-가상화폐-투자-Coinone-거래소-7831",
              "description": "2014년 마운트 곡스 거래소 파산 사태로 한 때 1,000달러에서 97달러까지 폭락했던 비트코인이 최근 새로운 투자수단으로 또 다시 급부상 하고 있다. 비트코인은 2009년 나카모토 사토시가 개발한 세계 최초의 블록체인 기반 가상화폐이다.",
              "published": true,
              "id": "핀테크-서비스-소비자-체험기-가상화폐-투자-Coinone-거래소-7831",
              "created_at": "2017-07-01",
              "title": "[핀테크 서비스 소비자 체험기] 가상화폐 투자, Coinone 거래소",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fbitcoin.jpg",
            },
            "type": "content",
          },
        ],
      },
    },
    {
      "name": "매거진",
      "sub1": {
        "name": "에디터 추천",
        "items": [
          {
            "name": "나만을 위한 내 돈 관리 앱!",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/500만이-선택한-내-돈-관리-앱-뱅크샐러드-aDMhD",
              "description": "‘돈 관리’ 하면 왠지 (나 말고..)부지런하고 꼼꼼한 사람들만 할 수 있는 것이라는 생각이 들곤 하는데요. 남의 이야기처럼 느껴졌던 돈 관리, ‘나만을 위한 내 돈 관리 앱’ 으로 이제 무료로, 쉽게 할 수 있습니다!",
              "published": true,
              "id": "500만이-선택한-내-돈-관리-앱-뱅크샐러드-aDMhD",
              "created_at": "2019-09-05",
              "title": "500만이 선택한 내 돈 관리 앱, 뱅크샐러드",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1567693701704-190904_%EB%B1%85%ED%81%AC%EC%83%90%EB%9F%AC%EB%93%9C_30%EB%8C%80_%EC%A7%80%EC%BC%9C%EC%A4%98%28%ED%99%94%EC%9D%B4%ED%8A%B8%29305%20%281%29.jpg",
            },
            "type": "content",
          },
          {
            "name": "항공사 마일리지 사용 팁",
            "content": {
              "author": {
                "id": "박주연-c7f6",
                "profile": {
                  "name": "박주연",
                  "summary": "아시아경제TV 앵커",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_parkjuyeon.png",
                },
              },
              "url": "https://banksalad.com/contents/내년이면-소멸되는-항공사-마일리지-어떻게-써야-할까-uWarU",
              "description": "2019년 1월 1일부터 유효기간 10년이 넘은 항공 마일리지는 소멸된다. 대한항공과 아시아나 항공에서 2008년 약관을 개정해 마일리지 유효기간을 10년으로 정했고, 이에 내년부터 유효기간이 지난 마일리지는 순차적으로 자동 소멸되는 것이다. 불과 3개월도 남지 않았다!",
              "published": true,
              "id": "내년이면-소멸되는-항공사-마일리지-어떻게-써야-할까-uWarU",
              "created_at": "2018-10-15",
              "title": "내년이면 소멸되는 항공사 마일리지, 어떻게 써야 할까?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552789362984-plane-841441_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "편의점 1+1 행사의 비밀",
            "content": {
              "author": {
                "id": "박주연-c7f6",
                "profile": {
                  "name": "박주연",
                  "summary": "아시아경제TV 앵커",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_parkjuyeon.png",
                },
              },
              "url": "https://banksalad.com/contents/편의점-1-1-행사의-비밀-5QtJR",
              "description": "이번 달 프랜차이즈 편의점에는 어떤 1+1 행사들이 펼쳐지고 있을까?",
              "published": true,
              "id": "편의점-1-1-행사의-비밀-5QtJR",
              "created_at": "2018-09-17",
              "title": "편의점 1+1 행사의 비밀 !",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552790264733-building-2583628_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "금융상품 고를 때 가격 비교 쉽게 하는 팁",
            "content": {
              "author": {
                "id": "뱅크샐러드-d41f",
                "profile": {
                  "name": "뱅크샐러드",
                  "summary": "<뱅크샐러드> 금융매거진",
                  "image_url": "https://cdn.banksalad.com/app/meta/introduce-a/favicon.png",
                },
              },
              "url": "https://banksalad.com/contents/금융상품-고를-때-가격-비교-쉽게-하는-팁-l1MiJ",
              "description": "요즘 물건을 살 때 가격을 비교하기는 참 쉽습니다. 어지간한 물건들은 그냥 포털사이트에 검색만 해도 낮은 가격부터 쭉 나오니 말입니다.  하지만 금융상품을 고를 때에는 가격, 즉 ‘가성비’를 따지기가 참 어렵습니다.  예를 들어 신용카드를 연회비만으로 비교할 수 있다면 참 편하겠지만, 우리가 돈을 어디서 얼마나 쓰고 다니는지에 따라 받을 수 있는 혜택은 달라집니다. ",
              "published": true,
              "id": "금융상품-고를-때-가격-비교-쉽게-하는-팁-l1MiJ",
              "created_at": "2019-01-29",
              "title": "금융상품 고를 때 가격 비교 쉽게 하는 팁",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1553059727529-woman-792162_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "sub4": {
        "name": "ABOUT",
        "items": [
          {
            "name": "집필진 소개",
            "url": "/contents/authors",
            "type": "url",
          },
          {
            "name": "기고자 모집",
            "url": "/contents/contribution",
            "type": "url",
          },
        ],
      },
      "sub3": {
        "name": "금융이야기",
        "items": [
          {
            "name": "최소한의 경제적 자유를 얻는 3가지 방법",
            "content": {
              "author": {
                "id": "차칸양-6b3c",
                "profile": {
                  "name": "차칸양",
                  "summary": "<불황을 이기는 월급의 경제학>의 저자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_chakanyang.png",
                },
              },
              "url": "https://banksalad.com/contents/직장인이-최소한의-경제적-자유를-얻기-위한-3가지-방법--0020",
              "description": "의외로 많은 사람들이 경제적 판타지를 가지고 있습니다. ‘가진 돈이 많다면...’ 이란 상상을 하며, 갖고 싶은 것, 사고 싶은 것, 먹고 싶은 것, 가고 싶은 곳 등을 머리에 그립니다. 한마디로 부자가 되고 싶어하죠. 포털 사이트 검색 창에 ‘부자’라고 쳐보세요. 그러면 첫 번째 완성어로 바로 ‘부자되는 방법’이 뜨는데, 엔터를 쳐보면 아마 놀라실 겁니다.",
              "published": true,
              "id": "직장인이-최소한의-경제적-자유를-얻기-위한-3가지-방법--0020",
              "created_at": "2016-11-13",
              "title": "직장인이 최소한의 경제적 자유를 얻기 위한 3가지 방법 ①",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551063738994-woman-570883_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "경제적 자유를 얻으려면 당장 실행할 5가지",
            "content": {
              "author": {
                "id": "조진환-d40f",
                "profile": {
                  "name": "조진환",
                  "summary": "<7일 만에 끝내는 돈 공부>,<경제습관을 상속하라>저자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%A1%B0%EC%A7%84%ED%99%98%EB%8B%98.JPG",
                },
              },
              "url": "https://banksalad.com/contents/경제적-자유를-얻고-싶다면-당장-실행해야-할-5가지-af01",
              "description": "10년 이상의 시간동안 많은 곳에서 강의를 하고 다양한 사람들을 만나 상담하고 책을 집필하는 과정에서 느낀 것은, 경제적 자유를 누리는 사람들에게 중요한 것은 학벌,지식,출신 정보 등이 아니라는 겁니다. 사람은 두 가지 분류로 나눠질 뿐입니다. 실행하는 사람과 실행하지 않는 사람.",
              "published": true,
              "id": "경제적-자유를-얻고-싶다면-당장-실행해야-할-5가지-af01",
              "created_at": "2017-03-11",
              "title": "경제적 자유를 얻고 싶다면 당장 실행해야 할 5가지",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551061121799-gui-2311261_1280.png",
            },
            "type": "content",
          },
          {
            "name": "저축을 가로막는 지출습관 탈출하기",
            "content": {
              "author": {
                "id": "한국금융투자자보호재단-2cc2",
                "profile": {
                  "name": "한국금융투자자보호재단",
                  "summary": "투자자의 권익향상을 위한 비영리 공익재단",
                  "image_url": "https://cdn.banksalad.com/entities/content-authors1505888031799-kici.png",
                },
              },
              "url": "https://banksalad.com/contents/생활금융-100단-저축을-가로막는-지출-습관에서-탈출하기-f7CWe",
              "description": " 저축은 커녕 매월 쓸 돈도 부족하다는 한 직장인의 이야기이다.  월급 받은 다음날 신용카드 결제 대금으로 월급이 몽땅 인출되어서 다시 신용카드로 한 달을 버틴다고 한다. 얼마 전에는 신용카드 결제 대금이 월급보다 많아 그나마 조금 모아놓았던 예금까지 해지했다고 한다. ",
              "published": true,
              "id": "생활금융-100단-저축을-가로막는-지출-습관에서-탈출하기-f7CWe",
              "created_at": "2017-09-22",
              "title": "저축을 가로막는 지출 습관 탈출!",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1553063700522-coins-1523383_1920.jpg",
            },
            "type": "content",
          },
          {
            "name": "초보자를 위한 신용관리 개론",
            "content": {
              "author": {
                "id": "유유자적-4lNa5",
                "profile": {
                  "name": "유유자적",
                  "summary": "재테크 블로거",
                  "image_url": "https://cdn.banksalad.com/graphic/color/logo/original/banksalad.png",
                },
              },
              "url": "https://banksalad.com/contents/초보자를-위한-신용등급-관리-개론-da8b",
              "description": "우리는 신용사회에 살고 있다. 신용이 좋으면 현금 없이도 어딜 가나 물건을 살 수 있고, 주택구매나 투자를 목적으로 큰 금액의 대출도 받을 수 있다. 그렇다면 신용이 좋다는 것은 무엇일까?",
              "published": true,
              "id": "초보자를-위한-신용등급-관리-개론-da8b",
              "created_at": "2016-10-18",
              "title": "초보자를 위한 신용등급 관리 개론",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552637447234-student-849825_1280.jpg",
            },
            "type": "content",
          },
        ],
      },
      "id": "magazine",
      "sub2": {
        "name": "금융생활 가이드",
        "items": [
          {
            "name": "가스비 아끼는 꿀팁",
            "content": {
              "author": {
                "id": "윤성애-3e27",
                "profile": {
                  "name": "윤성애",
                  "summary": "<돈 없어도 나는 재테크를 한다> 저자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2FProfile.png",
                },
              },
              "url": "https://banksalad.com/contents/추운-겨울-가스비-아끼는-꿀팁-6032",
              "description": "갑작스레 찾아온 겨울, 날 선 추위에 뼈마디가 시립니다. 하지만 곧 닥쳐올 더 큰 시련이 있습니다. 바로 '가스비'입니다. 가스비는 여름에는 적게 나오지만, 겨울에는 난방 때문에 금액이 많이 나오기 마련입니다. 가스비, 어떻게 절약할 수 있을까요?",
              "published": true,
              "id": "추운-겨울-가스비-아끼는-꿀팁-6032",
              "created_at": "2016-11-30",
              "title": "추운 겨울, 가스비 아끼는 꿀팁!",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1552638498964-warm-and-cozy-1975215_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "전기세 아끼는 꿀팁",
            "content": {
              "author": {
                "id": "윤성애-3e27",
                "profile": {
                  "name": "윤성애",
                  "summary": "<돈 없어도 나는 재테크를 한다> 저자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2FProfile.png",
                },
              },
              "url": "https://banksalad.com/contents/전기세를-아끼는-또-다른-방법들-ba76",
              "description": "이번 칼럼은 에어컨 전기세 절약하는 법에 이어, 다른 전자제품들의 전기세 아끼는 방법에 대해 알아보겠습니다.",
              "published": true,
              "id": "전기세를-아끼는-또-다른-방법들-ba76",
              "created_at": "2017-08-07",
              "title": "전기세를 아끼는 또 다른 방법들",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1550723488228-outlet-3532322_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "항공권 취소하면, 환불받을 수 있을까?",
            "content": {
              "author": {
                "id": "장은석-df35",
                "profile": {
                  "name": "장은석",
                  "summary": "서울신문 기자",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%9E%A5%EC%9D%80%EC%84%9D+%EA%B8%B0%EC%9E%90%EB%8B%98.jpg",
                },
              },
              "url": "https://banksalad.com/contents/온라인쇼핑몰에서-산-항공권-7일안에-취소하면-수수료-없어요-1786",
              "description": "직장인 A씨는 해외에서 여름휴가 보내기 위해 최근 온라인쇼핑몰에서 150만원을 내고 왕복 비행기표 2장을 샀다가 황당한 일을 당했습니다. 회사 일정 때문에 어쩔 수 없이 휴가를 미뤄야 해서 비행기표를 취소했는데요. 예매한 지 이틀밖에 되지 않았는데 항공사에서 1인당 30만원씩 총 60만원의 취소 수수료(위약금)를 뗀다네요.",
              "published": true,
              "id": "온라인쇼핑몰에서-산-항공권-7일안에-취소하면-수수료-없어요-1786",
              "created_at": "2017-07-06",
              "title": "온라인쇼핑몰에서 산 항공권, 7일안에 취소하면 수수료 없어요",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/entities/content-columns/1551077787223-plane-841441_1280.jpg",
            },
            "type": "content",
          },
          {
            "name": "해외 여행 시 신용카드 활용법!",
            "content": {
              "author": {
                "id": "박주연-c7f6",
                "profile": {
                  "name": "박주연",
                  "summary": "아시아경제TV 앵커",
                  "image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fprofile_parkjuyeon.png",
                },
              },
              "url": "https://banksalad.com/contents/해외-여행-시-신용카드-어떻게-사용할까-22b2",
              "description": "해외여행을 간 직장인 김 모씨. 방문한 아웃렛에서 마음에 드는 물건을 발견하고는 '아,오늘 환율이 얼마니까 이 정도 가격이겠구나!' 이리저리 가격을 따져본 후에 물건을 구매했다. 그런데 나중에 카드요금이 청구되었을 때, 결제된 날짜도 다르고 예상보다 더 많은 금액이 청구된 것을 알게 됐다.",
              "published": true,
              "id": "해외-여행-시-신용카드-어떻게-사용할까-22b2",
              "created_at": "2017-01-26",
              "title": "해외 여행 시, 신용카드 어떻게 사용할까?",
              "type": "column",
              "thumbnail_image_url": "https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent%252Fupload%252Fimages%252Ftravel-1410824_1280_800x535.jpg",
            },
            "type": "content",
          },
        ],
      },
    },
  ],
};
