import { NetworkRequestMapper } from 'data/http/mapper';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardBenefitTypeMapper } from 'data/http/mapper/card/benefit-type';
import { CardFilterMapper } from 'data/http/mapper/card/filter';
import { CardPriorityMapper } from 'data/http/mapper/card/priority';
import { CardSpendingMapper } from 'data/http/mapper/card/spending';
import { lets } from 'utils/index';

export class CardRecommendSpecMapper implements NetworkRequestMapper<CardRecommendSpec> {
    toJson(target: CardRecommendSpec): any {
        return {
            spendings: target.spendings.map(spending =>
                lets(new CardSpendingMapper().toJson(spending), it => {
                    delete it.storeName;
                    return it;
                })
            ),
            benefit_types: target.benefitTypes.map(type =>
                new CardBenefitTypeMapper().toJson(type)
            ),
            filter: new CardFilterMapper().toJson(target.filter),
            priority: new CardPriorityMapper().toJson(target.priority)
        }
    }
}
