import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { AdviceTopExpenseData } from 'core/entity/personal-finance/advice/content-data/top-expense';
import { lets, optional } from 'utils/index';
import { AdviceExpenseAmountSummaryMapper } from 'data/http/mapper/personal-finance/advice/expense-amount-summary';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceTopExpenseDataMapper implements NetworkResponseMapper<AdviceTopExpenseData> {
    fromJson(json: any): AdviceTopExpenseData {
        return new AdviceTopExpenseData(
            lets(json.period, it => {
                switch (it) {
                    case 'weekly':
                        return AdviceTopExpenseData.Period.WEEKLY;
                    case 'monthly':
                        return AdviceTopExpenseData.Period.MONTHLY;
                    default:
                        throw new MappingError();
                }
            }),
            new AdviceExpenseAmountSummaryMapper().fromJson(json.most_visited),
            new AdviceExpenseAmountSummaryMapper().fromJson(json.most_spent),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
