import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountPeriodSnapshot } from 'core/entity/personal-finance/advice/amount-period-snapshot';
import { AmountMapper } from 'data/http/mapper/amount';
import { parseDate } from 'utils/index';

export class AmountPeriodSnapshotMapper implements NetworkResponseMapper<AmountPeriodSnapshot> {
    fromJson(json: any): AmountPeriodSnapshot {
        return new AmountPeriodSnapshot(
            new AmountMapper().fromJson(json.amount),
            parseDate(json.base_datetime),
            parseDate(json.start_datetime),
            parseDate(json.end_datetime)
        )
    }
}
