import { Entity } from 'core/entity';

export class FundCompanySummary extends Entity {
    id: string;
    name: string;
    imageUrl: string;
    type: FundCompanySummary.Type;

    constructor(
        id: string,
        name: string,
        imageUrl: string,
        type: FundCompanySummary.Type
    ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.type = type;
    }
}

export module FundCompanySummary {
    export enum Type {
        BANK = 'bank',
        SECURITIES_FIRM = 'securities_firm'
    }
}
