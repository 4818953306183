import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { InsuranceGuaranteedDisease } from 'core/entity/insurance/guaranteed-disease';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';
import { lets } from 'utils/index';

export class InsuranceGuaranteedDiseaseMapper implements NetworkResponseMapper<InsuranceGuaranteedDisease> {
    fromJson(json: any): InsuranceGuaranteedDisease {
        return new InsuranceGuaranteedDisease(
            json.medical_cost,
            json.description,
            json.additional_description,
            json.guaranteed_price,
            json.guaranteed_category,
            json.disease_name,
            lets(json.checkup_item, it => {
                switch (it) {
                    case 'fat':
                        return MedicalCheckupJudgement.Item.FAT;
                    case 'sight':
                        return MedicalCheckupJudgement.Item.SIGHT;
                    case 'hearing':
                        return MedicalCheckupJudgement.Item.HEARING;
                    case 'high-blood-pressure':
                        return MedicalCheckupJudgement.Item.HIGH_BLOOD_PRESSURE;
                    case 'kidney':
                        return MedicalCheckupJudgement.Item.KIDNEY;
                    case 'anemia':
                        return MedicalCheckupJudgement.Item.ANEMIA;
                    case 'diabetes':
                        return MedicalCheckupJudgement.Item.DIABETES;
                    case 'dyslipidemia':
                        return MedicalCheckupJudgement.Item.DYSLIPIDEMIA;
                    case 'chronic-kidney':
                        return MedicalCheckupJudgement.Item.CHRONIC_KIDNEY;
                    case 'liver':
                        return MedicalCheckupJudgement.Item.LIVER;
                    case 'tb-chest':
                        return MedicalCheckupJudgement.Item.TB_CHEST;
                    case 'osteoporosis':
                        return MedicalCheckupJudgement.Item.OSTEOPOROSIS;
                    case 'not-categorized':
                        return MedicalCheckupJudgement.Item.NOT_CATEGORIZED;
                    default:
                        throw new MappingError();
                }
            })
        )
    }
}
