import React from 'react';

import { UserStatusInfoView } from 'presentation/routes/info/components/info';

const TITLE = `세션이 만료되어<br/>로그인이 비활성화 되었습니다`;
const INFO = '앱을 다시 실행해주세요';

export const UserTokenExpiredInfoView = () => (
    <UserStatusInfoView
        title={ TITLE }
        info={ INFO }
    />
);
