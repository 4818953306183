import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, optional } from 'utils/index';
import { UserCashAccountCreateSpec } from 'core/entity/personal-finance/cash-account/create-spec';

export class UserCashAccountCreateSpecMapper implements NetworkRequestMapper<UserCashAccountCreateSpec> {
    toJson(target: UserCashAccountCreateSpec): any {
        return compactObject({
            nickname: target.nickname,
            latest_balance: target.latestBalance,
            description: optional(target.description),
            hidden: target.hidden
        })
    }

}
