import { Entity } from 'core/entity';
import { List } from 'immutable';
import { PersonalizedLoan } from 'core/entity/personalized-recommend/loan';

export class PersonalizedLoanRecommendedResult extends Entity {
    satisfiedLimitationLoans: List<PersonalizedLoan>;
    unsatisfiedLimitationLoans: List<PersonalizedLoan>;
    totalSize: number;
    last: boolean;

    constructor(
        satisfiedLimitationLoans: List<PersonalizedLoan>,
        unsatisfiedLimitationLoans: List<PersonalizedLoan>,
        totalSize: number,
        last: boolean
    ) {
        super();
        this.satisfiedLimitationLoans = satisfiedLimitationLoans;
        this.unsatisfiedLimitationLoans = unsatisfiedLimitationLoans;
        this.totalSize = totalSize;
        this.last = last;
    }
}
