import { Application } from 'application/context.instance';
import { apply, emptyList, optional } from 'application/utils';
import { LoanCreditScoreAgency } from 'core/entity/loan/credit-score-agency';
import { LoanRecommendResultSummary } from 'core/entity/loan/recommend-result/summary';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { List } from 'immutable';
import { AnnualIncomeInfoAlert } from 'presentation/components/alert/annual-income-info';
import { CreditCoreInfoAlert } from 'presentation/components/alert/credit-score-info';
import { SurveyConfirm, SurveyContainer } from 'presentation/components/survey-container';
import { CreditLoanAmountForm } from 'presentation/components/survey-form/credit-loan-amount';
import { CreditLoanAmount } from 'presentation/components/survey-form/credit-loan-amount/model';
import { CreditLoanIncomeForm } from 'presentation/components/survey-form/credit-loan-income';
import { CreditLoanIncome } from 'presentation/components/survey-form/credit-loan-income/model';
import { CreditLoanJobForm } from 'presentation/components/survey-form/credit-loan-job';
import { CreditLoanJob } from 'presentation/components/survey-form/credit-loan-job/model';
import { CreditLoanScoreLevelForm } from 'presentation/components/survey-form/credit-loan-score-level';
import { CreditLoanScoreLevel } from 'presentation/components/survey-form/credit-loan-score-level/model';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';
import { generateDefaultLoanRecommendFilter } from 'presentation/module/initialized-contents/loan';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { toCurrency } from 'presentation/module/sugar';
import { Question } from 'presentation/view-model/question';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import styles from './styles.pcss';
const TEN_THOUSAND = 10000;

type Props = RouteComponentProps<any>

interface State {
    amount: CreditLoanAmount;
    score: CreditLoanScoreLevel;
    job: CreditLoanJob;
    income: CreditLoanIncome;
    recommendResult?: LoanRecommendResultSummary;
    alertCreditScore: boolean;
    alertAnnualIncome: boolean;
}

export class CreditLoansQuestions extends React.Component<Props, State> {
    state = {
      amount: new CreditLoanAmount(),
      score: new CreditLoanScoreLevel(),
      job: new CreditLoanJob(),
      income: new CreditLoanIncome(),
      recommendResult: null as LoanRecommendResultSummary,
      alertCreditScore: false,
      alertAnnualIncome: false
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
      this.fetchCachedRecommendSpec();
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    render() {
      const {
        amount,
        score,
        job,
        income,
        recommendResult,
        alertCreditScore,
        alertAnnualIncome
      } = this.state;

      const resultValue = optional(recommendResult, it =>
        it.computation.interestConfigurationSummary.isRangeInterest() ?
          `${it.computation.interestConfigurationSummary.getMinInterest().toFixed(2)}~${it.computation.interestConfigurationSummary.getMaxInterest().toFixed(2)}%` :
          `${it.computation.interestConfigurationSummary.getInterest().toFixed(2)}%`
      );
      const additionalInfo = optional(recommendResult, it =>
        `예상 대출한도 ${toCurrency((it.computation.maximumAmountLimit / 10000))}만원`
      );
      const confirmAlert = optional(recommendResult, it => {
        const limit = it.computation.maximumAmountLimit;

        return new SurveyConfirm(
          limit >= amount.value,
          `고객님이 받을 수 있는 최대 대출한도는 ${toCurrency(limit / TEN_THOUSAND)}만원으로 예상됩니다.\n대출금액을 ${toCurrency(limit / TEN_THOUSAND)}만원으로 변경하고 결과를 확인하시겠어요?`
        );
      });

      return (
        <div>
          <div className={ styles.cover }>
                    더 정확한 정보를 입력할수록 <strong>추천이 더 정확<span>해집니다</span></strong>
          </div>
          <SurveyContainer
            questions={ List([ amount, score, job, income ]) }
            resultTitle="예상 연 금리(평균)"
            resultValue={ resultValue }
            additionalInfo={ additionalInfo }
            previewMarginTop={ 44 }
            confirmAlert={ confirmAlert }
            linkUrl={ BANKSALAD_SITEMAP.CREDIT_LOAN_PROFITS }
            gaCategory={ GA_CATEGORY.LOANS_QUESTIONS.PREVIEW }
            gaAction={ GA_ACTION.LINK.LOANS_PROFITS }
            onChangeConfirm={ this.onChangeConfirm }
            onChange={ this.onChange }
          >
            <div className={ styles.title }>
              <span className={ styles.tag }>필수</span>
                        금리와 한도예측을 위한 질문입니다
            </div>
            <div className={ styles.boxQuestions }>
              <dl className={ styles.form }>
                <dt>
                  <span className={ styles.metaQuestion }>금리산출을 위한 질문</span>
                  <span className={ styles.essential }>필요하신 대출금액은 얼마인가요?</span>
                </dt>
                <dd>
                  <CreditLoanAmountForm
                    data={ amount }
                    onChange={ this.onChange }
                  />
                </dd>
                <dt>
                  <span className={ styles.metaQuestion }>금리산출을 위한 질문</span>
                  <span className={ styles.essential }>신용등급을 선택해주세요</span>
                  <button
                    className={ styles.toolTip }
                    onClick={ this.onClickAlertCreditScore }
                  >
                                    신용등급을 모르신다면?
                  </button>
                </dt>
                <dd className={ styles.dropDown }>
                  <CreditLoanScoreLevelForm
                    data={ score }
                    onChange={ this.onChange }
                  />
                </dd>
                <dt>
                  <span className={ styles.metaQuestion }>금리산출을 위한 질문</span>
                  <span className={ styles.essential }>현재 직업을 선택해주세요</span>
                </dt>
                <dd className={ styles.dropDown }>
                  <CreditLoanJobForm
                    data={ job }
                    onChange={ this.onChange }
                  />
                </dd>
                <dt>
                  <span className={ styles.metaQuestion }>한도산출을 위한 질문</span>
                  <span className={ styles.essential }>연 소득은 얼마인가요?</span>
                  <button
                    className={ styles.toolTip }
                    onClick={ this.onClickAlertIncome }
                  >
                                    연 소득이란?
                  </button>
                </dt>
                <dd>
                  <CreditLoanIncomeForm
                    data={ income }
                    onChange={ this.onChange }
                  />
                </dd>
              </dl>
            </div>
          </SurveyContainer>

          {
            alertCreditScore &&
                    <CreditCoreInfoAlert
                      onCancel={ this.onCloseAlertCreditScore }
                    />
          }
          {
            alertAnnualIncome &&
                    <AnnualIncomeInfoAlert
                      onCancel={ this.onCloseAlertAnnualIncome }
                    />
          }
        </div>
      );
    }

    private onClickAlertCreditScore = () => {
      sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_QUESTIONS.CREDIT_SCORE_ALERT, GA_ACTION.POPUP, '신용등급을 모르신다면?');
      this.setState({ alertCreditScore: true });
    };

    private onClickAlertIncome = () => {
      sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_QUESTIONS.INCOME_ALERT, GA_ACTION.POPUP, '연 소득이란?');
      this.setState({ alertAnnualIncome: true });
    };

    private onChangeConfirm = () => {
      sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_QUESTIONS.LIMIT_AMOUNT, GA_ACTION.INPUT_SHORTCUT, this.state.recommendResult.computation.maximumAmountLimit.toString());
      apply(Application.useCases.cacheLoanDesiredAmount, it => {
        it.desiredAmount = this.state.recommendResult.computation.maximumAmountLimit;
      }).runOnAnimateFrame().subscribe(() => { /* explicitly empty */ }, () => { /* explicitly empty */ });
    };

    private onChange = (question: Question) => {
      this.setState(apply({} as any, state => {
        if (question instanceof CreditLoanAmount) {
          state.amount = question;
        } else if (question instanceof CreditLoanScoreLevel) {
          state.score = question;
        } else if (question instanceof CreditLoanJob) {
          state.job = question;
        } else if (question instanceof CreditLoanIncome) {
          state.income = question;
        }
      }), this.onChangedQuestion);
    };

    private onCloseAlertCreditScore = () =>
      this.setState({ alertCreditScore: false });

    private onCloseAlertAnnualIncome = () =>
      this.setState({ alertAnnualIncome: false });

    private onChangedQuestion = () => {
      const { amount, score, job, income } = this.state;
      const questions = [amount, score, job, income];
      const valid = questions.reduce((prev, it) => prev && it.isValid(), true);

      if (valid) {
        this.fetchRecommendResult();
      }
    };

    private fetchRecommendResult = () => {
      const { amount, score, job, income } = this.state;

      this.setState({
        recommendResult: null
      }, () => {
        apply(Application.useCases.recommendLoanSimply, it => {
          it.spec = new LoanRecommendSpec(
            amount.value,
            score.value,
            LoanCreditScoreAgency.KCB, // TODO: 기획 상 KCB로 통일
            job.value,
            income.value,
            emptyList(),
            emptyList()
          );
          it.defaultFilter = generateDefaultLoanRecommendFilter();
        }).runOnAnimateFrame().subscribe(
          recommendResult => this.setState({ recommendResult })
        ).unsubscribeBy(this.subscriptionBag);
      });
    };

    private fetchCachedRecommendSpec = () => {
      Application.useCases.getCachedLoanRecommendSpec
        .runOnAnimateFrame()
        .subscribe(
          spec => {
            this.setState({
              amount: new CreditLoanAmount(spec.desiredAmount),
              score: new CreditLoanScoreLevel(spec.creditScore),
              job: new CreditLoanJob(spec.occupationalGroup),
              income: new CreditLoanIncome(spec.annualIncome)
            }, () => this.fetchRecommendResult());
          },
          () => {
            /* explicitly empty */
          }
        )
        .unsubscribeBy(this.subscriptionBag);
    }
}
