import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV5PrivacyPolicyRoute } from 'presentation/routes/policies/v5/privacy-policy';
import { PoliciesV5TermsRoute } from 'presentation/routes/policies/v5/terms';

export const PoliciesV5Route = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/privacy-policy`} component={ PoliciesV5PrivacyPolicyRoute } />
        <Route
          path={ `${match.url}/terms`}
          component={ PoliciesV5TermsRoute }
        />
    </Switch>
);
