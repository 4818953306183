import { Entity } from 'core/entity';

export class SVG extends Entity {
    data: string;

    constructor(
        data: string
    ) {
        super();
        this.data = data;
    }
}
