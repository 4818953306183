import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { List } from 'immutable';
import { AdviceInvestmentAssetValuationSnapshot } from 'core/entity/personal-finance/advice/investment-asset-valuation-snapshot';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';

export class AdviceInvestmentValuationData extends Entity implements AdviceContentData {
    period: AdviceInvestmentValuationData.Period;
    valuationHistory: List<AdviceInvestmentAssetValuationSnapshot>;
    message?: AdviceMessage;

    constructor(
        period: AdviceInvestmentValuationData.Period,
        valuationHistory: List<AdviceInvestmentAssetValuationSnapshot>,
        message: AdviceMessage = null
    ) {
        super();
        this.period = period;
        this.valuationHistory = valuationHistory;
        this.message = message;
    }
}

export module AdviceInvestmentValuationData {
    export enum Period {
        WEEKLY = 'weekly',
        MONTHLY = 'monthly'
    }
}
