import { CachedStateRepositoryType } from 'core/repository/cached-state';
import { SessionCachedStateStorageProvider } from 'data/browser-storage/api/cached-state';
import { Observable } from 'rxjs/Rx';
import { SessionCachedState } from 'core/entity/cached-state';
import { CachedStateType } from 'core/entity/cached-state/type';
import { CachedStateItem } from 'core/entity/cached-state/item';
import { emptyList, lets } from 'utils/index';

export class SessionCachedStateRepository implements CachedStateRepositoryType {
    private storage: SessionCachedStateStorageProvider;

    constructor(storage: SessionCachedStateStorageProvider) {
        this.storage = storage;
    }

    getAll(): Observable<SessionCachedState> {
        return this.storage.get();
    }

    get(type: CachedStateType): Observable<CachedStateItem> {
        return this.storage.get().map(it => {
            const result = it.items.find(item => item.type === type);
            if (!result) throw new Error('There is no cached state what you want');

            return result;
        })
    }

    set(item: CachedStateItem): Observable<void> {
        return this.storage.get()
            .catch(() =>
                Observable.of(new SessionCachedState(emptyList()))
            )
            .flatMap(it =>
                this.storage.set(
                    new SessionCachedState(
                        lets(it.items.find(it => it.type === item.type), founded =>
                            founded ?
                                it.items.filter(it => it.type === item.type).toList() :
                                it.items
                        ).push(item)
                    ),
                    true
                )
            );
    }

}
