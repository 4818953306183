import { CreditTipRepositoryType } from 'core/repository/personal-credit/tip';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { CreditTip } from 'core/entity/personal-credit/tips';
import { CreditScoreTipsApiProvider } from 'data/http/api/gateway/v2/credit-scores/tips';

export class CreditTipRepository implements CreditTipRepositoryType {
    private api: CreditScoreTipsApiProvider;

    constructor(api: CreditScoreTipsApiProvider) {
        this.api = api;
    }

    getAll(): Observable<List<CreditTip>> {
        return this.api.getAll();
    }

}
