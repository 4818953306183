import { Entity } from 'core/entity';
import { ContentTagCategory } from 'core/entity/content/tag/category';

export class ContentTagSummary extends Entity {
    id: string;
    name: string;
    imageUrl: string;
    category: ContentTagCategory;

    constructor(
      id: string,
      name: string,
      imageUrl: string,
      category: ContentTagCategory
    ) {
      super();
      this.id = id;
      this.name = name;
      this.imageUrl = imageUrl;
      this.category = category;
    }
}
