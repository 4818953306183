import { Entity } from 'core/entity';
import { List } from 'immutable';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';

export class MedicalCheckupSummaryResult extends Entity {
    id: number;
    year: number;
    judgement: List<MedicalCheckupJudgement>;
    checkupType: MedicalCheckupSummaryResult.Type;

    constructor(
        id: number,
        year: number,
        judgement: List<MedicalCheckupJudgement>,
        checkupType: MedicalCheckupSummaryResult.Type
    ) {
        super();
        this.id = id;
        this.year = year;
        this.judgement = judgement;
        this.checkupType = checkupType;
    }
}

export module MedicalCheckupSummaryResult {
    export enum Type {
        GENERAL_CHECKUP = '일반검진'
    }
}
