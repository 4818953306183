import { NetworkRequestMapper } from 'data/http/mapper';
import { UserCreateSpec } from 'core/entity/user/create-spec';
import { compactObject, optional, toDateString } from 'utils/index';
import { UserSexMapper } from 'data/http/mapper/user/sex';

export class UserCreateSpecMapper implements NetworkRequestMapper<UserCreateSpec> {
    toJson(target: UserCreateSpec): any {
        return compactObject({
            name: target.name,
            email: target.email,
            password: target.password,
            birthday: optional(target.birthday, toDateString),
            sex: optional(target.sex, new UserSexMapper().toJson),
            marketing_agreement_accepted: optional(target.marketingAgreementAccepted),
            kcb_agreement_accepted: optional(target.kcbAgreementAccepted)
        })
    }
}
