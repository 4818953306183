import { GA_ACTION, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    gaCategory: string;
}

export const BestCardBanner = ({ gaCategory }: Props) => {
  const onClick = () => {
    sendGAEvent(GA_DOMAIN.CARD, gaCategory, GA_ACTION.LINK.CARDS_QUESTIONS);
  };

  return (
    <a
      href={ BANKSALAD_SITEMAP.CARDS_QUESTIONS }
      className={ styles.wrap }
      onClick={ onClick }
    >
      <h3 className={ styles.title }>나의 BEST 카드찾기</h3>
      <h4 className={ styles.subTitle }>나에게 딱 맞는 카드를 찾아보세요</h4>
      <p className={ styles.description }>
        <span>내 소비패턴을 입력하면</span><br/>
        모든 카드사의 카드 중 <strong>혜택이 가장 좋은 카드</strong>를 찾아드려요!
      </p>
    </a>
  );
};
