import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { BankAccountPrimaryData } from 'core/entity/personal-finance/primary-data/bank-account';
import { optional } from 'utils/index';
import { SpiderBankAccountDataMapper } from 'data/http/mapper/personal-finance/spider-data/bank-account';

export class BankAccountPrimaryDataMapper implements
    NetworkResponseMapper<BankAccountPrimaryData>,
    NetworkRequestMapper<BankAccountPrimaryData>
{
    fromJson(json: any): BankAccountPrimaryData {
        return new BankAccountPrimaryData(
            optional(json.spider, new SpiderBankAccountDataMapper().fromJson)
        )
    }

    toJson(target: BankAccountPrimaryData): any {
        return {
            spider: optional(target.spider, new SpiderBankAccountDataMapper().toJson)
        };
    }
}
