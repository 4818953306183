import { AxiosInstance } from 'axios';
import { PersonalizedInsuranceApiProvider } from 'data/http/api/base/me/insurance';
import { InsuranceApiProvider } from 'data/http/api/gateway/v2/insurance';

export class BaseHttpProviderDependencies {
    insurance: InsuranceApiProvider;
    personalizedInsurance: PersonalizedInsuranceApiProvider;

    constructor(base: AxiosInstance) {
      this.insurance = new InsuranceApiProvider(base);
      this.personalizedInsurance = new PersonalizedInsuranceApiProvider(base);
    }
}
