import { MetaHelmet } from 'presentation/components/meta-helmet';
import { PoliciesV19PrivacyPolicyView } from 'presentation/routes/policies/v19/privacy-policy/view';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';

export const PoliciesV19PrivacyPolicyRoute = () => (
  <>
    <MetaHelmet meta={META_SET.PRIVACY_POLICY} />
    <PoliciesV19PrivacyPolicyView />
  </>
);
