import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentAuthorProfileSummary } from 'core/entity/content/author/profile/summary/profile';

export class ContentAuthorProfileSummaryMapper implements NetworkResponseMapper<ContentAuthorProfileSummary> {
    fromJson(json: any): ContentAuthorProfileSummary {
        return new ContentAuthorProfileSummary(
            json.name,
            json.summary,
            json.image_url
        )
    }
}
