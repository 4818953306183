import { AppIntroduceTypeAView } from 'presentation/routes/app/event/introduce-a';
import { KakaobankOpenEventView } from 'presentation/routes/app/event/kakaobank-open';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { AppView } from 'presentation/routes/app/view';
import { FineDustRoute } from 'presentation/routes/app/event/fine-dust-insurance';
import { MiniCancerRoute } from 'presentation/routes/app/event/mini-cancer-insurance';
import { SwitchInsuranceRoute } from "presentation/routes/app/event/switch-insurance";

export const AppRoute = ({ match }: RouteComponentProps<any>) => (
    <div>
        <Switch>
            <Route path={ `${match.url}/mini-cancer-insurance` } component={ MiniCancerRoute } />
            <Route path={ `${match.url}/fine-dust-insurance` } component={ FineDustRoute } />
            <Route path={ `${match.url}/switch-insurance` } component={ SwitchInsuranceRoute } />
            <Route path={ `${match.url}/kakaobank-open` } component={ KakaobankOpenEventView } />
            <Route path={ `${match.url}/introduce-a` } component={ AppIntroduceTypeAView } />
            <Route path={ `${match.url}` } component={ AppView } />
        </Switch>
    </div>
);
