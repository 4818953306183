import { LoanRepaymentType } from 'core/entity/loan/repayment-type';
import { List } from 'immutable';
import { listSequence } from 'utils/index';

export class CreditLoanCalculationResult {
    input: CreditLoanCalculationResult.Input;
    totalInterest: number;
    monthlyRepayment: List<CreditLoanCalculationResult.Calculation>;

    constructor(
        amount: number,
        periodMonths: number,
        interestRate: number,
        repaymentType: LoanRepaymentType
    ) {
        this.input = new CreditLoanCalculationResult.Input(
            amount,
            periodMonths,
            interestRate,
            repaymentType
        );

        switch (repaymentType) {
            case LoanRepaymentType.AT_END:
                this.calculateForAtEnd(amount, periodMonths, interestRate);
                break;
            case LoanRepaymentType.INITIAL_VALUE:
                this.calculateForInitialValue(amount, periodMonths, interestRate);
                break;
            case LoanRepaymentType.PROGRESSIVE:
                this.calculateForProgressive(amount, periodMonths, interestRate);
                break;
            default:
                this.calculateForAtEnd(amount, periodMonths, interestRate);
                break;
        }
    }

    private calculateForAtEnd(
        amount: number,
        periodMonths: number,
        interestRate: number
    ) {
        const interest = Math.round(amount * interestRate / 100 / 12);

        this.totalInterest = interest * periodMonths;
        this.monthlyRepayment = listSequence(periodMonths)
            .map((_, index) => {
                if (index < periodMonths - 1) {
                    return new CreditLoanCalculationResult.Calculation(
                        interest,
                        amount
                    )
                } else {
                    return new CreditLoanCalculationResult.Calculation(
                        amount + interest,
                        0
                    )
                }
            })
            .toList();
    }

    private calculateForProgressive(
        amount: number,
        periodMonths: number,
        interestRate: number
    ) {
        const monthlyRate = interestRate / 100 / 12;
        const monthlyRepayment = Math.round((
            amount * monthlyRate * Math.pow(1 + monthlyRate, periodMonths)
        ) / (
            Math.pow(1 + monthlyRate, periodMonths) - 1
        ));
        let balance = amount;

        this.totalInterest = 0;
        this.monthlyRepayment = listSequence(periodMonths)
            .map((_, index) => {
                const interest = Math.round(balance * monthlyRate);
                this.totalInterest += interest;

                if (index < periodMonths - 1) {
                    balance -= monthlyRepayment - interest;
                    return new CreditLoanCalculationResult.Calculation(
                        monthlyRepayment,
                        balance
                    )
                } else {
                    return new CreditLoanCalculationResult.Calculation(
                        balance + interest,
                        0
                    )
                }
            })
            .toList()
    }

    private calculateForInitialValue(
        amount: number,
        periodMonths: number,
        interestRate: number
    ) {
        const monthlyRate = interestRate / 100 / 12;
        const monthlyPrincipal = Math.round(amount / periodMonths);
        let balance = amount;

        this.totalInterest = 0;
        this.monthlyRepayment = listSequence(periodMonths)
            .map((_, index) => {
                const interest = Math.round(balance * monthlyRate);
                this.totalInterest += interest;

                if (index < periodMonths - 1) {
                    balance -= monthlyPrincipal;
                    return new CreditLoanCalculationResult.Calculation(
                        monthlyPrincipal + interest,
                        balance
                    )
                } else {
                    return new CreditLoanCalculationResult.Calculation(
                        balance + interest,
                        0
                    )
                }
            })
            .toList()
    }

}

export module CreditLoanCalculationResult {
    export class Input {
        amount: number;
        periodMonths: number;
        interestRate: number;
        repaymentType: LoanRepaymentType;

        constructor(
            amount: number,
            periodMonths: number,
            interestRate: number,
            repaymentType: LoanRepaymentType
        ) {
            this.amount = amount;
            this.periodMonths = periodMonths;
            this.interestRate = interestRate;
            this.repaymentType = repaymentType;
        }

        toRepaymentTypeString = () => {
            switch (this.repaymentType) {
                case LoanRepaymentType.AT_END:
                    return '만기상환';
                case LoanRepaymentType.PROGRESSIVE:
                    return '원리금균등상환';
                case LoanRepaymentType.INITIAL_VALUE:
                    return '원금균등상환';
                default:
                    return ''
            }
        }

    }

    export class Calculation {
        repayAmount: number;
        balance: number;

        constructor(
            repayAmount: number,
            balance: number
        ) {
            this.repayAmount = repayAmount;
            this.balance = balance;
        }
    }
}

