import { Entity } from 'core/entity';
import { List } from 'immutable';
import { CreditGradeMetadata } from 'core/entity/personal-credit/metadata/grade';

export class CreditMetadata extends Entity {
    grades: List<CreditGradeMetadata>;

    constructor(grades: List<CreditGradeMetadata>) {
        super();
        this.grades = grades;
    }
}
