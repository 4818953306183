import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';

export class LoanRepaymentTypeMappaer implements BrowserStorageMapper<LoanRepaymentType> {
    toJson(entity: LoanRepaymentType): any {
        switch (entity) {
            case LoanRepaymentType.AT_END:
                return 'at_end';
            case LoanRepaymentType.PROGRESSIVE:
                return 'progressive';
            case LoanRepaymentType.INITIAL_VALUE:
                return 'initial_value';
            default:
                return null;
        }
    }

    toEntity(value: any): LoanRepaymentType {
        switch (value) {
            case 'at_end':
                return LoanRepaymentType.AT_END;
            case 'progressive':
                return LoanRepaymentType.PROGRESSIVE;
            case 'initial_value':
                return LoanRepaymentType.INITIAL_VALUE;
            default:
                return null;
        }
    }

}
