import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { Observer } from 'rxjs/Observer';
import { Observable } from 'rxjs/Rx';
import { lets, optional, triesHard } from 'utils/index';

export enum BrowserStorageKey {
    ACCESS_TOKEN = 'access_token',
    CARD_SERVER_RENDER = 'card_server_render',
    CARD_RECOMMEND_PARAMS = 'card_recommend_params',
    CARD_FOR_COMPARE = 'card_for_compare',
    LOAN_SERVER_RENDER = 'loan_server_render',
    LOAN_RECOMMEND_SPEC = 'loan_recommend_spec',
    LOAN_RECOMMEND_FILTER = 'loan_recommend_filter',
    LOCAL_CACHED_STATE = 'local_cached_state',
    SESSION_CACHED_STATE = 'session_cached_state',
    MEDICAL_CHECKUP_JUDGEMENT = 'medical_checkup_judgement'
}

export abstract class BrowserStorageProvider<T> {
    protected abstract key: BrowserStorageKey;
    protected abstract mapper: BrowserStorageMapper<T>;
    private temporaryStatus = false;

    get(): Observable<T> {
        return Observable.create((observer: Observer<T>) => {
            const key = this.key.toString();
            const value = triesHard(() =>
                localStorage.getItem(key)
            ).next(() =>
                sessionStorage.getItem(key)
            ).mustBe(() =>
                null
            );

            if (value) {
                observer.next(this.mapper.toEntity(JSON.parse(value)));
                observer.complete();
            } else {
                observer.error(new Error(`Invalid browser storage key '${this.key.toString()}'`));
            }
        });
    }

    set(entity: T, temporary: boolean = this.temporaryStatus): Observable<void> {
        this.temporaryStatus = temporary;
        return this.clear().map(() =>
            lets(JSON.stringify(this.mapper.toJson(entity)), it =>
                temporary ?
                    sessionStorage.setItem(this.key.toString(), it) :
                    localStorage.setItem(this.key.toString(), it)
            )
        );
    }

    clear(): Observable<void> {
        return Observable.create((observer: Observer<void>) => {
            sessionStorage.removeItem(this.key.toString());
            localStorage.removeItem(this.key.toString());
            observer.next(null as void);
            observer.complete();
        })
    }

    syncGet(): T {
        const key = this.key.toString();
        const value = triesHard(() =>
            localStorage.getItem(key)
        ).next(() =>
            sessionStorage.getItem(key)
        ).mustBe(() =>
            null
        );

        return optional(value, it => this.mapper.toEntity(JSON.parse(it)));
    }
}
