import { User } from 'core/entity/user';
import { toInitialDateRange } from 'presentation/module/sugar';
import { today } from 'utils/index';

declare module "core/entity/user" {
    export interface User {
        presentName(size?: PresentNameSize): string;
        getStartDate(): Date;
        getEndDate(): Date;
    }
}

export enum PresentNameSize {
    SHORT = 5,
    MEDIUM = 7,
    LONG = 10
}

User.prototype.presentName = function(size: PresentNameSize = PresentNameSize.LONG) {
    return this.name.length > size ?
        `${this.name.substr(0, size)}..` :
        this.name
};

User.prototype.getStartDate = function() {
    return toInitialDateRange(today(), this.preference.initialDayOfMonth).first;
};

User.prototype.getEndDate = function() {
    return toInitialDateRange(today(), this.preference.initialDayOfMonth).second;
};
