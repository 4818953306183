import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, optional, toDateString } from 'utils/index';
import { UserBankAccountCreateSpec } from 'core/entity/personal-finance/bank-account/create-spec';
import { UserBankAccountTypeMapper } from 'data/http/mapper/personal-finance/bank-account';

export class UserBankAccountCreateSpecMapper implements NetworkRequestMapper<UserBankAccountCreateSpec> {
    toJson(target: UserBankAccountCreateSpec): any {
        return compactObject({
            type: new UserBankAccountTypeMapper().toJson(target.type),
            bank_id: target.bank.id,
            nickname: target.nickname,
            latest_balance: target.latestBalance,
            hidden: target.hidden,
            description: optional(target.description),
            created_at: optional(target.createdAt, it => toDateString(it, '-')),
            expired_at: optional(target.expiredAt, it => toDateString(it, '-'))
        })
    }
}
