import { SummarizedUserCardCompanyProducts } from 'core/entity/personal-finance/summarized-products/card-company';

declare module 'core/entity/personal-finance/summarized-products/card-company' {
    export interface SummarizedUserCardCompanyProducts {
        isEmpty(): boolean;
    }
}

SummarizedUserCardCompanyProducts.prototype.isEmpty = function() {
    return this.cards.isEmpty();
};
