import { Entity } from 'core/entity';
import { CardCFAReview } from 'core/entity/legal-configuration/card/cfa/review';

export class CardCFAReviewReviewed extends Entity implements CardCFAReview {
    number: string;

    constructor(number: string) {
        super();
        this.number = number;
    }
}
