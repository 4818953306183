import {
    NetworkRequestMapper,
    NetworkResponseMapper
} from 'data/http/mapper';
import { CardSpending } from 'core/entity/card/spending';

export class CardSpendingMapper implements
    NetworkResponseMapper<CardSpending>,
    NetworkRequestMapper<CardSpending>
{
    fromJson(json: any): CardSpending {
        return new CardSpending(
            json.store_id,
            json.store_name,
            json.expense
        )
    }

    toJson(target: CardSpending): any {
        return {
            store_id: target.storeId,
            store_name: target.storeName,
            expense: target.expense
        }
    }
}
