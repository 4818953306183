import { UserSecuritiesFirmAccountRepositoryType } from 'core/repository/personal-finance/securities-firm-account';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { UserSecuritiesFirmAccountUpdateSpec } from 'core/entity/personal-finance/securities-firm-account/update-spec';
import { UserSecuritiesFirmAccountsApiProvider } from 'data/http/api/gateway/v2/users/securities-firms/accounts';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserSecuritiesFirmAccountRepository implements UserSecuritiesFirmAccountRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserSecuritiesFirmAccountsApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserSecuritiesFirmAccountsApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    getAll(): Observable<List<UserSecuritiesFirmAccount>> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.getAll(token)
        )
    }

    get(id: string): Observable<UserSecuritiesFirmAccount> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.get(token, id)
        )
    }

    update(id: string, spec: UserSecuritiesFirmAccountUpdateSpec): Observable<UserSecuritiesFirmAccount> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.update(token, id, spec)
        )
    }

}
