import { FinanceSubSectorItem } from 'core/entity/finance-sector/item';
import { ContentSummary } from 'core/entity/content/summary';
import { Entity } from 'core/entity';
import { FinanceSectorItemBadge } from 'core/entity/finance-sector/item/badge';

export class FinanceSubSectorContentItem extends Entity implements FinanceSubSectorItem {
    name: string;
    badge?: FinanceSectorItemBadge;
    content: ContentSummary;

    constructor(
        name: string,
        content: ContentSummary,
        badge?: FinanceSectorItemBadge
    ) {
        super();
        this.name = name;
        this.content = content;
        this.badge = badge;
    }
}
