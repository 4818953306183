import React from 'react';

import { EventBus } from 'presentation/bus';
import { FixBodyEvent } from 'presentation/bus/event/fix-body-event';
import { FacebookShareButton } from 'presentation/components/share-button/facebook';
import { TwitterShareButton } from 'presentation/components/share-button/twitter';
import { KakaoTalkShareButton } from 'presentation/components/share-button/kakao-talk';
import { KakaoStoryShareButton } from 'presentation/components/share-button/kakao-story';
import { isMobile } from 'presentation/module/sugar';
import { NaverShareButton } from 'presentation/components/share-button/naver';
import { BandShareButton } from 'presentation/components/share-button/band';
import { ToastEvent } from 'presentation/bus/event/toast-event';
import { removeQueriesAndHashes } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    title: string;
    imageUrl: string;
}

export class OthersShareButton extends React.Component<Props> {
    private toggle: HTMLInputElement;
    private link: HTMLInputElement;

    render() {
        const { title, imageUrl } = this.props;
        return (
            <div className={ styles.wrap }>
                <input
                    ref={ node => this.toggle = node }
                    type="checkbox"
                    id="share"
                    className={ styles.toggle }
                    onChange={ e => EventBus.post(new FixBodyEvent(e.currentTarget.checked)) }
                />
                <label
                    htmlFor="share"
                    className={ styles.button }
                >
                    공유하기
                </label>
                <button
                    className={ styles.closeArea }
                    onClick={ () => {
                        this.toggle.checked = false;
                        EventBus.post(new FixBodyEvent(false))
                    } }
                >
                    공유팝업 닫기
                </button>
                <div className={ styles.share }>
                    <button
                        className={ styles.close }
                        onClick={ () => {
                            this.toggle.checked = false;
                            EventBus.post(new FixBodyEvent(false))
                        } }
                    >
                        공유팝업 닫기
                    </button>
                    <h4 className={ styles.shareTitle }>
                        더 많은 분들이 볼 수 있도록<br/>
                        SNS에 공유해주세요
                    </h4>
                    <ul className={ styles.shareButtons }>
                        <li>
                            <FacebookShareButton size={ 42 } />
                            <span className={ styles.shareLabel }>
                                페이스북
                            </span>
                        </li>
                        <li>
                            <TwitterShareButton title={ title } size={ 42 } />
                            <span className={ styles.shareLabel }>
                                트위터
                            </span>
                        </li>
                        {
                            isMobile() && (
                                <li>
                                    <KakaoTalkShareButton
                                        title={ title }
                                        imageUrl={ imageUrl }
                                        size={ 42 }
                                    />
                                    <span className={ styles.shareLabel }>
                                        카카오톡
                                    </span>
                                </li>
                            )
                        }
                        <li>
                            <KakaoStoryShareButton title={ title } size={ 42 }/>
                            <span className={ styles.shareLabel }>
                                카카오스토리
                            </span>
                        </li>
                        <li>
                            <NaverShareButton title={ title } size={ 42 }/>
                            <span className={ styles.shareLabel }>
                                네이버
                            </span>
                        </li>
                        <li>
                            <BandShareButton title={ title } size={ 42 }/>
                            <span className={ styles.shareLabel }>
                                밴드
                            </span>
                        </li>
                    </ul>
                    <div className={ styles.copy }>
                        <input ref={ node => this.link = node } type="text" value={ removeQueriesAndHashes(location.href) } readOnly />
                        <button onClick={ this.copy }>링크복사</button>
                    </div>
                </div>
            </div>
        )
    }

    private copy = () => {
        try {
            this.link.focus();
            this.link.setSelectionRange(0, this.link.value.length);

            const copySuccess = document.execCommand('copy');
            EventBus.post(new ToastEvent(
                copySuccess ? '주소가 복사 되었습니다' : '복사에 실패 하였습니다, 다시 한번 시도해 주세요'
            ))
        } catch (e) {
            EventBus.post(new ToastEvent(
                '복사에 실패 하였습니다, 다시 한번 시도해 주세요'
            ))
        }
    }
}
