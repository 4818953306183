import moment from 'moment';
import { List } from 'immutable';

import { CardBenefitType } from 'core/entity/card/benefit-type';
import { lets, Pair, pairOf, TimeUnit } from 'utils/index';
import { ToastEvent } from 'presentation/bus/event/toast-event';
import { EventBus } from 'presentation/bus';
import { GlobalAlertEvent } from 'presentation/bus/event/gloabl-alert-event';

export const isMobile = () => !!(
    navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
);

export const isIOS = () => !!(
    navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
);

export const isForDiscount = (types: List<CardBenefitType>) => (
    types.contains(CardBenefitType.ALL) ||
    types.contains(CardBenefitType.DISCOUNT) ||
    types.contains(CardBenefitType.POINT)
);

export const isEndToday = (startDate: Date, endDate: Date) => (
    startDate &&
    endDate &&
    endDate.getTime() - startDate.getTime() < TimeUnit.DAY
);

export const isExpiredTime = (endDate: Date) => (
    endDate &&
    lets(new Date(), it =>
        it.getTime() > endDate.getTime()
    )
);

export const isExpiredDate = (endDate: Date) => {
    return endDate &&
        lets(new Date(), now => {
            endDate.setDate(endDate.getDate() + 1);
            endDate.setHours(0, 0, 0, 0);

            return now.getTime() > endDate.getTime();
        })
};

export const toCurrency = (value: number, radix: number = null) => (
    (radix !== null ? value.toFixed(radix) : value.toString())
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
);

export const parseQueryString = (query: string): any => {
    if (query.substr(0, 1) === '?') query = query.substring(1, query.length);
    return Object.assign(
        {},
        ...query.split('&').map(it => {
            const set = it.split('=');
            const emptyObject = {} as any;
            emptyObject[set[0]] = set[1];
            return emptyObject;
        })
    );
};

export const regex = {
    email: new RegExp(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/gi),
    number: new RegExp(/.*[0-9]+.*/),
    upperCase: new RegExp(/.*[A-Z]+.*/),
    lowerCase: new RegExp(/.*[a-z]+.*/),
    specials: new RegExp(/.*[^가-힣a-zA-Z0-9].*/)
};

export const isValidPassword = (password: string) =>
    List.of(
        regex.number.test(password),
        regex.upperCase.test(password),
        regex.lowerCase.test(password),
        regex.specials.test(password)
    ).filter(it => it).size >= 3;

export const toast = (message: string) =>
    EventBus.post(new ToastEvent(message));
export const globalAlert = (
    title: string,
    message: string,
    onCancel?: () => void
) => (
    EventBus.post(new GlobalAlertEvent(title, message, onCancel))
);

export const toInitialDateRange = (standard: Date, initialDay: number): Pair<Date, Date> => {
    const startDate = standard.getDate() >= initialDay ?
        lets(moment(standard), it =>
            it.set('date', Math.min(it.daysInMonth(), initialDay))
        ) :
        lets(moment(standard).subtract(1, 'month'), lastMonth =>
            lastMonth.set('date', Math.min(lastMonth.daysInMonth(), initialDay))
        );
    const endDate = lets(moment(startDate).add(1, 'month'), nextMonth =>
        nextMonth.set('date', Math.min(nextMonth.daysInMonth(), initialDay) - 1)
    );

    return pairOf(startDate.toDate(), endDate.toDate());
};

export const scrollTop = () => (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
);
