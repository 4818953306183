import { Card } from 'core/entity/card';
import { CardInfoDefaultBenefits } from 'presentation/components/info/card/detail/benefits/default';
import { CardInfoPremiumVouchers } from 'presentation/components/info/card/detail/benefits/premium-vouchers';
import { CardInfoSelectiveBenefits } from 'presentation/components/info/card/detail/benefits/selective';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { isMobile } from 'presentation/module/sugar';
import React from 'react';
import { uniqueKey } from 'utils/index';

import styles from './styles.pcss';

enum Tab {
    DEFAULT_BENEFITS,
    SELECTIVE_BENEFITS,
    PREMIUM_VOUCHERS
}

interface Props {
    card: Card;
}

interface State {
    tab: Tab;
}

export class CardInfoBenefits extends React.Component<Props, State> {
    state = {
      tab: Tab.DEFAULT_BENEFITS
    };

    render() {
      const { complicated } = this.props.card;

      return complicated ? (
        this.renderComplicate()
      ) : (
        <>
          { this.renderTab() }
          { this.renderCurrentTab() }
        </>
      );
    }

    private renderComplicate = () => {
      const {
        issueUrlMobile,
        issueUrlDesktop
      } = this.props.card;

      return (
        <div className={ styles.complicate }>
          <h4>혜택 정보 준비 중</h4>
          <p>
                    정확한 정보를 제공해드리 위해 해당 카드의 혜택 정보를 구조화하는 중입니다<br/>
                    아래 카드사 사이트 링크를 통해 정보를 확인하세요
          </p>
          <a
            href={ isMobile() ? issueUrlMobile : issueUrlDesktop }
            target="_blank"
            rel="nofollow"
            onClick={ this.onClickOutboundLink }
          >
                    카드사 사이트로 이동
          </a>
        </div>
      );
    };

    private onClickOutboundLink = () => {
      sendGAEvent(GA_DOMAIN.CARD, GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_COMPLICATE, GA_ACTION.ISSUE, '카드사사이트로이동');
    };

    private renderCurrentTab = () => {
      const { card } = this.props;

      switch (this.state.tab) {
      case Tab.DEFAULT_BENEFITS:
        return (
          <CardInfoDefaultBenefits
            card={ card }
            containRestrictions={ this.hasRestrictions() }
          />
        );
      case Tab.SELECTIVE_BENEFITS:
        return (
          <CardInfoSelectiveBenefits
            card={ card }
            containRestrictions={ this.hasRestrictions() }
          />
        );
      case Tab.PREMIUM_VOUCHERS:
        return (
          <CardInfoPremiumVouchers
            card={ card }
          />
        );
      }
    };

    private renderTab = () => {
      const {
        selectiveBenefits,
        premiumVouchers,
      } = this.props.card;

      const tabs = [Tab.DEFAULT_BENEFITS]
        .concat(
          selectiveBenefits && !selectiveBenefits.isEmpty() ?
            [Tab.SELECTIVE_BENEFITS] : [],
          premiumVouchers && !premiumVouchers.isEmpty() ?
            [Tab.PREMIUM_VOUCHERS] : []
        );

      return tabs.length > 1 && (
        <ul className={ styles.tab }>
          {
            tabs.map(tab =>
              <li
                key={ uniqueKey() }
                style={{ width: `${100 / tabs.length}%` }}
              >
                <button
                  onClick={ () => {
                    this.setState({ tab });
                    sendGAEvent(
                      GA_DOMAIN.CARD,
                      GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS,
                      GA_ACTION.TAB,
                      this.tabToName(tab)
                    );
                  } }
                  className={ this.state.tab === tab ? styles.active : null }
                >
                  { this.tabToName(tab) }
                </button>
              </li>
            )
          }
        </ul>
      );
    };

    private tabToName = (tab: Tab) => {
      switch (tab) {
      case Tab.DEFAULT_BENEFITS:
        return '기본제공 혜택';
      case Tab.SELECTIVE_BENEFITS:
        return '선택형 혜택';
      case Tab.PREMIUM_VOUCHERS:
        return '프리미엄 바우처';
      }
    };

    private hasRestrictions = () => 
      !this.props.card.baseRests.isEmpty() || !this.props.card.condRests.isEmpty();
}
