import { UserCardCreditLimit } from 'core/entity/personal-finance/card-credit-limit';
import { SummarizedUserCardBills } from 'core/entity/personal-finance/summarized-card-bills';
import { UserCardRepositoryType } from 'core/repository/personal-finance/card';
import { UserCardCreditLimitsApiProvider } from 'data/http/api/gateway/v2/users/cards/companies/credit-limits';
import { SummarizedUserCardBillsApiProvider } from 'data/http/api/gateway/v2/users/cards/summarized-bills';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { UserCardUpdateSpec } from 'core/entity/personal-finance/card/update-spec';
import { UserCard } from 'core/entity/personal-finance/card';
import { UserCardsApiProvider } from 'data/http/api/gateway/v2/users/cards';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserCardRepository implements UserCardRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private cardApi: UserCardsApiProvider;
    private limitApi: UserCardCreditLimitsApiProvider;
    private summarizedBillApi: SummarizedUserCardBillsApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        cardApi: UserCardsApiProvider,
        limitApi: UserCardCreditLimitsApiProvider,
        summarizedBillApi: SummarizedUserCardBillsApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.cardApi = cardApi;
        this.limitApi = limitApi;
        this.summarizedBillApi = summarizedBillApi;
    }

    getUserCardCreditLimits(): Observable<List<UserCardCreditLimit>> {
        return this.tokenStorage.get().flatMap(token =>
            this.limitApi.get(token)
        );
    }

    getSummarizedUserCardBills(startDate: Date, endDate: Date): Observable<SummarizedUserCardBills> {
        return this.tokenStorage.get().flatMap(token =>
            this.summarizedBillApi.get(token, startDate, endDate)
        )
    }

    update(id: string, spec: UserCardUpdateSpec): Observable<UserCard> {
        return this.tokenStorage.get().flatMap(token =>
            this.cardApi.update(token, id, spec)
        )
    }
}
