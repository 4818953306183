import { Entity } from 'core/entity';
import { LoanPeriod } from 'core/entity/loan/period';
import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanInterestValue } from 'core/entity/loan/interest-value';

export class LoanBaseInterest extends Entity {
    interest: LoanInterestValue;
    period: LoanPeriod;
    type: LoanInterestType;

    constructor(
        interest: LoanInterestValue,
        period: LoanPeriod,
        type: LoanInterestType
    ) {
        super();
        this.interest = interest;
        this.period = period;
        this.type = type;
    }
}
