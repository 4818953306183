import { Config } from 'application/config';
import { BANKSALAD_DEEP_LINK } from 'presentation/module/deep-link';
import {
  trackEventForClickServiceCenterListItem,
  trackEventForExposeServiceCenterListItem,
} from 'presentation/routes-app/service-center/components/list-item/event';
import React from 'react';
import { isNamespaceProduction } from 'utils/env';

type Props = {
  id: number;
  title: string;
  target: string;
  iconName: string;
  url: string;
};

export const ARTICLE_ICON_NAME = 'Question';
export const BASE_URL = isNamespaceProduction()
  ? 'https://www.banksalad.com'
  : 'https://www.staging.banksalad.com';

export const CDN_BASE_URL = isNamespaceProduction()
  ? 'https://cdn.banksalad.com'
  : 'https://cdn.staging.banksalad.com';

export const POLICIES_BASE_URL = isNamespaceProduction()
  ? 'https://policies.banksalad.com'
  : 'https://policies.staging.banksalad.com';


import styles from './styles.pcss';

export const ServiceCenterListItem = ({ id, title, target, iconName, url }: Props) => {
  const isArticle = (iconName: string) => iconName === ARTICLE_ICON_NAME;
  const onClickLink = () => trackEventForClickServiceCenterListItem(id, title, isArticle(iconName));
  trackEventForExposeServiceCenterListItem(id, title, isArticle(iconName));
  // 카테고리가 하드코딩된 데이터인지 젠데스크 API call로 받은 데이터인지 구별하기 위한 값
  const isCategoryFromZendesk = url.includes(Config.hosts.zendesk);

  return (
    <li className={styles.wrapper}>
      <a
        href={BANKSALAD_DEEP_LINK.WEB_VIEW(
          title,
          isCategoryFromZendesk ? `${BASE_URL}${target}` : url
        )}
        className={styles[`titleWrapper${iconName}`] || styles['titleWrapperDefault']}
        onClick={onClickLink}
      >
        <span className={styles.title}>{title}</span>
      </a>
    </li>
  );
};
