import { TransactionFilter } from 'core/entity/personal-finance/transaction/filter';
import { NetworkRequestMapper } from 'data/http/mapper';
import { optional, toDateString } from 'utils/index';

export class TransactionFilterMapper implements NetworkRequestMapper<TransactionFilter> {
    toJson(target: TransactionFilter): any {
        return {
            start_date: optional(target.startDate, it => toDateString(it, '-')),
            end_date: optional(target.endDate, it => toDateString(it, '-')),
            product_ids: optional(target.productIds, it => it.join(',')),
            deleted: optional(target.deleted),
            offset: optional(target.offset),
            limit: optional(target.limit),
            categories: optional(target.categories, it => it.join(',')),
            include_overwritten: optional(target.includeOverwritten),
            title: optional(target.title)
        }
    }

}
