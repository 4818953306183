import { Loan } from 'core/entity/loan';
import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanOrganizationMapper } from 'data/http/mapper/loan/organization';
import { LoanDescriptionsMapper } from 'data/http/mapper/loan/descriptions';
import { LoanInterestConditionMapper } from 'data/http/mapper/loan/interest-condition';
import { optional, parseDate } from 'utils/index';
import { LoanInterestPromotionMapper } from 'data/http/mapper/loan/interest-promotion';
import { LoanOptionMapper } from 'data/http/mapper/loan/option';
import { List } from 'immutable';

export class LoanMapper implements NetworkResponseMapper<Loan> {
    fromJson(json: any): Loan {
        return new Loan(
            json.id,
            json.slug,
            json.name,
            new LoanOrganizationMapper().fromJson(json.organization),
            json.desktop_issue_url,
            json.mobile_issue_url,
            parseDate(json.updated_at),
            json.day_loan,
            json.general_credit,
            json.middle_class,
            json.exempt_reimbursement_fees,
            json.minus_account,
            new LoanDescriptionsMapper().fromJson(json.descriptions),
            List(json.options.map((option: any) => new LoanOptionMapper().fromJson(option))),
            new LoanInterestConditionMapper().fromJson(json.interest_condition),
            optional(json.interest_promotion, new LoanInterestPromotionMapper().fromJson)
        )
    }

}
