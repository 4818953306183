import { Entity } from 'core/entity';
import { PrimaryData } from 'core/entity/personal-finance/primary-data';
import { SpiderSecuritiesFirmAccountData } from 'core/entity/personal-finance/spider-data/securities-firm-account';

export class SecuritiesFirmAccountPrimaryData extends Entity implements PrimaryData {
    spider?: SpiderSecuritiesFirmAccountData;

    constructor(
        spider: SpiderSecuritiesFirmAccountData = null
    ) {
        super();
        this.spider = spider;
    }
}
