import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { RefreshToken } from 'core/entity/user/refresh-token';
import { RefreshTokenMapper } from 'data/http/mapper/user/refresh-token';

export class UserSessionApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<void> {
        return this.requestGET(
            `api/v2/users/session/${accessToken.accessToken}`
        )
    }

    put(accessToken: AccessToken): Observable<RefreshToken> {
        return this.requestPUT(
            `api/v2/users/session/${accessToken.accessToken}`,
            { refresh_token: accessToken.refreshToken }
        ).map(result =>
            new RefreshTokenMapper().fromJson(result.data)
        )
    }
}
