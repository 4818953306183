import { CardCompanyLegalConfiguration } from 'core/entity/legal-configuration/card-company';
import { HttpProvider } from 'data/http';
import { CardCompanyLegalConfigurationMapper } from 'data/http/mapper/legal-configuration/card-company';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { apply, emptyList } from 'utils/index';

export class CardCompanyLegalConfigurationApiProvider extends HttpProvider {
    getAll(): Observable<List<CardCompanyLegalConfiguration>> {
        return this.requestGET(
            `/api/v3/card-companies/legal-configuration`
        ).map((result: any) =>
            List(result.data.map((it: any) =>
                new CardCompanyLegalConfigurationMapper().fromJson(it)
            ))
        )
    }

    getByCardCompanyId(id: number): Observable<CardCompanyLegalConfiguration> {
        return this.requestGET(
            `/api/v3/card-companies/${id}/legal-configuration`
        ).map((result: any) =>
            new CardCompanyLegalConfigurationMapper().fromJson(result.data)
        )
    }

}

export class IgnoredCardCompanyLegalConfigurationApiProvider extends CardCompanyLegalConfigurationApiProvider {
    getAll(): Observable<List<CardCompanyLegalConfiguration>> {
        return Observable.of(emptyList())
    }

    getByCardCompanyId(id: number): Observable<CardCompanyLegalConfiguration> {
        return this.requestGET(
            `/api/v3/card-companies/${id}/legal-configuration`
        ).map((result: any) =>
            apply(new CardCompanyLegalConfigurationMapper().fromJson(result.data), it => {
                it.disabledForCompliance = false;
            })
        );
    }

}
