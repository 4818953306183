import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { List } from 'immutable';
import { UserFund } from 'core/entity/personal-finance/fund';
import { UserFundUpdateSpec } from 'core/entity/personal-finance/fund/update-spec';
import { UserFundMapper } from 'data/http/mapper/personal-finance/fund';
import { UserFundUpdateSpecMapper } from 'data/http/mapper/personal-finance/fund/update-spec';
import { UserFundCreateSpec } from 'core/entity/personal-finance/fund/create-spec';
import { UserFundCreateSpecMapper } from 'data/http/mapper/personal-finance/fund/create-spec';

export class UserFundsApiProvider extends HttpProvider {
    getAll(accessToken: AccessToken): Observable<List<UserFund>> {
        return this.requestGET(
            'api/v2/me/funds',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map(new UserFundMapper().fromJson))
        )
    }

    get(accessToken: AccessToken, id: string): Observable<UserFund> {
        return this.requestGET(
            `api/v2/me/funds/${id}`,
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserFundMapper().fromJson(result.data)
        )
    }

    create(accessToken: AccessToken, spec: UserFundCreateSpec): Observable<UserFund> {
        return this.requestPOST(
            'api/v2/me/funds',
            new UserFundCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserFundMapper().fromJson(result.data)
        )
    }

    update(accessToken: AccessToken, id: string, spec: UserFundUpdateSpec): Observable<UserFund> {
        return this.requestPATCH(
            `api/v2/me/funds/${id}`,
            new UserFundUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserFundMapper().fromJson(result.data)
        )
    }
}
