import { compactObject, optional, parseDate } from 'utils/index';
import { UserFinancialDataAssetSummary } from 'core/entity/personal-finance/financial-data/summary';
import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { UserBankAccountMapper } from 'data/http/mapper/personal-finance/bank-account';
import { UserFundMapper } from 'data/http/mapper/personal-finance/fund';
import { UserInsuranceMapper } from 'data/http/mapper/personal-finance/insurance';

export class UserFinancialDataAssetSummaryMapper implements
    NetworkResponseMapper<UserFinancialDataAssetSummary>,
    NetworkRequestMapper<UserFinancialDataAssetSummary>
{
    toJson(target: UserFinancialDataAssetSummary): any {
        return compactObject({
            base_datetime: target.baseDateTime.format('YYYY-MM-DD'),
            bank_accounts: target.bankAccounts.map(new UserBankAccountMapper().toJson).toArray(),
            funds: target.funds.map(new UserFundMapper().toJson).toArray(),
            insurances: optional(target.insurances, it =>
                it.map(new UserInsuranceMapper().toJson).toArray()
            )
        });
    }

    fromJson(json: any): UserFinancialDataAssetSummary {
        return new UserFinancialDataAssetSummary(
            parseDate(json.base_datetime),
            json.bank_accounts.map((bankAccount: any) => new UserBankAccountMapper().fromJson(bankAccount)).toList(),
            json.funds.map((fund: any) => new UserFundMapper().fromJson(fund)).toList(),
            optional(json.insurances, it =>
                it.map((insurance: any) => new UserInsuranceMapper().fromJson(insurance)).toList()
            )
        );
    }
}
