import { UserCardBill } from 'core/entity/personal-finance/card-bill';
import { UserCardBillContent } from 'core/entity/personal-finance/card-bill-content';
import { HttpProvider } from 'data/http';
import { UserCardBillMapper } from 'data/http/mapper/personal-finance/card-bill';
import { UserCardBillContentMapper } from 'data/http/mapper/personal-finance/card-bill-content';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';

export class UserCardBillsApiProvider extends HttpProvider {
    getAll(accessToken: AccessToken, companyId: string): Observable<List<UserCardBillContent>> {
        return this.requestGET(
            'api/v2/me/cards/bills',
            { company: companyId },
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map((it: any) =>
                new UserCardBillContentMapper().fromJson(it)
            ))
        )
    }

    get(accessToken: AccessToken, id: string): Observable<UserCardBill> {
        return this.requestGET(
            `api/v2/me/cards/bills/${id}`,
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCardBillMapper().fromJson(result.data)
        )
    }

}
