import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanInterestConfigurationSummary } from 'core/entity/loan/interest-configuration-summary';
import { optional } from 'utils/index';
import { LoanInterestPromotionMapper } from 'data/http/mapper/loan/interest-promotion';
import { LoanInterestValueMapper } from 'data/http/mapper/loan/interest-value';
import { LoanInterestTypeMapper } from 'data/http/mapper/loan/interest-type';

export class LoanInterestConfigurationSummaryMapper implements NetworkResponseMapper<LoanInterestConfigurationSummary> {
    fromJson(json: any): LoanInterestConfigurationSummary {
        return new LoanInterestConfigurationSummary(
            optional(json.interest_promotion, promotion => new LoanInterestPromotionMapper().fromJson(promotion)),
            new LoanInterestValueMapper().fromJson(json.total_interest),
            new LoanInterestTypeMapper().fromJson(json.type)
        );
    }
}
