import { HealthInsuranceSubmitRepositoryType } from 'core/repository/personal-finance/health-insurance/submit';
import { Observable } from 'rxjs';
import { HealthInsuranceSubmitResponse } from 'core/entity/personal-finance/health-insurance/submit/response';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { HealthInsuranceSubmitApiProvider } from 'data/http/api/gateway/v2/me/health-insurance/submit';

export class HealthInsuranceSubmitRepository implements HealthInsuranceSubmitRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: HealthInsuranceSubmitApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: HealthInsuranceSubmitApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    submit(loanId: string, userCreditGrade?: number): Observable<HealthInsuranceSubmitResponse> {
        return this.tokenStorage.get().flatMap(it => this.api.submit(it, loanId, userCreditGrade));
    }
}
