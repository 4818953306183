export enum FetchState {
    FETCHED = 'fetched',
    FETCHING = 'fetching',
    ERROR = 'error'
}

export enum FetchPageState {
    EMPTY = 'empty',
    FETCHED = 'fetched',
    FETCHING = 'fetching',
    ERROR = 'error',
    FINISHED = 'finished'
}

export enum CreditFetchState {
    EMPTY = 'empty',
    FETCHED = 'fetched',
    FETCHING = 'fetching',
    ERROR = 'error'
}

export enum CheckupFetchState {
    EMPTY = 'empty',
    FETCHED = 'fetched',
    FETCHING = 'fetching',
    ERROR = 'error',
    NOT_FOUND = 'not_found',
}
