import { Entity } from 'core/entity';

export class UserUpdateSpec extends Entity {
    name?: string;
    account?: UserUpdateSpec.Account;
    preference?: UserUpdateSpec.Preference;

    constructor(
        name: string = null,
        account: UserUpdateSpec.Account = null,
        preference: UserUpdateSpec.Preference = null
    ) {
        super();
        this.name = name;
        this.account = account;
        this.preference = preference;
    }
}

export module UserUpdateSpec {
    export class Account extends Entity {
        password: string;
        modifiedPassword: string;

        constructor(
            password: string,
            modifiedPassword: string
        ) {
            super();
            this.password = password;
            this.modifiedPassword = modifiedPassword;
        }
    }

    export class Preference extends Entity {
        initialDayOfMonth?: number;
        kcbAgreementAccepted?: boolean;
        injectingBankTransactionCategory?: boolean;

        constructor(
            initialDayOfMonth: number = null,
            kcbAgreementAccepted: boolean = null,
            injectingBankTransactionCategory: boolean = null
        ) {
            super();
            this.initialDayOfMonth = initialDayOfMonth;
            this.kcbAgreementAccepted = kcbAgreementAccepted;
            this.injectingBankTransactionCategory = injectingBankTransactionCategory;
        }
    }
}
