import { Context } from 'application/@context';
import { Config } from 'application/config';
import Axios from 'axios';

export const Application = new Context(
    Axios.create({
        baseURL: Config.hosts.gateway,
        ...Config.axios
    }),
    Axios.create({
        baseURL: Config.hosts.base,
        ...Config.axios
    }),
    Axios.create({
        baseURL: Config.hosts.cdn,
        ...Config.axios
    }),
    // zendesk의 경우 request header에 custom header가 있을 경우에는 CORS 에러 생김
    Axios.create({
        baseURL: Config.hosts.zendesk,
        timeout: Config.axios.timeout,
    }),
    Config.oauth.facebookAppId,
    Config.options.ignoredLegalService || false
);
