import { Card as FsCard  } from 'data/http/api/card/getCardInfo';
import { getTime } from 'date-fns';
import { List, Map } from 'immutable';
import { clone as lodashClone } from 'lodash';
import moment from 'moment';


export const emptyList = () => List([]);
export const listSequence = (size: number) => List().setSize(size);
export const today = () => new Date();
export const tomorrow = () => moment().add(1, 'day').toDate();
export const isEvenNumber = (number: number) => ((number % 2) === 0);
export const isEvenTenth = (number: number) => ((number % 20) <= 9);

export class Pair<T, P> {
    public first: T;
    public second: P;

    constructor(first: T, second: P) {
      this.first = first;
      this.second = second;
    }
}

export class Triple<T, P, O> {
    public first: T;
    public second: P;
    public third: O;

    constructor(first: T, second: P, third: O) {
      this.first = first;
      this.second = second;
      this.third = third;
    }
}

export const pairOf = <A, B>(first: A, second: B) =>
  new Pair(first, second);
export const tripleOf = <A, B, C>(first: A, second: B, third: C) =>
  new Triple(first, second, third);

export namespace TimeUnit {
    export const DAY = 1000 * 60 * 60 * 24;
    export const HOUR = 1000 * 60 * 60;
    export const MINIUTE = 1000 * 60;
    export const SECOND = 1000;
}

export const uniqueKey = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);

    return v.toString(16);
  });

export function apply<T>(target: T, applier: (a: T) => void): T {
  applier(target);

  return target;
}

export function clone<T>(instance: T, customizer?: (it: T) => T): T {
  const copy = new (instance.constructor as { new (): T })();
  Object.assign(copy, instance);

  return customizer ? customizer(copy) : copy;
}

export function lets<T, U>(target: T, player: (it: T) => U): U {
  const value: T = lodashClone(target);

  return player(value);
}

const beSelf = <T extends {}>(it: T): T => it;
export function optional<T, U>(target: T, player: (it: T) => U = beSelf): U | null {
  if (target === null || target === undefined) {
    return null;
  }
  const value: T = lodashClone(target);

  return player(value);
}

export function tries<E>(start: () => E): TryChain<E> {
  return new TryChain(start);
}

export function triesHard<E>(start: () => E): HardTryChain<E> {
  return new HardTryChain(start);
}

export function getUrlParam(key: string): string {
  const url = window.location.href;
  const regex = new RegExp(`${name}=(.*)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[1]) {
    return '';
  }

  return decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class TryChain<E> {
    private start: () => E;

    constructor(start: () => E) {
      this.start = start;
    }

    public next(player: () => E): TryChain<E> {
      try {
        return new TryChain(() => this.start());
      } catch (e) {
        return new TryChain(player);
      }
    }

    public unfortunately(last: () => E): E {
      try {
        return this.start();
      } catch (e) {
        return last();
      }
    }
}

class HardTryChain<E> {
    private start: () => E;

    constructor(start: () => E) {
      this.start = start;
    }

    public next(player: () => E): HardTryChain<E> {
      try {
        const result = this.start();
        if (result !== null && result !== undefined) {
          return new HardTryChain(() => this.start());
        } else {
          return new HardTryChain(player);
        }
      } catch (e) {
        return new HardTryChain(player);
      }
    }

    public mustBe(last: () => E): E {
      try {
        return optional(this.start()) || last();
      } catch (e) {
        return last();
      }
    }
}

export const removeQueriesAndHashes = (value: string): string =>
  value.replace(/(\?.*)|(#.*)/g, '');

export const getPadNumber = (pad: number, value: {}) => {
  const point = Array(pad).fill('0');
  const PAD = point.map(_ => '0').join('');

  return (PAD + value).slice(-pad);
};

export const toDateString = (date: Date, separator = '.'): string => {
  const padMonth = getPadNumber(2, date.getMonth() + 1);
  const padDate = getPadNumber(2, date.getDate());

  return `${date.getFullYear()}${separator}${padMonth}${separator}${padDate}`;
};

export const toTimeString = (timestamp: number, separator = ':'): string => {
  const MS_PER_HOURS = 1000 * 60 * 60;
  const MS_PER_MINUTES = 1000 * 60;
  const MS_PER_SECONDS = 1000;
  const PAD = '00';

  if (timestamp < 0) {
    return '00:00:00';
  }

  const padHours = Math.floor(timestamp / MS_PER_HOURS);
  const padMinutes = Math.floor((timestamp - (padHours * MS_PER_HOURS)) / MS_PER_MINUTES);
  const padSeconds = Math.floor((timestamp - (padHours * MS_PER_HOURS) - (padMinutes * MS_PER_MINUTES)) / MS_PER_SECONDS);

  return `${(PAD + padHours).slice(-2)}${separator}${(PAD + padMinutes).slice(-2)}${separator}${(PAD + padSeconds).slice(-2)}`;
};

export const parseNumberToInt = (value: number): number => parseInt(value.toString());

// TODO: 처리규정을 확실히 정해서 없앨 것
export const APP_VALUE = {
  NORMAL_STORE_ID: 10097,
  NORMAL_STORE_NAME: '일반가맹점',
};

export const compactObject = (data: {}): {} =>
  Map(data)
    .filter((item: {}) => item !== undefined && item !== null)
    .toObject();

const removeZonePattern = 'YYYY-MM-DD HH:mm:ss.SS';
export const parseDate = (date: string, utc = true): Date =>
  utc ?
    moment.utc(date).local().toDate() :
    moment(date, removeZonePattern).utc(false).toDate();

export const stringifyDate = (date: Date): string => moment.utc(date).local().toISOString();
export const removeCharactersExceptNumbers = (value: string): string => value.replace(/\D+/g, '');
export const replaceStringPNGtoSVG = (data: string): string => data.replace('png', 'svg');

export const getPromotionBannerText = (banksaladCard: FsCard) => {
  return `${banksaladCard.name} 이용 시 최대 ${
    Number(banksaladCard.cashback_promotion.cashback_amount_krw_0f) / 10000
  }만원을 드립니다.`;

};

// 하나카드 유전자 프로모션에서 사용되고 있습니다.
export const isDecemberDate = (dateAtMs: number) => {
  return dateAtMs >= getTime(new Date(2022, 11, 1)) && dateAtMs < getTime(new Date(2023, 0, 1));
};


/**
 * 모바일 브라우저에서만 true를 리턴합니다.
 * reference: https://stackdiary.com/detect-mobile-browser-javascript/
 */
export const isTouchableMobileDevice = () => 'ontouchstart' in document.documentElement;

/**
 * 4335 BC BC 바로 에어 플러스
 * 4199 BC 바로 리워드
 * 4200 BC 바로 페이백
 * 4201 BC 바로 클리어
 * 4120 BC 바로 시발
 * 4240 BC 바로 밸런스
 * 4111 MULTI Any
 * 4108 MULTI Oil
 * 4092 MULTI On
 * 4004 MULTI Young
 * 4110 MULTI Living
 */
const QR_CARD_IDS = ['4335', '4199', '4200', '4201', '4120', '4240', '4111', '4108', '4092', '4004', '4110'];
export const isQRCard = (id: string) => QR_CARD_IDS.includes(id);
