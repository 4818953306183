import { Entity } from 'core/entity';

export class PromotionMeta extends Entity {
    constructor(
        public title: string,
        public description: string,
        public keywords: string,
        public image: string,
    ) {
        super();
    }
}
