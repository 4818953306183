import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentAuthorProfile } from 'core/entity/content/author/profile';
import { ContentAuthorDescriptionMapper } from 'data/http/mapper/content/author/description';

export class ContentAuthorProfileMapper implements NetworkResponseMapper<ContentAuthorProfile> {
    fromJson(json: any): ContentAuthorProfile {
        return new ContentAuthorProfile(
            json.name,
            json.summary,
            json.image_url,
            new ContentAuthorDescriptionMapper().fromJson(json.description)
        )
    }
}
