import { Application } from 'application/context.instance';
import { Notice } from 'core/entity/notice';
import { FetchError } from 'presentation/components/fetch-state/error';
import { HTMLBody } from 'presentation/components/html-body';
import { Loader } from 'presentation/components/loader';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { isMobile } from 'presentation/module/sugar';
import { FetchState } from 'presentation/view-model/fetch-state';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { apply, toDateString } from 'utils/index';

import styles from './styles.pcss';

interface State {
    notice: Notice;
    fetchState: FetchState;
}

export class NoticeRoute extends React.Component<RouteComponentProps<any>, State> {
    state = {
      notice: null as Notice,
      fetchState: FetchState.FETCHING
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
      if (!isMobile()) location.href = BANKSALAD_SITEMAP.NOTICE;
      this.fetch();
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    render() {
      const { fetchState, notice } = this.state;

      return fetchState === FetchState.FETCHED ? (
        <div className={ styles.container }>
          <div className={ styles.head }>
            <h1 className={ styles.title }>{ notice.title }</h1>
            <h2 className={ styles.date }>{ toDateString(notice.createdAt, '.') }</h2>
            <button className={ styles.back } onClick={ this.onBack }>돌아가기</button>
          </div>
          <div className={ styles.body }>
            <HTMLBody html={ notice.description } />
          </div>
        </div>
      ) : fetchState === FetchState.FETCHING ? (
        <Loader padding={ 150 } radius={ 25 } />
      ) : (
        <FetchError padding={ 150 }>
                공지사항을 불러오는 중 에러가 발생했습니다<br/>
                잠시 후 다시 시도해주세요
        </FetchError>
      );
    }

    private fetch() {
      apply(Application.useCases.getNotice, it => {
        it.id = this.props.match.params.id;
      }).runOnAnimateFrame().subscribe(
        notice =>
          this.setState({
            notice,
            fetchState: FetchState.FETCHED
          }),
        () =>
          this.setState({
            fetchState: FetchState.ERROR
          })
      ).unsubscribeBy(this.subscriptionBag);
    }

    private onBack = () =>
      window.history.back();
}
