import { Service } from 'core/service';
import { ServiceCenterRepositoryType } from 'core/repository/service-center';

export class ServiceCenterService implements Service {
    constructor(
        private serviceCenterRepository: ServiceCenterRepositoryType
    ) {} 

    getCategories = () => (
        this.serviceCenterRepository.getCategories()
    );

    getQuestionsById = (id: number) => (
        this.serviceCenterRepository.getQuestionsById(id)
    );

    getArticleById = (id: number) => (
        this.serviceCenterRepository.getArticleById(id)
    );
}
