import React from 'react'

import { generateOneLink } from '../../setUrl';

const baseUrl = 'https://go.onelink.me/xMM7/3dbff3f8';

export class EventFineDustInsuranceB extends React.Component {
    componentDidMount() {
        location.href = generateOneLink(baseUrl,'fine-dust','190522', 'case-b');
    }
    render () {
        return (
            <div>
                {/* explicitly empty issue Num #1758 */}
            </div>
        )
    }
}
