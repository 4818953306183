import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { LoanSurveyConfigurationRepositoryType } from 'core/repository/loan-survey-configuration';
import { LoanStatisticsRepositoryType } from 'core/repository/loan-statistics';
import { Pair } from 'utils/index';
import { List } from 'immutable';
import { LoanInterviewHistory } from 'core/entity/loan/interview-history';
import { LoanStatistics } from 'core/entity/loan/statistics';

type Result = Pair<List<LoanInterviewHistory>, LoanStatistics>;

export class GetLoanProfitsPresentationalContents extends UseCase<Result> {
    private configureRepository: LoanSurveyConfigurationRepositoryType;
    private statisticsRepository: LoanStatisticsRepositoryType;

    constructor(
        configureRepository: LoanSurveyConfigurationRepositoryType,
        statisticsRepository: LoanStatisticsRepositoryType,
    ) {
        super();
        this.configureRepository = configureRepository;
        this.statisticsRepository = statisticsRepository;
    }

    protected build(): Observable<Result> {
        return Observable.zip(
            this.configureRepository.get(),
            this.statisticsRepository.get(),
            (configure, statistics) => new Pair(configure.histories, statistics)
        );
    }

    protected validate(): boolean {
        return true;
    }
}
