import { LoanOrganization } from 'core/entity/loan/organization';
import { LoanInterestPromotion } from 'core/entity/loan/interest-promotion';

export class CreditLoanIssueAlertModel {
    desktopIssueUrl: string;
    mobileIssueUrl: string;
    loanSlug: string;
    organization: LoanOrganization;
    promotion: LoanInterestPromotion;

    constructor(
        desktopIssueUrl: string,
        mobileIssueUrl: string,
        loanSlug: string,
        organization: LoanOrganization,
        promotion: LoanInterestPromotion
    ) {
        this.desktopIssueUrl = desktopIssueUrl;
        this.mobileIssueUrl = mobileIssueUrl;
        this.loanSlug = loanSlug;
        this.organization = organization;
        this.promotion = promotion;
    }
}
