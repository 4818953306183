import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class UserCreditScoreCardLimit extends Entity {
    total: Amount;
    cashService: Amount;

    constructor(
        total: Amount,
        cashService: Amount
    ) {
        super();
        this.total = total;
        this.cashService = cashService;
    }
}
