import { CardPriority } from 'core/entity/card/priority';
import { PriorityPreset } from 'presentation/view-model/card-priority/preset';

export const toCardPriority = (priority: PriorityPreset) => {
    switch (priority) {
        case PriorityPreset.PROFIT_DESC:
            return new CardPriority(CardPriority.Criteria.PROFIT, false);
        case PriorityPreset.PROFIT_ASC:
            return new CardPriority(CardPriority.Criteria.PROFIT, true);
        case PriorityPreset.PROMOTION_END_DATE_ASC:
            return new CardPriority(CardPriority.Criteria.PROMOTION_END_DATE, true);
        case PriorityPreset.PROMOTION_CREATE_DATE_DESC:
            return new CardPriority(CardPriority.Criteria.PROMOTION_CREATE_DATE, false);
        case PriorityPreset.ANNUAL_COST_DESC:
            return new CardPriority(CardPriority.Criteria.ANNUAL_COST, false);
        case PriorityPreset.ANNUAL_COST_ASC:
            return new CardPriority(CardPriority.Criteria.ANNUAL_COST, true);
    }
};
