import { PersonalizedLoanRepositoryType } from 'core/repository/personalized-recommend/loan';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { PersonalizedLoanApiProvider } from 'data/http/api/gateway/v4/me/loans';
import { Observable } from 'rxjs/Observable';
import { PersonalizedLoanRecommendedResult } from 'core/entity/personalized-recommend/loan/recommended-result';
import { PersonalizedLoanRecommendSpec } from 'core/entity/personalized-recommend/loan/recommend-spec';
import { List } from 'immutable';
import { PersonalizedLoanCategory } from 'core/entity/personalized-recommend/loan/category';
import { PersonalizedLoanCategoryApiProvider } from 'data/http/api/gateway/v3/loans/categories';

export class PersonalizedLoanRepository implements PersonalizedLoanRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private recommendApi: PersonalizedLoanApiProvider;
    private categoryApi: PersonalizedLoanCategoryApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        recommendApi: PersonalizedLoanApiProvider,
        categoryApi: PersonalizedLoanCategoryApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.recommendApi = recommendApi;
        this.categoryApi = categoryApi;
    }

    recommend = (
        recommendSpec: PersonalizedLoanRecommendSpec
    ): Observable<PersonalizedLoanRecommendedResult> => (
        this.tokenStorage
            .get()
            .flatMap(token => this.recommendApi.recommend(recommendSpec, token))
    );

    getCategories = (): Observable<List<PersonalizedLoanCategory>> => (
        this.categoryApi.getAll()
    );
}
