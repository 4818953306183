import { Card } from 'core/entity/card';
import { CardIssuePhoneAlert } from 'presentation/components/alert/card-issue-phone';
import { CardIssueUrlAlert } from 'presentation/components/alert/card-issue-url';
import { CardIssuePromotionAlert } from 'presentation/components/flexible-alert/card-issue-promotion';
import { GA_ACTION, GA_DOMAIN, GA_EVENT_VALUE, GA_LABEL, sendGAEvent } from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { isMobile } from 'presentation/module/sugar';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    card: Card;
    issueText: string;
    gaCategory: string;
    isOneButtonExposedCards: boolean;
}

interface State {
    popupIssueUrl: boolean;
    popupIssuePhone: boolean;
    popupIssuePromotion: boolean;
}

export class FixedCardIssue extends React.Component<Props, State> {
    state = {
      popupIssueUrl: false,
      popupIssuePhone: false,
      popupIssuePromotion: false,
    };

    render() {
      const { card, issueText, gaCategory, isOneButtonExposedCards } = this.props;
      const {
        popupIssueUrl,
        popupIssuePhone,
        popupIssuePromotion,
      } = this.state;
      const inPromotion = card.isInPromotion();
      const issuableOnline = card.isIssuableUrl();
      const issuableTel = card.isIssuableCall();
        
      return (
        <div className={ styles.fixed }>
          <img
            src={ card.imageUrl }
            className={ styles.defaultImage }
            onLoad={ this.onLoadImage }
          />
          <div className={ styles.cover }>
            <strong className={ styles.name }>{ card.name }</strong>
            <div className={ styles.buttons }>
              {isOneButtonExposedCards ? (
                <button
                  style={ { width: '150px' } }
                  onClick={ () => {
                    this.openIssueUrlAlert();
                    sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${card.companyName}`);
                    sendGAEvent(
                      GA_DOMAIN.CARD,
                      gaCategory,
                      GA_ACTION.POPUP_ISSUE,
                      GA_LABEL.ISSUE(issueText, card.companyName, card.id),
                      inPromotion ? GA_EVENT_VALUE.IN_PROMOTION : GA_EVENT_VALUE.NOT_IN_PROMOTION,
                    );
                  } }
                  className={ issuableOnline ? styles.online : styles.disabled }
                >
                  카드 자세히 보기
                </button>
              ) : (
                <>
                  <button
                    onClick={ () => {
                      this.openIssueUrlAlert();
                      sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${card.companyName}`);
                      sendGAEvent(
                        GA_DOMAIN.CARD,
                        gaCategory,
                        GA_ACTION.POPUP_ISSUE,
                        GA_LABEL.ISSUE(issueText, card.companyName, card.id),
                        inPromotion ? GA_EVENT_VALUE.IN_PROMOTION : GA_EVENT_VALUE.NOT_IN_PROMOTION,
                      );
                    } }
                    className={ issuableOnline ? styles.online : styles.disabled }
                  >
                    {issueText}
                  </button>
                  <button
                    onClick={ () => {
                      this.openIssuePhoneAlert();
                      sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${card.companyName}`);
                      sendGAEvent(
                        GA_DOMAIN.CARD,
                        gaCategory,
                        GA_ACTION.ISSUE,
                        GA_LABEL.ISSUE('전화신청', card.companyName, card.id),
                      );
                    } }
                    className={ issuableTel ? styles.tel : styles.disabled }
                  >
          전화 신청
                  </button>
                </>
              )}
            </div>
            <a
              href={ isMobile() ? card.issueUrlMobile : card.issueUrlDesktop }
              target='_blank'
              className={ styles.link }
              onClick={ () => {
                gaCategory && sendGAEvent(
                  GA_DOMAIN.CARD,
                  gaCategory,
                  GA_ACTION.ISSUE,
                  GA_LABEL.ISSUE('해당카드사로이동', card.companyName, card.id),
                );
              } }
            >
                        해당 카드사로 이동
            </a>
          </div>
          {
            popupIssuePhone &&
                    card.issuePhoneNumber &&
                    this.renderAlert()
          }
          {
            popupIssueUrl && (
              card.issueUrlDesktop ||
                        card.issueUrlMobile
            ) &&
                    this.renderAlert()
          }
          { popupIssuePromotion && this.renderAlert() }
        </div>
      );
    }

    private renderAlert = () => {
      const { popupIssuePhone } = this.state;
      const { card, gaCategory } = this.props;

      if (popupIssuePhone)
        return (
          <CardIssuePhoneAlert
            cardId={ card.id }
            issuePhoneNumber={ card.issuePhoneNumber }
            companyImageUrl={ card.companyImageUrl }
            onCancel={ () => this.setState({ popupIssuePhone: false }) }
          />
        );

      return card.annualCostPromotion ?
        <CardIssuePromotionAlert
          cardId={ card.id }
          description={ card.annualCostPromotion.description }
          summary={ card.annualCostPromotion.summary }
          companyName={ card.companyName }
          issueUrlDesktop={ card.issueUrlDesktop }
          issueUrlMobile={ card.issueUrlMobile }
          onCancel={ () => this.setState({ popupIssuePromotion: false }) }
        /> :
        <CardIssueUrlAlert
          cardId={ card.id }
          issueUrlDesktop={ card.issueUrlDesktop }
          issueUrlMobile={ card.issueUrlMobile }
          companyName={ card.companyName }
          companyImageUrl={ card.companyImageUrl }
          gaCategory={ gaCategory }
          onCancel={ () => this.setState({ popupIssueUrl: false }) }
        />;
    };

    private openIssueUrlAlert = () => {
      const { card } = this.props;

      if (card.isInPromotion()) {
        this.setState({
          popupIssuePromotion: true,
        });
      } else {
        this.setState({
          popupIssueUrl: true,
        });
      }
    };

    private openIssuePhoneAlert = () => {
      this.setState({
        popupIssuePhone: true,
      });
    };

    private onLoadImage = (e: React.ChangeEvent<HTMLImageElement>) => {
      const element = e.target;
      element.className = element.height <= element.width ?
        styles.landscapeImage : styles.portraitImage;
    };
}
