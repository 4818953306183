import { Entity } from 'core/entity';

export class CardCompanyCFAOverdue extends Entity {
    description: string;

    constructor(description: string) {
        super();
        this.description = description;
    }
}
