import { UserProductConfiguration } from 'core/entity/personal-finance/product-configuration';

declare module 'core/entity/personal-finance/product-configuration' {
    export interface UserProductConfiguration {
        isEmpty(): boolean;
    }
}

UserProductConfiguration.prototype.isEmpty = function() {
    return (
        this.actualAssets.isEmpty() &&
        this.bankAccounts.isEmpty() &&
        this.cards.isEmpty() &&
        this.cashAccounts.isEmpty() &&
        this.loans.isEmpty() &&
        this.securitiesFirmAccounts.isEmpty() &&
        this.funds.isEmpty() &&
        this.stocks.isEmpty()
    )
};
