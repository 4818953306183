import { HttpProviderDependencies } from 'application/@context/http-providers';
import { RepositoryDependencies } from 'application/@context/repositories';
import { ServiceDependencies } from 'application/@context/services';
import { StorageProviderDependencies } from 'application/@context/storage-providers';
import { UseCaseDependencies } from 'application/@context/use-cases';
import { AxiosInstance } from 'axios';
import { User } from 'core/entity/user';
import { Observable } from 'rxjs/Observable';

export class Context {
    private apiProviders: HttpProviderDependencies;
    private storageProviders: StorageProviderDependencies;
    private repositories: RepositoryDependencies;
    readonly useCases: UseCaseDependencies;
    services: ServiceDependencies;
    me?: User = null;

    constructor(
      gatewayInstance: AxiosInstance,
      baseInstance: AxiosInstance,
      cdnInstance: AxiosInstance,
      zendeskInstance: AxiosInstance,
      facebookClientId: string,
      ignoredLegalService: boolean,
    ) {
      this.apiProviders = new HttpProviderDependencies(
        gatewayInstance,
        baseInstance,
        cdnInstance,
        zendeskInstance,
        ignoredLegalService,
      );

      this.storageProviders = new StorageProviderDependencies();
      this.repositories = new RepositoryDependencies(this.apiProviders, this.storageProviders, facebookClientId);
      this.useCases = new UseCaseDependencies(this.repositories);
      this.services = new ServiceDependencies(this.repositories);
    }

    boot = (): Observable<void> => {
      const token = this.storageProviders.accessToken.syncGet();
      if (!token) {
        return Observable.of(null);
      }

      if (location.pathname.indexOf('policies') !== -1)
        return Observable.of(null);

      return this.services.user.keepLogin()
        .flatMap(() => this.services.user.get())
        .map(user => {
          this.me = user;
        });
    }
}
