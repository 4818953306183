import { RepositoryDependencies } from 'application/@context/repositories';
import { CacheCardForCompare } from 'core/use-case/cards/cache-card-for-compare';
import { ClearCachedCardForCompare } from 'core/use-case/cards/clear-cached-card-for-compare';
import { ClearCachedCardRecommendSpec } from 'core/use-case/cards/clear-cached-card-recommend-spec';
import { GetCachedCardForCompare } from 'core/use-case/cards/get-cached-card-for-compare';
import { GetCachedCardRecommendSpec } from 'core/use-case/cards/get-cached-card-recommend-spec';
import { GetCard } from 'core/use-case/cards/get-card';
import { GetCardConfiguration } from 'core/use-case/cards/get-card-configuration';
import { GetCardsProfitsConfig } from 'core/use-case/cards/get-cards-profits-config';
import { GetCardsTop10 } from 'core/use-case/cards/get-cards-top-10';
import { GetPromotionOnlyCards } from 'core/use-case/cards/get-promotion-only-cards';
import { GetServerRenderedCard } from 'core/use-case/cards/get-server-rendered-card';
import { RecommendCardSimply } from 'core/use-case/cards/recommend-card-simply';
import { RecommendCards } from 'core/use-case/cards/recommend-cards';
import { SearchCards } from 'core/use-case/cards/search-cards';
import { GetAnnuitySector } from 'core/use-case/finance-sector/get-annuity-sector';
import { GetBankSector } from 'core/use-case/finance-sector/get-bank-sector';
import { GetCardSector } from 'core/use-case/finance-sector/get-card-sector';
import { GetFinanceSector } from 'core/use-case/finance-sector/get-finance-sector';
import { GetFinanceSectorSummaries } from 'core/use-case/finance-sector/get-finance-sector-summaries';
import { GetFintechSector } from 'core/use-case/finance-sector/get-fintech-sector';
import { GetInvestmentSector } from 'core/use-case/finance-sector/get-investment-sector';
import { GetLoanSector } from 'core/use-case/finance-sector/get-loan-sector';
import { GetMagazineSector } from 'core/use-case/finance-sector/get-magazine-sector';
import { GetCardCompanyLegalConfigByCompanyId } from 'core/use-case/legal-configuration/get-card-company-legal-config-by-company-id';
import { GetCardCompanyLegalConfigs } from 'core/use-case/legal-configuration/get-card-company-legal-configs';
import { GetCardLegalConfigByCardId } from 'core/use-case/legal-configuration/get-card-legal-config-by-card-id';
import { GetLoanLegalConfigByLoanId } from 'core/use-case/legal-configuration/get-loan-legal-config-by-loan-id';
import { CacheLoanDesiredAmount } from 'core/use-case/loan/cache-loan-desired-amount';
import { CacheLoanRecommendFilter } from 'core/use-case/loan/cache-loan-recommend-filter';
import { CacheLoanRecommendSpec } from 'core/use-case/loan/cache-loan-recommend-spec';
import { ClearLoanRecommendCaches } from 'core/use-case/loan/clear-loan-recommend-caches';
import { ComputeLoanValues } from 'core/use-case/loan/compute-loan-values';
import { GetCachedLoanRecommendFilter } from 'core/use-case/loan/get-cached-loan-recommend-filter';
import { GetCachedLoanRecommendSpec } from 'core/use-case/loan/get-cached-loan-recommend-spec';
import { GetLoanProfitsCachedData } from 'core/use-case/loan/get-loan-profits-cached-data';
import { GetLoanProfitsPresentationalContents } from 'core/use-case/loan/get-loan-profits-presentaional-contents';
import { GetLoanStatistics } from 'core/use-case/loan/get-loan-statistics';
import { GetLoanSurveyConfiguration } from 'core/use-case/loan/get-loan-survey-configuration';
import { GetServerRenderedLoan } from 'core/use-case/loan/get-server-rendered-loan';
import { RecommendLoan } from 'core/use-case/loan/recommend-loan';
import { RecommendLoanSimply } from 'core/use-case/loan/recommend-loan-simply';
import { GetNotices } from 'core/use-case/notice/get-all-notice';
import { GetNotice } from 'core/use-case/notice/get-notice';
import { CreateUserTransaction } from 'core/use-case/personal-finance/create-user-transaction';
import { DeleteUserTransactions } from 'core/use-case/personal-finance/delete-user-transactions';
import { GetSpiderBanks } from 'core/use-case/personal-finance/get-spider-banks';
import { GetSpiderCardCompanies } from 'core/use-case/personal-finance/get-spider-card-companies';
import { GetSpiderSecuritiesFirms } from 'core/use-case/personal-finance/get-spider-securities-firms';
import { GetSummarizedUserCardBills } from 'core/use-case/personal-finance/get-summarized-user-card-bills';
import { GetSummarizedUserProducts } from 'core/use-case/personal-finance/get-summarized-user-products';
import { GetTransactionCategories } from 'core/use-case/personal-finance/get-transaction-categories';
import { GetUserTransactions } from 'core/use-case/personal-finance/get-transactions';
import { GetUserTransactionsByProduct } from 'core/use-case/personal-finance/get-transactions-by-product';
import { GetUserCardBill } from 'core/use-case/personal-finance/get-user-card-bill';
import { GetUserCardBillContentsByCompany } from 'core/use-case/personal-finance/get-user-card-bill-contents-by-company';
import { GetUserCardCreditLimits } from 'core/use-case/personal-finance/get-user-card-credit-limits';
import { GetUserProducts } from 'core/use-case/personal-finance/get-user-products';
import { RecoverUserTransactions } from 'core/use-case/personal-finance/recover-user-transactions';
import { UpdateUserActualAsset } from 'core/use-case/personal-finance/update-user-actual-asset';
import { UpdateUserBankAccount } from 'core/use-case/personal-finance/update-user-bank-account';
import { UpdateUserCard } from 'core/use-case/personal-finance/update-user-card';
import { UpdateUserCashAccount } from 'core/use-case/personal-finance/update-user-cash-account';
import { UpdateUserFund } from 'core/use-case/personal-finance/update-user-fund';
import { UpdateUserLoan } from 'core/use-case/personal-finance/update-user-loan';
import { UpdateUserStock } from 'core/use-case/personal-finance/update-user-stock';
import { UpdateUserTransaction } from 'core/use-case/personal-finance/update-user-transaction';
import { UpdateUserTransactionType } from 'core/use-case/personal-finance/update-user-transaction-type';
import { GetBestStoreGroups } from 'core/use-case/stores/get-best-store-groups';
import { GetStoreCategories } from 'core/use-case/stores/get-store-categories';
import { GetStoreGroups } from 'core/use-case/stores/get-store-groups';
import { GetStores } from 'core/use-case/stores/get-stores';
import { SearchStores } from 'core/use-case/stores/search-stores';

export class UseCaseDependencies {
    cacheCardForCompare: CacheCardForCompare;
    clearCachedCardForCompare: ClearCachedCardForCompare;
    clearCachedCardRecommendSpec: ClearCachedCardRecommendSpec;
    getCachedCardForCompare: GetCachedCardForCompare;
    getCachedCardRecommendSpec: GetCachedCardRecommendSpec;
    getCardConfiguration: GetCardConfiguration;
    getCardsProfitsConfig: GetCardsProfitsConfig;
    getCardsTop10: GetCardsTop10;
    getPromotionOnlyCards: GetPromotionOnlyCards;
    getServerRenderedCard: GetServerRenderedCard;
    getCard: GetCard;
    recommendCardSimply: RecommendCardSimply;
    recommendCards: RecommendCards;
    searchCards: SearchCards;
    getAnnuitySector: GetAnnuitySector;
    getBankSector: GetBankSector;
    getCardSector: GetCardSector;
    getFinanceSector: GetFinanceSector;
    getFinanceSectorSummaries: GetFinanceSectorSummaries;
    getFintechSector: GetFintechSector;
    getInvestmentSector: GetInvestmentSector;
    getLoanSector: GetLoanSector;
    getMagazineSector: GetMagazineSector;
    getBestStoreGroups: GetBestStoreGroups;
    getStoreCategories: GetStoreCategories;
    getStoreGroups: GetStoreGroups;
    getStores: GetStores;
    searchStores: SearchStores;
    getServerRenderedLoan: GetServerRenderedLoan;
    getLoanSurveyConfiguration: GetLoanSurveyConfiguration;
    getLoanProfitsPresentationalContents: GetLoanProfitsPresentationalContents;
    getLoanProfitsCachedData: GetLoanProfitsCachedData;
    recommendLoan: RecommendLoan;
    recommendLoanSimply: RecommendLoanSimply;
    cacheLoanRecommendFilter: CacheLoanRecommendFilter;
    cacheLoanRecommendSpec: CacheLoanRecommendSpec;
    clearLoanRecommendCaches: ClearLoanRecommendCaches;
    getCachedLoanRecommendFilter: GetCachedLoanRecommendFilter;
    getCachedLoanRecommendSpec: GetCachedLoanRecommendSpec;
    computeLoanValues: ComputeLoanValues;
    getLoanStatistics: GetLoanStatistics;
    getUserProducts: GetUserProducts;
    getSummarizedUserProducts: GetSummarizedUserProducts;
    getUserCardCreditLimits: GetUserCardCreditLimits;
    getSummarizedUserCardBills: GetSummarizedUserCardBills;
    getUserTransactionsByProduct: GetUserTransactionsByProduct;
    getUserTransactions: GetUserTransactions;
    deleteUserTransactions: DeleteUserTransactions;
    recoverUserTransactions: RecoverUserTransactions;
    getUserCardBillContentsByCompany: GetUserCardBillContentsByCompany;
    getUserCardBill: GetUserCardBill;
    updateUserCashAccount: UpdateUserCashAccount;
    updateUserActualAsset: UpdateUserActualAsset;
    updateUserBankAccount: UpdateUserBankAccount;
    updateUserCard: UpdateUserCard;
    updateUserLoan: UpdateUserLoan;
    updateUserFund: UpdateUserFund;
    updateUserStock: UpdateUserStock;
    updateUserTransaction: UpdateUserTransaction;
    updateUserTransactionType: UpdateUserTransactionType;
    createUserTransaction: CreateUserTransaction;
    getTransactionCategories: GetTransactionCategories;
    cacheLoanDesiredAmount: CacheLoanDesiredAmount;
    getCardLegalConfigByCardId: GetCardLegalConfigByCardId;
    getCardCompanyLegalConfigs: GetCardCompanyLegalConfigs;
    getCardCompanyLegalConfigByCompanyId: GetCardCompanyLegalConfigByCompanyId;
    getLoanLegalConfigByLoanId: GetLoanLegalConfigByLoanId;
    getNotices: GetNotices;
    getNotice: GetNotice;
    getSpiderBanks: GetSpiderBanks;
    getSpiderCardCompanies: GetSpiderCardCompanies;
    getSpiderSecuritiesFirms: GetSpiderSecuritiesFirms;

    constructor(repo: RepositoryDependencies) {
      this.cacheCardForCompare = new CacheCardForCompare(repo.card.cardSummaryForCompare);
      this.clearCachedCardForCompare = new ClearCachedCardForCompare(repo.card.cardSummaryForCompare);
      this.clearCachedCardRecommendSpec = new ClearCachedCardRecommendSpec(repo.card.cardRecommend);
      this.getCachedCardForCompare = new GetCachedCardForCompare(repo.card.cardSummaryForCompare);
      this.getCachedCardRecommendSpec = new GetCachedCardRecommendSpec(repo.card.cardRecommend);
      this.getCardConfiguration = new GetCardConfiguration(repo.card.cardConfiguration);
      this.getCardsProfitsConfig = new GetCardsProfitsConfig(repo.card.cardConfiguration, repo.card.cardRecommend);
      this.getCardsTop10 = new GetCardsTop10(repo.card.cardNetwork);
      this.getPromotionOnlyCards = new GetPromotionOnlyCards(repo.card.cardNetwork);
      this.getServerRenderedCard = new GetServerRenderedCard(repo.card.cardServerRender);
      this.getCard = new GetCard(repo.card.cardNetwork);
      this.recommendCardSimply = new RecommendCardSimply(repo.card.cardRecommend);
      this.recommendCards = new RecommendCards(repo.card.cardRecommend);
      this.searchCards = new SearchCards(repo.card.cardNetwork);
      this.getAnnuitySector = new GetAnnuitySector(repo.financeSector);
      this.getBankSector = new GetBankSector(repo.financeSector);
      this.getCardSector = new GetCardSector(repo.financeSector);
      this.getFinanceSector = new GetFinanceSector(repo.financeSector);
      this.getFinanceSectorSummaries = new GetFinanceSectorSummaries(repo.financeSector);
      this.getFintechSector = new GetFintechSector(repo.financeSector);
      this.getInvestmentSector = new GetInvestmentSector(repo.financeSector);
      this.getLoanSector = new GetLoanSector(repo.financeSector);
      this.getMagazineSector = new GetMagazineSector(repo.financeSector);
      this.getBestStoreGroups = new GetBestStoreGroups(repo.card.store);
      this.getStoreCategories = new GetStoreCategories(repo.card.store);
      this.getStoreGroups = new GetStoreGroups(repo.card.store);
      this.getStores = new GetStores(repo.card.store);
      this.searchStores = new SearchStores(repo.card.store);
      this.getServerRenderedLoan = new GetServerRenderedLoan(repo.loan.loan);
      this.getLoanProfitsPresentationalContents = new GetLoanProfitsPresentationalContents(repo.loan.loanSurveyConfiguration, repo.loan.loanStatistics);
      this.getLoanProfitsCachedData = new GetLoanProfitsCachedData(repo.loan.loanRecommend);
      this.getLoanSurveyConfiguration = new GetLoanSurveyConfiguration(repo.loan.loanSurveyConfiguration);
      this.recommendLoan = new RecommendLoan(repo.loan.loanRecommend);
      this.recommendLoanSimply = new RecommendLoanSimply(repo.loan.loanRecommend);
      this.cacheLoanRecommendFilter = new CacheLoanRecommendFilter(repo.loan.loanRecommend);
      this.cacheLoanRecommendSpec = new CacheLoanRecommendSpec(repo.loan.loanRecommend);
      this.clearLoanRecommendCaches = new ClearLoanRecommendCaches(repo.loan.loanRecommend);
      this.getCachedLoanRecommendFilter = new GetCachedLoanRecommendFilter(repo.loan.loanRecommend);
      this.getCachedLoanRecommendSpec = new GetCachedLoanRecommendSpec(repo.loan.loanRecommend);
      this.computeLoanValues = new ComputeLoanValues(repo.loan.loanRecommend);
      this.getLoanStatistics = new GetLoanStatistics(repo.loan.loanStatistics);
      this.cacheLoanDesiredAmount = new CacheLoanDesiredAmount(repo.loan.loanRecommend);
      this.getUserProducts = new GetUserProducts(repo.pfm.userProducts);
      this.getSummarizedUserProducts = new GetSummarizedUserProducts(repo.pfm.userProducts);
      this.getUserCardCreditLimits = new GetUserCardCreditLimits(repo.pfm.userCard);
      this.getSummarizedUserCardBills = new GetSummarizedUserCardBills(repo.pfm.userCard);
      this.getUserTransactionsByProduct = new GetUserTransactionsByProduct(repo.pfm.userTransaction);
      this.getUserTransactions = new GetUserTransactions(repo.pfm.userTransaction);
      this.getUserCardBillContentsByCompany = new GetUserCardBillContentsByCompany(repo.pfm.userCardBill);
      this.getUserCardBill = new GetUserCardBill(repo.pfm.userCardBill);
      this.updateUserCashAccount = new UpdateUserCashAccount(repo.pfm.userCashAccount);
      this.updateUserActualAsset = new UpdateUserActualAsset(repo.pfm.userActualAsset);
      this.updateUserBankAccount = new UpdateUserBankAccount(repo.pfm.userBankAccount);
      this.updateUserCard = new UpdateUserCard(repo.pfm.userCard);
      this.updateUserLoan = new UpdateUserLoan(repo.pfm.userLoan);
      this.updateUserFund = new UpdateUserFund(repo.pfm.userFund);
      this.updateUserStock = new UpdateUserStock(repo.pfm.userStock);
      this.updateUserTransaction = new UpdateUserTransaction(repo.pfm.userTransaction);
      this.updateUserTransactionType = new UpdateUserTransactionType(repo.pfm.userTransaction, repo.pfm.transactionCategory);
      this.deleteUserTransactions = new DeleteUserTransactions(repo.pfm.userTransaction);
      this.recoverUserTransactions = new RecoverUserTransactions(repo.pfm.userTransaction);
      this.createUserTransaction = new CreateUserTransaction(repo.pfm.userTransaction);
      this.getTransactionCategories = new GetTransactionCategories(repo.pfm.transactionCategory);
      this.getCardLegalConfigByCardId = new GetCardLegalConfigByCardId(repo.legalConfig.cardLegalConfig);
      this.getCardCompanyLegalConfigs = new GetCardCompanyLegalConfigs(repo.legalConfig.cardCompanyLegalConfig);
      this.getCardCompanyLegalConfigByCompanyId = new GetCardCompanyLegalConfigByCompanyId(repo.legalConfig.cardCompanyLegalConfig);
      this.getLoanLegalConfigByLoanId = new GetLoanLegalConfigByLoanId(repo.legalConfig.loanLegalConfig);
      this.getNotices = new GetNotices(repo.notice);
      this.getNotice = new GetNotice(repo.notice);
      this.getSpiderBanks = new GetSpiderBanks(repo.pfm.spiderBank);
      this.getSpiderCardCompanies = new GetSpiderCardCompanies(repo.pfm.spiderCardCompany);
      this.getSpiderSecuritiesFirms = new GetSpiderSecuritiesFirms(repo.pfm.spiderSecuritiesFirm);
    }
}
