import { FinanceSectorSummary } from 'core/entity/finance-sector/summary';
import { NetworkResponseMapper } from 'data/http/mapper';
import { FinanceSectorIDMapper } from 'data/http/mapper/finance-sector/id';
import { FinanceSubSectorSummaryMapper } from 'data/http/mapper/finance-sector/sub/summary';
import { optional } from 'utils/index';

export class FinanceSectorSummaryMapper implements NetworkResponseMapper<FinanceSectorSummary> {
  fromJson(json: any): FinanceSectorSummary {
    return new FinanceSectorSummary(
      new FinanceSectorIDMapper().fromJson(json.id),
      json.name,
      optional(json.sub1, it => new FinanceSubSectorSummaryMapper().fromJson(it)),
      optional(json.sub2, it => new FinanceSubSectorSummaryMapper().fromJson(it)),
      optional(json.sub3, it => new FinanceSubSectorSummaryMapper().fromJson(it)),
      optional(json.sub4, it => new FinanceSubSectorSummaryMapper().fromJson(it)),
      json.externalLinkTarget,
      json.externalLink,
    );
  }
}
