import { Entity } from 'core/entity';
import { Alfred2CardIssuingConfiguration } from 'core/entity/alfred2/card-issuing-configuration';

export class Alfred2CardIssuingChannelConfiguration extends Entity {
    desktopWeb: Alfred2CardIssuingConfiguration;
    mobileWeb: Alfred2CardIssuingConfiguration;
    mobileApp: Alfred2CardIssuingConfiguration;

    constructor(
        desktopWeb: Alfred2CardIssuingConfiguration,
        mobileWeb: Alfred2CardIssuingConfiguration,
        mobileApp: Alfred2CardIssuingConfiguration
    ) {
        super();
        this.desktopWeb = desktopWeb;
        this.mobileWeb = mobileWeb;
        this.mobileApp = mobileApp;
    }
}
