import { Entity } from 'core/entity';

export class RefreshToken extends Entity {
    accessToken: string;
    refreshToken: string;
    expiresIn: Date;

    constructor(
        accessToken: string,
        refreshToken: string,
        expiresIn: Date
    ) {
        super();
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.expiresIn = expiresIn;
    }
}
