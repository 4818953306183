import { Entity } from 'core/entity';

export class ComparableCardIssuingUrl extends Entity {
    desktop: string;
    mobile: string;
    app: string;

    constructor(
        desktop: string,
        mobile: string,
        app: string
    ) {
        super();
        this.desktop = desktop;
        this.mobile = mobile;
        this.app = app;
    }
}
