import { Entity } from 'core/entity';
import { PrimaryData } from 'core/entity/personal-finance/primary-data';
import { SpiderLoanData } from 'core/entity/personal-finance/spider-data/loan';

export class LoanPrimaryData extends Entity implements PrimaryData {
    spider?: SpiderLoanData;

    constructor(
        spider: SpiderLoanData = null
    ) {
        super();
        this.spider = spider;
    }
}
