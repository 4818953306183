import { SpiderLoanData } from 'core/entity/personal-finance/spider-data/loan';
import { NetworkResponseMapper } from 'data/http/mapper';
import { optional } from 'utils/index';

export class SpiderLoanDataMapper implements NetworkResponseMapper<SpiderLoanData> {
    fromJson(json: any): SpiderLoanData {
        return new SpiderLoanData(
            json.name,
            optional(json.response_contained)
        )
    }

}
