import { AdviceRepositoryType } from 'core/repository/personal-finance/advice';
import { Observable } from 'rxjs/Rx';
import { Advice } from 'core/entity/personal-finance/advice';
import { AdviceApiProvider } from 'data/http/api/gateway/v4/me/advice';
import { AdviceUpdateSpec } from 'core/entity/personal-finance/advice/update-spec';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class AdviceRepository implements AdviceRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: AdviceApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: AdviceApiProvider
    ) {
        this.api = api;
        this.tokenStorage = tokenStorage;
    }

    get(id: string): Observable<Advice> {
        return this.tokenStorage.get()
            .flatMap(it => this.api.get(id, it));
    }

    update(id: string, spec: AdviceUpdateSpec): Observable<void> {
        return this.tokenStorage.get()
            .flatMap(it => this.api.update(id, spec, it));
    }
}
