import { Entity } from 'core/entity';
import { LoanInterestPromotion } from 'core/entity/loan/interest-promotion';
import { LoanInterestValue } from 'core/entity/loan/interest-value';
import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanPrimeInterest } from 'core/entity/loan/prime-interest';

export class LoanInterestConfiguration extends Entity {
    primeInterest: LoanPrimeInterest;
    interestPromotion?: LoanInterestPromotion;
    baseInterest?: LoanInterestValue;
    additionalInterest?: LoanInterestValue;
    averageInterest?: LoanInterestValue;
    totalInterest: LoanInterestValue;
    type: LoanInterestType;

    constructor(
        primeInterest: LoanPrimeInterest,
        interestPromotion: LoanInterestPromotion = null,
        baseInterest: LoanInterestValue = null,
        additionalInterest: LoanInterestValue = null,
        averageInterest: LoanInterestValue = null,
        totalInterest: LoanInterestValue,
        type: LoanInterestType
    ) {
        super();
        this.primeInterest = primeInterest;
        this.interestPromotion = interestPromotion;
        this.baseInterest = baseInterest;
        this.additionalInterest = additionalInterest;
        this.averageInterest = averageInterest;
        this.totalInterest = totalInterest;
        this.type = type;
    }
}
