import { Entity } from 'core/entity';
import { CachedStateType } from 'core/entity/cached-state/type';

export class CachedStateItem extends Entity {
    type: CachedStateType;
    expiredAt: Date;

    constructor(
        type: CachedStateType,
        expiredAt: Date
    ) {
        super();
        this.type = type;
        this.expiredAt = expiredAt;
    }
}
