import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserBankAccountUpdateSpec } from 'core/entity/personal-finance/bank-account/update-spec';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserBankAccountMapper } from 'data/http/mapper/personal-finance/bank-account';
import { UserBankAccountUpdateSpecMapper } from 'data/http/mapper/personal-finance/bank-account/update-spec';
import { UserBankAccountCreateSpec } from 'core/entity/personal-finance/bank-account/create-spec';
import { UserBankAccountCreateSpecMapper } from 'data/http/mapper/personal-finance/bank-account/create-spec';

export class UserBankAccountsApiProvider extends HttpProvider {
    create(accessToken: AccessToken, spec: UserBankAccountCreateSpec): Observable<UserBankAccount> {
        return this.requestPOST(
            'api/v2/me/banks/accounts',
            new UserBankAccountCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserBankAccountMapper().fromJson(result.data)
        )
    }

    update(accessToken: AccessToken, id: string, spec: UserBankAccountUpdateSpec): Observable<UserBankAccount> {
        return this.requestPATCH(
            `api/v2/me/banks/accounts/${id}`,
            new UserBankAccountUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserBankAccountMapper().fromJson(result.data)
        )
    }
}
