import { Service } from 'core/service';
import { Observable } from 'rxjs/Rx';
import { UserCardRepositoryType } from 'core/repository/personal-finance/card';
import { UserCardUpdateSpec } from 'core/entity/personal-finance/card/update-spec';
import { UserCard } from 'core/entity/personal-finance/card';

export class UserCardService implements Service {
    private repository: UserCardRepositoryType;

    constructor(repository: UserCardRepositoryType) {
        this.repository = repository;
    }

    update = (id: string, spec: UserCardUpdateSpec): Observable<UserCard> =>
        this.repository.update(id, spec);
}
