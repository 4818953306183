import React from 'react';
import { List } from 'immutable';

import { CardAnnualCost } from 'core/entity/card/annual-cost';
import { toCurrency } from 'presentation/module/sugar';
import { uniqueKey } from 'utils/index';
import { CardInfoEmpty } from 'presentation/components/info/card/detail/base-unit/empty';

import styles from './styles.pcss';

interface Props {
    annualCosts: List<CardAnnualCost>
}

export const CardInfoAnnualCost = ({ annualCosts }: Props) => annualCosts.isEmpty() ? (
    <CardInfoEmpty>
        이 상품은 연회비가 없습니다.
    </CardInfoEmpty>
) : (
    <ul className={ styles.annual }>
    {
        annualCosts.map(cost =>
            <li key={ uniqueKey() }>
                <span>{ cost.brand.name }</span>
                <span>{ `${toCurrency(cost.price)}원` }</span>
            </li>
        )
    }
    </ul>
);
