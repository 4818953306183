import { Notice, NoticeType } from 'core/entity/notice';
import { HttpProvider } from 'data/http';
import { NoticeMapper } from 'data/http/mapper/notice';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class NoticesApiProvider extends HttpProvider {
  getAll(type: NoticeType = NoticeType.SERVICE): Observable<List<Notice>> {
    return this.requestGET('api/v2/notices', {
      disabled: false,
      notice_type_enum: type,
    }).map((result) =>
      List(result.data.map((it: any) => new NoticeMapper().fromJson(it))),
    );
  }

  get(id: number): Observable<Notice> {
    return this.requestGET(
      `api/v2/notices/${id}`,
    ).map(result =>
      new NoticeMapper().fromJson(result.data),
    );
  }

}
