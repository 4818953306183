import { Entity } from 'core/entity';
import { List } from 'immutable';
import { InsuranceApplyLink } from 'core/entity/insurance/apply-link';
import { InsuranceInformation } from 'core/entity/insurance/information';
import { InsuranceCommpany } from 'core/entity/insurance/company';
import { InsuranceGuaranteedDisease } from 'core/entity/insurance/guaranteed-disease';
import { InsurancePromotion } from 'core/entity/insurance/promotion';

export class Insurance extends Entity {
    applyLinks: List<InsuranceApplyLink>;
    name: string;
    information: InsuranceInformation;
    company: InsuranceCommpany;
    id: string;
    guaranteedDiseases: List<InsuranceGuaranteedDisease>;
    reviewedNumberDescription?: string;
    promotion?: string;

    constructor(
        applyLink: List<InsuranceApplyLink>,
        name: string,
        information: InsuranceInformation,
        company: InsuranceCommpany,
        id: string,
        guaranteedDiseases: List<InsuranceGuaranteedDisease>,
        reviewedNumberDescription: string = null,
        promotion: string = null
    ) {
        super();
        this.applyLinks = applyLink;
        this.name = name;
        this.information = information;
        this.company = company;
        this.id = id;
        this.guaranteedDiseases = guaranteedDiseases;
        this.reviewedNumberDescription = reviewedNumberDescription;
        this.promotion = promotion;
    }
}
