import { SummarizedUserSecuritiesFirmProducts } from 'core/entity/personal-finance/summarized-products/securities-firm';

declare module 'core/entity/personal-finance/summarized-products/securities-firm' {
    export interface SummarizedUserSecuritiesFirmProducts {
        isEmpty(): boolean;
    }
}

SummarizedUserSecuritiesFirmProducts.prototype.isEmpty = function() {
    return (
        this.stocks.isEmpty() &&
        this.funds.isEmpty() &&
        this.accounts.isEmpty()
    )
};
