import { List } from 'immutable';
import { CardSearchResult } from 'core/entity/card/search-result';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardMapper } from 'data/http/mapper/card';
import { emptyList } from 'utils/index';

export class CardSearchResultMapper implements NetworkResponseMapper<CardSearchResult> {
    fromJson(json: any): CardSearchResult {
        try {
            return (
                new CardSearchResult(
                    json.number_of_cards,
                    json.is_last,
                    List(json.cards.map((card: any) =>
                        new CardMapper().fromJson(card)
                    ))
                )
            )
        } catch (e) {
            // TODO: 현재 검색 API 불안정하여 보완하였음
            return (
                new CardSearchResult(0, true, emptyList())
            )
        }
    }
}
