import { Observable } from 'rxjs';
import { AccessTokenStorageProvider } from 'src/data/browser-storage/api/access-token';
import { HealthInsurancePaymentDetailApiProvider } from 'data/http/api/gateway/v2/me/health-insurance/payment-details';
import { HealthInsurancePaymentDetailRepositoryType } from 'core/repository/personal-finance/health-insurance/payment-details';
import { HealthInsurancePaymentDetailResponse } from 'core/entity/personal-finance/health-insurance/payment-detail/response';

export class HealthInsurancePaymentDetailRepository implements HealthInsurancePaymentDetailRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: HealthInsurancePaymentDetailApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: HealthInsurancePaymentDetailApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    get(): Observable<HealthInsurancePaymentDetailResponse> {
        return this.tokenStorage.get().flatMap(it => this.api.get(it));
    }
}
