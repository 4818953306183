import { Entity } from 'core/entity';
import { CardStoreBenefit } from 'core/entity/card/store-benefit';
import { List } from 'immutable';

export class CardCategoryBenefit extends Entity {
    category: string;
    storeBenefits: List<CardStoreBenefit>;

    constructor(
        category: string,
        storeBenefits: List<CardStoreBenefit>
    ) {
        super();
        this.category = category;
        this.storeBenefits = storeBenefits;
    }
}
