import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class AmountPeriodSnapshot extends Entity {
    amount: Amount;
    baseDatetime: Date;
    startDatetime: Date;
    endDatetime: Date;

    constructor(
        amount: Amount,
        baseDatetime: Date,
        startDatetime: Date,
        endDatetime: Date
    ) {
        super();
        this.amount = amount;
        this.baseDatetime = baseDatetime;
        this.startDatetime = startDatetime;
        this.endDatetime = endDatetime;
    }
}
