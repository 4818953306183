import { NetworkResponseMapper } from 'data/http/mapper';
import { PromotedContentSet } from 'core/entity/promoted-content-set';
import { ContentTagSummaryMapper } from 'data/http/mapper/content/tag/summary';
import { List } from 'immutable';
import { ContentSummaryMapper } from 'data/http/mapper/content/summary';

export class PromotedContentSetMapper implements NetworkResponseMapper<PromotedContentSet> {
    fromJson(json: any): PromotedContentSet {
        return new PromotedContentSet(
            new ContentTagSummaryMapper().fromJson(json.tag),
            List(json.contents.map((content: any) =>
                new ContentSummaryMapper().fromJson(content)
            ))
        )
    }

}
