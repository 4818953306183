import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardPremiumVoucher } from 'core/entity/card/premium-voucher';

export class CardPremiumVoucherGroup extends Entity {
    numberOfVoucher: number;
    numberOfSelect: number;
    selectType: CardPremiumVoucherGroup.Type;
    vouchers: List<CardPremiumVoucher>;

    constructor(
        numberOfVoucher: number,
        numberOfSelect: number,
        selectType: CardPremiumVoucherGroup.Type,
        vouchers: List<CardPremiumVoucher>
    ) {
        super();
        this.numberOfVoucher = numberOfVoucher;
        this.numberOfSelect = numberOfSelect;
        this.selectType = selectType;
        this.vouchers = vouchers;
    }
}

export module CardPremiumVoucherGroup {
    export enum Type {
        SELECTIVE,
        OPTIONAL,
        DEFAULT
    }
}
