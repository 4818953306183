import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, optional, toDateString } from 'utils/index';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { UserStockUpdateSpec } from 'core/entity/personal-finance/stock/update-spec';

export class UserStockUpdateSpecMapper implements NetworkRequestMapper<UserStockUpdateSpec> {
    toJson(target: UserStockUpdateSpec): any {
        return compactObject({
            securities_firm_id: optional(target.securitiesFirm, it => it.id),
            nickname: optional(target.nickname),
            description: optional(target.description),
            average_purchase_amount: optional(target.averagePurchaseAmount),
            quantity: optional(target.quantity),
            commission: optional(target.commission),
            tax: optional(target.tax),
            purchased_at: optional(target.purchasedAt, it => toDateString(it, '-')),
            hidden: optional(target.hidden),
            deleted_status: optional(target.deletedStatus, new DeletedStatusMapper().toJson)
        })
    }
}
