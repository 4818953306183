import React from 'react';
import { List } from 'immutable';

import { Alert } from 'presentation/components/alert';
import { LoanOccupationalGroup } from 'core/entity/loan/occupational-group';
import { FetchState } from 'presentation/view-model/fetch-state';
import { emptyList } from 'utils/index';
import { Loader } from 'presentation/components/loader';
import { FetchError } from 'presentation/components/fetch-state/error';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    active?: LoanOccupationalGroup
    onAccept: (occupation: LoanOccupationalGroup) => void,
    onCancel: () => void
}

interface State {
    fetchState: FetchState;
    occupations: List<LoanOccupationalGroup>;
    occupation?: LoanOccupationalGroup;
}

export class CreditLoanOccupationsAlert extends React.Component<Props, State> {
    state = {
        fetchState: FetchState.FETCHING,
        occupations: emptyList(),
        occupation: this.props.active || null as LoanOccupationalGroup
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { onAccept, onCancel } = this.props;
        const { occupations, occupation, fetchState } = this.state;

        return (
            <Alert
                title="직업을 선택해주세요"
                acceptTitle="확인"
                cancelTitle="취소"
                onAccept={ this.onSelectedOccupation(onAccept, occupation) }
                onCancel={ onCancel }
            >
                {
                    fetchState === FetchState.FETCHED ? (
                        <div>
                            <p className={ styles.desc }>특정직업군에 최적화된 대출상품을 보여드립니다</p>
                            <ul className={ styles.list }>
                                { occupations.map((item, i) => this.renderItem(item, i)) }
                            </ul>
                        </div>
                    ) : fetchState === FetchState.FETCHING ? (
                        <Loader padding={ 80 } radius={ 25 } />
                    ) : (
                        <FetchError>
                            데이터를 불러오는 중 에러가 발생했습니다.<br />
                            잠시 후 다시 시도해주세요
                        </FetchError>
                    )
                }
            </Alert>
        )
    }

    private renderItem = (
        target: LoanOccupationalGroup,
        index: number
    ) => {
        const { occupation } = this.state;
        const id = `occupations-${index}`;
        return (
            <li key={ id } className={ styles.item }>
                <input
                    type="checkbox"
                    id={ id }
                    checked={ !!occupation && target.id === occupation.id }
                    onChange={ this.onChange(target) }
                />
                <label htmlFor={ id }>{ target.name }</label>
            </li>
        )
    };

    private onSelectedOccupation = (
        onAccept: (occupation: LoanOccupationalGroup) => void,
        occupation: LoanOccupationalGroup
    ) => () => {
        sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_QUESTIONS.OCCUPATION, GA_ACTION.RADIO, occupation.name, 1);
        onAccept(occupation);
    };

    private onChange = (occupation: LoanOccupationalGroup) => () =>
        this.setState({ occupation });

    private fetch = () => {
        Application.useCases.getLoanSurveyConfiguration
            .runOnAnimateFrame()
            .subscribe(
                config =>
                    this.setState({
                        fetchState: FetchState.FETCHED,
                        occupations: config.occupationalGroups
                    })
            )
            .unsubscribeBy(this.subscriptionBag)
    }
}
