import React, { TouchEvent } from 'react';
import { ContentCardNews } from 'core/entity/content/card-news';

import styles from './styles.pcss';
const MIN_MOVEMENT = 50;

interface Props {
    content: ContentCardNews
}

interface State {
    page: number;
    targetPage: number;
    mouseX: number;
    moving: boolean;
}

export class ContentCardNewsViewer extends React.Component<Props, State> {
    state = {
        page: 0,
        targetPage: 0,
        mouseX: 0,
        moving: false
    };

    private startX = -1;
    private frame: number;

    componentDidMount() {
        this.frame = window.requestAnimationFrame(this.applyInertia);
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.frame);
    }

    render() {
        const { content } = this.props;
        const { page, mouseX } = this.state;

        return (
            <div
                className={ styles.wrap }
                onTouchStart={ this.onTouchStart }
                onTouchMove={ this.onTouchMove }
                onTouchEnd={ this.onTouchEnd }
            >
                <ul
                    className={ styles.list }
                    style={{ transform: `translateX(calc(${page * -100}% + ${mouseX}px))` }}
                >
                    {
                        content.fragments.map((fragment, i) =>
                            <li key={ `card-news-${i}` }>
                                <img
                                    src={ fragment.imageUrl }
                                    alt={ fragment.alternative }
                                />
                            </li>
                        )
                    }
                </ul>
                { page > 0 && <button className={ styles.leftArrow } onClick={ this.onPrev }>이전으로</button> }
                { page < content.fragments.size - 1 && <button className={ styles.rightArrow } onClick={ this.onNext }>다음으로</button> }
            </div>
        )
    }

    private onPrev = () =>
        this.setState({
            targetPage: this.validPage(this.state.targetPage - 1)
        });

    private onNext = () =>
        this.setState({
            targetPage: this.validPage(this.state.targetPage + 1)
        });

    private onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
        this.startX = e.touches[0].clientX;
        this.setState({ moving: true });
    };

    private onTouchMove = (e: TouchEvent<HTMLDivElement>) =>
        this.setState({ mouseX: e.touches[0].clientX - this.startX });

    private onTouchEnd = (e: TouchEvent<HTMLDivElement>) => {
        const movement = this.state.mouseX;
        const direction = -1 * movement / Math.abs(movement);

        this.setState({
            targetPage: Math.abs(movement) > MIN_MOVEMENT ?
                this.validPage(this.state.targetPage + direction) :
                this.state.targetPage,
            moving: false
        }, () => window.requestAnimationFrame(this.applyInertia))
    };

    private validPage = (page: number) =>
        Math.min(Math.max(page, 0), this.props.content.fragments.size - 1);

    private applyInertia = () => {
        const { targetPage, page, moving, mouseX } = this.state;
        let resultMouseX = mouseX;
        let resultPage = page;

        if (moving) return;

        if (Math.abs(mouseX) < 0.1) {
            resultMouseX = 0;
        } else if (Math.abs(mouseX) > 0) {
            resultMouseX = mouseX + (0 - mouseX) / 8;
        }

        if (Math.abs(targetPage - page) < 0.005) {
            resultPage = targetPage;
        } else if (Math.abs(targetPage - page) > 0) {
            resultPage = page + (targetPage - page) / 8;
        }

        this.setState({
            mouseX: resultMouseX,
            page: resultPage
        });

        window.requestAnimationFrame(this.applyInertia);
    }
}
