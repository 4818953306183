import { Entity } from 'core/entity';

export class SpiderData extends Entity {
    constructor(
        public name: string,
        public responseContained: boolean = null
    ) {
        super();
    }
}
