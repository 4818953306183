import { isProd } from 'presentation/module/extension';

const { IGNORED_LEGAL_SERVICE, COMMIT_SHA } = process.env;
const ignoredLegalService = IGNORED_LEGAL_SERVICE ?
  IGNORED_LEGAL_SERVICE.toLowerCase() === 'true' : false;


export namespace Config {
    export const sentry = {
      dsn: isProd() ? 'https://36278913ab2048a09888f08f6e3a5e72@sentry.io/131609' : '',
      release: COMMIT_SHA,
      environment: isProd() ? 'production' : 'staging',
      debug: !isProd(),
    };

    export const hosts = {
      gateway: window.gatewayHost,
      base: window.apiHost,
      cdn: isProd() ? 'https://cdn.banksalad.com' : 'https://cdn.banksalad.com/dev',
      zendesk: isProd() ? 'https://banksalad.zendesk.com' : 'https://banksalad1543548419.zendesk.com',
    };
    export const oauth = {
      facebookAppId: '250069005407221',
    };
    export const tracker = {
      kakaoPixelId: '2074218455202443754',
    };
    export const APIKeys = {
      recaptcha: '6LfLbKEUAAAAAGSUrODK8wNnf4Skrbp-Sm-SHVNz',
    };
    export const options = {
      ignoredLegalService,
    };
    export const axios = {
      timeout: 10000,
      headers: {
        'Banksalad-Application-Name': 'banksalad2:Web',
        'Banksalad-Caller': 'banksalad-web',
        'Accept': 'application/json',
        'Pragma': 'no-cache',
      },
    };
}
