import { List } from 'immutable';
import { PersonalizedFinanceKCBLoan } from 'core/entity/personalized-finance/loan/kcb';
import { toCurrency } from 'presentation/module/sugar';

declare module 'core/entity/personalized-finance/loan/kcb' {
    export interface PersonalizedFinanceKCBLoan {
        toBalanceString(): string;
    }
}

const toBalanceString = (figure: number) => {
    const units = List.of(
        { value: 1, text: '원' },
        { value: 10000, text: '만원' },
        { value: 100000000, text: '억원' }
    );
    const unit = units.findLast(it => figure >= it.value);
    const value = unit ? Number((figure / unit.value).toFixed(1)) : figure;

    return `${toCurrency(value)}${unit ? unit.text : '원'}`;
};

PersonalizedFinanceKCBLoan.prototype.toBalanceString = function () {
    return toBalanceString(this.balance);
};
