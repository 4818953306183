import React from 'react';

import { LoanSummary } from 'core/entity/loan/summary';
import { isEndToday, isExpiredTime } from 'presentation/module/sugar';
import { optional, toDateString, toTimeString } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    loan: LoanSummary
}

interface State {
    timeStamp: Date;
}

export class CreditLoansPromotionProfitsBanner extends React.Component<Props, State> {
    state = {
        timeStamp: new Date()
    };

    private interval: number;
    private isEndToday = isEndToday(new Date(), optional(this.props.loan.interestPromotion.endDate, it => it));

    componentDidMount() {
        if (this.isEndToday)
            this.interval = window.setInterval(() => this.setState({ timeStamp: new Date() }), 1000);
    }

    componentWillUnmount() {
        if (this.isEndToday)
            clearInterval(this.interval);
    }

    render() {
        const {
            interestPromotion
        } = this.props.loan;
        const now = new Date();

        return (
            <div className={ styles.promotion }>
                {
                    isExpiredTime(optional(interestPromotion.endDate, it => it)) ?
                        <div className={ styles.promotionDate }>
                            <span className={ styles.alertEndDate }>마감되었습니다</span>
                        </div> :
                        this.isEndToday ?
                            <div className={ styles.alertPromotionDate }>
                                <span className={ styles.promotionTitle }>이벤트 진행중!</span>
                                <span className={ styles.alertEndDate }>{ `${toTimeString(interestPromotion.endDate.getTime() - now.getTime(), ':')}남음` }</span>
                            </div> :
                            <div className={ styles.promotionDate }>
                                <span className={ styles.promotionTitle }>이벤트 진행중!</span>
                                { optional(interestPromotion.endDate, it => <span className={ styles.endDate }>{ `${toDateString(it)}까지` }</span>) }
                            </div>
                }
                <div className={ styles.promotionDescription }>
                    { `뱅크샐러드 제휴코드 ${interestPromotion.code} 입력하고 ${interestPromotion.description}` }
                </div>
            </div>
        )
    }
}
