import { Service } from 'core/service';
import { TransactionCategoryRepositoryType } from 'core/repository/personal-finance/transaction-category';
import { Observable } from 'rxjs/Observable';
import { TransactionIcon } from 'core/entity/personal-finance/transaction/category-icon';
import { List } from 'immutable';
import { TransactionCategoryUpdateSpec } from 'core/entity/personal-finance/transaction/category/update-spec';
import { TransactionCategoryCreateSpec } from 'core/entity/personal-finance/transaction/category/create-spec';
import { TransactionCategoryUpdatePrioritySpec } from 'core/entity/personal-finance/transaction/category/update-priority-spec';
import { TransactionCategory } from 'core/entity/personal-finance/transaction/category';

export class UserTransactionCategoryService implements Service {
    private repository: TransactionCategoryRepositoryType;

    constructor(repository: TransactionCategoryRepositoryType) {
        this.repository = repository;
    }

    getIcons = (): Observable<List<TransactionIcon>> => (
        this.repository.getIcons()
    );

    update = (id: string, spec: TransactionCategoryUpdateSpec): Observable<TransactionCategory> => (
        this.repository.update(id, spec)
    );

    updateCategoriesPriority = (spec: List<TransactionCategoryUpdatePrioritySpec>): Observable<List<TransactionCategory>> => (
        this.repository.updateCategoriesPriority(spec)
    );

    create = (spec: TransactionCategoryCreateSpec): Observable<List<TransactionCategory>> => (
        this.repository.create(spec)
    );

    delete = (id: string): Observable<TransactionCategory> => (
        this.repository.delete(id)
    );
}
