import { CardCompanyLegalConfiguration } from 'core/entity/legal-configuration/card-company';
import { CardCompanyLegalConfigurationRepositoryType } from 'core/repository/card-company-legal-configuration';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class GetCardCompanyLegalConfigByCompanyId extends UseCase<CardCompanyLegalConfiguration> {
    cardCompanyId: number;
    private repository: CardCompanyLegalConfigurationRepositoryType;

    constructor(repository: CardCompanyLegalConfigurationRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<CardCompanyLegalConfiguration> {
        return this.repository.getByCardCompanyId(this.cardCompanyId);
    }

    protected validate(): boolean {
        return !!this.cardCompanyId;
    }
}
