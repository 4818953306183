import { LoanCFAReview } from 'core/entity/legal-configuration/loan/cfa/review';
import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanCFAReviewInProgressMapper } from 'data/http/mapper/legal-configuration/loan/cfa/review/in-progress';
import { LoanCFAReviewNoReviewRequiredMapper } from 'data/http/mapper/legal-configuration/loan/cfa/review/no-review-required';
import { LoanCFAReviewReviewedMapper } from 'data/http/mapper/legal-configuration/loan/cfa/review/reviewed';

export class LoanCFAReviewMapper implements NetworkResponseMapper<LoanCFAReview> {
    fromJson(json: any): LoanCFAReview {
        switch (json.status) {
            case 'in-progress':
                return new LoanCFAReviewInProgressMapper().fromJson(json);
            case 'no-review-required':
                return new LoanCFAReviewNoReviewRequiredMapper().fromJson(json);
            case 'reviewed':
                return new LoanCFAReviewReviewedMapper().fromJson(json);
            default:
                return null;
        }
    }

}
