import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { CardLegalConfigurationRepositoryType } from 'core/repository/card-legal-configuration';
import { CardLegalConfigurationApiProvider } from 'data/http/api/gateway/v3/cards/legal-configuration';
import { Observable } from 'rxjs/Rx';

export class CardLegalConfigurationRepository implements CardLegalConfigurationRepositoryType {
    private api: CardLegalConfigurationApiProvider;

    constructor(api: CardLegalConfigurationApiProvider) {
        this.api = api;
    }

    getByCardId(id: number): Observable<CardLegalConfiguration> {
        return this.api.getByCardId(id);
    }

}
