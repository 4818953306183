import { Entity } from 'core/entity';

export class CreditTip extends Entity {
    contentId: string;
    title: string;
    url: string;
    imageUrl: string;

    constructor(
        contentId: string,
        title: string,
        url: string,
        imageUrl: string
    ) {
        super();
        this.contentId = contentId;
        this.title = title;
        this.url = url;
        this.imageUrl = imageUrl;
    }
}
