import { UseCase } from 'core/use-case';
import { StoreRepositoryType } from 'core/repository/store';
import { Store } from 'core/entity/store';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class SearchStores extends UseCase<List<Store>> {
    query: string = '';
    private repository: StoreRepositoryType;

    constructor(repository: StoreRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<Store>> {
        return this.repository.search(this.query);
    }

    protected validate(): boolean {
        return true;
    }
}
