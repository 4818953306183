import React from 'react';

import styles from './styles.pcss';

interface Props {
    children: any;
}

export const HorizontalItem = ({ children }: Props) => (
  <div className={ styles.wrap }>
    { children }
  </div>
);
