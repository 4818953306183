import { NetworkResponseMapper } from 'data/http/mapper';
import { CardMileage } from 'core/entity/card/mileage';
import { lets } from 'utils/index';

export class CardMileageMapper implements NetworkResponseMapper<CardMileage> {
    fromJson(json: any): CardMileage {
        return new CardMileage(
            lets(json.company, it => {
                switch (it) {
                    case 'KA':
                        return CardMileage.Company.KA;
                    case 'AA':
                        return CardMileage.Company.AA;
                    case 'OTHERS':
                        return CardMileage.Company.OTHERS;
                    default:
                        return CardMileage.Company.OTHERS;
                }
            }),
            json.amount
        )
    }

}
