import { NetworkRequestMapper } from 'data/http/mapper';
import { TransactionCategoryUpdatePrioritySpec } from 'core/entity/personal-finance/transaction/category/update-priority-spec';
import { List } from 'immutable';

export class TransactionCategoryUpdatePrioritySpecMapper implements NetworkRequestMapper<List<TransactionCategoryUpdatePrioritySpec>> {
    toJson(target: List<TransactionCategoryUpdatePrioritySpec>): any {
        return {
            categories: target.map(spec => ({
                id: spec.id,
                priority: spec.priority
            }))
        };
    }
}
