import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceNetAssetAmount } from 'core/entity/personal-finance/advice/net-asset-amount';
import { AmountMapper } from 'data/http/mapper/amount';
import { parseDate } from 'utils/index';

export class AdviceNetAssetAmountMapper implements NetworkResponseMapper<AdviceNetAssetAmount> {
    fromJson(json: any): AdviceNetAssetAmount {
        return new AdviceNetAssetAmount(
            new AmountMapper().fromJson(json.net_asset_valuation),
            new AmountMapper().fromJson(json.asset_valuation),
            new AmountMapper().fromJson(json.debt_amount),
            parseDate(json.base_datetime),
            parseDate(json.start_datetime),
            parseDate(json.end_datetime)
        )
    }
}
