import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceContent } from 'core/entity/personal-finance/advice/content';
import { AdviceContentDataMapper } from 'data/http/mapper/personal-finance/advice/content-data';

export class AdviceContentMapper implements NetworkResponseMapper<AdviceContent> {
    fromJson(json: any): AdviceContent {
        return new AdviceContent(
            json.priority,
            new AdviceContentDataMapper().fromJson(json.data)
        )
    }
}
