import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';
import { LoanRecommendResult } from 'core/entity/loan/recommend-result';
import { apply, emptyList } from 'utils/index';
import { List } from 'immutable';

export class ComputeLoanValues extends UseCase<LoanRecommendResult> {
    slug: string;
    primeInterestNodeIds: List<number> = emptyList();
    private repository: LoanRecommendRepositoryType;

    constructor(repository: LoanRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<LoanRecommendResult> {
        return Observable.zip(
            this.repository.getCachedSpec(),
            this.repository.getCachedFilter()
        ).flatMap(result =>
            this.repository.compute(
                this.slug,
                apply(result[0], it => {
                    it.primeInterestNodeIds = this.primeInterestNodeIds;
                }),
                result[1]
            )
        );
    }

    protected validate(): boolean {
        return !!this.slug;
    }

}
