import { NetworkResponseMapper } from 'data/http/mapper';
import { SVG } from 'core/entity/svg';

export class SVGMapper implements NetworkResponseMapper<SVG> {
    fromJson(json: any): SVG {
        return new SVG(
            json
        )
    }
}
