import React from 'react';
import { List } from 'immutable';

import { FlexibleDropDown } from 'presentation/components/flexible-drop-down';
import { CreditLoanScoreLevel } from 'presentation/components/survey-form/credit-loan-score-level/model';
import { optional } from 'utils/index';
import { GA_ACTION, GA_CATEGORY, GA_EVENT_VALUE, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    data: CreditLoanScoreLevel;
    onChange: (result: CreditLoanScoreLevel) => void
}

const CREDIT_LEVELS_PRESET = List([
    { level: 1, text: '1등급' },
    { level: 2, text: '2등급' },
    { level: 3, text: '3등급' },
    { level: 4, text: '4등급' },
    { level: 5, text: '5등급' },
    { level: 6, text: '6등급' },
    { level: 7, text: '7등급' },
    { level: 8, text: '8등급' },
    { level: 9, text: '9등급' },
    { level: 10, text: '10등급' }
]);

export const CreditLoanScoreLevelForm = (props: Props) => {
    const onChange = (value: any) => {
        const level = new CreditLoanScoreLevel(value.level);

        sendGAEvent(
            GA_DOMAIN.LOANS,
            GA_CATEGORY.LOANS_QUESTIONS.CREDIT_SCORE,
            GA_ACTION.DROP_DOWN,
            level.toValue(),
            GA_EVENT_VALUE.CLOSE
        );
        props.onChange(level);
    };

    return (
        <FlexibleDropDown
            value={ optional(props.data, it => CREDIT_LEVELS_PRESET.find(set => set.level === it.value)) }
            data={ CREDIT_LEVELS_PRESET }
            onChange={ onChange }
            onRenderActive={ value => <span className={ value ? styles.active : styles.inactive }>{ value ? value.text : '신용등급 선택' }</span> }
            onRenderItem={ value => <span className={ styles.item }>{ value.text }</span> }
        />
    );
};
