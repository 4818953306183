import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { EventBackgroundImageView } from 'presentation/components/background-image'
import { FetchState } from 'presentation/view-model/fetch-state';
import { Loader } from 'presentation/components/loader'
import { generateOneLink } from '../../setUrl';

import styles from './styles.pcss';
const BACKGROUND_IMAGE = 'https://cdn.banksalad.com/app/promotion/switch-insurance/bg.png';
const OUT_LINK_URL = 'https://cdn.banksalad.com/app/terms/switch-travel/banksalad_switch_insurance.pdf';
const BASE_URL = 'https://go.onelink.me/xMM7/a551b995';
const CID_POSTFIX = 'travelswinsu';
const CID_DATE = '190924';

interface State {
    fetchState: FetchState;
}

export class EventSwitchInsurance extends React.Component<RouteComponentProps<any>, State> {
    state = {
        fetchState : FetchState.FETCHING
    };

    render () {
        const {
            fetchState
        } = this.state;

        return (
            <section className={ styles.wrap }>
                {
                    fetchState === FetchState.FETCHING &&
                    this.renderLoader()
                }
                { this.renderView() }
            </section>
        );
    }

    private renderLoader = () => (
        <section className={ styles.loader }>
            <Loader padding={ 0 } radius={ 20 }/>
        </section>
    );

    private renderView = () => {
        const issueURL = generateOneLink(
            BASE_URL,
            CID_POSTFIX,
            CID_DATE
        );

        return (
            <div className={styles.content}>
                <EventBackgroundImageView
                    imageUrl={ BACKGROUND_IMAGE }
                    onLoad={ this.onLoadImage }
                />
                <a
                    href={ issueURL }
                    target="_blank"
                    className={ styles.issueLink }
                >
                    <span className="blind">
                        지금 다운로드
                    </span>
                </a>
                <a
                    href={ OUT_LINK_URL }
                    target="_blank"
                    className={ styles.link }
                >
                    <span className="blind">
                        모든 보장 보러가기
                    </span>
                </a>
            </div>
        )
    };

    private onLoadImage = () => (
        this.setState({
            fetchState: FetchState.FETCHED
        })
    );
}
