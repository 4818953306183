import { NetworkResponseMapper } from 'data/http/mapper';
import { AccessibleUser } from 'core/entity/accessible-user';
import { UserMapper } from 'data/http/mapper/user';
import { AccessTokenMapper } from 'data/http/mapper/user/access-token';

export class AccessibleUserMapper implements NetworkResponseMapper<AccessibleUser> {
    fromJson(json: any): AccessibleUser {
        return new AccessibleUser(
            new UserMapper().fromJson(json.user),
            new AccessTokenMapper().fromJson(json.token)
        )
    }

}
