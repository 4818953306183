import { Entity } from 'core/entity';

export class AdviceInstallmentConfigurationSnapshot extends Entity {
    round: number;
    month: number;

    constructor(
        round: number,
        month: number
    ) {
        super();
        this.round = round;
        this.month = month;
    }
}
