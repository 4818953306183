import { UserFinancialDataRepositoryType } from 'src/core/repository/personal-finance/financial-data';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { UserFinancialDataAssetSummary } from 'src/core/entity/personal-finance/financial-data/summary';
import { AccessTokenStorageProvider } from 'src/data/browser-storage/api/access-token';
import { UserFinancialDataAssetSummariesApiProvider } from 'src/data/http/api/gateway/v2/users/financial-data/asset-summaries';

export class UserFinancialDataRepository implements UserFinancialDataRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private assetSummariesApi: UserFinancialDataAssetSummariesApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        summariesApi: UserFinancialDataAssetSummariesApiProvider
    ) {
        this.assetSummariesApi = summariesApi;
        this.tokenStorage = tokenStorage;
    }

    getAssetSummaries = (
        startDate: Date,
        endDate: Date
    ): Observable<List<UserFinancialDataAssetSummary>> => (
        this.tokenStorage.get().flatMap(token => this.assetSummariesApi.get(token, startDate, endDate))
    );
}
