import { Entity } from 'core/entity';
import { List } from 'immutable';

export class ContentMetaInformation extends Entity {
    views: number;
    title: string;
    description: string;
    keywords: List<string>;

    constructor(
        views: number,
        title: string,
        description: string,
        keywords: List<string>
    ) {
        super();
        this.views = views;
        this.title = title;
        this.description = description;
        this.keywords = keywords;
    }
}
