import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardBenefitGroup } from 'core/entity/card/benefit-group';
import { CardCategoryBenefitMapper } from 'data/http/mapper/card/category-benefit';
import { CardCategorySpecialBenefitMapper } from 'data/http/mapper/card/category-special-benefit';
import { apply } from 'utils/index';

export class CardBenefitGroupMapper implements NetworkResponseMapper<CardBenefitGroup> {
    fromJson(json: any): CardBenefitGroup {
        return apply(new CardBenefitGroup(
            List(json.category_benefits.map((benefit: any) =>
                new CardCategoryBenefitMapper().fromJson(benefit)
            )),
            List(json.category_special_benefits.map((benefit: any) =>
                new CardCategorySpecialBenefitMapper().fromJson(benefit)
            )),
            json.additional_annual_cost
        ), it => {
            it.title = json.title;
        })
    }
}
