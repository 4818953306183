import { Observable } from 'rxjs/Rx';
import { UserRepositoryType } from 'core/repository/user';
import { User } from 'core/entity/user';
import { UserUpdateSpec } from 'core/entity/user/update-spec';
import { UserDropSpec } from 'core/entity/user/drop-spec';
import { UserCreateSpec } from 'core/entity/user/create-spec';
import { UsersApiProvider } from 'data/http/api/gateway/v2/users';
import { apply } from 'utils/index';
import { UserSessionApiProvider } from 'data/http/api/gateway/v2/users/session';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { MobileAuthenticationCodeSpec } from 'core/entity/user/mobile-authenticate-code-spec';
import { MobileAuthenticationCode } from 'core/entity/user/mobile-authenticate-code';
import { MobileAuthenticationSpec } from 'core/entity/user/mobile-authenticate-spec';
import { AccessToken } from 'core/entity/user/access-token';
import { EmailValidate } from 'core/entity/email/validate';
import { SuspendAccountSpec } from 'core/entity/user/suspend-account-spec';

export class UserRepository implements UserRepositoryType {
    private userApi: UsersApiProvider;
    private sessionApi: UserSessionApiProvider;
    private tokenStorage: AccessTokenStorageProvider;
    private facebookClientId: string;

    constructor(
        api: UsersApiProvider,
        sessionApi: UserSessionApiProvider,
        tokenStorage: AccessTokenStorageProvider,
        facebookClientId: string
    ) {
        this.userApi = api;
        this.sessionApi = sessionApi;
        this.tokenStorage = tokenStorage;
        this.facebookClientId = facebookClientId;
    }

    get(): Observable<User> {
        return this.tokenStorage.get().flatMap(token =>
            this.userApi.get(token)
        );
    }

    update(spec: UserUpdateSpec): Observable<User> {
        return this.tokenStorage.get().flatMap(token =>
            this.userApi.update(token, spec)
        )
    }

    requestMobileAuthenticationCodeOnLogin(
        spec: MobileAuthenticationCodeSpec
    ): Observable<MobileAuthenticationCode> {
        return this.userApi.requestMobileAuthenticationCodeOnLogin(spec);
    }

    requestMobileAuthenticationCodeOnSignUp(
        spec: MobileAuthenticationCodeSpec
    ): Observable<MobileAuthenticationCode> {
        return this.tokenStorage.get().flatMap((token: AccessToken) =>
            this.userApi.requestMobileAuthenticationCodeOnSignUp(token, spec)
        );
    }

    drop(spec: UserDropSpec): Observable<void> {
        return this.tokenStorage.get().flatMap(token =>
            this.userApi.drop(token, spec)
        ).flatMap(() =>
            this.tokenStorage.clear()
        );
    }

    signInWithEmail(email: string, password: string, kept: boolean): Observable<void> {
        return this.userApi.signInWithEmail(email, password)
            .flatMap(token =>
                this.tokenStorage.set(token, !kept)
            );
    }

    signUpWithEmail(spec: UserCreateSpec): Observable<void> {
        return this.userApi.signUpWithEmail(spec)
            .flatMap(user =>
                this.tokenStorage.set(user.token, true)
            );
    }

    signUpWithFacebook(token: string): Observable<void> {
        return this.userApi.signUpWithFacebook(token, this.facebookClientId)
            .flatMap(token =>
                this.tokenStorage.set(token, true)
            )
    }

    signInWithFacebook(token: string): Observable<void> {
        return this.userApi.signInWithFacebook(token, this.facebookClientId)
            .flatMap(token =>
                this.tokenStorage.set(token, true)
            )
    }

    signUpWithGoogle(token: string): Observable<void> {
        return this.userApi.signUpWithGoogle(token)
            .flatMap(token =>
                this.tokenStorage.set(token, true)
            )
    }

    signInWithGoogle(token: string): Observable<void> {
        return this.userApi.signInWithGoogle(token)
            .flatMap(token =>
                this.tokenStorage.set(token, true)
            )
    }

    signUpWithKakao(token: string): Observable<void> {
        return this.userApi.signUpWithKakao(token)
            .flatMap(token =>
                this.tokenStorage.set(token, true)
            )
    }

    signInWithKakao(token: string): Observable<void> {
        return this.userApi.signInWithKakao(token)
            .flatMap(token =>
                this.tokenStorage.set(token, true)
            )
    }

    signInWithMobileAuthentication(spec: MobileAuthenticationSpec): Observable<void> {
        return this.userApi.signInWithMobileAuthentication(spec)
            .flatMap(token =>
                this.tokenStorage.set(token, true)
            );
    }

    smsCodeVerify(spec: MobileAuthenticationSpec): Observable<void> {
        return this.tokenStorage.get()
            .flatMap((token: AccessToken) =>
                this.userApi.smsCodeVerify(token, spec)
            );
    }

    signOut(): Observable<void> {
        return this.tokenStorage.get().flatMap(token =>
            this.userApi.signOut(token)
        ).flatMap(() =>
            this.tokenStorage.clear()
        );
    }

    refreshPassword(email: string): Observable<void> {
        return this.userApi.refreshPassword(email);
    }

    validateToken(): Observable<Date> {
        return this.tokenStorage.get()
            .flatMap(token =>
                Observable.zip(
                    Observable.of(token),
                    this.sessionApi.get(token)
                )
            )
            .map(([token]) =>
                token.expiresIn
            )
            .catch(() =>
                this.tokenStorage.clear()
                    .flatMap(() =>
                        Observable.throw(new Error('로그인 토큰이 만료되었습니다'))
                    )
            );
    }

    refreshToken(): Observable<void> {
        return this.tokenStorage.get().flatMap(token =>
            Observable.zip(
                Observable.of(token),
                this.sessionApi.put(token)
            )
        ).flatMap(([token, refreshToken]) =>
            this.tokenStorage.set(
                apply(token, it => {
                    it.accessToken = refreshToken.accessToken;
                    it.refreshToken = refreshToken.refreshToken;
                    it.expiresIn = refreshToken.expiresIn
                })
            )
        ).catch(() =>
            this.tokenStorage.clear()
                .flatMap(() =>
                    Observable.throw(new Error('로그인 토큰이 만료되었습니다'))
                )
        )
    }

    validateEmail(email: string): Observable<EmailValidate> {
        return this.userApi.validateEmail(email);
    };

    suspendAccount(spec: SuspendAccountSpec): Observable<void> {
        return this.userApi.suspendAccount(spec)
    }
}
