import React from 'react';
import { RouteComponentProps } from 'react-router';

import { CreditLoanAmount } from 'presentation/components/survey-form/credit-loan-amount/model';
import { CreditLoansProfitsHeader } from 'presentation/components/loan-recommendation/header';
import { Application } from 'application/context.instance';
import { CreditLoanScoreLevel } from 'presentation/components/survey-form/credit-loan-score-level/model';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { CreditLoanProfitsStateModel } from 'presentation/routes/credit-loans/profits/model';
import { emptyList } from 'utils/index';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { CreditLoanJob } from 'presentation/components/survey-form/credit-loan-job/model';
import { CreditLoanIncome } from 'presentation/components/survey-form/credit-loan-income/model';
import { LoanCreditScoreAgency } from 'core/entity/loan/credit-score-agency';
import { SubscriptionBag } from 'presentation/module/extension';
import { FetchState } from 'presentation/view-model/fetch-state';
import { FetchError } from 'presentation/components/fetch-state/error';
import { CREDIT_LOAN_DEFAULT_FILTER, } from 'presentation/routes/credit-loans/profits/preset';
import { LoanStatistics } from 'core/entity/loan/statistics';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { CreditLoansProfits } from 'presentation/components/loan-recommendation/result';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';

export class CreditLoansProfitsRoute extends React.Component<RouteComponentProps<any>, CreditLoanProfitsStateModel> {
    state = {
        fetchState: FetchState.FETCHING,
        statistics: null as LoanStatistics,
        results: emptyList(),
        amount: new CreditLoanAmount(),
        score: new CreditLoanScoreLevel(),
        job: new CreditLoanJob(),
        histories: emptyList(),
        displayHistories: emptyList(),
        income: new CreditLoanIncome(),
        repaymentPeriod: LoanRecommendFilter.RepaymentPeriod.UNDER_ONE_YEAR,
        organizationTypes: CREDIT_LOAN_DEFAULT_FILTER.organizationTypes,
        interestTypes: CREDIT_LOAN_DEFAULT_FILTER.interestTypes,
        repaymentTypes: CREDIT_LOAN_DEFAULT_FILTER.repaymentTypes,
        exemptReimbursementFees: false,
        dayLoan: false,
        inPromotion: false,
        minusAccount: CREDIT_LOAN_DEFAULT_FILTER.minusAccount,
        sorter: LoanRecommendFilter.Sort.MINIMUM_INTEREST
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        this.fetchPresentationalContents();
    }

    render() {
        const {
            fetchState,
            results,
        } = this.state;

        return (
            <div>
                <MetaHelmet meta={ META_SET.CREDIT_LOAN_BEST }/>
                <CreditLoansProfitsHeader
                    profitsState={ this.state }
                    onChange={ this.onChange }
                />
                {
                    fetchState === FetchState.ERROR ?
                        <FetchError padding={ 250 }>
                            대출정보를 불러오는데 문제가 발생했습니다<br/>
                            잠시 후 새로고침 해주세요
                        </FetchError> :
                        <CreditLoansProfits
                            profitsState={ this.state }
                            results={ results }
                            fetchState={ fetchState }
                            onChange={ this.onChange }
                        />
                }
            </div>
        )
    }

    private fetchPresentationalContents = () =>
        Application.services.loan
            .getProfitsPresentationalContents()
            .useOnAnimateFrame()
            .subscribe(
                result =>
                    this.setState({
                        displayHistories: result.first,
                        statistics: result.second
                    }, () => this.fetchCachedData()),
                () =>
                    this.setState({
                        fetchState: FetchState.ERROR
                    })
            )
            .unsubscribeBy(this.subscriptionBag);

    private fetchCachedData = () =>
        Application.services.loan
            .getProfitsCachedData()
            .useOnAnimateFrame()
            .subscribe(
                result => {
                    this.setState({
                        amount: new CreditLoanAmount(result.first.desiredAmount),
                        score: new CreditLoanScoreLevel(result.first.creditScore),
                        job: new CreditLoanJob(result.first.occupationalGroup),
                        income: new CreditLoanIncome(result.first.annualIncome),
                        histories: result.first.histories,
                        repaymentPeriod : result.second.repaymentPeriod === null ?
                            CREDIT_LOAN_DEFAULT_FILTER.repaymentPeriod : result.second.repaymentPeriod,
                        organizationTypes : result.second.organizationTypes === null ?
                            CREDIT_LOAN_DEFAULT_FILTER.organizationTypes : result.second.organizationTypes,
                        interestTypes : result.second.interestTypes === null ?
                            CREDIT_LOAN_DEFAULT_FILTER.interestTypes : result.second.interestTypes,
                        repaymentTypes : result.second.repaymentTypes === null ?
                            CREDIT_LOAN_DEFAULT_FILTER.repaymentTypes : result.second.repaymentTypes,
                        exemptReimbursementFees : result.second.exemptReimbursementFees,
                        dayLoan : result.second.dayLoan,
                        minusAccount : result.second.minusAccount === null || result.second.minusAccount,
                        sorter : result.second.sorter === null ?
                            LoanRecommendFilter.Sort.MINIMUM_INTEREST : result.second.sorter,
                        inPromotion: result.second.inPromotion
                    }, this.fetchResults)
                },
                this.fetchResults
            )
            .unsubscribeBy(this.subscriptionBag);

    private fetchResults = async () => {
        const {
            amount,
            score,
            job,
            histories,
            income,
            repaymentPeriod,
            organizationTypes,
            interestTypes,
            repaymentTypes,
            exemptReimbursementFees,
            dayLoan,
            inPromotion,
            minusAccount,
            sorter
        } = this.state;

        await this.setState({
            fetchState: FetchState.FETCHING
        });

        Application.services.loan
            .recommendLoan(
                new LoanRecommendSpec(
                    amount.value,
                    score.value,
                    LoanCreditScoreAgency.KCB, // TODO: 기획 상 KCB로 통일
                    job.value,
                    income.value,
                    histories,
                    emptyList()
                ),
                new LoanRecommendFilter(
                    repaymentPeriod,
                    organizationTypes,
                    interestTypes,
                    repaymentTypes,
                    null,
                    exemptReimbursementFees || null,
                    dayLoan || null,
                    null,
                    minusAccount,
                    inPromotion || null,
                    sorter
                )
            )
            .useOnAnimateFrame()
            .subscribe(
                results => {
                    results.forEach((it, index) =>
                        sendGAEvent(
                            GA_DOMAIN.LOANS,
                            GA_CATEGORY.LOANS_PROFITS.LIST,
                            GA_ACTION.PRODUCT_IMPRESSION,
                            `${it.loan.organization.type.toString()}_${it.loan.organization.name}_${it.loan.slug}_${index + 1}`
                        )
                    );

                    this.setState({
                        results,
                        fetchState: FetchState.FETCHED
                    });
                },
                () =>
                    this.setState({
                        fetchState: FetchState.ERROR
                    })
            )
            .unsubscribeBy(this.subscriptionBag);
    };

    private onChange = (state: CreditLoanProfitsStateModel) =>
        this.setState((prevState) => ({
            ...prevState,
            ...state
        }), this.fetchResults);
}
