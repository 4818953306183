import React from 'react';

import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    right?: number
    bottom?: number,
    gaCategory?: string
}

export const FixedTopElevator = (props: Props) => (
    <div
        className={ styles.fixed }
        style={{
            right: props.right ? `${props.right}px` : '16px',
            bottom: props.bottom ? `${props.bottom}px` : '16px'
        }}
    >
        <button
            onClick={ () => {
                window.scrollTo(0, 0);
                props.gaCategory && sendGAEvent(
                    GA_DOMAIN.NONE,
                    props.gaCategory,
                    GA_ACTION.SCROLL,
                    'TOP'
                )
            } }
            className={ styles.shape }
        >
            맨 위로
        </button>
    </div>
);
