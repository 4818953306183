import React from 'react'

import { generateOneLink } from '../../setUrl';

const baseUrl = 'https://go.onelink.me/xMM7/136c5707';

export class EventCancerMiniInsuranceC extends React.Component {
    componentDidMount() {
        const convertedUrl = generateOneLink(baseUrl,'minicancerinsu','190419', 'case-c');
        location.href = convertedUrl;
    }
    render () {
        return (
            <div>
                {/* explicitly empty issue Num #1758 */}
            </div>
        )
    }
}
