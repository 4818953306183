import React from 'react';

import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardSearchAlert } from 'presentation/components/alert/card-search';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import { apply, lets } from 'utils/index';
import { Loader } from 'presentation/components/loader';
import { toCurrency } from 'presentation/module/sugar';
import { isForDiscount } from 'presentation/module/sugar';
import { CardSummary } from 'core/entity/card/summary';
import { Card } from 'core/entity/card';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_LABEL, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';
import { FetchError } from 'presentation/components/fetch-state/error';

import { FetchEmptyPaddingSize } from '../fetch-state/empty';

import styles from './styles.pcss';

interface Props {
    spec: CardRecommendSpec
}

interface State {
    alert: boolean,
    fetchState: FetchState,
    card: CardSummary,
    benefit: number
}

export class CardCompare extends React.Component<Props, State> {
    state = {
        alert: false,
        fetchState: FetchState.FETCHED,
        card: null as CardSummary,
        benefit: 0
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        this.fetchCache();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { alert, card } = this.state;
        return (
            <div className={ styles.wrap }>
                {
                    card ? (
                        <div className={ styles.button }>
                            <div
                                className={ styles.activeCard }
                                style={{ backgroundImage: `url("${card.imageUrl}")` }}
                            >
                                <a
                                    href={ BANKSALAD_SITEMAP_BY_ID.CARD_PROFITS(card.id) }
                                    onClick={ () => {
                                        sendGAEvent(
                                            GA_DOMAIN.CARD,
                                            GA_CATEGORY.CARDS_PROFITS.COMPARE_CARD,
                                            GA_ACTION.LINK.CARDS_DETAIL_PROFITS,
                                            GA_LABEL.PRODUCT_DETAIL('카드선택', card.companyName, card.id)
                                        )
                                    } }
                                >
                                    카드선택
                                </a>
                            </div>
                            { this.renderCard() }
                        </div>
                    ) : (
                        <button
                            onClick={ () => {
                                this.setState({ alert: true });
                                sendGAEvent(
                                    GA_DOMAIN.CARD,
                                    GA_CATEGORY.CARDS_PROFITS.COMPARE_CARD,
                                    GA_ACTION.POPUP
                                )
                            } }
                            className={ styles.button }
                        >
                            <div className={ styles.card }>카드선택</div>
                            { this.renderCard() }
                        </button>
                    )
                }
                {
                    card && (
                        <button
                            className={ styles.close }
                            onClick={ () => {
                                this.onClear();
                                sendGAEvent(
                                    GA_DOMAIN.CARD,
                                    GA_CATEGORY.CARDS_PROFITS.COMPARE_CARD,
                                    GA_ACTION.CLEAR
                                )
                            } }
                        >
                            비교카드 삭제
                        </button>
                    )
                }
                {
                    alert && (
                        <CardSearchAlert
                            title="지금 쓰고 있는 내 카드의 혜택은?"
                            description="내가 입력한 소비패턴에 따른 내 카드의 혜택 금액을 확인해보세요"
                            onAccept={ card => this.onAccept(card) }
                            onCancel={ () => this.setState({ alert: false }) }
                        />
                    )
                }
            </div>
        );
    }

    private renderCard() {
        const { card, fetchState, benefit } = this.state;

        return card ? lets(fetchState, it => {
            switch (it) {
                case FetchState.FETCHING:
                    return (
                        <div className={ styles.benefit }>
                            <Loader padding={ 3 } radius={ 15 }/>
                        </div>
                    );
                case FetchState.ERROR:
                    return (
                        <div className={ styles.benefit }>
                            <FetchError padding={ FetchEmptyPaddingSize.SMALL } />
                        </div>
                    );
                default:
                    return (
                        <div className={ styles.benefit }>
                            <h5>{ card.name }</h5>
                            <p>{ `월 혜택 ${toCurrency(benefit)}원` }</p>
                        </div>
                    );
            }
        }) : (
            <div className={ styles.desc }>
                <h5>지금 쓰고 있는 내 카드의 혜택은?</h5>
                <p>내 카드 VS 추천카드, 혜택을 비교하세요</p>
            </div>
        )
    }

    private onAccept(card: Card) {
        const { spec } = this.props;
        const summary = new CardSummary(
            card.id,
            card.name,
            card.companyName,
            card.imageUrl
        );

        this.setState({
            fetchState: FetchState.FETCHING,
            alert: false,
            card: summary
        });

        this.cache(summary);

        Application.services.card.computeBenefits(card.id, spec)
            .useOnAnimateFrame()
            .subscribe(
                result =>
                    this.setState({
                        benefit: (
                            isForDiscount(spec.benefitTypes) ?
                                result.summary.discount + result.summary.point - Math.trunc(card.annualCostWithPromotion / 12) :
                                result.summary.mileage
                        ),
                        fetchState: FetchState.FETCHED
                    })
            )
            .unsubscribeBy(this.subscriptionBag);
    }

    private onClear() {
        Application.useCases.clearCachedCardForCompare
            .runOnAnimateFrame()
            .subscribe()
            .unsubscribeBy(this.subscriptionBag);

        this.setState({
            card: null
        });
    }

    private fetchCache() {
        Application.useCases.getCachedCardForCompare
            .runOnAnimateFrame()
            .subscribe(
                card =>
                    this.setState({
                        fetchState: FetchState.FETCHED,
                        alert: false,
                        card
                    }),
                () =>
                    this.setState({
                        fetchState: FetchState.FETCHED,
                        alert: false,
                        card: null
                    })
            )
            .unsubscribeBy(this.subscriptionBag);
    }

    private cache(card: CardSummary) {
        apply(Application.useCases.cacheCardForCompare, it => {
            it.card = card;
        }).runOnAnimateFrame().subscribe().unsubscribeBy(this.subscriptionBag);
    }
}
