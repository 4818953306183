import { Observable } from 'rxjs';
import { List } from 'immutable';
import { PersonalizedFinanceKCBLoan } from 'src/core/entity/personalized-finance/loan/kcb/index';
import { PersonalizedFinanceKCBLoanSpec } from 'core/entity/personalized-finance/loan/kcb/spec';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { PersonalizedFinanceKCBLoanRepositoryType } from 'core/repository/personalized-finance/loan';
import { PersonalizedFinanceLoanApiProvider } from 'data/http/api/gateway/v4/me/loans/kcb-loans';

export class PersonalizedFinanceKCBLoanRepository implements PersonalizedFinanceKCBLoanRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: PersonalizedFinanceLoanApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: PersonalizedFinanceLoanApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    getAll = (): Observable<List<PersonalizedFinanceKCBLoan>> => (
        this.tokenStorage
            .get()
            .flatMap(token => this.api.getAll(token))
    );

    update = (
        id: number,
        spec: PersonalizedFinanceKCBLoanSpec
    ): Observable<List<PersonalizedFinanceKCBLoan>> => (
        this.tokenStorage
            .get()
            .flatMap(token => this.api.update(id, spec, token))
    );
}
