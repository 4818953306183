import Axios from 'axios';
import { Content } from 'core/entity/content';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { ContentMapper } from 'data/http/mapper/content';
import { ContentViewer } from 'presentation/components/content-viewer';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNamespaceProduction } from 'utils/env';

interface State {
    fetchState: FetchState;
    content?: Content;
}

export const CardsThemeRoute = ({ match }: RouteComponentProps<any>) => {
  const id = match.params.id;
  const [{ content, fetchState }, setState] = useState({
    content: null as Content,
    fetchState: FetchState.FETCHING,
  });
  const renderByFetchState = () => {
    switch(fetchState) {
    case FetchState.FETCHING:
      return <Loader padding={ 250 } radius={ 25 } />;
    case FetchState.ERROR:
      return (
        <FetchError padding={ 250 }>
          일시적인 오류로 콘텐츠를 표시할 수 없습니다<br/>
          잠시 후 다시 시도해주세요
        </FetchError>
      );
    default:
      if (!content.published) {
        location.href = BANKSALAD_SITEMAP.PAGE_NOT_FOUND;

        return;
      }

      return(
        <>
          <MetaHelmet meta={ META_SET.CARDS_THEME(content) }/>
          <ContentViewer content={ content } />
          <Footer />
        </>
      );
    }
  };

  useEffect(() => {
    (async () => {
      const API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/contents/${id}.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/contents/${id}.json`;
      
      const { data } = await Axios.get(API_PATH);
      
      setState({
        content: new ContentMapper().fromJson(data.data),
        fetchState: FetchState.FETCHED,
      });
    })();
  }, []);

  return (
    <>
      <Header active={ FinanceSectorID.CARD } />
      { renderByFetchState() }
    </>
  );
};
