import React from 'react';
import { List } from 'immutable';

import { Alert, AlertSize } from 'presentation/components/alert';
import { isMobile } from 'presentation/module/sugar';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, GA_LABEL, sendGAEvent } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';
import { CardIssueContainer } from 'presentation/components/alert/card-issue-container';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { isQRCard, isTouchableMobileDevice } from 'utils/index';

import styles from './styles.pcss';

// TODO: 일부 CMA 발급을 먼저 유도해야 해서 팝업 모양을 바꾸었음. 나중에 정리 필요
const exceptionsForEugene = List.of(3478, 3484);
const exceptionData = {
    companyName: '유진투자증권',
    issueUrl: isMobile() ?
        'http://event-eugenefn.com/event/banksalad/m_pop_new01.asp' :
        'http://event-eugenefn.com/event/banksalad/pop_new01.asp',
    gaCategory: GA_CATEGORY.CARD_ISSUE_ALERT.EUGENE_CMA
};

interface Props {
    cardId: number;
    issueUrlDesktop: string;
    issueUrlMobile?: string;
    companyName: string;
    companyImageUrl: string;
    gaCategory: string;
    onCancel: () => void;
}

export class CardIssueUrlAlert extends React.Component<Props> {
    private subscriptionBag = new SubscriptionBag();
    private issueUrl = isMobile() ? this.props.issueUrlMobile : this.props.issueUrlDesktop;

    componentDidMount() {
        const { cardId, companyName, gaCategory, onCancel } = this.props;
        if (exceptionsForEugene.indexOf(cardId) === -1) {
            sendGAEvent(
                GA_DOMAIN.CARD,
                gaCategory,
                GA_ACTION.ISSUE,
                GA_LABEL.ISSUE('바로이동', companyName, cardId)
            );

            // BC카드, 하나카드인 경우 QR 페이지로 routing
            if (isQRCard(`${cardId}`) && !isTouchableMobileDevice()) {
               window.open(`/cards/card-qr/${cardId}`, '_blank', 'noopener, noreferrer');
            } else {
                window.open(BANKSALAD_SITEMAP_BY_ID.CARD_ISSUE(cardId));
            }
            onCancel();
        }
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const {
            cardId,
            companyImageUrl,
            onCancel
        } = this.props;
        const inException = exceptionsForEugene.contains(cardId);

        return (
            <Alert
                onCancel={ () => onCancel() }
                size={ AlertSize.BIG }
            >
                <CardIssueContainer cardId={ cardId }>
                    {
                        inException &&
                        <div className={ styles.wrap }>
                            <img src={ companyImageUrl } className={ styles.image } />
                            <h5 className={ styles.title }>
                                이 카드는 { exceptionData.companyName } CMA 계좌 발급 후<br/>
                                신청할 수 있는 카드입니다
                            </h5>
                            <div className={ styles.buttons }>
                                <button
                                    onClick={ this.onClickOpenAccount }
                                    className={ styles.samsungAccount }
                                >
                                    계좌 개설하기
                                </button>
                                <button
                                    onClick={ this.onClickIssueCardNow }
                                    className={ styles.samsungCard }
                                >
                                    바로 카드신청
                                </button>
                            </div>
                            <p className={ styles.desc }>
                                - 카드사 및 제휴사의 사정으로 위 내용은 변경·중단 될 수 있습니다.<br/>
                                - 카드 이용 전에 상품설명서, 약관을 통해 이용조건을 확인해 주시기 바랍니다.<br/>
                                - 카드 이용금액 연체 시 21.0 ~ 27.9%의 연체이자율이 적용됩니다.(회원별, 연체기간별, 이용상품별 차등 적용)<br/>
                                - 신용카드남용은 가계경제에 위협이 됩니다.
                            </p>
                        </div>
                    }
                </CardIssueContainer>
            </Alert>
        )
    }

    private onClickOpenAccount = () => {
        this.redirect(exceptionData.issueUrl);
        sendGAEvent(
            GA_DOMAIN.CARD,
            exceptionData.gaCategory,
            GA_ACTION.ISSUE,
            '계좌개설하기'
        );
    };

    private onClickIssueCardNow = () => {
        const {
            cardId,
            companyName
        } = this.props;

        this.redirect(this.issueUrl);
        sendGAEvent(
            GA_DOMAIN.CARD,
            exceptionData.gaCategory,
            GA_ACTION.ISSUE,
            GA_LABEL.ISSUE('바로카드신청', companyName, cardId)
        );
    };

    private redirect = (issueUrl: string, action: () => void = () => {}) => {
        action();
        window.open(issueUrl);
    };
}
