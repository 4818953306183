import { PersonalFinanceMenu } from 'presentation/components/personal-finance/menu';
import { PersonalFinanceMenuType } from 'presentation/components/personal-finance/menu/model';
import { NoticeSuspendPFMService } from 'presentation/routes/me/my-finance/components/notice-suspend-pfm-service';
import React from 'react';

import styles from './styles.pcss';


export const MyFinanceRoute = () => (
  <>
    <PersonalFinanceMenu active={ PersonalFinanceMenuType.MY_FINANCE }/>
    <div className={ styles.wrap }>
      <div className={ styles.background }>
        <section className={ styles.dashboard }>
          <div className={ styles.container }>
            <div className={ styles.notice }>
              <NoticeSuspendPFMService />
            </div>
          </div>
        </section>
      </div>
    </div>
  </>
);
