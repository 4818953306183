import { Store } from 'core/entity/store';
import { StoreMapper } from 'data/http/mapper/store';
import { Page } from 'core/entity/page';
import { PageMapper } from 'data/http/mapper/page';
import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';

export class StoresApiProvider extends HttpProvider {
    getAll(groupId: number, page: Page): Observable<List<Store>> {
        return this.requestGET(
            `api/v3/stores/items/${groupId}`,
            new PageMapper().toJson(page)
        ).map((result: any) =>
            List(result.stores.map((store: any) =>
                new StoreMapper().fromJson(store)
            ))
        )
    }

    search(query: string): Observable<List<Store>> {
        return this.requestGET(
            'api/v3/stores',
            { q: query }
        ).map((result: any) =>
            List(result.stores.map((store: any) =>
                new StoreMapper().fromJson(store)
            ))
        )
    }
}
