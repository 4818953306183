import { Card } from 'core/entity/card';
import { CardSelectiveGroup } from 'core/entity/card/selective-group';
import { List } from 'immutable';
import { CardShinhanComboAlert } from 'presentation/components/alert/card-shinhan-combo';
import { CardInfoCategoryBenefits } from 'presentation/components/info/card/detail/benefits/category';
import { CardInfoCategorySpecialBenefits } from 'presentation/components/info/card/detail/benefits/category-special';
import { Selector } from 'presentation/components/selector';
import {
  SelectorModel,
  SelectorTheme
} from 'presentation/components/selector/model';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { toCurrency } from 'presentation/module/sugar';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    card: Card;
    group: CardSelectiveGroup;
    containRestrictions: boolean;
}

interface State {
    tab: string;
    comboAlert: boolean;
}

export class CardInfoSelectiveGroup extends React.Component<Props, State> {
    state = {
      tab: this.props.group.groups.first().title,
      comboAlert: false
    };

    render() {
      const {
        group,
        containRestrictions,
      } = this.props;
      const {
        shinhanCombo,
      } = this.props.card;

      const {
        numberOfGroups,
        selectType,
        numberOfSelect,
      } = group;

      const {
        comboAlert,
      } = this.state;

      return (
        <div className={ styles.group }>
          <div className={ styles.guide }>
            <h5>선택형 혜택 안내</h5>
            {this.props.card.id !== 4083 ?
              <p>
                { numberOfGroups }개의 선택형 혜택 중&nbsp;
                { selectType === CardSelectiveGroup.Type.SELECTIVE ? '반드시' : '최대' }&nbsp;
                { numberOfSelect }개를 선택하여 업종별로 할인 또는 적립 혜택을 제공받을 수 있습니다
              </p> : 
              <p>
                {`아래 ${numberOfGroups}개의 영역 중 월 이용금액이 가장 큰 1개 영역에 대해 할인 혜택을 받을 수 있습니다.`}
              </p>
            }
          </div>
          { shinhanCombo && this.renderShinhanCombo() }
          { this.renderTab() }
          { this.renderBenefits() }
          {
            comboAlert &&
                    <CardShinhanComboAlert
                      shinhanCombo={ shinhanCombo }
                      containRestrictions={ containRestrictions }
                      onCancel={ () => this.setState({ comboAlert: false }) }
                    />
          }
        </div>
      );
    }

    private renderShinhanCombo() {
      return (
        <div className={ styles.combo }>
          <h5>+ 신한 Combo서비스가 제공되는 카드입니다</h5>
          <p>
                    신한 Combo 서비스란?<br/>
                    카드가 제공하는 혜택과 별도로 내가 원하는 혜택을 추가로 선택/구매하여 이용할 수 있는 서비스
          </p>
          <button
            onClick={ () => {
              this.setState({ comboAlert: true });
              sendGAEvent(
                GA_DOMAIN.CARD,
                GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_SHINHAN,
                GA_ACTION.POPUP,
                '자세히보기'
              );
            } }
          >
                    자세히 보기
          </button>
        </div>
      );
    }

    private renderTab() {
      const { groups } = this.props.group;

      return (
        <Selector
          data={ List(
            groups.map(group =>
              new SelectorModel(
                group.title,
                group.title.replace('^', ' '),
                group.additionalAnnualCost !== null &&
                            group.additionalAnnualCost > 0 &&
                            `연회비 ${toCurrency(group.additionalAnnualCost)}원 추가`
              )
            )
          ) }
          chunkSize={ 6 }
          chunkSizeForTablet={ 4 }
          chunkSizeForMobile={ 3 }
          theme={ SelectorTheme.ROMAINE }
          onChange={ result => {
            this.setState({ tab: result.id });
            sendGAEvent(
              GA_DOMAIN.CARD,
              GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_SELECTIVE,
              GA_ACTION.TAB,
              result.id
            );
          } }
        />
      );
    }

    private renderBenefits() {
      const { card, group, containRestrictions } = this.props;
      const benefits = group.groups.find(it => it.title === this.state.tab);

      if (!benefits) {
        return;
      }

      return (
        <div>
          <CardInfoCategoryBenefits
            card={ card }
            benefits={ benefits.categoryBenefits }
            containRestrictions={ containRestrictions }
            gaCategory={ GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_SELECTIVE }
          />
          <CardInfoCategorySpecialBenefits
            card={ card }
            benefits={ benefits.categorySpecialBenefits }
            gaCategory={ GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_SELECTIVE_SPECIAL }
          />
        </div>
      );
    }
}
