import { TransactionCategoryFilter } from 'core/entity/personal-finance/transaction/category/filter';
import { TransactionCategoryRepositoryType } from 'core/repository/personal-finance/transaction-category';
import { UseCase } from 'core/use-case';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { TransactionCategory } from 'core/entity/personal-finance/transaction/category';

export class GetTransactionCategories extends UseCase<List<TransactionCategory>> {
    filter: TransactionCategoryFilter;
    private repository: TransactionCategoryRepositoryType;

    constructor(repository: TransactionCategoryRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<TransactionCategory>> {
        return this.repository
            .getAll(this.filter)
            .map((categories: List<TransactionCategory>) =>
                categories
                    .sortBy(category => category.priority)
                    .toList()
            );
    }

    protected validate(): boolean {
        return !!this.filter;
    }
}
