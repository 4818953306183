import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceMonthlyCardProfit } from 'core/entity/personal-finance/advice/monthly-card-profit';
import { Alfred2ComputedCardMapper } from 'data/http/mapper/alfred2/computed-card';
import { Alfred2TotalProfitConfigurationMapper } from 'data/http/mapper/alfred2/total-profit-configuration';

export class AdviceMonthlyCardProfitMapper implements NetworkResponseMapper<AdviceMonthlyCardProfit> {
    fromJson(json: any): AdviceMonthlyCardProfit {
        return new AdviceMonthlyCardProfit(
            new Alfred2ComputedCardMapper().fromJson(json.card),
            new Alfred2TotalProfitConfigurationMapper().fromJson(json.profit)
        );
    }
}
