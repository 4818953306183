import React from 'react';
import { List } from 'immutable';

import { toDateString } from 'utils/index';
import { isEndToday, isExpiredDate } from 'presentation/module/sugar';
import { EventTags } from 'presentation/components/event-tags';
import { sendGAEvent, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { EventsViewerListModel } from 'presentation/components/events-viewer/list/model';

import styles from './styles.pcss';

interface Props {
    linkUrl: (id: string) => string;
    gaCategory: string;
    gaAction: string;
    events: List<EventsViewerListModel>;
}

export const EventsViewerList = (props: Props) => {
    const {
        linkUrl,
        gaCategory,
        gaAction,
        events
    } = props;

    return (
        <section>
            <ul className={ styles.list }>
                {
                    events.map((event: EventsViewerListModel, index) =>
                        <li
                            key={ event.id }
                            className={ styles.item }
                        >
                            <a
                                href={ linkUrl(event.id) }
                                onClick={ () => sendGAEvent(GA_DOMAIN.NONE, gaCategory, gaAction, `자세히보기_${event.company.name}_${event.id}_${index + 1}`) }
                                className={ styles.link }
                            >
                                <div
                                    style={{
                                        backgroundImage: `url(${event.thumbNailImageUrl})`
                                    }}
                                    className={ styles.background }
                                />
                                <div className={ styles.content }>
                                    <div className={ styles.header }>
                                        <img
                                            src={ event.company.imageUrl }
                                            alt={ `${event.company.name} 로고` }
                                            className={ styles.logo }
                                        />
                                        <div className={ styles.tagsContainer }>
                                            <EventTags
                                                event={ event }
                                            />
                                        </div>
                                    </div>
                                    <h3 className={ styles.title }>
                                        { event.title }
                                    </h3>
                                    <div className={ styles.footer }>
                                        {
                                            event.endDate &&
                                            <span
                                                className={
                                                    isEndToday(event.startDate, event.endDate) ?
                                                        styles.expiredDueDate :
                                                        styles.dueDate
                                                }
                                            >
                                                {
                                                    isExpiredDate(event.endDate) ?
                                                        '마감되었습니다' :
                                                        `~${toDateString(event.endDate)}까지`
                                                }
                                            </span>
                                        }
                                        <span className={ styles.info }>자세히 보기</span>
                                    </div>
                                </div>
                                {
                                    isExpiredDate(event.endDate) &&
                                    <div className={ styles.blockLink }>
                                        <div><span>마감되었습니다</span></div>
                                    </div>
                                }
                            </a>
                        </li>
                    )
                }
            </ul>
        </section>
    )
};
