import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserBankAccountRepositoryType } from 'core/repository/personal-finance/bank-account';
import { UserBankAccountUpdateSpec } from 'core/entity/personal-finance/bank-account/update-spec';

export class UpdateUserBankAccount extends UseCase<UserBankAccount> {
    id: string;
    spec: UserBankAccountUpdateSpec;
    private repository: UserBankAccountRepositoryType;

    constructor(repository: UserBankAccountRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserBankAccount> {
        return this.repository.update(this.id, this.spec);
    }

    protected validate(): boolean {
        return !!this.id && !!this.spec;
    }

}
