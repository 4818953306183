import { ClickHeroBanner } from 'presentation/module/analytics/events/interfaces/hero';
import { sendTrackEvent } from 'presentation/module/analytics/events/send-track-event';
import { EventName } from 'presentation/module/analytics/events/types/event-name';

export const trackClickHeroBanner = (ctaButtonText: string) => {
  sendTrackEvent<ClickHeroBanner>({
    eventParam: {
      event_type: EventName.CLICK_HERO_BANNER,
      cta_button_text: ctaButtonText,
      url_path: window.location.pathname,
    },
  });
};
