import React from 'react';
import { RouteComponentProps } from 'react-router';

import { FetchState } from 'presentation/view-model/fetch-state';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { apply, lets } from 'utils/index';
import { CreditLoanInfo } from 'presentation/components/info/credit-loan';
import { Loader } from 'presentation/components/loader';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Loan } from 'core/entity/loan';
import { Application } from 'application/context.instance';
import { SubscriptionBag } from 'presentation/module/extension';
import { LoanLegalConfiguration } from 'core/entity/legal-configuration/loan';

interface State {
    loan?: Loan;
    legalConfig?: LoanLegalConfiguration;
    fetchState: FetchState;
}

export class CreditLoanRoute extends React.Component<RouteComponentProps<any>, State> {
    state = {
        loan: null as Loan,
        legalConfig: null as LoanLegalConfiguration,
        fetchState: FetchState.FETCHING
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { loan, legalConfig, fetchState } = this.state;
        return (
            <div>
                { loan && <MetaHelmet meta={ META_SET.CREDIT_LOAN(loan) } /> }
                {
                    lets(fetchState, it => {
                        switch (it) {
                            case FetchState.FETCHED:
                                return <CreditLoanInfo loan={ loan } legalConfig={ legalConfig } />;
                            case FetchState.FETCHING:
                                return <Loader padding={ 250 } radius={ 25 } />;
                            default:
                                return (
                                    <FetchError padding={ 250 }>
                                        데이터를 불러오는 중 오류가 발생했습니다<br/>
                                        잠시 후 다시 시도해주세요
                                    </FetchError>
                                )
                        }
                    })
                }
            </div>
        )
    }

    private fetch() {
        this.setState({
            fetchState: FetchState.FETCHING
        }, () =>
            Application.useCases.getServerRenderedLoan
                .runOnAnimateFrame()
                .subscribe(
                    loan =>
                        this.setState({
                            loan,
                            fetchState: FetchState.FETCHED
                        }, this.fetchLegalConfig),
                    () =>
                        this.setState({
                            fetchState: FetchState.ERROR
                        })
                )
                .unsubscribeBy(this.subscriptionBag)
        )
    }

    private fetchLegalConfig = () => {
        apply(Application.useCases.getLoanLegalConfigByLoanId, it => {
            it.loanId = this.state.loan.id;
        }).runOnAnimateFrame().subscribe(
            legalConfig =>
                this.setState({
                    legalConfig
                }),
            () =>
                this.setState({
                    legalConfig: null
                })
        ).unsubscribeBy(this.subscriptionBag);
    }
}
