import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';

export class AdviceContent extends Entity {
    priority: number;
    data: AdviceContentData;

    constructor(
        priority: number,
        data: AdviceContentData
    ) {
        super();
        this.priority = priority;
        this.data = data;
    }
}
