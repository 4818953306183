import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { InsurancePrimaryData } from 'core/entity/personal-finance/primary-data/insurance';
import { SpiderInsuranceDataMapper } from 'data/http/mapper/personal-finance/spider-data/insurance';
import { optional } from 'utils/index';

export class InsurancePrimaryDataMapper implements
    NetworkResponseMapper<InsurancePrimaryData>,
    NetworkRequestMapper<InsurancePrimaryData>
{
    fromJson(json: any): InsurancePrimaryData {
        return new InsurancePrimaryData(
            optional(json.spider, new SpiderInsuranceDataMapper().fromJson)
        )
    }

    toJson(target: InsurancePrimaryData): any {
        return {
            spider: optional(target.spider, new SpiderInsuranceDataMapper().toJson)
        };
    }
}
