import { Observable } from 'rxjs/Rx';
import { HttpProvider } from 'data/http';
import { LoanSurveyConfiguration } from 'core/entity/loan/survey-configuration';
import { LoanSurveyConfigurationMapper } from 'data/http/mapper/loan/survey-configuration';

export class LoanSurveyConfigurationApiProvider extends HttpProvider {
    get(): Observable<LoanSurveyConfiguration> {
        return this.requestGET(
            `api/v2/loans/survey-configuration`
        ).map((result: any) =>
            new LoanSurveyConfigurationMapper().fromJson(result.data)
        );
    }

}
