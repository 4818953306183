import React from 'react';
import { List } from 'immutable';

import { LoanRecommendResultSummary } from 'core/entity/loan/recommend-result/summary';
import { EventBus } from 'presentation/bus';
import { FixBodyEvent } from 'presentation/bus/event/fix-body-event';
import { CreditLoansProfitsFilter } from 'presentation/components/loan-recommendation/filter';
import { CreditLoansProfitsCard } from 'presentation/components/loan-recommendation/card';
import { CreditLoanProfitsStateModel } from 'presentation/routes/credit-loans/profits/model';
import { FetchState } from 'presentation/view-model/fetch-state';
import { Loader } from 'presentation/components/loader';
import { FetchEmpty, FetchEmptyPaddingSize } from 'presentation/components/fetch-state/empty';
import { toCurrency } from 'presentation/module/sugar';
import { CreditLoanAdditionalInfoAlert } from 'presentation/components/alert/credit-loan-additional-info';
import { optional } from 'utils/index';
import { CREDIT_LOAN_DEFAULT_FILTER } from 'presentation/routes/credit-loans/profits/preset';
import { CreditLoanAmount } from 'presentation/components/survey-form/credit-loan-amount/model';
import { CreditLoanAdditionalInfoAlertType } from 'presentation/components/alert/credit-loan-additional-info/model';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';
const FLAG_VISIBLE_COUNT = 3;

interface Props {
  fetchState: FetchState;
  profitsState: CreditLoanProfitsStateModel;
  results: List<LoanRecommendResultSummary>;
  onChange: (state: CreditLoanProfitsStateModel) => void;
}

interface State {
  infoAlert: boolean;
  infoAlertType?: CreditLoanAdditionalInfoAlertType;
}

export class CreditLoansProfits extends React.Component<Props, State> {
  state = {
    infoAlert: false,
    infoAlertType: null as CreditLoanAdditionalInfoAlertType,
  };

  render() {
    const {
      fetchState,
    } = this.props;
    const {
      infoAlert,
      infoAlertType,
    } = this.state;

    return (
      <div className={ styles.contentWrap }>
        <div className={ styles.contentContainer }>
          <ul className={ styles.content }>
            { this.renderFilter(this.props) }
            <li className={ styles.resultWrap }>
              {
                fetchState === FetchState.FETCHED ?
                  this.renderResults(this.props) :
                  <Loader padding={ 250 } radius={ 30 } />
              }
            </li>
          </ul>
        </div>
        {
          infoAlert &&
          <CreditLoanAdditionalInfoAlert type={ infoAlertType }
                                         onCancel={ this.onToggleInfoAlert() } />
        }
      </div>
    )
  }

  private renderResults = ({ onChange, fetchState, profitsState, results }: Props) => {
    const maximumLimit = optional(results.maxBy(it => it.computation.maximumAmountLimit), it =>
      it.computation.maximumAmountLimit,
    ) || 0;
    const fulfilledLoans =
      results
        .filter(loan => loan.computation.maximumAmountLimit >= profitsState.amount.value)
        .toList();
    const unfulfilledLoans =
      results
        .filter(loan => loan.computation.maximumAmountLimit < profitsState.amount.value)
        .toList();

    const onChangeMaximumLimit = (
      profitState: CreditLoanProfitsStateModel,
      maximumLimit: number,
      onChange: (state: CreditLoanProfitsStateModel) => void,
    ) => () => {
      sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.LIMIT_AMOUNT, GA_ACTION.INPUT_SHORTCUT, maximumLimit.toString());
      onChange({
        ...profitsState,
        amount: new CreditLoanAmount(maximumLimit),
      });
    };

    return (
      <>
            <span
              className={ fetchState === FetchState.FETCHED ? styles.resultCountOnTablet : styles.resultCount }>
                검색결과 <strong>{ results.size }개</strong><span>상품</span>
            </span>
        {
          results.isEmpty() ? (
            <FetchEmpty padding={ FetchEmptyPaddingSize.SMALL }>
              <p className={ styles.emptyDescription }>
                조건에 맞는 대출상품이 없습니다
              </p>
            </FetchEmpty>
          ) : (fulfilledLoans.isEmpty() && unfulfilledLoans.isEmpty()) ? (
            <FetchEmpty padding={ FetchEmptyPaddingSize.SMALL }>
              <p className={ styles.emptyDescription }>
                <small>조건에 맞는 대출상품이 없습니다</small>
                고객님의 최대 대출한도는 { toCurrency(maximumLimit / 10000) }만원으로 예상됩니다<br />
                한도 내에서 희망 대출금액을 다시 설정하세요
              </p>
              <button
                onClick={ onChangeMaximumLimit(profitsState, maximumLimit, onChange) }
                className={ styles.back }
              >
                대출금액 변경
              </button>
            </FetchEmpty>
          ) : (
            <>
              {
                !fulfilledLoans.isEmpty() &&
                this.renderLoans(fulfilledLoans)
              }
              {
                !unfulfilledLoans.isEmpty() &&
                <>
                  <div className={ styles.bar }><span>희망 대출금액에는 못 미치지만 금리가 낮은 상품목록 입니다</span></div>
                  { this.renderLoans(unfulfilledLoans) }
                </>
              }
            </>
          )
        }
      </>
    );
  };

  private renderLoans = (loans: List<LoanRecommendResultSummary>) => {
    const { profitsState } = this.props;

    return (
      <ol>
        {
          loans.map((summary, index) =>
            <li
              key={ summary.loan.slug }
              className={ styles.resultItem }
            >
              {
                index < FLAG_VISIBLE_COUNT &&
                <span className={ index === 0 ? styles.redFlag : styles.orangeFlag }>
                                { index + 1 }
                            </span>
              }
              <CreditLoansProfitsCard profitsState={ profitsState } loanResultSummary={ summary } />
            </li>,
          )
        }
      </ol>
    );
  };

  private renderFilter = (props: Props) => {
    const {
      profitsState,
      fetchState,
      onChange,
    } = props;

    return (
      <li className={ styles.filterWrap }>
        <input
          type='checkbox'
          id='card-filter'
          onChange={ this.onChangeFilter }
          className={ styles.filterTrigger }
        />
        <div
          className={ fetchState === FetchState.FETCHED ? styles.filterTriggerLabelWrapOnTablet : styles.filterTriggerLabelWrap }>
          <label
            htmlFor='card-filter'
            className={ styles.filterTriggerLabel }
          >
            필터
          </label>
          <button onClick={ this.resetFilter(props) } className={ styles.filterReset }>초기화</button>
        </div>
        <section className={ styles.filter }>
          <CreditLoansProfitsFilter
            state={ profitsState }
            onToggleInfoAlert={ this.onToggleInfoAlert }
            onChange={ onChange }
          />
        </section>
      </li>
    )
  };

  private onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.FILTER, GA_ACTION.POPUP, '필터');
    EventBus.post(new FixBodyEvent(e.currentTarget.checked));
  };

  private onToggleInfoAlert = (infoAlertType?: CreditLoanAdditionalInfoAlertType) => () => {
    sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.FILTER_ALERT, GA_ACTION.POPUP, infoAlertType);
    this.setState(prevState => ({
      infoAlert: !prevState.infoAlert,
      infoAlertType,
    }))
  };

  private resetFilter = ({ profitsState, onChange }: Props) => () => {
    sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.FILTER, GA_ACTION.CLEAR, '초기화');
    onChange({
      ...profitsState,
      ...CREDIT_LOAN_DEFAULT_FILTER,
    });
  };
}
