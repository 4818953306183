import { DropUserAlert } from 'presentation/components/flexible-alert/drop-user';
import React from 'react';

import styles from './styles.pcss';

interface State {
    alertDropUser: boolean;
}


export class SettingsPrivacyRoute extends React.Component<{}, State> {
    state = {
      alertDropUser: false,
      alertSignOut: false,
    };

    render() {
      const {
        alertDropUser,
      } = this.state;

      return (
        <>
          <h5 className={ styles.formTitle }>계정</h5>
          <ul className={ styles.formGroup }>
            <li className={ styles.noPadding }>
              <button
                className={ styles.formLabelButton }
                onClick={ this.onToggleDropAlert }
              >
                            회원 탈퇴
              </button>
            </li>
          </ul>
          {
            alertDropUser &&
                    <DropUserAlert onCancel={ this.onToggleDropAlert } />
          }
        </>
      );
    }

    private onToggleDropAlert = () =>
      this.setState({ alertDropUser: !this.state.alertDropUser });
}
