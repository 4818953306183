import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardPremiumVoucherGroup } from 'core/entity/card/premium-voucher-group';
import { lets } from 'utils/index';
import { CardPremiumVoucherMapper } from 'data/http/mapper/card/premium-voucher';

export class CardPremiumVoucherGroupMapper implements NetworkResponseMapper<CardPremiumVoucherGroup> {
    fromJson(json: any): CardPremiumVoucherGroup {
        return new CardPremiumVoucherGroup(
            json.number_of_vouchers,
            json.number_of_select,
            lets(json.select_type, it => {
                switch (it) {
                    case 'selective':
                        return CardPremiumVoucherGroup.Type.SELECTIVE;
                    case 'optional':
                        return CardPremiumVoucherGroup.Type.OPTIONAL;
                    default:
                        return CardPremiumVoucherGroup.Type.DEFAULT;
                }
            }),
            List(json.vouchers.map((voucher: any) =>
                new CardPremiumVoucherMapper().fromJson(voucher)
            ))
        )
    }
}
