import { NetworkResponseMapper } from 'data/http/mapper';
import { CreditMetadata } from 'core/entity/personal-credit/metadata';
import { CreditGradeMetadataMapper } from 'data/http/mapper/personal-credit/metadata/grade';

export class CreditMetadataMapper implements NetworkResponseMapper<CreditMetadata> {
    fromJson(json: any): CreditMetadata {
        return new CreditMetadata(
            json.grades.map(new CreditGradeMetadataMapper().fromJson).toList()
        )
    }

}
