import { NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { ComparableCard } from 'core/entity/comparable-card';
import { ComparableCardCompanyMapper } from 'data/http/mapper/comparable-card/company';
import { ComparableCardIssuingUrlMapper } from 'data/http/mapper/comparable-card/issuing-url';

export class ComparableCardMapper implements NetworkResponseMapper<ComparableCard> {
    fromJson(json: any): ComparableCard {
        return new ComparableCard(
            json.id,
            json.name,
            json.image_url,
            json.annual_cost,
            lets(json.type, type => {
                switch (type) {
                    case 'credit':
                        return ComparableCard.Type.CREDIT;
                    case 'check':
                        return ComparableCard.Type.CHECK;
                    case 'hybrid':
                        return ComparableCard.Type.HYBRID;
                    default:
                        return ComparableCard.Type.CREDIT;
                }
            }),
            new ComparableCardCompanyMapper().fromJson(json.company),
            new ComparableCardIssuingUrlMapper().fromJson(json.issuing_url)
        )
    }
}
