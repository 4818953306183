import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { SpiderBankSummary } from 'core/entity/personal-finance/bank/summary';

export class SpiderBankSummaryMapper implements
    NetworkResponseMapper<SpiderBankSummary>,
    NetworkRequestMapper<SpiderBankSummary>
{
    fromJson(json: any): SpiderBankSummary {
        return new SpiderBankSummary(
            json.id,
            json.name,
            json.image_url
        )
    }

    toJson(target: SpiderBankSummary): any {
        return {
            id: target.id,
            name: target.name,
            image_url: target.imageUrl
        };
    }
}
