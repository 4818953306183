import { NetworkRequestMapper } from 'data/http/mapper';
import { TransactionCategoryCreateSpec } from 'core/entity/personal-finance/transaction/category/create-spec';
import { compactObject, optional } from 'utils/index';
import { TransactionCategoryChildMapper } from 'data/http/mapper/personal-finance/transaction/category-child';

export class TransactionCategoryCreateSpecMapper implements NetworkRequestMapper<TransactionCategoryCreateSpec> {
    toJson(target: TransactionCategoryCreateSpec): any {
        return compactObject({
            name: target.name,
            transaction_type: target.transactionType,
            priority: target.priority,
            icon_id: optional(target.iconId),
            parent_id: optional(target.parentId),
            children: optional(target.children, children =>
                children.map(child => new TransactionCategoryChildMapper().toJson(child))
            )
        });
    }
}
