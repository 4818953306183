import { Entity } from 'core/entity';
import { SpiderBankSummary } from 'core/entity/personal-finance/bank/summary';
import { List } from 'immutable';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserFund } from 'core/entity/personal-finance/fund';

export class SummarizedUserBankProducts extends Entity {
    bank: SpiderBankSummary;
    accounts: List<UserBankAccount>;
    loans: List<UserLoan>;
    funds: List<UserFund>;

    constructor(
        bank: SpiderBankSummary,
        accounts: List<UserBankAccount>,
        loans: List<UserLoan>,
        funds: List<UserFund>
    ) {
        super();
        this.bank = bank;
        this.accounts = accounts;
        this.loans = loans;
        this.funds = funds;
    }
}
