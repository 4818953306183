import { Service } from 'core/service';
import { CreditScoreRepositoryType } from 'core/repository/personal-credit/score';
import { CreditTipRepositoryType } from 'core/repository/personal-credit/tip';
import { UserCreditScoreInquiryRecordStatusRepositoryType } from 'core/repository/personal-credit/inquiry-record-status';
import { UserCreditScoreCardStatusRepositoryType } from 'core/repository/personal-credit/card-status';
import { UserCreditScoreLoanStatusRepositoryType } from 'core/repository/personal-credit/loan-status';
import { UserCreditScoreJointSuretyStatusRepositoryType } from 'core/repository/personal-credit/joint-surety-status';
import { UserCreditScoreChangeRecordStatusRepositoryType } from 'core/repository/personal-credit/change-record-status';
import { UserCreditScoreOverdueStatusRepositoryType } from 'core/repository/personal-credit/overdue-status';
import { CreditMetadataRepositoryType } from 'core/repository/personal-credit/metadata';

export class PersonalCreditService implements Service {
    private creditScoreRepository: CreditScoreRepositoryType;
    private creditTipRepository: CreditTipRepositoryType;
    private creditInquiryRecordStatusRepository: UserCreditScoreInquiryRecordStatusRepositoryType;
    private creditMetadataRepository: CreditMetadataRepositoryType;
    private creditCardStatusRepository: UserCreditScoreCardStatusRepositoryType;
    private creditLoanStatusRepository: UserCreditScoreLoanStatusRepositoryType;
    private creditOverdueStatusRepository: UserCreditScoreOverdueStatusRepositoryType;
    private creditJointSuretyStatusRepository: UserCreditScoreJointSuretyStatusRepositoryType;
    private creditChangeRecordStatusRepository: UserCreditScoreChangeRecordStatusRepositoryType;

    constructor(
        creditScoreRepository: CreditScoreRepositoryType,
        creditTipRepository: CreditTipRepositoryType,
        creditInquiryRecordStatusRepository: UserCreditScoreInquiryRecordStatusRepositoryType,
        creditMetadataRepository: CreditMetadataRepositoryType,
        creditCardStatusRepository: UserCreditScoreCardStatusRepositoryType,
        creditLoanStatusRepository: UserCreditScoreLoanStatusRepositoryType,
        creditOverdueStatusRepository: UserCreditScoreOverdueStatusRepositoryType,
        creditJointSuretyStatusRepository: UserCreditScoreJointSuretyStatusRepositoryType,
        creditChangeRecordStatusRepository: UserCreditScoreChangeRecordStatusRepositoryType,
    ) {
        this.creditScoreRepository = creditScoreRepository;
        this.creditTipRepository = creditTipRepository;
        this.creditInquiryRecordStatusRepository = creditInquiryRecordStatusRepository;
        this.creditMetadataRepository = creditMetadataRepository;
        this.creditCardStatusRepository = creditCardStatusRepository;
        this.creditLoanStatusRepository = creditLoanStatusRepository;
        this.creditOverdueStatusRepository = creditOverdueStatusRepository;
        this.creditJointSuretyStatusRepository = creditJointSuretyStatusRepository;
        this.creditChangeRecordStatusRepository = creditChangeRecordStatusRepository;
    }

    getCreditScoreFromKCB = () =>
        this.creditScoreRepository.get();

    getCreditTips = () =>
        this.creditTipRepository.getAll();

    getCreditInquiryRecordStatus = () =>
        this.creditInquiryRecordStatusRepository.get();

    getCreditCardStatus = () =>
        this.creditCardStatusRepository.get();

    getCreditLoanStatus = () =>
        this.creditLoanStatusRepository.get();

    getCreditOverdueStatus = () =>
        this.creditOverdueStatusRepository.get();

    getCreditJointSuretyStatus = () =>
        this.creditJointSuretyStatusRepository.get();

    getCreditChangeRecordStatus = () =>
        this.creditChangeRecordStatusRepository.get();

    getCreditMetadata = () =>
        this.creditMetadataRepository.get();

}
