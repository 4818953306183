import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { ContentsViewer } from 'presentation/components/contents-viewer';
import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { AuthorDetail } from 'presentation/routes/contents/author';
import { Authors } from 'presentation/routes/contents/authors';
import { ContentsDetailRoute } from 'presentation/routes/contents/content';
import { ContentsContributionRoute } from 'presentation/routes/contents/contribution';
import { ContentsTagsRoute } from 'presentation/routes/contents/tags';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

export const ContentIndexRoute = () =>
  <div>
    <MetaHelmet meta={ META_SET.CONTENTS }/>
    <ContentsViewer />;
  </div>;

export const ContentsRoute = ({ match }: RouteComponentProps<any>) => (
  <div>
    <Header active={ FinanceSectorID.MAGAZINE } />
    <Switch>
      <Route exact path={ `${match.url}` } component={ ContentIndexRoute } />
      <Route path={ `${match.url}/tags/:slug` } component={ ContentsTagsRoute } />
      <Route path={ `${match.url}/contribution` } component={ ContentsContributionRoute } />
      <Route exact path={ `${match.url}/authors` } component={ Authors } />
      <Route path={ `${match.url}/authors/:id` } component={ AuthorDetail } />
      <Route path={ `${match.url}/:id` } component={ ContentsDetailRoute } />
    </Switch>
    <Footer />
  </div>
);
