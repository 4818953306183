import { NetworkResponseMapper } from 'data/http/mapper';
import { optional, parseDate } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';
import { AdviceWelcomeData } from 'core/entity/personal-finance/advice/content-data/welcome';
import { AmountMapper } from 'data/http/mapper/amount';

export class AdviceWelcomeDataMapper implements NetworkResponseMapper<AdviceWelcomeData> {
    fromJson(json: any): AdviceWelcomeData {
        return new AdviceWelcomeData(
            json.user_count,
            new AmountMapper().fromJson(json.average_benefit_amount),
            json.available_organization_count,
            parseDate(json.base_datetime),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
