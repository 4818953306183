import { Entity } from 'core/entity';
import { List } from 'immutable';
import { UserCreditScoreCard } from 'core/entity/personal-credit/card';

export class UserCreditScoreCardStatus extends Entity {
    cards: List<UserCreditScoreCard>;
    updatedAt: Date;
    changed: boolean;

    constructor(
        cards: List<UserCreditScoreCard>,
        updatedAt: Date,
        changed: boolean
    ) {
        super();
        this.cards = cards;
        this.updatedAt = updatedAt;
        this.changed = changed;
    }
}
