import { UserFundRepositoryType } from 'core/repository/personal-finance/fund';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { UserFund } from 'core/entity/personal-finance/fund';
import { UserFundUpdateSpec } from 'core/entity/personal-finance/fund/update-spec';
import { UserFundsApiProvider } from 'data/http/api/gateway/v2/users/funds';
import { UserFundCreateSpec } from 'core/entity/personal-finance/fund/create-spec';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserFundRepository implements UserFundRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserFundsApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserFundsApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    getAll(): Observable<List<UserFund>> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.getAll(token)
        )
    }

    get(id: string): Observable<UserFund> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.get(token, id)
        )
    }

    create(spec: UserFundCreateSpec): Observable<UserFund> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.create(token, spec)
        )
    }

    update(id: string, spec: UserFundUpdateSpec): Observable<UserFund> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.update(token, id, spec)
        )
    }
}
