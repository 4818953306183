import { Entity } from 'core/entity';
import { ContentAuthorProfile } from 'core/entity/content/author/profile';

export class ContentAuthor extends Entity {
    id: string;
    profile: ContentAuthorProfile;

    constructor(
        id: string,
        profile: ContentAuthorProfile
    ) {
        super();
        this.id = id;
        this.profile = profile;
    }
}
