import { Entity } from 'core/entity';
import { List } from 'immutable';

export class TransactionFilter extends Entity {
    startDate?: Date;
    endDate?: Date;
    productIds?: List<string>;
    deleted?: boolean;
    offset?: number;
    limit?: number;
    categories?: List<string>;
    includeOverwritten?: boolean;
    title?: string;

    constructor(
        startDate: Date = null,
        endDate: Date = null,
        productIds: List<string> = null,
        deleted: boolean = null,
        offset: number = null,
        limit: number = null,
        categories: List<string> = null,
        includeOverwritten: boolean = null,
        title: string = null
    ) {
        super();
        this.startDate = startDate;
        this.endDate = endDate;
        this.productIds = productIds;
        this.deleted = deleted;
        this.offset = offset;
        this.limit = limit;
        this.categories = categories;
        this.includeOverwritten = includeOverwritten;
        this.title = title;
    }
}
