import Rx from 'rxjs/Rx';

export interface Event {}

class Bus {
    private bus: Rx.Subject<Event> = new Rx.Subject<Event>();

    public post(t: Event): void {
        if (this.bus.observers.length > 0) {
            this.bus.next(t);
        }
    }

    public toObservable(): Rx.Observable<Event> {
        return this.bus.asObservable();
    }
}

export const EventBus = new Bus();
