import { ContentColumn } from 'core/entity/content/column';
import { FlexibleAlert } from 'presentation/components/flexible-alert';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    content: ContentColumn;
}

interface State {
    alertMethod: boolean;
}

export class ContentColumnViewer extends React.Component<Props, State> {
    state = {
      alertMethod: false
    };

    render() {
      const { content } = this.props;
      const { alertMethod } = this.state;

      return (
        <div>
          <p className={ styles.philosophy }>
            뱅크샐러드의 모든 금융사/금융상품 추천 콘텐츠는 저희의 <strong>에디팅 철학</strong>을 철저하게 준수합니다.<br/>
            뱅크샐러드의 추천 콘텐츠는 정답을 제시하는 것이 아니며, 정확하고 객관적인 데이터를 통해 소비자의 상품 선택에 도움을 드리는 것을 목적으로 합니다.
          </p>
          { content.article.methodology && this.renderMethodologyButton() }
          {
            (content.thumbnailImageUrl && content.thumbnailImageUrl.length > 0) && (
              <div
                className={ styles.thumbnail }
                style={{ backgroundImage: `url("${content.thumbnailImageUrl}")` }}
              >
                            썸네일 이미지
              </div>
            )
          }
          <div dangerouslySetInnerHTML={{ __html: content.article.body.replace(/``/gi, '\'') }} className="contents__detail" />
          { content.article.warning && this.renderNote() }
          {
            alertMethod && (
              <FlexibleAlert
                title="추천의 방법론 및 활용 데이터"
                onCancel={ this.onToggleAlert }
              >
                <div className={ styles.scrollable }>
                  <div
                    dangerouslySetInnerHTML={{ __html: content.article.methodology.replace(/``/gi, '\'') }}
                    className="contents__detail"
                  />
                </div>
              </FlexibleAlert>
            )
          }
        </div>
      );
    }

    private renderNote = () => {
      const { content } = this.props;

      return (
        <div className={ styles.note }>
          <input type="checkbox" id="note" className={ styles.noteToggle } />
          <label htmlFor="note" className={ styles.noteLabel }>유의사항</label>
          <section className={ styles.noteDesc }>
            <p
              dangerouslySetInnerHTML={{ __html: content.article.warning.replace(/``/gi, '\'') }}
              className="contents__detail"
            />
            { content.article.methodology && this.renderMethodologyButton() }
          </section>
        </div>
      );
    };

    private renderMethodologyButton = () => (
      <span className={ styles.methodology }>
            이 칼럼은&nbsp;
        <button onClick={ this.onToggleAlert }>
                이런 데이터
        </button>
            &nbsp;를 활용해 작성되었습니다
      </span>
    );

    private onToggleAlert = () =>
      this.setState({ alertMethod: !this.state.alertMethod })
}
