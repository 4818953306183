import { Service } from 'core/service';
import { CachedStateRepositoryType } from 'core/repository/cached-state';
import { CachedStateType } from 'core/entity/cached-state/type';
import { CachedStateItem } from 'core/entity/cached-state/item';

export class CacheService implements Service {
    private localCachedStateRepository: CachedStateRepositoryType;
    private sessionCachedStateRepository: CachedStateRepositoryType;

    constructor(
        localCachedStateRepository: CachedStateRepositoryType,
        sessionCachedStateRepository: CachedStateRepositoryType
    ) {
        this.localCachedStateRepository = localCachedStateRepository;
        this.sessionCachedStateRepository = sessionCachedStateRepository;
    }

    getAllLocalCachedState = () =>
        this.localCachedStateRepository.getAll();

    getLocalCachedState = (type: CachedStateType) =>
        this.localCachedStateRepository.get(type);

    setLocalCachedState = (item: CachedStateItem) =>
        this.localCachedStateRepository.set(item);

    getAllSessionCachedState = () =>
        this.sessionCachedStateRepository.getAll();

    getSessionCachedState = (type: CachedStateType) =>
        this.sessionCachedStateRepository.get(type);

    setSessionCachedState = (item: CachedStateItem) =>
        this.sessionCachedStateRepository.set(item);
}
