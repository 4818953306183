import { SpiderData } from 'core/entity/personal-finance/spider-data';

export class SpiderInsuranceData extends SpiderData {
    constructor(
        name: string,
        public dataSource: SpiderInsuranceData.dataSource,
        responseContained: boolean = null
    ) {
        super(name, responseContained);
    }
}

export module SpiderInsuranceData {
    export enum dataSource {
        INSURANCE_COMPANY = 'insurance_company',
        CREDIT4U = 'credit4u'
    }
}
