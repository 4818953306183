import { ChargedTransaction } from 'core/entity/personal-finance/charged-transaction';

declare module 'core/entity/personal-finance/charged-transaction' {
    export interface ChargedTransaction {
        presentInstallment(): string;
    }
}

ChargedTransaction.prototype.presentInstallment = function() {
    return this.installment ?
        `${this.installment.month}개월 할부 (${this.installment.round}회차)` :
        '일시불'
};
