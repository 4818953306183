import React from 'react';
import { List } from 'immutable';

import { uniqueKey } from 'utils/index';
import { lets } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    value: any;
    data: List<any>;
    scrollDisabled?: boolean;
    noArrow?: boolean;
    onChange: (value: any) => void;
    onRenderActive: (value: any) => any;
    onRenderItem: (value: any) => any;
}

export const FlexibleDropDown = (props: Props) => lets(uniqueKey(), id => {
    let toggle: HTMLInputElement;
    let list: HTMLUListElement;

    const onClick = (item: any) => () => {
        toggle.checked = false;
        props.onChange(item);
    };

    const onChangeToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            const index = props.data.findIndex(it => it === props.value);
            list.scrollTop = list.scrollHeight * index / props.data.size;
        }
    };

    return (
        <div className={ styles.wrap }>
            <input
                type="checkbox"
                ref={ node => toggle = node }
                id={ id }
                className={ styles.toggle }
                onChange={ onChangeToggle }
            />
            <label
                htmlFor={ id }
                className={ props.noArrow ? styles.active : styles.activeWithArrow }
            >
                { props.onRenderActive(props.value) }
            </label>
            <button
                onClick={ () => toggle.checked = false }
                className={ styles.close }
            >
                드롭다운 닫기
            </button>
            <div className={ styles.extend }>
                <ul
                    ref={ e => list = e }
                    className={ props.scrollDisabled ? styles.stuckList : styles.list }
                >
                    {
                        props.data.map((item, i) =>
                            <li key={ `${id}-${i}` } onClick={ onClick(item) }>
                                { props.onRenderItem(item) }
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
});
