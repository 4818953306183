import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { SpiderSecuritiesFirm } from 'core/entity/personal-finance/securities-firm';
import { SpiderSecuritiesFirmSummary } from 'core/entity/personal-finance/securities-firm/summary';

export class UserStockUpdateSpec extends Entity {
    securitiesFirm?: SpiderSecuritiesFirmSummary;
    nickname?: string;
    description?: string;
    averagePurchaseAmount?: number;
    quantity?: number;
    commission?: number;
    tax?: number;
    purchasedAt?: Date;
    hidden?: boolean;
    deletedStatus?: Asset.DeletedStatus;

    constructor(
        securitiesFirm: SpiderSecuritiesFirmSummary = null,
        nickname: string = null,
        description: string = null,
        averagePurchaseAmount: number = null,
        quantity: number = null,
        commission: number = null,
        tax: number = null,
        purchasedAt: Date = null,
        hidden: boolean = null,
        deletedStatus: Asset.DeletedStatus = null
    ) {
        super();
        this.securitiesFirm = securitiesFirm;
        this.nickname = nickname;
        this.description = description;
        this.averagePurchaseAmount = averagePurchaseAmount;
        this.quantity = quantity;
        this.commission = commission;
        this.tax = tax;
        this.purchasedAt = purchasedAt;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
    }
}
