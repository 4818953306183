import { TransactionCategoryParentSummary } from 'core/entity/personal-finance/transaction/category-parent/summary';
import { NetworkResponseMapper } from 'data/http/mapper';
import { TransactionIconSummaryMapper } from 'data/http/mapper/personal-finance/transaction/icon/summary';
import { optional } from 'utils/index';

export class TransactionCategoryParentSummaryMapper implements NetworkResponseMapper<TransactionCategoryParentSummary> {
    fromJson(json: any): TransactionCategoryParentSummary {
        return new TransactionCategoryParentSummary(
            json.id,
            json.name,
            optional(json.icon, it => new TransactionIconSummaryMapper().fromJson(it))
        )
    }

}
