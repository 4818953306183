import { SummarizedUserCardBill } from 'core/entity/personal-finance/summarized-card-bill';
import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderCardCompanySummaryMapper } from 'data/http/mapper/personal-finance/card-company/summary';
import { AmountMapper } from 'data/http/mapper/amount';
import { parseDate } from 'utils/index';

export class SummarizedUserCardBillMapper implements NetworkResponseMapper<SummarizedUserCardBill> {
    fromJson(json: any): SummarizedUserCardBill {
        return new SummarizedUserCardBill(
            new SpiderCardCompanySummaryMapper().fromJson(json.company),
            new AmountMapper().fromJson(json.amount),
            parseDate(json.due_date)
        )
    }

}
