import { Observable } from 'rxjs/Rx';
import { HttpProvider } from 'data/http';
import { SVG } from 'core/entity/svg';
import { SVGMapper } from 'data/http/mapper/svg';

export class SVGApiProvider extends HttpProvider {
    get(url: string): Observable<SVG> {
        return this.requestGET(
            url
        ).map((result: any) => {
            return new SVGMapper().fromJson(result);
        })
    }
}
