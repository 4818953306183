import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { Footer } from 'presentation/components/footer';
import { SectorsCard } from 'presentation/routes/sectors/card';
import { SectorsBank } from 'presentation/routes/sectors/bank';
import { SectorsInvestment } from 'presentation/routes/sectors/investment';
import { SectorsMagazine } from 'presentation/routes/sectors/magazine';
import { SectorsLoan } from 'presentation/routes/sectors/loan';
import { SectorsAnnuity } from 'presentation/routes/sectors/annuity';
import { SectorsFintech } from 'presentation/routes/sectors/fintech';

export const SectorsRoute = ({ match }: RouteComponentProps<any>) => (
    <div>
        <Switch>
            <Route path={ `${match.url}/card` } component={ SectorsCard } />
            <Route path={ `${match.url}/bank` } component={ SectorsBank } />
            <Route path={ `${match.url}/investment` } component={ SectorsInvestment } />
            <Route path={ `${match.url}/loan` } component={ SectorsLoan } />
            <Route path={ `${match.url}/annuity` } component={ SectorsAnnuity } />
            <Route path={ `${match.url}/fintech` } component={ SectorsFintech } />
            <Route path={ `${match.url}/magazine` } component={ SectorsMagazine } />
        </Switch>
        <Footer />
    </div>
);
