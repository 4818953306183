import { LoanRepositoryType } from 'core/repository/loan';
import { Observable } from 'rxjs/Rx';
import { Loan } from 'core/entity/loan';
import { LoanApiProvider } from 'data/http/api/gateway/v2/loan';
import { ServerRenderLoanStorageProvider } from 'data/browser-storage/api/loan';

export class LoanRepository implements LoanRepositoryType {
    private api: LoanApiProvider;
    private storage: ServerRenderLoanStorageProvider;

    constructor(api: LoanApiProvider, storage: ServerRenderLoanStorageProvider) {
        this.api = api;
        this.storage = storage;
    }

    get(slug: string): Observable<Loan> {
        return this.api.get(slug);
    }

    getServerRenderedLoan(): Observable<Loan> {
        return this.storage.get()
            .flatMap(loan =>
                this.storage.clear().map(() => loan)
            );
    }
}
