import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { parseDate } from 'utils/index';
import { UserCreditScoreLoan } from 'core/entity/personal-credit/loan';

export class UserCreditScoreLoanMapper implements NetworkResponseMapper<UserCreditScoreLoan> {
    fromJson(json: any): UserCreditScoreLoan {
        return new UserCreditScoreLoan(
            json.name,
            new AmountMapper().fromJson(json.principal),
            new AmountMapper().fromJson(json.balance),
            parseDate(json.created_at),
            parseDate(json.expired_at)
        )
    }

}
