import { Notice, NoticeType } from 'core/entity/notice';
import { NoticeRepositoryType } from 'core/repository/notice';
import { NoticesApiProvider } from 'data/http/api/gateway/v2/notices';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class NoticeRepository implements NoticeRepositoryType {
    private api: NoticesApiProvider;

    constructor(api: NoticesApiProvider) {
      this.api = api;
    }

    getAll(type: NoticeType): Observable<List<Notice>> {
      return this.api.getAll(type);
    }

    get(id: number): Observable<Notice> {
      return this.api.get(id);
    }

}
