import { Entity } from 'core/entity';

export class TransactionCategoryChild extends Entity {
    name: string;

    constructor(
        name: string
    ) {
        super();
        this.name = name;
    }
}
