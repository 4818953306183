import { HttpProviderDependencies } from 'application/@context/http-providers';
import { MedicalCheckupRepository } from 'data/repository/medical-checkup';
import { StorageProviderDependencies } from 'application/@context/storage-providers';

export class MedicalCheckupDomainRepositoryDependencies {
    medicalCheckUp: MedicalCheckupRepository;

    constructor(http: HttpProviderDependencies, storage: StorageProviderDependencies) {
        this.medicalCheckUp = new MedicalCheckupRepository(
            http.gateway.medicalCheckUpResult,
            storage.medicalCheckupJudgement,
            storage.accessToken
        );
    }
}
