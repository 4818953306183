import { CardMapper as NetworkCardMapper } from 'data/http/mapper/card';
import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { Card } from 'core/entity/card';
import { CardRecommendSpecMapper } from 'data/browser-storage/mapper/card/recommend-spec';
import { CardSummaryMapper } from 'data/browser-storage/mapper/card/summary';
import {
    BrowserStorageKey,
    BrowserStorageProvider
} from 'data/browser-storage';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardSummary } from 'core/entity/card/summary';

class ServerRenderCardStorageMapper implements BrowserStorageMapper<Card> {
    toEntity(json: any): Card {
        return new NetworkCardMapper().fromJson(json);
    }

    toJson(target: Card): any {
        throw new Error('Method not implemented.');
    }
}

export class ServerRenderCardStorageProvider extends BrowserStorageProvider<Card> {
    protected key = BrowserStorageKey.CARD_SERVER_RENDER;
    protected mapper = new ServerRenderCardStorageMapper();
}

export class CardRecommendSpecStorageProvider extends BrowserStorageProvider<CardRecommendSpec> {
    protected key = BrowserStorageKey.CARD_RECOMMEND_PARAMS;
    protected mapper = new CardRecommendSpecMapper();
}

export class CardSummaryForCompareStorageProvider extends BrowserStorageProvider<CardSummary> {
    protected key = BrowserStorageKey.CARD_FOR_COMPARE;
    protected mapper = new CardSummaryMapper();
}
