import React from 'react';

import styles from './styles.pcss';

interface Props {
    children: any;
}

export const CardInfoEmpty = (props: Props) => (
  <div className={ styles.empty }>
    { props.children }
  </div>
);
