import { StockPrimaryData } from 'core/entity/personal-finance/primary-data/stock';
import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderStockDataMapper } from 'data/http/mapper/personal-finance/spider-data/stock';
import { optional } from 'utils/index';

export class StockPrimaryDataMapper implements NetworkResponseMapper<StockPrimaryData> {
    fromJson(json: any): StockPrimaryData {
        return new StockPrimaryData(
            optional(json.spider, it => new SpiderStockDataMapper().fromJson(it))
        )
    }

}
