import { MappingError, NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { AdviceReaction } from 'core/entity/personal-finance/advice/reaction';

export class AdviceReactionMapper implements
    NetworkRequestMapper<AdviceReaction>,
    NetworkResponseMapper<AdviceReaction>
{
    toJson(target: AdviceReaction): any {
        switch (target) {
            case AdviceReaction.USEFUL:
                return 'useful';
            case AdviceReaction.NOT_USEFUL:
                return 'not_useful';
            case AdviceReaction.NEUTRAL:
                return 'neutral';
            default:
                throw new MappingError();
        }
    }

    fromJson(json: any): AdviceReaction {
        switch (json) {
            case 'useful':
                return AdviceReaction.USEFUL;
            case 'not_useful':
                return AdviceReaction.NOT_USEFUL;
            case 'neutral':
                return AdviceReaction.NEUTRAL;
            default:
                throw new MappingError();
        }
    }

}
