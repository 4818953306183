import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { lets } from 'utils/index';
import { CardPriority } from 'core/entity/card/priority';

export class CardPriorityMapper implements BrowserStorageMapper<CardPriority> {
    toEntity(json: any): CardPriority {
        return new CardPriority(
            lets(json.criteria, it => {
                switch (it) {
                    case 'annual-cost':
                        return CardPriority.Criteria.ANNUAL_COST;
                    case 'promotion-end-date':
                        return CardPriority.Criteria.PROMOTION_END_DATE;
                    case 'promotion-create-date':
                        return CardPriority.Criteria.PROMOTION_CREATE_DATE;
                    case 'promotion-amount':
                        return CardPriority.Criteria.PROMOTION_AMOUNT;
                    case 'rating':
                        return CardPriority.Criteria.RATING;
                    case 'profit':
                        return CardPriority.Criteria.PROFIT;
                    case 'none':
                        return CardPriority.Criteria.NONE;
                    default:
                        return CardPriority.Criteria.NONE;
                }
            }),
            json.ascending
        );
    }

    toJson(target: CardPriority): any {
        return {
            criteria: lets(target.criteria, it => {
                switch (it) {
                    case CardPriority.Criteria.ANNUAL_COST:
                        return 'annual-cost';
                    case CardPriority.Criteria.PROMOTION_END_DATE:
                        return 'promotion-end-date';
                    case CardPriority.Criteria.PROMOTION_CREATE_DATE:
                        return 'promotion-create-date';
                    case CardPriority.Criteria.PROMOTION_AMOUNT:
                        return 'promotion-amount';
                    case CardPriority.Criteria.RATING:
                        return 'rating';
                    case CardPriority.Criteria.PROFIT:
                        return 'profit';
                    case CardPriority.Criteria.NONE:
                        return 'none';
                    default:
                        return 'none';
                }
            })
        };
    }

}
