import { HOCCheckBox } from 'presentation/components/input/hoc-check-box';
import React from 'react';
import { uniqueKey } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    text: string;
    url?: string;
    checked: boolean;
    checkboxOnChange: () => void;
}

class LoginMobileCheckboxUIComponent extends React.Component<Props> {
    private id = uniqueKey();

    render() {
      const {
        text,
        url,
        checked,
        checkboxOnChange
      } = this.props;

      return (
        <div className={ styles.wrap }>
          <input
            id={ this.id }
            type="checkbox"
            onChange={ checkboxOnChange }
            checked={ checked }
            className={ styles.input }
          />
          {
            url ?
              <>
                <label htmlFor={ this.id } className={ styles.label }/>
                <a href={ url } target="_blank" rel="noopener noreferrer" className={ styles.link }>{ text }</a>
              </> :
              <label htmlFor={ this.id } className={ styles.label }>{ text }</label>
          }
        </div>

      );
    }
}

export const LoginMobile = HOCCheckBox(LoginMobileCheckboxUIComponent);
