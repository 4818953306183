import { Entity } from 'core/entity';

export class PersonalizedLoanLimitation extends Entity {
    constructor(
        public limitation: number,
        public status: PersonalizedLoanLimitation.Status,
        public wording: string = null,
        public additionalWording: string = null
    ) {
        super();
    }
}

export module PersonalizedLoanLimitation {
    export enum Status {
        CONFIRMED = 'confirmed',
        ESTIMATED = 'estimated'
    }
}
