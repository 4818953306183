import { Entity } from 'core/entity';
import { ContentAuthorDescription } from 'core/entity/content/author/description';

export class ContentAuthorProfile extends Entity {
    name: string;
    summary: string;
    imageUrl: string;
    description: ContentAuthorDescription;

    constructor(
      name: string,
      summary: string,
      imageUrl: string,
      description: ContentAuthorDescription
    ) {
      super();
      this.name = name;
      this.summary = summary;
      this.imageUrl = imageUrl;
      this.description = description;
    }
}
