import { Content } from 'core/entity/content';
import { ContentCardComparison } from 'core/entity/content/card-comparison';
import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { ContentCardNews } from 'core/entity/content/card-news';
import { ContentColumn } from 'core/entity/content/column';
import { ContentSummary } from 'core/entity/content/summary';
import { ContentTagCategory } from 'core/entity/content/tag/category';
import { PromotedContentSet } from 'core/entity/promoted-content-set';
import { List } from 'immutable';
import { Event, EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { BestCardBanner } from 'presentation/components/banner/best-card';
import { ContentCardComparisonViewer } from 'presentation/components/content-viewer/content-card-comparison-viewer';
import { ContentCardNewsViewer } from 'presentation/components/content-viewer/content-card-news-viewer';
import { ContentColumnViewer } from 'presentation/components/content-viewer/content-column-viewer';
import { FacebookShareButton } from 'presentation/components/share-button/facebook';
import { OthersShareButton } from 'presentation/components/share-button/others';
import { TwitterShareButton } from 'presentation/components/share-button/twitter';
import { activateOptimize, GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { allowCopy, preventCopy } from 'presentation/module/block-copy';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP, BANKSALAD_SITEMAP_BY_ID, OUTBOUND_LINK } from 'presentation/module/sitemap';
import React from 'react';
import { lets, toDateString } from 'utils/index';

import styles from './styles.pcss';
const FIXED_TOP = 287;
const HIDDEN_BOTTOM = 1400;
const old_bi_url = 'https://cdn.banksalad.com/entities/content-authors/1543806098201-%E1%84%83%E1%85%A1%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%85%E1%85%A9%E1%84%83%E1%85%B3.png';

interface Props {
  content: Content;
}

interface State {
  fixed: boolean;
  hidden: boolean;
  temptation: boolean;
}

export class ContentViewer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    sendPixelEvent(PIXEL.CONTENT);
    this.state = {
      fixed: false,
      hidden: false,
      temptation: false,
    };
  }

  private scrollTopTrace = 0;
  private subscriptionBag = new SubscriptionBag();

  componentDidMount() {
    activateOptimize();
    preventCopy();
    EventBus.toObservable()
      .subscribe((event: Event) => {
        if (event instanceof DocumentScrollEvent) {
          const fixed = event.scrollTop > FIXED_TOP;
          const hidden = event.scrollBottom < HIDDEN_BOTTOM;

          if (
            this.state.fixed !== fixed
            || this.state.hidden !== hidden
          ) {
            this.setState({ fixed, hidden });
          }

          lets(this.scrollTopTrace > event.scrollTop, it => {
            if (this.state.temptation !== it) {
              this.setState({ temptation: it });
            }
            this.scrollTopTrace = event.scrollTop;
          });
        }
      })
      .unsubscribeBy(this.subscriptionBag);
  }

  componentWillUnmount() {
    this.subscriptionBag.destroy();
    allowCopy();
  }

  render() {
    const { content } = this.props;
    const {
      fixed,
      hidden,
      temptation,
    } = this.state;

    return (
      <article className={ styles.wrap }>
        <section className={ fixed ? styles.fixedHead : styles.head }>
          <div className={ styles.container }>
            {
              (content instanceof ContentCardComparison) &&
              <h2 className={ styles.subTitle }>{ content.description }</h2>
            }
            <h1 className={ styles.title }>{ content.title }</h1>
            <a 
              href={ BANKSALAD_SITEMAP_BY_ID.AUTHOR(content.author.id) }
              className={ styles.profile }
            >
              <img
                src={ content.author.profile.imageUrl === old_bi_url ? 'https://cdn.banksalad.com/resources/images/logo/bi.png' : content.author.profile.imageUrl }
                style={ content.author.profile.imageUrl === old_bi_url ? { "borderRadius": "0%" } : { "borderRadius": "50%" } }
                alt={ `뱅크샐러드 집필진 ${content.author.profile.name}` }
                className={ styles.profileImage }
              />
              <div className={ styles.profileInfo }>
                <span className={ styles.profileName }>{ content.author.profile.name }</span>
                <span className={ styles.profileJob }>{ content.author.profile.summary }</span>
                <div className={ styles.profileDate }>
                  <div className={ styles.profileUpdatedAt }>
                    최종 수정일 { toDateString(content.updatedAt) }
                  </div>
                  <div className={ styles.profileCreatedAt }>
                    작성일 { toDateString(content.createdAt) }
                  </div>
                </div>
              </div>
            </a>
            <ul className={ styles.share }>
              <li><FacebookShareButton /></li>
              <li><TwitterShareButton title={ content.title } /></li>
              <li className={ styles.active }>
                <OthersShareButton
                  title={ content.title }
                  imageUrl={ content.thumbnailImageUrl }
                />
              </li>
            </ul>
          </div>
        </section>
        <section className={ styles.container }>
          <ul className={ styles.tags }>
            {
              content.tags.map(tag =>
                <li
                  key={ tag.id }
                  className={ styles.tag }
                >
                  <a href={ BANKSALAD_SITEMAP_BY_ID.CONTENTS_TAG(tag.id) }>
                    { tag.name }
                  </a>
                </li>,
              )
            }
          </ul>
        </section>
        <section className={ styles.contentContainer }>
          { this.renderBody(content) }
          <div className={ styles.tail }>
            <section className={ styles.tailBanner }>
              <BestCardBanner gaCategory={ GA_CATEGORY.CONTENTS_DETAIL.BANNER_QUESTION } />
            </section>
            <span className={ styles.tailAuthor }>
              {
                `${content.author.profile.name} ${content.author.profile.summary}, 
                업데이트 일자 ${toDateString(content.updatedAt)}`
              }
            </span>
            <span className={ styles.tailMessage }>
              뱅크샐러드 매거진에서 제공되는 모든 정보는 저작권에 의해 보호되며, 상업적 용도로 복제와 배포 등에 활용할 수 없습니다.<br />
              의견 보내기 <a href={ OUTBOUND_LINK.MAIL_TO_BANKSALAD() }>hello@banksalad.co.kr</a>
            </span>
          </div>
          <div id='tips' className={ styles.tipsHash } />
          { this.renderMore(content.promotedContentSets) }
          { this.renderFixedContents(content.promotedContentSets) }
        </section>
        {
          !content.promotedContentSets.isEmpty() && !hidden && (
            <a
              href='#tips'
              className={ temptation ? styles.visibleTemptation : styles.temptation }
            >
              당신을 위한 맞춤 금융꿀팁을 확인하세요!
            </a>
          )
        }
      </article>
    );
  }

  private renderBody = (content: Content) => {
    if (content instanceof ContentColumn) {
      return <ContentColumnViewer content={ content } />;
    } else if (content instanceof ContentCardNews) {
      return <ContentCardNewsViewer content={ content } />;
    } else if (content instanceof ContentCardComparison) {
      return <ContentCardComparisonViewer content={ content } />;
    }
  };

  private renderMore = (sets: List<PromotedContentSet>) => {
    const renderContent = (tagName: string) => (content: ContentSummary, index: number) => {
      const { id, title, thumbnailImageUrl } = content;
      const onClick = () => {
        sendGAEvent(
          GA_DOMAIN.NONE,
          `${GA_CATEGORY.CONTENTS_DETAIL.PROMOTED_CONTENT}_${tagName}`,
          this.toGAAction(content),
          `${title}_${id}_${index + 1}`,
        );
      };

      return (
        <li
          key={ id }
          className={ styles.relative }
        >
          <a
            href={ this.toLink(content) }
            onClick={ onClick }
          >
            <div
              className={ styles.relativeThumbnail }
              style={ { backgroundImage: `url("${thumbnailImageUrl}")` } }
            />
            <h1 className={ styles.relativeTitle }>
              { content.title }
            </h1>
          </a>
        </li>
      );
    };

    return (
      <section>
        <dl className={ styles.more }>
          {
            sets
              .filter((set) => !set.contents.isEmpty())
              .map(({ contents, tag }) => {
                const title = `'${tag.name}'${this.toPostFix(tag.category)}`;

                return (
                  <React.Fragment key={ tag.id }>
                    <dt>{ title }</dt>
                    <dd>{ contents.map(renderContent(tag.name)) }</dd>
                  </React.Fragment>
                );
              })
          }
        </dl>
      </section>
    );
  };

  private renderFixedContents = (sets: List<PromotedContentSet>) => {
    const { fixed, hidden } = this.state;

    return (
      !sets.isEmpty() &&
      !sets.flatMap(it => it.contents).isEmpty()
    ) && (
      <section className={ 
        fixed ? (hidden ? styles.hiddenFloating : styles.fixedFloating) : styles.floating
      }>
        <div className={ styles.floatingBanner }>
          <strong>
              나에게 딱 맞는<br />
                        카드를 찾아보세요
          </strong>
          <p>
              내 소비패턴을 입력하면<br />
            <strong>
                모든 카드사의 카드 중<br />
              <span>혜택이 가장 좋은 카드</span>를 찾아드려요!
            </strong>
          </p>
          <a
            href={ BANKSALAD_SITEMAP.CARDS_QUESTIONS }
            className={ styles.floatingBannerLink }
            onClick={ this.onClickFloatingBanner }
          >
              나의 BEST 카드 찾아보기
          </a>
        </div>
        <h1 className={ styles.floatingTitle }>조회수 급상승 금융정보</h1>
        <ul className={ styles.floatingContents }>
          {
            sets
              .filter(set => !set.contents.isEmpty())
              .map(set => 
                set.contents.size >= 2 ? set.contents.slice(0, 2) : set.contents.slice(0, 1)
              )
              .flatMap(set => set)
              .map((content, i) => (
                <li
                  key={ content.id }
                  className={ styles.floatingContent }
                >
                  <a
                    href={ this.toLink(content) }
                    onClick={ () =>
                      sendGAEvent(
                        GA_DOMAIN.NONE,
                        GA_CATEGORY.CONTENTS_DETAIL.FIXED,
                        this.toGAAction(content),
                        `${content.title}_${content.id}_${i + 1}`,
                      ) }
                  >
                    <div
                      className={ styles.floatingContentThumbnail }
                      style={ { backgroundImage: `url("${content.thumbnailImageUrl}")` } }
                    />
                    <h1 className={ styles.floatingContentTitle }>{ content.title }</h1>
                  </a>
                </li>
              ))
          }
        </ul>
      </section>
    );
  };

  private onClickFloatingBanner = () => (
    sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.CONTENTS_DETAIL.FIXED, GA_ACTION.LINK.CARDS_QUESTIONS, '나의 BEST 카드 찾아보기')
  );

  private toPostFix = (category: ContentTagCategory) => {
    switch (category) {
    case ContentTagCategory.TARGET:
      return '을 위한 추천글';
    case ContentTagCategory.PRODUCT:
      return ' 핵심정보';
    case ContentTagCategory.OCCASION:
      return '관련 꼭 읽어야 할 글';
    default:
      return ' 더 알아보기';
    }
  };

  private toLink = (content: ContentSummary) => {
    if (content instanceof ContentCardComparisonSummary) {
      return BANKSALAD_SITEMAP_BY_ID.CARD_THEME(content.id);
    } else {
      return BANKSALAD_SITEMAP_BY_ID.CONTENT(content.id);
    }
  };

  private toGAAction = (content: ContentSummary) => {
    if (content instanceof ContentCardComparisonSummary) {
      return GA_ACTION.LINK.CARDS_THEMES_DETAIL;
    } else {
      return GA_ACTION.LINK.CONTENTS_DETAIL;
    }
  };
}
