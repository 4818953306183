import React from 'react';

import { DropDown } from 'presentation/components/drop-down';
import { MediaDesktop, MediaTabletMobile } from 'presentation/components/responsive/media-component';
import {
    CREDIT_LOAN_CREDIT_SCORE_PRESET,
    CREDIT_LOAN_REPAYMENT_PERIOD_PRESET,
    CREDIT_LOAN_SORT_PRESET
} from 'presentation/routes/credit-loans/profits/preset';
import { toCurrency } from 'presentation/module/sugar';
import { DropDownModel } from 'presentation/components/drop-down/model';
import { CreditLoanProfitsStateModel } from 'presentation/routes/credit-loans/profits/model';
import { CreditLoanScoreLevel } from 'presentation/components/survey-form/credit-loan-score-level/model';
import { CreditLoanAmount } from 'presentation/components/survey-form/credit-loan-amount/model';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';
const TEN_THOUSAND = 10000;

const DROP_DOWN_INLINE_STYLE = {
    title: {
        padding: '6px 20px'
    }
};
const LAST_DROP_DOWN_INLINE_STYLE = {
    title: {
        padding: '12px 20px'
    }
};
const MOBILE_DROP_DOWN_INLINE_STYLE = {
    title: {
        padding: '12px 0',
        fontSize: '12px'
    }
};

interface Props {
    profitsState: CreditLoanProfitsStateModel;
    onChange: (state: CreditLoanProfitsStateModel) => void;
}

interface State {
    amount: number;
}

export class CreditLoansProfitsHeader extends React.Component<Props, State> {
    state = {
        amount: this.props.profitsState.amount.value / TEN_THOUSAND
    };

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.profitsState.amount.value !== this.state.amount)
            this.setState({ amount: nextProps.profitsState.amount.value / TEN_THOUSAND});
    }

    render() {
        const {
            profitsState
        } = this.props;
        const {
            amount
        } = this.state;

        return (
            <div>
                {
                    profitsState.statistics &&
                    <h3 className={ styles.resultsTitle }>
                        { `${toCurrency(profitsState.statistics.numberOfCompanies) }개 금융사의 ` }
                        { `${toCurrency(profitsState.statistics.numberOfLoans) }개의 ` }<br/>
                        온라인 신용대출 상품을 분석한 결과입니다
                    </h3>
                }
                <div className={ styles.amountWrap }>
                    <div className={ styles.amountContainer }>
                        <span className={ styles.amountTitle }><span>희망 </span>대출금액</span>
                        <div className={ styles.amountInputWrap }>
                            <div className={ styles.amountInputContainer }>
                                <label htmlFor="amount" className={ styles.amountInput }>
                                    <input
                                        id="amount"
                                        type="number"
                                        onChange={ (e) => this.setAmount(parseInt(e.currentTarget.value)) }
                                        value={ amount }
                                    />
                                </label>
                            </div>
                            <span className={ styles.amountUnit }>만원</span>
                        </div>
                        <button onClick={ this.onChangeAmount(amount, this.props) } className={ styles.amountButton }>
                            재탐색
                        </button>
                        { this.renderDropDownFilterList(this.props) }
                    </div>
                </div>
            </div>
        )
    }

    private renderDropDownFilterList = (props: Props) => (
        <div className={ styles.dropDownFiltersWrap }>
            <MediaDesktop>
                <ul className={ styles.dropDownFilters }>
                    <li className={ styles.dropDownFilter }>
                        <DropDown
                            inlineStyle={ DROP_DOWN_INLINE_STYLE }
                            list={CREDIT_LOAN_CREDIT_SCORE_PRESET}
                            gaCategory={ GA_CATEGORY.LOANS_PROFITS.CREDIT_SCORE }
                            selected={ this.selectedCreditScoreDropDownModel(props) }
                            onChange={ this.onChangeScore(props) }
                        />
                    </li>
                    <li className={ styles.dropDownFilter }>
                        <DropDown
                            inlineStyle={ DROP_DOWN_INLINE_STYLE }
                            list={ CREDIT_LOAN_REPAYMENT_PERIOD_PRESET }
                            gaCategory={ GA_CATEGORY.LOANS_PROFITS.REPAYMENT_PERIOD }
                            selected={ this.selectedRepaymentPeriodDropDownModel(props) }
                            onChange={ this.onChangeRepaymentPeriod(props) }
                        />
                    </li>
                    <li className={ styles.dropDownFilter }>
                        <DropDown
                            inlineStyle={ LAST_DROP_DOWN_INLINE_STYLE }
                            list={ CREDIT_LOAN_SORT_PRESET }
                            gaCategory={ GA_CATEGORY.LOANS_PROFITS.SORT }
                            selected={ this.selectedSorterDropDownModel(props) }
                            onChange={ this.onChangeSort(props) }
                        />
                    </li>
                </ul>
            </MediaDesktop>
            <MediaTabletMobile>
                <ul className={ styles.dropDownFilters }>
                    <li className={ styles.dropDownFilter }>
                        <DropDown
                            inlineStyle={ MOBILE_DROP_DOWN_INLINE_STYLE }
                            list={CREDIT_LOAN_CREDIT_SCORE_PRESET}
                            gaCategory={ GA_CATEGORY.LOANS_PROFITS.CREDIT_SCORE }
                            selected={ this.selectedCreditScoreDropDownModel(props) }
                            onChange={ this.onChangeScore(props) }
                        />
                    </li>
                    <li className={ styles.dropDownFilter }>
                        <DropDown
                            inlineStyle={ MOBILE_DROP_DOWN_INLINE_STYLE }
                            list={ CREDIT_LOAN_REPAYMENT_PERIOD_PRESET }
                            gaCategory={ GA_CATEGORY.LOANS_PROFITS.REPAYMENT_PERIOD }
                            selected={ this.selectedRepaymentPeriodDropDownModel(props) }
                            onChange={ this.onChangeRepaymentPeriod(props) }
                        />
                    </li>
                    <li className={ styles.dropDownFilter }>
                        <DropDown
                            inlineStyle={ MOBILE_DROP_DOWN_INLINE_STYLE }
                            list={ CREDIT_LOAN_SORT_PRESET }
                            gaCategory={ GA_CATEGORY.LOANS_PROFITS.SORT }
                            selected={ this.selectedSorterDropDownModel(props) }
                            onChange={ this.onChangeSort(props) }
                        />
                    </li>
                </ul>
            </MediaTabletMobile>
        </div>
    );

    private setAmount = (amount: number) => this.setState({ amount });

    private selectedCreditScoreDropDownModel = ({ profitsState }: Props) =>
        profitsState.score.isValid() ?
            new DropDownModel(profitsState.score.value, profitsState.score.toValue()) :
            CREDIT_LOAN_CREDIT_SCORE_PRESET.first();
    private selectedRepaymentPeriodDropDownModel = ({ profitsState }: Props) =>
        profitsState.repaymentPeriod ?
            new DropDownModel(profitsState.repaymentPeriod, `${profitsState.repaymentPeriod}년 이내 상환`) :
            CREDIT_LOAN_REPAYMENT_PERIOD_PRESET.first();
    private selectedSorterDropDownModel = ({ profitsState }: Props) =>
        profitsState.sorter ?
            new DropDownModel(profitsState.sorter, profitsState.sorter):
            CREDIT_LOAN_SORT_PRESET.first();

    private onChangeScore = ({ profitsState, onChange }: Props) => (selectedDropDownModel: DropDownModel) => {
        sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.CREDIT_SCORE, GA_ACTION.DROP_DOWN, selectedDropDownModel.value);
        onChange({
            ...profitsState,
            score: new CreditLoanScoreLevel(selectedDropDownModel.key)
        });
    };

    private onChangeRepaymentPeriod = ({ profitsState, onChange }: Props) => (selectedDropDownModel: DropDownModel) => {
        sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.REPAYMENT_PERIOD, GA_ACTION.DROP_DOWN, selectedDropDownModel.value);
        onChange({
            ...profitsState,
            repaymentPeriod: selectedDropDownModel.key
        });
    };

    private onChangeSort = ({ profitsState, onChange }: Props) => (selectedDropDownModel: DropDownModel) => {
        sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.SORT, GA_ACTION.DROP_DOWN, selectedDropDownModel.value);
        onChange({
            ...profitsState,
            sorter: selectedDropDownModel.key
        });
    };

    private onChangeAmount = (
        amount: number,
        { profitsState, onChange }: Props
    ) => () => {
        sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.AMOUNT, GA_ACTION.INPUT, amount.toString());
        onChange({
            ...profitsState,
            amount: amount <= 0 ? profitsState.amount : new CreditLoanAmount(amount * TEN_THOUSAND)
        })
    };
}
