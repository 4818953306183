import { Question } from 'presentation/view-model/question';

export class CreditLoanScoreLevel implements Question {
    topic = '신용등급';
    essential: boolean;
    clearable: boolean;
    value?: number;

    constructor(
        amount: number = null,
        essential: boolean = true,
        clearable: boolean = false
    ) {
        this.value = amount;
        this.essential = essential;
        this.clearable = clearable;
    }

    clear(): CreditLoanScoreLevel {
        return new CreditLoanScoreLevel();
    }

    toValue(): string {
        return this.value ? `${this.value}등급` : '';
    }

    isValid(): boolean {
        return this.value !== null;
    }
}
