import { UserTransaction } from 'core/entity/personal-finance/transaction';
import { TransactionFilter } from 'core/entity/personal-finance/transaction/filter';
import { UserTransactionRepositoryType } from 'core/repository/personal-finance/transaction';
import { UseCase } from 'core/use-case';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { apply } from 'utils/index';

export class GetUserTransactionsByProduct extends UseCase<List<UserTransaction>> {
    productId: string;
    limit: number = 0;
    offset: number = 0;
    private repository: UserTransactionRepositoryType;

    constructor(repository: UserTransactionRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<UserTransaction>> {
        return this.repository.getAll(
            apply(new TransactionFilter(), it => {
                it.productIds = List.of(this.productId);
                it.limit = this.limit;
                it.offset = this.offset;
            })
        );
    }

    protected validate(): boolean {
        return !!this.productId;
    }

}
