import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserLoanUpdateSpec } from 'core/entity/personal-finance/loan/update-spec';
import { UserLoanRepositoryType } from 'core/repository/personal-finance/loan';

export class UpdateUserLoan extends UseCase<UserLoan> {
    id: string;
    spec: UserLoanUpdateSpec;
    private repository: UserLoanRepositoryType;

    constructor(repository: UserLoanRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserLoan> {
        return this.repository.update(this.id, this.spec);
    }

    protected validate(): boolean {
        return !!this.id && !!this.spec;
    }

}
