import { UserActualAsset } from 'core/entity/personal-finance/actual-asset';
import { UserActualAssetUpdateSpec } from 'core/entity/personal-finance/actual-asset/update-spec';
import { UserActualAssetRepositoryType } from 'core/repository/personal-finance/actual-asset';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class UpdateUserActualAsset extends UseCase<UserActualAsset> {
    id: string;
    spec: UserActualAssetUpdateSpec;
    private repository: UserActualAssetRepositoryType;

    constructor(repository: UserActualAssetRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserActualAsset> {
        return this.repository.update(this.id, this.spec);
    }

    protected validate(): boolean {
        return !!this.id && !!this.spec;
    }

}
