import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { Pair } from 'utils/index';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanRecommendRepository } from 'data/repository/loan-recommend';

type Result = Pair<LoanRecommendSpec, LoanRecommendFilter>;

export class GetLoanProfitsCachedData extends UseCase<Result> {
    private repository: LoanRecommendRepository;

    constructor(
        repository: LoanRecommendRepository
    ) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<Result> {
        return Observable.zip(
            this.repository.getCachedSpec(),
            this.repository.getCachedFilter(),
            (spec, filter) => new Pair(spec, filter)
        );
    }

    protected validate(): boolean {
        return true;
    }
}
