import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardBenefitTypeMapper } from 'data/browser-storage/mapper/card/benefit-type';
import { CardFilterMapper } from 'data/browser-storage/mapper/card/filter';
import { CardPriorityMapper } from 'data/browser-storage/mapper/card/priority';
import { CardSpendingMapper } from 'data/browser-storage/mapper/card/spending';
import { List } from 'immutable';

export class CardRecommendSpecMapper implements BrowserStorageMapper<CardRecommendSpec> {
    toEntity(json: any): CardRecommendSpec {
        return new CardRecommendSpec(
            List(json.spendings.map((spending: any) =>
                new CardSpendingMapper().toEntity(spending)
            )),
            List(json.benefitTypes.map((type: any) =>
                new CardBenefitTypeMapper().toEntity(type)
            )),
            new CardFilterMapper().toEntity(json.filter),
            new CardPriorityMapper().toEntity(json.priority)
        );
    }

    toJson(target: CardRecommendSpec): any {
        return {
            spendings: target.spendings.map(spending =>
                new CardSpendingMapper().toJson(spending)
            ).toArray(),
            benefitTypes: target.benefitTypes.map(type =>
                new CardBenefitTypeMapper().toJson(type)
            ).toArray(),
            filter: new CardFilterMapper().toJson(target.filter),
            priority: new CardPriorityMapper().toJson(target.priority)
        };
    }

}
