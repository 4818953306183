import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { AmountMapper } from 'data/http/mapper/amount';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { optional } from 'utils/index';

export class UserCashAccountMapper implements NetworkResponseMapper<UserCashAccount> {
    fromJson(json: any): UserCashAccount {
        return new UserCashAccount(
            json.id,
            json.nickname,
            json.description,
            json.hidden,
            new DeletedStatusMapper().fromJson(json.deleted_status),
            json.default,
            optional(json.latest_balance, it =>
                new AmountMapper().fromJson(it)
            )
        )
    }

}
