import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class AdviceNetAssetAmount extends Entity {
    netAssetValuation: Amount;
    assetValuation: Amount;
    debtAmount: Amount;
    baseDatetime: Date;
    startDatetime: Date;
    endDatetime: Date;

    constructor(
        netAssetValuation: Amount,
        assetValuation: Amount,
        debtAmount: Amount,
        baseDatetime: Date,
        startDatetime: Date,
        endDatetime: Date
    ) {
        super();
        this.netAssetValuation = netAssetValuation;
        this.assetValuation = assetValuation;
        this.debtAmount = debtAmount;
        this.baseDatetime = baseDatetime;
        this.startDatetime = startDatetime;
        this.endDatetime = endDatetime;
    }
}
