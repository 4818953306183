import { Entity } from 'core/entity';

export class InsurancePromotion extends Entity {
    title: string;
    highlightColor: string;

    constructor(
        title: string,
        highlightColor: string
    ) {
        super();
        this.title = title;
        this.highlightColor = highlightColor;
    }
}
