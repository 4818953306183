import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanInterestRange } from 'core/entity/loan/interest-range';

export class LoanInterestRangeMapper implements NetworkResponseMapper<LoanInterestRange> {
    fromJson(json: any): LoanInterestRange {
        return new LoanInterestRange(
            json.min,
            json.max
        )
    }

}
