import { Service } from 'core/service';
import { Observable } from 'rxjs/Rx';
import { UserActualAssetRepositoryType } from 'core/repository/personal-finance/actual-asset';
import { UserActualAssetCreateSpec } from 'core/entity/personal-finance/actual-asset/create-spec';
import { UserActualAsset } from 'core/entity/personal-finance/actual-asset';
import { UserActualAssetUpdateSpec } from 'core/entity/personal-finance/actual-asset/update-spec';

export class UserActualAssetService implements Service {
    private repository: UserActualAssetRepositoryType;

    constructor(repository: UserActualAssetRepositoryType) {
        this.repository = repository;
    }

    create = (spec: UserActualAssetCreateSpec): Observable<UserActualAsset> =>
        this.repository.create(spec);

    update = (id: string, spec: UserActualAssetUpdateSpec): Observable<UserActualAsset> =>
        this.repository.update(id, spec);
}
