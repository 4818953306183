import { LoanCFAConfiguration } from 'core/entity/legal-configuration/loan/cfa';
import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanCFAReviewMapper } from 'data/http/mapper/legal-configuration/loan/cfa/review';
import { LoanCFADescriptionMapper } from 'data/http/mapper/legal-configuration/loan/cfa/description';

export class LoanCFAConfigurationMapper implements NetworkResponseMapper<LoanCFAConfiguration> {
    fromJson(json: any): LoanCFAConfiguration {
        return new LoanCFAConfiguration(
            new LoanCFAReviewMapper().fromJson(json.review),
            new LoanCFADescriptionMapper().fromJson(json.description)
        )
    }
}
