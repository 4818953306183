import { UseCase } from 'core/use-case';
import { Page } from 'core/entity/page';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardRecommendRepositoryType } from 'core/repository/card-recommend';
import { CardNonTemporalComputationResult } from 'core/entity/card/non-temporal-computation-result';
import { CardBenefitType } from 'core/entity/card/benefit-type';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class RecommendCards extends UseCase<List<CardNonTemporalComputationResult>> {
    spec?: CardRecommendSpec = null;
    page?: Page = null;
    private repository: CardRecommendRepositoryType;

    constructor(repository: CardRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<CardNonTemporalComputationResult>> {
        return this.repository.cacheSpec(this.spec)
            .flatMap(() =>
                this.repository.recommend(
                    this.spec,
                    this.page
                )
            ).map(result =>
                this.spec.benefitTypes.contains(CardBenefitType.MILEAGE_AA) ||
                this.spec.benefitTypes.contains(CardBenefitType.MILEAGE_KA) ||
                this.spec.benefitTypes.contains(CardBenefitType.MILEAGE_OTHERS) ?
                    result.sortBy(item => item.summary.mileage).reverse().toList() :
                    result.sort(
                        (prev: CardNonTemporalComputationResult, next: CardNonTemporalComputationResult) =>
                            this.toSumProfit(next) - this.toSumProfit(prev)
                    ).toList()
            );
    }

    protected validate(): boolean {
        return this.spec !== null && this.page !== null;
    }

    private toSumProfit = (result: CardNonTemporalComputationResult) =>
        result.summary.discount + result.summary.point - (result.card.annualCostWithPromotion / 12)

}
