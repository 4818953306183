import { HttpProvider } from 'data/http';
import { AccessToken } from 'core/entity/user/access-token';
import { Observable } from 'rxjs';
import { HealthInsuranceSubmitResponse } from 'core/entity/personal-finance/health-insurance/submit/response';
import { HealthInsuranceSubmitResponseMapper } from 'data/http/mapper/personal-finance/health-insurance/submit/response';
import { compactObject } from 'utils/index';

export class HealthInsuranceSubmitApiProvider extends HttpProvider {
    submit(accessToken: AccessToken, loanId: string, userCreditGrade?: number): Observable<HealthInsuranceSubmitResponse> {
        return this.requestPOST(
            'api/v2/me/health-insurance/submit',
            compactObject({ loan_id: loanId, user_credit_grade: userCreditGrade }),
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) => new HealthInsuranceSubmitResponseMapper().fromJson(result))
    }
}
