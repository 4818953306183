import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { FinanceSectorRepositoryType } from 'core/repository/finance-sector';
import { FinanceSectorSummary } from 'core/entity/finance-sector/summary';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { FinanceSectorsApiProvider } from 'data/http/api/gateway/v2/banksalad-finance-sectors';

export class FinanceSectorRepository implements FinanceSectorRepositoryType {
    private api: FinanceSectorsApiProvider;

    constructor(api: FinanceSectorsApiProvider) {
        this.api = api;
    }

    getAll(): Observable<List<FinanceSectorSummary>> {
        return this.api.getAll();
    }

    get (id: FinanceSectorID): Observable<FinanceSector> {
        return this.api.get(id);
    }

}
