import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardPreferredSpecialBenefit } from 'core/entity/card/preferred-special-benefit';
import { CardSpecialBenefitMapper } from 'data/http/mapper/card/special-benefit';

export class CardPreferredSpecialBenefitMapper implements NetworkResponseMapper<CardPreferredSpecialBenefit> {
    fromJson(json: any): CardPreferredSpecialBenefit {
        return new CardPreferredSpecialBenefit(
            json.title,
            List(json.benefits.map((benefit: any) =>
                new CardSpecialBenefitMapper().fromJson(benefit)
            ))
        )
    }
}
