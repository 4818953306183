import { List } from 'immutable';
import { Entity } from 'core/entity';
import { Card } from 'core/entity/card';
import { CardProfit } from 'core/entity/card/profit';
import { CardAmount } from 'core/entity/card/amount';

export class CardNonTemporalComputationResult extends Entity {
    card: Card;
    profits: List<CardProfit>;
    summary: CardAmount;

    constructor(
        card: Card,
        profits: List<CardProfit>,
        summary: CardAmount
    ) {
        super();
        this.card = card;
        this.profits = profits;
        this.summary = summary;
    }
}
