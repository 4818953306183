import React from 'react';

import { isMobile } from 'presentation/module/sugar';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, GA_LABEL, sendGAEvent } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';
import { CardIssueContainer } from 'presentation/components/alert/card-issue-container';
import { CardAnnualCostPromotionDescription } from 'presentation/components/card-promotion-description';
import { FlexibleAlert } from 'presentation/components/flexible-alert';
import { isQRCard, isTouchableMobileDevice } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    cardId: number;
    description: string;
    summary: string;
    issueUrlDesktop?: string;
    issueUrlMobile?: string;
    companyName?: string;
    notForIssue?: boolean;
    onAccept?: () => void;
    onCancel: () => void;
}

interface State {
    copied: boolean
}

export class CardIssuePromotionAlert extends React.Component<Props, State> {
    state = {
        copied: false
    };

    private subscriptionBag = new SubscriptionBag();

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const {
            cardId,
            description,
            summary,
            notForIssue,
            onCancel
        } = this.props;

        return (description || summary) && (
            <FlexibleAlert
                width={ 800 }
                mobileFullHeight={ true }
                onCancel={ onCancel }
            >
                <CardIssueContainer cardId={ cardId }>
                    <CardAnnualCostPromotionDescription
                        description={ description }
                        summary={ summary }
                        notForIssue={ notForIssue }
                        redirect={ this.redirect }
                    />
                </CardIssueContainer>
                <button
                    className={ styles.close }
                    onClick={ onCancel }
                >
                    닫기
                </button>
            </FlexibleAlert>
        )
    }

    private redirect = () => {
        const {
            cardId,
            issueUrlDesktop,
            issueUrlMobile,
            companyName,
            onAccept
        } = this.props;

        !!companyName &&
        sendGAEvent(
            GA_DOMAIN.CARD,
            GA_CATEGORY.CARD_ISSUE_ALERT.PROMOTION,
            GA_ACTION.ISSUE,
            GA_LABEL.ISSUE('신청페이지로이동', companyName, cardId)
        );

        // BC카드, 하나카드인 경우 QR 페이지로 routing
        if (isQRCard(`${cardId}`) && !isTouchableMobileDevice()) {
            window.open(`/cards/card-qr/${cardId}`, '_blank', 'noopener, noreferrer');
            !!onAccept && onAccept();
            return;
        }

        const url = isMobile() ? issueUrlMobile : issueUrlDesktop;
        !!url && window.open(url);
        !!onAccept && onAccept();
    }
}
