import React from 'react';

import styles from './styles.pcss';

interface Props {
    padding: number;
    radius: number;
}

export class Loader extends React.Component<Props, undefined> {
    render() {
        let wrapStyle = {
            paddingTop: this.props.padding,
            paddingBottom: this.props.padding
        };

        let loaderStyle = {
            width: this.props.radius * 2,
            height: this.props.radius * 2,
            border: `${this.props.radius / 5}px solid #f5f5f5`,
            borderTop: `${this.props.radius / 5}px solid #10df99`
        };

        return (
            <div
                style={ wrapStyle }
                className={ styles.wrap }
            >
                <div
                    style={ loaderStyle }
                    className={ styles.loader }
                />
            </div>
        )
    }
}
