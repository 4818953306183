import { HttpProviderDependencies } from 'application/@context/http-providers';
import { InsuranceRepository } from 'data/repository/insurance';

export class InsuranceDomainRepositoryDependencies {
    insurance: InsuranceRepository;

    constructor(http: HttpProviderDependencies) {
        this.insurance = new InsuranceRepository(http.base.insurance);
    }
}
