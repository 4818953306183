import React from 'react';

import { Application } from 'application/context.instance';
import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { Loader } from 'presentation/components/loader';
import { FetchState } from 'presentation/view-model/fetch-state';
import { apply } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    cardId: number;
    children: any;
}

interface State {
    fetchState: FetchState;
    config: CardLegalConfiguration;
}

export class CardIssueContainer extends React.Component<Props, State> {
    state = {
        fetchState: FetchState.FETCHING,
        config: null as CardLegalConfiguration
    };

    componentDidMount() {
        this.fetch();
    }

    render() {
        const { children } = this.props;
        const { fetchState, config } = this.state;

        return fetchState === FetchState.FETCHED ? (
            (config && config.issuingDisabled) ? (
                <div className={ styles.wrap }>
                    <h1 className={ styles.message }>
                        카드사의 요청에 의해 일시적으로 뱅크샐러드 내 발급이 중단되었습니다.<br />
                        해당 카드사의 홈페이지를 이용해 주시기 바랍니다
                    </h1>
                </div>
            ) : (
                <div>
                    { children }
                </div>
            )
        ) : (
            <Loader padding={ 150 } radius={ 25 } />
        );
    }

    private fetch = () => {
        const { cardId } = this.props;
        apply(Application.useCases.getCardLegalConfigByCardId, it => {
            it.cardId = cardId;
        }).runOnAnimateFrame().subscribe(
            config =>
                this.setState({
                    config,
                    fetchState: FetchState.FETCHED
                }),
            () =>
                this.setState({
                    fetchState: FetchState.FETCHED
                })
        )
    }
}
