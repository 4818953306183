import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { PoliciesV1Route } from 'presentation/routes/policies/v1';
import { PoliciesV2Route } from 'presentation/routes/policies/v2';
import { PoliciesV3Route } from 'presentation/routes/policies/v3';
import { PoliciesV4Route } from 'presentation/routes/policies/v4';
import { PoliciesV5Route } from 'presentation/routes/policies/v5';
import { PoliciesV6Route } from 'presentation/routes/policies/v6';
import { PoliciesV7Route } from 'presentation/routes/policies/v7';
import { PoliciesV8Route } from 'presentation/routes/policies/v8';
import { PoliciesV9Route } from 'presentation/routes/policies/v9';
import { PoliciesV10Route } from 'presentation/routes/policies/v10';
import { PoliciesV11Route } from 'presentation/routes/policies/v11';
import { PoliciesV12Route } from 'presentation/routes/policies/v12';
import { PoliciesV13Route } from 'presentation/routes/policies/v13';
import { PoliciesV14Route } from 'presentation/routes/policies/v14';
import { PoliciesV15Route } from 'presentation/routes/policies/v15';
import { PoliciesV16Route } from 'presentation/routes/policies/v16';
import { PoliciesV17Route } from 'presentation/routes/policies/v17';
import { PoliciesV18Route } from 'presentation/routes/policies/v18';
import { PoliciesV19Route } from 'presentation/routes/policies/v19';
import { PoliciesV20Route } from 'presentation/routes/policies/v20';
import React from 'react';
import { Redirect } from 'react-router';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

export const NewPoliciesRoute = ({ match }: RouteComponentProps<any>) => (
  <>
    <Header />
    <Switch>
      <Route path={`${match.url}/v1`} component={PoliciesV1Route} />
      <Route path={`${match.url}/v2`} component={PoliciesV2Route} />
      <Route path={`${match.url}/v3`} component={PoliciesV3Route} />
      <Route path={`${match.url}/v4`} component={PoliciesV4Route} />
      <Route path={`${match.url}/v5`} component={PoliciesV5Route} />
      <Route path={`${match.url}/v6`} component={PoliciesV6Route} />
      <Route path={`${match.url}/v7`} component={PoliciesV7Route} />
      <Route path={`${match.url}/v8`} component={PoliciesV8Route} />
      <Route path={`${match.url}/v9`} component={PoliciesV9Route} />
      <Route path={`${match.url}/v10`} component={PoliciesV10Route} />
      <Route path={`${match.url}/v11`} component={PoliciesV11Route} />
      <Route path={`${match.url}/v12`} component={PoliciesV12Route} />
      <Route path={`${match.url}/v13`} component={PoliciesV13Route} />
      <Route path={`${match.url}/v14`} component={PoliciesV14Route} />
      <Route path={`${match.url}/v15`} component={PoliciesV15Route} />
      <Route path={`${match.url}/v16`} component={PoliciesV16Route} />
      <Route path={`${match.url}/v17`} component={PoliciesV17Route} />
      <Route path={`${match.url}/v18`} component={PoliciesV18Route} />
      <Route path={`${match.url}/v19`} component={PoliciesV19Route} />
      <Route path={`${match.url}/v20`} component={PoliciesV20Route} />
      
      <Redirect from={`${match.url}/terms`} to={BANKSALAD_SITEMAP.POLICIES_TERMS_RECENT} />
      <Redirect
        from={`${match.url}/privacy-agreements/submit-document`}
        to={BANKSALAD_SITEMAP.POLICIES_PRIVACY_AGREEMENTS_SUBMIT_DOCUMENT_RECENT}
      />
      <Redirect
        from={`${match.url}/privacy-agreements/credit-goal`}
        to={BANKSALAD_SITEMAP.POLICIES_PRIVACY_AGREEMENTS_CREDIT_GOAL_RECENT}
      />
      <Redirect
        from={`${match.url}/privacy-agreements/excel-export`}
        to={BANKSALAD_SITEMAP.POLICIES_PRIVACY_AGREEMENTS_EXCEL_EXPORT}
      />
      <Redirect
        from={`${match.url}/privacy-agreements`}
        to={BANKSALAD_SITEMAP.POLICIES_PRIVACY_AGREEMENT_RECENT}
      />
      <Redirect
        from={`${match.url}/privacy-agreement`}
        to={BANKSALAD_SITEMAP.POLICIES_PRIVACY_AGREEMENT_RECENT}
      />
      <Redirect
        from={`${match.url}/subscription-agreement`}
        to={BANKSALAD_SITEMAP.POLICIES_SUBSCRIPTION_AGREEMENT_RECENT}
      />
      <Redirect
        from={`${match.url}/third-parties/kcb/credit-goal`}
        to={BANKSALAD_SITEMAP.POLICIES_THIRD_PARTIES_KCB_CREDIT_GOAL_RECENT}
      />
      <Redirect
        from={`${match.url}/third-parties/eight-percent/submit-document`}
        to={BANKSALAD_SITEMAP.POLICIES_THIRD_PARTIES_EIGHT_PERCENT_SUBMIT_DOCUMENT_RECENT}
      />
      <Redirect
        from={`${match.url}/kcb-all-credit`}
        to={BANKSALAD_SITEMAP.POLICIES_KCB_ALL_CREDIT_RECENT}
      />
      <Redirect
        from={`${match.url}/kcb-credit-agreement`}
        to={BANKSALAD_SITEMAP.POLICIES_KCB_CREDIT_AGREEMENT_RECENT}
      />
      <Redirect
        from={`${match.url}/kcb-credit-third-party`}
        to={BANKSALAD_SITEMAP.POLICIES_KCB_CREDIT_THIRD_PARTY_RECENT}
      />
      <Redirect
        from={`${match.url}/kcb-third-party`}
        to={BANKSALAD_SITEMAP.POLICIES_KCB_THIRD_PARTY_RECENT}
      />
    </Switch>
    <Footer />
  </>
);
