import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserCreditScoreLoanStatus } from 'core/entity/personal-credit/loan-status';
import { UserCreditScoreLoanStatusMapper } from 'data/http/mapper/personal-credit/loan-status';
import { parseDate } from 'utils/index';

export class UserCreditLoansStatusFromKCBApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<UserCreditScoreLoanStatus> {
        return this.requestGET(
            'api/v2/me/credit-scores/KCB/loan-status',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCreditScoreLoanStatusMapper(
                parseDate(result.updated_at),
                result.changed
            ).fromJson(result.data)
        )
    }

}
