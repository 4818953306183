import React from 'react';

import styles from './styles.pcss';

interface Props {
    html: string;
    fromPlaneText?: boolean;
}

const reformat = (value: string): string => {
    return JSON.parse(JSON.stringify(value)
        .replace(/\\r\\n/g, '<br/>')
        .replace(/\\n\\r/g, '<br/>')
        .replace(/\\r/g, '<br/>')
        .replace(/\\n/g, '<br/>')
        .replace(/\\t/g, ''));
};

export const HTMLBody = ({ html, fromPlaneText }: Props) =>
    <p
        dangerouslySetInnerHTML={ { __html: fromPlaneText ? reformat(html) : html.replace(/\\n/g, '<br/>') } }
        className={ styles.body }
    />;
