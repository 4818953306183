import { MappingError, NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { PaymentCycle } from 'core/entity/personal-finance/insurance/payment-cycle';
import { lets } from 'utils/index';

export class PaymentCycleMapper implements
    NetworkRequestMapper<PaymentCycle>,
    NetworkResponseMapper<PaymentCycle>
{
    fromJson(json: any): PaymentCycle {
        return new PaymentCycle(
            json.round,
            lets(json.unit, it => {
                switch (it) {
                    case 'daily':
                        return PaymentCycle.Unit.DAILY;
                    case 'monthly':
                        return PaymentCycle.Unit.MONTHLY;
                    case 'yearly':
                        return PaymentCycle.Unit.YEARLY;
                    default:
                        throw new MappingError();
                }
            })
        )
    }

    toJson(target: PaymentCycle): any {
        return {
            round: target.round,
            unit: lets(target.unit, it => {
                switch (it) {
                    case PaymentCycle.Unit.DAILY:
                        return 'daily';
                    case PaymentCycle.Unit.MONTHLY:
                        return 'monthly';
                    case PaymentCycle.Unit.YEARLY:
                        return 'yearly';
                    default:
                        throw new MappingError();
                }
            })
        }
    }

}
