import { Application } from 'application/context.instance';
import Axios from 'axios';
import { ContentTag } from 'core/entity/content/tag';
import { ContentTagMapper } from 'data/http/mapper/content/tag';
import { ContentsViewer } from 'presentation/components/contents-viewer';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { SubscriptionBag } from 'presentation/module/extension';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNamespaceProduction } from 'utils/env';
import { apply } from 'utils/index';

interface State {
    tagId: string;
    tag: ContentTag;
    fetchState: FetchState;
}

export const ContentsTagsRoute = ({ match }: RouteComponentProps<any>) => {
  const tagId = match.params.slug;
  const [{ tag }, setState] = useState({ 
    tag: null as ContentTag, 
  });

  useEffect(() => {
    (async () => {
      const API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/tags/${tagId}.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/tags/${tagId}.json`;

      const { data } = await Axios.get(API_PATH);

      setState({
        tag: new ContentTagMapper().fromJson(data.data),
      });
    })();
  }, []);

  return (
    <div>
      { tag && <MetaHelmet meta={ META_SET.CONTENTS_TAGS(tag) }/> }
      <ContentsViewer tagId={ tagId } />;
    </div>
  );
};
