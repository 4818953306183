import { Entity } from 'core/entity';
import { TransactionIconSummary } from 'core/entity/personal-finance/transaction/category-icon/summary';

export class TransactionCategoryParentSummary extends Entity {
    id: string;
    name: string;
    icon?: TransactionIconSummary;

    constructor(
        id: string,
        name: string,
        icon: TransactionIconSummary = null
    ) {
        super();
        this.id = id;
        this.name = name;
        this.icon = icon;
    }
}
