import { Entity } from 'core/entity';
import { ContentTagSummary } from 'core/entity/content/tag/summary';
import { List } from 'immutable';
import { ContentSummary } from 'core/entity/content/summary';

export class PromotedContentSet extends Entity {
    tag: ContentTagSummary;
    contents: List<ContentSummary>;

    constructor(
        tag: ContentTagSummary,
        contents: List<ContentSummary>
    ) {
        super();
        this.tag = tag;
        this.contents = contents;
    }
}
