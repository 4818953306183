import { Entity } from 'core/entity';

export class ServiceCenterArticle extends Entity {
  constructor(
    public title: string,
    public userName: string,
    public body: string,
    public date: Date,
    public url: string,
  ) {
    super();
  }
}
