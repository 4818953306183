import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV10PrivacyPolicyRoute } from 'presentation/routes/policies/v10/privacy-policy';

export const PoliciesV10Route = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/privacy-policy`} component={ PoliciesV10PrivacyPolicyRoute } />
    </Switch>
);
