import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { ComparableCardConfigurationSet } from 'core/entity/comparable-card/configuration-set';
import { ComparableCardConfigurationMapper } from 'data/http/mapper/comparable-card/configuration';

export class ComparableCardConfigurationSetMapper implements NetworkResponseMapper<ComparableCardConfigurationSet> {
    fromJson(json: any): ComparableCardConfigurationSet {
        return new ComparableCardConfigurationSet(
            json.title,
            json.description,
            List(json.configurations.map((set: any) =>
                new ComparableCardConfigurationMapper().fromJson(set)
            ))
        )
    }
}
