import { NetworkResponseMapper } from 'data/http/mapper';
import { List } from 'immutable';
import { ContentMetaInformation } from 'core/entity/content/meta-information';

export class ContentMetaInformationMapper implements NetworkResponseMapper<ContentMetaInformation> {
    fromJson(json: any): ContentMetaInformation {
        return new ContentMetaInformation(
            json.views,
            json.title,
            json.description,
            List(json.keywords)
        )
    }
}
