import { List } from 'immutable';
import { Card } from 'core/entity/card';
import { CardQuestionCardType } from 'presentation/components/survey-form/card-type/model';
import { CardBenefitType } from 'core/entity/card/benefit-type';
import { lets, APP_VALUE } from 'utils/index';
import { CardQuestionBenefitType } from 'presentation/components/survey-form/card-benefit-type/model';
import { CardQuestionMonthlySpending } from 'presentation/components/survey-form/card-monthly-spending/model';
import { CardQuestionSpending } from 'presentation/components/survey-form/card-spendings/model';
import { CardSpending } from 'core/entity/card/spending';

export const generateCardQuestionCardType = (types: List<Card.Type>) =>
    new CardQuestionCardType(types);

export const generateCardQuestionBenefitType = (types: List<CardBenefitType>) =>
    lets(types, it => {
        if (
            it.contains(CardBenefitType.DISCOUNT) ||
            it.contains(CardBenefitType.POINT)
        ) {
            return new CardQuestionBenefitType(
                CardQuestionBenefitType.Type.DISCOUNT
            )
        } else if (
            it.contains(CardBenefitType.MILEAGE_OTHERS) ||
            it.contains(CardBenefitType.MILEAGE_KA) ||
            it.contains(CardBenefitType.MILEAGE_AA)
        ) {
            return new CardQuestionBenefitType(
                CardQuestionBenefitType.Type.MILEAGE
            )
        }
    });

export const generateCardQuestionMonthlySpending = (spendings: List<CardSpending>) =>
    new CardQuestionMonthlySpending(
        spendings.reduce((acc, spending) => acc + spending.expense, 0)
    );

export const generateCardQuestionSpendings = (spendings: List<CardSpending>) =>
    spendings
        .filter(it => it.storeId !== APP_VALUE.NORMAL_STORE_ID)
        .map(it => new CardQuestionSpending(
            it.storeId,
            it.storeName,
            it.expense
        ))
        .toList();
