import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { CardBenefitType } from 'core/entity/card/benefit-type';
import { lets } from 'utils/index';

export class CardBenefitTypeMapper implements BrowserStorageMapper<CardBenefitType> {
    toEntity(json: any): CardBenefitType {
        return lets(json, it => {
            switch (it) {
                case 'all':
                    return CardBenefitType.ALL;
                case 'discount':
                    return CardBenefitType.DISCOUNT;
                case 'point':
                    return CardBenefitType.POINT;
                case 'mileage_aa':
                    return CardBenefitType.MILEAGE_AA;
                case 'mileage_ka':
                    return CardBenefitType.MILEAGE_KA;
                case 'mileage_others':
                    return CardBenefitType.MILEAGE_OTHERS;
                default:
                    return CardBenefitType.ALL;
            }
        })
    }

    toJson(target: CardBenefitType): any {
        return lets(target, it => {
            switch (it) {
                case CardBenefitType.ALL:
                    return 'all';
                case CardBenefitType.DISCOUNT:
                    return 'discount';
                case CardBenefitType.POINT:
                    return 'point';
                case CardBenefitType.MILEAGE_AA:
                    return 'mileage_aa';
                case CardBenefitType.MILEAGE_KA:
                    return 'mileage_ka';
                case CardBenefitType.MILEAGE_OTHERS:
                    return 'mileage_others';
                default:
                    return 'all';
            }
        })
    }

}
