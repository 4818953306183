import { lets } from 'utils/index';

export enum CLIX {
    MAIN,
    SECTORS_CARD,
    SECTORS_BANK,
    SECTORS_INVESTMENT,
    SECTORS_LOAN,
    SECTORS_ANNUITY,
    SECTORS_FINTECH,
    CONTENTS
}

export const sendClixEvent = (type: CLIX) => {
    const label = lets(type, it => {
        switch (it) {
            case CLIX.MAIN:
                return 'bIgLcNNJS8eEnOBQvvSfKw';
            case CLIX.SECTORS_CARD:
                return 'RoIOJzT5R3SOzcJcSvMpdg';
            case CLIX.SECTORS_BANK:
                return '4MMv8yyHR1mB0Hw4UhbxBg';
            case CLIX.SECTORS_INVESTMENT:
                return 'VAILtDUsT32oblObcvYd-w';
            case CLIX.SECTORS_LOAN:
                return 'gZEkguj9SJSbkkOg-pYHgQ';
            case CLIX.SECTORS_ANNUITY:
                return 'MQ4TCtXXRoGGspFrFeO4RQ';
            case CLIX.SECTORS_FINTECH:
                return 'V0n-wFomSRGnyp_FGMBpNQ';
            case CLIX.CONTENTS:
                return 'aPAsjpevSMuxU591yCt3ig';
            default:
                return 'bIgLcNNJS8eEnOBQvvSfKw';
        }
    });

    (window as any)['roosevelt_params'] = {
        retargeting_id: 'UFl6QHVMVMaMshNRbqfZZA00',
        tag_label: label
    };

    if (location.protocol !== 'file:') {
        let clixScript = document.createElement('script');
        clixScript.src = (location.protocol=='https:' ? 'https' : 'http' ) + '://adimg.daumcdn.net/rt/roosevelt.js';
        document.body.appendChild(clixScript);
    }
};
