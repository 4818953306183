import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentTagCategory } from 'core/entity/content/tag/category';
import { lets } from 'utils/index';

export class ContentTagCategoryMapper implements NetworkResponseMapper<ContentTagCategory> {
    fromJson(json: any): ContentTagCategory {
        return lets(json, it => {
            switch (it) {
                case 'target':
                    return ContentTagCategory.TARGET;
                case 'product':
                    return ContentTagCategory.PRODUCT;
                case 'occasion':
                    return ContentTagCategory.OCCASION;
                case 'etc':
                default:
                    return ContentTagCategory.ETC;
            }
        })
    }
}
