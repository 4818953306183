import { DropDownModel } from 'presentation/components/drop-down/model';
import { List } from 'immutable';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanOrganizationType } from 'core/entity/loan/organization/type';
import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';
import { generateDefaultLoanRecommendFilter } from 'presentation/module/initialized-contents/loan';

export const CREDIT_LOAN_SORT_PRESET = List([
    new DropDownModel(LoanRecommendFilter.Sort.MINIMUM_INTEREST, '금리 낮은 순'),
    new DropDownModel(LoanRecommendFilter.Sort.MAXIMUM_LOAN_LIMIT, '한도 높은 순'),
    new DropDownModel(LoanRecommendFilter.Sort.MAXIMUM_REPAYMENT_PERIOD, '최장 기간 순'),
]);

export const CREDIT_LOAN_CREDIT_SCORE_PRESET = List([
    new DropDownModel(1, '1등급'),
    new DropDownModel(2, '2등급'),
    new DropDownModel(3, '3등급'),
    new DropDownModel(4, '4등급'),
    new DropDownModel(5, '5등급'),
    new DropDownModel(6, '6등급'),
    new DropDownModel(7, '7등급'),
    new DropDownModel(8, '8등급'),
    new DropDownModel(9, '9등급'),
    new DropDownModel(10, '10등급'),
]);

export const CREDIT_LOAN_REPAYMENT_PERIOD_PRESET = List([
    new DropDownModel(LoanRecommendFilter.RepaymentPeriod.UNDER_ONE_YEAR, '1년 이내 상환'),
    new DropDownModel(LoanRecommendFilter.RepaymentPeriod.UNDER_TWO_YEARS, '2년 이내 상환'),
    new DropDownModel(LoanRecommendFilter.RepaymentPeriod.UNDER_THREE_YEARS, '3년 이내 상환'),
    new DropDownModel(LoanRecommendFilter.RepaymentPeriod.UNDER_FOUR_YEARS, '4년 이내 상환'),
    new DropDownModel(LoanRecommendFilter.RepaymentPeriod.UNDER_FIVE_YEARS, '5년 이내 상환'),
]);

export const CREDIT_LOAN_ORGANIZATION_TYPES_PRESET = List([
    List([LoanOrganizationType.PRIMARY_BANK]),
    List([LoanOrganizationType.SECONDARY_BANK]),
    List([LoanOrganizationType.CARD_COMPANY, LoanOrganizationType.CAPITAL]),
    List([LoanOrganizationType.INSURANCE]),
    List([LoanOrganizationType.P2P])
]);

export const CREDIT_LOAN_INTEREST_TYPES_PRESET = List([
    LoanInterestType.FIXED_INTEREST,
    LoanInterestType.NON_FIXED_INTEREST
]);

export const CREDIT_LOAN_REPAYMENT_TYPES_PRESET = List([
    LoanRepaymentType.AT_END,
    LoanRepaymentType.PROGRESSIVE,
    LoanRepaymentType.INITIAL_VALUE
]);

const DEFAULT_FILTER = generateDefaultLoanRecommendFilter();

export const CREDIT_LOAN_DEFAULT_FILTER = {
    minusAccount: DEFAULT_FILTER.minusAccount,
    dayLoan: DEFAULT_FILTER.dayLoan,
    inPromotion: DEFAULT_FILTER.inPromotion,
    exemptReimbursementFees: DEFAULT_FILTER.exemptReimbursementFees,
    organizationTypes: DEFAULT_FILTER.organizationTypes,
    interestTypes: DEFAULT_FILTER.interestTypes,
    repaymentTypes: DEFAULT_FILTER.repaymentTypes,
    repaymentPeriod: DEFAULT_FILTER.repaymentPeriod,
    sorter: DEFAULT_FILTER.sorter
};
