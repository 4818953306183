import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanOrganization } from 'core/entity/loan/organization';
import { LoanOrganizationTypeMapper } from 'data/http/mapper/loan/organization/type';

export class LoanOrganizationMapper implements NetworkResponseMapper<LoanOrganization> {
    fromJson(json: any): LoanOrganization {
        return new LoanOrganization(
            json.id,
            json.name,
            json.image_url,
            json.url,
            new LoanOrganizationTypeMapper().fromJson(json.type)
        )
    }

}
