import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { UserCashAccountUpdateSpec } from 'core/entity/personal-finance/cash-account/update-spec';
import { UserCashAccountRepositoryType } from 'core/repository/personal-finance/cash-account';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class UpdateUserCashAccount extends UseCase<UserCashAccount> {
    id: string;
    spec: UserCashAccountUpdateSpec;
    private repository: UserCashAccountRepositoryType;

    constructor(repository: UserCashAccountRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserCashAccount> {
        return this.repository.update(this.id, this.spec);
    }

    protected validate(): boolean {
        return !!this.id && !!this.spec;
    }

}
