import { Service } from 'core/service';
import { MedicalCheckupRepositoryType } from 'core/repository/medical-check-up';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { MedicalCheckupSummary } from 'core/entity/medical-checkup/summary';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';

export class MedicalCheckupService implements Service {
    private repository: MedicalCheckupRepositoryType;

    constructor(
        repository: MedicalCheckupRepositoryType
    ) {
        this.repository = repository;
    }

    getSummary = (): Observable<MedicalCheckupSummary> => (
        this.repository.getSummary()
    );

    getJudgement = (): Observable<List<MedicalCheckupJudgement>> => (
        this.repository.getJudgement()
    );

    setJudgement = (spec: List<MedicalCheckupJudgement>): Observable<void> => (
        this.repository.setJudgement(spec)
    );
}
