import { FinanceSubSectorItemSummary } from 'core/entity/finance-sector/item/summary';
import { FinanceSubSectorContentSummary } from 'core/entity/finance-sector/content-summary';
import { Entity } from 'core/entity';
import { FinanceSectorItemBadge } from 'core/entity/finance-sector/item/badge';

export class FinanceSubSectorContentItemSummary extends Entity implements FinanceSubSectorItemSummary {
    name: string;
    badge?: FinanceSectorItemBadge;
    content: FinanceSubSectorContentSummary;

    constructor(
        name: string,
        content: FinanceSubSectorContentSummary,
        badge?: FinanceSectorItemBadge
    ) {
        super();
        this.name = name;
        this.content = content;
        this.badge = badge;
    }
}
