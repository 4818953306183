import { UserLoanRepositoryType } from 'core/repository/personal-finance/loan';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserLoanUpdateSpec } from 'core/entity/personal-finance/loan/update-spec';
import { UserLoansApiProvider } from 'data/http/api/gateway/v2/users/loans';
import { UserLoanCreateSpec } from 'core/entity/personal-finance/loan/create-spec';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserLoanRepository implements UserLoanRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserLoansApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserLoansApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    getAll(): Observable<List<UserLoan>> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.getAll(token)
        )
    }

    get(id: string): Observable<UserLoan> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.get(token, id)
        )
    }

    create(spec: UserLoanCreateSpec): Observable<UserLoan> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.create(token, spec)
        )
    }

    update(id: string, spec: UserLoanUpdateSpec): Observable<UserLoan> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.update(token, id, spec)
        )
    }

}
