import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { PresentableAsset } from 'presentation/module/extension/personal-finance/base';

declare module 'core/entity/personal-finance/securities-firm-account' {
    export interface UserSecuritiesFirmAccount extends PresentableAsset {
        isExpired: () => boolean;
    }
}

UserSecuritiesFirmAccount.prototype.presentName = function() {
    return this.nickname ?
        this.nickname :
        this.primaryData.spider ?
            (this.primaryData.spider.name || this.number) :
            this.number;
};

UserSecuritiesFirmAccount.prototype.isExpired = function() {
    return this.closed || this.dormant;
};
