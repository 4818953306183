import { NetworkResponseMapper } from 'data/http/mapper';
import { UserProductConfiguration } from 'core/entity/personal-finance/product-configuration';
import { AmountMapper } from 'data/http/mapper/amount';
import { List } from 'immutable';
import { UserActualAssetMapper } from 'data/http/mapper/personal-finance/actual-asset';
import { UserBankAccountMapper } from 'data/http/mapper/personal-finance/bank-account';
import { UserCardMapper } from 'data/http/mapper/personal-finance/card';
import { UserCashAccountMapper } from 'data/http/mapper/personal-finance/cash-account';
import { UserLoanMapper } from 'data/http/mapper/personal-finance/loan';
import { UserSecuritiesFirmAccountMapper } from 'data/http/mapper/personal-finance/securities-firm-account';
import { UserFundMapper } from 'data/http/mapper/personal-finance/fund';
import { UserStockMapper } from 'data/http/mapper/personal-finance/stock';

export class UserProductConfigurationMapper implements NetworkResponseMapper<UserProductConfiguration> {
    fromJson(json: any): UserProductConfiguration {
        return new UserProductConfiguration(
            List(json.actual_assets.map((it: any) =>
                new UserActualAssetMapper().fromJson(it)
            )),
            List(json.bank_accounts.map((it: any) =>
                new UserBankAccountMapper().fromJson(it)
            )),
            List(json.cards.map((it: any) =>
                new UserCardMapper().fromJson(it)
            )),
            List(json.cash_accounts.map((it: any) =>
                new UserCashAccountMapper().fromJson(it)
            )),
            List(json.loans.map((it: any) =>
                new UserLoanMapper().fromJson(it)
            )),
            List(json.securities_firm_accounts.map((it: any) =>
                new UserSecuritiesFirmAccountMapper().fromJson(it)
            )),
            List(json.funds.map((it: any) =>
                new UserFundMapper().fromJson(it)
            )),
            List(json.stocks.map((it: any) =>
                new UserStockMapper().fromJson(it)
            )),
            new UserProductConfiguration.AmountSummary(
                new AmountMapper().fromJson(json.amount_summary.asset),
                new AmountMapper().fromJson(json.amount_summary.debt),
                new AmountMapper().fromJson(json.amount_summary.investment)
            )
        )
    }

}
