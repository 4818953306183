import React from 'react';
import { List } from 'immutable';

import styles from './styles.pcss';

interface Props {
    lists: List<any>;
    current: number;
    onChange: (order: number) => void;
}

export const BannerOrderList = (props: Props) => {
    const {
        lists,
        current,
        onChange
    } = props;

    const onClick = (order: number) => () => {
        onChange(order);
    };

    return (
        <ol className={ styles.orderList }>
            {
                lists.map((_, i) => {
                    const active = current === i;
                    const blindText =
                        `${i + 1} / ${lists.size} 이동`;

                    return (
                        <li
                            key={ `order-list-item-${i}` }
                            className={ styles.orderItem }
                        >
                            <button
                                onClick={ onClick(i) }
                                className={
                                    active ?
                                        styles.activeOrderButton :
                                        styles.orderButton
                                }
                            >
                                <span className="blind">
                                    { blindText }
                                </span>
                            </button>
                        </li>
                    )
                })
            }
        </ol>
    )
};
