import { SpiderFundData } from 'core/entity/personal-finance/spider-data/fund';
import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { lets, optional, compactObject } from 'utils/index';

export class SpiderFundDataMapper implements
    NetworkResponseMapper<SpiderFundData>,
    NetworkRequestMapper<SpiderFundData>
{
    fromJson(json: any): SpiderFundData {
        return new SpiderFundData(
            json.name,
            lets(json.company_type, it => {
                switch (it) {
                    case 'bank':
                        return SpiderFundData.CompanyType.BANK;
                    case 'securities_firm':
                        return SpiderFundData.CompanyType.SECURITIES_FIRM;
                    default:
                        return null;
                }
            }),
            optional(json.response_contained)
        )
    }

    toJson(target: SpiderFundData): any {
        return compactObject({
            name: target.name,
            company_type: lets(target.companyType, it => {
                switch (it) {
                    case SpiderFundData.CompanyType.BANK:
                        return 'bank';
                    case SpiderFundData.CompanyType.SECURITIES_FIRM:
                        return 'securities_firm';
                    default:
                        return null;
                }
            }),
            response_contained: optional(target.responseContained)
        });
    }
}
