import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, optional } from 'utils/index';
import { UserSecuritiesFirmAccountCreateSpec } from 'core/entity/personal-finance/securities-firm-account/create-spec';

export class UserSecuritiesFirmAccountCreateSpecMapper implements NetworkRequestMapper<UserSecuritiesFirmAccountCreateSpec> {
    toJson(target: UserSecuritiesFirmAccountCreateSpec): any {
        return compactObject({
            securities_firm_id: target.securitiesFirm.id,
            nickname: target.nickname,
            latest_balance: target.latestBalance,
            hidden: target.hidden,
            description: optional(target.description)
        })
    }
}
