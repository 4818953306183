import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { FinanceSubSectorSummary } from 'core/entity/finance-sector/sub/summary';
import { FinanceSubSectorItemSummaryMapper } from 'data/http/mapper/finance-sector/item/summary';

export class FinanceSubSectorSummaryMapper implements NetworkResponseMapper<FinanceSubSectorSummary> {
    fromJson(json: any): FinanceSubSectorSummary {
        return new FinanceSubSectorSummary(
            json.name,
            List(json.items.map((item: any) =>
                new FinanceSubSectorItemSummaryMapper().fromJson(item)
            ))
        )
    }
}
