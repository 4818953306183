import { Entity } from 'core/entity';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';
import { Amount } from 'core/entity/amount';
import { List } from 'immutable';
import { AdviceTransactionSnapshot } from 'core/entity/personal-finance/advice/transaction-snapshot';

export class AdviceCardCompanyInstallmentSnapshot extends Entity {
    company: SpiderCardCompanySummary;
    installmentAmount: Amount;
    transactions: List<AdviceTransactionSnapshot>;

    constructor(
        company: SpiderCardCompanySummary,
        installmentAmount: Amount,
        transactions: List<AdviceTransactionSnapshot>,
    ) {
        super();
        this.company = company;
        this.installmentAmount = installmentAmount;
        this.transactions = transactions;
    }
}
