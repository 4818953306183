import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV3PrivacyAgreementRoute } from 'presentation/routes/policies/v3/privacy-agreement';
import { PoliciesV3TermsRoute } from 'presentation/routes/policies/v3/terms';
import { PoliciesV3PrivacyPolicyRoute } from 'presentation/routes/policies/v3/privacy-policy';

export const PoliciesV3Route = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/privacy-policy`} component={ PoliciesV3PrivacyPolicyRoute } />
        <Route path={ `${match.url}/privacy-agreement`} component={ PoliciesV3PrivacyAgreementRoute } />
        <Route path={ `${match.url}/terms`} component={ PoliciesV3TermsRoute } />
    </Switch>
);
