import { CardAnnualCostPromotion } from 'core/entity/card/annual-cost/promotion';
import { currentMonthLastDate } from 'presentation/resources/card/promotion-cards';
import React from 'react';
import styles from './styles.pcss';

interface PropsBanner1 {
  data: CardAnnualCostPromotion;
}

export const CardPromotionBanner = (props: PropsBanner1) => {
  const { data }= props;

  return (
    <div className={ styles.wrap }>
      <strong className={ styles.title }>{ data.summary }</strong>
      <span className={ styles.desc }>
        지금 뱅크샐러드를 통해 카드 신청하고 연회비 지원 받으세요!
      </span>
      <span className={ styles.date }>행사기간 { data.endDate.format('YYYY-MM-DD') } 까지</span>
    </div>
  );
};

interface PropsBanner2 {
  bannerText: string;
}

export const CardPromotionBanner2 = ({ bannerText }: PropsBanner2) => (
  <div className={ styles.wrap }>
    <strong className={ styles.title }>{bannerText}</strong>
    <span className={ styles.desc }>
      지금 뱅크샐러드를 통해 이벤트 응모하고 혜택을 받으세요!
    </span>
    <span className={ styles.date }>{`행사기간 ${currentMonthLastDate} 까지`}</span>
  </div>
);
