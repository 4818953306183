import { UserLoan } from 'core/entity/personal-finance/loan';
import { PresentableAsset } from 'presentation/module/extension/personal-finance/base';

declare module 'core/entity/personal-finance/loan' {
    export interface UserLoan extends PresentableAsset {
        isExpired: () => boolean;
    }
}

UserLoan.prototype.presentName = function() {
    return this.nickname ?
        this.nickname :
        this.primaryData.spider ?
            (this.primaryData.spider.name || this.company.name) :
            this.company.name
};

UserLoan.prototype.isExpired = function() {
    return this.closed || this.dormant;
};
