import { Application } from 'application/context.instance';
import { Card } from 'core/entity/card';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { List } from 'immutable';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Header } from 'presentation/components/header';
import { EverydayPromotionCardsBanner } from 'presentation/components/horizontal-list/everyday-promotion-cards-banner';
import { LandingContentSlider } from 'presentation/components/landing-content-slider';
import {
  LandingContentSliderModel,
  LandingContentSliderType,
} from 'presentation/components/landing-content-slider/model';
import { LandingEvents } from 'presentation/components/landing-events';
import { LandingEventModel } from 'presentation/components/landing-events/model';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { SectorContentsRecommend } from 'presentation/components/sector-contents/recommend-type';
import { CLIX, sendClixEvent } from 'presentation/module/analytics/clix';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { EMERGENCY_CARD_SECTOR } from 'presentation/module/emergency-contents/sectors/card/emergency';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP, BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { getBenefitSumText } from 'presentation/routes/sectors/card/utils';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { emptyList } from 'utils/index';


import styles from './styles.pcss';

type Props = RouteComponentProps<any>;

interface State {
  sectorFetchState: FetchState;
  etcFetchState: FetchState;
  sector?: FinanceSector;
  themes: List<LandingContentSliderModel>;
  events: List<LandingEventModel>;
  cardBenefitSumText: string;
}

export class SectorsCard extends React.Component<Props, State> {
  state = {
    sectorFetchState: FetchState.FETCHING,
    etcFetchState: FetchState.FETCHING,
    sector: null as FinanceSector,
    themes: emptyList(),
    events: emptyList(),
    ranking: emptyList(),
    cardBenefitSumText: '',
  };

  private subscriptionBag = new SubscriptionBag();

  constructor(props: Props) {
    super(props);
    sendPixelEvent(PIXEL.SECTORS_CARD);
    sendClixEvent(CLIX.SECTORS_CARD);
  }

  componentDidMount() {
    this.fetchSector();
    (async () => {
      this.setState({ cardBenefitSumText: await getBenefitSumText() });
    })();
  }

  componentWillUnmount() {
    this.subscriptionBag.destroy();
  }

  render() {
    const { sectorFetchState, etcFetchState, sector, themes, events, cardBenefitSumText } =
      this.state;

    return (
      <div className={ styles.background }>
        <MetaHelmet meta={ META_SET.SECTORS_CARD } />
        <Header active={ FinanceSectorID.CARD } />
        <section className={ styles.coverWrap }>
          <div className={ styles.coverContainer }>
            <div className={ styles.coverTitle }>
              이벤트 응모하고
              <br />
              카드 사용하면
              <br />
              {cardBenefitSumText} 받아요!
            </div>
            <p className={ styles.coverDescription }>기간 한정 이벤트이니 지금 참여하세요.</p>
            <div className={ styles.coverRedirect }>
              <a
                href={ BANKSALAD_SITEMAP.CARDS_PROMOTIONS_BEST_CREDIT_CARDS }
                onClick={ this.onHeroButtonClicked }
                className={ styles.mustardButton }
              >
                이벤트 보기
              </a>
            </div>
          </div>
        </section>
        {this.renderCardsInPromotion()}
        {sector && sectorFetchState === FetchState.FETCHED ? (
          <>
            {!sector.generateLandingRecommends().isEmpty() && (
              <section className={ styles.section }>
                <div className={ styles.container }>
                  <h3 className={ styles.sectionTitle }>뱅크샐러드 Pick!</h3>
                  <SectorContentsRecommend
                    contents={ sector.generateLandingRecommends() }
                    gaCategory={ GA_CATEGORY.SECTORS.PICK }
                    url="https://banksalad.com/articles/%ED%98%9C%ED%83%9D-%EC%A2%8B%EC%9D%80-%EC%8B%A0%EC%9A%A9%EC%B9%B4%EB%93%9C-%EC%B6%94%EC%B2%9C-7%EC%A2%85-%EC%88%9C%EC%9C%84-2022"
                  />
                </div>
              </section>
            )}

            {!themes.isEmpty() && etcFetchState === FetchState.FETCHED && (
              <section className={ styles.section }>
                <div className={ styles.container }>
                  <h3 className={ styles.sectionTitle }>
                    테마별 추천카드
                    <a
                      href={ BANKSALAD_SITEMAP.CARDS_THEMES }
                      className={ styles.total }
                      onClick={ () =>
                        sendGAEvent(
                          GA_DOMAIN.CARD,
                          GA_CATEGORY.CARDS_HOW_IT_WORKS.CONTENTS,
                          GA_ACTION.LINK.CARDS_THEMES,
                          '전체보기',
                        ) }
                    >
                      전체보기
                    </a>
                  </h3>

                  <LandingContentSlider
                    themeType={ LandingContentSliderType.CARD_THEME }
                    themes={ themes }
                    gaCategory={ GA_CATEGORY.CARDS_HOW_IT_WORKS.THEMES }
                  />
                  <a href={ BANKSALAD_SITEMAP.CARDS_THEMES } className={ styles.themesMore }>
                    추천카드 더보기
                  </a>
                </div>
              </section>
            )}

            {!events.isEmpty() && etcFetchState === FetchState.FETCHED && (
              <section className={ styles.section }>
                <div className={ styles.container }>
                  <h3 className={ styles.sectionTitle }>
                    카드사 이벤트 모니터링
                    <a
                      href={ BANKSALAD_SITEMAP.CARDS_EVENTS }
                      className={ styles.total }
                      onClick={ () =>
                        sendGAEvent(
                          GA_DOMAIN.CARD,
                          GA_CATEGORY.CARDS_HOW_IT_WORKS.EVENTS,
                          GA_ACTION.LINK.CARDS_EVENTS,
                          '전체보기',
                        ) }
                    >
                      전체보기
                    </a>
                  </h3>
                  <LandingEvents
                    events={ events }
                    gaCategory={ GA_CATEGORY.CARDS_HOW_IT_WORKS.EVENTS }
                  />
                </div>
              </section>
            )}
          </>
        ) : sectorFetchState === FetchState.FETCHING ? (
          <Loader padding={ 200 } radius={ 25 } />
        ) : (
          <FetchError padding={ 200 }>
            일시적인 오류가 발생했습니다
            <br />
            잠시 후 재시도 해주세요
          </FetchError>
        )}
      </div>
    );
  }

  private fetchSector = () => {
    this.setState(
      {
        sectorFetchState: FetchState.FETCHING,
      },
      () =>
        Application.useCases.getCardSector
          .runOnAnimateFrame()
          .subscribe(
            sector =>
              this.setState({
                sectorFetchState: FetchState.FETCHED,
                sector,
              }),
            () =>
              this.setState({
                sectorFetchState: FetchState.FETCHED,
                sector: EMERGENCY_CARD_SECTOR,
              }),
          )
          .unsubscribeBy(this.subscriptionBag),
    );
  };

  private clearCache = () => {
    Application.useCases.clearCachedCardRecommendSpec
      .runOnAnimateFrame()
      .subscribe()
      .unsubscribeBy(this.subscriptionBag);
  };

  private onHeroButtonClicked = () => {
    sendGAEvent(
      GA_DOMAIN.CARD,
      GA_CATEGORY.SECTOR_CARD_HERO,
      GA_ACTION.LINK.CARDS_PROMOTIONS_BEST_CREDIT_CARDS,
      '카드 이벤트',
    );
    this.clearCache();
  };

  private renderCardsInPromotion = () => (
    <section className={ styles.section }>
      <div className={ styles.container }>
        <h3 className={ styles.sectionTitle }>
          <div>뱅크샐러드는 매일 연회비 할인</div>
          <a
            href={ BANKSALAD_SITEMAP.CARDS_PROMOTION_ANNUAL_FEE }
            className={ styles.total }
            onClick={ this.onClickShowAllOfPromotions }
          >
            전체보기
          </a>
        </h3>
        <EverydayPromotionCardsBanner />
      </div>
    </section>
  );

  private onClickShowAllOfPromotions = () =>
    sendGAEvent(
      GA_DOMAIN.CARD,
      GA_CATEGORY.CARDS_HOW_IT_WORKS.IN_PROMOTION,
      GA_ACTION.LINK.CARDS_PROMOTION_ANNUAL_FEE,
      '전체보기',
    );
}
