import { Observable } from 'rxjs/Rx';
import { SVGRepositoryType } from 'core/repository/svg';
import { SVGApiProvider } from 'data/http/api/cdn/svg/index';
import { SVG } from 'core/entity/svg';

export class SVGRepository implements SVGRepositoryType {
    private api: SVGApiProvider;

    constructor(api: SVGApiProvider) {
        this.api = api;
    }

    get(url: string): Observable<SVG> {
        return this.api.get(url);
    }
}
