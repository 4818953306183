import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreChangeRecordStatus } from 'core/entity/personal-credit/change-record-status';
import { UserCreditScoreChangeRecordMapper } from 'data/http/mapper/personal-credit/change-record';

export class UserCreditScoreChangeRecordStatusMapper implements NetworkResponseMapper<UserCreditScoreChangeRecordStatus> {
    private updatedAt: Date;
    private changed: boolean;

    constructor(
        updatedAt: Date,
        changed: boolean
    ) {
        this.updatedAt = updatedAt;
        this.changed = changed;
    }

    fromJson(json: any): UserCreditScoreChangeRecordStatus {
        return new UserCreditScoreChangeRecordStatus(
            json.map(new UserCreditScoreChangeRecordMapper().fromJson).toList(),
            this.updatedAt,
            this.changed
        )
    }

}
