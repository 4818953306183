import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { FundPrimaryDataMapper } from 'data/http/mapper/personal-finance/primary-data/fund';
import { parseDate, optional, compactObject } from 'utils/index';
import { UserFund } from 'core/entity/personal-finance/fund';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { FundCompanySummaryMapper } from 'data/http/mapper/personal-finance/fund-company/summary';
import { AmountMapper } from 'data/http/mapper/amount';

export class UserFundMapper implements
    NetworkResponseMapper<UserFund>,
    NetworkRequestMapper<UserFund>
{
    fromJson(json: any): UserFund {
        return new UserFund(
            json.id,
            json.hidden,
            new DeletedStatusMapper().fromJson(json.deleted_status),
            json.closed,
            new FundCompanySummaryMapper().fromJson(json.company),
            new AmountMapper().fromJson(json.principal),
            new FundPrimaryDataMapper().fromJson(json.primary_data),
            optional(json.nickname),
            optional(json.description),
            optional(json.valuation, new AmountMapper().fromJson),
            optional(json.quantity),
            optional(json.created_at, parseDate),
            optional(json.expired_at, parseDate)
        )
    }

    toJson(target: UserFund): any {
        return compactObject({
            id: target.id,
            hidden: target.hidden,
            deleted_status: new DeletedStatusMapper().toJson(target.deletedStatus),
            closed: target.closed,
            company: new FundCompanySummaryMapper().toJson(target.company),
            principal: new AmountMapper().toJson(target.principal),
            primary_data: new FundPrimaryDataMapper().toJson(target.primaryData),
            nickname: optional(target.nickname),
            description: optional(target.description),
            valuation: optional(target.valuation, new AmountMapper().toJson),
            quantity: optional(target.quantity),
            created_at: optional(target.createdAt),
            expired_at: optional(target.expiredAt)
        })
    }

}
