import React from 'react';

import { InputCurrency } from 'presentation/components/input/currency';
import {
    MediaDesktop,
    MediaTabletMobile
} from 'presentation/components/responsive/media-component';
import { sendGAEvent, GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { CreditLoanAmount } from 'presentation/components/survey-form/credit-loan-amount/model';

import styles from './styles.pcss';
const TEN_THOUSAND = 10000;
const MAX = 200000;

interface Props {
    data: CreditLoanAmount,
    onChange?: (result: CreditLoanAmount) => void
}

export class CreditLoanAmountForm extends React.Component<Props, undefined> {

    render() {
        const PRESET = [
            { value: 3000000, text: '300만' },
            { value: 5000000, text: '500만' },
            { value: 7000000, text: '700만' },
            { value: 10000000, text: '1,000만' },
            { value: 30000000, text: '3,000만' },
            { value: 50000000, text: '5,000만' },
            { value: 70000000, text: '7,000만' },
            { value: 100000000, text: '1억' }
        ];
        const { data } = this.props;
        const overflowMessage = '너무 큰 금액은 입력할 수 없습니다';

        return (
            <div>
                <div className={ styles.amount }>
                    <MediaDesktop>
                        <InputCurrency
                            value={ Math.floor(data.value / TEN_THOUSAND) }
                            color="#04C584"
                            fontSize={ 20 }
                            textAlign="right"
                            overflowLimit={ MAX }
                            overflowMessage={ overflowMessage }
                            onChange={ result => this.onChange(result * TEN_THOUSAND) }
                        />
                    </MediaDesktop>
                    <MediaTabletMobile>
                        <InputCurrency
                            value={ Math.floor(data.value / TEN_THOUSAND) }
                            color="#04C584"
                            fontSize={ 16 }
                            textAlign="right"
                            overflowLimit={ MAX }
                            overflowMessage={ overflowMessage }
                            onChange={ result => this.onChange(result * TEN_THOUSAND) }
                        />
                    </MediaTabletMobile>
                    <span className={ styles.amountCurrency }>만원</span>
                    <button
                        className={ styles.amountClear }
                        onClick={ this.onClear }
                    >
                        초기화
                    </button>
                </div>
                <ul className={ styles.preset }>
                    {
                        PRESET.map(item =>
                            <li key={ item.text }>
                                <button
                                    className={ styles.tag }
                                    onClick={ this.onChangeByShortcut(item.value) }
                                >
                                    { item.text }
                                </button>
                            </li>
                        )
                    }
                </ul>
            </div>
        )
    }

    private onClear = () => {
        sendGAEvent(
            GA_DOMAIN.LOANS,
            GA_CATEGORY.LOANS_QUESTIONS.AMOUNT,
            GA_ACTION.CLEAR
        );
        this.props.onChange(
            new CreditLoanAmount(0)
        )
    }

    private onChange = (amount: number) => {
        sendGAEvent(
            GA_DOMAIN.LOANS,
            GA_CATEGORY.LOANS_QUESTIONS.AMOUNT,
            GA_ACTION.INPUT,
            amount.toString()
        );
        this.props.onChange(
            new CreditLoanAmount(amount)
        )
    };

    private onChangeByShortcut = (amount: number) => () => {
        sendGAEvent(
            GA_DOMAIN.LOANS,
            GA_CATEGORY.LOANS_QUESTIONS.AMOUNT,
            GA_ACTION.INPUT_SHORTCUT,
            amount.toString()
        );
        this.props.onChange(
            new CreditLoanAmount(amount)
        )
    }
}
