import { SpiderInsuranceData } from 'core/entity/personal-finance/spider-data/insurance';
import { MappingError, NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { compactObject, lets, optional } from 'utils/index';

export class SpiderInsuranceDataMapper implements
    NetworkResponseMapper<SpiderInsuranceData>,
    NetworkRequestMapper<SpiderInsuranceData>
{
    fromJson(json: any): SpiderInsuranceData {
        return new SpiderInsuranceData(
            json.name,
            lets(json.datasource, it => {
                switch (it) {
                    case 'insurance_company':
                        return SpiderInsuranceData.dataSource.INSURANCE_COMPANY;
                    case 'credit4u':
                        return SpiderInsuranceData.dataSource.CREDIT4U;
                    default:
                        throw new MappingError();
                }
            }),
            optional(json.response_contained)
        )
    }

    toJson(target: SpiderInsuranceData): any {
        return compactObject({
            name: target.name,
            datasource: lets(target.dataSource, it => {
                switch (it) {
                    case SpiderInsuranceData.dataSource.INSURANCE_COMPANY:
                        return 'insurance_company';
                    case SpiderInsuranceData.dataSource.CREDIT4U:
                        return 'credit4u';
                    default:
                        throw new MappingError();
                }
            }),
            response_contained: optional(target.responseContained)
        });
    }
}
