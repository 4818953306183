import { Entity } from 'core/entity';
import { List } from 'immutable';
import { CachedStateItem } from 'core/entity/cached-state/item';

export interface CachedState {
    items: List<CachedStateItem>;
}

export class LocalCachedState extends Entity implements CachedState {
    items: List<CachedStateItem>;

    constructor(items: List<CachedStateItem>) {
        super();
        this.items = items;
    }
}

export class SessionCachedState extends Entity implements CachedState {
    items: List<CachedStateItem>;

    constructor(items: List<CachedStateItem>) {
        super();
        this.items = items;
    }
}
