import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { Header } from 'presentation/components/header';
import { Footer } from 'presentation/components/footer';
import { PageNotFound } from 'presentation/routes/errors/page-not-found';
import { PageOnError } from 'presentation/routes/errors/page-on-error';

export const ErrorsRoute = ({ match }: RouteComponentProps<any>) => (
    <div>
        <Header />
        <Switch>
            <Route path={ `${match.url}/page-not-found` } component={ PageNotFound } />
            <Route path={ `${match.url}/page-on-error` } component={ PageOnError } />
        </Switch>
        <Footer />
    </div>
);
