import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanPrimeInterestNode } from 'core/entity/loan/prime-interest-node';
import { List } from 'immutable';
import { optional } from 'utils/index';
import { LoanPrimeInterestNodeStatusMapper } from 'data/http/mapper/loan/prime-interest-node-status';

export class LoanPrimeInterestNodeMapper implements NetworkResponseMapper<LoanPrimeInterestNode> {
    fromJson(json: any): LoanPrimeInterestNode {
        return new LoanPrimeInterestNode(
            json.id,
            json.priority,
            json.level,
            json.description,
            json.description_warning,
            json.maximum_interest,
            List(json.children.map((item: any) =>
                new LoanPrimeInterestNodeMapper().fromJson(item)
            )),
            optional(json.status, it =>
                new LoanPrimeInterestNodeStatusMapper().fromJson(it)
            )
        )
    }

}
