import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardSpecialBenefit } from 'core/entity/card/special-benefit';

export class CardPreferredSpecialBenefit extends Entity {
    title: string;
    benefits: List<CardSpecialBenefit>;

    constructor(
        title: string,
        benefits: List<CardSpecialBenefit>
    ) {
        super();
        this.title = title;
        this.benefits = benefits;
    }
}
