import React from 'react';

import { META_SET } from 'presentation/view-model/meta-set/preset';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { PoliciesV1ThirdPartiesKcbCreditGoalView } from 'presentation/routes/policies/v1/third-parties/kcb/credit-goal/view';

export const PoliciesV1ThirdPartiesKcbCreditGoalRoute = () => (
    <>
        <MetaHelmet meta={ META_SET.THIRD_PARTY }/>
        <PoliciesV1ThirdPartiesKcbCreditGoalView/>
    </>
);
