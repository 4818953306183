import {
  sendTrackEventV3,
  TrackEventName,
} from 'presentation/module/analytics/trackevent';

const CATEGORY_VIEW_NAME = 'service_center_category';
const ARTICLE_VIEW_NAME = 'service_center_article';

export const trackEventForExposeServiceCenterListItem = (
  id: number,
  name: string,
  isArticle: boolean,
) => (
  sendTrackEventV3(
    TrackEventName.EXPOSE_VIEW,
    isArticle ? ARTICLE_VIEW_NAME : CATEGORY_VIEW_NAME,
    {
      object_id: id.toString(),
      object_name: name,
    },
  )
);

export const trackEventForClickServiceCenterListItem = (
  id: number,
  name: string,
  isArticle: boolean,
) => (
  sendTrackEventV3(
    TrackEventName.CLICK,
    isArticle ? ARTICLE_VIEW_NAME : CATEGORY_VIEW_NAME,
    {
      object_id: id.toString(),
      object_name: name,
    },
  )
);
