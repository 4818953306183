import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { UserSex } from 'core/entity/user/sex';

export class UserSexMapper implements
    NetworkRequestMapper<UserSex>,
    NetworkResponseMapper<UserSex>
{
    fromJson(json: any): UserSex {
        switch (json) {
            case 'male':
                return UserSex.MALE;
            case 'female':
                return UserSex.FEMALE;
            case 'unknown':
            default:
                return UserSex.UNKNOWN;
        }
    }

    toJson(target: UserSex): any {
        switch (target) {
            case UserSex.MALE:
                return 'male';
            case UserSex.FEMALE:
                return 'female';
            case UserSex.UNKNOWN:
            default:
                return 'unknown';
        }
    }

}
