import { Application } from 'application/context.instance';
import { Card } from 'core/entity/card';
import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Loader } from 'presentation/components/loader';
import { SubscriptionBag } from 'presentation/module/extension';
import { isMobile } from 'presentation/module/sugar';
import { FetchState } from 'presentation/view-model/fetch-state';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { apply } from 'utils/index';

import styles from './styles.pcss';

interface State {
    card: Card;
    config: CardLegalConfiguration;
    fetchState: FetchState;
    siteFetchState: FetchState;
}

export class CardDetailOnlineIssue extends React.Component<RouteComponentProps<any>, State> {
    state = {
      card: null as Card,
      config: null as CardLegalConfiguration,
      fetchState: FetchState.FETCHING,
      siteFetchState: FetchState.FETCHING
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
      this.fetchCard(this.props.match.params.id);
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    render() {
      const { config, fetchState } = this.state;

      return (
        <div className={ styles.wrap }>
          {
            fetchState === FetchState.FETCHED ? (
              config.issuingDisabled && this.renderLegalInfo()
            ) : fetchState === FetchState.FETCHING ? (
              this.renderLoading()
            ) : (
              this.renderError()
            )
          }
        </div>
      );
    }

    private redirectIssuePage = () => {
      const { card } = this.state;

      location.href = isMobile() ? card.issueUrlMobile : card.issueUrlDesktop;
    };

    private renderLegalInfo = () => (
      <p className={ styles.warning }>
            카드사의 요청에 의해 일시적으로 뱅크샐러드 내 발급이 중단되었습니다.<br />
            해당 카드사의 홈페이지를 이용해 주시기 바랍니다
      </p>
    );

    private renderLoading = () => (
      <div className={ styles.loading }>
        <Loader padding={ 12 } radius={ 15 } />
            카드신청 페이지를 불러오는 중 입니다
      </div>
    );

    private renderError = () => (
      <FetchError padding={ 100 }>
            카드신청 페이지를 불러오는 중 오류가 발생했습니다<br/>
            잠시 후 다시 시도해주세요<br/>
        <button
          className={ styles.retryButton }
          onClick={ this.onRetry }
        >
                다시 시도
        </button>
      </FetchError>
    );

    private fetchCard = (id: number) =>
      apply(Application.useCases.getCard, it => {
        it.id = id;
      }).runOnAnimateFrame().subscribe(
        card => this.setState({ card }, () => this.fetchLegalConfig(id)),
        () => this.setState({ fetchState: FetchState.ERROR })
      ).unsubscribeBy(this.subscriptionBag);

    private fetchLegalConfig = (id: number) =>
      apply(Application.useCases.getCardLegalConfigByCardId, it => {
        it.cardId = id;
      }).runOnAnimateFrame().subscribe(
        config => {
          config.issuingDisabled ?
            this.setState({
              config,
              fetchState: FetchState.FETCHED
            }) :
            this.redirectIssuePage();
        },
        () => this.redirectIssuePage()
      ).unsubscribeBy(this.subscriptionBag);

    private onRetry = () =>
      location.reload();
}
