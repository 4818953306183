import { NetworkResponseMapper, MappingError } from 'data/http/mapper';
import { Alfred2CardAnnualCostPromotion } from 'core/entity/alfred2/card-annual-cost-promotion';
import { lets } from 'utils/index';

export class Alfred2CardAnnualCostPromotionMapper implements NetworkResponseMapper<Alfred2CardAnnualCostPromotion> {
    fromJson(json: any): Alfred2CardAnnualCostPromotion {
        return new Alfred2CardAnnualCostPromotion(
            json.title,
            json.cost,
            json.link,
            lets(json.type, it => {
                switch (it) {
                    case 'discount':
                        return Alfred2CardAnnualCostPromotion.Type.DISCOUNT;
                    case 'cashback':
                        return Alfred2CardAnnualCostPromotion.Type.CASHBACK;
                    case 'point':
                        return Alfred2CardAnnualCostPromotion.Type.POINT;
                    case 'mileage':
                        return Alfred2CardAnnualCostPromotion.Type.MILEAGE;
                    default:
                        throw new MappingError();
                }
            })
        );
    }
}
