import { Observable } from 'rxjs/Rx';
import { CreditScore } from 'core/entity/personal-credit/score/index';
import { UserCreditScoresFromKCBApiProvider } from 'src/data/http/api/gateway/v2/me/credit-scores/kcb/index';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { CreditScoreRepositoryType } from 'core/repository/personal-credit/score';

export class CreditScoreRepository implements CreditScoreRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserCreditScoresFromKCBApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserCreditScoresFromKCBApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    get(): Observable<CreditScore> {
        return this.tokenStorage.get()
            .flatMap(it => this.api.get(it));
    }
}
