import { Card } from 'core/entity/card';
import { CardPremiumVoucher } from 'core/entity/card/premium-voucher';
import { CardPremiumVoucherBenefit } from 'core/entity/card/premium-voucher-benefit';
import { List } from 'immutable';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, GA_EVENT_VALUE } from 'presentation/module/analytics/ga';
import React from 'react';
import { uniqueKey } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    card: Card;
}

export const CardInfoPremiumVouchers = ({ card }: Props) => (
  <>
    <div className={ styles.guide }>
      <h5>프리미엄 바우처 안내</h5>
      <p>
        { card.name } 고객님을 위한 프리미엄 통합바우처 매년 1회 제공
      </p>
    </div>
    { renderVouchers(card) }
  </>
);

const renderVouchers = (
  card: Card
) => {
  const { premiumVouchers } = card;

  const onChangeInput = (voucher: CardPremiumVoucher) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      sendGAEvent(
        GA_DOMAIN.CARD,
        GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_VOUCHER,
        GA_ACTION.DROP_DOWN,
        voucher.title,
        e.currentTarget.checked ?
          GA_EVENT_VALUE.OPEN :
          GA_EVENT_VALUE.CLOSE
      );
    };

  const toDetails = (benefits: List<CardPremiumVoucherBenefit>) => (
    benefits.map(benefit => (
      <div key={ uniqueKey() } className={ styles.spec }>
        <h6>{ benefit.title }</h6>
        <span dangerouslySetInnerHTML={{ __html: benefit.description }}/>
      </div>
    ))
  );

  const toItems = (vouchers: List<CardPremiumVoucher>) => (
    vouchers.map(voucher => {
      const key = uniqueKey();

      return (
        <li key={ key }>
          <input
            type="checkbox"
            id={ key }
            onChange={ onChangeInput(voucher) }
          />
          <label htmlFor={ key }>{ voucher.title }</label>
          <section className={ styles.detail }>
            { toDetails(voucher.benefits) }
          </section>
        </li>
      );
    })
  );

  const groups = premiumVouchers.map(voucher =>
    <li key={ uniqueKey() }>
      <h5>
                프리미엄 바우처
        <small>
          { voucher.numberOfVoucher }가지 옵션 중 택{ voucher.numberOfSelect }
        </small>
      </h5>
      <ul className={ styles.items }>
        { toItems(voucher.vouchers) }
      </ul>
    </li>
  );

  return <ul className={ styles.benefits }>{ groups }</ul>;
};
