import React from 'react';

import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { Card } from 'core/entity/card';
import { CardInfoAnnualCost } from 'presentation/components/info/card/detail/annual-cost';
import { CardInfoRestrictions } from 'presentation/components/info/card/detail/restrictions';
import { CardInfoSpecialBenefits } from 'presentation/components/info/card/detail/special-benefits';
import { CardInfoBenefits } from 'presentation/components/info/card/detail/benefits';
import { optional } from 'utils/index';
import { CardInfoRepresentativeDescription } from 'presentation/components/info/card/detail/representative-description';
import { CardInfoUnit } from 'presentation/components/info/card/detail/base-unit';
import { CardCFAReviewReviewed } from 'core/entity/legal-configuration/card/cfa/review/reviewed';
import { Config } from 'application/config';

import styles from './styles.pcss';

interface Props {
  card: Card;
  cardConfig: CardLegalConfiguration;
}

export const CardInfoDetail = ({ card, cardConfig }: Props) => {
  const disabledBenefit = cardConfig && cardConfig.disabledForCompliance;
  const reviewed = cardConfig && (cardConfig.cfa.review instanceof CardCFAReviewReviewed);
  const shownRepresentativeBenefit =
    Config.options.ignoredLegalService ?
      optional(card.representativeBenefitDescription) :
      reviewed && optional(card.representativeBenefitDescription);
  const renderBlockedBenefitView = () => (
    <div className={ styles.emptyBenefit }>
      <h2 className={ styles.emptyTitle }>이 카드는 심의 절차 진행중입니다.</h2>
      <strong className={ styles.emptySubTitle }>서비스 이용에 불편을 드려 죄송합니다.</strong>
      <p className={ styles.emptyDesc }>
        더 정확한 상품 정보를 제공해 드리기 위해, 여신금융협회의 카드정보 심의 절차를 진행 중에 있습니다.<br />
        심의가 완료되는대로 카드 혜택 정보가 정상적으로 제공될 예정입니다.<br />
        그 전까지 혜택 정보는 각 카드사 홈페이지를 참고하시기 바라며, 그 외 모든 기능은 정상적으로 이용하실 수 있습니다.<br />
        <br />
        빠른 시일 내에 카드 신청 서비스가 가능해지도록 최선을 다하겠습니다.<br />
        항상 뱅크샐러드를 이용해 주셔서 감사드립니다.
      </p>
    </div>
  );

  return (
    <>
      <CardInfoUnit title='연회비'>
        <CardInfoAnnualCost annualCosts={ card.annualCosts } />
      </CardInfoUnit>
      {
        !(cardConfig && cardConfig.disabledForCompliance) &&
        !shownRepresentativeBenefit &&
        !(card.baseRests.isEmpty() && card.condRests.isEmpty()) &&
        <CardInfoUnit title='통합한도'>
          <CardInfoRestrictions baseRests={ card.baseRests } condRests={ card.condRests } />
        </CardInfoUnit>
      }
      {
        card.categorySpecialBenefits &&
        !(cardConfig && cardConfig.disabledForCompliance) &&
        !shownRepresentativeBenefit &&
        <CardInfoUnit title='특별서비스'>
          <CardInfoSpecialBenefits card={ card } />
        </CardInfoUnit>
      }
      {
        disabledBenefit ? (
          renderBlockedBenefitView()
        ) : shownRepresentativeBenefit ? (
          <CardInfoUnit title='주요혜택'>
            <CardInfoRepresentativeDescription card={ card } />
          </CardInfoUnit>
        ) : (
          <CardInfoUnit title='혜택정보'>
            <CardInfoBenefits card={ card } />
          </CardInfoUnit>
        )
      }
    </>
  )
};
