import React, { CSSProperties } from 'react';
import moment from 'moment';

import { EventBus } from 'presentation/bus'
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event'
import { SubscriptionBag } from 'presentation/module/extension'
import { toast } from 'presentation/module/sugar';
import { lets } from 'utils/index';

import styles from './styles.pcss';

export enum FloatingButtonThemeColor {
    PAPRIKA = 'paprika',
    ROMAINE = 'romaine',
    NAVY = 'navy',
    YELLOW = 'yellow',
    TOMATO = 'tomato'
}

interface FloatingButtonTheme {
    color: string,
    isRound: boolean
}

const defaultTheme = {
    color: FloatingButtonThemeColor.PAPRIKA,
    isRound: true
};

interface Props {
    title: string;
    minVisibleThreshold: number;
    closingDate?: Date;
    issueUrl?: string;
    onClick?: () => void;
    theme?: FloatingButtonTheme;
    bottomPositionPercentage?: number;
}

interface State {
    isVisibility: boolean;
    isAbsolute: boolean;
}

export class EventFloatingButton extends React.Component<Props, State> {
    state = {
        isVisibility: false,
        isAbsolute: false
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        EventBus.toObservable()
            .subscribe(
                (event: Event) => {
                    if (event instanceof DocumentScrollEvent) {
                        this.fetchButtonState(event);
                    }
                }
            )
            .unsubscribeBy(this.subscriptionBag);
    }

    private getAbsoluteBottomPixel = (): number => {
        const percentage = (this.props.bottomPositionPercentage || 0);
        return (percentage / 100) * document.body.clientHeight;
    };

    private getAbsolutePositionStyle = (): CSSProperties | {} => {
        const { isAbsolute } = this.state;
        const absoluteStyle = { position: 'absolute', bottom: this.getAbsoluteBottomPixel() };
        return isAbsolute ? absoluteStyle : {};
    };

    private getEnableButtonStyle = () => {
        const { theme } = this.props;
        const buttonTheme = theme ? theme : defaultTheme;
        const enableStyle = lets(buttonTheme.color, it => {
            switch (it) {
                case FloatingButtonThemeColor.PAPRIKA:
                    return styles.enablePaprikaButton;
                case FloatingButtonThemeColor.ROMAINE:
                    return styles.enableRomaineButton;
                case FloatingButtonThemeColor.NAVY:
                    return styles.enableNavyButton;
                case FloatingButtonThemeColor.YELLOW:
                    return styles.enableYellowButton;
                case FloatingButtonThemeColor.TOMATO:
                    return styles.enableTomatoButton;
                default:
                    return FloatingButtonThemeColor.PAPRIKA;
            }
        });
        const roundStyle = buttonTheme.isRound ? styles.roundButton : styles.rectangleButton;
        const buttonStyle = `${roundStyle} ${enableStyle}`;

        return this.state.isVisibility ? buttonStyle: styles.hidingIssueButton;
    };

    private renderEnableButton = () => {
        const { title, issueUrl, onClick } = this.props;
        const renderClickableElement  = () => (
            issueUrl ?
                <a
                    href={ issueUrl }
                    className={ this.getEnableButtonStyle() }
                    onClick={ onClick }
                    target='_blank'
                >
                    { title }
                </a> :
                <button
                    className={ this.getEnableButtonStyle() }
                    onClick={ onClick }
                >
                    { title }
                </button>
        );

        return (
            <div
                className={ styles.wrap }
                style={ this.getAbsolutePositionStyle() }
            >
                { renderClickableElement() }
            </div>
        )
    };

    private getDisableButtonStyle = () => {
        const { theme } = this.props;
        const buttonTheme = theme ? theme : defaultTheme;
        const roundStyle = buttonTheme.isRound ? styles.roundButton : styles.rectangleButton;
        const buttonStyle = `${roundStyle} ${styles.disableButton}`;

        return this.state.isVisibility ? buttonStyle : styles.hidingIssueButton;
    };

    private renderDisableButton = () => {
        const eventClosingTitle = '이 이벤트는 종료되었습니다';

        return (
            <div
                className={ styles.wrap }
                style={ this.getAbsolutePositionStyle() }
            >
                <a
                    className={ this.getDisableButtonStyle() }
                    onClick={ () => toast(eventClosingTitle) }
                >
                    { eventClosingTitle }
                </a>
            </div>
        )
    };

    render() {
        const { closingDate } = this.props;

        if (!closingDate || moment().isBefore(closingDate))
            return this.renderEnableButton();

        return this.renderDisableButton();
    }

    private fetchButtonState = ({
        scrollTop,
        scrollBottom
    }: DocumentScrollEvent) => {
        const bottomPixel = this.getAbsoluteBottomPixel();
        const isAbsolute = bottomPixel > 0 && scrollBottom <= bottomPixel;
        const isVisibility = scrollTop > this.props.minVisibleThreshold;

        this.setState({ isAbsolute, isVisibility });
    };
}


