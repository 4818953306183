import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class UserCreditScoreLoan extends Entity {
    name: string;
    principal: Amount;
    balance: Amount;
    createdAt: Date;
    expiredAt: Date;

    constructor(
        name: string,
        principal: Amount,
        balance: Amount,
        createdAt: Date,
        expiredAt: Date
    ) {
        super();
        this.name = name;
        this.principal = principal;
        this.balance = balance;
        this.createdAt = createdAt;
        this.expiredAt = expiredAt;
    }
}
