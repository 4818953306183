import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { List } from 'immutable';
import { UserSecuritiesFirmAccountUpdateSpec } from 'core/entity/personal-finance/securities-firm-account/update-spec';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { UserSecuritiesFirmAccountMapper } from 'data/http/mapper/personal-finance/securities-firm-account';
import { UserSecuritiesFirmAccountUpdateSpecMapper } from 'data/http/mapper/personal-finance/securities-firm-account/update-spec';
import { UserSecuritiesFirmAccountCreateSpec } from 'core/entity/personal-finance/securities-firm-account/create-spec';
import { UserSecuritiesFirmAccountCreateSpecMapper } from 'data/http/mapper/personal-finance/securities-firm-account/create-spec';

export class UserSecuritiesFirmAccountsApiProvider extends HttpProvider {
    getAll(accessToken: AccessToken): Observable<List<UserSecuritiesFirmAccount>> {
        return this.requestGET(
            'api/v2/me/securities-firms/accounts',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map(new UserSecuritiesFirmAccountMapper().fromJson))
        )
    }

    get(accessToken: AccessToken, id: string): Observable<UserSecuritiesFirmAccount> {
        return this.requestGET(
            `api/v2/me/securities-firms/accounts/${id}`,
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserSecuritiesFirmAccountMapper().fromJson(result.data)
        )
    }

    create(accessToken: AccessToken, spec: UserSecuritiesFirmAccountCreateSpec): Observable<UserSecuritiesFirmAccount> {
        return this.requestPOST(
            'api/v2/me/securities-firms/accounts',
            new UserSecuritiesFirmAccountCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserSecuritiesFirmAccountMapper().fromJson(result.data)
        )
    }

    update(accessToken: AccessToken, id: string, spec: UserSecuritiesFirmAccountUpdateSpec): Observable<UserSecuritiesFirmAccount> {
        return this.requestPATCH(
            `api/v2/me/securities-firms/accounts/${id}`,
            new UserSecuritiesFirmAccountUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserSecuritiesFirmAccountMapper().fromJson(result.data)
        )
    }
}
