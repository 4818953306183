import { Entity } from 'core/entity';

export class Alfred2PointAmount extends Entity {
    amount: number;

    constructor(
        amount: number
    ) {
        super();
        this.amount = amount;
    }
}
