import { List } from 'immutable';
import { UseCase } from 'core/use-case';
import { FinanceSectorRepositoryType } from 'core/repository/finance-sector';
import { FinanceSectorSummary } from 'core/entity/finance-sector/summary';
import { Observable } from 'rxjs/Rx';

export class GetFinanceSectorSummaries extends UseCase<List<FinanceSectorSummary>> {
    private repository: FinanceSectorRepositoryType;

    constructor(repository: FinanceSectorRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<FinanceSectorSummary>> {
        return this.repository.getAll();
    }

    protected validate(): boolean {
        return true;
    }
}
