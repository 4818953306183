import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { TransactionCategoryChild } from 'core/entity/personal-finance/transaction/category/child';

export class TransactionCategoryChildMapper implements NetworkResponseMapper<TransactionCategoryChild>, NetworkRequestMapper<TransactionCategoryChild> {
    toJson(target: TransactionCategoryChild): any {
        return {
            name: target.name
        };
    }

    fromJson(json: any): TransactionCategoryChild {
        return new TransactionCategoryChild(
            json.name
        )
    }
}
