import { Service } from 'core/service';
import { Observable } from 'rxjs/Rx';
import { UserFundRepositoryType } from 'core/repository/personal-finance/fund';
import { UserFundCreateSpec } from 'core/entity/personal-finance/fund/create-spec';
import { UserFund } from 'core/entity/personal-finance/fund';
import { UserFundUpdateSpec } from 'core/entity/personal-finance/fund/update-spec';

export class UserFundService implements Service {
    private repository: UserFundRepositoryType;

    constructor(repository: UserFundRepositoryType) {
        this.repository = repository;
    }

    create = (spec: UserFundCreateSpec): Observable<UserFund> =>
        this.repository.create(spec);

    update = (id: string, spec: UserFundUpdateSpec): Observable<UserFund> =>
        this.repository.update(id, spec);
}
