import { UserCreditScoreCardStatusRepositoryType } from 'core/repository/personal-credit/card-status';
import { Observable } from 'rxjs/Rx';
import { UserCreditCardsStatusFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/card-status';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { UserCreditScoreCardStatus } from 'core/entity/personal-credit/card-status';

export class UserCreditScoreCardStatusRepository implements UserCreditScoreCardStatusRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserCreditCardsStatusFromKCBApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserCreditCardsStatusFromKCBApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    get(): Observable<UserCreditScoreCardStatus> {
        return this.tokenStorage.get().flatMap(it =>
            this.api.get(it)
        );
    }

}
