import { Observable } from 'rxjs/Rx';
import { LoanStatisticsRepositoryType } from 'core/repository/loan-statistics';
import { LoanStatistics } from 'core/entity/loan/statistics';
import { LoanStatisticsApiProvider } from 'data/http/api/gateway/v2/loan/statistics';

export class LoanStatisticsRepository implements LoanStatisticsRepositoryType {
    private api: LoanStatisticsApiProvider;

    constructor(api: LoanStatisticsApiProvider) {
        this.api = api;
    }

    get(): Observable<LoanStatistics> {
        return this.api.get();
    }

}
