import { NetworkResponseMapper } from 'data/http/mapper';
import { CFADeliberationReviewConfiguration } from 'core/entity/personal-finance/CFA-deliberation-review-configuration';


export class CFADeliberationReviewConfigurationMapper implements NetworkResponseMapper<CFADeliberationReviewConfiguration> {
    fromJson(json: any): CFADeliberationReviewConfiguration {
        return new CFADeliberationReviewConfiguration(
            json.status
        );
    };
}
