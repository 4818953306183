import { Question } from 'presentation/view-model/question';
import { toCurrency } from 'presentation/module/sugar';

const TEN_THOUSAND = 10000;

export class CreditLoanAmount implements Question {
    topic = '대출 희망금액';
    essential: boolean;
    clearable: boolean;
    value: number;

    constructor(
        amount: number = 0,
        essential: boolean = true,
        clearable: boolean = false
    ) {
        this.value = amount;
        this.essential = essential;
        this.clearable = clearable;
    }

    clear(): CreditLoanAmount {
        return new CreditLoanAmount();
    }

    toValue(): string {
        return `${toCurrency(this.value / TEN_THOUSAND)}만원`;
    }

    isValid(): boolean {
        return this.value > 0;
    }
}
