import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceNetAssetValuationData } from 'core/entity/personal-finance/advice/content-data/net-asset-valuation';
import { AdviceNetAssetAmountMapper } from 'data/http/mapper/personal-finance/advice/net-asset-amount';
import { optional } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceNetAssetValuationDataMapper implements NetworkResponseMapper<AdviceNetAssetValuationData> {
    fromJson(json: any): AdviceNetAssetValuationData {
        return new AdviceNetAssetValuationData(
            json.net_asset_valuation_history.map(new AdviceNetAssetAmountMapper().fromJson).toList(),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
