import { NetworkRequestMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { CardPriority } from 'core/entity/card/priority';

export class CardPriorityMapper implements NetworkRequestMapper<CardPriority> {
    toJson(target: CardPriority): any {
        return {
            criteria: lets(target.criteria, it => {
                switch (it) {
                    case CardPriority.Criteria.ANNUAL_COST:
                        return 'annual_cost';
                    case CardPriority.Criteria.PROMOTION_END_DATE:
                        return 'promotion_end_date';
                    case CardPriority.Criteria.PROMOTION_CREATE_DATE:
                        return 'promotion_create_date';
                    case CardPriority.Criteria.PROMOTION_AMOUNT:
                        return 'promotion_amount';
                    case CardPriority.Criteria.RATING:
                        return 'rating';
                    case CardPriority.Criteria.PROFIT:
                        return 'profit';
                    case CardPriority.Criteria.NONE:
                        return 'none';
                }
            }),
            ascending: target.ascending
        };
    }
}
