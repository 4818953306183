import { TransactionProduct } from 'core/entity/personal-finance/transaction/product';
import { TransactionUpdateSpec } from 'core/entity/personal-finance/transaction/update-spec';
import { NetworkRequestMapper } from 'data/http/mapper';
import { TransactionTypeMapper } from 'data/http/mapper/personal-finance/transaction/type';
import { compactObject, lets, optional } from 'utils/index';

export class TransactionUpdateSpecMapper implements NetworkRequestMapper<TransactionUpdateSpec> {
    toJson(target: TransactionUpdateSpec): any {
        return compactObject({
            title: optional(target.title),
            description: optional(target.description),
            product: optional(target.product, product => ({
                id: product.id,
                type: lets(product.type, type => {
                    switch (type) {
                        case TransactionProduct.Type.BANK_ACCOUNT:
                            return 'bank_account';
                        case TransactionProduct.Type.CARD:
                            return 'card';
                        case TransactionProduct.Type.CASH_ACCOUNT:
                            return 'cash_account';
                        case TransactionProduct.Type.LOAN:
                            return 'loan';
                        default:
                            return null;
                    }
                })
            })),
            transacted_at: optional(target.transactedAt, it => it.format('YYYY-MM-DD HH:mm')),
            amount: optional(target.amount),
            type: optional(target.type, it => new TransactionTypeMapper().toJson(it)),
            category_id: optional(target.categoryId),
            updating_similar_transactions: optional(target.updatingSimilarTransactions),
            configuration: optional(target.configuration, it => ({
                excluded_from_budget: it.excludedFromBudget
            }))
        })
    }
}
