import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { Amount } from 'core/entity/amount';
import { lets } from 'utils/index';

export class AmountMapper implements
    NetworkResponseMapper<Amount>,
    NetworkRequestMapper<Amount>
{
    toJson(target: Amount): any {
        return {
            amount: target.amount,
            currency: target.currency
        }
    }

    fromJson(json: any): Amount {
        return new Amount(
            json.amount,
            lets(json.currency, it => {
                switch (it) {
                    case 'KRW':
                        return Amount.Currency.KRW;
                    case 'USD':
                        return Amount.Currency.USD;
                    default:
                        return null;
                }
            })
        )
    }
}
