import { InstallmentConfiguration } from 'core/entity/personal-finance/transaction/installment/configuration';
import { NetworkResponseMapper } from 'data/http/mapper';
import { InstallmentIncludedTransactionMapper } from 'data/http/mapper/personal-finance/transaction/installment/transaction';

export class InstallmentConfigurationMapper implements NetworkResponseMapper<InstallmentConfiguration> {
    fromJson(json: any): InstallmentConfiguration {
        return new InstallmentConfiguration(
            json.round,
            json.month,
            json.transactions.map((transaction: any) =>
                new InstallmentIncludedTransactionMapper().fromJson(transaction)
            )
        )
    }

}
