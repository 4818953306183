import { Entity } from 'core/entity';

export class LoanInterestRange extends Entity {
    min: number;
    max: number;

    constructor(
        min: number,
        max: number
    ) {
        super();
        this.min = min;
        this.max = max;
    }
}
