import { IncomeTaxDeductionConfiguration } from 'core/entity/personal-finance/transaction/income-tax-deduction-configuration';
import { NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';

export class IncomeTaxDeductionConfigurationMapper implements NetworkResponseMapper<IncomeTaxDeductionConfiguration> {
    fromJson(json: any): IncomeTaxDeductionConfiguration {
        return new IncomeTaxDeductionConfiguration(
            json.applied,
            lets(json.type, it => {
                switch (it) {
                    case 'normal':
                        return IncomeTaxDeductionConfiguration.Type.NORMAL;
                    case 'public_transportation':
                        return IncomeTaxDeductionConfiguration.Type.PUBLIC_TRANSPORTATION;
                    case 'traditional_market':
                        return IncomeTaxDeductionConfiguration.Type.TRADITIONAL_MARKET;
                    default:
                        return null;
                }
            })
        )
    }

}
