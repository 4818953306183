import { FundPrimaryData } from 'core/entity/personal-finance/primary-data/fund';
import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { SpiderFundDataMapper } from 'data/http/mapper/personal-finance/spider-data/fund';
import { optional } from 'utils/index';

export class FundPrimaryDataMapper implements
    NetworkResponseMapper<FundPrimaryData>,
    NetworkRequestMapper<FundPrimaryData>
{
    fromJson(json: any): FundPrimaryData {
        return new FundPrimaryData(
            optional(json.spider, it => new SpiderFundDataMapper().fromJson(it))
        )
    }

    toJson(target: FundPrimaryData): any {
        return {
            spider: optional(target.spider, new SpiderFundDataMapper().toJson)
        };
    }
}
