import { NetworkRequestMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { UserDropSpec } from 'core/entity/user/drop-spec';

export class UserDropSpecMapper implements NetworkRequestMapper<UserDropSpec> {
    toJson(target: UserDropSpec): any {
        return {
            password: target.password,
            reason: lets(target.reason, it => {
                switch (it) {
                    case UserDropSpec.Reason.INVALID_DATA:
                        return 'invalid-data';
                    case UserDropSpec.Reason.FEATURE_NOT_FOUND:
                        return 'feature-not-found';
                    case UserDropSpec.Reason.SECURITY:
                        return 'security';
                    case UserDropSpec.Reason.ETC:
                    default:
                        return 'etc';
                }
            }),
            payload: target.payload,
        }
    }

}
