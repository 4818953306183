import { NetworkResponseMapper } from 'data/http/mapper';
import { optional } from 'utils/index';
import { ContentColumnArticle } from 'core/entity/content/column/article';

export class NewContentColumnArticleMapper implements NetworkResponseMapper<ContentColumnArticle> {
    fromJson(json: any): ContentColumnArticle {
        return new ContentColumnArticle(
            json.body,
            optional(json.warning),
            optional(json.methodology)
        )
    }
}
