import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardPreferredSpecialBenefit } from 'core/entity/card/preferred-special-benefit';

export class CardCategorySpecialBenefit extends Entity {
    category: string;
    specialBenefits: List<CardPreferredSpecialBenefit>;

    constructor(
        category: string,
        specialBenefits: List<CardPreferredSpecialBenefit>
    ) {
        super();
        this.category = category;
        this.specialBenefits = specialBenefits;
    }
}
