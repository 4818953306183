import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { Header } from 'presentation/components/header';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { CreditLoansQuestions } from 'presentation/routes/credit-loans/questions';
import { CreditLoanRoute } from 'presentation/routes/credit-loans/credit-loan';
import { CreditLoansProfitsRoute } from 'presentation/routes/credit-loans/profits';
import { CreditLoanProfitsRoute } from 'presentation/routes/credit-loans/credit-loan-profits';
import { Footer } from 'presentation/components/footer';

export const CreditLoansRoute = ({ match }: RouteComponentProps<any>) => (
    <div>
        <Header active={ FinanceSectorID.LOAN }/>
        <Switch>
            <Route path={ `${match.url}/questions` } component={ CreditLoansQuestions } />
            <Route path={ `${match.url}/profits` } component={ CreditLoansProfitsRoute } />
            <Route path={ `${match.url}/:slug/profits` } component={ CreditLoanProfitsRoute } />
            <Route path={ `${match.url}/:slug` } component={ CreditLoanRoute } />
        </Switch>
        <Footer />
    </div>
);
