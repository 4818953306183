import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanStatistics } from 'core/entity/loan/statistics';

export class LoanStatisticsMapper implements NetworkResponseMapper<LoanStatistics> {
    fromJson(json: any): LoanStatistics {
        return new LoanStatistics(
            json.number_of_companies,
            json.number_of_loans
        )
    }

}
