import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { CardsRoute } from 'presentation/routes/cards';
import { ErrorsRoute } from 'presentation/routes/errors';
import { ContentsRoute } from 'presentation/routes/contents';
import { SectorsRoute } from 'presentation/routes/sectors';
import { CreditLoansRoute } from 'presentation/routes/credit-loans';
import { UsersRoute } from 'presentation/routes/users';
import { MeRoute } from 'presentation/routes/me';
import { NoticesRoute } from 'presentation/routes/notices';
import { Container } from 'presentation/components/container';
import { NewPoliciesRoute } from 'presentation/routes/policies';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { LandingView } from 'presentation/routes/view';
import { SignOutRoute } from 'presentation/routes/sign-out';
import { AppRoute } from 'presentation/routes/app';
import { InfoRoute } from 'presentation/routes/info';

export const IndexRoute = () => (
    <>
    <MetaHelmet meta={ META_SET.HOME }/>
    <LandingView />
    </>
);

export const EntryRoute = () => (
    <BrowserRouter>
        <Container>
            <Switch>
                <Route path="/app" component={ AppRoute } />
                <Route path="/sectors" component={ SectorsRoute } />
                <Route path="/cards" component={ CardsRoute } />
                <Route path="/credit-loans" component={ CreditLoansRoute } />
                <Route path="/errors" component={ ErrorsRoute } />
                <Route path="/contents" component={ ContentsRoute } />
                <Route path="/notices" component={ NoticesRoute } />
                <Route path="/users" component={ UsersRoute } />
                <Route path="/me" component={ MeRoute } />
                <Route path="/info" component={ InfoRoute } />
                <Route path="/sign-out" component={ SignOutRoute } />
                <Route path="/policies" component={ NewPoliciesRoute } />
                <Route path="/" component={ IndexRoute } />
            </Switch>
        </Container>
    </BrowserRouter>
);
