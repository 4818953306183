import { Entity } from 'core/entity';

export class MobileAuthenticationPhoneConfiguration extends Entity {
    number: string;
    company: MobileAuthenticationPhoneConfiguration.Company;

    constructor(
        number: string,
        company: MobileAuthenticationPhoneConfiguration.Company
    ) {
        super();
        this.number = number;
        this.company = company;
    }
}

export module MobileAuthenticationPhoneConfiguration {
    export enum Company {
        SKT = 'skt',
        KT = 'kt',
        LGU = 'lgu',
        SUB_SKT = 'sub-skt',
        SUB_KT = 'sub-kt',
        SUB_LGU = 'sub-lgu'
    }
}
