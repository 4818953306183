import { NetworkRequestMapper } from 'data/http/mapper';
import { UserFundUpdateSpec } from 'core/entity/personal-finance/fund/update-spec';
import { compactObject, optional, toDateString } from 'utils/index';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { FundCompanySummaryMapper } from 'data/http/mapper/personal-finance/fund-company/summary';

export class UserFundUpdateSpecMapper implements NetworkRequestMapper<UserFundUpdateSpec> {
    toJson(target: UserFundUpdateSpec): any {
        return compactObject({
            company: optional(target.company, it => {
                const json = new FundCompanySummaryMapper().toJson(it);

                return {
                    id: json.id,
                    type: json.type
                }
            }),
            nickname: optional(target.nickname),
            description: optional(target.description),
            principal: optional(target.principal),
            valuation: optional(target.valuation),
            quantity: optional(target.quantity),
            created_at: optional(target.createdAt, it => toDateString(it, '-')),
            expired_at: optional(target.expiredAt, it => toDateString(it, '-')),
            hidden: optional(target.hidden),
            deleted_status: optional(target.deletedStatus, new DeletedStatusMapper().toJson)
        })
    }
}
