import Rx from 'rxjs/Rx';

import { CardConfigurationRepositoryType } from 'core/repository/card-configuration';
import { CardConfiguration } from 'core/entity/card/configuration';
import { CardConfigurationApiProvider } from 'data/http/api/gateway/v3/cards/configuration';

export class CardConfigurationRepository implements CardConfigurationRepositoryType {
    private api: CardConfigurationApiProvider;

    constructor(api: CardConfigurationApiProvider) {
        this.api = api;
    }

    get(): Rx.Observable<CardConfiguration> {
        return this.api.get();
    }
}
