import { HttpProvider } from 'data/http';
import { AccessToken } from 'core/entity/user/access-token';
import { Observable } from 'rxjs/Observable';
import { PersonalizedLoanRecommendedResult } from 'core/entity/personalized-recommend/loan/recommended-result';
import { PersonalizedLoanRecommendSpecMapper } from 'data/http/mapper/personalized-recommend/loan/recommend-spec';
import { PersonalizedLoanRecommendSpec } from 'core/entity/personalized-recommend/loan/recommend-spec';
import { PersonalizedLoanRecommendedResultMapper } from 'data/http/mapper/personalized-recommend/loan/recommended-result';

export class PersonalizedLoanApiProvider extends HttpProvider {
    recommend = (
        recommendSpec: PersonalizedLoanRecommendSpec,
        accessToken: AccessToken
    ): Observable<PersonalizedLoanRecommendedResult> => (
        this.requestPOST(
            `/api/v4/me/loans/recommend`,
            new PersonalizedLoanRecommendSpecMapper().toJson(recommendSpec),
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) =>
            new PersonalizedLoanRecommendedResultMapper().fromJson(result.data)
        )
    );
}
