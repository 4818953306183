import moment from 'moment';
import { Observable } from 'rxjs/Rx';

import { SummarizedUserCardBills } from 'core/entity/personal-finance/summarized-card-bills';
import { UserCardRepositoryType } from 'core/repository/personal-finance/card';
import { UseCase } from 'core/use-case';

export class GetSummarizedUserCardBills extends UseCase<SummarizedUserCardBills> {
    private repository: UserCardRepositoryType;

    constructor(repository: UserCardRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<SummarizedUserCardBills> {
        const startDate = moment().startOf('day').toDate();
        const endDate = moment().add(1, 'month').endOf('day').toDate();

        return this.repository.getSummarizedUserCardBills(startDate, endDate);
    }

    protected validate(): boolean {
        return true;
    }
}
