import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanPrimeInterestNodeStatus } from 'core/entity/loan/prime-interest-node-status';
import { LoanPrimeInterestNodeStatusTypeMapper } from 'data/http/mapper/loan/prime-interest-node-status-type';
import { optional } from 'utils/index';
import { LoanInterestValueMapper } from 'data/http/mapper/loan/interest-value';

export class LoanPrimeInterestNodeStatusMapper implements NetworkResponseMapper<LoanPrimeInterestNodeStatus> {
    fromJson(json: any): LoanPrimeInterestNodeStatus {
        return new LoanPrimeInterestNodeStatus(
            new LoanPrimeInterestNodeStatusTypeMapper().fromJson(json.type),
            optional(json.potential_rate, new LoanInterestValueMapper().fromJson)
        )
    }
}
