export class RecommendCardViewModel {
    id: number;
    companyName: string;
    name: string;
    annualPromotion: boolean;
    imageUrl: string;

    constructor(
        id: number,
        companyName: string,
        name: string,
        annualPromotion: boolean,
        imageUrl: string
    ) {
        this.id = id;
        this.companyName = companyName;
        this.name = name;
        this.annualPromotion = annualPromotion;
        this.imageUrl = imageUrl;
    }
}
