import { AccessToken } from 'src/core/entity/user/access-token';
import { Observable } from 'rxjs';
import { HttpProvider } from 'data/http';
import { HealthInsurancePaymentDetailResponseMapper } from 'data/http/mapper/personal-finance/health-insurance/payment-details';
import { HealthInsurancePaymentDetailResponse } from 'core/entity/personal-finance/health-insurance/payment-detail/response';

export class HealthInsurancePaymentDetailApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<HealthInsurancePaymentDetailResponse> {
        return this.requestGET(
            'api/v2/me/health-insurance/payment-details',
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) => new HealthInsurancePaymentDetailResponseMapper().fromJson(result));
    }
}
