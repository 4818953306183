import { Entity } from 'core/entity';
import { CardEventSummary } from 'core/entity/card/event/summary';
import { List } from 'immutable';
import { CardEventCompany } from 'core/entity/card/event/company';

export class CardEvent extends Entity {
    id: string;
    company: CardEventCompany;
    title: string;
    thumbNailImageUrl: string;
    url: string;
    featured: boolean;
    hot: boolean;
    description: string;
    suggestedEvents: List<CardEventSummary>;
    startDate: Date;
    endDate: Date;

    constructor(
        id: string,
        company: CardEventCompany,
        title: string,
        thumbNailImageUrl: string,
        url: string,
        featured: boolean,
        hot: boolean,
        description: string,
        suggestedEvents: List<CardEventSummary>,
        startDate: Date,
        endDate: Date,
    ) {
        super();
        this.id = id;
        this.company = company;
        this.title = title;
        this.thumbNailImageUrl = thumbNailImageUrl;
        this.url = url;
        this.featured = featured;
        this.hot = hot;
        this.description = description;
        this.suggestedEvents = suggestedEvents;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
