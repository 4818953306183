import { NetworkRequestMapper } from 'data/http/mapper';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanOrganizationTypeMapper } from 'data/http/mapper/loan/organization/type';
import { LoanInterestTypeMapper } from 'data/http/mapper/loan/interest-type';
import { LoanRepaymentTypeMapper } from 'data/http/mapper/loan/repayment-type';
import { LoanRegistrationTypeMapper } from 'data/http/mapper/loan/registration-type';
import { optional } from 'utils/index';

export class LoanRecommendFilterMapper implements NetworkRequestMapper<LoanRecommendFilter> {
    toJson(target: LoanRecommendFilter): any {
        return {
            repayment_period: optional(target.repaymentPeriod, it => it * 12),
            organization_types: optional(target.organizationTypes, it =>
                it.map(type => new LoanOrganizationTypeMapper().toJson(type)).toArray()
            ),
            interest_types: optional(target.interestTypes, it =>
                it.map(type => new LoanInterestTypeMapper().toJson(type)).toArray()
            ),
            repayment_types: optional(target.repaymentTypes, it =>
                it.map(type => new LoanRepaymentTypeMapper().toJson(type)).toArray()
            ),
            registration_types: optional(target.registrationTypes, it =>
                it.map(type => new LoanRegistrationTypeMapper().toJson(type)).toArray()
            ),
            exempt_reimbursement_fees: optional(target.exemptReimbursementFees),
            day_loan: optional(target.dayLoan),
            general_credit: optional(target.generalCredit),
            minus_account: optional(target.minusAccount),
            in_promotion: optional(target.inPromotion),
            sorter: optional(target.sorter, it => {
                switch (it) {
                    case LoanRecommendFilter.Sort.MINIMUM_INTEREST:
                        return 'minimum_interest';
                    case LoanRecommendFilter.Sort.MAXIMUM_LOAN_LIMIT:
                        return 'maximum_loan_limit';
                    case LoanRecommendFilter.Sort.MAXIMUM_REPAYMENT_PERIOD:
                        return 'maximum_repayment_period';
                    default:
                        return null;
                }
            })
        }
    }

}
