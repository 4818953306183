import React from 'react';
import { List } from 'immutable';

import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardConfiguration } from 'core/entity/card/configuration';
import { Card } from 'core/entity/card';
import { emptyList, lets, triesHard } from 'utils/index';
import { CardBenefitType } from 'core/entity/card/benefit-type';
import { CardsAnnualCostFilter } from 'presentation/components/results-filter/card/annual-cost';
import { CardBrand } from 'core/entity/card/brand';
import { isForDiscount } from 'presentation/module/sugar';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_EVENT_VALUE, GA_DOMAIN } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

const EXCEPTED_CARD_COMPANY = List();
const BENEFIT_TYPES = {
    DISCOUNT: [
        CardBenefitType.ALL,
        CardBenefitType.POINT,
        CardBenefitType.DISCOUNT
    ],
    MILEAGE: [
        CardBenefitType.MILEAGE_AA,
        CardBenefitType.MILEAGE_KA,
        CardBenefitType.MILEAGE_OTHERS
    ]
};
const SPECIAL_BENEFIT_STYLES = [
    { key: '무이자할부', style: styles.noInterest },
    { key: '문화여가', style: styles.culture },
    { key: '외식', style: styles.meal },
    { key: '골프', style: styles.golf },
    { key: '자동차', style: styles.car },
    { key: '호텔 서비스', style: styles.hotel },
    { key: '여행/항공', style: styles.trip },
    { key: '금융/보험', style: styles.finance },
    { key: '바우처/상품권', style: styles.voucher },
    { key: '기타', style: styles.etc }
];
const FILTER_CARD_TYPES = [
    { key: Card.Type.CREDIT, text: '신용' },
    { key: Card.Type.CHECK, text: '체크' },
    { key: Card.Type.HYBRID, text: '하이브리드' }
];
const FILTER_BENEFIT_TYPES = {
    DISCOUNT: [
        { key: CardBenefitType.DISCOUNT, text: '할인' },
        { key: CardBenefitType.POINT, text: '적립' }
    ],
    MILEAGE: [
        { key: CardBenefitType.MILEAGE_KA, text: '대한항공 마일리지 적립' },
        { key: CardBenefitType.MILEAGE_AA, text: '아시아나 마일리지 적립' },
        { key: CardBenefitType.MILEAGE_OTHERS, text: '그 외 마일리지 적립' },
    ]
};
const SPECIAL_BENEFIT_FREE_INTEREST_FEE_KEY = 1;

interface Props {
    spec: CardRecommendSpec,
    config: CardConfiguration,
    onChange?: (spec: CardRecommendSpec) => void
}

export class CardsProfitsFilter extends React.Component<Props, undefined> {
    render() {
        const { spec, config } = this.props;
        const forDiscount = isForDiscount(spec.benefitTypes);

        return (
            <div>
                <div className={ styles.box }>
                    <h5 className={ styles.title }>
                        카드 종류
                        <button
                            onClick={ () => {
                                this.onSelectAllOfCardTypes();
                                sendGAEvent(
                                    GA_DOMAIN.CARD,
                                    GA_CATEGORY.CARDS_PROFITS.FILTER_CARD_TYPE_ALL,
                                    GA_ACTION.INPUT_SHORTCUT,
                                    '전체'
                                );
                            } }
                            className={ styles.all }
                        >
                            전체
                        </button>
                    </h5>
                    <ul className={ styles.checks }>
                        {
                            FILTER_CARD_TYPES.map(item => lets(`card_types-${item.key}`, id =>
                                <li key={ id } className={ styles.half }>
                                    <input
                                        type="checkbox"
                                        id={ id }
                                        checked={ spec.filter.cardTypes.contains(item.key) }
                                        onChange={ e => {
                                            this.onChangeCardType(item.key);
                                            sendGAEvent(
                                                GA_DOMAIN.CARD,
                                                GA_CATEGORY.CARDS_PROFITS.FILTER_CARD_TYPE,
                                                GA_ACTION.INPUT_SHORTCUT,
                                                item.text,
                                                e.currentTarget.checked ?
                                                    GA_EVENT_VALUE.CHECKED :
                                                    GA_EVENT_VALUE.UNCHECKED
                                            );
                                        } }
                                    />
                                    <label htmlFor={ id }>{ item.text }</label>
                                </li>
                            ))
                        }
                    </ul>

                    <h5 className={ styles.title }>
                        혜택 종류
                    </h5>
                    <ul className={ styles.tabs }>
                        <li>
                            <input
                                type="radio"
                                id="benefit_group-discount"
                                name="benefit_group"
                                checked={ forDiscount }
                                onChange={ () => {
                                    !forDiscount && this.setBenefitType(BENEFIT_TYPES.DISCOUNT);
                                    sendGAEvent(
                                        GA_DOMAIN.CARD,
                                        GA_CATEGORY.CARDS_PROFITS.FILTER_BENEFIT_TYPE,
                                        GA_ACTION.TAB,
                                        '할인적립'
                                    )
                                } }
                            />
                            <label htmlFor="benefit_group-discount">할인/적립</label>
                        </li>
                        <li>
                            <input
                                type="radio"
                                id="benefit_group-mileage"
                                name="benefit_group"
                                checked={ !forDiscount }
                                onChange={ () => {
                                    forDiscount && this.setBenefitType(BENEFIT_TYPES.MILEAGE);
                                    sendGAEvent(
                                        GA_DOMAIN.CARD,
                                        GA_CATEGORY.CARDS_PROFITS.FILTER_BENEFIT_TYPE,
                                        GA_ACTION.TAB,
                                        '항공마일리지'
                                    )
                                } }
                            />
                            <label htmlFor="benefit_group-mileage">항공마일</label>
                        </li>
                    </ul>
                    <ul className={ styles.checks }>
                        {
                            (forDiscount ? FILTER_BENEFIT_TYPES.DISCOUNT : FILTER_BENEFIT_TYPES.MILEAGE).map(item =>
                                lets(`benefit_types-${item.key}`, id =>
                                    <li
                                        key={ id }
                                        className={ forDiscount ? styles.half : null}
                                    >
                                        <input
                                            type="checkbox"
                                            id={ id }
                                            checked={ spec.benefitTypes.contains(item.key) }
                                            onChange={ e => {
                                                this.onChangeBenefitType(item.key);
                                                sendGAEvent(
                                                    GA_DOMAIN.CARD,
                                                    GA_CATEGORY.CARDS_PROFITS.FILTER_BENEFIT_TYPE,
                                                    GA_ACTION.CHECKBOX,
                                                    item.text,
                                                    e.currentTarget.checked ?
                                                        GA_EVENT_VALUE.CHECKED :
                                                        GA_EVENT_VALUE.UNCHECKED
                                                )
                                            } }
                                        />
                                        <label htmlFor={ id }>{ item.text }</label>
                                    </li>
                                )
                            )
                        }
                    </ul>

                    <h5 className={ styles.title }>
                        연회비
                    </h5>
                    <CardsAnnualCostFilter
                        min={ spec.filter.annualCostMin }
                        max={ spec.filter.annualCostMax }
                        onChange={ this.onChangeAnnualCost }
                    />
                    <ul className={ `${styles.checks} ${styles.event}` }>
                        <li>
                            <input
                                type="checkbox"
                                id="promotion-annual_cost"
                                checked={ spec.filter.promotionOnly }
                                onChange={ e => {
                                    this.onChangePromotionOnly();
                                    sendGAEvent(
                                        GA_DOMAIN.CARD,
                                        GA_CATEGORY.CARDS_PROFITS.FILTER_ANNUAL_COST_PROMOTION,
                                        GA_ACTION.CHECKBOX,
                                        '연회비지원상품만보기',
                                        e.currentTarget.checked ?
                                            GA_EVENT_VALUE.CHECKED :
                                            GA_EVENT_VALUE.UNCHECKED
                                    )
                                } }
                            />
                            <label
                                htmlFor="promotion-annual_cost"
                                className={ styles.eventLabel }
                            >
                                연회비 지원상품만 보기
                            </label>
                        </li>
                        <li>
                            <input
                                type="checkbox"
                                id="free-interest-fee"
                                checked={
                                    spec.filter.specialBenefitIds &&
                                    spec.filter.specialBenefitIds.contains(SPECIAL_BENEFIT_FREE_INTEREST_FEE_KEY)
                                }
                                onChange={ e => {
                                    this.onChangeSpecialBenefit(SPECIAL_BENEFIT_FREE_INTEREST_FEE_KEY);
                                    sendGAEvent(
                                        GA_DOMAIN.CARD,
                                        GA_CATEGORY.CARDS_PROFITS.FILTER_FREE_INTEREST_FEE,
                                        GA_ACTION.CHECKBOX,
                                        '전가맹점무이자할부카드만보기',
                                        e.currentTarget.checked ?
                                            GA_EVENT_VALUE.CHECKED :
                                            GA_EVENT_VALUE.UNCHECKED
                                    )
                                } }
                            />
                            <label htmlFor="free-interest-fee">
                                전가맹점 무이자할부 카드만 보기
                            </label>
                        </li>
                    </ul>

                    <h5 className={ styles.title }>
                        카드사
                        <button
                            onClick={ () => {
                                this.onSelectAllOfCompanies();
                                sendGAEvent(
                                    GA_DOMAIN.CARD,
                                    GA_CATEGORY.CARDS_PROFITS.FILTER_CARD_COMPANY_ALL,
                                    GA_ACTION.INPUT_SHORTCUT,
                                    '전체'
                                )
                            } }
                            className={ styles.all }
                        >
                            전체
                        </button>
                    </h5>
                    <ul className={ styles.checks }>
                        {
                            config.companies.entrySeq().map(([key, value]) => lets(`company-${key}`, id =>
                                <li key={ id } className={ styles.half }>
                                    <input
                                        type="checkbox"
                                        id={ id }
                                        checked={ spec.filter.companies === null || spec.filter.companies.contains(key) }
                                        onChange={ e => {
                                            this.onChangeCompany(key);
                                            sendGAEvent(
                                                GA_DOMAIN.CARD,
                                                GA_CATEGORY.CARDS_PROFITS.FILTER_CARD_COMPANY,
                                                GA_ACTION.CHECKBOX,
                                                value.replace(/(은행|카드|증권)/gi, ''),
                                                e.currentTarget.checked ?
                                                    GA_EVENT_VALUE.CHECKED :
                                                    GA_EVENT_VALUE.UNCHECKED
                                            )
                                        } }
                                    />
                                    <label htmlFor={ id }>
                                        { value.replace(/(은행|카드|증권)/gi, '') }
                                    </label>
                                </li>
                            ))
                        }
                    </ul>

                    <h5 className={ styles.title }>
                        브랜드
                        <button
                            onClick={ () => {
                                this.onSelectAllOfBrands();
                                sendGAEvent(
                                    GA_DOMAIN.CARD,
                                    GA_CATEGORY.CARDS_PROFITS.FILTER_BRAND_ALL,
                                    GA_ACTION.INPUT_SHORTCUT,
                                    '전체'
                                )
                            } }
                            className={ styles.all }
                        >
                            전체
                        </button>
                    </h5>
                    <ul className={ styles.checks }>
                        {
                            config.brands.map(brand => lets(`brand-${brand.name}`, id =>
                                <li key={ id } className={ styles.half }>
                                    <input
                                        type="checkbox"
                                        id={ id }
                                        checked={ spec.filter.brands === null || spec.filter.brands.contains(brand) }
                                        onChange={ e => {
                                            this.onChangeBrand(brand);
                                            sendGAEvent(
                                                GA_DOMAIN.CARD,
                                                GA_CATEGORY.CARDS_PROFITS.FILTER_BRAND,
                                                GA_ACTION.CHECKBOX,
                                                brand.name,
                                                e.currentTarget.checked ?
                                                    GA_EVENT_VALUE.CHECKED :
                                                    GA_EVENT_VALUE.UNCHECKED
                                            )
                                        } }
                                    />
                                    <label htmlFor={ id }>
                                        { brand.name }
                                    </label>
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <div className={ styles.box }>
                    <h5 className={ styles.title }>
                        특별서비스
                    </h5>
                    { this.renderSpecialBenefits() }
                </div>
            </div>
        )
    }

    private renderSpecialBenefits() {
        const { spec, config } = this.props;

        const toItem = (groupId: string, key: number, value: string) =>
            lets(`${groupId}-${key}`, id =>
                <li key={ id }>
                    <input
                        type="checkbox"
                        id={ id }
                        checked={ spec.filter.specialBenefitIds && spec.filter.specialBenefitIds.contains(key) }
                        onChange={ e => {
                            this.onChangeSpecialBenefit(key);
                            sendGAEvent(
                                GA_DOMAIN.CARD,
                                GA_CATEGORY.CARDS_PROFITS.FILTER_SPECIAL_SERVICE,
                                GA_ACTION.CHECKBOX,
                                value,
                                e.currentTarget.checked ?
                                    GA_EVENT_VALUE.CHECKED :
                                    GA_EVENT_VALUE.UNCHECKED
                            )
                        } }
                    />
                    <label htmlFor={ id }>
                        { value }
                    </label>
                </li>
            );

        const toGroup = (benefit: CardConfiguration.SpecialBenefit) =>
            lets(`specials-${benefit.title}`, id => {
                const style = triesHard(() =>
                    SPECIAL_BENEFIT_STYLES.find(it => it.key === benefit.title).style
                ).mustBe(() => styles.etc);

                return (
                    <li key={ id }>
                        <input
                            type="checkbox"
                            id={ id }
                            className={ styles.trigger }
                        />
                        <label htmlFor={ id }
                               className={ style }>
                            { benefit.title }
                        </label>
                        <ul className={ styles.checks }>
                            {
                                benefit.items
                                    .entrySeq()
                                    .map(([key, value]) =>
                                        toItem(id, key, value)
                                    )
                            }
                        </ul>
                    </li>
                )
            });

        return (
            <ul className={ styles.services }>
                {
                    config.specialBenefits.map(benefit =>
                        toGroup(benefit)
                    )
                }
            </ul>
        )
    }

    private onChangeCardType(type: Card.Type) {
        const { spec, onChange } = this.props;
        const result = lets(spec, it => {
            if (it.filter.cardTypes.contains(type)) {
                it.filter.cardTypes = it.filter.cardTypes
                    .filter(item => item !== type)
                    .toList();
            } else {
                it.filter.cardTypes = it.filter.cardTypes.push(type);
            }
            return it;
        });
        onChange && onChange(result);
    }

    private onSelectAllOfCardTypes() {
        const { spec, onChange } = this.props;
        const result = lets(spec, it => {
            if (
                it.filter.cardTypes === null ||
                it.filter.cardTypes.size === FILTER_CARD_TYPES.length
            ) {
                it.filter.cardTypes = emptyList();
            } else {
                it.filter.cardTypes = List(FILTER_CARD_TYPES.map(it => it.key));
            }
            return it;
        });
        onChange && onChange(result);
    }

    private onChangeBenefitType(type: CardBenefitType) {
        const { spec, onChange } = this.props;
        const result = lets(spec, it => {
            if (it.benefitTypes.contains(type)) {
                it.benefitTypes = it.benefitTypes
                    .filter(item => item !== type)
                    .toList()
            } else {
                it.benefitTypes = it.benefitTypes.push(type);
            }
            return it;
        });
        onChange && onChange(result);
    }

    private setBenefitType(types: Array<CardBenefitType>) {
        const { spec, onChange } = this.props;
        const result = lets(spec, it => {
            it.benefitTypes = List(types);
            return it;
        });
        onChange && onChange(result);
    }

    private onChangeAnnualCost = (min: number, max?: number) => {
        const { spec, onChange } = this.props;
        const result = lets(spec, it => {
            it.filter.annualCostMin = min;
            it.filter.annualCostMax = max;
            return it;
        });
        onChange && onChange(result);
    };

    private onChangePromotionOnly() {
        const { spec, onChange } = this.props;
        const result = lets(spec, it => {
            it.filter.promotionOnly = !it.filter.promotionOnly;
            return it;
        });
        onChange && onChange(result);
    }

    private onChangeCompany(company: string) {
        const { spec, config, onChange } = this.props;
        const result = lets(spec, it => {
            if (it.filter.companies) {
                if (it.filter.companies.contains(company)) {
                    it.filter.companies = it.filter.companies.filter(it => it !== company).toList();
                } else {
                    it.filter.companies = it.filter.companies.concat(company).toList();
                }
            } else {
                it.filter.companies = List(
                    config.companies
                        .entrySeq()
                        .filter(([key, value]) => key !== company)
                        .map(([key, value]) => key)
                );
            }
            return it;
        });

        // TODO: (심의) 알프레드 카드사 api 고쳐지면 legal-service로 대체
        if (EXCEPTED_CARD_COMPANY.contains(company)) {
            alert('해당 카드사의 요청에 의해 현재 추천이 중단된 카드사입니다. 서비스 이용에 불편을 드려 죄송합니다.');
        } else {
            onChange && onChange(result);
        }
    }

    private onSelectAllOfCompanies() {
        const { spec, config, onChange } = this.props;
        const result = lets(spec, it => {
            if (
                it.filter.companies === null ||
                it.filter.companies.size === config.companies.size
            ) {
                it.filter.companies = emptyList();
            } else {
                it.filter.companies = config.companies.entrySeq().map(([key]) => key).toList();
            }
            return it;
        });
        onChange && onChange(result);
    }

    private onChangeBrand(brand: CardBrand) {
        const { spec, config, onChange } = this.props;
        const result = lets(spec, it => {
            if (it.filter.brands) {
                if (it.filter.brands.contains(brand)) {
                    it.filter.brands = it.filter.brands.filter(it => !it.equals(brand)).toList();
                } else {
                    it.filter.brands = it.filter.brands.concat(brand).toList();
                }
            } else {
                it.filter.brands = config.brands.filter(it => it !== brand).toList();
            }
            return it;
        });
        onChange && onChange(result);
    }

    private onSelectAllOfBrands() {
        const { spec, config, onChange } = this.props;
        const result = lets(spec, it => {
            if (
                it.filter.brands === null ||
                it.filter.brands.size === config.brands.size
            ) {
                it.filter.brands = emptyList();
            } else {
                it.filter.brands = null;
            }
            return it;
        });
        onChange && onChange(result);
    }

    private onChangeSpecialBenefit(key: number) {
        const { spec, onChange } = this.props;
        const result = lets(spec, it => {
            if (it.filter.specialBenefitIds) {
                if (it.filter.specialBenefitIds.contains(key)) {
                    it.filter.specialBenefitIds = it.filter.specialBenefitIds.filter(it => it !== key).toList();
                } else {
                    it.filter.specialBenefitIds = it.filter.specialBenefitIds.concat(key).toList();
                }
            } else {
                it.filter.specialBenefitIds = List([key]);
            }
            return it;
        });
        onChange && onChange(result);
    }
}
