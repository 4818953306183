import { List } from 'immutable';
import { Entity } from 'core/entity';
import { LoanBaseInterestGroup } from 'core/entity/loan/base-interest-group';
import { LoanPrimeInterestNode } from 'core/entity/loan/prime-interest-node';
import { LoanInterestValue } from 'core/entity/loan/interest-value';

export class LoanInterestCondition extends Entity {
    baseInterests?: List<LoanBaseInterestGroup>;
    additionalInterest?: LoanInterestValue;
    averageInterests?: List<LoanInterestValue>;
    primeInterests: List<LoanPrimeInterestNode>;

    constructor(
        baseInterests: List<LoanBaseInterestGroup> = null,
        additionalInterest: LoanInterestValue = null,
        averageInterests: List<LoanInterestValue> = null,
        primeInterests: List<LoanPrimeInterestNode>
    ) {
        super();
        this.baseInterests = baseInterests;
        this.additionalInterest = additionalInterest;
        this.averageInterests = averageInterests;
        this.primeInterests = primeInterests;
    }
}
