import { Event } from 'presentation/bus';

export class DocumentScrollEvent implements Event {
    public scrollTop: number = 0;
    public scrollBottom: number = 0;

    constructor(scrollTop: number, scrollBottom: number) {
        this.scrollTop = scrollTop;
        this.scrollBottom = scrollBottom;
    }
}
