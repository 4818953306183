import { FinanceSectorID } from 'core/entity/finance-sector/id';
import React, { useEffect, useState } from 'react';
import { createCmsAxiosInstance } from 'utils/create-cms-axios-instance';

import { Gnb, GnbFromStrapi, mapGnbData } from './gnb-data';
import { GlobalNavigationBarDesktop } from './gnb-navigation-bar-desktop';
import { GlobalNavigationBarMobile } from './gnb-navigation-bar-mobile';
import styles from './styles.pcss';
import { isBanksaladWebView } from 'utils/is-banksalad-web-view';

interface Props {
  active?: FinanceSectorID;
  fixed?: boolean;
}

export const Header = ({ fixed }: Props) => {
  const [gnbData, setGnbData] = useState<Gnb[]>([]);

  useEffect(() => {
    (async () => {
      const { data } = await createCmsAxiosInstance().get('/banksalad-web-lnb');
      const gnbFromStrapi: GnbFromStrapi[] = data.data.attributes.gnb;

      setGnbData(mapGnbData(gnbFromStrapi));
    })();
  }, []);

  return (
    !isBanksaladWebView() &&
    <div className={fixed ? styles.fixedWrap : styles.wrap}>
      <GlobalNavigationBarDesktop gnbData={gnbData}/>
      <GlobalNavigationBarMobile gnbData={gnbData} />
    </div>
  );
};
