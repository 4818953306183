import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceCardUsedAmount } from 'core/entity/personal-finance/advice/card-used-amount';
import { AmountMapper } from 'data/http/mapper/amount';

export class AdviceCardUsedAmountMapper implements NetworkResponseMapper<AdviceCardUsedAmount> {
    fromJson(json: any): AdviceCardUsedAmount {
        return new AdviceCardUsedAmount(
            json.id,
            json.name,
            new AmountMapper().fromJson(json.used_amount)
        )
    }
}
