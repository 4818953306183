import React from 'react';
import moment from 'moment';

import styles from './styles.pcss';

interface State {
    timeStamp: Date;
    endTime: Date;
}

export class SignInMobileTimer extends React.Component<{}, State> {
    interval: number;
    state = {
        timeStamp: new Date(),
        endTime: moment().add(3, 'minutes').toDate()
    };

    componentDidMount() {
        this.interval = window.setInterval(() => this.setState({ timeStamp: new Date() }), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const endTime = moment(this.state.endTime);
        const now = moment();
        const isSameOrBefore = endTime.isSameOrBefore(now);

        if (isSameOrBefore)
            clearInterval(this.interval);

        const diffMinutes =
            !!endTime.diff(now, 'minutes') ? endTime.diff(now, 'minutes') : 0;
        const diffSeconds = endTime.add(-diffMinutes, 'minutes').diff(now, 'seconds');

        return isSameOrBefore ? (
            <p className={ styles.inValidNotice }>유효시간이<br/>만료되었습니다</p>
        ) : (
            <p className={ styles.validNotice }>
                <span>유효시간</span>
                <strong className={ styles.time }>
                    {  diffMinutes ? `${diffMinutes}분` : '' } { `${diffSeconds}초` }
                </strong>
            </p>
        );
    }
}
