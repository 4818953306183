import { Entity } from 'core/entity';
import { PersonalizedLoanOrganization } from 'core/entity/personalized-recommend/loan/organization';
import { PersonalizedLoanInterest } from 'core/entity/personalized-recommend/loan/interest';
import { PersonalizedLoanLimitation } from 'core/entity/personalized-recommend/loan/limitation';
import { PersonalizedLoanApplyLink } from 'core/entity/personalized-recommend/loan/app-link';
import { PersonalizedLoanMonthlyRepayment } from 'core/entity/personalized-recommend/loan/monthly-repayment';
import { PersonalizedLoan } from 'core/entity/personalized-recommend/loan';
import { PersonalizedLoanCategory } from 'core/entity/personalized-recommend/loan/category';
import { PersonalizedLoanSubmitStatus } from 'core/entity/personalized-recommend/loan/submit-status';

export class PersonalizedLoanGeneralType extends Entity implements PersonalizedLoan {
    constructor(
        public id: string,
        public name: string,
        public category: PersonalizedLoanCategory,
        public organization: PersonalizedLoanOrganization,
        public apiContracted: boolean,
        public loanInterest: PersonalizedLoanInterest,
        public loanLimitation: PersonalizedLoanLimitation,
        public applyLink: PersonalizedLoanApplyLink,
        public submitStatus: PersonalizedLoanSubmitStatus,
        public approvalWording: string,
        public issueReviewDescription: string = null,
        public monthlyRepayment: PersonalizedLoanMonthlyRepayment = null,
        public description: string = null,
        public additionalWording: string = null
    ) {
        super();
    }
}
