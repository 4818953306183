import React from 'react';

import { lets } from 'utils/index';
import { EventBus } from 'presentation/bus';
import { FixBodyEvent } from 'presentation/bus/event/fix-body-event';

import styles from './styles.pcss';

export enum AlertSize {
    SMALL, NORMAL, BIG, HUGE
}

interface Props {
    children: React.ReactNode
    title?: string
    acceptTitle?: string
    cancelTitle?: string
    onAccept?: () => void
    onCancel?: () => void
    size?: AlertSize
}

export class Alert extends React.Component<Props, undefined> {

    componentDidMount() {
        EventBus.post(new FixBodyEvent(true));
    }

    componentWillUnmount() {
        EventBus.post(new FixBodyEvent(false));
    }

    render() {
        const {
            children,
            title,
            acceptTitle,
            cancelTitle,
            onCancel,
            size
        } = this.props;

        const sizeStyle = lets(size, it => {
            switch (it) {
                case AlertSize.SMALL:
                    return styles.small;
                case AlertSize.NORMAL:
                    return styles.alert;
                case AlertSize.BIG:
                    return styles.big;
                case AlertSize.HUGE:
                    return styles.huge;
                default:
                    return styles.alert;
            }
        });

        return (
            <div className={ styles.wrap }>
                <div
                    onClick={ () => onCancel() }
                    className={ styles.dim }
                />
                <div className={ sizeStyle }>
                    {
                        title !== null && (
                            <div className={ styles.title }>
                                <h5>{ title }</h5>
                                <a onClick={ () => onCancel() }>닫기</a>
                            </div>
                        )
                    }
                    <div className={ styles.scrollable }>
                        <div className={ (acceptTitle || cancelTitle) ? styles.contentWithButtons : styles.content }>
                            { children }
                        </div>
                    </div>
                    { (acceptTitle || cancelTitle) && this.renderButtons() }
                </div>
            </div>
        )
    }

    private renderButtons() {
        const {
            cancelTitle,
            acceptTitle,
            onCancel,
            onAccept
        } = this.props;

        return (
            <div className={ styles.buttons }>
                {
                    cancelTitle && (
                        <a
                            className={ styles.cancel }
                            onClick={ () => onCancel() }
                        >
                            { cancelTitle }
                        </a>
                    )
                }
                {
                    acceptTitle && (
                        <a
                            onClick={ () => onAccept() }
                            className={ styles.accept }
                        >
                            { acceptTitle }
                        </a>
                    )
                }
            </div>
        );
    }
}
