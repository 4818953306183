import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { UserCreditScoreCard } from 'core/entity/personal-credit/card';
import { UserCreditScoreCardUsedAmountMapper } from 'data/http/mapper/personal-credit/card/used-amount';
import { UserCreditScoreCardLimitMapper } from 'data/http/mapper/personal-credit/card/credit-limit';
import { parseDate } from 'utils/index';

export class UserCreditScoreCardMapper implements
    NetworkResponseMapper<UserCreditScoreCard>,
    NetworkRequestMapper<UserCreditScoreCard>
{
    toJson(target: UserCreditScoreCard): any {
        return {
            name: target.name,
            used_amount: new UserCreditScoreCardUsedAmountMapper().toJson(target.usedAmount),
            overdue_amount: new AmountMapper().toJson(target.overdueAmount),
            credit_limit: new UserCreditScoreCardLimitMapper().toJson(target.creditLimit),
            created_at: target.createdAt.format('YYYY-MM-DD')
        }
    }

    fromJson(json: any): UserCreditScoreCard {
        return new UserCreditScoreCard(
            json.name,
            new UserCreditScoreCardUsedAmountMapper().fromJson(json.used_amount),
            new AmountMapper().fromJson(json.overdue_amount),
            new UserCreditScoreCardLimitMapper().fromJson(json.credit_limit),
            parseDate(json.created_at)
        )
    }
}
