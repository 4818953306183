import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceCreditScoreInquirySuggestionData } from 'core/entity/personal-finance/advice/content-data/credit-score-inquiry-suggestion';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';
import { parseDate, optional } from 'application/utils';

export class AdviceCreditScoreInquirySuggestionDataMapper implements NetworkResponseMapper<AdviceCreditScoreInquirySuggestionData> {
    fromJson(json: any): AdviceCreditScoreInquirySuggestionData {
        return new AdviceCreditScoreInquirySuggestionData(
            parseDate(json.base_datetime),
            optional(json.message, new AdviceMessageMapper().fromJson)
        );
    }
}
