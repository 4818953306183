import React from 'react';

import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { isIOS } from 'presentation/module/sugar';

import styles from './styles.pcss';

const IMAGE_URLS = {
    FIRST: 'https://cdn.banksalad.com/web/one-page-proposal/1.png',
    SECOND: 'https://cdn.banksalad.com/web/one-page-proposal/2.png',
    FORTH: 'https://cdn.banksalad.com/web/one-page-proposal/4.png',
    FIFTH: 'https://cdn.banksalad.com/web/one-page-proposal/5.png'
};
const APP_DOWNLOAD_URLS = {
    HEAD_PLAY_STORE: 'http://bit.ly/2lp129O',
    HEAD_APP_STORE: 'https://apple.co/2KcYeHB',
    FOOT_PLAY_STORE: 'http://bit.ly/2IdqEiV',
    FOOT_APP_STORE: 'https://apple.co/2yvi9Ar',
    FLOAT_PLAY_STORE: 'http://bit.ly/2lp20CY',
    FLOAT_APP_STORE: 'https://apple.co/2MatojB',
};

export class AppView extends React.Component {
    render() {
        return (
            <div className={ styles.container }>
                <div className={ styles.floatArea }>
                    <a
                        href={ isIOS() ? APP_DOWNLOAD_URLS.FLOAT_APP_STORE : APP_DOWNLOAD_URLS.FLOAT_PLAY_STORE }
                        target="_blank"
                        rel="nofollow"
                        className={ styles.startButton }
                        onClick={ this.onClickFloatLink }
                    >
                        지금 바로 시작하기
                    </a>
                </div>

                <section className={ styles.imageSection }>
                    <img src={ IMAGE_URLS.FIRST } />
                    <a
                        href={ APP_DOWNLOAD_URLS.HEAD_PLAY_STORE }
                        target="_blank"
                        rel="nofollow"
                        className={ styles.headGooglePlay }
                        onClick={ this.onClickHeadGooglePlay }
                    >
                        구글플레이
                    </a>
                    <a
                        href={ APP_DOWNLOAD_URLS.HEAD_APP_STORE }
                        target="_blank"
                        rel="nofollow"
                        className={ styles.headAppStore }
                        onClick={ this.onClickHeadAppStore }
                    >
                        앱스토어
                    </a>
                </section>
                <section className={ styles.imageSection }>
                    <img src={ IMAGE_URLS.SECOND } />
                </section>
                <section className={ styles.imageSection }>
                    <img src={ IMAGE_URLS.FORTH } />
                </section>
                <section className={ styles.imageSection }>
                    <img src={ IMAGE_URLS.FIFTH } />
                    <a
                        href={ APP_DOWNLOAD_URLS.FOOT_PLAY_STORE }
                        target="_blank"
                        rel="nofollow"
                        className={ styles.footGooglePlay }
                        onClick={ this.onClickFootGooglePlay }
                    >
                        구글플레이
                    </a>
                    <a
                        href={ APP_DOWNLOAD_URLS.FOOT_APP_STORE }
                        target="_blank"
                        rel="nofollow"
                        className={ styles.footAppStore }
                        onClick={ this.onClickFootAppStore }
                    >
                        앱스토어
                    </a>
                </section>
            </div>
        )
    }

    private onClickHeadGooglePlay = () => {
        sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.APP.HEAD, GA_ACTION.LINK.APP, '구글플레이');
    };

    private onClickHeadAppStore = () => {
        sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.APP.HEAD, GA_ACTION.LINK.APP, '앱스토어');
    };

    private onClickFootGooglePlay = () => {
        sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.APP.FOOT, GA_ACTION.LINK.APP, '구글플레이');
    };

    private onClickFootAppStore = () => {
        sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.APP.FOOT, GA_ACTION.LINK.APP, '앱스토어');
    };

    private onClickFloatLink = () => {
        sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.APP.FLOAT, GA_ACTION.LINK.APP, isIOS() ? '앱스토어' : '구글플레이');
    }
}
