import { NetworkResponseMapper } from 'data/http/mapper';
import { parseDate } from 'utils/index';
import { RefreshToken } from 'core/entity/user/refresh-token';

export class RefreshTokenMapper implements NetworkResponseMapper<RefreshToken> {
    fromJson(json: any): RefreshToken {
        return new RefreshToken(
            json.access_token,
            json.refresh_token,
            parseDate(json.expires_in)
        )
    }
}
