import { NetworkResponseMapper } from 'data/http/mapper';
import { FinanceSubSector } from 'core/entity/finance-sector/sub';
import { List } from 'immutable';
import { FinanceSubSectorItemMapper } from 'data/http/mapper/finance-sector/item';

export class FinanceSubSectorMapper implements NetworkResponseMapper<FinanceSubSector> {
    fromJson(json: any): FinanceSubSector {
        return new FinanceSubSector(
            json.name,
            List(json.items.map((item: any) =>
                new FinanceSubSectorItemMapper().fromJson(item)
            ))
        )
    }
}
