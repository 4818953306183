import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { Advice } from 'core/entity/personal-finance/advice';
import { AdviceMapper } from 'data/http/mapper/personal-finance/advice';
import { AdviceUpdateSpec } from 'core/entity/personal-finance/advice/update-spec';
import { AdviceUpdateSpecMapper } from 'data/http/mapper/personal-finance/advice/update-spec';
import { AccessToken } from 'core/entity/user/access-token';

export class AdviceApiProvider extends HttpProvider {
    get(id: string, accessToken: AccessToken): Observable<Advice> {
        return this.requestGET(
            `/api/v4/me/advice/${id}`,
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) =>
            new AdviceMapper().fromJson(result.data)
        )
    }

    update(id: string, spec: AdviceUpdateSpec, accessToken: AccessToken): Observable<void> {
        return this.requestPATCH(
            `/api/v4/me/advice/${id}`,
            new AdviceUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        )
    }
}
