import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import {
  LocalCachedStateStorageProvider,
  SessionCachedStateStorageProvider
} from 'data/browser-storage/api/cached-state';
import {
  CardRecommendSpecStorageProvider,
  CardSummaryForCompareStorageProvider,
  ServerRenderCardStorageProvider
} from 'data/browser-storage/api/card';
import {
  LoanRecommendFilterStorageProvider,
  LoanRecommendSpecStorageProvider,
  ServerRenderLoanStorageProvider
} from 'data/browser-storage/api/loan';
import { MedicalCheckupStorageProvider } from 'data/browser-storage/api/medical-checkup';

export class StorageProviderDependencies {
    serverRenderCard: ServerRenderCardStorageProvider;
    cardRecommendSpec: CardRecommendSpecStorageProvider;
    cardSummaryForCompare: CardSummaryForCompareStorageProvider;
    serverRenderLoan: ServerRenderLoanStorageProvider;
    loanRecommendSpec: LoanRecommendSpecStorageProvider;
    loanRecommendFilter: LoanRecommendFilterStorageProvider;
    medicalCheckupJudgement: MedicalCheckupStorageProvider;
    accessToken: AccessTokenStorageProvider;
    localCachedState: LocalCachedStateStorageProvider;
    sessionCachedState: SessionCachedStateStorageProvider;

    constructor() {
      this.serverRenderCard = new ServerRenderCardStorageProvider();
      this.cardRecommendSpec = new CardRecommendSpecStorageProvider();
      this.cardSummaryForCompare = new CardSummaryForCompareStorageProvider();
      this.serverRenderLoan = new ServerRenderLoanStorageProvider();
      this.loanRecommendSpec = new LoanRecommendSpecStorageProvider();
      this.loanRecommendFilter = new LoanRecommendFilterStorageProvider();
      this.medicalCheckupJudgement = new MedicalCheckupStorageProvider();
      this.accessToken = new AccessTokenStorageProvider();
      this.localCachedState = new LocalCachedStateStorageProvider();
      this.sessionCachedState = new SessionCachedStateStorageProvider();
    }
}
