import { EventsViewerListModel } from 'presentation/components/events-viewer/list/model';
import { List } from 'immutable';
import { EventTagsModel } from 'presentation/components/event-tags/model';
import { CardEvent } from 'core/entity/card/event';
import { CardEventSummary } from 'core/entity/card/event/summary';
import { optional } from 'utils/index';

export class EventViewerModel {
    id: string;
    title: string;
    url: string;
    description: string;
    company: EventViewerModel.Company;
    tags: EventTagsModel;
    suggestedEvents: List<EventsViewerListModel>;

    constructor(
        id: string,
        title: string,
        url: string,
        description: string,
        company: EventViewerModel.Company,
        tags: EventTagsModel,
        suggestedEvents: List<EventsViewerListModel>
    ) {
        this.id = id;
        this.title = title;
        this.url = url;
        this.description = description;
        this.company = company;
        this.tags = tags;
        this.suggestedEvents = suggestedEvents;
    }
}

export module EventViewerModel {
    export class Company {
        id: number;
        name: string;
        imageUrl: string;

        constructor(
            id: number,
            name: string,
            imageUrl: string
        ) {
            this.id = id;
            this.name = name;
            this.imageUrl = imageUrl;
        }
    }
}

export class EventViewerModelMapper {
    fromCardEvent(cardEvent: CardEvent): EventViewerModel {
        return new EventViewerModel(
            cardEvent.id,
            cardEvent.title,
            cardEvent.url,
            cardEvent.description,
            new EventViewerModel.Company(
                cardEvent.company.id,
                cardEvent.company.name,
                cardEvent.company.imageUrl
            ),
            new EventTagsModel(
                null,
                cardEvent.hot,
                null
            ),
            cardEvent.suggestedEvents.map((eventSummary: CardEventSummary) =>
                new EventsViewerListModel(
                    eventSummary.id,
                    eventSummary.title,
                    new EventTagsModel(
                        null,
                        eventSummary.hot,
                        null
                    ),
                    eventSummary.thumbNailImageUrl,
                    new EventsViewerListModel.Company(
                        eventSummary.company.id,
                        eventSummary.company.name,
                        eventSummary.company.imageUrl
                    ),
                    eventSummary.startDate,
                    optional(eventSummary.endDate)
                )
            ).toList()
        );
    }
}
