import { NetworkResponseMapper } from 'data/http/mapper';
import { CreditScore } from 'core/entity/personal-credit/score';
import { lets } from 'utils/index';
import { CreditBureauMapper } from 'data/http/mapper/personal-credit/bureau';

export class CreditScoreMapper implements NetworkResponseMapper<CreditScore> {
    private updatedAt: Date;
    private updateRequired: boolean;
    private changes: CreditScore.UserCreditScoreChanges;

    constructor(
        updatedAt: Date,
        updateRequired: boolean,
        changes: CreditScore.UserCreditScoreChanges
    ) {
        this.updatedAt = updatedAt;
        this.updateRequired = updateRequired;
        this.changes = changes;
    }

    fromJson(json: any): CreditScore {
        return new CreditScore(
            json.grade,
            lets(json.score, it => new CreditScore.Score(it.current, it.max)),
            json.top,
            lets(json.state, it => new CreditScore.State(it.description)),
            new CreditBureauMapper().fromJson(json.bureau),
            this.updatedAt,
            this.changes,
            this.updateRequired
        )
    }
}
