import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { Alfred2CardAnnualCostPromotion } from 'core/entity/alfred2/card-annual-cost-promotion';
import { Alfred2CardIssuingChannelConfiguration } from 'core/entity/alfred2/card-issuing-channel-configuration';
import { CFADeliberationReviewConfiguration } from 'core/entity/personal-finance/CFA-deliberation-review-configuration';
import { Alfred2CardCompany } from 'core/entity/alfred2/card-company';

export class Alfred2ComputedCard extends Entity {
    id: number;
    name: string;
    mileageCard: boolean;
    cardType: Alfred2ComputedCard.Type;
    company: Alfred2CardCompany;
    annualCost: Amount;
    imageUrl: string;
    imageOrientation: Alfred2ComputedCard.ImageOrientation;
    informationViewUrl: string;
    issuing: Alfred2CardIssuingChannelConfiguration;
    cfaDeliberationReview: CFADeliberationReviewConfiguration;
    annualCostPromotion?: Alfred2CardAnnualCostPromotion;

    constructor(
        id: number,
        name: string,
        mileageCard: boolean,
        cardType: Alfred2ComputedCard.Type,
        company: Alfred2CardCompany,
        annualCost: Amount,
        imageUrl: string,
        imageOrientation: Alfred2ComputedCard.ImageOrientation,
        informationViewUrl: string,
        issuing: Alfred2CardIssuingChannelConfiguration,
        cfaDeliberationReview: CFADeliberationReviewConfiguration,
        annualCostPromotion: Alfred2CardAnnualCostPromotion = null
    ) {
        super();
        this.id = id;
        this.name = name;
        this.mileageCard = mileageCard;
        this.cardType = cardType;
        this.company = company;
        this.annualCost = annualCost;
        this.imageUrl = imageUrl;
        this.imageOrientation = imageOrientation;
        this.informationViewUrl = informationViewUrl;
        this.issuing = issuing;
        this.cfaDeliberationReview = cfaDeliberationReview;
        this.annualCostPromotion = annualCostPromotion;
    }
}

export module Alfred2ComputedCard {
    export enum Type {
        CHECK, CREDIT, HYBRID
    }

    export enum ImageOrientation {
        LANDSCAPE,
        PORTRAIT
    }
}
