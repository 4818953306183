import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LocalCachedState, SessionCachedState } from 'core/entity/cached-state';
import { CachedStateItemMapper } from 'data/browser-storage/mapper/cached-state/item';

export class LocalCachedStateMapper implements BrowserStorageMapper<LocalCachedState> {
    toJson(entity: LocalCachedState): any {
        return {
            items: entity.items.map(new CachedStateItemMapper().toJson).toArray()
        }
    }

    toEntity(value: any): LocalCachedState {
        return new LocalCachedState(
            value.items.map(new CachedStateItemMapper().toEntity).toList()
        )
    }

}

export class SessionCachedStateMapper implements BrowserStorageMapper<SessionCachedState> {
    toJson(entity: SessionCachedState): any {
        return {
            items: entity.items.map(new CachedStateItemMapper().toJson).toArray()
        }
    }

    toEntity(value: any): SessionCachedState {
        return new SessionCachedState(
            value.items.map(new CachedStateItemMapper().toEntity).toList()
        )
    }

}
