import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { compactObject, optional, parseDate } from 'utils/index';
import { PersonalizedFinanceKCBLoan } from 'core/entity/personalized-finance/loan/kcb';

export class PersonalizedFinanceKCBLoanMapper implements
    NetworkRequestMapper<PersonalizedFinanceKCBLoan>,
    NetworkResponseMapper<PersonalizedFinanceKCBLoan>
{
    toJson(target: PersonalizedFinanceKCBLoan): any {
        return compactObject({
            loan_id: target.id,
            organization_name: target.organizationName,
            updated_at: target.updatedAt,
            created_at: target.createdAt.format('YYYY-MM-DD'),
            expired_at: target.expiredAt.format('YYYY-MM-DD'),
            principal: target.principal,
            balance: target.balance,
            interest: optional(target.interest),
            organization_image_url: optional(target.organizationImageUrl),
            organization_type: optional(target.organizationType),
            penalty_expired_at: optional(target.penaltyExpiredAt, it => it.format('YYYY-MM-DD'))
        });
    }

    fromJson(json: any): PersonalizedFinanceKCBLoan {
        return new PersonalizedFinanceKCBLoan(
            json.loan_id,
            json.organization_name,
            parseDate(json.updated_at),
            parseDate(json.created_at),
            parseDate(json.expired_at),
            json.principal,
            json.balance,
            optional(json.interest),
            optional(json.organization_image_url),
            optional(json.organization_type),
            optional(json.penalty_expired_at, it => parseDate(it))
        );
    }
}
