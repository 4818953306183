import { Question } from 'presentation/view-model/question';
import { toCurrency } from 'presentation/module/sugar';
import { triesHard } from 'utils/index';
import { CardSpending } from 'core/entity/card/spending';

export class CardQuestionSpending implements Question {
    topic = '';
    essential = false;
    clearable = true;
    storeId: number;
    storeName: string;
    amount: number;

    constructor(
        storeId: number,
        storeName: string,
        amount: number = 0
    ) {
        this.topic = storeName;
        this.storeId = storeId;
        this.storeName = storeName;
        this.amount = amount;
    }

    toValue(): string {
        return `${toCurrency(this.amount)}원`;
    }

    isValid(): boolean {
        return this.amount > 0;
    }

    clear(): CardQuestionSpending {
        return new CardQuestionSpending(this.storeId, this.storeName);
    }

    toEntity(): CardSpending {
        return new CardSpending(
            this.storeId,
            this.storeName,
            this.amount
        )
    }

    sameStore(storeId: number): boolean {
        return this.storeId === storeId;
    }

    equals(spending: CardQuestionSpending): boolean {
        return triesHard(() =>
            this.storeId === spending.storeId &&
            this.amount === spending.amount
        ).mustBe(() => false)
    }
}
