import { Application } from 'application/context.instance';
import { CachedStateItem } from 'core/entity/cached-state/item';
import { CachedStateType } from 'core/entity/cached-state/type';
import { EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { GA_ACTION, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import React from 'react';
import { tomorrow } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    gaCategory: string;
}

interface State {
    shown: boolean;
    disabled: boolean;
}

export class FixedBestCardRecommendationBanner extends React.Component<Props, State> {
    state = {
      shown: false,
      disabled: false
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
      this.fetch();
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    render() {
      const { shown, disabled } = this.state;
      const hidden = !shown || disabled;

      return (
        <div className={ hidden ? styles.hiddenFixed : styles.fixed }>
          <div className={ styles.container }>
            <div className={ styles.content }>
              <div className={ styles.product }>나의 BEST 카드찾기</div>
              <h2 className={ styles.title }>나에게 딱 맞는 카드를 찾아보세요</h2>
              <p className={ styles.description }>
                내 소비패턴을 입력하면<br/>
                <b>모든 카드사의 카드 중 <span className={ styles.highlight }>혜택이 가장 좋은 카드</span>를 찾아드려요!</b>
              </p>
            </div>
            <a              
              href={ BANKSALAD_SITEMAP.CARDS_QUESTIONS }
              className={ styles.deliveryButton }
              onClick={ this.onClickDelivery }
            >
                나의 BEST 카드 찾아보기
            </a>
            <div className={ styles.buttons }>
              <button className={ styles.closeButton } onClick={ this.onClickClose }>
                다음에 하겠습니다
              </button>
            </div>
          </div>
        </div>
      );
    }

    private onClickDelivery = () => {
      const { gaCategory } = this.props;
      sendGAEvent(
        GA_DOMAIN.CARD,
        gaCategory,
        GA_ACTION.LINK.CARDS_QUESTIONS,
        '나의BEST카드찾아보기'
      );
    };

    private onClickClose = () => {
      const { gaCategory } = this.props;
      sendGAEvent(
        GA_DOMAIN.CARD,
        gaCategory,
        GA_ACTION.CLOSE,
        '다음에하겠습니다'
      );
      this.cacheShown();
      this.setState({ disabled: true });
    };

    private fetch = () => {
      Application.services.cache
        .getSessionCachedState(CachedStateType.SHOWN_BEST_CARD_RECOMMENDATION_BANNER)
        .useOnAnimateFrame()
        .subscribe(
          () => { /* explicitly empty */ },
          () => this.subscribeScrollEvent()
        )
        .unsubscribeBy(this.subscriptionBag);
    };

    private subscribeScrollEvent = () => {
      EventBus.toObservable()
        .subscribe(event => {
          const { shown, disabled } = this.state;
          if (shown || disabled) return;

          if (event instanceof DocumentScrollEvent) {
            this.setState({ shown: true });
          }
        })
        .unsubscribeBy(this.subscriptionBag);
    };

    private cacheShown = () => {
      Application.services.cache
        .setSessionCachedState(
          new CachedStateItem(
            CachedStateType.SHOWN_BEST_CARD_RECOMMENDATION_BANNER,
            tomorrow()
          )
        )
        .useOnAnimateFrame()
        .subscribe()
        .unsubscribeBy(this.subscriptionBag);
    }
}
