import React from 'react';
import Rx from 'rxjs/Rx';

import { EventBus } from 'presentation/bus';
import { WindowResizeEvent } from 'presentation/bus/event/window-resize-event';
import styles from './styles.pcss';

let container: HTMLDivElement = null;
const MAX_THRESHOLD = 150;

interface Props {
    position: number;
    children: React.ReactNode;
    onPositionChange: (position: number) => void
}

interface State {
    xPosition: number;
    firstTouchPosition: number;
}

export class Carousel extends React.Component<Props, State> {
    state = {
        xPosition: 0,
        firstTouchPosition: 0
    };

    private eventBus: Rx.Subscription = EventBus.toObservable().subscribe(
        (event: Event) => {
            if (event instanceof WindowResizeEvent) {
                this.setState({
                    xPosition: event.width
                })
            }
        }
    );

    componentWillUnmount() {
        this.eventBus.unsubscribe();
    }

    render() {
        return (
            <div
                onMouseDown={ this.onMouseDown }
                onMouseUp={ this.onMouseUp }
                onTouchStart={ this.onMouseDown }
                onTouchEnd={ this.onMouseUp }
                className={ styles.wrap }
            >
                <div
                    ref={ node => container = node }
                    style={{
                        transform: `translateX(${this.getTranslatePosition()}px)`
                    }}
                    className={ styles.container }
                >
                    { this.props.children }
                </div>
            </div>
        )
    }

    private getTranslatePosition = () => {
        return container ? -(this.props.position * container.offsetWidth) : 0;
    };

    private getDelta = (position: number): number => {
        return Math.abs(position - this.state.firstTouchPosition);
    };

    private onMouseDown = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        let firstTouchPosition: number;

        if (e.nativeEvent instanceof MouseEvent) {
            firstTouchPosition = e.nativeEvent.clientX;
        } else if (e.nativeEvent instanceof TouchEvent) {
            firstTouchPosition = e.nativeEvent.changedTouches[0].clientX;
        }

        this.setState({
            firstTouchPosition
        });
    };

    private onMouseUp = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        const {
            firstTouchPosition
        } = this.state;
        const {
            position
        } = this.props;
        let lastTouchPosition: number;

        if (e.nativeEvent instanceof MouseEvent) {
            lastTouchPosition = e.nativeEvent.clientX;
        } else if (e.nativeEvent instanceof TouchEvent) {
            lastTouchPosition = e.nativeEvent.changedTouches[0].clientX;
        }

        if (this.getDelta(lastTouchPosition) < MAX_THRESHOLD)
            return;

        if (lastTouchPosition < firstTouchPosition) {
            this.props.onPositionChange(position + 1);
        } else {
            this.props.onPositionChange(position - 1);
        }
    };
}

