import { TransactionCategory } from 'core/entity/personal-finance/transaction/category';
import { TransactionCategoryFilter } from 'core/entity/personal-finance/transaction/category/filter';
import { HttpProvider } from 'data/http';
import { TransactionCategoryMapper } from 'data/http/mapper/personal-finance/transaction/category';
import { TransactionCategoryFilterMapper } from 'data/http/mapper/personal-finance/transaction/category/filter';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { TransactionIcon } from 'core/entity/personal-finance/transaction/category-icon';
import { TransactionCategoryIconMapper } from 'data/http/mapper/personal-finance/transaction/category/icon';
import { TransactionCategoryUpdateSpec } from 'core/entity/personal-finance/transaction/category/update-spec';
import { TransactionCategoryUpdateSpecMapper } from 'data/http/mapper/personal-finance/transaction/category/update-spec';
import { TransactionCategoryCreateSpec } from 'core/entity/personal-finance/transaction/category/create-spec';
import { TransactionCategoryCreateSpecMapper } from 'data/http/mapper/personal-finance/transaction/category/create-spec';
import { TransactionCategoryUpdatePrioritySpec } from 'core/entity/personal-finance/transaction/category/update-priority-spec';
import { TransactionCategoryUpdatePrioritySpecMapper } from 'data/http/mapper/personal-finance/transaction/category/update-priority-spec';

export class TransactionCategoryApiProvider extends HttpProvider {
    getAll(accessToken: AccessToken, filter: TransactionCategoryFilter): Observable<List<TransactionCategory>> {
        return this.requestGET(
            'api/v2/me/transactions/categories',
            new TransactionCategoryFilterMapper().toJson(filter),
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map((it: any) =>
                new TransactionCategoryMapper().fromJson(it)
            ))
        )
    }

    getIcons(accessToken: AccessToken): Observable<List<TransactionIcon>> {
        return this.requestGET(
            'api/v2/me/transactions/categories/icons',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map((it: any) =>
                new TransactionCategoryIconMapper().fromJson(it)
            ))
        )
    }

    update(accessToken: AccessToken, id: string, spec: TransactionCategoryUpdateSpec): Observable<TransactionCategory> {
        return this.requestPATCH(
            `api/v2/me/transactions/categories/${id}`,
            new TransactionCategoryUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result => new TransactionCategoryMapper().fromJson(result.data))
    }

    updateCategoriesPriority(accessToken: AccessToken, spec: List<TransactionCategoryUpdatePrioritySpec>): Observable<List<TransactionCategory>> {
        return this.requestPATCH(
            `api/v2/me/transactions/categories`,
            new TransactionCategoryUpdatePrioritySpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result => result.data.map((category: any) => new TransactionCategoryMapper().fromJson(category)))
    }

    create(accessToken: AccessToken, spec: TransactionCategoryCreateSpec): Observable<List<TransactionCategory>> {
        return this.requestPOST(
            'api/v2/me/transactions/categories',
            new TransactionCategoryCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map((it: any) =>
                new TransactionCategoryMapper().fromJson(it)
            ))
        )
    }

    delete(accessToken: AccessToken, id: string): Observable<TransactionCategory> {
        return this.requestDELETE(
            `api/v2/me/transactions/categories/${id}`,
            null,
            null,
            { Authorization: accessToken.accessToken }
        ).map(result => new TransactionCategoryMapper().fromJson(result.data))
    }
}
