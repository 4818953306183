import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV1PrivacyAgreementsCreditGoalView } from 'presentation/routes/policies/v1/privacy-agreements/credit-goal/view';

export const PoliciesV1PrivacyAgreementsCreditGoalRoute = () => (
    <>
        <MetaHelmet meta={ META_SET.PRIVACY_AGREEMENT }/>
        <PoliciesV1PrivacyAgreementsCreditGoalView/>
    </>
);
