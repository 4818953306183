import { Entity } from 'core/entity';
import { UserCreditScoreCardUsedAmount } from 'core/entity/personal-credit/card/used-amount';
import { Amount } from 'core/entity/amount';
import { UserCreditScoreCardLimit } from 'core/entity/personal-credit/card/credit-limit';

export class UserCreditScoreCard extends Entity {
    constructor(
        public name: string,
        public usedAmount: UserCreditScoreCardUsedAmount,
        public overdueAmount: Amount,
        public creditLimit: UserCreditScoreCardLimit,
        public createdAt: Date
    ) {
        super();
    }
}
