import { TransactionCategoryFilter } from 'core/entity/personal-finance/transaction/category/filter';
import { TransactionCategoryRepositoryType } from 'core/repository/personal-finance/transaction-category';
import { TransactionCategoryApiProvider } from 'data/http/api/gateway/v2/users/transactions/categories';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { TransactionIcon } from 'core/entity/personal-finance/transaction/category-icon';
import { TransactionCategoryUpdateSpec } from 'core/entity/personal-finance/transaction/category/update-spec';
import { TransactionCategoryCreateSpec } from 'core/entity/personal-finance/transaction/category/create-spec';
import { TransactionCategory } from 'core/entity/personal-finance/transaction/category';
import { TransactionCategoryUpdatePrioritySpec } from 'core/entity/personal-finance/transaction/category/update-priority-spec';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class TransactionCategoryRepository implements TransactionCategoryRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: TransactionCategoryApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: TransactionCategoryApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    getAll(filter: TransactionCategoryFilter): Observable<List<TransactionCategory>> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.getAll(token, filter)
            )
    }

    getIcons(): Observable<List<TransactionIcon>> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.getIcons(token)
            )
    }

    update(id: string, spec: TransactionCategoryUpdateSpec): Observable<TransactionCategory> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.update(token, id, spec)
            )
    }

    updateCategoriesPriority(spec: List<TransactionCategoryUpdatePrioritySpec>): Observable<List<TransactionCategory>> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.updateCategoriesPriority(token, spec)
            )
    }

    create(spec: TransactionCategoryCreateSpec): Observable<List<TransactionCategory>> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.create(token, spec)
            )
    }

    delete(id: string): Observable<TransactionCategory> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.delete(token, id)
            )
    }
}
