import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { CachedStateTypeMapper } from 'data/browser-storage/mapper/cached-state/type';
import { optional, parseDate, toDateString } from 'utils/index';
import { CachedStateItem } from 'core/entity/cached-state/item';

export class CachedStateItemMapper implements BrowserStorageMapper<CachedStateItem> {
    toJson(entity: CachedStateItem): any {
        return {
            type: new CachedStateTypeMapper().toJson(entity.type),
            expiredAt: optional(entity.expiredAt, toDateString)
        }
    }

    toEntity(value: any): CachedStateItem {
        return new CachedStateItem(
            new CachedStateTypeMapper().toEntity(value.type),
            optional(value.expiredAt, parseDate)
        )
    }

}
