import { Entity } from 'core/entity';
import { List } from 'immutable';
import { UserCreditScoreInquiryRecord } from 'core/entity/personal-credit/inquery-record';

export class UserCreditScoreInquiryRecordStatus extends Entity {
    inquiryRecords: List<UserCreditScoreInquiryRecord>;
    updatedAt: Date;
    changed: boolean;

    constructor(
        inquiryRecords: List<UserCreditScoreInquiryRecord>,
        updatedAt: Date,
        changed: boolean
    ) {
        super();
        this.inquiryRecords = inquiryRecords;
        this.updatedAt = updatedAt;
        this.changed = changed;
    }
}
