import { NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { FinanceSectorItemBadge } from 'core/entity/finance-sector/item/badge';

export class FinanceSectorItemBadgeMapper implements NetworkResponseMapper<FinanceSectorItemBadge> {
    fromJson(json: any): FinanceSectorItemBadge {
        return lets(json, it => {
            switch (it) {
                case 'best':
                    return FinanceSectorItemBadge.BEST;
                case 'hot':
                    return FinanceSectorItemBadge.HOT;
                case 'new':
                    return FinanceSectorItemBadge.NEW;
                default:
                    return null;
            }
        })
    }
}
