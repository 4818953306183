import { NetworkRequestMapper } from 'data/http/mapper';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { LoanCreditScoreAgencyMapper } from 'data/http/mapper/loan/credit-score-agency';

export class LoanRecommendSpecMapper implements NetworkRequestMapper<LoanRecommendSpec> {
    toJson(target: LoanRecommendSpec): any {
        return {
            desired_amount: target.desiredAmount,
            credit_score: target.creditScore,
            credit_score_agency: new LoanCreditScoreAgencyMapper().toJson(target.creditScoreAgency),
            occupational_group_id: target.occupationalGroup.id,
            annual_income: target.annualIncome,
            history_ids: target.histories.map(it => it.id).toArray(),
            prime_interest_node_ids: target.primeInterestNodeIds.toArray()
        }
    }

}
