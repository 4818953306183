import { Entity } from 'core/entity';
import { List } from 'immutable';
import { ComparableCardConfigurationSet } from 'core/entity/comparable-card/configuration-set';

export class ContentCardComparisonArticle extends Entity {
    introduction: string;
    conclusion: string;
    sets: List<ComparableCardConfigurationSet>;

    constructor(
        introduction: string,
        conclusion: string,
        sets: List<ComparableCardConfigurationSet>
    ) {
        super();
        this.introduction = introduction;
        this.conclusion = conclusion;
        this.sets = sets;
    }
}
