import { NetworkResponseMapper } from 'data/http/mapper';
import { Alfred2CardCompany } from 'core/entity/alfred2/card-company';

export class Alfred2CardCompanyMapper implements NetworkResponseMapper<Alfred2CardCompany> {
    fromJson(json: any): Alfred2CardCompany {
        return new Alfred2CardCompany(
            json.id,
            json.name,
            json.image_url
        );
    }
}
