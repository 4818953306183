import React from 'react';
import { List } from 'immutable';

import { toast, toCurrency } from 'presentation/module/sugar';
import { lets, uniqueKey } from 'utils/index';
import { CreditLoanCalculationResult } from 'presentation/components/info/credit-loan/calculator/model';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';
import { Alert } from 'presentation/components/alert';
import { LoanOption } from 'core/entity/loan/option';

import styles from './styles.pcss';
const TEN_THOUSAND = 10000;
const UNIT_TEN_THOUSAND = '만원';
const UNIT_MONTH = '개월';
const UNIT_PERCENT = '%';

interface Props {
    amount: number;
    periodMonths: number;
    interestRate: number;
    options: List<LoanOption>;
}

interface State {
    amount: number;
    periodMonths: number;
    interestRate: number;
    repaymentType: LoanRepaymentType;
    result?: CreditLoanCalculationResult;
    alert: boolean;
}

export class CreditLoanInfoCalculator extends React.Component<Props, State> {
    state = {
        amount: this.props.amount ? this.props.amount : 0,
        periodMonths: this.props.periodMonths ? this.props.periodMonths : 0,
        interestRate: this.props.interestRate ? this.props.interestRate : 0,
        repaymentType: this.props.options.first().repaymentType,
        result: null as CreditLoanCalculationResult,
        alert: false
    };

    componentWillReceiveProps(nextProps: Props) {
        if (this.state.interestRate !== nextProps.interestRate)
            return this.setState({ interestRate: nextProps.interestRate || 0 })
    }

    render() {
        const {
            amount,
            periodMonths,
            interestRate,
            repaymentType,
            result,
            alert
        } = this.state;

        return (
            <div>
                <h4 className={ styles.title }>매월 갚아야 할 금액은?</h4>
                <div className={ styles.formContainer }>
                    <dl className={ styles.form }>
                        <dt>대출금액</dt>
                        <dd>
                            <InputNumber
                                value={ amount / TEN_THOUSAND }
                                unit={ UNIT_TEN_THOUSAND }
                                onChange={ this.onChangeAmount }
                            />
                        </dd>

                        <dt>대출기간</dt>
                        <dd>
                            <InputNumber
                                value={ periodMonths }
                                unit={ UNIT_MONTH }
                                onChange={ this.onChangePeriod }
                            />
                        </dd>

                        <dt>연 이자율</dt>
                        <dd>
                            <InputNumber
                                value={ interestRate }
                                unit={ UNIT_PERCENT }
                                onChange={ this.onChangeInterestRate }
                            />
                        </dd>

                        <dt>
                            상환방법
                            <button className={ styles.question }>모르겠다면?</button>
                        </dt>
                        <dd>
                            <Radio
                                list={ this.toRepaymentTypesString() }
                                isActive={ it => it.key === repaymentType }
                                renderItem={ it => it.value }
                                onChange={ it => this.onChangeRepaymentType(it.key) }
                            />
                        </dd>
                    </dl>
                    <button
                        className={ styles.submit }
                        onClick={ this.calculate }
                    >
                        계산하기
                    </button>
                </div>
                {
                    result && (
                        <div className={ styles.result }>
                            <dl className={ styles.calculation }>
                                <dt>대출원금</dt>
                                <dd>
                                    { toCurrency(result.input.amount) }
                                    <span className={ styles.calculationUnit }>원</span>
                                </dd>

                                <dt className={ styles.active }>총 대출이자</dt>
                                <dd className={ styles.active }>
                                    { toCurrency(result.totalInterest) }
                                    <span className={ styles.calculationUnit }>원</span>
                                </dd>

                                <dt>총 상환금액</dt>
                                <dd>
                                    { toCurrency(result.input.amount + result.totalInterest) }
                                    <span className={ styles.calculationUnit }>원</span>
                                </dd>
                            </dl>
                            <section className={ styles.summary }>
                                <span className={ styles.summaryLabel }>1회차 월 상환액</span>
                                <span className={ styles.summaryValue }>
                                    { toCurrency(result.monthlyRepayment.first().repayAmount) }
                                    <span className={ styles.summaryUnit }>원</span>
                                </span>
                                <button
                                    className={ styles.summaryMore }
                                    onClick={ this.onToggleAlert }
                                >
                                    월별 상환금액 더보기
                                </button>
                            </section>

                            {
                                alert && (
                                    <ResultAlert
                                        result={ result }
                                        onCancel={ this.onToggleAlert }
                                    />
                                )
                            }
                        </div>
                    )
                }
            </div>
        )
    }

    private toRepaymentTypesString = () =>
        this.props.options.map(option => lets(option.repaymentType, type => {
            switch (type) {
                case LoanRepaymentType.AT_END:
                    return { key: LoanRepaymentType.AT_END, value: '만기상환' };
                case LoanRepaymentType.PROGRESSIVE:
                    return { key: LoanRepaymentType.PROGRESSIVE, value: '원리금 균등' };
                case LoanRepaymentType.INITIAL_VALUE:
                    return { key: LoanRepaymentType.INITIAL_VALUE, value: '원금 균등' };
            }
        })).toList();

    private onChangeAmount = (amount: number) =>
        this.setState({ amount: amount * TEN_THOUSAND });

    private onChangePeriod = (periodMonths: number) =>
        this.setState({ periodMonths });

    private onChangeInterestRate = (interestRate: number) =>
        this.setState({ interestRate });

    private onChangeRepaymentType = (repaymentType: LoanRepaymentType) =>
        this.setState({ repaymentType });

    private onToggleAlert = () =>
        this.setState(state => ({ alert: !state.alert }));

    private calculate = () => {
        const {
            amount,
            periodMonths,
            interestRate,
            repaymentType
        } = this.state;

        if (amount <= 0) {
            return toast('대출금액을 입력해주세요');
        } else if (periodMonths <= 0) {
            return toast('대출기간을 입력해주세요');
        } else if (interestRate <= 0) {
            return toast('이율을 입력해주세요');
        }

        this.setState({
            result: new CreditLoanCalculationResult(
                amount,
                periodMonths,
                interestRate,
                repaymentType
            )
        });
    }

}

interface InputNumberProps {
    value: number;
    unit?: string;
    onChange: (value: number) => void;
}

const InputNumber = (props: InputNumberProps) =>
    <div className={ styles.input }>
        <div className={ styles.inputContainer }>
            <input
                type="number"
                value={ props.value.toString().replace(/^0/, '') }
                onChange={ e => props.onChange(+e.currentTarget.value) }
            />
            <span className={ styles.inputShower }>{ toCurrency(props.value) }</span>
        </div>
        { props.unit && <span className={ styles.inputUnit }>{ props.unit }</span> }
    </div>;

interface RadioProps {
    list: List<any>;
    isActive: (item: any) => boolean;
    renderItem: (item: any) => string;
    onChange: (item: any) => void;
}

const Radio = (props: RadioProps) => lets(uniqueKey(), group =>
    <ul className={ styles.radio }>
        {
            props.list.map((item, i) => lets(`${group}-${i}`, key =>
                <li key={ key } style={{ width: `${100 / props.list.size}%` }}>
                    <input
                        type="radio"
                        name={ group }
                        id={ key }
                        checked={ props.isActive(item) }
                        className={ styles.radioTrigger }
                        onChange={ () => props.onChange(item) }
                    />
                    <label htmlFor={ key } className={ styles.radioButton }>{ props.renderItem(item) }</label>
                </li>
            ))
        }
    </ul>
);

interface ResultAlertProps {
    result: CreditLoanCalculationResult;
    onCancel: () => void;
}

const ResultAlert = (props: ResultAlertProps) =>
    <Alert
        title="월별 상환금과 대출잔금"
        onCancel={ props.onCancel }
    >
        <div className={ styles.alertHead }>
            <span className={ styles.alertHeadTitle }>
                <b>월 납입원금</b> { props.result.input.toRepaymentTypeString() }
            </span>
            <strong className={ styles.alertHeadValue }>
                { toCurrency(props.result.input.amount + props.result.totalInterest) }
            </strong>
        </div>
        <table className={ styles.table }>
            <thead>
            <tr>
                <th>회차</th>
                <th>상환금액</th>
                <th>대출잔금</th>
            </tr>
            </thead>
            <tbody>
            {
                props.result.monthlyRepayment.map((item, i) =>
                    <tr key={ `result-${i}` }>
                        <td>{ i + 1 }</td>
                        <td>{ toCurrency(item.repayAmount) }</td>
                        <td>{ toCurrency(item.balance) }</td>
                    </tr>
                )
            }
            </tbody>
        </table>
    </Alert>;
