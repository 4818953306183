import { Entity } from 'core/entity';

export class CardBrand extends Entity {
    id: CardBrand.Key;
    name: string;

    constructor(
        id: CardBrand.Key,
        name: string
    ) {
        super();
        this.id = id;
        this.name = name;
    }
}

export module CardBrand {
    export enum Key {
        LOCAL, // 국내전용
        VISA,
        MASTER,
        MAESTRO,
        AMEX,
        UNION_PAY,
        BC_LOCAL,
        BC_GLOBAL,
        URS,
        JCB,
        W_JCB,
        K_WORLD,
        CHAEUM, // 채움
        S_AND, // S&
        DINERS,
        CIRRUS,
        L_GLOBAL
    }
}
