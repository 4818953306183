import React from 'react';
import { List } from 'immutable';
import { chunk } from 'lodash';

import { LandingEventModel } from 'presentation/components/landing-events/model';
import { toDateString, uniqueKey } from 'utils/index';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { GA_ACTION, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    events: List<LandingEventModel>,
    gaCategory?: string
}

export const LandingEvents = (props: Props) => {
    return props.events.size > 0 && (
        <ul className={ styles.wrap }>
            {
                chunk(props.events.toArray(), 3)[0].map(event => (
                    <li key={ uniqueKey() }>
                        <a
                            href={ event.link }
                            style={{ backgroundImage: `url("${event.imageUrl}")`} }
                            className={ styles.box }
                            onClick={ () => props.gaCategory && sendGAEvent(GA_DOMAIN.CARD, props.gaCategory, GA_ACTION.LINK.CARDS_EVENTS_DETAIL, `${event.title}_${event.id}`) }
                        >
                            { renderBadge(event.startAt, event.endAt) }
                            <h5 className={ styles.title }>{ event.title }</h5>
                        </a>
                    </li>
                ))
            }
            <li>
                <a
                    href={ BANKSALAD_SITEMAP.LOGIN }
                    className={ styles.subscription }
                >
                    매 주 최신 카드사 이벤트를 <br/>메일로 받아보세요!
                </a>
            </li>
        </ul>
    );
};

const renderBadge = (startAt: Date, endAt: Date) => {
    const createdToday = toDateString(new Date()) === toDateString(startAt);
    const finished = new Date().getTime() > endAt.getTime();
    const leftOneDay = (endAt.getTime() - new Date().getTime()) < 1000 * 60 * 60 * 24;

    if (leftOneDay)
        return (
            <span className={ styles.badge }>
                <span>마감임박</span>
            </span>
        );

    if (createdToday)
        return (
            <span className={ `${styles.badge} ${styles.blueberry}` }>
                <span>오늘오픈</span>
            </span>
        );

    if (finished)
        return (
            <span className={ styles.badge }>
                <span>종료</span>
            </span>
        )
};
