import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1PrivacyPolicyView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 개인정보취급방침</h3>
      <p>
                주식회사 레이니스트(이하 "회사")는 뱅크샐러드 서비스(이하 "서비스")를 제공함에 있어 서비스의 기획부터 종료까지 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’), 개인정보보호법 등 국내의 개인정보 보호 법령을 철저히 준수하며, 관련 법규에 의거한 개인정보취급방침을 정하여 고객 권익 보호에 최선을 다하고 있습니다.개인정보취급방침의 ‘고객’은 정보통신망법 제2조(정의) 제4호에 규정된 정보통신서비스 제공자가 제공하는 정보통신서비스의 이용자에 한정합니다.회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보취급방침을 개정하는 경우 회사가 운영하는 사이트(이하 "사이트")에 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
      </p>
      <ol>
        <li>
          <b>1. 수집하는 개인정보의 항목</b>
          <p>회사는 고객이 입력한 정보를 기반으로 회원가입, 뱅크샐러드 서비스의 제공, 원활한 고객상담 등을 위하여 다음과 같은 정보를 수집합니다.</p>
          <ol>
            <li>
                    (1) 서비스 회원 가입을 위한 필수 수집 항목
              <p>- 이름, 이메일 주소, Facebook ID 또는 Google ID 식별자, 이동전화번호</p>
            </li>
            <li>
                    (2) 서비스 이용성 증대를 위한 선택 수집 항목
              <p>- 수입, 지출 등 금융 거래 관련 금융/카드사 SMS 수신 내역(이용자 동의 시 서비스가 단말기에서 직접 수집)</p>
              <p>- 사용하는 신용/체크카드 및 금융상품명, 결제 일자/결제 상호명/결제 금액/결제 수단 등 소비 내역, 성별, 연령, 소득 정보 등 통계학적 정보(서비스 가입 후 이용자가 선택적으로 입력)</p>
            </li>
          </ol>
        </li>
        <li>
          <b>2. 수집한 개인정보의 이용 목적</b>
          <p>회사는 다음과 같은 목적으로 개인정보를 수집합니다.</p>
          <ol>
            <li>(1) 이름, 이메일 주소, SNS서비스 Facebook의 ID 또는 Google ID 식별자, 이동전화번호를 수집하여 서비스 제공을 위한 회원 가입 및 관리에 이용</li>
            <li>(2) 고객의 수입, 지출 등 금융 거래 관련 금융/카드사 SMS 수신 내역을 수집하고 사용하는 금융상품명을 확인하여 지출 관리와 소비 코칭 서비스 제공</li>
            <li>(3) 성별, 연령, 소득 정보 등 통계학적 특성 분석에 기반한 새로운 맞춤형 서비스 제공 및 고객의 거래 내역, 금융 정보를 조합/분석하여 개인 맞춤형 금융 상품 추천 제공</li>
            <li>(4) 기타 새로운 정보의 소개 및 고지사항 전달, 본인 의사 확인, 서비스에서의 본인 식별 또는 인증 등 고객 관리, 서비스 관련 문의 및 불만사항 처리 등</li>
          </ol>
        </li>
        <li>
          <b>3. 개인정보 보유 및 이용 기간</b>
          <p>개인정보는 고객의 서비스의 이용 기간에만 활용되며, 회원 탈퇴 시 지체 없이 파기합니다. 단, 내부 방침에 의해 고객에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.</p>
          <ol>
            <li>
                    (1) 부정 이용 방지를 위한 보유
              <p>반복적인 회원 탈퇴 및 재가입으로 인한 경제적 이익 취득 등 불법,편법 행위 차단을 위해 회원 탈퇴 후 1년 동안 해당 회원의 뱅크샐러드 ID(Facebook의 ID 또는 Google ID 식별자, 이메일 주소), 탈퇴 일자 등을 1년간 보유</p>
            </li>
            <li>
                    (2) 관련 법령에 의한 보유
              <p>상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정에 의하여 일정 기간 정보를 보유해야 할 필요가 있을 경우 법령이 지정한 기간동안 보유</p>
              <ol>
                <li>가. 계약 또는 청약철회 등에 관한 기록 보유: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                <li>나. 대금결제 및 재화 등의 공급에 관한 기록 보유: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                <li>다. 소비자 불만 또는 분쟁 처리에 관한 기록 보유: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                <li>라. 본인확인에 관한 기록 보유: 6개월 (정보통신망 이용촉진 및 정보보호 등에 관한 법률)</li>
                <li>마. 방문에 관한 기록 보유: 3개월 (통신비밀보호법)</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>4. 고객 및 법정대리인의 권리와 행사 방법</b>
          <ol>
            <li>
                    (1) 개인정보의 열람 및 수정 요청
              <p>고객 및 법정대리인은 언제든지 등록되어 있는 본인의 개인정보를 조회, 수정할 수 있습니다. 이용자는 개인정보의 조회, 수정을 위하여 서비스에 등록된 정보를 클릭하거나 설정 메뉴에 접근하여 계정 정보 등 등록된 개인정보를 직접 열람하거나 수정할 수 있습니다.</p>
            </li>
            <li>
                    (2) 개인정보의 삭제(회원탈퇴)
              <p>고객이 회원탈퇴를 원하는 경우, 모바일 애플리케이션에 로그인한 후 '설정-뱅크샐러드 탈퇴하기'를 통해 직접 탈퇴할 수 있습니다. 만일 직접 탈퇴가 어려운 경우 개인정보관리담당자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치를 취하겠습니다.</p>
            </li>
          </ol>
        </li>
        <li>
          <b>5. 개인정보의 파기절차 및 방법</b>
          <p>개인정보는 회원탈퇴, 서비스 종료, 고객에게 동의받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.</p>
          <ol>
            <li>
                    (1) 파기절차 및 시점
              <p>고객이 서비스 가입 등을 위해 입력한 정보는 서비스 회원 탈퇴 등 이용목적이 달성된 후 내부 방침 및 기타 관련 법령(3. 개인정보 보유 및 이용기간 참고)에 의한 정보보호 방침에 의해 보관하거나 파기합니다. 일반적으로 회원 가입 시 수집된 개인 정보는 회원 탈퇴 시점에 바로 파기합니다. 단, 고객에게 별도로 동의를 받거나 관련 법령의 규정에 의해 개인정보를 보유해야 할 필요가 있는 경우에는 해당 법령에서 정한 기간 동안 보유한 후 기간이 지나면 바로 삭제합니다. 보관하는 정보는 다른 목적으로는 절대 이용하지 않습니다.</p>
            </li>
            <li>
                    (2) 파기방법
              <p>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
            </li>
          </ol>
        </li>
        <li>
          <b>6. 개인정보의 제3자 제공</b>
          <p>회사는 회원의 개인정보를 '개인정보의 수집 및 이용 목적'에서 명시한 범위 내에서만 사용하며, 그 범위를 초과하여 이용하거나 타인 또는 타기업/기관에게 제공하지 않습니다. 단, 서비스의 목적상 필수적으로 필요한 경우 주의를 기울여 아래 대상자에게 개인정보를 제공합니다.</p>
          <ol>
            <li>
                    (1) 서비스 사용자
              <p>뱅크샐러드 서비스 사용 중 고객의 소비내역 정보 및 고객이 선택적으로 입력한 기타 정보가 연결된 회원 간에 공개될 수 있습니다. 또한 고객이 상대방을 초대하거나 친구로 등록하는 경우, 설정에 따라 초대자 혹은 친구 식별을 위해 이름, 프로필 사진, 일부 소비내역 정보가 피초대자에게 공개될 수 있습니다.</p>
            </li>
            <li>
                    (2) 제휴사
              <p>
                        보다 나은 서비스를 제공하기 위해 고객의 개인정보를 제휴사에게 제공하거나 공유할 수 있습니다. 개인정보를 제휴사에게 공유할 경우에는 사전에 고객에게 제휴사명과 제공 또는 공유 정보, 사유, 공유 기간 등을 개별적으로 전자우편 등을 통하여 고지하고 동의를 구하는 절차를 거칩니다. 고객께서 동의하지 않는 경우에는 제휴사에게 정보를 제공하거나 공유하지 않습니다. 제휴 관계에 변화가 있거나 제휴관계가 종료될 시에도 같은 절차에 따라 고지하거나 동의를 구합니다.<br/>
                        [서비스 제공을 위해 개인정보를 제공하는 업체]<br/>
                        Google 웹로그 분석: 서비스 이용성 분석 툴 지원 업체
              </p>
              <ul>
                <li>- 제공하는 항목: 방문 횟수, 특정 기능 사용 여부 등 서비스 사용에 따라 발생하는 생성 정보</li>
                <li>- 제공 목적: 사용성 분석을 통해 서비스 개선 및 운영에 참고</li>
                <li>- 개인정보의 보유 및 이용기간: 회원 탈퇴 시 또는 위탁계약 종료 시</li>
              </ul>
            </li>
            <li>
                    (3) 법령에 특별한 규정이 있는 경우
            </li>
            <li>
                    (4) 수사기관이 수사 목적으로 정해진 절차와 방법에 따라 요구한 경우
            </li>
          </ol>
        </li>
        <li>
          <b>7. 수집한 개인정보의 위탁</b>
          <p>
                    회사는 고객의 동의 없이 고객의 정보를 외부업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁업무 내용에 대해 고객에게 통지하고 필요한 경우 사전동의를 받도록 하겠습니다.
          </p>
        </li>
        <li>
          <b>8. 개인 정보 수집 장치에 대한 안내 및 거부에 대한 사항</b>
          <p>
                    회사는 SMS 금융 거래 내역 자동입력 기능을 통하여 고객의 신용/체크카드 소비 내역 등 금융 거래내역을 수집하는 경우도 있습니다.
                    SMS 금융 거래 내역 자동입력 기능이란 서비스 내 지출내역 입력 및 관리를 원활하게 하기 위해 금융사 거래내역 SMS 수신 시 거래일자, 상호명, 금액정보를 조회하여 거래내역 및 분류정보를 자동으로 입력해 주는 기능입니다.
                    고객은 SMS 금융 거래 내역 자동입력 기능 사용에 선택권을 가지고 있습니다. 회사는 서비스 설치 및 가입 시 안드로이드 접근 권한 요청을 통해 동의를 받고 있습니다. SMS 지출내역 자동입력 기능 사용에 동의하지 않더라도 서비스 이용에 제한은 없으나, 서비스의 사용을 위해 모든 금융 거래 내역을 수동으로 직접 입력해야 할 수 있습니다.
          </p>
        </li>
        <li>
          <b>9. 개인정보보호를 위한 기술 및 관리적 대책</b>
          <p>
                    회사는 고객의 개인정보를 안전하게 관리하기 위하여 최선을 다하며 정보통신망법 및 개인정보보호법에서 요구하는 사항을 철저히 준수하고 있습니다. 회사의 과실로 개인정보의 상실, 유출, 변조, 훼손이 유발된 경우 회사는 즉각 이용자에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다. 개인정보보호를 위한 기술 및 관리적 대책은 아래와 같습니다.
          </p>
          <ol>
            <li>
                    (1) 기술적 대책
              <p>
                        회사는 암호알고리즘을 이용하여 네트워크상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.<br/>
                        해킹 등의 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.<br/>
                        고객의 개인정보는 비밀번호에 의해 보호되며 중요한 데이터는 암호화 알고리즘에 의해 보호되고 있습니다.
              </p>
            </li>
            <li>
                    (2) 관리적 대책
              <ol>
                <li>
                        가. 개인 정보 접근 권한자 최소화
                  <p>회사는 개인정보관련 취급 직원을 담당자에 한정시키고 있으며 개인 정보에 접근을 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있습니다.</p>
                </li>
                <li>
                        나. 개인정보 관리 관련 내부 교육 강화
                  <p>
                            회사는 내부 직원에 대한 수시 교육을 통하여 회사의 개인정보취급방침의 준수를 항상 강조하고 있습니다. 또한 신규직원 채용 시 정보보호서약서에 서명함으로 직원에 의한 정보유출을 사전에 방지하고 개인정보 취급방침에 대한 이행사항 및 직원 준수여부를 감시하기 위한 내부 절차를 마련하여 지속적으로 시행하고 있습니다.
                  </p>
                </li>
                <li>
                        다. 개인정보보호전담기구의 운영
                  <p>회사는 사내 개인정보보호전담기구 등을 통하여 회사의 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>10. 고객의 권리와 의무</b>
          <p>
                    고객은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 고객에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원 자격이 상실될 수 있습니다.<br/>
                    회사는 타인의 이메일 주소, Facebook 등 제휴업체 ID 또는 기타 개인정보를 도용하여 회원가입 한 자 또는 서비스를 이용한 자를 발견한 경우 지체 없이 해당 아이디에 대한 서비스 이용 정지 또는 회원 탈퇴 등 필요한 조치를 취합니다.<br/>
                    회사는 고객 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해서는 책임을 지지 않습니다. 고객은 본인의 개인정보를 보호하기 위해 자신의 ID와 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 할 의무가 있습니다. 또한 다른 사람이 추측할 수 있는 쉬운 비밀번호는 사용을 피하시기를 권장하며, 정기적으로 비밀번호를 변경하는 것이 바람직합니다.
          </p>
        </li>
        <li>
          <b>11. 개인정보관리책임자 및 담당자 안내</b>
          <p>회사는 고객의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보 관리 책임자 및 담당자를 지정하고 있습니다.</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <div>
                    <span>① 개인정보관리책임자</span>
                    <ul>
                      <li>- 이름 : 김태훈</li>
                      <li>- 소속 : (주)레이니스트</li>
                      <li>- 직위 : 대표이사</li>
                      <li>- 전화 : 02-3453-9300</li>
                      <li>- 이메일 : banksalad@banksalad.com</li>
                    </ul>
                  </div>
                </td>
                <td>
                  <div>
                    <span>② 개인정보관리담당자</span>
                    <ul>
                      <li>- 이름 : 김광훈</li>
                      <li>- 소속 : (주)레이니스트</li>
                      <li>- 직위 : 법무팀장</li>
                      <li>- 전화 : 02-3453-9300</li>
                      <li>- 이메일 : banksalad@banksalad.com</li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
                    기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의하시기 바랍니다.
            <ul>
              <li>- 개인정보침해신고센터 (www.118.or.kr / 118)</li>
              <li>- 대검찰청 사이버수사과 (www.spo.go.kr / 02-3480-2000)</li>
              <li>- 경찰청 사이버안전국 (www.ctrc.go.kr / 02-392-0330)</li>
            </ul>

          </p>
        </li>
        <li>
        </li>
      </ol>
      <p>
                본 개인정보취급방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 서비스 내 ‘공지사항’을 통해 사전 고지할 것입니다. 개인정보의 수집 및 활용, 제3자 제공 등과 같이 고객 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
      </p>
      <p>공고일자 : 2015. 11.30</p>
      <p>시행일자 : 2015. 11.30</p>
    </div>
  </div>
);
