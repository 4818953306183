import React from 'react';

import { SubscriptionBag } from 'presentation/module/extension';
import { EventBus } from 'presentation/bus';
import { GlobalAlertEvent } from 'presentation/bus/event/gloabl-alert-event';
import { FlexibleAlert } from 'presentation/components/flexible-alert';

import styles from './styles.pcss';

interface State {
    visible: boolean;
    title: string;
    message: string;
    onCancel?: () => void;
}

export class GlobalAlert extends React.Component<{}, State> {
    state = {
        visible: false,
        title: null as string,
        message: null as string,
        onCancel: null as () => void
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        EventBus.toObservable()
            .subscribe(
                (event: Event) => {
                    if (event instanceof GlobalAlertEvent) {
                        this.setState({
                            visible: true,
                            title: event.title,
                            message: event.message,
                            onCancel: event.onCancel
                        })
                    }
                }
            )
            .unsubscribeBy(this.subscriptionBag)
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const {
            title,
            visible,
            message
        } = this.state;

        return (
            visible &&
            <FlexibleAlert
                title={ title }
                onCancel={ this.toggleAlert }
            >
                <p
                    dangerouslySetInnerHTML={{ __html: message }}
                    className={styles.content}
                />
            </FlexibleAlert>
        );
    }

    private toggleAlert = () => (
        this.setState(prevState => ({
            visible: !prevState.visible
        }), () => {
            const { onCancel } = this.state;
            onCancel && onCancel();
        })
    );
}
