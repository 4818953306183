import { List } from 'immutable';
import moment from 'moment';

declare module 'immutable' {
    export interface List<T> {
        sumBy(extractor: (item: T) => number): number;
        averageBy(extractor: (item: T) => number): number;
    }
}

List.prototype.sumBy = function(f: (item: any) => number): number {
    return this.reduce((acc: number, value: any) => acc + f(value), 0);
};

List.prototype.averageBy = function(f: (item: any) => number): number {
    return this.isEmpty() ? 0 : this.reduce((acc: number, value: any) => acc + f(value), 0) / this.size;
};

declare global {
    interface Date {
        format(style: string): string;
    }

    interface String {
        find(value: string): boolean;
        isEmpty(): boolean;
    }

    interface Array<T> {
        toList(): List<T>;
    }
}

Date.prototype.format = function(style: string): string {
    return moment(this).format(style);
};

String.prototype.find = function(value: string): boolean {
    return this.replace(/\s/gi, '').indexOf(value.replace(/\s/gi, '')) !== -1;
};

String.prototype.isEmpty = function(): boolean {
    return !this || this.length === 0;
};

Array.prototype.toList = function() {
    return List(this);
};
