import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { LoanCompanySummary } from 'core/entity/personal-finance/loan-company/summary';

export class UserLoanUpdateSpec extends Entity {
    company: LoanCompanySummary;
    nickname: string;
    description: string;
    createdAt: Date;
    expiredAt: Date;
    latestBalance: number;
    principal: number;
    interestRate: number;
    hidden: boolean;
    deletedStatus: Asset.DeletedStatus;

    constructor(
        company: LoanCompanySummary = null,
        nickname: string = null,
        description: string = null,
        createdAt: Date = null,
        expiredAt: Date = null,
        latestBalance: number = null,
        principal: number = null,
        interestRate: number = null,
        hidden: boolean = null,
        deletedStatus: Asset.DeletedStatus = null,
    ) {
        super();
        this.nickname = nickname;
        this.description = description;
        this.createdAt = createdAt;
        this.expiredAt = expiredAt;
        this.latestBalance = latestBalance;
        this.principal = principal;
        this.interestRate = interestRate;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
        this.company = company;
    }
}
