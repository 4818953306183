import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { Alfred2PointAmount } from 'core/entity/alfred2/point-amount';

export class Alfred2TotalProfitConfiguration extends Entity {
    cash: Amount;
    point: Alfred2PointAmount;
    mileage: Alfred2PointAmount;

    constructor(
        cash: Amount,
        point: Alfred2PointAmount,
        mileage: Alfred2PointAmount
    ) {
        super();
        this.cash = cash;
        this.point = point;
        this.mileage = mileage;
    }
}
