import { NetworkResponseMapper } from 'data/http/mapper';
import { FinanceSubSectorItem } from 'core/entity/finance-sector/item';
import { lets } from 'utils/index';
import { FinanceSubSectorUrlItem } from 'core/entity/finance-sector/item/url';
import { FinanceSubSectorContentItem } from 'core/entity/finance-sector/item/content';
import { ContentSummaryMapper } from 'data/http/mapper/content/summary';
import { FinanceSectorItemBadgeMapper } from 'data/http/mapper/finance-sector/item/badge';

export class FinanceSubSectorItemMapper implements NetworkResponseMapper<FinanceSubSectorItem> {
    fromJson(json: any): FinanceSubSectorItem {
        return lets(json.type, type => {
            switch (type) {
                case 'url':
                    return new FinanceSubSectorUrlItem(
                        json.name,
                        json.url,
                        new FinanceSectorItemBadgeMapper().fromJson(json.badge)
                    );
                case 'content':
                    return new FinanceSubSectorContentItem(
                        json.name,
                        new ContentSummaryMapper().fromJson(json.content),
                        new FinanceSectorItemBadgeMapper().fromJson(json.badge)
                    );
                default:
                    return null;
            }
        })
    }
}
