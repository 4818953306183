import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanInterviewHistory } from 'core/entity/loan/interview-history';

export class LoanInterviewHistoryMapper implements NetworkResponseMapper<LoanInterviewHistory> {
    fromJson(json: any): LoanInterviewHistory {
        return new LoanInterviewHistory(
            json.id,
            json.name
        )
    }

}
