import { TransactionCategorySummary } from 'core/entity/personal-finance/transaction/category/summary';
import { SVGIconColor } from 'presentation/view-model/svg-icon';
import { lets } from 'application/utils';
import { TransactionType } from 'core/entity/personal-finance/transaction/type';

const DEFAULT_CATEGORY_ICON_URL = 'https://cdn.banksalad.com/transactions/categories/unknown.svg';

declare module 'core/entity/personal-finance/transaction/category/summary' {
    export interface TransactionCategorySummary {
        presentName(summarized?: boolean): string;
        presentIcon(): string;
        toSVGIconColor(): SVGIconColor;
        isUnspecified(): boolean;
    }
}

TransactionCategorySummary.prototype.presentName = function(summarized?: boolean) {
    return this.parent ? (
        this.isDefault ?
            this.parent.name :
            summarized ? 
                this.name :
                `${this.parent.name} > ${this.name}`
    ) : this.name
};

TransactionCategorySummary.prototype.presentIcon = function() {
    return this.parent ? (
        this.parent.icon ? this.parent.icon.imageUrl : DEFAULT_CATEGORY_ICON_URL
    ) : this.icon ? this.icon.imageUrl : DEFAULT_CATEGORY_ICON_URL;
};

TransactionCategorySummary.prototype.toSVGIconColor = function() {
    return lets(this.transactionType, it => {
        switch (it) {
            case TransactionType.EXPENSE:
                return SVGIconColor.ROMAIN;
            case TransactionType.INCOME:
                return SVGIconColor.RAINIST;
            default:
                return SVGIconColor.LOG;
        }
    })
};

TransactionCategorySummary.prototype.isUnspecified = function() {
    return this.isDefault && this.parent && this.parent.name === this.name;
};
