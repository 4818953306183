import { Question } from 'presentation/view-model/question';
import { LoanOccupationalGroup } from 'core/entity/loan/occupational-group';

export class CreditLoanJob implements Question {
    topic = '직업';
    essential: boolean;
    clearable: boolean;
    value?: LoanOccupationalGroup;

    constructor(
        occupation: LoanOccupationalGroup = null,
        essential: boolean = true,
        clearable: boolean = false
    ) {
        this.value = occupation;
        this.essential = essential;
        this.clearable = clearable;
    }

    clear(): CreditLoanJob {
        return new CreditLoanJob();
    }

    toValue(): string {
        return this.value ? this.value.name : '';
    }

    isValid(): boolean {
        return this.value !== null;
    }
}
