import { NetworkResponseMapper } from 'data/http/mapper';
import { CardEventSummary } from 'core/entity/card/event/summary';
import { CardEventCompany } from 'core/entity/card/event/company';
import { parseDate } from 'utils/index';

export class CardEventSummaryMapper implements NetworkResponseMapper<CardEventSummary> {
    fromJson(json: any): CardEventSummary {
        return new CardEventSummary(
            json.id,
            new CardEventCompany(
                json.company.id,
                json.company.name,
                json.company.image_url
            ),
            json.title,
            json.thumbnail_image_url,
            json.url,
            json.featured,
            json.hot,
            parseDate(json.start_date),
            parseDate(json.end_date)
        )
    }
}
