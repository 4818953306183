import { NetworkResponseMapper } from 'data/http/mapper';
import { HealthInsuranceSubmitResponse } from 'core/entity/personal-finance/health-insurance/submit/response';

export class HealthInsuranceSubmitResponseMapper implements NetworkResponseMapper<HealthInsuranceSubmitResponse> {
    fromJson(json: any): HealthInsuranceSubmitResponse {
        return new HealthInsuranceSubmitResponse(
            json.url
        );
    }
}
