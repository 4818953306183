import { NetworkRequestMapper } from 'data/http/mapper';
import { PersonalizedLoanRecommendSpec } from 'core/entity/personalized-recommend/loan/recommend-spec';
import { PersonalizedLoanRecommendConfigurationMapper } from 'data/http/mapper/personalized-recommend/loan/recommend-configuration';
import { PersonalizedLoanUserConfigurationMapper } from 'data/http/mapper/personalized-recommend/loan/user-configuration';
import { compactObject, optional } from 'utils/index';

export class PersonalizedLoanRecommendSpecMapper
    implements NetworkRequestMapper<PersonalizedLoanRecommendSpec>
{
    toJson(target: PersonalizedLoanRecommendSpec): any {
        return compactObject({
            recommend_configuration: new PersonalizedLoanRecommendConfigurationMapper().toJson(target.recommendConfiguration),
            user_configuration: new PersonalizedLoanUserConfigurationMapper().toJson(target.userConfiguration),
            offset: optional(target.offset),
            limit: optional(target.limit)
        });
    }
}
