import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1KCBCreditAgreementView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h2>개인(신용)정보 수집∙이용 동의서</h2>

      <h3>주식회사 레이니스트</h3>
            귀사의 뱅크샐러드 “모바일 어플리케이션 및 웹 사이트”(이하 “본건 서비스”) 이용과 관련하여 귀사가 본인의
            개인(신용)정보를 수집∙이용하는 경우에는 「개인정보 보호법」, 「신용정보의 이용 및 보호에 관한 법률」 및 「정보통신망
            이용촉진 및 정보보호 등에 관한 법률」에 따라 본인의 동의가 필요합니다. 이에 귀사가 다음과 같이 본인의
            개인(신용)정보를 수집∙이용하는 것에 동의합니다.

      <h3>개인(신용)정보 수집∙이용 동의</h3>
            위 개인(신용) 정보 수집·이용에 관한 동의는 본건 서비스 이용을 위하여 필수적이므로, 위 사항에 동의하셔야만 본건 서비스 이용이 가능합니다.

      <br/><br/>
      <table>
        <colgroup>
          <col width='40%' />
          <col width='40%' />
          <col width='20%' />
        </colgroup>
        <tbody>
          <tr>
            <th>수집 항목</th>
            <th>이용목적</th>
            <th>보유기간</th>
          </tr>
          <tr>
            <td>
              <div>* 신용조회 회사로부터 수집하는 신용점수, 신용등급, 신용정보 변동여부 및 변동내역, 타기관에 의한 신용정보 조회내역, 카드보유현황, 대출현황, 연체현황, 연대보증 현황 등의 고객 신용정보 세부 내역</div>
            </td>
            <td>
              <div>* 신용등급 및 신용점수 확인 등 고객의 신용정보 관리를 위한 정보 제공</div>
              <div>* 기타 고객에게 유익하다고 판단되는 정보의 제공</div>
            </td>
            <td>
              <div>해당 서비스 해지취소 시 또는 회원탈퇴 시 까지</div>
            </td>
          </tr>
        </tbody>
      </table>

      <br/><br/>
            뱅크샐러드 신용관리서비스는 선택 사항이므로 동의를 거부하는 경우에도 자산 괸리, 예산 관리 등 다른 서비스의 이용에는 영향이 없습니다.
    </div>
  </div>
);
