import { ContentAuthorProfileSummary } from 'core/entity/content/author/profile/summary/profile';
import { ContentAuthorSummary } from 'core/entity/content/author/summary';

export const EMERGENCY_AUTHORS = {
  BANKSALAD: new ContentAuthorSummary(
    '2',
    new ContentAuthorProfileSummary(
      '뱅크샐러드',
      '<뱅크샐러드> 금융매거진',
      'https://cdn.banksalad.com/resources/images/logo/bi.png',
    ),
  ),
  PARK_JOO_HYUN: new ContentAuthorSummary(
    '9',
    new ContentAuthorProfileSummary(
      '박주연',
      '아시아경제TV 앵커',
      'https://cdn.banksalad.com/content/upload/images/profile_parkjuyeon.png',
    ),
  ),
  KOREA_FINANCIAL_INVESTORS_PROTECTION_FOUNDATION: new ContentAuthorSummary(
    '21',
    new ContentAuthorProfileSummary(
      '한국금융투자자보호재단',
      '투자자의 권익향상을 위한 비영리 공익재단',
      'https://cdn.banksalad.com/content/upload/images/%ED%88%AC%EC%9E%90%EC%9E%90%EB%B3%B4%ED%98%B8%EC%9E%AC%EB%8B%A8%EB%A1%9C%EA%B3%A0.JPG',
    ),
  ),
  KIM_HYUN_WOO: new ContentAuthorSummary(
    '7',
    new ContentAuthorProfileSummary(
      '김현우',
      '행복자산관리연구소 소장',
      'https://cdn.banksalad.com/content/upload/images/profile_kimhyunwoo.png',
    ),
  ),
  ECONOMAD: new ContentAuthorSummary(
    '25',
    new ContentAuthorProfileSummary(
      '이코노마드',
      '돈과 행복의 지혜창고',
      'https://cdn.banksalad.com/content/upload/images/%EC%9D%B4%EC%BD%94%EB%85%B8%EB%A7%88%EB%93%9C.JPG',
    ),
  ),
  YOO_EUN_JUNG: new ContentAuthorSummary(
    '28',
    new ContentAuthorProfileSummary(
      '유은정',
      '세계파이낸스  금융부 기자',
      'https://cdn.banksalad.com/content/upload/images/%EC%9C%A0%EC%9D%80%EC%A0%95.png',
    ),
  ),
  PARK_JAE_CHAN: new ContentAuthorSummary(
    '39',
    new ContentAuthorProfileSummary(
      '박재찬',
      '한국보험신문 보험전문기자',
      'https://cdn.banksalad.com/content/upload/images/%EB%B0%95%EC%9E%AC%EC%B0%AC%EA%B8%B0%EC%9E%90%EB%8B%98.JPG',
    ),
  ),
  LEE_JONG_HO: new ContentAuthorSummary(
    '37',
    new ContentAuthorProfileSummary(
      '이종호',
      '뉴스토마토 금융부 기자',
      'https://cdn.banksalad.com/content/upload/images/%EC%9D%B4%EC%A2%85%ED%98%B84548.jpg',
    ),
  ),
  PARK_JI_HOON: new ContentAuthorSummary(
    '32',
    new ContentAuthorProfileSummary(
      '박지훈',
      '매경미디어그룹 LUXMEN 재테크전문기자',
      'https://cdn.banksalad.com/content/upload/images/%EB%B0%95%EC%A7%80%ED%9B%88%EA%B8%B0%EC%9E%90%EB%8B%98.JPG',
    ),
  ),
  KIM_KYUNG_EUN: new ContentAuthorSummary(
    '38',
    new ContentAuthorProfileSummary(
      '김경은',
      '이데일리 금융부 기자',
      'https://cdn.banksalad.com/content/upload/images/%EA%B9%80%EA%B2%BD%EC%9D%80%EA%B8%B0%EC%9E%90%EB%8B%98.JPG',
    ),
  ),
  JANG_JAE_HYUK: new ContentAuthorSummary(
    '38',
    new ContentAuthorProfileSummary(
      '장재혁',
      '인더뉴스 보험 취재 기자',
      'https://cdn.banksalad.com/content/upload/images/man_icon_with_glasses.JPG',
    ),
  ),
  NA_WON_SIK: new ContentAuthorSummary(
    '10',
    new ContentAuthorProfileSummary(
      '나원식',
      '비즈니스워치 금융부 기자',
      'https://cdn.banksalad.com/content/upload/images/profile_nawonsik.png',
    ),
  ),
  YOO_YOON_JUNG: new ContentAuthorSummary(
    '27',
    new ContentAuthorProfileSummary(
      '유윤정',
      '조선미디어그룹 조선비즈 보험팀장',
      'https://cdn.banksalad.com/content/upload/images/%EC%9C%A0%EC%9C%A4%EC%A0%95.png',
    ),
  ),
  PARK_YOUNG_JOON: new ContentAuthorSummary(
    '36',
    new ContentAuthorProfileSummary(
      '박영준',
      '대한금융신문 금융부 보험전문기자',
      'https://cdn.banksalad.com/content/upload/images/%EB%B0%95%EC%98%81%EC%A4%80%EA%B8%B0%EC%9E%90%EB%8B%98.JPG',
    ),
  ),
  JANG_SEUL_KI: new ContentAuthorSummary(
    '34',
    new ContentAuthorProfileSummary(
      '장슬기',
      '한국경제TV 경제부 기자',
      'https://cdn.banksalad.com/content/upload/images/%EC%9E%A5%EC%8A%AC%EA%B8%B0+%EA%B8%B0%EC%9E%90.jpg',
    ),
  ),
  HONG_SEUNG_HEE: new ContentAuthorSummary(
    '35',
    new ContentAuthorProfileSummary(
      '홍승희',
      '리툴코리아 책임연구원',
      'https://cdn.banksalad.com/content/upload/images/%ED%99%8D%EC%8A%B9%ED%9D%AC%EB%8B%98.JPG',
    ),
  ),
  LEE_MIN_AH: new ContentAuthorSummary(
    '31',
    new ContentAuthorProfileSummary(
      '이민아',
      '조선비즈 금융증권부 기자',
      'https://cdn.banksalad.com/content/upload/images/%EC%9D%B4%EB%AF%BC%EC%95%84%EA%B8%B0%EC%9E%90%EB%8B%98.JPG',
    ),
  ),
  KIM_SEUNG_DONG: new ContentAuthorSummary(
    '17',
    new ContentAuthorProfileSummary(
      '김승동',
      '뉴스핌 보험전문기자',
      'https://cdn.banksalad.com/content/upload/images/%EA%B9%80%EC%8A%B9%EB%8F%99%EA%B8%B0%EC%9E%90%EB%8B%98.JPG',
    ),
  ),
  CHOI_EUN_SOO: new ContentAuthorSummary(
    '41',
    new ContentAuthorProfileSummary(
      '최은수',
      '한국보험신문 편집부 기자',
      'https://cdn.banksalad.com/content/upload/images/%EC%B5%9C%EC%9D%80%EC%88%98%EA%B8%B0%EC%9E%90%EB%8B%98.JPG',
    ),
  ),
  KIM_NA_YUN: new ContentAuthorSummary(
    '15',
    new ContentAuthorProfileSummary(
      '김나연',
      '<2017 처음가계부> <대학생 재테크> 저자',
      'https://cdn.banksalad.com/content/upload/images/%EA%B9%80%EB%82%98%EC%97%B0+(2).png',
    ),
  ),
  LEE_CHUN: new ContentAuthorSummary(
    '18',
    new ContentAuthorProfileSummary(
      '이천',
      '희망재무설계 대표',
      'https://cdn.banksalad.com/content/upload/images/%EC%9D%B4%EC%B2%9C%EC%82%AC%EC%A7%84.jpg',
    ),
  ),
  PARK_MI_JUNG: new ContentAuthorSummary(
    '18',
    new ContentAuthorProfileSummary(
      '박미정',
      '경제교육협동조합 푸른살림 대표',
      'https://cdn.banksalad.com/content/upload/images/Tschool002.jpg',
    ),
  ),
  LEE_KWON_BOK: new ContentAuthorSummary(
    '20',
    new ContentAuthorProfileSummary(
      '이권복',
      '<돈이 그대 삶을 춤추게 하라>블로그 운영자',
      'https://cdn.banksalad.com/content/upload/images/%EA%BF%88%EA%BE%B8%EB%8A%94%EC%9E%90%EB%B3%B8%EA%B0%80%EC%9D%B4%EA%B6%8C%EB%B3%B5_%EC%82%AC%EC%A7%84.jpg',
    ),
  ),
  SONG_SEUNG_YONG: new ContentAuthorSummary(
    '16',
    new ContentAuthorProfileSummary(
      '송승용',
      '희망재무설계 이사',
      'https://cdn.banksalad.com/content/upload/images/%EC%86%A1%EC%8A%B9%EC%9A%A9%EB%8B%98.jpg',
    ),
  ),
  YOON_SUNG_AE: new ContentAuthorSummary(
    '3',
    new ContentAuthorProfileSummary(
      '윤성애',
      '<돈 없어도 나는 재테크를 한다> 저자',
      'https://cdn.banksalad.com/content/upload/images/Profile.png',
    ),
  ),
  JANG_EUN_SUK: new ContentAuthorSummary(
    '23',
    new ContentAuthorProfileSummary(
      '장은석',
      '서울신문 기자',
      'https://cdn.banksalad.com/content/upload/images/%EC%9E%A5%EC%9D%80%EC%84%9D+%EA%B8%B0%EC%9E%90%EB%8B%98.jpg',
    ),
  ),
  CHA_KHAN_YANG: new ContentAuthorSummary(
    '4',
    new ContentAuthorProfileSummary(
      '차칸양',
      '<불황을 이기는 월급의 경제학>의 저자',
      'https://cdn.banksalad.com/content/upload/images/profile_chakanyang.png',
    ),
  ),
  JO_JIN_HWAN: new ContentAuthorSummary(
    '22',
    new ContentAuthorProfileSummary(
      '조진환',
      '<7일 만에 끝내는 돈 공부>,<경제습관을 상속하라>저자',
      'https://cdn.banksalad.com/content/upload/images/%EC%A1%B0%EC%A7%84%ED%99%98%EB%8B%98.JPG',
    ),
  ),
  LEE_JAE_CHUL: new ContentAuthorSummary(
    '5',
    new ContentAuthorProfileSummary(
      '이재철',
      '<당신의 재테크 최선입니까?>저자',
      'https://cdn.banksalad.com/content/upload/images/profile_leejaechul.png',
    ),
  ),
  BIN_NU: new ContentAuthorSummary(
    '12',
    new ContentAuthorProfileSummary(
      '빈누',
      '<Financial Freedom> 블로거',
      'https://cdn.banksalad.com/content/upload/images/profile_binnu.png',
    ),
  ),
};
