import { Entity } from 'core/entity';
import { LoanInterestValue } from 'core/entity/loan/interest-value';

export class LoanConfirmationInterest extends Entity implements LoanInterestValue {
    value: number;

    constructor(
        value: number
    ) {
        super();
        this.value = value;
    }
}
