import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV2TermsView } from 'presentation/routes/policies/v2/terms/view';

export const PoliciesV2TermsRoute = () => (
    <>
    <MetaHelmet meta={ META_SET.TERMS }/>
    <PoliciesV2TermsView />
    </>
);
