import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { CardSpending } from 'core/entity/card/spending';

export class CardSpendingMapper implements BrowserStorageMapper<CardSpending> {
    toEntity(json: any): CardSpending {
        return new CardSpending(
            json.storeId,
            json.storeName,
            json.expense
        );
    }

    toJson(target: CardSpending): any {
        return {
            storeId: target.storeId,
            storeName: target.storeName,
            expense: target.expense
        };
    }

}
