import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardStoreBenefit } from 'core/entity/card/store-benefit';
import { CardBenefitMapper } from 'data/http/mapper/card/benefit';

export class CardStoreBenefitMapper implements NetworkResponseMapper<CardStoreBenefit> {
    fromJson(json: any): CardStoreBenefit {
        return new CardStoreBenefit(
            json.store_id,
            json.title,
            List(json.benefits.map((benefit: any) =>
                new CardBenefitMapper().fromJson(benefit)
            )),
            json.exclude
        )
    }
}
