import { NetworkResponseMapper } from 'data/http/mapper';
import { UserDefaultProductConfiguration } from 'core/entity/personal-finance/summarized-products/default-product-configuration';
import { UserCashAccountMapper } from 'data/http/mapper/personal-finance/cash-account';
import { optional } from 'utils/index';

export class UserDefaultProductConfigurationMapper implements NetworkResponseMapper<UserDefaultProductConfiguration> {
    fromJson(json: any): UserDefaultProductConfiguration {
        return new UserDefaultProductConfiguration(
            optional(json.cash_account, new UserCashAccountMapper().fromJson)
        )
    }
}
