import React from 'react';
import { List } from 'immutable';

import { Loan } from 'core/entity/loan';
import { lets, toDateString } from 'utils/index';
import { ServiceReportAlert } from 'presentation/components/alert/service-report';
import { LoanBaseInterestGroup } from 'core/entity/loan/base-interest-group';
import { LoanBaseInterest } from 'core/entity/loan/base-interest';
import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanPrimeInterestNode } from 'core/entity/loan/prime-interest-node';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { LoanInterestCondition } from 'core/entity/loan/interest-condition';

import styles from './styles.pcss';

interface Props {
    loan: Loan;
    showBestBanner?: boolean;
}

interface State {
    alertReporting: boolean;
}

export class CreditLoanInfoDetail extends React.Component<Props, State> {
    state = {
        alertReporting: false
    };

    render() {
        const { loan, showBestBanner } = this.props;
        const { alertReporting } = this.state;
        return (
            <div>
                <h4 className={ styles.updatedAt }>
                    <span>상품 정보 업데이트: { toDateString(loan.updatedAt, '.') }</span>
                    <button
                        className={ styles.report }
                        onClick={ this.onToggleAlert }
                    >
                        오류신고
                    </button>
                </h4>
                <ul className={ styles.contents }>
                    <li>
                        <h5 className={ styles.contentTitle }>상품 소개</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.product }}
                        />
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>대출 자격{ this.renderContentWarningTitle() }</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.qualifications }}
                        />
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>대출 한도</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.amountLimit }}
                        />
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>대출 기간</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.period }}
                        />
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>대출 금리</h5>
                        { this.renderDefaultInterestInfo(loan) }
                        { this.renderAverageInterestInfo(loan) }
                        { this.renderPrimeInterestTable(loan.interestCondition.primeInterests) }
                        { this.renderEventInterestTable(loan) }
                        {
                            loan.interestCondition.averageInterests ?
                                <small className={ styles.primeInterestWarning }>{ `*해당 금리는 ${loan.organization.name}에서 직접 제공받은 평균금리 값입니다.` }</small> :
                                <small className={ styles.primeInterestWarning }>*우대금리는 대출신청시 금리우대 요건을 충족 및 유지하여야 적용 가능합니다.</small>
                        }
                        {
                            showBestBanner && (
                                <div className={ styles.bestBanner }>
                                    <small>내가 받을 수 있는 대출금리는 얼마?</small>
                                    <h4><b>BEST 대출상품 추천</b>을 통해 <br/>나의 예상 금리를 알아보세요!</h4>
                                    <a href={ BANKSALAD_SITEMAP.CREDIT_LOAN_QUESTIONS } target="_blank">내 금리 알아보기</a>
                                </div>
                            )
                        }
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>가입 방법</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.registration }}
                        />
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>상환 방법</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.repayment }}
                        />
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>수수료 정보{ this.renderContentWarningTitle() }</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.additionalFees }}
                        />
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>준비물</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.requiredDocuments }}
                        />
                    </li>
                    <li>
                        <h5 className={ styles.contentTitle }>유의사항{ this.renderContentWarningTitle() }</h5>
                        <p
                            className={ styles.contentDescription }
                            dangerouslySetInnerHTML={{ __html: loan.descriptions.warning }}
                        />
                    </li>
                </ul>
                {
                    alertReporting &&
                    <ServiceReportAlert
                        onCancel={ this.onToggleAlert }
                    />
                }
            </div>
        )
    }

    private renderDefaultInterestInfo = (loan: Loan) => (
        loan.interestCondition.baseInterests && loan.interestCondition.additionalInterest &&
        <>
        { this.renderBaseInterestTable(loan.interestCondition.baseInterests) }
        { this.renderAdditionalInterestTable(loan.interestCondition) }
        </>
    );

    private renderAverageInterestInfo = (loan: Loan) => (
        loan.interestCondition.averageInterests &&
        <>
        <h6 className={ styles.contentSubTitle }>
            { `대출금리 ${loan.descriptions.interest} `  }
            <span className={ styles.contentTitleDescription }>(당사 여신취급 기준에 따라 차등적용)</span>
        </h6>
        { this.renderAverageInterestList(loan.interestCondition) }
        </>
    );

    private renderBaseInterestTable = (groups: List<LoanBaseInterestGroup>) =>
        <table className={ styles.table }>
            <thead>
            <tr>
                <th colSpan={ 3 }>기준금리</th>
            </tr>
            </thead>
            <tbody>
            {
                groups.map((group, i) =>
                    group.interests.map((interest, j) =>
                        <tr key={ `base-${i}-${j}` }>
                            { j === 0 && <td rowSpan={ group.interests.size }>{ this.toStringCriteriaDescription(group, interest) }</td> }
                            <td>{ this.toStringFromBaseInterest(interest) }</td>
                            {
                                interest.isRangeInterest() ?
                                    <td>{ `연 ${interest.getMinInterest().toFixed(2)}~${interest.getMaxInterest().toFixed(2)}%` }</td> :
                                    <td>{ `연 ${interest.getInterest().toFixed(2)}%` }</td>
                            }
                        </tr>
                    )
                )
            }
            </tbody>
        </table>;

    private renderAdditionalInterestTable = (interestCondition: LoanInterestCondition) => (
        <table className={ styles.table }>
            <thead>
            <tr>
                <th colSpan={ 2 }>가산금리</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>금융사 제공</td>
                {
                    interestCondition.isRangeInterest() ?
                        <td>{ `연 ${interestCondition.getMinInterest().toFixed(2)}~${interestCondition.getMaxInterest().toFixed(2)}%` }</td> :
                        <td>{ `연 ${interestCondition.getInterest().toFixed(2)}%` }</td>
                }
            </tr>
            </tbody>
        </table>
    );

    private renderEventInterestTable = (loan: Loan) => (
        loan.isAvailablePromotionInterestValue() &&
        <table className={ styles.table }>
            <thead>
            <tr>
                <th colSpan={ 2 }>이벤트 금리</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{ `뱅크샐러드 제휴코드 ${loan.interestPromotion.code}입력 시` }</td>
                <td className={ styles.highlightTableData }>
                    {
                        loan.interestPromotion.isRangeInterest() ?
                            `-${loan.interestPromotion.getMinInterest().toFixed(2)}% ~ -${loan.interestPromotion.getMaxInterest().toFixed(2)}%` :
                            `-${loan.interestPromotion.getInterest().toFixed(2)}%`
                    }
                </td>
            </tr>
            </tbody>
        </table>
    );

    private renderAverageInterestList = (interestCondition: LoanInterestCondition) => (
        <>
        <h6 className={ styles.averageInterestsContentSubTitle }>신용등급별 평균금리</h6>
        <ol>
        {
            interestCondition.getAverageInterests().map((interest: string, i) => (
                <li key={ `average-${i}` } className={ styles.averageItem }>{`${i + 1}등급 `}<strong>{ interest }</strong></li>
            ))
        }
        </ol>
        </>
    );

    private renderPrimeInterestTable = (interests: List<LoanPrimeInterestNode>) => {
        return interests.map((interestGroup: LoanPrimeInterestNode, i: number) => {
            return (
                <table key={ `prime-table-${i}` } className={ styles.table }>
                    <thead>
                    <tr>
                        <th>{ interestGroup.description }</th>
                        <th className={ styles.primeInterestTableHead }>{ interestGroup.interestDescription }</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderRow(interestGroup.children) }
                    </tbody>
                </table>
            );
        });
    };

    private renderRow = (interests: List<LoanPrimeInterestNode>): any => {
        if (interests.isEmpty())
            return;

        return (
            interests.map((interest, i) =>
                <React.Fragment key={ `prime-fragment-${interest.level}-${i}` }>
                <tr
                    key={ `prime-${i}` }
                    className={ styles[`primeInterestLevel${interest.level + 1}`] }
                >
                    <td className={ interest.level === 1 ? styles.root : null }>{ interest.description }</td>
                    <td className={ interest.level === 1 ? styles.root : null }>
                        { interest.interestDescription }
                    </td>
                </tr>
                { this.renderRow(interest.children) }
                </React.Fragment>
            )
        );
    };

    private onToggleAlert = () =>
        this.setState(state => ({ alertReporting: !state.alertReporting }));

    private toStringCriteriaDescription = (group: LoanBaseInterestGroup, interest: LoanBaseInterest) => {
        const type = lets(interest.type, it => {
            switch (it) {
                case LoanInterestType.FIXED_INTEREST:
                    return '고정';
                case LoanInterestType.NON_FIXED_INTEREST:
                    return '변동';
                default:
                    return '';
            }
        });

        return `${group.criteriaDescription}(${type})`;
    };

    private toStringFromBaseInterest = (interest: LoanBaseInterest) => {

        return interest.period.min !== interest.period.max ?
            `${interest.period.min}~${interest.period.max}개월 대출 시` :
            `${interest.period.min}개월 대출 시`;
    };

    private renderContentWarningTitle = (title: string = '가입 전 반드시 확인하세요!') => (
        <strong className={ styles.contentWarningTitle }>{ title }</strong>
    );
}
