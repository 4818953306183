import { Entity } from 'core/entity';

export class AdviceTag extends Entity {
    key: string;
    title: string;
    color: string;

    constructor(
        key: string,
        title: string,
        color: string
    ) {
        super();
        this.key = key;
        this.title = title;
        this.color = color;
    }
}
