import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { UserCreditScoreCardLimit } from 'core/entity/personal-credit/card/credit-limit';

export class UserCreditScoreCardLimitMapper implements
    NetworkResponseMapper<UserCreditScoreCardLimit>,
    NetworkRequestMapper<UserCreditScoreCardLimit>
{
    toJson(target: UserCreditScoreCardLimit): any {
        return {
            total: new AmountMapper().toJson(target.total),
            cash_service: new AmountMapper().toJson(target.cashService)
        }
    }

    fromJson(json: any): UserCreditScoreCardLimit {
        return new UserCreditScoreCardLimit(
            new AmountMapper().fromJson(json.total),
            new AmountMapper().fromJson(json.cash_service)
        )
    }
}
