import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { InsuranceApplyLink } from 'core/entity/insurance/apply-link';
import { lets, optional } from 'utils/index';

export class InsuranceApplyLinkMapper implements NetworkResponseMapper<InsuranceApplyLink> {
    fromJson(json: any): InsuranceApplyLink {
        return new InsuranceApplyLink(
            lets(json.type, it => {
                switch (it) {
                    case 'internet':
                        return InsuranceApplyLink.Type.INTERNET;
                    case 'phone':
                        return InsuranceApplyLink.Type.PHONE;
                    default:
                        throw new MappingError();
                }
            }),
            json.value,
            optional(json.title)
        )
    }
}
