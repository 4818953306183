import React from 'react';

import styles from './styles.pcss';

interface Props {
    children?: any;
    padding?: number;
    onRetry?: () => void;
}

export const FetchError = (props: Props) => (
    <div
        style={{ padding: props.padding ? `${props.padding}px 0`: '0' }}
        className={ styles.wrap }
    >
        {
            props.children ||
            <>
                <b>일시적인 오류입니다</b><br/>
                잠시 후 다시 시도해주세요<br/>
                {
                    props.onRetry &&
                    <button
                        className={ styles.retry }
                        onClick={ props.onRetry }
                    >
                        다시 시도
                    </button>
                }
            </>
        }
    </div>
);
