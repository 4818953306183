import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardAmount } from 'core/entity/card/amount';
import { CardMileageMapper } from 'data/http/mapper/card/mileage';

export class CardAmountMapper implements NetworkResponseMapper<CardAmount> {
    fromJson(json: any): CardAmount {
        return new CardAmount(
            json.discount,
            json.point,
            json.mileage,
            List(json.mileages.map((mileage: any) =>
                new CardMileageMapper().fromJson(mileage)
            ))
        )
    }

}
