export class EventTagsModel {
    special?: boolean;
    hot?: boolean;
    alert?: boolean;

    constructor(
        special?: boolean,
        hot?: boolean,
        alert?: boolean
    ) {
        this.special = special;
        this.hot = hot;
        this.alert = alert;
    }
}
