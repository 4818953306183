import { NetworkRequestMapper } from 'data/http/mapper';
import { UserUpdateSpec } from 'core/entity/user/update-spec';
import { compactObject, optional } from 'utils/index';

export class UserUpdateSpecMapper implements NetworkRequestMapper<UserUpdateSpec> {
    toJson(target: UserUpdateSpec): any {
        return compactObject({
            name: optional(target.name),
            account: optional(target.account, it => compactObject({
                password: optional(it.password),
                modified_password: optional(it.modifiedPassword)
            })),
            preference: optional(target.preference, it => compactObject({
                initial_day_of_month: optional(it.initialDayOfMonth),
                kcb_agreement_accepted: optional(it.kcbAgreementAccepted),
                injecting_bank_transaction_category: optional(it.injectingBankTransactionCategory)
            }))
        })
    }

}
