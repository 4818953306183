import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserCreditScoreChangeRecordStatus } from 'core/entity/personal-credit/change-record-status';
import { parseDate } from 'utils/index';
import { UserCreditScoreChangeRecordStatusMapper } from 'data/http/mapper/personal-credit/change-record-status';

export class UserCreditScoreChangeRecordStatusApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<UserCreditScoreChangeRecordStatus> {
        return this.requestGET(
            'api/v2/me/credit-scores/KCB/change-records',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCreditScoreChangeRecordStatusMapper(
                parseDate(result.updated_at),
                result.changed
            ).fromJson(result.data)
        )
    }

}
