import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanSurveyConfiguration } from 'core/entity/loan/survey-configuration';
import { LoanInterviewHistoryMapper } from 'data/http/mapper/loan/interview-history';
import { LoanOccupationalGroupMapper } from 'data/http/mapper/loan/occupational-group';

export class LoanSurveyConfigurationMapper implements NetworkResponseMapper<LoanSurveyConfiguration> {
    fromJson(json: any): LoanSurveyConfiguration {
        return new LoanSurveyConfiguration(
            List(json.histories.map((item: any) =>
                new LoanInterviewHistoryMapper().fromJson(item)
            )),
            List(json.occupational_groups.map((item: any) =>
                new LoanOccupationalGroupMapper().fromJson(item)
            ))
        )
    }

}
