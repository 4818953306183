import { NetworkResponseMapper } from 'data/http/mapper';
import { InsuranceCommpany } from 'core/entity/insurance/company';

export class InsuranceCompanyMapper implements NetworkResponseMapper<InsuranceCommpany> {
    fromJson(json: any): InsuranceCommpany {
        return new InsuranceCommpany(
            json.name,
            json.image_url,
            json.is_price_visible,
            json.image_url_square,
        )
    }
}
