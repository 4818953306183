import { Entity } from 'core/entity';
import { LoanInterestConfigurationSummary } from 'core/entity/loan/interest-configuration-summary';
import { LoanOption } from 'core/entity/loan/option';
import { List } from 'immutable';

export class LoanComputationResultSummary extends Entity {
    interestConfigurationSummary: LoanInterestConfigurationSummary;
    maximumAmountLimit: number;
    options: List<LoanOption>;

    constructor(
        interestConfigurationSummary: LoanInterestConfigurationSummary,
        maximumAmountLimit: number,
        options: List<LoanOption>
    ) {
        super();
        this.interestConfigurationSummary = interestConfigurationSummary;
        this.maximumAmountLimit = maximumAmountLimit;
        this.options = options;
    }
}
