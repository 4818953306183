import { Entity } from 'core/entity';
import { TransactionIcon } from 'core/entity/personal-finance/transaction/category-icon';

export class TransactionCategoryParent extends Entity {
    id: string;
    name: string;
    icon?: TransactionIcon;

    constructor(
        id: string,
        name: string,
        icon: TransactionIcon = null
    ) {
        super();
        this.id = id;
        this.name = name;
        this.icon = icon;
    }
}
