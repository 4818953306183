import { NetworkResponseMapper } from 'data/http/mapper';
import { Advice } from 'core/entity/personal-finance/advice';
import { AdviceTypeMapper } from 'data/http/mapper/personal-finance/advice/type';
import { AdviceMetadataMapper } from 'data/http/mapper/personal-finance/advice/metadata';
import { AdviceContentMapper } from 'data/http/mapper/personal-finance/advice/content';
import { optional } from 'utils/index';
import { AdviceReasonMapper } from 'data/http/mapper/personal-finance/advice/reason';
import { AdviceTagMapper } from 'data/http/mapper/personal-finance/advice/tag';
import { AdviceReactionMapper } from 'data/http/mapper/personal-finance/advice/reaction';

export class AdviceMapper implements NetworkResponseMapper<Advice> {
    fromJson(json: any): Advice {
        return new Advice(
            json.id,
            json.version,
            new AdviceTypeMapper().fromJson(json.type),
            new AdviceTagMapper().fromJson(json.tag),
            new AdviceMetadataMapper().fromJson(json.metadata),
            json.contents.map(new AdviceContentMapper().fromJson).toList(),
            new AdviceReactionMapper().fromJson(json.reaction),
            optional(json.hero_image_url),
            optional(json.title),
            optional(json.reason, new AdviceReasonMapper().fromJson)
        )
    }
}
