import { Entity } from 'core/entity';
import { List } from 'immutable';
import { ComparableCardConfiguration } from 'core/entity/comparable-card/configuration';

export class ComparableCardConfigurationSet extends Entity {
    title: string;
    description: string;
    configurations: List<ComparableCardConfiguration>;

    constructor(
        title: string,
        description: string,
        configurations: List<ComparableCardConfiguration>
    ) {
        super();
        this.title = title;
        this.description = description;
        this.configurations = configurations;
    }
}
