import { Entity } from 'core/entity';

export class MobileAuthenticationSpec extends Entity {
    authenticationRequestId: string;
    phoneNumber: string;
    verificationCode: string;

    constructor(
        authenticationRequestId: string,
        phoneNumber: string,
        verificationCode: string
    ) {
        super();
        this.authenticationRequestId = authenticationRequestId;
        this.phoneNumber = phoneNumber;
        this.verificationCode = verificationCode;
    }
}
