import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { AdviceExpenseSummaryData } from 'core/entity/personal-finance/advice/content-data/expense-summary';
import { lets, optional } from 'utils/index';
import { AmountPeriodSnapshotMapper } from 'data/http/mapper/personal-finance/advice/amount-period-snapshot';
import { AmountMapper } from 'data/http/mapper/amount';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceExpenseSummaryDataMapper implements NetworkResponseMapper<AdviceExpenseSummaryData> {
    fromJson(json: any): AdviceExpenseSummaryData {
        return new AdviceExpenseSummaryData(
            lets(json.period, it => {
                switch (it) {
                    case 'daily':
                        return AdviceExpenseSummaryData.Period.DAILY;
                    case 'weekly':
                        return AdviceExpenseSummaryData.Period.WEEKLY;
                    default:
                        throw new MappingError();
                }
            }),
            json.expense_amount_history.map(new AmountPeriodSnapshotMapper().fromJson).toList(),
            optional(json.average_expense_amount, new AmountMapper().fromJson),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
