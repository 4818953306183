import { Entity } from 'core/entity';

export class PersonalizedLoanInterestPromotion extends Entity {
    code: string;
    description: string;
    interestPromotionWording: string;
    type: PersonalizedLoanInterestPromotion.Type;

    constructor(
        code: string,
        description: string,
        interestPromotionWording: string,
        type: PersonalizedLoanInterestPromotion.Type,
    ) {
        super();
        this.code = code;
        this.description = description;
        this.interestPromotionWording = interestPromotionWording;
        this.type = type;
    }
}

export module PersonalizedLoanInterestPromotion {
    export enum Type {
        INTEREST_DISCOUNT = 'interest-discount',
        GIFT = 'gift'
    }
}
