import { NetworkResponseMapper } from 'data/http/mapper';
import { CardPremiumVoucherBenefit } from 'core/entity/card/premium-voucher-benefit';
import { apply } from 'utils/index';

export class CardPremiumVoucherBenefitMapper implements NetworkResponseMapper<CardPremiumVoucherBenefit> {
    fromJson(json: any): CardPremiumVoucherBenefit {
        return apply(new CardPremiumVoucherBenefit(
            json.title
        ), it => {
            it.description = json.description;
        })
    }
}
