import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { PresentableAsset } from 'presentation/module/extension/personal-finance/base';

declare module 'core/entity/personal-finance/cash-account' {
    export interface UserCashAccount extends PresentableAsset {
        /* explicitly empty */
    }
}

UserCashAccount.prototype.presentName = function() {
    return this.isDefault ?
        '현금 (기본)' :
        this.nickname
};
