import { Service } from 'core/service';
import { HealthInsurancePaymentDetailRepositoryType } from 'core/repository/personal-finance/health-insurance/payment-details';
import { HealthInsuranceCertificateRepositoryType } from 'core/repository/personal-finance/health-insurance/certificates';
import { HealthInsuranceSubmitRepositoryType } from 'core/repository/personal-finance/health-insurance/submit';

export class HealthInsuranceService implements Service {
    private paymentDetailsRepository: HealthInsurancePaymentDetailRepositoryType;
    private certificatesRepository: HealthInsuranceCertificateRepositoryType;
    private submitRepository: HealthInsuranceSubmitRepositoryType;

    constructor(
        paymentDetailsRepository: HealthInsurancePaymentDetailRepositoryType,
        certificatesRepository: HealthInsuranceCertificateRepositoryType,
        submitRepository: HealthInsuranceSubmitRepositoryType
    ) {
        this.paymentDetailsRepository = paymentDetailsRepository;
        this.certificatesRepository = certificatesRepository;
        this.submitRepository = submitRepository;
    }

    getPaymentDetailResponse = () => this.paymentDetailsRepository.get();
    getCertificateResponse = () => this.certificatesRepository.get();
    getSubmitResponse = (loanId: string, userCreditGrade?: number) => this.submitRepository.submit(loanId, userCreditGrade);
}
