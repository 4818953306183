import { Entity } from 'core/entity';
import { LoanSummary } from 'core/entity/loan/summary';
import { LoanComputationResultSummary } from 'core/entity/loan/computation-result-summary';

export class LoanRecommendResultSummary extends Entity {
    loan: LoanSummary;
    computation: LoanComputationResultSummary;

    constructor(
        loan: LoanSummary,
        computation: LoanComputationResultSummary
    ) {
        super();
        this.loan = loan;
        this.computation = computation;
    }
}
