import React from 'react';

import { OUTBOUND_LINK } from 'presentation/module/sitemap';
import { removeQueriesAndHashes } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    title: string;
    size?: number;
}

export const NaverShareButton = (props: Props) =>
    <button
        className={ styles.button }
        onClick={ () => window.open(OUTBOUND_LINK.NAVER_SHARE(props.title, removeQueriesAndHashes(location.href))) }
        style={{
            width: `${props.size || 34}px`,
            height: `${props.size || 34}px`
        }}
    >
        네이버 공유
    </button>;
