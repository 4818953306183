import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { CardLegalConfigurationMapper } from 'data/http/mapper/legal-configuration/card';
import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { apply } from 'utils/index';

export class CardLegalConfigurationApiProvider extends HttpProvider {
    getByCardId(id: number): Observable<CardLegalConfiguration> {
        return this.requestGET(
            `/api/v3/cards/${id}/legal-configuration`
        ).map((result: any) =>
            new CardLegalConfigurationMapper().fromJson(result.data)
        )
    }

}

export class IgnoredCardLegalConfigurationApiProvider extends CardLegalConfigurationApiProvider {
    getByCardId(id: number): Observable<CardLegalConfiguration> {
        return this.requestGET(
            `/api/v3/cards/${id}/legal-configuration`
        ).map((result: any) =>
            apply(new CardLegalConfigurationMapper().fromJson(result.data), it => {
                it.disabledForCompliance = false;
                it.issuingDisabled = false
            })
        );
    }
}
