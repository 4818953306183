import { HttpProviderDependencies } from 'application/@context/http-providers';
import { StorageProviderDependencies } from 'application/@context/storage-providers';
import { PersonalizedLoanRepository } from 'data/repository/personalized-recommend/loan';
import { PersonalizedInsuranceRepository } from 'data/repository/personalized-recommend/insurance';

export class PersonalizedRecommendDomainRepositoryDependencies {
    loan: PersonalizedLoanRepository;
    insurance: PersonalizedInsuranceRepository;

    constructor(
        http: HttpProviderDependencies,
        storage: StorageProviderDependencies
    ) {
        this.loan = new PersonalizedLoanRepository(
            storage.accessToken,
            http.gateway.personalizedLoan,
            http.gateway.personalizedLoanCategory
        );
        this.insurance = new PersonalizedInsuranceRepository(storage.accessToken, http.base.personalizedInsurance);
    }
}
