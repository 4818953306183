import { Service } from 'core/service';
import { UserBankAccountService } from 'core/service/personal-finance/bank-account';
import { UserBankAccountRepositoryType } from 'core/repository/personal-finance/bank-account';
import { UserCashAccountService } from 'core/service/personal-finance/cash-account';
import { UserCashAccountRepositoryType } from 'core/repository/personal-finance/cash-account';
import { UserActualAssetService } from 'core/service/personal-finance/actual-asset';
import { UserActualAssetRepositoryType } from 'core/repository/personal-finance/actual-asset';
import { UserStockRepositoryType } from 'core/repository/personal-finance/stock';
import { UserFundRepositoryType } from 'core/repository/personal-finance/fund';
import { UserCardRepositoryType } from 'core/repository/personal-finance/card';
import { UserLoanRepositoryType } from 'core/repository/personal-finance/loan';
import { UserStockService } from 'core/service/personal-finance/stock';
import { UserFundService } from 'core/service/personal-finance/fund';
import { UserLoanService } from 'core/service/personal-finance/loan';
import { UserTransactionCategoryService } from 'core/service/personal-finance/category';
import { TransactionCategoryRepositoryType } from 'core/repository/personal-finance/transaction-category';
import { UserProductRepositoryType } from 'core/repository/personal-finance/product';
import { Observable } from 'rxjs/Rx';
import { SummarizedUserProducts } from 'core/entity/personal-finance/summarized-products';
import { apply, emptyList } from 'utils/index';
import { UserProductConfiguration } from 'core/entity/personal-finance/product-configuration';
import { Amount } from 'core/entity/amount';
import { Asset } from 'core/entity/personal-finance/asset';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserCardUpdateSpec } from 'core/entity/personal-finance/card/update-spec';
import { UserCard } from 'core/entity/personal-finance/card';
import { UserBankAccountUpdateSpec } from 'core/entity/personal-finance/bank-account/update-spec';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserFund } from 'core/entity/personal-finance/fund';
import { UserStock } from 'core/entity/personal-finance/stock';
import { UserCashAccountUpdateSpec } from 'core/entity/personal-finance/cash-account/update-spec';
import { UserLoanUpdateSpec } from 'core/entity/personal-finance/loan/update-spec';
import { UserFundUpdateSpec } from 'core/entity/personal-finance/fund/update-spec';
import { UserActualAsset } from 'core/entity/personal-finance/actual-asset';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { UserSecuritiesFirmAccountUpdateSpec } from 'core/entity/personal-finance/securities-firm-account/update-spec';
import { UserStockUpdateSpec } from 'core/entity/personal-finance/stock/update-spec';
import { UserActualAssetUpdateSpec } from 'core/entity/personal-finance/actual-asset/update-spec';
import { UserCardService } from 'core/service/personal-finance/card';
import { UserSecuritiesFirmAccountRepositoryType } from 'core/repository/personal-finance/securities-firm-account';
import { UserSecuritiesFirmAccountService } from 'core/service/personal-finance/securities-firm-account';
import { AdviceRepositoryType } from 'core/repository/personal-finance/advice';
import { AdviceService } from 'core/service/personal-finance/advice';
import { HealthInsuranceService } from 'core/service/personal-finance/health-insurance';
import { HealthInsurancePaymentDetailRepositoryType } from 'core/repository/personal-finance/health-insurance/payment-details';
import { HealthInsuranceSubmitRepositoryType } from 'core/repository/personal-finance/health-insurance/submit';
import { HealthInsuranceSubmitResponse } from 'core/entity/personal-finance/health-insurance/submit/response';
import { HealthInsuranceCertificateResponse } from 'core/entity/personal-finance/health-insurance/certificate/response';
import { HealthInsurancePaymentDetailResponse } from 'core/entity/personal-finance/health-insurance/payment-detail/response';
import { UserFinancialDataService } from 'core/service/personal-finance/financial-data';
import { UserFinancialDataRepositoryType } from 'core/repository/personal-finance/financial-data';
import AmountSummary = UserProductConfiguration.AmountSummary;
import DeletedStatus = Asset.DeletedStatus;

export class PersonalFinanceService implements Service {
    bankAccount: UserBankAccountService;
    cashAccount: UserCashAccountService;
    actualAsset: UserActualAssetService;
    card: UserCardService;
    stock: UserStockService;
    fund: UserFundService;
    loan: UserLoanService;
    category: UserTransactionCategoryService;
    securitiesFirmAccount: UserSecuritiesFirmAccountService;
    advice: AdviceService;
    healthInsurance: HealthInsuranceService;
    financialData: UserFinancialDataService;
    private productRepository: UserProductRepositoryType;

    constructor(
        bankAccountRepository: UserBankAccountRepositoryType,
        cashAccountRepository: UserCashAccountRepositoryType,
        actualAssetRepository: UserActualAssetRepositoryType,
        stockRepository: UserStockRepositoryType,
        fundRepository: UserFundRepositoryType,
        cardRepository: UserCardRepositoryType,
        loanRepository: UserLoanRepositoryType,
        categoryRepository: TransactionCategoryRepositoryType,
        securitiesFirmAccountRepository: UserSecuritiesFirmAccountRepositoryType,
        productRepository: UserProductRepositoryType,
        adviceRepository: AdviceRepositoryType,
        healthInsurancePaymentDetailRepository: HealthInsurancePaymentDetailRepositoryType,
        healthInsuranceCertificateRepository: HealthInsurancePaymentDetailRepositoryType,
        healthInsuranceSubmitRepository: HealthInsuranceSubmitRepositoryType,
        financialDataRepository: UserFinancialDataRepositoryType
    ) {
        this.bankAccount = new UserBankAccountService(bankAccountRepository);
        this.cashAccount = new UserCashAccountService(cashAccountRepository);
        this.actualAsset = new UserActualAssetService(actualAssetRepository);
        this.card = new UserCardService(cardRepository);
        this.stock =  new UserStockService(stockRepository);
        this.fund =  new UserFundService(fundRepository);
        this.loan =  new UserLoanService(loanRepository);
        this.category = new UserTransactionCategoryService(categoryRepository);
        this.securitiesFirmAccount = new UserSecuritiesFirmAccountService(securitiesFirmAccountRepository);
        this.productRepository = productRepository;
        this.advice = new AdviceService(adviceRepository);
        this.healthInsurance = new HealthInsuranceService(
            healthInsurancePaymentDetailRepository,
            healthInsuranceCertificateRepository,
            healthInsuranceSubmitRepository
        );
        this.financialData = new UserFinancialDataService(financialDataRepository)
    }

    getSummarizedProducts = (
        hidden: boolean = null,
        deleted: boolean = null
    ): Observable<SummarizedUserProducts> =>
        this.productRepository
            .getGroupByCompany(hidden, deleted)
            .map(products => {
                products.securitiesFirms = products.securitiesFirms.map(firm => {
                    firm.funds = firm.funds.filter(it => !it.closed).toList();
                    firm.stocks = firm.stocks.filter(it => !it.closed).toList();
                    return firm;
                }).toList();
                return products
            });

    getClosedInvestments = (): Observable<SummarizedUserProducts> =>
        this.productRepository
            .getGroupByCompany(null, null)
            .map(products => {
                return new SummarizedUserProducts(
                    emptyList(),
                    emptyList(),
                    products.securitiesFirms
                        .map(firm => {
                            firm.accounts = emptyList();
                            firm.funds = firm.funds.filter(it => it.closed).toList();
                            firm.stocks = firm.stocks.filter(it => it.closed).toList();
                            return firm;
                        })
                        .filter(firm =>
                            !firm.funds.isEmpty() ||
                            !firm.stocks.isEmpty()
                        )
                        .toList(),
                    new UserProductConfiguration(
                        emptyList(),
                        emptyList(),
                        emptyList(),
                        emptyList(),
                        emptyList(),
                        emptyList(),
                        emptyList(),
                        emptyList(),
                        new AmountSummary(
                            new Amount(0, Amount.Currency.KRW),
                            new Amount(0, Amount.Currency.KRW),
                            new Amount(0, Amount.Currency.KRW)
                        )
                    ),
                    null
                );
            });

    hideProduct = (asset: Asset.Type): Observable<Asset.Type> =>
        this.setHiddenStatus(asset, true);

    deleteProduct = (asset: Asset.Type): Observable<Asset.Type> =>
        this.setDeletedStatus(asset, DeletedStatus.DELETED);

    deleteProductTransactions = (asset: Asset.Type): Observable<Asset.Type> =>
        this.setDeletedStatus(asset, DeletedStatus.TRANSACTIONS_DELETED);

    restoreHiddenProduct = (asset: Asset.Type): Observable<Asset.Type> =>
        this.setHiddenStatus(asset, false);

    restoreDeletedProduct = (asset: Asset.Type): Observable<Asset.Type> =>
        this.setDeletedStatus(asset, DeletedStatus.NORMAL);

    private setHiddenStatus = (asset: Asset.Type, hidden: boolean): Observable<Asset.Type> => {
        if (asset instanceof UserActualAsset) {
            return this.actualAsset.update(
                asset.id,
                apply(new UserActualAssetUpdateSpec(), it => it.hidden = hidden)
            );
        } else if (asset instanceof UserBankAccount) {
            return this.bankAccount.update(
                asset.id,
                apply(new UserBankAccountUpdateSpec(), it => it.hidden = hidden)
            );
        } else if (asset instanceof UserCashAccount) {
            return this.cashAccount.update(
                asset.id,
                apply(new UserCashAccountUpdateSpec(), it => it.hidden = hidden)
            );
        } else if (asset instanceof UserCard) {
            return this.card.update(
                asset.id,
                apply(new UserCardUpdateSpec(), it => it.hidden = hidden)
            );
        } else if (asset instanceof UserLoan) {
            return this.loan.update(
                asset.id,
                apply(new UserLoanUpdateSpec(), it => it.hidden = hidden)
            );
        } else if (asset instanceof UserSecuritiesFirmAccount) {
            return this.securitiesFirmAccount.update(
                asset.id,
                apply(new UserSecuritiesFirmAccountUpdateSpec(), it => it.hidden = hidden)
            );
        } else if (asset instanceof UserFund) {
            return this.fund.update(
                asset.id,
                apply(new UserFundUpdateSpec(), it => it.hidden = hidden)
            );
        } else if (asset instanceof UserStock) {
            return this.stock.update(
                asset.id,
                apply(new UserStockUpdateSpec(), it => it.hidden = hidden)
            );
        }
    };

    private setDeletedStatus = (asset: Asset.Type, deleted: DeletedStatus): Observable<Asset.Type> => {
        if (asset instanceof UserActualAsset) {
            return this.actualAsset.update(
                asset.id,
                apply(new UserActualAssetUpdateSpec(), it => it.deletedStatus = deleted)
            );
        } else if (asset instanceof UserBankAccount) {
            return this.bankAccount.update(
                asset.id,
                apply(new UserBankAccountUpdateSpec(), it => it.deletedStatus = deleted)
            );
        } else if (asset instanceof UserCashAccount) {
            return this.cashAccount.update(
                asset.id,
                apply(new UserCashAccountUpdateSpec(), it => it.deletedStatus = deleted)
            );
        } else if (asset instanceof UserCard) {
            return this.card.update(
                asset.id,
                apply(new UserCardUpdateSpec(), it => it.deletedStatus = deleted)
            );
        } else if (asset instanceof UserLoan) {
            return this.loan.update(
                asset.id,
                apply(new UserLoanUpdateSpec(), it => it.deletedStatus = deleted)
            );
        } else if (asset instanceof UserSecuritiesFirmAccount) {
            return this.securitiesFirmAccount.update(
                asset.id,
                apply(new UserSecuritiesFirmAccountUpdateSpec(), it => it.deletedStatus = deleted)
            );
        } else if (asset instanceof UserFund) {
            return this.fund.update(
                asset.id,
                apply(new UserFundUpdateSpec(), it => it.deletedStatus = deleted)
            );
        } else if (asset instanceof UserStock) {
            return this.stock.update(
                asset.id,
                apply(new UserStockUpdateSpec(), it => it.deletedStatus = deleted)
            );
        }
    };

    getPaymentDetailResponse = (): Observable<HealthInsurancePaymentDetailResponse> => this.healthInsurance.getPaymentDetailResponse();
    getCertificateResponse = (): Observable<HealthInsuranceCertificateResponse> => this.healthInsurance.getCertificateResponse();
    getSubmitResponse = (loanId: string, userCreditGrade?: number): Observable<HealthInsuranceSubmitResponse> =>
        this.healthInsurance.getSubmitResponse(loanId, userCreditGrade);
}
