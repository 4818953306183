import React from 'react';

import { CardInfoSelectiveGroup } from 'presentation/components/info/card/detail/benefits/selective/group';
import { Card } from 'core/entity/card';

interface Props {
    card: Card;
    containRestrictions: boolean;
}

export const CardInfoSelectiveBenefits = ({ card, containRestrictions }: Props) => {
    const { selectiveBenefits } = card;

    return (
        <>
        {
            selectiveBenefits.map((benefit, i) =>
                <CardInfoSelectiveGroup
                    key={ `selective-benefits-${i}` }
                    card={ card }
                    group={ benefit }
                    containRestrictions={ containRestrictions }
                />
            )
        }
        </>
    )
};
