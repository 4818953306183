import { apply } from 'utils/index';
import { List } from 'immutable';
import { UseCase } from 'core/use-case';
import { Page } from 'core/entity/page';
import { CardSearchSpec } from 'core/entity/card/search-spec';
import { CardPriority } from 'core/entity/card/priority';
import { CardFilter } from 'core/entity/card/filter';
import { CardSearchResult } from 'core/entity/card/search-result';
import { CardRepositoryType } from 'core/repository/card';
import { Observable } from 'rxjs/Rx';

export class GetPromotionOnlyCards extends UseCase<CardSearchResult> {
    page?: Page = null;
    companies?: List<string> = null;
    priority?: CardPriority = null;
    private repository: CardRepositoryType;

    constructor(repository: CardRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<CardSearchResult> {
        return this.repository.search(
            new CardSearchSpec(
                apply(new CardFilter(), it => {
                    it.promotionOnly = true;
                    it.unstoppedOnly = true;
                    it.companies = this.companies;
                }),
                this.priority
            ),
            this.page,
            false
        );
    }

    protected validate(): boolean {
        return (
            this.page !== null &&
            this.priority !== null
        );
    }
}
