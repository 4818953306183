import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentSummary } from 'core/entity/content/summary';
import { ContentColumnSummaryMapper } from 'data/http/mapper/content/column/summary';
import { ContentCardComparisonSummaryMapper } from 'data/http/mapper/content/card-comparison/summary';
import { ContentCardNewsSummaryMapper } from 'data/http/mapper/content/card-news/summary';

export class ContentSummaryMapper implements NetworkResponseMapper<ContentSummary> {
    fromJson(json: any): ContentSummary {
        switch (json.type) {
            case 'column':
                return new ContentColumnSummaryMapper().fromJson(json);
            case 'card-news':
                return new ContentCardNewsSummaryMapper().fromJson(json);
            case 'card-comparison':
                return new ContentCardComparisonSummaryMapper().fromJson(json);
            default:
                return null;
        }
    }
}
