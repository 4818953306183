import { AppBadgeArea } from 'presentation/components/footer/app-badge-area';
import { AppDownloadIconArea } from "presentation/components/footer/app-download-icon-area";
import { FooterLegalMenu } from 'presentation/components/footer/footer-legal-menu';
import { FooterSiteMenu } from 'presentation/components/footer/footer-site-menu';
import { OUTBOUND_LINK } from 'presentation/module/sitemap';
import React from 'react';
import { isBanksaladWebView } from 'utils/is-banksalad-web-view';

import styles from './styles.pcss';

enum MAIL_TO {
  TITLE = '[문의] 문의 제목을 입력해주세요',
  BODY = '',
}

enum FOOTER_TEXT {
  COMPANY_NAME = '(주)뱅크샐러드',
  COMPANY_INFO = '대표: 김태훈  |  사업자등록번호: 105-87-73978  |  통신판매업신고: 2023-서울마포-2465',
  EMAIL_TEXT = 'E-mail: ',
  EMAIL_ADDRESS = 'hello@banksalad.com',
  ADDRESS = '주소: 서울특별시 마포구 마포대로 45, 일진빌딩 10층',
  DISCLOSURE = '뱅크샐러드는 국내 최다 1400여 개 금융상품을 비교하여 누구나 유리한 상품을 선택하도록 돕습니다. 이제 비대면 건강 검사로 모두가 나에게 필요한 건강 솔루션을 선택하도록 자산 관리의 경험을 바꿔나가고 있습니다.\n금융을 넘어 건강 자산까지 뱅크샐러드',
  COPYRIGHT = '© Banksalad Co., Ltd. All rights reserved.',
  SEPARATOR = ' | ',
}

const Copyright = () => (
  <div className={ styles.copyright }>
    <span className={ styles.bold }>{ FOOTER_TEXT.COMPANY_NAME }</span><br />
    <span>{ FOOTER_TEXT.COMPANY_INFO }</span> <br />
    <span>{ FOOTER_TEXT.EMAIL_TEXT }</span>
    <address>
      <a
        href={ OUTBOUND_LINK.MAIL_TO_BANKSALAD(MAIL_TO.TITLE, MAIL_TO.BODY) }
        rel='nofollow'>
        { FOOTER_TEXT.EMAIL_ADDRESS }
      </a>
    </ address>
    <span>{ FOOTER_TEXT.SEPARATOR }</span>
    <span>{ FOOTER_TEXT.ADDRESS }</span><br />
    <span className={ styles.whiteSpacePreWrap }>{ FOOTER_TEXT.DISCLOSURE }</span><br /><br />
    <span>{ FOOTER_TEXT.COPYRIGHT }</span>
  </div>
);

export const Footer = () => (
  !isBanksaladWebView() &&
  <footer className={ styles.wrap }>
    <div className={ styles.container }>
      <AppBadgeArea>
        <AppDownloadIconArea className={ styles.desktopAndTablet } />
      </AppBadgeArea>
      <FooterSiteMenu />
    </div>

    <div className={ styles.container }>
      <FooterLegalMenu>
        <Copyright />
        <AppDownloadIconArea className={ styles.mobile } />
      </FooterLegalMenu>
    </div>
  </footer>
);
