import { Entity } from 'core/entity';
import { TransactionIcon } from 'core/entity/personal-finance/transaction/category-icon';
import { TransactionCategoryParent } from 'core/entity/personal-finance/transaction/category-parent';
import { TransactionType } from 'core/entity/personal-finance/transaction/type';

export class TransactionCategory extends Entity {
    id: string;
    name: string;
    transactionType: TransactionType;
    priority: number;
    icon?: TransactionIcon;
    parent?: TransactionCategoryParent;

    constructor(
        id: string,
        name: string,
        transactionType: TransactionType,
        priority: number,
        icon: TransactionIcon = null,
        parent: TransactionCategoryParent = null
    ) {
        super();
        this.id = id;
        this.name = name;
        this.transactionType = transactionType;
        this.priority = priority;
        this.icon = icon;
        this.parent = parent;
    }
}
