import { CardTransactionPrimaryData } from 'core/entity/personal-finance/transaction/primary-data/card';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { SpiderDataMapper } from 'data/http/mapper/personal-finance/spider-data';
import { TransactionProductPrimaryDataMapper } from 'data/http/mapper/personal-finance/transaction/product/primary-data';
import { parseDate, optional } from 'utils/index';

export class CardTransactionPrimaryDataMapper implements NetworkResponseMapper<CardTransactionPrimaryData> {
    fromJson(json: any): CardTransactionPrimaryData {
        return new CardTransactionPrimaryData(
            json.id,
            json.title,
            parseDate(json.transacted_at),
            new AmountMapper().fromJson(json.amount),
            json.installment_month,
            optional(json.product, it => new TransactionProductPrimaryDataMapper().fromJson(it)),
            optional(json.spider, it => new SpiderDataMapper().fromJson(it))
        );
    }

}
