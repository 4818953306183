import { Entity } from 'core/entity';

export class PromotionTracker extends Entity {
    constructor(
        public viewName: string,
        public objectName: string,
    ) {
        super();
    }
}
