import { NetworkResponseMapper } from 'data/http/mapper';
import { UserAccount } from 'core/entity/user/account';
import { optional } from 'utils/index';

export class UserAccountMapper implements NetworkResponseMapper<UserAccount> {
    fromJson(json: any): UserAccount {
        return new UserAccount(
            json.email,
            json.email_verified,
            json.password_configured,
            optional(json.google_id),
            optional(json.facebook_id),
            optional(json.twitter_id)
        )
    }

}
