import { Entity } from 'core/entity';
import { UserCashAccount } from 'core/entity/personal-finance/cash-account';

export class UserDefaultProductConfiguration extends Entity {
    cashAccount?: UserCashAccount;

    constructor(cashAccount: UserCashAccount) {
        super();
        this.cashAccount = cashAccount;
    }
}
