import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { List } from 'immutable';
import { UserStock } from 'core/entity/personal-finance/stock';
import { UserStockUpdateSpec } from 'core/entity/personal-finance/stock/update-spec';
import { UserStockMapper } from 'data/http/mapper/personal-finance/stock';
import { UserStockUpdateSpecMapper } from 'data/http/mapper/personal-finance/stock/update-spec';
import { UserStockCreateSpec } from 'core/entity/personal-finance/stock/create-spec';
import { UserStockCreateSpecMapper } from 'data/http/mapper/personal-finance/stock/create-spec';

export class UserStocksApiProvider extends HttpProvider {
    getAll(accessToken: AccessToken): Observable<List<UserStock>> {
        return this.requestGET(
            'api/v2/me/stocks',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map(new UserStockMapper().fromJson))
        )
    }

    get(accessToken: AccessToken, id: string): Observable<UserStock> {
        return this.requestGET(
            `api/v2/me/stocks/${id}`,
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserStockMapper().fromJson(result.data)
        )
    }

    create(accessToken: AccessToken, spec: UserStockCreateSpec): Observable<UserStock> {
        return this.requestPOST(
            'api/v2/me/stocks',
            new UserStockCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserStockMapper().fromJson(result.data)
        )
    }

    update(accessToken: AccessToken, id: string, spec: UserStockUpdateSpec): Observable<UserStock> {
        return this.requestPATCH(
            `api/v2/me/stocks/${id}`,
            new UserStockUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserStockMapper().fromJson(result.data)
        )
    }
}
