import React from 'react';
import { List } from 'immutable';

import { CardConditionalRestriction } from 'core/entity/card/conditional-restriction';
import { uniqueKey } from 'utils/index';
import { DATA_TEST_LABEL } from 'utils/test';

import styles from './styles.pcss';

interface Props {
  baseRests: List<string>,
  condRests: List<CardConditionalRestriction>
}

export const CardInfoRestrictions = ({ baseRests, condRests }: Props) => (
  <div data-test={ DATA_TEST_LABEL.CARD_INFO_RESTRICTION }>
    {
      !baseRests.isEmpty() && (
        <table className={ styles.table }>
          <tbody>
          {
            baseRests.map(rest =>
              <tr key={ uniqueKey() }>
                <td className={ styles.restriction }>{ rest }</td>
              </tr>,
            )
          }
          </tbody>
        </table>
      )
    }
    {
      !condRests.isEmpty() && (
        <table className={ styles.table }>
          <tbody>
          {
            condRests.map(rest =>
              <tr key={ uniqueKey() }>
                <td className={ styles.condition }>{ rest.condition }</td>
                <td className={ styles.restriction }>{ rest.restriction }</td>
              </tr>,
            )
          }
          </tbody>
        </table>
      )
    }
  </div>
);
