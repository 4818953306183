import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentCardComparisonArticle } from 'core/entity/content/card-comparison/article';
import { ComparableCardConfigurationSetMapper } from 'data/http/mapper/comparable-card/configuration-set';

export class ContentCardComparisonArticleMapper implements NetworkResponseMapper<ContentCardComparisonArticle> {
    fromJson(json: any): ContentCardComparisonArticle {
        return new ContentCardComparisonArticle(
            json.introduction,
            json.conclusion,
            List(json.sets.map((set: any) =>
                new ComparableCardConfigurationSetMapper().fromJson(set)
            ))
        )
    }
}
