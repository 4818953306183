import { NetworkResponseMapper } from 'data/http/mapper';
import { ComparableCardIssuingUrl } from 'core/entity/comparable-card/issuing-urls';

export class ComparableCardIssuingUrlMapper implements NetworkResponseMapper<ComparableCardIssuingUrl> {
    fromJson(json: any): ComparableCardIssuingUrl {
        return new ComparableCardIssuingUrl(
            json.desktop,
            json.mobile,
            json.app
        )
    }
}
