import { NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoanOrganizationMapper } from 'data/http/mapper/personalized-recommend/loan/organization';
import { PersonalizedLoanInterestMapper } from 'data/http/mapper/personalized-recommend/loan/interest';
import { PersonalizedLoanLimitationMapper } from 'data/http/mapper/personalized-recommend/loan/limitation';
import { PersonalizedLoanApplyLinkMapper } from 'data/http/mapper/personalized-recommend/loan/app-link';
import { optional } from 'utils/index';
import { PersonalizedLoanMonthlyRepaymentMapper } from 'data/http/mapper/personalized-recommend/loan/monthly-repayment';
import { PersonalizedLoanGeneralType } from 'core/entity/personalized-recommend/loan/general-loan-type';
import { PersonalizedLoanSubmitStatusMapper } from 'data/http/mapper/personalized-recommend/loan/submit-status';

export class PersonalizedLoanGeneralTypeMapper implements NetworkResponseMapper<PersonalizedLoanGeneralType> {
    fromJson(json: any): PersonalizedLoanGeneralType {
        return new PersonalizedLoanGeneralType(
            json.id,
            json.display_name,
            json.loan_category,
            new PersonalizedLoanOrganizationMapper().fromJson(json.organization),
            json.api_contracted,
            new PersonalizedLoanInterestMapper().fromJson(json.loan_interest),
            new PersonalizedLoanLimitationMapper().fromJson(json.loan_limitation),
            new PersonalizedLoanApplyLinkMapper().fromJson(json.apply_link),
            new PersonalizedLoanSubmitStatusMapper().fromJson(json.submit_status),
            json.approval_wording,
            optional(json.issue_review_description),
            optional(json.monthly_repayment, new PersonalizedLoanMonthlyRepaymentMapper().fromJson),
            optional(json.description),
            optional(json.additional_wording)
        );
    }
}
