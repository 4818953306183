import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceCashFlowData } from 'core/entity/personal-finance/advice/content-data/cash-flow';
import { AmountMapper } from 'data/http/mapper/amount';
import { optional } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceCashFlowDataMapper implements NetworkResponseMapper<AdviceCashFlowData> {
    fromJson(json: any): AdviceCashFlowData {
        return new AdviceCashFlowData(
            new AmountMapper().fromJson(json.total_income),
            new AmountMapper().fromJson(json.total_expense),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
