import 'raf/polyfill';
import 'presentation/module/extension';
import 'presentation/normalize.pcss';

import moment from 'moment';
import { EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { FixBodyEvent } from 'presentation/bus/event/fix-body-event';
import { KeyUpEvent } from 'presentation/bus/event/key-up-event';
import { OnPopStateEvent } from 'presentation/bus/event/on-pop-state-event';
import { WindowResizeEvent } from 'presentation/bus/event/window-resize-event';
import { GlobalAlert } from 'presentation/components/container/components';
import { Toast } from 'presentation/components/toast';
import { scrollTop } from 'presentation/module/sugar';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { apply } from 'utils/index';

moment.locale('ko_KR');

type Props = RouteComponentProps<any>;

class Wrapper extends React.Component<Props> {
  private eventBus = EventBus.toObservable().subscribe((event: Event) => {
    if (event instanceof FixBodyEvent) {
      event.fix ? this.fixBody() : this.unfixBody();
    }
  });

  componentDidMount() {
    window.onscroll = () => {
      const top = scrollTop();

      EventBus.post(
        new DocumentScrollEvent(top, document.body.clientHeight - top - window.innerHeight)
      );
    };

    window.onresize = () => {
      EventBus.post(new WindowResizeEvent(window.innerWidth, window.innerHeight));
    };

    window.onkeyup = (e: KeyboardEvent) => {
      EventBus.post(new KeyUpEvent(e.keyCode));
    };

    window.onpopstate = () => {
      EventBus.post(new OnPopStateEvent());
    };
  }

  componentWillUnmount() {
    this.eventBus.unsubscribe();
    window.onscroll = null;
    window.onresize = null;
    window.onkeyup = null;
    window.onpopstate = null;
  }

  componentDidUpdate(props: RouteComponentProps<any>) {
    if (this.props.location.pathname !== props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div>
        {this.props.children}
        <Toast />
        <GlobalAlert />
      </div>
    );
  }

  private fixBody = () => {
    apply(document.body.style, it => {
      it.height = '100%';
      it.overflow = 'hidden';
    });
  };

  private unfixBody = () => {
    apply(document.body.style, it => {
      it.height = 'auto';
      it.overflow = 'visible';
    });
  };
}

export const Container = withRouter<Props, any>(Wrapper);
