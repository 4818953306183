import { UseCase } from 'core/use-case';
import { TransactionCreateSpec } from 'core/entity/personal-finance/transaction/create-spec';
import { Observable } from 'rxjs/Rx';
import { UserTransactionRepositoryType } from 'core/repository/personal-finance/transaction';
import { UserTransaction } from 'core/entity/personal-finance/transaction';

export class CreateUserTransaction extends UseCase<UserTransaction> {
    spec: TransactionCreateSpec;
    private repository: UserTransactionRepositoryType;

    constructor(repository: UserTransactionRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserTransaction> {
        return this.repository.create(this.spec);
    }

    protected validate(): boolean {
        return !!this.spec;
    }
}
