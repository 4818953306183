import { LoanPrimaryData } from 'core/entity/personal-finance/primary-data/loan';
import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderLoanDataMapper } from 'data/http/mapper/personal-finance/spider-data/loan';
import { optional } from 'utils/index';

export class LoanPrimaryDataMapper implements NetworkResponseMapper<LoanPrimaryData> {
    fromJson(json: any): LoanPrimaryData {
        return new LoanPrimaryData(
            optional(json.spider, it => new SpiderLoanDataMapper().fromJson(it))
        )
    }

}
