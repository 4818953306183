import { HttpProviderDependencies } from 'application/@context/http-providers';
import { StorageProviderDependencies } from 'application/@context/storage-providers';
import { PersonalizedFinanceKCBLoanRepository } from 'data/repository/personalized-finance/loan/kcb';

export class PersonalizedFinanceDomainRepositoryDependencies {
    loan: PersonalizedFinanceKCBLoanRepository;

    constructor(
        http: HttpProviderDependencies,
        storage: StorageProviderDependencies
    ) {
        this.loan = new PersonalizedFinanceKCBLoanRepository(storage.accessToken, http.gateway.personalizedFinanceLoan);
    }
}
