import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreChangeRecord } from 'core/entity/personal-credit/change-record';
import { lets, parseDate } from 'utils/index';

export class UserCreditScoreChangeRecordMapper implements NetworkResponseMapper<UserCreditScoreChangeRecord> {
    fromJson(json: any): UserCreditScoreChangeRecord {
        return new UserCreditScoreChangeRecord(
            json.title,
            json.collection_agency,
            lets(json.type, it => {
                switch (it.toLowerCase()) {
                    case 'general':
                        return UserCreditScoreChangeRecord.Type.GENERAL;
                    case 'overdue':
                        return UserCreditScoreChangeRecord.Type.OVERDUE;
                    default:
                        throw new MappingError();
                }
            }),
            parseDate(json.changed_at)
        )
    }

}
