import { Entity } from 'core/entity';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';

export class InsuranceGuaranteedDisease extends Entity {
    medicalCost: number;
    description: string;
    additionalDescription: string;
    price: number;
    category: string;
    diseaseName: string;
    checkupItem: MedicalCheckupJudgement.Item;

    constructor(
        medicalCost: number,
        description: string,
        additionalDescription: string,
        price: number,
        category: string,
        diseaseName: string,
        checkupItem: MedicalCheckupJudgement.Item
    ) {
        super();
        this.medicalCost = medicalCost;
        this.description = description;
        this.additionalDescription = additionalDescription;
        this.price = price;
        this.category = category;
        this.diseaseName = diseaseName;
        this.checkupItem = checkupItem;
    }
}

