import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { Amount } from 'core/entity/amount';
import { StockPrimaryData } from 'core/entity/personal-finance/primary-data/stock';
import { SpiderSecuritiesFirmSummary } from 'core/entity/personal-finance/securities-firm/summary';

export class UserStock extends Entity {
    id: string;
    nickname?: string;
    code?: string;
    description: string;
    hidden: boolean;
    deletedStatus: Asset.DeletedStatus;
    closed: boolean;
    primaryData: StockPrimaryData;
    averagePurchaseAmount?: Amount;
    valuation?: Amount;
    quantity?: number;
    purchasedAt?: Date;
    securitiesFirm: SpiderSecuritiesFirmSummary;
    commission?: number;
    tax?: number;
    principal?: Amount;
    holdingDuration?: number;

    constructor(
        id: string,
        description: string,
        hidden: boolean,
        deletedStatus: Asset.DeletedStatus,
        closed: boolean,
        primaryData: StockPrimaryData,
        nickname: string = null,
        code: string = null,
        averagePurchaseAmount: Amount = null,
        valuation: Amount = null,
        quantity: number = null,
        purchasedAt: Date = null,
        securitiesFirm: SpiderSecuritiesFirmSummary = null,
        commission: number = null,
        tax: number = null,
        principal: Amount = null,
        holdingDuration: number = null
    ) {
        super();
        this.id = id;
        this.nickname = nickname;
        this.code = code;
        this.description = description;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
        this.closed = closed;
        this.averagePurchaseAmount = averagePurchaseAmount;
        this.valuation = valuation;
        this.quantity = quantity;
        this.purchasedAt = purchasedAt;
        this.securitiesFirm = securitiesFirm;
        this.commission = commission;
        this.tax = tax;
        this.principal = principal;
        this.holdingDuration = holdingDuration;
        this.primaryData = primaryData;
    }
}
