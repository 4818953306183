import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanAverageInterest } from 'core/entity/loan/interest-value/average-interest';

export class LoanAverageInterestMapper implements NetworkResponseMapper<LoanAverageInterest> {
    fromJson(json: any): LoanAverageInterest {
        return new LoanAverageInterest(
            json.value
        );
    }
}
