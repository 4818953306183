import { NetworkRequestMapper } from 'data/http/mapper';
import { CardSearchSpec } from 'core/entity/card/search-spec';
import { CardFilterMapper } from 'data/http/mapper/card/filter';
import { CardPriorityMapper } from 'data/http/mapper/card/priority';

export class CardSearchSpecMapper implements NetworkRequestMapper<CardSearchSpec> {
    toJson(target: CardSearchSpec): any {
        return {
            filter: new CardFilterMapper().toJson(target.filter),
            priority: new CardPriorityMapper().toJson(target.priority)
        }
    }
}
