import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';

export class AdviceCardExpirationData extends Entity implements AdviceContentData {
    baseDateTime: Date;
    userName: string;
    cardId: string;
    cardName: string;
    cardRenewDate: Date;
    cardRenewRemainingDay: number;

    constructor(
        baseDateTime: Date,
        userName: string,
        cardId: string,
        cardName: string,
        cardRenewDate: Date,
        cardRenewRemainingDay: number,
    ) {
        super();
        this.baseDateTime = baseDateTime;
        this.userName = userName;
        this.cardId = cardId;
        this.cardName = cardName;
        this.cardRenewDate = cardRenewDate;
        this.cardRenewRemainingDay = cardRenewRemainingDay;
    }
}
