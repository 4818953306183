import { HttpProviderDependencies } from 'application/@context/http-providers';
import { CardDomainRepositoryDependencies } from 'application/@context/repositories/card';
import { InsuranceDomainRepositoryDependencies } from 'application/@context/repositories/insurance';
import { LegalConfigDomainRepositoryDependencies } from 'application/@context/repositories/legal-config';
import { LoanDomainRepositoryDependencies } from 'application/@context/repositories/loan';
import { MedicalCheckupDomainRepositoryDependencies } from 'application/@context/repositories/medical-checkup';
import { PersonalizedFinanceDomainRepositoryDependencies } from 'application/@context/repositories/personalized-finance';
import { PersonalizedRecommendDomainRepositoryDependencies } from 'application/@context/repositories/personalized-recommend';
import { PFMDomainRepositoryDependencies } from 'application/@context/repositories/pfm';
import { StorageProviderDependencies } from 'application/@context/storage-providers';
import { LocalCachedStateRepository } from 'data/repository/cached-state/local';
import { SessionCachedStateRepository } from 'data/repository/cached-state/session';
import { FinanceSectorRepository } from 'data/repository/finance-sector';
import { NoticeRepository } from 'data/repository/notice';
import { PromotionRepository } from 'data/repository/promotion';
import { ServiceCenterRepository } from 'data/repository/service-center';
import { SVGRepository } from 'data/repository/svg';
import { UserRepository } from 'data/repository/user';

export class RepositoryDependencies {
    card: CardDomainRepositoryDependencies;
    loan: LoanDomainRepositoryDependencies;
    insurance: InsuranceDomainRepositoryDependencies;
    pfm: PFMDomainRepositoryDependencies;
    legalConfig: LegalConfigDomainRepositoryDependencies;
    personalizedRecommend: PersonalizedRecommendDomainRepositoryDependencies;
    personalizedFinance: PersonalizedFinanceDomainRepositoryDependencies;
    medicalCheckup: MedicalCheckupDomainRepositoryDependencies;
    financeSector: FinanceSectorRepository;
    user: UserRepository;
    notice: NoticeRepository;
    svg: SVGRepository;
    promotion: PromotionRepository;
    localCachedState: LocalCachedStateRepository;
    sessionCachedState: SessionCachedStateRepository;
    serviceCenter: ServiceCenterRepository;

    constructor(
      http: HttpProviderDependencies,
      storage: StorageProviderDependencies,
      facebookClientId: string
    ) {
      this.card = new CardDomainRepositoryDependencies(http, storage);
      this.loan = new LoanDomainRepositoryDependencies(http, storage);
      this.insurance = new InsuranceDomainRepositoryDependencies(http);
      this.pfm = new PFMDomainRepositoryDependencies(http, storage);
      this.legalConfig = new LegalConfigDomainRepositoryDependencies(http, storage);
      this.personalizedRecommend = new PersonalizedRecommendDomainRepositoryDependencies(http, storage);
      this.personalizedFinance = new PersonalizedFinanceDomainRepositoryDependencies(http, storage);
      this.medicalCheckup = new MedicalCheckupDomainRepositoryDependencies(http, storage);
      this.financeSector = new FinanceSectorRepository(http.gateway.financeSectors);
      this.user = new UserRepository(http.gateway.users, http.gateway.userSession, storage.accessToken, facebookClientId);
      this.notice = new NoticeRepository(http.gateway.notices);
      this.svg = new SVGRepository(http.svg);
      this.promotion = new PromotionRepository(http.promotion);
      this.localCachedState = new LocalCachedStateRepository(storage.localCachedState);
      this.sessionCachedState = new SessionCachedStateRepository(storage.sessionCachedState);
      this.serviceCenter = new ServiceCenterRepository(http.zendesk.serviceCenter);
    }
}
