import React from 'react';

import { Application } from 'application/context.instance';
import { Card } from 'core/entity/card';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { CardInfo } from 'presentation/components/info/card';
import { Loader } from 'presentation/components/loader';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { SubscriptionBag } from 'presentation/module/extension';
import { FetchState } from 'presentation/view-model/fetch-state';
import { apply } from 'utils/index';

interface Props {
    cardId: number;
}

interface State {
    cardFetchState: FetchState;
    legalConfigFetchState: FetchState;
    card?: Card;
    legalConfig?: CardLegalConfiguration;
}

export class CardDetailView extends React.Component<Props, State> {
    state = {
        cardFetchState: FetchState.FETCHING,
        legalConfigFetchState: FetchState.FETCHING,
        card: null as Card,
        legalConfig: null as CardLegalConfiguration
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        this.fetch();
        this.fetchLegalConfig();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const {
            card,
            legalConfig,
            cardFetchState,
            legalConfigFetchState
        } = this.state;

        return (
            <>
            <Header active={ FinanceSectorID.CARD } />
            {
                (cardFetchState === FetchState.FETCHED && legalConfigFetchState === FetchState.FETCHED) ? (
                    <CardInfo
                        card={ card }
                        legalConfig={ legalConfig }
                    />
                ) : (cardFetchState === FetchState.FETCHING || legalConfigFetchState === FetchState.FETCHING) ? (
                    <Loader padding={ 250 } radius={ 25 } />
                ) : (
                    <FetchError padding={ 250 }>
                        카드정보를 불러오는데 문제가 발생했습니다<br/>
                        잠시 후 새로고침 해주세요
                    </FetchError>
                )
            }
            <Footer />
            </>
        )
    }

    private fetch = () => {
        this.setState({
            cardFetchState: FetchState.FETCHING
        });

        Application.useCases.getServerRenderedCard
            .runOnAnimateFrame()
            .subscribe(
                card => {
                    sendPixelEvent(PIXEL.CARD, `카드_${card.companyName}`);
                    this.setState({
                        cardFetchState: FetchState.FETCHED,
                        card: card
                    });
                },
                this.fetchForSPA
            )
            .unsubscribeBy(this.subscriptionBag)
    };

    private fetchForSPA = () => {
        const { cardId } = this.props;

        apply(Application.useCases.getCard, it => it.id = cardId)
            .runOnAnimateFrame()
            .subscribe(
                card => this.setState({
                    cardFetchState: FetchState.FETCHED,
                    card: card
                }),
                () => this.setState({
                    cardFetchState: FetchState.ERROR
                })
            )
            .unsubscribeBy(this.subscriptionBag);
    };

    private fetchLegalConfig = () => {
        const { cardId } = this.props;

        Application.services.legalConfig.getCardLegalConfigByCardId(cardId)
            .useOnAnimateFrame()
            .subscribe(
                legalConfig =>
                    this.setState({
                        legalConfig,
                        legalConfigFetchState: FetchState.FETCHED
                    }),
                () =>
                    this.setState({
                        legalConfigFetchState: FetchState.FETCHED
                    })
            )
            .unsubscribeBy(this.subscriptionBag);
    }
}
