import { CardCompanyCFAOverdue } from 'core/entity/legal-configuration/card-company/cfa/overdue';
import { NetworkResponseMapper } from 'data/http/mapper';

export class CardCompanyCFAOverdueMapper implements NetworkResponseMapper<CardCompanyCFAOverdue> {
    fromJson(json: any): CardCompanyCFAOverdue {
        return new CardCompanyCFAOverdue(
            json.description
        );
    }

}
