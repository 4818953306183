import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { UserStock } from 'core/entity/personal-finance/stock';
import { UserStockUpdateSpec } from 'core/entity/personal-finance/stock/update-spec';
import { UserStockRepositoryType } from 'core/repository/personal-finance/stock';

export class UpdateUserStock extends UseCase<UserStock> {
    id: string;
    spec: UserStockUpdateSpec;
    private repository: UserStockRepositoryType;

    constructor(repository: UserStockRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserStock> {
        return this.repository.update(this.id, this.spec);
    }

    protected validate(): boolean {
        return !!this.id && !!this.spec;
    }

}
