import React from 'react';

import { ToolTip } from 'presentation/components/tooltip';

import styles from './styles.pcss';

export const CardProfitsDescBanner = () => (
    <div className={ styles.box }>
        <h4 className={ styles.title }>뱅크샐러드의 계산방법</h4>
        <ul className={ styles.list }>
            <li className={ styles.annualCost }>
                <h5>연회비</h5>
                <p>카드의 브랜드(VISA, MASTER 등)에 따라 연회비가 다른데요. 저희는 최대한 보수적으로, 가장 연회비가 높은 브랜드를 선택했다고 가정하고 계산하고 있습니다. 또한 선택서비스를 추가했을 때 얻는 혜택 금액이 추가 연회비보다 높다면, 해당 연회비를 추가하여 계산하고 있어요.</p>
            </li>
            <li className={ styles.totalLimit }>
                <h5>
                    전월실적과 통합할인한도
                    <ToolTip message="지난달 카드사용액에 따라 이번달에 할인받을 수 있는 최대금액을 말합니다.">
                        <span className={ styles.tagTotalLimit }>
                            통합할인한도 란?
                        </span>
                    </ToolTip>
                </h5>
                <p>카드의 전월실적에 따라서 최대로 할인받을 수 있는 금액이 달라지는 경우가 있는데요. 그래서 입력하신 총 소비금액을 고객님의 전월실적으로 가정하고, 그에 맞는 통합할인 한도를 적용해 혜택을 계산하고 있어요.</p>
            </li>
            <li className={ styles.selectiveCard }>
                <h5>
                    선택형카드
                    <ToolTip message={`카드의 혜택을 직접 고르실 수 있습니다.\n다만 혜택에 따라 연회비가 추가될 수 있어요.`}>
                        <span className={ styles.tagSelectiveCard }>
                            선택형카드 란?
                        </span>
                    </ToolTip>
                </h5>
                <p>본인이 원하는 혜택을 직접 선택할 수 있는 선택형 카드의 경우, 입력하신 소비패턴에 가장 적합한 혜택들을 선택하셨다고 가정하고 계산했답니다.</p>
            </li>
            <li className={ styles.benefitTime }>
                <h5>카드 결제시간</h5>
                <p>점심식사 할인 혜택처럼, 언제 결제하느냐에 따라 혜택금액이 달라지는 독특한 카드들이 있어요. 이 경우에는 혜택을 받을 수 있는 시간에 결제했다고 가정하고 계산했답니다.</p>
            </li>
        </ul>
    </div>
);
