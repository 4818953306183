import React from 'react';

import styles from './styles.pcss';

interface Props {
    title: string;
    children: any;
}

export const CardInfoUnit = (props: Props) => (
  <section className={ styles.unit }>
    <h4 className={ styles.title }>{ props.title }</h4>
    { props.children }
  </section>
);
