import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, optional } from 'utils/index';
import { PersonalizedFinanceKCBLoanSpec } from 'core/entity/personalized-finance/loan/kcb/spec';

export class PersonalizedFinanceKCBLoanSpecMapper implements
    NetworkRequestMapper<PersonalizedFinanceKCBLoanSpec>
{
    toJson(target: PersonalizedFinanceKCBLoanSpec): any {
        return compactObject({
            interest: optional(target.interest),
            penalty_expired_at: optional(target.penaltyExpiredAt)
        });
    }
}
