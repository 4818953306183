import { NetworkResponseMapper } from 'data/http/mapper';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSubSectorMapper } from 'data/http/mapper/finance-sector/sub';
import { optional } from 'utils/index';
import { FinanceSectorIDMapper } from 'data/http/mapper/finance-sector/id';

export class FinanceSectorMapper implements NetworkResponseMapper<FinanceSector> {
    fromJson(json: any): FinanceSector {
        return new FinanceSector(
            new FinanceSectorIDMapper().fromJson(json.id),
            json.name,
            new FinanceSubSectorMapper().fromJson(json.sub1),
            new FinanceSubSectorMapper().fromJson(json.sub2),
            new FinanceSubSectorMapper().fromJson(json.sub3),
            optional(json.sub4, it => new FinanceSubSectorMapper().fromJson(it))
        )
    }
}
