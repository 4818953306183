import { NetworkResponseMapper } from 'data/http/mapper';
import { CardEventSummary } from 'core/entity/card/event/summary';
import { List } from 'immutable';
import { CardEvent } from 'core/entity/card/event';
import { CardEventSummaryMapper } from 'data/http/mapper/card/event/summary';

export class CardEventMapper implements NetworkResponseMapper<CardEvent> {
    fromJson(json: any): CardEvent {
        const summary: CardEventSummary = new CardEventSummaryMapper().fromJson(json);

        return new CardEvent(
            summary.id,
            summary.company,
            summary.title,
            summary.thumbNailImageUrl,
            summary.url,
            summary.featured,
            summary.hot,
            json.description,
            List(json.suggested_events.map((eventCardSummary: any) => new CardEventSummaryMapper().fromJson(eventCardSummary))),
            summary.startDate,
            summary.endDate
        )
    }
}
