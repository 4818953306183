import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceMetadata } from 'core/entity/personal-finance/advice/metadata';
import { optional, parseDate } from 'utils/index';

export class AdviceMetadataMapper implements NetworkResponseMapper<AdviceMetadata> {
    fromJson(json: any): AdviceMetadata {
        return new AdviceMetadata(
            optional(json.base_datetime, parseDate),
            optional(json.created_at, parseDate),
            optional(json.read_at, parseDate),
            optional(json.data_start_datetime, parseDate),
            optional(json.data_end_datetime, parseDate),
            optional(json.finalized_at, parseDate)
        )
    }

}
