import { Entity } from 'core/entity';

export class UserCreditScoreOrganization extends Entity {
    name: string;

    constructor(name: string) {
        super();
        this.name = name;
    }
}
