import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { Asset } from 'core/entity/personal-finance/asset';

export class DeletedStatusMapper implements
    NetworkResponseMapper<Asset.DeletedStatus>,
    NetworkRequestMapper<Asset.DeletedStatus>
{
    toJson(target: Asset.DeletedStatus): any {
        switch (target) {
            case Asset.DeletedStatus.NORMAL:
                return 'normal';
            case Asset.DeletedStatus.DELETED:
                return 'deleted';
            case Asset.DeletedStatus.TRANSACTIONS_DELETED:
                return 'transactions_deleted';
            default:
                return null;
        }
    }

    fromJson(json: any): Asset.DeletedStatus {
        switch (json) {
            case 'normal':
                return Asset.DeletedStatus.NORMAL;
            case 'deleted':
                return Asset.DeletedStatus.DELETED;
            case 'transactions_deleted':
                return Asset.DeletedStatus.TRANSACTIONS_DELETED;
            default:
                return null;
        }
    }

}
