import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { LoginRequired } from 'presentation/routes/login-required';
import { SettingsRoute } from 'presentation/routes/me/settings';
import { Header } from 'presentation/components/header';
import { Footer } from 'presentation/components/footer';
import { MyFinanceRoute } from 'presentation/routes/me/my-finance';

export const MeRoute = ({ match }: RouteComponentProps<any>) => (
    <LoginRequired>
        <Header active={ FinanceSectorID.ME } />
        <Switch>
            <Route path={ `${match.url}/settings` } component={ SettingsRoute } />
            <Route component={ MyFinanceRoute } />
        </Switch>
        <Footer />
    </LoginRequired>
);
