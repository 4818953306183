import { PersonalizedLoanMonthlyRepayment } from 'core/entity/personalized-recommend/loan/monthly-repayment';
import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';

export class PersonalizedLoanMonthlyRepaymentMapper implements NetworkResponseMapper<PersonalizedLoanMonthlyRepayment> {
    fromJson(json: any): PersonalizedLoanMonthlyRepayment {
        return new PersonalizedLoanMonthlyRepayment(
            json.repayment_money,
            lets(json.type, type => {
                switch (type) {
                    case 'iol':
                        return PersonalizedLoanMonthlyRepayment.Type.IOL;
                    case 'cam':
                        return PersonalizedLoanMonthlyRepayment.Type.CAM;
                    case 'cpm':
                        return PersonalizedLoanMonthlyRepayment.Type.CPM;
                    default:
                        throw new MappingError();
                }
            })
        );
    }
}
