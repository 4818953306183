import { NetworkResponseMapper } from 'data/http/mapper';
import { CardAnnualCostPromotion } from 'core/entity/card/annual-cost/promotion';
import { parseDate } from 'utils/index';

export class CardAnnualCostPromotionMapper implements NetworkResponseMapper<CardAnnualCostPromotion> {
    fromJson(json: any): CardAnnualCostPromotion {
        return new CardAnnualCostPromotion(
            json.summary,
            json.description,
            json.additional_description,
            json.issue_url,
            parseDate(json.start_date, false),
            parseDate(json.end_date, false)
        );
    }
}
