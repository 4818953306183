import { Entity } from 'core/entity';

export class ContentAuthorProfileSummary extends Entity {
    name: string;
    summary: string;
    imageUrl: string;

    constructor(
      name: string,
      summary: string,
      imageUrl: string
    ) {
      super();
      this.name = name;
      this.summary = summary;
      this.imageUrl = imageUrl;
    }
}
