import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class AdviceCardUsedAmount extends Entity {
    id: string;
    name: string;
    usedAmount: Amount;

    constructor(
        id: string,
        name: string,
        usedAmount: Amount
    ) {
        super();
        this.id = id;
        this.name = name;
        this.usedAmount = usedAmount;
    }
}
