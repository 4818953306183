import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs';
import { PersonalizedLoanCategory } from 'core/entity/personalized-recommend/loan/category';
import { List } from 'immutable';
import { PersonalizedLoanCategoryMapper } from 'data/http/mapper/personalized-recommend/loan/category';

export class PersonalizedLoanCategoryApiProvider extends HttpProvider {
    getAll(): Observable<List<PersonalizedLoanCategory>> {
        return this.requestGET(
            `/api/v3/loans/categories`
        ).map((response: any) => {
            return response.data.map(new PersonalizedLoanCategoryMapper().fromJson).toList();
        })
    }
}
