import { QuestionCategory } from 'core/entity/service-center/category';
import { NetworkResponseMapper } from 'data/http/mapper';

interface ZendeskCategoryProps {
  created_at: string;
  description: string;
  html_url: string;
  id: number;
  locale: string;
  name: string;
  outdated: boolean;
  position: number;
  source_locale: string;
  updated_at: string;
  url: string;
}

export class ZendeskQuestionMapper implements NetworkResponseMapper<QuestionCategory> {
  fromJson(json: any): QuestionCategory {
    return new QuestionCategory(json.name, json.description || 'Default', json.id, json.url);
  }
}

/*
 * 카테고리에 '고객안심센터' 메뉴 추가를 위한 하드코딩
 * */
export const STATIC_CATEGORIES: ZendeskCategoryProps[] = [
  {
    created_at: '2021-08-18T05:00:00Z',
    description: 'SafetyCenter',
    html_url: 'https://help.banksalad.com/고객안심센터',
    id: 0,
    locale: 'ko',
    name: '고객안심센터',
    outdated: false,
    position: 0,
    source_locale: 'ko',
    updated_at: '2021-08-18T05:00:00Z',
    url: 'https://help.banksalad.com/고객안심센터',
  },
  {
    created_at: '2021-06-16T00:00:00Z',
    description: 'UsingGuide',
    html_url:
      'https://m.blog.naver.com/PostList.nhn?blogId=banksalad&from=postList&categoryNo=35&parentCategoryNo=35',
    id: 0,
    locale: 'ko',
    name: '이용가이드',
    outdated: false,
    position: 1,
    source_locale: 'ko',
    updated_at: '2021-06-16T00:00:00Z',
    url: 'https://m.blog.naver.com/PostList.nhn?blogId=banksalad&from=postList&categoryNo=35&parentCategoryNo=35',
  },
];
