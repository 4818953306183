import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV2PrivacyAgreementView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 개인(신용)정보 수집ㆍ이용 동의</h3>
      <b>주식회사 레이니스트</b>
      <p>
                귀사의 뱅크샐러드 “모바일 어플리케이션 및 웹 사이트”(이하 “본건 서비스”) 이용과 관련하여 귀사가 본인의 개인 (신용)정보를 수집∙이용하는 경우에는 「개인정보 보호법」, 「신용정보의 이용 및 보호에 관한 법률」 및 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」에 따라 본인의 동의가 필요합니다. 이에 귀사가 다음과 같이 본인의 개인(신용) 정보를 수집∙이용하는 것에 동의합니다.
      </p>
      <b>[필수] 개인(신용)정보 수집∙이용 동의</b>
      <table>
        <tbody>
          <tr>
            <th className={ styles.width20 }>수집 항목</th>
            <th className={ styles.width40 }>이용목적</th>
            <th className={ styles.width20 }>보유기간</th>
          </tr>
          <tr>
            <td className={ styles.width40 }>
              <div>이메일 주소 및 비밀번호 또는 Facebook ID ,Google ID, Kakao ID, Twitter ID, Naver ID, Daum ID 등 가입 을 위해 필요한 제휴 서비 스 계정</div>
            </td>
            <td className={ styles.width40 }>
              <div>* 회원 가입 및 회원 정보 관리 회원제 서비스 제공</div>
              <div>* 고지사항 전달, 서비스 제공관련 안내, 서비스 이용 본인의사 확인, 서비스 이용에 따른 민원사항 처리</div>
            </td>
            <td className={ styles.width40 }>
              <div>회원탈퇴 후 5일까지</div>
            </td>
          </tr>
          <tr>
            <td className={ styles.width40 }>
              <div>SMS/LMS/MMS 메시지, 어플리케이션 푸쉬 알림, 고객의 수기 입력, 카드사/은행사/보험사/증권 사 등의 금융사 또는 국세청 ID/비밀번호, 공인인증서 비밀번호 입력을 통해 수집하는 회원 보유 금융상품 정보(금융사, 금융상품명, 금융상품 종류, 계좌번호, 신용/체크카드 끝 4 자리 등) 및 신용정보, 계좌 잔액, 입출금 내역, 카드 결제 내역, 보유 포인트 내역등의 회원 금융 거래 정보</div>
            </td>
            <td className={ styles.width40 }>
              <div>* 금융 거래 내역 정보 분석 및 관리</div>
              <div>* 보유 금융 상품 정보 분석 및 관리</div>
              <div>* 예산 관리 및 지출 관리 서비스 제공 * 자산관리 서비스 제공</div>
              <div>* 회원 맞춤형 금융상품 추천</div>
              <div>* 신용등급 및 신용점수 등 고객의 신용정보 관리를 위한 정보 제공</div>
              <div>* 안정적인 서비스 운영 및 제공을 위한 안전성 테스트 및 모니터링</div>
              <div>* 기타 고객에게 유익하다고 판단되는 정보의 제공</div>
            </td>
            <td className={ styles.width40 }>
              <div>회원탈퇴 후 5일까지</div>
            </td>
          </tr>
          <tr>
            <td className={ styles.width40 }>
              <div>이름, 생년월일, 성별, 내외국인정보 이동정보통신사 및 이동전화번호, 휴대폰이나 아이핀을 통한 본인 인증 시 수집되는 CI/DI 값 등 회원 본인 확인을 위해 필요한 식별자 정보</div>
            </td>
            <td className={ styles.width40 }>
              <div>* 본인 인증 및 확인</div>
              <div>* 안정적인 서비스 운영 및 제공을 위한 안전성 테스트 및 모니터링</div>
            </td>
            <td className={ styles.width40 }>
              <div>회원탈퇴 후 5일까지</div>
            </td>
          </tr>
        </tbody>
      </table>
      <p>위 개인(신용) 정보 수집·이용에 관한 동의는 본건 서비스 이용을 위하여 필수적이므로, 위 사항에 동의하셔야 만 본건 서비스 이용이 가능합니다.</p>
      <p>귀사가 위와 같이 본인의 개인(신용)정보를 수집·이용하는 것에 동의합니다.</p>
    </div>
  </div>
);

