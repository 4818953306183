import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { SummarizedUserProducts } from 'core/entity/personal-finance/summarized-products';
import { UserProductRepositoryType } from 'core/repository/personal-finance/product';

export class GetSummarizedUserProducts extends UseCase<SummarizedUserProducts> {
    hidden: boolean = null;
    deleted: boolean = null;
    private repository: UserProductRepositoryType;

    constructor(repository: UserProductRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<SummarizedUserProducts> {
        return this.repository.getGroupByCompany(this.hidden, this.deleted);
    }

    protected validate(): boolean {
        return true;
    }
}
