import { NetworkRequestMapper } from 'data/http/mapper';
import { UserCardUpdateSpec } from 'core/entity/personal-finance/card/update-spec';
import { compactObject, optional } from 'utils/index';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';

export class UserCardUpdateSpecMapper implements NetworkRequestMapper<UserCardUpdateSpec> {
    toJson(target: UserCardUpdateSpec): any {
        return compactObject({
            nickname: optional(target.nickname),
            description: optional(target.description),
            hidden: optional(target.hidden),
            deleted_status: optional(target.deletedStatus, new DeletedStatusMapper().toJson)
        })
    }
}
