import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoanApplyLink } from 'core/entity/personalized-recommend/loan/app-link';
import { lets } from 'utils/index';

export class PersonalizedLoanApplyLinkMapper implements NetworkResponseMapper<PersonalizedLoanApplyLink> {
    fromJson(json: any): PersonalizedLoanApplyLink {
        return new PersonalizedLoanApplyLink(
            json.desktop_link,
            json.mobile_link,
            lets(json.type, type => {
                switch (type) {
                    case 'loan-apply':
                        return PersonalizedLoanApplyLink.Type.LOAN_APPLY;
                    case 'confirm-loan-interest':
                        return PersonalizedLoanApplyLink.Type.CONFIRM_LOAN_INTEREST;
                    default:
                        throw new MappingError();
                }
            })
        )
    }
}
