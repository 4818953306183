import { AxiosInstance } from 'axios';
import { CardCompaniesApiProvider } from 'data/http/api/gateway/v1/cards/companies';
import { SpiderBanksApiProvider } from 'data/http/api/gateway/v2/banks/spider';
import { FinanceSectorsApiProvider } from 'data/http/api/gateway/v2/banksalad-finance-sectors';
import { SpiderCardCompaniesApiProvider } from 'data/http/api/gateway/v2/cards/companies/spider';
import { CreditScoreMetadataApiProvider } from 'data/http/api/gateway/v2/credit-scores/kcb/metadata';
import { CreditScoreTipsApiProvider } from 'data/http/api/gateway/v2/credit-scores/tips';
import { LoanApiProvider } from 'data/http/api/gateway/v2/loan';
import { LoanStatisticsApiProvider } from 'data/http/api/gateway/v2/loan/statistics';
import { LoanSurveyConfigurationApiProvider } from 'data/http/api/gateway/v2/loan/survey-configuration';
import { UserCreditScoresFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb';
import { UserCreditCardsStatusFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/card-status';
import { UserCreditScoreChangeRecordStatusApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/change-records';
import { UserCreditScoreInquiryRecordsFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/inquiry-records';
import { UserCreditJointSuretiesStatusFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/joint-surety-status';
import { UserCreditLoansStatusFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/loan-status';
import { UserCreditOverduesStatusFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/overdue-status';
import { HealthInsuranceCertificateApiProvider } from 'data/http/api/gateway/v2/me/health-insurance/certificates';
import { HealthInsurancePaymentDetailApiProvider } from 'data/http/api/gateway/v2/me/health-insurance/payment-details';
import { HealthInsuranceSubmitApiProvider } from 'data/http/api/gateway/v2/me/health-insurance/submit';
import { MedicalCheckUpResultApiProvider } from 'data/http/api/gateway/v2/me/medical-checkups/result/inedx';
import { NoticesApiProvider } from 'data/http/api/gateway/v2/notices';
import { UsersApiProvider } from 'data/http/api/gateway/v2/users';
import { UserActualAssetsApiProvider } from 'data/http/api/gateway/v2/users/actual-assets';
import { UserBankAccountsApiProvider } from 'data/http/api/gateway/v2/users/bank-accounts';
import { UserCardsApiProvider } from 'data/http/api/gateway/v2/users/cards';
import { UserCardBillsApiProvider } from 'data/http/api/gateway/v2/users/cards/bills';
import { UserCardCreditLimitsApiProvider } from 'data/http/api/gateway/v2/users/cards/companies/credit-limits';
import { SummarizedUserCardBillsApiProvider } from 'data/http/api/gateway/v2/users/cards/summarized-bills';
import { UserCashAccountsApiProvider } from 'data/http/api/gateway/v2/users/cash-accounts';
import { UserFinancialDataAssetSummariesApiProvider } from 'data/http/api/gateway/v2/users/financial-data/asset-summaries';
import { UserFundsApiProvider } from 'data/http/api/gateway/v2/users/funds';
import { UserLoansApiProvider } from 'data/http/api/gateway/v2/users/loans';
import { UserProductsApiProvider } from 'data/http/api/gateway/v2/users/products';
import { UserSecuritiesFirmAccountsApiProvider } from 'data/http/api/gateway/v2/users/securities-firms/accounts';
import { UserSessionApiProvider } from 'data/http/api/gateway/v2/users/session';
import { UserStocksApiProvider } from 'data/http/api/gateway/v2/users/stocks';
import { TransactionCategoryApiProvider } from 'data/http/api/gateway/v2/users/transactions/categories';
import {
  CardCompanyLegalConfigurationApiProvider,
  IgnoredCardCompanyLegalConfigurationApiProvider
} from 'data/http/api/gateway/v3/card-companies/legal-configuration';
import { CardsApiProvider } from 'data/http/api/gateway/v3/cards';
import { CardConfigurationApiProvider } from 'data/http/api/gateway/v3/cards/configuration';
import {
  CardLegalConfigurationApiProvider,
  IgnoredCardLegalConfigurationApiProvider
} from 'data/http/api/gateway/v3/cards/legal-configuration';
import { PersonalizedLoanCategoryApiProvider } from 'data/http/api/gateway/v3/loans/categories';
import {
  IgnoredLoanLegalConfigurationApiProvider,
  LoanLegalConfigurationApiProvider
} from 'data/http/api/gateway/v3/loans/legal-configuration';
import { UserTransactionApiProvider } from 'data/http/api/gateway/v3/me/transactions';
import { SpiderSecuritiesFirmsApiProvider } from 'data/http/api/gateway/v3/securities-firms/spider';
import { StoresApiProvider } from 'data/http/api/gateway/v3/stores';
import { StoreCategoriesApiProvider } from 'data/http/api/gateway/v3/stores/categories';
import { AdviceApiProvider } from 'data/http/api/gateway/v4/me/advice';
import { PersonalizedLoanApiProvider } from 'data/http/api/gateway/v4/me/loans';
import { PersonalizedFinanceLoanApiProvider } from 'data/http/api/gateway/v4/me/loans/kcb-loans';

export class GatewayHttpProviderDependencies {
    /* Sector */
    financeSectors: FinanceSectorsApiProvider;

    /* card */
    cards: CardsApiProvider;
    cardCompanies: CardCompaniesApiProvider;
    cardConfiguration: CardConfigurationApiProvider;
    stores: StoresApiProvider;
    storeCategories: StoreCategoriesApiProvider;

    /* loan */
    loan: LoanApiProvider;
    loanSurveyConfiguration: LoanSurveyConfigurationApiProvider;
    loanStatistics: LoanStatisticsApiProvider;

    /* user */
    users: UsersApiProvider;
    userSession: UserSessionApiProvider;

    /* PFM */
    userProducts: UserProductsApiProvider;
    userCards: UserCardsApiProvider;
    userCardCreditLimits: UserCardCreditLimitsApiProvider;
    userCardBills: UserCardBillsApiProvider;
    summarizedUserCardBills: SummarizedUserCardBillsApiProvider;
    userCashAccounts: UserCashAccountsApiProvider;
    userActualAssets: UserActualAssetsApiProvider;
    userBankAccounts: UserBankAccountsApiProvider;
    userLoans: UserLoansApiProvider;
    userSecuritiesFirmAccounts: UserSecuritiesFirmAccountsApiProvider;
    userFunds: UserFundsApiProvider;
    userStocks: UserStocksApiProvider;
    transactionCategory: TransactionCategoryApiProvider;
    spiderBanks: SpiderBanksApiProvider;
    spiderCardCompanies: SpiderCardCompaniesApiProvider;
    spiderSecuritiesFirms: SpiderSecuritiesFirmsApiProvider;
    userTransaction: UserTransactionApiProvider;
    advice: AdviceApiProvider;
    userFinancialDataAssetSummaries: UserFinancialDataAssetSummariesApiProvider;

    /* PFM - credit */
    creditScoreFromKCB: UserCreditScoresFromKCBApiProvider;
    creditTips: CreditScoreTipsApiProvider;
    creditInquiryRecords: UserCreditScoreInquiryRecordsFromKCBApiProvider;
    creditCardsStatusFromKCB: UserCreditCardsStatusFromKCBApiProvider;
    creditLoansStatusFromKCB: UserCreditLoansStatusFromKCBApiProvider;
    creditOverduesStatusFromKCB: UserCreditOverduesStatusFromKCBApiProvider;
    creditJointSuretiesStatusFromKCB: UserCreditJointSuretiesStatusFromKCBApiProvider;
    creditChangeRecords: UserCreditScoreChangeRecordStatusApiProvider;
    creditMetadata: CreditScoreMetadataApiProvider;

    /* PFM - health-insurance */
    healthInsurancePaymentDetail: HealthInsurancePaymentDetailApiProvider;
    healthInsuranceCertificate: HealthInsuranceCertificateApiProvider;
    healthInsuranceSubmit: HealthInsuranceSubmitApiProvider;

    /* medical-check-up */
    medicalCheckUpResult: MedicalCheckUpResultApiProvider;

    /* personalized recommend */
    personalizedLoan: PersonalizedLoanApiProvider;
    personalizedLoanCategory: PersonalizedLoanCategoryApiProvider;

    /* personalized finance */
    personalizedFinanceLoan: PersonalizedFinanceLoanApiProvider;

    /* legal config */
    cardLegalConfig: CardLegalConfigurationApiProvider;
    cardCompanyLegalConfig: CardCompanyLegalConfigurationApiProvider;
    loanLegalConfig: LoanLegalConfigurationApiProvider;

    /* etc */
    notices: NoticesApiProvider;

    constructor(gateway: AxiosInstance, ignoredLegalService: boolean) {
      /* Sector */
      this.financeSectors = new FinanceSectorsApiProvider(gateway);

      /* card */
      this.cards = new CardsApiProvider(gateway);
      this.cardCompanies = new CardCompaniesApiProvider(gateway);
      this.cardConfiguration = new CardConfigurationApiProvider(gateway);
      this.stores = new StoresApiProvider(gateway);
      this.storeCategories = new StoreCategoriesApiProvider(gateway);

      /* loan */
      this.loan = new LoanApiProvider(gateway);
      this.loanSurveyConfiguration = new LoanSurveyConfigurationApiProvider(gateway);
      this.loanStatistics = new LoanStatisticsApiProvider(gateway);

      /* user */
      this.users = new UsersApiProvider(gateway);
      this.userSession = new UserSessionApiProvider(gateway);

      /* PFM */
      this.userProducts = new UserProductsApiProvider(gateway);
      this.userCards = new UserCardsApiProvider(gateway);
      this.userCardCreditLimits = new UserCardCreditLimitsApiProvider(gateway);
      this.userCardBills = new UserCardBillsApiProvider(gateway);
      this.summarizedUserCardBills = new SummarizedUserCardBillsApiProvider(gateway);
      this.userCashAccounts = new UserCashAccountsApiProvider(gateway);
      this.userActualAssets = new UserActualAssetsApiProvider(gateway);
      this.userBankAccounts = new UserBankAccountsApiProvider(gateway);
      this.userLoans = new UserLoansApiProvider(gateway);
      this.userSecuritiesFirmAccounts = new UserSecuritiesFirmAccountsApiProvider(gateway);
      this.userFunds = new UserFundsApiProvider(gateway);
      this.userStocks = new UserStocksApiProvider(gateway);
      this.transactionCategory = new TransactionCategoryApiProvider(gateway);
      this.spiderBanks = new SpiderBanksApiProvider(gateway);
      this.spiderCardCompanies = new SpiderCardCompaniesApiProvider(gateway);
      this.spiderSecuritiesFirms = new SpiderSecuritiesFirmsApiProvider(gateway);
      this.userTransaction = new UserTransactionApiProvider(gateway);
      this.advice = new AdviceApiProvider(gateway);
      this.userFinancialDataAssetSummaries = new UserFinancialDataAssetSummariesApiProvider(gateway);

      /* PFM - credit */
      this.creditScoreFromKCB = new UserCreditScoresFromKCBApiProvider(gateway);
      this.creditTips = new CreditScoreTipsApiProvider(gateway);
      this.creditInquiryRecords = new UserCreditScoreInquiryRecordsFromKCBApiProvider(gateway);
      this.creditCardsStatusFromKCB = new UserCreditCardsStatusFromKCBApiProvider(gateway);
      this.creditLoansStatusFromKCB = new UserCreditLoansStatusFromKCBApiProvider(gateway);
      this.creditOverduesStatusFromKCB = new UserCreditOverduesStatusFromKCBApiProvider(gateway);
      this.creditJointSuretiesStatusFromKCB = new UserCreditJointSuretiesStatusFromKCBApiProvider(gateway);
      this.creditChangeRecords = new UserCreditScoreChangeRecordStatusApiProvider(gateway);
      this.creditMetadata = new CreditScoreMetadataApiProvider(gateway);

      /* PFM - health-insurance */
      this.healthInsurancePaymentDetail = new HealthInsurancePaymentDetailApiProvider(gateway);
      this.healthInsuranceCertificate = new HealthInsuranceCertificateApiProvider(gateway);
      this.healthInsuranceSubmit = new HealthInsuranceSubmitApiProvider(gateway);

      /* medical-check-up */
      this.medicalCheckUpResult = new MedicalCheckUpResultApiProvider(gateway);

      /* personalized recommend */
      this.personalizedLoan = new PersonalizedLoanApiProvider(gateway);
      this.personalizedLoanCategory = new PersonalizedLoanCategoryApiProvider(gateway);

      /* personalized finance */
      this.personalizedFinanceLoan = new PersonalizedFinanceLoanApiProvider(gateway);

      /* legal config */
      this.cardLegalConfig =
            ignoredLegalService ?
              new IgnoredCardLegalConfigurationApiProvider(gateway) :
              new CardLegalConfigurationApiProvider(gateway);
      this.cardCompanyLegalConfig =
            ignoredLegalService ?
              new IgnoredCardCompanyLegalConfigurationApiProvider(gateway) :
              new CardCompanyLegalConfigurationApiProvider(gateway);
      this.loanLegalConfig =
            ignoredLegalService ?
              new IgnoredLoanLegalConfigurationApiProvider(gateway) :
              new LoanLegalConfigurationApiProvider(gateway);

      /* etc */
      this.notices = new NoticesApiProvider(gateway);
    }
}
