import { Entity } from 'core/entity';
import { TransactionProduct } from 'core/entity/personal-finance/transaction/product';
import { TransactionType } from 'core/entity/personal-finance/transaction/type';

export class TransactionCreateSpec extends Entity {
    title: string;
    description?: string;
    product: TransactionProduct;
    transactedAt: Date;
    amount: number;
    type: TransactionType;
    categoryId: string;
    configuration?: TransactionCreateSpec.Configuration;

    constructor(
        title: string,
        product: TransactionProduct,
        transactedAt: Date,
        amount: number,
        type: TransactionType,
        categoryId: string,
        description: string = null,
        configuration: TransactionCreateSpec.Configuration = null
    ) {
        super();
        this.title = title;
        this.product = product;
        this.transactedAt = transactedAt;
        this.amount = amount;
        this.type = type;
        this.categoryId = categoryId;
        this.description = description;
        this.configuration = configuration;
    }
}

export module TransactionCreateSpec {
    export class Configuration extends Entity {
        excludedFromBudget?: boolean;

        constructor(
            excludedFromBudget: boolean = null
        ) {
            super();
            this.excludedFromBudget = excludedFromBudget;
        }
    }
}
