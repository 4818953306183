import { TransactionProductPrimaryData } from 'core/entity/personal-finance/transaction/product/primary-data';
import { NetworkResponseMapper } from 'data/http/mapper';

export class TransactionProductPrimaryDataMapper implements NetworkResponseMapper<TransactionProductPrimaryData> {
    fromJson(json: any): TransactionProductPrimaryData {
        return new TransactionProductPrimaryData(
            json.name,
            json.number
        )
    }

}
