import { Service } from 'core/service';
import { Observable } from 'rxjs/Rx';
import { UserStockRepositoryType } from 'core/repository/personal-finance/stock';
import { UserStockCreateSpec } from 'core/entity/personal-finance/stock/create-spec';
import { UserStock } from 'core/entity/personal-finance/stock';
import { UserStockUpdateSpec } from 'core/entity/personal-finance/stock/update-spec';

export class UserStockService implements Service {
    private repository: UserStockRepositoryType;

    constructor(repository: UserStockRepositoryType) {
        this.repository = repository;
    }

    create = (spec: UserStockCreateSpec): Observable<UserStock> =>
        this.repository.create(spec);

    update = (id: string, spec: UserStockUpdateSpec): Observable<UserStock> =>
        this.repository.update(id, spec);
}
