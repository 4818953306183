import { InstallmentIncludedTransaction } from 'core/entity/personal-finance/transaction/installment/transaction';
import { NetworkResponseMapper } from 'data/http/mapper';

export class InstallmentIncludedTransactionMapper implements NetworkResponseMapper<InstallmentIncludedTransaction> {
    fromJson(json: any): InstallmentIncludedTransaction {
        return new InstallmentIncludedTransaction(
            json.id
        )
    }

}
