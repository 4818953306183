import { MappingError, NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoanCategory } from 'core/entity/personalized-recommend/loan/category';

export class PersonalizedLoanCategoryMapper implements
    NetworkRequestMapper<PersonalizedLoanCategory>,
    NetworkResponseMapper<PersonalizedLoanCategory>
{
    fromJson(json: any): PersonalizedLoanCategory {
        switch (json) {
            case 'credit-loan':
                return PersonalizedLoanCategory.CREDIT_LOAN;
            case 'mortgage-loan':
                return PersonalizedLoanCategory.MORTGAGE_LOAN;
            case 'business-loan':
                return PersonalizedLoanCategory.BUSINESS_LOAN;
            case 'auto-loan':
                return PersonalizedLoanCategory.AUTO_LOAN;
            case 'hatsal-loan':
                return PersonalizedLoanCategory.HATSAL_LOAN;
            case 'car-loan':
                return PersonalizedLoanCategory.CAR_LOAN;
            case 'insurance-mortgage-loan':
                return PersonalizedLoanCategory.INSURANCE_MORTGAGE_LOAN;
            default:
                throw new MappingError();
        }
    }

    toJson(target: PersonalizedLoanCategory): any {
        switch (target) {
            case PersonalizedLoanCategory.CREDIT_LOAN:
                return 'credit-loan';
            case PersonalizedLoanCategory.MORTGAGE_LOAN:
                return 'mortgage-loan';
            case PersonalizedLoanCategory.BUSINESS_LOAN:
                return 'business-loan';
            case PersonalizedLoanCategory.AUTO_LOAN:
                return 'auto-loan';
            case PersonalizedLoanCategory.HATSAL_LOAN:
                return 'hatsal-loan';
            case PersonalizedLoanCategory.CAR_LOAN:
                return 'car-loan';
            case PersonalizedLoanCategory.INSURANCE_MORTGAGE_LOAN:
                return 'insurance-mortgage-loan';
            default:
                throw new MappingError();
        }
    }
}
