import React from 'react';
import Helmet from 'react-helmet';

import { MetaSet } from 'presentation/view-model/meta-set';

interface Props {
    meta: MetaSet
}

export const MetaHelmet = (props: Props) => {
    const { meta } = props;
    return (
        <Helmet>
            <title>{ meta.title }</title>
            <meta name="og:title" content={ meta.title } />
            <meta name="description" content={ meta.description } />
            <meta name="og:description" content={ meta.description } />
            <meta name="image" content={ meta.image } />
            <meta name="og:image" content={ meta.image } />
            <meta name="keywords" content={ meta.keywords } />
            <meta name="viewport" content={ meta.viewport } />
        </Helmet>
    )
};
