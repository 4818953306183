import { SpiderData } from 'core/entity/personal-finance/spider-data';

export class SpiderSecuritiesFirmAccountData extends SpiderData {
    constructor(
        name: string,
        responseContained: boolean = null
    ) {
        super(name, responseContained);
    }
}
