import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';

export class SummarizedUserCardBill extends Entity {
    company: SpiderCardCompanySummary;
    amount: Amount;
    dueDate: Date;

    constructor(
        company: SpiderCardCompanySummary,
        amount: Amount,
        dueDate: Date
    ) {
        super();
        this.company = company;
        this.amount = amount;
        this.dueDate = dueDate;
    }
}
