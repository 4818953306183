import { Entity } from 'core/entity';
import { UserSecuritiesFirmAccountItem } from 'core/entity/personal-finance/securities-firm-account/item';

export class UserSecuritiesFirmAccountFundItem extends Entity implements UserSecuritiesFirmAccountItem {
    id: string;
    name: string;

    constructor(
        id: string,
        name: string
    ) {
        super();
        this.id = id;
        this.name = name;
    }
}
