import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';
import { SpiderCardCompanyRepositoryType } from 'core/repository/personal-finance/card-company';

export class GetSpiderCardCompanies extends UseCase<List<SpiderCardCompanySummary>> {
    onlyCodeConfigured: boolean = false;
    private repository: SpiderCardCompanyRepositoryType;

    constructor(repository: SpiderCardCompanyRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<SpiderCardCompanySummary>> {
        return this.repository.getAll(this.onlyCodeConfigured);
    }

    protected validate(): boolean {
        return true;
    }
}
