import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1KCBCreditThirdPartyView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h2>개인(신용)정보 제3자 제공에 관한 사항</h2>

      <h3>주식회사 레이니스트</h3>
            귀사의 뱅크샐러드 “모바일 어플리케이션 및 웹 사이트”(이하 “본건 서비스”) 이용과 관련하여 귀사가 본인의 개인(신용)정보를 제3자에게 제공하고자 하는 경우에는 「개인정보 보호법」, 「신용정보의 이용 및 보호에 관한 법률」 및 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에 따라 본인의 동의가 필요합니다. 이에 귀사가 다음과 같이 본인의 개인(신용)정보를 아래와 같이 제3자에게 제공하는 것에 동의합니다.

      <table>
        <colgroup>
          <col width='25%' />
          <col width='25%' />
          <col width='25%' />
          <col width='25%' />
        </colgroup>
        <tbody>
          <tr>
            <th>제공받는 자</th>
            <th>제공항목</th>
            <th>제공받는 자의<br/>개인(신용)정보 이용 목적</th>
            <th>보유·이용기간</th>
          </tr>
          <tr>
            <td>
              <div>코리아크레딧뷰로(주)<br/>(신용조회회사)</div>
            </td>
            <td>
              <ul>
                <li>이름, 생년월일, 성별, 이동전화번호, 내외국인구분, 중복가입확인정보(DI), 암호화된 동일인 식별정보(CI), 아이핀 번호(아이핀 이용 시)</li>
              </ul>
            </td>
            <td>
              <div>본인 식별을 통한 신용등급, 신용점수 등의 고객신용정보 확인</div>
            </td>
            <td>
              <div>뱅크샐러드 신용관리 서비스 이용을 위한 제공 동의 시 부터 해당 서비스 해지ㆍ취소 시 또는 회원탈퇴 시까지(관련법령에 따라 보존할 필요가 있는 경우는 해당 보존기간)</div>
            </td>
          </tr>
        </tbody>
      </table>
      <br/>
            위 개인(신용) 정보 제 3자 제공에 관한 동의는 본건 서비스 이용을 위하여 필수적이므로, 위 사항에 동의하셔야만 본건 서비스 이용이 가능합니다.<br/>
            귀사가 위와 같이 본인의 개인(신용)정보를 제3자에게 제공하는 것에 동의합니다.
    </div>
  </div>
);
