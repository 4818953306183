import { Entity } from 'core/entity';

export class InsuranceInformation extends Entity {
    caution: string;
    guaranteedDetail: string;
    paymentExample: string;
    refundExample: string;
    detail: string;
    planingLogic: string;

    constructor(
        caution: string,
        guaranteedDetail: string,
        paymentExample: string,
        refundExample: string,
        detail: string,
        planingLogic: string
    ) {
        super();
        this.caution = caution;
        this.guaranteedDetail = guaranteedDetail;
        this.paymentExample = paymentExample;
        this.refundExample = refundExample;
        this.detail = detail;
        this.planingLogic = planingLogic;
    }
}
