import {
    NetworkRequestMapper,
    NetworkResponseMapper
} from 'data/http/mapper';
import { CardBrand } from 'core/entity/card/brand';
import { lets } from 'utils/index';

export class CardBrandMapper implements
    NetworkResponseMapper<CardBrand>,
    NetworkRequestMapper<CardBrand>
{
    toJson(target: CardBrand): any {
        return lets(target, it => {
            switch (it.id) {
                case CardBrand.Key.LOCAL:
                    return '국내전용';
                case CardBrand.Key.VISA:
                    return 'VISA';
                case CardBrand.Key.MASTER:
                    return 'MASTER';
                case CardBrand.Key.MAESTRO:
                    return 'MAESTRO';
                case CardBrand.Key.AMEX:
                    return 'AMEX';
                case CardBrand.Key.UNION_PAY:
                    return 'UNION PAY';
                case CardBrand.Key.BC_LOCAL:
                    return 'BC';
                case CardBrand.Key.BC_GLOBAL:
                    return 'BC Global';
                case CardBrand.Key.URS:
                    return 'URS';
                case CardBrand.Key.JCB:
                    return 'JCB';
                case CardBrand.Key.W_JCB:
                    return 'W(JCB)';
                case CardBrand.Key.K_WORLD:
                    return 'K-World';
                case CardBrand.Key.CHAEUM:
                    return '채움';
                case CardBrand.Key.S_AND:
                    return 'S&';
                case CardBrand.Key.DINERS:
                    return 'DINERS';
                case CardBrand.Key.CIRRUS:
                    return 'CIRRUS';
                case CardBrand.Key.L_GLOBAL:
                    return 'L.GLOBAL';
                default:
                    return '국내전용';
            }
        })
    }

    fromJson(json: any): CardBrand {
        return new CardBrand(
            lets(json, it => {
                switch (it) {
                    case '국내전용':
                        return CardBrand.Key.LOCAL;
                    case 'VISA':
                        return CardBrand.Key.VISA;
                    case 'MASTER':
                        return CardBrand.Key.MASTER;
                    case 'MAESTRO':
                        return CardBrand.Key.MAESTRO;
                    case 'AMEX':
                        return CardBrand.Key.AMEX;
                    case 'UNION PAY':
                        return CardBrand.Key.UNION_PAY;
                    case 'BC':
                        return CardBrand.Key.BC_LOCAL;
                    case 'BC Global':
                        return CardBrand.Key.BC_GLOBAL;
                    case 'URS':
                        return CardBrand.Key.URS;
                    case 'JCB':
                        return CardBrand.Key.JCB;
                    case 'W(JCB)':
                        return CardBrand.Key.W_JCB;
                    case 'K-World':
                        return CardBrand.Key.K_WORLD;
                    case '채움':
                        return CardBrand.Key.CHAEUM;
                    case 'S&':
                        return CardBrand.Key.S_AND;
                    case 'DINERS':
                        return CardBrand.Key.DINERS;
                    case 'CIRRUS':
                        return CardBrand.Key.CIRRUS;
                    case 'L.GLOBAL':
                        return CardBrand.Key.L_GLOBAL;
                    default:
                        return CardBrand.Key.LOCAL;
                }
            }),
            json
        )
    }

}
