import { Entity } from 'core/entity';
import { UserCreditScoreOrganization } from 'core/entity/personal-credit/organization';

export class UserCreditScoreInquiryRecord extends Entity {
    organization: UserCreditScoreOrganization;
    purpose: string;
    inquiredAt: Date;

    constructor(
        organization: UserCreditScoreOrganization,
        purpose: string,
        inquiredAt: Date
    ) {
        super();
        this.organization = organization;
        this.purpose = purpose;
        this.inquiredAt = inquiredAt;
    }
}
