import { NetworkResponseMapper } from 'data/http/mapper';
import { InsuranceInformation } from 'core/entity/insurance/information';

export class InsuranceInformationMapper implements NetworkResponseMapper<InsuranceInformation> {
    fromJson(json: any): InsuranceInformation {
        return new InsuranceInformation(
            json.caution,
            json.guaranteed_detail,
            json.payment_example,
            json.refund_example,
            json.detail,
            json.how_to_calculate
        )
    }
}
