import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceLoanData } from 'core/entity/personal-finance/advice/content-data/loan';
import { AmountPeriodSnapshotMapper } from 'data/http/mapper/personal-finance/advice/amount-period-snapshot';
import { optional } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceLoanDataMapper implements NetworkResponseMapper<AdviceLoanData> {
    fromJson(json: any): AdviceLoanData {
        return new AdviceLoanData(
            json.loan_history.map(new AmountPeriodSnapshotMapper().fromJson).toList(),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
