import { trackClickFooter } from 'presentation/module/analytics/events/trackers/footer';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface Props {
  children: React.ReactNode;
}

const BI_TITLE = '뱅크샐러드 메인';

export const AppBadgeArea = ({ children }: Props) => (
  <section className={ styles.container }>
    <a href={ BANKSALAD_SITEMAP.MAIN } onClick={ () => {
      trackClickFooter('뱅크샐러드 로고');
    } }>
      <div className={ styles.bi } title={ BI_TITLE }/>
    </a>
    { children }
  </section>
);
