import { List } from 'immutable';
import { UseCase } from 'core/use-case';
import { CardRepositoryType } from 'core/repository/card';
import { Card } from 'core/entity/card';
import { Page } from 'core/entity/page';
import { CardSearchSpec } from 'core/entity/card/search-spec';
import { apply } from 'utils/index';
import { CardFilter } from 'core/entity/card/filter';
import { CardPriority } from 'core/entity/card/priority';
import { Observable } from 'rxjs/Rx';

export class SearchCards extends UseCase<List<Card>> {
    word?: string = null;
    private repository: CardRepositoryType;

    constructor(repository: CardRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<Card>> {
        return this.repository.search(
            new CardSearchSpec(
                apply(new CardFilter(), it => {
                    it.name = this.word;
                }),
                new CardPriority(
                    CardPriority.Criteria.NONE,
                    false
                )
            ),
            new Page(1000, 0),
            false
        ).map(it => it.cards);
    }

    protected validate(): boolean {
        return this.word !== null;
    }
}
