import { Entity } from 'core/entity';

export class UserAccount extends Entity {
    email: string;
    emailVerified: boolean;
    passwordConfigured: boolean;
    googleId?: string;
    facebookId?: string;
    twitterId?: string;

    constructor(
        email: string,
        emailVerified: boolean,
        passwordConfigured: boolean,
        googleId: string = null,
        facebookId: string = null,
        twitterId: string = null
    ) {
        super();
        this.email = email;
        this.emailVerified = emailVerified;
        this.passwordConfigured = passwordConfigured;
        this.googleId = googleId;
        this.facebookId = facebookId;
        this.twitterId = twitterId;
    }
}
