import { SummarizedUserBankProducts } from 'core/entity/personal-finance/summarized-products/bank';

declare module 'core/entity/personal-finance/summarized-products/bank' {
    export interface SummarizedUserBankProducts {
        isEmpty(): boolean;
    }
}

SummarizedUserBankProducts.prototype.isEmpty = function() {
    return (
        this.accounts.isEmpty() &&
        this.funds.isEmpty() &&
        this.loans.isEmpty()
    )
};
