import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentTagSummary } from 'core/entity/content/tag/summary';
import { ContentTagCategoryMapper } from 'data/http/mapper/content/tag/category';

export class ContentTagSummaryMapper implements NetworkResponseMapper<ContentTagSummary> {
    fromJson(json: any): ContentTagSummary {
        return new ContentTagSummary(
            json.id,
            json.name,
            json.image_url,
            new ContentTagCategoryMapper().fromJson(json.category)
        )
    }
}
