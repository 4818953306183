import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { List } from 'immutable';
import { AmountPeriodSnapshot } from 'core/entity/personal-finance/advice/amount-period-snapshot';
import { Amount } from 'core/entity/amount';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';

export class AdviceExpenseSummaryData extends Entity implements AdviceContentData {
    period: AdviceExpenseSummaryData.Period;
    expenseAmountHistory: List<AmountPeriodSnapshot>;
    averageExpenseAmount?: Amount;
    message?: AdviceMessage;

    constructor(
        period: AdviceExpenseSummaryData.Period,
        expenseAmountHistory: List<AmountPeriodSnapshot>,
        averageExpenseAmount: Amount = null,
        message: AdviceMessage = null
    ) {
        super();
        this.period = period;
        this.expenseAmountHistory = expenseAmountHistory;
        this.averageExpenseAmount = averageExpenseAmount;
        this.message = message;
    }
}

export module AdviceExpenseSummaryData {
    export enum Period {
        DAILY = 'daily',
        WEEKLY = 'weekly'
    }
}
