import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanPrimeInterestNodeMapper } from 'data/http/mapper/loan/prime-interest-node';
import { LoanPrimeInterest } from 'core/entity/loan/prime-interest';
import { LoanInterestValueMapper } from 'data/http/mapper/loan/interest-value';

export class LoanPrimeInterestMapper implements NetworkResponseMapper<LoanPrimeInterest> {
    fromJson(json: any): LoanPrimeInterest {
        return new LoanPrimeInterest(
            List(json.nodes.map((it: any) =>
                new LoanPrimeInterestNodeMapper().fromJson(it)
            )),
            new LoanInterestValueMapper().fromJson(json.interest)
        )
    }

}
