import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV5TermsView } from 'presentation/routes/policies/v5/terms/view';

export const PoliciesV5TermsRoute = () => (
    <>
        <MetaHelmet meta={ META_SET.TERMS }/>
        <PoliciesV5TermsView />
    </>
);
