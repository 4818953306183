import { NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoanRecommendedResult } from 'core/entity/personalized-recommend/loan/recommended-result';
import { List } from 'immutable';
import { PersonalizedLoanMapper } from 'data/http/mapper/personalized-recommend/loan';

export class PersonalizedLoanRecommendedResultMapper
    implements NetworkResponseMapper<PersonalizedLoanRecommendedResult> {
    fromJson(json: any): PersonalizedLoanRecommendedResult {
        return new PersonalizedLoanRecommendedResult(
            List(json.satisfied_limitation_loans.map((loan: any) =>
                new PersonalizedLoanMapper().fromJson(loan)
            )),
            List(json.unsatisfied_limitation_loans.map((loan: any) =>
                new PersonalizedLoanMapper().fromJson(loan)
            )),
            json.total_size,
            json.last
        );
    }
}
