import { Entity } from 'core/entity';

export class IncomeTaxDeductionConfiguration extends Entity {
    applied: boolean;
    type: IncomeTaxDeductionConfiguration.Type;

    constructor(
        applied: boolean,
        type: IncomeTaxDeductionConfiguration.Type
    ) {
        super();
        this.applied = applied;
        this.type = type;
    }
}

export module IncomeTaxDeductionConfiguration {
    export enum Type {
        NORMAL = 'normal',
        PUBLIC_TRANSPORTATION = 'public_transportation',
        TRADITIONAL_MARKET = 'traditional_market'
    }
}
