import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { FinanceSectorSummary } from 'core/entity/finance-sector/summary';
import { HttpProvider } from 'data/http';
import { BanksaladFinanceSectors } from 'data/http/api/gateway/v2/banksalad-finance-sectors/sectors-data';
import { FinanceSectorMapper } from 'data/http/mapper/finance-sector';
import { FinanceSectorSummaryMapper } from 'data/http/mapper/finance-sector/summary';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class FinanceSectorsApiProvider extends HttpProvider {
  getAll(): Observable<List<FinanceSectorSummary>> {
    return Observable
      .of(BanksaladFinanceSectors)
      .map((result: any) =>
        List(result.data.map((sector: any) =>
          new FinanceSectorSummaryMapper().fromJson(sector)
        ))
      );
  }

  get(id: FinanceSectorID): Observable<FinanceSector> {
    return Observable
      .of(BanksaladFinanceSectors.data.find(sector => sector.id === id))
      .map((result: any) =>
        new FinanceSectorMapper().fromJson(result)
      );
  }
}
