import { Observable } from 'rxjs/Rx';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { UserCreditScoreJointSuretyStatusRepositoryType } from 'core/repository/personal-credit/joint-surety-status';
import { UserCreditJointSuretiesStatusFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/joint-surety-status';
import { UserCreditScoreJointSuretyStatus } from 'core/entity/personal-credit/joint-surety-status';

export class CreditJointSuretiesStatusFromKCBRepository implements UserCreditScoreJointSuretyStatusRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserCreditJointSuretiesStatusFromKCBApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserCreditJointSuretiesStatusFromKCBApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    get(): Observable<UserCreditScoreJointSuretyStatus> {
        return this.tokenStorage.get().flatMap(it =>
            this.api.get(it)
        );
    }

}
