import { ChargedTransactionInstallmentConfiguration } from 'core/entity/personal-finance/charged-transaction/installment-configuration';
import { NetworkResponseMapper } from 'data/http/mapper';

export class ChargedTransactionInstallmentConfigurationMapper implements NetworkResponseMapper<ChargedTransactionInstallmentConfiguration> {
    fromJson(json: any): ChargedTransactionInstallmentConfiguration {
        return new ChargedTransactionInstallmentConfiguration(
            json.round,
            json.month
        )
    }

}
