import { Observable } from 'rxjs/Rx';
import { PersonalizedInsuranceRepositoryType } from 'core/repository/personalized-recommend/insurance';
import { PersonalizedInsuranceApiProvider } from 'data/http/api/base/me/insurance';
import { PersonalizedInsuranceRecommendSpec } from 'core/entity/personalized-recommend/insurance/recommend-spec';
import { PersonalizedInsuranceRecommendedResult } from 'core/entity/personalized-recommend/insurance/recommend-result';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class PersonalizedInsuranceRepository implements PersonalizedInsuranceRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: PersonalizedInsuranceApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: PersonalizedInsuranceApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    recommend = (
        spec: PersonalizedInsuranceRecommendSpec
    ): Observable<PersonalizedInsuranceRecommendedResult> => {
        return this.tokenStorage
            .get()
            .flatMap(it => this.api.recommend(it, spec));
    };
}
