import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentAuthorSummary } from 'core/entity/content/author/summary';
import { ContentAuthorProfileSummaryMapper } from 'data/http/mapper/content/author/profile/summary';

export class ContentAuthorSummaryMapper implements NetworkResponseMapper<ContentAuthorSummary> {
    fromJson(json: any): ContentAuthorSummary {
        return new ContentAuthorSummary(
            json.id,
            new ContentAuthorProfileSummaryMapper().fromJson(json.profile)
        )
    }
}
