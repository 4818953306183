import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { EventSwitchInsurance } from "presentation/routes/app/event/switch-insurance/view";

export const SwitchInsuranceRoute = ({match}: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}` } component={ EventSwitchInsurance }/>
    </Switch>
);

