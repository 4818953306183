import { Entity } from 'core/entity';

export class PaymentCycle extends Entity {
    constructor(
        public round: number,
        public unit: PaymentCycle.Unit
    ) {
        super();
    }
}

export module PaymentCycle {
    export enum Unit {
        DAILY = 'daily',
        MONTHLY = 'monthly',
        YEARLY = 'yearly'
    }
}
