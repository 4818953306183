import { Entity } from 'core/entity';
import { CardPriority } from 'core/entity/card/priority';
import { CardFilter } from 'core/entity/card/filter';

export class CardSearchSpec extends Entity {
    filter: CardFilter;
    priority: CardPriority;

    constructor(
        filter: CardFilter,
        priority: CardPriority
    ) {
        super();
        this.filter = filter;
        this.priority = priority;
    }
}
