import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardPremiumVoucherBenefit } from 'core/entity/card/premium-voucher-benefit';

export class CardPremiumVoucher extends Entity {
    title: string;
    benefits: List<CardPremiumVoucherBenefit>;
    additionalAnnualCost: number;

    constructor(
        title: string,
        benefits: List<CardPremiumVoucherBenefit>,
        additionalAnnualCost: number
    ) {
        super();
        this.title = title;
        this.benefits = benefits;
        this.additionalAnnualCost = additionalAnnualCost;
    }
}
