import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanOccupationalGroup } from 'core/entity/loan/occupational-group';

export class LoanOccupationalGroupMapper implements BrowserStorageMapper<LoanOccupationalGroup> {
    toJson(entity: LoanOccupationalGroup): any {
        return {
            id: entity.id,
            name: entity.name
        };
    }

    toEntity(value: any): LoanOccupationalGroup {
        return new LoanOccupationalGroup(
            value.id,
            value.name
        );
    }

}
