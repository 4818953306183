import { trackClickFooter } from 'presentation/module/analytics/events/trackers/footer';
import { OUTBOUND_LINK } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface Props {
  className?: string;
}

enum APP_DOWNLOAD_TEXT {
  IPHONE = '아이폰 앱 다운로드',
  ANDROID = '안드로이드 앱 다운로드',
}

export const AppDownloadIconArea = ({
  className = '',
}: Props) => {

  return (
    <ul className={ className }>
      <div className={ styles.app }>
        <a
          onClick={ () => {
            trackClickFooter('app store');
          } }
          target='_blank'
          rel='noopener noreferrer'
          href={ OUTBOUND_LINK.FOOTER_UTM.APPLE_STORE }
        >
          <div className={ styles.apple } title={ APP_DOWNLOAD_TEXT.IPHONE } />
        </a>
        <a
          onClick={ () => {
            trackClickFooter('play store');
          } }
          target='_blank'
          rel='noopener noreferrer'
          href={ OUTBOUND_LINK.FOOTER_UTM.GOOGLE_PLAY_STORE }
        >
          <div className={ styles.google } title={ APP_DOWNLOAD_TEXT.ANDROID } />
        </a>
      </div>
    </ul>
  );
};
