import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreInquiryRecordStatus } from 'core/entity/personal-credit/inquery-record-status';
import { UserCreditScoreInquiryRecordMapper } from 'data/http/mapper/personal-credit/inquiry-record';

export class UserCreditScoreInquiryRecordStatusMapper implements NetworkResponseMapper<UserCreditScoreInquiryRecordStatus> {
    private updatedAt: Date;
    private changed: boolean;

    constructor(
        updatedAt: Date,
        changed: boolean
    ) {
        this.updatedAt = updatedAt;
        this.changed = changed;
    }

    fromJson(json: any): UserCreditScoreInquiryRecordStatus {
        return new UserCreditScoreInquiryRecordStatus(
            json.map(new UserCreditScoreInquiryRecordMapper().fromJson).toList(),
            this.updatedAt,
            this.changed
        )
    }

}
