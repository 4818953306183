import { Entity } from "core/entity";

export class QuestionCategory extends Entity {
  constructor(
    public nameKo: string,
    public nameEn: string,
    public id: number,
    public url: string,
  ) {
    super();
  }
}
