import { Entity } from 'core/entity';
import { ContentAuthorSummary } from 'core/entity/content/author/summary';
import { ContentSummary } from 'core/entity/content/summary';

export class ContentColumnSummary extends Entity implements ContentSummary {
    id: string;
    title: string;
    description: string;
    author: ContentAuthorSummary;
    thumbnailImageUrl: string;
    createdAt: Date;

    constructor(
      id: string,
      title: string,
      description: string,
      author: ContentAuthorSummary,
      thumbnailImageUrl: string,
      createdAt: Date
    ) {
      super();
      this.id = id;
      this.title = title;
      this.description = description;
      this.author = author;
      this.thumbnailImageUrl = thumbnailImageUrl;
      this.createdAt = createdAt;
    }
}
