import './charged-transaction';
import './product-configuration';
import './summarized-products';
import './summarized-user-bank-products';
import './summarized-user-card-company-products';
import './summarized-user-securities-firm-products';
import './transaction-category';
import './transaction-category-summary';
import './transaction-create-spec';
import './transaction-type';
import './user-bank-account';
import './user-card';
import './user-cash-account';
import './user-default-product-configuration';
import './user-fund';
import './user-loan';
import './user-product-configuration';
import './user-securities-firm-account';
import './user-stock';
