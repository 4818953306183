import { NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { Color } from 'presentation/module/color';
import { InsurancePromotion } from 'core/entity/insurance/promotion';

export class InsurancePromotionMapper implements NetworkResponseMapper<InsurancePromotion> {
    fromJson(json: any): InsurancePromotion {
        return new InsurancePromotion(
            json.title,
            lets(json.highlight_color, it => {
                switch (it) {
                    case 'romaine200':
                        return Color.v2romaine200;
                    case 'paprika250':
                        return Color.paprika250;
                    default:
                        return Color.paprika250;
                }
            })
        )
    }
}
