import { Entity } from 'core/entity';
import { LoanPrimeInterestNodeStatus } from 'core/entity/loan/prime-interest-node-status';
import { List } from 'immutable';

export class LoanPrimeInterestNode extends Entity {
    id: number;
    priority: number;
    level: number;
    description: string;
    descriptionWarning: string;
    interestDescription: string;
    children: List<LoanPrimeInterestNode>;
    status?: LoanPrimeInterestNodeStatus;

    constructor(
        id: number,
        priority: number,
        level: number,
        description: string,
        descriptionWarning: string,
        interestDescription: string,
        children: List<LoanPrimeInterestNode>,
        status: LoanPrimeInterestNodeStatus = null
    ) {
        super();
        this.id = id;
        this.priority = priority;
        this.level = level;
        this.description = description;
        this.descriptionWarning = descriptionWarning;
        this.status = status;
        this.interestDescription = interestDescription;
        this.children = children;
        this.status = status;
    }
}
