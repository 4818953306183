import { List } from 'immutable';
import { Question } from 'presentation/view-model/question';
import { lets } from 'utils/index';
import { CardBenefitType } from 'core/entity/card/benefit-type';

export class CardQuestionBenefitType implements Question {
    topic = '카드용도';
    essential = true;
    clearable = false;
    value?: CardQuestionBenefitType.Type;

    constructor(type: CardQuestionBenefitType.Type = null) {
        this.value = type;
    }

    toValue(): string {
        return lets(this.value, it => {
            switch (it) {
                case CardQuestionBenefitType.Type.DISCOUNT:
                    return '할인/적립';
                case CardQuestionBenefitType.Type.MILEAGE:
                    return '항공마일리지';
                default:
                    return '';
            }
        })
    }

    isValid(): boolean {
        return this.value !== null;
    }

    clear(): CardQuestionBenefitType {
        return new CardQuestionBenefitType();
    }

    toBenefitTypes(): List<CardBenefitType> {
        switch (this.value) {
            case CardQuestionBenefitType.Type.DISCOUNT:
                return List([
                    CardBenefitType.DISCOUNT,
                    CardBenefitType.POINT
                ]);
            case CardQuestionBenefitType.Type.MILEAGE:
                return List([
                    CardBenefitType.MILEAGE_AA,
                    CardBenefitType.MILEAGE_KA,
                    CardBenefitType.MILEAGE_OTHERS
                ]);
            default:
                return List([
                    CardBenefitType.ALL
                ]);
        }
    }
}

export module CardQuestionBenefitType {
    export enum Type {
        DISCOUNT, MILEAGE
    }
}
