import React from 'react';

import { HorizontalItem } from 'presentation/components/horizontal-list/base/item';
import { lets } from 'utils/index';

import styles from './styles.pcss';
const DEFAULT_SPLIT_COUNT = 4;

interface Props {
    splitCount?: number;
    itemMinWidth?: number;
    children: any;
}

interface State {
    enabledLeftArrow: boolean;
    enabledRightArrow: boolean;
}

export class HorizontalList extends React.Component<Props, State> {
    state = {
        enabledLeftArrow: false,
        enabledRightArrow: true
    };

    private listElement: HTMLUListElement;

    render() {
        const { splitCount, itemMinWidth, children } = this.props;
        const { enabledLeftArrow, enabledRightArrow } = this.state;
        const leftArrowStyle = enabledLeftArrow ? styles.leftArrow : styles.disabledLeftArrow;
        const rightArrowStyle = enabledRightArrow ? styles.rightArrow : styles.disabledRightArrow;

        const count = splitCount || DEFAULT_SPLIT_COUNT;
        const width = count > 1 ? `calc((100% - 24px * ${count - 1}) / ${count})` : '100%';
        const minWidth = itemMinWidth ? `${itemMinWidth}px` : 'none';
        const items = children instanceof Array ?
            (children as Array<any>)
                .filter(it => it.type === HorizontalItem) :
            lets(children as any, it =>
                it.type === HorizontalItem ? [it] : []
            );

        return (
            <div className={ styles.wrap }>
                <ul
                    ref={ e => this.listElement = e }
                    className={ styles.list }
                    onScroll={ this.onScroll }
                >
                {
                    items.map((it, i) =>
                        <li
                            key={ `h-list-${i}` }
                            style={{ width, minWidth }}
                        >
                            { it }
                        </li>
                    )
                }
                </ul>
                <button className={ leftArrowStyle } onClick={ this.onClickLeftArrow }>이전</button>
                <button className={ rightArrowStyle } onClick={ this.onClickRightArrow }>다음</button>
            </div>
        )
    }

    private onScroll = (e: React.UIEvent<HTMLUListElement>) => {
        const { scrollLeft, clientWidth, scrollWidth } = e.currentTarget;
        let enabledLeftArrow = this.state.enabledLeftArrow;
        let enabledRightArrow = this.state.enabledLeftArrow;

        if (scrollLeft === 0) {
            enabledLeftArrow = false;
            enabledRightArrow = true;
        } else if (scrollLeft + clientWidth === scrollWidth) {
            enabledLeftArrow = true;
            enabledRightArrow = false;
        } else {
            enabledLeftArrow = true;
            enabledRightArrow = true;
        }

        if (
            this.state.enabledLeftArrow !== enabledLeftArrow ||
            this.state.enabledRightArrow !== enabledRightArrow
        ) {
            this.setState({
                enabledLeftArrow,
                enabledRightArrow
            })
        }
    };

    private onClickLeftArrow = () => {
        this.scrollList(-1);
    };

    private onClickRightArrow = () => {
        this.scrollList(1);
    };

    private scrollList = (direction: 1 | -1) => {
        const { splitCount, itemMinWidth } = this.props;
        const count = splitCount || DEFAULT_SPLIT_COUNT;
        const movement = Math.max(this.listElement.clientWidth / count, itemMinWidth);

        this.listElement.scrollLeft += movement * direction;
    }
}
