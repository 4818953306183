import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { List } from 'immutable';
import { AdviceCardCompanyInstallmentSnapshot } from 'core/entity/personal-finance/advice/card-company-installment-snapshot';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';

export class AdviceCardInstallmentSummaryData extends Entity implements AdviceContentData {
    data: List<AdviceCardCompanyInstallmentSnapshot>;
    baseDatetime: Date;
    message?: AdviceMessage;

    constructor(
        data: List<AdviceCardCompanyInstallmentSnapshot>,
        baseDatetime: Date,
        message: AdviceMessage = null
    ) {
        super();
        this.data = data;
        this.baseDatetime = baseDatetime;
        this.message = message;
    }
}
