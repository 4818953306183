import Cookies from 'universal-cookie';

import { makeUUID } from "./make-uuid";

export const getWebDeviceId = () => {
  const cookies = new Cookies(document.cookie);

  const webDeviceId = cookies.get('webDeviceId') as string | undefined;
  // 크롬기준 1년이상으로 maxAge가 늘어나지 않습니다.
  const maxAgeInSeconds = 365 * 24 * 60 * 60; // 1년을 초 단위로 계산

  if (webDeviceId !== undefined) {
    // maxAge 갱신
    cookies.set('webDeviceId', webDeviceId, {
      maxAge: maxAgeInSeconds,
      path: '/',
    });

    return webDeviceId;
  }

  const uuidV4 = makeUUID();

  cookies.set('webDeviceId', uuidV4, {
    maxAge: maxAgeInSeconds,
    path: '/',
  });

  return uuidV4;
};
