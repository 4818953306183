import { TransactionType } from 'core/entity/personal-finance/transaction/type';
import { NetworkResponseMapper } from 'data/http/mapper';

export class TransactionTypeMapper implements
    NetworkResponseMapper<TransactionType>,
    NetworkResponseMapper<TransactionType>
{
    toJson(target: TransactionType): any {
        switch (target) {
            case TransactionType.EXPENSE:
                return 'expense';
            case TransactionType.INCOME:
                return 'income';
            case TransactionType.LOG:
                return 'log';
            default:
                return null;
        }
    }

    fromJson(json: any): TransactionType {
        switch (json) {
            case 'expense':
                return TransactionType.EXPENSE;
            case 'income':
                return TransactionType.INCOME;
            case 'log':
                return TransactionType.LOG;
            default:
                return null;
        }
    }

}
