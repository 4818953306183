import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, optional, toDateString } from 'utils/index';
import { UserStockCreateSpec } from 'core/entity/personal-finance/stock/create-spec';

export class UserStockCreateSpecMapper implements NetworkRequestMapper<UserStockCreateSpec> {
    toJson(target: UserStockCreateSpec): any {
        return compactObject({
            securities_firm_id: target.securitiesFirm.id,
            nickname: target.nickname,
            average_purchase_amount: target.averagePurchaseAmount,
            quantity: target.quantity,
            hidden: target.hidden,
            description: optional(target.description),
            purchased_at: optional(target.purchasedAt, it => toDateString(it, '-'))
        })
    }
}
