import { List } from 'immutable';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSubSector } from 'core/entity/finance-sector/sub';
import { FinanceSubSectorContentItem } from 'core/entity/finance-sector/item/content';
import { ContentColumnSummary } from 'core/entity/content/column/summary';
import { EMERGENCY_AUTHORS } from 'presentation/module/emergency-contents';
import { FinanceSectorID } from 'core/entity/finance-sector/id';

export const EMERGENCY_BANK_SECTOR = new FinanceSector(
    FinanceSectorID.BANK,
    '은행',
    new FinanceSubSector(
        '상품 추천',
        List([
            new FinanceSubSectorContentItem(
                '2017 BEST 적금',
                new ContentColumnSummary(
                    '293',
                    '2017 BEST 적금',
                    '95개 은행의 565개 적금 상품을 비교분석해 BEST적금을 선정하였습니다. 더 높은 금리를 적용 받으면서 목돈을 모을 수 있는 BEST 적금 상품을 소개합니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fbestsaving.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '2017 BEST 예금',
                new ContentColumnSummary(
                    '295',
                    '2017 BEST 예금',
                    '예금은 적금을 통해 모았던 목돈을 굴릴 수 있는 가장 안정적인 방법입니다. 매달 꾸준히 납입을 하는 적금과 달리, 가입 시 한 번에 목돈을 예치하여 이자를 받는 상품이죠. 뱅크샐러드는 95개 은행의 457개 예금 상품을 비교분석해 가장 많은 이자를 받을 수 있는 BEST예금상품을 선정하였습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fmoney-2180338_1920.jpg',
                    new Date()
                )
            )
        ])
    ),
    new FinanceSubSector(
        '상품 리뷰',
        List([
            new FinanceSubSectorContentItem(
                '2017 수시입출금 계좌 BEST',
                new ContentColumnSummary(
                    '308',
                    '2017 수시입출금 계좌 BEST',
                    '수시입출금 계좌는 자유롭게 입출금이 가능한 금융상품입니다. 흔히 은행에서 “통장 만들어 주세요”라고 하면 개설해주는 계좌가 대부분 수시입출금 계좌입니다. 워낙 입출금이 잦은 계좌라 혜택이나 금리를 다른 상품에 비해 상대적으로 신경을 덜 쓰는 경우가 많습니다. 그래서 뱅크샐러드는 소비자에게 더 좋은 상품이 없는지 직접 조사, 2017 BEST를 선정했습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fbookkeeping-615384_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '직장인을 위한 급여통장 추천',
                new ContentColumnSummary(
                    '155',
                    '직장인을 위한 급여통장 추천',
                    '할인율, 적립률 뿐만 아니라 혜택 조건과 한도까지 고려하여 2016년 정말 혜택이 좋았던 BEST 신용카드를 한곳에 모아보았습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content/upload/images/shutterstock_268436969.jpg',
                    new Date()
                )
            )
        ])
    ),
    new FinanceSubSector(
        '상품 매거진',
        List([
            new FinanceSubSectorContentItem(
                '지출관리 고수가 되는 통장 활용법',
                new ContentColumnSummary(
                    '211',
                    '지출관리 고수가 되는 통장 활용법',
                    '‘지출 계획 지키기가 왜 이렇게 어렵지?’ 우리가 매월 지출 내용을 파악하며 하는 불평이다. 가계부를 정리할 때는 왜 이렇게 많이 썼나 머리카락이 쭈뼛하지만, 고민도 그때 뿐이다.',
                    EMERGENCY_AUTHORS.KOREA_FINANCIAL_INVESTORS_PROTECTION_FOUNDATION,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2F7658298768_e4c2c2635e_b.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '재테크의 시작, 통장쪼개기 하는 법',
                new ContentColumnSummary(
                    '4',
                    '재테크의 시작, 통장쪼개기 하는 법',
                    '공부 혹은 일을 하다가 12시가 되어 \'밥 먹으러 가자\'는 소릴 들으면 나도 모르게 배가 고픕니다. 점심시간이라고 학교에서, 직장에서 정했기 때문입니다. \'12시=밥 먹는 시간\'이라는 연상회로가 뇌에서 형성된 덕분입니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcover_004_mini_800x533.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '은행거래 혜택 100% 활용하는 법!',
                new ContentColumnSummary(
                    '41',
                    '은행거래 혜택 100% 활용하는 법!',
                    '사회초년생 정훈씨는 목돈마련을 위해 저축을 결심했습니다. 요즘은 저금리시대라 은행의 예·적금 이자율이 워낙 낮기 때문에 은행이나 예·적금 상품을 고른다는 것이 큰 의미가 없다고 보고 점심시간에 직장에서 가까운 A은행 점포에 들러 월 50만원씩 납입하는 만기 3년짜리 정기 적금에 가입하였습니다.   3년 후, 정훈씨의 적금만기일이 되어 적금을 찾고 보니, 비슷한 시기에 비슷한 금액을 저축한 직장동료 준원씨에 비해 이자수익이 20만원 이상 차이가 났습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2FHand_Putting_Deposit_Into_Piggy_Bank_%285737295175%29.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '예적금 수익률을 높이는 8가지 팁',
                new ContentColumnSummary(
                    '97',
                    '예적금 수익률을 높이는 8가지 팁',
                    '할인율, 적립률 뿐만 아니라 혜택 조건과 한도까지 고려하여 2016년 정말 혜택이 좋았던 BEST 신용카드를 한곳에 모아보았습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content/upload/images/shutterstock_268436969.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '예적금 관리를 도와주는 서비스 총정리',
                new ContentColumnSummary(
                    '134',
                    '예적금 관리를 도와주는 서비스 총정리',
                    '예⋅적금 상품을 관리하다 보면 가끔 고민이 되는 순간이 찾아옵니다.   \'만기일에 일이 생겨서 예금을 찾으러 가지 못 할 것 같은데, 어떡하지?\'  \'목돈이 조금 필요한데, 내가 갖고있는 예금을 해지해야 할까?\'',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2FDon%27t_worry.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '나에게 맞는 예적금을 고르는 법',
                new ContentColumnSummary(
                    '320',
                    '나에게 맞는 예적금을 고르는 법',
                    '결혼, 전세자금, 내집마련, 자녀육아, 노후설계 의 공통점은 바로 ‘목돈’ 입니다. 살면서 목돈은 중요한 역할을 하기 때문에 우리는 목돈을 준비하려고 합니다. 그리고 이런 목돈이라는 공통된 목적을 갖고 있는 가장 대표적인 금융 상품이 바로 예·적금 이죠. 적금으로 목돈을 만들고 예금으로 목돈을 운용해서 더 큰 목돈을 만드는 것 입니다. 그렇다면 예·적금을 고를 때 고려해야 될 점은 무엇일까요? 뱅크샐러드가 예·적금 선택 시 고려사항에 대해 알아봤습니다.',
                    EMERGENCY_AUTHORS.YOON_SUNG_AE,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fconsultant-779590_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '아차! 돈을 잘못 보낸 순간 대처법',
                new ContentColumnSummary(
                    '34',
                    '아차! 돈을 잘못 보낸 순간 대처법',
                    '# 휴가철을 맞아 가족들과 여행을 떠난 지훈이. 신나는 마음으로 가족들과 휴가지로 출발하였는데, 갑자기 예약한 숙박업체로부터 전화가왔습니다. 숙박비가 입금되지 않았다는 것입니다. 숙박비를 엉뚱한 곳에 잘못 보냈다는 사실을 알게 된 지훈이는, 잘못 보낸 돈을 어떻게 돌려받을 수 있을지 막막한 상황입니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fman-1246277_1280.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '주택청약종합저축 A to Z',
                new ContentColumnSummary(
                    '42',
                    '주택청약종합저축 A to Z',
                    '2016년 8월 말 기준, 전체 가입자 2,092만 명.  1순위 1,217만 명.  바로 청약통장 가입자 수이다.  얼추 대한민국 국민의 절반 정도는 갖고 있다는 뜻이다. 이렇게 많은 사람이 가입했다면 당연히 꼭 필요하다는 의미일 수도 있는데, 이 많은 사람들이 과연 청약통장에 대해 얼마나 알고 있을까?',
                    EMERGENCY_AUTHORS.KIM_HYUN_WOO,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-cityscape-919050_1920_mini_800x400.jpg',
                    new Date()
                )
            )
        ])
    )
);
