import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreOrganization } from 'core/entity/personal-credit/organization';

export class UserCreditScoreOrganizationMapper implements NetworkResponseMapper<UserCreditScoreOrganization> {
    fromJson(json: any): UserCreditScoreOrganization {
        return new UserCreditScoreOrganization(
            json.name
        );
    }

}
