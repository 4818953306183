import React from 'react';

import { toCurrency } from 'presentation/module/sugar';
import { lets, uniqueKey } from 'utils/index';
import { CardInfoProfitsModel } from 'presentation/components/info/card/profits/model';
import { ToolTip } from 'presentation/components/tooltip';
import { FetchEmpty, FetchEmptyPaddingSize } from 'presentation/components/fetch-state/empty';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, GA_EVENT_VALUE, sendGAEvent } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    data: CardInfoProfitsModel
}

export const CardInfoProfits = (props: Props) => {
    const { data } = props;
    const {
        annualCostWithPromotion,
        maximumAnnualCost,
        noBenefitSpendings,
        profits,
        summary,
        forDiscount,
        spendingCount,
        inPromotion
    } = data;
    const totalDiscount = summary.discount + summary.point;
    const totalBenefit = (
        forDiscount ?
            summary.discount + summary.point - Math.trunc(annualCostWithPromotion / 12) :
            summary.mileage
    );
    const promotionAnnualCost = Math.max(maximumAnnualCost - annualCostWithPromotion, 0);
    const toolTipMessage = inPromotion ?
        `<strong style="color:#3BCCC5;font-weight:bold;">연회비 이벤트 진행중</strong><br/>첫 연회비 ${toCurrency(maximumAnnualCost)}원에 ${toCurrency(promotionAnnualCost)}원 지원<br/>고객부담 연회비 ${toCurrency(annualCostWithPromotion)}원의 1개월 분인 ${toCurrency(Math.trunc(annualCostWithPromotion / 12))}원 차감` :
        `연회비 ${toCurrency(annualCostWithPromotion)}원의 1개월 분인 ${toCurrency(Math.trunc(annualCostWithPromotion / 12))}원 차감`;
    const message = (
        forDiscount ? (
            totalBenefit > 0 ? (
                <span>
                    카드만 바꿔도,<br/>
                    연간 <b>약 { toCurrency(totalBenefit * 12) }원의 혜택</b>을 받으시겠네요!
                </span>
            ) : totalBenefit === 0 ?
                <span>연회비와 혜택금액이 같네요.<br/>더 좋은 카드가 있지 않을까요?</span> :
                <span>연회비가 혜택금액보다 더 크네요.<br/>다른 카드를 찾아보세요!</span>
        ) : lets(totalBenefit, it => {
            let result = '';
            const travel = [
                { place: '브라질', amount: 1000000 },
                { place: '프랑스', amount: 70000 },
                { place: '푸켓', amount: 40000 },
                { place: '일본', amount: 30000 },
                { place: '제주도', amount: 10000 }
            ];

            [6, 12].some(month => {
                const target = travel.find(item => item.amount < it * month);
                if (target) {
                    result = `${month}개월 후에는 ${target.place}에 갈 수 있겠어요!`;
                    return false;
                }
            });
            return result;
        })
    );

    return (
        <div>
            <section className={ styles.summary }>
                <span className={ styles.summaryCalculation }>
                    {
                        forDiscount ?
                            `월 혜택 ${toCurrency(totalDiscount)}원에서 연회비 차감후` :
                            '총 혜택'
                    }
                    {
                        forDiscount && (
                            <ToolTip message={ toolTipMessage }>
                                <span className={ styles.summaryQuestion }>
                                    연회비 계산법
                                    { inPromotion && <span className={ styles.promotionTag }>EVENT</span> }
                                </span>
                            </ToolTip>
                        )
                    }
                </span>
                <strong className={ styles.summaryTotal }>
                    {
                        forDiscount ?
                            `${toCurrency(totalBenefit)}원` :
                            `${toCurrency(totalBenefit)}마일`
                    }
                </strong>
            </section>
            <section className={ styles.recommend }>
                <span className={ styles.recommendMessage }>
                    { message }
                </span>
            </section>
            <section className={ styles.calculation }>
                <h5 className={ styles.calculationTitle }>
                    월 혜택에 반영된 소비내역
                    <b>입력한 { spendingCount }건중 { profits.size }건</b>
                </h5>
                <ul className={ styles.calculationList }>
                    <li className={ styles.total }>
                        <span className={ styles.expense }>
                            { `${toCurrency(data.getSpendingAmount())}원` }
                        </span>
                        <span className={ styles.discount }>
                            <span className={ forDiscount ? styles.active : styles.etc }>
                                { `${toCurrency(summary.discount)}원` }
                            </span>
                        </span>
                        <span className={ styles.point }>
                            <span className={ forDiscount ? styles.active : styles.etc }>
                                { `${toCurrency(summary.point)}점` }
                            </span>
                        </span>
                        <span className={ styles.mileage }>
                            <span className={ forDiscount ? styles.etc : styles.active }>
                                { `${toCurrency(summary.mileage)}마일` }
                            </span>
                        </span>
                    </li>
                    {
                        profits.isEmpty() && (
                            <FetchEmpty padding={ FetchEmptyPaddingSize.EXTRA_SMALL }>
                                이 카드로 받을 수 있는 혜택이 없네요<br/>
                                혜택을 받을 수 있는 다른 카드를 찾아보세요!
                            </FetchEmpty>
                        )
                    }
                    {
                        profits.map((profit, i) => lets(`profits-${i}`, key =>
                            <li key={ key }>
                                <input
                                    type="checkbox"
                                    id={ key }
                                    onChange={ e => sendGAEvent(
                                        GA_DOMAIN.CARD,
                                        GA_CATEGORY.CARD_PROFITS.PROFITS_TABLE,
                                        GA_ACTION.DROP_DOWN,
                                        profit.spending.storeName,
                                        e.currentTarget.checked ?
                                            GA_EVENT_VALUE.OPEN :
                                            GA_EVENT_VALUE.CLOSE
                                    ) }
                                />
                                <label htmlFor={ key }>
                                    <span className={ styles.store }>
                                        { profit.spending.storeName }
                                    </span>
                                    <span className={ styles.expense }>
                                        <span>{ `${toCurrency(profit.spending.expense)}원` }</span>
                                    </span>
                                    <span className={ styles.discount }>
                                        <span className={ forDiscount ? styles.active : styles.etc }>
                                            { `${toCurrency(profit.amount.discount)}원` }
                                        </span>
                                    </span>
                                    <span className={ styles.point }>
                                        <span className={ forDiscount ? styles.active : styles.etc }>
                                            { `${toCurrency(profit.amount.point)}점` }
                                        </span>
                                    </span>
                                    <span className={ styles.mileage }>
                                        <span className={ forDiscount ? styles.etc : styles.active }>
                                            { `${toCurrency(profit.amount.mileage)}마일` }
                                        </span>
                                    </span>
                                </label>
                                <ul className={ styles.profits }>
                                    {
                                        profit.benefits.map((benefit, j) => lets(`benefits-${i}-${j}`, benefitKey =>
                                            <li key={ benefitKey }>
                                                <strong className={ styles.profitTitle }>{ benefit.benefit }</strong>
                                                { benefit.conditions.map(it => <span key={ uniqueKey() } className={ styles.profitCond }>{ it }</span>) }
                                                { benefit.restrictions.map(it => <span key={ uniqueKey() } className={ styles.profitRest }>{ it }</span>) }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>
                        ))
                    }
                </ul>
                {
                    !noBenefitSpendings.isEmpty() && (
                        <div className={ styles.noBenefits }>
                            <input
                                type="checkbox"
                                id="no-benefit-spendings"
                                onChange={ e => sendGAEvent(
                                    GA_DOMAIN.CARD,
                                    GA_CATEGORY.CARD_PROFITS.PROFITS_TABLE,
                                    GA_ACTION.DROP_DOWN,
                                    '받지못한혜택',
                                    e.currentTarget.checked ?
                                        GA_EVENT_VALUE.OPEN :
                                        GA_EVENT_VALUE.CLOSE
                                ) }
                            />
                            <label htmlFor="no-benefit-spendings">
                                받지못한 혜택으로는 어떤 것들이 있을까요?
                            </label>
                            <div className={ styles.noBenefitsList }>
                                <h6>혜택이 없는 경우 <strong>{ noBenefitSpendings.size }건</strong></h6>
                                <ul>
                                    {
                                        noBenefitSpendings.map((spending, i) =>
                                            <li key={ `no-benefit-${i}` }>
                                                <span className={ styles.noBenefitStore }>{ spending.storeName }</span>
                                                <span className={ styles.noBenefitAmount }>{ `${toCurrency(spending.expense)}원` }</span>
                                                <span className={ styles.noBenefitDesc }>이 카드엔 해당 가맹점의 혜택이 없네요</span>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                }
            </section>
        </div>
    );
};
