import { Entity } from 'core/entity';
import { ContentAuthorProfileSummary } from 'core/entity/content/author/profile/summary/profile';

export class ContentAuthorSummary extends Entity {
    id: string;
    profile: ContentAuthorProfileSummary;

    constructor(
      id: string,
      profile: ContentAuthorProfileSummary
    ) {
      super();
      this.id = id;
      this.profile = profile;
    }
}
