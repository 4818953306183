import { TransactionUpdateResult } from 'core/entity/personal-finance/transaction/update-result';
import { TransactionUpdateSpec } from 'core/entity/personal-finance/transaction/update-spec';
import { UserTransactionRepositoryType } from 'core/repository/personal-finance/transaction';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class UpdateUserTransaction extends UseCase<TransactionUpdateResult> {
    id: string;
    spec: TransactionUpdateSpec;
    private repository: UserTransactionRepositoryType;

    constructor(repository: UserTransactionRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<TransactionUpdateResult> {
        return this.repository.update(this.id, this.spec);
    }

    protected validate(): boolean {
        return !!this.id && !!this.spec;
    }

}
