import { MappingError, NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoanSubmitStatus } from 'core/entity/personalized-recommend/loan/submit-status';

export class PersonalizedLoanSubmitStatusMapper implements
    NetworkRequestMapper<PersonalizedLoanSubmitStatus>,
    NetworkResponseMapper<PersonalizedLoanSubmitStatus>
{
    fromJson(json: any): PersonalizedLoanSubmitStatus {
        switch (json) {
            case 'always':
                return PersonalizedLoanSubmitStatus.ALWAYS;
            case 'enable':
                return PersonalizedLoanSubmitStatus.ENABLE;
            case 'disable':
                return PersonalizedLoanSubmitStatus.DISABLE;
            default:
                throw new MappingError();
        }
    }

    toJson(target: PersonalizedLoanSubmitStatus): any {
        switch (target) {
            case PersonalizedLoanSubmitStatus.ALWAYS:
                return 'always';
            case PersonalizedLoanSubmitStatus.ENABLE:
                return 'enable';
            case PersonalizedLoanSubmitStatus.DISABLE:
                return 'disable';
            default:
                throw new MappingError();
        }
    }
}
