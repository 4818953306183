import { Entity } from 'core/entity';
import { LoanInterestPromotion } from 'core/entity/loan/interest-promotion';
import { LoanInterestValue } from 'core/entity/loan/interest-value';
import { LoanInterestType } from 'core/entity/loan/interest-type';

export class LoanInterestConfigurationSummary extends Entity {
    interestPromotion?: LoanInterestPromotion;
    totalInterest: LoanInterestValue;
    type: LoanInterestType;

    constructor(
        interestPromotion: LoanInterestPromotion = null,
        totalInterest: LoanInterestValue,
        type: LoanInterestType
    ) {
        super();
        this.interestPromotion = interestPromotion;
        this.totalInterest = totalInterest;
        this.type = type;
    }
}
