import { CardCFAConfiguration } from 'core/entity/legal-configuration/card/cfa';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardCFAReviewMapper } from 'data/http/mapper/legal-configuration/card/cfa/review';
import { CardCFADescriptionMapper } from 'data/http/mapper/legal-configuration/card/description';

export class CardCFAConfigurationMapper implements NetworkResponseMapper<CardCFAConfiguration> {
    fromJson(json: any): CardCFAConfiguration {
        return new CardCFAConfiguration(
            new CardCFAReviewMapper().fromJson(json.review),
            new CardCFADescriptionMapper().fromJson(json.description)
        )
    }

}
