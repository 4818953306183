import { NetworkResponseMapper } from 'data/http/mapper';
import { StockPrimaryDataMapper } from 'data/http/mapper/personal-finance/primary-data/stock';
import { parseDate, optional } from 'utils/index';
import { UserStock } from 'core/entity/personal-finance/stock';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { AmountMapper } from 'data/http/mapper/amount';
import { SpiderSecuritiesFirmSummaryMapper } from 'data/http/mapper/personal-finance/securities-firm/summary';

export class UserStockMapper implements NetworkResponseMapper<UserStock> {
    fromJson(json: any): UserStock {
        return new UserStock(
            json.id,
            json.description,
            json.hidden,
            new DeletedStatusMapper().fromJson(json.deleted_status),
            json.closed,
            new StockPrimaryDataMapper().fromJson(json.primary_data),
            optional(json.nickname),
            optional(json.code),
            optional(json.average_purchase_amount, it =>
                new AmountMapper().fromJson(it)
            ),
            optional(json.valuation, it =>
                new AmountMapper().fromJson(it)
            ),
            optional(json.quantity),
            optional(json.purchased_at, it => parseDate(it)),
            optional(json.securities_firm, it =>
                new SpiderSecuritiesFirmSummaryMapper().fromJson(it)
            ),
            optional(json.commission),
            optional(json.tax),
            optional(json.principal, it =>
                new AmountMapper().fromJson(it)
            ),
            optional(json.holding_duration)
        )
    }

}
