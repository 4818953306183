import { NetworkRequestMapper } from 'data/http/mapper';
import { CardFilter } from 'core/entity/card/filter';
import { lets, optional } from 'utils/index';
import { CardBrandMapper } from 'data/http/mapper/card/brand';
import { CardTypeMapper } from 'data/http/mapper/card/type';

export class CardFilterMapper implements NetworkRequestMapper<CardFilter> {
    toJson(target: CardFilter): any {
        return {
            card_ids: target.cardIds,
            name: target.name,
            contracted_only: target.contractedOnly,
            unstopped_only: target.unstoppedOnly,
            annual_cost_min: target.annualCostMin,
            annual_cost_max: target.annualCostMax,
            promotion_only: target.promotionOnly,
            brands: optional(target.brands, it =>
                it.map(brand => new CardBrandMapper().toJson(brand))
            ),
            companies: target.companies,
            special_benefits: target.specialBenefitIds,
            card_types: optional(target.cardTypes, it =>
                it.map(type => new CardTypeMapper().toJson(type))
            ),
            channel: lets(target.channel, it => {
                switch (it) {
                    case CardFilter.Channel.INTERPARK:
                        return 'interpark-ticket';
                    default:
                        return 'banksalad';
                }
            })
        }
    }
}
