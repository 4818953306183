import Axios from 'axios';
import { CardEvent } from 'core/entity/card/event';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { CardEventMapper } from 'data/http/mapper/card/event';
import { EventViewer } from 'presentation/components/event-viewer';
import { EventViewerModel, EventViewerModelMapper } from 'presentation/components/event-viewer/model';
import { Header } from 'presentation/components/header';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { GA_ACTION, GA_CATEGORY } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNamespaceProduction } from 'utils/env';

interface State {
  event: CardEvent;
  eventFetchState: FetchState;
}

export const CardsEvent = ({ match }: RouteComponentProps<any>) => {
  const id = match.params.id;
  const [{ event, fetchState }, setState] = useState({
    event: null as CardEvent,
    fetchState: FetchState.FETCHING
  });

  const toEventViewerModelFromCardEvent = (cardEvent: CardEvent): EventViewerModel =>
    new EventViewerModelMapper().fromCardEvent(cardEvent);

  useEffect(() => {
    (async () => {
      const API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/card-events/${id}.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/card-events/${id}.json`;
          
      const { data } = await Axios.get(API_PATH);
          
      setState({
        event: new CardEventMapper().fromJson(data.data),
        fetchState: FetchState.FETCHED,
      });
    })();
  }, []);

  return (
    <div>
      { event && <MetaHelmet meta={ META_SET.CARDS_EVENT(event) }/> }
      <Header active={ FinanceSectorID.CARD } />
      <EventViewer
        linkUrl={ BANKSALAD_SITEMAP_BY_ID.CARD_EVENT }
        ga={{
          issueLabel: '해당 카드사로 이동',
          heroCategory: GA_CATEGORY.CARDS_EVENT.HERO,
          suggestedCategory: GA_CATEGORY.CARDS_EVENT.SUGGESTED_EVENTS,
          suggestedAction: GA_ACTION.LINK.CARDS_EVENTS_DETAIL,
          floatButtonCategory: GA_CATEGORY.CARDS_EVENT.FIXED_ISSUE
        }}
        event={ event && toEventViewerModelFromCardEvent(event) }
        fetchState={ fetchState }
      />
    </div>
  );
};
