import { Alert, AlertSize } from 'presentation/components/alert';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    onCancel: () => void;
}

// TODO: 등급확인 링크 추가
export const CreditCoreInfoAlert = (props: Props) =>
  <Alert
    size={ AlertSize.SMALL }
    onCancel={ props.onCancel }
  >
    <section className={ styles.info }>
      <h2 className={ styles.title }>신용등급을 모르신다면?</h2>
      <p className={ styles.desc }>
        연체나 현금서비스 이용경험이 없는 직장인이라면 2-3등급,<br/>
        사회초년생이라면 4등급을 선택하세요
      </p>
      <p className={ styles.subDesc }>
        신용등급은 연 3회까지 무료로 조회할 수 있으며<br/>
        조회해도 신용등급에는 어떠한 영향도 주지 않습니다
      </p>
      <section className={ styles.buttons }>
        <a href="https://www.credit.co.kr/" target="_blank" rel="noopener noreferrer">NICE에서 내 등급 확인</a>
        <a href="http://www.allcredit.co.kr/index.jsp" target="_blank" rel="noopener noreferrer">KCB에서 내 등급 확인</a>
      </section>
    </section>
  </Alert>;
