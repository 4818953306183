import { Entity } from 'core/entity';

export class CardConditionalRestriction extends Entity {
    condition: string;
    restriction: string;

    constructor(
        condition: string,
        restriction: string
    ) {
        super();
        this.condition = condition;
        this.restriction = restriction;
    }
}
