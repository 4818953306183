import { List } from 'immutable';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSubSector } from 'core/entity/finance-sector/sub';
import { FinanceSubSectorContentItem } from 'core/entity/finance-sector/item/content';
import { ContentColumnSummary } from 'core/entity/content/column/summary';
import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { EMERGENCY_AUTHORS } from 'presentation/module/emergency-contents';
import { FinanceSectorID } from 'core/entity/finance-sector/id';

export const EMERGENCY_CARD_SECTOR = new FinanceSector(
    FinanceSectorID.CARD,
    '카드',
    new FinanceSubSector(
        '상품 추천',
        List([
            new FinanceSubSectorContentItem(
                '혜택 좋은 BEST 신용카드',
                new ContentColumnSummary(
                    '155',
                    '혜택 좋은 BEST 신용카드',
                    '할인율, 적립률 뿐만 아니라 혜택 조건과 한도까지 고려하여 2016년 정말 혜택이 좋았던 BEST 신용카드를 한곳에 모아보았습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fbest%EC%8B%A0%EC%9A%A9%EC%B9%B4%EB%93%9C-01.png',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '혜택 좋은 BEST 체크카드',
                new ContentColumnSummary(
                    '90',
                    '혜택 좋은 BEST 체크카드',
                    '체크카드는 은행 계좌와 연결하여, 결제 즉시 계좌에서 돈이 빠져나가는 방식의 카드지요. 계좌 잔액내에서만 사용할 수 있기에, 예산 내에서 소비하는 습관을 잡을 수 있는 좋은 수단입니다. 개인의 신용과 상관없이 발급이 가능하기 때문에, 아직 신용카드를 발급받기 어려운 학생들도 은행 계좌만 있다면 쉽게 발급받아 사용할 수 있는 카드랍니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-shutterstock_435304801_mini_1280x873.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '2017 BEST 마일리지카드',
                new ContentColumnSummary(
                    '302',
                    '2017 BEST 항공 마일리지 카드',
                    '2017년 BEST 항공 마일리지 카드는 무엇일까요! 여행을 좋아하시는 분들의 마음을 설레게 하는 마일리지 카드. 같은 금액을 사용해도 카드에 따라 적립에 차이가 있습니다. 그래서 뱅크샐러드가 알아봤습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fplane-841441_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '2017 BEST VIP카드',
                new ContentColumnSummary(
                    '96',
                    '부문별 BEST VIP 카드',
                    '높은 연회비, 그러나 남들과는 뭔가 다른 특별한 혜택을 받을 수 있는 VIP카드! VIP카드는 일반적으로 연회비가 60만원 이상으로 상당히 높지만, 혜택을 꼼꼼히 살펴보면 연회비를 뛰어넘는 혜택을 제공하는 경우가 많습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-shutterstock_477977422_mini_1024x682.jpg',
                    new Date()
                )
            )
        ])
    ),
    new FinanceSubSector(
        '상품 리뷰',
        List([
            new FinanceSubSectorContentItem(
                'PP카드가 무료로 제공되는 카드',
                new ContentCardComparisonSummary(
                    '4',
                    'PP카드가 무료로 제공되는 카드',
                    'PP Priority Pass 카드는 전세계 공항라운지를 무료로 이용할 수 있는 멤버십 카드인데요. 해외에 자주 다녀오시는 분이라면 엄청나게 유용하지만, 비싼 멤버십 비용이 문제였죠. 다행히 여기 PP카드를 무료로 제공하고, 바우처 등 다양한 프리미엄 혜택까지 제공하는 카드들이 있습니다. 라운지에서 와인 한 잔 마시면서, 긴 비행시간으로 쌓인 피로를 날려보세요!',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F2_%ED%94%BC%ED%94%BC%EC%B9%B4%EB%93%9C.jpg',
                    new Date(),
                    true,
                    ''
                )
            ),
            new FinanceSubSectorContentItem(
                '잘 쌓이는 항공마일리지 신용카드',
                new ContentCardComparisonSummary(
                    '6',
                    '잘 쌓이는 항공마일리지 신용카드',
                    '<b>꼭</b> 비행기를 많이 타야만 항공마일리지가 많이 쌓이는 건 아닙니다! 카드 사용에 따라 항공마일리지가 쌓이는 카드들이 있어요. 단골 항공사가 있다면 해당 항공사의 마일리지 카드로, 그때그때 저렴한 항공사를 선택한다면, 통합형 마일리지 카드로 골라보세요. 항공마일리지카드 하나면 우리도 이제 무료 좌석 업그레이드와 공짜 항공권을 얻어낼 수 있어요!',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F4_%ED%95%AD%EA%B3%B5%EB%A7%88%EC%9D%BC%EB%A6%AC%EC%A7%80%EC%B9%B4%EB%93%9C.jpg',
                    new Date(),
                    false,
                    ''
                )
            ),
            new FinanceSubSectorContentItem(
                '전 가맹점 무이자 할부 카드',
                new ContentCardComparisonSummary(
                    '7',
                    '전 가맹점 무이자 할부 카드',
                    '<b>신용카드</b>를 사용하다 보면 어느샌가 할부로 지르는 것들이 많아지죠. 그런데 할부 결제의 이자가 약 10~20% 정도 된다는 충격적인 사실, 알고 계셨나요? 아무리 그래도 할부를 포기할 수 없다면, 모든 가맹점에서 무이자 할부가 가능한 카드들을 사용해보세요. 이자 없이 할부가 어디서나 가능한 카드 중 정말 혜택이 좋은 카드만 엄선하였습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F5_%EB%AC%B4%EC%9D%B4%EC%9E%90%ED%95%A0%EB%B6%80%EC%B9%B4%EB%93%9C.jpg',
                    new Date(),
                    false,
                    ''
                )
            ),
            new FinanceSubSectorContentItem(
                '통신비 할인 많이 되는 카드',
                new ContentCardComparisonSummary(
                    '11',
                    '통신비 할인 많이 되는 카드',
                    '<b>매 달</b> 나가는 핸드폰 요금, 통신비뿐만 아니라 기기 할부금까지 더하면 은근히 고정적으로 많은 금액이 나가고 있죠. 통신사 자체 할인, 멤버쉽 할인과 카드 할인은 중복으로 받을 수 있으므로! 카드 할인까지 똑똑하게 챙겨서 고정지출을 줄여보세요. 각 통신사별로 정말 혜택이 좋은 카드만 엄선했습니다. 내 통신사에 맞는 카드를 찾아보세요!',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F11_%ED%86%B5%EC%8B%A0%EB%B9%84%ED%95%A0%EC%9D%B8%EC%B9%B4%EB%93%9C.jpg',
                    new Date(),
                    false,
                    ''
                )
            ),
            new FinanceSubSectorContentItem(
                '해외여행 할인 카드',
                new ContentCardComparisonSummary(
                    '30',
                    '해외여행 할인 카드',
                    '<b>YOLO!</b> 해외 여행을 준비하고 계신가요? "한 번뿐인 인생, 즐기면서 살자!"는 욜로 라이프 때문인지 요즘 정말 많은 분들이 해외 여행 떠나고 계시는데요. 한 조사에 따르면 3박 4일 해외여행 경비는 평균 150만원이라고 하는데요. 항공료부터 경비까지 여기저기 돈 쓸 때가 너무나도 많죠! 그런데 카드만 잘 골라도 여행 경비를 확 줄 일 수 있다는 사실 알고 계신가요? 항공료 할인 카드부터 해외 결제 할인 카드까지 알뜰하게 할인해주는 카드들을 엄선해보았습니다!',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content/upload/images/content-upload-images-2560288782.jpg',
                    new Date(),
                    false,
                    ''
                )
            ),
            new FinanceSubSectorContentItem(
                '연회비 없는 신용카드',
                new ContentCardComparisonSummary(
                    '14',
                    '연회비 없는 신용카드',
                    '<b>지난</b> 9월 30일 개정된 여신전문금융업법의 시행으로, 온라인으로 직접 카드를 신청하여 발급받으면 연회비의 100%까지 혜택을 받을 수 있게 되었습니다. 다이렉트 자동차 보험처럼 설계사의 도움을 받지 않는 경우 설계사에게 지급되었던 수수료를 고객에게 돌려주기 위한 정책으로 완전히 소비자에게 집중된 정책이라고 볼 수 있습니다. 이에 따라 여러 카드사들에서 카드 발급 시 연회비 캐시백 프로모션을 진행하고 있습니다. 하지만 어떤 카드들이 연회비 100%캐시백이 되는지, 어떤 카드를 발급받아야 할지 고민이 많으셨죠? 그래서 정리했습니다. 연회비 캐시백이 진행되고 있는 카드 중 혜택이 많은 신용카드!',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F12_%EC%97%B0%ED%9A%8C%EB%B9%84%ED%95%A0%EC%9D%B8%EC%B9%B4%EB%93%9C.png',
                    new Date(),
                    false,
                    ''
                )
            ),
            new FinanceSubSectorContentItem(
                '직장인 신용카드 추천',
                new ContentCardComparisonSummary(
                    '9',
                    '직장인 신용카드 추천',
                    '<b>직장</b> 생활을 시작하면 고정수입이 생기다 보니, 소비패턴이 완전히 바뀌면서 지출도 갑자기 늘어나게 됩니다. 매일 사 먹는 점심식사라던가, 커피, 출퇴근 대중교통 등이 대표적이죠. 뱅크샐러드가 직장인들의 라이프스타일에 맞춰진 직장인 필수 카드들을 엄선해보았습니다. 당신이 직장인이라면, 직장인을 위한 신용카드로 더 큰 혜택을 챙겨보세요!',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F7_%EC%A7%81%EC%9E%A5%EC%9D%B8%EC%8B%A0%EC%9A%A9%EC%B9%B4%EB%93%9C.jpg',
                    new Date(),
                    false,
                    ''
                )
            ),
            new FinanceSubSectorContentItem(
                '하이브리드 카드',
                new ContentCardComparisonSummary(
                    '18',
                    '하이브리드 카드',
                    '<b>하이브리드 카드</b>, 한 번쯤은 들어보셨을 텐데요. 하이브리드 카드는 계좌 잔액을 통해 결제하는 체크카드 방식과 은행/카드사가 대신 결제를 해주는 신용카드 방식을 적절하게 섞은 카드입니다. 체크, 신용 무엇하나 놓치고 싶지 않은 당신을 위해 혜택 좋은 하이브리드 카드를 골라봤습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F18_%ED%95%98%EC%9D%B4%EB%B8%8C%EB%A6%AC%EB%93%9C%EC%B9%B4%EB%93%9C.jpg',
                    new Date(),
                    false,
                    ''
                )
            ),
        ])
    ),
    new FinanceSubSector(
        '상품 매거진',
        List([
            new FinanceSubSectorContentItem(
                '신용카드 기본기 : 신용카드의 장단점부터, 발급방법까지',
                new ContentColumnSummary(
                    '178',
                    '신용카드 기본기 : 신용카드의 장단점부터, 발급방법까지',
                    '생활 속 편리함을 주는 신용카드는 잘 사용하면 득이지만 남용하게 되면 독이 됩니다. 무분별한 소비를 불러일으킬수 있다는 이유로 신용카드 대신 체크카드 사용을 권하는 경우도 있지만, 조금만 신경써서 사용한다면 체크카드 사용보다 많은 이점이 있습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fshutterstock_365771174.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '최고의 신용카드를 고르는 방법',
                new ContentColumnSummary(
                    '153',
                    '최고의 신용카드를 고르는 방법',
                    '대부분의 소비자는 자신의 소비 행태에 가장 맞는 카드가 무엇인지 모른 채 카드를 사용합니다. 뱅크샐러드는 이러한 갈증을 해소하고 싶었습니다.  4년 동안 카드 상품 분석, 추천을 하면서 결국 카드를 선택할 때에는 다음 4가지 사항을 기준으로 해야 한다는 것을 알게 되었습니다',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fchoosebestcard.JPG',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '신용카드 포인트 100% 활용법',
                new ContentColumnSummary(
                    '119',
                    '신용카드 포인트 100% 활용법',
                    '알뜰하게 신용카드를 사용해보겠노라고 포인트 적립률이 높은 신용카드를 선택한 직장인 A씨. 열심히 포인트를 모으고 모아 10만 포인트 가까이를 쌓았는데, 막상 포인트를 활용해 물건을 사려고 했더니 가맹점으로부터 거절당했다.',
                    EMERGENCY_AUTHORS.PARK_JOO_HYUN,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcredit-card-1730085_1280.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '해외결제 시 꼭 알아야 할 8가지',
                new ContentColumnSummary(
                    '6',
                    '해외결제 시 꼭 알아야 할 8가지',
                    '해외에서 현금다발을 갖고 다니면 범죄의 표적이 되기 쉽습니다. 게다가 현금을 잃어버리면 그걸로 ‘땡’입니다. 반면 카드는 현금을 갖고 다니는 불편과 위험을 줄여줍니다. 도난을 당해도 신고만 잘하면 잃은 금액은 보전 받을 수도 있기에 카드를 선호하는 사람이 많습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2F-pVA1QWdd97lbRpzPTyXIeYetH0.png',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '할부 취소의 테크닉',
                new ContentColumnSummary(
                    '30',
                    '할부 취소의 테크닉',
                    '# 이슬이는 자전거로 출퇴근하기 위해 120만 원짜리 자전거를 12개월 할부로 샀습니다. 그런데 ‘날이 덥다’, ‘비가 온다’며 자전거 타기를 미루다가 결국 자전거를 환불하기로 결심했습니다. 이슬이는 할부 취소를 할 수 있을까요?',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2FWOX9dpt5uPKkflJurRBHa3Y4Cgo_800x600.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '카드 할부와 리볼빙의 차이',
                new ContentColumnSummary(
                    '99',
                    '카드 할부와 리볼빙의 차이',
                    '#카드 값 연체위기가 왔을 때 카드 리볼빙을 알게 된 직장인 김 씨. 어떻게 할까 고민하던 김 씨는 결국 카드사 리볼빙 서비스를 이용하게 됐다. 그러나 계속되는 카드 사용과 함께 연 20%의 고금리를 감당하지 못하게 됐고, 결국 개인회생을 신청해야 하는 상황에 처하게 됐다. 어떻게 된 일일까?',
                    EMERGENCY_AUTHORS.PARK_JOO_HYUN,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcredit-cards07.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '할인한도에 대한 모든 것',
                new ContentColumnSummary(
                    '7',
                    '할인한도에 대한 모든 것',
                    '카드사는 우리에게 이렇게 문제를 내곤 합니다. "30% 할인 혜택이 클까,  10% 할인 혜택이 클까?" 그러면서 이렇게 덧붙입니다. 우리 카드는 30% 할인을 해주니 네가 합리적인 사람이라면 우리 카드를 써야 돼. 하지만 그들이 낸 부등호 퀴즈에 대한 답은 <가 될 수도 있고, =가 될 수도 있습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcover_007_800x532.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '카드 분실 시, 어떻게 해야 할까?',
                new ContentColumnSummary(
                    '37',
                    '카드 분실 시, 어떻게 해야 할까?',
                    '# 직장인 지훈씨는 카드를 잃어버렸다는 사실을 알았으나, 설마 누군가가 사용할 것이라고는 생각하지 못하고 이틀 후에야 분실신고를 하였습니다. 그 사이 지훈씨의 카드를 습득한 사람이 420만원을 사용하였습니다. 생각도 하기 싫은 난감한 상황이지만, 누구에게나 쉽게 일어날 수 있는 일이지요.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-file0003648574923_mini_800x599.jpg',
                    new Date()
                )
            )
        ])
    )
);
