import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { AccessToken } from 'core/entity/user/access-token';
import { parseDate, stringifyDate } from 'utils/index';

export class AccessTokenMapper implements BrowserStorageMapper<AccessToken> {
    toJson(entity: AccessToken): any {
        return {
            accessToken: entity.accessToken,
            refreshToken: entity.refreshToken,
            expiresIn: stringifyDate(entity.expiresIn)
        }
    }

    toEntity(value: any): AccessToken {
        return new AccessToken(
            value.accessToken,
            value.refreshToken,
            parseDate(value.expiresIn)
        );
    }

}
