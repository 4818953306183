import { Entity } from 'core/entity';
import { UserCreditScoreOrganization } from 'core/entity/personal-credit/organization';
import { Amount } from 'core/entity/amount';

export class UserCreditScoreJointSurety extends Entity {
    organization: UserCreditScoreOrganization;
    contractedAt: Date;
    totalAmount: Amount;

    constructor(
        organization: UserCreditScoreOrganization,
        contractedAt: Date,
        totalAmount: Amount
    ) {
        super();
        this.organization = organization;
        this.contractedAt = contractedAt;
        this.totalAmount = totalAmount;
    }
}
