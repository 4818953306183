import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';
import { optional } from 'utils/index';
import { AdviceActionMapper } from 'data/http/mapper/personal-finance/advice/action';

export class AdviceMessageMapper implements NetworkResponseMapper<AdviceMessage> {
    fromJson(json: any): AdviceMessage {
        return new AdviceMessage(
            optional(json.image_url),
            optional(json.body),
            optional(json.action, new AdviceActionMapper().fromJson)
        )
    }
}
