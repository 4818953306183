import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { ContentSummary } from 'core/entity/content/summary';
import { List } from 'immutable';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    contents: List<ContentSummary>;
    gaCategory: string;
    url?: string;
}

export const SectorContentsRecommend = (props: Props) => {
  const hugeIndex = List(props.contents.size === 2 ? [0, 1] : [0]);
  const bigIndex = List(props.contents.size % 2 === 1 ? [1, 2] : []);
  const clearIndex = List(props.contents.size % 2 === 0 ? [4] : []);

  const toLink = (content: ContentSummary) => {
    if (content instanceof ContentCardComparisonSummary) {
      return BANKSALAD_SITEMAP_BY_ID.CARD_THEME(content.id);
    } else {
      return BANKSALAD_SITEMAP_BY_ID.CONTENT(content.id);
    }
  };

  const sendGA = (content: ContentSummary) => {
    if (content instanceof ContentCardComparisonSummary) {
      sendGAEvent(
        GA_DOMAIN.NONE,
        props.gaCategory,
        GA_ACTION.LINK.CARDS_THEMES_DETAIL,
        `${content.title}_${content.id}`,
      );
    } else {
      sendGAEvent(
        GA_DOMAIN.NONE,
        props.gaCategory,
        GA_ACTION.LINK.CONTENTS_DETAIL,
        `${content.title}_${content.id}`,
      );
    }
  };

  return (
    <ul className={ styles.wrap }>
      {
        props.contents.map((content, i) =>
          <li
            key={ `content-d-${i}` }
            className={ hugeIndex.contains(i) ? styles.hugeItem :
              bigIndex.contains(i) ? styles.bigItem :
                clearIndex.contains(i) ? styles.clearItem : styles.item }
          >
            <a
              href={ props.url === undefined ? toLink(content) : props.url  }
              className={ styles.button }
              onClick={ () => sendGA(content) }
            >
              <div className={ styles.thumbnail }>
                <div
                  style={ { backgroundImage: `url("${content.thumbnailImageUrl}")` } }
                  className={ styles.thumbnailImage }
                />
                <div className={ styles.thumbnailGradientShadow } />
                <div className={ styles.thumbnailShadow } />
                <span className={ styles.thumbnailTitle }>{ content.title }</span>
              </div>
              <div className={ styles.info }>
                <span className={ styles.title }>{ content.title }</span>
                <p className={ styles.description }>{ content.description }</p>
              </div>
              <div className={ styles.author }>
                <div
                  style={ { backgroundImage: `url("${content.author.profile.imageUrl}")` } }
                  className={ styles.authorImage }
                />
                <div className={ styles.authorName }>
                  { content.author.profile.name }
                  { 
                    content.author.profile.summary.length > 0 
                    && `·${content.author.profile.summary}`
                  }
                </div>
              </div>
            </a>
          </li>,
        )
      }
    </ul>
  );
};
