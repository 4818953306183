import {
    NetworkRequestMapper,
    NetworkResponseMapper
} from 'data/http/mapper';
import { LoanInterestType } from 'core/entity/loan/interest-type';

export class LoanInterestTypeMapper implements
    NetworkRequestMapper<LoanInterestType>,
    NetworkResponseMapper<LoanInterestType>
{
    toJson(target: LoanInterestType): any {
        switch (target) {
            case LoanInterestType.FIXED_INTEREST:
                return 'fixed_interest';
            case LoanInterestType.NON_FIXED_INTEREST:
                return 'non_fixed_interest';
        }
    }

    fromJson(json: any): LoanInterestType {
        switch (json) {
            case 'fixed_interest':
                return LoanInterestType.FIXED_INTEREST;
            case 'non_fixed_interest':
            default:
                return LoanInterestType.NON_FIXED_INTEREST;
        }
    }

}
