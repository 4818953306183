export interface BrowserStorageMapper<T> {
    toJson(entity: T): any;
    toEntity(value: any): T;
}

export class BrowserStorageMappingError extends Error {
    constructor() {
        super('Inappropriate mapping');
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
