import { NetworkResponseMapper } from 'data/http/mapper';
import { Alfred2CardIssuingChannelConfiguration } from 'core/entity/alfred2/card-issuing-channel-configuration';
import { Alfred2CardIssuingConfigurationMapper } from 'data/http/mapper/alfred2/card-issuing-configuration';

export class Alfred2CardIssuingChannelConfigurationMapper implements NetworkResponseMapper<Alfred2CardIssuingChannelConfiguration> {
    fromJson(json: any): Alfred2CardIssuingChannelConfiguration {
        return new Alfred2CardIssuingChannelConfiguration(
            new Alfred2CardIssuingConfigurationMapper().fromJson(json['desktop-web']),
            new Alfred2CardIssuingConfigurationMapper().fromJson(json['mobile-web']),
            new Alfred2CardIssuingConfigurationMapper().fromJson(json['mobile-app'])
        );
    }
}
