import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardAnnualCost } from 'core/entity/card/annual-cost';
import { CardAnnualCostPromotion } from 'core/entity/card/annual-cost/promotion';
import { CardCategoryBenefit } from 'core/entity/card/category-benefit';
import { CardSelectiveGroup } from 'core/entity/card/selective-group';
import { CardCategorySpecialBenefit } from 'core/entity/card/category-special-benefit';
import { CardPremiumVoucherGroup } from 'core/entity/card/premium-voucher-group';
import { CardConditionalRestriction } from 'core/entity/card/conditional-restriction';
import { CardShinhanCombo } from 'core/entity/card/shinhan-combo';

export class Card extends Entity {
    id: number;
    name: string;
    description?: string = null;
    imageUrl: string;
    imageOrientation: Card.ImageOrientation;
    issueUrlDesktop: string;
    issueUrlMobile: string;
    issueUrlApp: string;
    issueUrlInterparkDesktop?: string = null;
    issueUrlInterparkMobile?: string = null;
    issuePhoneNumber?: string = null;
    companyId: number;
    companyImageUrl: string;
    companyName: string;
    type: Card.Type;
    defaultBenefits: List<CardCategoryBenefit>;
    selectiveBenefits?: List<CardSelectiveGroup> = null;
    specialBenefitCategories: List<string>;
    categorySpecialBenefits?: List<CardCategorySpecialBenefit> = null;
    premiumVouchers?: List<CardPremiumVoucherGroup> = null;
    baseRests: List<string>;
    condRests: List<CardConditionalRestriction>;
    annualCosts: List<CardAnnualCost>;
    annualCostPromotion?: CardAnnualCostPromotion = null;
    annualCostWithPromotion: number;
    minimumAnnualCost: number;
    maximumAnnualCost: number;
    stopped: boolean;
    complicated: boolean;
    shinhanCombo?: CardShinhanCombo = null;
    representativeBenefitDescription?: string = null;
    informationUrlDesktop?: string = null;
    informationUrlMobile?: string = null;
    informationUrlApp?: string = null;

    constructor(
        id: number,
        name: string,
        imageUrl: string,
        imageOrientation: Card.ImageOrientation,
        issueUrlDesktop: string,
        issueUrlMobile: string,
        issueUrlApp: string,
        companyId: number,
        companyImageUrl: string,
        companyName: string,
        type: Card.Type,
        defaultBenefits: List<CardCategoryBenefit>,
        specialBenefitCategories: List<string>,
        baseRests: List<string>,
        condRests: List<CardConditionalRestriction>,
        annualCosts: List<CardAnnualCost>,
        annualCostWithPromotion: number,
        minimumAnnualCost: number,
        maximumAnnualCost: number,
        stopped: boolean,
        complicated: boolean
    ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.imageOrientation = imageOrientation;
        this.issueUrlDesktop = issueUrlDesktop;
        this.issueUrlMobile = issueUrlMobile;
        this.issueUrlApp = issueUrlApp;
        this.companyId = companyId;
        this.companyImageUrl = companyImageUrl;
        this.companyName = companyName;
        this.type = type;
        this.defaultBenefits = defaultBenefits;
        this.specialBenefitCategories = specialBenefitCategories;
        this.baseRests = baseRests;
        this.condRests = condRests;
        this.annualCosts = annualCosts;
        this.annualCostWithPromotion = annualCostWithPromotion;
        this.minimumAnnualCost = minimumAnnualCost;
        this.maximumAnnualCost = maximumAnnualCost;
        this.stopped = stopped;
        this.complicated = complicated;
    }
}

export module Card {
    export enum Type {
        CHECK, CREDIT, HYBRID
    }

    export enum ImageOrientation {
        LANDSCAPE = 'landscape',
        PORTRAIT = 'portrait'
    }
}
