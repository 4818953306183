import { UserActualAsset } from 'core/entity/personal-finance/actual-asset';
import { UserActualAssetUpdateSpec } from 'core/entity/personal-finance/actual-asset/update-spec';
import { UserActualAssetRepositoryType } from 'core/repository/personal-finance/actual-asset';
import { UserActualAssetsApiProvider } from 'data/http/api/gateway/v2/users/actual-assets';
import { Observable } from 'rxjs/Rx';
import { UserActualAssetCreateSpec } from 'core/entity/personal-finance/actual-asset/create-spec';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserActualAssetRepository implements UserActualAssetRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserActualAssetsApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserActualAssetsApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    create(spec: UserActualAssetCreateSpec): Observable<UserActualAsset> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.create(token, spec)
            )
    }

    update(id: string, spec: UserActualAssetUpdateSpec): Observable<UserActualAsset> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.update(token, id, spec)
            )
    }

}
