import { Entity } from 'core/entity';

export class ContentAuthorDescription extends Entity {
    title: string;
    body: string;

    constructor(
        title: string,
        body: string
    ) {
        super();
        this.title = title;
        this.body = body;
    }
}
