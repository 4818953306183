import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { UserFund } from 'core/entity/personal-finance/fund';
import { UserActualAsset } from 'core/entity/personal-finance/actual-asset';
import { UserStock } from 'core/entity/personal-finance/stock';
import { UserCard } from 'core/entity/personal-finance/card';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';

export module Asset {
    export enum DeletedStatus {
        NORMAL = 'normal',
        DELETED = 'deleted',
        TRANSACTIONS_DELETED = 'transactions_deleted'
    }

    export type Type =
        UserBankAccount | UserLoan | UserCashAccount | UserActualAsset |
        UserFund | UserStock | UserCard | UserSecuritiesFirmAccount;
}
