import { SpiderData } from 'core/entity/personal-finance/spider-data';

export class SpiderFundData extends SpiderData {
    constructor(
        name: string,
        public companyType: SpiderFundData.CompanyType,
        responseContained: boolean = null
    ) {
        super(name, responseContained);
    }
}

export module SpiderFundData {
    export enum CompanyType {
        BANK = 'bank',
        SECURITIES_FIRM = 'securities_firm'
    }
}
