import { Entity } from 'core/entity';

export class InsuranceCommpany extends Entity {
    name: string;
    imageUrl: string;
    isPriceVisible: boolean;
    imageUrlSquare: string;

    constructor(
        name: string,
        imageUrl: string,
        isPriceVisible: boolean,
        imageUrlSquare: string = '',
    ) {
        super();
        this.name = name;
        this.imageUrl = imageUrl;
        this.imageUrlSquare = imageUrlSquare;
        this.isPriceVisible = isPriceVisible;
    }
}
