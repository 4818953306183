import Cookies from "universal-cookie";

export const BANKSALAD_APPLICATION_NAME = 'banksalad-application-name';
export const APPLICATION_NAME = {
  WEB: 'banksalad2:Web',
  ANDROID: 'banksalad2:Android',
  IOS: 'banksalad2:iOS',
} as const;
/**
 *  구뱅샐에 enterprise-web 코드를 export 해서 사용하기 어렵기 때문에
 *  enterprise-web에 로직을 복사하여 사용합니다.
 */
export const isBanksaladWebView = () => {
  try {
    const cookies = new Cookies(document.cookie);
    const OS_NAME = {
      WEB: 'web',
      ANDROID: 'android',
      IOS: 'ios',
    } as const;
    const OS_NAMES: Array<string> = [OS_NAME.IOS, OS_NAME.ANDROID];
    type ApplicationName =
      | 'banksalad2:iOS'
      | 'banksalad2:Android'
      | 'banksalad2:Web'
      | 'ios'
      | 'android'
      | '';
    const normalizeOsName = (name: string): string | never => {
      /* cookie 에서 값을 조회할 때 ':' 이 '%3A'로 encoding 되는 경우가 있어서 decode해서 사용한다.  */
      switch (decodeURIComponent(name) as ApplicationName) {
      case APPLICATION_NAME.IOS:
        return OS_NAME.IOS;
      case APPLICATION_NAME.ANDROID:
        return OS_NAME.ANDROID;
      case APPLICATION_NAME.WEB:
        return OS_NAME.WEB;
        // 일부 서비스 환경에서 banksalad-application-name 을 ios/android 로 세팅하는 경우가 있기 때문에 그 경우를 대비해 추가함
      case 'ios':
        return OS_NAME.IOS;
      case 'android':
        return OS_NAME.ANDROID;
      default:
        throw new Error(`invalid application name. (${name} 호출됨)`);
      }
    };

    const isBanksaladApplicationName = (applicationName?: string) => {
      return (
        !!(applicationName && typeof applicationName === 'string') && OS_NAMES.includes(applicationName)
      );
    };

    return isBanksaladApplicationName(normalizeOsName(cookies.get(BANKSALAD_APPLICATION_NAME)));
  } catch (e) {
    console.error(e);

    return false;
  }
};
