import { MappingError } from 'data/http/mapper';
import { lets } from 'utils/index';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';
import { BrowserStorageMapper } from 'data/browser-storage/mapper';

export class MedicalCheckupJudgementItemStorageMapper implements BrowserStorageMapper<MedicalCheckupJudgement.Item> {
    toJson(target: MedicalCheckupJudgement.Item): any {
        return lets(target, it => {
            switch (it) {
                case MedicalCheckupJudgement.Item.FAT:
                    return 'fat';
                case MedicalCheckupJudgement.Item.SIGHT:
                    return 'sight';
                case MedicalCheckupJudgement.Item.HEARING:
                    return 'hearing';
                case MedicalCheckupJudgement.Item.HIGH_BLOOD_PRESSURE:
                    return 'high-blood-pressure';
                case MedicalCheckupJudgement.Item.KIDNEY:
                    return 'kidney';
                case MedicalCheckupJudgement.Item.ANEMIA:
                    return 'anemia';
                case MedicalCheckupJudgement.Item.DIABETES:
                    return 'diabetes';
                case MedicalCheckupJudgement.Item.DYSLIPIDEMIA:
                    return 'dyslipidemia';
                case MedicalCheckupJudgement.Item.CHRONIC_KIDNEY:
                    return 'chronic-kidney';
                case MedicalCheckupJudgement.Item.LIVER:
                    return 'liver';
                case MedicalCheckupJudgement.Item.TB_CHEST:
                    return 'tb-chest';
                case MedicalCheckupJudgement.Item.OSTEOPOROSIS:
                    return 'osteoporosis';
                case MedicalCheckupJudgement.Item.NOT_CATEGORIZED:
                    return 'not-categorized';
            }
        })
    }

    toEntity(json: any): MedicalCheckupJudgement.Item {
        return lets(json, it => {
            switch (it) {
                case 'fat':
                    return MedicalCheckupJudgement.Item.FAT;
                case 'sight':
                    return MedicalCheckupJudgement.Item.SIGHT;
                case 'hearing':
                    return MedicalCheckupJudgement.Item.HEARING;
                case 'high-blood-pressure':
                    return MedicalCheckupJudgement.Item.HIGH_BLOOD_PRESSURE;
                case 'kidney':
                    return MedicalCheckupJudgement.Item.KIDNEY;
                case 'anemia':
                    return MedicalCheckupJudgement.Item.ANEMIA;
                case 'diabetes':
                    return MedicalCheckupJudgement.Item.DIABETES;
                case 'dyslipidemia':
                    return MedicalCheckupJudgement.Item.DYSLIPIDEMIA;
                case 'chronic-kidney':
                    return MedicalCheckupJudgement.Item.CHRONIC_KIDNEY;
                case 'liver':
                    return MedicalCheckupJudgement.Item.LIVER;
                case 'tb-chest':
                    return MedicalCheckupJudgement.Item.TB_CHEST;
                case 'osteoporosis':
                    return MedicalCheckupJudgement.Item.OSTEOPOROSIS;
                case 'not-categorized':
                    return MedicalCheckupJudgement.Item.NOT_CATEGORIZED;
                default:
                    throw new MappingError();
            }
        })
    }


}
