import { PersonalFinanceMenu } from 'presentation/components/personal-finance/menu';
import { PersonalFinanceMenuType } from 'presentation/components/personal-finance/menu/model';
import { SettingsPrivacyRoute } from 'presentation/routes/me/settings/privacy';
import React from 'react';

import styles from './styles.pcss';


export const SettingsRoute = () => (
  <>
    <PersonalFinanceMenu active={ PersonalFinanceMenuType.SETTINGS } />
    <div className={ styles.wrap }>
      <div className={ styles.background }>
        <section className={ styles.content }>
          <div className={ styles.container }>
            <h1 className={ styles.title }>
                            개인설정
            </h1>
            <div className={ styles.body }>
              <SettingsPrivacyRoute />
            </div>
          </div>
        </section>
      </div>
    </div>
  </>
);
