import { Application } from 'application/context.instance';
import { CardAmount } from 'core/entity/card/amount';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { Store } from 'core/entity/store';
import { StoreGroup } from 'core/entity/store/group';
import { List } from 'immutable';
import { EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { FixBodyEvent } from 'presentation/bus/event/fix-body-event';
import { FetchEmptyPaddingSize } from 'presentation/components/fetch-state/empty';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { SurveyConfirm, SurveyContainer } from 'presentation/components/survey-container';
import { CardBenefitTypeForm } from 'presentation/components/survey-form/card-benefit-type';
import { CardQuestionBenefitType } from 'presentation/components/survey-form/card-benefit-type/model';
import { CardMonthlySpendingForm } from 'presentation/components/survey-form/card-monthly-spending';
import { CardQuestionMonthlySpending } from 'presentation/components/survey-form/card-monthly-spending/model';
import { CardQuestionSpending } from 'presentation/components/survey-form/card-spendings/model';
import { CardStoreForm } from 'presentation/components/survey-form/card-spendings/store';
import { CardStoreGroupForm } from 'presentation/components/survey-form/card-spendings/store-group';
import { StoreCategories } from 'presentation/components/survey-form/card-store-categories';
import { CardTypeForm } from 'presentation/components/survey-form/card-type';
import { CardQuestionCardType } from 'presentation/components/survey-form/card-type/model';
import { GA_ACTION, GA_CATEGORY } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { toast, toCurrency } from 'presentation/module/sugar';
import {
  generateCardQuestionBenefitType,
  generateCardQuestionCardType,
  generateCardQuestionMonthlySpending,
  generateCardQuestionSpendings,
} from 'presentation/routes/cards/questions/mapper';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { Question } from 'presentation/view-model/question';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { apply, emptyList, lets } from 'utils/index';

import styles from './styles.pcss';
const FIXED_TOP = 770;
const FIXED_TOP_EMPTY = 52;
const SIZE_OF_STORE_GROUP = 4;

interface State {
    cardType: CardQuestionCardType;
    benefitType: CardQuestionBenefitType;
    monthlySpending: CardQuestionMonthlySpending;
    annualCostMin: number;
    annualCostMax: number;
    spendings: List<CardQuestionSpending>;
    resultAmount: CardAmount;
    resultAnnualCost: number;
    storeGroups: List<StoreGroup>;
    resultFetchState: FetchState;
    storeGroupsFetchState: FetchState;
    navigationFixed: boolean;
    companies?: List<string>;
}

export class CardsQuestions extends React.Component<RouteComponentProps<any>, State> {
    state = {
      cardType: new CardQuestionCardType(),
      benefitType: new CardQuestionBenefitType(),
      monthlySpending: new CardQuestionMonthlySpending(),
      annualCostMin: 0,
      annualCostMax: 50000,
      spendings: emptyList(),
      resultAmount: new CardAmount(0, 0, 0, emptyList()),
      resultAnnualCost: 0,
      storeGroups: emptyList(),
      resultFetchState: FetchState.FETCHED,
      storeGroupsFetchState: FetchState.FETCHED,
      navigationFixed: false,
      companies: null as List<string>,
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
      this.fetchConfig();

      EventBus.toObservable().subscribe(
        (event: Event) => {
          if (event instanceof DocumentScrollEvent) {
            lets(event.scrollTop > FIXED_TOP, fixed => {
              this.state.navigationFixed !== fixed &&
                        this.setState({ navigationFixed: fixed });
            });
          }
        }
      ).unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
      EventBus.post(new FixBodyEvent(false));
      this.subscriptionBag.destroy();
    }

    render() {
      const {
        cardType,
        benefitType,
        monthlySpending,
        spendings,
        resultAmount,
        resultAnnualCost,
        resultFetchState,
        navigationFixed
      } = this.state;

      const questions = List([
        cardType,
        benefitType,
        monthlySpending
      ]).concat(spendings).toList();
      const forDiscount = benefitType.value === CardQuestionBenefitType.Type.DISCOUNT;
      const result =
            resultFetchState === FetchState.FETCHED ?
              forDiscount ?
                `${toCurrency(resultAmount.discount + resultAmount.point - Math.trunc(resultAnnualCost / 12))}원` :
                `${toCurrency(resultAmount.mileage)}마일` :
              null;
      const confirmAlert = new SurveyConfirm(
        !spendings.isEmpty(),
        `소비패턴을 입력하지 않으면 정확한 추천을 받지 못하실 수 있습니다. 계속하시겠습니까?`
      );

      return (
        <>
          <MetaHelmet meta={ META_SET.CARDS_BEST } />
          <Header active={ FinanceSectorID.CARD } />
          <div className={ styles.wrap }>
            { this.renderCover() }
            <SurveyContainer
              questions={ questions }
              resultTitle="예상 월 혜택"
              resultValue={ result }
              fixed={ true }
              previewMarginTop={ 56 }
              confirmAlert={ confirmAlert }
              linkUrl={ BANKSALAD_SITEMAP.CARDS_PROFITS }
              gaCategory={ GA_CATEGORY.CARDS_QUESTIONS.PREVIEW }
              gaAction={ GA_ACTION.LINK.CARDS_PROFITS }
              onChange={ this.onChange }
            >
              <div className={ styles.container }>
                <div className={ styles.question }>
                  <span className={ styles.tag }>기본</span>
                            필수 입력 항목입니다
                </div>
                <dl className={ styles.form }>
                  <dt className={ styles.essential }>어떤 카드를 찾으세요?</dt>
                  <dd>
                    <CardTypeForm
                      data={ cardType }
                      onChange={ result => this.onChange(result) }
                    />
                  </dd>

                  <dt className={ styles.essential }>어떤 혜택을 선호하세요?</dt>
                  <dd>
                    <CardBenefitTypeForm
                      data={ benefitType }
                      onChange={ result => this.onChange(result) }
                    />
                  </dd>

                  <dt className={ styles.essential }>카드로 월 평균 얼마나 사용하세요?</dt>
                  <dd>
                    <CardMonthlySpendingForm
                      data={ monthlySpending }
                      onChange={ result => this.onChange(result) }
                    />
                  </dd>
                </dl>

                <div className={ styles.question }>
                  <span className={ styles.tag }>개인화</span>
                            정확한 추천을 위한 소비패턴 입력항목입니다
                </div>
                <div className={ styles.navigation }>
                  <StoreCategories
                    fixed={ navigationFixed }
                    onChange={ category => {
                      window.scrollTo(0, FIXED_TOP - FIXED_TOP_EMPTY);
                      this.fetchStoreGroups(category.id);
                    } }
                    onChangeSpending={ spending => this.onChangeSpending(spending) }
                    onSelectedBestCategory={ () => {
                      window.scrollTo(0, FIXED_TOP - FIXED_TOP_EMPTY);
                      this.fetchStoreGroups(null);
                    } }
                    gaCategory={ GA_CATEGORY.CARDS_QUESTIONS.STORE_GROUP_MENU }
                  />
                </div>
                <div className={ styles.spendings }>
                  { this.renderSpendings() }
                </div>
              </div>
            </SurveyContainer>
          </div>
          <Footer />
        </>
      );
    }

    private renderSpendings() {
      const {
        storeGroupsFetchState,
        storeGroups,
        spendings
      } = this.state;

      const toStoreForm = (store: Store) =>
        <CardStoreForm
          store={ store }
          spending={ spendings.find(spending => spending.sameStore(store.id)) }
          onChange={ result => this.onChangeSpending(result) }
          gaCategory={ GA_CATEGORY.CARDS_QUESTIONS.SPENDING_STORE(store.name) }
        />;

      return lets(storeGroupsFetchState, it => {
        switch (it) {
        case FetchState.FETCHING:
          return <Loader padding={ 30 } radius={ 25 } />;
        case FetchState.ERROR:
          return (
            <FetchError padding={ FetchEmptyPaddingSize.MEDIUM }>
                            데이터를 불러오는 중 에러가 발생했습니다<br/>
                            잠시 후 다시 시도해주세요
            </FetchError>
          );
        default:
          return (
            <ul className={ styles.groups }>
              {
                storeGroups.map(group =>
                  <li key={ group.name }>
                    <h5 className={ styles.groupTitle }>{ group.name }</h5>
                    <div className={ styles.group }>
                      <CardStoreGroupForm
                        storeGroup={ group }
                        toStoreForm={ store => toStoreForm(store) }
                        gaCategory={ GA_CATEGORY.CARDS_QUESTIONS.SPENDING_STORE_GROUP(group.name) }
                      />
                    </div>
                  </li>
                )
              }
            </ul>
          );
        }
      });
    }

    private isPreviewActivated(): boolean {
      return (
        this.state.cardType.isValid() &&
            this.state.benefitType.isValid() &&
            this.state.monthlySpending.isValid()
      );
    }

    private onChange = (target: Question) => {
      const fetcher = () => this.fetchResult();

      if (target instanceof CardQuestionCardType) {
        this.setState({ cardType: target }, fetcher);
      } else if (target instanceof CardQuestionBenefitType) {
        this.setState({ benefitType: target }, fetcher);
      } else if (target instanceof CardQuestionMonthlySpending) {
        const totalSpending = this.state.spendings.reduce(
          (acc, spending) => acc + spending.amount, 0
        );

        if (totalSpending > target.value) {
          toast('월 평균 금액은 소비패턴 금액 합 이상으로만 설정할 수 있습니다.');
        }

        this.setState({
          monthlySpending: new CardQuestionMonthlySpending(
            Math.max(totalSpending, target.value)
          )
        }, fetcher);
      } else if (target instanceof CardQuestionSpending) {
        this.onChangeSpending(target);
      }
    };

    private onChangeSpending = (spending: CardQuestionSpending) => {
      const spendings = this.state.spendings
        .filter(it => !it.sameStore(spending.storeId))
        .toList()
        .push(spending)
        .filter(it => it.isValid())
        .toList();

      const totalAmount = spendings.reduce(
        (acc, spending) => acc + spending.amount, 0
      );

      this.setState(state => ({
        monthlySpending: totalAmount > state.monthlySpending.value ?
          new CardQuestionMonthlySpending(totalAmount) :
          state.monthlySpending,
        spendings
      }), () => this.fetchResult());
    };

    private fetchSpec() {
      Application.useCases.getCachedCardRecommendSpec
        .runOnAnimateFrame()
        .subscribe(
          spec => {
            this.setState({
              cardType: generateCardQuestionCardType(spec.filter.cardTypes),
              annualCostMin: spec.filter.annualCostMin,
              annualCostMax: spec.filter.annualCostMax,
              benefitType: generateCardQuestionBenefitType(spec.benefitTypes),
              monthlySpending: generateCardQuestionMonthlySpending(spec.spendings),
              spendings: generateCardQuestionSpendings(spec.spendings)
            }, () => {
              this.fetchResult();
            });
          },
          () => null
        )
        .unsubscribeBy(this.subscriptionBag);
    }

    private fetchConfig() {
      Application.useCases.getCardConfiguration
        .runOnAnimateFrame()
        .subscribe(
          config =>
            this.setState({
              companies: config.companies.entrySeq().map(([key]) => key).toList()
            }, () => this.fetchStoreGroups(null)),
          () =>
            this.setState({
              companies: null,
            }, () => this.fetchStoreGroups(null))
        ).unsubscribeBy(this.subscriptionBag);
    }

    private fetchResult() {
      const {
        cardType,
        benefitType,
        monthlySpending,
        spendings,
        annualCostMin,
        annualCostMax,
        companies,
      } = this.state;

      if (!this.isPreviewActivated()) {
        return;
      }

      this.setState({
        resultFetchState: FetchState.FETCHING
      }, () => {
        apply(Application.useCases.recommendCardSimply, it => {
          it.annualCostMin = annualCostMin;
          it.annualCostMax = annualCostMax;
          it.monthlySpending = monthlySpending.value;
          it.cardTypes = cardType.types;
          it.benefitTypes = benefitType.toBenefitTypes();
          it.spendings = spendings.map(spending => spending.toEntity()).toList();
          it.companies = companies;
        }).runOnAnimateFrame().subscribe(
          result =>
            this.setState({
              resultAmount: result.first,
              resultAnnualCost: result.second,
              resultFetchState: FetchState.FETCHED
            }),
          () =>
            this.setState({
              resultFetchState: FetchState.ERROR
            })
        ).unsubscribeBy(this.subscriptionBag);
      });
    }

    private fetchStoreGroups(categoryId: number = null) {
      this.setState({
        storeGroupsFetchState: FetchState.FETCHING
      }, () => {
        const applier = (groups: List<StoreGroup>) =>
          this.setState({
            storeGroups: groups,
            storeGroupsFetchState: FetchState.FETCHED
          }, () => this.fetchSpec());

        const errorHandler = () =>
          this.setState({
            storeGroupsFetchState: FetchState.ERROR
          });

        if (categoryId) {
          apply(Application.useCases.getStoreGroups, it => {
            it.categoryId = categoryId;
            it.numberOfStores = SIZE_OF_STORE_GROUP;
          }).runOnAnimateFrame().subscribe(
            applier,
            errorHandler
          ).unsubscribeBy(this.subscriptionBag);
        } else {
          apply(Application.useCases.getBestStoreGroups, it => {
            it.numberOfStores = SIZE_OF_STORE_GROUP;
          }).runOnAnimateFrame().subscribe(
            applier,
            errorHandler
          ).unsubscribeBy(this.subscriptionBag);
        }
      });
    }

    private renderCover = () => {
      return (
        <div className={ styles.cover }>
          <h1 className={ styles.coverTitle }><span>자주 쓰는 곳을 고르면 <br/></span>
            <strong>혜택 1원까지</strong> 계산해서 <br/><strong>신용카드 추천</strong>해 드려요
          </h1>
          <p className={ styles.coverDescription }>지금 쓰는 카드보다 더 혜택 좋은 신용카드를 확인하세요</p>
        </div>
      );
    };
}
