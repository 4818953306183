import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanComputationResultSummary } from 'core/entity/loan/computation-result-summary';
import { LoanInterestConfigurationSummaryMapper } from 'data/http/mapper/loan/interest-configuration-summary';
import { LoanOptionMapper } from 'data/http/mapper/loan/option';
import { List } from 'immutable';

export class LoanComputationResultSummaryMapper implements NetworkResponseMapper<LoanComputationResultSummary> {
    fromJson(json: any): LoanComputationResultSummary {
        return new LoanComputationResultSummary(
            new LoanInterestConfigurationSummaryMapper().fromJson(json.interest_configuration),
            json.maximum_amount_limit,
            List(json.options.map((option: any) => new LoanOptionMapper().fromJson(option)))
        )
    }

}
