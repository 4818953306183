import { HttpStatusCode } from 'data/http';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { globalAlert } from 'presentation/module/sugar';
import { isBanksaladWebView } from 'utils/is-banksalad-web-view';

const LOCKED_ALERT_TITLE = '잠김계정입니다';
const LOCKED_ALERT_MESSAGE =
    '고객님의 요청으로 계정이 정지 상태로 변환되었습니다.<br/>정지 상태 해제는 뱅크샐러드 앱에서 본인인증을 통해 언제든 가능합니다.';
const PAGE_CONVERSION_THRESHOLD = 3000;

export const networkErrorRedirectHandler = (statusCode: HttpStatusCode) => {
  const callbackWhenLocked = () => {
    if (isBanksaladWebView()) {
      return location.href = BANKSALAD_SITEMAP.SUSPENDED;
    }

    localStorage.clear();
    sessionStorage.clear();
    globalAlert(
      LOCKED_ALERT_TITLE,
      LOCKED_ALERT_MESSAGE,
      () => location.href = BANKSALAD_SITEMAP.MAIN
    );
  };

  const callbackWhenUnauthorized = () => {
    if (isBanksaladWebView()) {
      return location.href = BANKSALAD_SITEMAP.TOKEN_EXPIRED;
    }

    setTimeout(
      () => location.href = BANKSALAD_SITEMAP.MAIN,
      PAGE_CONVERSION_THRESHOLD
    );
  };

  switch (statusCode) {
  case HttpStatusCode.LOCKED:
    return callbackWhenLocked();
  case HttpStatusCode.UNAUTHORIZED:
    return callbackWhenUnauthorized();
  default:
    return;
  }
};
