import { List } from 'immutable';
import { CardProfit } from 'core/entity/card/profit';
import { CardAmount } from 'core/entity/card/amount';
import { CardSpending } from 'core/entity/card/spending';

export class CardInfoProfitsModel {
    profits: List<CardProfit>;
    noBenefitSpendings: List<CardSpending>;
    summary: CardAmount;
    annualCostWithPromotion: number;
    maximumAnnualCost: number;
    forDiscount: boolean;
    spendingCount: number;
    inPromotion: boolean;

    constructor(
        profits: List<CardProfit>,
        noBenefitSpendings: List<CardSpending>,
        summary: CardAmount,
        annualCostWithPromotion: number,
        maximumAnnualCost: number,
        forDiscount: boolean,
        spendingCount: number,
        inPromotion: boolean
    ) {
        this.profits = profits;
        this.noBenefitSpendings = noBenefitSpendings;
        this.summary = summary;
        this.annualCostWithPromotion = annualCostWithPromotion;
        this.maximumAnnualCost = maximumAnnualCost;
        this.forDiscount = forDiscount;
        this.spendingCount = spendingCount;
        this.inPromotion = inPromotion;
    }

    getSpendingAmount(): number {
        return this.profits.reduce((acc, value) =>
            acc + value.spending.expense, 0
        )
    }
}
