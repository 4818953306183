import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanInterestCondition } from 'core/entity/loan/interest-condition';
import { LoanBaseInterestGroupMapper } from 'data/http/mapper/loan/base-interest-group';
import { List } from 'immutable';
import { LoanPrimeInterestNodeMapper } from 'data/http/mapper/loan/prime-interest-node';
import { LoanInterestValueMapper } from 'data/http/mapper/loan/interest-value';
import { optional } from 'utils/index';

export class LoanInterestConditionMapper implements NetworkResponseMapper<LoanInterestCondition> {
    fromJson(json: any): LoanInterestCondition {
        return new LoanInterestCondition(
            optional(json.base_interests, baseInterests =>
                List(baseInterests.map((item: any) =>
                    new LoanBaseInterestGroupMapper().fromJson(item)
                ))
            ),
            optional(json.additional_interest, additionalInterest =>
                new LoanInterestValueMapper().fromJson(additionalInterest)
            ),
            optional(json.average_interests, averageInterests =>
                List(averageInterests.map((item: any) =>
                    new LoanInterestValueMapper().fromJson(item)
                ))
            ),
            List(json.prime_interests.map((item: any) =>
                new LoanPrimeInterestNodeMapper().fromJson(item)
            ))
        )
    }

}
