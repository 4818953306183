import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { AdviceType, AdviceTypeId } from 'core/entity/personal-finance/advice/type';

export class AdviceTypeMapper implements NetworkResponseMapper<AdviceType> {
    fromJson(json: any): AdviceType {
        const adviceFilteredByCustom = (json.split('_').toList()).first() === 'custom';
        
        if (adviceFilteredByCustom) {
            return new AdviceType(AdviceTypeId.CUSTOM, json);
        }

        switch (json) {
            case 'monthly_report':
                return new AdviceType(AdviceTypeId.MONTHLY_REPORT, json);
            case 'weekly_report':
                return new AdviceType(AdviceTypeId.WEEKLY_REPORT, json);
            case 'card_installment_summary':
                return new AdviceType(AdviceTypeId.CARD_INSTALLMENT_SUMMARY, json);
            case 'excessive_consumption_alert_level_1': // TODO: delete legacy type after migration
            case 'excessive_spending_alert_level_1':
                return new AdviceType(AdviceTypeId.EXCESSIVE_SPENDING_ALERT_LEVEL_1, json);
            case 'excessive_consumption_alert_level_2': // TODO: delete legacy type after migration
            case 'excessive_spending_alert_level_2':
                return new AdviceType(AdviceTypeId.EXCESSIVE_SPENDING_ALERT_LEVEL_2, json);
            case 'excessive_consumption_alert_level_3': // TODO: delete legacy type after migration
            case 'excessive_spending_alert_level_3':
                return new AdviceType(AdviceTypeId.EXCESSIVE_SPENDING_ALERT_LEVEL_3, json);
            case 'reduced_alcohol_consumption': // TODO: delete legacy type after migration
            case 'reduced_spending_alcohol':
                return new AdviceType(AdviceTypeId.REDUCED_SPENDING_ALCOHOL, json);
            case 'late_night_alcohol_consumption': // TODO: delete legacy type after migration
            case 'late_night_spending_alcohol':
                return new AdviceType(AdviceTypeId.LATE_NIGHT_SPENDING_ALCOHOL, json);
            case 'excessive_spending_taxi':
                return new AdviceType(AdviceTypeId.EXCESSIVE_SPENDING_TAXI, json);
            case 'reduced_spending_taxi':
                return new AdviceType(AdviceTypeId.REDUCED_SPENDING_TAXI, json);
            case 'late_night_internet_shopping':
                return new AdviceType(AdviceTypeId.LATE_NIGHT_INTERNET_SHOPPING, json);
            case 'excessive_spending_cafe':
                return new AdviceType(AdviceTypeId.EXCESSIVE_SPENDING_CAFE, json);
            case 'monthly_card_benefit_report_level_1':
                return new AdviceType(AdviceTypeId.MONTHLY_CARD_BENEFIT_REPORT_LEVEL_1, json);
            case 'monthly_card_benefit_report_level_2':
                return new AdviceType(AdviceTypeId.MONTHLY_CARD_BENEFIT_REPORT_LEVEL_2, json);
            case 'monthly_card_benefit_report_level_3':
                return new AdviceType(AdviceTypeId.MONTHLY_CARD_BENEFIT_REPORT_LEVEL_3, json);
            case 'monthly_card_benefit_report_level_4':
                return new AdviceType(AdviceTypeId.MONTHLY_CARD_BENEFIT_REPORT_LEVEL_4, json);
            case 'welcome':
                return new AdviceType(AdviceTypeId.WELCOME, json);
            case 'credit_score_inquiry_suggestion':
                return new AdviceType(AdviceTypeId.CREDIT_SCORE_INQUIRY_SUGGESTION, json);
            default:
                throw new MappingError();
        }
    }
}
