import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { LoanCompanyType } from 'core/entity/personal-finance/loan-company/type';

export class LoanCompanyTypeMapper implements
    NetworkRequestMapper<LoanCompanyType>,
    NetworkResponseMapper<LoanCompanyType>
{
    toJson(target: LoanCompanyType): any {
        switch (target) {
            case LoanCompanyType.BANK:
                return 'bank';
            default:
                return null;
        }
    }

    fromJson(json: any): LoanCompanyType {
        switch (json) {
            case 'bank':
                return LoanCompanyType.BANK;
            default:
                return null;
        }
    }

}
