import { UseCase } from 'core/use-case';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardRecommendRepositoryType } from 'core/repository/card-recommend';
import { Observable } from 'rxjs/Rx';

export class GetCachedCardRecommendSpec extends UseCase<CardRecommendSpec> {
    private repository: CardRecommendRepositoryType;

    constructor(repository: CardRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<CardRecommendSpec> {
        return this.repository.getCachedSpec();
    }

    protected validate(): boolean {
        return true;
    }
}
