import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { PresentableAsset } from 'presentation/module/extension/personal-finance/base';

declare module 'core/entity/personal-finance/bank-account' {
    export interface UserBankAccount extends PresentableAsset {
        presentIdentity: () => string;
        isExpired: () => boolean;
    }
}

UserBankAccount.prototype.presentName = function() {
    return this.nickname ?
        this.nickname :
        this.primaryData.spider ?
            this.primaryData.spider.name :
            this.number
};

UserBankAccount.prototype.presentIdentity = function() {
    const bankName = this.bank.name.replace(/은행/i, '');
    return `${bankName} ${this.number ? this.number : ''}`
};

UserBankAccount.prototype.isExpired = function() {
    return this.closed || this.dormant
};
