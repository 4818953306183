import { List } from 'immutable';
import { Carousel } from 'presentation/components/carousel';
import {
  BdsColorIconTicketWonOriginalSvg,
  BdsMonoIconBubbleSmilingFaceWithCircleOneSolidSvg,
  BdsMonoIconDnaSolidSvg,
  BdsMonoIconGaugeWonLine,
  BdsMonoIconHouseSolid 
} from 'presentation/components/shared/bpl/icons';
import { trackClickHeroBanner } from 'presentation/module/analytics/events/trackers/hero';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import {
  BASE_URL,
  CDN_BASE_URL,
} from 'presentation/routes-app/service-center/components/list-item';
import { getBenefitSumText } from 'presentation/routes/sectors/card/utils';
import React from 'react';
import { lets } from 'utils/index';

import styles from './styles.pcss';

interface State {
  page: number;
  cardBenefitSumText: string;
}

const PAGE_SIZE = 6;
let pagingTimer: ReturnType<typeof setInterval> = null;
export const CARD_EVENT_REWARD = '103';

export class MainHeroBanner extends React.Component<{}, State> {
  state = {
    page: 0,
    cardBenefitSumText: '',
  };

  componentDidMount() {
    pagingTimer = setInterval(this.moveNextPage, 5000);
    (async () => {
      this.setState({ cardBenefitSumText: await getBenefitSumText() });
    })();
  }

  componentWillUnmount() {
    clearTimeout(pagingTimer);
  }

  render() {
    const { page } = this.state;

    return (
      <div className={styles.wrap}>
        <Carousel position={page} onPositionChange={page => this.onSetPage(page)()}>
          <ul className={styles.carousel}>
            {this.renderRecommendLoansBanner()}
            {this.renderLowestLoanRatesBanner()}
            {this.renderAppBanner()}
            {this.renderCuration()}
            {this.renderCardBanner()}
            {this.renderHealthBanner()}
          </ul>
        </Carousel>
        {this.renderCarouselNavigation()}
      </div>
    );
  }

  private renderCarouselNavigation = () => {
    const { page } = this.state;

    return (
      <ul className={styles.navigation}>
        {List()
          .setSize(PAGE_SIZE)
          .map((_, i) => (
            <li key={`nav-${i}`}>
              <button
                className={page === i ? styles.activeNavigationButton : styles.navigationButton}
                onClick={this.onSetPage(i)}
              >
                {`${i + 1}페이지 배너 보기`}
              </button>
            </li>
          ))}
      </ul>
    );
  };

  /**
   * @figma
   * https://www.figma.com/file/edmexasyqay5nY0R2Ig9Rw/%EC%9B%B9_Hero-IMG?type=design&node-id=119-1533&mode=design&t=9yxH6EahXYyvnFpJ-0
   */
  private renderRecommendLoansBanner = () => {
    const CTA_BUTTONS_TEXT = {
      credit: '신용대출 바로가기',
      mortgage: '주택담보대출 바로가기',
    };

    return (
      <li className={styles.carouselItemRecommendLoansBanner}>
        <section className={styles.recommendLoansBannerSection}>
          <div className={styles.recommendLoansBannerInfo}>
            <h2>
              뱅샐에서 대출받으면
              <em>금리 0.1% 무조건 할인</em>
            </h2>
            <h3>나에게 유리한 대출을 찾아보세요</h3>

            <div className={styles.recommendLoansBannerButtonBox}>
              <a
                href={`/prequalification/loans/credit`}
                onClick={() => {
                  () => trackClickHeroBanner(CTA_BUTTONS_TEXT.credit);
                }}
                className={styles.recommendLoansBannerButton}
              >
                <BdsMonoIconGaugeWonLine width={24} height={24} />
                <strong>{CTA_BUTTONS_TEXT.credit}</strong>
              </a>
              <a
                href={`/prequalification/loans/mortgage`}
                onClick={() => {
                  () => trackClickHeroBanner(CTA_BUTTONS_TEXT.mortgage);
                }}
                className={styles.recommendLoansBannerButton}
              >
                <BdsMonoIconHouseSolid width={24} height={24} />
                <strong>{CTA_BUTTONS_TEXT.mortgage}</strong>
              </a>
            </div>
          </div>

          <img
            src={`${CDN_BASE_URL}/graphic/color/illustration/hero-image-web/loan-coupon.png`}
            alt="데스크탑 히어로 쿠폰 이미지"
          />

          <img
            src={`${CDN_BASE_URL}/graphic/color/illustration/hero-image-mobile/loan-coupon.png`}
            alt="모바일 히어로 쿠폰 이미지"
          />
        </section>
      </li>
    );
  };

  /**
   * @figma
   * https://www.figma.com/file/edmexasyqay5nY0R2Ig9Rw/%EB%82%B4%EB%A7%8C%EC%B5%9C_Hero-IMG?type=design&node-id=1-4077&t=Kt2CIu4dwhqMHjiS-4
   */
  private renderLowestLoanRatesBanner = () => {
    const CTA_BUTTON_TEXT = '금리 캐시백 쿠폰 받기';

    return (
      <li className={styles.carouselItemLowestLoanRatesBanner}>
        <section className={styles.lowestLoanRatesBannerSection}>
          <section className={styles.lowestLoanRatesBannerInfo}>
            <h2>
              대출 최저금리{'\n'}
              <small>내가 만들 수 있다면?</small>
            </h2>
            <h3>
              {'뱅샐 금리 캐시백 쿠폰을 모아 '}
              <br className={styles.onlyMobile} />
              대출 금리를 낮춰보세요
            </h3>

            <a
              href={`${BASE_URL}/prequalification/loans/event/upgrade`}
              onClick={() => {
                () => trackClickHeroBanner(CTA_BUTTON_TEXT);
              }}
              className={styles.lowestLoanRatesBannerCtaButton}
            >
              <BdsColorIconTicketWonOriginalSvg />
              <strong>{CTA_BUTTON_TEXT}</strong>
            </a>
          </section>

          <img
            src={`${CDN_BASE_URL}/graphic/color/illustration/curation/rising-interest-rate.png`}
            alt="대출 최저금리 배너 이미지"
            className={styles.lowestLoanRatesBannerImage}
          />
        </section>
      </li>
    );
  };

  private renderAppBanner = () => (
    <li className={styles.carouselItemApp}>
      <div className={styles.container}>
        <div className={styles.title}>
          금융을 내 편으로
          <br />
          뱅크샐러드
        </div>
        <div className={styles.buttons}>
          <a
            href="https://banksalad.page.link/31eV"
            className={styles.greenButton}
            onClick={() =>
              sendGAEvent(
                GA_DOMAIN.NONE,
                GA_CATEGORY.LANDING.HERO_BANKSALAD,
                GA_ACTION.LINK.APP_DOWNLOAD,
                '앱다운로드페이지'
              )
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            뱅크샐러드 사용해보기
          </a>
          <a
            href="https://go.banksalad.com/introduce_services/"
            className={styles.whiteButton}
            onClick={() =>
              sendGAEvent(
                GA_DOMAIN.NONE,
                GA_CATEGORY.LANDING.HERO_BANKSALAD,
                GA_ACTION.LINK.APP_INTRODUCE,
                '앱소개보기'
              )
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            서비스 더 알아보기
          </a>
        </div>
      </div>
    </li>
  );

  private renderCardBanner = () => (
    <li className={styles.carouselItemCard}>
      <div className={styles.container}>
        <div className={styles.titleCard}>
          이벤트 응모하고
          <br />
          카드 사용하면
          <br />
          {this.state.cardBenefitSumText} 받아요!
        </div>
        <p className={styles.descriptionCard}>기간 한정 이벤트이니 지금 참여하세요.</p>
        <div className={styles.buttons}>
          <a
            href={BANKSALAD_SITEMAP.CARDS_PROMOTIONS_BEST_CREDIT_CARDS}
            className={styles.mustardButton}
            onClick={() =>
              sendGAEvent(
                GA_DOMAIN.CARD,
                GA_CATEGORY.MAIN_ROLLING_BANNER_HERO,
                GA_ACTION.LINK.CARDS_PROMOTIONS_BEST_CREDIT_CARDS,
                '카드 이벤트'
              )
            }
          >
            이벤트 보기
          </a>
        </div>
      </div>
    </li>
  );

  private renderCuration = () => (
    <li className={styles.carouselItemCuration}>
      <div className={styles.container}>
        <div className={styles.title}>
          <small>대출 앞에 작아질 때!</small>
          <br />
          뱅크샐러드 금융매칭
        </div>
        <p className={styles.description}>
          1분만에, 은행 방문 없이, <br className={styles.onlyMobile} />
          신용점수 영향 없이
        </p>
        <div className={styles.buttons}>
          <a
            href={'/loan'}
            className={styles.violetButton}
            onClick={() =>
              sendGAEvent(
                GA_DOMAIN.LOANS,
                GA_CATEGORY.LANDING.HERO,
                GA_ACTION.LINK.LOANS_IS_MY_SIDE,
                '내 최저금리 조회하기'
              )
            }
          >
            내 최저금리 조회하기
          </a>
        </div>
      </div>
    </li>
  );

 /**
   * @figma
   * https://www.figma.com/file/edmexasyqay5nY0R2Ig9Rw/%EC%9B%B9_Hero-IMG?type=design&node-id=188-2013&mode=design&t=QP85eYxbuMDjDfxX-4
   */
 private renderHealthBanner = () => {
   const CTA_BUTTONS_TEXT = {
     dtcGeneticTesting: '유전자 검사 무료로 하기',
     oralMicrobiomeTesting: '미생물 검사 무료로 하기',
   };

   return (
     <li className={styles.carouselItemRecommendHealthBanner}>
       <section className={styles.recommendHealthBannerSection}>
         <div className={styles.recommendHealthBannerInfo}>
           <h2>
             <p className={styles.recommendHealthBannerTitle}>30만원 상당의 검사를 무료로</p>
             <em>오직 뱅크샐러드에서</em>
           </h2>

           <div className={styles.recommendHealthBannerButtonBox}>
             <a
               href={`/health/dtc-genetic-testing?funnel=bs_web_home_hero`}
               onClick={() => {
                 () => trackClickHeroBanner(CTA_BUTTONS_TEXT.dtcGeneticTesting);
               }}
             >
               <BdsMonoIconDnaSolidSvg width={24} height={24} />
               <strong>{CTA_BUTTONS_TEXT.dtcGeneticTesting}</strong>
             </a>
             <a
               href={`/health/oral-microbiome-testing?funnel=bs_web_home_hero`}
               onClick={() => {
                 () => trackClickHeroBanner(CTA_BUTTONS_TEXT.oralMicrobiomeTesting);
               }}
             >
               <BdsMonoIconBubbleSmilingFaceWithCircleOneSolidSvg width={24} height={24} />
               <strong>{CTA_BUTTONS_TEXT.oralMicrobiomeTesting}</strong>
             </a>
           </div>
         </div>

         <img
           className={styles.recommendHealthBannerImage}
           src={`${CDN_BASE_URL}/graphic/color/illustration/hero-image-web/microbiome-gene-kit.png`}
           alt="원격검사 키트 이미지"
         />
       </section>
     </li>
   );
 };


  private moveNextPage = () =>
    this.setState(state => ({
      page: lets(state.page + 1, it => {
        if (it < 0) {
          return PAGE_SIZE - 1;
        } else if (it >= PAGE_SIZE) {
          return 0;
        } else {
          return it;
        }
      }),
    }));

  private onSetPage = (page: number) => () =>
    this.setState({
      page: Math.max(Math.min(page, PAGE_SIZE - 1), 0),
    });
}
