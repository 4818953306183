import React from 'react';

import styles from '../../../styles.pcss';

export const PoliciesV1PrivacyAgreementsExcelExportView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 개인정보 수집ㆍ이용 동의</h3>
      <p>엑셀 내보내기 서비스 제공을 위하여 아래와 같이 개인정보 수집 및 이용 동의를 받고자 합니다.</p>
      <ol>
        <li>1. 개인정보 수집 및 이용 목적 : 엑셀 내보내기 서비스 제공</li>
        <li>
                    2. 수집하는 개인정보 항목
          <ul>
            <li>필수 정보 : 이메일 주소</li>
          </ul>
        </li>
        <li>3. 개인정보 보유 및 이용 기간 : 이메일 전송 완료 즉시 파기</li>
      </ol>
      <br/>
      <p>개인정보 수집 및 이용 동의를 거부할 권리가 있으며, 동의 거부 시 엑셀 내보내기 서비스를 이용하실 수 없습니다.</p>
    </div>
  </div>
);

