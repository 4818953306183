import { NetworkRequestMapper } from 'data/http/mapper';
import { CardBenefitType } from 'core/entity/card/benefit-type';
import { lets } from 'utils/index';

export class CardBenefitTypeMapper implements NetworkRequestMapper<CardBenefitType> {
    toJson(target: CardBenefitType): any {
        return lets(target, it => {
            switch (it) {
                case CardBenefitType.ALL:
                    return 'all';
                case CardBenefitType.DISCOUNT:
                    return 'discount';
                case CardBenefitType.POINT:
                    return 'point';
                case CardBenefitType.MILEAGE_AA:
                    return 'mileage-AA';
                case CardBenefitType.MILEAGE_KA:
                    return 'mileage-KA';
                case CardBenefitType.MILEAGE_OTHERS:
                    return 'mileage-OTHERS';
                default:
                    return 'all';
            }
        });
    }
}
