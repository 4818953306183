import { UserProductConfiguration } from 'core/entity/personal-finance/product-configuration';
import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserStock } from 'core/entity/personal-finance/stock';
import { UserCard } from 'core/entity/personal-finance/card';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserFund } from 'core/entity/personal-finance/fund';

declare module 'core/entity/personal-finance/product-configuration' {
    export interface UserProductConfiguration {
        toPureAssetAmount(totalBills: number): number;
        noSpiderHistory(): boolean;
        isEmpty(): boolean;
    }
}

UserProductConfiguration.prototype.toPureAssetAmount = function(totalBills: number) {
    return (
        this.amountSummary.asset.amount +
        this.amountSummary.investment.amount -
        this.amountSummary.debt.amount -
        totalBills
    )
};

UserProductConfiguration.prototype.noSpiderHistory = function() {
    return (
        this.bankAccounts.filter((it: UserBankAccount) => !!it.primaryData.spider).isEmpty() &&
        this.cards.filter((it: UserCard) => !!it.primaryData.spider).isEmpty() &&
        this.loans.filter((it: UserLoan) => !!it.primaryData.spider).isEmpty() &&
        this.securitiesFirmAccounts.filter((it: UserSecuritiesFirmAccount) => !!it.primaryData.spider).isEmpty() &&
        this.funds.filter((it: UserFund) => !!it.primaryData.spider).isEmpty() &&
        this.stocks.filter((it: UserStock) => !!it.primaryData.spider).isEmpty()
    )
};

UserProductConfiguration.prototype.isEmpty = function() {
    return (
        this.funds.isEmpty() &&
        this.stocks.isEmpty() &&
        this.loans.isEmpty() &&
        this.securitiesFirmAccounts.isEmpty() &&
        this.cashAccounts.filter((it: UserCashAccount) => !it.isDefault).isEmpty() &&
        this.cards.isEmpty() &&
        this.bankAccounts.isEmpty()
    )
};
