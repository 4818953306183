import { TransactionCreateSpec } from 'core/entity/personal-finance/transaction/create-spec';

declare module 'core/entity/personal-finance/transaction/create-spec' {
    export interface TransactionCreateSpec {
        isActivated(): boolean;
    }
}

TransactionCreateSpec.prototype.isActivated = function () {
    return !!this.title && !!this.product.id && !!this.transactedAt && !!this.amount && !!this.type;
};
