import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { Entity } from 'core/entity';
import { List } from 'immutable';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';
import { AdviceTransactionPeriodSnapshot } from 'core/entity/personal-finance/advice/transaction-period-snapshot';

export class AdviceReducedSpendingAlcoholData extends Entity implements AdviceContentData {
    data: List<AdviceTransactionPeriodSnapshot>;
    baseDatetime: Date;
    message?: AdviceMessage;

    constructor(
        data: List<AdviceTransactionPeriodSnapshot>,
        baseDatetime: Date,
        message: AdviceMessage = null
    ) {
        super();
        this.data = data;
        this.baseDatetime = baseDatetime;
        this.message = message;
    }
}
