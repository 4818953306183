import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentTagSummaryMapper } from 'data/http/mapper/content/tag/summary';
import { ContentMetaInformationMapper } from 'data/http/mapper/content/meta-information';
import { ContentCardComparison } from 'core/entity/content/card-comparison';
import { ContentCardComparisonArticleMapper } from 'data/http/mapper/content/card-comparison/article';
import { PromotedContentSetMapper } from 'data/http/mapper/promoted-content-set';
import { ContentAuthorSummaryMapper } from 'data/http/mapper/content/author/summary';
import { parseDate, optional } from 'utils/index';
import { ContentSummaryMapper } from 'data/http/mapper/content/summary';

export class ContentCardComparisonMapper implements NetworkResponseMapper<ContentCardComparison> {
    fromJson(json: any): ContentCardComparison {
        return new ContentCardComparison(
            json.id,
            json.title,
            json.description,
            new ContentAuthorSummaryMapper().fromJson(json.author),
            json.thumbnail_image_url,
            parseDate(json.created_at),
            parseDate(json.updated_at),
            json.published,
            json.featured,
            List(json.tags.map((tag: any) =>
                new ContentTagSummaryMapper().fromJson(tag)
            )),
            new ContentMetaInformationMapper().fromJson(json.meta),
            new ContentCardComparisonArticleMapper().fromJson(json.article),
            json.hot,
            json.label,
            optional(json.related_contents, it =>  List(it.map((content: any) =>
                new ContentSummaryMapper().fromJson(content)
            ))),
            optional(json.promoted_content_sets, it => List(it.map((set: any) =>
                new PromotedContentSetMapper().fromJson(set)
            ))) || List()
        )
    }
}
