import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1SubscriptionAgreementView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 정보ㆍ광고 수신 동의</h3>
      <table>
        <tbody>
          <tr>
            <th className={ styles.width20 }><b>전송내용</b></th>
            <th className={ styles.width40 }><b>전송 방법</b></th>
          </tr>
          <tr>
            <td className={ styles.width20 }>
              <div>이벤트 정보, 뉴스레터, 금융 관련 정보 및 칼럼, 회원 맞 춤형 혜택 안내</div>
            </td>
            <td className={ styles.width40 }>
              <div>* 문자 메시지(SMS, MMS 등)</div>
              <div>* 이메일</div>
              <div>* 모바일 어플리케이션 Push 알림</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);
