import { NetworkRequestMapper } from 'data/http/mapper';
import { AdviceUpdateSpec } from 'core/entity/personal-finance/advice/update-spec';
import { AdviceReactionMapper } from 'data/http/mapper/personal-finance/advice/reaction';
import { optional } from 'utils/index';

export class AdviceUpdateSpecMapper implements NetworkRequestMapper<AdviceUpdateSpec> {
    toJson(target: AdviceUpdateSpec): any {
        return {
            reaction: optional(target.reaction, new AdviceReactionMapper().toJson)
        }
    }

}
