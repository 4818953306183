import { Observable } from 'rxjs/Rx';
import { UserProductRepositoryType } from 'core/repository/personal-finance/product';
import { UserProductConfiguration } from 'core/entity/personal-finance/product-configuration';
import { UserProductsApiProvider } from 'data/http/api/gateway/v2/users/products';
import { SummarizedUserProducts } from 'core/entity/personal-finance/summarized-products';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserProductRepository implements UserProductRepositoryType {
    private api: UserProductsApiProvider;
    private tokenStorage: AccessTokenStorageProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserProductsApiProvider
    ) {
        this.api = api;
        this.tokenStorage = tokenStorage;
    }

    get(): Observable<UserProductConfiguration> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.get(token)
        )
    }

    getGroupByCompany(hidden?: boolean, deleted?: boolean): Observable<SummarizedUserProducts> {
        return this.tokenStorage.get().flatMap(token =>
            this.api.getGroupByCompany(token, hidden, deleted)
        )
    }
}
