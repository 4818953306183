import { UseCase } from 'core/use-case';
import { CardConfiguration } from 'core/entity/card/configuration';
import { CardConfigurationRepositoryType } from 'core/repository/card-configuration';
import { CardRecommendRepositoryType } from 'core/repository/card-recommend';
import { Pair } from 'utils/index';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { Observable } from 'rxjs/Rx';

type Result = Pair<CardConfiguration, CardRecommendSpec>;

export class GetCardsProfitsConfig extends UseCase<Result> {
    private configRepository: CardConfigurationRepositoryType;
    private recommendRepository: CardRecommendRepositoryType;

    constructor(
        configRepository: CardConfigurationRepositoryType,
        recommendRepository: CardRecommendRepositoryType
    ) {
        super();
        this.configRepository = configRepository;
        this.recommendRepository = recommendRepository;
    }

    protected build(): Observable<Result> {
        return Observable.zip(
            this.configRepository.get(),
            this.recommendRepository.getCachedSpec(),
            (config, cache) => new Pair(config, cache)
        );
    }

    protected validate(): boolean {
        return true;
    }

}
