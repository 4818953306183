export class SignInTextEditorModel {
    type: SignInTextEditorModel.TextEditorType;
    placeholder: string;

    constructor(
        type: SignInTextEditorModel.TextEditorType,
        placeholder: string
    ) {
        this.type = type;
        this.placeholder = placeholder;
    }
}

export module SignInTextEditorModel {
    export enum TextEditorType {
        EMAIL = 'email',
        PASSWORD = 'password',
        TEXT = 'text'
    }
}
