export enum PIXEL {
  MAIN,
  SECTORS_CARD,
  SECTORS_BANK,
  SECTORS_INVESTMENT,
  SECTORS_LOAN,
  SECTORS_ANNUITY,
  SECTORS_FINTECH,
  CARDS_PROFITS,
  CARD,
  CARD_ISSUE,
  CONTENTS,
  CONTENT,
}

export enum KakaoPixelEventName {
  PAGE_VIEW = "pageView",
  COMPLETE_REGISTRATION = "completeRegistration",
  VIEW_CART = "viewCart",
  VIEW_CONTENT = "viewContent",
  SEARCH = "search",
  PURCHASE = "purchase",
}

export const sendPixelEvent = (type: PIXEL, tag?: string) => {
  switch (type) {
  case PIXEL.MAIN:
    fbq("trackCustom", "전체_랜딩");
    break;
  case PIXEL.SECTORS_CARD:
    fbq("trackCustom", "카드_랜딩");
    break;
  case PIXEL.CARDS_PROFITS:
    fbq("trackCustom", "카드_결과");
    window.trackKakaoPixel(KakaoPixelEventName.VIEW_CART);
    break;
  case PIXEL.SECTORS_BANK:
    fbq("trackCustom", "은행_랜딩");
    break;
  case PIXEL.SECTORS_INVESTMENT:
    fbq("trackCustom", "투자_랜딩");
    break;
  case PIXEL.SECTORS_LOAN:
    fbq("trackCustom", "대출_랜딩");
    break;
  case PIXEL.SECTORS_ANNUITY:
    fbq("trackCustom", "연금_랜딩");
    break;
  case PIXEL.SECTORS_FINTECH:
    fbq("trackCustom", "핀테크_랜딩");
    break;
  case PIXEL.CONTENTS:
    fbq("track", "ViewContent");
    fbq("trackCustom", "매거진_랜딩");
    break;
  case PIXEL.CONTENT:
    fbq("trackCustom", "매거진_상세");
    window.trackKakaoPixel(KakaoPixelEventName.VIEW_CONTENT);
    break;
  case PIXEL.CARD:
    window.trackKakaoPixel(KakaoPixelEventName.SEARCH, tag);
    break;
  case PIXEL.CARD_ISSUE:
    window.trackKakaoPixel(KakaoPixelEventName.PURCHASE, tag);
    break;
  }
};
