import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { compactObject, optional, parseDate } from 'utils/index';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { AmountMapper } from 'data/http/mapper/amount';
import { SpiderBankSummaryMapper } from 'data/http/mapper/personal-finance/bank/summary';
import { BankAccountPrimaryDataMapper } from 'data/http/mapper/personal-finance/primary-data/bank-account';

export class UserBankAccountMapper implements
    NetworkResponseMapper<UserBankAccount>,
    NetworkRequestMapper<UserBankAccount>
{
    fromJson(json: any): UserBankAccount {
        return new UserBankAccount(
            json.id,
            new UserBankAccountTypeMapper().fromJson(json.type),
            json.hidden,
            new DeletedStatusMapper().fromJson(json.deleted_status),
            json.closed,
            json.dormant,
            new AmountMapper().fromJson(json.latest_balance),
            new SpiderBankSummaryMapper().fromJson(json.bank),
            new BankAccountPrimaryDataMapper().fromJson(json.primary_data),
            optional(json.number),
            optional(json.nickname),
            optional(json.description),
            optional(json.created_at, parseDate),
            optional(json.expired_at, parseDate),
        )
    }

    toJson(target: UserBankAccount): any {
        return compactObject({
            id: target.id,
            type: new UserBankAccountTypeMapper().toJson(target.type),
            hidden: target.hidden,
            deleted_status: new DeletedStatusMapper().toJson(target.deletedStatus),
            closed: target.closed,
            dormant: target.dormant,
            latest_balance: target.latestBalance,
            bank: new SpiderBankSummaryMapper().toJson(target.bank),
            primary_data: new BankAccountPrimaryDataMapper().toJson(target.primaryData),
            number: optional(target.number),
            nickname: optional(target.nickname),
            description: optional(target.description),
            created_at: optional(target.createdAt),
            expired_at: optional(target.expiredAt)
        });
    }
}

export class UserBankAccountTypeMapper implements
    NetworkRequestMapper<UserBankAccount.Type>,
    NetworkResponseMapper<UserBankAccount.Type>
{
    toJson(target: UserBankAccount.Type): any {
        switch (target) {
            case UserBankAccount.Type.CHECKING:
                return 'checking';
            case UserBankAccount.Type.DEPOSIT:
                return 'deposit';
            case UserBankAccount.Type.SAVING:
                return 'saving';
            case UserBankAccount.Type.TRUST:
                return 'trust';
            default:
                return 'checking';
        }
    }

    fromJson(json: any): UserBankAccount.Type {
        switch (json) {
            case 'checking':
                return UserBankAccount.Type.CHECKING;
            case 'deposit':
                return UserBankAccount.Type.DEPOSIT;
            case 'saving':
                return UserBankAccount.Type.SAVING;
            case 'trust':
                return UserBankAccount.Type.TRUST;
            default:
                return null;
        }
    }
}
