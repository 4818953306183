import { TransactionProduct } from 'core/entity/personal-finance/transaction/product';
import { NetworkRequestMapper } from 'data/http/mapper';
import { TransactionTypeMapper } from 'data/http/mapper/personal-finance/transaction/type';
import { lets, optional } from 'utils/index';
import { TransactionCreateSpec } from 'core/entity/personal-finance/transaction/create-spec';

export class TransactionCreateSpecMapper implements NetworkRequestMapper<TransactionCreateSpec> {
    toJson(target: TransactionCreateSpec): any {
        return {
            title: optional(target.title),
            description: optional(target.description),
            product: optional(target.product, it => ({
                id: it.id,
                type: lets(it.type, type => {
                    switch (type) {
                        case TransactionProduct.Type.BANK_ACCOUNT:
                            return 'bank_account';
                        case TransactionProduct.Type.CARD:
                            return 'card';
                        case TransactionProduct.Type.CASH_ACCOUNT:
                            return 'cash_account';
                        case TransactionProduct.Type.LOAN:
                            return 'loan';
                        default:
                            return null;
                    }
                })
            })),
            transacted_at: optional(target.transactedAt, it => it.format('YYYY-MM-DD HH:mm')),
            amount: optional(target.amount),
            type: optional(target.type, it => new TransactionTypeMapper().toJson(it)),
            category_id: optional(target.categoryId),
            configuration: optional(target.configuration, it => ({
                excluded_from_budget: it.excludedFromBudget
            }))
        }
    }

}
