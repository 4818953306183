import { CardLegalConfigurationRepository } from 'data/repository/card-legal-configuration';
import { LoanLegalConfigurationRepository } from 'data/repository/loan-legal-configuration';
import { CardCompanyLegalConfigurationRepository } from 'data/repository/card-company-legal-configuration';
import { HttpProviderDependencies } from 'application/@context/http-providers';
import { StorageProviderDependencies } from 'application/@context/storage-providers';

export class LegalConfigDomainRepositoryDependencies {
    cardLegalConfig: CardLegalConfigurationRepository;
    cardCompanyLegalConfig: CardCompanyLegalConfigurationRepository;
    loanLegalConfig: LoanLegalConfigurationRepository;

    constructor(http: HttpProviderDependencies, storage: StorageProviderDependencies) {
        this.cardLegalConfig = new CardLegalConfigurationRepository(http.gateway.cardLegalConfig);
        this.cardCompanyLegalConfig = new CardCompanyLegalConfigurationRepository(http.gateway.cardCompanyLegalConfig);
        this.loanLegalConfig = new LoanLegalConfigurationRepository(http.gateway.loanLegalConfig);
    }
}
