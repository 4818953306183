import { Entity } from 'core/entity';

export class CFADeliberationReviewConfiguration extends Entity {
    status: string;

    constructor(
        status: string
    ) {
        super();
        this.status = status;
    }
}
