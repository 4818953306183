import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { LoanStatistics } from 'core/entity/loan/statistics';
import { LoanStatisticsRepositoryType } from 'core/repository/loan-statistics';

export class GetLoanStatistics extends UseCase<LoanStatistics> {
    private repository: LoanStatisticsRepositoryType;

    constructor(repository: LoanStatisticsRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<LoanStatistics> {
        return this.repository.get();
    }

    protected validate(): boolean {
        return true;
    }

}
