import { UserTransaction } from 'core/entity/personal-finance/transaction';
import { TransactionFilter } from 'core/entity/personal-finance/transaction/filter';
import { TransactionUpdateResult } from 'core/entity/personal-finance/transaction/update-result';
import { TransactionUpdateSpec } from 'core/entity/personal-finance/transaction/update-spec';
import { UserTransactionRepositoryType } from 'core/repository/personal-finance/transaction';
import { UserTransactionApiProvider } from 'data/http/api/gateway/v3/me/transactions';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { TransactionCreateSpec } from 'core/entity/personal-finance/transaction/create-spec';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserTransactionRepository implements UserTransactionRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserTransactionApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserTransactionApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    create(spec: TransactionCreateSpec): Observable<UserTransaction> {
        return this.tokenStorage.get()
            .flatMap(token => this.api.create(token, spec));
    }

    getAll(filter: TransactionFilter): Observable<List<UserTransaction>> {
        return this.tokenStorage.get()
            .flatMap(token => this.api.getAll(token, filter));
    }

    update(id: string, spec: TransactionUpdateSpec): Observable<TransactionUpdateResult> {
        return this.tokenStorage.get()
            .flatMap(token => this.api.update(token, id, spec));
    }

    delete(transactions: List<UserTransaction>): Observable<void> {
        return this.tokenStorage.get()
            .flatMap(token => this.api.delete(token, transactions));
    }

    recover(transactions: List<UserTransaction>): Observable<void> {
        return this.tokenStorage.get()
            .flatMap(token => this.api.recover(token, transactions));
    }
}
