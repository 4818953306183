import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV4TermsView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h2>뱅크샐러드 이용약관</h2>
      <p className={ styles.textRight }>효력발생일: 2021년 4월 15일</p>
      <h3>이용약관에 관하여</h3>
      <p>
        뱅크샐러드 서비스를 선택해주신 여러분께 감사드립니다.
        <br/>
        <br/>
        뱅크샐러드 이용약관(이하 “<b>본 약관</b>”)은 주식회사 뱅크샐러드(이하 “<b>회사</b>”)와 회사가 제공하는 뱅크샐러드 서비스(이하 “<b>서비스</b>”)를 이용하는 이용자 사이의 권리·의무 관계를 정합니다. 또한, 이용자가 서비스를 이용함에 있어 필요한 제반 사항들에 대해 설명합니다.
        <br/>
        <br/>
        <b>본 약관에는 서비스 제공 및 이용 과정에서 회사와 이용자가 반드시 준수해야 하는 사항들이 포함되어 있으므로, 서비스를 이용하기 전에 반드시 본 약관을 주의 깊게 읽어보시고 본 약관의 내용에 동의하시는 경우에만 서비스를 이용하시기 바랍니다.</b> 본 약관에서 다루고 있는 주요한 내용은 다음과 같습니다.
      </p>
      <br/>
      <ul>
        <li><a href='/policies/v4/terms/#1'>회원가입</a></li>
        <li><a href='/policies/v4/terms/#2'>서비스 이용</a></li>
        <li><a href='/policies/v4/terms/#3'>제3자 서비스와 상품 등</a></li>
        <li><a href='/policies/v4/terms/#4'>회사의 의무와 책임</a></li>
        <li><a href='/policies/v4/terms/#5'>이용자의 의무와 책임</a></li>
        <li><a href='/policies/v4/terms/#6'>이용자 콘텐츠</a></li>
        <li><a href='/policies/v4/terms/#7'>의사소통</a></li>
        <li><a href='/policies/v4/terms/#8'>소프트웨어</a></li>
        <li><a href='/policies/v4/terms/#9'>서비스 중단 및 장애</a></li>
        <li><a href='/policies/v4/terms/#10'>서비스 탈퇴</a></li>
        <li><a href='/policies/v4/terms/#11'>손해배상</a></li>
        <li><a href='/policies/v4/terms/#12'>분쟁의 해결</a></li>
      </ul>
      <br/>
      <p>
        회사는 필요한 경우에 본 약관(본 약관에서 언급된 각종 정책 등을 포함, 이하 같음)을 개정할 수 있습니다. 본 약관이 개정되는 경우 회사는 변경되는 내용과 변경일자를 서비스 내에 공지합니다. 특히 이용자에게 불리할 수 있는 중대한 내용 변경이 있는 경우에는 최소 30일 이전에 이용자에게 알려, 서비스를 계속 이용할 것인지 여부를 충분히 검토하고 결정할 수 있도록 합니다. <b>변경되는 약관 내용에 동의하지 않으시는 경우 서비스 이용을 중단하셔야 하며, 공지된 약관 변경일자까지 회원가입을 유지하시는 경우에는 변경된 약관 내용에 동의하시는 것으로 간주됩니다.</b>
        <br/>
        <br/>
        회사가 이용자를 위해 다양한 서비스를 개발하고 제공하는 과정에서 본 약관 외에 특정한 서비스나 기능에 적용되는 별도의 이용약관을 제공할 수 있습니다. 그러한 별도의 이용약관은 해당 서비스나 기능에 있어서는 본 약관에 우선하여 적용됩니다. 회사는 이용자가 본 약관을 쉽게 확인 할 수 있도록 서비스 내에 본 약관을 상시 게시합니다.
        <br/>
        <br/>
      </p>
      <br/>
      <h4 id='1'>회원가입에 관하여</h4>
      <p>
        이용자의 다양한 정보를 통합하여 제공하고, 새로운 가치와 편의를 제공하는 서비스의 특성상 서비스를 이용하기 위해서는 반드시 회사가 제공하는 절차에 따라 회원으로 가입해야 합니다. 원활한 회원관리와 편리한 서비스 이용을 위해 회원가입 절차 내에 본인확인이나, 인증서 발급, 정보 보유기관과의 연동과 같은 과정이 포함될 수 있습니다. 회사는 본 약관과 회사가 마련한 각종 정책을 준수할 것을 약속하고, 회원가입절차를 완료한 이용자에 한하여 서비스의 이용을 허락합니다.
        <br/>
        <br/>
        <b>이용자는 어떠한 경우에도 타인의 명의를 사용하여 회원으로 가입하여서는 안됩니다. 또한, 만 14세 미만의 아동은 회원가입 및 서비스 이용이 제한됩니다.</b> 회사는 타인 명의로 회원가입한 이용자, 만 14세 미만의 이용자를 발견한 경우 해당 이용자의 서비스 이용을 차단하며, 이후 재가입 금지 등 필요한 조치를 취할 수 있습니다.
        <br/>
        <br/>
        회사는 서비스 가입 과정에서 회원이나 추천인에 대한 리워드 등 일정한 혜택을 제공할 수 있습니다. 이러한 혜택의 내용은 수시로 변경될 수 있으며, 회사는 이에 관한 사항을 명확하게 공지합니다.
      </p>
      <br/>
      <h4 id='2'>서비스 이용에 관하여</h4>
      <p>
        회사는 서비스 내에 다양한 기능을 제공합니다. 이용자는 서비스를 이용하여 흩어져 있는 자신의 다양한 정보(개인의 금융, 자산, 건강, 통신, 행정 정보 등을 포함하나 이에 한정되지 않음)를 조회할 수 있습니다. 또한 관련 법령상 허용된 이용자의 권리와 동의 권한을 행사하여 제3자가 보유하고 있는 이용자의 정보를 회사에 전송하거나 제공하도록 할 수도 있습니다. 회사는 위와 같은 정보들을 이용자를 위해 가공하고, 분석하여 이용자에게 도움이 되는 새로운 정보를 제공하거나, 합리적인 의사결정을 할 수 있도록 도움을 줍니다. 나아가 회사는 이용자가 스스로 자신의 정보를 관리하고, 통제하며, 유통·활용할 수 있는 다양한 기능들을 개발하여 제공합니다.
        <br/>
        <br/>
        회사는 이용자의 다양한 정보를 통합·분석하고, 이를 이용자가 쉽게 이해할 수 있도록 제공함으로써 이용자가 자기 자신에 대해 더 잘 이해하고, 자신의 정보를 바탕으로 다양한 문제들을 보다 합리적으로 해결할 수 있도록 돕고자 합니다. 예를 들어, 이용자의 금융 자산 현황을 분석하여 이용자에게 보다 나은 금융상품을 추천하거나, 소비 내역을 분석하여 적절한 조언을 제공하며, 건강 정보를 분석하여 바람직한 생활습관을 제안할 수 있습니다. 이용자의 더 많은 정보를, 더 잘 분석해서, 더 많은 도움과 혜택을 드리는 것이 회사와 서비스의 가장 중요한 목적입니다.
        <br/>
        <br/>
        또한, 회사는 이용자의 정보를 분석하여 이용자가 합리적인 의사결정을 해야 하는 순간에 도움이 되는 정보를 제공하기 위해 노력합니다. 또한, 이용자와 관련된 새로운 정보의 발생이나 변경 사실을 알려드릴 수 있습니다. 이러한 정보는 서비스 내에서 다양한 방법으로 제공되며, 필요한 경우에는 앱푸시 알림이나 회사가 합리적이라고 판단하는 별도의 방법으로 이용자에게 적시에 제공될 수 있도록 노력합니다. 이용자는 서비스 내에서 이러한 정보 제공 설정을 직접 변경할 수 있습니다.
        <br/>
        <br/>
        나아가, 회사는 이용자가 금융상품이나 일반 재화 및 용역(이하 “상품 등”)을 선택함에 있어서 보다 합리적인 결정을 할 수 있도록 이용자의 정보를 분석하여 이용자에게 적합한 상품 등을 맞춤형으로 추천하거나, 이에 관한 컨설팅을 제공할 수 있습니다. 이러한 기능은 본질적으로 특정한 상품 등에 대한 광고나 홍보의 성격을 포함할 수 밖에 없으나, 회사는 이러한 기능을 제공함에 있어 회사나 제3자의 이익을 위해 이용자에게 적합하지 않은 상품 등을 추천하거나 권유하지 않습니다.
        <br/>
        <br/>
        이용자는 서비스를 무료로 이용할 수 있습니다. 다만, 회사가 서비스 내에서 별도의 유료 서비스나 기능을 제공하는 경우 이에 대한 별도 이용약관을 제공하고, 반드시 이용자의 명시적인 서비스 이용 의사를 확인하여 해당 유료 서비스나 기능을 제공할 것입니다. 이용자는 유료 서비스 이용과 관련하여 관련 법령상 보장된 모든 권리를 갖으며, 본 약관의 어떠한 내용도 이러한 법적 권리를 제한하는 것으로 해석되지 않습니다.
        <br/>
        <br/>
        회사는 이용자가 서비스를 이용할 수 있는 다양한 방법을 제공합니다. 우선 이용자는 회사가 제공하는 모바일 애플리케이션이나 웹사이트를 통해 서비스를 이용할 수 있습니다. 또한, 회사는 회사와 제휴관계에 있는 제3자의 모바일 애플리케이션이나 웹사이트 등에서 서비스가 제공되도록 할 수도 있습니다. 다만 웹사이트를 통해 제공되는 서비스나, 제휴 서비스의 경우에는 그 취지나 목적에 따라 서비스의 일부 기능들이 제한되거나 달리 제공될 수 있습니다.
        <br/>
        <br/>
        회사는 이용자가 편리하게 서비스를 이용할 수 있도록 서비스의 내용과 서비스 제공 방식을 지속적으로 개선하고, 새로운 기능과 방식을 개발할 것입니다. 경우에 따라서는 관련된 법령과 규제기관의 명령 등에 따라 서비스의 내용을 변경해야 하는 경우도 있습니다. 이에 따라 서비스와 기능들의 내용과 제공 방식은 계속해서 변경될 수 있습니다. 또한, 일정한 기능이나 제공 방식이 이용자에게 도움이 되지 않는다고 판단되면 그러한 기능 제공이나 제공 방식을 중단할 수 있습니다. 이러한 변경이나 중단이 이용자의 이익을 해칠 수 있는 경우에는 이용자들에게 그러한 변경 또는 중단 사실을 미리 알릴 것입니다.
      </p>
      <br/>
      <h4 id='3'>제3자 서비스와 상품 등에 관하여</h4>
      <p>
        회사는 이용자가 보다 나은 서비스를 경험할 수 있도록 서비스 내에 제3자가 제공하는 서비스를 포함시킬 수 있습니다. 예를 들어, 신용정보회사가 제공하는 개인신용평점 조회서비스를 서비스 내에서 이용할 수 있도록 할 수 있습니다. 이처럼 서비스 내에서 제3자의 서비스가 제공되는 경우 회사는 해당 서비스의 제공자를 명확히 표시하고, 이용자가 해당 서비스의 이용약관에 동의하는 별도의 절차를 두어 이용자가 제3자의 서비스를 이용한다는 사실을 충분히 이해할 수 있도록 할 것입니다.
        <br/>
        <br/>
        한편, 회사는 서비스 내에서 상품 등을 추천하거나 상품 등에 관한 컨설팅을 제공할 수 있으나, 원칙적으로 실제 상품 등을 가입하는 절차는 해당 상품의 제공자인 제3자가 제공합니다. 이러한 경우 회사는 상품 등의 제공자를 명시하거나, 상품 등의 가입 페이지의 구성을 달리하는 등의 방식으로 이용자가 상품 등의 제공자가 제3자라는 사실과, 상품 등 가입을 위한 계약의 당사자가 회사가 아닌 제3자라는 사실을 쉽게 인지할 수 있도록 할 것입니다.
        <br/>
        <br/>
        회사는 이용자의 편의를 위해 제3자 서비스나 상품 등에 관한 정보를 제공할 수 있고, 기타 편의를 제공할 수 있으나, 제3자 서비스나 상품 등에 대한 제공자는 제3자이며, 회사는 이에 대한 책임을 지지 않습니다. 제3자 서비스나 상품 등을 이용하려는 경우 제공되는 이용약관 등을 신중히 읽어보시고, 결정하시기 바랍니다.
      </p>
      <br/>
      <h4 id='4'>회사의 의무와 책임에 관하여</h4>
      <p>
        회사는 서비스를 제공함에 있어 관련된 법령을 준수하며 해당 법령에서 요구하는 의무와 책임을 다합니다. 이로 인해 서비스 제공에 있어 일정한 제한이 발생할 수도 있습니다. 나아가, 회사는 이용자가 자신의 정보를 보다 효과적으로 통제하고, 활용할 수 있도록 편리한 기능을 제공하기 위해 노력합니다. 또한, 이용자들이 자신의 정보 처리를 맡기는데 있어서 충분한 믿음을 가질 수 있도록 정보 처리에 있어 투명성, 객관성, 안전성을 확보하는데 최선의 노력을 다할 것입니다.
        <br/>
        <br/>
        회사는 서비스의 개선을 위해 항상 이용자의 의견에 귀 기울이겠습니다. 이용자의 불편을 신속히 해결하며, 이용자가 서비스를 통해 보다 큰 혜택과 도움을 받을 수 있도록 계속해서 고민하고 연구하겠습니다.
      </p>
      <br/>
      <h4 id='5'>이용자의 의무와 책임에 관하여</h4>
      <p>
        이용자 역시 서비스를 이용함에 있어 관련된 법령을 준수하고, 해당 법령에서 요구하는 의무와 책임을 다해야 합니다. 나아가 본 약관을 성실히 준수하여야 하며, 서비스 이용 과정에서 회사와 다른 이용자, 제3자의 정당한 이익을 해치는 어떠한 행위도 하여서는 아니됩니다. 예를 들어, 다음과 같은 행위들은 엄격히 금지됩니다.
        <br/>
        <ul>
          <li>- 타인의 명의로 회원가입을 하거나, 타인에게 자신의 명의로 회원가입을 하도록 허락하는 행위</li>
          <li>- 서비스 이용 과정에서 타인의 접근매체 또는 인증수단을 사용하거나, 타인에게 자신의 접근매체 또는 인증수단을 사용하도록 허락하는 행위</li>
          <li>- 방법을 불문하고 타인의 정보를 확인하거나 획득하기 위한 목적으로 서비스를 이용하는 행위</li>
          <li>- 서비스 이용 과정에서 의도적으로 허위의 정보를 입력하거나, 이러한 허위 정보 입력으로 인해 왜곡된 분석 결과를 부정한 목적으로 활용하는 행위</li>
          <li>- 서비스를 당초 의도된 목적 이외의 목적으로 활용하거나, 상업적인 목적으로 서비스를 전부 또는 일부 기능(화면, 디자인, 구성, 로고 등을 포함하나 이에 한정되지 않음)을 무단으로 사용하는 행위</li>
          <li>- 회사의 사전 허락 없이 유사한 내용의 서비스를 개발하고, 개선하기 위한 목적으로 서비스를 이용하고 분석하는 행위</li>
          <li>- 회사가 예정하고 있는 방식이 아닌 서비스에 장애를 야기할 수 있는 방식으로 서비스에 접근하거나 서비스를 이용하는 행위</li>
          <li>- 서비스와 관련하여 사실과 다른 허위의 정보를 게시하거나 이와 유사한 방법으로 회사와 서비스, 다른 이용자의 명예나 신뢰를 훼손하는 행위</li>
          <li>- 서비스 제공과 관련된 시스템의 보안을 위협하는 행위 및 서비스의 정상적인 운영을 의도적으로 방해하는 행위</li>
          <li>- 본 약관 및 관련 법령상 의무를 위반하거나, 이러한 의무를 우회하는 방법으로 서비스를 악용하는 행위</li>
        </ul>
        <br/>
        <b>회사는 본 약관에 위반한 이용자에 대해서는 지체없이 서비스 제공을 중단하며, 회원탈퇴 등 필요한 조치를 취할 수 있습니다.</b> 회사는 본 약관을 위반한 행위가 회사, 다른 이용자 및 제3자에게 중대한 위해를 야기할 수 있다고 판단되는 경우에는 필요한 조치를 먼저 취한 뒤에 이용자에게 그 사유를 합리적인 수단으로 통지할 수 있습니다. 이용자는 회사의 조치가 부당하다고 판단하는 경우 이에 대한 이의를 제기할 수 있습니다
        <br/>
        <br/>
        이용자는 서비스의 내용과 성능이 이용자의 정보에 따라 달라지며, 이용자가 정확한 정보를 제공해야 서비스가 제대로 제공될 수 있다는 점을 이해합니다. 이용자는 항상 최신의 정보가 정확하게 제공되도록 노력해야 하며, 자신에 관한 잘못된 정보가 확인될 경우 그러한 정보의 원본을 보유한 기관을 통해 해당 정보가 신속하게 정정되도록 해야 합니다. 회사는 기술적으로 가능한 경우 이러한 정정 절차를 돕기 위해 노력합니다. <b>회사가 이용자 정보를 변조하는 등 귀책사유가 있는 경우를 제외하고, 잘못되거나 정정되지 않은 정보로 인해 발생한 이용자의 손해에 대해서는 회사가 책임지지 않습니다.</b>
        <br/>
        <br/>
        나아가, 이용자는 서비스의 특성상 서비스 내에서 이용자 본인의 다양한 정보가 열람될 수 있다는 점을 이해하고, 타인이 서비스에 접근하지 못하도록 충분한 주의를 기울여야 합니다. 예를 들어, 타인에게 자신의 모바일 단말기를 제공하는 경우 서비스에 접근하지 못하도록 조치하여야 하며, 타인에게 서비스 접근을 위한 비밀번호 등을 알려주어서는 안됩니다. 이용자는 비밀번호 등 서비스 접근방식을 주기적으로 변경하는 등 자신의 책임으로 이를 잘 관리해야 합니다.
      </p>
      <br/>
      <h4 id='6'>이용자 콘텐츠에 관하여</h4>
      <p>
        서비스를 이용하는 과정에서 이용자가 직접 입력하는 정보나 각종 콘텐츠는 서비스를 제공하거나, 이용자가 제공한 목적과 합리적인 관련이 있는 범위 내에서 이용됩니다. 만약 이용자가 제공하는 정보나 콘텐츠가 저작권 등의 법적 보호를 받는 대상이라면 해당 저작권 등 법적 권리는 권리자가 계속 보유하며, <b>다만, 이용자는 위와 같은 목적을 위해 해당 정보나 콘텐츠를 이용할 수 있는 권한을 회사(계열사 포함)에게 부여합니다.</b> 이러한 권한은 무상이며, 비독점적이고, 전 세계적으로 적용되며, 특별한 사정이 없는 이상 이용자가 서비스를 탈퇴할 때까지 유지됩니다.
      </p>
      <br/>
      <h4 id='7'>의사소통에 관하여</h4>
      <p>
        회사는 이용자가 서비스를 이용하는데 있어 중요한 사항이나 법령에 따라 통지가 필요한 사항들을 이용자에게 알립니다. 각 사항의 성격에 따라 서비스 내에 이용자별로 개인화된 공간으로 소식이나 알림을 개별적으로 제공할 수도 있고, 서비스 밖에서도 그러한 사항을 알 수 있도록 앱 푸시 알림이나 SMS, 이와 유사한 방식을 이용하여 주요한 소식을 알릴 수 있습니다. 또한 성격에 따라 서비스 내 공지사항 등을 통해 전체 이용자에게 공지하거나, 게시하는 형태로 소식을 전할 수도 있습니다. 서비스를 이용하는데 있어 중요한 사항이나 법령에 따라 통지가 필요한 경우에는 이용자의 알림 설정과 무관하게 통지가 이루어질 수 있습니다.
        <br/>
        <br/>
        이용자는 언제든지 서비스에 대한 의견을 제시할 수 있습니다. 회사는 서비스 내에 고객센터 등을 통해 이용자들의 의견을 듣고, 필요한 조치를 취합니다. 이용자는 회사가 아무런 의무나 대가 제공 없이 자신이 제공한 의견을 바탕으로 서비스를 개선하거나 필요한 조치를 취할 수 있다는 점을 이해합니다. 회사는 이용자의 의견을 소중히 여기며, 이용자의 의견이 합리적으로 반영될 수 있도록 노력합니다.
      </p>
      <br/>
      <h4 id='8'>소프트웨어에 관하여</h4>
      <p>
        이용자는 서비스의 그 어떤 부분도 임의로 복사, 수정, 배포, 판매 또는 대여할 수 없으며, 회사의 사전 서면 승인 없이 서비스와 관련된 소스 코드의 그 어떠한 부분도 추출하거나, 역설계 하는 등의 방법으로 부당하게 활용하여서는 안 됩니다.
        <br/>
        <br/>
        서비스에는 오픈소스 소프트웨어가 포함되어 있으며, 회사는 해당되는 오픈소스 라이선스에서 요구하는 이용고지를 서비스 내에서 제공하고 있습니다. 이용자는 개인설정 화면에서 해당 이용고지를 확인할 수 있습니다.
        <br/>
        <br/>
        회사는 이용자에게 보다 나은 서비스를 제공하기 위해 정기적으로 서비스를 개선합니다. 변경된 서비스나 기능을 이용하기 위해서 설치된 모바일 애플리케이션을 업데이트해야 할 수 있으며, 이 경우 접속된 모바일 통신 환경에 따라 이용자가 가입한 통신상품에서 정한 데이터 요금이 부과될 수 있습니다.
      </p>
      <br/>
      <h4 id='9'>서비스 중단 및 장애에 관하여</h4>
      <p>
        회사는 서비스 개선이나 점검 등의 합리적인 사정으로 서비스의 전부 또는 일부를 중단해야 하는 경우 이용자에게 이러한 사정을 미리 안내합니다.
        <br/>
        <br/>
        또한, 서비스의 특성상 제3자가 보유하고 있는 정보를 전송 또는 제공받아야 하는 상황에서 해당 제3자 측의 사정이나, 정보통신망의 장애 등으로 인해 일시적으로 서비스 제공에 장애가 발생할 수 있습니다. 또한, 서비스 제공 과정에서 회사의 시스템에 예기치 못한 장애가 발생할 가능성도 존재합니다. 회사는 회사가 통제할 수 있는 영역에서 장애가 발생하지 않도록 노력하겠으며, 어떠한 사유로 장애가 발생하던지 그러한 장애 상황을 신속히 복구하고, 이용자에게 그러한 상황을 알려 불편을 최소화할 수 있도록 노력하겠습니다. <b>법령에서 달리 정하지 않는 이상 미리 안내된 서비스 중단 및 회사에 고의 또는 중과실이 없는 장애에 대해서는 회사가 별도의 책임을 지지 않습니다.</b>
      </p>
      <br/>
      <h4 id='10'>서비스 탈퇴에 관하여</h4>
      <p>
        이용자가 원하는 경우에는 언제든지 서비스 탈퇴 절차를 거쳐 서비스 이용을 중단할 수 있습니다. 회사는 이용자가 서비스를 탈퇴할 수 있는 방법을 서비스 내에 제공합니다. 회사는 안전한 서비스 이용 환경을 유지하고, 서비스의 오남용을 막거나 보다 나은 서비스를 제공하기 위하여 필요한 경우 탈퇴 이용자의 회원 재가입을 일정 기간 제한할 수 있습니다.
      </p>
      <br/>
      <h4 id='11'>손해배상에 관하여</h4>
      <p>
        이용자가 서비스 가입 및 이용과 관련하여 본 약관이나 관련 법령을 위반하거나 기타 귀책사유로 인해 회사나 다른 이용자, 제3자에게 손해를 입힌 경우 해당 이용자는 그러한 손해를 배상해야 합니다. 또한 이용자가 책임져야 하는 사유에 관하여 회사 또는 회사의 임직원이 소송이나 법적 청구의 대상이 된 경우 이용자는 자신의 비용으로 회사 및 해당 임직원을 면책시켜야 합니다.
        <br/>
        <br/>
        회사가 서비스 제공과 관련하여 본 약관이나 관련 법령을 위반하여 이용자나 제3자에게 손해를 입힌 경우 회사는 그러한 손해를 배상해야 합니다. 또한 회사가 책임져야 하는 사유에 관하여 이용자가 소송이나 법적 청구의 대상이 된 경우 회사는 자신의 비용으로 해당 이용자를 면책시켜야 합니다.
        <br/>
        <br/>
        회사나 이용자는 자신이 책임질 수 없는 불가항력으로 인한 손해에 대해 책임지지 않습니다. <b>나아가 회사는 법령상 허용되는 범위에서 다음에 관하여 책임지지 않습니다.</b>
        <br/>
        <ul>
          <li>- 제3자가 제공하는 서비스나 상품 등</li>
          <li>- 이용자나 제3자의 귀책사유로 인해 발생한 서비스 중단이나 장애</li>
          <li>- 이용자의 정보를 보유한 기관이 제공한 부정확한 정보로 인해 발생하는 결과</li>
          <li>- 간접손해, 기대 이익의 상실, 특별손해, 결과적 손해, 징벌적 손해 </li>
        </ul>
      </p>
      <br/>
      <h4 id='12'>분쟁의 해결에 관하여</h4>
      <p>
        이용자는 서비스 이용 과정에 불편한 점이 있을 경우 언제든지 서비스 내 고객센터 또는 이메일(<a href='mailto:hello@banksalad.com'>hello@banksalad.com</a>)을 이용하여 민원을 제기할 수 있습니다. 회사는 민원 처리 전담 인력을 두고, 제기된 민원을 신속하게 처리하기 위해 노력합니다. 다만, 서비스의 특성상 회사가 직접 처리하기 어려운 민원도 존재합니다. 예를 들어, 서비스 내에서 추천하는 금융상품의 경우, 해당 상품을 제공자가 아닌 회사가 처리할 수 있는 민원 유형이 제한될 수 있습니다.
        <br/>
        <br/>
        회사가 본 약관에 따라 일정한 조치를 취할 수 있음에도 일정 기간 동안 그러한 조치를 취하지 않았다는 사실만으로 그러한 권한을 포기한 것으로 해석되지 않습니다.
        <br/>
        <br/>
        본 약관의 해석은 대한민국 법령에 따르며, 본 약관의 어떠한 내용도 강행규정에 따라 이용자가 가지는 정당한 법적 권리나 권한을 부당하게 제한하지 않습니다. 본 약관 및 서비스와 관련된 모든 분쟁은 관련 법령에서 정한 관할 법원이 관할권을 갖습니다.
      </p>
    </div>
  </div>
);
