import { Entity } from 'core/entity';

export class PersonalizedLoanApplyLink extends Entity {
    desktopLink: string;
    mobileLink: string;
    type: PersonalizedLoanApplyLink.Type;

    constructor(
        desktopLink: string,
        mobileLink: string,
        type: PersonalizedLoanApplyLink.Type
    ) {
        super();
        this.desktopLink = desktopLink;
        this.mobileLink = mobileLink;
        this.type = type;
    }
}

export module PersonalizedLoanApplyLink {
    export enum Type {
        LOAN_APPLY = 'loan-apply',
        CONFIRM_LOAN_INTEREST = 'confirm-loan-interest'
    }
}
