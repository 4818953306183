import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { optional } from 'utils/index';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';
import { MedicalCheckupJudgementItemMapper } from 'data/http/mapper/medical-checkup/judgement/item';

export class MedicalCheckupJudgementMapper implements NetworkResponseMapper<MedicalCheckupJudgement> {
    fromJson(json: any): MedicalCheckupJudgement {
        return new MedicalCheckupJudgement(
            new MedicalCheckupJudgementItemMapper().fromJson(json),
            optional(json.medical_expense),
            optional(json.judgement_result, it => {
                switch (it) {
                    case 'normal':
                        return MedicalCheckupJudgement.Result.NORMAL;
                    case 'caution':
                        return MedicalCheckupJudgement.Result.CAUTION;
                    case 'warning':
                        return MedicalCheckupJudgement.Result.WARNING;
                    default:
                        throw new MappingError();
                }
            })
        )
    }
}
