import { Card } from 'core/entity/card';
import { CardBenefit } from 'core/entity/card/benefit';
import { CardCategoryBenefit } from 'core/entity/card/category-benefit';
import { CardStoreBenefit } from 'core/entity/card/store-benefit';
import { List } from 'immutable';
import { HTMLBody } from 'presentation/components/html-body';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN, GA_EVENT_VALUE } from 'presentation/module/analytics/ga';
import React from 'react';
import { uniqueKey } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    card?: Card;
    benefits: List<CardCategoryBenefit>;
    containRestrictions: boolean;
    gaCategory: string;
}

export const CardInfoCategoryBenefits = (props: Props) => {

  // TODO: 심의이슈로 임시처리 #1365
  const applyExceptionText = (text: string) => (
    props.card && props.card.id === 3580 ?
      text.replace(/SK엔크린/g, 'SK에너지') :
      text
  );

  const onChangeInput = (item: CardStoreBenefit) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      props.gaCategory && sendGAEvent(
        GA_DOMAIN.CARD,
        props.gaCategory,
        GA_ACTION.DROP_DOWN,
        item.title,
        e.currentTarget.checked ?
          GA_EVENT_VALUE.OPEN :
          GA_EVENT_VALUE.CLOSE
      );
    };

  const toDetails = (basicBenefits: List<CardBenefit>) => (
    basicBenefits.map((spec, i) => (
      <div key={ `basic-benefits-${i}` } className={ styles.spec }>
        <h6>{ applyExceptionText(spec.benefit) }</h6>
        {
          spec.conditions.map((condition, j) =>
            <span
              key={ `conditions-${i}-${j}` }
              className={ styles.condition }
            >
              { applyExceptionText(condition) }
            </span>
          )
        }
        {
          spec.restrictions.map((restriction, j) =>
            <span
              key={ `conditions-${i}-${j}` }
              className={ styles.restriction }
            >
              { applyExceptionText(restriction) }
            </span>
          )
        }
        {
          spec.description &&
            <div className={ styles.desc }>
              <HTMLBody html={ applyExceptionText(spec.description) } fromPlaneText={ true } />
            </div>
        }
      </div>
    ))
  );

  const toItems = (storeBenefits: List<CardStoreBenefit>) => (
    storeBenefits.map(item => {
      const key = uniqueKey();

      return (
        <li key={ key }>
          <input
            type="checkbox"
            id={ key }
            onChange={ onChangeInput(item) }
          />
          <label htmlFor={ key }>{ applyExceptionText(item.title) }</label>
          <section className={ styles.detail }>
            { toDetails(item.benefits) }
            { 
              props.containRestrictions && 
              item.exclude && 
              <small className={ styles.exclude }>*해당 혜택은 통합한도에 관계없이 이용이 가능합니다</small> 
            }
          </section>
        </li>
      );
    })
  );

  const groups = props.benefits.map(benefit =>
    <li key={ uniqueKey() } id={ benefit.category }>
      <h5
        className={ matchIconStyle(benefit.category) }
      >
        { benefit.category }
      </h5>
      <ul className={ styles.items }>
        { toItems(benefit.storeBenefits) }
      </ul>
    </li>
  );

  return <ul className={ styles.benefits }>{ groups }</ul>;
};

const matchIconStyle = (category: string) => {
  switch (category) {
  case '주점':
    return styles.iconAlcohol;
  case '오락':
  case '온라인게임':
  case '놀이공원':
  case '워터파크':
  case '웹툰':
    return styles.iconAmusement;
  case '뷰티':
  case '미용실':
    return styles.iconBeauty;
  case '교육':
  case '육아':
  case '도서':
  case '서점':
    return styles.iconBook;
  case '카페':
  case '베이커리':
  case '커피':
  case '도너츠':
    return styles.iconCafe;
  case '여행':
  case '호텔':
  case '숙박':
  case '레저':
  case '스포츠':
  case '여행/항공':
    return styles.iconCarrier;
  case '영화':
  case '문화':
  case '문화여가':
  case '온라인공연예매':
  case '온라인영화예매':
  case '음악서비스':
    return styles.iconCulture;
  case '기부':
    return styles.iconDonation;
  case '패션':
    return styles.iconFashion;
  case '금융':
  case '무아지할부':
    return styles.iconFinance;
  case '외식':
  case '패밀리레스토랑':
    return styles.iconFood;
  case '생활':
    return styles.iconLife;
  case '마트':
  case '편의점':
    return styles.iconMart;
  case '의료':
    return styles.iconMedical;
  case '통신':
  case '모바일':
  case '휴대폰요금':
    return styles.iconMobile;
  case '주유':
  case '자동차':
  case '휘발유':
  case 'LPG':
  case '등유':
  case '경유':
  case '고속도로':
  case '자동차구입':
  case '전기차충전':
    return styles.iconGas;
  case '결제':
  case '바우처/상품권':
  case '모바일결제':
    return styles.iconPayment;
  case '반려동물':
    return styles.iconPet;
  case '쇼핑':
  case '백화점':
  case '홈쇼핑':
  case '대형마트':
  case '온라인쇼핑몰':
    return styles.iconShopping;
  case '문구':
  case '잡화':
    return styles.iconStationary;
  case '교통':
  case '대중교통':
    return styles.iconTraffic;
  default:
    return styles.iconEtc;
  }
};
