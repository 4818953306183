import { CreditMetadataRepositoryType } from 'core/repository/personal-credit/metadata';
import { Observable } from 'rxjs';
import { CreditMetadata } from 'core/entity/personal-credit/metadata';
import { CreditScoreMetadataApiProvider } from 'data/http/api/gateway/v2/credit-scores/kcb/metadata';

export class CreditMetadataRepository implements CreditMetadataRepositoryType {
    private api: CreditScoreMetadataApiProvider;

    constructor(api: CreditScoreMetadataApiProvider) {
        this.api = api;
    }

    get(): Observable<CreditMetadata> {
        return this.api.getAll();
    }
}
