import {
    NetworkRequestMapper,
    NetworkResponseMapper
} from 'data/http/mapper';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';

export class LoanRepaymentTypeMapper implements
    NetworkRequestMapper<LoanRepaymentType> ,
    NetworkResponseMapper<LoanRepaymentType>
{
    fromJson(json: any): LoanRepaymentType {
        switch (json) {
            case 'at_end':
                return LoanRepaymentType.AT_END;
            case 'progressive':
                return LoanRepaymentType.PROGRESSIVE;
            case 'initial_value':
            default:
                return LoanRepaymentType.INITIAL_VALUE;
        }
    }

    toJson(target: LoanRepaymentType): any {
        switch (target) {
            case LoanRepaymentType.AT_END:
                return 'at_end';
            case LoanRepaymentType.PROGRESSIVE:
                return 'progressive';
            case LoanRepaymentType.INITIAL_VALUE:
            default:
                return 'initial_value';
        }
    }

}
