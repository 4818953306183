import { NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedInsuranceTag } from 'core/entity/personalized-recommend/insurance/tag';
import { lets } from 'utils/index';
import { Color } from 'presentation/module/color';

export class PersonalizedInsuranceTagMapper implements NetworkResponseMapper<PersonalizedInsuranceTag> {
    fromJson(json: any): PersonalizedInsuranceTag {
        return new PersonalizedInsuranceTag(
            json.title,
            lets(json.bg_color, it => {
                switch (it) {
                    case 'romaine400':
                        return Color.v2romaine400;
                    case 'romaine700':
                        return Color.v2romaine700;
                    case 'sky500':
                        return Color.sky500;
                    case 'sky800':
                        return Color.sky800;
                    case 'grape500':
                        return Color.grape500;
                    case 'blueberry350':
                        return Color.blueberry350;
                    case 'tomato400':
                        return Color.tomato400;
                    case 'black350':
                        return Color.black350;
                    default:
                        return Color.black350;
                }
            })
        )
    }
}
