import React from 'react';
import { List } from 'immutable';

import { Loan } from 'core/entity/loan';
import { LoanComputationResult } from 'core/entity/loan/computation-result';
import { toCurrency } from 'presentation/module/sugar';
import { LoanPrimeInterestNode } from 'core/entity/loan/prime-interest-node';
import { lets, optional } from 'application/utils';
import { LoanPrimeInterestNodeStatusType } from 'core/entity/loan/prime-interest-node-status-type';
import { EventBus } from 'presentation/bus';
import { SelectLoanPrimeInterestNodeEvent } from 'presentation/bus/event/select-loan-prime-interest-node-event';
import { GA_ACTION, GA_CATEGORY, GA_EVENT_VALUE, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { LoanInterestValue } from 'core/entity/loan/interest-value';
import { LoanAverageInterest } from 'core/entity/loan/interest-value/average-interest';
import { LoanConfirmationInterest } from 'core/entity/loan/interest-value/confirmation-interest';
import { LoanRangeInterest } from 'core/entity/loan/interest-value/range-interest';
import { LoanInterestConfiguration } from 'core/entity/loan/interest-configuration';
import { Application } from 'application/context.instance';
import { CreditLoanScoreLevel } from 'presentation/components/survey-form/credit-loan-score-level/model';
import { SubscriptionBag } from 'presentation/module/extension';
import { ToolTip } from 'presentation/components/tooltip';

import styles from './styles.pcss';

interface Props {
    loan: Loan;
    profits: LoanComputationResult;
}

const toolTipMessage =
    '예상한도는 상품의 최대 한도에서 입력된 신용등급과 소득을 기반으로, 뱅크샐러드에서 계산한 예상값 입니다. 현재 이용중인 타 은행 대출, 현금서비스 등 기타 요인에 의해 실제 금리와 한도는 달라질 수 있습니다. 정확한 금리와 한도 정보는 [신청하기] 버튼을 통해 해당 금융사 홈페이지에서 확인 가능합니다.';

export class CreditLoanInfoProfits extends React.Component<Props> {
    state = {
        fetchingNode: null as LoanPrimeInterestNode,
        score: new CreditLoanScoreLevel()
    };

    private DEFAULT_INTEREST_ID = 'default-interest';
    private PRIME_INTEREST_ID = 'prime-interest';
    private EVENT_INTEREST_ID = 'event-interest';

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        this.fetchCachedRecommendSpec();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { loan, profits } = this.props;
        const { score } = this.state;
        const promotionInterestValue = optional(profits.interestConfiguration.interestPromotion, it => (
            it.isRangeInterest() ?
                this.toRangeInterestValueString(
                    it.getMinInterest(),
                    it.getMaxInterest()
                ) :
                this.toInitInterestValueString(it.getInterest())
        ));
        const totalInterestValue = profits.interestConfiguration.isRangeInterest() ?
            this.toRangeInterestValueString(
                profits.interestConfiguration.getMinInterest(),
                profits.interestConfiguration.getMaxInterest()
            ) :
            this.toInitInterestValueString(profits.interestConfiguration.getInterest());
        const loanPrimeInterestValue = profits.interestConfiguration.primeInterest.isRangeInterest() ?
            this.toRangeInterestValueString(
                profits.interestConfiguration.primeInterest.getMinInterest(),
                profits.interestConfiguration.primeInterest.getMaxInterest()
            ) :
            this.toInitInterestValueString(profits.interestConfiguration.primeInterest.getInterest());

        return (
            <div>
                <section className={ styles.summary }>
                    { score.toValue() } 기준 예상금리와 한도
                    <strong className={ styles.summaryValue }>
                        <span className={ styles.summaryType }>
                            { this.toInterestTypeString(profits.interestConfiguration.totalInterest) }
                        </span>
                        { totalInterestValue }
                    </strong>
                    <strong className={ styles.summaryLimit }>
                        <span className={ styles.summaryLimitWrap }>
                            뱅크샐러드 예상한도는 <strong>최대 { toCurrency(Number(profits.maximumAmountLimit / 10000)) }만원</strong>입니다
                        </span>
                        { this.renderToolTip() }
                    </strong>
                    <p className={ styles.summaryDescription }>
                        뱅크샐러드는 고객님이 입력하신 정보와 <b>금융사 제공 데이터를 바탕으로 금리와 한도를 계산</b>합니다.<br/>
                        현재 이용중인 타 은행 대출, 현금서비스 등 기타 요인에 의해 실제 금리와 한도는 달라질 수 있습니다
                    </p>
                </section>
                <ul className={ styles.calculation }>
                    {
                        profits.interestConfiguration.isAverageInterest() ?
                            this.renderAverageDefaultInterestItem(profits) :
                            this.renderDefaultInterestItem(profits)
                    }
                    {
                        !profits.interestConfiguration.primeInterest.nodes.isEmpty() &&
                        <li>
                            <input
                                type="checkbox"
                                id={ this.PRIME_INTEREST_ID }
                                defaultChecked={ true }
                                onChange={ this.gaInterestFolderEvent('우대금리') }
                                className={ styles.calculationTrigger }
                            />
                            <label htmlFor={ this.PRIME_INTEREST_ID } className={ styles.calculationButton }>
                                <span className={ styles.calculationLabel }>우대금리</span>
                                <span className={ styles.calculationValue }>{ `- ${loanPrimeInterestValue}` }</span>
                            </label>
                            <div className={ styles.calculationFolder }>
                                { this.renderPrimeInterests(profits.interestConfiguration.primeInterest.nodes) }
                            </div>
                        </li>
                    }
                    {
                        loan.isAvailablePromotionInterestValue() &&
                        <li>
                            <input type="checkbox" id={ this.EVENT_INTEREST_ID } onChange={ this.gaInterestFolderEvent('이벤트금리') } defaultChecked={ true } className={ styles.calculationTrigger }/>
                            <label htmlFor={ this.EVENT_INTEREST_ID } className={ styles.calculationButton }>
                                <span className={ styles.calculationLabel }>이벤트 금리</span>
                                <span className={ styles.calculationValue }>{ `- ${promotionInterestValue} `} </span>
                            </label>
                            <div className={ styles.calculationFolder }>
                                <span className={ styles.eventLabel }>
                                    {
                                        `뱅크샐러드 제휴코드 ${profits.interestConfiguration.interestPromotion.code}입력 시
                                        ${promotionInterestValue} 금리인하`
                                    }
                                </span>
                                <span className={ styles.eventValue }>{ `- ${promotionInterestValue}` }</span>
                            </div>
                        </li>
                    }
                </ul>
                <section className={ styles.result }>
                    <span className={ styles.resultLabel }>예상 연 금리</span>
                    <span className={ styles.resultValue }>{ `= ${totalInterestValue}` }</span>
                </section>
            </div>
        )
    }

    private renderAverageDefaultInterestItem = (profits: LoanComputationResult) => (
        <li>
            <div className={ styles.averageCalculationButton }>
                <span className={ styles.calculationLabel }>평균금리</span>
                <span className={ styles.calculationValue }>{ this.toDefaultInterestValueString(profits.interestConfiguration) }</span>
            </div>
        </li>
    );

    private renderDefaultInterestItem = (profits: LoanComputationResult) => (
        <li>
            <input type="checkbox" id={ this.DEFAULT_INTEREST_ID } className={ styles.calculationTrigger } onChange={ this.gaInterestFolderEvent('기본금리') } />
            <label htmlFor={ this.DEFAULT_INTEREST_ID } className={ styles.calculationButton }>
                <span className={ styles.calculationLabel }>기본금리</span>
                <span className={ styles.calculationValue }>{ this.toDefaultInterestValueString(profits.interestConfiguration) }</span>
            </label>
            <div className={ styles.calculationFolder }>
                { this.renderDefaultInterests(profits.interestConfiguration) }
            </div>
        </li>
    );

    private renderDefaultInterests = (interestConfiguration: LoanInterestConfiguration) => (
        <ul className={ styles.defaultInterests }>
            <li>
                <span className={ styles.defaultInterestLabel }>
                    기준금리
                </span>
                <span className={ styles.defaultInterestValue }>
                    +
                    {
                        interestConfiguration.isBaseInterestRangeInterest() ?
                            this.toRangeInterestValueString(
                                interestConfiguration.getMinBaseInterest(),
                                interestConfiguration.getMaxBaseInterest()
                            ) :
                            this.toInitInterestValueString(interestConfiguration.getBaseInterest())
                    }
                </span>
            </li>
            <li>
                <span className={ styles.defaultInterestLabel }>
                    가산금리
                    <small>신용등급에 따른 추가금리</small>
                </span>
                <span className={ styles.defaultInterestValue }>
                    +
                    {
                        interestConfiguration.isAdditionalInterestRangeInterest() ?
                            this.toRangeInterestValueString(
                                interestConfiguration.getMinAdditionalInterest(),
                                interestConfiguration.getMaxAdditionalInterest()
                            ) :
                            this.toInitInterestValueString(interestConfiguration.getAdditionalInterest())
                    }
                </span>
            </li>
        </ul>
    );

    private renderPrimeInterests = (interests: List<LoanPrimeInterestNode>, depth: number = 0): any => {
        if (interests.isEmpty())
            return;

        return interests.map((interestGroup: LoanPrimeInterestNode, i: number) =>
            <ul key={ `prime-container-${i}` } className={ styles.primeInterests }>
                { this.renderItem(interestGroup, `prime-group-${interestGroup.level}-${i}`) }
                {
                    interestGroup.children.map((interest) => lets(`prime-${interest.level}-${i}`, id =>
                        <React.Fragment key={ `prime-fragment-${interest.level}-${depth}` }>
                        { this.renderItem(interest, id) }
                        { this.renderPrimeInterests(interest.children, depth++) }
                        </React.Fragment>
                    ))
                }
            </ul>
        );
    };

    private renderItem = (item: LoanPrimeInterestNode, id: string) => (
        <li key={ id } className={ styles[`primeInterestLevel${item.level + 1}`] }>
            <button
                className={ this.toCheckBoxStyle(item) }
                onClick={ this.onChangePrimeNode(item) }
            >
                <span className={ styles.primeInterestDescription }>
                    { item.description }
                    <small>{ item.descriptionWarning }</small>
                </span>
                <span className={ styles.primeInterestValue }>
                    { `${item.interestDescription}` }
                </span>
            </button>
        </li>
    );

    private toCheckBoxStyle = (interest: LoanPrimeInterestNode) => {
        if (!interest.status) {
            return styles.nonCheckable;
        }

        if (interest.children.isEmpty()) {
            switch (interest.status.type) {
                case LoanPrimeInterestNodeStatusType.CHECKED:
                    return styles.checked;
                case LoanPrimeInterestNodeStatusType.CHECKABLE:
                    return styles.unchecked;
                case LoanPrimeInterestNodeStatusType.NON_CHECKABLE:
                default:
                    return styles.nonCheckable;
            }
        } else {
            switch (interest.status.type) {
                case LoanPrimeInterestNodeStatusType.CHECKED:
                    return styles.checkedGroup;
                default:
                    return styles.uncheckedGroup;
            }
        }
    };

    private toInterestTypeString = (interest: LoanInterestValue) => {
        if (interest instanceof LoanAverageInterest)
            return '평균';
        else if (interest instanceof LoanConfirmationInterest)
            return '확정';
        else if (interest instanceof LoanRangeInterest)
            return '';
    };

    private toDefaultInterestValueString = (interestConfiguration: LoanInterestConfiguration) => (
        interestConfiguration.getMinDefaultInterest() === interestConfiguration.getMaxDefaultInterest() ?
            `${interestConfiguration.getMinDefaultInterest().toFixed(2)}%` :
            `${interestConfiguration.getMinDefaultInterest().toFixed(2)}~${interestConfiguration.getMaxDefaultInterest().toFixed(2)}%`
    );

    private toInitInterestValueString = (interest: number) => (
        `${interest.toFixed(2)}%`
    );

    private toRangeInterestValueString = (min: number, max: number) => (
        min === max ?
            `${min.toFixed(2)}%` :
            `${min.toFixed(2)}~${max.toFixed(2)}%`
    );

    private onChangePrimeNode = (node: LoanPrimeInterestNode) => () => {
        EventBus.post(new SelectLoanPrimeInterestNodeEvent(node))
    };

    private gaInterestFolderEvent = (label: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        sendGAEvent(
            GA_DOMAIN.LOANS,
            GA_CATEGORY.LOAN_PROFITS.TAB_PROFIT,
            GA_ACTION.FOLDER,
            label,
            e.target.checked ? GA_EVENT_VALUE.OPEN : GA_EVENT_VALUE.CLOSE
        )
    }

    private fetchCachedRecommendSpec = () => {
        Application.useCases.getCachedLoanRecommendSpec
            .runOnAnimateFrame()
            .subscribe(
                spec => {
                    this.setState({
                        score: new CreditLoanScoreLevel(spec.creditScore),
                    })
                },
                () => {
                    /* explicitly empty */
                }
            )
            .unsubscribeBy(this.subscriptionBag);
    }

    private renderToolTip = () => (
        <ToolTip message={ toolTipMessage }>
            <span className={ styles.toolTip }>
                <span className="blind">예상한도 주의사항</span>
            </span>
        </ToolTip>
    );
}
