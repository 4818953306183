import { NetworkResponseMapper } from 'data/http/mapper';
import { Content } from 'core/entity/content';
import { ContentColumnMapper } from 'data/http/mapper/content/column';
import { ContentCardNewsMapper } from 'data/http/mapper/content/card-news';
import { ContentCardComparisonMapper } from 'data/http/mapper/content/card-comparison';

export class ContentMapper implements NetworkResponseMapper<Content> {
    fromJson(json: any): Content {
        switch (json.type) {
            case 'column':
                return new ContentColumnMapper().fromJson(json);
            case 'card-news':
                return new ContentCardNewsMapper().fromJson(json);
            case 'card-comparison':
                return new ContentCardComparisonMapper().fromJson(json);
            default:
                return null;
        }
    }
}
