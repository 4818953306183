import { NetworkResponseMapper } from 'data/http/mapper';
import { PromotionTracker } from 'core/entity/promotion/promotion-tracker';

export class PromotionTrackerMapper implements NetworkResponseMapper<PromotionTracker> {
    fromJson(json: any): PromotionTracker {
        return new PromotionTracker(
            json.view_name,
            json.object_name
        )
    }
}
