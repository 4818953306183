import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceExpenseAmountSummary } from 'core/entity/personal-finance/advice/expense-amount-summary';
import { AmountMapper } from 'data/http/mapper/amount';

export class AdviceExpenseAmountSummaryMapper implements NetworkResponseMapper<AdviceExpenseAmountSummary> {
    fromJson(json: any): AdviceExpenseAmountSummary {
        return new AdviceExpenseAmountSummary(
            json.name,
            json.count,
            new AmountMapper().fromJson(json.total_amount)
        )
    }
}
