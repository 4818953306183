import { Entity } from 'core/entity';

export class CardCompany extends Entity {
    id: number;
    nameKo: string;
    nameEn: string;
    imageUrl: string;
    contracted: boolean;

    constructor(
        id: number,
        nameKo: string,
        nameEn: string,
        imageUrl: string,
        contracted: boolean
    ) {
        super();
        this.id = id;
        this.nameKo = nameKo;
        this.nameEn = nameEn;
        this.imageUrl = imageUrl;
        this.contracted = contracted;
    }
}
