import { NetworkResponseMapper } from 'data/http/mapper';
import { PromotionButton } from 'core/entity/promotion/promotion-button';

export class PromotionButtonMapper implements NetworkResponseMapper<PromotionButton> {
    fromJson(json: any): PromotionButton {
        return new PromotionButton(
            json.title,
            json.color,
            json.is_round
        )
    }
}
