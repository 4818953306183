import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderSecuritiesFirm } from 'core/entity/personal-finance/securities-firm';
import { optional } from 'utils/index';

export class SpiderSecuritiesFirmMapper implements NetworkResponseMapper<SpiderSecuritiesFirm> {
    fromJson(json: any): SpiderSecuritiesFirm {
        return new SpiderSecuritiesFirm(
            json.id,
            json.name,
            json.image_url,
            json.default,
            json.priority,
            optional(json.code)
        )
    }
}
