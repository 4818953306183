import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';

export class SpiderCardCompanySummaryMapper implements NetworkResponseMapper<SpiderCardCompanySummary> {
    fromJson(json: any): SpiderCardCompanySummary {
        return new SpiderCardCompanySummary(
            json.id,
            json.name,
            json.image_url
        )
    }

}
