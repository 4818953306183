import { UseCase } from 'core/use-case';
import { Page } from 'core/entity/page';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardRecommendRepositoryType } from 'core/repository/card-recommend';
import { CardAmount } from 'core/entity/card/amount';
import { CardBenefitType } from 'core/entity/card/benefit-type';
import { Card } from 'core/entity/card';
import { apply, lets, Pair, APP_VALUE, emptyList } from 'utils/index';
import { CardFilter } from 'core/entity/card/filter';
import { CardPriority } from 'core/entity/card/priority';
import { CardSpending } from 'core/entity/card/spending';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';

export class RecommendCardSimply extends UseCase<Pair<CardAmount, number>> {
    companies?: List<string>;
    monthlySpending?: number = null;
    spendings?: List<CardSpending> = null;
    benefitTypes?: List<CardBenefitType> = null;
    cardTypes?: List<Card.Type> = null;
    annualCostMin?: number = null;
    annualCostMax?: number = null;
    private repository: CardRecommendRepositoryType;

    constructor(repository: CardRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<Pair<CardAmount, number>> {
        const spec = new CardRecommendSpec(
            lets(this.spendings, it => {
                const totalSpending = it.reduce(
                    (acc, spending) => acc + spending.expense, 0
                );

                return it.push(
                    new CardSpending(
                        APP_VALUE.NORMAL_STORE_ID,
                        APP_VALUE.NORMAL_STORE_NAME,
                        this.monthlySpending - totalSpending
                    )
                );
            }),
            this.benefitTypes,
            apply(new CardFilter(), filter => {
                filter.cardTypes = this.cardTypes;
                filter.annualCostMin = this.annualCostMin;
                filter.annualCostMax = this.annualCostMax;
                filter.companies = this.companies;
            }),
            new CardPriority(
                CardPriority.Criteria.PROFIT,
                false
            )
        );

        return this.repository.cacheSpec(spec)
            .flatMap(() =>
                this.repository.recommend(
                    spec,
                    new Page(1, 0)
                )
            ).map(cards => cards.isEmpty() ?
                new Pair(
                    new CardAmount(0, 0, 0, emptyList()),
                    0
                ) :
                new Pair(
                    cards.first().summary,
                    cards.first().card.annualCostWithPromotion
                )
            )
    }

    protected validate(): boolean {
        return (
            this.monthlySpending !== null &&
            this.spendings !== null &&
            this.benefitTypes !== null &&
            this.cardTypes !== null
        );
    }
}
