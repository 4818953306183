import { Event } from 'presentation/bus';

export class WindowResizeEvent implements Event {
    public width: number;
    public height: number;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
    }
}
