import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs';
import { AccessToken } from 'core/entity/user/access-token';
import { HealthInsuranceCertificateResponseMapper } from 'data/http/mapper/personal-finance/health-insurance/certificates';
import { HealthInsuranceCertificateResponse } from 'core/entity/personal-finance/health-insurance/certificate/response';

export class HealthInsuranceCertificateApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<HealthInsuranceCertificateResponse> {
        return this.requestGET(
            'api/v2/me/health-insurance/certificates',
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) => new HealthInsuranceCertificateResponseMapper().fromJson(result));
    }
}
