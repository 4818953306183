import Axios from 'axios';
import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { ContentSummary } from 'core/entity/content/summary';
import { ContentSummaryMapper } from 'data/http/mapper/content/summary';
import { List } from 'immutable';
import { Loader } from 'presentation/components/loader';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { FetchPageState } from 'presentation/view-model/fetch-state';
import React, { useEffect, useState } from 'react';
import { isNamespaceProduction } from 'utils/env';
import { emptyList, toDateString } from 'utils/index';

import styles from './styles.pcss';
const A_DAY = 1000 * 60 * 60 * 24;

interface Props {
    tagId?: string;
    authorId?: string;
}

interface State {
    contents: List<ContentSummary>;
    fetchState: FetchPageState;
}

export const ContentsViewer = ({ tagId, authorId }: Props) => {
  const [{ contents, fetchState }, setState] = useState({
    contents: emptyList(),
    fetchState: FetchPageState.FETCHING,
  });

  const renderContents = () => {
    const toTitleStyle = (date: Date) =>
      new Date().getTime() - date.getTime() < A_DAY * 3 ?
        styles.contentNewTitle : styles.contentTitle;
    const toLink = (content: ContentSummary) =>
      (content instanceof ContentCardComparisonSummary) ?
        BANKSALAD_SITEMAP_BY_ID.CARD_THEME(content.id) :
        BANKSALAD_SITEMAP_BY_ID.CONTENT(content.id);
    const sendGA = (content: ContentSummary, index: number) =>
      sendGAEvent(
        (content instanceof ContentCardComparisonSummary) ?
          GA_DOMAIN.CARD :
          GA_DOMAIN.NONE,
        GA_CATEGORY.CONTENTS.LIST,
        (content instanceof ContentCardComparisonSummary) ?
          GA_ACTION.LINK.CARDS_THEMES_DETAIL :
          GA_ACTION.LINK.CONTENTS_DETAIL,
        `${content.title}_${content.id}_${index + 1}`,
      );

    const toItem = (content: ContentSummary, index: number) => (
      <li key={ `columns-${index}` }>
        <a
          href={ toLink(content) }
          onClick={ () => sendGA(content, index) }
        >
          <div 
            className={ styles.contentThumbnail } 
            style={ { backgroundImage: `url("${content.thumbnailImageUrl}")` } } 
          />
          <section className={ styles.contentInfo }>
            <div 
              className={ styles.contentSmallThumbnail }
              style={ { backgroundImage: `url("${content.thumbnailImageUrl}")` } }
            />
            <h1 className={ toTitleStyle(content.createdAt) }>{ content.title }</h1>
            <p className={ styles.contentDesc }>{ content.description }</p>
            <div className={ styles.contentExtra }>
              <a 
                href={ BANKSALAD_SITEMAP_BY_ID.AUTHOR(content.author.id) } 
                className={ styles.contentAuthor }>
                { content.author.profile.name }
              </a>
              <span className={ styles.contentDate }>{ toDateString(content.createdAt, '.') }</span>
            </div>
          </section>
        </a>
      </li>
    );

    return (
      <ul className={ styles.contents }>
        { contents.map(toItem) }
      </ul>
    );
  };


  useEffect(() => {
    (async () => {
      const CONTENTS_API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/contents.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/contents.json`;
      const AUTHOR_CONTENTS_API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/author-contents/${authorId}.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/author-contents/${authorId}.json`;
      const TAG_CONTENTS_API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/tag-contents/${tagId}.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/tag-contents/${tagId}.json`;

      const { data } = await Axios.get(
        (tagId === undefined && authorId === undefined) 
          ? CONTENTS_API_PATH 
          : tagId === undefined 
            ? AUTHOR_CONTENTS_API_PATH 
            : TAG_CONTENTS_API_PATH
      );

      setState({
        contents: data.data.map((content: any) => new ContentSummaryMapper().fromJson(content)),
        fetchState: FetchPageState.FETCHED,
      });
    })();
  }, []);

  const REGEX_REMOVE_ALL_CHARACTERS_AFTER_HYPHEN = /-\w+$/;

  return (
    <>
      <div className={ styles.wrap }>
        <div className={ styles.container }>
          <section className={ styles.head }>
            <h2 className={ styles.title }>{ tagId ? `#${tagId.replace(REGEX_REMOVE_ALL_CHARACTERS_AFTER_HYPHEN, '')}` : '전체 글' }</h2>
          </section>
          { renderContents() }
          { fetchState === FetchPageState.FETCHING && <Loader padding={ 100 } radius={ 25 }/> }
        </div>
      </div>
    </>
  );
};
