import { Service } from 'core/service';
import { UserCashAccountRepositoryType } from 'core/repository/personal-finance/cash-account';
import { UserCashAccountCreateSpec } from 'core/entity/personal-finance/cash-account/create-spec';
import { Observable } from 'rxjs/Rx';
import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { UserCashAccountUpdateSpec } from 'core/entity/personal-finance/cash-account/update-spec';

export class UserCashAccountService implements Service {
    private repository: UserCashAccountRepositoryType;

    constructor(repository: UserCashAccountRepositoryType) {
        this.repository = repository;
    }

    create = (spec: UserCashAccountCreateSpec): Observable<UserCashAccount> =>
        this.repository.create(spec);

    update = (id: string, spec: UserCashAccountUpdateSpec): Observable<UserCashAccount> =>
        this.repository.update(id, spec);
}
