import { UserExpenseTransaction } from 'core/entity/personal-finance/transaction/expense';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { BanksaladStoreMapper } from 'data/http/mapper/personal-finance/banksalad-store';
import { TransactionCategorySummaryMapper } from 'data/http/mapper/personal-finance/transaction/category/summary';
import { ExpenseTransactionConfigurationMapper } from 'data/http/mapper/personal-finance/transaction/expense/configuration';
import { TransactionPrimaryDataMapper } from 'data/http/mapper/personal-finance/transaction/primary-data';
import { TransactionProductMapper } from 'data/http/mapper/personal-finance/transaction/product';
import { parseDate, optional } from 'utils/index';

export class UserExpenseTransactionMapper implements NetworkResponseMapper<UserExpenseTransaction> {
    fromJson(json: any): UserExpenseTransaction {
        return new UserExpenseTransaction(
            json.id,
            new AmountMapper().fromJson(json.amount),
            json.title,
            parseDate(json.transacted_at),
            new TransactionProductMapper().fromJson(json.product),
            new TransactionCategorySummaryMapper().fromJson(json.category),
            json.deleted,
            new ExpenseTransactionConfigurationMapper().fromJson(json.configuration),
            optional(json.primary_data, it => new TransactionPrimaryDataMapper().fromJson(it)),
            optional(json.description),
            optional(json.banksalad_store, it => new BanksaladStoreMapper().fromJson(it))
        )
    }

}
