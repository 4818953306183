import { Entity } from 'core/entity';
import { TransactionProduct } from 'core/entity/personal-finance/transaction/product';
import { TransactionType } from 'core/entity/personal-finance/transaction/type';

export class TransactionUpdateSpec extends Entity {
    product: TransactionProduct;
    categoryId: string;
    title?: string;
    description?: string;
    transactedAt?: Date;
    amount?: number;
    type?: TransactionType;
    updatingSimilarTransactions?: boolean;
    configuration?: TransactionUpdateSpec.Configuration;

    constructor(
        product: TransactionProduct,
        categoryId: string,
        title: string = null,
        description: string = null,
        transactedAt: Date = null,
        amount: number = null,
        type: TransactionType = null,
        updatingSimilarTransactions: boolean = null,
        configuration: TransactionUpdateSpec.Configuration = null
    ) {
        super();
        this.title = title;
        this.description = description;
        this.product = product;
        this.transactedAt = transactedAt;
        this.amount = amount;
        this.type = type;
        this.categoryId = categoryId;
        this.updatingSimilarTransactions = updatingSimilarTransactions;
        this.configuration = configuration;
    }
}

export module TransactionUpdateSpec {
    export class Product extends Entity {
        id: string;
        type: ProductType;

        constructor(
            id: string,
            type: ProductType
        ) {
            super();
            this.id = id;
            this.type = type;
        }
    }

    export enum ProductType {
        CARD = 'card',
        BANK_ACCOUNT = 'bank_account'
    }

    export class Configuration extends Entity {
        excludedFromBudget?: boolean;

        constructor(
            excludedFromBudget: boolean = null
        ) {
            super();
            this.excludedFromBudget = excludedFromBudget;
        }
    }
}
