import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardCFAConfigurationMapper } from 'data/http/mapper/legal-configuration/card/cfa';

export class CardLegalConfigurationMapper implements NetworkResponseMapper<CardLegalConfiguration> {
    fromJson(json: any): CardLegalConfiguration {
        return new CardLegalConfiguration(
            json.id,
            new CardCFAConfigurationMapper().fromJson(json.cfa),
            json.disabled_for_compliance,
            json.issuing_disabled
        )
    }

}
