import { MetaHelmet } from 'presentation/components/meta-helmet';
import { PoliciesV20PrivacyPolicyView } from 'presentation/routes/policies/v20/privacy-policy/view';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';

export const PoliciesV20PrivacyPolicyRoute = () => (
  <>
    <MetaHelmet meta={META_SET.PRIVACY_POLICY} />
    <PoliciesV20PrivacyPolicyView />
  </>
);
