import { NetworkResponseMapper } from 'data/http/mapper';
import { EmailValidate } from 'core/entity/email/validate';
import { optional } from 'utils/index';

export class EmailValidateMapper implements NetworkResponseMapper<EmailValidate> {
    fromJson(json: any): EmailValidate {
        return new EmailValidate(
            json.valid,
            optional(json.message),
        );
    }
}
