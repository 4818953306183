import { Entity } from 'core/entity';

export class Notice extends Entity {
  id: number;
  title: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: number,
    title: string,
    description: string,
    createdAt: Date,
    updatedAt: Date,
  ) {
    super();
    this.id = id;
    this.title = title;
    this.description = description;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

/**
 * @description
 * SERVICE, COMPANY => client 에서 사용하는 enum
 * NOTICE_TYPE_USER, NOTICE_TYPE_PUBLIC => search params
 */
export enum NoticeType {
  SERVICE = 'NOTICE_TYPE_USER',
  COMPANY = 'NOTICE_TYPE_PUBLIC',
}
