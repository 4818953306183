import { Notice } from 'core/entity/notice';
import { NoticeRepositoryType } from 'core/repository/notice';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class GetNotice extends UseCase<Notice> {
    id: number;
    private repository: NoticeRepositoryType;

    constructor(repository: NoticeRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<Notice> {
        return this.repository.get(this.id);
    }

    protected validate(): boolean {
        return !!this.id;
    }
}
