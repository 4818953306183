import Axios from 'axios';
import { ContentAuthorSummary } from 'core/entity/content/author/summary';
import { ContentAuthorSummaryMapper } from 'data/http/mapper/content/author/summary';
import { List } from 'immutable';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { AuthorCard } from 'presentation/routes/contents/authors/card';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React, { useEffect, useState } from 'react';
import { isNamespaceProduction } from 'utils/env';

import styles from './styles.pcss';

interface State {
    authors: List<ContentAuthorSummary>;
    fetchState: FetchState;
}

export const Authors = () => {
  const [{ authors, fetchState }, setState] = useState({ 
    authors: null as List<ContentAuthorSummary>, 
    fetchState: FetchState.FETCHING 
  });

  useEffect(() => {
    (async () => {
      const API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/authors.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/authors.json`;
  
      const { data } = await Axios.get(API_PATH);
  
      setState({
        authors: data.data.map((author: any) => new ContentAuthorSummaryMapper().fromJson(author)),
        fetchState: FetchState.FETCHED,
      });
    })();
  }, []);

  const contents = () => {
    switch (fetchState) {
    case FetchState.FETCHED:
      return (
        <section className={ styles.authors }>
          <div className={ styles.container }>
            <ul className={ styles.list }>
              {
                authors.map((author) =>
                  <AuthorCard
                    key={ author.id }
                    summary={ author }
                  />
                )
              }
            </ul>
          </div>
        </section>
      );
    case FetchState.FETCHING:
      return <Loader padding={ 100 } radius={ 25 }/>;
    case FetchState.ERROR:
      return (
        <FetchError padding={ 100 }>
          기고자를 불러오는데 오류가 발생했습니다
          <br/>
          잠시 후 새로고침 해주세요
        </FetchError>
      );
    }
  };

  return (
    <div>
      <MetaHelmet meta={ META_SET.CONTENTS_AUTHORS }/>
      <section className={ styles.hero }>
        <h1 className={ styles.heroTitle }>BANKSALAD MAGAZINE</h1>
        <h2 className={ styles.heroSubTitle }>뱅크샐러드 매거진의 집필진을 소개합니다</h2>
        <p className={ styles.heroDesc }>
          당신이 지금보다 더 많은 금융 혜택을 돌려받아야 한다고 믿는
          <br/>
          금융 각 분야의 전문가들이 글을 쓰고 있습니다
        </p>
      </section>
      { contents() }
      <section className={ styles.banner }>
        <div className={ styles.bannerContainer }>
          <h3 className={ styles.bannerTitle }>뱅크샐러드는 <strong>금융전문가분들의 기고를 기다립니다</strong></h3>
          <a
            href={ BANKSALAD_SITEMAP.CONTENTS_CONTRIBUTION }
            className={ styles.bannerLink }
          >
            기고방법 확인하기
          </a>
        </div>
      </section>
    </div>
  );
};
