type ValueOf<T> = T[keyof T];

interface WebInfoInLocalStorage {
  webDeviceId: string;
  webBanksaladUserId: string;
}

export const setWebInfoToLocalStorage = (
  key: keyof WebInfoInLocalStorage,
  value: ValueOf<WebInfoInLocalStorage>
) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getWebInfoFromLocalStorage = <CustomReturnType extends ValueOf<WebInfoInLocalStorage>>(
  key: keyof WebInfoInLocalStorage
): CustomReturnType | null => {
  const value = localStorage.getItem(key);

  return value === null ? null : JSON.parse(value);
};
