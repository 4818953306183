import { FirebaseScreen } from 'presentation/module/analytics/firebase/firebase.v2';
import { compactObject } from 'utils/index';

const VERSION = '1.0.0';

export interface TrackEventParam {
    screen_domain?: TrackEventScreenDomain;
    screen_domain_detail?: TrackEventScreenDomainDetail;
    screen_name?: TrackEventScreenName;
    screen_name_detail?: string;
    screen_slug_id?: string;
    screen_slug_category?: TrackEventScreenSlugCategory;
    popup_type1?: TrackEventPopupType1;
    popup_type2?: TrackEventPopupType2;
    object_category?: TrackEventObjectCategory;
    object_type? : TrackEventObjectType;
    object_id?: string;
    object_name?: string;
    object_organization?: string;
    object_organization_type?: string;
    option1?: string | number | boolean;
    option2?: string | number | boolean;
    option3?: string | number | boolean;
    option4?: string | number | boolean;
    destination_screen_domain?: string;
    destination_screen_domain_detail?: string;
    destination_screen_name?: string;
    destination_screen_name_detail?: string;
    destination_screen_slug_id?: string;
    destination_slug_type?: string;
    destination_screen_slug?: string;
    destination_object_type?: string;
    destination_object_id?: string;
    destination_object_name?: string;
    destination_object_organization?: string;
    slug_type?: string;
    screen_slug?: string;

    data_source?: string;
    event_action_value?: string,
    product_type?: string,
    organization?: string,
    event_domain?: string,
    event_domain_detail?: string,
    event_info?: string,

    service_version?: number;
}

export enum TrackEventName {
    APPLY = 'apply',
    ISSUE = 'issue',
    ISSUE_PRODUCT = 'issue_product',
    ISSUE_LOAN = 'issue_loan',
    ISSUE_INSURANCE = 'issue_insurance',
    EXPOSE_SCREEN = 'expose_screen',
    EXPOSE_PRODUCT = 'expose_product',
    EXPOSE_VIEW = 'expose_view',
    EXPOSE = 'expose',
    REACH_SCREEN = 'reach_screen',
    SELECT = 'select',
    SELECT_CHECKBOX = 'select_checkbox',
    POPUP = 'popup',
    MOVE = 'move',
    CLICK = 'click',
    CLOSE = 'close',
    FEEDBACK = 'feedback',
    START_EXPLORE = 'start_explore',
    START_MANAGE_CREDIT = 'start_manage_credit',
    EXPLORE_CREDIT_SCORE = 'explore_credit_score',
    REFRESH_CREDIT_SCORE = 'refresh_credit_score',
    GET_RECOMMENDATION = 'get_recommendation',
    CLICK_QUESTION_MARK = 'click_question_mark',
    CHECK_DETAIL = 'check_detail',
    CHECK_CREDIT_INFO = 'check_credit_info',
    CLICK_CREDIT_GOAL = 'click_credit_goal',
    CLICK_CREDIT_SCORE_BAR = 'click_credit_score_bar',
    CHANGE_TAB = 'change_tab',
    VIEW_CONTENT = 'view_content',
    SHARE = 'share',
    COPY = 'copy',
    OUTBOUND_LINK = 'outbound_link'
}

export enum TrackEventType {
    APPSFLYER = 'Appsflyer',
    AMPLITUDE = 'Amplitude',
    FIREBASE = 'Firebase',
    BRAZE = 'Braze'
}

export enum TrackEventScreenDomain {
    JAETECH = '재테크',
    JAETECH_CREDIT_SCORE = 'jaetech_credit_score',
    JAETECH_INSURANCE_RECOMMENDATION = 'jaetech_insurance_recommendation',
    JAETECH_INSURANCE_RECOMMENDATION_EMPTY = 'jaetech_insurance_recommendation_empty',
    CREDIT_MANAGEMENT = 'credit_management',
    LOAN_RECOMMENDATION = 'loan_recommendation',
    ASSET = 'asset'
}

export enum TrackEventScreenDomainDetail {
    LOAN_NEGOTIATE = '대출협상',
    INSURANCE = '보험설계'
}

export enum TrackEventScreenName {
    HOME = '홈',
    DETAIL = '상세',
    GENERAL_LOAN = '신규',
    REFINANCE_LOAN = '대환',
    SIMPLIFY_LOAN_ISSUE_PAPER_SUBMISSION = 'simplify_loan_issue_paper_submission',
    LOAN_ISSUE_PAPER_REQUEST_FORM = 'loan_issue_paper_request_form',
    HEALTH_CERTIFICATE_DETAIL = 'health_certificate_detail',
    REQUEST_HEALTH_CERTIFICATE = 'request_health_certificate',
    INSURANCE_DETAIL = 'insurance_detail',
    INSURANCE_DETAIL_KOR = '보험상세',
    CREDIT_RELATED_PAPER_SUBMISSION = 'credit_related_paper_submission',
    CREDIT_SCORE_GOAL = 'credit_score_goal',
    CREDIT_INFO = 'credit_info',
    CREDIT_TERMS_AGREEMENT = 'credit_terms_agreement',
    CREDIT_MANAGEMENT = 'credit_management',
    CREDIT_GOAL = 'credit_goal',
    LOAN_RECOMMENDATION = 'loan_recommendation',
    MAIN = '메인',
    WHOLE_INSURANCE = 'whole_insurance',
    CANCER_INSURANCE = 'cancer_insurance',
    SICKNESS_INSURANCE = 'sickness_insurance',
    ACTUAL_EXPENSE_INSURANCE = 'actual_expense_insurance'
}

export enum TrackEventScreenSlugCategory {
    LOAN = '대출',
    LOAN_ENG = 'loan'
}

export enum TrackEventSlugType {
    INSURANCE = 'insurance'
}

export enum TrackEventObjectCategory {
    CARD = 'card',
    CARD_KOR = '카드',
    LOAN = 'loan',
    INSURANCE = 'insurance',
    INSURANCE_KOR = '보험'
}

export enum TrackEventObjectType {
    CARD = '카드',
    CARD_CHECK = 'check',
    CARD_CREDIT = 'credit',
    CARD_HYBRID = 'hybrid',
    LOAN = 'loan',
    INSURANCE = 'insurance'
}

export enum TrackEventPopupType1 {
    SIMPLIFY_SUBMIT = '서류간소화',
    ERROR = '오류',
    CREDIT_INFORMATION = '신용정보'
}

export enum TrackEventPopupType2 {
    NETWORK = '네트워크'
}

export const sendTrackEvent = (
    eventName: TrackEventName,
    eventParam: TrackEventParam = {} as TrackEventParam,
    eventType: Array<TrackEventType>,
    version: string = VERSION
): void => {
    const eventParamToSend = compactObject({
        ...eventParam,
        version
    });

    window.trackEvent(eventName, eventParamToSend, eventType);
};

export const sendTrackEventV3 = (
    eventName: TrackEventName,
    viewName: string,
    eventParam: TrackEventParam = {} as TrackEventParam
): void => {
    const eventParamWithVersion = {
        ...eventParam,
        version: '3.0.0'
    };
    const eventTypes = [ TrackEventType.AMPLITUDE, TrackEventType.FIREBASE, TrackEventType.BRAZE, TrackEventType.APPSFLYER ];

    window.trackEvent(`${eventName}__${viewName}`, eventParamWithVersion, eventTypes);
};

export const sendTrackEventBasedFirebase = (
    eventName: TrackEventName,
    eventParam: TrackEventParam = {} as TrackEventParam
): void => {
    const eventParamToSend = compactObject({
        ...eventParam,
        data_source: 'firebase',
        version: VERSION
    });
    const eventTypeToSend = [ TrackEventType.AMPLITUDE ];

    window.trackEvent(eventName, eventParamToSend, eventTypeToSend);
};

export const getScreenNameFromFirebaseScreen = (
    screenName: FirebaseScreen
): TrackEventScreenName => {
    switch (screenName) {
        case FirebaseScreen.CREDIT_GOAL:
            return TrackEventScreenName.CREDIT_GOAL;
        case FirebaseScreen.CREDIT_MANAGEMENT:
            return TrackEventScreenName.CREDIT_MANAGEMENT;
    }
};
