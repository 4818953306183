import { CardSummaryRepositoryType } from 'core/repository/card-summary';
import { CardSummary } from 'core/entity/card/summary';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class CacheCardForCompare extends UseCase<void> {
    card?: CardSummary;
    private repository: CardSummaryRepositoryType;

    constructor(repository: CardSummaryRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<void> {
        return this.repository.post(this.card);
    }

    protected validate(): boolean {
        return !!this.card;
    }
}
