import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1MedicalCheckupAgreementView = () => (
    <div className={ styles.content }>
        <div className={ styles.container }>
            <h2>개인정보(민감정보) 수집 및 이용 동의</h2>
            <h5>보험 추천 서비스 제공을 위하여 아래와 같이 개인정보(민감정보) 수집 및 이용 동의를 받고자 합니다.</h5>
            <ul>
            <li>
                1. 개인정보(민감정보) 수집 및 이용 목적: 보험 추천 서비스 제공
            </li>
            <li>
                2.수집하는 개인정보(민감정보) 항목<br/>
                - 필수 정보 : 신장, 체중, 허리둘레, 체질량 지수, 시력, 청력, 혈압, 신장질환(요단백), 빈혈(혈색소), 당뇨병(식전혈당), 고혈압/이상지질혈증/동맥경화(총 콜레스테롤, HDL-콜레스테롤, 트리글리세라이드, LDL-콜레스테롤), 만성신장질환(혈청크레아티닌), 간장질환(신사구체 여과율(GFR), AST(SGOT), ALT(SGPT), 감마지피티), 폐결핵흉부질환, 골다공증, 검진일자, 검진 소견, 검진 결과
            </li>
            <li>
                3.개인정보(민감정보) 보유 및 이용 기간: 회원 탈퇴 시
            </li>
            <li>
                개인정보(민감정보) 수집 및 이용 동의를 거부할 권리가 있으며, 동의 거부 시 보험 추천 제공 서비스를 이용하실 수 없습니다.
            </li>
            </ul>
        </div>
    </div>
);
