import { LoanLegalConfiguration } from 'core/entity/legal-configuration/loan';
import { LoanCFAReview } from 'core/entity/legal-configuration/loan/cfa/review';
import { LoanCFAReviewInProgress } from 'core/entity/legal-configuration/loan/cfa/review/in-progress';
import { LoanCFAReviewNoReviewRequired } from 'core/entity/legal-configuration/loan/cfa/review/no-review-required';
import { LoanCFAReviewReviewed } from 'core/entity/legal-configuration/loan/cfa/review/reviewed';
import React from 'react';

import styles from '../styles.pcss';

interface Props {
    config?: LoanLegalConfiguration;
}

export class LoanLegalTerms extends React.Component<Props> {
  render() {
    const { config } = this.props;

    return !!config ? (
      <div className={ styles.terms }>
        <div dangerouslySetInnerHTML={{ __html: config.cfa.description.message }} />
        <ul>
          { config && <li><b>{ this.renderStatus(config.cfa.review) }</b></li> }
        </ul>
      </div>
    ) : (
      <div className={ styles.terms }>
        <ul>
          <li>여신금융협회 심의필 : 심의번호 기재예정</li>
        </ul>
      </div>
    );
  }

    private renderStatus = (review: LoanCFAReview) => {
      if (review instanceof LoanCFAReviewInProgress) {
        return '심의필 : 심의번호 기재예정';
      } else if (review instanceof LoanCFAReviewNoReviewRequired) {
        return '이 대출상품의 정보는 대출사와 무관하게 뱅크샐러드가 객관적으로 수집 및 분류하여 투명하게 제공하고 있습니다.';
      } else if (review instanceof LoanCFAReviewReviewed) {
        return `심의필 : ${review.number}`;
      } else {
        return null;
      }
    };

}
