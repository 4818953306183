import { Application } from 'application/context.instance';
import { Notice, NoticeType } from 'core/entity/notice';
import { List } from 'immutable';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { HTMLBody } from 'presentation/components/html-body';
import { Loader } from 'presentation/components/loader';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { apply, emptyList, toDateString } from 'utils/index';
import { isBanksaladWebView } from 'utils/is-banksalad-web-view';

import styles from './styles.pcss';

interface State {
    notices: List<Notice>;
    fetchState: FetchState;
    type: NoticeType;
}

export class NoticeAllRoute extends React.Component<
    RouteComponentProps<any>,
    State
> {
    state = {
      notices: emptyList(),
      fetchState: FetchState.FETCHING,
      type: NoticeType.SERVICE,
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
      this.fetch(NoticeType.SERVICE);
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    onSelectNoticeType(e: React.ChangeEvent<HTMLInputElement>) {
      this.setState(
        {
          type: e.target.id as NoticeType,
        },
        () => {
          this.fetch(this.state.type);
        },
      );
    }

    render() {
      const { notices, fetchState } = this.state;

      return fetchState === FetchState.FETCHED ? (
        isBanksaladWebView() ? (
          <ul className={ styles.simpleList }>
            {notices.map(this.renderNoticeSimply)}
          </ul>
        ) : (
          <>
            <Header />
            <div className={ styles.wrap }>
              <div className={ styles.container }>
                <strong className={ styles.topic }>
                  { NOTICE_TYPE_LABEL[this.state.type] || '공지사항' }
                </strong>
                <div
                  className={ styles.noticeSelectBox }
                  role='radiogroup'
                >
                  <label
                    htmlFor={ NoticeType.SERVICE }
                    aria-selected={ this.state.type === NoticeType.SERVICE }
                  >
                    <input
                      type='radio'
                      name='noticeType'
                      id={ NoticeType.SERVICE }
                      onChange={ this.onSelectNoticeType.bind(this) }
                    />
                    <span>서비스 공지사항</span>
                  </label>
                  <label
                    htmlFor={ NoticeType.COMPANY }
                    aria-selected={ this.state.type === NoticeType.COMPANY }
                  >
                    <input
                      type='radio'
                      name='noticeType'
                      id={ NoticeType.COMPANY }
                      onChange={ this.onSelectNoticeType.bind(this) }
                    />
                    <span>회사 공지사항</span>
                  </label>
                </div>

                <ul className={ styles.list }>
                  {notices.map(this.renderNotice)}
                </ul>
              </div>
            </div>
            <Footer />
          </>
        )
      ) : fetchState === FetchState.FETCHING ? (
        <Loader padding={ 150 } radius={ 25 } />
      ) : (
        <FetchError padding={ 150 }>
                공지사항을 불러오는 중 에러가 발생했습니다
          <br />
                잠시 후 다시 시도해주세요
        </FetchError>
      );
    }

    private renderNoticeSimply = (notice: Notice, index: number) => {
      return (
        <li key={ `notices-${index}` }>
          <a
            href={ BANKSALAD_SITEMAP_BY_ID.NOTICE(notice.id) }
            className={ styles.simpleLink }
          >
            <h2>{toDateString(notice.createdAt, '.')}</h2>
            <h1>{notice.title}</h1>
          </a>
        </li>
      );
    };

    private renderNotice = (notice: Notice) => {
      const id = `notices-${notice.id}`;

      return (
        <li key={ id }>
          <input
            type='checkbox'
            id={ id }
            className={ styles.noticeTrigger }
          />
          <label htmlFor={ id } className={ styles.noticeLabel }>
            <h2>{toDateString(notice.createdAt, '.')}</h2>
            <h1>{notice.title}</h1>
          </label>
          <div className={ styles.noticeContent }>
            <HTMLBody html={ notice.description } />
          </div>
        </li>
      );
    };

    private fetch = (type: NoticeType) => {
      apply(Application.useCases.getNotices, (it) => {
        it.type = type;
      })
        .runOnAnimateFrame()
        .subscribe(
          (notices) =>
            this.setState({
              notices,
              fetchState: FetchState.FETCHED,
            }),
          () =>
            this.setState({
              fetchState: FetchState.ERROR,
            }),
        )
        .unsubscribeBy(this.subscriptionBag);
    };
}

const NOTICE_TYPE_LABEL = {
  [NoticeType.SERVICE]: '서비스 공지사항',
  [NoticeType.COMPANY]: '회사 공지사항',
} as const;
