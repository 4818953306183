import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanInterviewHistory } from 'core/entity/loan/interview-history';

export class LoanInterviewHistoryMapper implements BrowserStorageMapper<LoanInterviewHistory> {
    toJson(entity: LoanInterviewHistory): any {
        return {
            id: entity.id,
            name: entity.name
        };
    }

    toEntity(value: any): LoanInterviewHistory {
        return new LoanInterviewHistory(
            value.id,
            value.name
        );
    }

}
