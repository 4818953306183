import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceAction } from 'core/entity/personal-finance/advice/action';
import { optional } from 'utils/index';

export class AdviceActionMapper implements NetworkResponseMapper<AdviceAction> {
    fromJson(json: any): AdviceAction {
        return new AdviceAction(
            json.url,
            optional(json.description)
        )
    }
}
