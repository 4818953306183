import { Entity } from 'core/entity';

export class InsuranceApplyLink extends Entity {
    type: InsuranceApplyLink.Type;
    value: string;
    title?: string;

    constructor(
        type: InsuranceApplyLink.Type,
        value: string,
        title: string = null
    ) {
        super();
        this.type = type;
        this.value = value;
        this.title = title;
    }
}

export module InsuranceApplyLink {
    export enum Type {
        PHONE,
        INTERNET
    }
} 
