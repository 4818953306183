import { ClickGnb, ClickLnb } from "../interfaces/gnb";
import { sendTrackEvent } from "../send-track-event";
import { EventName } from "../types/event-name";

export const trackClickGnb = (gnbText: string) => {
  sendTrackEvent<ClickGnb>({
    eventParam: {
      event_type: EventName.CLICK_GNB,
      gnb_text: gnbText,
      url_path: window.location.pathname,
    },
  });
};

export const trackClickLnb = (gnbText: string, lnbText: string) => {
  sendTrackEvent<ClickLnb>({
    eventParam: {
      event_type: EventName.CLICK_LNB,
      gnb_text: gnbText,
      lnb_text: lnbText,
      url_path: window.location.pathname,
    },
  });
};
