import { Entity } from 'core/entity';
import { ComparableCard } from 'core/entity/comparable-card';

export class ComparableCardConfiguration extends Entity {
    card: ComparableCard;
    caption: string;

    constructor(
        card: ComparableCard,
        caption: string
    ) {
        super();
        this.card = card;
        this.caption = caption;
    }
}
