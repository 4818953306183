import { Entity } from 'core/entity';

export class CardMileage extends Entity {
    company: CardMileage.Company;
    amount: number;

    constructor(
        company: CardMileage.Company,
        amount: number
    ) {
        super();
        this.company = company;
        this.amount = amount;
    }
}

export module CardMileage {
    export enum Company {
        KA, AA, OTHERS
    }
}
