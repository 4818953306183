import { RepositoryDependencies } from 'application/@context/repositories';
import { CacheService } from 'core/service/cache';
import { CardService } from 'core/service/card';
import { InsuranceService } from 'core/service/insurance';
import { LegalConfigService } from 'core/service/legal-config';
import { LoanService } from 'core/service/loan';
import { MedicalCheckupService } from 'core/service/medical-check-up';
import { PersonalCreditService } from 'core/service/personal-credit';
import { PersonalFinanceService } from 'core/service/personal-finance';
import { PersonalizedFinanceLoanService } from 'core/service/personalized-finance/loan';
import { PersonalizedRecommendService } from 'core/service/personalized-recommend';
import { PromotionService } from 'core/service/promotion';
import { ServiceCenterService } from 'core/service/service-center';
import { SVGService } from 'core/service/svg';
import { UserService } from 'core/service/user';

export class ServiceDependencies {
    personalizedFinance: PersonalizedFinanceLoanService;
    personalizedRecommend: PersonalizedRecommendService;
    card: CardService;
    loan: LoanService;
    insurance: InsuranceService;
    medicalCheckup: MedicalCheckupService;
    personalFinance: PersonalFinanceService;
    personalCredit: PersonalCreditService;
    user: UserService;
    svg: SVGService;
    promotion: PromotionService;
    cache: CacheService;
    legalConfig: LegalConfigService;
    serviceCenter: ServiceCenterService;

    constructor(repo: RepositoryDependencies) {
      this.card = new CardService(repo.card.cardRecommend);
      this.loan = new LoanService(
        repo.loan.loan,
        repo.loan.loanRecommend,
        repo.loan.loanSurveyConfiguration,
        repo.loan.loanStatistics
      );
      this.insurance = new InsuranceService(repo.insurance.insurance);
      this.personalFinance = new PersonalFinanceService(
        repo.pfm.userBankAccount,
        repo.pfm.userCashAccount,
        repo.pfm.userActualAsset,
        repo.pfm.userStock,
        repo.pfm.userFund,
        repo.pfm.userCard,
        repo.pfm.userLoan,
        repo.pfm.transactionCategory,
        repo.pfm.userSecuritiesFirmAccount,
        repo.pfm.userProducts,
        repo.pfm.advice,
        repo.pfm.healthInsuranceCertificate,
        repo.pfm.healthInsurancePaymentDetail,
        repo.pfm.healthInsuranceSubmit,
        repo.pfm.userFinancialData
      );
      this.personalCredit = new PersonalCreditService(
        repo.pfm.creditScoreFromKCB,
        repo.pfm.creditTip,
        repo.pfm.creditInquiryRecord,
        repo.pfm.creditMetadata,
        repo.pfm.creditCardsStatusFromKCB,
        repo.pfm.creditLoansStatusFromKCB,
        repo.pfm.creditOverduesStatusFromKCB,
        repo.pfm.creditJointSuretiesStatusFromKCB,
        repo.pfm.creditChangeRecord
      );
      this.medicalCheckup = new MedicalCheckupService(
        repo.medicalCheckup.medicalCheckUp
      );
      this.personalizedRecommend = new PersonalizedRecommendService(
        repo.personalizedRecommend.loan,
        repo.personalizedRecommend.insurance
      );
      this.personalizedFinance = new PersonalizedFinanceLoanService(repo.personalizedFinance.loan);
      this.user = new UserService(repo.user);
      this.svg = new SVGService(repo.svg);
      this.promotion = new PromotionService(repo.promotion);
      this.cache = new CacheService(repo.localCachedState, repo.sessionCachedState);
      this.legalConfig = new LegalConfigService(repo.legalConfig.cardLegalConfig, repo.legalConfig.loanLegalConfig);
      this.serviceCenter = new ServiceCenterService(repo.serviceCenter);
    }
}
