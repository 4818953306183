import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class UserCardBillContent extends Entity {
    id: string;
    dueDate: Date;
    amount: Amount;

    constructor(
        id: string,
        dueDate: Date,
        amount: Amount
    ) {
        super();
        this.id = id;
        this.dueDate = dueDate;
        this.amount = amount;
    }
}
