import { Entity } from 'core/entity';

export class CardAnnualCostPromotion extends Entity {
    summary: string;
    description: string;
    additionalDescription: string;
    issueUrl: string;
    startDate: Date;
    endDate: Date;

    constructor(
        summary: string,
        description: string,
        additionalDescription: string,
        issueUrl: string,
        startDate: Date,
        endDate: Date
    ) {
        super();
        this.summary = summary;
        this.description = description;
        this.additionalDescription = additionalDescription;
        this.issueUrl = issueUrl;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
