import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { List } from 'immutable';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';
import { AdviceNetAssetAmount } from 'core/entity/personal-finance/advice/net-asset-amount';

export class AdviceNetAssetValuationData extends Entity implements AdviceContentData {
    netAssetValuationHistory: List<AdviceNetAssetAmount>;
    message?: AdviceMessage;

    constructor(
        netAssetValuationHistory: List<AdviceNetAssetAmount>,
        message: AdviceMessage = null
    ) {
        super();
        this.netAssetValuationHistory = netAssetValuationHistory;
        this.message = message;
    }
}
