import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { CardSummary } from 'core/entity/card/summary';

export class CardSummaryMapper implements BrowserStorageMapper<CardSummary> {
    toEntity(json: any): CardSummary {
        return new CardSummary(
            json.id,
            json.name,
            json.company_name,
            json.image_url
        );
    }

    toJson(target: CardSummary): any {
        return {
            id: target.id,
            name: target.name,
            company_name: target.companyName,
            image_url: target.imageUrl
        };
    }

}
