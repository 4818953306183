import { Entity } from 'core/entity';
import { List } from 'immutable';
import { LoanPrimeInterestNode } from 'core/entity/loan/prime-interest-node';
import { LoanInterestValue } from 'core/entity/loan/interest-value';

export class LoanPrimeInterest extends Entity {
    nodes: List<LoanPrimeInterestNode>;
    interest: LoanInterestValue;

    constructor(
        nodes: List<LoanPrimeInterestNode>,
        interest: LoanInterestValue
    ) {
        super();
        this.nodes = nodes;
        this.interest = interest;
    }
}
