import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { UserCashAccountUpdateSpec } from 'core/entity/personal-finance/cash-account/update-spec';
import { HttpProvider } from 'data/http';
import { UserCashAccountMapper } from 'data/http/mapper/personal-finance/cash-account';
import { UserCashAccountUpdateSpecMapper } from 'data/http/mapper/personal-finance/cash-account/update-spec';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserCashAccountCreateSpec } from 'core/entity/personal-finance/cash-account/create-spec';
import { UserCashAccountCreateSpecMapper } from 'data/http/mapper/personal-finance/cash-account/create-spec';

export class UserCashAccountsApiProvider extends HttpProvider {
    create(accessToken: AccessToken, spec: UserCashAccountCreateSpec): Observable<UserCashAccount> {
        return this.requestPOST(
            'api/v2/me/cash-accounts',
            new UserCashAccountCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCashAccountMapper().fromJson(result.data)
        )
    }

    update(accessToken: AccessToken, id: string, spec: UserCashAccountUpdateSpec): Observable<UserCashAccount> {
        return this.requestPATCH(
            `api/v2/me/cash-accounts/${id}`,
            new UserCashAccountUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCashAccountMapper().fromJson(result.data)
        )
    }
}
