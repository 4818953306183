import { Card } from 'core/entity/card';
import { List } from 'immutable';
import { HorizontalList } from 'presentation/components/horizontal-list';
import { HorizontalItem } from 'presentation/components/horizontal-list/base/item';
import {
  GA_ACTION,
  GA_CATEGORY,
  GA_DOMAIN,
  GA_LABEL,
  sendGAEvent,
} from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface PromotionBannerText {
  highlight?: string;
  promotion?: string;
}

class PromotionCard {
  constructor(
    public id: number,
    public companyName: string,
    public imageUrl: string,
    public imageOrientation: Card.ImageOrientation,
    public name: string,
    public summary: string,
    public annualCostDescription: string,
    public customPromotionTitle?: PromotionBannerText,
  ) {}
}

const PRESET = List.of(
  new PromotionCard(4200, 'BC 바로카드', 'https://cdn.banksalad.com/resources/images/cards/bc_baro_payback.png', Card.ImageOrientation.PORTRAIT, 'BC 바로 페이백 플러스', '전월 실적/할인 한도 없이 어디서나 0.7% 기본 할인', '최대 15,000원'),
  new PromotionCard(2928, '삼성카드', 'https://cdn.banksalad.com/cards/samsung_2928.png', Card.ImageOrientation.LANDSCAPE, '삼성카드 taptap O', '직접 선택하는 혜택, 탭탭오 옵션형!', '최대 10,000원'),
  new PromotionCard(4199, 'BC 바로카드', 'https://cdn.banksalad.com/resources/images/cards/bc_baro_reward.png', Card.ImageOrientation.PORTRAIT, 'BC 바로 리워드 플러스', '한도 없이 어디서나 최대 1.5% 기본적립', '최대 10,000원'),
  new PromotionCard(3725, 'KB국민카드', 'https://cdn.banksalad.com/entities/etc/1534729093799-3725.png', Card.ImageOrientation.LANDSCAPE, '탄탄대로 올쇼핑카드', '여기저기, 빈틈없이 챙겨받는 올쇼핑 할인!', '최대 15,000원'),
  new PromotionCard(3722, '마패카드', 'https://cdn.banksalad.com/entities/etc/1533712616223-3722.png', Card.ImageOrientation.LANDSCAPE, 'my pass 마패 카드', '대중교통 할인은 my pass 마패 카드!', '최대 17,000원'),
  new PromotionCard(3422, '신한카드', 'https://cdn.banksalad.com/resources/images/cards/shinhan_asiana_air_1.5.png', Card.ImageOrientation.PORTRAIT, '아시아나 신한카드 Air 1.5', '1.5배 높은 적립! 아시아나 신한카드 Air 1.5', '최대 45,000원'),
  new PromotionCard(
    2804, '삼성카드',
    'https://cdn.banksalad.com/cards/samsung_2804.png',
    Card.ImageOrientation.LANDSCAPE,
    '삼성카드 & MILEAGE PLATINUM(스카이패스)',
    '더욱 강력한 스카이패스 마일리지 혜택',
    '최대 49,000원',
  ),
);

export class EverydayPromotionCardsBanner extends React.Component {
  componentDidMount() {
    PRESET.map((it, i) =>
      sendGAEvent(
        GA_DOMAIN.CARD,
        GA_CATEGORY.SECTORS.CARD.EVERYDAY_PROMOTIONS,
        GA_ACTION.PRODUCT_IMPRESSION,
        GA_LABEL.PRODUCT_IMPRESSION(it.id, it.companyName, i + 1),
      ),
    );
  }

  render() {
    return (
      <HorizontalList itemMinWidth={ 180 }>
        {
          PRESET.map((it, i) => {
            const imageStyle = it.imageOrientation === Card.ImageOrientation.LANDSCAPE ?
              styles.landscapeImage : styles.portraitImage;
            const shadowStyle = it.imageOrientation === Card.ImageOrientation.LANDSCAPE ?
              styles.landscapeShadow : styles.portraitShadow;
            const promotionText: PromotionBannerText =
              it.customPromotionTitle
                ? {
                  highlight: it.customPromotionTitle.highlight,
                  promotion: it.customPromotionTitle.promotion
                } : {
                  highlight: '100%',
                  promotion: '연회비 캐시백'
                };

            return (
              <HorizontalItem key={ `p-cards-${i}` }>
                <a
                  href={ BANKSALAD_SITEMAP_BY_ID.CARD(it.id) }
                  className={ styles.button }
                  onClick={ this.onClickCard(it) }
                >
                  <div className={ styles.imageArea }>
                    <img src={ it.imageUrl } alt={ it.name } className={ imageStyle }/>
                    <div className={ shadowStyle }>상세 보기</div>
                  </div>
                  <h2 className={ styles.name }>{ it.name }</h2>
                  <p className={ styles.summary }>{ it.summary }</p>
                  <div className={ styles.box }>
                    <span className={ styles.canceledText }>{ it.annualCostDescription }</span>
                    {
                      this.renderPromotionTitle(
                        !!it.customPromotionTitle,
                        promotionText
                      )
                    }
                  </div>
                </a>
              </HorizontalItem>
            );
          }).toArray()
        }
      </HorizontalList>
    );
  }

    private onClickCard = (it: PromotionCard) => () => {
      sendGAEvent(
        GA_DOMAIN.CARD,
        GA_CATEGORY.SECTORS.CARD.EVERYDAY_PROMOTIONS,
        GA_ACTION.LINK.CARDS_DETAIL,
        GA_LABEL.PRODUCT_DETAIL('상세보기', it.companyName, it.id),
      );
    };

    private renderPromotionTitle = (
      hasCustomEvent: boolean,
      { highlight, promotion }: PromotionBannerText,
    ) => {
      const bannerStyle = hasCustomEvent ? styles.eventPromotion : styles.event;
      const accentStyle = hasCustomEvent ? styles.smallAccent : styles.accent;

      return (
        <strong className={ bannerStyle }>
          <span className={ accentStyle }>{ highlight }</span>{' '}
          { promotion }
        </strong>
      );
    }
}
