import axios, { AxiosRequestConfig } from "axios";

import { isNamespaceProduction } from "./env";

export const createCmsAxiosInstance = (options?: AxiosRequestConfig) => {
  const CMS_HOST = {
    staging: 'https://financialshopping.staging.banksalad.com/api',
    production: 'https://financialshopping.banksalad.com/api',
  };

  return axios.create({
    baseURL: isNamespaceProduction() ? CMS_HOST.production : CMS_HOST.staging,
    headers: {
      Authorization: `Bearer ${process.env.STRAPI_READ_ONLY_TOKEN}`,
    },
    ...options,
  });
};
