import React from 'react';

import { Application } from 'application/context.instance';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { lets } from 'utils/index';

interface Props {
    children: any
}

export const LoginNotRequired = (props: Props) =>
    lets(Application.me, me => {
        if (me) location.href = BANKSALAD_SITEMAP.MAIN;
        return me ? null : <>{ props.children }</>;
    });
