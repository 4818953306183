import React from 'react';

import styles from './styles.pcss';

interface Props {
    description: string;
    summary: string;
    notForIssue?: boolean;
    redirect?: () => void;
}

export const CardAnnualCostPromotionDescription = ({ redirect, description, summary, notForIssue }: Props) => (
    <div className={ redirect ? styles.wrap : styles.extendedWrap }>
        <section className={ styles.head }>
            { summary }
            <strong>
                { notForIssue ? '연회비 이벤트 중인 상품입니다' : '아래 내용을 반드시 확인하세요!' }
            </strong>
        </section>
        <section className={ redirect ? styles.body : styles.extendedBody }>
            <dl>
            <dt>행사내용</dt>
            <dd dangerouslySetInnerHTML={{ __html: description }} />
            </dl>
        </section>
        {
            redirect &&
            <section className={ styles.foot }>
                <button
                    onClick={ redirect }
                    className={ styles.issue }
                >
                    { notForIssue ? '해당 카드사로 이동' : '신청페이지로 이동' }
                </button>
            </section>
        }
    </div>
);
