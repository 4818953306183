import { NetworkResponseMapper } from 'data/http/mapper';
import { optional } from 'utils/index';
import { LoanInterestPromotionMapper } from 'data/http/mapper/loan/interest-promotion';
import { LoanInterestValueMapper } from 'data/http/mapper/loan/interest-value';
import { LoanInterestTypeMapper } from 'data/http/mapper/loan/interest-type';
import { LoanInterestConfiguration } from 'core/entity/loan/interest-configuration';
import { LoanPrimeInterestMapper } from 'data/http/mapper/loan/prime-interest';

export class LoanInterestConfigurationMapper implements NetworkResponseMapper<LoanInterestConfiguration> {
    fromJson(json: any): LoanInterestConfiguration {
        return new LoanInterestConfiguration(
            new LoanPrimeInterestMapper().fromJson(json.prime_interest),
            optional(json.interest_promotion, promotion => new LoanInterestPromotionMapper().fromJson(promotion)),
            optional(json.base_interest, baseInterest => new LoanInterestValueMapper().fromJson(baseInterest)),
            optional(json.additional_interest, additionalInterest => new LoanInterestValueMapper().fromJson(additionalInterest)),
            optional(json.average_interest, averageInterest => new LoanInterestValueMapper().fromJson(averageInterest)),
            new LoanInterestValueMapper().fromJson(json.total_interest),
            new LoanInterestTypeMapper().fromJson(json.type)
        );
    }
}
