import { NetworkResponseMapper } from 'data/http/mapper';
import { UserSecuritiesFirmAccountStockItem } from 'core/entity/personal-finance/securities-firm-account/stock-item';

export class UserSecuritiesFirmAccountStockItemMapper implements NetworkResponseMapper<UserSecuritiesFirmAccountStockItem> {
    fromJson(json: any): UserSecuritiesFirmAccountStockItem {
        return new UserSecuritiesFirmAccountStockItem(
            json.id,
            json.name
        )
    }

}
