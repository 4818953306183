import { trackClickFooter } from 'presentation/module/analytics/events/trackers/footer';
import { BANKSALAD_SITEMAP, OUTBOUND_LINK } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface Props {
  children: React.ReactNode;
}

interface FooterMenu {
  title: string;
  target: string;
  rel?: string;
}

enum MENU_TEXT {
  NOTICE = '공지사항',
  FEE_GUIDANCE = '뱅샐머니 이용안내',
  BANKSALAD_MONEY_SUSPENSION = '뱅샐머니 이용정지',
  PRIVACY_POLICY = '개인정보처리방침',
  TERMS = '이용약관',
  ELECTRONIC_FINANCIAL_TRANSACTIONS ='전자금융거래이용약관',
  CERTIFICATION_CENTER = '인증서센터',
  SERVICE_CENTER = '고객센터',
  COLLABORATION = '사업 제휴',
  USAGE_GUIDANCE = '이용 안내'
}

const REL_NO_FOLLOW = 'nofollow';

const FOOTER_MENU_ARRAY: FooterMenu[] = [
  {
    title: MENU_TEXT.NOTICE,
    target: BANKSALAD_SITEMAP.NOTICE,
  },
  {
    title: MENU_TEXT.FEE_GUIDANCE,
    target: BANKSALAD_SITEMAP.FEE_GUIDANCE,
  },
  {
    title: MENU_TEXT.BANKSALAD_MONEY_SUSPENSION,
    target: BANKSALAD_SITEMAP.BANKSALAD_MONEY_SUSPENSION,
  },
  {
    title: MENU_TEXT.PRIVACY_POLICY,
    target: BANKSALAD_SITEMAP.POLICIES_PRIVACY_POLICY_RECENT,
  },
  {
    title: MENU_TEXT.TERMS,
    target: BANKSALAD_SITEMAP.POLICIES_TERMS_RECENT,
  },
  {
    title: MENU_TEXT.ELECTRONIC_FINANCIAL_TRANSACTIONS,
    target: BANKSALAD_SITEMAP.POLICIES_ELECTRONIC_FINANCIAL_TRANSACTIONS,
  },
  {
    title: MENU_TEXT.CERTIFICATION_CENTER,
    target: BANKSALAD_SITEMAP.CERT,
  },
  {
    title: MENU_TEXT.SERVICE_CENTER,
    target: BANKSALAD_SITEMAP.SERVICE_CENTER,
  },
  {
    title: MENU_TEXT.COLLABORATION,
    target: OUTBOUND_LINK.MAIL_TO_RAINIST,
    rel: REL_NO_FOLLOW,
  },
];

export const FooterLegalMenu = ({ children }: Props) => {
  const renderMenu = (footerMenuArray: FooterMenu[]) =>
    footerMenuArray.map(({ title, target, rel }: FooterMenu) => (
      <li key={ `key-${title}` } onClick={ () => {
        trackClickFooter(title);
      } }>
        { rel ? (
          <a href={ target } rel={ rel }>
            {title}
          </a>
        ) : (
          <a href={ target } className={ title === MENU_TEXT.PRIVACY_POLICY ? styles.bold : undefined } target="_blank" rel="noopener noreferrer" >
            {title}
          </a>
        ) }
      </li>
    ));

  return (
    <section className={ styles.legal }>
      <div className={ styles.menuTitle }>{MENU_TEXT.USAGE_GUIDANCE}</div>
      <ul className={ styles.policies }>
        { renderMenu(FOOTER_MENU_ARRAY) }
      </ul>
      { children }
    </section>
  );
};

