import { UseCase } from 'core/use-case';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { Observable } from 'rxjs/Rx';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';

export class CacheLoanRecommendSpec extends UseCase<void> {
    spec: LoanRecommendSpec;
    private repository: LoanRecommendRepositoryType;

    constructor(repository: LoanRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<void> {
        return this.repository.cacheSpec(this.spec);
    }

    protected validate(): boolean {
        return !!this.spec;
    }

}
