import { Card } from 'core/entity/card';
import { CardCategorySpecialBenefit } from 'core/entity/card/category-special-benefit';
import { CardPreferredSpecialBenefit } from 'core/entity/card/preferred-special-benefit';
import { CardSpecialBenefit } from 'core/entity/card/special-benefit';
import { List } from 'immutable';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN, GA_EVENT_VALUE } from 'presentation/module/analytics/ga';
import React from 'react';
import { uniqueKey } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    card: Card;
    benefits: List<CardCategorySpecialBenefit>;
    gaCategory?: string;
}

export const CardInfoCategorySpecialBenefits = (props: Props) => {

  // TODO: 심의이슈로 임시처리 #1365
  const applyExceptionText = (text: string) => (
    props.card && props.card.id === 3580 ?
      text.replace(/SK엔크린/g, 'SK에너지') :
      text
  );

  const onChangeInput = (item: CardPreferredSpecialBenefit) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      props.gaCategory && sendGAEvent(
        GA_DOMAIN.CARD,
        props.gaCategory,
        GA_ACTION.DROP_DOWN,
        item.title,
        e.currentTarget.checked ?
          GA_EVENT_VALUE.OPEN :
          GA_EVENT_VALUE.CLOSE
      );
    };

  const toDetails = (basicBenefits: List<CardSpecialBenefit>) => (
    basicBenefits.map(spec => (
      <div key={ uniqueKey() }>
        <h6>{ applyExceptionText(spec.title) }</h6>
        <span dangerouslySetInnerHTML={{ __html: applyExceptionText(spec.description) }}/>
      </div>
    ))
  );

  const toItems = (specialBenefits: List<CardPreferredSpecialBenefit>) => (
    specialBenefits.map(item => {
      const key = uniqueKey();

      return (
        <li key={ key }>
          <input
            type="checkbox"
            id={ key }
            onChange={ onChangeInput(item) }
          />
          <label htmlFor={ key }>{ applyExceptionText(item.title) }</label>
          <section className={ styles.detail }>
            { toDetails(item.benefits) }
          </section>
        </li>
      );
    })
  );

  const groups = props.benefits.map(benefit =>
    <li key={ uniqueKey() }>
      <h5>
        { benefit.category }
      </h5>
      <ul className={ styles.items }>
        { toItems(benefit.specialBenefits) }
      </ul>
    </li>
  );

  return <ul className={ styles.benefits }>{ groups }</ul>;
};
