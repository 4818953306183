import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanComputationResult } from 'core/entity/loan/computation-result';
import { LoanInterestConfigurationMapper } from 'data/http/mapper/loan/interest-configuration';
import { LoanOptionMapper } from 'data/http/mapper/loan/option';
import { List } from 'immutable';

export class LoanComputationResultMapper implements NetworkResponseMapper<LoanComputationResult> {
    fromJson(json: any): LoanComputationResult {
        return new LoanComputationResult(
            new LoanInterestConfigurationMapper().fromJson(json.interest_configuration),
            json.maximum_amount_limit,
            List(json.options.map((option: any) => new LoanOptionMapper().fromJson(option)))
        )
    }
}
