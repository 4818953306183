import Axios from 'axios';
import { Content } from 'core/entity/content';
import { ContentCardComparison } from 'core/entity/content/card-comparison';
import { ContentMapper } from 'data/http/mapper/content';
import { ContentViewer } from 'presentation/components/content-viewer';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { BANKSALAD_SITEMAP, BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNamespaceProduction } from 'utils/env';

interface State {
  fetchState: FetchState;
  content?: Content;
}

export const ContentsDetailRoute = ({ match }: RouteComponentProps<any>) => {
  const id = match.params.id;
  const [{ content, fetchState }, setState] = useState({
    content: null as Content,
    fetchState: FetchState.FETCHING,
  });

  useEffect(() => {
    (async () => {
      const API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/contents/${id}.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/contents/${id}.json`;
      
      const { data } = await Axios.get(API_PATH);
      
      setState({
        content: new ContentMapper().fromJson(data.data),
        fetchState: FetchState.FETCHED,
      });
    })();
  }, []);

  switch(fetchState) {
  case FetchState.FETCHING:
    return <Loader padding={ 250 } radius={ 25 } />;
  case FetchState.ERROR:
    return (
      <FetchError padding={ 250 }>
        일시적인 오류로 콘텐츠를 표시할 수 없습니다<br/>
        잠시 후 다시 시도해주세요
      </FetchError>
    );
  default:
    if (!content.published) {
      location.href = BANKSALAD_SITEMAP.PAGE_NOT_FOUND;

      return;
    }

    if (content instanceof ContentCardComparison) {
      location.href = BANKSALAD_SITEMAP_BY_ID.CARD_THEME(content.id);

      return;
    }

    return(
      <>
        <MetaHelmet meta={ META_SET.CONTENT(content) }/>
        <ContentViewer content={ content } />
      </>
    );
  }
};
