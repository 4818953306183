import { UserCashAccountUpdateSpec } from 'core/entity/personal-finance/cash-account/update-spec';
import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, optional } from 'utils/index';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';

export class UserCashAccountUpdateSpecMapper implements NetworkRequestMapper<UserCashAccountUpdateSpec> {
    toJson(target: UserCashAccountUpdateSpec): any {
        return compactObject({
            nickname: target.nickname,
            latest_balance: target.latestBalance,
            description: target.description,
            hidden: target.hidden,
            deleted_status: optional(target.deletedStatus, new DeletedStatusMapper().toJson)
        })
    }

}
