import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { AdviceTag } from 'core/entity/personal-finance/advice/tag';

export class AdviceTagMapper implements NetworkResponseMapper<AdviceTag> {
    fromJson(json: any): AdviceTag {
        return new AdviceTag(
            json.key,
            json.title,
            json.color
        )
    }
}
