import { Alert, AlertSize } from 'presentation/components/alert';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    onCancel: () => void;
}

// TODO: 연소득 확인 링크 추가
export const AnnualIncomeInfoAlert = (props: Props) =>
  <Alert
    size={ AlertSize.SMALL }
    onCancel={ props.onCancel }
  >
    <section className={ styles.info }>
      <h2 className={ styles.title }>연 소득이란?</h2>
      <p className={ styles.desc }>
        1년 동안 벌어들인 연간 소득금액(세전)을 의미합니다
      </p>
      <p className={ styles.subDesc }>
        정확한 소득금액은 국세청 사이트(홈택스)를 통해 확인이 가능합니다
      </p>
      <section className={ styles.button }>
        <a href="https://www.hometax.go.kr" target="_blank" rel="noopener noreferrer">연 소득을 모르신다면?</a>
      </section>
    </section>
  </Alert>;
