import { Observable } from 'rxjs/Rx';
import { HttpProvider } from 'data/http';
import { Insurance } from 'core/entity/insurance';
import { InsuranceMapper } from 'data/http/mapper/insurance';

export class InsuranceApiProvider extends HttpProvider {
    get(id: string): Observable<Insurance> {
        return this.requestGET(
            `v1/insurance/insurance-products/${id}`
        ).map((result: any) => {
            return new InsuranceMapper().fromJson(result.data);
        })
    }
}
