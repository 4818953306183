import React from 'react';

import { MediaDesktopTablet, MediaMobile } from 'presentation/components/responsive/media-component';

import styles from './styles.pcss';

interface Props {
    cardCompany: string;
    pcImageUrl: string;
    mobileImageUrl: string;
}

export const CardIssueGuideItem = ({ cardCompany, mobileImageUrl, pcImageUrl }: Props) => (
    <div className={ styles.guide }>
        <MediaDesktopTablet>
            <img src={ pcImageUrl } alt={ `${cardCompany} 발급 가이드` }/>
        </MediaDesktopTablet>
        <MediaMobile>
            <img src={ mobileImageUrl } alt={ `${cardCompany} 발급 가이드` }/>
        </MediaMobile>
    </div>
);
