import { UserTransaction } from 'core/entity/personal-finance/transaction';
import { TransactionFilter } from 'core/entity/personal-finance/transaction/filter';
import { TransactionUpdateResult } from 'core/entity/personal-finance/transaction/update-result';
import { TransactionUpdateSpec } from 'core/entity/personal-finance/transaction/update-spec';
import { AccessToken } from 'core/entity/user/access-token';
import { HttpProvider } from 'data/http';
import { UserTransactionMapper } from 'data/http/mapper/personal-finance/transaction';
import { TransactionFilterMapper } from 'data/http/mapper/personal-finance/transaction/filter';
import { TransactionUpdateResultMapper } from 'data/http/mapper/personal-finance/transaction/update-result';
import { TransactionUpdateSpecMapper } from 'data/http/mapper/personal-finance/transaction/update-spec';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { TransactionCreateSpec } from 'core/entity/personal-finance/transaction/create-spec';
import { TransactionCreateSpecMapper } from 'data/http/mapper/personal-finance/transaction/create-spec';

export class UserTransactionApiProvider extends HttpProvider {
    create(accessToken: AccessToken, spec: TransactionCreateSpec): Observable<UserTransaction> {
        return this.requestPOST(
            '/api/v2/me/transactions',
            new TransactionCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) =>
            new UserTransactionMapper().fromJson(result.data)
        )
    }

    getAll(accessToken: AccessToken, filter: TransactionFilter): Observable<List<UserTransaction>> {
        return this.requestGET(
            '/api/v3/me/transactions',
            new TransactionFilterMapper().toJson(filter),
            { Authorization: accessToken.accessToken }
        ).map((result: any) =>
            List(result.data.map((it: any) =>
                new UserTransactionMapper().fromJson(it)
            ))
        )
    }

    update(accessToken: AccessToken, id: string, spec: TransactionUpdateSpec): Observable<TransactionUpdateResult> {
        return this.requestPATCH(
            `/api/v2/me/transactions/${id}`,
            new TransactionUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) =>
            new TransactionUpdateResultMapper().fromJson(result.data)
        )
    }

    delete(accessToken: AccessToken, transactions: List<UserTransaction>): Observable<void> {
        return this.requestPOST(
            '/api/v2/me/transactions/delete',
            { transaction_ids: transactions.map(transaction => transaction.id).toArray() },
            null,
            { Authorization: accessToken.accessToken }
        )
    }

    recover(accessToken: AccessToken, transactions: List<UserTransaction>): Observable<void> {
        return this.requestPOST(
            `/api/v2/me/transactions/recover`,
            {
                'transactions': transactions.map(it => ({
                    'id': it.id
                }))
            },
            null,
            { Authorization: accessToken.accessToken }
        )
    }
}
