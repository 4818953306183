import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceMonthlyCardProfitSummaryData } from 'core/entity/personal-finance/advice/content-data/monthly-card-profit-summary';
import { AdviceMonthlyCardProfitSummaryMapper } from 'data/http/mapper/personal-finance/advice/monthly-card-profit-summary';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';
import { parseDate, optional } from 'application/utils';

export class AdviceMonthlyCardProfitSummaryDataMapper implements NetworkResponseMapper<AdviceMonthlyCardProfitSummaryData> {
    fromJson(json: any): AdviceMonthlyCardProfitSummaryData {
        return new AdviceMonthlyCardProfitSummaryData(
            parseDate(json.start_datetime),
            parseDate(json.end_datetime),
            json.profit_efficiency_level,
            json.profit_efficiency_ratio,
            json.summaries.map(new AdviceMonthlyCardProfitSummaryMapper().fromJson).toList(),
            optional(json.message, new AdviceMessageMapper().fromJson)
        );
    }
}
