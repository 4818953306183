import { Entity } from 'core/entity';

export class InsuranceCompanySummary extends Entity {
    constructor(
        public id: string,
        public name: string,
        public imageUrl: string
    ) {
        super();
    }
}
