import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceInvestmentAssetValuationSnapshot } from 'core/entity/personal-finance/advice/investment-asset-valuation-snapshot';
import { AmountMapper } from 'data/http/mapper/amount';
import { parseDate } from 'utils/index';

export class AdviceInvestmentAssetValuationSnapshotMapper implements
    NetworkResponseMapper<AdviceInvestmentAssetValuationSnapshot>
{
    fromJson(json: any): AdviceInvestmentAssetValuationSnapshot {
        return new AdviceInvestmentAssetValuationSnapshot(
            new AmountMapper().fromJson(json.valuation),
            new AmountMapper().fromJson(json.principal),
            parseDate(json.base_datetime),
            parseDate(json.start_datetime),
            parseDate(json.end_datetime)
        )
    }
}
