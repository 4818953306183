import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceTransactionSnapshot } from 'core/entity/personal-finance/advice/transaction-snapshot';
import { optional, parseDate } from 'utils/index';
import { AmountMapper } from 'data/http/mapper/amount';
import { TransactionProductMapper } from 'data/http/mapper/personal-finance/transaction/product';
import { TransactionCategorySummaryMapper } from 'data/http/mapper/personal-finance/transaction/category/summary';
import { AdviceInstallmentConfigurationSnapshotMapper } from 'data/http/mapper/personal-finance/advice/installment-configuration-snapshot';

export class AdviceTransactionSnapshotMapper implements NetworkResponseMapper<AdviceTransactionSnapshot> {
    fromJson(json: any): AdviceTransactionSnapshot {
        return new AdviceTransactionSnapshot(
            json.id,
            json.title,
            parseDate(json.transacted_at),
            new AmountMapper().fromJson(json.amount),
            new TransactionProductMapper().fromJson(json.product),
            new TransactionCategorySummaryMapper().fromJson(json.category),
            optional(json.installment_configuration, new AdviceInstallmentConfigurationSnapshotMapper().fromJson)
        )
    }

}
