import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceCardExpirationData } from 'core/entity/personal-finance/advice/content-data/card-expiration';
import { parseDate } from 'application/utils';

export class AdviceCardExpirationDataMapper implements NetworkResponseMapper<AdviceCardExpirationData> {
    fromJson(json: any): AdviceCardExpirationData {
        return new AdviceCardExpirationData(
            parseDate(json.base_datetime),
            json.user_name,
            json.card_id,
            json.card_name,
            parseDate(json.card_renew_date),
            json.card_renew_remaining_day
        );
    }
}
