import { Service } from 'core/service';
import { PersonalizedLoanService } from 'core/service/personalized-recommend/loan';
import { PersonalizedLoanRepositoryType } from 'core/repository/personalized-recommend/loan';
import { PersonalizedInsuranceService } from 'core/service/personalized-recommend/insurance';
import { PersonalizedInsuranceRepositoryType } from 'core/repository/personalized-recommend/insurance';

export class PersonalizedRecommendService implements Service {
    loan: PersonalizedLoanService;
    insurance: PersonalizedInsuranceService;

    constructor(
        loanRepository: PersonalizedLoanRepositoryType,
        insuranceRepository: PersonalizedInsuranceRepositoryType
    ) {
        this.loan = new PersonalizedLoanService(loanRepository);
        this.insurance = new PersonalizedInsuranceService(insuranceRepository);
    }
}
