import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { LoanCompanySummary } from 'core/entity/personal-finance/loan-company/summary';
import { LoanCompanyTypeMapper } from 'data/http/mapper/personal-finance/loan-company/type';

export class LoanCompanySummaryMapper implements
    NetworkRequestMapper<LoanCompanySummary>,
    NetworkResponseMapper<LoanCompanySummary>
{
    toJson(target: LoanCompanySummary): any {
        return {
            id: target.id,
            type: new LoanCompanyTypeMapper().toJson(target.type),
            name: target.name,
            image_url: target.imageUrl
        }
    }

    fromJson(json: any): LoanCompanySummary {
        return new LoanCompanySummary(
            json.id,
            new LoanCompanyTypeMapper().fromJson(json.type),
            json.name,
            json.image_url
        )
    }

}
