import { List } from 'immutable';
import { Entity } from 'core/entity';
import { Store } from 'core/entity/store';

export class StoreGroup extends Entity {
    id: number;
    name: string;
    numberOfStores: number;
    stores: List<Store>;

    constructor(
        id: number,
        name: string,
        numberOfStores: number,
        stores: List<Store>
    ) {
        super();
        this.id = id;
        this.name = name;
        this.numberOfStores = numberOfStores;
        this.stores = stores;
    }
}
