import { Service } from 'core/service';
import { InsuranceRepositoryType } from 'core/repository/insurance';
import { Insurance } from 'core/entity/insurance';
import { Observable } from 'rxjs';

export class InsuranceService implements Service {
    private repository: InsuranceRepositoryType;

    constructor(
        repository: InsuranceRepositoryType
    ) {
        this.repository = repository;
    }

    get = (id: string): Observable<Insurance> => (
        this.repository.get(id)
    );
}
