import { NetworkResponseMapper } from 'data/http/mapper';
import { CardCompany } from 'core/entity/card/company';

export class CardCompanyMapper implements NetworkResponseMapper<CardCompany> {
    fromJson(json: any): CardCompany {
        return new CardCompany(
            json.card_company_id,
            json.name_ko,
            json.name_en,
            json.image_path,
            json.contracted
        );
    }
}
