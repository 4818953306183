import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV1PrivacyAgreementsView } from 'presentation/routes/policies/v1/privacy-agreements/view';
import { PoliciesV1PrivacyAgreementsCreditGoalRoute } from 'presentation/routes/policies/v1/privacy-agreements/credit-goal';
import { PoliciesV1PrivacyAgreementsSubmitDocumentRoute } from 'presentation/routes/policies/v1/privacy-agreements/submit-document';
import { PoliciesV1PrivacyAgreementsExcelExportRoute } from 'presentation/routes/policies/v1/privacy-agreements/excel-export';

const IndexView = () => (
    <>
        <MetaHelmet meta={ META_SET.PRIVACY_AGREEMENT }/>
        <PoliciesV1PrivacyAgreementsView />
    </>
);

export const PoliciesV1PrivacyAgreementsRoute = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/credit-goal`} component={ PoliciesV1PrivacyAgreementsCreditGoalRoute } />
        <Route path={ `${match.url}/submit-document`} component={ PoliciesV1PrivacyAgreementsSubmitDocumentRoute } />
        <Route path={ `${match.url}/excel-export`} component={ PoliciesV1PrivacyAgreementsExcelExportRoute } />
        <Route path={ `${match.url}`} component={ IndexView } />
    </Switch>
);
