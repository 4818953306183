import { TransactionIcon } from 'core/entity/personal-finance/transaction/category-icon';
import { NetworkResponseMapper } from 'data/http/mapper';
import { replaceStringPNGtoSVG } from 'utils/index';

export class TransactionIconMapper implements NetworkResponseMapper<TransactionIcon> {
    fromJson(json: any): TransactionIcon {
        return new TransactionIcon(
            json.id,
            json.name,
            replaceStringPNGtoSVG(json.image_url)
        )
    }

}
