import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanOccupationalGroup } from 'core/entity/loan/occupational-group';

export class LoanOccupationalGroupMapper implements NetworkResponseMapper<LoanOccupationalGroup> {
    fromJson(json: any): LoanOccupationalGroup {
        return new LoanOccupationalGroup(
            json.id,
            json.name
        )
    }

}
