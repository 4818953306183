import Axios from 'axios';
import { isProd } from 'presentation/module/extension';
import { useEffect, useState } from 'react';

/** 대출 기관 정보 */
interface Lender {
  /** 대출 기관 구분 Global Unique ID (v1.organization.OrganizationMessage guid) */
  guid: string;
  /** 대출 기관 이름 */
  name: string;
  /** 대출 기관 로고 URL */
  logoUrl: string;
  /** 대출 기관 업권 (enum: v1.loancompare.Industry) */
  industryEnum: string;
}

export enum LoanType {
  // 신용 대출
  LOAN_TYPE_CREDIT_LOAN = 'LOAN_TYPE_CREDIT_LOAN',
  // 주택 담보 대출
  LOAN_TYPE_MORTGAGE_LOAN = 'LOAN_TYPE_MORTGAGE_LOAN',
}

export const usePrequalLenderNames = (loanTypeEnum?: LoanType) => {
  const [prequalLenderNames, setPrequalLenderNames] = useState('');

  useEffect(() => {
    (async () => {
      const API_PATH = isProd()
        ? 'https://api.banksalad.com/v1/loancompare/lenders'
        : 'https://api.staging.banksalad.com/v1/loancompare/lenders';

      // param 이 undefined 일때 default 는 LOAN_TYPE_CREDIT_LOAN 입니다.
      const { data } = await Axios.get(API_PATH, {
        params: loanTypeEnum ? { loan_type_enum: loanTypeEnum } : undefined,
      });
      const lenders = data.lenders as Lender[];

      // SC제일은행, BNK경남은행, 전북은행, 신한카드, ...
      const lenderNames = lenders.reduce(
        (acc, cur, idx) => (idx === 0 ? cur.name : `${acc}, ${cur.name}`),
        ''
      );
      setPrequalLenderNames(lenderNames);
    })();
  }, []);

  return { prequalLenderNames };
};
