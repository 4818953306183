import { Card } from 'core/entity/card';
import { CardEvent } from 'core/entity/card/event';
import { Content } from 'core/entity/content';
import { ContentAuthor } from 'core/entity/content/author';
import { ContentTag } from 'core/entity/content/tag';
import { ContentTagCategory } from 'core/entity/content/tag/category';
import { Loan } from 'core/entity/loan';
import { MetaSet } from 'presentation/view-model/meta-set';
import { apply } from 'utils/index';

const DESC_MAX_SIZE = 150;

export const META_SET = {
  HOME: new MetaSet(
    '뱅크샐러드 | 금융을 넘어 건강 자산까지',
    '금융을 넘어 건강 자산까지 뱅크샐러드 - 신용대출 · 신용카드 · 주택담보대출 · 예적금 · 무료 건강 검사',
    '뱅크샐러드,뱅샐,샐러드뱅크,뱅셀,돈관리,돈관리앱,카드,카드추천,신용카드추천,체크카드추천,적금추천,예금추천,CMA추천,CMA통장추천,보험비교,보험비교사이트,금리계산기,금리비교사이트,혜택많은신용카드,신용카드비교,체크카드비교'
  ),
  SECTORS_CARD: new MetaSet(
    '나에게 딱 맞는 카드 추천 사이트 | 뱅크샐러드',
    '남들이 좋다는 카드 말고, 나에게 좋은 카드를 찾아보세요! - 이달의 인기 카드, 상황별 BEST 카드, 나의 BEST 카드',
    '신용카드,체크카드,항공마일리지카드,카드추천,신용카드추천,체크카드추천,하이브리드카드,카드비교,카드혜택,신용카드비교,신용카드혜택,체크카드비교,체크카드혜택,항공마일리지카드혜택,항공마일리지카드추천,항공마일리지카드비교'
  ),
  SECTORS_BANK: new MetaSet(
    '나에게 딱 맞는 예적금 추천 사이트 | 뱅크샐러드',
    '남들이 좋다는 예적금 말고, 나에게 좋은 예적금을 찾아보세요! - 예금추천, 적금추천, 예적금특판이벤트',
    '적금,적금계산기,적금이자계산기,적금이자율계산,적금추천,1년단기적금,장기적금,적금금리비교,예금,예금계산기,예금이자계산기,예금이자율계산,예금추천,1년단기예금,장기예금,예금금리비교'
  ),
  SECTORS_INVESTMENT: new MetaSet(
    '나에게 딱 맞는 투자 상품 추천 | 뱅크샐러드',
    '남들이 좋다는 투자 상품 말고, 나에게 가장 큰 수익을 주는 투자 상품을  찾아보세요! - CMA, P2P투자, 주식',
    '돈모으는방법,투자,CMA통장,CMA통장추천,CMA통장금리비교,증권,펀드,부동산투자,P2P대출,주식투자,P2P투자,부동산P2P,펀딩,로보어드바이저'
  ),
  SECTORS_LOAN: new MetaSet(
    '맞춤형 대출 비교 사이트 | 뱅크샐러드',
    '남이 좋다는 대출 상품 말고, 나에게 좋은 대출 상품을 찾아보세요! - 대출이자계산, 대출비교, 대출상식',
    '대출,대출계산기,대출금리계산기,대출이자,전세자금대출,무직자대출,주택담보대출,신용대출,소액대출,모바일대출,직장인신용대출,개인사업자대출,직장인당일대출,모바일소액대출,주부대출'
  ),
  SECTORS_ANNUITY: new MetaSet(
    '맞춤형 연금 비교 사이트 | 뱅크샐러드',
    '남이 좋다는 연금말고, 나에게 좋은 연금을 찾아보세요! - 연금, 연금비교, 연금상식',
    '연금,퇴직연금,개인연금,국민연금,연금저축,연금보험,즉시연금,변액연금,노후연금,어린이연금,은행연금저축,종신연금,노후연금보험,사적연금'
  ),
  SECTORS_FINTECH: new MetaSet(
    '핀테크 트렌드 매거진 | 뱅크샐러드',
    '편리한 금융 생활을 도와줄 스마트폰 핀테크 서비스를 알아보세요! - 금융서비스, 금융트렌드,fintech',
    '핀테크,핀태크,핀테크스타트업,핀태크서비스,핀테크서비스,금융어플,금융서비스,금융사이트,개인금융서비스,스마트폰금융서비스,핵심금융서비스'
  ),
  SECTORS_MAGAZINE: new MetaSet(
    '금융 매거진 | 뱅크샐러드',
    '꼭 알아야 하지만 복잡한 금융을 쉽고, 깊이 있게 전달합니다. 재테크부터 경제 이슈까지 금융 전반에 걸친 폭넓은 시야를 얻어보세요! - 금융 칼럼, 카드 뉴스',
    '재테크,제테크,세테크,짠테크,직장인재테크,사회초년생재테크,재무상담,재무설계,목돈모으기,월급관리,돈관리,생활비절약,생활비아끼기,금융상식,금융팁'
  ),
  CONTENT: (content: Content) => new MetaSet(
    `${content.meta.title} | 뱅크샐러드`,
    content.meta.description,
    content.meta.keywords.join(',')
  ),
  CONTENTS: new MetaSet(
    '금융 매거진 전체보기 | 뱅크샐러드',
    '꼭 알아야하는 최신 금융 정보를 전달합니다. 재테크부터 경제 이슈까지 폭넓은 시야를 얻어보세요!  - 금융 칼럼, 카드 뉴스',
    '재테크,제테크,세테크,짠테크,직장인재테크,사회초년생재테크,재무상담,재무설계,목돈모으기,월급관리,돈관리,생활비절약,생활비아끼기,금융상식,금융팁'
  ),
  CONTENTS_TAGS: (tag: ContentTag) => {
    switch (tag.category) {
    case ContentTagCategory.TARGET:
      return new MetaSet(
        `알면 돈이 보이는 '${tag.name}' 금융 꿀팁 | 뱅크샐러드`,
        `아는 만큼 절약되는 돈, 소중한 내 돈을 아껴 줄 ${tag.name} 꿀팁을 확인해보세요! - 돈모으는방법, 재테크, 짠테크, 세테크`,
        '무료재무설계,돈모으는방법,재테크,제테크,짠테크,세테크,부자되는방법,재테크방법,제테크방법,직장인제테크,사회초년생재테크,신혼부부재테크,20대재테크,30대재테크'
      );
    case ContentTagCategory.PRODUCT:
      return new MetaSet(
        `알면 돈이 보이는 '${tag.name}' 꿀팁 | 뱅크샐러드`,
        `아는 만큼 절약되는 돈, 소중한 내 돈을 아껴 줄 ${tag.name} 꿀팁을 확인해보세요! - ${tag.name}, 재테크, 재무관리`,
        `돈모으는방법,재테크,제테크,짠테크,세테크,부자되는방법,제테크방법,재테크방법,${tag.name},${tag.name}가입,${tag.name}비교,${tag.name}추천,${tag.name}해지`
      );
    case ContentTagCategory.OCCASION:
    default:
      return new MetaSet(
        `알면 돈이 보이는 '${tag.name}' 꿀팁 | 뱅크샐러드`,
        `아는 만큼 절약되는 돈, 소중한 내 돈을 아껴 줄 ${tag.name} 꿀팁을 확인해보세요! - 금융, 일상꿀팁, ${tag.name}`,
        `${tag.name},돈모으는방법,재테크,제테크,짠테크,세테크,부자되는방법,제테크방법,재테크방법,금융,돈모으는법`
      );
    }
  },
  CONTENTS_AUTHORS: new MetaSet(
    '집필진 소개 | 뱅크샐러드',
    '당신이 지금보다 더 많은 금융 혜택을 돌려받아야 한다고 믿는 뱅크샐러드의 전문가 집필진을 소개합니다.',
    '금융정보,금융상품,블로그,콘텐츠,카드뉴스,칼럼'
  ),
  CONTENTS_AUTHOR: (author: ContentAuthor) => new MetaSet(
    `${author.profile.name} 작가 님의 금융 매거진 | 뱅크샐러드`,
    `작가 소개 ${author.profile.description}`,
    '금융정보,금융상품,블로그,콘텐츠,카드뉴스,칼럼'
  ),
  CONTENTS_CONTRIBUTION: new MetaSet(
    '기고자 모집 | 뱅크샐러드',
    '어려운 금융을 쉽고, 깊이 있게 풀어주실 금융전문 기고자를 기다립니다.',
    '금융정보,금융상품,블로그,콘텐츠,카드뉴스,칼럼'
  ),
  CARD: (card: Card) => apply(
    new MetaSet(
      `${card.companyName} - ${card.name} 혜택 | 뱅크샐러드`, '',
      `${card.name},${card.companyName}${card.name},${card.name}혜택,${card.name}할인,${card.name}적립,${card.name}전월실적,${card.name}할인한도,${card.name}연회비,${card.name}캐시백`,
      card.imageUrl
    ), it => {
      const benefits = card.defaultBenefits
        .flatMap(defaultBenefit => defaultBenefit.storeBenefits.map(storeBenefit => storeBenefit.title))
        .join();
      it.description = `${card.name} 혜택, 꼼꼼하게 확인해보세요! ${benefits.substring(0, DESC_MAX_SIZE)}`;
    }
  ),
  CARDS_EVENTS: new MetaSet(
    '카드 이벤트 모아보기 | 뱅크샐러드',
    '카드 이벤트를 찾고 계세요? 여러 카드사 홈페이지 방문할 필요 없이, 한 곳에서 확인해보세요! - 카드 이벤트, 신용카드 이벤트, 카드 사 이벤트',
    '신용카드이벤트,카드사이벤트,국민카드이벤트,신한카드이벤트,삼성카드이벤트,하나카드이벤트,우리카드이벤트,현대카드이벤트,롯데카드이벤트,씨티카드이벤트,비씨카드이벤트,농협카드이벤트,IBK카드이벤트'
  ),
  CARDS_EVENT: (event: CardEvent) => new MetaSet(
    `${event.company.name} - ${event.title} | 뱅크샐러드`,
    `${event.company.name} - ${event.title}, 이벤트 혜택부터 기간까지 꼼꼼하게 확인해보세요!`,
    `${event.company.name}이벤트,${event.title},${event.title}기간,${event.title}혜택`
  ),
  CARDS_BEST: new MetaSet(
    '국내 최초 나에게 맞는 신용카드 추천 - 하이패스 · 통신비 · 주유할인 · 대중교통 | 뱅크샐러드',
    '자주 쓰는 곳을 선택하면 혜택 좋은 신용카드를 추천해 드려요 | 신용카드 추천 받기',
    '신용카드,체크카드,항공마일리지카드,카드추천,신용카드추천,체크카드추천,하이브리드카드,카드비교,카드혜택,신용카드비교,신용카드혜택,체크카드비교,체크카드혜택,항공마일리지카드혜택,항공마일리지카드추천,항공마일리지카드비교',
    'https://cdn.banksalad.com/graphic/color/illustration/og-image/credit-card-curation.png',
  ),
  CARDS_ANNUAL_FEE_PROMOTION_RESULT: new MetaSet(
    '연회비 캐시백 카드 | 뱅크샐러드',
    '연회비 캐시백 이벤트가 진행 중인 카드를 찾고 계세요? 여러 카드사 홈페이지 방문할 필요 없이, 한 곳에서 확인해보세요! - 카드 연회비, 연회비 없는 카드',
    '카드연회비,연회비,연회비캐시백,연회비캐쉬백,연회비없는카드,연회비저렴한카드,연회비이벤트,신용카드연회비,연회비없는신용카드,신용카드연회비무료,카드연회비무료,신용카드연회비면제,연회비싼신용카드'
  ),
  CARDS_THEMES: new MetaSet(
    '상황별 BEST 카드 추천 | 뱅크샐러드',
    'PP카드부터, 생활비카드까지! 혜택 분석 결과를 바탕으로 선정된 상황별 BEST 카드를 확인해보세요! - 주유카드, 항공 마일리지 카드, 통신사 할인카드',
    '20대신용카드추천,PP카드추천,후불교통카드추천,항공마일리지카드추천,결혼준비카드추천,통신사할인카드추천,패밀리레스토랑할인카드추천,카페할인카드추천,신차할인카드추천,쇼핑할인카드추천,주유할인카드추천,직장인신용카드추천'
  ),
  CARDS_THEME: (content: Content) => new MetaSet(
    `${content.meta.title} | 뱅크샐러드`,
    content.meta.description,
    content.meta.keywords.join(',')
  ),
  CARDS_ANNUAL_FEE_PROMOTION: new MetaSet(
    '연회비 이벤트 안내'
  ),
  ERROR_MAINTENANCE: new MetaSet(
    '서버 점검중 | 뱅크샐러드'
  ),
  ERROR_NOT_FOUND: new MetaSet(
    '페이지를 찾을 수 없습니다 | 뱅크샐러드'
  ),
  ERROR_PAGE:  new MetaSet(
    '페이지에 오류가 발생했습니다 | 뱅크샐러드'
  ),
  CREDIT_LOAN: (loan: Loan) => new MetaSet(
    `${loan.organization.name} ${loan.name} 금리 정리 | 뱅크샐러드`,
    `${loan.name} 금리, 신용등급별 금리와 한도부터 우대금리 혜택까지 꼼꼼하게 확인해보세요!`,
    `${loan.organization.name}${loan.name},${loan.name},${loan.name}금리,${loan.name}이자,${loan.name}우대금리,${loan.name}한도`
  ),
  CREDIT_LOAN_BEST: new MetaSet(
    '대출 이자, 한도 계산하기 | 뱅크샐러드',
    '나의 대출 금액, 신용등급, 연 소득까지 계산하여, 금리는 낮고 한도는 높은 대출 상품을 찾아보세요! - 대출금리, 신용대출금리, 마이너스통장',
    '신용대출금리,저저축은행금리비교,주택담보대출금리,대출금리계산기,대출금리,저금리대환대출,아파트담보대출금리비교,직장인저금리대출,은행별주택담보대출금리비교,저금리서민대출,저금리대출,중금리대출,고금리전환대출,은행대출금리,마이너스통장금리,마이너스통장이자'
  ),

  ADVICE_DEFAULT: new MetaSet(
    '조언 상세'
  ),
  ADVICE_PERIOD_TRANSACTIONS: (startDate: Date, endDate: Date) => new MetaSet(
    `${startDate.format('M월 D일')} ~ ${endDate.format('M월 D일')} 지출내역`
  ),

  MY_CREDIT: new MetaSet('신용관리'),
  MY_CREDIT_AGREEMENT: new MetaSet('이용약관 및 개인정보 동의'),
  MY_CREDIT_INQUIRY_RECORDS: new MetaSet('신용조회 기록'),
  MY_CREDIT_CHANGE_RECORDS: new MetaSet('최근 변동내역'),
  MY_CREDIT_GOAL: new MetaSet('등급별 금융생활'),
  MY_CREDIT_STATUS: new MetaSet('내 신용평가 정보'),
  MY_CREDIT_STATUS_CARDS: new MetaSet('내 카드 현황'),
  MY_CREDIT_STATUS_LOANS: new MetaSet('내 대출 현황'),
  MY_CREDIT_STATUS_OVERDUES: new MetaSet('내 연체 현황'),
  MY_CREDIT_STATUS_JOINT_SURETIES: new MetaSet('내 연대보증 현황'),

  PERSONALIZED_RECOMMEND_GENERAL_LOAN: new MetaSet('새로 대출받기'),
  PERSONALIZED_RECOMMEND_REFINANCE_LOAN: new MetaSet('대출 갈아타기'),
  PERSONALIZED_RECOMMEND_LOAN_SUBMIT_DOCUMENT: new MetaSet('간편 서류제출 서비스'),

  PERSONALIZED_RECOMMEND_INSURANCE: new MetaSet('보험추천', null, null, null, 'width=device-width, initial-scale=1, maximum-scale=3.0, minimum-scale=1, user-scalable=no, viewport-fit=cover'),

  MEDICAL_CHECK_UP_RESULT: new MetaSet('내 건강검진 결과'),

  EVENT: new MetaSet('뱅크샐러드 이벤트', null, null, null, 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'),
  /* Policies */
  TERMS: new MetaSet(
    '이용약관 | 뱅크샐러드'
  ),
  PRIVACY_AGREEMENT: new MetaSet(
    '개인정보 수집∙이용 동의 | 뱅크샐러드'
  ),
  PRIVACY_POLICY: new MetaSet(
    '개인정보 처리방침 | 뱅크샐러드'
  ),
  SUBSCRIPTION_AGREEMENT: new MetaSet(
    '정보ㆍ광고 수신 동의 | 뱅크샐러드'
  ),
  KCB_THIRD_PARTY: new MetaSet(
    '개인(신용)정보 제3자 제공에 관한 사항 | 뱅크샐러드'
  ),
  KCB_ALL_CREDIT: new MetaSet(
    'KCB 올크레딧 이용약관 | 뱅크샐러드'
  ),
  KCB_CREDIT_THIRD_PARTY: new MetaSet(
    '개인(신용)정보 제3자 제공에 관한 사항 | 뱅크샐러드'
  ),
  KCB_CREDIT_AGREEMENT: new MetaSet(
    '개인(신용)정보 수집∙이용 동의 | 뱅크샐러드'
  ),
  THIRD_PARTY: new MetaSet(
    '개인정보 제3자 제공에 관한 사항 | 뱅크샐러드'
  ),
  MEDICAL_CHECKUP_AGREEMENT: new MetaSet(
    '개인정보(민감정보) 수집 및 이용 동의 | 뱅크샐러드'
  ),
  INSURANCE: new MetaSet(
    '보험상세'
  )
};
