import { OUTBOUND_LINK } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';


export const NoticeSuspendPFMService = () => (
  <section className={ styles.section }>
    <h3 className={ styles.title }>
            뱅크샐러드 PC가계부<br/> 서비스 잠정중단 안내
    </h3>
    <div className={ styles.content }>
            안녕하세요 뱅크샐러드입니다.<br/>
            서비스 안정성을 높이기 위하여 PC 가계부 서비스가 잠정적으로 중단될 예정입니다.<br/>
      <div className={ styles.highlight }>
                종료 서비스 : 뱅크샐러드 웹 PC가계부<br/>
                종료 일자 : 2019년 8월 5일
      </div>
            많은 고객분들께서 사랑해주시는 서비스였기에 중단을 결정하기가 어려웠습니다.<br/>
            당분간 아쉬운 작별을 하게 되었지만 그동안 PC 가계부의 발전을 위해 주셨던 소중한 의견은 잊지 않고<br/>
            반영할 수 있도록 노력하겠습니다.<br/><br/>
            뱅크샐러드 모바일 앱에 PC가계부와 동일한 계정으로 로그인하시면 같은 내역을 확인하실 수 있습니다.<br/>
            궁금하신 점이 있다면 <a href='mailto:hello@banksalad.com' className={ styles.mail }>hello@banksalad.com</a>로 언제든지 말씀 부탁드립니다.<br/><br/>
            감사합니다.
      <div className={ styles.linkContainer }>
        <h4 className={ styles.linkTitle }>
                    앱 다운로드
        </h4>
        <ul className={ styles.links }>
          <li className={ styles.link }>
            <a
              href={ OUTBOUND_LINK.APPLE_STORE_UTM.DEFAULT }
              target='_blank'
              rel='noopener noreferrer'
              className={ styles.ios }
            >
              <span className='blind'>
                            애플 스토어
              </span>
            </a>
          </li>
          <li className={ styles.link }>
            <a
              href={ OUTBOUND_LINK.GOOGLE_PLAYSTORE_UTM.DEFAULT }
              target='_blank'
              rel='noopener noreferrer'
              className={ styles.aos }
            >
              <span className='blind'>
                            구글 플레이
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);
