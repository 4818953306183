import { Entity } from 'core/entity';
import { PrimaryData } from 'core/entity/personal-finance/primary-data';
import { SpiderInsuranceData } from 'core/entity/personal-finance/spider-data/insurance';

export class InsurancePrimaryData extends Entity implements PrimaryData {
    constructor(
        public spider: SpiderInsuranceData = null
    ) {
        super();
    }
}
