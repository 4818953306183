import { Entity } from 'core/entity';
import { LoanCFAConfiguration } from 'core/entity/legal-configuration/loan/cfa';

export class LoanLegalConfiguration extends Entity {
    id: number;
    cfa: LoanCFAConfiguration;
    issuingDisabled: boolean;

    constructor(
        id: number,
        cfa: LoanCFAConfiguration,
        issuingDisabled: boolean
    ) {
        super();
        this.id = id;
        this.cfa = cfa;
        this.issuingDisabled = issuingDisabled;
    }
}
