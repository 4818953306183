import { Entity } from 'core/entity';
import { LoanInterestValue } from 'core/entity/loan/interest-value';
import { LoanInterestRange } from 'core/entity/loan/interest-range';

export class LoanRangeInterest extends Entity implements LoanInterestValue {
    value: LoanInterestRange;

    constructor(
        value: LoanInterestRange
    ) {
        super();
        this.value = value;
    }
}
