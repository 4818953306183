import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { Asset } from 'core/entity/personal-finance/asset';

export class UserActualAsset extends Entity {
    id: string;
    nickname: string;
    description: string;
    hidden: boolean;
    worth: Amount;
    deletedStatus: Asset.DeletedStatus;

    constructor(
        id: string,
        nickname: string,
        description: string,
        hidden: boolean,
        worth: Amount,
        deletedStatus: Asset.DeletedStatus
    ) {
        super();
        this.id = id;
        this.nickname = nickname;
        this.description = description;
        this.hidden = hidden;
        this.worth = worth;
        this.deletedStatus = deletedStatus;
    }
}
