import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardBenefitType } from 'core/entity/card/benefit-type';
import { CardFilter } from 'core/entity/card/filter';
import { CardPriority } from 'core/entity/card/priority';
import { CardSpending } from 'core/entity/card/spending';

export class CardRecommendSpec extends Entity {
    spendings: List<CardSpending>;
    benefitTypes: List<CardBenefitType>;
    filter: CardFilter;
    priority: CardPriority;

    constructor(
        spendings: List<CardSpending>,
        benefitTypes: List<CardBenefitType>,
        filter: CardFilter,
        priority: CardPriority
    ) {
        super();
        this.spendings = spendings;
        this.benefitTypes = benefitTypes;
        this.filter = filter;
        this.priority = priority;
    }
}
