import { Entity } from 'core/entity';

export class Alfred2CardIssuingConfiguration extends Entity {
    url: string;
    phoneNumber?: string;

    constructor(
        url: string,
        phoneNumber: string = null
    ) {
        super();
        this.url = url;
        this.phoneNumber = phoneNumber;
    }
}
