import { Entity } from 'core/entity';
import { List } from 'immutable';
import { UserCreditScoreLoan } from 'core/entity/personal-credit/loan';

export class UserCreditScoreLoanStatus extends Entity {
    loans: List<UserCreditScoreLoan>;
    updatedAt: Date;
    changed: boolean;

    constructor(
        loans: List<UserCreditScoreLoan>,
        updatedAt: Date,
        changed: boolean
    ) {
        super();
        this.loans = loans;
        this.updatedAt = updatedAt;
        this.changed = changed;
    }
}
