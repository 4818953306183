import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { UserTransaction } from 'core/entity/personal-finance/transaction';
import { TransactionCategorySummary } from 'core/entity/personal-finance/transaction/category/summary';
import { TransactionPrimaryData } from 'core/entity/personal-finance/transaction/primary-data';
import { TransactionProduct } from 'core/entity/personal-finance/transaction/product';

export class UserIncomeTransaction extends Entity implements UserTransaction {
    id: string;
    amount: Amount;
    title: string;
    transactedAt: Date;
    product: TransactionProduct;
    category: TransactionCategorySummary;
    deleted: boolean;
    primaryData?: TransactionPrimaryData;
    description?: string;

    constructor(
        id: string,
        amount: Amount,
        title: string,
        transactedAt: Date,
        product: TransactionProduct,
        category: TransactionCategorySummary,
        deleted: boolean,
        primaryData: TransactionPrimaryData = null,
        description: string = null
    ) {
        super();
        this.id = id;
        this.amount = amount;
        this.title = title;
        this.transactedAt = transactedAt;
        this.product = product;
        this.category = category;
        this.deleted = deleted;
        this.primaryData = primaryData;
        this.description = description;
    }
}
