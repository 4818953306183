import { AxiosError, AxiosInstance } from 'axios';
import { networkErrorRedirectHandler } from 'data/http/api/util';
import { Observable } from 'rxjs/Rx';

export enum HttpStatusCode {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    TIME_OUT = 408,
    CONFLICT = 409,
    BAN_USER = 418,
    UNPROCESSABLE_ENTITY = 422,
    LOCKED = 423,
    EXCESSIVE_REQUEST = 429,
    UNKNOWN = 0
}

export class NetworkError extends Error {
    statusCode: number;

    constructor(statusCode: number, message?: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.statusCode = statusCode;
    }
}

export abstract class HttpProvider {
    private httpInstance: AxiosInstance;

    constructor(instance: AxiosInstance) {
        this.httpInstance = instance;
    }

    protected requestGET = (url: string, params: any = {}, headers: any = {}) =>
        Observable
            .fromPromise(this.httpInstance.get(url, { params, headers }))
            .map(response => response.data)
            .catch(this.handleError);

    protected requestPOST = (url: string, data: any = {}, params: any = {}, headers: any = {}) =>
        Observable
            .fromPromise(this.httpInstance.post(url, data, { params, headers }))
            .map(response => response.data)
            .catch(this.handleError);

    protected requestPUT = (url: string, data: any = {}, params: any = {}, headers: any = {}) =>
        Observable
            .fromPromise(this.httpInstance.put(url, data, { params, headers }))
            .map(response => response.data)
            .catch(this.handleError);

    protected requestPATCH = (url: string, data: any = {}, params: any = {}, headers: any = {}) =>
        Observable
            .fromPromise(this.httpInstance.patch(url, data, { params, headers }))
            .map(response => response.data)
            .catch(this.handleError);

    protected requestDELETE = (url: string, data: any = {}, params: any = {}, headers: any = {}) =>
        Observable
            .fromPromise(this.httpInstance.delete(url, { data, params, headers }))
            .map(response => response.data)
            .catch(this.handleError);

    private handleError = (error: AxiosError) => {
        if (error.response) {
            const networkError = new NetworkError(error.response.status);
            networkErrorRedirectHandler(networkError.statusCode);

            return Observable.throw(networkError);
        }

        return Observable.throw(error);
    };
}
