import React from 'react';

import { CreditLoanJob } from 'presentation/components/survey-form/credit-loan-job/model';
import { CreditLoanOccupationsAlert } from 'presentation/components/alert/credit-loan-occupations';
import { optional } from 'utils/index';
import { LoanOccupationalGroup } from 'core/entity/loan/occupational-group';
import { FetchState } from 'presentation/view-model/fetch-state';

import styles from './styles.pcss';

interface Props {
    data: CreditLoanJob;
    onChange: (result: CreditLoanJob) => void
}

interface State {
    alert: boolean,
    fetchState: FetchState
}

export class CreditLoanJobForm extends React.Component<Props, State> {
    state = {
        alert: false,
        fetchState: FetchState.FETCHING
    };

    render() {
        const { data } = this.props;
        const { alert } = this.state;
        return (
            <div>
                <button
                    className={ data.isValid() ? styles.activeButton : styles.button }
                    onClick={ this.toggleAlert }
                >
                    { data.isValid() ? data.toValue() : '직업 선택하기' }
                </button>
                {
                    alert &&
                    <CreditLoanOccupationsAlert
                        active={ optional(data, it => it.value) }
                        onAccept={ this.onAccept }
                        onCancel={ this.toggleAlert }
                    />
                }
            </div>
        )
    }

    private toggleAlert = () =>
        this.setState(state => ({ alert: !state.alert }));

    private onAccept = (occupation: LoanOccupationalGroup) => {
        this.setState({
            alert: false
        }, () => {
            this.props.onChange(
                new CreditLoanJob(occupation)
            );
        });
    };
}
