import { Entity } from 'core/entity';
import { CardCFAConfiguration } from 'core/entity/legal-configuration/card/cfa';

export class CardLegalConfiguration extends Entity {
    id: number;
    cfa: CardCFAConfiguration;
    disabledForCompliance: boolean;
    issuingDisabled: boolean;

    constructor(
        id: number,
        cfa: CardCFAConfiguration,
        disabledForCompliance: boolean,
        issuingDisabled: boolean
    ) {
        super();
        this.id = id;
        this.cfa = cfa;
        this.disabledForCompliance = disabledForCompliance;
        this.issuingDisabled = issuingDisabled;
    }
}
