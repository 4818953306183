import { NetworkRequestMapper } from 'data/http/mapper';
import { Page } from 'core/entity/page';

export class PageMapper implements NetworkRequestMapper<Page> {
    toJson(target: Page): any {
        return {
            limit: target.limit,
            offset: target.offset
        }
    }

}
