import moment from 'moment';

import { CreditScore } from 'core/entity/personal-credit/score';
import { UserCreditScoreCardStatus } from 'core/entity/personal-credit/card-status';
import { CreditMetadata } from 'core/entity/personal-credit/metadata';
import { optional } from 'utils/index';
import { UserCreditScoreCard } from 'core/entity/personal-credit/card';

const rateTable = [
    { grade: 1, averageLoanInterestRate: 6.7 },
    { grade: 2, averageLoanInterestRate: 7.7 },
    { grade: 3, averageLoanInterestRate: 9.7 },
    { grade: 4, averageLoanInterestRate: 12.3 },
    { grade: 5, averageLoanInterestRate: 15.1 },
    { grade: 6, averageLoanInterestRate: 16.9 },
    { grade: 7, averageLoanInterestRate: 19 },
    { grade: 8, averageLoanInterestRate: 20 },
    { grade: 9, averageLoanInterestRate: 21.9 },
    { grade: 10, averageLoanInterestRate: 21.9 }
];

export interface CreditGoalViewModel {
    grade: number;
    leftPoint: number;
    percentage: number;
    interestRateDifference: number;
}

declare module 'core/entity/personal-credit/score' {
    export interface CreditScore {
        getBaseMonth(): string;
        toGoalData(metadata: CreditMetadata): CreditGoalViewModel;
    }
}

CreditScore.prototype.getBaseMonth = function() {
    return moment(this.updatedAt).add(-1, 'month').format('M');
};

CreditScore.prototype.toGoalData = function(metadata: CreditMetadata) {
    const grade = this.grade > 9 ? 8 : this.grade - 1;
    const gradeMetadata = metadata.grades.find(it => it.grade === this.grade);
    const targetMetadata = metadata.grades.find(it => it.grade === grade);
    const leftPoint = targetMetadata ?
        Math.max(targetMetadata.scoreRange.min - this.score.current, 0) : 0;
    const percentage = gradeMetadata && targetMetadata ?
        Math.floor(
            (1 - (leftPoint / (targetMetadata.scoreRange.max - gradeMetadata.scoreRange.min))) * 100
        ) : 0;

    const currentRate = optional(rateTable.find(it => it.grade === this.grade), it =>
        it.averageLoanInterestRate
    ) || 0;
    const goalRate = grade < 1 ?
        optional(rateTable.find(it => it.grade === this.grade + 1), it =>
            it.averageLoanInterestRate
        ) || 0 :
        optional(rateTable.find(it => it.grade === grade), it =>
            it.averageLoanInterestRate
        ) || 0;

    return {
        grade,
        leftPoint,
        percentage,
        interestRateDifference: Math.abs(Math.floor(currentRate * 10) - Math.floor(goalRate * 10)) / 10
    }
};

declare module 'core/entity/personal-credit/card-status' {
    export interface UserCreditScoreCardStatus {
        getBaseMonth(): string;
    }
}

UserCreditScoreCardStatus.prototype.getBaseMonth = function() {
    return moment(this.updatedAt).add(-1, 'month').format('M');
};

declare module 'core/entity/personal-credit/card' {
    export interface UserCreditScoreCard {
        isCreditCard(): boolean;
    }
}

UserCreditScoreCard.prototype.isCreditCard = function () {
    return this.name.find('신용') || (this.name.find('체크') && this.creditLimit.total.amount > 0);
};
