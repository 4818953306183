import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { CreditTip } from 'core/entity/personal-credit/tips';
import { CreditTipMapper } from 'data/http/mapper/personal-credit/tip';

export class CreditScoreTipsApiProvider extends HttpProvider {
    getAll(): Observable<List<CreditTip>> {
        return this.requestGET(
            'api/v2/credit-scores/tips'
        ).map(result =>
            result.data.map(new CreditTipMapper().fromJson).toList()
        )
    }

}
