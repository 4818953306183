import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { SpiderBankAccountData } from 'core/entity/personal-finance/spider-data/bank-account';
import { compactObject, lets, optional } from 'utils/index';

export class SpiderBankAccountDataMapper implements
    NetworkResponseMapper<SpiderBankAccountData>,
    NetworkRequestMapper<SpiderBankAccountData>
{
    fromJson(json: any): SpiderBankAccountData {
        return new SpiderBankAccountData(
            json.name,
            lets(json.account_type, it => {
                switch (it) {
                    case 10:
                    case 11:
                        return SpiderBankAccountData.AccountType.CHECK;
                    default:
                        return SpiderBankAccountData.AccountType.ETC;
                }
            }),
            optional(json.response_contained)
        )
    }

    toJson(target: SpiderBankAccountData): any {
        return compactObject({
            name: target.name,
            account_type: lets(target.accountType, it => {
                switch (it) {
                    case SpiderBankAccountData.AccountType.CHECK:
                        return 'check';
                    default:
                        return 'etc';
                }
            }),
            response_contained: optional(target.responseContained)
        });
    }
}
