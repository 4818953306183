import React from 'react';
import { List } from 'immutable';

import { Application } from 'application/context.instance';
import { UserDropSpec } from 'core/entity/user/drop-spec';
import { FlexibleAlert } from 'presentation/components/flexible-alert';
import { Radio } from 'presentation/components/input/radio';
import { Loader } from 'presentation/components/loader';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import { apply, lets } from 'utils/index';
import { toast } from 'presentation/module/sugar';
import { HttpStatusCode, NetworkError } from 'data/http';

import styles from './styles.pcss';
const REASONS = List.of(
    { key: UserDropSpec.Reason.INVALID_DATA, value: '오류가 너무 많습니다' },
    { key: UserDropSpec.Reason.FEATURE_NOT_FOUND, value: '필요한 기능이 없습니다' },
    { key: UserDropSpec.Reason.SECURITY, value: '보안이 걱정됩니다' },
    { key: UserDropSpec.Reason.ETC, value: '기타' }
);

interface Props {
    onCancel: () => void;
}

interface State {
    spec: UserDropSpec;
    fetchState: FetchState;
}

export class DropUserAlert extends React.Component<Props, State> {
    state = {
        spec: new UserDropSpec('', UserDropSpec.Reason.INVALID_DATA, ''),
        fetchState: FetchState.FETCHED
    };

    private subscriptionBag = new SubscriptionBag();

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { onCancel } = this.props;
        const { spec, fetchState } = this.state;

        return fetchState === FetchState.FETCHED ? (
            <FlexibleAlert
                title="회원 탈퇴"
                mobileFullHeight={ true }
                onCancel={ onCancel }
            >
                <div className={ styles.container }>
                    <p className={ styles.desc }>
                        뱅크샐러드를 탈퇴하시게 되면 저장하신 소비내역 및 카드 정보가 모두 삭제되며 다시 복구가 불가능합니다.
                    </p>
                    <div className={ styles.form }>
                        <div className={ styles.formGroup }>
                            <h2 className={ styles.formTitle }>뱅크샐러드를 떠나는 이유를 알려주세요</h2>
                            <ul className={ styles.formRadio }>
                                {
                                    REASONS.map((reason, i) =>
                                        <li key={ `reasons-${i}` }>
                                            <Radio
                                                checked={ spec.reason === reason.key }
                                                text={ reason.value }
                                                name="reason"
                                                color="blueberry"
                                                onChange={ this.onChangeReason(reason.key) }
                                            />
                                        </li>
                                    )
                                }
                            </ul>
                            {
                                spec.reason === UserDropSpec.Reason.ETC &&
                                <textarea
                                    placeholder="직접 입력하기"
                                    className={ styles.formText }
                                    value={ spec.payload }
                                    onChange={ this.onChangePayload }
                                />
                            }
                        </div>
                        {
                            Application.me.account.passwordConfigured &&
                            <div className={ styles.formGroup }>
                                <h2 className={ styles.formTitle }>비밀번호를 입력해주세요</h2>
                                <input
                                    type="password"
                                    placeholder="비밀번호"
                                    className={ styles.formInput }
                                    onChange={ this.onChangePassword }
                                />
                            </div>
                        }
                    </div>
                </div>
                <section className={ styles.foot }>
                    <button className={ styles.cancelButton } onClick={ this.onCancel }>취소</button>
                    <button className={ styles.acceptButton } onClick={ this.onAccept }>탈퇴</button>
                </section>
            </FlexibleAlert>
        ) : (
            <div className={ styles.fixed }>
                <Loader padding={ 250 } radius={ 25 } />
            </div>
        )
    }

    private onChangeReason = (reason: UserDropSpec.Reason) => () =>
        this.setState({
            spec: apply(this.state.spec, it => {
                it.reason = reason;
            })
        });

    private onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({
            spec: apply(this.state.spec, it => {
                it.password = e.target.value
            })
        });

    private onChangePayload = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        this.setState({
            spec: apply(this.state.spec, it => {
                it.payload = e.target.value
            })
        });

    private onAccept = async () => {
        const { spec } = this.state;

        if (Application.me.account.passwordConfigured && spec.password.isEmpty()) {
            toast('비밀번호를 입력해주세요');
            return;
        }

        await this.setState({
            fetchState: FetchState.FETCHING
        });

        Application.services.user
            .drop(spec)
            .useOnAnimateFrame()
            .subscribe(
                () => {
                    alert('탈퇴가 완료되었습니다. 서비스를 이용해주셔서 감사합니다.');
                    location.href = BANKSALAD_SITEMAP.MAIN;
                },
                error => {
                    if (error instanceof NetworkError) {
                        this.handleError(error);
                    }

                    this.setState({
                        fetchState: FetchState.FETCHED
                    });
                }
            )
            .unsubscribeBy(this.subscriptionBag);
    };

    private onCancel = () => {
        if (this.state.fetchState === FetchState.FETCHING) {
            return;
        }

        this.props.onCancel();
    };

    private handleError = (error: NetworkError) => {
        toast(lets(error.statusCode, code => {
            switch (code) {
                case HttpStatusCode.FORBIDDEN:
                    return '정확한 비밀번호를 입력해주세요';
                case HttpStatusCode.NOT_FOUND:
                    return '계정정보가 정확하지 않습니다';
                default:
                    return '알 수 없는 에러가 발생했습니다';
            }
        }))
    };
}
