import { CardSearchSpec } from 'core/entity/card/search-spec';
import { CardSearchSpecMapper } from 'data/http/mapper/card/search-spec';
import { Page } from 'core/entity/page';
import { PageMapper } from 'data/http/mapper/page';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardNonTemporalComputationResult } from 'core/entity/card/non-temporal-computation-result';
import { CardRecommendSpecMapper } from 'data/http/mapper/card/recommend-spec';
import { CardNonTemporalComputationResultMapper } from 'data/http/mapper/card/non-temporal-computation-result';
import { lets } from 'utils/index';
import { CardSearchResult } from 'core/entity/card/search-result';
import { CardSearchResultMapper } from 'data/http/mapper/card/search-result';
import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { Card } from 'core/entity/card';
import { CardMapper } from 'data/http/mapper/card';

export class CardsApiProvider extends HttpProvider {
    get(id: number): Observable<Card> {
        return this.requestGET(
            `api/v3/cards/${id}`
        ).map((result: any) =>
            new CardMapper().fromJson(result)
        )
    }

    search(spec: CardSearchSpec, page: Page, simple: boolean): Observable<CardSearchResult> {
        return this.requestPOST(
            'api/v3/cards/search',
            new CardSearchSpecMapper().toJson(spec),
            Object.assign(
                new PageMapper().toJson(page),
                { simple }
            )
        ).map((result: any) =>
            new CardSearchResultMapper().fromJson(result)
        )
    }

    recommend(spec: CardRecommendSpec, page: Page): Observable<List<CardNonTemporalComputationResult>> {
        return this.requestPOST(
            'api/v3/cards/recommend?mode=non-temporal-spendings',
            new CardRecommendSpecMapper().toJson(spec),
            new PageMapper().toJson(page)
        ).map((result: any) =>
            List(result.results.map((card: any) =>
                new CardNonTemporalComputationResultMapper().fromJson(card)
            ))
        )
    }

    compute(cardId: number, spec: CardRecommendSpec): Observable<CardNonTemporalComputationResult> {
        return this.requestPOST(
            `api/v3/cards/${cardId}/compute?mode=non-temporal-spendings`,
            lets(new CardRecommendSpecMapper().toJson(spec), it => ({
                spendings: it.spendings,
                benefit_types: it.benefit_types
            }))
        ).map((result: any) =>
            new CardNonTemporalComputationResultMapper().fromJson(result)
        )
    }
}
