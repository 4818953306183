import { Entity } from 'core/entity';
import { InsurancePaymentPrice } from 'core/entity/insurance/payment-price';
import { InsurancePromotion } from 'core/entity/insurance/promotion';
import { PersonalizedInsuranceTag } from 'core/entity/personalized-recommend/insurance/tag';
import { InsuranceCommpany } from 'core/entity/insurance/company';
import { PersonalizedInsuranceRecommendResultWording } from 'core/entity/personalized-recommend/insurance/recommend-result/wording';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';
import { List } from 'immutable';

export class PersonalizedInsuranceRecommendResultSummary extends Entity {
    constructor(
        public id: string,
        public company: InsuranceCommpany,
        public type: PersonalizedInsuranceRecommendResultSummary.Type,
        public kind: PersonalizedInsuranceRecommendResultSummary.Kind,
        public renewalType: PersonalizedInsuranceRecommendResultSummary.RenewalType,
        public name: string,
        public insurancePeriod: number,
        public paymentPeriod: number,
        public mainClaimVolume: string,
        public matchedItems: List<MedicalCheckupJudgement.Item>,
        public tags: List<PersonalizedInsuranceTag>,
        public paymentPrice: InsurancePaymentPrice,
        public totalGuaranteedPrice: number,
        public expectedMedicalExpenses: number,
        public calculationCriteria: string,
        public maxGuaranteedPrice: number,
        public wording: PersonalizedInsuranceRecommendResultWording,
        public promotion: InsurancePromotion = null,
        public applyUrl: string = null
    ) {
        super();
    }
}

export module PersonalizedInsuranceRecommendResultSummary {
    export enum RenewalType {
        RENEWAL = '갱신형',
        NON_RENEWAL = '비갱신형'
    }

    export enum Type {
        ACCIDENT = '상해보험',
        ACTUAL_EXPENSES = '실비보험',
        CANCER = '암보험',
        CHILD = '어린이보험',
        CRITICAL_ILLNESS = '중대질병보험',
        DRIVER = '운전자보험',
        ILLNESS = '질병보험',
        MOTOR = '자동차보험',
        PENSION = '연금보험',
        PENSION_SAVINGS = '연금저축보험',
        SAVINGS = '저축보험',
        TOOTH = '치아보함',
        MINI = '미니보험',
    }

    export enum Kind {
        MATURITY_REFUND_100 = '만기환급형-100%',
        MATURITY_REFUND_50 = '만기환급형-50%',
        PURE_GUARANTEE = '순수보장형',
        CANCEL_IMPOSSIBLE_REFUND = '무해지환급형',
        STANDARD = '표준형'
    }
}
