import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';

export class CacheLoanRecommendFilter extends UseCase<void> {
    filter: LoanRecommendFilter;
    private repository: LoanRecommendRepositoryType;

    constructor(repository: LoanRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<void> {
        return this.repository.cacheFilter(this.filter);
    }

    protected validate(): boolean {
        return !!this.filter;
    }

}
