import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { Asset } from 'core/entity/personal-finance/asset';

export class UserCashAccount extends Entity {
    id: string;
    nickname: string;
    description: string;
    hidden: boolean;
    deletedStatus: Asset.DeletedStatus;
    isDefault: boolean;
    latestBalance?: Amount;

    constructor(
        id: string,
        nickname: string,
        description: string,
        hidden: boolean,
        deletedStatus: Asset.DeletedStatus,
        isDefault: boolean,
        latestBalance: Amount = null
    ) {
        super();
        this.id = id;
        this.nickname = nickname;
        this.description = description;
        this.hidden = hidden;
        this.latestBalance = latestBalance;
        this.deletedStatus = deletedStatus;
        this.isDefault = isDefault;
    }
}
