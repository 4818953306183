import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, lets, optional, toDateString } from 'utils/index';
import { FundCompanySummaryMapper } from 'data/http/mapper/personal-finance/fund-company/summary';
import { UserFundCreateSpec } from 'core/entity/personal-finance/fund/create-spec';

export class UserFundCreateSpecMapper implements NetworkRequestMapper<UserFundCreateSpec> {
    toJson(target: UserFundCreateSpec): any {
        return compactObject({
            company: lets(target.company, it => {
                const json = new FundCompanySummaryMapper().toJson(it);

                return {
                    id: json.id,
                    type: json.type
                }
            }),
            nickname: target.nickname,
            principal: target.principal,
            valuation: target.valuation,
            quantity: target.quantity,
            hidden: target.hidden,
            description: optional(target.description),
            created_at: optional(target.createdAt, it => toDateString(it, '-')),
            expired_at: optional(target.expiredAt, it => toDateString(it, '-'))
        })
    }
}
