import LottieWeb from 'lottie-web';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    lottieFile: Lottie.Animation;
    loop: boolean;
    onFinish?: () => void;
}

export class Lottie extends React.Component<Props> {
    private lottieTarget: HTMLDivElement;
    private lottie = LottieWeb;

    componentDidMount() {
      const { lottieFile, loop, onFinish } = this.props;

      const animation = this.lottie.loadAnimation({
        animationData: require(`presentation/resources/lottie/${lottieFile}`),
        container: this.lottieTarget,
        renderer: 'svg',
        loop: onFinish ? false : loop
      });

      onFinish &&
        setTimeout(() => {
          onFinish();
        }, animation.getDuration(false) * 1000);
    }

    componentWillUnmount() {
      this.lottie.destroy();
    }

    render() {
      return (
        <div
          ref={ it => this.lottieTarget = it }
          className={ styles.animationView }
        />
      );
    }
}


export namespace Lottie {
    export enum Animation {
        CREDIT_GRADE_IMPROVED = 'credit-grade-improved.json',
        CREDIT_GRADE_WORSEN = 'credit-grade-worsen.json',
        CREDIT_SCORE_IMPROVED = 'credit-score-improved.json',
        CREDIT_SCORE_WORSEN = 'credit-score-worsen.json',
        INTEREST_COUNTER = 'interest-counter.json',
        INSURANCE_EMPTY = 'insurance-empty.json',
        CARD_ROTATE = 'card-rotate.json',
        SIMPLELOAN_MAIN = 'simpleloan-main.json',
        MORTGAGE_LOAN = 'mortgage-loan.json',
        CAR_INSURANCE = 'car-insurance.json'
    }
}
