import { Application } from 'application/context.instance';
import { ContentSummary } from 'core/entity/content/summary';
import { FinanceSubSectorContentSummary } from 'core/entity/finance-sector/content-summary';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { List } from 'immutable';
import { EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { MainHeroBanner } from 'presentation/components/hero-banner/main';
import { LandingPick } from 'presentation/components/landing-pick';
import { Loader } from 'presentation/components/loader';
import { CLIX, sendClixEvent } from 'presentation/module/analytics/clix';
import {
  GA_ACTION,
  GA_CATEGORY,
  GA_DOMAIN,
  sendGAEvent
} from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { SubscriptionBag } from 'presentation/module/extension';
import {
  OUTBOUND_LINK
} from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import React from 'react';
import { apply, emptyList, lets, Pair, pairOf } from 'utils/index';

import styles from './styles.pcss';

const ALLOW_SECTOR = List([
  FinanceSectorID.CARD,
  FinanceSectorID.BANK,
  FinanceSectorID.INVESTMENT,
  FinanceSectorID.LOAN,
  FinanceSectorID.ANNUITY,
  FinanceSectorID.FINTECH,
  FinanceSectorID.MAGAZINE
]);
const TABLET_SIZE = 768;
const DESKTOP_BANNER_SIZE = 90;
const TABLET_BANNER_SIZE = 160;

type Pick = Pair<string, List<FinanceSubSectorContentSummary>>;
type Tab = Pair<FinanceSectorID, string>;

interface Props {
    /* explicitly empty */
}

interface State {
    sectorsFetchState: FetchState;
    reviewsFetchState: FetchState;
    tipsFetchState: FetchState;
    picks: List<Pick>;
    reviews: List<ContentSummary>;
    tips: List<ContentSummary>;
    tabs: List<Tab>;
    reviewTabName: string;
    tipTabName: string;
    fixThreadHoldOver: boolean;
}

export class LandingView extends React.Component<Props, State> {
    state = {
      rankingCreditCards: emptyList(),
      sectorsFetchState: FetchState.FETCHING,
      reviewsFetchState: FetchState.FETCHING,
      tipsFetchState: FetchState.FETCHING,
      picks: emptyList(),
      reviews: emptyList(),
      tips: emptyList(),
      tabs: emptyList(),
      reviewTabName: '',
      tipTabName: '',
      fixThreadHoldOver: false
    };

    private subscriptionBag = new SubscriptionBag();

    constructor(props: Props) {
      super(props);
      sendPixelEvent(PIXEL.MAIN);
      sendClixEvent(CLIX.MAIN);
    }

    componentDidMount() {
      this.fetchSectors();
      EventBus.toObservable()
        .subscribe(event => {
          if (event instanceof DocumentScrollEvent) {
            const { fixThreadHoldOver } = this.state;
            const threadHold = window.innerWidth <= TABLET_SIZE 
              ? TABLET_BANNER_SIZE : DESKTOP_BANNER_SIZE;

            if (!fixThreadHoldOver && event.scrollTop >= threadHold) {
              this.setState({ fixThreadHoldOver: true });
            } else if (fixThreadHoldOver && event.scrollTop < threadHold) {
              this.setState({ fixThreadHoldOver: false });
            }
          }
        })
        .unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    render() {
      const {
        picks,
        sectorsFetchState,
        fixThreadHoldOver
      } = this.state;

      return(
        <div className={ fixThreadHoldOver ? styles.fixedWrap : styles.wrap }>
          <Header fixed={fixThreadHoldOver} />
          <MainHeroBanner />
          <section className={ styles.section }>
            <div className={ styles.container }>
              <div className={ styles.featureTitle }>
                내게 맞는 금융상품, 비교하기 힘드시죠?
                <strong>
                뱅크샐러드는 <span className={ styles.mobileWordBreak }/>쉽고 정확하게 비교합니다.
                </strong>
              </div>
              <ul className={ styles.features }>
                <li>
                  <div className={ styles.featureImageData }/>
                  <div className={ styles.featureDesc }>
                    <div>국내 최대 금융상품 데이터</div>
                    <div>금융 상품 데이터뿐만 아니라, 전월 실적, 한도 조건 등 복잡하고 어려운 금융상품의 각종 조건 데이터까지 모두 챙겼습니다.</div>
                  </div>
                </li>
                <li>
                  <div className={ styles.featureImagePersonalized }/>
                  <div className={ styles.featureDesc }>
                    <div>나만을 위한 금융매칭</div>
                    <div>수백만가지 경우의 수를 계산하는 추천 알고리즘이 나를 위한 맞춤 상품을 찾아드립니다.</div>
                  </div>
                </li>
                <li>
                  <div className={ styles.featureImageAd }/>
                  <div className={ styles.featureDesc }>
                    <div>광고나 홍보 없음</div>
                    <div>뱅크샐러드의 추천에는 광고도, 홍보도 없습니다. 오직 객관적인 데이터만을 활용합니다.</div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          {
            sectorsFetchState === FetchState.FETCHED ? (
              <>
                {
                  !picks.isEmpty() && (
                    <section className={ styles.section }>
                      <div className={ styles.container }>
                        <div className={ styles.sectionTitle }>뱅크샐러드 Pick!</div>
                        <ul className={ styles.pickedContentContainer }>
                          {
                            picks.map((pick, index) =>
                              <li
                                key={ `landing-picked-content-${index}` }
                                className={ styles.pickedContent }
                              >
                                <LandingPick content={ pick }/>
                              </li>
                            )
                          }
                        </ul>
                      </div>
                    </section>
                  )
                }
              </>
            ) : sectorsFetchState === FetchState.FETCHING ? (
              <Loader padding={ 200 } radius={ 25 } />
            ) : (
              <FetchError padding={ 200 }>
                일시적인 오류가 발생했습니다<br />
                잠시 후 재시도 해주세요
              </FetchError>
            )
          }

          <div className={ styles.appWrap }>
            <div className={ styles.container }>
              <div className={ styles.appContent }>
                <div>구글 플레이 선정 2016년 `올해를 빛낸 혁신적인 앱` 수상</div>
                <div>금융을 내 편으로<br/>뱅크샐러드 다운로드</div>
                <div className={ styles.app }>
                  <a
                    className={ styles.google }
                    href={OUTBOUND_LINK.FOOTER_UTM.GOOGLE_PLAY_STORE}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={ () => sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.LANDING.APP_LINK_PLAYSTORE, GA_ACTION.LINK.APP, '') }
                  />
                  <a
                    className={ styles.apple }
                    href={OUTBOUND_LINK.FOOTER_UTM.APPLE_STORE}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={ () => sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.LANDING.APP_LINK_APPLESTORE, GA_ACTION.LINK.APP, '') }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={ styles.allianceWrap }>
            <span className={ styles.backgroundShinhan }/>
            <span className={ styles.backgroundSamsung }/>
            <span className={ styles.backgroundNH }/>
            <span className={ styles.backgroundLotte }/>
            <span className={ styles.backgroundHyundai }/>
            <div>
              뱅크샐러드는 <span className={ styles.mobileWordBreak }/>
              금융사와의 제휴를 통해<br/> 정확한 금융 정보를 전달합니다
            </div>
          </div>
          <div className={ styles.contactWrap }>
            <p>
              뱅크샐러드는 IBK기업은행 카드 추천 솔루션 제작부터 <span className={ styles.mobileWordBreak }/>
              KT CLiP 앱 카드데이터 제휴까지 
              <span className={ styles.mobileWordBreak }/>다양한 파트너와 협업을 진행했습니다.<br/>
              <span className={ styles.mobileWordBreak }><br/></span>
              국내 최대 금융 데이터와 <span className={ styles.mobileWordBreak }/>
              고도화된 금융추천엔진을 통해 사업을 발전시키고 싶다면 
              <span className={ styles.mobileWordBreak }/>
              언제든 <a href="mailto:banksalad@banksalad.com" onClick={ () => sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.LANDING.ALLIANCE, GA_ACTION.MAIL, 'banksalad@banksalad.com') }>banksalad@banksalad.com</a>으로 문의 주세요.
            </p>
          </div>
          <Footer/>
        </div>
      );
    }

    private renderTabs = (
      group: 'reviews' | 'tips',
      tabs: List<Tab>,
      onChange: (tab: Tab) => void
    ) => (
      <ul className={ styles.tabs }>
        {
          tabs.map((tab, i) =>
            <li key={ `tab-${i}` }>
              <input
                type="radio"
                id={ `${group}-${i}` }
                name={ group }
                className={ styles.tabToggle }
                defaultChecked={ i === 0 }
              />
              <label
                htmlFor={ `${group}-${i}` }
                onClick={ () => {
                  sendGAEvent(
                    GA_DOMAIN.NONE,
                    group === 'reviews' ?
                      GA_CATEGORY.LANDING.REVIEWS :
                      GA_CATEGORY.LANDING.TIPS,
                    GA_ACTION.TAB,
                    tab.second
                  );
                  onChange(tab);
                } }
                className={ styles.tabButton }
              >
                { tab.second }
              </label>
            </li>
          )
        }
      </ul>
    );

    private fetchSectors() {
      this.setState({
        sectorsFetchState: FetchState.FETCHING
      }, () => {
        Application.useCases.getFinanceSectorSummaries
          .runOnAnimateFrame()
          .subscribe(
            sectors =>
              this.setState({
                tabs: sectors
                  .filter(sector => ALLOW_SECTOR.contains(sector.id))
                  .map(sector => pairOf(sector.id, sector.name))
                  .toList(),
                picks: sectors
                  .filter(sector => ALLOW_SECTOR.contains(sector.id))
                  .map(sector => pairOf(sector.name, sector.generateLandingRecommends()))
                  .toList(),
                sectorsFetchState: FetchState.FETCHED
              }, () => lets(sectors.first(), it => {
                this.fetchReviews(it.id, it.name);
                this.fetchTips(it.id, it.name);
              })),
            () =>
              this.setState({
                sectorsFetchState: FetchState.ERROR
              })
          )
          .unsubscribeBy(this.subscriptionBag);
      });
    }

    private fetchReviews = (id: FinanceSectorID, name: string) => {
      this.setState({
        reviewsFetchState: FetchState.FETCHING
      }, () => {
        apply(Application.useCases.getFinanceSector, it => {
          it.id = id;
        }).runOnAnimateFrame().subscribe(
          sector =>
            this.setState({
              reviews: sector.generateLandingReviews(),
              reviewTabName: name,
              reviewsFetchState: FetchState.FETCHED
            }),
          () =>
            this.setState({
              reviewsFetchState: FetchState.ERROR
            })
        ).unsubscribeBy(this.subscriptionBag);
      });
    };

    private fetchTips = (id: FinanceSectorID, name: string) => {
      this.setState({
        tipsFetchState: FetchState.FETCHING
      }, () => {
        apply(Application.useCases.getFinanceSector, it => {
          it.id = id;
        }).runOnAnimateFrame().subscribe(
          sector =>
            this.setState({
              tips: sector.generateLandingMagazines(),
              tipTabName: name,
              tipsFetchState: FetchState.FETCHED
            }),
          () =>
            this.setState({
              tipsFetchState: FetchState.ERROR
            })
        ).unsubscribeBy(this.subscriptionBag);
      });
    };
}
