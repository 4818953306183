import { UserCardBillRepositoryType } from 'core/repository/personal-finance/card-bill';
import { UseCase } from 'core/use-case';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { UserCardBillContent } from 'core/entity/personal-finance/card-bill-content';

export class GetUserCardBillContentsByCompany extends UseCase<List<UserCardBillContent>> {
    cardCompanyId: string;
    private repository: UserCardBillRepositoryType;

    constructor(repository: UserCardBillRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<UserCardBillContent>> {
        return this.repository.getAll(
            this.cardCompanyId
        );
    }

    protected validate(): boolean {
        return !!this.cardCompanyId;
    }

}
