import { UseCase } from 'core/use-case';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { UserTransactionRepositoryType } from 'core/repository/personal-finance/transaction';
import { UserTransaction } from 'core/entity/personal-finance/transaction';

export class DeleteUserTransactions extends UseCase<void> {
    transactions: List<UserTransaction>;
    private repository: UserTransactionRepositoryType;

    constructor(repository: UserTransactionRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<void> {
        return this.repository.delete(this.transactions);
    }

    protected validate(): boolean {
        return !this.transactions.isEmpty();
    }
}
