import React from 'react';

import { CardQuestionMonthlySpending } from 'presentation/components/survey-form/card-monthly-spending/model';
import { InputCurrency } from 'presentation/components/input/currency';
import {
    MediaDesktop,
    MediaTabletMobile
} from 'presentation/components/responsive/media-component';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    data: CardQuestionMonthlySpending,
    onChange?: (result: CardQuestionMonthlySpending) => void
}

export class CardMonthlySpendingForm extends React.Component<Props, undefined> {

    render() {
        const PRESET = [
            { value: 500000, text: '50만' },
            { value: 700000, text: '70만' },
            { value: 1000000, text: '100만' },
            { value: 1500000, text: '150만' },
            { value: 2000000, text: '200만' },
            { value: 5000000, text: '500만' },
            { value: 10000000, text: '1,000만' }
        ];
        const { data } = this.props;
        const overflowMessage = '너무 큰 금액은 입력할 수 없습니다';

        return (
            <div>
                <div className={ styles.amount }>
                    <MediaDesktop>
                        <InputCurrency
                            value={ data.value }
                            color="#04C584"
                            fontSize={ 20 }
                            textAlign="right"
                            overflowMessage={ overflowMessage }
                            onChange={ result => this.onChange(result) }
                            onBlur={ e => sendGAEvent(GA_DOMAIN.CARD, GA_CATEGORY.CARDS_QUESTIONS.MONTHLY_SPENDING_AMOUNT, GA_ACTION.INPUT, e.currentTarget.value) }
                        />
                    </MediaDesktop>
                    <MediaTabletMobile>
                        <InputCurrency
                            value={ data.value }
                            color="#04C584"
                            fontSize={ 16 }
                            textAlign="right"
                            overflowMessage={ overflowMessage }
                            onChange={ result => this.onChange(result) }
                            onBlur={ e => sendGAEvent(GA_DOMAIN.CARD, GA_CATEGORY.CARDS_QUESTIONS.MONTHLY_SPENDING_AMOUNT, GA_ACTION.INPUT, e.currentTarget.value) }
                        />
                    </MediaTabletMobile>
                    <span className={ styles.amountCurrency }>원</span>
                    <button
                        className={ styles.amountClear }
                        onClick={ () => this.onClear() }
                    >
                        초기화
                    </button>
                </div>
                <ul className={ styles.preset }>
                    {
                        PRESET.map(item =>
                            <li key={ item.text }>
                                <button
                                    className={ styles.tag }
                                    onClick={ () => this.onChangeByShortcut(item.value) }
                                >
                                    { item.text }
                                </button>
                            </li>
                        )
                    }
                </ul>
            </div>
        )
    }

    private onClear() {
        sendGAEvent(
            GA_DOMAIN.CARD,
            GA_CATEGORY.CARDS_QUESTIONS.MONTHLY_SPENDING_AMOUNT,
            GA_ACTION.CLEAR
        );
        this.props.onChange(
            new CardQuestionMonthlySpending(0)
        )
    }

    private onChange(amount: number) {
        sendGAEvent(
            GA_DOMAIN.CARD,
            GA_CATEGORY.CARDS_QUESTIONS.MONTHLY_SPENDING_AMOUNT,
            GA_ACTION.INPUT,
            amount.toString()
        );
        this.props.onChange(
            new CardQuestionMonthlySpending(amount)
        )
    };

    private onChangeByShortcut(amount: number) {
        sendGAEvent(
            GA_DOMAIN.CARD,
            GA_CATEGORY.CARDS_QUESTIONS.MONTHLY_SPENDING_AMOUNT,
            GA_ACTION.INPUT_SHORTCUT,
            amount.toString()
        );
        this.props.onChange(
            new CardQuestionMonthlySpending(amount)
        )
    }
}
