import { Entity } from 'core/entity';

export class Amount extends Entity {
    constructor(
        public amount: number,
        public currency: Amount.Currency
    ) {
        super();
    }
}

export module Amount {
    export enum Currency {
        KRW = 'KRW',
        USD = 'USD'
    }
}
