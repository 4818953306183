import { Application } from 'application/context.instance';
import Axios from 'axios';
import { ContentSummary } from 'core/entity/content/summary';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { ContentSummaryMapper } from 'data/http/mapper/content/summary';
import { List } from 'immutable';
import { Header } from 'presentation/components/header';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { SectorContentsRecommend } from 'presentation/components/sector-contents/recommend-type';
import { SectorContentsReview } from 'presentation/components/sector-contents/review-type';
import { CLIX, sendClixEvent } from 'presentation/module/analytics/clix';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { EMERGENCY_MAGAZINE_SECTOR } from 'presentation/module/emergency-contents/sectors/magazine/emergency';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { ContentsHero } from 'presentation/routes/sectors/magazine/hero';
import { ContentsNewMagazines } from 'presentation/routes/sectors/magazine/new-magazines';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNamespaceProduction } from 'utils/env';
import { emptyList } from 'utils/index';

import styles from './styles.pcss';

type Props = RouteComponentProps<any>

interface State {
    featuredContents: List<ContentSummary>;
    brandNewContents: List<ContentSummary>;
    sector?: FinanceSector;
    presentationalContentsFetchState: FetchState;
    sectorFetchState: FetchState;
}

export const SectorsMagazine = () => {
  const [state, setState] = useState({
    featuredContents: emptyList(),
    brandNewContents: emptyList(),
    sector: null as FinanceSector,
    contentsFetchState: FetchState.FETCHING,
    sectorFetchState: FetchState.FETCHING,
  });
  const subscriptionBag = new SubscriptionBag();
  const { 
    featuredContents, 
    brandNewContents, 
    sector, 
    contentsFetchState, 
    sectorFetchState,
  } = state;

  useEffect(() => {
    sendPixelEvent(PIXEL.CONTENTS);
    sendClixEvent(CLIX.CONTENTS);
  }, []);

  useEffect(() => {
    (async () => {
      const FEATURED_COLUMN_CONTENTS_API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/featured-column-contents.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/featured-column-contents.json`;

      const COLUMN_CONTENTS_API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/column-contents.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/column-contents.json`;
      
      const [featuredContents, brandNewContents] = await Promise.all([
        Axios.get(FEATURED_COLUMN_CONTENTS_API_PATH),
        Axios.get(COLUMN_CONTENTS_API_PATH),
      ]);
      
      setState({
        ...state,
        featuredContents: featuredContents.data.data.map(
          (content: any) => new ContentSummaryMapper().fromJson(content)
        ),
        brandNewContents: brandNewContents.data.data.slice(0, 4).map(
          (content: any) => new ContentSummaryMapper().fromJson(content)
        ),
        contentsFetchState: FetchState.FETCHED,
      });
    })();
  }, []);

  useEffect(() => {
    Application.useCases.getMagazineSector
      .runOnAnimateFrame()
      .subscribe(
        sector => setState({
          ...state,
          sector,
          sectorFetchState: FetchState.FETCHED,
        }),
        error => setState({
          ...state,
          sector: EMERGENCY_MAGAZINE_SECTOR,
          sectorFetchState: FetchState.FETCHED,
        }),
      )
      .unsubscribeBy(subscriptionBag);
  }, [contentsFetchState]);

  return (
    <div>
      <MetaHelmet meta={ META_SET.SECTORS_MAGAZINE }/>
      <Header active={ FinanceSectorID.MAGAZINE } />
      {
        contentsFetchState === FetchState.FETCHED ?
          <div>
            <ContentsHero
              lists={ featuredContents }
            />
            <section className={ styles.contentWrap }>
              <div className={ styles.contentContainer }>
                <h2 className={ styles.newMagazineTitle }>최신 매거진</h2>
                <a
                  href={ BANKSALAD_SITEMAP.CONTENTS }
                  onClick={ () => sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.SECTORS.MAGAZINE.NEW, GA_ACTION.LINK.CONTENTS_COLUMN, '전체보기') }
                  className={ styles.contentMore }
                >
                  <span>전체보기</span>
                </a>
                <ContentsNewMagazines
                  contents={ brandNewContents }
                />
              </div>
            </section>
            <hr className={ styles.divider }/>
          </div> :
          <Loader padding={ 200 } radius={ 25 }/>
      }
      {
        sector && sectorFetchState === FetchState.FETCHED ?
          <div>
            <section className={ styles.contentWrap }>
              <div className={ styles.contentContainer }>
                <h2 className={ styles.newMagazineTitle }>실속있는 금융생활 가이드</h2>
                <SectorContentsRecommend
                  contents={ sector.generateLandingRecommends() }
                  gaCategory={ GA_CATEGORY.SECTORS.PICK }
                />
              </div>
            </section>
            <section className={ styles.editingBannerWrap }>
              <div className={ styles.contentContainer }>
                <p className={ styles.editingText }>
                  <strong>뱅크샐러드 매거진은</strong>
                                    소비자 관점에서, 전문성있는, 유용한 정보에 집중합니다
                </p>
                <a
                  href={ BANKSALAD_SITEMAP.CONTENTS_CONTRIBUTION }
                  onClick={ () => sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.SECTORS.MAGAZINE.CONTRIBUTION, GA_ACTION.LINK.CONTENTS_CONTRIBUTION, '뱅크샐러드에디팅철학보기') }
                  className={ styles.editingLink }
                >
                  <span>뱅크샐러드 에디팅 철학보기</span>
                </a>
              </div>
            </section>
            <section className={ styles.contentWrap }>
              <div className={ styles.contentContainer }>
                <h2 className={ styles.newMagazineTitle }>쉽고, 재밌는 금융이야기</h2>
                <SectorContentsReview
                  contents={ sector.generateLandingReviews() }
                  gaCategory={ GA_CATEGORY.SECTORS.REVIEW }
                />
              </div>
            </section>
            <section className={ styles.authorInfoWrap }>
              <div className={ styles.authorInfoContainer }>
                <p className={ styles.authorInfoText }>
                  <strong>당신이 더 많은 금융혜택을<br/> 돌려받아야 한다고 믿는</strong>
                                    뱅크샐러드 매거진<br/> 집필진을 소개합니다
                </p>
                <a
                  href={ BANKSALAD_SITEMAP.CONTENTS_AUTHORS }
                  onClick={ () => sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.SECTORS.MAGAZINE.AUTHORS, GA_ACTION.LINK.CONTENTS_AUTHORS, '뱅크샐러드집필진보기') }
                  className={ styles.authorInfoLink }
                >
                  <span>뱅크샐러드 집필진 보기</span>
                </a>
              </div>
            </section>
          </div> :
          contentsFetchState === FetchState.FETCHED
            && <Loader padding={ 200 } radius={ 25 }/>
      }
    </div>
  );
};
