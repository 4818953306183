import { NetworkResponseMapper } from 'data/http/mapper';
import { ServiceCenterQuestion } from 'core/entity/service-center/question';

export class ServiceCenterQuestionMapper implements NetworkResponseMapper<ServiceCenterQuestion> {
    fromJson(json: any): ServiceCenterQuestion {
        return new ServiceCenterQuestion(
            json.id,
            json.title,
            json.url,
        )
    }
}
