import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';

import styles from './styles.pcss';

export const PageOnError = () => (
  <div>
    <MetaHelmet meta={ META_SET.ERROR_PAGE }/>
    <div className={ styles.wrap }>
      <div className={ styles.container }>
        <h2 className={ styles.title }>Oops! 예기치못한 오류가 발생하였습니다</h2>
        <p className={ styles.desc }>
                    재시도 후에도 문제가 지속되면<br/>
          <a href="mailto:hello@banksalad.com">hello@banksalad.com</a>
                    로 뱅크샐러드에게 알려주시면 친절히 안내해드리겠습니다
        </p>
      </div>
    </div>
  </div>
);
