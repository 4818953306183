import { NetworkResponseMapper } from 'data/http/mapper';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { AmountMapper } from 'data/http/mapper/amount';
import { SpiderSecuritiesFirmSummaryMapper } from 'data/http/mapper/personal-finance/securities-firm/summary';
import { UserSecuritiesFirmAccountItemConfigurationMapper } from 'data/http/mapper/personal-finance/securities-firm-account/item-configuration';
import { optional } from 'utils/index';
import { SecuritiesFirmAccountPrimaryDataMapper } from 'data/http/mapper/personal-finance/primary-data/securities-firm-account';

export class UserSecuritiesFirmAccountMapper implements NetworkResponseMapper<UserSecuritiesFirmAccount> {
    fromJson(json: any): UserSecuritiesFirmAccount {
        return new UserSecuritiesFirmAccount(
            json.id,
            json.hidden,
            new DeletedStatusMapper().fromJson(json.deleted_status),
            json.closed,
            json.dormant,
            new AmountMapper().fromJson(json.latest_balance),
            new SpiderSecuritiesFirmSummaryMapper().fromJson(json.securities_firm),
            new UserSecuritiesFirmAccountItemConfigurationMapper().fromJson(json.item_configuration),
            new SecuritiesFirmAccountPrimaryDataMapper().fromJson(json.primary_data),
            optional(json.number),
            optional(json.nickname),
            optional(json.description)
        )
    }

}
