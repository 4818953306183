import { Entity } from 'core/entity';
import { Alfred2ComputedCard } from 'core/entity/alfred2/computed-card';
import { Alfred2TotalProfitConfiguration } from 'core/entity/alfred2/total-profit-configuration';

export class AdviceMonthlyCardProfit extends Entity {
    card: Alfred2ComputedCard;
    profit: Alfred2TotalProfitConfiguration;

    constructor(
        card: Alfred2ComputedCard,
        profit: Alfred2TotalProfitConfiguration
    ) {
        super();
        this.card = card;
        this.profit = profit;
    }
}
