import { StoreRepositoryType } from 'core/repository/store';
import { StoreCategory } from 'core/entity/store/category';
import { StoreGroup } from 'core/entity/store/group';
import { Store } from 'core/entity/store';
import { Page } from 'core/entity/page';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { StoresApiProvider } from 'data/http/api/gateway/v3/stores';
import { StoreCategoriesApiProvider } from 'data/http/api/gateway/v3/stores/categories';

export class StoreRepository implements StoreRepositoryType {
    private storesApi: StoresApiProvider;
    private categoriesApi: StoreCategoriesApiProvider;

    constructor(
        storesApi: StoresApiProvider,
        categoriesApi: StoreCategoriesApiProvider
    ) {
        this.storesApi = storesApi;
        this.categoriesApi = categoriesApi;
    }

    getStores(groupId: number, page: Page): Observable<List<Store>> {
        return this.storesApi.getAll(groupId, page);
    }

    getStoreCategories(): Observable<List<StoreCategory>> {
        return this.categoriesApi.getCategories();
    }

    getBestStoreGroups(numberOfStores: number): Observable<List<StoreGroup>> {
        return this.categoriesApi.getBestGroups(numberOfStores);
    }

    getStoreGroups(categoryId: number, numberOfStores: number): Observable<List<StoreGroup>> {
        return this.categoriesApi.getGroups(categoryId, numberOfStores);
    }

    search(query: string): Observable<List<Store>> {
        return this.storesApi.search(query);
    }
}
