export class SelectorModel {
    id: string;
    title: string;
    subtitle?: string;

    constructor(
        id: string,
        title: string,
        subtitle: string = null
    ) {
        this.id = id;
        this.title = title;
        this.subtitle = subtitle;
    }
}

export enum SelectorTheme {
    ROMAINE, BLUEBERRY
}
