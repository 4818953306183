import { Entity } from 'core/entity';

export class PersonalizedLoanOrganization extends Entity {
    name: string;
    imageUrl: string;
    type: PersonalizedLoanOrganization.Type;

    constructor(
        name: string,
        imageUrl: string,
        type: PersonalizedLoanOrganization.Type
    ) {
        super();
        this.name = name;
        this.imageUrl = imageUrl;
        this.type = type;
    }
}

export module PersonalizedLoanOrganization {
    export enum Type {
        PRIMARY_BANK = 'primary-bank',
        SECONDARY_BANK = 'secondary-bank',
        CARD_COMPANY = 'card-company',
        CAPITAL = 'capital',
        INSURANCE = 'insurance',
        P2P = 'p2p'
    }
}
