import { Entity } from "core/entity";

export class ServiceCenterQuestion extends Entity {
    constructor(
        public id: number,
        public title: string,
        public url: string,
    ) {
        super();
    }
}
