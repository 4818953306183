import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanInterestType } from 'core/entity/loan/interest-type';

export class LoanInterestTypeMppaer implements BrowserStorageMapper<LoanInterestType> {
    toJson(entity: LoanInterestType): any {
        switch (entity) {
            case LoanInterestType.FIXED_INTEREST:
                return 'fixed_interest';
            case LoanInterestType.NON_FIXED_INTEREST:
                return 'non_fixed_interest';
            default:
                return null;
        }
    }

    toEntity(value: any): LoanInterestType {
        switch (value) {
            case 'fixed_interest':
                return LoanInterestType.FIXED_INTEREST;
            case 'non_fixed_interest':
                return LoanInterestType.NON_FIXED_INTEREST;
            default:
                return null;
        }
    }

}
