import { List } from 'immutable';
import { EventBus } from 'presentation/bus';
import { InitializeSettingPageEvent } from 'presentation/bus/event/initialize-setting-page-event';
import { PersonalFinanceMenuType } from 'presentation/components/personal-finance/menu/model';
import { MediaDesktop, MediaTabletMobile } from 'presentation/components/responsive/media-component';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';
const MENUS = List.of(
  { type: PersonalFinanceMenuType.MY_FINANCE, link: BANKSALAD_SITEMAP.PFM_MY_FINANCE },
  { type: PersonalFinanceMenuType.TRANSACTIONS, link: null },
  { type: PersonalFinanceMenuType.STATISTICS, link: null },
  { type: PersonalFinanceMenuType.SECRETARY, link: null },
  { type: PersonalFinanceMenuType.BUDGET, link: null },
  { type: PersonalFinanceMenuType.GOAL, link: null },
  { type: PersonalFinanceMenuType.FINANCIAL_TECHNOLOGY, link: null }
);
let menu: HTMLUListElement;

const renderSettingButton = (active: boolean) =>
  <a
    href={ BANKSALAD_SITEMAP.PFM_SETTINGS }
    className={ active ? styles.activeSettingButton : styles.settingButton }
    onClick={ () => EventBus.post(new InitializeSettingPageEvent()) }
  >
    설정
  </a>;

export const PersonalFinanceMenu = (props: { active: PersonalFinanceMenuType }) =>
  <div className={ styles.wrap }>
    <ul ref={ ref => menu = ref } className={ styles.menu }>
      {
        MENUS.map((menu, i) =>
          <li key={ `menus-${i}` }>
            {
              menu.link ?
                <a
                  href={ menu.link }
                  className={ props.active === menu.type ? styles.activeButton : styles.button }
                >
                  { menu.type.toString() }
                </a> :
                <span className={ styles.disableButton }>
                  { menu.type.toString() }
                </span>
            }
          </li>
        )
      }
      <li className={ styles.forMobile }>
        { renderSettingButton(props.active === PersonalFinanceMenuType.SETTINGS) }
      </li>
    </ul>
    <div className={ styles.right }>
      <MediaDesktop>
        { renderSettingButton(props.active === PersonalFinanceMenuType.SETTINGS) }
      </MediaDesktop>
      <MediaTabletMobile>
        <button
          className={ styles.scroll }
          onClick={ () => menu.scrollLeft += 50 }
        >
          메뉴 스크롤이동
        </button>
      </MediaTabletMobile>
    </div>
  </div>;
