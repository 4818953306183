import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { AdviceRecommendedExpenseAmount } from 'core/entity/personal-finance/advice/recommended-expense-amount';
import { Amount } from 'core/entity/amount';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';

export class AdviceBudgetData extends Entity implements AdviceContentData {
    ageGroup: number;
    sex: AdviceBudgetData.Sex;
    totalExpense: Amount;
    recommendedExpenseAmount?: AdviceRecommendedExpenseAmount;
    totalBudget?: Amount;
    message?: AdviceMessage;

    constructor(
        ageGroup: number,
        sex: AdviceBudgetData.Sex,
        totalExpense: Amount,
        recommendedExpenseAmount: AdviceRecommendedExpenseAmount = null,
        totalBudget: Amount = null,
        message: AdviceMessage = null
    ) {
        super();
        this.ageGroup = ageGroup;
        this.sex = sex;
        this.totalExpense = totalExpense;
        this.recommendedExpenseAmount = recommendedExpenseAmount;
        this.totalBudget = totalBudget;
        this.message = message;
    }
}

export module AdviceBudgetData {
    export enum Sex {
        MALE = 'male',
        FEMALE = 'female'
    }
}
