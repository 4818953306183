import Axios from 'axios';
import { ContentAuthor } from 'core/entity/content/author';
import { ContentAuthorMapper } from 'data/http/mapper/content/author';
import { ContentsViewer } from 'presentation/components/contents-viewer';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { isNamespaceProduction } from 'utils/env';

import styles from './styles.pcss';
const old_bi_url = 'https://cdn.banksalad.com/entities/content-authors/1543806098201-%E1%84%83%E1%85%A1%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%85%E1%85%A9%E1%84%83%E1%85%B3.png';

interface State {
    author: ContentAuthor;
    fetchState: FetchState;
}

export const AuthorDetail = ({ match }: RouteComponentProps<any>) => {
  const id = match.params.id;
  const [{ author, fetchState }, setState] = useState({
    author: null as ContentAuthor,
    fetchState: FetchState.FETCHING,
  });

  const renderComponent = () =>
    (fetchState === FetchState.FETCHED && author) ?
      (
        <div>
          <div className={ styles.authorInfo }>
            <div className={ styles.authorInfoContainer }>
              <img
                src={ author.profile.imageUrl === old_bi_url ? 'https://cdn.banksalad.com/resources/images/logo/bi.png' : author.profile.imageUrl }
                alt=''
                style={ author.profile.imageUrl === old_bi_url ? { 'borderRadius':'0%' } : { 'borderRadius':'50%' } }
                className={ styles.authorImage }
              />
              <h1 className={ styles.authorName }>{ author.profile.name }</h1>
              <h2 className={ styles.authorDesc }>{ author.profile.summary }</h2>
            </div>
          </div>
          <div className={ styles.list }>
            <ContentsViewer authorId={ author.id } />
          </div>
        </div>
      ): fetchState === FetchState.FETCHING ? (
        <Loader padding={ 200 } radius={ 25 } />
      ) : (
        <FetchError padding={ 200 }>
          일시적인 오류가 발생했습니다<br/>
          잠시 후 새로고침 해주세요
        </FetchError>
      );

  useEffect(() => {
    (async () => {
      const API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/authors/${id}.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/authors/${id}.json`;
      
      const { data } = await Axios.get(API_PATH);
      
      setState({
        author: new ContentAuthorMapper().fromJson(data.data),
        fetchState: FetchState.FETCHED,
      });
    })();
  }, []);

  return (
    <div>
      { author && <MetaHelmet meta={ META_SET.CONTENTS_AUTHOR(author) }/> }
      { renderComponent() }
    </div>
  );
};
