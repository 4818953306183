import { EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { EventTags } from 'presentation/components/event-tags';
import { EventViewerModel } from 'presentation/components/event-viewer/model';
import { EventsViewerList } from 'presentation/components/events-viewer/list';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Loader } from 'presentation/components/loader';
import { GA_ACTION, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { FetchState } from 'presentation/view-model/fetch-state';
import React from 'react';
import { Subscription } from 'rxjs/Rx';

import styles from './styles.pcss';

const BANNER_HEIGHT = 280;

interface Props<T> {
    linkUrl: (id: string | number) => string;
    ga: {
        issueLabel: string;
        heroCategory: string;
        suggestedCategory: string;
        suggestedAction: string;
        floatButtonCategory: string;
    };
    event: T;
    fetchState: FetchState;
}

interface State {
    visibleFloat: boolean;
}

export class EventViewer extends React.Component<Props<EventViewerModel>, State> {
    state = {
      visibleFloat: false
    };

    private eventBus: Subscription = EventBus.toObservable().subscribe(
      (event: Event) => {
        if (event instanceof DocumentScrollEvent) {
          if (!this.state.visibleFloat && event.scrollTop > BANNER_HEIGHT) {
            this.setState({
              visibleFloat: true
            });
          } else if (this.state.visibleFloat && event.scrollTop <= BANNER_HEIGHT) {
            this.setState({
              visibleFloat: false
            });
          }
        }
      }
    );

    componentWillUnmount() {
      this.eventBus.unsubscribe();
    }

    render() {
      const {
        event,
        fetchState,
        linkUrl,
        ga
      } = this.props;

      return (
        fetchState === FetchState.ERROR ?
          <FetchError padding={ 300 }>
            이벤트를 불러오는 중 오류가 발생했습니다<br/>
            잠시 후 새로고침 해주세요
          </FetchError>
          : fetchState === FetchState.FETCHING ? (
            <Loader padding={ 300 } radius={ 25 } />
          ) : (
            <div className={ styles.wrap }>
              {
                event &&
                <div className={ styles.container }>
                  <div className={ styles.content }>
                    <section className={ styles.header }>
                      <div className={ styles.tagContainer }>
                        <EventTags
                          event={ event }
                        />
                      </div>
                      <h1 className={ styles.title }>
                        <div className={ styles.titleText }><span>{ event.title }</span></div>
                        <div>
                          <div className={ styles.headerLinks }>
                            <div className={ styles.headerLinksContainer }>
                              <a
                                href={ event.url }
                                target="_blank"
                                rel='noopener noreferrer'
                                onClick={ () => sendGAEvent(
                                  GA_DOMAIN.NONE, 
                                  ga.heroCategory, 
                                  GA_ACTION.ISSUE, 
                                  `${ga.issueLabel}_${event.company.name}_${event.id}`) 
                                }
                                className={ styles.headerOutboundLink }
                              >
                                { ga.issueLabel }
                              </a>
                            </div>
                          </div>
                        </div>
                      </h1>
                    </section>
                    <section
                      dangerouslySetInnerHTML={ { __html: event.description.replace(/``/gi, '\'') } }
                      className={ styles.description }
                    >
                    </section>
                  </div>
                  {
                    !event.suggestedEvents.isEmpty() &&
                    <div>
                      <h4
                        className={ styles.suggestedSectionTitle }
                      >
                        <span>이런 상품/이벤트는 어떠세요?</span>
                      </h4>
                      <EventsViewerList
                        linkUrl={ linkUrl }
                        gaCategory={ ga.suggestedCategory }
                        gaAction={ ga.suggestedAction }
                        events={ event.suggestedEvents }
                      />
                    </div>
                  }
                  { this.renderFloatingButtonSection() }
                </div>
              }
            </div>
          )
      );
    }

    private renderFloatingButtonSection() {
      const {
        visibleFloat
      } = this.state;
      const {
        ga,
        event
      } = this.props;

      return (
        <section
          className={
            visibleFloat ?
              styles.visibleFloat :
              styles.hiddenFloat
          }
        >
          <a
            href={ event.url }
            target="_blank"
            rel='noopener noreferrer'
            onClick={ () => sendGAEvent(
              GA_DOMAIN.NONE, 
              ga.floatButtonCategory, 
              GA_ACTION.ISSUE, 
              `${ga.issueLabel}_${event.company.name}_${event.id}`) 
            }
            className={ styles.floatOutBoundLink }
          >
            { ga.issueLabel }
          </a>
        </section>
      );
    }
}
