import { Entity } from 'core/entity';
import { MedicalCheckupSummaryResult } from 'core/entity/medical-checkup/summary/result';

export class MedicalCheckupSummary extends Entity {
    updatedAt: string;
    result?: MedicalCheckupSummaryResult;

    constructor(
        updatedAt: string,
        result: MedicalCheckupSummaryResult = null
    ) {
        super();
        this.updatedAt = updatedAt;
        this.result = result;
    }
}
