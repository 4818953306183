export enum FirebaseEventName {
    /* domain - common */
    REACH_SCREEN = 'reach_screen',
    CLOSE_SCREEN = 'close_screen',
    START_EXPLORE = 'start_explore',
    CHECK_DETAIL = 'check_detail',
    CHECK_HISTORY = 'check_history',
    GET_RECOMMENDATION = 'get_recommendation',
    VIEW_CONTENT = 'view_content',
    CLICK_QUESTION_MARK = 'click_question_mark',
    EXPOSE_PRODUCT = 'expose_product',
    START_MANAGE_CREDIT = 'start_manage_credit',
    ISSUE_PRODUCT = 'issue_product',
    ISSUE_PRODUCT_POPUP = 'issue_product_popup',
    EXPLORE_PRODUCT_DETAIL = 'explore_product_detail',
    ADD_ASSETS = 'add_assets',
    CHANGE_SORT = 'change_sort',
    CHANGE_TAB = 'change_tab',
    CHANGE_USER_PRODUCT = 'change_user_product',

    /* domain - credit management */
    EXPLORE_CREDIT_SCORE = 'explore_credit_score',
    REFRESH_CREDIT_SCORE = 'refresh_credit_score',
    CHECK_CREDIT_INFO = 'check_credit_info',
    CLICK_CREDIT_SCORE_BAR = 'click_credit_score_bar',

    /* domain - personalized loan recommend */
    CHANGE_INTEREST = 'change_interest',
    CLICK_CREDIT_GOAL = 'click_credit_goal'
}

export enum FirebaseScreen {
    CREDIT_INFO = 'credit_info',
    CREDIT_TERMS_AGREEMENT = 'credit_terms_agreement',
    CREDIT_MANAGEMENT = 'credit_management',
    CREDIT_GOAL = 'credit_goal',
    LOAN_RECOMMENDATION = 'loan_recommendation',
    ADVICE_DETAIL = 'advice_detail'
}

export enum FirebaseEventDomain {
    CREDIT_MANAGEMENT = 'credit_management',
    LOAN_RECOMMENDATION = 'loan_recommendation',
    ASSISTANT = 'assistant',
    ASSET = 'asset'
}

export enum FirebaseProductType {
    CARD = 'card',
    LOAN = 'loan'
}

export enum FirebaseProductPromotionType {
    DISCOUNT_INTEREST = 'discount_interest',
    ANNUAL = 'annual'
}

interface FirebaseParams {
    screen_info?: string;
    screen_from?: string;
    screen_name_detail?: string;
    event_info?: string;
    event_info_value?: string;
    event_action_value?: string;
    product_id?: string;
    product_promotion?: string;
    product_type?: string;
    domain_detail?: string;
    organization?: string;
    type?: string;
    event_domain_detail?: string;
}

export const sendFirebaseEventV2 = (
  name: FirebaseEventName,
  screen: FirebaseScreen,
  domain: FirebaseEventDomain,
  params: FirebaseParams = null
): void => {
  // 사용하지 않는 코드지만 서비스에 영향을 주지 않기 위해 주석처리
};
