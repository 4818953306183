import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentTagSummaryMapper } from 'data/http/mapper/content/tag/summary';
import { ContentColumn } from 'core/entity/content/column';
import { ContentMetaInformationMapper } from 'data/http/mapper/content/meta-information';
import { NewContentColumnArticleMapper } from 'data/http/mapper/content/column/article';
import { PromotedContentSetMapper } from 'data/http/mapper/promoted-content-set';
import { ContentAuthorSummaryMapper } from 'data/http/mapper/content/author/summary';
import { parseDate, optional } from 'utils/index';

export class ContentColumnMapper implements NetworkResponseMapper<ContentColumn> {
    fromJson(json: any): ContentColumn {
        return new ContentColumn(
            json.id,
            json.title,
            json.description,
            new ContentAuthorSummaryMapper().fromJson(json.author),
            json.thumbnail_image_url,
            parseDate(json.created_at),
            parseDate(json.updated_at),
            json.published,
            json.featured,
            List(json.tags.map((tag: any) =>
                new ContentTagSummaryMapper().fromJson(tag)
            )),
            new ContentMetaInformationMapper().fromJson(json.meta),
            optional(json.promoted_content_sets, it => List(it.map((set: any) =>
                new PromotedContentSetMapper().fromJson(set)
            ))) || List(),
            new NewContentColumnArticleMapper().fromJson(json.article)
        )
    }
}
