import { Entity } from 'core/entity';

export class ContentColumnArticle extends Entity {
    body: string;
    warning?: string;
    methodology?: string;

    constructor(
        body: string,
        warning?: string,
        methodology?: string
    ) {
        super();
        this.body = body;
        this.warning = warning;
        this.methodology = methodology;
    }
}
