import { trackClickFooter } from 'presentation/module/analytics/events/trackers/footer';
import React from 'react';
import styles from './styles.pcss';

interface FooterMenuLinkProps {
  linkTo: string;
  target: '_self' | '_blank';
  text: string;
  rightLabel?: React.ReactElement;
}

export const FooterMenuLink = ({
  linkTo,
  target,
  text,
  rightLabel = null,
}: FooterMenuLinkProps) => (
  <li className={ styles.siteList }>
    <a 
      className={ styles.link } 
      onClick={ () => {
        trackClickFooter(text);
      } } 
      href={ linkTo } 
      target={ target } 
    >
      { text }
    </a>
    { rightLabel }
  </li>
) ;
