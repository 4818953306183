import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { ContentSummary } from 'core/entity/content/summary';
import { List } from 'immutable';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    contents: List<ContentSummary>;
    gaCategory: string;
}

export const SectorContentsReview = (props: Props) => {
  const toLink = (content: ContentSummary) => {
    if (content instanceof ContentCardComparisonSummary) {
      return BANKSALAD_SITEMAP_BY_ID.CARD_THEME(content.id);
    } else {
      return BANKSALAD_SITEMAP_BY_ID.CONTENT(content.id);
    }
  };

  const sendGA = (content: ContentSummary) => {
    if (content instanceof ContentCardComparisonSummary) {
      sendGAEvent(
        GA_DOMAIN.NONE,
        props.gaCategory,
        GA_ACTION.LINK.CARDS_THEMES_DETAIL,
        `${content.title}_${content.id}`
      );
    } else {
      sendGAEvent(
        GA_DOMAIN.NONE,
        props.gaCategory,
        GA_ACTION.LINK.CONTENTS_DETAIL,
        `${content.title}_${content.id}`
      );
    }
  };

  return (
    <ul className={ styles.wrap }>
      {
        props.contents.map((content, i) =>
          <li key={ `content-d-${i}` }>
            <a
              href={ toLink(content) }
              className={ styles.button }
              onClick={ () => sendGA(content) }
            >
              <div
                style={{ backgroundImage: `url("${content.thumbnailImageUrl}")` }}
                className={ styles.thumbnail }
              />
              <span className={ styles.title }>{ content.title }</span>
              <span className={ styles.author }>
                { content.author.profile.name }
                { 
                  content.author.profile.summary.length > 0 && `·${content.author.profile.summary}` 
                }
              </span>
            </a>
          </li>
        )
      }
    </ul>
  );
};
