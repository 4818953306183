import { Service } from 'core/service';
import { Observable } from 'rxjs/Rx';
import { UserLoanRepositoryType } from 'core/repository/personal-finance/loan';
import { UserLoanCreateSpec } from 'core/entity/personal-finance/loan/create-spec';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserLoanUpdateSpec } from 'core/entity/personal-finance/loan/update-spec';

export class UserLoanService implements Service {
    private repository: UserLoanRepositoryType;

    constructor(repository: UserLoanRepositoryType) {
        this.repository = repository;
    }

    create = (spec: UserLoanCreateSpec): Observable<UserLoan> =>
        this.repository.create(spec);

    update = (id: string, spec: UserLoanUpdateSpec): Observable<UserLoan> =>
        this.repository.update(id, spec);
}
