import { Entity } from 'core/entity';

export class HealthInsuranceCertificateResponse extends Entity {
    updatedAt: Date;

    constructor(
        updatedAt: Date
    ) {
        super();
        this.updatedAt = updatedAt;
    }
}
