/*
 * 참고사항
 * v1/src/js/etc/constants/ga.js 와 동기화 시킬 것
 */
import { FallbackEvent } from "./events/interfaces/fallback";
import { sendTrackEvent } from "./events/send-track-event";
import { EventParam } from "./events/types/event-name";

export enum GA_DOMAIN {
  CARD = '카드',
  LOANS = '대출',
  NONE = ''
}

export const GA_CATEGORY = {
  APP: {
    HEAD: '상단',
    SECURITY: '보안소개',
    FOOT: '하단',
    FLOAT: '플로팅',
  },
  LANDING: {
    HERO: '히어로',
    HERO_BANKSALAD: '히어로_뱅크샐러드',
    HERO_CARD: '히어로_카드',
    HERO_BANK: '히어로_은행',
    HERO_INVESTMENT: '히어로_투자',
    HERO_INSURANCE: '히어로_보험',
    PICK: (name: string) => `뱅크샐러드Pick_${name}`,
    REVIEWS: '경제전문가의금융리뷰',
    REVIEW: (name: string) => `경제전문가의금융리뷰_${name}`,
    TIPS: '금융꿀팁',
    TIP: (name: string) => `부자되는금융꿀팁_${name}`,
    APP_LINK_PLAYSTORE: '앱플레이스토어',
    APP_LINK_APPLESTORE: '앱애플스토어',
    ALLIANCE: '제휴사',
    BANNER_ATL: '배너_ATL',
    LAST_UPDATED: '최신업데이트카드',
  },
  CARDS_BROWSE: {
    RESULT_LIST: '결과리스트',
  },
  CARDS_CARD: {
    RESULT_LIST: '결과리스트',
    FILTER_ANNUAL_COST: '필터_연회비',
    FILTER_CARD_TYPE: '필터_카드종류',
    FILTER_CARD_COMPANY_ALL: '필터_카드사전체',
    FILTER_CARD_TYPE_AND_COMPANY: '필터_카드사_카드종류',
    FILTER_CARD_COMPANY: '필터_카드사',
    FILTER_CARD_CATEGORY: '필터_주요혜택',
  },
  CARDS_HOW_IT_WORKS: {
    HERO: '히어로',
    IN_PROMOTION: '뱅크샐러드는매일연회비할인',
    CONTENTS: '카드발급전꼭봐야할글',
    THEMES: '테마별추천카드',
    EVENTS: '카드사이벤트모니터링',
  },
  CARDS_QUESTIONS: {
    CARD_TYPE: '카드종류',
    BENEFIT_TYPE: '카드용도',
    MONTHLY_SPENDING_AMOUNT: '월평균소비',
    STORE_GROUP_MENU: '업종메뉴',
    STORE_ALERT_SEARCH: '가맹점검색팝업_검색창',
    STORE_ALERT_RESULT: '가맹점검색팝업_검색결과',
    STORE_ALERT_BUTTONS: '가맹점검색팝업_하단버튼',
    STORE_ALERT_SPENDING: (store: string) => `가맹점검색팝업_소비패턴_${store}`,
    SPENDING_STORE: (store: string) => `소비패턴_${store}`,
    SPENDING_STORE_GROUP: (group: string) => `소비패턴그룹_${group}`,
    PREVIEW: '계산결과',
  },
  CARDS_PROFITS: {
    FILTER_CARD_TYPE: '필터_카드종류',
    FILTER_CARD_TYPE_ALL: '필터_카드종류전체',
    FILTER_BENEFIT_TYPE: '필터_혜택종류',
    FILTER_ANNUAL_COST: '필터_연회비',
    FILTER_ANNUAL_COST_PROMOTION: '필터_연회비지원상품보기',
    FILTER_FREE_INTEREST_FEE: '필터_전가맹점무이자할부카드만보기',
    FILTER_CARD_COMPANY_ALL: '필터_카드사전체',
    FILTER_CARD_COMPANY: '필터_카드사',
    FILTER_BRAND_ALL: '필터_브랜드전체',
    FILTER_BRAND: '필터_브랜드',
    FILTER_SPECIAL_SERVICE: '필터_특별서비스',
    COMPARE_CARD: '내카드와비교하기',
    RESULT_LIST: '결과리스트',
    RESULT_CARD: '결과카드',
  },
  CARD_PROFITS: {
    HERO: '히어로',
    BANNER: '프로모션배너',
    BANNER_QUESTION: '카드BEST설문배너',
    RECOMMEND_CARDS: '발급가능카드추천',
    PROFITS: '예상월혜택',
    PROFITS_TABLE: '예상월혜택_계산표',
    DETAIL: '카드정보',
    DETAIL_SPECIAL_SERVICE: '카드정보_특별서비스',
    DETAIL_BENEFITS: '카드정보_혜택정보',
    DETAIL_BENEFITS_DEFAULT: '카드정보_혜택정보_기본',
    DETAIL_BENEFITS_SELECTIVE: '카드정보_혜택정보_선택형',
    DETAIL_BENEFITS_SELECTIVE_SPECIAL: '카드정보_혜택정보_선택형특별',
    DETAIL_BENEFITS_SHINHAN: '카드정보_혜택정보_신한콤보',
    DETAIL_BENEFITS_VOUCHER: '카드정보_혜택정보_바우처',
    DETAIL_BENEFITS_COMPLICATE: '카드정보_혜택정보_혜택준비중',
    DETAIL_BENEFITS_SHINHAN_ALERT: '카드정보_혜택정보_신한콤보팝업',
    FIXED: '고정',
    FIXED_ISSUE: '고정_신청',
    BOTTOM: '카드정보하단',
  },
  CARD_ISSUE_ALERT: {
    ONLINE: '온라인신청팝업',
    CALL: '전화신청팝업',
    PROMOTION: '온라인프로모션신청팝업',
    EUGENE_CMA: '온라인유진CMA신청팝업',
  },
  CARDS_ANNUAL_FEE_PROMOTION: {
    RESULT_LIST: '결과리스트',
    FILTER_COMPANY: '필터_금융사',
    FILTER_SORT: '필터_정렬',
    BEST_CARD_BANNER: 'BEST카드추천배너',
  },
  CARDS_EVENTS: {
    HERO: '히어로',
    FILTER_SORT: '필터_정렬',
    RESULT_EVENT: '결과카드',
  },
  CARDS_EVENT: {
    HERO: '히어로',
    FIXED_ISSUE: '고정_신청',
    SUGGESTED_EVENTS: '추천콘텐츠',
  },
  CARDS_THEMES: {
    HERO: '히어로',
    FEATURED_LIST: '취향저격카드',
    RESULT_LIST: '결과리스트',
  },
  CARDS_THEMES_DETAIL: {
    CARDS: '카드',
    NEWSLETTER: '뉴스레터',
    RELATED_CONTENTS: '연관콘텐츠',
    CARDS_THEMES: '테마별추천카드보기',
  },
  LOANS_QUESTIONS: {
    AMOUNT: '대출금액',
    LIMIT_AMOUNT: '최대대출금액',
    PREVIEW: '계산결과',
    CREDIT_SCORE: '신용등급',
    OCCUPATION: '직업',
    INCOME: '연소득',
    CREDIT_SCORE_ALERT: '신용등급안내팝업',
    INCOME_ALERT: '연속득안내팝업',
  },
  LOANS_PROFITS: {
    AMOUNT: '대출금액',
    LIMIT_AMOUNT: '최대대출금액',
    CREDIT_SCORE: '신용등급',
    REPAYMENT_PERIOD: '상환기간',
    SORT: '필터_정렬',
    FILTER: '필터',
    FILTER_LOAN_TYPE: '필터_대출종류',
    FILTER_ORGANIZATION_TYPE: '필터_금융사',
    FILTER_DAY_LOAN: '필터_당일대출',
    FILTER_EXEMPT_REIMBURSEMENT_FEES: '필터_중도상환수수료면제',
    FILTER_INTEREST_TYPE: '필터_금리방식',
    REPAYMENT_TYPE: '필터_상환방법',
    HISTORIES: '필터_우대금리',
    FILTER_ALERT: '필터안내팝업',
    QUALIFICATION_ALERT: '가입대상안내팝업',
    LIST: '결과리스트',
    CARD: '결과카드',
  },
  LOAN_PROFITS: {
    PROMOTION: '프로모션배너',
    CARD_HEAD: '카드상단',
    CARD_TAB: '카드탭',
    TAB_PROFIT: '예상금리탭',
    TAB_DETAIL: '상세정보탭',
    BOTTOM: '하단',
    FIXED: '고정',
  },
  LOAN_ETC: {
    PROMOTION_ALERT: '프로모션팝업',
  },
  CONTENTS: {
    SORT: '콘텐츠정렬',
    LIST: '콘텐츠결과',
    BOTTOM: '콘텐츠결과하단',
  },
  CONTENTS_AUTHORS: {
    CARD: '집필진카드',
  },
  CONTENTS_DETAIL: {
    FIXED: '고정_타겟광고',
    PROMOTED_CONTENT: '타겟광고',
    BANNER_QUESTION: '카드BEST설문배너',
  },
  SECTORS: {
    HERO: '히어로',
    PICK: '뱅크샐러드Pick',
    REVIEW: '경제전문가의금융리뷰',
    TIP: '부자되는금융꿀팁',
    MAGAZINE: {
      NEW: '최신매거진',
      CONTRIBUTION: '에디팅철학',
      AUTHORS: '집필진소개',
    },
    CARD: {
      LAST_UPDATED: '최신업데이트카드',
      EVERYDAY_PROMOTIONS: '뱅크샐러드는매일연회비할인',
    },
  },
  FOOTER: {
    RECOMMEND_MENU: '추천메뉴',
    RECOMMEND_CONTENT: '추천금융매거진',
    APP_LINK_PLAY_STORE: '앱플레이스토어',
    APP_LINK_APPLE_STORE: '앱애플스토어',
    APP_LINK_CERT: '공인인증센터',
  },
  USER_MY_FINANCE: {
    ASSET: '자산영역',
  },
  USER_TRANSACTIONS: {
    HEADER: '상단메뉴',
    FILTER: '필터',
    GENERATOR_ALERT: '내역생성팝업',
    LIST_VIEW: '내역뷰',
    CALENDAR_VIEW: '달력뷰',
    LIST_VIEW_UNDER_CALENDAR: '달력하단내역뷰',
  },
  USER: {
    SUSPENDED: '계정정지',
  },
  APP_ALERT: '앱링크팝업',
  BRIDGE_PAGE: {
    FLOATING: '플로팅_앱브릿지페이지',
  },
  // 신규는 이 아래 추가합니다. (위는 기존 이벤트와 겹칠 확률이 높음)
  SECTOR_CARD_HERO: 'sector_카드_히어로',
  MAIN_ROLLING_BANNER_HERO: 'main_롤링배너_히어로',
};

export const GA_ACTION = {
  LINK: {
    HOME: '이동_홈',
    LOGIN: '이동_로그인',
    APP: '이동_앱',
    CERT_CENTER: '이동_공인인증센터',
    SAFETY_CENTER: '이동_고객안심센터',
    USER_SUSPEND: '뱅샐머니(송금)이용정지',
    OUTBOUND: '이동_외부',

    PFM_MY_FINANCE: '이동_유저MY금융',
    PFM_TRANSACTIONS: '이동_유저내역',
    PFM_SETTINGS: '이동_유저개인설정',

    CARDS_HOW_IT_WORKS: '이동_카드랜딩',
    CARDS_QUESTIONS: '이동_카드BEST설문',
    CARDS_PROFITS: '이동_카드BEST결과',
    CARDS_DETAIL_PROFITS: '이동_카드BEST상세',
    CARDS: '이동_카드업종별',
    CARDS_DETAIL: '이동_카드상세',
    CARDS_PROMOTION_ANNUAL_FEE: '이동_카드연회비이벤트',
    CARDS_EVENTS: '이동_카드이벤트',
    CARDS_EVENTS_DETAIL: '이동_카드이벤트상세',
    CARDS_THEMES: '이동_카드테마별',
    CARDS_THEMES_DETAIL: '이동_카드테마별상세',
    CARDS_PROMOTIONS_BEST_CREDIT_CARDS: '이동_카드프로모션리스트',

    LOANS_QUESTIONS: '이동_대출BEST설문',
    LOANS_PROFITS: '이동_대출BEST결과',
    LOANS_DETAIL_PROFITS: '이동_대출BEST상세',
    LOANS_DETAIL: '이동_대출상세',
    LOANS_IS_MY_SIDE: '이동_금융매칭',

    INSURANCES_HOW_IT_WORKS: '이동_보험랜딩',

    CONTENTS: '이동_매거진랜딩',
    CONTENTS_DETAIL: '이동_매거진상세',
    CONTENTS_CATEGORIES: '이동_매거진카테고리',
    CONTENTS_COLUMN: '이동_매거진칼럼',
    CONTENTS_AUTHORS: '이동_매거진기고자',
    CONTENTS_AUTHORS_DETAIL: '이동_매거진기고자상세',
    CONTENTS_CONTRIBUTION: '이동_매거진기고자모집',

    SECTORS_CARD: '이동_신용카드섹터',
    SECTORS_BANK: '이동_은행섹터',
    SECTORS_INVESTMENT: '이동_투자섹터',
    SECTORS_LOAN: '이동_신용대출섹터',
    SECTORS_HEALTH: '이동_무료건강검사섹터',
    SECTORS_ARTICLE: '이동_돈이되는금융소식섹터',
    SECTORS_MORTGAGE: '이동_주택담보섹터',
    SECTORS_SAVING: '이동_예적금섹터',
    SECTORS_ANNUITY: '이동_연금섹터',
    SECTORS_FINTECH: '이동_핀테크섹터',
    SECTORS_MAGAZINE: '이동_매거진섹터',

    APP_INTRODUCE: '이동_공인인증센터',
    APP_DOWNLOAD: '이동_onelink',

    URL: (url: string | number) => `이동_${url}`,
  },
  POPUP: '팝업',
  POPUP_ISSUE: '팝업_신청',
  ISSUE: '신청',
  SCROLL: '스크롤',
  DROP_DOWN: '드롭다운',
  CHECKBOX: '체크박스',
  RADIO: '라디오',
  INPUT: '직접입력',
  INPUT_SHORTCUT: '쉬운입력',
  CLEAR: '초기화',
  MORE: '더보기',
  NEXT: '다음',
  PREV: '이전',
  RANGE: '범위',
  TAB: '탭',
  COPY: '복사',
  MAIL: '메일',
  PRODUCT_IMPRESSION: '상품노출',
  FOLDER: '폴더',
  ALL: '전체선택',
  SIGN_OUT: '로그아웃',
  DELETE: '삭제',
  UPDATE: '수정',
  SORT: '정렬',
  SAVE: '저장',
  CLOSE: '닫기',
};

export const GA_LABEL = {
  ISSUE: (word: string, company: string, id: number | string) =>
    `${word}_${company}_${id}`,
  PRODUCT_DETAIL: (word: string, company: string, id: number | string) =>
    `${word}_${company}_${id}`,
  DETAIL: (word: string, id: number | string) =>
    `${word}_${id}`,
  PRODUCT_IMPRESSION: (id: number, companyName: string, order: number) =>
    `${id}_${companyName}_${order}`,
};

export const GA_EVENT_VALUE = {
  CHECKED: 1,
  UNCHECKED: 0,
  IN_PROMOTION: 1,
  NOT_IN_PROMOTION: 0,
  OPEN: 1,
  CLOSE: 0,
};

export const sendGAEvent = (domain: GA_DOMAIN, category: string, action: string, label = '', event?: number) => {
  const formattedAction = action.replace(/ /g, '');
  const formattedLabel = label.replace(/ /g, '');
  const combinedCategory = domain === GA_DOMAIN.NONE ?
    category :
    `${domain.toString()}:${category}`;
  // TODO: just test customized facebook pixel tracker like ga
  fbq && fbq('trackCustom', formattedAction, { category, label: formattedLabel, eventValue: event } as any);
  sendTrackEvent<FallbackEvent, string>({ 
    eventParam: {
      event_type: `${formattedAction}_${combinedCategory}`,
      action: formattedAction,
      category: combinedCategory,
      label: formattedLabel,
      path_url: window.location.pathname,
      value: event
    }
  });
};

interface Props<Type> {
  eventType: Type;
  eventParam?: EventParam;
}

export const sendGA4Event = <Type>({ eventType, eventParam }: Props<Type>) => {
  if (window.gtag !== null) {
    window.gtag('event', eventType, {
      event_category: eventParam?.current_screen,
      event_label: getLabel(eventParam),
    });
  };
};

/**
 * GA 및 Pixel 에 넘겨주는 label 값을 정의합니다.
 * 관련 문서 : https://docs.google.com/document/d/1zTFTXg084xfFaKNGMEFZ9jz6oERxkj25Nk7e7Tol5Mo
 */
export const getLabel = (eventParam?: EventParam) => {
  if (eventParam === undefined) {
    return undefined;
  }

  if (eventParam.object_organization && eventParam.object_name && eventParam.object_id) {
    return `${eventParam.object_organization}_${eventParam.object_name}_${eventParam.object_id}`;
  }

  return JSON.stringify(eventParam);
};

interface DataLayer {
  push: (input: any) => boolean;
}
declare const dataLayer: DataLayer;
export const activateOptimize = () =>
  dataLayer && dataLayer.push({ event: 'optimize.activate' });
