import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { Amount } from 'core/entity/amount';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';

export class AdviceCashFlowData extends Entity implements AdviceContentData {
    totalIncome: Amount;
    totalExpense: Amount;
    message?: AdviceMessage;

    constructor(
        totalIncome: Amount,
        totalExpense: Amount,
        message: AdviceMessage = null
    ) {
        super();
        this.totalIncome = totalIncome;
        this.totalExpense = totalExpense;
        this.message = message;
    }
}
