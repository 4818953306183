import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardSpending } from 'core/entity/card/spending';
import { CardAmount } from 'core/entity/card/amount';
import { CardBenefit } from 'core/entity/card/benefit';

export class CardProfit extends Entity {
    spending: CardSpending;
    amount: CardAmount;
    benefits: List<CardBenefit>;

    constructor(
        spending: CardSpending,
        amount: CardAmount,
        benefits: List<CardBenefit>
    ) {
        super();
        this.spending = spending;
        this.amount = amount;
        this.benefits = benefits;
    }
}
