import { Entity } from 'core/entity';
import { List } from 'immutable';
import { FinanceSubSectorItem } from 'core/entity/finance-sector/item';

export class FinanceSubSector extends Entity {
    name: string;
    items: List<FinanceSubSectorItem>;

    constructor(
        name: string,
        items: List<FinanceSubSectorItem>
    ) {
        super();
        this.name = name;
        this.items = items;
    }
}
