import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { AdviceBudgetData } from 'core/entity/personal-finance/advice/content-data/budget';
import { lets, optional } from 'utils/index';
import { AmountMapper } from 'data/http/mapper/amount';
import { AdviceRecommendedExpenseAmountMapper } from 'data/http/mapper/personal-finance/advice/recommended-expense-amount';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceBudgetDataMapper implements NetworkResponseMapper<AdviceBudgetData> {
    fromJson(json: any): AdviceBudgetData {
        return new AdviceBudgetData(
            json.age_group,
            lets(json.sex, it => {
                switch (it) {
                    case 'male':
                        return AdviceBudgetData.Sex.MALE;
                    case 'female':
                        return AdviceBudgetData.Sex.FEMALE;
                    default:
                        throw new MappingError();
                }
            }),
            new AmountMapper().fromJson(json.total_expense),
            optional(json.recommended_expense_amount, new AdviceRecommendedExpenseAmountMapper().fromJson),
            optional(json.total_budget, new AmountMapper().fromJson),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
