import { Entity } from 'core/entity';
import {
    IncomeTaxDeductionConfiguration
} from 'core/entity/personal-finance/transaction/income-tax-deduction-configuration';
import { InstallmentConfiguration } from 'core/entity/personal-finance/transaction/installment/configuration';

export class ExpenseTransactionConfiguration extends Entity {
    excludedFromAnalysis: boolean;
    excludedFromBudget: boolean;
    incomeTaxDeduction: IncomeTaxDeductionConfiguration;
    installment?: InstallmentConfiguration;

    constructor(
        excludedFromAnalysis: boolean,
        excludedFromBudget: boolean,
        incomeTaxDeduction: IncomeTaxDeductionConfiguration,
        installment: InstallmentConfiguration = null,
    ) {
        super();
        this.excludedFromAnalysis = excludedFromAnalysis;
        this.excludedFromBudget = excludedFromBudget;
        this.incomeTaxDeduction = incomeTaxDeduction;
        this.installment = installment;
    }
}
