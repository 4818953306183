import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV11PrivacyPolicyView } from 'presentation/routes/policies/v11/privacy-policy/view';

export const PoliciesV11PrivacyPolicyRoute = () => (
    <>
        <MetaHelmet meta={ META_SET.PRIVACY_POLICY }/>
        <PoliciesV11PrivacyPolicyView />
    </>
);
