import React from 'react';

import styles from '../../../../styles.pcss';

export const PoliciesV1ThirdPartiesEightPercentSubmitDocumentView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h2>개인정보 제3자 제공 동의</h2>
      <h3>서류간소화 서비스 제공을 위하여 아래와 같이 개인정보 제3자 제공 동의를 받고자 합니다.</h3>
      <br/>
      <ol>
        <li>1. 개인정보를 제공받는 자 : 주식회사 에잇퍼센트</li>
        <li>
                2. 제공하는 개인정보 항목
          <ol>
            <li>1) 건강보험 납부 확인서 : 이름, 생년월일, 납부자번호, 건강보험료, 장기요양보험료</li>
            <li>2) 건강보험 자격득실 확인서 : 이름, 생년월일, 성별, 직장명, 건강보험 자격취득일 및 자격상실일</li>
          </ol>
        </li>
        <li>3. 개인정보 보유 및 이용 기간 : 대출신청 후 목적 달성 시</li>
        <li>4. 개인정보 제공 목적 : 대출 심사</li>
      </ol>
      <br/>
      <p>개인정보 제공 동의를 거부할 권리가 있으며, 동의 거부 시 서류간소화 서비스를 이용하실 수 없습니다.</p>
    </div>
  </div>
);
