import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanBaseInterest } from 'core/entity/loan/base-interest';
import { LoanPeriodMapper } from 'data/http/mapper/loan/period';
import { LoanInterestTypeMapper } from 'data/http/mapper/loan/interest-type';
import { LoanInterestValueMapper } from 'data/http/mapper/loan/interest-value';

export class LoanBaseInterestMapper implements NetworkResponseMapper<LoanBaseInterest> {
    fromJson(json: any): LoanBaseInterest {
        return new LoanBaseInterest(
            new LoanInterestValueMapper().fromJson(json.interest),
            new LoanPeriodMapper().fromJson(json.period),
            new LoanInterestTypeMapper().fromJson(json.type)
        )
    }

}
