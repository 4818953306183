import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreInquiryRecord } from 'core/entity/personal-credit/inquery-record';
import { parseDate } from 'utils/index';
import { UserCreditScoreOrganizationMapper } from 'data/http/mapper/personal-credit/organization';

export class UserCreditScoreInquiryRecordMapper implements NetworkResponseMapper<UserCreditScoreInquiryRecord> {
    fromJson(json: any): UserCreditScoreInquiryRecord {
        return new UserCreditScoreInquiryRecord(
            new UserCreditScoreOrganizationMapper().fromJson(json.organization),
            json.purpose,
            parseDate(json.inquired_at)
        )
    }

}
