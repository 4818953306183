import React from 'react';
import { EventBus } from 'presentation/bus';
import { ToastEvent } from 'presentation/bus/event/toast-event';
import { SubscriptionBag } from 'presentation/module/extension';

import styles from './styles.pcss';

interface State {
    show: boolean,
    message: string
}

export class Toast extends React.Component<{}, State> {
    state = {
        show: false,
        message: '',
    };

    private toastTimer: number;
    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        EventBus.toObservable().subscribe(
            (event: Event) => {
                if (event instanceof ToastEvent) {
                    window.clearTimeout(this.toastTimer);

                    this.setState({
                        show: true,
                        message: event.message
                    }, () => {
                        this.toastTimer = window.setTimeout(() => {
                            this.setState({ show: false });
                        }, 3000);
                    })
                }
            }
        ).unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
        window.clearTimeout(this.toastTimer);
    }

    render() {
        const { show, message } = this.state;

        return (
            <div className={ show ? styles.toast : styles.hiddenToast }>
                <p dangerouslySetInnerHTML={{ __html: message }} />
                <button className={ styles.close } onClick={ this.onClear }>닫기</button>
            </div>
        );
    }

    private onClear = () => {
        window.clearTimeout(this.toastTimer);
        this.setState({ show: false });
    }
}
