import { AccessToken } from 'src/core/entity/user/access-token';
import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { MedicalCheckupSummary } from 'core/entity/medical-checkup/summary';
import { MedicalCheckupSummaryMapper } from 'data/http/mapper/medical-checkup/summary';

export class MedicalCheckUpResultApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<MedicalCheckupSummary> {
        return this.requestGET(
            '/api/v2/me/medical-checkups/results',
            null,
            { Authorization: accessToken.accessToken },
        ).map((result: any) => {
            return new MedicalCheckupSummaryMapper().fromJson(result);
        })
    }
}
