import { NetworkResponseMapper } from 'data/http/mapper';
import { ComparableCardCompany } from 'core/entity/comparable-card/company';

export class ComparableCardCompanyMapper implements NetworkResponseMapper<ComparableCardCompany> {
    fromJson(json: any): ComparableCardCompany {
        return new ComparableCardCompany(
            json.id,
            json.name,
            json.image_url
        )
    }
}
