import { Card } from 'core/entity/card';
import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { CardCFAReview } from 'core/entity/legal-configuration/card/cfa/review';
import { CardCFAReviewInProgress } from 'core/entity/legal-configuration/card/cfa/review/in-progress';
import { CardCFAReviewNoReviewRequired } from 'core/entity/legal-configuration/card/cfa/review/no-review-required';
import { CardCFAReviewReviewed } from 'core/entity/legal-configuration/card/cfa/review/reviewed';
import React from 'react';

import styles from '../styles.pcss';

interface Props {
    config?: CardLegalConfiguration;
    cardType?: Card.Type;
}

export const CardLegalTerms = ({
  config,
  cardType = Card.Type.CREDIT,
}: Props) => {
  const reviewer = cardType === Card.Type.CHECK ? '준법감시인' : '여신금융협회';

  const renderStatus = (review: CardCFAReview) => {
    if (review instanceof CardCFAReviewInProgress) {
      return `${reviewer} 심의필 : 심의번호 기재예정`;
    } else if (review instanceof CardCFAReviewNoReviewRequired) {
      return '이 카드의 혜택 정보는 카드사와 무관하게 뱅크샐러드가 객관적으로 수집 및 분류하여 투명하게 제공하고 있습니다.';
    } else if (review instanceof CardCFAReviewReviewed) {
      return `${reviewer} 심의필 : ${review.number}`;
    } else {
      return null;
    }
  };

  return !!config ? (
    <div className={ styles.terms }>
      <div dangerouslySetInnerHTML={{ __html: config.cfa.description.message }} />
      <ul>
        { config && <li><b>{ renderStatus(config.cfa.review) }</b></li> }
      </ul>
    </div>
  ) : (
    <div className={ styles.terms }>
      <ul>
        <li>카드사 및 제휴사의 사정으로 위 내용은 변경·중단 될 수 있습니다.</li>
        <li>카드 이용 전에 상품설명서, 약관을 통해 이용조건을 확인해 주시기 바랍니다.</li>
        <li><b>신용카드 남용은 가계경제에 위협이 됩니다.</b></li>
        <li>{`${reviewer} 심의필 : 심의번호 기재예정`}</li>
      </ul>
    </div>
  );
};
