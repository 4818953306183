import { SummarizedUserCardBills } from 'core/entity/personal-finance/summarized-card-bills';
import { HttpProvider } from 'data/http';
import { SummarizedUserCardBillsMapper } from 'data/http/mapper/personal-finance/summarized-user-card-bills';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { toDateString } from 'utils/index';

export class SummarizedUserCardBillsApiProvider extends HttpProvider {
    get(accessToken: AccessToken, startDate: Date, endDate: Date): Observable<SummarizedUserCardBills> {
        return this.requestGET(
            'api/v2/me/cards/summarized-bills',
            { start_date: toDateString(startDate, '-'), end_date: toDateString(endDate, '-') },
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new SummarizedUserCardBillsMapper().fromJson(result.data)
        )
    }

}
