import { Entity } from 'core/entity';
import { List } from 'immutable';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { UserFund } from 'core/entity/personal-finance/fund';
import { UserStock } from 'core/entity/personal-finance/stock';
import { SpiderSecuritiesFirmSummary } from 'core/entity/personal-finance/securities-firm/summary';

export class SummarizedUserSecuritiesFirmProducts extends Entity {
    securitiesFirm: SpiderSecuritiesFirmSummary;
    accounts: List<UserSecuritiesFirmAccount>;
    funds: List<UserFund>;
    stocks: List<UserStock>;

    constructor(
        securitiesFirm: SpiderSecuritiesFirmSummary,
        accounts: List<UserSecuritiesFirmAccount>,
        funds: List<UserFund>,
        stocks: List<UserStock>
    ) {
        super();
        this.securitiesFirm = securitiesFirm;
        this.accounts = accounts;
        this.funds = funds;
        this.stocks = stocks;
    }
}
