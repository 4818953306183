import { Entity } from 'core/entity';
import { LoanPrimeInterestNodeStatusType } from 'core/entity/loan/prime-interest-node-status-type';
import { LoanInterestValue } from 'core/entity/loan/interest-value';

export class LoanPrimeInterestNodeStatus extends Entity {
    type: LoanPrimeInterestNodeStatusType;
    potentialRate?: LoanInterestValue;

    constructor(
        type: LoanPrimeInterestNodeStatusType,
        potentialRate: LoanInterestValue = null
    ) {
        super();
        this.type = type;
        this.potentialRate = potentialRate;
    }
}
