import { Entity } from 'core/entity';

export class PersonalizedLoanMonthlyRepayment extends Entity {
    repaymentMoney: number;
    type: PersonalizedLoanMonthlyRepayment.Type;

    constructor(
        repaymentMoney: number,
        type: PersonalizedLoanMonthlyRepayment.Type
    ) {
        super();
        this.repaymentMoney = repaymentMoney;
        this.type = type;
    }
}

export module PersonalizedLoanMonthlyRepayment {
    export enum Type {
        IOL = 'iol',
        CAM = 'cam',
        CPM = 'cpm'
    }
}
