import { Entity } from 'core/entity';

export class AdviceType extends Entity {
    constructor(
        public id: AdviceTypeId,
        public rawValue: string
    ) {
        super();
    }
}

export enum AdviceTypeId {
    MONTHLY_REPORT = 'monthly_report',
    WEEKLY_REPORT = 'weekly_report',
    CARD_INSTALLMENT_SUMMARY = 'card_installment_summary',
    EXCESSIVE_SPENDING_ALERT_LEVEL_1 = 'excessive_spending_alert_level_1',
    EXCESSIVE_SPENDING_ALERT_LEVEL_2 = 'excessive_spending_alert_level_2',
    EXCESSIVE_SPENDING_ALERT_LEVEL_3 = 'excessive_spending_alert_level_3',
    REDUCED_SPENDING_ALCOHOL = 'reduced_spending_alcohol',
    LATE_NIGHT_SPENDING_ALCOHOL = 'late_night_spending_alcohol',
    EXCESSIVE_SPENDING_TAXI = 'excessive_spending_taxi',
    REDUCED_SPENDING_TAXI = 'reduced_spending_taxi',
    LATE_NIGHT_INTERNET_SHOPPING = 'late_night_internet_shopping',
    EXCESSIVE_SPENDING_CAFE = 'excessive_spending_cafe',
    CUSTOM = 'custom',
    MONTHLY_CARD_BENEFIT_REPORT_LEVEL_1 = 'monthly_card_benefit_report_level_1',
    MONTHLY_CARD_BENEFIT_REPORT_LEVEL_2 = 'monthly_card_benefit_report_level_2',
    MONTHLY_CARD_BENEFIT_REPORT_LEVEL_3 = 'monthly_card_benefit_report_level_3',
    MONTHLY_CARD_BENEFIT_REPORT_LEVEL_4 = 'monthly_card_benefit_report_level_4',
    WELCOME = 'welcome',
    CREDIT_SCORE_INQUIRY_SUGGESTION = 'credit_score_inquiry_suggestion'
}
