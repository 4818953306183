import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { Loan } from 'core/entity/loan';
import { LoanRepositoryType } from 'core/repository/loan';

export class GetServerRenderedLoan extends UseCase<Loan> {
    private repository: LoanRepositoryType;

    constructor(repository: LoanRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<Loan> {
        return this.repository.getServerRenderedLoan();
    }

    protected validate(): boolean {
        return true;
    }

}
