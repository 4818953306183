import React from 'react';
import Rx from 'rxjs/Rx';
import { List } from 'immutable';
import { chunk } from 'lodash';

import {
    SelectorModel,
    SelectorTheme
} from 'presentation/components/selector/model';
import { uniqueKey, lets } from 'utils/index';
import { EventBus } from 'presentation/bus';
import { WindowResizeEvent } from 'presentation/bus/event/window-resize-event';
import { RESPONSIVE } from 'presentation/components/responsive';

import styles from './styles.pcss';

interface Props {
    data: List<SelectorModel>,
    chunkSize: number,
    chunkSizeForTablet?: number,
    chunkSizeForMobile?: number,
    theme: SelectorTheme,
    onChange?: (selected: SelectorModel) => void
}

interface State {
    tab: SelectorModel,
    chunkSize: number
}

export class Selector extends React.Component<Props, State> {
    state = {
        tab: this.props.data.first(),
        chunkSize: this.generateChunkSize(window.innerWidth)
    };

    private eventBus: Rx.Subscription = EventBus.toObservable().subscribe(
        (event: Event) => {
            if (event instanceof WindowResizeEvent) {
                const chunkSize =
                    this.generateChunkSize((event as WindowResizeEvent).width);
                if (this.state.chunkSize !== chunkSize) {
                    this.setState({ chunkSize });
                }
            }
        }
    );

    componentWillUnmount() {
        this.eventBus.unsubscribe();
    }

    render() {
        return (
            <ul className={ styles.tabs }>
                { this.renderRow() }
            </ul>
        )
    }

    private renderRow() {
        const {
            data,
            theme
        } = this.props;

        const {
            chunkSize
        } = this.state;

        const themeStyle = lets(theme, it => {
            switch (it) {
                case SelectorTheme.ROMAINE:
                    return styles.romaine;
                case SelectorTheme.BLUEBERRY:
                    return styles.blueberry;
                default:
                    return styles.blueberry;
            }
        });

        return chunk(data.toArray(), chunkSize).map(group =>
            <li key={ uniqueKey() } className={ styles.tab }>
                <table><tbody><tr>
                    {
                        group.map(item =>
                            <td
                                key={ uniqueKey() }
                                style={{ width: `${100 / group.length}%` }}
                                className={ this.state.tab.id === item.id ? themeStyle : styles.item }
                            >
                                <a
                                    onClick={ () => this.onChange(item) }
                                    className={ styles.button }
                                >
                                    { item.title }
                                    {
                                        item.subtitle &&
                                        <small>{ item.subtitle }</small>
                                    }
                                </a>
                            </td>
                        )
                    }
                </tr></tbody></table>
            </li>
        )
    }

    private onChange(tab: SelectorModel) {
        const { onChange } = this.props;
        onChange && onChange(tab);
        this.setState({ tab });
    }

    private generateChunkSize(width: number) {
        if (width <= RESPONSIVE.MOBILE && this.props.chunkSizeForMobile) {
            return this.props.chunkSizeForMobile;
        } else if (width <= RESPONSIVE.TABLET && this.props.chunkSizeForTablet) {
            return this.props.chunkSizeForTablet;
        }
        return this.props.chunkSize;
    }
}
