import React from 'react';

import { FetchState } from 'presentation/view-model/fetch-state';
import { CardInfo } from 'presentation/components/info/card';
import { Loader } from 'presentation/components/loader';
import { FetchError } from 'presentation/components/fetch-state/error';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { isForDiscount } from 'presentation/module/sugar';
import { CardInfoProfitsModel } from 'presentation/components/info/card/profits/model';
import { Card } from 'core/entity/card';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { Footer } from 'presentation/components/footer';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { Header } from 'presentation/components/header';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';

interface Props {
    cardId: number;
}

interface State {
    cardFetchState: FetchState,
    legalConfigFetchState: FetchState;
    card?: Card,
    legalConfig?: CardLegalConfiguration,
    profits?: CardInfoProfitsModel
}

export class CardDetailProfitsView extends React.Component<Props, State> {
    state = {
        cardFetchState: FetchState.FETCHING,
        legalConfigFetchState: FetchState.FETCHING,
        card: null as Card,
        legalConfig: null as CardLegalConfiguration,
        profits: null as CardInfoProfitsModel,
        forDiscount: false
    };

    private subscriptionBag = new SubscriptionBag();

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.fetch();
        this.fetchLegalConfig();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const {
            card,
            legalConfig,
            profits,
            cardFetchState,
            legalConfigFetchState
        } = this.state;

        return (
            <>
            { card && <MetaHelmet meta={ META_SET.CARD(card) }/> }
            <Header active={ FinanceSectorID.CARD } />
            {
                (cardFetchState === FetchState.FETCHED && legalConfigFetchState === FetchState.FETCHED) ? (
                    <CardInfo
                        card={ card }
                        legalConfig={ legalConfig }
                        profits={ profits }
                    />
                ) : (cardFetchState === FetchState.FETCHING || legalConfigFetchState === FetchState.FETCHING) ? (
                    <Loader padding={ 250 } radius={ 25 } />
                ) : (
                    <FetchError padding={ 250 }>
                        카드정보를 불러오는데 문제가 발생했습니다<br/>
                        잠시 후 새로고침 해주세요
                    </FetchError>
                )
            }
            <Footer />
            </>
        )
    }

    private fetch = async () => {
        const { cardId } = this.props;

        await this.setState({
            cardFetchState: FetchState.FETCHING
        });

        Application.useCases.getCachedCardRecommendSpec
            .runOnAnimateFrame()
            .subscribe(
                spec => this.fetchCompute(spec),
                () => {
                    location.href = BANKSALAD_SITEMAP_BY_ID.CARD(cardId)
                }
            )
            .unsubscribeBy(this.subscriptionBag);
    };

    private fetchCompute = (spec: CardRecommendSpec) => {
        const { cardId } = this.props;

        Application.services.card
            .computeBenefits(cardId, spec)
            .useOnAnimateFrame()
            .subscribe(
                result => {
                    sendPixelEvent(PIXEL.CARD, `카드_${result.card.companyName}`);
                    this.setState({
                        cardFetchState: FetchState.FETCHED,
                        card: result.card,
                        profits: new CardInfoProfitsModel(
                            result.profits,
                            spec.spendings.filter(spending =>
                                !result.profits.map(it => it.spending.storeId).contains(spending.storeId)
                            ).toList(),
                            result.summary,
                            result.card.annualCostWithPromotion,
                            result.card.maximumAnnualCost,
                            isForDiscount(spec.benefitTypes),
                            spec.spendings.size,
                            result.card.isInPromotion()
                        )
                    });
                },
                () =>
                    this.setState({
                        cardFetchState: FetchState.ERROR
                    })
            )
            .unsubscribeBy(this.subscriptionBag);
    };

    private fetchLegalConfig = () => {
        const { cardId } = this.props;

        Application.services.legalConfig.getCardLegalConfigByCardId(cardId)
            .useOnAnimateFrame()
            .subscribe(
                legalConfig =>
                    this.setState({
                        legalConfig,
                        legalConfigFetchState: FetchState.FETCHED
                    }),
                () =>
                    this.setState({
                        legalConfigFetchState: FetchState.FETCHED
                    })
            )
            .unsubscribeBy(this.subscriptionBag);
    }
}
