import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderSecuritiesFirmAccountData } from 'core/entity/personal-finance/spider-data/securities-firm-account';
import { optional } from 'utils/index';

export class SpiderSecuritiesFirmAccountDataMapper implements NetworkResponseMapper<SpiderSecuritiesFirmAccountData> {
    fromJson(json: any): SpiderSecuritiesFirmAccountData {
        return new SpiderSecuritiesFirmAccountData(
            json.name,
            optional(json.response_contained)
        )
    }

}
