import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceCardCompanyInstallmentSnapshot } from 'core/entity/personal-finance/advice/card-company-installment-snapshot';
import { SpiderCardCompanySummaryMapper } from 'data/http/mapper/personal-finance/card-company/summary';
import { AmountMapper } from 'data/http/mapper/amount';
import { AdviceTransactionSnapshotMapper } from 'data/http/mapper/personal-finance/advice/transaction-snapshot';

export class AdviceCardCompanyInstallmentSnapshotMapper implements
    NetworkResponseMapper<AdviceCardCompanyInstallmentSnapshot>
{
    fromJson(json: any): AdviceCardCompanyInstallmentSnapshot {
        return new AdviceCardCompanyInstallmentSnapshot(
            new SpiderCardCompanySummaryMapper().fromJson(json.company),
            new AmountMapper().fromJson(json.installment_amount),
            json.transactions.map(new AdviceTransactionSnapshotMapper().fromJson).toList()
        )
    }

}
