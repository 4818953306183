import { Entity } from 'core/entity';
import { List } from 'immutable';
import { LoanInterviewHistory } from 'core/entity/loan/interview-history';
import { LoanOccupationalGroup } from 'core/entity/loan/occupational-group';

export class LoanSurveyConfiguration extends Entity {
    histories: List<LoanInterviewHistory>;
    occupationalGroups: List<LoanOccupationalGroup>;

    constructor(
        histories: List<LoanInterviewHistory>,
        occupationalGroups: List<LoanOccupationalGroup>
    ) {
        super();
        this.histories = histories;
        this.occupationalGroups = occupationalGroups;
    }
}
