import { Observable } from 'rxjs/Rx';
import { UseCase } from 'core/use-case';
import { CardRecommendRepositoryType } from 'core/repository/card-recommend';

export class ClearCachedCardRecommendSpec extends UseCase<void> {
    private repository: CardRecommendRepositoryType;

    constructor(repository: CardRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<void> {
        return this.repository.clearCachedSpec();
    }

    protected validate(): boolean {
        return true;
    }
}
