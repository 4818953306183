import { Entity } from 'core/entity';
import { LoanOrganization } from 'core/entity/loan/organization';
import { LoanDescriptionsSummary } from 'core/entity/loan/descriptions/summary';
import { LoanInterestPromotion } from 'core/entity/loan/interest-promotion';

export class LoanSummary extends Entity {
    slug: string;
    name: string;
    organization: LoanOrganization;
    desktopIssueUrl: string;
    mobileIssueUrl: string;
    updatedAt: Date;
    dayLoan: boolean;
    generalCredit: boolean;
    middleClass: boolean;
    exemptReimbursementFees: boolean;
    minusAccount: boolean;
    descriptions: LoanDescriptionsSummary;
    interestPromotion?: LoanInterestPromotion;

    constructor(
        slug: string,
        name: string,
        organization: LoanOrganization,
        desktopIssueUrl: string,
        mobileIssueUrl: string,
        updatedAt: Date,
        dayLoan: boolean,
        generalCredit: boolean,
        middleClass: boolean,
        exemptReimbursementFees: boolean,
        minusAccount: boolean,
        descriptions: LoanDescriptionsSummary,
        interestPromotion: LoanInterestPromotion = null
    ) {
        super();
        this.slug = slug;
        this.name = name;
        this.organization = organization;
        this.desktopIssueUrl = desktopIssueUrl;
        this.mobileIssueUrl = mobileIssueUrl;
        this.updatedAt = updatedAt;
        this.dayLoan = dayLoan;
        this.generalCredit = generalCredit;
        this.middleClass = middleClass;
        this.exemptReimbursementFees = exemptReimbursementFees;
        this.minusAccount = minusAccount;
        this.descriptions = descriptions;
        this.interestPromotion = interestPromotion;
    }
}
