import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceTransactionPeriodSnapshot } from 'core/entity/personal-finance/advice/transaction-period-snapshot';
import { AdviceTransactionSnapshotMapper } from 'data/http/mapper/personal-finance/advice/transaction-snapshot';
import { parseDate } from 'utils/index';

export class AdviceTransactionPeriodSnapshotMapper implements NetworkResponseMapper<AdviceTransactionPeriodSnapshot> {
    fromJson(json: any): AdviceTransactionPeriodSnapshot {
        return new AdviceTransactionPeriodSnapshot(
            json.transactions.map(new AdviceTransactionSnapshotMapper().fromJson).toList(),
            parseDate(json.start_datetime),
            parseDate(json.end_datetime)
        )
    }

}
