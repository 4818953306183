import { NetworkResponseMapper } from 'data/http/mapper';
import { CardConditionalRestriction } from 'core/entity/card/conditional-restriction';

export class CardConditionalRestrictionMapper implements NetworkResponseMapper<CardConditionalRestriction> {
    fromJson(json: any): CardConditionalRestriction {
        return new CardConditionalRestriction(
            json.condition,
            json.restriction
        )
    }
}
