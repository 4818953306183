import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { CardFilter } from 'core/entity/card/filter';
import { apply, lets, optional } from 'utils/index';
import { CardBrandMapper } from 'data/browser-storage/mapper/card/brand';
import { Card } from 'core/entity/card';
import { List } from 'immutable';

export class CardFilterMapper implements BrowserStorageMapper<CardFilter> {
    toEntity(json: any): CardFilter {
        return apply(new CardFilter(), it => {
            it.cardIds = optional(json.cardIds, it => List(it));
            it.name = json.name;
            it.contractedOnly = json.contractedOnly;
            it.unstoppedOnly = json.unstoppedOnly;
            it.annualCostMin = json.annualCostMin;
            it.annualCostMax = json.annualCostMax;
            it.promotionOnly = json.promotionOnly;
            it.brands = optional(json.brands, it => List(it.map((brand: any) =>
                new CardBrandMapper().toEntity(brand)
            )));
            it.companies = optional(json.companies, it => List(it));
            it.specialBenefitIds = optional(json.specialBenefitIds, it => List(it));
            it.cardTypes = optional(json.cardTypes, it => List(it.map((type: any) =>
                lets(type, cardType => {
                    switch (cardType) {
                        case 'credit':
                            return Card.Type.CREDIT;
                        case 'check':
                            return Card.Type.CHECK;
                        case 'hybrid':
                            return Card.Type.HYBRID;
                        default:
                            return Card.Type.CREDIT;
                    }
                })
            )));
            it.channel = lets(json.channel, it => {
                switch (it) {
                    case 'banksalad':
                        return CardFilter.Channel.BANKSALAD;
                    case 'interpark':
                        return CardFilter.Channel.INTERPARK;
                    default:
                        return CardFilter.Channel.BANKSALAD;
                }
            });
        });
    }

    toJson(target: CardFilter): any {
        return {
            cardIds: optional(target.cardIds, it => it.toArray()),
            name: target.name,
            contractedOnly: target.contractedOnly,
            unstoppedOnly: target.unstoppedOnly,
            annualCostMin: target.annualCostMin,
            annualCostMax: target.annualCostMax,
            promotionOnly: target.promotionOnly,
            brands: optional(target.brands, it => it.map(brand =>
                new CardBrandMapper().toJson(brand)
            ).toArray()),
            companies: optional(target.companies, it => it.toArray()),
            specialBenefitIds: optional(target.specialBenefitIds, it => it.toArray()),
            cardTypes: optional(target.cardTypes, it => it.map(type =>
                lets(type, cardType => {
                    switch (cardType) {
                        case Card.Type.CREDIT:
                            return 'credit';
                        case Card.Type.CHECK:
                            return 'check';
                        case Card.Type.HYBRID:
                            return 'hybrid';
                        default:
                            return 'credit';
                    }
                })
            ).toArray()),
            channel: lets(target.channel, it => {
                switch (it) {
                    case CardFilter.Channel.BANKSALAD:
                        return 'banksalad';
                    case CardFilter.Channel.INTERPARK:
                        return 'interpark';
                    default:
                        return 'banksalad';
                }
            })
        };
    }
}
