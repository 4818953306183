import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { InsuranceCompanySummary } from 'core/entity/personal-finance/insurance-company/summary';

export class InsuranceCompanySummaryMapper implements
    NetworkRequestMapper<InsuranceCompanySummary>,
    NetworkResponseMapper<InsuranceCompanySummary>
{
    fromJson(json: any): InsuranceCompanySummary {
        return new InsuranceCompanySummary(
            json.id,
            json.name,
            json.image_url
        )
    }

    toJson(target: InsuranceCompanySummary): any {
        return {
            id: target.id,
            name: target.name,
            image_url: target.imageUrl
        }
    }

}
