import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { Loan } from 'core/entity/loan';
import { LoanMapper as LoanNetworkMapper } from 'data/http/mapper/loan';
import {
    BrowserStorageKey,
    BrowserStorageProvider
} from 'data/browser-storage';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanRecommendFilterMapper } from 'data/browser-storage/mapper/loan/recommend-filter';
import { LoanRecommendSpecMapper } from 'data/browser-storage/mapper/loan/recommend-spec';

class ServerRenderLoanStorageMapper implements BrowserStorageMapper<Loan> {
    toEntity(json: any): Loan {
        return new LoanNetworkMapper().fromJson(json);
    }

    toJson(target: Loan): any {
        throw new Error('Method not implemented.');
    }
}

export class ServerRenderLoanStorageProvider extends BrowserStorageProvider<Loan> {
    protected key = BrowserStorageKey.LOAN_SERVER_RENDER;
    protected mapper = new ServerRenderLoanStorageMapper();
}

export class LoanRecommendSpecStorageProvider extends BrowserStorageProvider<LoanRecommendSpec> {
    protected key = BrowserStorageKey.LOAN_RECOMMEND_SPEC;
    protected mapper = new LoanRecommendSpecMapper();
}

export class LoanRecommendFilterStorageProvider extends BrowserStorageProvider<LoanRecommendFilter> {
    protected key = BrowserStorageKey.LOAN_RECOMMEND_FILTER;
    protected mapper = new LoanRecommendFilterMapper();
}
