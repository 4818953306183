import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { NetworkResponseMapper } from 'data/http/mapper';

export class FinanceSectorIDMapper implements NetworkResponseMapper<FinanceSectorID> {
  fromJson(json: any): FinanceSectorID {
    switch (json) {
    case 'card':
      return FinanceSectorID.CARD;
    case 'health':
      return FinanceSectorID.HEALTH_SELF_TESTING;
    case 'bank':
      return FinanceSectorID.BANK;
    case 'investment':
      return FinanceSectorID.INVESTMENT;
    case 'loan':
      return FinanceSectorID.LOAN;
    case 'annuity':
      return FinanceSectorID.ANNUITY;
    case 'fintech':
      return FinanceSectorID.FINTECH;
    case 'magazine':
      return FinanceSectorID.MAGAZINE;
    case 'article':
      return FinanceSectorID.ARTICLE;
    case 'mortgage':
      return FinanceSectorID.MORTGAGE;
    case 'saving':
      return FinanceSectorID.SAVING;
    }
  }
}
