import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanDescriptions } from 'core/entity/loan/descriptions';

export class LoanDescriptionsMapper implements NetworkResponseMapper<LoanDescriptions> {
    fromJson(json: any): LoanDescriptions {
        return new LoanDescriptions(
            json.product,
            json.amount_limits,
            json.interest,
            json.period,
            json.registration_method,
            json.additional_fees,
            json.required_documents,
            json.warning,
            json.qualifications,
            json.repayment
        )
    }

}
