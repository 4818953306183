import { Entity } from 'core/entity';
import { List } from 'immutable';
import { UserCreditScoreJointSurety } from 'core/entity/personal-credit/joint-surety';

export class UserCreditScoreJointSuretyStatus extends Entity {
    jointSureties: List<UserCreditScoreJointSurety>;
    updatedAt: Date;
    changed: boolean;

    constructor(
        jointSureties: List<UserCreditScoreJointSurety>,
        updatedAt: Date,
        changed: boolean
    ) {
        super();
        this.jointSureties = jointSureties;
        this.updatedAt = updatedAt;
        this.changed = changed;
    }
}
