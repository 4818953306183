import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserProductConfiguration } from 'core/entity/personal-finance/product-configuration';
import { UserProductConfigurationMapper } from 'data/http/mapper/personal-finance/product-configuration';
import { SummarizedUserProducts } from 'core/entity/personal-finance/summarized-products';
import { SummarizedUserProductsMapper } from 'data/http/mapper/personal-finance/summarized-products';

export class UserProductsApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<UserProductConfiguration> {
        return this.requestGET(
            'api/v2/me/products',
            { hidden: false, deleted: false },
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserProductConfigurationMapper().fromJson(result.data)
        )
    }

    getGroupByCompany(accessToken: AccessToken, hidden?: boolean, deleted?: boolean): Observable<SummarizedUserProducts> {
        return this.requestGET(
            'api/v2/me/summarized-products',
            { hidden, deleted },
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new SummarizedUserProductsMapper().fromJson(result.data)
        )
    }
}
