import { NetworkRequestMapper } from 'data/http/mapper';
import { UserSecuritiesFirmAccountUpdateSpec } from 'core/entity/personal-finance/securities-firm-account/update-spec';
import { compactObject, optional } from 'utils/index';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';

export class UserSecuritiesFirmAccountUpdateSpecMapper implements NetworkRequestMapper<UserSecuritiesFirmAccountUpdateSpec> {
    toJson(target: UserSecuritiesFirmAccountUpdateSpec): any {
        return compactObject({
            nickname: optional(target.nickname),
            latest_balance: optional(target.latestBalance),
            description: optional(target.description),
            hidden: optional(target.hidden),
            deleted_status: optional(target.deletedStatus, new DeletedStatusMapper().toJson)
        })
    }
}
