import { TransactionType } from 'core/entity/personal-finance/transaction/type';

declare module 'core/entity/personal-finance/transaction/type' {
    export namespace TransactionType {
        export function presentName(type: TransactionType): string;
    }
}

TransactionType.presentName = function(type: TransactionType) {
    switch (type) {
        case TransactionType.INCOME:
            return '수입';
        case TransactionType.EXPENSE:
            return '지출';
        case TransactionType.LOG:
            return '이체';
        default:
            return '???';
    }
};
