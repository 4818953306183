import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceCardInstallmentSummaryData } from 'core/entity/personal-finance/advice/content-data/card-installment-summary';
import { AdviceCardCompanyInstallmentSnapshotMapper } from 'data/http/mapper/personal-finance/advice/card-company-installment-snapshot';
import { optional, parseDate } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceCardInstallmentSummaryDataMapper implements NetworkResponseMapper<AdviceCardInstallmentSummaryData> {
    fromJson(json: any): AdviceCardInstallmentSummaryData {
        return new AdviceCardInstallmentSummaryData(
            json.data.map(new AdviceCardCompanyInstallmentSnapshotMapper().fromJson).toList(),
            parseDate(json.base_datetime),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
