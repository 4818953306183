import { NetworkResponseMapper } from 'data/http/mapper';
import { parseDate } from 'utils/index';
import { HealthInsurancePaymentDetailResponse } from 'core/entity/personal-finance/health-insurance/payment-detail/response';

export class HealthInsurancePaymentDetailResponseMapper implements NetworkResponseMapper<HealthInsurancePaymentDetailResponse> {
    fromJson(json: any): HealthInsurancePaymentDetailResponse {
        return new HealthInsurancePaymentDetailResponse(
            parseDate(json.updated_at)
        );
    }
}
