import { NetworkResponseMapper } from 'data/http/mapper';
import { MobileAuthenticationCode } from 'core/entity/user/mobile-authenticate-code';

export class MobileAuthenticationCodeMapper implements NetworkResponseMapper<MobileAuthenticationCode> {
    fromJson(json: any): MobileAuthenticationCode {
        return new MobileAuthenticationCode(
            json.authentication_request_id
        )
    }
}
