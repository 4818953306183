import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class AdviceExpenseAmountSummary extends Entity {
    name: string;
    count: number;
    totalAmount: Amount;

    constructor(
        name: string,
        count: number,
        totalAmount: Amount
    ) {
        super();
        this.name = name;
        this.count = count;
        this.totalAmount = totalAmount;
    }
}
