import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV9PrivacyPolicyRoute } from 'presentation/routes/policies/v9/privacy-policy';

export const PoliciesV9Route = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/privacy-policy`} component={ PoliciesV9PrivacyPolicyRoute } />
    </Switch>
);
