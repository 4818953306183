import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { SpiderBankSummary } from 'core/entity/personal-finance/bank/summary';
import { SpiderBankRepositoryType } from 'core/repository/personal-finance/bank';

export class GetSpiderBanks extends UseCase<List<SpiderBankSummary>> {
    onlyCodeConfigured: boolean = false;
    private repository: SpiderBankRepositoryType;

    constructor(repository: SpiderBankRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<SpiderBankSummary>> {
        return this.repository.getAll(this.onlyCodeConfigured);
    }

    protected validate(): boolean {
        return true;
    }
}
