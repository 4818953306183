import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanInterestValue } from 'core/entity/loan/interest-value';
import { LoanAverageInterestMapper } from 'data/http/mapper/loan/interest-value/average-interest';
import { LoanConfirmationInterestMapper } from 'data/http/mapper/loan/interest-value/confirmation-interest';
import { LoanRangeInterestMapper } from 'data/http/mapper/loan/interest-value/range-interest';

export class LoanInterestValueMapper implements NetworkResponseMapper<LoanInterestValue> {
    fromJson(json: any): LoanInterestValue {
        switch (json.type) {
            case 'average':
                return new LoanAverageInterestMapper().fromJson(json);
            case 'confirmation':
                return new LoanConfirmationInterestMapper().fromJson(json);
            case 'range':
                return new LoanRangeInterestMapper().fromJson(json);
            default:
                return null;
        }
    }
}
