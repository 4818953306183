import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { SpiderCardCompanyRepositoryType } from 'core/repository/personal-finance/card-company';
import { SpiderCardCompaniesApiProvider } from 'data/http/api/gateway/v2/cards/companies/spider';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';

export class SpiderCardCompanyRepository implements SpiderCardCompanyRepositoryType {
    private api: SpiderCardCompaniesApiProvider;

    constructor(api: SpiderCardCompaniesApiProvider) {
        this.api = api;
    }

    getAll(onlyCodeConfigured: boolean): Observable<List<SpiderCardCompanySummary>> {
        return this.api.getAll(onlyCodeConfigured);
    }
}
