import {
    NetworkRequestMapper,
    NetworkResponseMapper
} from 'data/http/mapper';
import { Card } from 'core/entity/card';
import { lets } from 'utils/index';

export class CardTypeMapper implements
    NetworkRequestMapper<Card.Type>,
    NetworkResponseMapper<Card.Type>
{
    toJson(target: Card.Type): any {
        return lets(target, it => {
            switch (it) {
                case Card.Type.CREDIT:
                    return 'credit';
                case Card.Type.CHECK:
                    return 'check';
                case Card.Type.HYBRID:
                    return 'hybrid';
            }
        });
    }

    fromJson(json: any): Card.Type {
        return lets(json, it => {
            switch (it) {
                case 'credit':
                    return Card.Type.CREDIT;
                case 'check':
                    return Card.Type.CHECK;
                case 'hybrid':
                    return Card.Type.HYBRID;
            }
        });
    }

}
