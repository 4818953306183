import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import DeletedStatus = Asset.DeletedStatus;
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { SpiderBankSummary } from 'core/entity/personal-finance/bank/summary';

export class UserBankAccountUpdateSpec extends Entity {
    type?: UserBankAccount.Type;
    nickname?: string;
    latestBalance?: number;
    description?: string;
    createdAt?: Date;
    expiredAt?: Date;
    hidden?: boolean;
    deletedStatus?: DeletedStatus;
    bank?: SpiderBankSummary;

    constructor(
        type: UserBankAccount.Type = null,
        nickname: string = null,
        latestBalance: number = null,
        description: string = null,
        createdAt: Date = null,
        expiredAt: Date = null,
        hidden: boolean = null,
        deletedStatus: DeletedStatus = null,
        bank: SpiderBankSummary = null
    ) {
        super();
        this.type = type;
        this.nickname = nickname;
        this.latestBalance = latestBalance;
        this.description = description;
        this.createdAt = createdAt;
        this.expiredAt = expiredAt;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
        this.bank = bank;
    }
}
