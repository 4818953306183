import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { List } from 'immutable';
import { AdviceCardUsedAmount } from 'core/entity/personal-finance/advice/card-used-amount';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';

export class AdviceCardExpenseData extends Entity implements AdviceContentData {
    cards: List<AdviceCardUsedAmount>;
    message?: AdviceMessage;

    constructor(
        cards: List<AdviceCardUsedAmount>,
        message: AdviceMessage = null
    ) {
        super();
        this.cards = cards;
        this.message = message;
    }
}
