import { List, OrderedMap } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardConfiguration } from 'core/entity/card/configuration';
import { CardBrandMapper } from 'data/http/mapper/card/brand';

export class CardConfigurationMapper implements NetworkResponseMapper<CardConfiguration> {
    fromJson(json: any): CardConfiguration {
        return new CardConfiguration(
            List(json.brands.map((brand: any) =>
                new CardBrandMapper().fromJson(brand)
            )),
            OrderedMap(json.companies.map((company: any) =>
                [company.key, company.name]
            )),
            List(json.special_benefits.map((benefit: any) =>
                new CardConfiguration.SpecialBenefit(
                    benefit.title,
                    OrderedMap(benefit.items.map((item: any) =>
                        [item.key, item.description]
                    )
                )
            )))
        )
    }
}
