import { Entity } from 'core/entity';

export class AdviceUserCardSummary extends Entity {
    id: string;
    name: string;
    
    constructor(
        id: string,
        name: string
    ) {
        super();
        this.id = id;
        this.name = name;
    }
}
