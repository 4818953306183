import { SignUpWithMobileAuthenticationView } from 'presentation/routes/users/signup/mobile-authentication';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import styles from './styles.pcss';

export const SignUpRoute = ({ match }: RouteComponentProps<any>) => {
  return (
    <div className={ styles.wrap }>
      <div className={ styles.content }>
        <Switch>
          <Route
            path={ `${ match.url }/mobile-authentication` }
            component={ SignUpWithMobileAuthenticationView }
          />
        </Switch>
      </div>
    </div>
  );
};
