import { Entity } from 'core/entity';
import { CardBrand } from 'core/entity/card/brand';

export class CardAnnualCost extends Entity {
    brand: CardBrand;
    price: number;

    constructor(
        brand: CardBrand,
        price: number
    ) {
        super();
        this.brand = brand;
        this.price = price;
    }
}
