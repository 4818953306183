import { Service } from 'core/service';
import { CardLegalConfigurationRepositoryType } from 'core/repository/card-legal-configuration';
import { LoanLegalConfigurationRepositoryType } from 'core/repository/loan-legal-configuration';

export class LegalConfigService implements Service {
    private cardConfig: CardLegalConfigurationRepositoryType;
    private loanConfig: LoanLegalConfigurationRepositoryType;

    constructor(
        cardConfig: CardLegalConfigurationRepositoryType,
        loanConfig: LoanLegalConfigurationRepositoryType
    ) {
        this.cardConfig = cardConfig;
        this.loanConfig = loanConfig;
    }

    getCardLegalConfigByCardId = (cardId: number) =>
        this.cardConfig.getByCardId(cardId);

    getLoanLegalConfigByLoanId = (loanId: number) =>
        this.loanConfig.getByLoanId(loanId);
}
