import React from 'react';

import { EventBus } from 'presentation/bus';
import { FixBodyEvent } from 'presentation/bus/event/fix-body-event';
import { KeyUpEvent } from 'presentation/bus/event/key-up-event';
import { SubscriptionBag } from 'presentation/module/extension';
import { KEY_CODE } from 'presentation/module/key-code';
import { WindowResizeEvent } from 'presentation/bus/event/window-resize-event';

import styles from './styles.pcss';
const MOBILE_SCREEN_WIDTH = 425;

interface Props {
    title?: string;
    width?: number;
    mobileFullHeight?: boolean;
    children: any;
    onCancel: () => void;
}

export class FlexibleAlert extends React.Component<Props> {
    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        EventBus.post(new FixBodyEvent(true));
        EventBus.toObservable()
            .subscribe(event => {
                if (event instanceof KeyUpEvent && event.keyCode === KEY_CODE.ESC) {
                    this.onCancel();
                } else if (event instanceof WindowResizeEvent) {
                    this.forceUpdate();
                }
            })
            .unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
        EventBus.post(new FixBodyEvent(false));
        this.subscriptionBag.destroy();
    }

    render() {
        const {
            title,
            width,
            mobileFullHeight,
            children
        } = this.props;

        return (
            <div className={ styles.fixed }>
                <div
                    onClick={ this.onCancel }
                    className={ styles.dim }
                />
                <div
                    style={{ width: `${width ? width : 500}px` }}
                    className={ styles.container }
                >
                    {
                        title &&
                        <h2 className={ styles.title }>
                            { title }
                            <button
                                className={ styles.close }
                                onClick={ this.onCancel }
                            >
                                닫기
                            </button>
                        </h2>
                    }
                    <div
                        style={{
                            height: (mobileFullHeight || false) && window.innerWidth <= MOBILE_SCREEN_WIDTH ?
                                `${window.innerHeight}px` : 'auto'
                        }}
                        className={ styles.body }
                    >
                        { children }
                    </div>
                </div>
            </div>
        )
    }

    private onCancel = () =>
        this.props.onCancel();
}
