import { Entity } from 'core/entity';
import { UserAccount } from 'core/entity/user/account';
import { UserPreference } from 'core/entity/user/preference';
import { UserSex } from 'core/entity/user/sex';

export class User extends Entity {
    id: number;
    name: string;
    sex: UserSex;
    createdAt: Date;
    authenticated: boolean;
    account: UserAccount;
    preference: UserPreference;
    birthday?: Date;
    totalIncome?: number;

    constructor(
        id: number,
        name: string,
        sex: UserSex,
        createdAt: Date,
        authenticated: boolean,
        account: UserAccount,
        preference: UserPreference,
        birthday: Date = null,
        totalIncome: number = null
    ) {
        super();
        this.id = id;
        this.name = name;
        this.sex = sex;
        this.createdAt = createdAt;
        this.authenticated = authenticated;
        this.account = account;
        this.preference = preference;
        this.birthday = birthday;
        this.totalIncome = totalIncome;
    }
}
