import { Entity } from 'core/entity';
import { FinanceSubSectorItemSummary } from 'core/entity/finance-sector/item/summary';
import { FinanceSectorItemBadge } from 'core/entity/finance-sector/item/badge';

export class FinanceSubSectorUrlItemSummary extends Entity implements FinanceSubSectorItemSummary {
    name: string;
    badge?: FinanceSectorItemBadge;
    url: string;

    constructor(
        name: string,
        url: string,
        badge?: FinanceSectorItemBadge
    ) {
        super();
        this.name = name;
        this.url = url;
        this.badge = badge;
    }
}
