import { NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { FinanceSubSectorItemSummary } from 'core/entity/finance-sector/item/summary';
import { FinanceSubSectorUrlItemSummary } from 'core/entity/finance-sector/item/url/summary';
import { FinanceSubSectorContentItemSummary } from 'core/entity/finance-sector/item/content/summary';
import { FinanceSubSectorContentSummaryMapper } from 'data/http/mapper/finance-sector/content-summary';
import { FinanceSectorItemBadgeMapper } from 'data/http/mapper/finance-sector/item/badge';

export class FinanceSubSectorItemSummaryMapper implements NetworkResponseMapper<FinanceSubSectorItemSummary> {
    fromJson(json: any): FinanceSubSectorItemSummary {
        return lets(json.type, type => {
            switch (type) {
                case 'url':
                    return new FinanceSubSectorUrlItemSummary(
                        json.name,
                        json.url,
                        new FinanceSectorItemBadgeMapper().fromJson(json.badge)
                    );
                case 'content':
                    return new FinanceSubSectorContentItemSummary(
                        json.name,
                        new FinanceSubSectorContentSummaryMapper().fromJson(json.content),
                        new FinanceSectorItemBadgeMapper().fromJson(json.badge)
                    );
                default:
                    return null;
            }
        })
    }
}
