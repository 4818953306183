import React from 'react';

import { Alert } from 'presentation/components/alert';
import { CreditLoanAdditionalInfoAlertType } from 'presentation/components/alert/credit-loan-additional-info/model';

import styles from './styles.pcss';

interface Props {
    type: CreditLoanAdditionalInfoAlertType,
    onCancel: () => void
}

export const CreditLoanAdditionalInfoAlert = ({ type, onCancel }: Props) => {
    const renderContent = (type: CreditLoanAdditionalInfoAlertType) => {
        switch (type) {
            case CreditLoanAdditionalInfoAlertType.REPAYMENT_TYPE:
                return (
                    <div className={ styles.wrap }>
                        <h1 className={ styles.title }>{ CreditLoanAdditionalInfoAlertType.REPAYMENT_TYPE }</h1>
                        <ul className={ styles.list }>
                            <li className={ styles.imageItem }>
                                <div>
                                    <h2 className={ styles.subTitle }>원금 균등상환</h2>
                                    <p className={ styles.description }>매달 원금을 일정하게 상환하고 남은 금액에 따른 이자 납부</p>
                                </div>
                                <span className={ styles.repaymentImageProgressive }/>
                            </li>
                            <li className={ styles.imageItem }>
                                <div>
                                    <h2 className={ styles.subTitle }>원리금 균등상환</h2>
                                    <p className={ styles.description }>매달 원금과 이자를 일정하게 상환</p>
                                </div>
                                <span className={ styles.repaymentImageInitialValue }/>
                            </li>
                            <li className={ styles.imageItem }>
                                <div>
                                    <h2 className={ styles.subTitle }>만기일시 상환</h2>
                                    <p className={ styles.description }>매달 이자만 상환하고 만기일에 원금 일시상환</p>
                                </div>
                                <span className={ styles.repaymentImageAtEnd }/>
                            </li>
                        </ul>
                    </div>
                );
            case CreditLoanAdditionalInfoAlertType.INTEREST_TYPE:
                return (
                    <div className={ styles.wrap }>
                        <h1 className={ styles.title }>{ CreditLoanAdditionalInfoAlertType.INTEREST_TYPE }</h1>
                        <ul className={ styles.list }>
                            <li className={ styles.item }>
                                <div>
                                    <h2 className={ styles.subTitle }>고정 금리</h2>
                                    <p className={ styles.description }>대출기간 동안 기준금리가 변하지 않는 상품</p>
                                </div>
                            </li>
                            <li className={ styles.item }>
                                <div>
                                    <h2 className={ styles.subTitle }>변동 금리</h2>
                                    <p className={ styles.description }>대출기간 동안 시장 금리변동에 따라 기준금리도 변하는 상품</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                );
            case CreditLoanAdditionalInfoAlertType.LOAN_CATEGORY_TYPE:
                return (
                    <div className={ styles.wrap }>
                        <h1 className={ styles.title }>{ CreditLoanAdditionalInfoAlertType.LOAN_CATEGORY_TYPE }</h1>
                        <ul className={ styles.list }>
                            <li className={ styles.item }>
                                <div>
                                    <h2 className={ styles.subTitle }>일반 대출</h2>
                                    <p className={ styles.description }>대출금이 한번에 내 계좌로 입금되는 상품</p>
                                </div>
                            </li>
                            <li className={ styles.item }>
                                <div>
                                    <h2 className={ styles.subTitle }>마이너스 통장</h2>
                                    <p className={ styles.description }>한도 내에서 자유롭게 돈을 찾아 쓸 수 있는 상품</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                );
        }
    };

    return (
        <Alert onCancel={ onCancel }>
            { renderContent(type) }
        </Alert>
    )
};
