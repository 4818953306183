import { MedicalCheckupRepositoryType } from 'core/repository/medical-check-up';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { MedicalCheckUpResultApiProvider } from 'data/http/api/gateway/v2/me/medical-checkups/result/inedx';
import { MedicalCheckupSummary } from 'core/entity/medical-checkup/summary';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';
import { MedicalCheckupStorageProvider } from 'data/browser-storage/api/medical-checkup';

export class MedicalCheckupRepository implements MedicalCheckupRepositoryType {
    private resultsApi: MedicalCheckUpResultApiProvider;
    private judgementStorage: MedicalCheckupStorageProvider;
    private tokenStorage: AccessTokenStorageProvider;

    constructor(
        resultsApi: MedicalCheckUpResultApiProvider,
        judgementStorage: MedicalCheckupStorageProvider,
        tokenStorage: AccessTokenStorageProvider
    ) {
        this.resultsApi = resultsApi;
        this.judgementStorage = judgementStorage;
        this.tokenStorage = tokenStorage;
    }

    getSummary = (): Observable<MedicalCheckupSummary> => {
        return this.tokenStorage.get().flatMap(token =>
            this.resultsApi.get(token)
        );
    };

    getJudgement = (): Observable<List<MedicalCheckupJudgement>> => {
        return this.judgementStorage.get()
    };

    setJudgement = (spec: List<MedicalCheckupJudgement>): Observable<void> => {
        return this.judgementStorage.set(spec)
    };

}
