import { Entity } from 'core/entity';

export class AdviceAction extends Entity {
    url: string;
    description?: string;

    constructor(
        url: string,
        description: string = null
    ) {
        super();
        this.url = url;
        this.description = description;
    }
}
