import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanBaseInterestGroup } from 'core/entity/loan/base-interest-group';
import { LoanBaseInterestMapper } from 'data/http/mapper/loan/base-interest';
import { List } from 'immutable';

export class LoanBaseInterestGroupMapper implements NetworkResponseMapper<LoanBaseInterestGroup> {
    fromJson(json: any): LoanBaseInterestGroup {
        return new LoanBaseInterestGroup(
            json.criteria_description,
            List(json.interests.map((item: any) =>
                new LoanBaseInterestMapper().fromJson(item)
            ))
        )
    }

}
