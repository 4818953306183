import { NetworkResponseMapper } from 'data/http/mapper';
import { Alfred2PointAmount } from 'core/entity/alfred2/point-amount';

export class Alfred2PointAmountMapper implements NetworkResponseMapper<Alfred2PointAmount> {
    fromJson(json: any): Alfred2PointAmount {
        return new Alfred2PointAmount(
            json.amount
        );
    }
}
