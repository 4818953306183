import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { Amount } from 'core/entity/amount';
import { SpiderSecuritiesFirmSummary } from 'core/entity/personal-finance/securities-firm/summary';
import {
    UserSecuritiesFirmAccountItemConfiguration
} from 'core/entity/personal-finance/securities-firm-account/item-configuration';
import { SecuritiesFirmAccountPrimaryData } from 'core/entity/personal-finance/primary-data/securities-firm-account';

export class UserSecuritiesFirmAccount extends Entity {
    id: string;
    hidden: boolean;
    deletedStatus: Asset.DeletedStatus;
    closed: boolean;
    dormant: boolean;
    latestBalance: Amount;
    securitiesFirm: SpiderSecuritiesFirmSummary;
    itemConfiguration: UserSecuritiesFirmAccountItemConfiguration;
    primaryData: SecuritiesFirmAccountPrimaryData;
    number?: string;
    nickname?: string;
    description?: string;

    constructor(
        id: string,
        hidden: boolean,
        deletedStatus: Asset.DeletedStatus,
        closed: boolean,
        dormant: boolean,
        latestBalance: Amount,
        securitiesFirm: SpiderSecuritiesFirmSummary,
        itemConfiguration: UserSecuritiesFirmAccountItemConfiguration,
        primaryData: SecuritiesFirmAccountPrimaryData,
        number: string = null,
        nickname: string = null,
        description: string = null
    ) {
        super();
        this.id = id;
        this.number = number;
        this.nickname = nickname;
        this.description = description;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
        this.closed = closed;
        this.dormant = dormant;
        this.latestBalance = latestBalance;
        this.securitiesFirm = securitiesFirm;
        this.itemConfiguration = itemConfiguration;
        this.primaryData = primaryData;
    }
}
