import { Entity } from 'core/entity';
import { LoanOption } from 'core/entity/loan/option';
import { List } from 'immutable';
import { LoanInterestConfiguration } from 'core/entity/loan/interest-configuration';

export class LoanComputationResult extends Entity {
    interestConfiguration: LoanInterestConfiguration;
    maximumAmountLimit: number;
    options: List<LoanOption>;

    constructor(
        interestConfiguration: LoanInterestConfiguration,
        maximumAmountLimit: number,
        options: List<LoanOption>
    ) {
        super();
        this.interestConfiguration = interestConfiguration;
        this.maximumAmountLimit = maximumAmountLimit;
        this.options = options;
    }
}
