import React from 'react';
import { DropDownModel } from 'presentation/components/drop-down/model';
import { List } from 'immutable';

import { sendGAEvent, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { uniqueKey } from 'utils/index';
import { lets } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    list: List<DropDownModel>,
    selected: DropDownModel,
    inlineStyle?: {
        title?: any,
        arrow?: any,
        border?: any,
        mobile?: any
    },
    gaCategory?: string,
    onChange: (item: DropDownModel) => void
}

export const DropDown = (props: Props) => {
    const {
        selected,
        list,
        inlineStyle,
        gaCategory,
        onChange
    } = props;
    let input: HTMLInputElement;
    const dropDownKey = uniqueKey();

    const renderItem = (item: DropDownModel, index: number) => (
        <li
            key={ `${ dropDownKey }-${ index }` }
            className={ styles.item }
        >
            <button
                onClick={ () => {
                    onChange(item);
                    input.checked = false;
                } }
                className={
                    selected.value === item.value ?
                        styles.selectedButton :
                        styles.defaultButton
                }
                data-test={ `drop-down${index}` }
            >
                { item.value }
            </button>
        </li>
    );

    return lets(uniqueKey(),it =>
        <div className={ styles.container }>
            <input
                id={ it }
                type="checkbox"
                ref={ node => input = node }
                className={ styles.input }
            />
            <div
                onClick={ () => input.checked = false }
                className={ styles.clickAbleLayer }
            />
            <label
                htmlFor={ it }
                style={ inlineStyle && inlineStyle.title }
                onClick={ () => !input.checked && sendGAEvent(GA_DOMAIN.NONE, gaCategory, '정렬필터_펼치기')}
                className={ styles.title }
                data-test="dropdown-label"
            >
                { selected.value }
                <span
                    style={ inlineStyle && inlineStyle.arrow }
                    className={ styles.arrow }
                />
            </label>
            <ul className={ styles.list }>
                { list.map((item, index) => renderItem(item, index)) }
            </ul>
        </div>
    );
};
