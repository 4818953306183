import { BdsColorIllustrationSettingBanksaladSignature1Svg } from "presentation/components/shared/bpl/icons";
import { trackClickGnb, trackClickLnb } from 'presentation/module/analytics/events/trackers/gnb';
import { BANKSALAD_SITEMAP } from "presentation/module/sitemap";
import React, { MouseEventHandler, ReactNode } from 'react';

import { Gnb, LnbCategoryItem } from '../gnb-data';
import styles from './styles.pcss';

interface GlobalNavigationBarDesktopProps {
  gnbData: Gnb[];
}

export const GlobalNavigationBarDesktop = ({ gnbData }: GlobalNavigationBarDesktopProps) => {

  return (
    <nav className={styles.gnbDesktop}>
      <a
        href={BANKSALAD_SITEMAP.MAIN}
        className={styles.bi}
      >
        <BdsColorIllustrationSettingBanksaladSignature1Svg width={141} height={23} title='뱅크샐러드' />
        <h1>뱅크샐러드</h1>
      </a>

      <GnbList gnbData={gnbData} />
    </nav>
  );
};

interface GnbListProps {
    gnbData: Gnb[];
}

export const GnbList = (props: GnbListProps) => (
  <>
    {props.gnbData.map(gnbItem =>
      <GnbListItem gnbItem={gnbItem} key={gnbItem.id}/>
    )}
    <div className={ styles.gnbDim }/>
  </>
);

const GnbListItem = ({ gnbItem }: { gnbItem: Gnb }) => {
  const isLnbCategoriesEmpty =
    gnbItem.lnbCategories === undefined || gnbItem.lnbCategories.length === 0;

  return (
    <>
      <a
        href={ gnbItem.link.url }
        target={ gnbItem.link.linkTarget }
        onClick={ () => trackClickGnb(gnbItem.title) }
        key={ gnbItem.id }
        className={ isLnbCategoriesEmpty ? styles.gnbItem : styles.gnbItemDimVisible }
      >
        {gnbItem.title}
      </a>
      <LnbCategoryList gnbItem={gnbItem} />
    </>
  );
};

const LnbCategoryList = ({
  gnbItem
}: { gnbItem: Gnb }) => {
  const isLnbCategoriesEmpty =
    gnbItem.lnbCategories === undefined || gnbItem.lnbCategories.length === 0;

  if (isLnbCategoriesEmpty && gnbItem.featuredItem === undefined) {
    return null;
  }

  return  (
    <div className={ styles.lnbContainer }>
      {gnbItem.featuredItem !== undefined &&
          <>
            <div>
              {gnbItem.featuredItem}
            </div>
            <div className={ styles.verticalDivider } />
          </>
      }

      <div className={ styles.lnbCategoryList }>
        {gnbItem.lnbCategories !== undefined
            && gnbItem.lnbCategories.map((lnbCategory, index) => (
              <section
                className={ styles.lnbCategory }
                key={ `${gnbItem.id}-${lnbCategory.title}${index}`}
              >
                {lnbCategory.featuredItemForDesktop !== undefined &&
                  lnbCategory.featuredItemForDesktop
                }

                {lnbCategory.title !== undefined && (
                  <div className={ styles.lnbCategoryTitle }>{lnbCategory.title}</div>
                )}

                <ul>
                  {lnbCategory.items.map((item) => (
                    <LnbCategoryItem
                      lnbCategoryItem={item}
                      gnbItemTitle={gnbItem.title}
                      key={ `${gnbItem.id}-${lnbCategory.title}${index}-${item.title}` } />
                  ))}
                </ul>
              </section>
            ))}
      </div>
    </div>
  );
};


const LnbCategoryItem = ({
  lnbCategoryItem,
  gnbItemTitle
}: { lnbCategoryItem: LnbCategoryItem; gnbItemTitle: string }) => {
  return (
    <li className={ styles.lnbCategoryItem }>
      <a
        href={ lnbCategoryItem.url }
        onClick={ () => trackClickLnb(gnbItemTitle, lnbCategoryItem.title) }
      >
        {lnbCategoryItem.title}
      </a>
    </li>
  );
};

interface GnbFeaturedItemDesktopProps {
  title: ReactNode;
  image: ReactNode;
  buttonText: string;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
}

export const GnbFeaturedItemDesktop = ({
  title,
  image,
  buttonText,
  onButtonClick,
}: GnbFeaturedItemDesktopProps) => {

  return (
    <div className={styles.gnbFeaturedItem}>
      {title}
      {image}
      <button onClick={onButtonClick} className={styles.gnbFeaturedItemButton}>
        {buttonText}
      </button>
    </div>
  );
};
