import { Observable } from 'rxjs/Rx';
import { Scheduler } from 'rxjs/Scheduler';

export abstract class UseCase<T> {
    run(
        executorScheduler: Scheduler,
        postExecutionScheduler: Scheduler
    ): Observable<T> {
        if (!this.validate()) {
            throw Error('Invalid params in UseCase');
        }

        return this.build()
            .subscribeOn(executorScheduler)
            .observeOn(postExecutionScheduler);
    }

    protected abstract build(): Observable<T>;
    protected abstract validate(): boolean;
}
