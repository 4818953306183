import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { optional } from 'application/utils';
import { LoanOrganizationTypeMapper } from 'data/browser-storage/mapper/loan/organization-type';
import { LoanInterestTypeMppaer } from 'data/browser-storage/mapper/loan/interest-type';
import { LoanRepaymentTypeMappaer } from 'data/browser-storage/mapper/loan/repayment-type';
import { LoanRegistrationTypeMapper } from 'data/browser-storage/mapper/loan/registration-type';
import { List } from 'immutable';

export class LoanRecommendFilterMapper implements BrowserStorageMapper<LoanRecommendFilter> {
    toEntity(json: any): LoanRecommendFilter {
        return new LoanRecommendFilter(
            optional(json.repaymentPeriod),
            optional(json.organizationTypes, (it: any) =>
                List(it.map((item: any) =>
                    new LoanOrganizationTypeMapper().toEntity(item)
                ))
            ),
            optional(json.interestTypes, (it: any) =>
                List(it.map((item: any) =>
                    new LoanInterestTypeMppaer().toEntity(item)
                ))
            ),
            optional(json.repaymentTypes, (it: any) =>
                List(it.map((item: any) =>
                    new LoanRepaymentTypeMappaer().toEntity(item)
                ))
            ),
            optional(json.registrationTypes, (it: any) =>
                List(it.map((item: any) =>
                    new LoanRegistrationTypeMapper().toEntity(item)
                ))
            ),
            optional(json.exemptReimbursementFees),
            optional(json.dayLoan),
            optional(json.generalCredit),
            optional(json.minusAccount),
            optional(json.inPromotion),
            optional(json.sorter, it => {
                switch (it) {
                    case 'minimum_interest':
                        return LoanRecommendFilter.Sort.MINIMUM_INTEREST;
                    case 'maximum_loan_limit':
                        return LoanRecommendFilter.Sort.MAXIMUM_LOAN_LIMIT;
                    case 'maximum_repayment_period':
                        return LoanRecommendFilter.Sort.MAXIMUM_REPAYMENT_PERIOD;
                    default:
                        return null;
                }
            })
        );
    }

    toJson(target: LoanRecommendFilter): any {
        return {
            repaymentPeriod: optional(target.repaymentPeriod),
            organizationTypes: optional(target.organizationTypes, it =>
                it.map(item =>
                    new LoanOrganizationTypeMapper().toJson(item)
                ).toArray()
            ),
            interestTypes: optional(target.interestTypes, it =>
                it.map(item =>
                    new LoanInterestTypeMppaer().toJson(item)
                ).toArray()
            ),
            repaymentTypes: optional(target.repaymentTypes, it =>
                it.map(item =>
                    new LoanRepaymentTypeMappaer().toJson(item)
                ).toArray()
            ),
            registrationTypes: optional(target.registrationTypes, it =>
                it.map(item =>
                    new LoanRegistrationTypeMapper().toJson(item)
                ).toArray()
            ),
            exemptReimbursementFees: optional(target.exemptReimbursementFees),
            dayLoan: optional(target.dayLoan),
            generalCredit: optional(target.generalCredit),
            minusAccount: optional(target.minusAccount),
            inPromotion: optional(target.inPromotion),
            sorter: optional(target.sorter, it => {
                switch (it) {
                    case LoanRecommendFilter.Sort.MINIMUM_INTEREST:
                        return 'minimum_interest';
                    case LoanRecommendFilter.Sort.MAXIMUM_LOAN_LIMIT:
                        return 'maximum_loan_limit';
                    case LoanRecommendFilter.Sort.MAXIMUM_REPAYMENT_PERIOD:
                        return 'maximum_repayment_period';
                    default:
                        return null;
                }
            })
        };
    }

}
