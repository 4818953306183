import { SpiderBankRepositoryType } from 'core/repository/personal-finance/bank';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { SpiderBankSummary } from 'core/entity/personal-finance/bank/summary';
import { SpiderBanksApiProvider } from 'data/http/api/gateway/v2/banks/spider';

export class SpiderBankRepository implements SpiderBankRepositoryType {
    private api: SpiderBanksApiProvider;

    constructor(api: SpiderBanksApiProvider) {
        this.api = api;
    }

    getAll(onlyCodeConfigured: boolean): Observable<List<SpiderBankSummary>> {
        return this.api.getAll(onlyCodeConfigured);
    }
}
