/**
 * productcuration-web 에 있는 함수, idl 에 있는 타입들을 그대로 복붙해온 파일입니다.
 * idl 은 install 되지 않아 필요한 타입들만 복붙해왔고, 
 * api 응답 값 camelize 를 위한 humps 를 설치할 수 없어 snake_case 그대로 사용하였습니다.
 * (humps 는 typescript4.1 이상 필요. https://github.com/DefinitelyTyped/DefinitelyTyped/blob/711e002edef4299546b1c301abd4dd7f098f9eea/types/humps/index.d.ts#L7) 
 */

import axios, { AxiosInstance } from 'axios';
import { isProd } from 'presentation/module/extension';
import { covertToMoneyFormat } from 'presentation/routes/sectors/card/utils/convert-to-money-format';

export interface OrganizationMessage {
  /** 기관구분 global unique id */
  guid: string;
  /** 기관명 */
  name: string;
  /**
   * 기관 로고 이미지 URL
   * bds-graphic: color/logo/original, color/logo/circle
   */
  logo_image_url: string;
  /**
   * 업권
   enum: v1.organization.Industry
   */
  industry_enum: string;
  /**
   * 오브젝트 ID (하위호환성 유지 용도)
   * Deprecated. Use `guid` instead.
   *
   * @deprecated
   */
  objectid: string;
  /**
   * 세부업권
   enum: v1.organization.SubIndustry
   */
  sub_industry_enum: string;
}

export interface CardOrganizationCashbackPromotion {
  /** 캐시백 금액 */
  cashback_amount_krw_0f: string;
  /** 이벤트 대상 카드 */
  cards: CardForCashbackPromotion[];
  /** 프로모션 URL */
  promotion_url: string;
  /** 참여방법 */
  steps: CardCashbackPromotionStep[];
}

export interface CardForCashbackPromotion {
  product_guid: string;
  /** 상품명 */
  name: string;
  /** 캐치프레이즈 (한줄 카드 설명) */
  catchphrase: string | undefined;
  /** 카드 이미지 */
  image_url: string;
  should_display_image_vertically: boolean;
  /** 카드사 프로모션 페이지 URL (6월 캐시백 부터 deprecate 예정) */
  promotion_url: string;
  /** 연회비 (브랜드 별로 연회비가 다를 수 있으나 대표적인 기본 연회비 하나 반환) */
  annual_cost_amount_krw0f: string;
}

export interface CardCashbackPromotionStep {
  /** 제목 (e.g. 15만원 이용하고 15만원 캐시백 받기) */
  title: string;
  description: string | undefined;
  /** 응모기한 시작일 */
  application_date_gte_ms: string;
  /** 응모기한 종료일 */
  application_date_lte_ms: string;
}
export interface CardForAnnualCostPromotion {
  product_guid: string;
  /** 상품명 */
  name: string;
  /** 캐치프레이즈 (한줄 카드 설명) */
  catchphrase: string | undefined;
  /** 카드 이미지 */
  image_url: string;
  should_display_image_vertically: boolean;
}

export interface CardOrganizationPromotion {
  /** 프로모션 ID */
  id: string;
  /** 기관 */
  organization: OrganizationMessage;
  /** 프로모션 시작일자 */
  start_date_ms: string;
  /** 프로모션 종료일자 */
  end_date_ms: string | undefined;
  /** 한줄 소개 문구 (e.g. 최대 16만원 증정, KB국민카드 온라인 신규발급 회원 연회비 100% 캐시백) */
  introductory_phrase: string;
  /** 보조 소개 html 문구 (e.g. 하나카드 이용하고 최대 16만원 받으세요) */
  auxiliary_introductory_phrase: string | undefined;
  /** 커버 이미지 */
  cover_image_url: string | undefined;
  /** 활성화 여부 */
  is_enabled: boolean;
  /** 프로모션 설명 (흰 배경에 들어가는 프로모션 혜택 설명) */
  promotion_description: string;
  /** 그 외 설명 (회색배경에 들어가는 각종 고지사항 및 설명) */
  legal_description: string | undefined;
  /** 캐시백 프로모션 (캐시백, 연회비 중 1개만 들어옴) */
  cashback_promotion?: CardOrganizationCashbackPromotion | undefined;
}


const getBenefitText = (promotions: CardOrganizationPromotion[]) => {
  const money = Math.max(
    ...promotions.map(promotion =>
      Number(promotion.cashback_promotion?.cashback_amount_krw_0f ?? 0)
    )
  );

  const benefitSumCurrency = covertToMoneyFormat({
    money,
    needWon: true,
    // @ts-ignore
    omitUnit: 1000,
    units: [
      { value: 10000, unit: '만' },
      { value: 1000, unit: '천' },
    ],
  });

  return `최대 ${benefitSumCurrency}`;
};

/**
 * 실제 pcw 에는 여러 컴포넌트에 걸쳐있는 로직이지만, banksalad-web 에서 임시로 활용하기 위해 정리한 함수입니다.
 */
export const getBenefitSumText = async () => {
  const baseUrl = isProd() ? 'https://api.banksalad.com' : 'https://api.staging.banksalad.com';
  const url = '/v1/productcuration/card-organization-promotions';
  try{
    const { data } = await createAxiosInstance(baseUrl).get(url);
    const { promotions } = data;

    const benefitSumText = getBenefitText(promotions as CardOrganizationPromotion[]);

    return benefitSumText;
  } catch{
    return '추가 혜택';
  }
};

export const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const headers = {
    Accept: 'application/json',
    Pragma: 'no-cache',
    'Banksalad-Application-Name': 'banksalad2:Web',
  };

  const instance = axios.create({
    headers,
    baseURL,
    timeout: 60000,
  });
  
  return instance;
};
