import { TransactionCategory } from 'core/entity/personal-finance/transaction/category';
import { NetworkResponseMapper } from 'data/http/mapper';
import { TransactionCategoryParentMapper } from 'data/http/mapper/personal-finance/transaction/category-parent';
import { TransactionIconMapper } from 'data/http/mapper/personal-finance/transaction/icon';
import { TransactionTypeMapper } from 'data/http/mapper/personal-finance/transaction/type';
import { optional } from 'utils/index';

export class TransactionCategoryMapper implements NetworkResponseMapper<TransactionCategory> {
    fromJson(json: any): TransactionCategory {
        return new TransactionCategory(
            json.id,
            json.name,
            new TransactionTypeMapper().fromJson(json.transaction_type),
            json.priority,
            optional(json.icon, it =>
                new TransactionIconMapper().fromJson(it)
            ),
            optional(json.parent, it =>
                new TransactionCategoryParentMapper().fromJson(it)
            )
        )
    }
}
