import { NetworkResponseMapper } from 'data/http/mapper';
import { CardCFADescription } from 'core/entity/legal-configuration/card/cfa/description';

export class CardCFADescriptionMapper implements NetworkResponseMapper<CardCFADescription> {
    fromJson(json: any): CardCFADescription {
        return new CardCFADescription(
            json.id,
            json.name,
            json.message
        )
    }
}
