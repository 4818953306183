import { Entity } from 'core/entity';
import { List } from 'immutable';
import { AdviceTransactionSnapshot } from 'core/entity/personal-finance/advice/transaction-snapshot';

export class AdviceTransactionPeriodSnapshot extends Entity {
    transactions: List<AdviceTransactionSnapshot>;
    startDatetime: Date;
    endDatetime: Date;

    constructor(
        transactions: List<AdviceTransactionSnapshot>,
        startDatetime: Date,
        endDatetime: Date
    ) {
        super();
        this.transactions = transactions;
        this.startDatetime = startDatetime;
        this.endDatetime = endDatetime;
    }
}
