import { Entity } from 'core/entity';
import { UserTransaction } from 'core/entity/personal-finance/transaction';
import { TransactionType } from 'core/entity/personal-finance/transaction/type';
import { List } from 'immutable';

export class TransactionUpdateResult extends Entity {
    updatedTransactions: List<UserTransaction>;
    similarTransactions?: TransactionUpdateResult.Similars;

    constructor(
        updatedTransactions: List<UserTransaction>,
        similarTransactions: TransactionUpdateResult.Similars = null
    ) {
        super();
        this.updatedTransactions = updatedTransactions;
        this.similarTransactions = similarTransactions;
    }
}

export module TransactionUpdateResult {
    export class Similars extends Entity {
        transactions: List<string>;
        updatingConfiguration: TransactionUpdateResult.SimilarsUpdatingConfiguration;

        constructor(
            transactions: List<string>,
            updatingConfiguration: TransactionUpdateResult.SimilarsUpdatingConfiguration
        ) {
            super();
            this.transactions = transactions;
            this.updatingConfiguration = updatingConfiguration;
        }
    }

    export class SimilarsUpdatingConfiguration extends Entity {
        categoryId: string;
        transactionType: TransactionType;

        constructor(
            categoryId: string,
            transactionType: TransactionType
        ) {
            super();
            this.categoryId = categoryId;
            this.transactionType = transactionType;
        }
    }
}
