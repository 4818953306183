import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanOption } from 'core/entity/loan/option';
import { LoanPeriodMapper } from 'data/http/mapper/loan/period';
import { LoanInterestTypeMapper } from 'data/http/mapper/loan/interest-type';
import { optional } from 'utils/index';
import { LoanRepaymentTypeMapper } from 'data/http/mapper/loan/repayment-type';

export class LoanOptionMapper implements NetworkResponseMapper<LoanOption> {
    fromJson(json: any): LoanOption {
        return new LoanOption(
            new LoanPeriodMapper().fromJson(json.period),
            new LoanInterestTypeMapper().fromJson(json.interest_rate_type),
            optional(json.interest_rate_update_period, updatePeriod => updatePeriod),
            new LoanRepaymentTypeMapper().fromJson(json.repayment_type)
        );
    }
}
