import { Card } from 'core/entity/card';
import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { CardCFAReviewReviewed } from 'core/entity/legal-configuration/card/cfa/review/reviewed';
import { Card as FsCard, getCardInfo  } from 'data/http/api/card/getCardInfo';
import { EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { WindowResizeEvent } from 'presentation/bus/event/window-resize-event';
import { CardIssuePhoneAlert } from 'presentation/components/alert/card-issue-phone';
import { CardIssueUrlAlert } from 'presentation/components/alert/card-issue-url';
import { BestCardBanner } from 'presentation/components/banner/best-card';
import { CardProfitsDescBanner } from 'presentation/components/banner/card-profits-desc';
import { CardPromotionBanner, CardPromotionBanner2 } from 'presentation/components/banner/card-promotion';
import { FixedCardIssue } from 'presentation/components/fixed/card-issue';
import { FixedTopElevator } from 'presentation/components/fixed/top-elevator';
import { CardIssuePromotionAlert } from 'presentation/components/flexible-alert/card-issue-promotion';
import {
  KONA_COMPANY_ID,
  RECOMMENDS_CARDS_FOR_NOT_REVIEWED_CHECK_CARDS,
  RECOMMENDS_CARDS_FOR_NOT_REVIEWED_CREDIT_CARDS,
} from 'presentation/components/info/card/constants';
import { CardInfoDetail } from 'presentation/components/info/card/detail';
import { CardIssueGuide } from 'presentation/components/info/card/detail/issueGuide';
import { RecommendCardViewModel } from 'presentation/components/info/card/model/recommend-card';
import { CardInfoProfits } from 'presentation/components/info/card/profits';
import { CardInfoProfitsModel } from 'presentation/components/info/card/profits/model';
import { CardLegalTerms } from 'presentation/components/legal-configuration/card';
import { RESPONSIVE } from 'presentation/components/responsive';
import {
  GA_ACTION,
  GA_CATEGORY,
  GA_DOMAIN,
  GA_EVENT_VALUE,
  GA_LABEL,
  sendGAEvent,
} from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP, BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import React from 'react';
import { getPromotionBannerText, lets } from 'utils/index';

import styles from './styles.pcss';

const shuffledRecommendCreditCards =
    RECOMMENDS_CARDS_FOR_NOT_REVIEWED_CREDIT_CARDS
      .flatMap(cardsGroupByCompany =>
        cardsGroupByCompany
          .sortBy(Math.random)
          .slice(0, 1),
      );
const shuffledRecommendCheckCards =
    RECOMMENDS_CARDS_FOR_NOT_REVIEWED_CHECK_CARDS
      .flatMap(cardsGroupByCompany =>
        cardsGroupByCompany
          .sortBy(Math.random)
          .slice(0, 1),
      );

enum Tab {
    PROFITS, DETAIL
}

interface Props {
    card: Card;
    legalConfig?: CardLegalConfiguration;
    profits?: CardInfoProfitsModel;
}

interface State {
    tab: Tab;
    popupIssueUrl: boolean;
    popupIssuePhone: boolean;
    popupIssuePromotion: boolean;
    gaCategory: string;
    atTop: boolean;
    lessThanTablet: boolean;
    banksaladCard?: FsCard;
}

export class CardInfo extends React.Component<Props, State> {
    private subscriptionBag = new SubscriptionBag();
    state = {
      tab: this.props.profits ?
        Tab.PROFITS :
        Tab.DETAIL,
      popupIssueUrl: false,
      popupIssuePhone: false,
      popupIssuePromotion: false,
      gaCategory: '',
      atTop: true,
      lessThanTablet: window.innerWidth <= RESPONSIVE.TABLET,
      banksaladCard: {} as FsCard,
    };

    componentDidMount() {
      (async () => this.setState({ banksaladCard: await getCardInfo(this.props.card.id) }))();
      this.sendImpressionEvents();

      EventBus.toObservable().subscribe(
        (event: Event) => {
          if (event instanceof DocumentScrollEvent) {
            lets(event.scrollTop < 10, it => {
              if (this.state.atTop !== it) {
                this.setState({
                  atTop: it,
                });
              }
            });
          } else if (event instanceof WindowResizeEvent) {
            lets(event.width <= RESPONSIVE.TABLET, it => {
              if (this.state.lessThanTablet !== it) {
                this.setState({
                  lessThanTablet: it,
                });
              }
            });
          }
        },
      ).unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    render() {
      const { card, profits, legalConfig } = this.props;
      const {
        tab,
        popupIssueUrl,
        popupIssuePhone,
        popupIssuePromotion,
        atTop,
        lessThanTablet,
        banksaladCard,
      } = this.state;
      const issuableUrl = card.isIssuableUrl();
      const inPromotion = card.isInPromotion();
 
      const isCashbackCardPromotion = Boolean(banksaladCard?.cashback_promotion);

      // 이용유도 프로모션을 진행중인 카드 중 일부는 기존 2개의 버튼이 아닌 1개만 노출하도록 합니다. (제휴사 요청)
      // https://banksalad.slack.com/archives/C012U71T9A4/p1658817385297489?thread_ts=1655958473.163619&cid=C012U71T9A4
      const oneButtonExposedCardCompanyGuids = ['CRD0001', "CRD0002"];
            
      const isOneButtonExposedCards = isCashbackCardPromotion
        ? oneButtonExposedCardCompanyGuids
          .find(guid => guid === banksaladCard.organization.guid) !== undefined
        : false;

      const issueText = isCashbackCardPromotion ? '카드 자세히보기' : '온라인신청';

      return (
        <div className={ styles.back }>
          <section className={ styles.cover }>
            <div className={ styles.container }>
              <h3 className={ styles.type }>
                <span>{ card.companyName }</span>
                <span>{ this.typeToString(card.type) }</span>
              </h3>
              <img
                src={ card.imageUrl }
                className={ styles.defaultImage }
                onLoad={ this.onLoadImage }
              />
              <h1 className={ styles.name }>{ card.name }</h1>
              {
                legalConfig &&
                            legalConfig.issuingDisabled ?
                  <p className={ styles.desc }>해당 카드사의 요청에 의해 현재 추천이 중단된 상품입니다</p> :
                  <>
                    <p className={ styles.desc }>{ card.description }</p>
                    { 
                      this.renderIssueButtons(
                        issueText, 
                        isCashbackCardPromotion, 
                        isOneButtonExposedCards
                      ) 
                    }
                  </>
              }
              { card.stopped && <div className={ styles.stopped }>발급 중단된 카드입니다</div> }
            </div>
          </section>
          {
            this.isRecommendsCardVisible() &&
            this.renderRecommendCards()
          }
          {
            !profits &&
            this.renderLinkBannerOfBestCard()
          }
          <section>
            <div className={ styles.container }>
              <CardIssueGuide
                companyId={ card.companyId }
                companyName={ card.companyName }
              />
            </div>
          </section>
          <section className={ styles.wrap }>
            <div className={ styles.container }>
              <div className={ styles.box }>
                { this.renderTab() }
                {
                  !isCashbackCardPromotion && inPromotion && (
                    <button
                      onClick={ () => {
                        this.openIssueUrlAlert(GA_CATEGORY.CARD_PROFITS.BANNER);
                        sendGAEvent(
                          GA_DOMAIN.CARD,
                          tab === Tab.PROFITS ?
                            GA_CATEGORY.CARD_PROFITS.PROFITS :
                            GA_CATEGORY.CARD_PROFITS.DETAIL,
                          GA_ACTION.POPUP_ISSUE,
                          GA_LABEL.ISSUE('프로모션배너', card.companyName, card.id),
                        );
                      } }
                      className={ styles.promotionBanner }
                    >
                      <CardPromotionBanner data={ card.annualCostPromotion } />
                    </button>
                  )
                }
                
                {isCashbackCardPromotion && (
                  <button
                    onClick={ () => {
                      sendGAEvent(
                        GA_DOMAIN.CARD,
                        tab === Tab.PROFITS ?
                          GA_CATEGORY.CARD_PROFITS.PROFITS :
                          GA_CATEGORY.CARD_PROFITS.DETAIL,
                        GA_ACTION.POPUP_ISSUE,
                        GA_LABEL.ISSUE('프로모션배너', card.companyName, card.id),
                      );
                      const promotionId = 
                      banksaladCard.cashback_promotion.card_organization_promotion_id;
                      
                      window.location.assign(
                        `/product/cards/event/${promotionId}`
                      );
                    } }
                    className={ styles.promotionBanner }
                  >
                    <CardPromotionBanner2 bannerText={ getPromotionBannerText(banksaladCard) } />
                  </button>
                )
                }
                { this.renderContent() }
              </div>
              {
                <div className={ styles.buttons }>
                  {
                    profits ? (
                      <a href={ BANKSALAD_SITEMAP.CARDS_PROFITS } className={ styles.main }>
                        결과 리스트로
                      </a>
                    ) : (
                      <a href={ BANKSALAD_SITEMAP.MAIN } className={ styles.main }>
                        뱅크샐러드 메인으로
                      </a>
                    )
                  }
                  {
                    legalConfig &&
                    !legalConfig.issuingDisabled &&
                    <button
                      onClick={ () => {
                        if (issuableUrl) {
                          this.openIssueUrlAlert(GA_CATEGORY.CARD_PROFITS.BOTTOM);
                          sendGAEvent(
                            GA_DOMAIN.CARD,
                            GA_CATEGORY.CARD_PROFITS.BOTTOM,
                            GA_ACTION.POPUP_ISSUE,
                            GA_LABEL.ISSUE('해당카드사로이동', card.companyName, card.id),
                            inPromotion ?
                              GA_EVENT_VALUE.IN_PROMOTION :
                              GA_EVENT_VALUE.NOT_IN_PROMOTION,
                          );
                        }
                      } }
                      className={ styles.issue }
                    >
                        해당 카드사로 이동
                    </button>
                  }
                </div>
              }
              {
                profits && (
                  <div className={ styles.cardProfitsDescBanner }>
                    <CardProfitsDescBanner />
                  </div>
                )
              }
              <div className={ styles.terms }>
                <CardLegalTerms
                  config={ legalConfig }
                  cardType={ card.type }
                />
              </div>
            </div>
          </section>
          {
            !atTop &&
                    !card.stopped &&
                    !(legalConfig && legalConfig.issuingDisabled) &&
                    <FixedCardIssue
                      card={ card }
                      issueText={ issueText }
                      gaCategory={ GA_CATEGORY.CARD_PROFITS.FIXED_ISSUE }
                      isOneButtonExposedCards={ isOneButtonExposedCards }
                    />
          }
          {
            !atTop &&
                    <FixedTopElevator
                      bottom={ !card.stopped ? lessThanTablet ? 64 : 200 : null }
                      gaCategory={ GA_CATEGORY.CARD_PROFITS.FIXED }
                    />
          }
          {
            popupIssuePhone &&
                    card.issuePhoneNumber &&
                    this.renderAlert()
          }
          {
            popupIssueUrl && (
              card.issueUrlDesktop ||
                        card.issueUrlMobile
            ) &&
                    this.renderAlert()
          }
          { popupIssuePromotion && this.renderAlert() }
        </div>
      );
    }

    private renderAlert = () => {
      const { popupIssuePhone, gaCategory } = this.state;
      const { card } = this.props;

      if (popupIssuePhone)
        return (
          <CardIssuePhoneAlert
            cardId={ card.id }
            issuePhoneNumber={ card.issuePhoneNumber }
            companyImageUrl={ card.companyImageUrl }
            onCancel={ () => this.setState({ popupIssuePhone: false }) }
          />
        );

      return card.annualCostPromotion ?
        <CardIssuePromotionAlert
          cardId={ card.id }
          description={ card.annualCostPromotion.description }
          summary={ card.annualCostPromotion.summary }
          companyName={ card.companyName }
          issueUrlDesktop={ card.issueUrlDesktop }
          issueUrlMobile={ card.issueUrlMobile }
          onCancel={ () => this.setState({ popupIssuePromotion: false }) }
        /> :
        <CardIssueUrlAlert
          cardId={ card.id }
          issueUrlDesktop={ card.issueUrlDesktop }
          issueUrlMobile={ card.issueUrlMobile }
          companyName={ card.companyName }
          companyImageUrl={ card.companyImageUrl }
          gaCategory={ gaCategory }
          onCancel={ () => this.setState({ popupIssueUrl: false }) }
        />;
    };

    private renderTab() {
      const { profits } = this.props;
      const tabs = profits ?
        [Tab.PROFITS, Tab.DETAIL] :
        [Tab.DETAIL];

      const toTabName = (type: Tab) => {
        return lets(type, it => {
          switch (it) {
          case Tab.PROFITS:
            return '예상 월혜택';
          case Tab.DETAIL:
            return '카드 정보';
          }
        });
      };

      return (
        <ul className={ styles.tab }>
          {
            tabs.map((tab, i) =>
              <li
                key={ `card-tabs-${i}` }
                style={ { width: `${100 / tabs.length}%` } }
                className={ this.state.tab === tab ? styles.activeTab : null }
              >
                <button
                  className={ styles.tabButton }
                  onClick={ () => {
                    this.setState({ tab });
                    sendGAEvent(
                      GA_DOMAIN.CARD, 
                      GA_CATEGORY.CARD_PROFITS.DETAIL, 
                      GA_ACTION.TAB, 
                      toTabName(tab)
                    );
                  } }
                >
                  { toTabName(tab) }
                </button>
              </li>,
            )
          }
        </ul>
      );
    }

    private renderIssueButtons(
      issueText: string, 
      isCardPromotion: boolean, 
      isOneButtonExposedCards: boolean
    ) {
      const { card } = this.props;
      const issuableUrl = card.isIssuableUrl();
      const issuablePhone = card.isIssuableCall();
      const inPromotion = card.isInPromotion();

      return (
        <div className={ styles.issueButtons }>
          {isOneButtonExposedCards ? (
            <button
              onClick={ () => {
                if (issuableUrl) {
                  this.openIssueUrlAlert(GA_CATEGORY.CARD_PROFITS.HERO);
                  sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${card.companyName}`);
                  sendGAEvent(
                    GA_DOMAIN.CARD,
                    GA_CATEGORY.CARD_PROFITS.HERO,
                    GA_ACTION.POPUP_ISSUE,
                    GA_LABEL.ISSUE(issueText, card.companyName, card.id),
                    inPromotion ? GA_EVENT_VALUE.IN_PROMOTION : GA_EVENT_VALUE.NOT_IN_PROMOTION,
                  );
                }
              } }
              className={ issuableUrl ? styles.issueOnline : styles.issueOnlineDisabled }
            >
                카드 자세히 보기
              {!isCardPromotion && inPromotion && (
                <a className={ styles.issueBubble }>
                이벤트
                  <br />
                진행중
                </a>
              )}
            </button>
          ) : (
            <>
              <button
                onClick={ () => {
                  if (issuableUrl) {
                    this.openIssueUrlAlert(GA_CATEGORY.CARD_PROFITS.HERO);
                    sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${card.companyName}`);
                    sendGAEvent(
                      GA_DOMAIN.CARD,
                      GA_CATEGORY.CARD_PROFITS.HERO,
                      GA_ACTION.POPUP_ISSUE,
                      GA_LABEL.ISSUE(issueText, card.companyName, card.id),
                      inPromotion ? GA_EVENT_VALUE.IN_PROMOTION : GA_EVENT_VALUE.NOT_IN_PROMOTION,
                    );
                  }
                } }
                className={ issuableUrl ? styles.issueOnline : styles.issueOnlineDisabled }
              >
                { issueText }
                {!isCardPromotion && inPromotion && (
                  <a className={ styles.issueBubble }>
                    이벤트
                    <br />
                    진행중
                  </a>
                )}
              </button>
              <button
                onClick={ () => {
                  if (issuablePhone) {
                    this.openIssuePhoneAlert();
                    sendPixelEvent(PIXEL.CARD_ISSUE, `카드_${card.companyName}`);
                    sendGAEvent(
                      GA_DOMAIN.CARD,
                      GA_CATEGORY.CARD_PROFITS.HERO,
                      GA_ACTION.ISSUE,
                      GA_LABEL.ISSUE('전화신청', card.companyName, card.id),
                    );
                  }
                } }
                className={ issuablePhone ? styles.issueCall : styles.issueCallDisabled }
              >
                전화 신청
              </button>
            </>
          )}
        </div>
      );
    }

    private renderContent() {
      const { card, profits, legalConfig } = this.props;
      const { tab } = this.state;

      switch (tab) {
      case Tab.PROFITS:
        return <CardInfoProfits data={ profits } />;
      case Tab.DETAIL:
        return (
          <CardInfoDetail
            card={ card }
            cardConfig={ legalConfig }
          />
        );
      }
    }

    private openIssueUrlAlert(gaCategory: string) {
      const { card } = this.props;
      const inPromotion = card.isInPromotion();

      if (inPromotion) {
        this.setState({
          popupIssuePromotion: true,
          gaCategory,
        });
      } else {
        this.setState({
          popupIssueUrl: true,
          gaCategory,
        });
      }
    }

    private openIssuePhoneAlert() {
      this.setState({
        popupIssuePhone: true,
      });
    }

    private typeToString(type: Card.Type) {
      return lets(type, it => {
        switch (it) {
        case Card.Type.CHECK:
          return '체크카드';
        case Card.Type.CREDIT:
          return '신용카드';
        case Card.Type.HYBRID:
          return '하이브리드 카드';
        }
      });
    }

    private renderRecommendCards = () => {
      const renderRecommendCard = (card: RecommendCardViewModel) => (
        <li key={ card.id } className={ styles.recommendCardWrap }>
          <a
            href={ BANKSALAD_SITEMAP_BY_ID.CARD(card.id) }
            className={ styles.recommendCard }
            onClick={ this.onClickRecommendCard(card.companyName, card.id) }
          >
            <div className={ styles.recommendCardImageContainer }>
              <img
                src={ card.imageUrl }
                alt={ `${card.name}카드 이미지` }
                className={ styles.defaultImage }
                onLoad={ this.onLoadImage }
              />
              <div className={ styles.hoverImage }>카드정보 보기</div>
            </div>
            <ul className={ styles.recommendCardTags }>
              <li className={ styles.recommendCardAvailableTag }>발급가능</li>
              {
                card.annualPromotion &&
                        <li className={ styles.recommendCardPromotionTag }>연회비 캐시백</li>
              }
            </ul>
            <strong className={ styles.recommendCardTitle }>{ card.name }</strong>
          </a>
        </li>
      );
      const cards = this.props.card.type === Card.Type.CHECK ?
        shuffledRecommendCheckCards : shuffledRecommendCreditCards;

      return (
        <section className={ styles.recommendCardsWrap }>
          <div className={ styles.container }>
            <h3>이 상품을 본 고객이 <strong>많이 본 다른상품</strong></h3>
            <ul className={ styles.recommendCards }>
              { cards.map(card => renderRecommendCard(card)) }
            </ul>
          </div>
        </section>
      );
    };

    private onClickRecommendCard = (companyName: string, id: number) => () => (
      sendGAEvent(
        GA_DOMAIN.CARD,
        GA_CATEGORY.CARD_PROFITS.RECOMMEND_CARDS,
        GA_ACTION.LINK.CARDS_DETAIL,
        `${companyName}_${id}`,
      )
    );

    private renderLinkBannerOfBestCard = () => (
      <section>
        <div className={ styles.container }>
          <div className={ styles.bannerOfBestCardWrap }>
            <BestCardBanner gaCategory={ GA_CATEGORY.CARD_PROFITS.BANNER_QUESTION }/>
          </div>
        </div>
      </section>
    );

    private isRecommendsCardVisible = () => {
      const { card, legalConfig } = this.props;

      return (
        legalConfig && !(legalConfig.cfa.review instanceof CardCFAReviewReviewed) &&
            card && (card.companyId !== KONA_COMPANY_ID)
      );
    };

    private onLoadImage = (e: React.ChangeEvent<HTMLImageElement>) => {
      const element = e.target;

      element.className = element.height <= element.width ?
        styles.landscapeImage : styles.portraitImage;
    };

    private sendImpressionEvents = () => {
      if (!this.isRecommendsCardVisible()) return;

      const cards = this.props.card.type === Card.Type.CHECK ?
        shuffledRecommendCheckCards : shuffledRecommendCreditCards;

      cards.forEach(card => sendGAEvent(
        GA_DOMAIN.CARD,
        GA_CATEGORY.CARD_PROFITS.RECOMMEND_CARDS,
        GA_ACTION.PRODUCT_IMPRESSION,
        `${card.companyName}_${card.id}`,
      ));
    }
}
