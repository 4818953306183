import { Entity } from 'core/entity';
import { CardCategoryBenefit } from 'core/entity/card/category-benefit';
import { CardCategorySpecialBenefit } from 'core/entity/card/category-special-benefit';
import { List } from 'immutable';

export class CardBenefitGroup extends Entity {
    categoryBenefits: List<CardCategoryBenefit>;
    categorySpecialBenefits: List<CardCategorySpecialBenefit>;
    title?: string;
    additionalAnnualCost: number;

    constructor(
        categoryBenefits: List<CardCategoryBenefit>,
        categorySpecialBenefits: List<CardCategorySpecialBenefit>,
        additionalAnnualCost: number
    ) {
        super();
        this.categoryBenefits = categoryBenefits;
        this.categorySpecialBenefits = categorySpecialBenefits;
        this.additionalAnnualCost = additionalAnnualCost;
    }
}
