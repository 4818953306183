import { CashAccountTransactionPrimaryData } from 'core/entity/personal-finance/transaction/primary-data/cash-account';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { SpiderDataMapper } from 'data/http/mapper/personal-finance/spider-data';
import { TransactionProductPrimaryDataMapper } from 'data/http/mapper/personal-finance/transaction/product/primary-data';
import { parseDate, optional } from 'utils/index';

export class CashAccountTransactionPrimaryDataMapper implements NetworkResponseMapper<CashAccountTransactionPrimaryData> {
    fromJson(json: any): CashAccountTransactionPrimaryData {
        return new CashAccountTransactionPrimaryData(
            json.id,
            json.title,
            parseDate(json.transacted_at),
            new AmountMapper().fromJson(json.amount),
            optional(json.product, it => new TransactionProductPrimaryDataMapper().fromJson(it)),
            optional(json.spider, it => new SpiderDataMapper().fromJson(it))
        );
    }

}
