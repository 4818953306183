import React from 'react';
import { List } from 'immutable';

import { Alert, AlertSize } from 'presentation/components/alert';
import { CardShinhanCombo } from 'core/entity/card/shinhan-combo';
import { uniqueKey } from 'utils/index';
import { Selector } from 'presentation/components/selector';
import {
    SelectorModel,
    SelectorTheme
} from 'presentation/components/selector/model';
import { toCurrency } from 'presentation/module/sugar';
import { CardInfoCategoryBenefits } from 'presentation/components/info/card/detail/benefits/category';
import { CardSelectiveGroup } from 'core/entity/card/selective-group';
import { GA_CATEGORY } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

enum Tab {
    INFO,
    COMBO1,
    COMBO2
}

interface Props {
    shinhanCombo: CardShinhanCombo,
    containRestrictions: boolean,
    onCancel: () => void
}

interface State {
    tab: Tab,
    selectedCombo1: string,
    selectedCombo2: string
}

export class CardShinhanComboAlert extends React.Component<Props, State> {
    state = {
        tab: Tab.INFO,
        selectedCombo1: this.props.shinhanCombo.combo1.groups.first().title,
        selectedCombo2: this.props.shinhanCombo.combo2.groups.first().title
    };

    render() {
        const { onCancel } = this.props;

        return (
            <Alert
                title="Combo 서비스란?"
                onCancel={ () => onCancel() }
                size={ AlertSize.HUGE }
            >
                { this.renderTab() }
                { this.renderContent() }
            </Alert>
        )
    }

    private renderTab() {
        const table = [
            { key: Tab.INFO, value: '서비스 안내' },
            { key: Tab.COMBO1, value: 'Combo Group1' },
            { key: Tab.COMBO2, value: 'Combo Group2' }
        ];

        return (
            <ul className={ styles.tabs }>
            {
                table.map(item =>
                    <li
                        key={ uniqueKey() }
                        style={{ width: `${100 / table.length}%` }}
                        className={ styles.tab }
                    >
                        <button
                            onClick={ () => this.setState({ tab: item.key }) }
                            className={ this.state.tab === item.key ? styles.active : null }
                        >
                            { item.value }
                        </button>
                    </li>
                )
            }
            </ul>
        )
    }

    private renderContent() {
        const { combo1, combo2 } = this.props.shinhanCombo;

        switch (this.state.tab) {
            case Tab.INFO:
                return this.renderInfo();
            case Tab.COMBO1:
                return this.renderCombo(
                    combo1,
                    this.state.selectedCombo1,
                    selected => this.setState({ selectedCombo1: selected })
                );
            case Tab.COMBO2:
                return this.renderCombo(
                    combo2,
                    this.state.selectedCombo2,
                    selected => this.setState({ selectedCombo2: selected })
                );
            default:
                return this.renderInfo();
        }
    }

    private renderInfo = () => (
        <div className={ styles.info }>
            <h5 className={ styles.title }>신한 Combo 서비스</h5>
            <p className={ styles.desc }>카드가 제공하는 혜택과 별도로 내가 원하는 혜택을 추가로 선택/구매하여 이용할 수 있는 서비스입니다</p>

            <dl className={ styles.explain }>
            <dt>Combo Group1</dt>
            <dd>
                - 1인당 최대 2개까지 신청 가능함<br/>
                - 통신사 카드 등 각종 멤버십과 중복할인 가능하며 결제일에 할인이 적용됨<br/>
                (단, Combo가 탑재된 카드에서 제공되는 카드 서비스와 Combo서비스가 중복될 경우 해당 카드의 서비스만 제공됨)<br/>
                - 전월 이용금액 따라 차등 제공됨<br/>
                (각 Combo별 상세내용 및 전월 이용금액 세부기준 참고)<br/>
            </dd>
            <dt>Combo Group2</dt>
            <dd>
                - 1인당 신청개수의 제한 없음<br/>
                - 시작일 및 서비스 기간은 각 Combo별로 상이함<br/>
                - 결제 전 “Combo 이용해주세요”라고 미리 알린 후 서비스를 이용하여야 함
            </dd>
            </dl>
        </div>
    );

    private renderCombo(
        group: CardSelectiveGroup,
        selected: string,
        onChangeSelected: (selected: string) => void
    ) {
        const { containRestrictions } = this.props;
        const { groups } = group;
        const benefits = groups
            .find(group => group.title === selected)
            .categoryBenefits;

        return (
            <div>
                <Selector
                    data={ List(
                        groups.map(group =>
                            new SelectorModel(
                                group.title,
                                group.title.replace('^', ' '),
                                group.additionalAnnualCost !== null &&
                                group.additionalAnnualCost > 0 &&
                                `연회비 ${toCurrency(group.additionalAnnualCost)}원 추가`
                            )
                        )
                    ) }
                    chunkSize={ 4 }
                    chunkSizeForTablet={ 3 }
                    chunkSizeForMobile={ 3 }
                    theme={ SelectorTheme.BLUEBERRY }
                    onChange={ result => onChangeSelected(result.id) }
                />
                <CardInfoCategoryBenefits
                    benefits={ benefits }
                    containRestrictions={ containRestrictions }
                    gaCategory={ GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_SHINHAN_ALERT }
                />
            </div>
        );
    }
}
