import { List } from 'immutable';
import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { QuestionCategory } from 'core/entity/service-center/category';
import { STATIC_CATEGORIES, ZendeskQuestionMapper } from 'data/http/mapper/zendesk/category';
import { ServiceCenterArticle } from 'core/entity/service-center/article';
import { ServiceCenterAnswerMapper } from 'data/http/mapper/zendesk/article';
import { ServiceCenterQuestion } from 'core/entity/service-center/question';
import { ServiceCenterQuestionMapper } from 'data/http/mapper/zendesk/question';

export class ZendeskQuestionApiProvider extends HttpProvider {
    getCategories(): Observable<List<QuestionCategory>> {
        return this.requestGET(
            '/api/v2/help_center/ko/categories',
        ).map((result: any) => {
            const { categories } = result;
            const mergedCategories = STATIC_CATEGORIES.concat(categories);

            return List(mergedCategories.map((category: any) => (
                new ZendeskQuestionMapper().fromJson(category)
            )));
        });
    }

    getQuestionsById(id: number): Observable<List<ServiceCenterQuestion>> {
        return this.requestGET(
            `/api/v2/help_center/ko/categories/${id}/articles.json?per_page=100`
        )
        .map((result: any) => (
            List(result.articles.map((article: any) => (
                new ServiceCenterQuestionMapper().fromJson(article)
            )))
        ))
    }

    getArticleById(id: number): Observable<ServiceCenterArticle> {
        return this.requestGET(
            `/api/v2/help_center/ko/articles/${id}.json?include=users`
        )
        .map((json: any) => (
            new ServiceCenterAnswerMapper().fromJson(json)
        ))
    }
} 
