import { UserCardBill } from 'core/entity/personal-finance/card-bill';
import { UserCardBillRepositoryType } from 'core/repository/personal-finance/card-bill';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class GetUserCardBill extends UseCase<UserCardBill> {
    billId: string;
    private repository: UserCardBillRepositoryType;

    constructor(repository: UserCardBillRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserCardBill> {
        return this.repository.get(this.billId);
    }

    protected validate(): boolean {
        return !!this.billId;
    }

}
