import { trackAnalyticsEvent } from "@banksalad/js-banksalad";
import { captureException } from "@sentry/browser";

import { sendAmplitudeEvent } from "../amplitude";
import { sendGA4Event } from "../ga";
import { EventName, EventParam } from "./types/event-name";
import { isBanksaladWebView } from "utils/is-banksalad-web-view";

/**
 * EventType을 신규 이벤트는 EventName으로, 구 이벤트(ga3)는 string을 사용합니다.
 * e.g. sendTrackEvent<NewEventParam, EventName>(), sendTrackEvent<OldEventParam, string>()
 */
export type SendTrackEventParam<Param extends EventParam, EventType> = {
  eventParam: {
    event_type: EventType;
  } & Param;
};

export const sendTrackEvent = <Param, EventType extends EventName | string = EventName>({
  eventParam: { event_type: eventType, ...eventParam },
}: SendTrackEventParam<Param, EventType>) => {
  if (isBanksaladWebView()) {
    try {
      trackAnalyticsEvent({
        name: eventType.toString(),
        eventPropertiesJson: {
          ...eventParam,
        }
      });
    } catch (error) {
      console.error(error);
      captureException(error);
    }  

    return;
  }

  sendAmplitudeEvent({ eventType, eventParam });
  sendGA4Event({ eventType, eventParam });
  // TODO: Pixel, Kakao event logging 추가
};
