import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanCreditScoreAgency } from 'core/entity/loan/credit-score-agency';

export class LoanCreditScoreAgencyMapper implements BrowserStorageMapper<LoanCreditScoreAgency> {
    toJson(entity: LoanCreditScoreAgency): any {
        switch (entity) {
            case LoanCreditScoreAgency.KCB:
                return 'kcb';
            case LoanCreditScoreAgency.NICE:
                return 'nice';
            case LoanCreditScoreAgency.BANK_CUSTOM:
                return 'bank_custom';
            default:
                return null;
        }
    }

    toEntity(value: any): LoanCreditScoreAgency {
        switch (value) {
            case 'kcb':
                return LoanCreditScoreAgency.KCB;
            case 'nice':
                return LoanCreditScoreAgency.NICE;
            case 'bank_custom':
                return LoanCreditScoreAgency.BANK_CUSTOM;
            default:
                return null;
        }
    }

}
