import { NetworkResponseMapper } from 'data/http/mapper';
import { FinanceSubSectorContentSummary } from 'core/entity/finance-sector/content-summary';
import { lets } from 'utils/index';

export class FinanceSubSectorContentSummaryMapper implements NetworkResponseMapper<FinanceSubSectorContentSummary> {
    fromJson(json: any): FinanceSubSectorContentSummary {
        return new FinanceSubSectorContentSummary(
            json.id,
            json.title,
            json.thumbnail_image_url,
            lets(json.type, it => {
                switch (it) {
                    case 'column':
                        return FinanceSubSectorContentSummary.Type.COLUMN;
                    case 'card-news':
                        return FinanceSubSectorContentSummary.Type.CARD_NEWS;
                    case 'card-comparison':
                        return FinanceSubSectorContentSummary.Type.CARD_COMPARISON;
                    default:
                        return FinanceSubSectorContentSummary.Type.COLUMN;
                }
            })
        )
    }
}
