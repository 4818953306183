import React from 'react';

import { toCurrency } from 'presentation/module/sugar';
import { lets } from 'utils/index';
import { CardQuestionSpending } from 'presentation/components/survey-form/card-spendings/model';
import { Store } from 'core/entity/store';
import { isMobile } from 'presentation/module/sugar';
import { InputCurrency } from 'presentation/components/input/currency';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    store: Store,
    spending?: CardQuestionSpending
    onChange?: (result: CardQuestionSpending) => void,
    gaCategory?: string
}

interface State {
    active: boolean,
    spending?: CardQuestionSpending
}

export class CardStoreForm extends React.Component<Props, State> {
    state = {
        active: false,
        spending: this.props.spending
    };

    private DEFAULT_STORE_IMAGE = 'https://cdn.banksalad.com/stores/default.png';

    render() {
        const { store, spending, gaCategory } = this.props;
        const { active } = this.state;
        const amount = spending ? spending.amount : 0;
        const activate = () => !active && this.setState({ active: true });
        const deactivate = () => active && this.setState({ active: false });

        return (
            <div
                onMouseMove={ !isMobile() ? activate : null }
                onMouseLeave={ deactivate }
                onTouchMove={ !isMobile() ? activate : null }
                onTouchEnd={ !isMobile() ? deactivate : null }
                onClick={ isMobile() ? activate : null }
                className={ styles.wrap }
            >
                <span className={ styles.name }>{ store.name }</span>
                {
                    amount > 0 && (
                        <span className={ styles.amount }>
                            { `월 ${toCurrency(amount)}원` }
                        </span>
                    )
                }
                <img
                    src={ store.imageUrl }
                    onError={ e => e.currentTarget.src = this.DEFAULT_STORE_IMAGE }
                    className={ styles.image }
                    onClick={ activate }
                />
                <div
                    style={{ display: active ? 'block' : 'none' }}
                    className={ styles.keypad }
                >
                    <div className={ styles.keypadInput }>
                        <InputCurrency
                            value={ amount }
                            color="#04C584"
                            fontSize={ 16 }
                            textAlign="right"
                            overflowMessage="너무 큰 금액은 입력할 수 없습니다"
                            onChange={ result => this.onChange(result) }
                            onFocus={ () => this.setState({ active: true }) }
                            onBlur={ e => gaCategory && sendGAEvent(GA_DOMAIN.CARD, gaCategory, GA_ACTION.INPUT, e.currentTarget.value) }
                        />
                    </div>
                    <ul className={ styles.preset }>
                        {
                            store.validSpendings.map(value => lets(amount === value, cancelable =>
                                <li key={ `${store.id}-${value}` }>
                                    <button
                                        onClick={ () => {
                                            this.onChange(cancelable ? 0 : value);
                                            gaCategory && (
                                                sendGAEvent(
                                                    GA_DOMAIN.CARD,
                                                    gaCategory,
                                                    cancelable ? GA_ACTION.CLEAR : GA_ACTION.INPUT_SHORTCUT,
                                                    cancelable ? '0' : value.toString()
                                                )
                                            );
                                        } }
                                        className={ cancelable ? styles.cancel : null }
                                    >
                                        { cancelable ? '취소' : this.toCurrencyKor(value) }
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        )
    }

    private onChange(amount: number) {
        const { store, onChange } = this.props;
        onChange && onChange(
            new CardQuestionSpending(
                store.id,
                store.name,
                amount
            )
        );
    }

    private toCurrencyKor(amount: number) {
        const TEN_THOUSAND = 10000;

        if(Math.trunc(amount / TEN_THOUSAND) > 0) {
            return `${Math.trunc(amount / TEN_THOUSAND)}만원`;
        }
        return `${amount}원`;
    }
}
