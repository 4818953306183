import { Entity } from 'core/entity';

export class MobileAuthenticationCode extends Entity {
    authenticationRequestId: string;

    constructor(
        authenticationRequestId: string
    ) {
        super();
        this.authenticationRequestId = authenticationRequestId;
    }
}
