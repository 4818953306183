import { NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoan } from 'core/entity/personalized-recommend/loan';
import { PersonalizedLoanGeneralTypeMapper } from 'data/http/mapper/personalized-recommend/loan/general-loan-type';
import { PersonalizedLoanRefinanceTypeMapper } from 'data/http/mapper/personalized-recommend/loan/refinance-loan-type';

export class PersonalizedLoanMapper implements NetworkResponseMapper<PersonalizedLoan> {
    fromJson(json: any): PersonalizedLoan {
        switch (json.type) {
            case 'general-credit-loan':
                return new PersonalizedLoanGeneralTypeMapper().fromJson(json);
            case 'refinance-loan':
                return new PersonalizedLoanRefinanceTypeMapper().fromJson(json);
        }
    }
}
