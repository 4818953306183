import { UserCardCreditLimit } from 'core/entity/personal-finance/card-credit-limit';
import { HttpProvider } from 'data/http';
import { UserCardCreditLimitMapper } from 'data/http/mapper/personal-finance/card-credit-limit';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';

export class UserCardCreditLimitsApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<List<UserCardCreditLimit>> {
        return this.requestGET(
            'api/v2/me/cards/companies/credit-limits',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map((limit: any) =>
                new UserCardCreditLimitMapper().fromJson(limit)
            ))
        )
    }

}
