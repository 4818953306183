import { Entity } from 'core/entity';
import { AdviceAction } from 'core/entity/personal-finance/advice/action';

export class AdviceMessage extends Entity {
    imageUrl?: string;
    body?: string;
    action?: AdviceAction;

    constructor(
        imageUrl: string = null,
        body: string = null,
        action: AdviceAction = null
    ) {
        super();
        this.imageUrl = imageUrl;
        this.body = body;
        this.action = action;
    }
}
