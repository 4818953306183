import React from 'react';

import { CardIssueGuideItem } from 'presentation/components/info/card/detail/issueGuide/item';
import { lets } from 'utils/index';
import { CITI_COMPANY_ID, KONA_COMPANY_ID, SUHYUP_COMPANY_ID } from 'presentation/components/info/card/constants';

interface Props {
    companyId: number;
    companyName: string;
}

export const CardIssueGuide = ({ companyId, companyName }: Props) => (
    lets(companyId, id => {
        switch (id) {
            case CITI_COMPANY_ID:
                return (
                    <CardIssueGuideItem
                        cardCompany={ companyName }
                        mobileImageUrl="https://cdn.banksalad.com/resources/images/cards/guide/citi_mobile.png"
                        pcImageUrl="https://cdn.banksalad.com/resources/images/cards/guide/citi_pc.png"
                    />
                );
            case SUHYUP_COMPANY_ID:
                return (
                    <CardIssueGuideItem
                        cardCompany={ companyName }
                        mobileImageUrl="https://cdn.banksalad.com/web/issue-guide/suhyup_mobile.png"
                        pcImageUrl="https://cdn.banksalad.com/web/issue-guide/suhyup_pc.png"
                    />
                );
            case KONA_COMPANY_ID:
                return (
                    <CardIssueGuideItem
                        cardCompany={ companyName }
                        mobileImageUrl="https://cdn.banksalad.com/resources/images/cards/guide/kona_mobile.png"
                        pcImageUrl="https://cdn.banksalad.com/resources/images/cards/guide/kona_pc.png"
                    />
                );
            default:
                return null;
        }
    })
);
