import Axios from "axios";
import { isProd } from "presentation/module/extension";

export interface FindOneCardByAlfredCardIdResponse {
  /** 없는 경우 null 반환 */
  card: Card | null;
}

// 필요한 명세만 가져왔습니다.
export interface Card {
  name: string;
  organization: OrganizationMessage;
  product_guid: string;
  cashback_promotion?: CardCashbackPromotion | undefined;
  annual_cost_promotion?: CardAnnualCostPromotion | undefined;

}

export interface CardCashbackPromotion {
  /** 프로모션 시작일자 */
  start_date_ms: string;
  /** 프로모션 종료일자 */
  end_date_ms?: string | undefined;
  /** 캐시백 금액 */
  cashback_amount_krw_0f: string;
  /** 카드사 프로모션 페이지 URL */
  promotion_url: string;
  /** 프로모션 ID */
  card_organization_promotion_id: string;
}

export interface CardAnnualCostPromotion {
  /** 프로모션 시작일자 */
  start_date_ms: string;
  /** 프로모션 종료일자 */
  end_date_ms?: string | undefined;
  /** 연회비 할인 퍼센트 */
  discount_percentage: number;
  /** 프로모션 ID */
  card_organization_promotion_id: string;
}

export interface OrganizationMessage {
  /** 기관구분 global unique id */
  guid: string;
  /** 기관명 */
  name: string;
  
}

export const getCardInfo = async (cardId: number) => {

  const API_PATH = isProd()
    ? `https://api.banksalad.com/v1/productcuration/find-one-card-by-alfred-card-id`
    : `https://api.staging.banksalad.com/v1/productcuration/find-one-card-by-alfred-card-id`;
  const { data } = await Axios.get(API_PATH, {
    params: {
      alfred_card_id: cardId,
    },
  });

  const { card } = data;

  return card as Card | null;
};
