import { Entity } from 'core/entity';

export class UserPreference extends Entity {
    initialDayOfMonth: number;
    marketingAgreementAccepted: boolean;
    marketingAgreementAcceptedAt: Date;
    kcbAgreementAcceptedAt: Date;
    splittingInstallment: boolean;
    injectingBankTransactionCategory: boolean;

    constructor(
        initialDayOfMonth: number,
        marketingAgreementAccepted: boolean,
        marketingAgreementAcceptedAt: Date,
        kcbAgreementAcceptedAt: Date,
        splittingInstallment: boolean,
        injectingBankTransactionCategory: boolean
    ) {
        super();
        this.initialDayOfMonth = initialDayOfMonth;
        this.marketingAgreementAccepted = marketingAgreementAccepted;
        this.marketingAgreementAcceptedAt = marketingAgreementAcceptedAt;
        this.kcbAgreementAcceptedAt = kcbAgreementAcceptedAt;
        this.splittingInstallment = splittingInstallment;
        this.injectingBankTransactionCategory = injectingBankTransactionCategory;
    }
}
