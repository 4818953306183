import { Entity } from 'core/entity';
import { CardCompanyCFAConfiguration } from 'core/entity/legal-configuration/card-company/cfa';

export class CardCompanyLegalConfiguration extends Entity {
    id: number;
    cfa: CardCompanyCFAConfiguration;
    disabledForCompliance: boolean;

    constructor(
        id: number,
        cfa: CardCompanyCFAConfiguration,
        disabledForCompliance: boolean
    ) {
        super();
        this.id = id;
        this.cfa = cfa;
        this.disabledForCompliance = disabledForCompliance;
    }
}
