import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoanLimitation } from 'core/entity/personalized-recommend/loan/limitation';
import { lets, optional } from 'utils/index';

export class PersonalizedLoanLimitationMapper implements NetworkResponseMapper<PersonalizedLoanLimitation> {
    fromJson(json: any): PersonalizedLoanLimitation {
        return new PersonalizedLoanLimitation(
            json.limitation,
            lets(json.status, status => {
                switch (status) {
                    case 'confirmed':
                        return PersonalizedLoanLimitation.Status.CONFIRMED;
                    case 'estimated':
                        return PersonalizedLoanLimitation.Status.ESTIMATED;
                    default:
                        throw new MappingError();
                }
            }),
            optional(json.wording),
            optional(json.additional_wording)
        );
    }
}
