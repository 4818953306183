import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';

export class ClearLoanRecommendCaches extends UseCase<void> {
    private repository: LoanRecommendRepositoryType;

    constructor(repository: LoanRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<void> {
        return Observable.zip(
            this.repository.clearSpec(),
            this.repository.clearFilter(),
            () => null as void
        );
    }

    protected validate(): boolean {
        return true;
    }

}
