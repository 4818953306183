import { BankAccountTransactionPrimaryData } from 'core/entity/personal-finance/transaction/primary-data/bank-account';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { SpiderDataMapper } from 'data/http/mapper/personal-finance/spider-data';
import { TransactionProductPrimaryDataMapper } from 'data/http/mapper/personal-finance/transaction/product/primary-data';
import { parseDate, optional } from 'utils/index';

export class BankAccountTransactionPrimaryDataMapper implements NetworkResponseMapper<BankAccountTransactionPrimaryData> {
    fromJson(json: any): BankAccountTransactionPrimaryData {
        return new BankAccountTransactionPrimaryData(
            json.title,
            json.category,
            parseDate(json.transacted_at),
            new AmountMapper().fromJson(json.amount),
            new AmountMapper().fromJson(json.balance_after_transaction),
            optional(json.product, it => new TransactionProductPrimaryDataMapper().fromJson(it)),
            optional(json.spider, it => new SpiderDataMapper().fromJson(it))
        );
    }

}
