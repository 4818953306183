import { HttpProvider } from 'data/http';
import { CreditMetadataMapper } from 'data/http/mapper/personal-credit/metadata';
import { CreditMetadata } from 'core/entity/personal-credit/metadata';
import { Observable } from 'rxjs';

export class CreditScoreMetadataApiProvider extends HttpProvider {
    getAll(): Observable<CreditMetadata> {
        return this.requestGET(
            'api/v2/credit-scores/KCB/metadata'
        ).map(result =>
            new CreditMetadataMapper().fromJson(result.data)
        )
    }
}
