import { PersonalizedLoanInterest } from 'core/entity/personalized-recommend/loan/interest';
import { PersonalizedLoanLimitation } from 'core/entity/personalized-recommend/loan/limitation';
import { PersonalizedLoanInterestPromotion } from 'core/entity/personalized-recommend/loan/interest-promotion';
import { List } from 'immutable';
import { PersonalizedLoanApplyLink } from 'core/entity/personalized-recommend/loan/app-link';
import { PersonalizedLoanGeneralType } from 'core/entity/personalized-recommend/loan/general-loan-type';
import { PersonalizedLoanRefinanceType } from 'core/entity/personalized-recommend/loan/refinance-loan-type';
import { PersonalizedLoan } from 'core/entity/personalized-recommend/loan';
import { PersonalizedLoanSubmitStatus } from 'core/entity/personalized-recommend/loan/submit-status';

const HYUNDAI_CAPITAL_NAME = '현대캐피탈';

declare module 'core/entity/personalized-recommend/loan' {
    export interface PersonalizedLoan {
        hasExtraInfoList(): boolean;
        hasDiscountInterestPromotion(): boolean;
        getFirebaseApplyLinkType(): string;
        isSubmitAlways(): boolean;
        isSubmitEnable(): boolean;
        isSubmitDisable(): boolean;
        isHyundaiCapital(): boolean;
        getThirdPartyAgreementUrl(): string;
    }
}

declare module 'core/entity/personalized-recommend/loan/general-loan-type' {
    export interface PersonalizedLoanGeneralType {
        hasExtraInfoList(): boolean;
        hasDiscountInterestPromotion(): boolean;
        getFirebaseApplyLinkType(): string;
        isSubmitAlways(): boolean;
        isSubmitEnable(): boolean;
        isSubmitDisable(): boolean;
        isHyundaiCapital(): boolean;
        getThirdPartyAgreementUrl(): string;
    }
}

PersonalizedLoanGeneralType.prototype.isSubmitAlways = function () {
    return PersonalizedLoanSubmitStatus.ALWAYS === this.submitStatus;
};

PersonalizedLoanGeneralType.prototype.isSubmitEnable = function () {
    return PersonalizedLoanSubmitStatus.ENABLE === this.submitStatus;
};

PersonalizedLoanGeneralType.prototype.isSubmitDisable = function () {
    return PersonalizedLoanSubmitStatus.DISABLE === this.submitStatus;
};

PersonalizedLoanGeneralType.prototype.isHyundaiCapital = function () {
    return this.organization.name === HYUNDAI_CAPITAL_NAME;
};

PersonalizedLoanGeneralType.prototype.getThirdPartyAgreementUrl = function () {
    return (this.apiContracted && PersonalizedLoanSubmitStatus.ENABLE === this.submitStatus) ?
        'https://banksalad.com/policies/third-parties/eight-percent/submit-document' :
        '';
};

PersonalizedLoanGeneralType.prototype.hasExtraInfoList = function () {
    return !List.of(
        this.issueReviewDescription,
        (this.loanInterest.loanInterestPromotion && this.loanInterest.loanInterestPromotion.description),
        this.description
    )
        .filter((item: any) => item)
        .isEmpty();
};

PersonalizedLoanGeneralType.prototype.hasDiscountInterestPromotion = function () {
    return (
        this.loanInterest.loanInterestPromotion &&
        this.loanInterest.loanInterestPromotion.type === PersonalizedLoanInterestPromotion.Type.INTEREST_DISCOUNT
    );
};

PersonalizedLoanGeneralType.prototype.getFirebaseApplyLinkType = function () {
    switch (this.applyLink.type) {
        case PersonalizedLoanApplyLink.Type.LOAN_APPLY:
            return 'apply';
        case PersonalizedLoanApplyLink.Type.CONFIRM_LOAN_INTEREST:
            return 'interest';
    }
};

declare module 'core/entity/personalized-recommend/loan/refinance-loan-type' {
    export interface PersonalizedLoanRefinanceType {
        hasExtraInfoList(): boolean;
        hasDiscountInterestPromotion(): boolean;
        getFirebaseApplyLinkType(): string;
        isSubmitAlways(): boolean;
        isSubmitEnable(): boolean;
        isSubmitDisable(): boolean;
        isHyundaiCapital(): boolean;
        getThirdPartyAgreementUrl(): string;
    }
}

PersonalizedLoanRefinanceType.prototype.isSubmitAlways = function () {
    return PersonalizedLoanSubmitStatus.ALWAYS === this.submitStatus;
};

PersonalizedLoanRefinanceType.prototype.isSubmitEnable = function () {
    return PersonalizedLoanSubmitStatus.ENABLE === this.submitStatus;
};

PersonalizedLoanRefinanceType.prototype.isSubmitDisable = function () {
    return PersonalizedLoanSubmitStatus.DISABLE === this.submitStatus;
};

PersonalizedLoanRefinanceType.prototype.isHyundaiCapital = function () {
    return this.organization.name === HYUNDAI_CAPITAL_NAME;
};

PersonalizedLoanRefinanceType.prototype.getThirdPartyAgreementUrl = function () {
    return (this.apiContracted && PersonalizedLoanSubmitStatus.ENABLE === this.submitStatus) ?
        'https://banksalad.com/policies/third-parties/eight-percent/submit-document' :
        '';
};

PersonalizedLoanRefinanceType.prototype.hasExtraInfoList = function () {
    return !List.of(
        this.issueReviewDescription,
        (this.loanInterest.loanInterestPromotion && this.loanInterest.loanInterestPromotion.description),
        this.description
    )
        .filter((item: any) => item)
        .isEmpty();
};

PersonalizedLoanRefinanceType.prototype.hasDiscountInterestPromotion = function () {
    return (
        this.loanInterest.loanInterestPromotion &&
        this.loanInterest.loanInterestPromotion.type === PersonalizedLoanInterestPromotion.Type.INTEREST_DISCOUNT
    );
};

PersonalizedLoanRefinanceType.prototype.getFirebaseApplyLinkType = function () {
    switch (this.applyLink.type) {
        case PersonalizedLoanApplyLink.Type.LOAN_APPLY:
            return 'apply';
        case PersonalizedLoanApplyLink.Type.CONFIRM_LOAN_INTEREST:
            return 'interest';
    }
};

declare module 'core/entity/personalized-recommend/loan/app-link' {
    export interface PersonalizedLoanApplyLink {
        isLoanApplyType(): boolean;
    }
}

PersonalizedLoanApplyLink.prototype.isLoanApplyType = function () {
    return this.type === PersonalizedLoanApplyLink.Type.LOAN_APPLY;
};

declare module 'core/entity/personalized-recommend/loan/interest' {
    export interface PersonalizedLoanInterest {
        isConfirmed(): boolean;
        toConditionString(): string;
    }
}

PersonalizedLoanInterest.prototype.isConfirmed = function () {
    return this.status === PersonalizedLoanInterest.Status.CONFIRMED;
};

PersonalizedLoanInterest.prototype.toConditionString = function () {
    return this.isConfirmed() ? '확정' : '예상';
};

declare module 'core/entity/personalized-recommend/loan/limitation' {
    export interface PersonalizedLoanLimitation {
        isConfirmed(): boolean;
        toConditionString(): string;
        toString(prefix?: boolean): string;
    }
}

PersonalizedLoanLimitation.prototype.isConfirmed = function () {
    return this.status === PersonalizedLoanLimitation.Status.CONFIRMED;
};

PersonalizedLoanLimitation.prototype.toConditionString = function () {
    return this.isConfirmed() ? '확정' : '예상';
};

PersonalizedLoanLimitation.prototype.toString = function (prefix: boolean = true) {
    if (!!this.wording) return this.wording;
    const units = List.of(
        { value: 1000, text: '천원' },
        { value: 10000, text: '만원' },
        { value: 100000000, text: '억원' }
    );
    const unit = units.findLast(it => this.limitation >= it.value);
    const value = unit ?
        (
            this.limitation > 100000000 &&
            this.limitation % unit.value > 0
        ) ?
            (this.limitation / unit.value).toFixed(1) :
            Math.floor(this.limitation / unit.value) :
        this.limitation.toString();
    const prefixText = prefix && !this.isConfirmed() ? '최대 ' : '';

    return `${prefixText}${value}${unit.text}`;
};

declare module 'core/entity/personalized-recommend/loan/interest-promotion' {
    export interface PersonalizedLoanInterestPromotion {
        isInterestDiscountPromotion(): boolean;
    }
}

PersonalizedLoanInterestPromotion.prototype.isInterestDiscountPromotion = function () {
    return this.type === PersonalizedLoanInterestPromotion.Type.INTEREST_DISCOUNT;
};
