import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreJointSuretyMapper } from 'data/http/mapper/personal-credit/joint-sureties-status/joint-surety-status';
import { UserCreditScoreJointSuretyStatus } from 'core/entity/personal-credit/joint-surety-status';

export class UserCreditScoreJointSuretyStatusMapper implements NetworkResponseMapper<UserCreditScoreJointSuretyStatus> {
    private updatedAt: Date;
    private changed: boolean;

    constructor(
        updatedAt: Date,
        changed: boolean
    ) {
        this.updatedAt = updatedAt;
        this.changed = changed;
    }

    fromJson(json: any): UserCreditScoreJointSuretyStatus {
        return new UserCreditScoreJointSuretyStatus(
            json.joint_sureties.map(new UserCreditScoreJointSuretyMapper().fromJson).toList(),
            this.updatedAt,
            this.changed
        )
    }

}
