import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';

import styles from './styles.pcss';

export const PageNotFound = () =>
  <div>
    <MetaHelmet meta={ META_SET.ERROR_NOT_FOUND }/>
    <div className={ styles.wrap }>
      <div className={ styles.container }>
        <h2 className={ styles.title }>요청하신 페이지를 찾을 수 없습니다</h2>
        <h3 className={ styles.guide }>
                    이전 페이지로 돌아가시거나, <a href="/">여기</a>를 눌러 뱅크샐러드 홈으로 돌아가세요
        </h3>
        <p className={ styles.desc }>
                    정확한 URL주소임에도 계속 이 화면이 등장한다면<br/>
          <a href="mailto:hello@banksalad.com">hello@banksalad.com</a>로 뱅크샐러드에게 알려주시면 친절히 안내해드리겠습니다
        </p>
      </div>
    </div>
  </div>;
