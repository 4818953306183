import { Entity } from 'core/entity';

export class CardPremiumVoucherBenefit extends Entity {
    title: string;
    description?: string;

    constructor(
        title: string
    ) {
        super();
        this.title = title;
    }
}
