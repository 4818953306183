import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, lets, optional, toDateString } from 'utils/index';
import { UserBankAccountUpdateSpec } from 'core/entity/personal-finance/bank-account/update-spec';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';

export class UserBankAccountUpdateSpecMapper implements NetworkRequestMapper<UserBankAccountUpdateSpec> {
    toJson(target: UserBankAccountUpdateSpec): any {
        return compactObject({
            type: optional(target.type, it => {
                switch (it) {
                    case UserBankAccount.Type.CHECKING:
                        return 'checking';
                    case UserBankAccount.Type.DEPOSIT:
                        return 'deposit';
                    case UserBankAccount.Type.SAVING:
                        return 'saving';
                    case UserBankAccount.Type.TRUST:
                        return 'trust';
                    default:
                        return 'checking';
                }
            }),
            nickname: optional(target.nickname),
            latest_balance: optional(target.latestBalance),
            description: optional(target.description),
            created_at: optional(target.createdAt, it => toDateString(it, '-')),
            expired_at: optional(target.expiredAt, it => toDateString(it, '-')),
            hidden: optional(target.hidden),
            deleted_status: optional(target.deletedStatus, new DeletedStatusMapper().toJson),
            bank_id: optional(target.bank, it => it.id)
        })
    }
}
