import { Entity } from 'core/entity';
import { LoanCompanySummary } from 'core/entity/personal-finance/loan-company/summary';
import { Asset } from 'core/entity/personal-finance/asset';
import { Amount } from 'core/entity/amount';
import { LoanPrimaryData } from 'core/entity/personal-finance/primary-data/loan';

export class UserLoan extends Entity {
    id: string;
    number: string;
    company: LoanCompanySummary;
    hidden: boolean;
    deletedStatus: Asset.DeletedStatus;
    closed: boolean;
    dormant: boolean;
    latestBalance: Amount;
    primaryData: LoanPrimaryData;
    principal?: Amount;
    interestRate?: number;
    nickname?: string;
    description?: string;
    createdAt?: Date;
    expiredAt?: Date;

    constructor(
        id: string,
        number: string,
        company: LoanCompanySummary,
        hidden: boolean,
        deletedStatus: Asset.DeletedStatus,
        closed: boolean,
        dormant: boolean,
        latestBalance: Amount,
        primaryData: LoanPrimaryData,
        principal: Amount = null,
        interestRate: number = null,
        nickname: string = null,
        description: string = null,
        createdAt: Date = null,
        expiredAt: Date = null
    ) {
        super();
        this.id = id;
        this.number = number;
        this.company = company;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
        this.closed = closed;
        this.dormant = dormant;
        this.latestBalance = latestBalance;
        this.principal = principal;
        this.interestRate = interestRate;
        this.primaryData = primaryData;
        this.nickname = nickname;
        this.description = description;
        this.createdAt = createdAt;
        this.expiredAt = expiredAt;
    }
}
