import { Entity } from 'core/entity';

export class EmailValidate extends Entity {
    constructor(
        public valid: boolean,
        public message?: string,
    ) {
        super();
    }
}
