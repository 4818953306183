import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardCategorySpecialBenefit } from 'core/entity/card/category-special-benefit';
import { CardPreferredSpecialBenefitMapper } from 'data/http/mapper/card/preferred-special-benefit';

export class CardCategorySpecialBenefitMapper implements NetworkResponseMapper<CardCategorySpecialBenefit> {
    fromJson(json: any): CardCategorySpecialBenefit {
        return new CardCategorySpecialBenefit(
            json.category,
            List(json.special_benefits.map((preferredSpecialBenefit: any) =>
                new CardPreferredSpecialBenefitMapper().fromJson(preferredSpecialBenefit)
            ))
        )
    }
}
