import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { FundCompanySummary } from 'core/entity/personal-finance/fund-company/summary';

export class FundCompanySummaryMapper implements
    NetworkRequestMapper<FundCompanySummary>,
    NetworkResponseMapper<FundCompanySummary>
{
    toJson(target: FundCompanySummary): any {
        return {
            id: target.id,
            name: target.name,
            image_url: target.imageUrl,
            type: lets(target.type, type => {
                switch (type) {
                    case FundCompanySummary.Type.BANK:
                        return 'bank';
                    case FundCompanySummary.Type.SECURITIES_FIRM:
                        return 'securities_firm';
                    default:
                        return null;
                }
            })
        }
    }

    fromJson(json: any): FundCompanySummary {
        return new FundCompanySummary(
            json.id,
            json.name,
            json.image_url,
            lets(json.type, it => {
                switch (it) {
                    case 'bank':
                        return FundCompanySummary.Type.BANK;
                    case 'securities_firm':
                        return FundCompanySummary.Type.SECURITIES_FIRM;
                    default:
                        return null;
                }
            })
        )
    }

}
