import { MedicalCheckupJudgementItemStorageMapper } from 'data/browser-storage/mapper/medical-checkup/judgement/item';
import { optional } from 'application/utils';
import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';
import { MappingError } from 'data/http/mapper';

export class MedicalCheckupJudgementStorageMapper implements BrowserStorageMapper<MedicalCheckupJudgement> {
    toJson(target: MedicalCheckupJudgement): any {
        return {
            checkup_item: new MedicalCheckupJudgementItemStorageMapper().toJson(target.checkupItem),
            judgement_result: optional(target.judgementResult, it => {
                switch (it) {
                    case MedicalCheckupJudgement.Result.NORMAL:
                        return 'normal';
                    case MedicalCheckupJudgement.Result.CAUTION:
                        return 'caution';
                    case MedicalCheckupJudgement.Result.WARNING:
                        return 'warning';
                    default:
                        return null;
                }
            }),
            medical_expense: optional(target.medicalExpense)
        }
    }

    toEntity(json: any): MedicalCheckupJudgement {
        return new MedicalCheckupJudgement(
            new MedicalCheckupJudgementItemStorageMapper().toEntity(json.checkup_item),
            optional(json.medical_expense),
            optional(json.judgement_result, it => {
                switch (it) {
                    case 'normal':
                        return MedicalCheckupJudgement.Result.NORMAL;
                    case 'caution':
                        return MedicalCheckupJudgement.Result.CAUTION;
                    case 'warning':
                        return MedicalCheckupJudgement.Result.WARNING;
                    default:
                        throw new MappingError();
                }
            })
        )
    }
}
