import { NetworkRequestMapper } from 'data/http/mapper';
import { PersonalizedLoanUserConfiguration } from 'core/entity/personalized-recommend/loan/user-configuration';
import { compactObject, optional } from 'utils/index';
import { PersonalizedFinanceKCBLoanMapper } from 'data/http/mapper/personalized-finance/loan/kcb';
import { UserFinancialDataAssetSummaryMapper } from 'data/http/mapper/personal-finance/financial-asset/summary';
import { UserCreditScoreCardMapper } from 'data/http/mapper/personal-credit/card';

export class PersonalizedLoanUserConfigurationMapper implements NetworkRequestMapper<PersonalizedLoanUserConfiguration> {
    toJson(target: PersonalizedLoanUserConfiguration): any {
        return compactObject({
            credit_score: target.creditScore,
            overdue_status: optional(target.overdueStatus),
            overdue_record: optional(target.overdueRecord),
            substitute_payment_status: optional(target.substitutePaymentStatus),
            card_limit_max: optional(target.cardLimitMax),
            user_loan: optional(target.userLoan, new PersonalizedFinanceKCBLoanMapper().toJson),
            user_assets: optional(target.userAssets, userAssets =>
                userAssets.map(new UserFinancialDataAssetSummaryMapper().toJson).toArray()
            ),
            card_status: optional(target.cardStatus, cards =>
                cards.map(new UserCreditScoreCardMapper().toJson).toArray()
            ),
            user_loans: optional(target.userLoans, userLoans =>
                userLoans.map(new PersonalizedFinanceKCBLoanMapper().toJson).toArray()
            )
        });
    }
}
