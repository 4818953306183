import { Page } from 'core/entity/page';
import { CardSearchSpec } from 'core/entity/card/search-spec';
import { Card } from 'core/entity/card';
import { CardSearchResult } from 'core/entity/card/search-result';
import { CardRepositoryType } from 'core/repository/card';
import { ServerRenderCardStorageProvider } from 'data/browser-storage/api/card';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class CardServerRenderRepository implements CardRepositoryType {
    private storage: ServerRenderCardStorageProvider;

    constructor(storage: ServerRenderCardStorageProvider) {
        this.storage = storage;
    }

    search(spec: CardSearchSpec, page: Page): Observable<CardSearchResult> {
        throw new Error('Method not implemented.');
    }

    get(id: number): Observable<Card> {
        throw new Error("Method not implemented.");
    }

    getAll(): Observable<List<Card>> {
        return this.storage.get()
            .flatMap(card =>
                this.storage.clear().map(() => List.of(card))
            );
    }
}
