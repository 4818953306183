import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV1KCBAllCreditRoute } from 'presentation/routes/policies/v1/kcb-all-credit';
import { PoliciesV1KCBCreditAgreementRoute } from 'presentation/routes/policies/v1/kcb-credit-agreement';
import { PoliciesV1KCBCreditThirdPartyRoute } from 'presentation/routes/policies/v1/kcb-credit-third-party';
import { PoliciesV1TermsRoute } from 'presentation/routes/policies/v1/terms';
import { PoliciesV1PrivacyAgreementsRoute } from 'presentation/routes/policies/v1/privacy-agreements';
import { PoliciesV1PrivacyPolicyRoute } from 'presentation/routes/policies/v1/privacy-policy';
import { PoliciesV1SubscriptionAgreementRoute } from 'presentation/routes/policies/v1/subscription-agreement';
import { PoliciesV1KCBThirdPartyRoute } from 'presentation/routes/policies/v1/kcb-third-party';
import { PoliciesV1ThirdPartiesRoute } from 'presentation/routes/policies/v1/third-parties';
import { PoliciesV1MedicalCheckupAgreementRoute } from 'presentation/routes/policies/v1/medical-checkup-agreement';

export const PoliciesV1Route = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/terms`} component={ PoliciesV1TermsRoute } />
        <Route path={ `${match.url}/privacy-policy`} component={ PoliciesV1PrivacyPolicyRoute } />
        <Route path={ `${match.url}/privacy-agreements`} component={ PoliciesV1PrivacyAgreementsRoute } />
        <Route path={ `${match.url}/privacy-agreement`} component={ PoliciesV1PrivacyAgreementsRoute } />
        <Route path={ `${match.url}/subscription-agreement`} component={ PoliciesV1SubscriptionAgreementRoute } />
        <Route path={ `${match.url}/third-parties`} component={ PoliciesV1ThirdPartiesRoute } />
        <Route path={ `${match.url}/kcb-third-party`} component={ PoliciesV1KCBThirdPartyRoute } />
        <Route path={ `${match.url}/kcb-all-credit`} component={ PoliciesV1KCBAllCreditRoute } />
        <Route path={ `${match.url}/kcb-credit-agreement`} component={ PoliciesV1KCBCreditAgreementRoute } />
        <Route path={ `${match.url}/kcb-credit-third-party`} component={ PoliciesV1KCBCreditThirdPartyRoute } />
        <Route path={ `${match.url}/medical-checkup-agreement`} component={ PoliciesV1MedicalCheckupAgreementRoute } />
    </Switch>
);
