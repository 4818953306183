import React from 'react';

import { Alert } from 'presentation/components/alert';
import { OUTBOUND_LINK } from 'presentation/module/sitemap';

import styles from './styles.pcss';

interface Props {
    onCancel: () => void
}

export const ServiceReportAlert = (props: Props) =>
    <Alert
        onCancel={ props.onCancel }
    >
        <section className={ styles.container }>
            <h2 className={ styles.title }>잘못된 정보를 발견하셨나요?</h2>
            <p className={ styles.desc }>
                불편을 드려 대단히 죄송합니다.<br/>
                <a href={ OUTBOUND_LINK.MAIL_TO_BANKSALAD() }>hello@banksalad.com</a>으로 알려주시면<br/>
                확인 후 빠르게 정정하겠습니다.
            </p>
            <p className={ styles.subDesc }>
                항상 최신의 정확한 정보를 제공하기 위해 최선을 다하겠습니다.<br />
                감사합니다.
            </p>
        </section>
    </Alert>;
