import { Service } from 'core/service';
import { CardRecommendRepositoryType } from 'core/repository/card-recommend';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';

export class CardService implements Service {
    private recommendationRepository: CardRecommendRepositoryType;

    constructor(recommendationRepository: CardRecommendRepositoryType) {
        this.recommendationRepository = recommendationRepository;
    }

    computeBenefits = (cardId: number, spec: CardRecommendSpec) =>
        this.recommendationRepository.compute(cardId, spec);
}
