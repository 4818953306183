import { Entity } from 'core/entity';

export class LoanCFADescription extends Entity {
    id: string;
    name: string;
    message: string;

    constructor(
        id: string,
        name: string,
        message: string
    ) {
        super();
        this.id = id;
        this.name = name;
        this.message = message;
    }
}
