import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderSecuritiesFirmSummary } from 'core/entity/personal-finance/securities-firm/summary';

export class SpiderSecuritiesFirmSummaryMapper implements NetworkResponseMapper<SpiderSecuritiesFirmSummary> {
    fromJson(json: any): SpiderSecuritiesFirmSummary {
        return new SpiderSecuritiesFirmSummary(
            json.id,
            json.name,
            json.image_url
        )
    }

}
