import { NetworkResponseMapper } from 'data/http/mapper';
import { UserSecuritiesFirmAccountFundItem } from 'core/entity/personal-finance/securities-firm-account/fund-item';

export class UserSecuritiesFirmAccountFundItemMapper implements NetworkResponseMapper<UserSecuritiesFirmAccountFundItem> {
    fromJson(json: any): UserSecuritiesFirmAccountFundItem {
        return new UserSecuritiesFirmAccountFundItem(
            json.id,
            json.name
        )
    }

}
