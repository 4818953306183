import { UserCardBill } from 'core/entity/personal-finance/card-bill';
import { UserCardBillContent } from 'core/entity/personal-finance/card-bill-content';
import { UserCardBillRepositoryType } from 'core/repository/personal-finance/card-bill';
import { UserCardBillsApiProvider } from 'data/http/api/gateway/v2/users/cards/bills';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserCardBillRepository implements UserCardBillRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserCardBillsApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserCardBillsApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    getAll(companyId: string): Observable<List<UserCardBillContent>> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.getAll(token, companyId)
            )
    }

    get(id: string): Observable<UserCardBill> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.get(token, id)
            )
    }

}
