import React from 'react';

import { Alert, AlertSize } from 'presentation/components/alert';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { CardIssueContainer } from 'presentation/components/alert/card-issue-container';

import styles from './styles.pcss';

interface Props {
    cardId: number;
    issuePhoneNumber: string;
    companyImageUrl: string;
    onCancel: () => void;
}

export const CardIssuePhoneAlert = (props: Props) => {
    const redirect = (action: () => void = () => {}) => {
        const { issuePhoneNumber } = props;

        sendGAEvent(
            GA_DOMAIN.CARD,
            GA_CATEGORY.CARD_ISSUE_ALERT.CALL,
            GA_ACTION.ISSUE,
            `카드신청 센터 ${props.issuePhoneNumber}`
        );

        location.href = `tel:${issuePhoneNumber}`;
        action();
    };

    // TODO: 심의 이슈 해결 후 복구할 것
    // if (isMobile()) {
    //     redirect(() => props.onCancel());
    //     return null;
    // }

    return (
        <Alert
            onCancel={ () => props.onCancel() }
            size={ AlertSize.BIG }
        >
            <CardIssueContainer cardId={ props.cardId }>
                <div className={ styles.wrap }>
                    <img src={ props.companyImageUrl } className={ styles.image } />
                    <h5 className={ styles.title }>
                        <button
                            onClick={ () => {
                                redirect();
                                sendGAEvent(
                                    GA_DOMAIN.CARD,
                                    GA_CATEGORY.CARD_ISSUE_ALERT.CALL,
                                    GA_ACTION.ISSUE,
                                    `카드신청 센터 ${props.issuePhoneNumber}`
                                );
                            } }
                        >
                           카드신청 센터 { props.issuePhoneNumber }
                        </button>
                    </h5>
                    <p className={ styles.desc }>
                        위의 번호로 전화하시면 카드사의 발급 전담 상담원과 연결됩니다.<br/><br/>
                        본인 인증에 필요한 신분증(주민등록증 또는 운전면허증)을 준비해 주세요.<br/>
                        카드 신청 완료까지 10분~15분 정도 소요됩니다.
                    </p>
                </div>
            </CardIssueContainer>
        </Alert>
    );
};
