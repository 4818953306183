import { NetworkResponseMapper } from 'data/http/mapper';
import { CreditGradeMetadata } from 'core/entity/personal-credit/metadata/grade';
import { lets } from 'utils/index';

export class CreditGradeMetadataMapper implements NetworkResponseMapper<CreditGradeMetadata> {
    fromJson(json: any): CreditGradeMetadata {
        return new CreditGradeMetadata(
            json.grade,
            lets(json.score_range, it =>
                new CreditGradeMetadata.ScoreRange(
                    it.min,
                    it.max
                )
            )
        );
    }
}
