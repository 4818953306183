import { HttpProvider } from 'data/http';
import { AccessToken } from 'core/entity/user/access-token';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { UserFinancialDataAssetSummary } from 'core/entity/personal-finance/financial-data/summary';
import { UserFinancialDataAssetSummaryMapper } from 'data/http/mapper/personal-finance/financial-asset/summary';

export class UserFinancialDataAssetSummariesApiProvider extends HttpProvider {
    get(
        accessToken: AccessToken,
        startDate: Date,
        endDate: Date
    ): Observable<List<UserFinancialDataAssetSummary>> {
        return this.requestGET(
            'api/v2/me/asset-summaries',
            {
                start_date: startDate.format('YYYY-MM-DD'),
                end_date: endDate.format('YYYY-MM-DD')
            },
            { Authorization: accessToken.accessToken }
        ).map(summaries =>
            summaries.data.map((summary: any) => new UserFinancialDataAssetSummaryMapper().fromJson(summary)).toList()
        )
    }
}
