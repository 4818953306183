import React from 'react';

import { CardQuestionBenefitType } from 'presentation/components/survey-form/card-benefit-type/model';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    data: CardQuestionBenefitType,
    onChange?: (result: CardQuestionBenefitType) => void
}

export const CardBenefitTypeForm = (props: Props) => {
    const RADIO_GROUP_NAME = 'form-card-benefit-type';
    const RADIO_DISCOUNT = 'form-card-benefit-type-discount';
    const RADIO_MILEAGE = 'form-card-benefit-type-mileage';

    const toGALabel = (type: CardQuestionBenefitType.Type) => {
        switch (type) {
            case CardQuestionBenefitType.Type.DISCOUNT:
                return '할인적립';
            case CardQuestionBenefitType.Type.MILEAGE:
                return '항공마일리지';
        }
    };

    const onChange = (type: CardQuestionBenefitType.Type) => {
        sendGAEvent(
            GA_DOMAIN.CARD,
            GA_CATEGORY.CARDS_QUESTIONS.BENEFIT_TYPE,
            GA_ACTION.RADIO,
            toGALabel(type)
        );
        props.onChange && props.onChange(
            new CardQuestionBenefitType(type)
        );
    };

    return (
        <ul className={ styles.wrap }>
            <li>
                <input
                    type="radio"
                    id={ RADIO_DISCOUNT }
                    name={ RADIO_GROUP_NAME }
                    checked={ props.data.value === CardQuestionBenefitType.Type.DISCOUNT }
                    onChange={ () => onChange(CardQuestionBenefitType.Type.DISCOUNT) }
                />
                <label htmlFor={ RADIO_DISCOUNT }>할인/적립</label>
            </li>
            <li>
                <input
                    type="radio"
                    id={ RADIO_MILEAGE }
                    name={ RADIO_GROUP_NAME }
                    checked={ props.data.value === CardQuestionBenefitType.Type.MILEAGE }
                    onChange={ () => onChange(CardQuestionBenefitType.Type.MILEAGE) }
                />
                <label htmlFor={ RADIO_MILEAGE }>항공마일리지</label>
            </li>
        </ul>
    )
};
