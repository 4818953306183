import React, { FocusEvent } from 'react';
import Rx from 'rxjs/Rx'

import { toast, toCurrency } from 'presentation/module/sugar';
import { lets } from 'utils/index';

import styles from './styles.pcss';
const MAX_VALUE = 2100000000;

interface Props {
    value: number,
    fontSize?: number,
    textAlign?: string,
    color?: string,
    overflowLimit?: number,
    overflowMessage?: string,
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void,
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void,
    onChange: (result: number) => void
}

interface State {
    value: number
}

type TextAlignProperty = "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start"

export class InputCurrency extends React.Component<Props, State> {
    state = {
        value: this.props.value
    };

    private stream = new Rx.Subject<number>();
    private subscription: Rx.Subscription;

    componentDidMount() {
        this.subscription = this.stream
            .debounceTime(500)
            .subscribeOn(Rx.Scheduler.async)
            .observeOn(Rx.Scheduler.animationFrame)
            .subscribe(
                value => this.props.onChange(value)
            );
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    componentWillReceiveProps(props: Props) {
        if (this.state.value !== props.value) {
            this.setState({ value: props.value });
        }
    }

    render() {
        const {
            color,
            fontSize,
            textAlign,
            onFocus,
            onBlur
        } = this.props;
        const { value } = this.state;

        return (
            <div className={ styles.wrap }>
                <input
                    type="text"
                    value={ value.toString() }
                    onChange={ e => this.onChange(e.currentTarget.value) }
                    onKeyDown={ e => e.keyCode === 13 && e.currentTarget.blur() }
                    onFocus={ e => onFocus && onFocus(e) }
                    onBlur={ e => onBlur && onBlur(e) }
                    style={{
                        color: color ? color : '#2B2B2B',
                        fontSize: fontSize ? `${fontSize}px` : '16px',
                        textAlign: textAlign ? textAlign as TextAlignProperty : 'left'
                    }}
                    className={ styles.input }
                />
                <span
                    className={ styles.show }
                    style={{
                        color: color ? color : '#2B2B2B',
                        fontSize: fontSize ? `${fontSize}px` : '16px',
                        textAlign: textAlign ? textAlign as TextAlignProperty : 'left'
                    }}
                >
                    { toCurrency(value) }
                </span>
            </div>
        )
    }

    private onChange(text: string) {
        const { overflowLimit, overflowMessage } = this.props;
        const { value } = this.state;
        const result = lets(text === parseInt(text).toString(), it => {
            if (it) {
                return parseInt(text);
            } else if (text.length === 0) {
                return 0;
            } else {
                return value;
            }
        });

        if (overflowLimit ? overflowLimit < result : Math.pow(2, 31) - 1 < result) {
            overflowMessage && toast(overflowMessage);
            this.setState({ value: overflowLimit ? overflowLimit : MAX_VALUE });
            this.stream.next(overflowLimit ? overflowLimit : MAX_VALUE);
            return;
        }

        this.setState({ value: result });
        this.stream.next(result);
    };
}
