import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanDescriptionsSummary } from 'core/entity/loan/descriptions/summary';

export class LoanDescriptionsSummaryMapper implements NetworkResponseMapper<LoanDescriptionsSummary> {
    fromJson(json: any): LoanDescriptionsSummary {
        return new LoanDescriptionsSummary(
            json.qualifications,
            json.repayment
        )
    }

}
