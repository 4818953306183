import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreCardUsedAmount } from 'core/entity/personal-credit/card/used-amount';
import { AmountMapper } from 'data/http/mapper/amount';

export class UserCreditScoreCardUsedAmountMapper implements
    NetworkResponseMapper<UserCreditScoreCardUsedAmount>,
    NetworkRequestMapper<UserCreditScoreCardUsedAmount>
{
    toJson(target: UserCreditScoreCardUsedAmount): any {
        return {
            total: new AmountMapper().toJson(target.total),
            lump_sum_payment: new AmountMapper().toJson(target.lumpSumPayment),
            installment: new AmountMapper().toJson(target.installment),
            check_card: new AmountMapper().toJson(target.checkCard),
            cash_service: new AmountMapper().toJson(target.cashService)
        }
    }

    fromJson(json: any): UserCreditScoreCardUsedAmount {
        return new UserCreditScoreCardUsedAmount(
            new AmountMapper().fromJson(json.total),
            new AmountMapper().fromJson(json.lump_sum_payment),
            new AmountMapper().fromJson(json.installment),
            new AmountMapper().fromJson(json.check_card),
            new AmountMapper().fromJson(json.cash_service)
        )
    }
}
