import React from 'react';

import { removeQueriesAndHashes } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    title: string;
    imageUrl: string;
    size?: number;
}

export const KakaoTalkShareButton = (props: Props) =>
    <button
        className={ styles.button }
        onClick={ () => {
            Kakao.Link.sendScrap({
                requestUrl: removeQueriesAndHashes(location.href)
            });
        } }
        style={{
            width: `${props.size || 34}px`,
            height: `${props.size || 34}px`
        }}
    >
        카카오톡 공유
    </button>;
