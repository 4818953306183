import { Entity } from 'core/entity';
import { TransactionType } from 'core/entity/personal-finance/transaction/type';

export class TransactionCategoryFilter extends Entity {
    transactionType?: TransactionType;
    parent?: boolean;
    child?: boolean;
    includeDefault?: boolean;

    constructor(
        transactionType: TransactionType = null,
        parent: boolean = null,
        child: boolean = null,
        includeDefault: boolean = null
    ) {
        super();
        this.transactionType = transactionType;
        this.parent = parent;
        this.child = child;
        this.includeDefault = includeDefault;
    }
}
