import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { SpiderData } from 'core/entity/personal-finance/spider-data';
import { TransactionPrimaryData } from 'core/entity/personal-finance/transaction/primary-data';
import { TransactionProductPrimaryData } from 'core/entity/personal-finance/transaction/product/primary-data';

export class CardTransactionPrimaryData extends Entity implements TransactionPrimaryData {
    id: string;
    title: string;
    transactedAt: Date;
    amount: Amount;
    installmentMonth: number;
    product?: TransactionProductPrimaryData;
    spider?: SpiderData;

    constructor(
        id: string,
        title: string,
        transactedAt: Date,
        amount: Amount,
        installmentMonth: number,
        product: TransactionProductPrimaryData = null,
        spider: SpiderData = null
    ) {
        super();
        this.id = id;
        this.title = title;
        this.transactedAt = transactedAt;
        this.amount = amount;
        this.installmentMonth = installmentMonth;
        this.product = product;
        this.spider = spider;
    }
}
