import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardPremiumVoucher } from 'core/entity/card/premium-voucher';
import { CardPremiumVoucherBenefitMapper } from 'data/http/mapper/card/premium-voucher-benefit';

export class CardPremiumVoucherMapper implements NetworkResponseMapper<CardPremiumVoucher> {
    fromJson(json: any): CardPremiumVoucher {
        return new CardPremiumVoucher(
            json.title,
            List(json.benefits.map((benefit: any) =>
                new CardPremiumVoucherBenefitMapper().fromJson(benefit)
            )),
            json.additional_annual_cost
        )
    }
}
