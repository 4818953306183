import React, { MouseEvent, TouchEvent } from 'react';
import { List } from 'immutable';

import { toCurrency } from 'presentation/module/sugar';
import { lets } from 'utils/index';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';
const PRESET = List([0, 10000, 30000, 50000, 70000, 100000]);

interface Props {
    costs?: List<number>,
    min: number,
    max: number,
    onChange?: (min: number, max?: number) => void
}

interface State {
    minAnnualCost: number,
    maxAnnualCost: number,
    draggableMin: boolean,
    draggableMax: boolean
}

export class CardsAnnualCostFilter extends React.Component<Props, State> {
    state = {
        minAnnualCost: 0,
        maxAnnualCost: 0,
        draggableMin: false,
        draggableMax: false
    };

    componentDidMount() {
        this.setState({
            minAnnualCost: this.valueToPercent(
                this.getCosts(),
                this.props.min || this.getCosts().first()
            ),
            maxAnnualCost: this.valueToPercent(
                this.getCosts(),
                this.props.max || this.getCosts().last()
            )
        })
    }

    render() {
        const {
            draggableMin,
            draggableMax,
            minAnnualCost,
            maxAnnualCost
        } = this.state;
        const costs = this.getCosts();
        const min = this.props.min || costs.first();
        const max = this.props.max || costs.last();
        const draggable = draggableMin || draggableMax;

        return (
            <div
                onMouseDown={ this.onDragStart }
                onMouseLeave={ this.onDragEnd }
                onMouseMove={ draggable ? this.onDragging : null }
                onMouseUp={ this.onDragEnd }
                onTouchStart={ this.onDragStart }
                onTouchMove={ this.onDragging }
                onTouchEnd={ this.onDragEnd }
            >
                <section className={ styles.texts }>
                    <span className={ styles.min }>{ `${toCurrency(min)}원` }</span>
                    <span className={ styles.max }>{ `${toCurrency(max)}원` }</span>
                </section>
                <section className={ styles.bar }>
                    <div className={ styles.sticks }>
                        {
                            costs.map((cost, i) =>
                                <span
                                    key={ `stick-${i}` }
                                    style={{ left: `${100 / (costs.size - 1) * i}%` }}
                                />
                            )
                        }
                    </div>
                    <div className={ styles.active }>
                        <span
                            style={{
                                left: `${minAnnualCost * 100}%`,
                                width: `${(maxAnnualCost - minAnnualCost) * 100}%`
                            }}
                            className={ styles.range }
                        />
                        <button
                            style={{ left: `${minAnnualCost * 100}%` }}
                            className={ styles.min }
                        >
                            { `연회비 최소 ${min}원` }
                        </button>
                        <button
                            style={{ left: `${maxAnnualCost * 100}%` }}
                            className={ styles.max }
                        >
                            { `연회비 최대 ${max}원` }
                        </button>
                    </div>
                </section>
            </div>
        );
    }

    private onDragStart = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
        const { minAnnualCost, maxAnnualCost } = this.state;
        const clickedPoint = (() => {
            if (e.nativeEvent instanceof MouseEvent) {
                return ((e as MouseEvent<HTMLDivElement>).clientX - e.currentTarget.offsetLeft) / e.currentTarget.clientWidth;
            } else if (e.nativeEvent instanceof TouchEvent) {
                return ((e as TouchEvent<HTMLDivElement>).touches[0].clientX - e.currentTarget.offsetLeft) / e.currentTarget.clientWidth;
            }
        })();

        if (minAnnualCost > clickedPoint) {
            this.setState({
                draggableMin: true,
                minAnnualCost: clickedPoint
            });
        } else if (maxAnnualCost < clickedPoint) {
            this.setState({
                draggableMax: true,
                maxAnnualCost: clickedPoint
            });
        } else if (
            Math.abs(minAnnualCost - clickedPoint) >
            Math.abs(maxAnnualCost - clickedPoint)
        ) {
            this.setState({
                draggableMax: true,
                maxAnnualCost: clickedPoint
            });
        } else {
            this.setState({
                draggableMin: true,
                minAnnualCost: clickedPoint
            });
        }
    };

    private onDragEnd = () => {
        const { onChange } = this.props;
        const {
            draggableMin,
            draggableMax,
            minAnnualCost,
            maxAnnualCost
        } = this.state;

        if (!draggableMin && !draggableMax) {
            return;
        }

        const costs = this.getCosts();
        const minValue = this.percentToValue(costs, minAnnualCost);
        const maxValue = this.percentToValue(costs, maxAnnualCost);
        const minPercent = this.valueToPercent(costs, minValue);
        const maxPercent = this.valueToPercent(costs, maxValue);

        this.setState({
            draggableMin: false,
            draggableMax: false,
            minAnnualCost: minPercent,
            maxAnnualCost: maxPercent
        });

        if (this.props.min !== minValue || this.props.max !== maxValue) {
            onChange && onChange(minValue, maxValue === PRESET.last() ? null : maxValue);
            sendGAEvent(
                GA_DOMAIN.CARD,
                GA_CATEGORY.CARDS_PROFITS.FILTER_ANNUAL_COST,
                GA_ACTION.RANGE,
                `${minValue}_${maxValue}`
            )
        }
    };

    private onDragging = (e: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
        const { draggableMin, draggableMax } = this.state;
        const percent = Math.max(
            Math.min(
                lets(e, it => {
                    if (it.nativeEvent instanceof MouseEvent) {
                        return ((it as MouseEvent<HTMLDivElement>).clientX - it.currentTarget.offsetLeft) / it.currentTarget.clientWidth;
                    } else if (it.nativeEvent instanceof TouchEvent) {
                        return ((it as TouchEvent<HTMLDivElement>).touches[0].clientX - it.currentTarget.offsetLeft) / it.currentTarget.clientWidth;
                    }
                }), 1
            ), 0
        );

        if (draggableMin) {
            this.setState(state => ({
                minAnnualCost: Math.min(percent, state.maxAnnualCost)
            }));
        } else if (draggableMax) {
            this.setState(state => ({
                maxAnnualCost: Math.max(percent, state.minAnnualCost)
            }));
        }
    };

    private findClosest = (list: List<number>, value: number): number => {
        if (list.size === 1) {
            return list.first();
        }

        const first = Math.abs(list.first() - value);
        const second = Math.abs(list.get(1) - value);

        if (first < second) {
            return this.findClosest(list.remove(1), value);
        } else if (first > second) {
            return this.findClosest(list.remove(0), value);
        } else {
            return value;
        }
    };

    private getCosts = () => {
        return (this.props.costs || PRESET).sort().toList();
    };

    private valueToPercent = (list: List<number>, value: number): number => {
        const percents = list.map((item, i) => i / (list.size - 1)).toList();
        const closestValue = this.findClosest(
            list,
            value
        );

        return percents.get(
            list.indexOf(closestValue)
        );
    };

    private percentToValue = (list: List<number>, percent: number): number => {
        const percents = list.map((item, i) => i / (list.size - 1)).toList();
        const closestPercent = this.findClosest(
            percents,
            percent
        );

        return list.get(
            percents.indexOf(closestPercent)
        );
    };
}
