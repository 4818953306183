import { Service } from 'core/service';
import { AdviceRepositoryType } from 'core/repository/personal-finance/advice';
import { Observable } from 'rxjs/Rx';
import { Advice } from 'core/entity/personal-finance/advice';
import { AdviceUpdateSpec } from 'core/entity/personal-finance/advice/update-spec';

export class AdviceService implements Service {
    private repository: AdviceRepositoryType;

    constructor(repository: AdviceRepositoryType) {
        this.repository = repository;
    }

    get(id: string): Observable<Advice> {
        return this.repository.get(id);
    }

    update(id: string, spec: AdviceUpdateSpec): Observable<void> {
        return this.repository.update(id, spec);
    }
}
