import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { MedicalCheckupJudgementMapper } from 'data/http/mapper/medical-checkup/judgement';
import { MedicalCheckupSummaryResult } from 'core/entity/medical-checkup/summary/result';

export class MedicalCheckupSummaryResultMapper implements NetworkResponseMapper<MedicalCheckupSummaryResult> {
    fromJson(json: any): MedicalCheckupSummaryResult {
        return new MedicalCheckupSummaryResult(
            json.id,
            json.year,
            json.judgement.map(new MedicalCheckupJudgementMapper().fromJson).toList(),
            lets(json.checkup_type, it => {
                switch (it) {
                    case 'general-checkup':
                        return MedicalCheckupSummaryResult.Type.GENERAL_CHECKUP;
                    default:
                        throw new MappingError();
                }
            })
        )
    }
}
