import { TransactionPrimaryData } from 'core/entity/personal-finance/transaction/primary-data';
import { NetworkResponseMapper } from 'data/http/mapper';
import { BankAccountTransactionPrimaryDataMapper } from 'data/http/mapper/personal-finance/transaction/primary-data/bank-account';
import { CardTransactionPrimaryDataMapper } from 'data/http/mapper/personal-finance/transaction/primary-data/card';
import { CashAccountTransactionPrimaryDataMapper } from 'data/http/mapper/personal-finance/transaction/primary-data/cash-account';

export class TransactionPrimaryDataMapper implements NetworkResponseMapper<TransactionPrimaryData> {
    fromJson(json: any): TransactionPrimaryData {
        switch (json.type) {
            case 'card':
                return new CardTransactionPrimaryDataMapper().fromJson(json);
            case 'bank_account':
                return new BankAccountTransactionPrimaryDataMapper().fromJson(json);
            case 'cash_account':
                return new CashAccountTransactionPrimaryDataMapper().fromJson(json);
            default:
                return null;
        }
    }

}
