import { Notice } from 'core/entity/notice';
import { NetworkResponseMapper } from 'data/http/mapper';
import { parseDate } from 'utils/index';

export class NoticeMapper implements NetworkResponseMapper<Notice> {
    fromJson(json: any): Notice {
        return new Notice(
            json.id,
            json.title,
            json.description,
            parseDate(json.created_at),
            parseDate(json.updated_at)
        )
    }

}
