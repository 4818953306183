import React from 'react';
import { EventsViewerListModel } from 'presentation/components/events-viewer/list/model';
import { EventViewerModel } from 'presentation/components/event-viewer/model';

import styles from './styles.pcss';

interface Props {
    event: EventsViewerListModel | EventViewerModel
}

export const EventTags = (props: Props) => {
    const {
        event
    } = props;

    return (
        <ul className={ styles.tags }>
            {
                event.tags.special &&
                <li className={ styles.special }>특판</li>
            }
            {
                event.tags.hot &&
                <li className={ styles.hot }>HOT</li>
            }
            {
                event.tags.alert &&
                <li className={ styles.alert }>선착순</li>
            }
        </ul>
    )
};
