import { Entity } from 'core/entity';
import { UserFund } from 'src/core/entity/personal-finance/fund/index';
import { UserBankAccount } from 'src/core/entity/personal-finance/bank-account/index';
import { UserInsurance } from 'core/entity/personal-finance/insurance';
import { List } from 'immutable';

export class UserFinancialDataAssetSummary extends Entity {
    constructor(
        public baseDateTime: Date,
        public bankAccounts: List<UserBankAccount>,
        public funds: List<UserFund>,
        public insurances: List<UserInsurance> = null
    ) {
        super();
    }
}
