import { init, setUserId, track } from '@amplitude/marketing-analytics-browser';
import { isNamespaceProduction } from 'utils/env';
import { getWebDeviceId } from 'utils/get-web-device-id';
import { isBanksaladWebView } from 'utils/is-banksalad-web-view';
import { getWebInfoFromLocalStorage } from 'utils/local-storage';

import { EventParam } from './events/types/event-name';

interface Props<Type> {
  eventType: Type;
  eventParam?: EventParam;
}

export const sendAmplitudeEvent = <Type>({ eventType, eventParam = {}}: Props<Type>) => {
  const userId = getWebInfoFromLocalStorage<string>('webBanksaladUserId');

  // amplitude 에서는 userId가 5자리 이상이어야 합니다. https://www.docs.developers.amplitude.com/analytics/apis/http-v2-api/#device-ids-and-user-ids-minimum-length
  setUserId(userId === null ? undefined : userId.padStart(5, '0'));
  track(eventType.toString(), eventParam);
};

/**
 * TODO:
 * Window 객체를 확인하는 이유는 SSR 상황에서 Browser Storage에 저장된 deviceId에 접근하지 못해 생기는 이슈 입니다.
 * 위의 이슈는 Library Dependency는 없으며 우리가 사용하고 있는 Nextjs(SSR)에 Dependency가 있는 상황입니다.
 * 이 문제를 해결하기 위해서는 SSR 상황에서도 deviceId를 유지시켜줘야 하며 일반적으로 Cookie값을 통해 해결되길 기대합니다.
 */
export const initAmplitude = () => {
  /**
   * 구뱅샐에서 Serving되는 WebView는 Amplitude를 사용하지 않습니다.
   * 참고: https://banksalad.slack.com/archives/C01KCA68AQH/p1675935577022109
   */
  if (typeof window !== 'undefined' && !isBanksaladWebView()) {
    init(getAmplitudeApiKey(), undefined, {
      deviceId: getWebDeviceId(),
      pageViewTracking: {
        trackHistoryChanges: 'pathOnly',
      },
      attribution: {
        resetSessionOnNewCampaign: true,
      },
    });
  }
};

const getAmplitudeApiKey = () =>
  isNamespaceProduction() ? AMPLITUDE_PRODUCTION_ID : AMPLITUDE_STAGING_ID;

// AMPLITUDE_API_KEY 는 브라우저에 노출되어도 안전합니다.
// 참고: https://github.com/amplitude/Amplitude-Javascript/issues/100
const AMPLITUDE_STAGING_ID = 'e1c37a469de2f3f24139ec8197c24eab';
const AMPLITUDE_PRODUCTION_ID = 'efc72f1c4e5bf7701bb789d18763bd45';
