import React from 'react';

import styles from './styles.pcss';

interface Props {
    title: string;
    info: string;
}

export const UserStatusInfoView = ({
  title,
  info
}: Props) => (
  <div className={ styles.wrap }>
    <h1
      dangerouslySetInnerHTML={{ __html: title }}
      className={ styles.title }
    />
    <p
      dangerouslySetInnerHTML={{ __html: info }}
      className={ styles.info }
    />
  </div>
);
