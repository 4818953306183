import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceCardExpenseData } from 'core/entity/personal-finance/advice/content-data/card-expense';
import { AdviceCardUsedAmountMapper } from 'data/http/mapper/personal-finance/advice/card-used-amount';
import { optional } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceCardExpenseDataMapper implements NetworkResponseMapper<AdviceCardExpenseData> {
    fromJson(json: any): AdviceCardExpenseData {
        return new AdviceCardExpenseData(
            json.cards.map(new AdviceCardUsedAmountMapper().fromJson).toList(),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
