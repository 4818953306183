import { CardConfiguration } from 'core/entity/card/configuration';
import { CardConfigurationMapper } from 'data/http/mapper/card/configuration';
import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';

export class CardConfigurationApiProvider extends HttpProvider {
    get(): Observable<CardConfiguration> {
        return this.requestGET(
            '/api/v3/cards/configuration'
        ).map((result: any) =>
            new CardConfigurationMapper().fromJson(result)
        )
    }
}
