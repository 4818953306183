import { Entity } from 'core/entity';
import { CardBenefitGroup } from 'core/entity/card/benefit-group';
import { List } from 'immutable';

export class CardSelectiveGroup extends Entity {
    numberOfGroups: number;
    numberOfSelect: number;
    selectType: CardSelectiveGroup.Type;
    groups: List<CardBenefitGroup>;

    constructor(
        numberOfGroups: number,
        numberOfSelect: number,
        selectType: CardSelectiveGroup.Type,
        groups: List<CardBenefitGroup>
    ) {
        super();
        this.numberOfGroups = numberOfGroups;
        this.numberOfSelect = numberOfSelect;
        this.selectType = selectType;
        this.groups = groups;
    }
}

export module CardSelectiveGroup {
    export enum Type {
        OPTIONAL,
        SELECTIVE
    }
}
