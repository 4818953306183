import { UserCard } from 'core/entity/personal-finance/card';
import { PresentableAsset } from 'presentation/module/extension/personal-finance/base';

declare module 'core/entity/personal-finance/card' {
    export interface UserCard extends PresentableAsset {
        isExpired: () => boolean;
    }
}

UserCard.prototype.presentName = function() {
    return this.nickname ?
        this.nickname :
        this.primaryData.spider ?
            this.primaryData.spider.name :
            '알 수 없는 카드'
};

UserCard.prototype.isExpired = function() {
    return this.closed || this.dormant
};
