import React from 'react';
import { RouteComponentProps } from 'react-router';

import { FetchState } from 'presentation/view-model/fetch-state';
import { Header } from 'presentation/components/header';
import { FinanceSector } from 'core/entity/finance-sector';
import { Loader } from 'presentation/components/loader';
import { FetchError } from 'presentation/components/fetch-state/error';
import { SectorContentsRecommend } from 'presentation/components/sector-contents/recommend-type';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { CLIX, sendClixEvent } from 'presentation/module/analytics/clix';
import { GA_CATEGORY } from 'presentation/module/analytics/ga';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';

import styles from './styles.pcss';

interface Props extends RouteComponentProps<any> {
    /* explicitly empty */
}

interface State {
    fetchState: FetchState,
    sector?: FinanceSector
}

export class SectorsAnnuity extends React.Component<Props, State> {
    state = {
        fetchState: FetchState.ERROR,
        sector: null as FinanceSector
    };

    private subscriptionBag = new SubscriptionBag();

    constructor(props: Props) {
        super(props);
        sendPixelEvent(PIXEL.SECTORS_ANNUITY);
        sendClixEvent(CLIX.SECTORS_ANNUITY);
    }

    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { fetchState, sector } = this.state;

        return (
            <div className={ styles.background }>
                <MetaHelmet meta={ META_SET.SECTORS_ANNUITY }/>
                <Header active={ FinanceSectorID.ANNUITY } />
                <div className={ styles.coverWrap }>
                    <div className={ styles.coverContainer }>
                        <h2 className={ styles.coverTitle }>
                            <span className={ styles.coverWhisper }>
                                납입 대비 지급 금액이 가장 높은 상품찾기
                            </span>
                            <strong>세금혜택</strong>은 물론<br/>
                            <strong>수익률</strong>까지 따져서 가입하세요!
                        </h2>
                    </div>
                    <div className={ styles.coverGraphics } />
                </div>
                {
                    sector &&
                    fetchState === FetchState.FETCHED ? (
                        <div>
                            {
                                !sector.generateLandingRecommends().isEmpty() && (
                                    <section className={ styles.section }>
                                        <div className={ styles.container }>
                                            <h3 className={ styles.sectionTitle }>뱅크샐러드 Pick!</h3>
                                            <SectorContentsRecommend
                                                contents={ sector.generateLandingRecommends() }
                                                gaCategory={ GA_CATEGORY.SECTORS.PICK }
                                            />
                                        </div>
                                    </section>
                                )
                            }
                        </div>
                    ) : fetchState === FetchState.FETCHING ? (
                        <Loader padding={ 200 } radius={ 25 } />
                    ) : (
                        <FetchError padding={ 200 }>
                            일시적인 오류가 발생했습니다<br />
                            잠시 후 재시도 해주세요
                        </FetchError>
                    )
                }
            </div>
        )
    }

    private fetch = () => {
        this.setState({
            fetchState: FetchState.FETCHING
        }, () =>
            Application.useCases.getAnnuitySector
                .runOnAnimateFrame()
                .subscribe(
                    sector =>
                        this.setState({
                            fetchState: FetchState.FETCHED,
                            sector
                        }),
                    error =>
                        this.setState({
                            fetchState: FetchState.ERROR
                        })
                )
                .unsubscribeBy(this.subscriptionBag)
        );
    }

}
