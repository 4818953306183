import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import {
    ChargedTransactionInstallmentConfiguration
} from 'core/entity/personal-finance/charged-transaction/installment-configuration';

export class ChargedTransaction extends Entity {
    title: string;
    amount: Amount;
    transactedDate: Date;
    installment?: ChargedTransactionInstallmentConfiguration;

    constructor(
        title: string,
        amount: Amount,
        transactedDate: Date,
        installment: ChargedTransactionInstallmentConfiguration = null
    ) {
        super();
        this.title = title;
        this.amount = amount;
        this.transactedDate = transactedDate;
        this.installment = installment;
    }
}
