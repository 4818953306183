import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { StoreMapper } from 'data/http/mapper/store';
import { StoreGroup } from 'core/entity/store/group';

export class StoreGroupMapper implements NetworkResponseMapper<StoreGroup> {
    fromJson(json: any): StoreGroup {
        return new StoreGroup(
            json.id,
            json.name,
            json.number_of_stores,
            List(json.stores.map((store: any) =>
                new StoreMapper().fromJson(store)
            ))
        )
    }
}
