import { NetworkRequestMapper } from 'data/http/mapper';
import { LoanRegistrationType } from 'core/entity/loan/registration-type';

export class LoanRegistrationTypeMapper implements NetworkRequestMapper<LoanRegistrationType> {
    toJson(target: LoanRegistrationType): any {
        switch (target) {
            case LoanRegistrationType.BRANCH_REGISTRABLE:
                return 'branch_registrable';
            case LoanRegistrationType.INTERNET_BANKING_REGISTRABLE:
                return 'internet_banking_registrable';
            case LoanRegistrationType.CALL_CENTER_REGISTRABLE:
                return 'call_center_registrable';
            case LoanRegistrationType.MOBILE_REGISTRABLE:
                return 'mobile_registrable';
            default:
                return 'internet_banking_registrable';
        }
    }

}
