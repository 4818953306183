import { NetworkResponseMapper } from 'data/http/mapper';
import { Alfred2TotalProfitConfiguration } from 'core/entity/alfred2/total-profit-configuration';
import { AmountMapper } from 'data/http/mapper/amount';
import { Alfred2PointAmountMapper } from 'data/http/mapper/alfred2/point-amount';

export class Alfred2TotalProfitConfigurationMapper implements NetworkResponseMapper<Alfred2TotalProfitConfiguration> {
    fromJson(json: any): Alfred2TotalProfitConfiguration {
        return new Alfred2TotalProfitConfiguration(
            new AmountMapper().fromJson(json.cash),
            new Alfred2PointAmountMapper().fromJson(json.point),
            new Alfred2PointAmountMapper().fromJson(json.mileage)
        );
    }
}
