import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceReducedSpendingAlcoholData } from 'core/entity/personal-finance/advice/content-data/reduced-spending-alcohol';
import { optional, parseDate } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';
import { AdviceTransactionPeriodSnapshotMapper } from 'data/http/mapper/personal-finance/advice/transaction-period-snapshot';

export class AdviceReducedSpendingAlcoholDataMapper implements NetworkResponseMapper<AdviceReducedSpendingAlcoholData> {
    fromJson(json: any): AdviceReducedSpendingAlcoholData {
        return new AdviceReducedSpendingAlcoholData(
            json.data.map(new AdviceTransactionPeriodSnapshotMapper().fromJson).toList(),
            parseDate(json.base_datetime),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }
}
