import { Entity } from 'core/entity';
import { FinanceSubSector } from 'core/entity/finance-sector/sub';
import { FinanceSectorID } from 'core/entity/finance-sector/id';

export class FinanceSector extends Entity {
    id: FinanceSectorID;
    name: string;
    sub1: FinanceSubSector;
    sub2: FinanceSubSector;
    sub3: FinanceSubSector;
    sub4?: FinanceSubSector;

    constructor(
        id: FinanceSectorID,
        name: string,
        sub1: FinanceSubSector,
        sub2: FinanceSubSector,
        sub3: FinanceSubSector,
        sub4?: FinanceSubSector
    ) {
        super();
        this.id = id;
        this.name = name;
        this.sub1 = sub1;
        this.sub2 = sub2;
        this.sub3 = sub3;
        this.sub4 = sub4;
    }
}
