import './card';
import './loan';
import './personal-finance';
import './personal-advice';
import './finance-sector';
import './user';
import './observable';
import './credit';
import './personalized-recommend';
import './personalized-finance';

import * as Sentry from '@sentry/browser';
import { UseCase } from 'core/use-case';
import { NetworkError } from 'data/http';
import { KakaoPixelEventName } from 'presentation/module/analytics/pixel';
import { OptimizeVariantType } from 'presentation/module/optimize-variant-type';
import { Observable, Scheduler } from 'rxjs/Rx';
import { Subscription } from 'rxjs/Subscription';

declare module "core/use-case" {
    export interface UseCase<T> {
        runOnAnimateFrame(): Observable<T>;
    }
}

UseCase.prototype.runOnAnimateFrame = function() {
  return this.run(Scheduler.async, Scheduler.animationFrame)
    .catch((err: Error) => {
      if (!(err instanceof NetworkError)) {
        Sentry.captureException(err);
      }
      throw err;
    });
};

declare module "rxjs/Subscription" {
    export interface Subscription {
        unsubscribeBy(bag: SubscriptionBag): void;
    }
}

Subscription.prototype.unsubscribeBy = function(bag: SubscriptionBag) {
  bag.push(this);
};

export class SubscriptionBag {
    private subscriptions: Array<Subscription> = [];

    push(subscription: Subscription) {
      this.subscriptions.push(subscription);
    }

    destroy() {
      this.subscriptions.forEach(it => it.unsubscribe());
    }
}

export enum NAMESPACE_ENV {
    PRODUCTION = 'production',
    DEVELOPMENT = 'development',
}

/**
 * @deprecated
 * isNamespaceProduction를 사용해 주세요. 
 */
export const isProd = (): boolean => window.namespaceEnv === NAMESPACE_ENV.PRODUCTION;

declare global {
  interface Window {
    webViewNativeInterface: any;
    webkit: any;
    variantType: OptimizeVariantType;
    sendFirebaseEvent: (filteredName: any, filteredParams: any) => void;
    applicationVersion: number;
    namespaceEnv: NAMESPACE_ENV;
    apiHost: string;
    gatewayHost: string;
    trackEvent: (filteredName: any, filteredParam: any, filteredType: any) => void;
    trackKakaoPixel: (eventName: KakaoPixelEventName, args?: any) => void;
    webViewFinish: () => void;
    gtag: any;
  }
}
