import { NetworkResponseMapper } from 'src/data/http/mapper/index';
import { parseDate } from 'utils/index';
import { AmountMapper } from 'data/http/mapper/amount';
import { UserCreditScoreJointSurety } from 'core/entity/personal-credit/joint-surety';
import { UserCreditScoreOrganizationMapper } from 'data/http/mapper/personal-credit/organization';

export class UserCreditScoreJointSuretyMapper implements NetworkResponseMapper<UserCreditScoreJointSurety> {
    fromJson(json: any): UserCreditScoreJointSurety {
        return new UserCreditScoreJointSurety(
            new UserCreditScoreOrganizationMapper().fromJson(json.organization),
            parseDate(json.contracted_at),
            new AmountMapper().fromJson(json.total_amount)
        )
    }
}
