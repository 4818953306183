import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { TransactionProduct } from 'core/entity/personal-finance/transaction/product';
import { TransactionCategorySummary } from 'core/entity/personal-finance/transaction/category/summary';
import { AdviceInstallmentConfigurationSnapshot } from 'core/entity/personal-finance/advice/installment-configuration-snapshot';

export class AdviceTransactionSnapshot extends Entity {
    id: string;
    title: string;
    transactedAt: Date;
    amount: Amount;
    product: TransactionProduct;
    category: TransactionCategorySummary;
    installmentConfiguration?: AdviceInstallmentConfigurationSnapshot;

    constructor(
        id: string,
        title: string,
        transactedAt: Date,
        amount: Amount,
        product: TransactionProduct,
        category: TransactionCategorySummary,
        installmentConfiguration: AdviceInstallmentConfigurationSnapshot = null
    ) {
        super();
        this.id = id;
        this.title = title;
        this.transactedAt = transactedAt;
        this.amount = amount;
        this.product = product;
        this.category = category;
        this.installmentConfiguration = installmentConfiguration;
    }
}
