import { Notice, NoticeType } from 'core/entity/notice';
import { NoticeRepositoryType } from 'core/repository/notice';
import { UseCase } from 'core/use-case';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class GetNotices extends UseCase<List<Notice>> {
    type: NoticeType;
    private repository: NoticeRepositoryType;

    constructor(repository: NoticeRepositoryType) {
      super();
      this.repository = repository;
    }

    protected build(): Observable<List<Notice>> {
      return this.repository.getAll(this.type);
    }

    protected validate(): boolean {
      return true;
    }
}
