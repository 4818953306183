import { AxiosInstance } from 'axios';
import { ZendeskQuestionApiProvider } from 'data/http/api/zendesk/category';

export class ZendeskProviderDependencies {
    serviceCenter: ZendeskQuestionApiProvider;

    constructor(zendesk: AxiosInstance) {
        this.serviceCenter = new ZendeskQuestionApiProvider(zendesk);
    }
}
