import { Entity } from 'core/entity';
import { CardBenefit } from 'core/entity/card/benefit';
import { List } from 'immutable';

export class CardStoreBenefit extends Entity {
    storeId: number;
    title: string;
    benefits: List<CardBenefit>;
    exclude: boolean;

    constructor(
        storeId: number,
        title: string,
        benefits: List<CardBenefit>,
        exclude: boolean
    ) {
        super();
        this.storeId = storeId;
        this.title = title;
        this.benefits = benefits;
        this.exclude = exclude;
    }
}
