import { Event } from 'presentation/bus';

export class GlobalAlertEvent implements Event {
    constructor(
        public title: string,
        public message: string,
        public onCancel: () => void = null
    ) {
    }
}
