import { NetworkResponseMapper } from 'data/http/mapper';
import { CardSpecialBenefit } from 'core/entity/card/special-benefit';

export class CardSpecialBenefitMapper implements NetworkResponseMapper<CardSpecialBenefit> {
    fromJson(json: any): CardSpecialBenefit {
        return new CardSpecialBenefit(
            json.title,
            json.description
        )
    }
}
