import { ContentColumnSummary } from 'core/entity/content/column/summary';
import { ContentSummary } from 'core/entity/content/summary';
import { List } from 'immutable';
import { BannerOrderList } from 'presentation/components/banner-order-list';
import { Carousel } from 'presentation/components/carousel';
import { GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    lists: List<ContentSummary>;
}

interface State {
    order: number;
}

export class ContentsHero extends React.Component<Props, State> {
    state = {
      order: 0
    };

    render() {
      const {
        order
      } = this.state;
      const {
        lists
      } = this.props;

      return (
        <div className={ styles.wrap }>
          <Carousel
            position={ order }
            onPositionChange={ this.onChangeBannerOrder }
          >
            <ul className={ styles.list }>
              {
                lists.map((item) =>
                  <li
                    key={ item.id }
                    className={ styles.item }
                  >
                    <div
                      style={{ backgroundImage: `url(${item.thumbnailImageUrl})` }}
                      className={ styles.background }
                    />
                    <div className={ styles.itemContainer }>
                      {
                        item instanceof ContentColumnSummary &&
                        <h1 className={ styles.category }>에디터가 뽑은 추천칼럼</h1>
                      }
                      <h1 className={ styles.title }>{ item.title }</h1>
                      <strong className={ styles.author }>{ item.author.profile.name }</strong>
                      <a
                        href={ BANKSALAD_SITEMAP_BY_ID.CONTENT(item.id) }
                        onClick={ () => sendGAEvent(
                          GA_DOMAIN.NONE, 
                          GA_CATEGORY.SECTORS.HERO, 
                          GA_ACTION.LINK.CONTENTS_DETAIL, `글보러가기_${item.id}`
                        ) }
                        className={ styles.link }
                      >
                        글 보러가기
                      </a>
                    </div>
                  </li>
                )
              }
            </ul>
          </Carousel>
          <div className={ styles.bannerOrderContainer }>
            <BannerOrderList
              lists={ lists }
              current={ order }
              onChange={ this.onChangeBannerOrder }
            />
          </div>
        </div>
      );
    }

    private onChangeBannerOrder = (order: number) => {
      const {
        lists
      } = this.props;

      if (order >= (lists.size) || order < 0)
        return;

      this.setState({
        order
      });
    }
}
