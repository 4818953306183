import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentAuthor } from 'core/entity/content/author';
import { ContentAuthorProfileMapper } from 'data/http/mapper/content/author/profile';

export class ContentAuthorMapper implements NetworkResponseMapper<ContentAuthor> {
    fromJson(json: any): ContentAuthor {
        return new ContentAuthor(
            json.id,
            new ContentAuthorProfileMapper().fromJson(json.profile)
        )
    }
}
