import { List } from 'immutable';
import { RecommendCardViewModel } from 'presentation/components/info/card/model/recommend-card';

export const RECOMMENDS_CARDS_FOR_NOT_REVIEWED_CREDIT_CARDS: List<List<RecommendCardViewModel>> = List.of(
  List.of(
    new RecommendCardViewModel(
      1572,
      '신한카드',
      '신한 Hi-Point 카드',
      true,
      'https://cdn.banksalad.com/entities/etc/1561359772723-1572.png',
    ),
    new RecommendCardViewModel(
      3578,
      '신한카드',
      '신한카드 Deep Dream',
      true,
      'https://cdn.banksalad.com/cards/3578.png',
    ),
    new RecommendCardViewModel(
      1897,
      '신한카드',
      '신한카드 B.Big(삑)',
      true,
      'https://cdn.banksalad.com/entities/etc/1560309225803-1897.png',
    ),
    new RecommendCardViewModel(
      2396,
      '신한카드',
      '신한카드 Mr.Life',
      true,
      'https://cdn.banksalad.com/entities/etc/1575275479392-2396.png',
    ),
  ),
  List.of(
    new RecommendCardViewModel(
      2928,
      '삼성카드',
      '삼성카드 taptap O',
      true,
      'https://cdn.banksalad.com/cards/samsung_2928.png',
    ),
    new RecommendCardViewModel(
      2927,
      '삼성카드',
      '삼성카드 taptap S',
      true,
      'https://cdn.banksalad.com/cards/samsung_2927.png',
    ),
    new RecommendCardViewModel(
      3350,
      '국민카드',
      '청춘대로 톡톡카드',
      true,
      'https://cdn.banksalad.com/cards/kb_3350.png',
    ),
    new RecommendCardViewModel(
      2502,
      '국민카드',
      'KB국민 다담카드(스마트 OTP)',
      true,
      'https://cdn.banksalad.com/cards/kb_2502.png',
    ),
    new RecommendCardViewModel(
      2244,
      '국민카드',
      'KB국민 FINETECH카드(마일리지 아시아나)',
      true,
      'https://cdn.banksalad.com/cards/kb_04582.png',
    ),
  ),
  List.of(
    new RecommendCardViewModel(
      3722,
      '하나카드',
      'my pass 마패 카드',
      true,
      'https://cdn.banksalad.com/entities/etc/1533712616223-3722.png',
    ),
    new RecommendCardViewModel(
      3644,
      '하나카드',
      '통커 카드',
      true,
      'https://cdn.banksalad.com/entities/etc/1524028472807-3644.png',
    ),
  ),
);

export const RECOMMENDS_CARDS_FOR_NOT_REVIEWED_CHECK_CARDS: List<List<RecommendCardViewModel>> = List.of(
  List.of(
    new RecommendCardViewModel(
      1572,
      '신한카드',
      '신한 Hi-Point 카드',
      true,
      'https://cdn.banksalad.com/entities/etc/1561359772723-1572.png',
    ),
    new RecommendCardViewModel(
      3578,
      '신한카드',
      '신한카드 Deep Dream',
      true,
      'https://cdn.banksalad.com/cards/3578.png',
    ),
    new RecommendCardViewModel(
      1897,
      '신한카드',
      '신한카드 B.Big(삑)',
      true,
      'https://cdn.banksalad.com/entities/etc/1560309225803-1897.png',
    ),
    new RecommendCardViewModel(
      2396,
      '신한카드',
      '신한카드 Mr.Life',
      true,
      'https://cdn.banksalad.com/entities/etc/1575275479392-2396.png',
    ),
  ),
  List.of(
    new RecommendCardViewModel(
      2928,
      '삼성카드',
      '삼성카드 taptap O',
      true,
      'https://cdn.banksalad.com/cards/samsung_2928.png',
    ),
    new RecommendCardViewModel(
      2927,
      '삼성카드',
      '삼성카드 taptap S',
      true,
      'https://cdn.banksalad.com/cards/samsung_2927.png',
    ),
    new RecommendCardViewModel(
      3350,
      '국민카드',
      '청춘대로 톡톡카드',
      true,
      'https://cdn.banksalad.com/cards/kb_3350.png',
    ),
    new RecommendCardViewModel(
      2502,
      '국민카드',
      'KB국민 다담카드(스마트 OTP)',
      true,
      'https://cdn.banksalad.com/cards/kb_2502.png',
    ),
    new RecommendCardViewModel(
      2244,
      '국민카드',
      'KB국민 FINETECH카드(마일리지 아시아나)',
      true,
      'https://cdn.banksalad.com/cards/kb_04582.png',
    ),
  ),
  List.of(
    new RecommendCardViewModel(
      3722,
      '하나카드',
      'my pass 마패 카드',
      true,
      'https://cdn.banksalad.com/entities/etc/1533712616223-3722.png',
    ),
    new RecommendCardViewModel(
      3644,
      '하나카드',
      '통커 카드',
      true,
      'https://cdn.banksalad.com/entities/etc/1524028472807-3644.png',
    ),
  ),
);

export const CITI_COMPANY_ID = 1;
export const SUHYUP_COMPANY_ID = 23;
export const KONA_COMPANY_ID = 33;
