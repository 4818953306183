import React, { ReactNode } from 'react';

import styles from './styles.pcss';

interface Props {
    title: string;
    children: ReactNode;
    subTitle?: string;
    isSingUpView?: boolean;
    visibleFooter?: boolean;
}


export const UserAuthenticationViewContainer = ({
  title,
  subTitle,
  children
}: Props) => {
  return (
    <section className={ styles.wrap }>
      <h1 className={ styles.title }>
        { title }
      </h1>
      {
        !!subTitle &&
                <h3 className={ styles.subTitle }>
                  { subTitle }
                </h3>
      }
      <div className={ styles.content }>
        { children }
      </div>
    </section>
  );
};
