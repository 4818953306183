import { Observable } from 'rxjs/Rx';
import { HttpProvider } from 'data/http';
import { Promotion } from 'core/entity/promotion';
import { PromotionMapper } from 'data/http/mapper/promotion';

export class PromotionApiProvider extends HttpProvider {
    get(promotionName: string): Observable<Promotion> {
        return this.requestGET(`/app/promotion/${promotionName}/config.json`)
            .map((result: any) => (
                new PromotionMapper().fromJson(result)
            ))
    }
}
