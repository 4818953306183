import { MappingError, NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, lets } from 'utils/index';
import { MobileAuthenticationPhoneConfiguration } from 'core/entity/user/mobile-authenticate-phone-configuration';

export class MobileAuthenticationPhoneConfigurationMapper implements NetworkRequestMapper<MobileAuthenticationPhoneConfiguration> {
    toJson(target: MobileAuthenticationPhoneConfiguration): any {
        return compactObject({
            number: target.number,
            company: lets(target.company, company => {
                switch (company) {
                    case MobileAuthenticationPhoneConfiguration.Company.SKT:
                        return 'skt';
                    case MobileAuthenticationPhoneConfiguration.Company.KT:
                        return 'kt';
                    case MobileAuthenticationPhoneConfiguration.Company.LGU:
                        return 'lgu';
                    case MobileAuthenticationPhoneConfiguration.Company.SUB_SKT:
                        return 'sub-skt';
                    case MobileAuthenticationPhoneConfiguration.Company.SUB_KT:
                        return 'sub-kt';
                    case MobileAuthenticationPhoneConfiguration.Company.SUB_LGU:
                        return 'sub-lgu';
                    default:
                        throw new MappingError();
                }
            })
        })
    }
}
