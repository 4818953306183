import { Entity } from 'core/entity';

export class LoanDescriptions extends Entity {
    product: string;
    amountLimit: string;
    interest: string;
    period: string;
    registration: string;
    additionalFees: string;
    requiredDocuments: string;
    warning: string;
    qualifications: string;
    repayment: string;

    constructor(
        product: string,
        amountLimit: string,
        interest: string,
        period: string,
        registration: string,
        additionalFees: string,
        requiredDocuments: string,
        warning: string,
        qualifications: string,
        repayment: string
    ) {
        super();
        this.product = product;
        this.amountLimit = amountLimit;
        this.interest = interest;
        this.period = period;
        this.registration = registration;
        this.additionalFees = additionalFees;
        this.requiredDocuments = requiredDocuments;
        this.warning = warning;
        this.qualifications = qualifications;
        this.repayment = repayment;
    }
}
