import { UseCase } from 'core/use-case';
import { CardSummaryRepositoryType } from 'core/repository/card-summary';
import { CardSummary } from 'core/entity/card/summary';
import { Observable } from 'rxjs/Rx';

export class GetCachedCardForCompare extends UseCase<CardSummary> {
    private repository: CardSummaryRepositoryType;

    constructor(repository: CardSummaryRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<CardSummary> {
        return this.repository.getAll().map(it => it.first());
    }

    protected validate(): boolean {
        return true;
    }
}
