import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreCardStatus } from 'core/entity/personal-credit/card-status';
import { UserCreditScoreCardMapper } from 'data/http/mapper/personal-credit/card';

export class UserCreditScoreCardStatusMapper implements
    NetworkResponseMapper<UserCreditScoreCardStatus>,
    NetworkRequestMapper<UserCreditScoreCardStatus>
{
    private updatedAt: Date;
    private changed: boolean;

    constructor(
        updatedAt: Date,
        changed: boolean
    ) {
        this.updatedAt = updatedAt;
        this.changed = changed;
    }

    toJson(target: UserCreditScoreCardStatus): any {
        return {
            cards: target.cards.map(new UserCreditScoreCardMapper().toJson).toArray()
        };
    }

    fromJson(json: any): UserCreditScoreCardStatus {
        return new UserCreditScoreCardStatus(
            json.cards.map(new UserCreditScoreCardMapper().fromJson).toList(),
            this.updatedAt,
            this.changed
        )
    }

}
