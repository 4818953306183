import React from 'react';
import { RouteComponentProps } from 'react-router';
import { List } from 'immutable';

import { FetchState } from 'presentation/view-model/fetch-state';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { CreditLoanInfo } from 'presentation/components/info/credit-loan';
import { Loader } from 'presentation/components/loader';
import { FetchError } from 'presentation/components/fetch-state/error';
import { apply, emptyList, lets } from 'utils/index';
import { LoanRecommendResult } from 'core/entity/loan/recommend-result';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { EventBus } from 'presentation/bus';
import { SelectLoanPrimeInterestNodeEvent } from 'presentation/bus/event/select-loan-prime-interest-node-event';
import { LoanPrimeInterestNode } from 'core/entity/loan/prime-interest-node';
import { LoanPrimeInterestNodeStatusType } from 'core/entity/loan/prime-interest-node-status-type';
import {
    GA_ACTION, GA_CATEGORY, GA_EVENT_VALUE, GA_DOMAIN,
    sendGAEvent
} from 'presentation/module/analytics/ga';
import { CreditLoanAmount } from 'presentation/components/survey-form/credit-loan-amount/model';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanLegalConfiguration } from 'core/entity/legal-configuration/loan';

interface State {
    loan?: LoanRecommendResult;
    legalConfig?: LoanLegalConfiguration;
    nodes: List<LoanPrimeInterestNode>;
    fetchState: FetchState;
    amount: CreditLoanAmount;
    repaymentPeriod: LoanRecommendFilter.RepaymentPeriod;
}

export class CreditLoanProfitsRoute extends React.Component<RouteComponentProps<any>, State> {
    state = {
        repaymentPeriod: LoanRecommendFilter.RepaymentPeriod.UNDER_ONE_YEAR,
        amount: new CreditLoanAmount(),
        loan: null as LoanRecommendResult,
        legalConfig: null as LoanLegalConfiguration,
        nodes: emptyList(),
        fetchState: FetchState.FETCHING
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        this.fetchCachedData();
        EventBus
            .toObservable()
            .subscribe(event => {
                if (event instanceof SelectLoanPrimeInterestNodeEvent) {
                    this.onChangePrimeInterestNode(event.node);
                }
            })
            .unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { loan, legalConfig, fetchState, amount, repaymentPeriod } = this.state;

        return (
            <div>
                { loan && <MetaHelmet meta={ META_SET.CREDIT_LOAN_BEST } /> }
                {
                    lets(fetchState, it => {
                        switch (it) {
                            case FetchState.FETCHED:
                                return (
                                    <CreditLoanInfo
                                        loan={ loan.loan }
                                        legalConfig={ legalConfig }
                                        profits={ loan.computation }
                                        amount={ amount }
                                        repaymentPeriod={ repaymentPeriod }
                                    />
                                );
                            case FetchState.FETCHING:
                                return <Loader padding={ 250 } radius={ 25 } />;
                            default:
                                return (
                                    <FetchError padding={ 250 }>
                                        데이터를 불러오는 중 오류가 발생했습니다<br/>
                                        잠시 후 다시 시도해주세요
                                    </FetchError>
                                )
                        }
                    })
                }
            </div>
        )
    }

    private fetch() {
        apply(Application.useCases.computeLoanValues, it => {
            it.slug = this.props.match.params.slug;
            it.primeInterestNodeIds = this.state.nodes.map(it => it.id).toList()
        }).runOnAnimateFrame().subscribe(
            loan =>
                this.setState({
                    loan,
                    fetchState: FetchState.FETCHED
                }, this.fetchLegalConfig),
            () =>
                location.href = BANKSALAD_SITEMAP.CREDIT_LOAN_QUESTIONS
        ).unsubscribeBy(this.subscriptionBag);
    }

    private fetchLegalConfig = () => {
        apply(Application.useCases.getLoanLegalConfigByLoanId, it => {
            it.loanId = this.state.loan.loan.id;
        }).runOnAnimateFrame().subscribe(
            legalConfig =>
                this.setState({
                    legalConfig
                }),
            () =>
                this.setState({
                    legalConfig: null
                })
        ).unsubscribeBy(this.subscriptionBag);
    };

    private fetchCachedData = () => {
        Application.useCases.getLoanProfitsCachedData
            .runOnAnimateFrame()
            .subscribe(
                result => {
                    this.setState({
                        amount: new CreditLoanAmount(result.first.desiredAmount),
                        repaymentPeriod : result.second.repaymentPeriod,
                    }, () => this.fetch())
                },
                () => this.fetch()
            ).unsubscribeBy(this.subscriptionBag);
    };

    private onChangePrimeInterestNode = (node: LoanPrimeInterestNode) => {
        const { nodes } = this.state;
        if (!node.children.isEmpty()) {
            return;
        }

        if (
            node.status.type === LoanPrimeInterestNodeStatusType.CHECKABLE &&
            !nodes.find(it => it.id === node.id)
        ) {
            sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOAN_PROFITS.TAB_PROFIT, GA_ACTION.CHECKBOX, node.description, GA_EVENT_VALUE.CHECKED);
            this.setState({
                nodes: nodes.push(node)
            }, this.fetch)
        } else if (
            node.status.type === LoanPrimeInterestNodeStatusType.CHECKED &&
            !!nodes.find(it => it.id === node.id)
        ) {
            sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOAN_PROFITS.TAB_PROFIT, GA_ACTION.CHECKBOX, node.description, GA_EVENT_VALUE.UNCHECKED);
            this.setState({
                nodes: nodes.filter(it => it.id !== node.id).toList()
            }, this.fetch)
        }
    };
}
