import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoanInterest } from 'core/entity/personalized-recommend/loan/interest';
import { lets, optional } from 'utils/index';
import { PersonalizedLoanInterestPromotionMapper } from 'data/http/mapper/personalized-recommend/loan/interest-promotion';

export class PersonalizedLoanInterestMapper implements NetworkResponseMapper<PersonalizedLoanInterest> {
    fromJson(json: any): PersonalizedLoanInterest {
        return new PersonalizedLoanInterest(
            lets(json.status, type => {
                switch (type) {
                    case 'confirmed':
                        return PersonalizedLoanInterest.Status.CONFIRMED;
                    case 'estimated':
                        return PersonalizedLoanInterest.Status.ESTIMATED;
                    case 'need-consulting':
                        return PersonalizedLoanInterest.Status.NEED_CONSULTING;
                    default:
                        throw new MappingError();
                }
            }),
            json.interest_wording,
            json.wording,
            optional(json.additional_wording),
            optional(json.loan_promotion, new PersonalizedLoanInterestPromotionMapper().fromJson)
        );
    }
}
