import { ClickFooter } from "../interfaces/footer";
import { sendTrackEvent } from "../send-track-event";
import { EventName } from "../types/event-name";

export const trackClickFooter = (footerText: string) => {
  sendTrackEvent<ClickFooter>({
    eventParam: {
      event_type: EventName.CLICK_FOOTER,
      footer_text: footerText,
      url_path: window.location.pathname,
    },
  });
};
