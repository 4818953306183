import { BdsColorIllustrationSettingBanksaladSignature1Svg, BdsMonoBottomNavigationMenuOffLarge1Svg, BdsMonoCommonArrowRightLarge1Svg, BdsMonoCommonArrowUpLarge1Svg, BdsMonoTopNavigationCloseLarge1Svg } from 'presentation/components/shared/bpl/icons';
import { trackClickGnb, trackClickLnb } from 'presentation/module/analytics/events/trackers/gnb';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import React, { useState } from 'react';

import { Gnb, LnbCategoryItem, MobileFeaturedItem } from '../gnb-data';
import styles from './styles.pcss';

interface GlobalNavigationBarMobileProps {
  gnbData: Gnb[];
}

export const GlobalNavigationBarMobile = ({ gnbData }: GlobalNavigationBarMobileProps) => {
  const [isGnbListOpened, setIsGnbListOpened] = useState(false);

  const toggleMenu = (open: boolean) => {
    setIsGnbListOpened(open);
  };

  return (
    <nav className={styles.gnbMobileContainer}>
      <div className={styles.gnbMobile}>
        <a
          href={BANKSALAD_SITEMAP.MAIN}
          className={ isGnbListOpened ? styles.biHidden : styles.bi }
        >
          <BdsColorIllustrationSettingBanksaladSignature1Svg width={141} height={23} title='뱅크샐러드' />
          <h1>뱅크샐러드</h1>
        </a>

        <a onClick={() => toggleMenu(!isGnbListOpened)}>
          {isGnbListOpened
            ? <BdsMonoTopNavigationCloseLarge1Svg />
            : <BdsMonoBottomNavigationMenuOffLarge1Svg />
          }
        </a>
      </div>

      <GnbListMobile
        gnbData={gnbData}
        isGnbListOpened={isGnbListOpened}
      />
    </nav>
  );
};

const GnbListMobile = ({
  gnbData,
  isGnbListOpened
}: { gnbData: Gnb[]; isGnbListOpened: boolean }) => {
  const [openedGnbId, setOpenedGnbId] = useState<Gnb['id']>(null);

  return (
    <ul className={ isGnbListOpened ? styles.gnbListMobileVisible : styles.gnbListMobileHidden }>
      {gnbData.map((gnbItem) =>
        <GnbListItemMobile
          gnbItem={gnbItem}
          key={gnbItem.id}
          isLnbCategoryListOpened={openedGnbId === gnbItem.id}
          onClick={() => setOpenedGnbId(
            openedGnbId === gnbItem.id ? null : gnbItem.id
          )}
        />
      )}
    </ul>
  );
};

const GnbListItemMobile = ({
  gnbItem,
  isLnbCategoryListOpened,
  onClick
}: {
  gnbItem: Gnb;
  isLnbCategoryListOpened: boolean;
  onClick: () => void;
}) => {
  const hasLnbData = 
    gnbItem.lnbCategories !== undefined ||
    gnbItem.featuredItemsInMobile !== undefined;

  if (hasLnbData) {
    return (
      <li
        key={ gnbItem.id }
        className={ styles.gnbItemMobileContainer }
        onClick={onClick}
      >
        <div className={ styles.gnbItemTitleContainer }>
          <div className={ styles.gnbItemTitleIconText }>
            <div className={ styles.icon }>
              {gnbItem.icon}
            </div>
            {gnbItem.title}
          </div>
          <div
            className={
              isLnbCategoryListOpened
                ? styles.rotateArrowContainerReverse : styles.rotateArrowContainer
            }
          >
            <BdsMonoCommonArrowUpLarge1Svg />
          </div>
        </div>

        <LnbCategoryListMobile
          gnbItem={gnbItem} isLnbCategoryListOpened={isLnbCategoryListOpened}
        />
      </li>
    );
  }

  return (
    <li key={ gnbItem.id }>
      <a
        href={ gnbItem.link.url }
        onClick={ () => trackClickGnb(gnbItem.title) }
        target={gnbItem.link.linkTarget}
        className={ styles.gnbItemTitleContainer }
      >
        <div className={ styles.gnbItemTitleIconText }>
          <div className={ styles.icon }>
            {gnbItem.icon}
          </div>
          {gnbItem.title}
        </div>
        <div onClick={onClick} className={ styles.arrowGray } >
          <BdsMonoCommonArrowRightLarge1Svg />
        </div>
      </a>
    </li>
  );
};

const LnbCategoryListMobile = ({
  gnbItem,
  isLnbCategoryListOpened
}: { gnbItem: Gnb; isLnbCategoryListOpened: boolean }) => {
  const isLnbCategoriesEmpty =
    gnbItem.lnbCategories === undefined || gnbItem.lnbCategories.length === 0;

  if (isLnbCategoriesEmpty && gnbItem.featuredItem === undefined) {
    return null;
  }

  return (
    <div className={ isLnbCategoryListOpened
      ? styles.lnbCategoryListVisible : styles.lnbCategoryListHidden }>

      {gnbItem.featuredItemsInMobile !== undefined &&
        <ul>
          {gnbItem.featuredItemsInMobile.map(item => (
            <LnbCategoryFeaturedItemMobile
              mobileFeaturedItem={item}
              gnbItemTitle={gnbItem.title}
              key={`${gnbItem.title}-${item.title}`} />
          ))}
          <HorizontalDivider />
        </ul>
      }

      {gnbItem.lnbCategories !== undefined && gnbItem.lnbCategories.map((lnbCategory, index) => (
        lnbCategory.title === undefined && lnbCategory.items.length < 0 ?
          null
          : (<section key={ `${gnbItem.id}-${lnbCategory.title}` }>
            {index !== 0 && <HorizontalDivider />}
            <div className={ styles.lnbCategoryTitle }>{lnbCategory.title}</div>
            <ul>
              {lnbCategory.items.map((item) =>
                <LnbCategoryItemMobile
                  lnbCategoryItem={item}
                  gnbItemTitle={gnbItem.title}
                  key={`${lnbCategory.title}-${lnbCategory.title}-${item.title}`}
                />
              )}
            </ul>
          </section>)
      ))}

    </div>
  );
};

const LnbCategoryFeaturedItemMobile = ({
  mobileFeaturedItem,
  gnbItemTitle
}: { mobileFeaturedItem: MobileFeaturedItem; gnbItemTitle: string }) => {
  return (
    <li>
      <a
        href={ mobileFeaturedItem.link.url }
        onClick={ () => trackClickLnb(gnbItemTitle, mobileFeaturedItem.title) }
        target={mobileFeaturedItem.link.linkTarget}
        className={ styles.lnbCategoryItemContainer }
      >
        <div className={ styles.lnbFeaturedItemTitleContainer }>
          <div className={ styles.lnbFeaturedItemTitle }>
            {mobileFeaturedItem.title}
          </div>
          {mobileFeaturedItem.type && <Badge type={mobileFeaturedItem.type} />}
        </div>
        <div className={ styles.arrowGray }>
          <BdsMonoCommonArrowRightLarge1Svg />
        </div>
      </a>
    </li>
  );
};

const LnbCategoryItemMobile = ({
  lnbCategoryItem,
  gnbItemTitle
}: { lnbCategoryItem: LnbCategoryItem; gnbItemTitle: string }) => {
  return (
    <li>
      <a
        href={ lnbCategoryItem.url }
        onClick={ () => trackClickLnb(gnbItemTitle, lnbCategoryItem.title) }
        className={ styles.lnbCategoryItemContainer }
      >
        <div className={ styles.lnbItemTitle }>
          {lnbCategoryItem.title}
        </div>
        <div className={ styles.arrowGray }>
          <BdsMonoCommonArrowRightLarge1Svg />
        </div>
      </a>
    </li>
  );
};

const Badge = ({ type }: {type: MobileFeaturedItem['type']}) => {
  const getStyles = (type: MobileFeaturedItem['type']) => {
    switch (type) {
    case 'New':
      return styles.newBadge; 
    case 'BEST':
    default:
      return styles.bestBadge; 
    }
  };

  return (
    <div className={ getStyles(type) }>
      {type}
    </div>
  );
};

const HorizontalDivider = () => {
  return (
    <div className={ styles.horizontalDivider} />
  );
};
