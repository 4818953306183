import { Entity } from 'core/entity';
import { UserCreditScoreOrganization } from 'core/entity/personal-credit/organization';
import { Amount } from 'core/entity/amount';

export class UserCreditScoreOverdueConfiguration extends Entity {
    organization: UserCreditScoreOrganization;
    principal: Amount;
    balance: Amount;
    repaymentAmount: Amount;
    createdAt: Date;

    constructor(
        organization: UserCreditScoreOrganization,
        principal: Amount,
        balance: Amount,
        repaymentAmount: Amount,
        createdAt: Date
    ) {
        super();
        this.organization = organization;
        this.principal = principal;
        this.balance = balance;
        this.repaymentAmount = repaymentAmount;
        this.createdAt = createdAt;
    }
}
