import { Card } from 'core/entity/card';
import { CardCategorySpecialBenefit } from 'core/entity/card/category-special-benefit';
import { CardPreferredSpecialBenefit } from 'core/entity/card/preferred-special-benefit';
import { HTMLBody } from 'presentation/components/html-body';
import { CardInfoEmpty } from 'presentation/components/info/card/detail/base-unit/empty';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, GA_EVENT_VALUE, sendGAEvent } from 'presentation/module/analytics/ga';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    card: Card;
}

export class CardInfoSpecialBenefits extends React.Component<Props> {
  render() {
    const { categorySpecialBenefits } = this.props.card;

    return categorySpecialBenefits.isEmpty() ? (
      <CardInfoEmpty>
        이 상품은 제공하는 특별서비스가 없습니다.
      </CardInfoEmpty>
    ) : (
      <ul className={ styles.benefits }>
        { categorySpecialBenefits.map(this.renderBenefit) }
      </ul>
    );
  }

  private renderBenefit = (benefit: CardCategorySpecialBenefit, index: number) => {
    const id = `category-special-benefits-${index}`;

    return (
      <li key={ id }>
        <input
          type="checkbox"
          id={ id }
          onChange={ e => {
            sendGAEvent(
              GA_DOMAIN.CARD,
              GA_CATEGORY.CARD_PROFITS.DETAIL_SPECIAL_SERVICE,
              GA_ACTION.DROP_DOWN,
              benefit.category,
              e.currentTarget.checked ?
                GA_EVENT_VALUE.OPEN :
                GA_EVENT_VALUE.CLOSE
            );
          } }
        />
        <label htmlFor={ id }>{ benefit.category }</label>
        <section className={ styles.detail }>
          <ul className={ styles.group }>
            { benefit.specialBenefits.map(this.renderBenefitDetail(index)) }
          </ul>
        </section>
      </li>
    );
  };
  
  private renderBenefitDetail = (parentIndex: number) => 
    (item: CardPreferredSpecialBenefit, index: number) => (
      <li key={ `category-special-benefit-detail-${parentIndex}-${index}` }>
        <h5>{ item.title }</h5>
        <ul className={ styles.descriptions }>
          {
            item.benefits.map((detail, i) =>
              <li key={ `category-special-benefit-detail-${parentIndex}-${index}-${i}` }>
                <h6>{ detail.title }</h6>
                { detail.description !== null && <HTMLBody html={ detail.description } /> }
              </li>
            )
          }
        </ul>
      </li>
    );
}
