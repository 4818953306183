import React from 'react';

import { HOCCheckBox } from 'presentation/components/input/hoc-check-box';
import { uniqueKey } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    text: string;
    checked: boolean;
    checkboxOnChange: () => void;
}

class LoginMobileAllCheckboxUIComponent extends React.Component<Props> {
    private id = uniqueKey();

    render() {
        const {
            text,
            checked,
            checkboxOnChange
        } = this.props;

        return (
            <div className={ styles.wrap }>
                <input
                    id={ this.id }
                    type="checkbox"
                    onChange={ checkboxOnChange }
                    checked={ checked }
                    className={ styles.input }
                />
                <label htmlFor={ this.id } className={ styles.label }>{ text }</label>
            </div>

        )
    }
}

export const LoginMobileAllCheckbox = HOCCheckBox(LoginMobileAllCheckboxUIComponent);
