import { UseCase } from 'core/use-case';
import { CardConfiguration } from 'core/entity/card/configuration';
import { CardConfigurationRepositoryType } from 'core/repository/card-configuration';
import { Observable } from 'rxjs/Rx';

export class GetCardConfiguration extends UseCase<CardConfiguration> {
    private repository: CardConfigurationRepositoryType;

    constructor(repository: CardConfigurationRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<CardConfiguration> {
        return this.repository.get();
    }

    protected validate(): boolean {
        return true;
    }
}
