import { Observable } from 'rxjs/Rx';
import { LoanSurveyConfigurationRepositoryType } from 'core/repository/loan-survey-configuration';
import { LoanSurveyConfiguration } from 'core/entity/loan/survey-configuration';
import { LoanSurveyConfigurationApiProvider } from 'data/http/api/gateway/v2/loan/survey-configuration';

export class LoanSurveyConfigurationRepository implements LoanSurveyConfigurationRepositoryType {
    private api: LoanSurveyConfigurationApiProvider;

    constructor(api: LoanSurveyConfigurationApiProvider) {
        this.api = api;
    }

    get(): Observable<LoanSurveyConfiguration> {
        return this.api.get();
    }

}
