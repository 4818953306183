import { UserActualAsset } from 'core/entity/personal-finance/actual-asset';
import { UserActualAssetUpdateSpec } from 'core/entity/personal-finance/actual-asset/update-spec';
import { HttpProvider } from 'data/http';
import { UserActualAssetMapper } from 'data/http/mapper/personal-finance/actual-asset';
import { UserActualAssetUpdateSpecMapper } from 'data/http/mapper/personal-finance/actual-asset/update-spec';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserActualAssetCreateSpec } from 'core/entity/personal-finance/actual-asset/create-spec';
import { UserActualAssetCreateSpecMapper } from 'data/http/mapper/personal-finance/actual-asset/create-spec';

export class UserActualAssetsApiProvider extends HttpProvider {
    create(accessToken: AccessToken, spec: UserActualAssetCreateSpec): Observable<UserActualAsset> {
        return this.requestPOST(
            'api/v2/me/actual-assets',
            new UserActualAssetCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserActualAssetMapper().fromJson(result.data)
        )
    }

    update(accessToken: AccessToken, id: string, spec: UserActualAssetUpdateSpec): Observable<UserActualAsset> {
        return this.requestPATCH(
            `api/v2/me/actual-assets/${id}`,
            new UserActualAssetUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserActualAssetMapper().fromJson(result.data)
        )
    }

}
