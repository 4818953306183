import { List } from 'immutable';
import { Entity } from 'core/entity';
import { ContentTagCategory } from 'core/entity/content/tag/category';
import { ContentSummary } from 'core/entity/content/summary';

export class ContentTag extends Entity {
    id: string;
    name: string;
    imageUrl: string;
    category: ContentTagCategory;
    featured: boolean;
    featuredContents: List<ContentSummary>;

    constructor(
        id: string,
        name: string,
        imageUrl: string,
        category: ContentTagCategory,
        featured: boolean,
        featuredContents: List<ContentSummary>
    ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.category = category;
        this.featured = featured;
        this.featuredContents = featuredContents;
    }
}
