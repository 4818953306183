import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardBrand } from 'core/entity/card/brand';
import { Card } from 'core/entity/card';

export class CardFilter extends Entity {
    cardIds?: List<number> = null;
    name?: string = null;
    contractedOnly?: boolean = null;
    unstoppedOnly?: boolean = null;
    annualCostMin?: number = null;
    annualCostMax?: number = null;
    promotionOnly?: boolean = null;
    brands?: List<CardBrand> = null;
    companies?: List<string> = null;
    specialBenefitIds?: List<number> = null;
    cardTypes?: List<Card.Type> = null;
    channel: CardFilter.Channel;

    constructor(
        cardIds: List<number> = null,
        name: string = null,
        contractedOnly: boolean = null,
        unstoppedOnly: boolean = null,
        annualCostMin: number = null,
        annualCostMax: number = null,
        promotionOnly: boolean = null,
        brands: List<CardBrand> = null,
        companies: List<string> = null,
        specialBenefitIds: List<number> = null,
        cardTypes: List<Card.Type> = null,
        channel: CardFilter.Channel = CardFilter.Channel.BANKSALAD
    ) {
        super();
        this.cardIds = cardIds;
        this.name = name;
        this.contractedOnly = contractedOnly;
        this.unstoppedOnly = unstoppedOnly;
        this.annualCostMin = annualCostMin;
        this.annualCostMax = annualCostMax;
        this.promotionOnly = promotionOnly;
        this.brands = brands;
        this.companies = companies;
        this.specialBenefitIds = specialBenefitIds;
        this.cardTypes = cardTypes;
        this.channel = channel;
    }
}

export module CardFilter {
    export enum Channel {
        BANKSALAD,
        INTERPARK
    }
}
