import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { parseDate } from 'utils/index';
import { UserCreditScoreInquiryRecordStatus } from 'core/entity/personal-credit/inquery-record-status';
import { UserCreditScoreInquiryRecordStatusMapper } from 'data/http/mapper/personal-credit/inquiry-record-status';

export class UserCreditScoreInquiryRecordsFromKCBApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<UserCreditScoreInquiryRecordStatus> {
        return this.requestGET(
            'api/v2/me/credit-scores/KCB/inquiry-records',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCreditScoreInquiryRecordStatusMapper(
                parseDate(result.updated_at),
                result.changed
            ).fromJson(result.data)
        )
    }

}
