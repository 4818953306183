import { List } from 'immutable';
import { Entity } from 'core/entity';
import { Card } from 'core/entity/card';

export class CardSearchResult extends Entity {
    numberOfCards: number;
    isLast: boolean;
    cards: List<Card>;

    constructor(
        numberOfCards: number,
        isLast: boolean,
        cards: List<Card>
    ) {
        super();
        this.numberOfCards = numberOfCards;
        this.isLast = isLast;
        this.cards = cards;
    }
}
