import { Entity } from 'core/entity';
import { List } from 'immutable';
import { UserSecuritiesFirmAccountFundItem } from 'core/entity/personal-finance/securities-firm-account/fund-item';
import { UserSecuritiesFirmAccountStockItem } from 'core/entity/personal-finance/securities-firm-account/stock-item';

export class UserSecuritiesFirmAccountItemConfiguration extends Entity {
    funds: List<UserSecuritiesFirmAccountFundItem>;
    stocks: List<UserSecuritiesFirmAccountStockItem>;

    constructor(
        funds: List<UserSecuritiesFirmAccountFundItem>,
        stocks: List<UserSecuritiesFirmAccountStockItem>
    ) {
        super();
        this.funds = funds;
        this.stocks = stocks;
    }
}
