import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentAuthorSummaryMapper } from 'data/http/mapper/content/author/summary';
import { ContentCardNewsSummary } from 'core/entity/content/card-news/summary';
import { parseDate } from 'utils/index';

export class ContentCardNewsSummaryMapper implements NetworkResponseMapper<ContentCardNewsSummary> {
    fromJson(json: any): ContentCardNewsSummary {
        return new ContentCardNewsSummary(
            json.id,
            json.title,
            json.description,
            new ContentAuthorSummaryMapper().fromJson(json.author),
            json.thumbnail_image_url,
            parseDate(json.created_at)
        )
    }
}
