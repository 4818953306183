import { List } from 'immutable';
import { Entity } from 'core/entity';
import { CardMileage } from 'core/entity/card/mileage';

export class CardAmount extends Entity {
    discount: number;
    point: number;
    mileage: number;
    mileages: List<CardMileage>;

    constructor(
        discount: number,
        point: number,
        mileage: number,
        mileages: List<CardMileage>
    ) {
        super();
        this.discount = discount;
        this.point = point;
        this.mileage = mileage;
        this.mileages = mileages;
    }
}
