import { PoliciesV4PrivacyAgreementRoute } from 'presentation/routes/policies/v4/privacy-agreement';
import { PoliciesV4PrivacyPolicyRoute } from 'presentation/routes/policies/v4/privacy-policy';
import { PoliciesV4TermsRoute } from 'presentation/routes/policies/v4/terms';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

export const PoliciesV4Route = ({ match }: RouteComponentProps<any>) => (
  <Switch>
    <Route
      path={ `${match.url}/privacy-policy` }
      component={ PoliciesV4PrivacyPolicyRoute }
    />
    <Route
      path={ `${match.url}/privacy-agreement` }
      component={ PoliciesV4PrivacyAgreementRoute }
    />
    <Route
      path={ `${match.url}/terms`}
      component={ PoliciesV4TermsRoute }
    />
  </Switch>
);
