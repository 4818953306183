import { UserFund } from 'core/entity/personal-finance/fund';
import { PresentableAsset } from 'presentation/module/extension/personal-finance/base';
import { UserFundCreateSpec } from 'core/entity/personal-finance/fund/create-spec';

declare module 'core/entity/personal-finance/fund' {
    export interface UserFund extends PresentableAsset {
        getValuationAmount(): number;
        getPrincipleAmount(): number;
        getProfitAmount(): number;
        getProfitPercent(): number;
    }
}

UserFund.prototype.presentName = function() {
    return this.nickname ? this.nickname :
        this.primaryData.spider ?
            this.primaryData.spider.name : ''
};

UserFund.prototype.getValuationAmount = function() {
    return this.valuation ?
        this.valuation.amount :
        this.principal ? this.principal.amount : 0;
};

UserFund.prototype.getPrincipleAmount = function() {
    return this.principal ? this.principal.amount : 0;
};

UserFund.prototype.getProfitAmount = function() {
    return this.getValuationAmount() - this.getPrincipleAmount();
};

UserFund.prototype.getProfitPercent = function() {
    return this.getPrincipleAmount() !== 0 ?
        this.getProfitAmount() / this.getPrincipleAmount() * 100 : 0;
};
