import React from 'react';
import { List } from 'immutable';
import { chunk } from 'lodash';

import { StoreGroup } from 'core/entity/store/group';
import { FetchPageState } from 'presentation/view-model/fetch-state';
import { Store } from 'core/entity/store';
import { apply, emptyList } from 'utils/index';
import { EventBus } from 'presentation/bus';
import { WindowResizeEvent } from 'presentation/bus/event/window-resize-event';
import { RESPONSIVE } from 'presentation/components/responsive';
import { Page } from 'core/entity/page';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';

import styles from './styles.pcss';
const TABLET_CHUNK_SIZE = 4;
const DESKTOP_CHUNK_SIZE = 3;

interface Props {
    storeGroup: StoreGroup,
    toStoreForm: (store: Store) => any,
    gaCategory?: string
}

interface State {
    fetchState: FetchPageState,
    line: number,
    chunkSize: number,
    additionalStores: List<Store>
}

export class CardStoreGroupForm extends React.Component<Props, State> {
    state = {
        fetchState: FetchPageState.FETCHED,
        line: 1,
        chunkSize: window.innerWidth <= RESPONSIVE.TABLET ?
            TABLET_CHUNK_SIZE :
            DESKTOP_CHUNK_SIZE,
        additionalStores: emptyList()
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        EventBus.toObservable().subscribe(
            (event: Event) => {
                if (event instanceof WindowResizeEvent) {
                    const chunkSize =
                        this.generateChunkSize((event as WindowResizeEvent).width);
                    if (this.state.chunkSize !== chunkSize) {
                        this.setState({ chunkSize });
                    }
                }
            }
        ).unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { storeGroup } = this.props;
        const { chunkSize, line } = this.state;

        return (
            <div className={ styles.wrap }>
                { this.renderGroup() }
                {
                    chunkSize * line < storeGroup.numberOfStores && (
                        <button
                            onClick={ () => this.fetchNext() }
                            className={ styles.more }
                        >
                            { `+ 가맹점 더보기 (${chunkSize * line}/${storeGroup.numberOfStores})` }
                        </button>
                    )
                }
            </div>
        )
    }

    private renderGroup() {
        const { storeGroup, toStoreForm } = this.props;
        const { chunkSize, line, additionalStores } = this.state;
        const stores = storeGroup.stores.concat(additionalStores).toArray();

        return chunk(stores, chunkSize)
            .filter((_, index) => index < line)
            .map((row, index) =>
                <ul
                    key={ `group-${index}` }
                    className={ styles.stores }
                >
                    {
                        row.map(store =>
                            <li key={ store.id }>
                                { toStoreForm(store) }
                            </li>
                        )
                    }
                </ul>
            )
    }

    private fetchNext() {
        const { storeGroup, gaCategory } = this.props;
        const { line } = this.state;
        const size = Math.max(TABLET_CHUNK_SIZE, DESKTOP_CHUNK_SIZE);

        gaCategory && sendGAEvent(
            GA_DOMAIN.CARD,
            gaCategory,
            GA_ACTION.MORE,
            '가맹점 더보기'
        );

        if (this.state.fetchState === FetchPageState.FINISHED) {
            this.setState(state => ({ line: state.line + 1 }));
            return;
        }

        this.setState({
            fetchState: FetchPageState.FETCHING
        });

        apply(Application.useCases.getStores, it => {
            it.groupId = storeGroup.id;
            it.page = new Page(size, line * size);
        }).runOnAnimateFrame().subscribe(
            stores =>
                this.setState(state => ({
                    additionalStores: state.additionalStores.concat(stores).toList(),
                    line: state.line + 1,
                    fetchState: stores.size < size ?
                        FetchPageState.FINISHED :
                        FetchPageState.FETCHED
                }))
        ).unsubscribeBy(this.subscriptionBag)
    }

    private generateChunkSize = (width: number) =>
        width <= RESPONSIVE.TABLET ?
            TABLET_CHUNK_SIZE :
            DESKTOP_CHUNK_SIZE;
}
