import { Entity } from 'core/entity';
import { LoanCreditScoreAgency } from 'core/entity/loan/credit-score-agency';
import { LoanOccupationalGroup } from 'core/entity/loan/occupational-group';
import { LoanInterviewHistory } from 'core/entity/loan/interview-history';
import { List } from 'immutable';

export class LoanRecommendSpec extends Entity {
    desiredAmount: number;
    creditScore: number;
    creditScoreAgency: LoanCreditScoreAgency;
    occupationalGroup: LoanOccupationalGroup;
    annualIncome: number;
    histories: List<LoanInterviewHistory>;
    primeInterestNodeIds: List<number>;

    constructor(
        desiredAmount: number,
        creditScore: number,
        creditScoreAgency: LoanCreditScoreAgency,
        occupationalGroup: LoanOccupationalGroup,
        annualIncome: number,
        histories: List<LoanInterviewHistory>,
        primeInterestNodeIds: List<number>
    ) {
        super();
        this.desiredAmount = desiredAmount;
        this.creditScore = creditScore;
        this.creditScoreAgency = creditScoreAgency;
        this.occupationalGroup = occupationalGroup;
        this.annualIncome = annualIncome;
        this.histories = histories;
        this.primeInterestNodeIds = primeInterestNodeIds;
    }
}
