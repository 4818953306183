import { Entity } from 'core/entity';
import { LoanCFAReview } from 'core/entity/legal-configuration/loan/cfa/review';

export class LoanCFAReviewReviewed extends Entity implements LoanCFAReview {
    number: string;

    constructor(number: string) {
        super();
        this.number = number;
    }
}
