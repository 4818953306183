import { TransactionCategoryParent } from 'core/entity/personal-finance/transaction/category-parent';
import { NetworkResponseMapper } from 'data/http/mapper';
import { TransactionIconMapper } from 'data/http/mapper/personal-finance/transaction/icon';
import { optional } from 'utils/index';

export class TransactionCategoryParentMapper implements NetworkResponseMapper<TransactionCategoryParent> {
    fromJson(json: any): TransactionCategoryParent {
        return new TransactionCategoryParent(
            json.id,
            json.name,
            optional(json.icon, it => new TransactionIconMapper().fromJson(it))
        )
    }

}
