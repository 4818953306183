import { CardCFAReview } from 'core/entity/legal-configuration/card/cfa/review';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardCFAReviewInProgressMapper } from 'data/http/mapper/legal-configuration/card/cfa/review/in-progress';
import { CardCFAReviewNoReviewRequiredMapper } from 'data/http/mapper/legal-configuration/card/cfa/review/no-review-required';
import { CardCFAReviewReviewedMapper } from 'data/http/mapper/legal-configuration/card/cfa/review/reviewed';

export class CardCFAReviewMapper implements NetworkResponseMapper<CardCFAReview> {
    fromJson(json: any): CardCFAReview {
        switch (json.status) {
            case 'in-progress':
                return new CardCFAReviewInProgressMapper().fromJson(json);
            case 'no-review-required':
                return new CardCFAReviewNoReviewRequiredMapper().fromJson(json);
            case 'reviewed':
                return new CardCFAReviewReviewedMapper().fromJson(json);
            default:
                return null;
        }
    }

}
