import { List } from 'immutable';
import { LoanPrimeInterestNode } from 'core/entity/loan/prime-interest-node';
import { Loan } from 'core/entity/loan';
import { isMobile } from 'presentation/module/sugar';
import { LoanSummary } from 'core/entity/loan/summary';
import { LoanInterestPromotion } from 'core/entity/loan/interest-promotion';
import { LoanRangeInterest } from 'core/entity/loan/interest-value/range-interest';
import { LoanInterestValue } from 'core/entity/loan/interest-value';
import { LoanInterestCondition } from 'core/entity/loan/interest-condition';
import { LoanBaseInterest } from 'core/entity/loan/base-interest';
import { LoanPrimeInterest } from 'core/entity/loan/prime-interest';
import { LoanInterestConfigurationSummary } from 'core/entity/loan/interest-configuration-summary';
import { LoanInterestConfiguration } from 'core/entity/loan/interest-configuration';
import { LoanConfirmationInterest } from 'core/entity/loan/interest-value/confirmation-interest';
import { LoanAverageInterest } from 'core/entity/loan/interest-value/average-interest';

const isRangeInterest =
    (interest: LoanInterestValue): boolean => interest instanceof LoanRangeInterest;

const getRangeInterestMin =
    (interest: LoanInterestValue): number => interest instanceof LoanRangeInterest ? interest.value.min : 0;

const getRangeInterestMax =
    (interest: LoanInterestValue): number => interest instanceof LoanRangeInterest ? interest.value.max : 0;

const getInterestValue =
    (interest: LoanInterestValue): number =>
        (interest instanceof LoanConfirmationInterest || interest instanceof LoanAverageInterest) ? interest.value : 0;

declare module 'core/entity/loan/prime-interest-node' {
    export interface LoanPrimeInterestNode {
        flatten(): List<LoanPrimeInterestNode>;
    }
}

LoanPrimeInterestNode.prototype.flatten = function() {
    let result: Array<LoanPrimeInterestNode> = [];

    result.push(this);
    if (!this.children.isEmpty()) {
        result = result.concat(
            this.children.flatMap((it: LoanPrimeInterestNode) => it.flatten()).toArray()
        )
    }
    return List(result);
};

declare module 'core/entity/loan' {
    export interface Loan {
        getIssueUrl(): string;
        isInPromotion(): boolean;
        isAvailablePromotionInterestValue(): boolean;
    }
}

Loan.prototype.getIssueUrl = function() {
    return isMobile() ?
        this.mobileIssueUrl :
        this.desktopIssueUrl;
};

Loan.prototype.isInPromotion = function() {
    const today = new Date().getTime();
    return !!this.interestPromotion &&
        this.interestPromotion.startDate.getTime() <= today &&
        (this.interestPromotion.endDate === null || this.interestPromotion.endDate.getTime() >= today)
};

Loan.prototype.isAvailablePromotionInterestValue = function() {
    return this.isInPromotion() && !!this.interestPromotion.interest.value;
};

declare module 'core/entity/loan/summary' {
    export interface LoanSummary {
        getIssueUrl(): string;
        isInPromotion(): boolean;
        isAvailablePromotionInterestValue(): boolean;
    }
}

LoanSummary.prototype.getIssueUrl = function() {
    return isMobile() ?
        this.mobileIssueUrl :
        this.desktopIssueUrl;
};

LoanSummary.prototype.isInPromotion = function() {
    const today = new Date().getTime();
    return !!this.interestPromotion &&
        this.interestPromotion.startDate.getTime() <= today &&
        (this.interestPromotion.endDate === null || this.interestPromotion.endDate.getTime() >= today)
};

LoanSummary.prototype.isAvailablePromotionInterestValue = function() {
    return this.isInPromotion() && !!this.interestPromotion.interest.value;
};

declare module 'core/entity/loan/interest-promotion' {
    export interface LoanInterestPromotion {
        isRangeInterest(): boolean;
        getInterest(): number;
        getMinInterest(): number;
        getMaxInterest(): number;
    }
}

LoanInterestPromotion.prototype.isRangeInterest = function () {
    return isRangeInterest(this.interest);
};
LoanInterestPromotion.prototype.getInterest = function () {
    return this.interest.value;
};
LoanInterestPromotion.prototype.getMinInterest = function () {
    return this.interest.value.min;
};
LoanInterestPromotion.prototype.getMaxInterest = function () {
    return this.interest.value.max;
};

declare module 'core/entity/loan/interest-configuration-summary' {
    export interface LoanInterestConfigurationSummary {
        isRangeInterest(): boolean;
        getInterest(): number;
        getMinInterest(): number;
        getMaxInterest(): number;
        getMinOriginInterest(): number;
        getMaxOriginInterest(): number;
    }
}

LoanInterestConfigurationSummary.prototype.isRangeInterest = function () {
    return isRangeInterest(this.totalInterest);
};
LoanInterestConfigurationSummary.prototype.getInterest = function () {
    return this.totalInterest.value;
};
LoanInterestConfigurationSummary.prototype.getMinInterest = function () {
    return this.totalInterest.value.min;
};
LoanInterestConfigurationSummary.prototype.getMaxInterest = function () {
    return this.totalInterest.value.max;
};
LoanInterestConfigurationSummary.prototype.getMaxOriginInterest = function () {
    const maxTotalInterest = isRangeInterest(this.totalInterest) ?
        this.totalInterest.value.max :
        this.totalInterest.value;
    const minPromotionInterest = this.interestPromotion ?
        isRangeInterest(this.interestPromotion.interest) ?
            this.interestPromotion.interest.value.min :
            this.interestPromotion.interest.value :
        0;

    return maxTotalInterest + minPromotionInterest;
};
LoanInterestConfigurationSummary.prototype.getMinOriginInterest = function () {
    const minTotalInterest = isRangeInterest(this.totalInterest) ?
        this.totalInterest.value.min :
        this.totalInterest.value;
    const maxPromotionInterest = this.interestPromotion ?
        isRangeInterest(this.interestPromotion.interest) ?
            this.interestPromotion.interest.value.max :
            this.interestPromotion.interest.value :
        0;

    return minTotalInterest + maxPromotionInterest;
};

declare module 'core/entity/loan/interest-condition' {
    export interface LoanInterestCondition {
        isRangeInterest(): boolean;
        getInterest(): number;
        getMinInterest(): number;
        getMaxInterest(): number;
        getAverageInterests(): List<string>;
    }
}

LoanInterestCondition.prototype.isRangeInterest = function () {
    return isRangeInterest(this.additionalInterest);
};
LoanInterestCondition.prototype.getInterest = function () {
    return this.additionalInterest.value;
};
LoanInterestCondition.prototype.getMinInterest = function () {
    return this.additionalInterest.value.min;
};
LoanInterestCondition.prototype.getMaxInterest = function () {
    return this.additionalInterest.value.max;
};
LoanInterestCondition.prototype.getAverageInterests = function () {
    return this.averageInterests.map((interest: LoanInterestValue) =>
        isRangeInterest(interest) ?
            `${getRangeInterestMin(interest).toFixed(2)}% ~ ${getRangeInterestMax(interest).toFixed(2)}%` :
            `${getInterestValue(interest).toFixed(2)}%`
    )
};

declare module 'core/entity/loan/base-interest' {
    export interface LoanBaseInterest {
        isRangeInterest(): boolean;
        getInterest(): number;
        getMinInterest(): number;
        getMaxInterest(): number;
    }
}

LoanBaseInterest.prototype.isRangeInterest = function () {
    return isRangeInterest(this.interest);
};
LoanBaseInterest.prototype.getInterest = function () {
    return this.interest.value;
};
LoanBaseInterest.prototype.getMinInterest = function () {
    return this.interest.min;
};
LoanBaseInterest.prototype.getMaxInterest = function () {
    return this.interest.max;
};

declare module 'core/entity/loan/prime-interest' {
    export interface LoanPrimeInterest {
        isRangeInterest(): boolean;
        getInterest(): number;
        getMinInterest(): number;
        getMaxInterest(): number;
    }
}

LoanPrimeInterest.prototype.isRangeInterest = function () {
    return isRangeInterest(this.interest);
};
LoanPrimeInterest.prototype.getInterest = function () {
    return this.interest.value;
};
LoanPrimeInterest.prototype.getMinInterest = function () {
    return this.interest.min;
};
LoanPrimeInterest.prototype.getMaxInterest = function () {
    return this.interest.max;
};

declare module 'core/entity/loan/interest-configuration' {
    export interface LoanInterestConfiguration {
        isRangeInterest(): boolean;
        isAverageInterest(): boolean;
        getInterest(): number;
        getMinInterest(): number;
        getMaxInterest(): number;
        getMaxDefaultInterest(): number;
        getMinDefaultInterest(): number;
        isAdditionalInterestRangeInterest(): boolean;
        getAdditionalInterest(): number;
        getMinAdditionalInterest(): number;
        getMaxAdditionalInterest(): number;
        isBaseInterestRangeInterest(): boolean;
        getBaseInterest(): number;
        getMinBaseInterest(): number;
        getMaxBaseInterest(): number;
    }
}

LoanInterestConfiguration.prototype.isAverageInterest = function () {
    return !!this.averageInterest;
};
LoanInterestConfiguration.prototype.isRangeInterest = function () {
    return isRangeInterest(this.totalInterest);
};
LoanInterestConfiguration.prototype.getInterest = function () {
    return this.totalInterest.value;
};
LoanInterestConfiguration.prototype.getMinInterest = function () {
    return this.totalInterest.value.min;
};
LoanInterestConfiguration.prototype.getMaxInterest = function () {
    return this.totalInterest.value.max;
};
LoanInterestConfiguration.prototype.isAdditionalInterestRangeInterest = function () {
    return isRangeInterest(this.additionalInterest);
};
LoanInterestConfiguration.prototype.getAdditionalInterest = function () {
    return this.additionalInterest.value;
};
LoanInterestConfiguration.prototype.getMinAdditionalInterest = function () {
    return this.additionalInterest.value.min;
};
LoanInterestConfiguration.prototype.getMaxAdditionalInterest = function () {
    return this.additionalInterest.value.max;
};
LoanInterestConfiguration.prototype.isBaseInterestRangeInterest = function () {
    return isRangeInterest(this.baseInterest);
};
LoanInterestConfiguration.prototype.getBaseInterest = function () {
    return this.baseInterest.value;
};
LoanInterestConfiguration.prototype.getMinBaseInterest = function () {
    return this.baseInterest.value.min;
};
LoanInterestConfiguration.prototype.getMaxBaseInterest = function () {
    return this.baseInterest.value.max;
};
LoanInterestConfiguration.prototype.getMaxDefaultInterest = function () {
    if (this.isAverageInterest())
        return isRangeInterest(this.averageInterest) ?
            this.averageInterest.value.max :
            this.averageInterest.value;

    const maxBaseInterest = isRangeInterest(this.baseInterest) ?
        this.getMaxBaseInterest() : this.getBaseInterest();

    const maxAdditionalInterest = isRangeInterest(this.additionalInterest) ?
        this.getMaxAdditionalInterest() : this.getAdditionalInterest();

    return maxBaseInterest + maxAdditionalInterest;
};
LoanInterestConfiguration.prototype.getMinDefaultInterest = function () {
    if (this.isAverageInterest())
        return isRangeInterest(this.averageInterest) ?
            this.averageInterest.value.min :
            this.averageInterest.value;

    const minBaseInterest = isRangeInterest(this.baseInterest) ?
        this.getMinBaseInterest() : this.getBaseInterest();

    const minAdditionalInterest = isRangeInterest(this.additionalInterest) ?
        this.getMinAdditionalInterest() : this.getAdditionalInterest();

    return minBaseInterest + minAdditionalInterest;
};
