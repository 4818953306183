import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { LoanSurveyConfigurationRepositoryType } from 'core/repository/loan-survey-configuration';
import { LoanSurveyConfiguration } from 'core/entity/loan/survey-configuration';

export class GetLoanSurveyConfiguration extends UseCase<LoanSurveyConfiguration> {
    private repository: LoanSurveyConfigurationRepositoryType;

    constructor(repository: LoanSurveyConfigurationRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<LoanSurveyConfiguration> {
        return this.repository.get();
    }

    protected validate(): boolean {
        return true;
    }

}
