import { NetworkResponseMapper } from 'data/http/mapper';
import { SummarizedUserBankProducts } from 'core/entity/personal-finance/summarized-products/bank';
import { SpiderBankSummaryMapper } from 'data/http/mapper/personal-finance/bank/summary';
import { UserBankAccountMapper } from 'data/http/mapper/personal-finance/bank-account';
import { List } from 'immutable';
import { UserLoanMapper } from 'data/http/mapper/personal-finance/loan';
import { UserFundMapper } from 'data/http/mapper/personal-finance/fund';

export class SummarizedUserBankProductsMapper implements NetworkResponseMapper<SummarizedUserBankProducts> {
    fromJson(json: any): SummarizedUserBankProducts {
        return new SummarizedUserBankProducts(
            new SpiderBankSummaryMapper().fromJson(json.bank),
            List(json.bank_accounts.map(new UserBankAccountMapper().fromJson)),
            List(json.bank_loans.map(new UserLoanMapper().fromJson)),
            List(json.bank_funds.map(new UserFundMapper().fromJson))
        )
    }
}
