import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV4PrivacyAgreementView = () => (
  <div className={ styles.content }>
    <h3>뱅크샐러드 개인(신용)정보 수집ㆍ이용 동의</h3>
    <b>㈜레이니스트 귀중</b>
    <p>
      ㈜ 레이니스트가 제공하는 서비스 이용과 관련하여 본인의 개인(신용)정보를 조회(수집·이용) 하고자 하는 경우에는
      「신용정보의 이용 및 보호 등에 관한 법률」 제15조 제2항, 제32조 제1항, 제33조, 제34조, 「개인정보보호법」 제15조 제1항에 따라 본인의 동의가 필요합니다.
    </p>
    <br/>
    <br/>
    <b>[필수] 개인(신용)정보 수집∙이용 동의</b>
    <table>
      <tbody>
        <tr>
          <th className={ styles.width20 }>수집·이용할 항목</th>
          <th className={ styles.width40 }>수집·이용 목적</th>
          <th className={ styles.width20 }>보유·이용 기간</th>
        </tr>
        <tr>
          <td className={ styles.width40 }>
            <p>
              성명, 생년월일, 성별, 내외국인정보, 이동전화 통신사, 이동전화번호, 연계정보(CI)/중복가입확인정보(DI) 값 등 식별자 정보
            </p>
          </td>
          <td className={ styles.width40 }>
            <p>회원 가입, 본인 인증 및 확인</p>
          </td>
          <td className={ styles.width40 }>
            <p>회원 탈퇴 후 5일까지</p>
          </td>
        </tr>
        <tr>
          <td className={ styles.width40 }>
            <p>회원 보유 금융 관련 정보</p>
            <br/>
            <br/>
            <p>
              수신상품<br/>
              금융기관명, 금융상품 종류 및 명칭, 계좌번호, 거래내역, 잔액 등
            </p>
            <br/>
            <br/>
            <p>
              카드상품<br/>
              (신용/체크)카드번호, 신용정보, 카드결제내역, 구매품목정보
              ,카드 이용한도, 현금서비스(단기카드대출) 및 카드론
              (장기카드대출) 이용 내역 및 한도, 보유 포인트 내역 등
            </p>
            <br/>
            <br/>
            <p>
              투자상품<br/>
              금융기관명, 금융상품종류 및 명칭, 계좌번호, 투자원금, 보유수량, 현재가, 평균매입가, 매입금액,
              평가금액, 평가손익, 수익률, 신규일, 만기일, 대출금액, 출금가능금액 외화예수금 등
            </p>
            <br/>
            <br/>
            <p>
              대출상품<br/>
              금융기관명, 금융상품 종류 및 명칭, 계좌번호, 대출잔액,
              대출원금, 대출이자, 대출신규일, 대출만기일 등
            </p>
            <br/>
            <br/>
            <p>
              보험상품<br/>
              금융기관명, 금융상품 종류 및 명칭, 가입번호, 해지환급금,
              보험료, 납입주기, 총 납입금, 계약일, 만기일 등
            </p>
            <br/>
            <br/>
            <p>
              연금상품<br/>
              (국민연금 등) 연금종류, 예상연금액, 납부월수, 납부총액, 예상 납부 월수, 예상 납부 보험료 총액, 연금개시일 등<br/>
              (퇴직연금 등) 금융기관명, 상품유형, 상품명, 가입일자, 연금개시일, 적립금, 계좌번호, 납부총액, 기출금액, 연납입액 등
            </p>
          </td>
          <td className={ styles.width40 }>
            <p>
              금융 상품 정보 및
              거래내역(예산 및 지출) 분석 등 자산 관리 서비스 및
              회원 맞춤형 금융상품 추천
            </p>
          </td>
          <td className={ styles.width40 }>
            <p>회원 탈퇴 후 5일까지</p>
          </td>
        </tr>
      </tbody>
    </table>
    <table>
      <tbody>
        <tr>
          <td className={ styles.width20 }>
            <p>
              동의를 거부할 권리 및
              거부할 경우 불이익
            </p>
          </td>
          <td>
            <p>
              위 개인(신용)정보 수집·이용에 관한 동의는 계약의 체결 및 이행을 위하여 필수적이므로,
              위 사항에 동의해야만 서비스의 이용이 가능하며 거부 시 본 서비스를 이용할 수 없습니다.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

