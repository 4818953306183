import { Entity } from 'core/entity';
import { Loan } from 'core/entity/loan';
import { LoanComputationResult } from 'core/entity/loan/computation-result';

export class LoanRecommendResult extends Entity {
    loan: Loan;
    computation: LoanComputationResult;

    constructor(
        loan: Loan,
        computation: LoanComputationResult
    ) {
        super();
        this.loan = loan;
        this.computation = computation;
    }
}
