import React from 'react';

import { uniqueKey } from 'utils/index';
import { GA_ACTION, GA_EVENT_VALUE, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    checked: boolean,
    text: string,
    gaCategory?: string,
    onChange: (checkedState: boolean) => void
}

interface State {
    checked: boolean;
}

export class CheckBox extends React.Component<Props, State> {
    state = {
        checked: this.props.checked
    };

    componentWillReceiveProps(nextProps: Props) {
        if(nextProps.checked !== null && this.state.checked !== nextProps.checked)
            this.setState({ checked: nextProps.checked });
    }

    render() {
        const {
            text,
            gaCategory,
            onChange
        } = this.props;
        const {
            checked
        } = this.state;
        const key = uniqueKey();

        return (
            <div className={ styles.checkBoxWrap }>
                <input
                    id={ key }
                    type="checkbox"
                    onChange={ this.onChangeInput(checked, text, onChange, gaCategory) }
                    className={ styles.input }
                    checked={ checked }
                />
                <label
                    htmlFor={ key }
                    className={ styles.label }
                >
                    { text }
                </label>

            </div>
        )
    }

    private onChangeInput = (
        checked: boolean,
        text: string,
        onChange: (checkedState: boolean) => void,
        gaCategory?: string
    ) => () => {
        this.setState({ checked: !checked }, () => {
            const {
                checked
            } = this.state;

            gaCategory && sendGAEvent(GA_DOMAIN.NONE, gaCategory, GA_ACTION.CHECKBOX, text, checked ? GA_EVENT_VALUE.CHECKED : GA_EVENT_VALUE.UNCHECKED);
            onChange(checked);
        });
    };
};
