import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, optional } from 'utils/index';
import { UserActualAssetCreateSpec } from 'core/entity/personal-finance/actual-asset/create-spec';

export class UserActualAssetCreateSpecMapper implements NetworkRequestMapper<UserActualAssetCreateSpec> {
    toJson(target: UserActualAssetCreateSpec): any {
        return compactObject({
            nickname: target.nickname,
            worth: target.worth,
            description: optional(target.description),
            hidden: target.hidden
        })
    }
}
