import React from 'react';

import { Alert } from 'presentation/components/alert';

import styles from './styles.pcss';

interface Props {
    title: string;
    qualification: string;
    onCancel: () => void;
}

export const CreditLoanQualificationAlert = ({ title, qualification, onCancel }: Props) => (
    <Alert
        title={ title && `${title} 가입조건` }
        onCancel={ onCancel }
    >
        <div className={ styles.wrap }>
            <h2 className={ styles.title }>가입조건(상세)</h2>
            <p className={ styles.description } dangerouslySetInnerHTML={{ __html: qualification }}/>
        </div>
    </Alert>
);
