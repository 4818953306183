import { mobile } from 'bowser';
import { List } from 'immutable';
import { EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { FetchEmpty, FetchEmptyPaddingSize } from 'presentation/components/fetch-state/empty';
import { Loader } from 'presentation/components/loader';
import { GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { MultiAnswerQuestion, Question } from 'presentation/view-model/question';
import React from 'react';
import { Subscription } from 'rxjs/Rx';
import { lets, uniqueKey } from 'utils/index';

import styles from './styles.pcss';

const FIXED_BREAK_POINT = 260;

/**
 * SurveyContainer가 Footer와 겹치지 않도록 하기 위한 변수
 * : SurveyContainer height + SurveyContainer margin-top + SurveyContainer margin-bottom + Footer height
 */
const BOTTOM_ABSOLUTE_BREAK_POINT = 450 + 56 + 32 + 656.22;

interface Props {
    children: any;
    questions: List<Question>;
    resultTitle: string;
    resultValue?: string;
    previewMarginTop?: number;
    skipMobilePreview?: boolean;
    fixed?: boolean;
    additionalInfo?: string;
    confirmAlert?: SurveyConfirm;
    linkUrl: string;
    gaCategory: string;
    gaAction: string;
    onChangeConfirm?: () => void;
    onChange: (question: Question) => void;
}

interface State {
    previewFixed: boolean;
    previewAbsoluteBottom: boolean;
}

export class SurveyContainer extends React.Component<Props, State> {
    state = {
      previewFixed: false,
      previewAbsoluteBottom: false
    };
    private id = uniqueKey();
    private eventBus: Subscription = EventBus.toObservable().subscribe(
      (event: Event) => {
        if (event instanceof DocumentScrollEvent) {
          if (this.props.fixed) {
            const shouldFix = event.scrollTop > FIXED_BREAK_POINT;
            const shouldAbsoluteBottom = window.innerHeight < BOTTOM_ABSOLUTE_BREAK_POINT
                && event.scrollBottom < BOTTOM_ABSOLUTE_BREAK_POINT - window.innerHeight;

            lets(shouldAbsoluteBottom, fixed => {
              this.state.previewAbsoluteBottom !== fixed &&
              this.setState({ previewAbsoluteBottom: fixed });
            });

            lets(shouldFix, fixed => {
              this.state.previewFixed !== fixed &&
              this.setState({ previewFixed: fixed });
            });
          }
        }
      }
    );

    componentWillUnmount() {
      this.eventBus.unsubscribe();
    }

    render() {
      const {
        children,
        questions,
        resultTitle,
        resultValue,
        additionalInfo,
        previewMarginTop,
        linkUrl
      } = this.props;
      const {
        previewFixed,
        previewAbsoluteBottom
      } = this.state;
      const essentials = questions.filter((question: Question) => question.essential).toList();
      const optionals = questions.filter((question: Question) => !question.essential).toList();
      const activated = essentials.reduce((acc, item) => acc && item.isValid(), true);
      const essentialsContained = essentials.reduce((acc, item) => acc || item.isValid(), false);
      const optionalContained = optionals.reduce((acc, item) => acc || item.isValid(), false);
      const empty =
            essentials.filter(it => it.isValid()).isEmpty() &&
            optionals.filter(it => it.isValid()).isEmpty();

      return (
        <div className={ styles.wrap }>
          <div className={ styles.container }>
            { children }
            <input
              type="checkbox"
              id={ this.id }
              className={ styles.trigger }
            />
            { activated && this.renderTriggerLabel(this.props) }
            <div
              className={ 
                previewAbsoluteBottom 
                  ? styles.bottomAbsolutePreview 
                  : previewFixed 
                    ? styles.fixedPreview 
                    : styles.preview 
              }
              style={{ marginTop: `${previewMarginTop || 0}px` }}
            >
              <h4 className={ styles.head }>
                <span>입력 항목</span>
                <label htmlFor={ this.id } className={ styles.moreSurvey }>
                    추가입력/수정
                </label>
              </h4>
              {
                empty ?
                  <FetchEmpty padding={ FetchEmptyPaddingSize.SMALL }>
                    설문을 채우고 추천을 받아보세요
                  </FetchEmpty> :
                  <div className={ styles.body }>
                    { essentialsContained && this.renderQuestions(essentials, 'essentials') }
                    { optionalContained && this.renderQuestions(optionals, 'optionals') }
                  </div>
              }
              <div className={ styles.foot }>
                {
                  activated &&
                  <section className={ styles.result }>
                    <span className={ 
                      additionalInfo 
                        ? styles.resultTitleWithInfo 
                        : styles.resultTitle
                    }>
                      { resultTitle }
                    </span>
                    <span className={ styles.resultValue }>
                      { resultValue ? resultValue : <Loader padding={ 14 } radius={ 15 }/> }
                      { 
                        additionalInfo && 
                        <span className={ styles.additionalInfo }>{ additionalInfo }</span> 
                      }
                    </span>
                  </section>
                }
                <a
                  href={ activated ? linkUrl : '#' }
                  onClick={ activated ? this.onClickSubmit : null }
                  className={ activated ? styles.next : styles.disabledNext }
                >
                  결과보기
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    private renderTriggerLabel = (props: Props) => {
      const {
        skipMobilePreview,
        linkUrl,
        resultValue,
        resultTitle
      } = props;

      const resultComponent = (
        <div>
          결과보기<br/>
          <strong>
            {
              resultValue 
                ? `${resultTitle} ${resultValue}`
                : <Loader padding={ 2 } radius={ 10 } />
            }
          </strong>
        </div>
      );

      return skipMobilePreview ?
        <a
          href={ linkUrl }
          onClick={ this.onClickSubmit }
          className={ styles.triggerLabel }
        >
          { resultComponent }
        </a> :
        <label htmlFor={ this.id } className={ styles.triggerLabel }>{ resultComponent }</label>;
    };

    private renderQuestions = (list: List<Question>, key: string) =>
      <ul className={ styles.questions }>
        {
          list
            .filter(it => it.isValid())
            .map(this.renderQuestion(key))
            .toArray()
        }
      </ul>;

    private renderQuestion = (key: string) => (question: Question, index: number) => {
      const listKey = `${key}-${index}`;
      const { onChange } = this.props;

      if (question instanceof MultiAnswerQuestion) {
        return (
          <li key={ listKey }>
            <span className={ styles.topic }>{ question.topic }</span>
            <ul className={ styles.values }>
              {
                question.values.map((it, i) =>
                  <li
                    key={ `${listKey}-${i}` }
                    className={ question.clearable ? styles.clearable : null }
                  >
                    { question.toValueOf(it) }
                    {
                      question.clearable &&
                      <button
                        className={ styles.clear }
                        onClick={ () => onChange(question.remove(it)) }
                      >
                          제거
                      </button>
                    }
                  </li>
                )
              }
            </ul>
          </li>
        );
      } else {
        return (
          <li key={ listKey }>
            <span className={ styles.topic }>{ question.topic }</span>
            <span className={ question.clearable ? styles.clearableValue : styles.value }>
              { question.toValue() }
              {
                question.clearable &&
                <button
                  onClick={ () => onChange(question.clear()) }
                  className={ styles.clear }
                >
                    제거
                </button>
              }
            </span>
          </li>
        );
      }
    };

    private onClickSubmit = (e: React.MouseEvent<HTMLAnchorElement>) => {
      const { gaCategory, gaAction, confirmAlert, onChangeConfirm } = this.props;

      if ( !mobile && confirmAlert && !confirmAlert.valid) {
        if (confirm(confirmAlert.message)) {
          onChangeConfirm && onChangeConfirm();
          sendGAEvent(GA_DOMAIN.NONE, gaCategory, gaAction, '결과보기');
        } else {
          e.preventDefault();
        }
      } else {
        sendGAEvent(GA_DOMAIN.NONE, gaCategory, gaAction, '결과보기');
      }
    };

}

export class SurveyConfirm {
    valid: boolean;
    message: string;

    constructor(
      valid: boolean,
      message: string
    ) {
      this.valid = valid;
      this.message = message;
    }
}
