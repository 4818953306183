import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserLoanUpdateSpec } from 'core/entity/personal-finance/loan/update-spec';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserLoanUpdateSpecMapper } from 'data/http/mapper/personal-finance/loan/update-spec';
import { UserLoanMapper } from 'data/http/mapper/personal-finance/loan';
import { List } from 'immutable';
import { UserLoanCreateSpec } from 'core/entity/personal-finance/loan/create-spec';
import { UserLoanCreateSpecMapper } from 'data/http/mapper/personal-finance/loan/create-spec';

export class UserLoansApiProvider extends HttpProvider {
    getAll(accessToken: AccessToken): Observable<List<UserLoan>> {
        return this.requestGET(
            'api/v2/me/loans',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map(new UserLoanMapper().fromJson))
        )
    }

    get(accessToken: AccessToken, id: string): Observable<UserLoan> {
        return this.requestGET(
            `api/v2/me/loans/${id}`,
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserLoanMapper().fromJson(result.data)
        )
    }

    create(accessToken: AccessToken, spec: UserLoanCreateSpec): Observable<UserLoan> {
        return this.requestPOST(
            'api/v2/me/loans',
            new UserLoanCreateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserLoanMapper().fromJson(result.data)
        )
    }

    update(accessToken: AccessToken, id: string, spec: UserLoanUpdateSpec): Observable<UserLoan> {
        return this.requestPATCH(
            `api/v2/me/loans/${id}`,
            new UserLoanUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserLoanMapper().fromJson(result.data)
        )
    }
}
