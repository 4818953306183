import { NetworkResponseMapper } from 'data/http/mapper';
import { Promotion } from 'core/entity/promotion';
import { PromotionUrlMapper } from 'data/http/mapper/promotion/promotion-url';
import { PromotionDateMapper } from 'data/http/mapper/promotion/promotion-date';
import { PromotionButtonMapper } from 'data/http/mapper/promotion/promotion-button';
import { PromotionTrackerMapper } from 'data/http/mapper/promotion/promotion-tracker';
import { PromotionMetaMapper } from 'data/http/mapper/promotion/promotion-meta';

export class PromotionMapper implements NetworkResponseMapper<Promotion> {
    fromJson(json: any): Promotion {
        return new Promotion(
            json.is_token_required,
            new PromotionUrlMapper().fromJson(json.url),
            new PromotionDateMapper().fromJson(json.date),
            new PromotionButtonMapper().fromJson(json.button),
            new PromotionTrackerMapper().fromJson(json.tracker),
            new PromotionMetaMapper().fromJson(json.meta),
        )
    }
}
