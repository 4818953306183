import { Card } from 'core/entity/card';
import { CardSearchSpec } from 'core/entity/card/search-spec';
import { Page } from 'core/entity/page';
import { CardSearchResult } from 'core/entity/card/search-result';
import { CardRepositoryType } from 'core/repository/card';
import { CardsApiProvider } from 'data/http/api/gateway/v3/cards';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';

export class CardNetworkRepository implements CardRepositoryType {
    private api: CardsApiProvider;

    constructor(api: CardsApiProvider) {
        this.api = api;
    }

    search(spec: CardSearchSpec, page: Page, simple: boolean): Observable<CardSearchResult> {
        return this.api.search(spec, page, simple);
    }

    get(id: number): Observable<Card> {
        return this.api.get(id);
    }

    getAll(): Observable<List<Card>> {
        throw new Error('Method not implemented.');
    }
}
