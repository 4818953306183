import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class UserCreditScoreCardUsedAmount extends Entity {
    total: Amount;
    lumpSumPayment: Amount;
    installment: Amount;
    checkCard: Amount;
    cashService: Amount;

    constructor(
        total: Amount,
        lumpSumPayment: Amount,
        installment: Amount,
        checkCard: Amount,
        cashService: Amount
    ) {
        super();
        this.total = total;
        this.lumpSumPayment = lumpSumPayment;
        this.installment = installment;
        this.checkCard = checkCard;
        this.cashService = cashService;
    }
}
