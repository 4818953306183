import { Entity } from 'core/entity';
import { LoanPeriod } from 'core/entity/loan/period';
import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';

export class LoanOption extends Entity {
    period: LoanPeriod;
    interestRateType: LoanInterestType;
    interestRateUpdatePeriod?: number;
    repaymentType: LoanRepaymentType;

    constructor(
        period: LoanPeriod,
        interestRateType: LoanInterestType,
        interestRateUpdatePeriod: number = null,
        repaymentType: LoanRepaymentType
    ) {
        super();
        this.period = period;
        this.interestRateType = interestRateType;
        this.interestRateUpdatePeriod=  interestRateUpdatePeriod;
        this.repaymentType = repaymentType;
    }
}
