import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV5PrivacyPolicyView = () =>
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 개인정보처리방침</h3>
      <p>
                뱅크샐러드 서비스(이하 “서비스”)는 주식회사 레이니스트(이하 “회사”)가 운영하는 모바일 어플리케이션 서비스와 웹 사이트 입니다. 서비스를 제공함에 있어 정보통신망 이용촉진 및 정보보호(이하 “정보통신망법”), 개인정보보호법, 신용정보의 이용 및 보호에 관한 법률 등 개인정보 보호에 관한 관련 법령에 따라 이용자의 개인정보 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다. 회사는 개인정보처리방침을 개정하는 경우 회사가 운영하는 사이트(이하 "사이트") 혹은 서비스에 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
      </p>
      <ol>
        <li>
          <b>제 1 조 처리하는 개인정보의 수집항목과 이용목적 및 수집방법</b>
          <ol>
            <li>
                    ① 개인정보 수집항목과 이용목적
              <p>
                        “서비스”는 회원 가입 및 서비스 제공을 위해 아래의 개인정보를 수집 및 이용하고 있습니다. 단, “서비스”는 고객의 사생활 침해할 우려가 있는 사상·신념, 노동조합·정당의 가입·탈퇴, 정치적견해, 건강, 성생활 등에 관한 정보는 처리하지 않습니다.
              </p>
              <table>
                <tbody>
                  <tr>
                    <th className={ styles.width20 }>구분</th>
                    <th>수집 항목</th>
                    <th>이용목적</th>
                  </tr>
                  <tr>
                    <td className={ styles.width20 }>
                      <div>필수 수집</div>
                    </td>
                    <td className={ styles.width40 }>
                      <div>
                                    이메일 주소 및 비밀번호 또는 Facebook ID ,Google ID, Kakao ID, Twitter ID, Naver ID, Daum ID 등 가입을 위해 필요한 제휴 서비스 계정
                      </div>
                    </td>
                    <td className={ styles.width40 }>
                      <div>* 회원 가입 및 회원 정보 관리</div>
                      <div>* 회원제 서비스 제공</div>
                      <div>* 고지사항 전달, 서비스 제공관련 안내, 서비스 이용 본인의사 확인, 서비스 이용에 따른 민원사항 처리</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={ styles.width20 }>
                      <div>필수 수집</div>
                    </td>
                    <td className={ styles.width40 }>
                      <div>
                                    SMS/LMS/MMS 메시지, 어플리케이션 푸쉬 알림, 고객의 수기 입력, 카드사/은행사/보험사/증권사등의 금융사 또는 국세청 ID/비밀번호, 공인인증서 비밀번호 입력을 통해 수집하는 회원 보유 금융상품 정보(금융사, 금융상품명, 수시입출급/대출/보험/펀드등의 금융상품 종류, 계좌번호, 신용/체크카드 번호) 및 신용정보, 계좌 잔액, 입출금내역, 카드 결제 내역, 구매품목정보, 카드 이용한도, 단기카드대출 이용 내역 및 한도, 카드론 이용 내역 및 한도, 보유 포인트 내역, 등의 회원 금융 거래 정보
                      </div>
                    </td>
                    <td className={ styles.width40 }>
                      <div>* 금융 거래 내역 정보 분석 및 관리</div>
                      <div>* 보유 금융 상품 정보 분석 및 관리</div>
                      <div>* 예산 관리 및 지출 관리 서비스 제공</div>
                      <div>* 자산관리 서비스 제공</div>
                      <div>* 회원 맞춤형 금융상품 추천</div>
                      <div>* 신용등급 및 신용점수 등 고객의 신용정보 관리를 위한 정보 제공</div>
                      <div>* 안정적인 서비스 운영 및 제공을 위한 안전성 테스트 및 모니터링</div>
                      <div>* 기타 고객에게 유익하다고 판단되는 정보의 제공</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={ styles.width20 }>
                      <div>필수 수집</div>
                    </td>
                    <td className={ styles.width40 }>
                      <div>
                                    이름, 생년월일, 성별, 내외국인 정보, 이동정보통신사 및 이동전화번호, 휴대폰이나 아이핀을 통한 본인 인증 시 수집되는 CI/DI 값 등 회원 본인 확인을 위해 필요한 식별자 정보
                      </div>
                    </td>
                    <td className={ styles.width40 }>
                      <div>* 본인 인증 및 확인</div>
                      <div>* 안정적인 서비스 운영 및 제공을 위한 안전성 테스트 및 모니터링</div>
                    </td>
                  </tr>
                  <tr>
                    <td className={ styles.width20 }>
                      <div>선택 수집</div>
                    </td>
                    <td className={ styles.width40 }>
                      <div>
                                    이동전화번호, 이메일, 직종, 직장, 거주지역
                      </div>
                    </td>
                    <td className={ styles.width40 }>
                      <div>* 이벤트, 뉴스레터, 금융 관련 정보 및 칼럼, 회원 맞춤형 혜택 등의 프로모션 참여 제안</div>
                      <div>* 마케팅, 광고에 활용</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
                    ② 수집방법
              <ol>
                <li>1. 회원가입 및 서비스 이용과정에서 이용자가 개인정보 수집에 대해 동의하고 직접 입 력</li>
                <li>2. 이용자의 동의에 따라, 휴대폰 문자메시지, 모바일 어플리케이션 푸쉬 알림 등을 통 하여 읽어오는 방법</li>
                <li>3. 고객이 서비스 내에서 설문 응답 등을 통해 직접입력</li>
                <li>4. 쿠키정보와 같은 생성정보의 경우 PC웹, 모바일웹, 앱 이용과정에서 자동 생성</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제 2 조 개인정보 수집 및 이용에 대한 동의</b>
          <ol>
            <li>① “서비스”는 “개인정보 수집 및 이용”에 대한 별도의 동의 획득 절차를 두고, 고객이 이에 대해 ‘동의한다’ 또는 ‘동의하지 않는다’ 버튼을 통해 클릭할 수 있는 절차를 마련하고 있습니다. ‘동의한다’ 버튼을 클릭하면 개인정보 수집 및 이용에 동의한 것으로 봅니다.</li>
            <li>② 고객은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 받는 별도의 불이익은 없습니다. 단, 서비스 이용이 불가능 하거나, 서비스 이용 목적에 따른 서비스 제공에 제한이 따르게 됩니다.</li>
          </ol>
        </li>
        <li>
          <b>제 3 조 개인정보 처리 및 보유 기간</b>
          <ol>
            <li>
                    ① 개인정보는 고객이 동의한 서비스의 수집∙이용 기간에만 위 처리 목적을 위하여 보유∙이용 되며, 회원 탈퇴 시 지체없이 파기합니다. 다만, 아래의 경우를 포함하여 관계법령의 규정에 따라 개인정보를 보존하여야 하는 경우에는 관련 규정에 따라 해당 정보를 보관합니다.
              <ol>
                <li>1. 계약 또는 청약철회 등에 관한 기록 보유: 5 년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                <li>2. 대금결제 및 재화 등의 공급에 관한 기록 보유: 5 년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                <li>3. 소비자 불만 또는 분쟁 처리에 관한 기록 보유: 3 년 (전자상거래 등에서의 소비자보호에 관한 법률)</li>
                <li>4. 본인확인에 관한 기록 보유: 6 개월 (정보통신망 이용촉진 및 정보보호 등에 관한 법률)</li>
                <li>5. 방문에 관한 기록 보유: 3 개월 (통신비밀보호법)</li>
              </ol>
            </li>
            <li>② 반복적인 회원 탈퇴 및 재가입으로 인한 경제적 이익 취득 등 불법, 편법 행위 차단을 위해 문제시 되는 회원의 개인정보(뱅크샐러드 ID, 탈퇴 일자 등)를 회원 탈퇴 후 1 년간 보유할 수 있습니다.</li>
          </ol>
        </li>
        <li>
          <b>제 4 조 개인정보의 파기절차 및 방법</b>
          <ol>
            <li>① 회사는 개인정보의 수집 및 이용 목적이 달성하거나 개인정보의 보유기간이 경과된 경우에는 해당정보를 지체없이 파기하는 것을 원칙으로 합니다. 단, 관련 법령 등에 따라 보존할 필요가 있는 개인정보의 경우 해당 개인정보 또는 개인정보파일을 다른 개인정보와 분리해서 저장•관리 합니다. 이때 별도로 보관되는 개인정보는 각각의 보존 근거에 해당하는 목적 이외의 다른 목적으로 이용되지 않습니다.</li>
            <li>② 서비스를 1년 동안 이용하지 않는 고객의 경우, 고객의 정보를 다른 이용자와 분리하여 별도로 저장∙관리합니다.</li>
            <li>③ 개인정보를 파기할 때, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 복원이 불가능한 방법으로 영구 삭제하는 방법으로 파기합니다.</li>
          </ol>
        </li>
        <li>
          <b>제 5 조 개인정보의 제 3 자 제공</b>
          <ol>
            <li>① 회사는 관계 법령에 따라 이용자의 동의가 있거나 법률에 특별한 규정이 있는 등의 경우에만 개인정보를 제 3 자에게 제공합니다.</li>
            <li>
                    ② 회사는 다음과 같이 개인정보를 제 3 자에게 제공하고 있습니다.
              <table>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <th>소재국</th>
                    <th>제공받는 자의 개인(신용)정보 이용 목적</th>
                    <th>제공항목</th>
                    <th>보유·이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      <div>코리아크레딧뷰로(주)</div>
                    </td>
                    <td className={ styles.width10 }>
                      <div>국내</div>
                    </td>
                    <td>
                      <div>본인 확인</div>
                    </td>
                    <td>
                      <div>
                                    * 이름, 성별, 이동 전화번호, 내외국인구분, 중복가입 확인정보(DI), 암호화된 동일인 식별정보(CI), 아이핀 번호(아이핀이용 시)
                      </div>
                    </td>
                    <td>
                      <div>제공 동의일로부터 개인(신용)정보를제공받는 자의 목적을 달성할 때까지</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ol>
        </li>
        <li>
          <b>제 6 조 수집한 개인정보의 처리위탁</b>
          <ol>
            <li>
                    ① “서비스”는 회원 가입 및 서비스 제공을 위해 아래와 같이 개인정보 처리 업무를 외부 전문업체에 위탁하여 운영하고 있습니다.
              <table>
                <tbody>
                  <tr>
                    <th>목적</th>
                    <th>위탁 업체</th>
                    <th>개인정보</th>
                  </tr>
                  <tr>
                    <td>
                      <div>본인확인 서비스 업무</div>
                    </td>
                    <td>
                      <div>코리아크레딧뷰로 주식회사</div>
                    </td>
                    <td>
                      <div>이름, 생년월일, 성별, 이동전화번호, 통신사, 내외국인구분</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
                    ② “서비스”는 고객의 사전 동의가 있거나, 관련 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 본 동의서에서 명시한 범위를 넘어 고객의 개인정보를 이용하거나 타인 또는 타기업이나 기관에 제공하지 않습니다.
            </li>
            <li>
                    ③ 위탁계약 시 개인정보보호의 안전을 기하기 위하여 개인정보보호 관련 지시 엄수, 개인정보에 관한 비밀유지, 제 3 자 제공의 금지 및 사고 시의 책임부담, 처리 종료 후의 개인정보의 반환 또는 파기 등을 명확히 규정하고 당해 계약 내용을 서면 및 전자적으로 보관하고 있습니다.
            </li>
          </ol>
        </li>
        <li>
          <b>제 7 조 고객 및 법정대리인의 권리∙ 의무 및 그 행사 방법</b>
          <ol>
            <li>① 고객 및 법정대리인은 개인정보의 조회, 수정을 위하여 서비스에 등록된 정보를 클릭하거나 설정 메뉴에 접근하여 계정 정보 등 등록된 개인정보를 직접 열람하거나 수정할 수 있습니다. 자 신의 개인정보를 열람한 고객은 사실과 다르거나 확인할 수 없는 개인정보에 대하여 회사에 정정 또는 삭제를 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있 는 경우에는 그 삭제를 요구할 수 없습니다.</li>
            <li>② 회사는 만 14 세 미만 아동의 개인정보를 보호하기 위해 본 웹사이트의 회원가입은 만 14 세 이상이 되어야 가능하도록 운영하고 있습니다. 즉 만 14 세 미만은 서비스의 회원가입이 불가하며, 그에 따라 법정대리인이 만 14 세 미만 아동 회원의 권리를 행사하는 경우는 예정되어 있지 않습니다.</li>
            <li>
                    ③ 이용자 등은 관련 법령에 따라 다음과 같은 권리를 행사할 수 있으며, 개인(신용)정보관리 책임자에게 전화, e-mail 로 연락하면 즉각 처리됩니다.
              <ol>
                <li>1. 개인정보 열람 요구</li>
                <li>2. 오류 등이 있을 경우 정정 요구</li>
                <li>3. 삭제 요구</li>
                <li>4. 처리 정지 요구</li>
              </ol>
            </li>
            <li>④ 고객이 개인정보의 오류 등에 대한 정정 또는 개인정보의 삭제를 요구한 경우, 회사는 이용자 등이 요청한 정정 또는 삭제 조치를 완료할 때까지 당해 개인정보를 이용하거나 제 3 자에게 제공하지 않습니다.</li>
            <li>⑤ 고객이 회원탈퇴를 원하는 경우, 서비스 뱅크샐러드 웹 사이트 및 모바일 애플리케이션에 로그인한 후 '탈퇴하기'를 통해 직접 탈퇴할 수 있습니다. 만일 직접 탈퇴가 어려운 경우 개인정보관리담당자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치를 취하겠습니다.</li>
          </ol>
        </li>
        <li>
          <b>제 8 조 개인정보 자동 수집 장치에 대한 안내 및 거부에 대한 사항</b>
          <p>
                    회사는 고객의 개인화된 서비스를 제공하기 위하여 '쿠키(cookie)'를 사용하기도 합니다. ‘쿠키 (Cookie)’란 웹사이트가 이용자의 컴퓨터 브라우저(넷스케이프, 인터넷익스플로러 등)로 전송하는 소량의 정보로서 고객 웹사이트에 로그인(log-in)하면 서버는 고객의 브라우저에 있는 쿠키의 내 용을 읽고, 고객의 추가정보를 고객 본인의 컴퓨터에서 찾아 서비스를 제공하게 됩니다. 이러한 쿠키 정보는 이용자께 유용하고 보다 편리한 맞춤 서비스를 제공하는데 사용되게 됩니다. 다음과 같은 목적을 위해 쿠키를 사용합니다.
          </p>
          <ol>
            <li>① 회원으로 등록하면, 처음 로그인할 때 쿠키가 설정되며, 로그아웃 하면 삭제됩니다. 회원 로그 인과 관련하여 쿠키가 사용되는 것은 현재 로그인 상태인지를 확인하기 위하여 사용됩니다.</li>
            <li>② 사용자 환경 설정 등에서도 쿠키를 사용합니다. 개인이 직접 선택한 검색 사용환경 값을 저장 해 두었다가 사용자가 필요로 하는 환경 설정을 위하여 이 정보를 사용합니다.</li>
            <li>③ 회원과 비회원의 접속 빈도의 차이나 반복 사용 정도 등 이용자의 이용 행동에 관한 정보를 파악하여 타겟 마케팅에 사용합니다.</li>
            <li>④ 쿠키는 회사가 진행하는 이벤트 등과 같은 각종 프로모션 행사 등에서 고객의 참여 빈도수 확인 등을 위하여 사용됩니다.</li>
            <li>⑤ 고객은 웹 브라우저의 옵션을 조정하여 모든 쿠키를 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있습니다. 그 방법은 아래와 같습니다.</li>
          </ol>
          <p>Internet Explorer 사용하는 경우</p>
          <ul>
            <li>- [도구] 메뉴에서 [인터넷옵션]을 클릭합니다.</li>
            <li>- [개인정보]탭을 클릭합니다.</li>
            <li>- 개인정보 설정수준을 사용자 조정하시면 됩니다.</li>
          </ul>
        </li>
        <li>
          <b>제 9 조 고객의 권리와 의무</b>
          <ol>
            <li>① 고객은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 고객에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원 자격이 상실될 수 있습니다.</li>
            <li>② 회사는 타인의 이메일 주소, Facebook 등 제휴업체 ID 또는 기타 개인정보를 도용하여 회원가입 한 자 또는 서비스를 이용한 자를 발견한 경우 지체 없이 해당 아이디에 대한 서비스 이용 정지 또는 회원 탈퇴 등 필요한 조치를 취합니다.</li>
            <li>③ 회사는 고객 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해서는 책임을 지지 않습니다. 고객은 본인의 개인정보를 보호하기 위해 자신의 ID 와 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 할 의무가 있습니다. 또한 다른 사람이 추측할 수 있는 쉬운 비밀번호는 사용을 피하시기를 권장하며, 정기적으로 비밀번호를 변경하는 것이 바람직합니다.</li>
          </ol>
        </li>
        <li>
          <b>제 10 조 개인정보의 안정성 확보 조치</b>
          <p>
                    개인정보 보호법 제 29 조(안전조치의무) 등 관련 규정에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적 및 물리적조치를 취하고 있습니다.
          </p>
          <ol>
            <li>① 개인정보의 안전한 처리를 위한 개인정보 처리방침을 수립 및 시행하고 있습니다.</li>
            <li>② 개인정보처리시스템은 접근이 통제된 별도의 서버에 두고 있으며, 개인정보의 안전한 보 관을 위한 보관시설의 마련 또는 잠금 장치의 설치 등 물리적 조치를 시행하고 있습니다.</li>
            <li>③ 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있으며, 접속기록의 보관 및 위조, 변조 방지를 위한 조치를 취하고 있습니다.</li>
            <li>④ 개인정보를 저장 또는 전송시에는 암호화 기술 등을 적용하여 안전하게 관리하고 있습니 다.</li>
            <li>⑤ 해킹이나 컴퓨터 바이러스 등에 의한 개인(신용)정보 유출 및 훼손을 막기 위한 보안프로 그램을 설치 및 갱신하고 있습니다.</li>
            <li>⑥ 개인정보를 취급하는 직원을 대상으로 개인정보 보호 의무 등에 관해 정기적인 사내 교육을 실시하고 있습니다.</li>
            <li>⑦ 회사는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책 임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기위해서 자신의 ID 와 비 밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.</li>
          </ol>
        </li>
        <li>
          <b>제 11 조 개인정보관리책임자 및 담당자 안내</b>
          <ol>
            <li>
                    ① 회사는 고객의 개인정보 관련 문의사항 및 불만 처리 등을 위하여 아래와 같이 개인정보 관리 책임자 및 담당자를 지정하고 있습니다.
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <span>① 개인정보관리책임자</span>
                        <ul>
                          <li>- 이름 : 김태훈</li>
                          <li>- 소속 : (주)레이니스트</li>
                          <li>- 직위 : 대표이사</li>
                          <li>- 전화 : 02-3453-9300</li>
                          <li>- 이메일 : banksalad@banksalad.com</li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>② 개인정보관리담당자</span>
                        <ul>
                          <li>- 이름 : 박규인</li>
                          <li>- 소속 : (주)레이니스트</li>
                          <li>- 직위 : 팀장</li>
                          <li>- 전화 : 02-3453-9300</li>
                          <li>- 이메일 : gragpark@rainist.com</li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
                    ② 기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의하시기 바랍니다.
              <ol>
                <li>1. 개인정보침해신고센터 (www.118.or.kr / 118)</li>
                <li>2. 개인정보분쟁조정위원회 (www.kopico.or.kr / 02-405-5150)</li>
                <li>3. 대검찰청 사이버수사과 (www.spo.go.kr / 02-3480-2000)</li>
                <li>4. 경찰청 사이버안전국 (www.ctrc.go.kr / 02-392-0330)</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
      <p>공고일자 : 2017. 07. 25.</p>
      <p>시행일자 : 2017. 08. 15.</p>
    </div>
  </div>;
