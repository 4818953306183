import { Entity } from 'core/entity';
import { AdviceType } from 'core/entity/personal-finance/advice/type';
import { AdviceMetadata } from 'core/entity/personal-finance/advice/metadata';
import { List } from 'immutable';
import { AdviceReason } from 'core/entity/personal-finance/advice/reason';
import { AdviceContent } from 'core/entity/personal-finance/advice/content';
import { AdviceTag } from 'core/entity/personal-finance/advice/tag';
import { AdviceReaction } from 'core/entity/personal-finance/advice/reaction';

export class Advice extends Entity {
    id: string;
    version: number;
    type: AdviceType;
    tag: AdviceTag;
    metadata: AdviceMetadata;
    contents: List<AdviceContent>;
    reaction: AdviceReaction;
    heroImageUrl?: string;
    title?: string;
    reason?: AdviceReason;

    constructor(
        id: string,
        version: number,
        type: AdviceType,
        tag: AdviceTag,
        metadata: AdviceMetadata,
        contents: List<AdviceContent>,
        reaction: AdviceReaction,
        heroImageUrl: string = null,
        title: string = null,
        reason: AdviceReason = null
    ) {
        super();
        this.id = id;
        this.version = version;
        this.type = type;
        this.tag = tag;
        this.metadata = metadata;
        this.contents = contents;
        this.reaction = reaction;
        this.heroImageUrl = heroImageUrl;
        this.title = title;
        this.reason = reason;
    }
}
