import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject } from 'utils/index';
import { MobileAuthenticationSpec } from 'core/entity/user/mobile-authenticate-spec';

export class MobileAuthenticationSpecMapper implements NetworkRequestMapper<MobileAuthenticationSpec> {
    toJson(target: MobileAuthenticationSpec): any {
        return compactObject({
            authentication_request_id: target.authenticationRequestId,
            phone_number: target.phoneNumber,
            verification_code: target.verificationCode
        })
    }
}
