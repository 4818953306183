import { LoanRepository } from 'data/repository/loan';
import { LoanSurveyConfigurationRepository } from 'data/repository/loan-survey-configuration';
import { LoanRecommendRepository } from 'data/repository/loan-recommend';
import { LoanStatisticsRepository } from 'data/repository/loan-statistics';
import { HttpProviderDependencies } from 'application/@context/http-providers';
import { StorageProviderDependencies } from 'application/@context/storage-providers';

export class LoanDomainRepositoryDependencies {
    loan: LoanRepository;
    loanRecommend: LoanRecommendRepository;
    loanSurveyConfiguration: LoanSurveyConfigurationRepository;
    loanStatistics: LoanStatisticsRepository;

    constructor(http: HttpProviderDependencies, storage: StorageProviderDependencies) {
        this.loan = new LoanRepository(http.gateway.loan, storage.serverRenderLoan);
        this.loanRecommend = new LoanRecommendRepository(http.gateway.loan, storage.loanRecommendSpec, storage.loanRecommendFilter);
        this.loanSurveyConfiguration = new LoanSurveyConfigurationRepository(http.gateway.loanSurveyConfiguration);
        this.loanStatistics = new LoanStatisticsRepository(http.gateway.loanStatistics);
    }
}
