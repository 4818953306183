import { NetworkResponseMapper } from 'data/http/mapper';
import { UserPreference } from 'core/entity/user/preference';
import { parseDate } from 'utils/index';

export class UserPreferenceMapper implements NetworkResponseMapper<UserPreference> {
    fromJson(json: any): UserPreference {
        return new UserPreference(
            json.initial_day_of_month,
            json.marketing_agreement_accepted,
            parseDate(json.marketing_agreement_accepted_at),
            parseDate(json.kcb_agreement_accepted_at),
            json.splitting_installment,
            json.injecting_bank_transaction_category
        )
    }

}
