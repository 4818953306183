import { NetworkResponseMapper } from 'data/http/mapper';
import { ComparableCardMapper } from 'data/http/mapper/comparable-card';
import { ComparableCardConfiguration } from 'core/entity/comparable-card/configuration';

export class ComparableCardConfigurationMapper implements NetworkResponseMapper<ComparableCardConfiguration> {
    fromJson(json: any): ComparableCardConfiguration {
        return new ComparableCardConfiguration(
            new ComparableCardMapper().fromJson(json.card),
            json.caption
        )
    }
}
