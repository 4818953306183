import { Observable } from 'rxjs';
import { List } from 'immutable';
import { Service } from 'core/service';
import { UserFinancialDataRepositoryType } from 'core/repository/personal-finance/financial-data';
import { UserFinancialDataAssetSummary } from 'core/entity/personal-finance/financial-data/summary';

export class UserFinancialDataService implements Service {
    private repository: UserFinancialDataRepositoryType;

    constructor(
        repository: UserFinancialDataRepositoryType
    ) {
        this.repository = repository;
    }

    getAssetSummaries = (startDate: Date, endDate: Date): Observable<List<UserFinancialDataAssetSummary>> => (
        this.repository.getAssetSummaries(startDate, endDate)
            .map(summaries =>
                summaries
                    .groupBy(summary => summary.baseDateTime.format('MM'))
                    .map(summaries => summaries.sortBy(summary => summary.baseDateTime.getTime()))
                    .map(summaries => summaries.last())
                    .slice(-3)
                    .toList()
            )

    );
}
