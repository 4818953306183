import { Service } from 'core/service';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';
import { Observable } from 'rxjs';
import { apply, emptyList, pairOf } from 'utils/index';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { List } from 'immutable';
import { LoanStatisticsRepositoryType } from 'core/repository/loan-statistics';
import { LoanSurveyConfigurationRepositoryType } from 'core/repository/loan-survey-configuration';
import { LoanRepositoryType } from 'core/repository/loan';

export class LoanService implements Service {
    private loanRepository: LoanRepositoryType;
    private recommendationRepository: LoanRecommendRepositoryType;
    private configurationRepository: LoanSurveyConfigurationRepositoryType;
    private statisticsRepository: LoanStatisticsRepositoryType;

    constructor(
        loanRepository: LoanRepositoryType,
        recommendationRepository: LoanRecommendRepositoryType,
        configurationRepository: LoanSurveyConfigurationRepositoryType,
        statisticsRepository: LoanStatisticsRepositoryType
    ) {
        this.recommendationRepository = recommendationRepository;
        this.configurationRepository = configurationRepository;
        this.statisticsRepository = statisticsRepository;
    }

    getCachedRecommendSpec = () =>
        this.recommendationRepository.getCachedSpec();

    getCachedRecommendFilter = () =>
        this.recommendationRepository.getCachedFilter();

    cacheDesiredAmount = (desiredAmount: number) =>
        this.recommendationRepository.getCachedSpec()
            .flatMap(spec =>
                this.recommendationRepository.cacheDesiredAmount(
                    apply(spec, it =>
                        it.desiredAmount = desiredAmount
                    )
                )
            );

    cacheRecommendSpec = (spec: LoanRecommendSpec) =>
        this.recommendationRepository.cacheSpec(spec);

    cacheRecommendFilter = (filter: LoanRecommendFilter) =>
        this.recommendationRepository.cacheFilter(filter);

    clearRecommendCaches = () =>
        Observable.zip(
            this.recommendationRepository.clearSpec(),
            this.recommendationRepository.clearFilter(),
            () => null as void
        );

    compute = (slug: string, primeInterestNodeIds: List<number> = emptyList()) =>
        Observable.zip(
            this.recommendationRepository.getCachedSpec(),
            this.recommendationRepository.getCachedFilter()
        ).flatMap(result =>
            this.recommendationRepository.compute(
                slug,
                apply(result[0], it => {
                    it.primeInterestNodeIds = primeInterestNodeIds;
                }),
                result[1]
            )
        );

    getProfitsCachedData = () =>
        Observable.zip(
            this.recommendationRepository.getCachedSpec(),
            this.recommendationRepository.getCachedFilter(),
            (spec, filter) => pairOf(spec, filter)
        );

    getProfitsPresentationalContents = () =>
        Observable.zip(
            this.configurationRepository.get(),
            this.statisticsRepository.get(),
            (configure, statistics) => pairOf(configure.histories, statistics)
        );

    getLoanStatistics = () =>
        this.statisticsRepository.get();

    getSurveyConfigurations = () =>
        this.configurationRepository.get();

    getServerRenderedLoan = () =>
        this.loanRepository.getServerRenderedLoan();

    recommendLoan = (spec: LoanRecommendSpec, filter: LoanRecommendFilter) =>
        Observable.zip(
            this.recommendationRepository.cacheSpec(spec),
            this.recommendationRepository.cacheFilter(filter)
        ).flatMap(
            () => this.recommendationRepository.recommend(spec, filter)
        );

    recommendLoanSimply = (spec: LoanRecommendSpec, defaultFilter: LoanRecommendFilter) =>
        Observable.zip(
            this.recommendationRepository.cacheSpec(spec),
            this.recommendationRepository.cacheFilter(defaultFilter)
        ).flatMap(() => (
            this.recommendationRepository.recommendSimply(
                spec,
                defaultFilter
            )
        ));
}
