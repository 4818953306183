import { CardLegalConfiguration } from 'core/entity/legal-configuration/card';
import { CardLegalConfigurationRepositoryType } from 'core/repository/card-legal-configuration';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class GetCardLegalConfigByCardId extends UseCase<CardLegalConfiguration> {
    cardId: number;
    private repository: CardLegalConfigurationRepositoryType;

    constructor(repository: CardLegalConfigurationRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<CardLegalConfiguration> {
        return this.repository.getByCardId(this.cardId);
    }

    protected validate(): boolean {
        return !!this.cardId;
    }
}
