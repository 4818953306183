import { Observable } from 'rxjs/Rx';
import { HttpProvider } from 'data/http';
import { LoanStatistics } from 'core/entity/loan/statistics';
import { LoanStatisticsMapper } from 'data/http/mapper/loan/statistics';

export class LoanStatisticsApiProvider extends HttpProvider {
    get(): Observable<LoanStatistics> {
        return this.requestGET(
            'api/v2/loans/statistics'
        ).map((result: any) =>
            new LoanStatisticsMapper().fromJson(result.data)
        );
    }
}
