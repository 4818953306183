export enum PersonalFinanceMenuType {
    MY_FINANCE = 'MY금융',
    TRANSACTIONS = '내역',
    STATISTICS = '통계',
    SECRETARY = '금융비서',
    BUDGET = '예산',
    GOAL = '목표',
    FINANCIAL_TECHNOLOGY = '재테크',
    SETTINGS = '설정'
}
