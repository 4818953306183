import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1KCBAllCreditView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h2>KCB 올크레딧 이용약관</h2>

      <h3>제1장 총칙</h3>

      <h4>제1조 (목적)</h4>
            이 약관은 코리아크레딧뷰로 주식회사(이하 "회사"라 함)가 인터넷 및 모바일 등 정보통신설비를 통해 제공하는 서비스를 이용함에 있어 이용자와 "회사"간에 준수할 사항을 정함을 목적으로 합니다.

      <h4>제2조 (정의)</h4>
            ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
      <ol>
        <li>
                "서비스"라 함은 회사가 다음과 같은 사이트(이하 "사이트"라 함)에서 제공하는 제7조 제1항의 서비스를 말하며, "유료 서비스"라 함은 이용의 대가로 이용자가 이용료 등을 회사에 납입하여야 하는 서비스를 말합니다.
          <br/><br/>
                가. 올크레딧 사이트(www.allcredit.co.kr; m.allcredit.co.kr)<br/>
                나. 기타 추가 서비스를 제공하기 위한 사이트
        </li>
        <li>"이용자"라 함은 사이트에 접속하여 이 약관에 동의하고 회사가 제공하는 서비스를 이용하는 회원 및 비회원을 말합니다.</li>
        <li>"회원"이라 함은 사이트에 회원으로 가입하고 회사가 제공하는 서비스를 이용하는 자를 말합니다.</li>
        <li>"비회원"이라 함은 사이트에 회원으로 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.</li>
        <li>"아이디"(ID)라 함은 회원의 식별 및 서비스 이용을 위하여 회원이 신청하고 "회사"가 부여하는 문자와 숫자의 조합을 말합니다.</li>
        <li>"비밀번호"(패스워드)라 함은 아이디로 식별되는 회원의 본인여부를 검증하기 위하여 회원이 설정하여 회사에 등록한 문자와 숫자의 조합을 말합니다.</li>
        <li>“개인정보"라 함은 생존하는 개인에 관한 정보로서 성명 · 주민등록번호 등에 의하여 개인을 알아볼 수 있는 정보를 말합니다.</li>
        <li>"제휴사"라 함은 회사와 전략적 제휴를 통하여 회원정보 공유, 공동 마케팅, 공동 사업을 추진하는 사업체를 말합니다.</li>
      </ol>

            ② 제1항에서 정의한 용어 이외의 사항은 관련법규 및 상관례에서 정하는 바에 따릅니다<br/>

      <h4>제3조 (약관의 명시 및 변경)</h4>
            ① "회사"는 이 약관을 "이용자"가 쉽게 알 수 있도록 사이트의 초기 화면에 게시하여 명시하고, 이용자의 요청이 있는 경우에는 전자문서의 전송, 모사전송, 우편 또는 직접 교부의 방식 등으로 이 약관의 사본을 교부하고 그 약관의 내용을 설명합니다.<br/>
            ② "이용자"가 회원가입 시 또는 무료서비스 이용 및 유료서비스 구매 시 이 약관의 하단에 기재된 "동의"버튼을 클릭할 경우에 이 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 이 약관의 적용에 동의하는 것으로 봅니다.<br/>
            ③ "회사"는 관련법규를 위배하지 않는 범위에서 이 약관을 변경할 수 있으며, 약관을 변경하는 경우에는 시행일 1개월 전부터 변경되는 약관을 사이트의 공지란을 통해 초기화면에 게시하거나 "이용자"에게 통지합니다.<br/>
            ④ "이용자"는 전항의 규정에 따른 약관의 변경내용이 게시되거나 통지된 후부터 변경되는 약관의 시행일 전의 영업일까지 회원 탈퇴(해지)를 할 수 있습니다.전단의 기간 안에"이용자"가 약관의 변경내용에 대하여 이의를 제기하지 아니하는 경우에는 약관의 변경을 승인한 것으로 봅니다.

      <h4>제4조 (다른 법령의 준용)</h4>
            이 약관에서 정하지 아니한 사항은 약관의 규제에 관한 법률, 정보통신망 이용 촉진 및 정보보호등에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전자거래기본법, 전자금융거래법, 전자상거래등에서의 소비자보호에 관한 법률 등 관련법규 또는 상관례에 따릅니다.

      <h3>제2장 서비스의 이용계약 및 해지</h3>

      <h4>제5조 (서비스 이용계약의 성립)</h4>
            ① "이용자"는 이 약관에 동의한다는 의사표시를 하고 "회사"가 정한 양식에 따라 "이용자"의 개인정보를 기입함으로써 회원 가입을 신청합니다.<br/>
            ② "회사"와 "이용자"간의 서비스 이용 계약은 이 약관에 동의한 "이용자"의 이용신청에 대하여 "회사"가 승낙함으로써 성립하고, "이용자"는 회사가 승낙을 한 때로부터 회원의 자격을 취득합니다.<br/>
            ③ "회사"는 다음 각 호에 정한 사유가 있는 경우에는 "이용자"의 서비스 이용신청을 승낙하지 않을 수 있습니다

      <ol>
        <li>이용신청 시 정보를 허위로 기재하거나 다른 사람 명의를 도용한 경우</li>
        <li>“회사"가 정한 이용신청 절차에 위반하거나 이용신청을 승낙하는 것에 기술상 문제가 있는 경우</li>
        <li>공공질서 및 미풍양속을 저해할 목적으로 이용 신청한 경우</li>
        <li>기타"회사"가 이용승낙이 곤란하다고 판단하는 사유가 있는 경우</li>
      </ol>

      <h4>제6조 (서비스 이용계약의 해지)</h4>
            ① "이용자"가 서비스 이용계약을 해지하고자 하는 때에는 "이용자"본인이 "회사"에 해지신청을 하거나 사이트의 회원탈퇴 메뉴에서 회원 탈퇴하기 확인버튼을 클릭하고 이에 따른 탈퇴 절차를 거침으로써 계약을 해지할 수 있습니다.<br/>
            ② "회사"는 "이용자"가 다음 각 호에 해당하는 행위를 하였을 경우 임의로(단, 제7호의 경우에는 사전에 통지합니다) 이용계약을 해지하거나 서비스의 이용을 중단시킬 수 있습니다. 이 경우 유료 서비스 중단에 따른 이용료의 정산을 하지 아니합니다.
      <ol>
        <li>다른 사람의 명의, 주민등록번호, 아이디 및 비밀번호를 도용한 경우</li>
        <li>서비스 운영을 고의로 방해한 경우</li>
        <li>공공질서 및 미풍양속에 저해되는 내용을 유포시킨 경우</li>
        <li>타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</li>
        <li>서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우</li>
        <li>컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
        <li>기타 이 약관 및 "회사"가 정한 서비스 이용조건, 관련법규에 위반한 경우</li>
      </ol>

      <h3>제3장 서비스의 이용</h3>

      <h4>제7조 (서비스의 제공 및 변경 )</h4>
            ① "회사“는 다음 각호의 서비스를 "이용자"에게 제공하며, 서비스의 상세한 내용은 서비스의 제공화면에서 설명합니다.

      <ol>
        <li>
                신용관리 서비스<br/>
                본인의 신용등급 및 신용평점 확인, 신용등급 관리방안, 신용정보 변동내역 통보 등 본인의 신용정보를 관리할 수 있는 서비스
        </li>
        <li>
                명의보호 서비스<br/>
                내 명의의 불법신용조회를 차단하거나 금융거래발생시 실시간 알람을 받을 수 있는 서비스
        </li>
        <li>
                카드/대출관리 서비스<br/>
                본인의 카드관련정보(카드 이용금액, 카드보유개수 등) 및 대출관련정보(대출금액, 연체금액 등)을 확인할 수 있는 서비스
        </li>
        <li>
                기타 "회사"가 개발하거나 다른 회사와의 협력을 통해 제공하는 서비스
        </li>
      </ol>

            ② "회사"는 업무 수행상 필요하다고 판단되는 경우 전항의 서비스 내용을 추가하거나 변경할 수 있으며, 이 경우 추가 또는 변경한 서비스의 내용을 사이트에 게시하거나 이용자에게 개별 통지합니다.<br/>
            ③ "회사"는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 변경하거나 중단할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 “이용자”에게 별도의 보상을 하지 않습니다.

      <h4>제8조 (서비스 이용의 제한 및 중단)</h4>
            ① "회사"는 다음 각 호에 해당하는 사유가 있는 경우 서비스의 전부 또는 일부의 이용을 제한하거나 중단시킬 수 있습니다.
      <ol>
        <li>사이트의 장애 및 보수 등으로 부득이한 경우</li>
        <li>천재지변, 국가비상사태, 정전, 통신두절 등으로 부득이한 경우</li>
        <li>"회사"의 파업, 파산, 사업목적의 변경으로 인한 서비스의 폐쇄</li>
        <li>기타 서비스 제공을 계속하기가 부적절하거나 현저히 곤란한 사유가 있는 경우</li>
      </ol>
            ② 전항의 의한 서비스의 이용 제한 및 중단의 경우 "회사"는 사이트 화면에 게시 하거나 전화, 이메일 등 기타 적절한 방법으로 "이용자"에게 통지합니다.

      <h4>제9조 (서비스 이용료)</h4>
            ① "회사"는 유료 서비스 이용료에 관한 자세한 내용에 대하여는 유료 서비스 이용계약 체결 시 해당 서비스 신청화면에 게시하거나 기타의 방법으로 "이용자"에게 이 약관과 별도로 공지합니다.<br/>
            ② "이용자"는 유료 서비스 이용료를 다음 각호의 방법으로 납입할 수 있습니다.
      <ol>
        <li>"회사"가 인정하는 신용카드에 의한 결제</li>
        <li>"회사"가 인정하는 계좌이체</li>
        <li>"회사"가 인정하는 휴대폰에 의한 결제</li>
        <li>기타 "회사"가 인정하는 방법</li>
      </ol>

      <h4>제10조 (환불)</h4>
            ① "이용자"가 유료서비스를 구매한 후 구매취소 및 환불을 요청할 경우에는 "회사"는 다음의 각호에 따라 환불처리 합니다.<br/>
      <ol>
        <li>
                "이용자"가 종합신용관리서비스 등 서비스 구매 후 1회 조회로 상품의 가치가 손실되는 경우
          <br/><br/>
                가. 구매 후 1회도 조회하지 않은 경우에는 결제를 취소하거나 전액환불을 합니다. 단, 구매 후 7일을 초과하는 경우에는 "이용자"가 지급한 이용료에서 총 이용료의 10%에 해당하는 금액(수수료 및 위약금)을 공제한 금액을 환불합니다.<br/>
                나. 구매 후 1회 이상 조회한 경우에는 "이용자"가 지급한 이용료에서 총 이용료의 10%에 해당하는 금액 (수수료 및 위약금)과 이용기간에 해당하는 금액 및 제출용 신용보고서(평점 기본 보고서) 금액을 공제한 금액을 환불합니다.
        </li>
        <li>"이용자"가 명의보호서비스 등 서비스 이용기간에 따라 이용료를 산정한 서비스를 구매한 경우에는 "이용자"가 지급한 이용료에서 총 이용료의 10%에 해당하는 금액 (수수료 및 위약금)과 이용기간에 해당하는 금액을 공제한 금액을 환불합니다.</li>
        <li>“회사"의 귀책사유 및 사이트 자체의 문제로 인해 "이용자"가 서비스를 제공받을 수 없는 경우 수수료 및 위약금 없이 "이용자"가 지급한 이용료에서 이용기간에 해당하는 금액을 공제한 금액을 환불합니다.</li>
      </ol>
            ② "이용자"가 제1항 에 따라 환불을 요청한 경우 "회사"는 특별한 사유가 없는 한 14일 이내에 10원미만은 절상한 금액을 환불합니다.<br/>
            ③ "회사"에서 마케팅 활동 등의 목적으로 제공하는 무상 서비스이용권은 환불되지 않습니다.<br/>
            ④ 기타 본 약관에서 정하지 않은 환불에 관한 사항은 "회사"가 서비스 소개화면 등 사이트에 별도로 고지하는 내용에 따릅니다.

      <h4>제11조 (정보 및 광고의 제공)</h4>
            ① "회사"는 서비스와 관련된 정보 및 광고를 사이트, 서비스 화면 등에 게재할 수 있습니다.<br/>
            ② "회사"는 서비스와 관련된 정보 및 광고를 전화, 전자우편, 휴대전화 문자메시지 등에 의하여 전송하려고 하는 경우에는 “이용자”의 사전 동의를 받습니다.<br/>
            ③ 제2항에 따라 정보 및 광고를 제공받은 “이용자”는 원치 않을 경우 언제든지 가입신청 메뉴와 개인정보변경 메뉴 등에서 수신거부을 할 수 있습니다. 다만 관련법에 따른 거래관련정보 및 공시의무정보, 약관변경 안내 등은 수신거부에도 불구하고 전송될 수 있습니다.

      <h4>제12조 (광고의 게재 등)</h4>
            ① "회사"는 서비스에 적절하다고 판단되거나 활용 가능성 있는 광고를 사이트에 게재할 수 있습니다.<br/>
            ② "회사"는 사전 동의를 한 "이용자"에 한하여 "회사" 또는 제휴사의 상품소개 관련정보를 이메일, 휴대폰 SMS 등을 통해 발송할 수 있습니다.

      <h4>제13조 (이용자의 게시물)</h4>
            ① "이용자"가 게시한 게시물의 내용에 대한 권리 및 책임은 "이용자"에게 있으며, "이용자"의 게시물이 타인의 지적재산권 등을 침해함으로써 발생하는 민 · 형사상의 책임은 전적으로 "이용자"가 부담합니다.<br/>
            ② "회사"는 "이용자"가 게시한 내용이 다음 각호에 해당하는 경우 임의로 삭제할 수 있습니다. 다만, "회사"는 이러한 게시물의 삭제의무를 부담하지 않습니다.
      <ol>
        <li>"회사" 또는 제3자의 지적재산권 등을 침해하는 내용인 경우</li>
        <li>타인을 비방하거나 명예를 훼손하는 내용인 경우</li>
        <li>공공질서 및 미풍양속을 해칠 우려가 있는 내용인 경우</li>
        <li>탈퇴한 회원이 등록한 게시물</li>
        <li>범죄적 행위에 결부된다고 인정되는 내용일 경우</li>
        <li>"회사"가 규정한 게시기간을 초과한 경우</li>
        <li>"회사"의 서비스 제공을 고의적으로 방해하는 내용일 경우</li>
        <li>기타 이 약관이나 관련법규에 위배된다고 판단되는 경우</li>
      </ol>
            ③ "이용자"는 서비스를 이용하여 얻은 정보를 가공하거나 판매해서는 안되며, 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.

      <h3>제4장	 책임과 의무</h3>
      <h4>제14조 (회사의 책임과 의무)</h4>
            ① "회사"는 이 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공하는데 최선을 다하며, 사이트에 장애가 생긴 경우 특별한 사유가 없는 한 지체없이 이를 수리 또는 복구합니다.<br/>
            ② "회사"는 "이용자"의 개인정보를 보호하고 개인정보와 관련한 이용자의 불만을 처리하기 위하여 개인정보보호책임자를 지정하고, 개인정보처리방침을 제정하여 사이트 초기화면에 공시합니다.<br/>
            ③ "회사"는 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 아니하도록 안전성 확보에 필요한 기술적 · 관리적 보호대책을 수립해야 합니다.<br/>
            ④ "회사"는 "이용자"로 부터 정당한 의견이나 불만 제기 등이 있는 경우 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 "이용자"에게 그 사유와 처리일정을 통보합니다.

      <h4>제15조 (이용자의 책임과 의무)</h4>
            ① "이용자"는 다음 각 호에 해당하는 행위를 해서는 안됩니다.
      <ol>
        <li>다른 사람의 명의, 아이디, 비밀번호 등 개인정보를 도용하거나 부정하게 사용하는 행위</li>
        <li>서비스에서 얻은 정보를 회사의 사전승낙 없이 이용 이외의 목적으로 이용하거나 제3자에게 제공하는 행위</li>
        <li>"회사"및 제3자의 지적재산권 등을 침해하는 행위</li>
        <li>공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위</li>
        <li>범죄와 결부된다고 객관적으로 판단되는 행위</li>
        <li>기타 약관 및"회사"가 공지한 사항, 관련법규를 위반하는 행위</li>
      </ol>
            ② "이용자"는 아이디 및 비밀번호 등 본인의 개인정보를 타인이 사용하게 해서는 안되며, 본인의 개인정보를 타인이 이용함으로써 발생한 결과에 대한 책임은 "이용자"에게 있습니다.<br/>
            ③ "이용자"는 "회사"의 사전승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대하여 "회사"는 책임을 지지 않습니다. "이용자"는 이와 같은 영업활동으로 인하여 "회사"에 손해를 발생시킨 경우 그 손해를 배상하여야 합니다.<br/>
            ④ "이용자"는 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.

      <h4>제16조 (면책)</h4>
            "회사"는 다음 각 호에 해당하는 경우에는 책임을 지지 않습니다.
      <ol>
        <li>"이용자"의 아이디 및 비밀번호 등 관리소홀로 손해가 발생할 경우</li>
        <li>천재지변, 전쟁 등 불가항력적인 사유로 서비스를 제공하지 못하는 경우</li>
        <li>통신기기, 통신회선 또는 "회사"가 직접 통제할 수 없는 장애에 의한 업무처리 지연이나 불능이 발생한 경우</li>
        <li>"이용자"가 제15조(이용자의 책임과 의무)를 위반하여 손해가 발생한 경우</li>
        <li>"이용자"의 동의를 얻어 제휴사에 제공한 정보가 제휴사에 의해 유출이 된 경우</li>
        <li>기타 "회사"의 고의 또는 과실로 인하여 발생한 사고가 아닌 경우</li>
      </ol>

      <h4>제17조 (지적재산권의 귀속 및 이용제한)</h4>
            ① "회사"가 작성한 저작물에 대한 지적재산권은 "회사"에 귀속합니다.<br/>
            ② "이용자"는 사이트를 이용함으로써 얻은 정보 중 "회사"의 지적재산권에 속하는 정보를 "회사"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 제공하여서는 안됩니다.

      <h3>제5장	 손해배상 및 분쟁의 해결 등</h3>
      <h4>제18조 (손해배상)</h4>
            ① "회사" 및 "이용자"가 이 약관을 위반하는 경우, 귀책 당사자는 책임을 부담하며, 이로 인하여 상대방에게 손해를 입힌 경우에는 이를 배상하여야 합니다.<br/>
            ② "회사"는 천재지변, 국가비상사태, 전쟁, 파업, 기타" 회사"의 지배범위 밖의 사유로 인하여"이용자"및 제3자에게 발생시킨 손해에 대하여 책임지지 아니합니다.

      <h4>제19조 (회사와 제휴사와의 관계)</h4>
            ① "회사"는 제휴사가 제공하는 서비스에 대하여 보증책임을 지지 않습니다.<br/>
            ② "회사"와 제휴사는 독자적으로 사이트를 운영하므로 "회사"는 제휴사와 "이용자"간에 행해진 거래에 대해 어떠한 책임도 지지 않습니다.

      <h4>제20조 (통지)</h4>
            ① "회사"가 "이용자"에 대하여 개별 통지를 하는 경우," 회사"는 "이용자"가 제공한 주소, 전화번호, 이메일 등 "회사"가 적절하다고 판단하는 방법을 선택할 수 있습니다.<br/>
            ② "회사"가 불특정 다수 "이용자"에 대하여 통지를 하는 경우 "회사"는 1주일 이상 사이트 화면에 게시함으로써 개별 통지에 갈음할 수 있습니다.<br/>
            ③ "이용자“가 잘못된 연락처 정보를 제공하거나 변경된 연락처 정보를 “회사”에 통보하지 않는 등 “이용자”의 귀책 사유 로 인하여 통지를 받지 못하는 경우 "회사"는 책임을 지지 않습니다.

      <h4>제21조 (분쟁 해결)</h4>
            ① 회사"는 "이용자"가 제기하는 정당한 의견이나 불만을 반영하고 그 처리를 위하여 고객상담부서를 설치하여 운영합니다.<br/>
            ② "회사”는 “이용자”로부터 제출되는 불만사항 및 의견을 신속하게 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보합니다.<br/>
            ③ "회사"와 "이용자"는 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.

      <h4>제22조 (준거법 및 관할법원)</h4>
            "회사"와 "이용자"간에 발생한 분쟁에 대하여는 대한민국 법을 적용하며, 분쟁으로 인한 소송은 민사소송법상 관할을 가지는 대한민국의 법원에 제기합니다.

      <h3>부 칙</h3>
            (시행일) 본 약관은 2016년 8월 1일부터 시행합니다.
      <br/><br/>
            이전 버전은 올크레딧 PC Web 이용약관에서 확인하실 수 있습니다.
    </div>
  </div>
);
