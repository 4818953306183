import { Service } from 'core/service';
import { PersonalizedInsuranceRepositoryType } from 'core/repository/personalized-recommend/insurance';
import { PersonalizedInsuranceRecommendSpec } from 'core/entity/personalized-recommend/insurance/recommend-spec';
import { Observable } from 'rxjs/Observable';
import { PersonalizedInsuranceRecommendedResult } from 'core/entity/personalized-recommend/insurance/recommend-result';

export class PersonalizedInsuranceService implements Service {
    private repository: PersonalizedInsuranceRepositoryType;

    constructor(
        recommendationRepository: PersonalizedInsuranceRepositoryType
    ) {
        this.repository = recommendationRepository;
    }

    recommendInsurance = (
        spec: PersonalizedInsuranceRecommendSpec
    ): Observable<PersonalizedInsuranceRecommendedResult> => (
        this.repository.recommend(spec)
    );
}
