import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardSelectiveGroup } from 'core/entity/card/selective-group';
import { lets } from 'utils/index';
import { CardBenefitGroupMapper } from 'data/http/mapper/card/benefit-group';

export class CardSelectiveGroupMapper implements NetworkResponseMapper<CardSelectiveGroup> {
    fromJson(json: any): CardSelectiveGroup {
        return new CardSelectiveGroup(
            json.number_of_groups,
            json.number_of_select,
            lets(json.select_type, it => {
                switch (it) {
                    case 'optional':
                        return CardSelectiveGroup.Type.OPTIONAL;
                    case 'selective':
                        return CardSelectiveGroup.Type.SELECTIVE;
                    default:
                        return CardSelectiveGroup.Type.OPTIONAL;
                }
            }),
            List(json.groups.map((benefitGroup: any) =>
                new CardBenefitGroupMapper().fromJson(benefitGroup)
            ))
        )
    }
}
