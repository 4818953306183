import { UseCase } from 'core/use-case';
import { StoreRepositoryType } from 'core/repository/store';
import { StoreCategory } from 'core/entity/store/category';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class GetStoreCategories extends UseCase<List<StoreCategory>> {
    private repository: StoreRepositoryType;

    constructor(repository: StoreRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<StoreCategory>> {
        return this.repository.getStoreCategories();
    }

    protected validate(): boolean {
        return true;
    }
}
