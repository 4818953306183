import { NetworkResponseMapper, MappingError } from 'data/http/mapper';
import { Alfred2ComputedCard } from 'core/entity/alfred2/computed-card';
import { Alfred2CardCompanyMapper } from 'data/http/mapper/alfred2/card-company';
import { AmountMapper } from 'data/http/mapper/amount';
import { Alfred2CardAnnualCostPromotionMapper } from 'data/http/mapper/alfred2/card-annual-cost-promotion';
import { Alfred2CardIssuingChannelConfigurationMapper } from 'data/http/mapper/alfred2/card-issuing-channel-configuration';
import { CFADeliberationReviewConfigurationMapper } from 'data/http/mapper/personal-finance/CFA-deliberation-review-configuration';
import { optional, lets } from 'application/utils';

export class Alfred2ComputedCardMapper implements NetworkResponseMapper<Alfred2ComputedCard> {
    fromJson(json: any): Alfred2ComputedCard {
        return new Alfred2ComputedCard(
            json.id,
            json.name,
            json.mileage_card,
            lets(json.card_type, it => {
                switch (it) {
                    case 'credit':
                        return Alfred2ComputedCard.Type.CREDIT;
                    case 'check':
                        return Alfred2ComputedCard.Type.CHECK;
                    case 'hybrid':
                        return Alfred2ComputedCard.Type.HYBRID;
                    default:
                        throw new MappingError();
                }
            }),
            new Alfred2CardCompanyMapper().fromJson(json.company),
            new AmountMapper().fromJson(json.annual_cost),
            json.image_url,
            lets(json.image_orientation, it => {
                switch (it) {
                    case 'landscape':
                        return Alfred2ComputedCard.ImageOrientation.LANDSCAPE;
                    case 'portrait':
                        return Alfred2ComputedCard.ImageOrientation.PORTRAIT;
                    default:
                        throw new MappingError();
                }
            }),
            json.information_view_url,
            new Alfred2CardIssuingChannelConfigurationMapper().fromJson(json.issuing),
            new CFADeliberationReviewConfigurationMapper().fromJson(json.cfa_deliberation_review),
            optional(json.annual_cost_promotion, new Alfred2CardAnnualCostPromotionMapper().fromJson)
        );
    }
}
