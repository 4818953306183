import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { UserFund } from 'core/entity/personal-finance/fund';
import { UserFundRepositoryType } from 'core/repository/personal-finance/fund';
import { UserFundUpdateSpec } from 'core/entity/personal-finance/fund/update-spec';

export class UpdateUserFund extends UseCase<UserFund> {
    id: string;
    spec: UserFundUpdateSpec;
    private repository: UserFundRepositoryType;

    constructor(repository: UserFundRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserFund> {
        return this.repository.update(this.id, this.spec);
    }

    protected validate(): boolean {
        return !!this.id && !!this.spec;
    }

}
