import { Card } from 'core/entity/card';
import { List } from 'immutable';
import { CardInfoCategoryBenefits } from 'presentation/components/info/card/detail/benefits/category';
import { Selector } from 'presentation/components/selector';
import { SelectorModel, SelectorTheme } from 'presentation/components/selector/model';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import React from 'react';

interface Props {
    card: Card;
    containRestrictions: boolean;
}

export const CardInfoDefaultBenefits = ({ card, containRestrictions }: Props) => {
  const {
    defaultBenefits,
  } = card;

  // https://github.com/banksalad/banksalad-web/pull/2246
  const filteredBenefits = defaultBenefits
    .map((defaultBenefit) => {
      const filteredStoreBenefits = defaultBenefit.storeBenefits.filter(
        (storeBenefit) => !storeBenefit.title.includes(" 0%")
      );

      return {
        ...defaultBenefit,
        storeBenefits: filteredStoreBenefits,
      };
    })
    .filter((defaultBenefit) => defaultBenefit.storeBenefits.size > 0);

  return (
    <>
      <Selector
        data={ List(
          filteredBenefits.map(benefit =>
            new SelectorModel(
              benefit.category,
              benefit.category
            )
          )
        ) }
        chunkSize={ 7 }
        chunkSizeForTablet={ 5 }
        chunkSizeForMobile={ 3 }
        theme={ SelectorTheme.ROMAINE }
        onChange={ result => {
          location.replace(`#${result.id}`);

          sendGAEvent(
            GA_DOMAIN.CARD,
            GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_DEFAULT,
            GA_ACTION.SCROLL,
            result.id
          );
        } }
      />
      <CardInfoCategoryBenefits
        card={ card }
        benefits={ List(filteredBenefits) }
        containRestrictions={ containRestrictions }
        gaCategory={ GA_CATEGORY.CARD_PROFITS.DETAIL_BENEFITS_DEFAULT }
      />
    </>
  );
};
