import { UserTransaction } from 'core/entity/personal-finance/transaction';
import { NetworkResponseMapper } from 'data/http/mapper';
import { UserExpenseTransactionMapper } from 'data/http/mapper/personal-finance/transaction/expense';
import { UserIncomeTransactionMapper } from 'data/http/mapper/personal-finance/transaction/income';
import { UserLogTransactionMapper } from 'data/http/mapper/personal-finance/transaction/log';

export class UserTransactionMapper implements NetworkResponseMapper<UserTransaction> {
    fromJson(json: any): UserTransaction {
        switch (json.type) {
            case 'expense':
                return new UserExpenseTransactionMapper().fromJson(json);
            case 'income':
                return new UserIncomeTransactionMapper().fromJson(json);
            case 'log':
                return new UserLogTransactionMapper().fromJson(json);
            default:
                return null;
        }
    }

}
