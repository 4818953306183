import { CardCompanyLegalConfiguration } from 'core/entity/legal-configuration/card-company';
import { CardCompanyLegalConfigurationRepositoryType } from 'core/repository/card-company-legal-configuration';
import { UseCase } from 'core/use-case';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class GetCardCompanyLegalConfigs extends UseCase<List<CardCompanyLegalConfiguration>> {
    private repository: CardCompanyLegalConfigurationRepositoryType;

    constructor(repository: CardCompanyLegalConfigurationRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<CardCompanyLegalConfiguration>> {
        return this.repository.getAll();
    }

    protected validate(): boolean {
        return true;
    }
}
