import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV3TermsView } from 'presentation/routes/policies/v3/terms/view';

export const PoliciesV3TermsRoute = () => (
    <>
    <MetaHelmet meta={ META_SET.TERMS }/>
    <PoliciesV3TermsView />
    </>
);
