import {
  LoanType,
  usePrequalLenderNames,
} from 'presentation/routes/policies/usePrequalLenderNames';
import React, { useState } from 'react';

import styles from '../../styles.pcss';

export const PoliciesV20PrivacyPolicyView = () => {
  const [visible, setVisible] = useState(false);
  const { prequalLenderNames } = usePrequalLenderNames();
  const { prequalLenderNames: mortgageLenderNames } = usePrequalLenderNames(
    LoanType.LOAN_TYPE_MORTGAGE_LOAN
  );

  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <h3>뱅크샐러드 개인정보처리방침</h3>
        <p>
          주식회사 뱅크샐러드(이하 ‘<strong>회사</strong>’)는 뱅크샐러드 서비스(이하 ‘
          <strong>서비스</strong>’)를 제공하면서 개인정보 보호법, 신용정보의 이용 및 보호에 관한
          법률 및 기타 관련 법령에 따라 이용자의 개인정보(개인신용정보 포함, 이하 같음)를 안전하고
          적법하게 처리합니다.
        </p>
        <ol>
          <li>
            <h4>본 개인정보처리방침의 목적</h4>
            <p>
              회사는 본 개인정보처리방침을 통해 이용자의 개인정보가 어떻게 처리되는지 투명하게
              공개합니다. 이용자는 본 개인정보처리방침을 통하여 회사가 어떠한 개인정보를 수집하고,
              수집한 개인정보를 어떻게 이용하며, 누구에게 이를 공유하고, 언제 파기하는지 등을 확인할
              수 있습니다.
              <br />
              <br />
              또한, 이용자가 회사에 대하여 자신의 개인정보에 관한 열람, 정정, 삭제, 처리정지를
              요청할 수 있는 방법을 설명합니다.
            </p>
            <br />
          </li>
          <li>
            <h4>회사가 수집하는 개인정보</h4>
            <h5>회원가입시 수집하는 개인정보</h5>
            <br />
            <p>
              회사는 이용자가 회원가입을 할 때 이름, 생년월일, 성별, 휴대폰번호, 이동통신사,
              내외국인 정보, 동일인 식별 연계정보(CI), 중복가입확인정보(DI), 뱅크샐러드 회원번호를
              수집하거나 생성합니다.
              <br />
              <br />
              회사는 이용자가 가입한 신용정보 조회 및 관리 서비스를 뱅크샐러드 앱 내에서 편리하게
              이용할 수 있도록 코리아크레딧뷰로부터 이용자의 개인신용평점 및 분석정보, 신용카드·대출
              등 금융거래 및 현황정보, 신용도 판단 정보, 신용정보 변동·조회 정보를 제공받습니다.
              <br />
              <br />
              이용자가 회원가입시에 발급받는 뱅크샐러드 인증서와 관련된 개인정보 처리에 대해서는{' '}
              <a
                href="https://policies.banksalad.com/banksaladcertificate/%EB%B1%85%ED%81%AC%EC%83%90%EB%9F%AC%EB%93%9C-%EC%9D%B8%EC%A6%9D%EC%84%9C-%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8"
                rel="noopener noreferrer"
                target="_blank"
              >
                뱅크샐러드 인증서 개인정보처리방침
              </a>
              을 참고하시기 바랍니다.
              <br />
              <br />
            </p>
            <h5>서비스 이용과정에서 수집하는 개인정보</h5>
            <br />
            <p>
              회사는 서비스 이용 과정에서 이용자가 관련 법령에서 정한 전송요구권을 행사하여 회사에
              전송하는 개인정보를 수집합니다. 이용자가 전송요구할 수 있는 정보에는 여수신정보,
              보험정보, 카드정보, 금융투자정보, 선불전자지급수단정보, 전자결제정보, 개인형 IRP정보,
              통신업 정보, 보증보험 정보, 인수채권 및 금전대부 정보, 선불카드 정보, P2P 정보,
              공공정보가 포함되며, 회사는 기술적 상황 등을 고려하여 이러한 정보의 전부 또는 일부를
              전송요구할 수 있는 수단을 제공하고, 이 중 이용자가 실제로 전송을 요구하여 회사에
              전송되는 개인정보를 수집합니다.
            </p>
            <ul>
              <li>
                - 전송요구 대상 개인정보 항목 상세 보기
                <button onClick={() => setVisible(!visible)}>🔽</button>
                <br />
                {visible && (
                  <table>
                    <tbody>
                      <tr>
                        <td rowSpan={12} className={styles.width20}>
                          <div>여수신정보</div>
                        </td>
                        <td colSpan={2} className={styles.width30}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>
                            고객정보 최초생성일시, 계좌번호, 회차번호, 상품명, 외화계좌여부,
                            마이너스약정 여부, 계좌번호 별 구분 코드·상태코드
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>계좌정보</div>
                        </td>
                        <td>
                          <div>계좌기본정보</div>
                        </td>
                        <td>
                          <div>
                            저축방법(코드), 계좌개설일자, 만기일, 통화코드, 약정액, 월 납입액
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>계좌잔액정보</div>
                        </td>
                        <td>
                          <div>통화코드, 현재잔액, 출금 가능액, 적용금리, 최종납입회차</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>
                            거래일시 또는 거래일자, 거래번호, 거래유형(코드), 거래구분, 통화코드,
                            거래금액, 적요, 거래 후 잔액, 납입회차
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>투자상품정보</div>
                        </td>
                        <td>
                          <div>기본정보</div>
                        </td>
                        <td>
                          <div>표준펀드코드, 납입유형(코드), 개설일, 만기일</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>잔액정보</div>
                        </td>
                        <td>
                          <div>통화코드, 잔액, 평가금액, 투자원금, 보유좌수</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>
                            거래일시 또는 거래일자, 거래번호, 거래유형, 통화코드, 기준가, 거래좌수,
                            거래금액, 거래 후 잔고평가금액
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>대출상품정보</div>
                        </td>
                        <td>
                          <div>기본정보</div>
                        </td>
                        <td>
                          <div>
                            대출일, 만기일, 최종적용금리, 월상환일, 상환방식(코드), 자동이체
                            기관(코드), 상환계좌번호(자동이체)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>잔액정보</div>
                        </td>
                        <td>
                          <div>통화코드, 대출잔액, 대출원금, 다음 이자 상환일</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>
                            거래일시 또는 거래일자, 거래번호, 거래유형, 통화코드, 거래금액, 거래 후
                            대출잔액, 거래금액 중 원금, 거래금액 중 이자, 환출이자, 이자종류(코드),
                            이자적용시작일, 이자적용종료일, 적용이율, 이자 금액, 장기대출 거래내역
                            정보, 리볼빙 상세정보
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          <div>운용리스</div>
                        </td>
                        <td>
                          <div>기본정보</div>
                        </td>
                        <td>
                          <div>
                            대출일, 만기일, 월상환일, 다음 납입 예정일, 상환방식(코드), 자동이체
                            기관(코드), 상환계좌번호(자동이체)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>거래일시, 거래번호, 거래유형, 거래금액</div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={11}>
                          <div>보험정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>
                            증권번호, 상품명, 보험종류 구분(코드), 계약상태(코드), 계좌번호, 상품명,
                            계좌번호 별 구분코드, 계좌번호 별 상태 코드
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={4}>
                          <div>보험정보</div>
                        </td>
                        <td>
                          <div>보험계약정보</div>
                        </td>
                        <td>
                          <div>
                            갱신여부, 계약체결일, 만기일자, 통화코드, 보험가입금액, 변액보험여부,
                            유니버셜 여부, 연금개시일, 연금수령주기, 대출실행 가능 상품 여부,
                            피보험자수, 피보험자번호, 피보험자명, 주피보험자 여부
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>특약정보</div>
                        </td>
                        <td>
                          <div>
                            특약명, 특약의 상태(코드), 특약만기일자, 특약가입금액, 통화코드, 특약의
                            유형
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>보험료납입 기본정보</div>
                        </td>
                        <td>
                          <div>
                            납입기간구분(코드), 납입주기(코드), 총 납입 횟수, 납입기관(코드),
                            납입일자, 납입종료일자, 납입 보험료, 통화코드, 자동대출납입 신청여부
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>납입내역</div>
                        </td>
                        <td>
                          <div>
                            납입일자, 납입연월, 납입회차, 실납입 보험료, 통화코드, 수금방법(코드)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          <div>자동차보험정보</div>
                        </td>
                        <td>
                          <div>계약정보</div>
                        </td>
                        <td>
                          <div>
                            차량번호(차대번호), 자동차보험 구분(코드), 계약자 차량명, 보험시기,
                            보험종기, 연령특약, 운전자한정특약, 자기차량손해, 자기부담금 구분(코드),
                            자기부담금 금액
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>납입정보</div>
                        </td>
                        <td>
                          <div>
                            거래일시 또는 거래일자, 거래번호, 자동차보험 보험료, 납입회차, 실납입
                            보험료, 수금방법(코드)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>보험대출상품</div>
                        </td>
                        <td>
                          <div>기본정보</div>
                        </td>
                        <td>
                          <div>대출일, 만기일, 상환방식(코드), 증권번호</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>잔액정보</div>
                        </td>
                        <td>
                          <div>통화코드, 대출잔액, 대출원금, 다음 이자 상환일</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>이자 납입 및 대출원금 상환내역</div>
                        </td>
                        <td>
                          <div>
                            거래일시 또는 거래일자, 거래번호, 통화코드, 대출원금상환액, 이자납입액,
                            이자적용시작일, 이자적용종료일, 적용이율, 이자종류(코드)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>보장정보</div>
                        </td>
                        <td>
                          <div>증권별 보장정보</div>
                        </td>
                        <td>
                          <div>
                            계약관계자구분(코드), 피보험자계약자관계(코드) 담보(코드),
                            담보특성(코드), 회사담보일련번호, 회사담보명, 담보상태(코드), 담보금액,
                            담보기간시작일자, 담보기간종료일자
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={11}>
                          <div>카드정보</div>
                        </td>
                        <td>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>보유카드정보</div>
                        </td>
                        <td>
                          <div>
                            카드번호, 카드식별자, 카드상품명, 본인/가족 구분(코드), 카드구분(코드)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>카드정보</div>
                        </td>
                        <td>
                          <div>카드기본정보</div>
                        </td>
                        <td>
                          <div>
                            교통 기능 여부, 현금카드기능 여부, 결제은행(코드), 카드브랜드(코드),
                            상품 연회비, 발급일자
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>포인트정보</div>
                        </td>
                        <td>
                          <div>포인트명, 잔여 포인트, 2개월 후 소멸예정포인트</div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          <div>월별 카드 이용정보</div>
                        </td>
                        <td>
                          <div>월별 청구정보</div>
                        </td>
                        <td>
                          <div>결제순번, 월별 청구금액, 결제일, 청구년월, 결제년월일</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>청구 상세정보</div>
                        </td>
                        <td>
                          <div>
                            사용일시 또는 사용일자, 카드식별자, 거래번호, 이용금액, 통화코드,
                            가맹점명, 가맹점 사업자등록번호, 신용판매 수수료, 전체 할부회차, 현재
                            할부회차, 할부 결제 후 잔액, 카드매입(취소)정보, 무승인매입 정보,
                            상품구분(코드)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          <div>일별 카드 이용정보</div>
                        </td>
                        <td>
                          <div>결제 기본정보</div>
                        </td>
                        <td>
                          <div>리볼빙 약정 여부, 결제순번, 결제예정일, 결제예정금액</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>리볼빙 정보</div>
                        </td>
                        <td>
                          <div>
                            신청일, 최소결제비율, 최소결제금액, 약정결제비율, 약정결제금액, 리볼빙
                            이월잔액
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>일별 카드 이용정보</div>
                        </td>
                        <td>
                          <div>승인 상세정보</div>
                        </td>
                        <td>
                          <div>
                            승인번호, 결제상태, 사용구분(신용/체크), 승인일시, 정정 또는 승인취소
                            일시, 가맹점명, 가맹점 사업자등록번호, 이용금액, 정정 후 금액, 전체
                            할부회차, 결제(승인) 국가코드, 결제(승인) 시 통화코드, 원화 환산 금액
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>카드대출정보</div>
                        </td>
                        <td>
                          <div>대출목록</div>
                        </td>
                        <td>
                          <div>단기대출 여부, 장기대출 여부</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>단기대출 이용정보</div>
                        </td>
                        <td>
                          <div>이용일시, 이용금액(취급액), 단기대출잔액, 결제예정일, 이자율</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>장기대출 이용정보</div>
                        </td>
                        <td>
                          <div>
                            대출일시 도는 대출일자, 일자의 대출회차, 대출종류, 상품명, 이용금액,
                            이자율, 만기일, 장기대출 잔액, 상환방법(코드), 상환액 중 이자
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={5}>
                          <div>금융투자정보</div>
                        </td>
                        <td>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>보유계좌정보</div>
                        </td>
                        <td>
                          <div>
                            계좌번호, 계좌명, 계좌종류(코드), 계좌개설일, 세제혜택 적용여부(계좌)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          <div>계좌정보</div>
                        </td>
                        <td>
                          <div>잔액정보</div>
                        </td>
                        <td>
                          <div>기준일자, 통화코드, 예수금, 신용 융자, 대출금</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>
                            통화코드, 거래일시 또는 거래일자, 거래번호, 거래종류(코드), 거래종류
                            상세, 거래금액, 정산금액, 거래후잔액, 종목명(상품명), 종목(코드),
                            해외주식 거래소(코드), 거래수량, 거래단가
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>상품정보</div>
                        </td>
                        <td>
                          <div>개별상품 기본정보</div>
                        </td>
                        <td>
                          <div>
                            기준일자, 상품종류(코드), 상품종류 상세, 종목(코드), 해외주식 거래소
                            코드, 종목명, 파생상품포지션구분(코드), 신용구분(코드), 세제혜택
                            적용여부, 통화코드, 매입금액, 보유수량, 평가금액
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>연금계좌정보</div>
                        </td>
                        <td>
                          <div>연금계좌 추가정보</div>
                        </td>
                        <td>
                          <div>연금가입일, 납부총액, 기출금액, 최종납입일, 연금기수령액</div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={4}>
                          <div>선불전자지급수단정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>
                            권면ID, 권면명, 가입일, 권면한도, 자동충전 등록 여부, 계정ID 목록,
                            통화코드
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>선불발행정보</div>
                        </td>
                        <td>
                          <div>선불잔액정보</div>
                        </td>
                        <td>
                          <div>총잔액, 충전포인트 잔액, 적립포인트 잔액, 적립예정, 소멸예정</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>선불발행정보</div>
                        </td>
                        <td>
                          <div>자동충전정보</div>
                        </td>
                        <td>
                          <div>
                            충전지불수단 기관(코드), 충전지불수단 식별키, 충전조건(코드), 기준날짜,
                            기준금액, 충전금액
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>선불거래내역</div>
                        </td>
                        <td>
                          <div>
                            거래유형(코드), 거래일시, 거래번호, 거래금액, 거래 후 잔액, 거래상대
                            기관(코드), 거래상대 식별키, 거래메모, 가맹점명, 상품(구매)제목,
                            상품(구매)분류 코드, 결제방법(코드)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>전자결제정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>가입일, 계정ID 노출용, 계정ID Data 호출용, 결제수단 등록 여부</div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>결제수단 등록정보</div>
                        </td>
                        <td>
                          <div>결제수단 기관(코드), 결제수단 식별키, 주된 결제수단 지정 여부</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>결제내역</div>
                        </td>
                        <td>
                          <div>
                            결제유형, 주문번호, 결제일시, 결제번호, 통화코드, 결제금액, 결제수단
                            기관(코드), 결제수단 식별키, 할부개월, 가맹점명, 가맹점 사업자등록번호,
                            거래메모, 상품(구매)제목, 상품(구매) 분류 코드, 결제방법(코드)
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={4}>
                          <div>개인형 IRP 정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>목록정보</div>
                        </td>
                        <td>
                          <div>상품명, 계좌번호</div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>기본정보</div>
                        </td>
                        <td>
                          <div>
                            계좌잔액, 계좌평가금액, 사용자부담금, 가입자부담금, 개설일, 최초 입금일,
                            최초 제도 가입일, 연금개시 시작(예정)일
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>거래일시 또는 거래일자, 거래번호, 거래구분(코드), 거래금액</div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>개별운용상품 정보</div>
                        </td>
                        <td>
                          <div>
                            개별운용상품명, 상품가입번호, 상품유형(코드), 평가금액, 납입(투자)원금,
                            보유좌수, 신규일, 만기일, 약정이자율
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={4}>
                          <div>통신업 정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>계약관리번호, 가입번호, 통신구분, 가입구분</div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>청구정보</div>
                        </td>
                        <td>
                          <div>청구금액, 납부예정일자</div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>납부정보</div>
                        </td>
                        <td>
                          <div>납부연월, 납부금액, 납부수단</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>결제정보</div>
                        </td>
                        <td>
                          <div>소액결제 이용내역</div>
                        </td>
                        <td>
                          <div>이용일시, 가맹점명, 결제금액, 결제상품명</div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>보증보험 정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>증권번호, 상품명, 보험종류 구분(코드), 계약상태(코드)</div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>기본정보</div>
                        </td>
                        <td>
                          <div>
                            계약체결일, 종료일자, 보험가입금액, 피보험자수, 피보험자명,
                            납입기간구분(코드), 총 납입 보험료
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>보험료 납입내역</div>
                        </td>
                        <td>
                          <div>납입일자, 납입회차, 실납입 보험료, 수금방법(코드)</div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>인수채권 및 금전대부 정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>채권번호, 기관분류(코드), 최초 대출기관, 채권인수(편입)일</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>인수(특수)채권정보</div>
                        </td>
                        <td>
                          <div>계좌잔액정보</div>
                        </td>
                        <td>
                          <div>직전 채권인계기관, 채무조정 여부, 상환방식, 대출잔액, 대출원금</div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div>거래(상환)내역정보</div>
                        </td>
                        <td>
                          <div>
                            거래일시 또는 거래일자, 거래번호, 거래금액, 거래 후 대출잔액, 거래금액
                            중 이자, 거래금액 중 원금
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={4}>
                          <div>선불카드 정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>
                            선불카드식별자, 선불카드 상품명, 발급일자 또는 기명일자, 액면한도 또는
                            최대충전한도
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>선불발행정보</div>
                        </td>
                        <td>
                          <div>선불잔액정보</div>
                        </td>
                        <td>
                          <div>
                            총잔액, 충전포인트 잔액, 적립포인트 잔액, 적립예정금액, 소멸예정금액
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          <div>선불거래정보</div>
                        </td>
                        <td>
                          <div>결제내역</div>
                        </td>
                        <td>
                          <div>
                            승인번호, 결제상태, 승인일시, 정정 또는 승인취소 일시, 가맹점명, 가맹점
                            사업자등록번호, 이용금액, 정정후 금액, 전체 할부회차
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역 (결제 외)</div>
                        </td>
                        <td>
                          <div>
                            거래유형(코드), 거래일시 또는 거래일자, 거래번호, 거래금액, 거래 후
                            잔액, 거래상대 기관(코드), 거래상대 식별키
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={4}>
                          <div>P2P 정보</div>
                        </td>
                        <td colSpan={2}>
                          <div>고객정보</div>
                        </td>
                        <td>
                          <div>대출계약번호, 상품유형(코드), 대출계약금액, 대출일</div>
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={3}>
                          <div>대출정보</div>
                        </td>
                        <td>
                          <div>기본정보</div>
                        </td>
                        <td>
                          <div>만기일, 대출금리, 상환방식(코드), 부동산담보 LTV 비율</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>잔액정보</div>
                        </td>
                        <td>
                          <div>대출잔액, 다음 상환회차, 다음 상환일, 예정원금, 예정이자</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>거래내역</div>
                        </td>
                        <td>
                          <div>
                            상환일시, 상환회차, 상환유형(코드), 상환금액, 상환금액 중 원금, 상환금액
                            중 이자
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>공공정보</div>
                        </td>
                        <td colSpan={3}>
                          <div>
                            국세·지방세·관세 납부 정보, 고용보험·산재보험·건강장기요양보험·연금보험
                            보험료 납부 정보, 국민연금·공무원연금 내역
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </li>
            </ul>
            <br />
            <p>
              이용자는 서비스 이용 과정에서 자신이 보유한 부동산 및 자동차 정보, 연금 정보, 현금사용
              및 소득 관련 정보, 가상화폐 보유 정보 등을 다른 기관이나 다른 서비스 연결을 통해
              조회하거나, 기타 자산과 예산 등의 정보를 직접 입력할 수 있습니다.
            </p>
            <ul>
              <li>
                - <strong>부동산 및 자동차 관련 정보</strong>에는 부동산 계약 및 시세 정보(부동산명,
                건물 유형, 동·호수를 제외한 주소, 공급/전용 면적, 매입가·전세가·보증금 및 월세,
                계약체결일, 계약기간, 연결대출정보, 희망판매가, 현재시세, 이용자가 추가 입력한
                부동산 정보 등), 자동차 정보(차량 소유자 실명, 차량번호, 차종, 연식, 연비, 주행거리,
                차대번호, 자동차 검사정보, 현재시세, 차량 출고가, 이용자가 추가 입력한 자동차 정보
                등)가 포함됩니다.
              </li>
              <li>
                - <strong>직접 입력 정보</strong>에는 이용자가 직접 입력하는 자산(현금, 계좌, 대출,
                주식, 펀드, 실물자산, 가상화폐 등)과 이용자가 직접 설정하는 예산이나 은퇴 계획 등
                정보가 포함됩니다.
              </li>
            </ul>
            <br />
            <p>
              이용자는 서비스를 이용하여 자신의 건강 상태를 확인하고 건강을 관리하기 위해 다음과
              같은 정보를 다른 기관 이나 다른 서비스 연결을 통해 서비스 내에서 조회하거나, 필요한
              정보를 직접 입력할 수 있습니다.
            </p>
            <ul>
              <li>
                - <strong>국민건강보험공단 건강검진 정보</strong>에는 건강검진
                결과정보(신체계측정보, 검진항목, 검진결과, 검진일자, 검진소견 등)가 포함됩니다.
              </li>
              <li>
                - <strong>DTC 유전자 검사 정보</strong>에는 유전자 분석 결과, 주소, 이메일, 키트
                배송 등 진행상황 정보, 서명정보, 인종(민족)정보가 포함됩니다.
              </li>
              <li>
                - <strong>삼성헬스 및 애플건강 활동 및 신체 측정 정보</strong>에는 디바이스에 저장된
                걸음 정보(걸음 수, 걷기/달리기 거리, 걸음속도, 이동거리, 일일 걸음수 요약 정보 등),
                수면시간 정보, 키, 몸무게, 디바이스 ID 정보가 포함됩니다.
              </li>
              <li>
                - <strong>직접 입력 정보</strong>에는 이용자의 신체계측정보 및 가족력 정보가
                포함됩니다.
              </li>
            </ul>
            <br />
            <p>
              이용자는 보다 나은 혜택이나 편의를 위해 서비스 내의 다음과 같은 기능들을 이용할 수
              있고, 그 과정에서 회사는 이에 필요한 추가 정보를 수집합니다.
            </p>
            <ul>
              <li>
                - <strong>뱅샐머니</strong>를 이용하는 경우 본인인증 절차에 더하여 금융기관명,
                계좌정보(계좌번호, 계좌종류, 계좌 잔액, 거래내역정보 등), 선불전자지급수단을 통한
                결제 및 송금내역 등 금융거래정보를 수집합니다. 또한, 관련 법령상 고객확인 의무를
                이행하기 위하여 이름, 영문명, 이메일, 주소, 직업, 주민등록번호, 거래목적,
                사업자금원천 등 정보를 수집합니다.
              </li>
              <li>
                - <strong>코리아크레딧뷰로 신용점수 올리기 기능</strong>을 이용하는 경우 회사가
                수집하는 정보에는 중복가입확인정보(DI), 신용점수 올리기 결과 정보가 포함됩니다.
              </li>
              <li>
                - <strong>맞춤 대출 및 주택담보 대출 금리 비교 기능</strong>을 이용하는 경우 회사가
                수집하는 정보에는 직장정보(직장인·개인사업자 여부, 정규직·계약직·프리랜서 여부,
                입사년월 등) 대출금리 비교 정보(대출거래조건, 가심사 결과 등), 담보대상 정보가
                포함됩니다.
              </li>
              <li>
                - <strong>주주모임</strong> 기능을 이용하는 경우 이용자가 자신의 프로필을 작성할 수
                있고, 특정 투자 종목에 대한 자신의 투자 내역(평균단가, 보유수량 등) 및 투자 성과,
                이에 대한 의견 등을 다른 이용자와 공유할 수 있습니다. 또한, 다른 사람의 의견에 대해
                자신의 의견을 답글로 제시할 수 있습니다.
              </li>
              <li>
                - <strong>청약 가점</strong> 기능을 이용하는 경우 출생년도, 기혼 여부, 혼인신고
                날짜, 동일 세대 주택 보유 여부, 동일 세대 주택 매매 여부 및 시점, 거주가족수,
                미혼자녀수, 청약통장 개설일 등의 정보가 포함됩니다.
              </li>
              <li>
                - <strong>이벤트 참여 및 특정 이용자 대상 설문조사, 인터뷰</strong>를 하는 경우
                회사가 수집하는 정보에는 이름, 휴대폰번호, 성별, 나이, 설문 및 인터뷰 답변 정보가
                포함됩니다.
              </li>
              <li>
                - <strong>이용자의 문의사항</strong>을 처리하기 위해 회사는 문의내용에 포함된
                개인정보를 수집할 수 있습니다.
              </li>
              <li>
                - <strong>데이터 내보내기 기능</strong>을 이용하는 경우 회사는 데이터를 받을 이메일
                주소, 이용자가 설정한 비밀번호를 수집합니다.
              </li>
            </ul>
            <br />
            <p>
              또한, 이용자가 서비스를 이용하는 과정에서 서비스를 이용하는 IP, 단말기 정보, 접속내역,
              OS 버전, 광고식별자 등의 기기정보가 자동으로 생성되거나 수집될 수 있습니다. 또한,
              회사는 이용자의 서비스(서비스를 통해 연결된 제3자의 서비스를 포함함) 이용내역을 비롯한
              행태정보를 수집합니다.
              <br />
              <br />
              이용자가 서비스 내에서 다른 기관이나 다른 서비스를 연결하여 정보를 조회하는 경우 해당
              기관 또는 서비스에 접근하기 위해 로그인 정보(ID, 비밀번호)나 공동인증서가 사용될 수
              있습니다. 이러한 정보는 본 개인정보처리방침에 명시되지 않은 이상 회사의 서버에
              전송되거나 저장되지 않고 오로지 이용자의 단말기에만 저장되어 안전하게 처리됩니다.
              회사는 (주)코드에프가 제공하는 스크래핑 모듈 서비스를 이용하여 이용자가 다른 기관 또는
              서비스 내 자신의 정보를 편리하게 조회할 수 있도록 합니다.
              <br />
              <br />
              이용자는 관련 법령이 허용하는 범위에서 전송요구권을 행사하거나, 제3자 제공에
              동의함으로써 제3자가 보유하고 있는 자신의 정보를 회사에 전송하거나, 제공하도록 할 수
              있습니다. 이러한 경우 회사는 관련 법령에서 정한 방식으로 해당 정보를 수집하고,
              처리합니다.
              <br />
              <br />
            </p>
          </li>
          <li>
            <h4>회사가 개인정보를 처리하는 목적</h4>
            <p>회사는 다음과 같은 목적을 위해 이용자의 개인정보를 처리합니다.</p>
            <ul>
              <li>
                -{' '}
                <strong>
                  회사는 이용자에게 자산관리 서비스를 제공하기 위해 이용자의 개인정보를 처리합니다.
                </strong>{' '}
                예를 들어, 이용자가 자신의 다양한 자산 내역을 통합 조회하고, 가계부를 통해 지출을
                합리적으로 관리할 수 있도록 합니다. 또한, 투자 내역과 분석 결과를 다른 이용자들과
                공유하고, 자신의 생각이나 의견을 나눌 수 있도록 합니다. 자신의 신용평점을 조회하고,
                이를 관리할 수 있도록 하며, 은퇴를 대비하여 노후를 설계할 수 있도록 돕습니다. 이
                과정에서 제3자가 보유하는 이용자의 정보를 가져오거나, 제3자가 제공하는 서비스를
                이용하기 위해 이용자의 개인정보가 처리될 수 있습니다.
              </li>
              <li>
                -{' '}
                <strong>
                  회사는 이용자에게 보다 적합한 금융상품을 소개하거나 추천하기 위하여 이용자의
                  개인정보를 처리합니다.
                </strong>{' '}
                예를 들어, 이용자가 사용하는 신용카드 혜택과 지출 내역을 분석하여 보다 나은 혜택을
                받을 수 있는 다른 신용카드를 추천하거나, 이용자의 정보를 바탕으로 가장 유리한 조건의
                대출 상품을 소개합니다. 그 외에도 관련 법령에 따라 금융상품을 추천하거나 소개할 때
                이용자의 정보를 바탕으로 이용자에게 도움이 되는 맞춤 상품을 우선적으로 제안합니다.
              </li>
              <li>
                -{' '}
                <strong>
                  회사는 이용자가 자신의 건강 정보를 이해하고, 건강을 관리할 수 있도록 이용자의
                  개인정보를 처리합니다.
                </strong>{' '}
                예를 들어, 이용자의 건강검진내역, 단말기 내 저장된 활동 및 신체 측정 정보, DTC
                유전자 검사 결과를 바탕으로 이용자의 건강 상태 등을 쉽게 이해할 수 있도록 하며,
                다양한 정보를 바탕으로 건강한 습관이나 운동, 상품이나 서비스 등을 추천하여 이용자의
                건강관리를 돕습니다.
              </li>
              <li>
                -{' '}
                <strong>
                  회사는 서비스 내 다양한 기능들을 이용자가 편리하게 이용할 수 있도록 하며, 서비스를
                  개선하고, 새로운 기능을 개발하기 위해 이용자의 개인정보를 처리합니다.
                </strong>{' '}
                회사는 신용정보의 이용 및 보호에 관한 법률상 본인신용정보관리업의 겸영업무 및
                부수업무를 수행합니다. 예를 들어, 이용자가 뱅샐머니와 같은 송금 서비스나 뱅크샐러드
                인증서 서비스를 안전하게 이용할 수 있도록 이용자의 개인정보를 처리합니다. 그 외에도
                이용자의 개인정보를 분석하여 연말정산 환급액과 같은 유용한 정보나 조언을 제공하고,
                청약 가점을 계산하고, 제휴카드를 통해 보다 나은 혜택을 받을 수 있도록 합니다. 또한,
                회사는 이용자의 서비스 이용기록, 접속빈도, 연결한 데이터, 통계자료 및 이용자 의견 등
                다양한 정보를 바탕으로 기존 기능을 개선하거나, 새로운 기능을 개발하여 이용자에게
                보다 나은 혜택과 편의를 제공합니다.
              </li>
              <li>
                -{' '}
                <strong>
                  회사는 이용자가 자신의 정보를 실질적으로 관리하고, 활용할 수 있는 기능을 제공하기
                  위해 이용자의 개인정보를 처리합니다.
                </strong>{' '}
                회사는 이용자가 자신이 연결하거나, 직접 입력한 정보를 확인하고 이를 관리할 수 있도록
                합니다. 또한, 이용자가 자신의 정보를 제3자에게 전송하거나, 직접 다운로드 받거나,
                이를 거래할 수 있는 기능을 제공할 수 있습니다. 회사는 이용자가 자신의 정보를
                실질적으로 통제할 수 있는 기능을 제공하기 위해 이용자의 개인정보를 처리합니다.
              </li>
              <li>
                -{' '}
                <strong>
                  회사는 안전한 서비스 이용 환경을 지키고, 회사와 이용자들의 정당한 이익을 보호하기
                  위해 이용자의 개인정보를 처리합니다.
                </strong>{' '}
                예를 들어, 회사는 이용자 식별 및 본인 확인, 연령 확인, 타인 명의 또는 접근매체 사용
                여부 확인, 회원탈퇴 의사 확인, 회원관리, 민원 처리, 분쟁 해결 등을 위해 이용자의
                개인정보를 처리합니다. 또한, 관련 법령 및 이용약관을 위반하는 이용자에 대한 이용
                제한 조치, 재가입 제한 조치, 부정한 서비스 이용 행위를 탐지하고 예방하기 위한 조치,
                다양한 금융사기 예방, 서비스 보안 강화 및 개인정보 처리 방식의 개선 등을 위해
                이용자의 개인정보를 처리합니다.
              </li>
              <li>
                -{' '}
                <strong>
                  회사는 이용자에게 서비스 이용에 관한 다양한 정보를 제공하고, 기타 유용한 정보와
                  광고를 제공하기 위해 이용자의 개인정보를 처리합니다.
                </strong>{' '}
                예를 들어, 회사는 새로운 기능 추가나 기능 변경에 대한 안내, 서비스 이용 방식 변경에
                대한 통지, 서비스 중단 및 장애 안내, 이벤트 소개, 상품이나 서비스 광고 등 이용자에게
                필요하고 유용한 정보를 제공하기 위하여 이용자의 개인정보를 처리합니다.
              </li>
              <li>
                -{' '}
                <strong>
                  회사는 서비스 자체를 홍보하는데 이용자의 개인정보를 활용할 수 있습니다.
                </strong>{' '}
                예를 들어, 서비스 홍보 자료 내에 특정 이용자의 의견 등 개인정보가 포함될 수
                있습니다.
              </li>
              <li>
                -{' '}
                <strong>
                  회사는 관련 법령상 의무를 준수하기 위하여 이용자의 개인정보를 처리할 수 있습니다.
                </strong>{' '}
                예를 들어, 특정 금융거래정보의 보고 및 이용 등에 관한 법률상의 의무를 준수하기
                위하여 이용자의 정보를 수집·이용하며, 사법기관의 정당한 요청 및 적법한 근거에 따라
                이용자 정보를 제공할 수 있습니다.
              </li>
            </ul>
            <br />
            <p>
              회사는 이용자의 개인정보를 가명화하여 관련 법령에서 정한 바에 따라 처리할 수 있습니다.
              또한, 수집 목적과 합리적 관련성이 있는 범위에서 관련 법령에 따라 이용자의 추가적인
              동의 없이 이용자의 개인정보를 이용하거나 제공할 수 있습니다. 나아가 이용자의
              개인정보를 모두 익명처리한 정보의 집합물을 관련 법령에 따라 제공하거나 처리할 수
              있습니다.
            </p>
            <br />
          </li>
          <li>
            <h4>제3자에게 제공하는 정보</h4>
            <p>
              이용자가 전송요구를 하거나, 제휴 서비스를 이용하는 경우, 회사는 필요한 범위에서
              이용자의 동의를 받아 이용자의 개인정보를 정보제공자 및 제휴 서비스 제공자 등에게
              제공합니다. 이용자의 개인정보를 제3자에게 제공하는 경우는 다음과 같습니다.
            </p>
            <table>
              <tbody>
                <tr>
                  <th className={styles.width20}>제공받는 자</th>
                  <th>제공 목적</th>
                  <th>제공 항목</th>
                  <th>보유 및 이용 기간</th>
                </tr>
                <tr>
                  <td>
                    <div>이용자가 서비스 내에서 개인신용정보 전송요구를 한 정보제공자</div>
                  </td>
                  <td>
                    <div>본인확인 및 개인신용정보 전송</div>
                  </td>
                  <td>
                    <div>전자서명, 동일인 식별 연계정보(CI), 인증서, 전송요구서</div>
                  </td>
                  <td>
                    <div>제공목적 달성시까지</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>한국신용정보원</div>
                  </td>
                  <td>
                    <div>마이데이터 서비스 가입현황 및 전송요구 내역 조회</div>
                  </td>
                  <td>
                    <div>
                      회원가입여부, 서비스목록수, 서비스목록, 클라이언트ID, 전송요구내역수,
                      전송요구내역목록, 정보제공자 기관코드, 권한범위, 전송요구일자,
                      전송요구종료시점
                    </div>
                  </td>
                  <td>
                    <div>제공목적 달성시까지</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>코리아크레딧뷰로(주)</div>
                  </td>
                  <td>
                    <div>
                      개인신용평점 등 신용정보 조회 및 관리 서비스 (신용정보 변동 알림, 신용평점
                      올리기 기능 등 포함)
                    </div>
                  </td>
                  <td>
                    <div>
                      <a href="https://policies.banksalad.com/%EC%8B%A0%EC%9A%A9/%EC%8B%A0%EC%9A%A9%EC%A0%90%EC%88%98-%EB%A0%88%EB%B2%A8%EC%97%85/%EA%B8%88%EC%9C%B5%EB%A7%88%EC%9D%B4%EB%8D%B0%EC%9D%B4%ED%84%B0/%EA%B0%9C%EC%9D%B8(%EC%8B%A0%EC%9A%A9)%EC%A0%95%EB%B3%B4-%EC%A0%9C3%EC%9E%90-%EC%A0%9C%EA%B3%B5-%EB%8F%99%EC%9D%98%EC%84%9C(%EA%B8%88%EC%9C%B5-%EB%A7%88%EC%9D%B4%EB%8D%B0%EC%9D%B4%ED%84%B0)(%ED%95%84%EC%88%98)/">
                        제공상세항목
                      </a>
                    </div>
                  </td>
                  <td>
                    <div>
                      각 신용관리 서비스 (KCB의 올크레딧) 탈퇴시까지 (단, 관련 법령상 일정 기간
                      보유해야 하는 경우 해당 기간 동안)
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>맞춤 대출 비교 서비스 제휴 금융사 ({prequalLenderNames})</div>
                  </td>
                  <td>
                    <div>
                      맞춤 대출 비교 서비스 제공을 위한 이용자 개인(신용)정보 조회 및 대출거래 가능
                      여부 확인, 서비스 관련 민원 및 고객문의 응대
                    </div>
                  </td>
                  <td>
                    <div>
                      이름, 생년월일, 휴대폰번호, 직업(직장인, 개인사업자, 무직), 직장명,
                      고용형태(정규직, 계약직, 프리랜서), 입사년월, 연소득
                    </div>
                  </td>
                  <td>
                    <div>
                      동의 철회시 또는 제공 목적 달성시까지 (단, 관련 법령에 따라 보존할 필요가 있는
                      경우 해당 보존 기간까지)
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>주택담보 대출 비교 서비스 제휴 금융사 ({mortgageLenderNames})</div>
                  </td>
                  <td>
                    <div>
                      주택담보 대출 비교 서비스 제공을 위한 이용자 개인(신용)정보 조회 및 대출거래
                      가능 여부 확인, 서비스 관련 민원 및 고객문의 응대
                    </div>
                  </td>
                  <td>
                    <div>
                      이름, 생년월일, 성별, 휴대폰번호, 동일인 식별 연계정보(CI), 조회 주택 정보,
                      대출목적, 직업, 신용점수
                    </div>
                  </td>
                  <td>
                    <div>
                      동의 철회시 또는 제공 목적 달성시까지 (단, 관련 법령에 따라 보존할 필요가 있는
                      경우 해당 보존 기간까지)
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>금융결제원, 오픈뱅킹 공동업무 참여 금융기관</div>
                  </td>
                  <td>
                    <div>
                      오픈뱅킹 출금이체, 입출금이체 관련 서비스 제공, 오픈뱅킹 출금이체 관련 통지 등
                      의무이행 및 문의대응
                    </div>
                  </td>
                  <td>
                    <div>이름, 생년월일, 성별, 동일인 식별 연계정보(CI), 금융기관명, 계좌번호</div>
                  </td>
                  <td>
                    <div>
                      회원탈퇴일로부터 5년 (단, 서비스 해지 이후에는 금융사고 조사, 분쟁해결,
                      민원처리, 법령상 의무이행, 부정이용 방지 업무 수행 등의 목적을 위하여 보유함)
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>(주)랩지노믹스</div>
                  </td>
                  <td>
                    <div>
                      이용자확인, 유전자 검사 시행 및 결과지 제공, 유전자 분석 결과 서비스 앱 내
                      제공, 유전자검사 민원·문의 대응, 인종별 유전정보 차이에 따른 정확한 유전자검사
                      수행
                    </div>
                  </td>
                  <td>
                    <div>
                      이름, 휴대폰번호, 동일인 식별 연계정보(CI), 성별, 생년월일, 주소, 이메일, 키트
                      배송 등 진행상황 정보, 서명정보, 인종(민족)정보
                    </div>
                  </td>
                  <td>
                    <div>10년 또는 철회요구시까지</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>(주)에브리플레이</div>
                  </td>
                  <td>
                    <div>
                      유전자 검사 결과
                      에브리핏 서비스 내 제공
                    </div>
                  </td>
                  <td>
                    <div>
                      동일인 식별 연계정보(CI), 운동⋅건강⋅식습관 유전자 검사 결과
                    </div>
                  </td>
                  <td>
                    <div>
                      목적 달성 또는 회원탈퇴 시까지
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>더베스트금융서비스(주)</div>
                  </td>
                  <td>
                    <div>
                      보험 보장 분석 및 추천
                    </div>
                  </td>
                  <td>
                    <div>
                      아이디, 고객명, 성별, 보험 연령, 연락처, 카카오톡 상담내역, 보유한 보험 관련 정보, 질병별 보장 분석 정보
                    </div>
                  </td>
                  <td>
                    <div>
                      상담 취소 시 즉시, 상담종료일로부터 1개월 후 또는 철회 시(단, 관련 법령에 따라 보존할 필요가 있는 경우 해당 보존 기간까지)
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
              한편, 요금정산을 위해 필요한 경우나 법령의 규정에 따라 개인정보를 제출하여야 하는
              의무가 있는 경우, 통계작성·과학적 연구·공익적 기록 보존 등의 목적으로 이용자를 식별할
              수 없는 형태로 정보를 제공하는 경우, 수집목적과 합리적 관련성이 있는 범위에서 관련
              법령에 따라 정보를 제공하는 경우 등에는 이용자의 별도 동의 없이 개인정보를 제공할 수
              있습니다.
            </p>
            <br />
          </li>
          <li>
            <h4>개인정보의 처리위탁</h4>
            <p>
              회사는 이용자에게 서비스를 제공하는데 필요한 일부 업무의 처리를 제3자에게 위탁하고
              있으며, 다음과 같이 일부 위탁 업무에는 개인정보의 처리가 수반됩니다.
            </p>
            <table>
              <tbody>
                <tr>
                  <th>수탁사</th>
                  <th>위탁 업무 내용</th>
                </tr>
                <tr>
                  <td>
                    <div>코리아크레딧뷰로 주식회사</div>
                  </td>
                  <td>
                    <div>본인확인업무 및 실명확인업무</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>인비즈넷주식회사</div>
                  </td>
                  <td>
                    <div>ARS 인증업무</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>GS 네오텍</div>
                  </td>
                  <td>
                    <div>고객센터 콜센터 시스템 운영 및 유지보수 업무</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>(주)씨엘엠앤에스</div>
                  </td>
                  <td>
                    <div>자동차 시세 정보 조회</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>아마존웹서비시즈코리아 유한책임회사</div>
                  </td>
                  <td>
                    <div>클라우드컴퓨팅 서비스</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>더치트(주)</div>
                  </td>
                  <td>
                    <div>금융사기 방지를 위한 사기계좌 조회</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>(주)코스콤</div>
                  </td>
                  <td>
                    <div>전자금융거래내역 전송요구 (거점)중계기관</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>(주)쿠콘</div>
                  </td>
                  <td>
                    <div>계좌인증 업무</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>엠비아이솔루션(주)</div>
                  </td>
                  <td>
                    <div>채팅 상담솔루션(해피톡) 운영 및 유지보수 업무</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              회사는 이용자의 개인정보 처리 업무 중 일부를 아래와 같이 국외 사업자에게 위탁하고
              있습니다.
              <br />
              서비스 이용 도중 개인정보의 국외이전을 거부하시려면 회원탈퇴를 진행하시거나 고객센터를 통해서 요청하실 수 있습니다. 
              다만, 국외이전을 거부하실 경우 서비스 이용이 불가능합니다.
            </p>
            <table>
              <tbody>
                <tr>
                  <th>이전 받는 자</th>
                  <th className={styles.width10}>이전 되는 국가</th>
                  <th>이전 일시/방법</th>
                  <th>이전 항목</th>
                  <th>이용 목적</th>
                  <th>이용 기간</th>
                </tr>
                <tr>
                  <td>
                    <div>Amplitude</div>
                  </td>
                  <td>
                    <div>미국1)</div>
                  </td>
                  <td rowSpan={6}>
                    <div>서비스 이용 시점에 네트워크를 통한 전송</div>
                  </td>
                  <td>
                    <div>User ID, 방문 일시, 서비스 이용 기록, 불량 이용기록, 기기정보 등</div>
                  </td>
                  <td>
                    <div>앱 사용 현황 분석</div>
                  </td>
                  <td rowSpan={6}>
                    <div>위탁 목적 달성시 까지</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Google Cloud Platform (Firebase)</div>
                  </td>
                  <td>
                    <div>미국2)</div>
                  </td>
                  <td>
                    <div>User ID, 방문 일시, 서비스 이용 기록, 불량 이용기록, 기기정보 등</div>
                  </td>
                  <td>
                    <div>앱 사용 이력 정보 관리</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Braze</div>
                  </td>
                  <td>
                    <div>미국3)</div>
                  </td>
                  <td>
                    <div>User ID, 방문 일시, 서비스 이용 기록, 기기정보 등</div>
                  </td>
                  <td>
                    <div>고객 분석에 따른 메시징</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Instabug</div>
                  </td>
                  <td>
                    <div>미국4)</div>
                  </td>
                  <td rowSpan={3}>
                    <div>User ID, 방문 일시, 서비스 이용 기록, 기기정보 등</div>
                  </td>
                  <td rowSpan={3}>
                    <div>크래시 수집 및 분석</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Sentry</div>
                  </td>
                  <td>
                    <div>미국5)</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>Datadog</div>
                  </td>
                  <td>
                    <div>미국6)</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <i>* 주소 및 연락처</i>
            <ol>
              <li>1) 631 Howard Street, San Francisco, CA 94127 USA (+1 -415-231-2353)</li>
              <li>2) 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA</li>
              <li>3) 330 West 34th Street 18th Floor, New York, NY 10001 USA</li>
              <li>4) 855 El Camino Real St. Suite 13A-111, Palo Alto, CA 94301 USA</li>
              <li>5) 45 Fremont Street 8th Floor, San Francisco, CA 94105 USA</li>
              <li>6) 620 8th Avenue, Floor 45, New York, NY 10018-1741 USA</li>
            </ol>
            <br />
          </li>
          <li>
            <h4>개인정보 보유기간 및 파기</h4>
            <p>
              회사는 이용자가 회원탈퇴하는 경우 관련 법령에 따라 이용자의 개인정보를 파기합니다.
              <br />
              <br />
              다만, 회사는 서비스 남용 및 부정이용방지, 탈퇴 후 분쟁 및 민원처리, 재가입제한 등 회원
              관리, 관련 법령 준수 등을 위하여 본인확인 정보, 회원가입 및 탈퇴일시, 탈퇴사유,
              부정이용기록, 민원처리 내역을 회원탈퇴 후 5년까지 보유합니다. 또한, DTC 유전자 검사의
              정산 업무 및 중복신청 방지를 위해 키트 배송 등 검사 진행 상황 정보는 회원탈퇴 후
              18개월 동안 보유합니다.
              <br />
              <br />
              회사는 관련 법령이 요구하는 경우 일정한 기간 동안 이용자의 개인정보를 안전하게
              보관합니다. 회사가 법령에 따라 개인정보를 보관해야 하는 경우는 다음과 같습니다.
            </p>
            <ul>
              <li>
                - 전자상거래 등에서의 소비자보호에 관한 법률
                <ul>
                  <li>- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                  <li>- 계약 또는 청약철회 등에 관한 기록 : 5년</li>
                  <li>- 대금결제 및 재화등의 공급에 관한 기록 : 5년</li>
                  <li>- 표시/광고에 대한 기록 : 6개월</li>
                </ul>
              </li>
              <li>
                - 전자금융거래법
                <ul>
                  <li>- 전자금융에 관한 기록 : 5년</li>
                </ul>
              </li>
              <li>
                - 특정금융거래정보의 보고 및 이용 등에 관한 법률
                <ul>
                  <li>- 고객확인정보 : 5년</li>
                </ul>
              </li>
              <li>
                - 신용정보의 이용 및 보호에 관한 법률
                <ul>
                  <li>- 금융거래 관계 설정 및 거래내역 등 금융거래정보 : 5년</li>
                </ul>
              </li>
            </ul>
            <br />
            <p>
              회사는 개인정보를 파기해야 하는 경우 해당 개인정보를 재생할 수 없는 방법으로
              파기하거나, 더 이상 특정 개인인 정보주체를 알아볼 수 없도록 해당 정보를
              익명처리합니다. 회사가 관련 법령에 따라 일정 기간 보관하는 개인정보의 경우에도 해당
              기간이 도과하면 위와 같은 방법으로 안전하게 파기하거나 익명처리합니다.
            </p>
            <br />
          </li>
          <li>
            <h4>이용자와 법정대리인의 권리 행사</h4>
            <p>
              이용자는 회사가 보유한 이용자의 개인정보에 대하여 열람·정정·삭제·처리정지를 요구할 수
              있습니다. 회사는 이용자 본인임을 확인하기 위해 신분증 사본을 요청하거나 기타 기술적인
              방식을 통해 본인확인을 합니다. 또한, 회사는 이러한 요청을 처리하기 위해 필요한 서식을
              마련하고, 이용자로 하여금 이를 작성하여 제출할 것을 요청할 수 있습니다.
              <br />
              <br />
              이용자는 ‘전체’ 탭 ‘<strong>연결관리</strong>’ 메뉴를 이용하여 현재 연결된 기관과
              전송되는 정보를 확인할 수 있고, ‘전체’ 탭 오른쪽 상단의 ‘설정’(톱니바퀴 아이콘)을 눌러
              자신의 ‘<strong>데이터 활용내역</strong>’을 조회할 수도 있습니다. 이용자는 앱 내
              고객센터를 통해 ‘데이터 활용내역’을 매년 앱 알림으로 통지해 줄 것을 요청할 수
              있습니다.
              <br />
              <br />
              나아가, 이용자는 ‘설정’ 내 ‘<strong>기본 정보</strong>’ 메뉴에서 이름 및 휴대폰 번호를
              수정할 수 있으며, ‘<strong>연결관리</strong>’ 메뉴에서 연결된 기관의 연결을 해제하여
              더 이상 정보가 전송되지 않도록 할 수 있습니다. 또한, 언제든지 서비스를 탈퇴함으로써
              자신의 개인정보가 파기 또는 익명처리 되도록 할 수 있습니다.
              <br />
              <br />
              또한 이용자는 ‘설정’ 내 ‘<strong>데이터 내보내기</strong>’ 메뉴를 통하여 ‘내 수입/지출
              내역’과 ‘자산 상태와 현금 흐름 분석’ 데이터를 편리하게 다운로드 받을 수 있습니다.
              <br />
              <br />
              서비스 이용 과정에서 연결된 개인정보의 정정 또는 삭제는 해당 정보를 보유하고 있는
              기관에 직접 하는 것이 효과적입니다. 다만, 회사는 기술적으로 가능한 경우 이러한 정보를
              정정하거나 삭제할 수 있도록 이용자를 지원합니다.
              <br />
              <br />
              이용자의 법정대리인은 이용자를 대리하여 이용자의 개인정보 열람·정정·삭제·처리정지를
              요구할 수 있습니다. 회사는 이에 필요한 서식을 마련하고, 법정대리인에게 해당 서식의
              작성 및 대리권을 증명할 수 있는 자료의 제출을 요청할 수 있습니다. 이용자나
              법정대리인은 서비스 앱 내 ‘고객센터’를 이용하거나 아래 개인정보 보호업무 수행 부서로
              연락하여 이러한 권리를 행사할 수 있습니다.
            </p>
            <br />
          </li>
          <li>
            <h4>자동 정보 수집 장치의 설치, 운영 및 거부에 관한 사항</h4>
            <p>
              회사는 이용자에게 맞춤서비스를 제공하고 서비스 이용환경을 개선하기 위해 이용자의
              서비스 이용 정보를 저장하고, 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
              <br />
              <br />
              이용자가 웹사이트에 방문할 경우 웹사이트는 이용자의 디바이스에 저장되어 있는 쿠키를
              읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공합니다. 쿠키는 이용자가
              웹사이트를 방문할 때, 이용자의 편리성을 높이기 위해 사용됩니다.
              <br />
              <br />
              이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저의
              옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,
              아니면 모든 쿠키의 저장을 거부할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우 이용자의
              설정 정보를 저장해야 하는 서비스는 이용에 어려움이 있을 수 있습니다.
            </p>
            <br />
          </li>
          <li>
            <h4>개인정보의 안전성 확보</h4>
            <p>
              회사는 이용자의 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보
              조치를 취하고 있습니다. 회사는 개인정보 보호법, 신용정보의 이용 및 보호에 관한 법률,
              전자금융거래법 등 관련 법령에서 요구하는 기술적·관리적 보호조치 의무를 준수하며,
              이용자의 개인정보가 안전하게 보호될 수 있도록 최선의 노력을 기울이고 있습니다.
              <br />
              <br />
              나아가 회사는 개인정보 보호를 위한 내부 정책을 수립하여 시행하고 있으며, 서비스의 개발
              및 운영 과정에서 개인정보가 충분히 보호되는지 점검하고 있습니다. 또한, 회사의 모든
              임직원이 이용자의 개인정보 보호를 최우선으로 생각할 수 있도록 전사적인 교육 및 실태
              점검을 정기적으로 진행합니다. 회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의
              개인정보가 유출되거나 훼손되는 것을 막기 위해 개인정보 접근권한 관리 및 암호화 등의
              기술적 보호조치도 취하고 있습니다.
            </p>
            <br />
          </li>
          <li>
            <h4>개인정보 관련 고충사항 처리</h4>
            <p>
              회사는 개인정보 처리를 비롯한 이용자 고충사항을 신속하게 처리하기 위하여 서비스 앱
              내에 ‘고객센터’를 운영하고 있습니다. 이용자는 ‘고객센터’를 통해 궁금한 사항을
              문의하며, 필요한 조치를 요청할 수 있습니다.
              <br />
              <br />
              또한, 회사는 개인정보 보호 업무를 수행하는 부서를 두고 있습니다. 고객센터를 통한 고충
              처리가 충분하지 않을 경우 아래 부서로 연락을 취할 수 있습니다.
              <br />
              <br />
              개인정보 보호책임자 및 신용정보관리 보호인
              <br />
              성명 : 이정운
              <br />
              부서 : Corporate Affairs Group
              <br />
              연락처 : <a href="mailto:privacy@banksalad.com">privacy@banksalad.com</a>
              <br />
              <br />
              <span className={styles.textSmall}>
                개인신용정보 열람 청구 및 개인신용정보 관련 고충처리 담당부서
              </span>
              <br />
              부서명 : 준법지원팀 / 고객경험팀
              <br />
              연락처 : <a href="mailto:privacy@banksalad.com">privacy@banksalad.com</a>
              <br />
              <br />
              기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의하시기 바랍니다.
              <br />
              <ul>
                <li>
                  - 개인정보침해 신고센터(
                  <a href="https://privacy.kisa.or.kr" target="_blank" rel="noopener noreferrer">
                    https://privacy.kisa.or.kr
                  </a>
                  , 국번없이 118)
                </li>
                <li>
                  - 대검찰청 사이버수사과 (
                  <a href="https://spo.go.kr" target="_blank" rel="noopener noreferrer">
                    https://spo.go.kr
                  </a>
                  , 국번없이 1301)
                </li>
                <li>
                  - 경찰청 사이버안전국 (
                  <a href="https://ecrm.cyber.go.kr" target="_blank" rel="noopener noreferrer">
                    https://ecrm.cyber.go.kr
                  </a>
                  , 국번없이 182)
                </li>
                <li>
                  - 개인정보 분쟁조정위원회(
                  <a href="https://www.kopico.go.kr" target="_blank" rel="noopener noreferrer">
                    https://www.kopico.go.kr
                  </a>
                  , 국번없이 1833-6972)
                </li>
              </ul>
            </p>
            <br />
          </li>
          <li>
            <h4>개별 서비스를 위한 개인정보처리방침</h4>
            <p>
              회사는 관련 법령 및 규제기관의 명령 등을 준수하기 위하여 회사가 제공하는 특정 서비스에
              대하여 별도의 개인정보처리방침을 마련하여 운영할 수 있습니다. 회사가 제공하는 별도의
              개인정보처리방침은 다음과 같습니다. 해당 서비스와 관련된 개인정보 처리 내역은 해당
              개인정보처리방침을 참고하여 주시기 바랍니다.
            </p>
            <ul>
              <li>
                <a href="https://policies.banksalad.com/banksaladcertificate/%EB%B1%85%ED%81%AC%EC%83%90%EB%9F%AC%EB%93%9C-%EC%9D%B8%EC%A6%9D%EC%84%9C-%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8">
                  뱅크샐러드 인증서 개인정보처리방침
                </a>
              </li>
            </ul>
          </li>
          <li>
            <h4>개인정보처리방침 변경</h4>
            <p>
              회사는 개인정보처리방침 내용의 변경이 있을 경우 공지사항을 통해 사전에 고지하고 시행하겠습니다.
            </p>
          </li>
        </ol>
        <br />
        <br />
        <p>최종개정일 : 2023. 11. 15.</p>
      </div>
    </div>
  );
};
