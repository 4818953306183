import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class AdviceInvestmentAssetValuationSnapshot extends Entity {
    valuation: Amount;
    principal: Amount;
    baseDatetime: Date;
    startDatetime: Date;
    endDatetime: Date;

    constructor(
        valuation: Amount,
        principal: Amount,
        baseDatetime: Date,
        startDatetime: Date,
        endDatetime: Date
    ) {
        super();
        this.valuation = valuation;
        this.principal = principal;
        this.baseDatetime = baseDatetime;
        this.startDatetime = startDatetime;
        this.endDatetime = endDatetime;
    }
}
