import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardNonTemporalComputationResult } from 'core/entity/card/non-temporal-computation-result';
import { CardMapper } from 'data/http/mapper/card';
import { CardAmountMapper } from 'data/http/mapper/card/amount';
import { CardProfitMapper } from 'data/http/mapper/card/profit';

export class CardNonTemporalComputationResultMapper implements NetworkResponseMapper<CardNonTemporalComputationResult> {
    fromJson(json: any): CardNonTemporalComputationResult {
        return new CardNonTemporalComputationResult(
            new CardMapper().fromJson(json.card),
            List(json.profits.map((profit: any) =>
                new CardProfitMapper().fromJson(profit)
            )),
            new CardAmountMapper().fromJson(json.summary)
        )
    }

}
