import { SVGApiProvider } from 'data/http/api/cdn/svg';
import { GatewayHttpProviderDependencies } from 'application/@context/http-providers/gateway';
import { BaseHttpProviderDependencies } from 'application/@context/http-providers/base';
import { AxiosInstance } from 'axios';
import { ZendeskProviderDependencies } from './zendesk';
import { PromotionApiProvider } from 'data/http/api/cdn/promotion';

export class HttpProviderDependencies {
    base: BaseHttpProviderDependencies;
    gateway: GatewayHttpProviderDependencies;
    zendesk: ZendeskProviderDependencies;
    svg: SVGApiProvider;
    promotion: PromotionApiProvider;

    constructor(
        gatewayInstance: AxiosInstance,
        baseInstance: AxiosInstance,
        cdnInstance: AxiosInstance,
        serviceCenterInstance: AxiosInstance,
        ignoredLegalService: boolean
    ) {
        this.base = new BaseHttpProviderDependencies(baseInstance);
        this.zendesk = new ZendeskProviderDependencies(serviceCenterInstance);
        this.gateway = new GatewayHttpProviderDependencies(gatewayInstance, ignoredLegalService);
        this.svg = new SVGApiProvider(cdnInstance);
        this.promotion = new PromotionApiProvider(cdnInstance);
    }
}
