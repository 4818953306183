import { UserCardCreditLimit } from 'core/entity/personal-finance/card-credit-limit';
import { UserCardRepositoryType } from 'core/repository/personal-finance/card';
import { UseCase } from 'core/use-case';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class GetUserCardCreditLimits extends UseCase<List<UserCardCreditLimit>> {
    private repository: UserCardRepositoryType;

    constructor(repository: UserCardRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<UserCardCreditLimit>> {
        return this.repository.getUserCardCreditLimits();
    }

    protected validate(): boolean {
        return true;
    }

}
