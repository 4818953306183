import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { FundCompanySummary } from 'core/entity/personal-finance/fund-company/summary';

export class UserFundUpdateSpec extends Entity {
    company?: FundCompanySummary;
    nickname?: string;
    description?: string;
    principal?: number;
    valuation?: number;
    quantity?: number;
    createdAt?: Date;
    expiredAt?: Date;
    hidden?: boolean;
    deletedStatus?: Asset.DeletedStatus;

    constructor(
        company: FundCompanySummary = null,
        nickname: string = null,
        description: string = null,
        principal: number = null,
        valuation: number = null,
        quantity: number = null,
        createdAt: Date = null,
        expiredAt: Date = null,
        hidden: boolean = null,
        deletedStatus: Asset.DeletedStatus = null
    ) {
        super();
        this.company = company;
        this.nickname = nickname;
        this.description = description;
        this.principal = principal;
        this.valuation = valuation;
        this.quantity = quantity;
        this.createdAt = createdAt;
        this.expiredAt = expiredAt;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
    }
}
