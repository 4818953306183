import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';

import { EventCancerMiniInsuranceLanding } from './landing';
import { EventCancerMiniInsuranceC } from './case-c';

export const MiniCancerRoute = ({ match }: RouteComponentProps<any>) => (
    <div>
        <Switch>
            <Route path={ `${match.url}/case-a` } component={ EventCancerMiniInsuranceLanding } />
            <Route path={ `${match.url}/case-b` } component={ EventCancerMiniInsuranceLanding } />
            <Route path={ `${match.url}/case-c` } component={ EventCancerMiniInsuranceC } />
        </Switch>
    </div>
);

