import { SpiderData } from 'core/entity/personal-finance/spider-data';
import { NetworkResponseMapper } from 'data/http/mapper';

export class SpiderDataMapper implements NetworkResponseMapper<SpiderData> {
    fromJson(json: any): SpiderData {
        return new SpiderData(
            json.name,
            json.response_contained
        )
    }

}
