import { Entity } from 'core/entity';
import { List } from 'immutable';

export interface Question {
    readonly topic: string
    readonly essential: boolean;
    readonly clearable: boolean;
    toValue(): string;
    isValid(): boolean;
    clear(): Question;
}

export abstract class MultiAnswerQuestion<T> implements Question {
    readonly abstract topic: string;
    abstract values: List<T>;
    readonly abstract essential: boolean;
    readonly abstract clearable: boolean;

    abstract isValid(): boolean
    abstract toValueOf(item: T): string
    abstract remove(item: T): MultiAnswerQuestion<T>

    clear(): Question {
        throw new Error("Method not supported.");
    }

    toValue(): string {
        throw new Error("Method not supported.");
    }
}

export interface EntityMappable<T extends Entity> {
    from(entity: T): void
    toEntity(): T
}
