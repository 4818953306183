import { Service } from 'core/service';
import { SVGRepositoryType } from 'core/repository/svg';
import { Observable } from 'rxjs/Observable';
import { SVG } from 'core/entity/svg';

export class SVGService implements Service {
    private repository: SVGRepositoryType;

    constructor(repository: SVGRepositoryType) {
        this.repository = repository;
    }

    get = (url: string): Observable<SVG> => this.repository.get(url);
}
