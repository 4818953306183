import { Entity } from 'core/entity';

export class LoanDescriptionsSummary extends Entity {
    qualifications: string;
    repayment: string;

    constructor(
        qualifications: string,
        repayment: string
    ) {
        super();
        this.qualifications = qualifications;
        this.repayment = repayment;
    }
}
