import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { User } from 'core/entity/user';
import { AccessToken } from 'core/entity/user/access-token';
import { UserUpdateSpec } from 'core/entity/user/update-spec';
import { UserMapper } from 'data/http/mapper/user';
import { UserUpdateSpecMapper } from 'data/http/mapper/user/update-spec';
import { UserCreateSpec } from 'core/entity/user/create-spec';
import { UserCreateSpecMapper } from 'data/http/mapper/user/create-spec';
import { AccessTokenMapper } from 'data/http/mapper/user/access-token';
import { UserDropSpec } from 'core/entity/user/drop-spec';
import { UserDropSpecMapper } from 'data/http/mapper/user/drop-spec';
import { AccessibleUser } from 'core/entity/accessible-user';
import { AccessibleUserMapper } from 'data/http/mapper/accessible-user';
import { MobileAuthenticationSpec } from 'core/entity/user/mobile-authenticate-spec';
import { MobileAuthenticationSpecMapper } from 'data/http/mapper/user/mobile-authentication-spec';
import { MobileAuthenticationCodeSpec } from 'core/entity/user/mobile-authenticate-code-spec';
import { MobileAuthenticationCode } from 'core/entity/user/mobile-authenticate-code';
import { MobileAuthenticationCodeSpecMapper } from 'data/http/mapper/user/mobile-authentication-code-spec';
import { MobileAuthenticationCodeMapper } from 'data/http/mapper/user/mobile-authentication--code';
import { EmailValidate } from 'core/entity/email/validate';
import { EmailValidateMapper } from 'data/http/mapper/email/validate';
import { SuspendAccountSpec } from 'core/entity/user/suspend-account-spec';
import { SuspendAccountSpecMapper } from 'data/http/mapper/user/suspend-account-spec';

export class UsersApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<User> {
        return this.requestGET(
            'api/v2/me',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserMapper().fromJson(result.data)
        )
    }

    requestMobileAuthenticationCodeOnLogin(spec: MobileAuthenticationCodeSpec): Observable<MobileAuthenticationCode> {
        return this.requestPOST(
            'api/v2/users/authenticate',
            new MobileAuthenticationCodeSpecMapper().toJson(spec)
        ).map(result =>
            new MobileAuthenticationCodeMapper().fromJson(result.data)
        )
    }

    requestMobileAuthenticationCodeOnSignUp(accessToken: AccessToken, spec: MobileAuthenticationCodeSpec): Observable<MobileAuthenticationCode> {
        return this.requestPOST(
            'api/v2/me/authenticate',
            new MobileAuthenticationCodeSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new MobileAuthenticationCodeMapper().fromJson(result.data)
        );
    }

    update(accessToken: AccessToken, spec: UserUpdateSpec): Observable<User> {
        return this.requestPATCH(
            'api/v2/me',
            new UserUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserMapper().fromJson(result.data)
        )
    }

    deleteFinancialData(accessToken: AccessToken): Observable<void> {
        return this.requestDELETE(
            'api/v2/me/financial-data',
            null, null,
            { Authorization: accessToken.accessToken }
        )
    }

    drop(accessToken: AccessToken, spec: UserDropSpec): Observable<void> {
        return this.requestPOST(
            'api/v2/me/drop',
            new UserDropSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        )
    }

    signUpWithEmail(spec: UserCreateSpec): Observable<AccessibleUser> {
        return this.requestPOST(
            'api/v2/users',
            new UserCreateSpecMapper().toJson(spec)
        ).map(result =>
            new AccessibleUserMapper().fromJson(result.data)
        )
    }

    signInWithEmail(email: string, password: string): Observable<AccessToken> {
        return this.requestPOST(
            'api/v2/users/session',
            {
                email: email,
                password: password
            }
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    signUpWithFacebook(token: string, clientId: string): Observable<AccessToken> {
        return this.requestPOST(
            'api/v2/users/sign-up-via-facebook',
            {
                token: token,
                client_id: clientId
            }
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    signInWithFacebook(token: string, clientId: string): Observable<AccessToken> {
        return this.requestPOST(
            'api/v2/users/sign-in-via-facebook',
            {
                token: token,
                client_id: clientId
            }
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    signUpWithGoogle(token: string): Observable<AccessToken> {
        return this.requestPOST(
            'api/v2/users/sign-up-via-google',
            { token: token }
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    signInWithGoogle(token: string): Observable<AccessToken> {
        return this.requestPOST(
            'api/v2/users/sign-in-via-google',
            { token: token }
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    signUpWithKakao(token: string): Observable<AccessToken> {
        return this.requestPOST(
            'api/v2/users/sign-up-via-kakao',
            { token: token }
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    signInWithKakao(token: string): Observable<AccessToken> {
        return this.requestPOST(
            'api/v2/users/sign-in-via-kakao',
            { token: token }
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    signInWithMobileAuthentication(spec: MobileAuthenticationSpec): Observable<AccessToken> {
        return this.requestPOST(
            'api/v2/users/session/mobile-authentication',
            new MobileAuthenticationSpecMapper().toJson(spec)
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    smsCodeVerify(accessToken: AccessToken, spec: MobileAuthenticationSpec): Observable<void> {
        return this.requestPOST(
            'api/v2/me/sms-code-verify',
            new MobileAuthenticationSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        );
    }

    signOut(accessToken: AccessToken): Observable<void> {
        return this.requestDELETE(
            `api/v2/users/session/${accessToken.accessToken}`
        )
    }

    validateToken(accessToken: AccessToken): Observable<void> {
        return this.requestGET(
            `api/v2/users/session/${accessToken.accessToken}`
        )
    }

    refreshToken(accessToken: AccessToken): Observable<AccessToken> {
        return this.requestPUT(
            `api/v2/users/session/${accessToken.accessToken}`,
            { refresh_token: accessToken.refreshToken }
        ).map(result =>
            new AccessTokenMapper().fromJson(result.data)
        )
    }

    refreshPassword(email: string): Observable<void> {
        return this.requestDELETE(
            `api/v2/users/${email}/accounts/password`
        )
    }

    validateEmail(email: string): Observable<EmailValidate> {
        return this.requestPOST(
            'api/v2/users/emails/validate',
            { email },
        ).map(result =>
            new EmailValidateMapper().fromJson(result.data)
        );
    }

    suspendAccount(spec: SuspendAccountSpec): Observable<void> {
        return this.requestPOST(
            '/api/v2/users/suspend-by-remittance-password',
            new SuspendAccountSpecMapper().toJson(spec)
        );
    }
}
