import { List } from 'immutable';
import { Question } from 'presentation/view-model/question';
import { emptyList, lets } from 'utils/index';
import { Card } from 'core/entity/card';

export class CardQuestionCardType implements Question {
    topic = '카드종류';
    essential = true;
    clearable = false;
    types: List<Card.Type>;

    constructor(cardTypes: List<Card.Type> = emptyList()) {
        this.types = cardTypes;
    }

    toValue(): string {
        return this.types
            .map(it => this.toCardTypeString(it))
            .join(', ');
    }

    isValid(): boolean {
        return this.types.size > 0;
    }

    clear(): CardQuestionCardType {
        return new CardQuestionCardType();
    }

    private toCardTypeString(type: Card.Type) {
        return lets(type, it => {
            switch (it) {
                case Card.Type.CREDIT:
                    return '신용';
                case Card.Type.CHECK:
                    return '체크';
                case Card.Type.HYBRID:
                    return '하이브리드';
                default:
                    return '';
            }
        })
    }
}
