import { FinanceSubSectorContentSummary } from 'core/entity/finance-sector/content-summary';
import { List } from 'immutable';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import React from 'react';
import { lets } from 'utils/index';
import { Pair } from 'utils/index';

import styles from './styles.pcss';

type Pick = Pair<string, List<FinanceSubSectorContentSummary>>

interface Props {
    content: Pick;
}

interface State {
    index: number;
}

export class LandingPick extends React.Component<Props, State> {
    state = {
      index: 0
    };

    render() {
      const { content } = this.props;
      const { index } = this.state;

      return !content.second.isEmpty() && lets(content.second.get(index), active => (
        <div className={ styles.landingPickWrap }>
          <div
            className={ styles.contentImage }
            style={ { backgroundImage: `url("${active.thumbnailImageUrl}")` } }
          />
          <div className={ styles.contentBox }>
            <div>{ content.first }</div>
            <div>{ active.title }</div>
            <div className={ styles.buttonContainer }>
              <button
                className={ styles.prev }
                onClick={ this.onClickPrev }
              >
                이전 콘텐츠
              </button>
              <button
                className={ styles.next }
                onClick={ this.onClickNext }
              >
                다음 콘텐츠
              </button>
            </div>
          </div>
          {/* https://github.com/banksalad/banksalad-web/pull/2381 */}
          <a
            href={ 
              active.id === '혜택-좋은-신용카드-추천-7종-순위-2022' 
                ? 'https://banksalad.com/articles/%ED%98%9C%ED%83%9D-%EC%A2%8B%EC%9D%80-%EC%8B%A0%EC%9A%A9%EC%B9%B4%EB%93%9C-%EC%B6%94%EC%B2%9C-7%EC%A2%85-%EC%88%9C%EC%9C%84-2022' 
                : BANKSALAD_SITEMAP_BY_ID.CONTENT(active.id) 
            }
            className={ styles.clickArea }
            onClick={ () => 
              sendGAEvent(
                GA_DOMAIN.NONE, 
                GA_CATEGORY.LANDING.PICK(content.first), 
                GA_ACTION.LINK.CONTENTS_DETAIL, 
                `${content.second}_${content.first}`
              ) 
            }
          >
            { active.title } 읽어보기
          </a>
        </div>
      ));
    }

    private onClickPrev = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      const { content } = this.props;
      const { index } = this.state;

      this.setState({
        index: lets(index - 1, index =>
          index < 0 ? content.second.size - 1 : index
        )
      });
      sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.LANDING.PICK(content.first), GA_ACTION.PREV);
    };

    private onClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      const { content } = this.props;
      const { index } = this.state;

      this.setState({
        index: lets(index + 1, index =>
          index >= content.second.size ? 0 : index
        )
      });
      sendGAEvent(GA_DOMAIN.NONE, GA_CATEGORY.LANDING.PICK(content.first), GA_ACTION.NEXT);
    };
}
