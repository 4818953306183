import { Entity } from 'core/entity';
import { SummarizedUserBankProducts } from 'core/entity/personal-finance/summarized-products/bank';
import { List } from 'immutable';
import { SummarizedUserSecuritiesFirmProducts } from 'core/entity/personal-finance/summarized-products/securities-firm';
import { UserProductConfiguration } from 'core/entity/personal-finance/product-configuration';
import { UserDefaultProductConfiguration } from 'core/entity/personal-finance/summarized-products/default-product-configuration';
import { SummarizedUserCardCompanyProducts } from 'core/entity/personal-finance/summarized-products/card-company';

export class SummarizedUserProducts extends Entity {
    banks: List<SummarizedUserBankProducts>;
    cardCompanies: List<SummarizedUserCardCompanyProducts>;
    securitiesFirms: List<SummarizedUserSecuritiesFirmProducts>;
    userConfiguration: UserProductConfiguration;
    defaultConfiguration: UserDefaultProductConfiguration;

    constructor(
        banks: List<SummarizedUserBankProducts>,
        cardCompanies: List<SummarizedUserCardCompanyProducts>,
        securitiesFirms: List<SummarizedUserSecuritiesFirmProducts>,
        userConfiguration: UserProductConfiguration,
        defaultConfiguration: UserDefaultProductConfiguration
    ) {
        super();
        this.banks = banks;
        this.cardCompanies = cardCompanies;
        this.securitiesFirms = securitiesFirms;
        this.userConfiguration = userConfiguration;
        this.defaultConfiguration = defaultConfiguration;
    }
}
