import { CardCompanyLegalConfiguration } from 'core/entity/legal-configuration/card-company';
import { CardCompanyLegalConfigurationRepositoryType } from 'core/repository/card-company-legal-configuration';
import { CardCompanyLegalConfigurationApiProvider } from 'data/http/api/gateway/v3/card-companies/legal-configuration';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class CardCompanyLegalConfigurationRepository implements CardCompanyLegalConfigurationRepositoryType {
    private api: CardCompanyLegalConfigurationApiProvider;

    constructor(api: CardCompanyLegalConfigurationApiProvider) {
        this.api = api;
    }

    getAll(): Observable<List<CardCompanyLegalConfiguration>> {
        return this.api.getAll();
    }

    getByCardCompanyId(id: number): Observable<CardCompanyLegalConfiguration> {
        return this.api.getByCardCompanyId(id);
    }
}
