import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';
import { PrimaryData } from 'core/entity/personal-finance/primary-data';

export class UserCard extends Entity {
    id: string;
    number: string;
    hidden: boolean;
    deletedStatus: Asset.DeletedStatus;
    closed: boolean;
    dormant: boolean;
    company: SpiderCardCompanySummary;
    primaryData: PrimaryData;
    nickname?: string;
    description?: string;

    constructor(
        id: string,
        number: string,
        hidden: boolean,
        deletedStatus: Asset.DeletedStatus,
        closed: boolean,
        dormant: boolean,
        company: SpiderCardCompanySummary,
        primaryData: PrimaryData,
        nickname: string = null,
        description: string = null
    ) {
        super();
        this.id = id;
        this.number = number;
        this.nickname = nickname;
        this.description = description;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
        this.closed = closed;
        this.dormant = dormant;
        this.company = company;
        this.primaryData = primaryData;
    }
}
