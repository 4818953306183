import { Entity } from 'core/entity';
import { List } from 'immutable';
import { UserCreditScoreOverdueConfiguration } from 'core/entity/personal-credit/overdue-configuration';
import { UserCreditScoreSubstitutedPaymentConfiguration } from 'core/entity/personal-credit/substituted-payment-configuration';

export class UserCreditScoreOverdueStatus extends Entity {
    overdueConfigurations: List<UserCreditScoreOverdueConfiguration>;
    substitutedPaymentConfigurations: List<UserCreditScoreSubstitutedPaymentConfiguration>;
    updatedAt: Date;
    changed: boolean;

    constructor(
        overdueConfigurations: List<UserCreditScoreOverdueConfiguration>,
        substitutedPaymentConfigurations: List<UserCreditScoreSubstitutedPaymentConfiguration>,
        updatedAt: Date,
        changed: boolean
    ) {
        super();
        this.overdueConfigurations = overdueConfigurations;
        this.substitutedPaymentConfigurations = substitutedPaymentConfigurations;
        this.updatedAt = updatedAt;
        this.changed = changed;
    }
}
