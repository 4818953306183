import { Service } from 'core/service';
import { UserBankAccountRepositoryType } from 'core/repository/personal-finance/bank-account';
import { UserBankAccountCreateSpec } from 'core/entity/personal-finance/bank-account/create-spec';
import { Observable } from 'rxjs/Rx';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserBankAccountUpdateSpec } from 'core/entity/personal-finance/bank-account/update-spec';

export class UserBankAccountService implements Service {
    private repository: UserBankAccountRepositoryType;

    constructor(repository: UserBankAccountRepositoryType) {
        this.repository = repository;
    }

    create = (spec: UserBankAccountCreateSpec): Observable<UserBankAccount> =>
        this.repository.create(spec);

    update = (id: string, spec: UserBankAccountUpdateSpec): Observable<UserBankAccount> =>
        this.repository.update(id, spec);
}
