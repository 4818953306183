import { List } from 'immutable';
import { CardCategoryBenefit } from 'core/entity/card/category-benefit';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardStoreBenefitMapper } from 'data/http/mapper/card/store-benefit';

export class CardCategoryBenefitMapper implements NetworkResponseMapper<CardCategoryBenefit> {
    fromJson(json: any): CardCategoryBenefit {
        return new CardCategoryBenefit(
            json.category,
            List(json.store_benefits.map((storeBenefit: any) =>
                new CardStoreBenefitMapper().fromJson(storeBenefit)
            ))
        )
    }
}
