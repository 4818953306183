import { Footer } from 'presentation/components/footer';
import { Header } from 'presentation/components/header';
import { LoginNotRequired } from 'presentation/routes/login-not-required';
import { SignUpRoute } from 'presentation/routes/users/signup';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

export const UsersRoute = ({ match }: RouteComponentProps<any>) => (
  <>
    <Header />
    <Switch>
      <LoginNotRequired>
        <Route path={ `${match.url}/sign-up` } component={ SignUpRoute } />
      </LoginNotRequired>
    </Switch>
    <Footer />

  </>
);
