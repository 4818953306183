import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject, lets, optional, toDateString } from 'utils/index';
import { LoanCompanySummaryMapper } from 'data/http/mapper/personal-finance/loan-company/summary';
import { UserLoanCreateSpec } from 'core/entity/personal-finance/loan/create-spec';

export class UserLoanCreateSpecMapper implements NetworkRequestMapper<UserLoanCreateSpec> {
    toJson(target: UserLoanCreateSpec): any {
        return compactObject({
            company: lets(target.company, it => {
                const company = new LoanCompanySummaryMapper().toJson(it);
                return {
                    id: company.id,
                    type: company.type
                }
            }),
            nickname: target.nickname,
            hidden: target.hidden,
            latest_balance: optional(target.latestBalance, it => it),
            principal: optional(target.principal, it => it),
            description: optional(target.description, it => it),
            created_at: optional(target.createdAt, it => toDateString(it, '-')),
            expired_at: optional(target.expiredAt, it => toDateString(it, '-'))
        })
    }
}
