import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { SpiderSecuritiesFirmsApiProvider } from 'data/http/api/gateway/v3/securities-firms/spider';
import { SpiderSecuritiesFirm } from 'core/entity/personal-finance/securities-firm';
import { SpiderSecuritiesFirmRepositoryType } from 'core/repository/personal-finance/securities-firm';

export class SpiderSecuritiesFirmRepository implements SpiderSecuritiesFirmRepositoryType {
    private api: SpiderSecuritiesFirmsApiProvider;

    constructor(api: SpiderSecuritiesFirmsApiProvider) {
        this.api = api;
    }

    getAll(onlyCodeConfigured: boolean): Observable<List<SpiderSecuritiesFirm>> {
        return this.api.getAll(onlyCodeConfigured);
    }
}
