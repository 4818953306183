import { AccessToken } from 'src/core/entity/user/access-token';
import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { PersonalizedInsuranceRecommendedResult } from 'core/entity/personalized-recommend/insurance/recommend-result';
import { PersonalizedInsuranceRecommendSpec } from 'core/entity/personalized-recommend/insurance/recommend-spec';
import { PersonalizedInsuranceRecommendResultMapper } from 'data/http/mapper/personalized-recommend/insurance/recommend-result';
import { PersonalizedInsuranceRecommendSpecMapper } from 'data/http/mapper/personalized-recommend/insurance/recommend-spec';

export class PersonalizedInsuranceApiProvider extends HttpProvider {
    recommend(accessToken: AccessToken, spec: PersonalizedInsuranceRecommendSpec): Observable<PersonalizedInsuranceRecommendedResult> {
        return this.requestPOST(
            '/v1/insurance/users/me/insurance-products/recommend',
            new PersonalizedInsuranceRecommendSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken },
        ).map((result: any) => {
            return new PersonalizedInsuranceRecommendResultMapper().fromJson(result);
        })
    }
}
