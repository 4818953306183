import React from 'react';

import { HTMLBody } from 'presentation/components/html-body';
import { Card } from 'core/entity/card';
import { isMobile } from 'presentation/module/sugar';

import styles from './styles.pcss';

interface Props {
    card: Card;
}

export const CardInfoRepresentativeDescription = ({ card }: Props) => {
    const url = isMobile() ?
        (card.informationUrlMobile || card.informationUrlDesktop) :
        (card.informationUrlDesktop || card.informationUrlMobile);

    return (
        <div className={ styles.wrap }>
            <HTMLBody html={ card.representativeBenefitDescription } />
            <a
                href={ url }
                target="_blank"
                className={ styles.button }
            >
                혜택 정보 더 보기
            </a>
        </div>
    )
};
