import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { UserProductConfiguration } from 'core/entity/personal-finance/product-configuration';
import { UserProductRepositoryType } from 'core/repository/personal-finance/product';

export class GetUserProducts extends UseCase<UserProductConfiguration> {
    private repository: UserProductRepositoryType;

    constructor(repository: UserProductRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserProductConfiguration> {
        return this.repository.get();
    }

    protected validate(): boolean {
        return true;
    }

}
