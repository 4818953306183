import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceReason } from 'core/entity/personal-finance/advice/reason';

export class AdviceReasonMapper implements NetworkResponseMapper<AdviceReason> {
    fromJson(json: any): AdviceReason {
        return new AdviceReason(
            json.title,
            json.description
        )
    }
}
