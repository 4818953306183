import { Entity } from 'core/entity';

export class AdviceReason extends Entity {
    title: string;
    description: string;

    constructor(
        title: string,
        description: string
    ) {
        super();
        this.title = title;
        this.description = description;
    }
}
