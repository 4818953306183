import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { List } from 'immutable';
import { UserActualAsset } from 'core/entity/personal-finance/actual-asset';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserCard } from 'core/entity/personal-finance/card';
import { UserCashAccount } from 'core/entity/personal-finance/cash-account';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { UserFund } from 'core/entity/personal-finance/fund';
import { UserStock } from 'core/entity/personal-finance/stock';

export class UserProductConfiguration extends Entity {
    actualAssets: List<UserActualAsset>;
    bankAccounts: List<UserBankAccount>;
    cards: List<UserCard>;
    cashAccounts: List<UserCashAccount>;
    loans: List<UserLoan>;
    securitiesFirmAccounts: List<UserSecuritiesFirmAccount>;
    funds: List<UserFund>;
    stocks: List<UserStock>;
    amountSummary: UserProductConfiguration.AmountSummary;

    constructor(
        actualAssets: List<UserActualAsset>,
        bankAccounts: List<UserBankAccount>,
        cards: List<UserCard>,
        cashAccounts: List<UserCashAccount>,
        loans: List<UserLoan>,
        securitiesFirmAccounts: List<UserSecuritiesFirmAccount>,
        funds: List<UserFund>,
        stocks: List<UserStock>,
        amountSummary: UserProductConfiguration.AmountSummary
    ) {
        super();
        this.actualAssets = actualAssets;
        this.bankAccounts = bankAccounts;
        this.cards = cards;
        this.cashAccounts = cashAccounts;
        this.loans = loans;
        this.securitiesFirmAccounts = securitiesFirmAccounts;
        this.funds = funds;
        this.stocks = stocks;
        this.amountSummary = amountSummary;
    }
}

export module UserProductConfiguration {
    export class AmountSummary extends Entity {
        asset: Amount;
        debt: Amount;
        investment: Amount;

        constructor(
            asset: Amount,
            debt: Amount,
            investment: Amount
        ) {
            super();
            this.asset = asset;
            this.debt = debt;
            this.investment = investment;
        }
    }
}
