import { UserCreditScoreInquiryRecordStatusRepositoryType } from 'core/repository/personal-credit/inquiry-record-status';
import { Observable } from 'rxjs/Rx';
import { UserCreditScoreInquiryRecordStatus } from 'core/entity/personal-credit/inquery-record-status';
import { UserCreditScoreInquiryRecordsFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/inquiry-records';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class CreditInquiryRecordStatusRepository implements UserCreditScoreInquiryRecordStatusRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserCreditScoreInquiryRecordsFromKCBApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserCreditScoreInquiryRecordsFromKCBApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    get(): Observable<UserCreditScoreInquiryRecordStatus> {
        return this.tokenStorage.get().flatMap(it =>
            this.api.get(it)
        )
    }

}
