import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Observable';
import { List } from 'immutable';
import { AccessToken } from 'core/entity/user/access-token';
import { PersonalizedFinanceKCBLoanMapper } from 'data/http/mapper/personalized-finance/loan/kcb';
import { PersonalizedFinanceKCBLoan } from 'core/entity/personalized-finance/loan/kcb';
import { PersonalizedFinanceKCBLoanSpec } from 'core/entity/personalized-finance/loan/kcb/spec';
import { PersonalizedFinanceKCBLoanSpecMapper } from 'data/http/mapper/personalized-finance/loan/kcb/spec';


export class PersonalizedFinanceLoanApiProvider extends HttpProvider {
    getAll = (
        accessToken: AccessToken
    ): Observable<List<PersonalizedFinanceKCBLoan>> => (
        this.requestGET(
            '/api/v4/me/kcb-loans',
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) =>
            result.data.map(new PersonalizedFinanceKCBLoanMapper().fromJson).toList()
        )
    );

    update = (
        id: number,
        spec: PersonalizedFinanceKCBLoanSpec,
        accessToken: AccessToken
    ): Observable<List<PersonalizedFinanceKCBLoan>> => (
        this.requestPATCH(
            `/api/v4/me/kcb-loans/${id}`,
            new PersonalizedFinanceKCBLoanSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map((result: any) =>
            result.data.map(new PersonalizedFinanceKCBLoanMapper().fromJson).toList()
        )
    )
}
