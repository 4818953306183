import { Entity } from 'core/entity';

export class CreditGradeMetadata extends Entity {
    grade: number;
    scoreRange: CreditGradeMetadata.ScoreRange;

    constructor(
        grade: number,
        scoreRange: CreditGradeMetadata.ScoreRange
    ) {
        super();
        this.grade = grade;
        this.scoreRange = scoreRange;
    }
}

export module CreditGradeMetadata {
    export class ScoreRange extends Entity {
        min: number;
        max: number;

        constructor(
            min: number,
            max: number
        ) {
            super();
            this.min = min;
            this.max = max;
        }
    }
}
