import { Observable } from 'rxjs/Rx';
import { PromotionRepositoryType } from 'core/repository/promotion';
import { PromotionApiProvider } from 'data/http/api/cdn/promotion';
import { Promotion } from 'core/entity/promotion';

export class PromotionRepository implements PromotionRepositoryType {
    private api: PromotionApiProvider;

    constructor(api: PromotionApiProvider) {
        this.api = api;
    }

    get(url: string): Observable<Promotion> {
        return this.api.get(url);
    }
}
