import { NetworkResponseMapper } from 'data/http/mapper';
import { SummarizedUserProducts } from 'core/entity/personal-finance/summarized-products';
import { List } from 'immutable';
import { SummarizedUserBankProductsMapper } from 'data/http/mapper/personal-finance/summarized-products/bank';
import { SummarizedUserCardCompanyProductsMapper } from 'data/http/mapper/personal-finance/summarized-products/card-company';
import { SummarizedUserSecuritiesFirmProductsMapper } from 'data/http/mapper/personal-finance/summarized-products/securities-firm';
import { UserProductConfigurationMapper } from 'data/http/mapper/personal-finance/product-configuration';
import { UserDefaultProductConfigurationMapper } from 'data/http/mapper/personal-finance/summarized-products/default-product-configuration';

export class SummarizedUserProductsMapper implements NetworkResponseMapper<SummarizedUserProducts> {
    fromJson(json: any): SummarizedUserProducts {
        return new SummarizedUserProducts(
            List(json.banks.map(new SummarizedUserBankProductsMapper().fromJson)),
            List(json.card_companies.map(new SummarizedUserCardCompanyProductsMapper().fromJson)),
            List(json.securities_firms.map(new SummarizedUserSecuritiesFirmProductsMapper().fromJson)),
            new UserProductConfigurationMapper().fromJson(json.user_configuration),
            new UserDefaultProductConfigurationMapper().fromJson(json.default_configuration)
        )
    }
}
