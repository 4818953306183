import React from 'react';

import { UserAuthenticationViewContainer } from 'presentation/routes/users/components/container';
import { MobileAuthenticationView } from 'presentation/components/users/mobile-authentication';

const TITLE_TEXT = '본인인증';
const SUB_TITLE_TEXT = '계정 도용을 막기위한 본인 인증 절차입니다';

export const SignUpWithMobileAuthenticationView = () => (
    <UserAuthenticationViewContainer
        title={ TITLE_TEXT }
        subTitle={ SUB_TITLE_TEXT }
        visibleFooter={ false }
    >
        <MobileAuthenticationView
            isUnderSignUpMode={ true }
        />
    </UserAuthenticationViewContainer>
);
