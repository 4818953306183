import { TransactionUpdateResult } from 'core/entity/personal-finance/transaction/update-result';
import { UserTransactionRepositoryType } from 'core/repository/personal-finance/transaction';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { TransactionType } from 'core/entity/personal-finance/transaction/type';
import { TransactionCategoryRepositoryType } from 'core/repository/personal-finance/transaction-category';
import { TransactionCategoryFilter } from 'core/entity/personal-finance/transaction/category/filter';
import { TransactionUpdateSpec } from 'core/entity/personal-finance/transaction/update-spec';
import { apply, lets } from 'utils/index';
import { TransactionProduct } from 'core/entity/personal-finance/transaction/product';

export class UpdateUserTransactionType extends UseCase<TransactionUpdateResult> {
    id: string;
    type: TransactionType;
    product: TransactionProduct;
    amount: number;

    private transactionRepository: UserTransactionRepositoryType;
    private categoryRepository: TransactionCategoryRepositoryType;

    constructor(
        transactionRepository: UserTransactionRepositoryType,
        categoryRepository: TransactionCategoryRepositoryType
    ) {
        super();
        this.transactionRepository = transactionRepository;
        this.categoryRepository = categoryRepository;
    }

    protected build(): Observable<TransactionUpdateResult> {
        return this.categoryRepository
            .getAll(new TransactionCategoryFilter(this.type, true, null, true))
            .map(categories => categories.first())
            .flatMap(category =>
                this.transactionRepository.update(
                    this.id,
                    apply(new TransactionUpdateSpec(this.product, category.id), it => {
                        it.type = this.type;
                        it.amount = lets(this.type, type => {
                            switch (type) {
                                case TransactionType.EXPENSE:
                                    return -Math.abs(this.amount);
                                case TransactionType.INCOME:
                                    return Math.abs(this.amount);
                                case TransactionType.LOG:
                                    return this.amount
                            }
                        });
                    })
                )
            )
    }

    protected validate(): boolean {
        return !!this.id && !!this.type;
    }

}
