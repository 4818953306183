import { ExpenseTransactionConfiguration } from 'core/entity/personal-finance/transaction/expense/configuration';
import { NetworkResponseMapper } from 'data/http/mapper';
import { IncomeTaxDeductionConfigurationMapper } from 'data/http/mapper/personal-finance/transaction/income-tax-deduction-configuration';
import { InstallmentConfigurationMapper } from 'data/http/mapper/personal-finance/transaction/installment/configuration';
import { optional } from 'utils/index';

export class ExpenseTransactionConfigurationMapper implements NetworkResponseMapper<ExpenseTransactionConfiguration> {
    fromJson(json: any): ExpenseTransactionConfiguration {
        return new ExpenseTransactionConfiguration(
            json.excluded_from_analysis,
            json.excluded_from_budget,
            new IncomeTaxDeductionConfigurationMapper().fromJson(json.income_tax_deduction),
            optional(json.installment, it => new InstallmentConfigurationMapper().fromJson(it))
        )
    }

}
