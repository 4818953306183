import { LoanLegalConfiguration } from 'core/entity/legal-configuration/loan';
import { LoanLegalConfigurationRepositoryType } from 'core/repository/loan-legal-configuration';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';

export class GetLoanLegalConfigByLoanId extends UseCase<LoanLegalConfiguration> {
    loanId: number;
    private repository: LoanLegalConfigurationRepositoryType;

    constructor(repository: LoanLegalConfigurationRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<LoanLegalConfiguration> {
        return this.repository.getByLoanId(this.loanId);
    }

    protected validate(): boolean {
        return !!this.loanId;
    }
}
