import { Entity } from 'core/entity';

export class Page extends Entity {
    limit: number;
    offset: number;

    constructor(
        limit: number,
        offset: number
    ) {
        super();
        this.limit = limit;
        this.offset = offset;
    }
}
