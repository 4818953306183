import { UseCase } from 'core/use-case';
import { CardRepositoryType } from 'core/repository/card';
import { Card } from 'core/entity/card';
import { Observable } from 'rxjs/Rx';

export class GetServerRenderedCard extends UseCase<Card> {
    private repository: CardRepositoryType;

    constructor(repository: CardRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<Card> {
        return this.repository.getAll().map(it => it.first());
    }

    protected validate(): boolean {
        return true;
    }
}
