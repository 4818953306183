import { FinanceSectorID } from 'core/entity/finance-sector/id';
import {
  FetchEmpty,
  FetchEmptyPaddingSize
} from 'presentation/components/fetch-state/empty';
import { Header } from 'presentation/components/header';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';

export const CardsEvents = () => {
  return (
    <div>
      <MetaHelmet meta={ META_SET.CARDS_EVENTS }/>
      <Header active={ FinanceSectorID.CARD } />
      <div style={{ paddingTop: 120 }}>
        <FetchEmpty padding={ FetchEmptyPaddingSize.EXTRA_SMALL }>
          추천결과가 없습니다
        </FetchEmpty>
      </div>
    </div>
  );
};
