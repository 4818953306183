import { User } from 'core/entity/user';
import { UserDropSpec } from 'core/entity/user/drop-spec';
import { MobileAuthenticationCode } from 'core/entity/user/mobile-authenticate-code';
import { MobileAuthenticationCodeSpec } from 'core/entity/user/mobile-authenticate-code-spec';
import { MobileAuthenticationSpec } from 'core/entity/user/mobile-authenticate-spec';
import { SuspendAccountSpec } from 'core/entity/user/suspend-account-spec';
import { UserUpdateSpec } from 'core/entity/user/update-spec';
import { UserRepositoryType } from 'core/repository/user';
import { Service } from 'core/service';
import { Observable } from 'rxjs/Observable';

const THREE_DAYS = 1000 * 60 * 60 * 24 * 3;

export class UserService implements Service {
    private repository: UserRepositoryType;

    constructor(repository: UserRepositoryType) {
        this.repository = repository;
    }

    get = (): Observable<User> => this.repository.get();

    requestMobileAuthenticationCodeOnLogin = (
        spec: MobileAuthenticationCodeSpec
    ): Observable<MobileAuthenticationCode> => (
        this.repository.requestMobileAuthenticationCodeOnLogin(spec)
    );

    requestMobileAuthenticationCodeOnSignUp = (
        spec: MobileAuthenticationCodeSpec
    ): Observable<MobileAuthenticationCode> => (
        this.repository.requestMobileAuthenticationCodeOnSignUp(spec)
    );

    signInWithMobileAuthentication = (
        spec: MobileAuthenticationSpec
    ): Observable<void> => (
        this.repository.signInWithMobileAuthentication(spec)
    );

    smsCodeVerify = (
        spec: MobileAuthenticationSpec
    ): Observable<void> => (
        this.repository.smsCodeVerify(spec)
    );

    signInWithEmail = (
        email: string,
        password: string,
        kept: boolean = true
    ): Observable<void> => this.repository.signInWithEmail(email, password, kept);

    signInFacebook = (token: string): Observable<void> => (
        this.repository.signInWithFacebook(token)
    );

    signInWithGoogle = (token: string): Observable<void> => (
        this.repository.signInWithGoogle(token)
    );

    signInWithKakao = (token: string): Observable<void> => (
        this.repository.signInWithKakao(token)
    );

    signOut = (): Observable<void> =>
        this.repository.signOut();

    refreshPassword = (email: string): Observable<void> =>
        this.repository.refreshPassword(email);

    changeName = (name: string): Observable<User> =>
        this.repository.update(
            new UserUpdateSpec(name, null, null)
        );

    changePassword = (password: string, modifiedPassword: string): Observable<User> =>
        this.repository.update(
            new UserUpdateSpec(
                null,
                new UserUpdateSpec.Account(
                    password,
                    modifiedPassword
                ),
                null
            )
        );

    changeInitialDate = (initialDate: number): Observable<User> =>
        this.repository.update(
            new UserUpdateSpec(
                null, null,
                new UserUpdateSpec.Preference(initialDate, null, null)
            )
        );

    drop = (spec: UserDropSpec): Observable<void> =>
        this.repository.drop(spec);

    keepLogin = (): Observable<void> =>
        this.repository.validateToken()
            .flatMap(expiredIn =>
                expiredIn.getTime() - new Date().getTime() < THREE_DAYS ?
                    this.repository.refreshToken() :
                    Observable.of(null)
            );

    suspendAccount = (spec: SuspendAccountSpec): Observable<void> =>  (
        this.repository.suspendAccount(spec)
    );
}
