import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV1PrivacyPolicyView } from 'presentation/routes/policies/v1/privacy-policy/view';

export const PoliciesV1PrivacyPolicyRoute = () => (
    <>
    <MetaHelmet meta={ META_SET.PRIVACY_POLICY }/>
    <PoliciesV1PrivacyPolicyView />
    </>
);
