import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanPrimeInterestNodeStatusType } from 'core/entity/loan/prime-interest-node-status-type';

export class LoanPrimeInterestNodeStatusTypeMapper implements NetworkResponseMapper<LoanPrimeInterestNodeStatusType> {
    fromJson(json: any): LoanPrimeInterestNodeStatusType {
        switch (json) {
            case 'checked':
                return LoanPrimeInterestNodeStatusType.CHECKED;
            case 'checkable':
                return LoanPrimeInterestNodeStatusType.CHECKABLE;
            case 'non_checkable':
            default:
                return LoanPrimeInterestNodeStatusType.NON_CHECKABLE;
        }
    }

}
