import { List } from 'immutable';
import { Entity } from 'core/entity';

export class CardBenefit extends Entity {
    benefit: string;
    conditions: List<string>;
    restrictions: List<string>;
    description?: string;

    constructor(
        benefit: string,
        conditions: List<string>,
        restrictions: List<string>,
        description: string = null
    ) {
        super();
        this.benefit = benefit;
        this.conditions = conditions;
        this.restrictions = restrictions;
        this.description = description;
    }
}
