import {
    BrowserStorageKey,
    BrowserStorageProvider
} from 'data/browser-storage';
import { MedicalCheckupStorageMapper } from 'data/browser-storage/mapper/medical-checkup';
import { List } from 'immutable';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';

export class MedicalCheckupStorageProvider extends BrowserStorageProvider<List<MedicalCheckupJudgement>> {
    protected key = BrowserStorageKey.MEDICAL_CHECKUP_JUDGEMENT;
    protected mapper = new MedicalCheckupStorageMapper();
}
