import React from 'react';
import { List } from 'immutable';

import { FetchState } from 'presentation/view-model/fetch-state';
import { StoreCategory } from 'core/entity/store/category';
import { Loader } from 'presentation/components/loader';
import { CardStoreAlert } from 'presentation/components/alert/card-store';
import { CardQuestionSpending } from 'presentation/components/survey-form/card-spendings/model';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { EventBus } from 'presentation/bus';
import { FixBodyEvent } from 'presentation/bus/event/fix-body-event';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';
import { emptyList } from 'utils/index';
import { FetchError } from 'presentation/components/fetch-state/error';

import styles from './styles.pcss';

interface Props {
    fixed: boolean,
    onChange?: (result: StoreCategory) => void
    onSelectedBestCategory?: () => void,
    onChangeSpending?: (result: CardQuestionSpending) => void,
    gaCategory?: string
}

interface State {
    data: List<StoreCategory>,
    active?: StoreCategory,
    alert: boolean,
    fetchState: FetchState
}

export class StoreCategories extends React.Component<Props, State> {
    state = {
        data: emptyList(),
        active: null as StoreCategory,
        alert: false,
        fetchState: FetchState.FETCHED
    };

    private triggerElement: HTMLInputElement;
    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    componentDidUpdate() {
        this.triggerElement && this.toggleDocumentFixed(
            this.triggerElement.checked
        );
    }

    render() {
        const { fixed } = this.props;
        const { active, fetchState } = this.state;
        const categoryName = active ? active.name : '인기업종 TOP30';

        switch (fetchState) {
            case FetchState.FETCHING:
                return <Loader padding={ 25 } radius={ 15 }/>;
            case FetchState.ERROR:
                return (
                    <FetchError padding={ 100 }>
                        카드정보를 불러오는데 문제가 발생했습니다<br/>
                        잠시 후 새로고침 해주세요
                    </FetchError>
                );
            default:
                return (
                    <div className={ styles.wrap }>
                        <input
                            type="checkbox"
                            id="card-store-category"
                            ref={ target => this.triggerElement = target }
                            className={ styles.trigger }
                            onChange={ e => this.toggleDocumentFixed(e.target.checked) }
                        />
                        <label htmlFor="card-store-category" className={ fixed ? styles.fixedLabel : styles.label }>
                            { `업종카테고리 > ${categoryName}` }
                        </label>
                        <section className={ fixed ? styles.fixedMenu : styles.menu }>
                            <button
                                onClick={ () => this.onAlertOpen() }
                                className={ styles.search }
                            >
                                업종/가맹점 검색
                            </button>
                            <div className={ styles.container }>
                                <h5 className={ styles.title }>업종 카테고리</h5>
                                <ul className={ styles.categories }>
                                    { this.renderCategories() }
                                </ul>
                            </div>
                        </section>
                        {
                            this.state.alert && (
                                <CardStoreAlert
                                    onAccept={ spending => this.onAddSpending(spending) }
                                    onCancel={ () => this.setState({ alert: false }) }
                                />
                            )
                        }
                    </div>
                );
        }
    }

    private renderCategories() {
        const { active } = this.state;

        return (
            <ul className={ styles.categories }>
                <li>
                    <button
                        onClick={ () => this.onClickBest() }
                        className={ active ? styles.button : `${styles.button} ${styles.active}` }
                    >
                        인기업종 TOP30
                    </button>
                </li>
                {
                    this.state.data.map(category =>
                        <li key={ category.name }>
                            <button
                                onClick={ () => this.onChangeActive(category) }
                                className={ category.equals(active) ? `${styles.button} ${styles.active}` : styles.button }
                            >
                                { category.name }
                            </button>
                        </li>
                    )
                }
            </ul>
        )
    }

    private fetch() {
        this.setState({
            fetchState: FetchState.FETCHING
        });

        Application.useCases.getStoreCategories
            .runOnAnimateFrame()
            .subscribe(
                categories =>
                    this.setState({
                        data: categories,
                        fetchState: FetchState.FETCHED
                    }),
                () =>
                    this.setState({
                        fetchState: FetchState.ERROR
                    })
            )
            .unsubscribeBy(this.subscriptionBag)
    }

    private onAlertOpen() {
        const { gaCategory } = this.props;
        this.setState({ alert: true });
        gaCategory && sendGAEvent(GA_DOMAIN.CARD, gaCategory, GA_ACTION.POPUP, '업종/가맹점검색');
    }

    private onChangeActive(category: StoreCategory) {
        const { onChange, gaCategory } = this.props;
        this.triggerElement.checked = false;
        this.toggleDocumentFixed(false);
        this.setState({ active: category });
        onChange && onChange(category);
        gaCategory && sendGAEvent(GA_DOMAIN.CARD, gaCategory, GA_ACTION.RADIO, category.name);
    }

    private onClickBest() {
        const { onSelectedBestCategory, gaCategory } = this.props;
        this.setState({ active: null });
        this.triggerElement.checked = false;
        this.toggleDocumentFixed(false);
        onSelectedBestCategory && onSelectedBestCategory();
        gaCategory && sendGAEvent(GA_DOMAIN.CARD, gaCategory, GA_ACTION.RADIO, '인기업종TOP10');
    }

    private onAddSpending(spending: CardQuestionSpending) {
        const { onChangeSpending } = this.props;
        this.setState({ alert: false });
        this.triggerElement.checked = false;
        this.toggleDocumentFixed(false);
        onChangeSpending && onChangeSpending(spending)
    }

    private toggleDocumentFixed(fix: boolean) {
        if (!this.props.fixed) {
            return;
        }

        EventBus.post(new FixBodyEvent(fix));
    }
}
