import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { LoanRecommendResultSummary } from 'core/entity/loan/recommend-result/summary';

export class RecommendLoanSimply extends UseCase<LoanRecommendResultSummary> {
    spec: LoanRecommendSpec;
    defaultFilter: LoanRecommendFilter;
    private repository: LoanRecommendRepositoryType;

    constructor(repository: LoanRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<LoanRecommendResultSummary> {
        return Observable.zip(
            this.repository.cacheSpec(this.spec),
            this.repository.cacheFilter(this.defaultFilter)
        ).flatMap(() => (
            this.repository.recommendSimply(
                this.spec,
                this.defaultFilter
            )
        ));
    }

    protected validate(): boolean {
        return !!this.spec && !!this.defaultFilter;
    }

}
