import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV8PrivacyPolicyRoute } from 'presentation/routes/policies/v8/privacy-policy';

export const PoliciesV8Route = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/privacy-policy`} component={ PoliciesV8PrivacyPolicyRoute } />
    </Switch>
);
