import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { CreditBureau } from 'core/entity/personal-credit/bureau';

export class CreditBureauMapper implements NetworkResponseMapper<CreditBureau> {
    fromJson(json: any): CreditBureau {
        switch (json.toUpperCase()) {
            case 'KCB':
                return CreditBureau.KCB;
            case 'NICE':
                return CreditBureau.NICE;
            default:
                throw new MappingError();
        }
    }

}
