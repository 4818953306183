import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserCreditScoreCardStatusMapper } from 'data/http/mapper/personal-credit/card-status';
import { UserCreditScoreCardStatus } from 'core/entity/personal-credit/card-status';
import { parseDate } from 'utils/index';

export class UserCreditCardsStatusFromKCBApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<UserCreditScoreCardStatus> {
        return this.requestGET(
            'api/v2/me/credit-scores/KCB/card-status',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCreditScoreCardStatusMapper(
                parseDate(result.updated_at),
                result.changed
            ).fromJson(result.data)
        )
    }

}
