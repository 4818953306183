import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV1KCBAllCreditView } from 'presentation/routes/policies/v1/kcb-all-credit/view';

export const PoliciesV1KCBAllCreditRoute = () => (
    <>
    <MetaHelmet meta={ META_SET.KCB_ALL_CREDIT }/>
    <PoliciesV1KCBAllCreditView />
    </>
);
