import { Entity } from 'core/entity';

export class ContentCardNewsFragment extends Entity {
    imageUrl: string;
    alternative: string;

    constructor(
        imageUrl: string,
        alternative: string
    ) {
        super();
        this.imageUrl = imageUrl;
        this.alternative = alternative;
    }
}
