import { LoanLegalConfiguration } from 'core/entity/legal-configuration/loan';
import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanCFAConfigurationMapper } from 'data/http/mapper/legal-configuration/loan/cfa';

export class LoanLegalConfigurationMapper implements NetworkResponseMapper<LoanLegalConfiguration> {
    fromJson(json: any): LoanLegalConfiguration {
        return new LoanLegalConfiguration(
            json.id,
            new LoanCFAConfigurationMapper().fromJson(json.cfa),
            json.issuing_disabled
        )
    }

}
