import { List } from 'immutable';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';
import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { MedicalCheckupJudgementStorageMapper } from 'data/browser-storage/mapper/medical-checkup/judgement';

export class MedicalCheckupStorageMapper implements BrowserStorageMapper<List<MedicalCheckupJudgement>> {
    toJson(target: List<MedicalCheckupJudgement>): any {
        return {
            judgement: target.map(new MedicalCheckupJudgementStorageMapper().toJson).toArray()
        }
    }

    toEntity(json: any): List<MedicalCheckupJudgement> {
        return json.judgement.map(
            new MedicalCheckupJudgementStorageMapper().toEntity
        ).toList()
    }
}
