import { UseCase } from 'core/use-case';
import { StoreRepositoryType } from 'core/repository/store';
import { Store } from 'core/entity/store';
import { Page } from 'core/entity/page';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';

export class GetStores extends UseCase<List<Store>> {
    groupId?: number = null;
    page?: Page = null;
    private repository: StoreRepositoryType;

    constructor(repository: StoreRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<Store>> {
        return this.repository.getStores(this.groupId, this.page);
    }

    protected validate(): boolean {
        return (
            this.groupId !== null &&
            this.page !== null
        );
    }
}
