import { Entity } from 'core/entity';
import { SpiderBankAccountData } from 'core/entity/personal-finance/spider-data/bank-account';

export class BankAccountPrimaryData extends Entity {
    spider?: SpiderBankAccountData;

    constructor(
        spider: SpiderBankAccountData = null
    ) {
        super();
        this.spider = spider;
    }
}
