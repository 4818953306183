import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanCFADescription } from 'core/entity/legal-configuration/loan/cfa/description';

export class LoanCFADescriptionMapper implements NetworkResponseMapper<LoanCFADescription> {
    fromJson(json: any): LoanCFADescription {
        return new LoanCFADescription(
            json.id,
            json.name,
            json.message
        )
    }
}
