import React from 'react';

import { Application } from 'application/context.instance';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';

// TODO: v1 마이그레이션 완료되면 지울 것
export const SignOutRoute = () => {
    Application.services.user
        .signOut()
        .useOnAnimateFrame()
        .subscribe(
            () => location.href = BANKSALAD_SITEMAP.MAIN,
            () => location.href = BANKSALAD_SITEMAP.MAIN
        );
    return <div>잠시만 기다려주세요</div>;
};
