import { NetworkResponseMapper } from 'data/http/mapper';
import { AccessToken } from 'core/entity/user/access-token';
import { parseDate } from 'utils/index';

export class AccessTokenMapper implements NetworkResponseMapper<AccessToken> {
    fromJson(json: any): AccessToken {
        return new AccessToken(
            json.access_token,
            json.refresh_token,
            parseDate(json.expires_in)
        )
    }

}
