import React from 'react';

import styles from './styles.pcss';

interface Props {
  imageUrl: string;
  className?: string;
  children?: React.ReactNode;
  onLoad?: () => void;
}

export const EventBackgroundImageView = ({
  imageUrl,
  className = '',
  children,
  onLoad,
}: Props) => (
  <div className={ `${ styles.wrap } ${ className }` }>
    <img
      className={ styles.image }
      src={ imageUrl }
      onLoad={ onLoad && onLoad }
    />
    { children }
  </div>
);
