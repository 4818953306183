import { Entity } from 'core/entity';
import { AdviceMonthlyCardProfit } from 'core/entity/personal-finance/advice/monthly-card-profit';
import { AdviceUserCardSummary } from 'core/entity/personal-finance/advice/user-card-summary';

export class AdviceMonthlyCardProfitSummary extends Entity {
    userCard: AdviceUserCardSummary;
    userProfit?: AdviceMonthlyCardProfit;
    maximizedProfit?: AdviceMonthlyCardProfit;

    constructor(
        userCard: AdviceUserCardSummary,
        userProfit: AdviceMonthlyCardProfit = null,
        maximizedProfit: AdviceMonthlyCardProfit = null
    ) {
        super();
        this.userCard = userCard;
        this.userProfit = userProfit;
        this.maximizedProfit = maximizedProfit;
    }
}
