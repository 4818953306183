import { parse } from 'query-string';
import { List } from 'immutable';

enum MEDIA {
  NAVER = 'n_keyword',
  DAUM = 'DMKW',
  GOOGLE = 'GGKW'
}

const params = parse(location.search);

export const generateOneLink = (baseUrl: string, cidPostFix: string, cidDate: string, content?: string): string => {
    let pid = '&pid=';
    let c = '&c=';
    let af_adset = '&af_adset=';
    const af_sub3 = '&af_sub3=';
    const af_sub4 = '&af_sub4=';
    const af_sub5 = '&af_sub5=';
    const af_ad = '&af_ad=';
    let af_channel = '&af_channel=';
    const af_keywords = '&af_keywords=';
    let afTarget = '';
    let generatedQueryParamValues;
    let defaultCampaignValue = '';
    
    const paramsKey = [afTarget, af_sub3, af_sub4, af_sub5, af_keywords, af_ad];

    const queryKeysByMedia = new Map<string, List<string>>(
      [
          [MEDIA.NAVER, List(['n_ad_group', 'n_ad', 'n_query', 'n_rank', 'n_keyword', 'n_keyword'])],
          [MEDIA.DAUM, List(['d_ad_group', 'd_ad', 'DMSKW', 'd_rank', 'DMKW', 'DMKW'])],
          [MEDIA.GOOGLE, List(['g_ad_group', 'g_ad', 'g_query', 'g_rank', 'GGKW', 'GGKW'])]
      ]
    )

    if (!!getParameterByName(MEDIA.NAVER)) {
        pid = `${pid}NaverAD`;

        const naverQueryKeys = queryKeysByMedia.get(MEDIA.NAVER);

        generatedQueryParamValues = naverQueryKeys.map((value: string, index: number) => {
            return paramsKey[index] + getParameterByName(value);
        }) 

        af_channel = `${af_channel}NaverAD`;
    } else if (!!getParameterByName(MEDIA.DAUM)) {
        pid = `${pid}DaumAD`;

        const daumQueryKeys = queryKeysByMedia.get(MEDIA.DAUM);

        generatedQueryParamValues = daumQueryKeys.map((value: string, index: number) => {
            return paramsKey[index] + getParameterByName(value);
        }) 

        af_channel = `${af_channel}DaumAD`;
    } else if (!!getParameterByName(MEDIA.GOOGLE)) {
        pid = `${pid}GoogleAD`;

        const googleQueryKeys = queryKeysByMedia.get(MEDIA.GOOGLE);

        generatedQueryParamValues = googleQueryKeys.map((value: string, index: number) => {
            return paramsKey[index] + getParameterByName(value);
        }) 

        af_channel = `${af_channel}DaumAD`;
    }  else {
        pid = getParameterByName('pid');
        
        if (!pid) {
            return `${baseUrl}?&pid=Unknown`
        }
        af_channel = `${af_channel}${pid}`;
        afTarget = getParameterByName('af_adset');
        af_adset = `${af_adset}${afTarget}`;
        
        defaultCampaignValue = `${c}${cidDate}_${pid}_${cidPostFix}_${afTarget}`;
        
        return `${baseUrl}?${generateCampaignId(defaultCampaignValue, content)}${af_channel}${af_adset}${location.search.replace('?','&')}`;
    }

    af_channel = `${af_channel}${pid}`;
    defaultCampaignValue = `${c}${cidDate}_${pid}_${cidPostFix}_${afTarget}`;
    const midUrl = `${baseUrl}?${pid}${af_channel}${generateCampaignId(defaultCampaignValue, content)}`;

    return generateParameters(midUrl, generatedQueryParamValues.toList());
};

const generateParameters = (baseUrl: string, generatedQueryParamValues: List<string>) => (
    baseUrl + generatedQueryParamValues.join('')
);

const generateCampaignId = (defaultCampaignId: string ,content: string) => (
    content ? `${defaultCampaignId}_${content}` : defaultCampaignId
);

const replacePlusToSpace = (targetString: string) => (
    decodeURIComponent(targetString.replace(/\+/g, ' '))
);

const getParameterByName = (name: string) => (
    params[name] ? replacePlusToSpace(params[name]) : ''
);
