import { LoanPrimeInterestNode } from 'core/entity/loan/prime-interest-node';
import { Event } from 'presentation/bus';

export class SelectLoanPrimeInterestNodeEvent implements Event {
    public node: LoanPrimeInterestNode = null;

    constructor(node: LoanPrimeInterestNode) {
        this.node = node;
    }
}
