import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { SpiderSecuritiesFirm } from 'core/entity/personal-finance/securities-firm';
import { SpiderSecuritiesFirmMapper } from 'data/http/mapper/personal-finance/securities-firm';

export class SpiderSecuritiesFirmsApiProvider extends HttpProvider {
    getAll(onlyCodeConfigured: boolean): Observable<List<SpiderSecuritiesFirm>> {
        return this.requestGET(
            'api/v3/securities-firms/spider',
            { 'only-code-configured': onlyCodeConfigured }
        ).map((result: any) =>
            List(result.data.map(new SpiderSecuritiesFirmMapper().fromJson))
        )
    }
}
