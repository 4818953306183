import { Card } from 'core/entity/card';
import { triesHard } from 'utils/index';

declare module "core/entity/card" {
    export interface Card {
        isIssuableUrl: () => boolean,
        isIssuableCall: () => boolean,
        isInPromotion: () => boolean,
        isLandscapeImage: () => boolean
    }
}

Card.prototype.isIssuableUrl = function() {
    return !this.stopped && (this.issueUrlDesktop || this.issueUrlMobile);
};

Card.prototype.isIssuableCall = function() {
    return !this.stopped && this.issuePhoneNumber;
};

Card.prototype.isInPromotion = function() {
    return triesHard(() => {
        const { startDate, endDate } = this.annualCostPromotion;
        const today = new Date();
        return (
            !this.stopped &&
            today.getTime() >= startDate.getTime() &&
            today.getTime() <= endDate.getTime()
        );
    }).mustBe(() => false)
};

Card.prototype.isLandscapeImage = function () {
    return this.imageOrientation === Card.ImageOrientation.LANDSCAPE
};
