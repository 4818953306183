import React from 'react';
import Rx from 'rxjs/Rx';
import { List } from 'immutable';

import { Alert } from 'presentation/components/alert';
import { Card } from 'core/entity/card';
import { FetchState } from 'presentation/view-model/fetch-state';
import { Loader } from 'presentation/components/loader';
import { apply, emptyList } from 'utils/index';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';

import styles from './styles.pcss';

interface Props {
    title?: string,
    description?: string,
    onAccept?: (card: Card) => void,
    onCancel?: () => void
}

interface State {
    empty: boolean,
    cards: List<Card>,
    fetchState: FetchState
}

export class CardSearchAlert extends React.Component<Props, State> {
    state = {
        empty: true,
        cards: emptyList(),
        fetchState: FetchState.FETCHED
    };

    private subscriptionBag = new SubscriptionBag();
    private searchStream = new Rx.Subject<string>();

    componentDidMount() {
        this.searchStream
            .debounceTime(300)
            .subscribeOn(Rx.Scheduler.async)
            .observeOn(Rx.Scheduler.animationFrame)
            .subscribe(
                text => this.fetch(text)
            )
            .unsubscribeBy(this.subscriptionBag)
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { title, description, onCancel } = this.props;
        const { empty } = this.state;
        return (
            <Alert
                title={ title ? title : '카드 검색하기' }
                onCancel={ () => onCancel() }
            >
                <div>
                    <div className={ styles.search }>
                        <input
                            type="text"
                            placeholder="카드명을 입력하세요"
                            onChange={ e => this.searchStream.next(e.target.value) }
                        />
                    </div>
                    {
                        empty ? (
                            <div className={ styles.empty }>
                                { description ? description : '검색어를 입력해주세요' }
                            </div>
                        ) : this.renderContent()
                    }
                </div>
            </Alert>
        )
    }

    private renderContent() {
        const { onAccept } = this.props;
        const { fetchState, cards } = this.state;

        switch (fetchState) {
            case FetchState.FETCHING:
                return <Loader padding={ 80 } radius={ 25 } />;
            case FetchState.ERROR:
                return (
                    <div className={ styles.error }>
                        에러가 발생했습니다.<br/>
                        잠시 후 다시 시도해주세요.
                    </div>
                );
            default:
                return cards.isEmpty() ? (
                    <div className={ styles.empty }>검색결과가 없습니다</div>
                ) : (
                    <ul className={ styles.cards }>
                        {
                            cards.map(item =>
                                <li key={ `card-${item.id}` }>
                                    <button
                                        onClick={ () => onAccept && onAccept(item) }
                                        className={ styles.card }
                                    >
                                        <img src={ item.imageUrl } className={ styles.cardImage } />
                                        <span className={ styles.cardName }>{ item.name }</span>
                                    </button>
                                </li>
                            )
                        }
                    </ul>
                )
        }
    }

    private fetch(word: string) {
        if (word.length <= 0) {
            this.setState({
                empty: true
            });
            return;
        }

        this.setState({
            fetchState: FetchState.FETCHING,
            empty: false
        });

        apply(Application.useCases.searchCards, it => {
            it.word = word
        }).runOnAnimateFrame().subscribe(
            cards =>
                this.setState({
                    fetchState: FetchState.FETCHED,
                    cards
                })
        ).unsubscribeBy(this.subscriptionBag)
    }
}
