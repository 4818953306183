import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';

export class AdviceRecommendedExpenseAmount extends Entity {
    amount: Amount;
    ratio: number;

    constructor(
        amount: Amount,
        ratio: number
    ) {
        super();
        this.amount = amount;
        this.ratio = ratio;
    }
}
