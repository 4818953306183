import { NetworkRequestMapper } from 'data/http/mapper';
import { PersonalizedLoanRecommendConfiguration } from 'core/entity/personalized-recommend/loan/recommend-configuration';
import { compactObject, optional } from 'utils/index';
import { PersonalizedLoanCategoryMapper } from 'data/http/mapper/personalized-recommend/loan/category';

export class PersonalizedLoanRecommendConfigurationMapper implements NetworkRequestMapper<PersonalizedLoanRecommendConfiguration> {
    toJson(target: PersonalizedLoanRecommendConfiguration): any {
        return compactObject({
            api_contracted: target.apiContracted,
            loan_categories: optional(target.loanCategories, it =>
                it.map(new PersonalizedLoanCategoryMapper().toJson).toArray()
            ),
            repayment_period: optional(target.repaymentPeriod),
            desire_money: optional(target.desireMoney),
            loan_type: optional(target.loanType),
            sort_type: optional(target.sortType),
            organization_types: optional(target.organizationTypes)
        });
    }
}
