import { Entity } from 'core/entity';
import { List } from 'immutable';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';
import { UserCard } from 'core/entity/personal-finance/card';

export class SummarizedUserCardCompanyProducts extends Entity {
    company: SpiderCardCompanySummary;
    cards: List<UserCard>;

    constructor(
        company: SpiderCardCompanySummary,
        cards: List<UserCard>
    ) {
        super();
        this.company = company;
        this.cards = cards;
    }
}
