import { StoreCategory } from 'core/entity/store/category';
import { StoreCategoryMapper } from 'data/http/mapper/store/category';
import { StoreGroup } from 'core/entity/store/group';
import { StoreGroupMapper } from 'data/http/mapper/store/group';
import { HttpProvider } from 'data/http';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class StoreCategoriesApiProvider extends HttpProvider {
    getCategories(): Observable<List<StoreCategory>> {
        return this.requestGET(
            'api/v3/stores/categories'
        ).map((result: any) =>
            List(result.map((category: any) =>
                new StoreCategoryMapper().fromJson(category)
            ))
        )
    }

    getBestGroups(numberOfStores: number): Observable<List<StoreGroup>> {
        return this.requestGET(
            'api/v3/stores/categories/best',
            { 'number-of-stores': numberOfStores }
        ).map((result: any) =>
            List(result.map((category: any) =>
                new StoreGroupMapper().fromJson(category)
            ))
        )
    }

    getGroups(categoryId: number, numberOfStores: number): Observable<List<StoreGroup>> {
        return this.requestGET(
            `api/v3/stores/categories/${categoryId}`,
            { 'number-of-stores': numberOfStores }
        ).map((result: any) =>
            List(result.map((category: any) =>
                new StoreGroupMapper().fromJson(category)
            ))
        )
    }
}
