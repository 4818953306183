import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { SpiderData } from 'core/entity/personal-finance/spider-data';
import { TransactionPrimaryData } from 'core/entity/personal-finance/transaction/primary-data';
import { TransactionProductPrimaryData } from 'core/entity/personal-finance/transaction/product/primary-data';

export class BankAccountTransactionPrimaryData extends Entity implements TransactionPrimaryData {
    title: string;
    category: string;
    transactedAt: Date;
    amount: Amount;
    balanceAfterTransaction: Amount;
    product?: TransactionProductPrimaryData;
    spider?: SpiderData;

    constructor(
        title: string,
        category: string,
        transactedAt: Date,
        amount: Amount,
        balanceAfterTransaction: Amount,
        product: TransactionProductPrimaryData = null,
        spider: SpiderData = null
    ) {
        super();
        this.title = title;
        this.category = category;
        this.transactedAt = transactedAt;
        this.amount = amount;
        this.balanceAfterTransaction = balanceAfterTransaction;
        this.product = product;
        this.spider = spider;
    }
}
