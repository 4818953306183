import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanOrganizationMapper } from 'data/http/mapper/loan/organization';
import { LoanSummary } from 'core/entity/loan/summary';
import { LoanDescriptionsSummaryMapper } from 'data/http/mapper/loan/descriptions/summary';
import { LoanInterestPromotionMapper } from 'data/http/mapper/loan/interest-promotion';
import { optional, parseDate } from 'utils/index';

export class LoanSummaryMapper implements NetworkResponseMapper<LoanSummary> {
    fromJson(json: any): LoanSummary {
        return new LoanSummary(
            json.slug,
            json.name,
            new LoanOrganizationMapper().fromJson(json.organization),
            json.desktop_issue_url,
            json.mobile_issue_url,
            parseDate(json.updated_at),
            json.day_loan,
            json.general_credit,
            json.middle_class,
            json.exempt_reimbursement_fees,
            json.minus_account,
            new LoanDescriptionsSummaryMapper().fromJson(json.descriptions),
            optional(json.interest_promotion, new LoanInterestPromotionMapper().fromJson)
        )
    }

}
