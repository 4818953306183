import { Application } from 'application/context.instance';
import { CardConfiguration } from 'core/entity/card/configuration';
import { CardNonTemporalComputationResult } from 'core/entity/card/non-temporal-computation-result';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { Page } from 'core/entity/page';
import { List, OrderedMap } from 'immutable';
import { Event, EventBus } from 'presentation/bus';
import { DocumentScrollEvent } from 'presentation/bus/event/document-scroll-event';
import { FixBodyEvent } from 'presentation/bus/event/fix-body-event';
import { CardCompare } from 'presentation/components/card-compare';
import { FetchEmpty, FetchEmptyPaddingSize } from 'presentation/components/fetch-state/empty';
import { FetchError } from 'presentation/components/fetch-state/error';
import { Header } from 'presentation/components/header';
import { Loader } from 'presentation/components/loader';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { CardProfitsItem } from 'presentation/components/results-card/card';
import { CardsProfitsFilter } from 'presentation/components/results-filter/card';
import { activateOptimize, GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { SubscriptionBag } from 'presentation/module/extension';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { toCurrency } from 'presentation/module/sugar';
import { FetchPageState, FetchState } from 'presentation/view-model/fetch-state';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { parse } from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { apply, emptyList, lets } from 'utils/index';

import recommendParams from './recommend-params.json';
import styles from './styles.pcss';
const PAGE_SIZE = 10;
const SCROLL_TOP_TO_FIX_COMPARE = 270;
const SCROLL_BOTTOM_TO_FETCH = 600;

type Props = RouteComponentProps<any>

interface State {
    fetchConfigState: FetchState;
    fetchCardsState: FetchPageState;
    spec?: CardRecommendSpec;
    config?: CardConfiguration;
    page: number;
    cards: List<CardNonTemporalComputationResult>;
    fixedCompare: boolean;
}

export class CardsProfits extends React.Component<Props, State> {
    state = {
      fetchConfigState: FetchState.FETCHING,
      fetchCardsState: FetchPageState.FETCHED,
      spec: null as CardRecommendSpec,
      config: null as CardConfiguration,
      page: 0,
      cards: emptyList(),
      fixedCompare: false
    };

    private subscriptionBag = new SubscriptionBag();

    constructor(props: Props) {
      super(props);
      sendPixelEvent(PIXEL.CARDS_PROFITS);
    }

    componentDidMount() {
      activateOptimize();
      
      EventBus.toObservable().subscribe(
        (event: Event) => {
          if (event instanceof DocumentScrollEvent) {
            const {
              fixedCompare,
              fetchConfigState,
              fetchCardsState
            } = this.state;

            if (!fixedCompare && event.scrollTop > SCROLL_TOP_TO_FIX_COMPARE) {
              this.setState({ fixedCompare: true });
            } else if (fixedCompare && event.scrollTop < SCROLL_TOP_TO_FIX_COMPARE) {
              this.setState({ fixedCompare: false });
            }

            if (
              fetchConfigState === FetchState.FETCHED &&
                        fetchCardsState === FetchPageState.FETCHED &&
                        event.scrollBottom < SCROLL_BOTTOM_TO_FETCH
            ) {
              this.fetchResult();
              sendGAEvent(
                GA_DOMAIN.CARD,
                GA_CATEGORY.CARDS_PROFITS.RESULT_LIST,
                GA_ACTION.MORE,
                '스크롤내리기'
              );
            }
          }
        }
      ).unsubscribeBy(this.subscriptionBag);
      this.fetchConfig();
    }

    componentWillUnmount() {
      this.subscriptionBag.destroy();
    }

    render() {
      const {
        fetchConfigState,
        fetchCardsState,
        spec,
        config,
        fixedCompare
      } = this.state;

      const renderComponent = lets(fetchConfigState, it => {
        switch (it) {
        case FetchState.FETCHING:
          return <Loader padding={ 300 } radius={ 25 } />;
        case FetchState.ERROR:
          return (
            <FetchError padding={ FetchEmptyPaddingSize.LARGE }>
                            일시적인 오류가 발생했습니다<br/>
                            잠시 후 새로고침 해주세요
            </FetchError>
          );
        default:
          const totalAmount = spec.spendings
            .reduce((acc, it) => acc + it.expense, 0);

          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const isEvent = urlParams.get("event") === 'true';

          return (
            <div className={ styles.wrap }>
              <h3 className={ styles.head }>
                {isEvent ? 
                  <>
                    혜택 좋은 신용카드를 모았어요! <br />
                    할인/적립 금액을 비교하고 바로 발급하세요.
                  </> 
                  : 
                  <>
                    입력하신 <strong>{ toCurrency(totalAmount) }원</strong>의 소비로<br/>
                    가장 많은 혜택을 받을 수 있는 카드를 찾았어요!
                  </>
                }
              </h3>
              <div className={ styles.container }>
                <input
                  type="checkbox"
                  id="card-filter"
                  className={ styles.filterTrigger }
                  onChange={ e => EventBus.post(new FixBodyEvent(e.currentTarget.checked)) }
                />
                <label htmlFor="card-filter">필터</label>
                <section className={ styles.filter }>
                  <label
                    htmlFor="card-filter"
                    className={ styles.filterTitle }
                  >
                                        필터
                  </label>
                  <CardsProfitsFilter
                    spec={ spec }
                    config={ config }
                    onChange={ result => this.fetchResult(result) }
                  />
                </section>
                <section className={ styles.result }>
                  <div className={  `${styles.compare} ${fixedCompare ? styles.fixed : ''}` }>
                    <div className={ styles.compareContainer }>
                      <CardCompare spec={ spec }/>
                    </div>
                  </div>
                  { fixedCompare && <div className={ styles.compareEmpty }/> }
                  { this.renderCards() }
                  {
                    fetchCardsState !== FetchPageState.ERROR &&
                                        fetchCardsState !== FetchPageState.EMPTY &&
                                        fetchCardsState !== FetchPageState.FINISHED && (
                      <Loader padding={ 50 } radius={ 25 }/>
                    )
                  }
                </section>
              </div>
            </div>
          );
        }
      });

      return (
        <div>
          <MetaHelmet meta={ META_SET.CARDS_BEST } />
          <Header active={ FinanceSectorID.CARD } />
          { renderComponent }
        </div>
      );
    }

    private renderCards() {
      const { fetchCardsState, spec, config, cards } = this.state;
      switch (fetchCardsState) {
      case FetchPageState.EMPTY:
        return (
          <FetchEmpty padding={ FetchEmptyPaddingSize.SMALL }>
                        추천결과가 없습니다<br/>
                        필터를 수정해서 나에게 꼭 맞는 카드를 찾아보세요!
          </FetchEmpty>
        );
      case FetchPageState.ERROR:
        return (
          <FetchError padding={ 100 }>
                        일시적인 오류가 발생했습니다<br/>
                        잠시 후 새로고침 해주세요
          </FetchError>
        );
      default:
        let allOfServices = OrderedMap<number, string>();
        config.specialBenefits.forEach(it => {
          it.items.entrySeq().forEach(([ key, value ]) => {
            allOfServices = allOfServices.set(key, value);
          });
        });
        const activeSpecialServices = spec.filter.specialBenefitIds ?
          spec.filter.specialBenefitIds.map(id =>
            allOfServices.get(id)
          ).toList() :
          emptyList();

        return (
          <ul className={ styles.cards }>
            {
              cards.map((card, i) =>
                <li key={ `result-${card.card.id}` }>
                  <CardProfitsItem
                    card={ card }
                    rank={ i + 1 }
                    benefitTypes={ spec.benefitTypes }
                    activeSpecialServices={ activeSpecialServices }
                    gaCategory={ GA_CATEGORY.CARDS_PROFITS.RESULT_CARD }
                  />
                </li>
              )
            }
          </ul>
        );
      }
    }

    private fetchConfig() {
      this.setState({
        fetchConfigState: FetchState.FETCHING
      }, () =>
        Application.useCases.getCardsProfitsConfig
          .runOnAnimateFrame()
          .subscribe(
            result => {            
              this.setState({
                fetchConfigState: FetchState.FETCHED,
                config: result.first,
                spec: result.second
              }, () => this.fetchResult(result.second));
            },
            error => {
              // 관련 문서: https://docs.google.com/document/d/1em7_t7twJ7nImsgnWs5xS61JwlktGOaFjtvPHameDXw/edit#
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
              const isEvent = urlParams.get("event") === 'true';
            
              if(isEvent) {  
                sessionStorage.setItem("card_recommend_params", JSON.stringify(recommendParams));
                location.reload();
              } else {
                location.href = BANKSALAD_SITEMAP.CARDS_QUESTIONS;
              }
            }
              
          ).unsubscribeBy(this.subscriptionBag)
      );
    }

    private fetchResult(customSpec: CardRecommendSpec = null) {
      const { spec, page, fetchCardsState } = this.state;
      const targetSpec = customSpec || spec;
      const currentPage = customSpec ? 0 : page;

      if (fetchCardsState === FetchPageState.FETCHING) {
        return;
      }

      this.setState(state => ({
        fetchCardsState: FetchPageState.FETCHING,
        spec: targetSpec,
        cards: customSpec ? emptyList() : state.cards,
        page: currentPage
      }), () => {
        apply(Application.useCases.recommendCards, it => {
          it.spec = targetSpec;
          it.page = new Page(PAGE_SIZE, currentPage * PAGE_SIZE);
        }).runOnAnimateFrame().subscribe(
          cards => {
            this.sendGAResult(cards, currentPage * PAGE_SIZE);
            this.setState(state => ({
              fetchCardsState: cards.size === 0 && currentPage === 0 ?
                FetchPageState.EMPTY : cards.size < PAGE_SIZE ?
                  FetchPageState.FINISHED :
                  FetchPageState.FETCHED,
              cards: state.cards.concat(cards).toList(),
              page: state.page + 1
            }));
          },
          error =>
            this.setState({
              fetchCardsState: FetchPageState.ERROR
            })
        );
      });
    }

    private sendGAResult(cards: List<CardNonTemporalComputationResult>, pageIndex: number) {
      cards.map((item, i) => sendGAEvent(GA_DOMAIN.CARD, GA_CATEGORY.CARDS_PROFITS.RESULT_LIST, GA_ACTION.PRODUCT_IMPRESSION, `${item.card.companyName}_${item.card.id}_${pageIndex + i + 1}`));
    }
}
