import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { AdviceExpenseAmountSummary } from 'core/entity/personal-finance/advice/expense-amount-summary';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';

export class AdviceTopExpenseData extends Entity implements AdviceContentData {
    period: AdviceTopExpenseData.Period;
    mostVisited: AdviceExpenseAmountSummary;
    mostSpent: AdviceExpenseAmountSummary;
    message?: AdviceMessage;

    constructor(
        period: AdviceTopExpenseData.Period,
        mostVisited: AdviceExpenseAmountSummary,
        mostSpent: AdviceExpenseAmountSummary,
        message: AdviceMessage = null
    ) {
        super();
        this.period = period;
        this.mostVisited = mostVisited;
        this.mostSpent = mostSpent;
        this.message = message;
    }
}

export module AdviceTopExpenseData {
    export enum Period {
        WEEKLY = 'weekly',
        MONTHLY = 'monthly'
    }
}
