import { NetworkResponseMapper } from 'data/http/mapper';
import { Insurance } from 'core/entity/insurance';
import { InsuranceApplyLinkMapper } from 'data/http/mapper/insurance/apply-link';
import { InsuranceGuaranteedDiseaseMapper } from 'data/http/mapper/insurance/guaranteed-disease';
import { InsuranceCompanyMapper } from 'data/http/mapper/insurance/company';
import { InsuranceInformationMapper } from 'data/http/mapper/insurance/information';
import { optional } from 'utils/index';

export class InsuranceMapper implements NetworkResponseMapper<Insurance> {
    fromJson(json: any): Insurance {
        return new Insurance(
            json.apply_methods.map(new InsuranceApplyLinkMapper().fromJson).toList(),
            json.name,
            new InsuranceInformationMapper().fromJson(json.apply_with_caution),
            new InsuranceCompanyMapper().fromJson(json.company),
            json.id,
            json.guaranteed_disease.map(new InsuranceGuaranteedDiseaseMapper().fromJson).toList(),
            optional(json.reviewed_number_description),
            optional(json.category)
        );
    }
}
