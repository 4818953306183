import { TransactionCategoryFilter } from 'core/entity/personal-finance/transaction/category/filter';
import { NetworkRequestMapper } from 'data/http/mapper';
import { TransactionTypeMapper } from 'data/http/mapper/personal-finance/transaction/type';
import { compactObject, optional } from 'utils/index';

export class TransactionCategoryFilterMapper implements NetworkRequestMapper<TransactionCategoryFilter> {
    toJson(target: TransactionCategoryFilter): any {
        return compactObject({
            transaction_type: optional(target.transactionType, it => new TransactionTypeMapper().toJson(it)),
            parent: optional(target.parent),
            child: optional(target.child),
            include_default: optional(target.includeDefault)
        })
    }

}
