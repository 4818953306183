import { Entity } from 'core/entity';
import { List } from 'immutable';
import { AdviceMonthlyCardProfitSummary } from 'core/entity/personal-finance/advice/monthly-card-profit-summary';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';

export class AdviceMonthlyCardProfitSummaryData extends Entity implements AdviceContentData {
    startDatetime: Date;
    endDatetime: Date;
    profitEfficiencyLevel: number;
    profitEfficiencyRatio: number;
    summaries: List<AdviceMonthlyCardProfitSummary>;
    message?: AdviceMessage;

    constructor(
        startDatetime: Date,
        endDatetime: Date,
        profitEfficiencyLevel: number,
        profitEfficiencyRatio: number,
        summaries: List<AdviceMonthlyCardProfitSummary>,
        message: AdviceMessage = null
    ) {
        super();
        this.startDatetime = startDatetime;
        this.endDatetime = endDatetime;
        this.profitEfficiencyLevel = profitEfficiencyLevel;
        this.profitEfficiencyRatio = profitEfficiencyRatio;
        this.summaries = summaries;
        this.message = message;
    }
}
