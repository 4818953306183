import { Entity } from 'core/entity';

export class LoanStatistics extends Entity {
    numberOfCompanies: number;
    numberOfLoans: number;

    constructor(
        numberOfCompanies: number,
        numberOfLoans: number
    ) {
        super();
        this.numberOfCompanies = numberOfCompanies;
        this.numberOfLoans = numberOfLoans;
    }
}
