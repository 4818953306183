import { Entity } from 'core/entity';
import { CreditBureau } from 'core/entity/personal-credit/bureau';

export class CreditScore extends Entity {
    grade: number;
    score: CreditScore.Score;
    top: number;
    state: CreditScore.State;
    bureau: CreditBureau;
    updatedAt: Date;
    changes: CreditScore.UserCreditScoreChanges;
    updateRequired: boolean;

    constructor(
        grade: number,
        score: CreditScore.Score,
        top: number,
        state: CreditScore.State,
        bureau: CreditBureau,
        updatedAt: Date,
        changes: CreditScore.UserCreditScoreChanges,
        updateRequired: boolean
    ) {
        super();
        this.grade = grade;
        this.score = score;
        this.top = top;
        this.state = state;
        this.bureau = bureau;
        this.updatedAt = updatedAt;
        this.changes = changes;
        this.updateRequired = updateRequired;
    }
}

export module CreditScore {
    export class Score extends Entity {
        current: number;
        max: number;

        constructor(
            current: number,
            max: number
        ) {
            super();
            this.current = current;
            this.max = max;
        }
    }

    export class State extends Entity {
        description: string;

        constructor(description: string) {
            super();
            this.description = description;
        }
    }

    export class UserCreditScoreChanges extends Entity {
        statusChanged: boolean;
        gradeDiff: number;
        scoreDiff: number;

        constructor(
            statusChanged: boolean,
            gradeDiff: number,
            scoreDiff: number
        ) {
            super();
            this.statusChanged = statusChanged;
            this.gradeDiff = gradeDiff;
            this.scoreDiff = scoreDiff;
        }
    }
}
