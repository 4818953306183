import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV1KCBThirdPartyView } from 'presentation/routes/policies/v1/kcb-third-party/view';

export const PoliciesV1KCBThirdPartyRoute = () => (
    <>
    <MetaHelmet meta={ META_SET.KCB_THIRD_PARTY }/>
    <PoliciesV1KCBThirdPartyView />
    </>
);
