import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentCardNewsFragment } from 'core/entity/content/card-news/fragment';

export class NewContentCardNewsFragmentMapper implements NetworkResponseMapper<ContentCardNewsFragment> {
    fromJson(json: any): ContentCardNewsFragment {
        return new ContentCardNewsFragment(
            json.image_url,
            json.alternative
        )
    }
}
