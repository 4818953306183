import { HttpProvider } from 'data/http';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserCard } from 'core/entity/personal-finance/card';
import { UserCardMapper } from 'data/http/mapper/personal-finance/card';
import { UserCardUpdateSpec } from 'core/entity/personal-finance/card/update-spec';
import { UserCardUpdateSpecMapper } from 'data/http/mapper/personal-finance/card/update-spec';

export class UserCardsApiProvider extends HttpProvider {
    getAll(accessToken: AccessToken, hidden: boolean = null, deleted: boolean = null): Observable<List<UserCard>> {
        return this.requestGET(
            'api/v2/me/cards',
            { hidden, deleted },
            { Authorization: accessToken.accessToken }
        ).map(result =>
            List(result.data.map(new UserCardMapper().fromJson))
        )
    }

    get(accessToken: AccessToken, id: string): Observable<UserCard> {
        return this.requestGET(
            `api/v2/me/cards/${id}`,
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCardMapper().fromJson(result.data)
        )
    }

    update(accessToken: AccessToken, id: string, spec: UserCardUpdateSpec): Observable<UserCard> {
        return this.requestPATCH(
            `api/v2/me/cards/${id}`,
            new UserCardUpdateSpecMapper().toJson(spec),
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCardMapper().fromJson(result.data)
        )
    }
}
