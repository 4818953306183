import { List } from 'immutable';
import { Entity } from 'core/entity';
import { LoanBaseInterest } from 'core/entity/loan/base-interest';

export class LoanBaseInterestGroup extends Entity {
    criteriaDescription: string;
    interests: List<LoanBaseInterest>;

    constructor(
        criteriaDescription: string,
        interests: List<LoanBaseInterest>
    ) {
        super();
        this.criteriaDescription = criteriaDescription;
        this.interests = interests;
    }
}
