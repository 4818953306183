import React from 'react';

import { UserStatusInfoView } from 'presentation/routes/info/components/info';

const TITLE = `회원님의 요청에 의해<br/>이용이 일시 정지된 계정입니다`;
const INFO = '앱을 다시 실행해주세요';

export const UserSuspendedInfoView = () => (
    <UserStatusInfoView
        title={ TITLE }
        info={ INFO }
    />
);
