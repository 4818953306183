import { UserCardBillContent } from 'core/entity/personal-finance/card-bill-content';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { parseDate } from 'utils/index';

export class UserCardBillContentMapper implements NetworkResponseMapper<UserCardBillContent> {
    fromJson(json: any): UserCardBillContent {
        return new UserCardBillContent(
            json.id,
            parseDate(json.due_date),
            new AmountMapper().fromJson(json.amount)
        )
    }

}
