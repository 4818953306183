import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanConfirmationInterest } from 'core/entity/loan/interest-value/confirmation-interest';

export class LoanConfirmationInterestMapper implements NetworkResponseMapper<LoanConfirmationInterest> {
    fromJson(json: any): LoanConfirmationInterest {
        return new LoanConfirmationInterest(
            json.value
        );
    }
}
