import { BrowserStorageKey, BrowserStorageProvider } from 'data/browser-storage';
import { LocalCachedState, SessionCachedState } from 'core/entity/cached-state';
import { LocalCachedStateMapper } from 'data/browser-storage/mapper/cached-state';

export class LocalCachedStateStorageProvider extends BrowserStorageProvider<LocalCachedState> {
    protected key = BrowserStorageKey.LOCAL_CACHED_STATE;
    protected mapper = new LocalCachedStateMapper();
}

export class SessionCachedStateStorageProvider extends BrowserStorageProvider<SessionCachedState> {
    protected key = BrowserStorageKey.SESSION_CACHED_STATE;
    protected mapper = new LocalCachedStateMapper();
}
