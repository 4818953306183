import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV3TermsView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 이용약관</h3>
      <b>-목차-</b>
      <ol>
        <li>
          <b>제 1 장 총칙</b>
          <ol>
            <li>제 1 조 (목적)</li>
            <li>제 2 조 (용어의 정의)</li>
            <li>제 3 조 (약관의 명시와 개정)</li>
            <li>제 4 조 (약관의 해석)</li>
          </ol>
        </li>
        <li>
          <b>제 2 장 서비스 이용계약</b>
          <ol>
            <li>제 5 조 (이용계약의 성립)</li>
            <li>제 6 조 (이용신청)</li>
            <li>제 7 조 (이용계약의 해지)</li>
            <li>제 8 조 (이용자에 대한 통지)</li>
          </ol>
        </li>
        <li>
          <b>제 3 장 회원정보의 보호</b>
          <ol>
            <li>제 9 조 (회원정보의 수집과 보호)</li>
            <li>제 10 조 (회원의 계정(ID) 및 비밀번호 등의 관리 책임)</li>
            <li>제 11 조 (회원정보의 변경)</li>
          </ol>
        </li>
        <li>
          <b>제 4 장 서비스의 이용</b>
          <ol>
            <li>제 12 조 (서비스의 내용 및 변경)</li>
            <li>제 13 조 (서비스의 제공시간 및 중지)</li>
            <li>제 14 조 (정보의 제공 및 광고의 게재)</li>
          </ol>
        </li>
        <li>
          <b>제 5 장 계약 당사자의 의무</b>
          <ol>
            <li>제 15 조 (회사의 의무)</li>
            <li>제 16 조 (이용자의 의무)</li>
            <li>제 17 조 (권리의 귀속)</li>
          </ol>
        </li>
        <li>
          <b>제 6 장 손해배상 등</b>
          <ol>
            <li>제 18 조 (손해배상)</li>
            <li>제 19 조 (책임의 한계)</li>
            <li>제 20 조 (준거법 및 재판관할)</li>
            <li>제 21 조 (회사의 연락처)</li>
            <li>제 22 조 (부칙)</li>
          </ol>
        </li>
      </ol>
      <ol>
        <li>
          <b>제 1 장 총칙</b>
          <ol>
            <li>
              <b>제 1 조 (목적)</b>
              <p>본 약관은 주식회사 레이니스트 (이하 "회사"라 함)가 제공하는 뱅크샐러드 서비스(이하 "서비스")의 이용과 관련하여, 회사와
                        이용자의 권리, 의무 및 책임사항, 서비스의 이용과 절차, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
            </li>
            <li>
              <b>제 2 조 (용어의 정의)</b>
              <ol>
                <li>
                        ① 이 약관에서 사용하는 용어의 정의는 아래와 같습니다.
                  <ul>
                    <li>- "서비스"라 함은 회사의 웹 사이트 및 모바일 어플리케이션을 통해 이용자에게 제공하는 서비스를 통칭하며,
                            이용자의 동의 하에 수집하거나 직접 입력한 정보를 확인할 수 있게 하는 서비스, 이용자의 정보와 금융상품별
                            혜택 정보를 분석하여 코칭을 제공하는 서비스, 맞춤형 금융상품을 추천하는 서비스, 금융관련 각종 컨텐츠 제공
                            서비스 등으로 구성됩니다.
                    </li>
                    <li>- "회원"이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고 회원을 대상으로
                            제공하는 서비스를 이용하는 고객을 말합니다.
                    </li>
                    <li>- “비회원”이라 함은 회원에 가입하지 않고 회사가 제공하는 서비스를 이용하는 고객을 말합니다</li>
                    <li>- “이용자”라 함은 회원 및 비회원을 포함하여 회사가 제공하는 서비스를 이용하는 모든 고객을 말합니다.
                    </li>
                    <li>- "회원 ID"라 함은 회원의 식별과 회원의 서비스 이용을 위하여 회원 가입 시 입력한 이메일 주소나
                            문자와 숫자, 특수문자의 등으로 이루어진 고유한 정보를 말합니다.
                    </li>
                    <li>- “비밀번호”라 함은 회사의 서비스를 이용하려는 사람이 회원 ID 를 부여 받은 자와 동일인임을 확인하고
                            회원의 정보를 보호하기 위하여 회원이 설정하고 회사가 승인한 문자, 숫자, 특수문자 등의 조합을 말합니다.
                    </li>
                    <li>- “앱 잠금 비밀번호“란 회원이 서비스를 이용을 위해 모바일 어플리케이션 또는 웹 사이트에 회원이 직접
                            입력하는 본인확인용 개인식별번호를 말합니다.
                    </li>
                    <li>- "유료서비스"라 함은 "서비스" 중 "회사"가 유료로 제공하는 뱅크샐러드와 관련한 각종 부가 서비스를
                            의미합니다.
                    </li>
                    <li>- “신용정보 관리 서비스”란 회사가 본 약관에 정한 바에 따라 이용자에 대하여 제공하거나 제공하기 위하여 개발하는
                            서비스로서, 이용자의 금융 소비 패턴의 분석, 신용정보회사가 제공하는 신용정보에 대한 접근 제공 등을 통하여,
                            이용자 개인이 자신의 신용상태를 파악하고 신용상태를 관리하는데 도움을 주기 위한 서비스를 말합니다.
                    </li>
                    <li>- “신용정보 조회 서비스”란 회사가 “코리아크레딧뷰로 주식회사”와 제휴하여(이하 “제휴 신용정보회사”), 이용자로
                            하여금 직접 제휴 신용정보회사로부터 본인의 신용정보를 조회할 수 있도록 지원하는 서비스를 말합니다.
                    </li>
                  </ul>
                </li>
                <li>② 이 약관에서 사용하는 용어의 정의는 제 1 항에서 정하는 것을 제외하고는 관련법령에서 정하는 바에 의하며, 관련
                        법령에서 정하지 않는 것은 일반적인 관례에 의합니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 3 조 (약관의 명시와 개정)</b>
              <ol>
                <li>① 회사는 이 약관을 이용자가 알 수 있도록 서비스가 제공되는 모바일 어플리케이션 및 웹 사이트에 게시합니다.</li>
                <li>② 회사는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률,
                        개인정보 보호법, 신용정보의 이용 및 보호에 관현 법률 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수
                        있습니다.
                </li>
                <li>③ 회사가 약관을 개정할 경우에는 개정 약관의 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정 약관의 적용일자 7
                        일전에 회원에게 공지합니다. 단 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30 일 전에 공지하도록
                        합니다.
                </li>
                <li>④ 회원은 변경된 약관에 대해 거부할 권리가 있으며, 변경된 약관이 공지된 지 15 일이내에 거부의사를 표명할 수
                        있습니다. 회원이 변경된 약관을 거부하는 경우 본 서비스 제공자인 회사는 15 일의 기간을 정하여 회원에게 사전통지 후
                        당해 회원과의 계약을 해지할 수 있습니다. 만약, 회원이 해당 기간 내에 거부 의사를 표시하지 않거나, 전항에 따라 시행일
                        이후에 서비스를 이용하는 경우에는 변경된 약관에 동의한 것으로 간주합니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 4 조 (약관의 해석)</b>
              <ol>
                <li>① 이용자가 회사와 개별계약을 체결하여 서비스를 이용하는 경우 회사는 개별 서비스에 대한 이용약관 또는 운영정책 등(이하
                        "운영정책 등")을 둘 수 있으며, 해당 내용이 본 약관과 상충되는 경우 개별서비스에 대한 운영정책 등이 우선합니다.
                </li>
                <li>② 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망
                        이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 신용정보의 이용 및 보호에 관현 법률 등의 관계법령에 따릅니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제 2 장 서비스 이용계약</b>
          <ol>
            <li>
              <b>제 5 조 (이용계약의 성립)</b>
              <ol>
                <li>① 회사와 회원 사이의 서비스 이용계약(이하 "이용계약"이라 함)은 서비스를 이용하고자 하는 자(이하 "가입신청자"라
                        함)가 웹 사이트나 모바일 어플리케이션을 통해 회사가 정한 양식에 따라 회원가입신청을 하고, 본 “약관”과 “개인정보
                        처리방침”, “개인 정보 수집∙이용 동의서”의 내용에 동의를 하면 회사가 이에 대해 승낙함으로써 체결됩니다.
                </li>
                <li>② 본 “약관”, “개인정보 처리방침”, “개인 정보 수집∙이용 동의서”에 대한 동의의 의사표시는 "가입신청자"가
                        회원가입신청 당시 “개인 정보 수집ᆞ이용 동의서”, "서비스 이용약관" 및 “개인정보 처리방침”에 동의 버튼을 누르는
                        것으로 이루어지며, 이와 동시에 위 "가입신청자"가 본 “약관”, “개인정보 처리방침” 및 “개인 정보 수집∙이용
                        동의서”에 대하여 동의한 것으로 간주합니다.
                </li>
                <li>
                        ③ 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 단, 다음 각 호에 해당하는 신청에 대하여는
                        승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
                  <ol>
                    <li>1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우</li>
                    <li>2. 타인의 정보를 이용한 가입신청의 경우</li>
                    <li>3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
                    <li>4. 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
                    </li>
                  </ol>
                </li>
                <li>④ 만 14세 미만의 경우 서비스 가입을 제한하고 있으며, 만 14세 미만의 서비스 사용을 사후 확인한 경우 이용계약을 해제할 수 있습니다.
                </li>
                <li>⑤ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
                </li>
                <li>⑥ 회사가 제 3항, 제 4항 및 제 5항에 따라 회원가입 신청을 승낙하지 아니하거나 유보한 경우, 회사는 원칙적으로
                        그 사실을 가입신청자에게 알리도록 합니다.
                </li>
                <li>⑦ 이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</li>
                <li>⑧ 회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴등을 세분하여 이용에 차등을 둘
                        수 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 6 조 (이용신청)</b>
              <ol>
                <li>① 회원으로 가입하여 서비스를 이용하고자 하는 자는 이메일 주소와 비밀번호를 제공하거나, Facebook ID/Google
                        ID/Kakao ID/Twitter ID/Naver ID/Daum ID 등의 소셜 서비스 ID 식별자 중 하나의 정보를
                        제공해야 합니다. 또한 서비스 이용을 위해 필요한 개인 정보의 수집과 이용에 대한 동의를 받을 수 있습니다. 다만, 일부
                        서비스의 이용 신청에 대해서는 추가로 사용자 인증 및 동의 절차를 필요로 합니다.
                </li>
                <li>② 가입 신청자가 제 1항에 정한 사항을 거부할 경우 회원가입 및 서비스 이용이 제한될 수 있으며, 타인의 정보를 도용하여 회원으로 가입한 자는
                        회사가 제공하는 서비스를 이용할 수 없으며, 이용하여서도 안 됩니다.
                </li>
                <li>③ 타인의 개인정보를 도용하여 이용신청을 한 회원의 모든 ID 는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.
                </li>
                <li>④ 회사는 제 1 항의 기재항목 외에도 이용자에게 유익한 정보의 제공을 위해 직업, 나이, 성별, 소득, 소비내역, 보유
                        금융상품 정보, 금융 거래 실적, 관심사, 취미 등의 정보를 제공할 것을 요청할 수 있습니다. 단, 가입신청자는 그러한
                        정보의 제공을 거절할 수 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 7 조 (이용계약의 해지)</b>
              <ol>
                <li>① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 회사에 해지신청을 하거나 웹 사이트 및 모바일 어플리케이션에서
                        회원 탈퇴하기 버튼을 클릭하고 이에 따른 탈퇴 절차를 거침으로써 이용계약을 해지할 수 있습니다. 회사는 관련법령 등에서
                        정하는 바에 따라 이를 즉시 처리하여야 합니다.
                </li>
                <li>
                        ② 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 임의로 이용계약을 해지하거나 해당 회원의 서비스의 이용을 중단
                        시킬 수 있습니다.
                  <ol>
                    <li>1. 회원이 제 6조 제2항, 제3항에서 정하고 있는 사항에 해당됨을 확인한 경우</li>
                    <li>2. 서비스 운영을 고의로 방해한 경우</li>
                    <li>3. 공공질서 및 미풍양속에 저해되는 내용을 유포시킨 경우</li>
                    <li>4. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</li>
                    <li>5. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 등 운영자의
                            합리적이고 객관적인 판단에 의하여 회원의 자격이 없다고 인정되는 경우
                    </li>
                    <li>6. 컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
                    <li>7. 회사의 지적재산권을 침해하는 행위</li>
                    <li>8. 기타 본 약관이나 관련법규에 위배된다고 판단되는 경우</li>
                  </ol>
                </li>
                <li>③ 이용계약을 해지할 경우, 관련법령 및 회사의 개인정보보호정책에서 정한 바에 따라 회사가 회원정보를 보유하는 경우를
                        제외하고는 해지 후 즉시 회원의 모든 데이터는 지체없이 파기됩니다.
                </li>
                <li>④ 회원은 회사의 이용제한 조치 등에 대하여 이의신청을 할 수 있습니다. 회원의 이의 사유가 정당하다고 인정되는 경우
                        회사는 즉시 회원의 서비스 이용을 재개하여야 합니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 8 조 (이용자에 대한 통지)</b>
              <ol>
                <li>① 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 회원이 지정한 이메일 주소로 통지합니다.</li>
                <li>② 회사는 이용자 전체에 대한 통지의 경우 7 일 간 서비스 공지사항 또는 회사홈페이지에 게시함으로써 제 1 항의 통지에
                        갈음할 수 있습니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제 3 장 회원 정보의 보호</b>
          <ol>
            <li>
              <b>제 9 조 (회원정보의 수집과 보호)</b>
              <ol>
                <li>1. 회사는 이용계약을 위하여 회원이 제공 동의한 정보 외에도 수집 또는 처리목적을 밝혀 회원의 정보를 수집할 수 있으며,
                        관계 법령에 의해 회원 동의가 필요한 경우에는 동의를 받습니다.
                </li>
                <li>2. 회사가 정보수집 및 처리를 위하여 회원의 동의를 받는경우, 회사는 수집 및 처리하는 개인정보의 항목 및 수집방법,
                        수집목적 및 처리목적, 개인정보의 처리 및 보유기간, 제 3 자에 대한 정보제공 사항(제공받는 자, 제공받는 자의
                        이용목적, 제공정보의 항목, 보유 및 이용기간)을 개인정보처리방침으로 미리 명시하거나 고지합니다.
                </li>
                <li>3. 회원은 회사에게 정보를 제공하고자 하는 경우 사실대로 제공하여야 합니다.</li>
                <li>
                        4. 회사는 수집한 회원정보를 정보수집 시에 밝힌 수집목적, 처리목적 외의 용도로 사용하거나 회원의 동의 없이 제 3
                        자에게 제공하지 않습니다. 다만, 다음의 경우는 예외로 합니다.
                  <ul>
                    <li>- 법령에서 회원정보의 이용과 제 3 자에 대한 정보제공을 허용하고 있는 경우</li>
                    <li>- 회원의 동의 하에, 회원에게 유용한 정보 또는 광고를 제공하기 위한 자료로 활용되는 경우</li>
                  </ul>
                </li>
                <li>
                        5. 회사의 개인정보 관리 책임자 및 연락처는 아래와 같습니다.
                  <ul>
                    <li>- 성명 : 박규인</li>
                    <li>- 연락처 : gragpark@rainist.com</li>
                  </ul>
                </li>
                <li>6. 회원은 언제든지 자신에 관한 정보를 열람할 수 있고, 회사 또는 개인정보 관리 책임자에게 잘못된 정보에 대한 정정을
                        요청할 수 있습니다. 회사는 회원으로부터 정보정정의 요청이 있는 경우 그 오류가 시정될 때까지 해당 정보를 이용하지
                        않습니다
                </li>
                <li>7. 회사는 회원정보의 보호를 위하여 관리자를 최소한으로 한정하며, 회사의 고의 또는 과실로 인하여 회원정보가 분실,
                        도난, 유출, 변조된 경우에는 그로 인한 회원의 손해에 대하여 모든 책임을 부담합니다.
                </li>
                <li>8. 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서는 일체의 책임을 지지
                        않습니다.
                </li>
                <li>9. 기타 회원정보 관련 사항은 회사가 별도로 고지하는 개인정보처리방침에 따릅니다.</li>
              </ol>
            </li>
            <li>
              <b>제 10 조 (회원의 계정(ID) 및 비밀번호 등의 관리 책임)</b>
              <ol>
                <li>① 회원은 서비스의 원활한 이용 및 회원의 정보보호, 서비스 이용안내 등의 편의를 위하여 회원이 선정한 이메일 주소,
                        제휴사의 계정(제6조 제1항에서 열거하고 있는 소셜 네트워크 서비스 등의 계정)을 본 서비스의 계정(ID)으로 사용합니다.
                        다만, 서비스는 회원의 계정(ID)이 반사회적이거나 미풍양속을 해치거나 또는 운영자로 오인할 우려가 있는 경우 등에는 해당
                        ID 의 사용을 거부하거나 제한할 수 있습니다
                </li>
                <li>② 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능여부 등 제반 이용자 관리 업무를 수행합니다.</li>
                <li>③ 회원은 자신의 계정정보를 선량한 관리자로서의 주의의무를 다하여 관리하여야 합니다. 회원이 본인의 계정정보를 소홀히
                        관리하거나 제 3 자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
                </li>
                <li>④ 회원은 서비스가 정한 기준을 충족하는 범위 내에서 자유롭게 비밀번호를 정할 수 있으며, 정해진 비밀번호는 회원이 원하는
                        경우 언제든지 변경이 가능합니다.
                </li>
                <li>⑤ 회원은 서비스의 이용을 위하여 사용하는 비밀번호에 대한 보호 및 관리 책임을 부담합니다. 다만, 회사는 보안 등을
                        이유로 회원에게 정기적 또는 비정기적으로 비밀번호의 변경을 권고할 수 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 11 조 (회원정보의 변경)</b>
              <ol>
                <li>① 회원이 직접 입력하거나 회원의 동의 하에 수집되는 정보가 변경되었을 경우, 회원은
                        상당한 기간 이내에 그 변경사항을 수정해야합니다. 단, 회원 ID등 변경할 수 없는 사항은 수정할 수 없음을 원칙으로 합니다.
                </li>
                <li>② 회원정보가 변경되었음에도 해당 사항을 수정하지 않음으로써 발생하는 각종 손해와 잘못된 수정으로 인하여 발생하는 손해에
                        대하여 회사는 아무런 책임을 지지 않습니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제4장 서비스 이용</b>
          <ol>
            <li>
              <b>제 12 조 (서비스의 내용 및 변경)</b>
              <ol>
                <li>
                        ① 회사는 이용자에게 아래와 같은 서비스를 제공합니다.
                  <ul>
                    <li>- 이용자의 동의 하에 수집하거나 직접 입력한 정보를 확인 할 수 있는 서비스 : 신용정보 및
                            소득/연령/성별/직업 등의 개인 프로필 정보, 신용/체크카드 사용 내역 및 현금 사용내역 등의 소비내역정보,
                            보유계좌 잔액 및 입출금내역, 보유 자산 및 카드/예금/적금/대출/보험/펀드 등의 보유 금융상품 정보, 기타
                            금융 관련 정보 및 기타 개인정보 확인 등
                    </li>
                    <li>- 이용자의 정보와 금융상품별 혜택 정보 분석을 통한 코칭 서비스 : 예산 관리, 지출 관리, 자산 관리,
                            소득공제 관리, 신용정보 관리 및 신용점수 향상 코칭, 카드 실적 조건 및 혜택 한도 모니터링, 소비 코칭,
                            쿠폰 제공, 기타 금융 생활 관련 코칭 등
                    </li>
                    <li>- 맞춤형 금융 상품 추천 및 추천 상품 가입 링크 제공 서비스</li>
                    <li>- 금융상품별 혜택 정보 제공, 금융상품별 사용자 리뷰 등록 및 확인 기능, 특판 상품 정보 제공, 금융관련
                            이벤트 정보 제공, 금융 관련 칼럼 또는 카드 뉴스 등의 컨텐츠 제공
                    </li>
                    <li>- 기타 회사가 개발하거나 다른 회사와의 협력을 통해 제공하는 서비스</li>
                  </ul>
                </li>
                <li>② 회사는 업무 수행상 필요하다고 판단되는 경우 전항의 서비스 내용을 추가하거나 변경할 수 있으며, 이 경우 추가 또는
                        변경한 서비스의 내용을 미리 웹 사이트에 게시하거나 이용자에게 개별 통지 합니다.
                </li>
                <li>③ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 기획이나 운영상 또는 회사의 긴박한 상황 등 필요에 의해
                        수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법령상 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지 않습니다.
                </li>
                <li>④ 회사는 유료서비스의 변경 내용이 중대하거나 이용자에게 불리한 경우 해당 유료서비스를 제공받는 이용자에게 제 8 조에서
                        정한 방법으로 이용자에게 통지합니다. 이 경우, 동의를 거절한 이용자에 대해서는 변경 전 서비스를 제공합니다. 다만, 변경
                        전 서비스의 제공이 불가능한 경우 회사는 해당 서비스의 제공을 중지하거나 중단할 수 있으며, 관련 규정에 따라 환불을
                        진행합니다.
                </li>
                <li>
                        ⑤ 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
                  <ul>
                    <li>- 전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인 사유가 있는 경우</li>
                    <li>- 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</li>
                    <li>- 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
                    <li>- 기타 회사의 제반 사정으로 서비스를 할 수 없는경우</li>
                  </ul>
                </li>
                <li>⑥ 회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 중대한 과실로 인한 경우를 제외하고는 서비스의 변경 및
                        중지로 발생하는 문제에 대해서 책임을 부담하지 않습니다.
                </li>
                <li>⑦ 회사의 금융상품 관련 정보 제공서비스를 받은 후 해당 금융상품에 관한 계약을 체결할 것인지 여부는 오로지 계약 당사자인
                        이용자와 금융상품 제공자의 의사에 달려 있고, 계약상의 모든 권리, 의무는 당사자에게 있으므로 회사는 계약과 관련된 일체의
                        책임을 지지 않습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 13 조 (서비스의 제공시간 및 중지)</b>
              <ol>
                <li>① 일부 서비스의 경우 회사의 필요에 따라 지정된 일자부터 서비스를 제공할 수 있습니다.</li>
                <li>② 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1 일 24 시간 서비스를 제공합니다. 다만, 정보통신설비
                        등 시스템 정기점검, 서버의 증설 및 교체, 각종 버그 패치, 새로운 서비스로의 교체 등 운영상 필요한 경우, 일정기간
                        동안 서비스를 일시 중지시킬 수 있습니다.
                </li>
                <li>③ 제 2 항 단서의 경우 회사는 그 내용 및 시간을 홈페이지나 서비스 내에 공지하며, 이 기간 동안 이용자가 공지내용을
                        인지하지 못한 데 대하여 당 사이트는 책임을 부담하지 아니합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가
                        있는 경우 사후에 통지할 수 있습니다.
                </li>
                <li>④ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에 공시한 바에
                        따릅니다.
                </li>
                <li>⑤ 회사가 사업종목의 전환, 사업의 포기 등의 이유로 이용자가 신청한 유료서비스를 제공할 수 없게 되는 경우, 회사는 제
                        8 조에서 정한 방법으로 이용자에게 통지하고 관련 규정에 따라 이용자에게 보상합니다.
                </li>
                <li>⑥ 이용자가 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지하거나 이용자의 동의 없이
                        이용계약을 해지할 수 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 14 조 (정보의 제공 및 광고의 게재)</b>
              <ol>
                <li>① 회사는 이용자가 서비스 이용 중 필요하다고 인정되는 다양한 정보를 웹 사이트 또는 모바일 어플리케이션에 게시하거나
                        이메일, SMS/LMS/MMS 메시지, 전화, 모바일 어플리케이션 푸쉬 알림 등을 이용하여 회원에게 제공할 수 있습니다.
                        다만, 회원은 관련 법령에 따른 거래관련 정보, 고객센터 답변 등을 제외한 정보의 전송에 대하여 언제든지 전자우편, 전화
                        등을 통하여 수신거절의 의사표시를 할 수 있습니다.
                </li>
                <li>② 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 회원은 서비스 이용 시 노출되는 광고게재에 대하여
                        동의합니다.
                </li>
                <li>③ 회사는 사전동의를 한 회원에 한하여 이벤트, 뉴스레터, 금융 관련 정보 및 칼럼, 회사 또는 제휴사의 상품소개
                        관련정보를 이메일, 휴대폰 SMS/LMS/MMS, 모바일 어플리케이션 푸쉬 알림 등을 통해 발송할 수 있습니다.
                </li>
                <li>④ 제 2 항의 광고에 회원이 참여하여 회사가 아닌 제3자와 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해서
                        회사는 어떠한 책임도 부담하지 않습니다.
                </li>
                <li>⑤ 회사는 상기 정보의 제공 및 광고의 게재와 관련해서는 정보를 회사에 제공하는 플랫폼사업자, Google Play 등
                        앱스토어 사업자, 기타 제휴사의 약관 및 회사의 약관에 준거하며 관련 법령 및 시행령의 규정을 준수합니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제 5 장 계약 당사자의 의무</b>
          <ol>
            <li>
              <b>제 15 조 (회사의 의무)</b>
              <ol>
                <li>① 회사는 본 약관 및 관련 법령에서 금지하는 행위 및 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스의
                        제공을 위하여 최선을 다하여 노력합니다.
                </li>
                <li>② 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 신용정보를 포함한 일체의 개인정보 보호를 위한 보안시스템을 갖추어야
                        하며 개인정보 처리방침을 공시하고 준수합니다.
                </li>
                <li>③ 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 신속하게
                        처리하여야 합니다. 다만, 처리에 장기간이 소요되는 경우 이용자에게 게시판 또는 전자우편 등을 통하여 지체 사유를 안내하고
                        처리과정 및 처리 결과를 전달합니다.
                </li>
                <li>④ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 회원과의 계약관련 절차 및 내용등에 있어 회원에게 편의를
                        제공하도록 노력합니다.
                </li>
                <li>
                        ⑤ 회사가 유료서비스를 제공하는 경우, 회사는 다음의 사항을 해당 서비스의 이용 초기화면이나 FAQ 화면 등에 이용자가
                        알기 쉽게 표시합니다.
                  <ul>
                    <li>- 유료서비스의 명칭 또는 제호</li>
                    <li>- 유료서비스의 내용, 이용방법, 이용료, 결제방법 기타 이용조건</li>
                    <li>- 이용가능 기기 및 이용에 필요한 최소한의 기술사양</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <b>제 16 조 (이용자의 의무)</b>
              <ol>
                <li>
                        ① 이용자는 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당하는 행위를 하여서는
                        아니되며, 위반시 이용계약의 해지, 손해배상 및 관계법령에 의거하여 처벌받을 수 있습니다.
                  <ol>
                    <li>1. 가입신청 또는 정보변경을 목적으로 회사에 개인정보 등록 시 실명이 아닌 정보 또는 다른 사람의 정보를
                            사용하거나 허위 사실을 기재하는 행위
                    </li>
                    <li>2. 타인으로 가장하거나 금융회사 등에서 제공하지 않은 조작된 SMS/LMS/MMS 정보를 이용하여 서비스의
                            분석 시스템에 혼란을 주거나, 다른 회원의 계정 및 비밀번호를 도용, 부정하게 사용하거나, 타인의 신용카드,
                            전화번호 등을 무단으로 도용하여 유료 콘텐츠 등을 구매하는 행위
                    </li>
                    <li>3. 회사가 제공하지 않는 서비스를 회사의 서비스에 이용하거나, 회사가 제공하는 서비스를 다른 서비스에 제공
                            또는 가능하게 하는 행위
                    </li>
                    <li>4. 회사의 서비스 및 제공된 어플리케이션을 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제, 유통,
                            조장하거나 상업적으로 이용, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
                    </li>
                    <li>5. 회사의 서비스 및 제공된 어플리케이션을 이용하여 자기 또는 타인에게 재산상의 이익을 발생시키는 행위
                    </li>
                    <li>6. 회사 및 제3자의 명예를 훼손하거나 업무를 방해하거나 회사 및 제3자에게 손해를 가하는 행위</li>
                    <li>7. 회사의 지적재산권, 제 3 자의 지적재산권, 초상권 등 기타 권리를 침해하거나 회사의 승인을 받지 않고
                            다른 회원의 개인정보를 수집, 저장, 유포, 게시하는 행위
                    </li>
                    <li>8. 제 3 자를 기망하여 이득을 취하거나 회사가 제공하는 서비스를 불건전하게 이용하거나 하여 제3자에게
                            피해를 주는 행위
                    </li>
                    <li>9. 관련 법령에 의하여 전송 또는 게시가 금지되는 정보 또는 컴퓨터 소프트웨어, 하드웨어, 전기통신장비의
                            정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스 기타 다른 컴퓨터 코드, 파일, 프로그램을
                            포함하고 있는 자료를 전송, 게시, 유포, 사용하는 행위
                    </li>
                    <li>10. 회사로부터 특별한 권리를 부여 받지 않고 어플리케이션을 변경하거나 어플리케이션에 다른 프로그램을 추가
                            또는 삽입하거나 서버를 해킹, 역설계, 소스코드나 어플리케이션 데이터의 유출 및 변경, 별도의 서버를
                            구축하거나 웹사이트의 일부분을 임의로 변경 또는 도용하여 회사를 사칭하는 행위
                    </li>
                    <li>11. 회사의 직원이나 운영자를 가장, 사칭하거나 또는 타인의 명의를 도용하여 정보를 등록하거나 글을
                            게시하거나 메일을 발송하는 행위
                    </li>
                    <li>12. 회사의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을 목적으로 서비스를 이용하는
                            행위
                    </li>
                    <li>13. 기타 공공질서 및 미풍양속을 위반하거나 회사의 서비스에 악영향을 끼칠 수 있는 불법적, 부당한 행위
                            및 법령에 위배되는 행위
                    </li>
                  </ol>
                </li>
                <li>② 이용자는 회사 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을 확인하고 이를 준수할 의무가 있으며 기타 회사의
                        업무에 방해되는 행위를 하여서는 안 됩니다.
                </li>
                <li>③ 회원의 계정에 관한 관리 책임은 회원에게 있으며, 이를 제 3 자가 이용하도록 하여서는 안 됩니다.</li>
                <li>
                        ④ 회사는 제 1 항, 제 2 항 및 다음 각 호의 어느 하나에 해당하는 행위의 구체적인 유형을 운영 정책에서 정할 수
                        있으며, 회원 이를 준수할 의무가 있습니다.
                  <ol>
                    <li>1. 회원의 계정명, 비밀번호의 정함에 대한 제한</li>
                    <li>2. 회원 본인 및 타인 계정의 등록에 대한 제한</li>
                    <li>3. 기타 이용자의 서비스 이용에 대한 본질적인 권리를 침해하지 않는 범위 내에서 회사가 운영상 필요하다고
                            인정되는 사항
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <b>제 17 조 (권리의 귀속)</b>
              <ol>
                <li>① 서비스 및 서비스 내 회사가 제작한 콘텐츠 및 이에 부속한 콘텐츠 등에 대한 저작권 기타 지적재산권은 회사에
                        귀속됩니다. 단 제휴계약에 따라 제공된 저작물 등은 제외합니다
                </li>
                <li>② 회사는 회사가 정한 이용조건에 따라 서비스와 관련하여 회원에게 계정(ID), 게시물 등을 이용할 수 있는 이용권만을
                        부여하며, 회원은 이를 양도, 판매, 담보 제공하는 등 일체의 처분행위를 할 수 없습니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제 6 장 손해배상 등 </b>
          <ol>
            <li>
              <b>제 18 조 (손해배상)</b>
              <ol>
                <li>① 회사가 제공하는 서비스의 하자 등에 의하여 이용자에게 피해가 발생한 경우, 회사는 이용자에 대하여 손해를 배상하여야
                        합니다.
                </li>
                <li>② 이용자가 이 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우 또는 이용자가 서비스의 이용과 관련하여 회사에
                        손해를 입힌 경우 이용자는 회사에 대하여 손해를 배상하여야 합니다.
                </li>
                <li>③ 이용자가 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 회사가 당해 이용자 외의 제 3 자로부터
                        손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 이용자는 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가
                        면책되지 못한 경우(관련 법령에 따라 책임에 대한 제한이나 면제가 허용되지 않는 경우 등) 당해 이용자는 그로 인하여
                        회사에 발생한 모든 손해를 배상할 책임이 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제 19 조 (책임의 한계)</b>
              <ol>
                <li>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임을
                        부담하지 않습니다.
                </li>
                <li>② 회사는 이용자의 귀책사유로 인한 서비스 중지 또는 이용의 장애에 대하여는 책임을 지지 아니하며, 기간통신사업자가
                        전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 이용자에게 손해가 발생한 경우에는 책임을 부담하지 않습니다.
                </li>
                <li>③ 회사는 이용자가 작성하는 등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는
                        책임을 부담하지 않습니다.
                </li>
                <li>④ 회사는 이용자 간 또는 이용자와 제 3 자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 어떠한 책임도 부담하지
                        아니하며, 이용자가 기대하는 이익에 관하여 책임을 부담하지 않습니다.
                </li>
                <li>⑤ 회사는 제 3 자로부터 제공받은 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 부담하지 않으며,
                        서비스를 이용하여 이용자가 기대하는 손익이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
                </li>
                <li>⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다.</li>
              </ol>
            </li>
            <li>
              <b>제 20 조 (준거법 및 재판관할)</b>
              <ol>
                <li>① 회사와 이용자 간에 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
                <li>② 회사와 이용자 간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.</li>
              </ol>
            </li>
            <li>
              <b>제 21 조 (회사의 연락처)</b>
              <p>회사의 상호 및 주소 등은 다음과 같습니다.</p>
              <ol>
                <li>1. 상호 : 주식회사 레이니스트</li>
                <li>2. 대표자 : 김태훈</li>
                <li>3. 주소: 서울특별시 강남구 강남대로 556, 17층(논현동, 논현빌딩)</li>
                <li>4. 대표전화 : 02-3453-9300</li>
                <li>5. 이메일 주소 : banksalad@banksalad.com</li>
              </ol>
            </li>
            <li>
              <b>제 22 조 (부칙)</b>
              <ol>
                <li>본 약관은 2018. 05. 23. 부터 시행합니다.</li>
                <li>공고일자 : 2018. 05. 16.</li>
                <li>시행일자 : 2018. 05. 23</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
);
