import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';
import { SummarizedUserCardBill } from 'core/entity/personal-finance/summarized-card-bill';
import { List } from 'immutable';

export class SummarizedUserCardBills extends Entity {
    totalAmount: Amount;
    bills: List<SummarizedUserCardBill>;
    missingCardCompanies: List<SpiderCardCompanySummary>;

    constructor(
        totalAmount: Amount,
        bills: List<SummarizedUserCardBill>,
        missingCardCompanies: List<SpiderCardCompanySummary>
    ) {
        super();
        this.totalAmount = totalAmount;
        this.bills = bills;
        this.missingCardCompanies = missingCardCompanies;
    }
}
