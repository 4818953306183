import { HttpProviderDependencies } from 'application/@context/http-providers';
import { StorageProviderDependencies } from 'application/@context/storage-providers';
import { CardConfigurationRepository } from 'data/repository/card-configuration';
import { CardRecommendRepository } from 'data/repository/card-recommend';
import { CardSummaryForCompareRepository } from 'data/repository/card-summary/for-compare';
import { CardNetworkRepository } from 'data/repository/card/network';
import { CardServerRenderRepository } from 'data/repository/card/server-render';
import { StoreRepository } from 'data/repository/store';

export class CardDomainRepositoryDependencies {
    cardNetwork: CardNetworkRepository;
    cardServerRender: CardServerRenderRepository;
    cardConfiguration: CardConfigurationRepository;
    cardRecommend: CardRecommendRepository;
    cardSummaryForCompare: CardSummaryForCompareRepository;
    store: StoreRepository;

    constructor(http: HttpProviderDependencies, storage: StorageProviderDependencies) {
      this.cardNetwork = new CardNetworkRepository(http.gateway.cards);
      this.cardServerRender = new CardServerRenderRepository(storage.serverRenderCard);
      this.cardConfiguration = new CardConfigurationRepository(http.gateway.cardConfiguration);
      this.cardRecommend = new CardRecommendRepository(http.gateway.cards, storage.cardRecommendSpec);
      this.cardSummaryForCompare = new CardSummaryForCompareRepository(storage.cardSummaryForCompare);
      this.store = new StoreRepository(http.gateway.stores, http.gateway.storeCategories);
    }
}
