import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardAmountMapper } from 'data/http/mapper/card/amount';
import { CardProfit } from 'core/entity/card/profit';
import { CardBenefitMapper } from 'data/http/mapper/card/benefit';
import { CardSpendingMapper } from 'data/http/mapper/card/spending';

export class CardProfitMapper implements NetworkResponseMapper<CardProfit> {
    fromJson(json: any): CardProfit {
        return new CardProfit(
            new CardSpendingMapper().fromJson(json.spending),
            new CardAmountMapper().fromJson(json.amount),
            List(json.benefits.map((benefit: any) =>
                new CardBenefitMapper().fromJson(benefit)
            ))
        )
    }

}
