import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanPeriod } from 'core/entity/loan/period';

export class LoanPeriodMapper implements NetworkResponseMapper<LoanPeriod> {
    fromJson(json: any): LoanPeriod {
        return new LoanPeriod(
            json.min,
            json.max
        )
    }

}
