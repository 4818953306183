import { NetworkResponseMapper } from 'data/http/mapper';
import { Card } from 'core/entity/card';
import { lets } from 'utils/index';

export class CardImageOrientationMapper implements NetworkResponseMapper<Card.ImageOrientation> {
    fromJson(json: any): Card.ImageOrientation {
        return lets(json, it => {
            switch (it) {
                case 'landscape':
                    return Card.ImageOrientation.LANDSCAPE;
                case 'portrait':
                    return Card.ImageOrientation.PORTRAIT;
            }
        });
    }
}
