import { Entity } from 'core/entity';
import { PrimaryData } from 'core/entity/personal-finance/primary-data';
import { SpiderFundData } from 'core/entity/personal-finance/spider-data/fund';

export class FundPrimaryData extends Entity implements PrimaryData {
    spider?: SpiderFundData;

    constructor(
        spider: SpiderFundData = null
    ) {
        super();
        this.spider = spider;
    }
}
