import { NetworkResponseMapper } from 'data/http/mapper';
import { optional } from 'utils/index';
import { SecuritiesFirmAccountPrimaryData } from 'core/entity/personal-finance/primary-data/securities-firm-account';
import { SpiderSecuritiesFirmAccountDataMapper } from 'data/http/mapper/personal-finance/spider-data/securities-firm-account';

export class SecuritiesFirmAccountPrimaryDataMapper implements NetworkResponseMapper<SecuritiesFirmAccountPrimaryData> {
    fromJson(json: any): SecuritiesFirmAccountPrimaryData {
        return new SecuritiesFirmAccountPrimaryData(
            optional(json.spider, it => new SpiderSecuritiesFirmAccountDataMapper().fromJson(it)),

        )
    }

}
