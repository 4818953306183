import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreOverdueStatus } from 'core/entity/personal-credit/overdue-status';
import { UserCreditScoreOverdueConfigurationMapper } from 'data/http/mapper/personal-credit/overdue-configuration';
import { UserCreditScoreSubstitutedPaymentConfigurationMapper } from 'data/http/mapper/personal-credit/substituted-payment-configuration';

export class UserCreditScoreOverdueStatusMapper implements NetworkResponseMapper<UserCreditScoreOverdueStatus> {
    private updatedAt: Date;
    private changed: boolean;

    constructor(
        updatedAt: Date,
        changed: boolean
    ) {
        this.updatedAt = updatedAt;
        this.changed = changed;
    }

    fromJson(json: any): UserCreditScoreOverdueStatus {
        return new UserCreditScoreOverdueStatus(
            json.overdue_configurations.map(new UserCreditScoreOverdueConfigurationMapper().fromJson).toList(),
            json.substituted_payment_configurations.map(new UserCreditScoreSubstitutedPaymentConfigurationMapper().fromJson).toList(),
            this.updatedAt,
            this.changed
        )
    }

}
