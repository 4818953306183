import { EventTagsModel } from 'presentation/components/event-tags/model';
import { CardEvent } from 'core/entity/card/event';
import { optional } from 'utils/index';

export class EventsViewerListModel {
    id: string;
    title: string;
    tags: EventTagsModel;
    thumbNailImageUrl: string;
    company: EventsViewerListModel.Company;
    startDate: Date;
    endDate?: Date;

    constructor(
        id: string,
        title: string,
        tags: EventTagsModel,
        thumbNailImageUrl: string,
        company: EventsViewerListModel.Company,
        startDate: Date,
        endDate?: Date
    ) {
        this.id = id;
        this.title = title;
        this.tags = tags;
        this.thumbNailImageUrl = thumbNailImageUrl;
        this.company = company;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}

export class EventsViewerListModelMapper {
    fromCardEvent = (cardEvent: CardEvent) =>
        new EventsViewerListModel(
            cardEvent.id,
            cardEvent.title,
            new EventTagsModel(
                null,
                cardEvent.hot,
                null
            ),
            cardEvent.thumbNailImageUrl,
            new EventsViewerListModel.Company(
                cardEvent.company.id,
                cardEvent.company.name,
                cardEvent.company.imageUrl
            ),
            cardEvent.startDate,
            optional(cardEvent.endDate)
        );
}

export module EventsViewerListModel {
    export class Company {
        id: number;
        name: string;
        imageUrl: string;

        constructor(
            id: number,
            name: string,
            imageUrl: string
        ) {
            this.id = id;
            this.name = name;
            this.imageUrl = imageUrl;
        }
    }
}
