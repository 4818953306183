import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentAuthorSummaryMapper } from 'data/http/mapper/content/author/summary';
import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { parseDate, optional } from 'utils/index';

export class ContentCardComparisonSummaryMapper implements NetworkResponseMapper<ContentCardComparisonSummary> {
    fromJson(json: any): ContentCardComparisonSummary {
        return new ContentCardComparisonSummary(
            json.id,
            json.title,
            json.description,
            new ContentAuthorSummaryMapper().fromJson(json.author),
            json.thumbnail_image_url,
            parseDate(json.created_at),
            json.hot,
            optional(json.label)
        )
    }
}
