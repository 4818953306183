import { Entity } from 'core/entity';
import { List } from 'immutable';
import { UserCreditScoreChangeRecord } from 'core/entity/personal-credit/change-record';

export class UserCreditScoreChangeRecordStatus extends Entity {
    changeRecords: List<UserCreditScoreChangeRecord>;
    updatedAt: Date;
    changed: boolean;

    constructor(
        changeRecords: List<UserCreditScoreChangeRecord>,
        updatedAt: Date,
        changed: boolean
    ) {
        super();
        this.changeRecords = changeRecords;
        this.updatedAt = updatedAt;
        this.changed = changed;
    }
}
