import { NetworkRequestMapper } from 'data/http/mapper';
import { PersonalizedInsuranceRecommendSpec } from 'core/entity/personalized-recommend/insurance/recommend-spec';
import { lets } from 'utils/index';
import { MedicalCheckupJudgement } from 'core/entity/medical-checkup/judgement';

export class PersonalizedInsuranceRecommendSpecMapper implements NetworkRequestMapper<PersonalizedInsuranceRecommendSpec> {
    toJson(target: PersonalizedInsuranceRecommendSpec): any {
        return {
            recommend_configuration: {
                checkup_items: target.checkupItems.map(item =>
                    lets(item, it => {
                        switch (it) {
                            case MedicalCheckupJudgement.Item.FAT:
                                return 'fat';
                            case MedicalCheckupJudgement.Item.SIGHT:
                                return 'sight';
                            case MedicalCheckupJudgement.Item.HEARING:
                                return 'hearing';
                            case MedicalCheckupJudgement.Item.HIGH_BLOOD_PRESSURE:
                                return 'high-blood-pressure';
                            case MedicalCheckupJudgement.Item.KIDNEY:
                                return 'kidney';
                            case MedicalCheckupJudgement.Item.ANEMIA:
                                return 'anemia';
                            case MedicalCheckupJudgement.Item.DIABETES:
                                return 'diabetes';
                            case MedicalCheckupJudgement.Item.DYSLIPIDEMIA:
                                return 'dyslipidemia';
                            case MedicalCheckupJudgement.Item.CHRONIC_KIDNEY:
                                return 'chronic-kidney';
                            case MedicalCheckupJudgement.Item.LIVER:
                                return 'liver';
                            case MedicalCheckupJudgement.Item.TB_CHEST:
                                return 'tb-chest';
                            case MedicalCheckupJudgement.Item.OSTEOPOROSIS:
                                return 'osteoporosis';
                            case MedicalCheckupJudgement.Item.NOT_CATEGORIZED:
                                return 'not-categorized';
                        }
                    })
                ).toArray()
            }
        }
    }
}
