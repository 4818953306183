import { EventBackgroundImageView } from 'presentation/components/background-image';
import {
    EventFloatingButton,
    FloatingButtonThemeColor
} from 'presentation/components/floating-button';
import { Loader } from 'presentation/components/loader';
import {
    GA_ACTION,
    GA_CATEGORY,
    GA_DOMAIN,
    sendGAEvent
} from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { generateOneLinkV2 } from 'presentation/routes/app/event/utils';
import { FetchState } from 'presentation/view-model/fetch-state'
import React from 'react'

interface State {
    imageFetchState: FetchState;
}

const ISSUE_BUTTON_TITLE = '카카오뱅크 연동하기';
const BACKGROUND_IMAGE = 'https://cdn.banksalad.com/app/promotion/kakaobank-open/bg.png';
const BASE_ONE_LINK = 'https://go.onelink.me/xMM7';
const CTA_LOCATION = 'Floating';
const CLOSE_PARAMETER = encodeURIComponent(BANKSALAD_SITEMAP.CERT);
import styles from './styles.pcss';
const params = location.search;

export class KakaobankOpenEventView extends React.Component<{}, State> {
    state = {
        imageFetchState: FetchState.FETCHING,
    };

    componentDidMount() {
        this.loadImage();
    }

    private loadImage = () => {
        const img = new Image();
        img.src = BACKGROUND_IMAGE;

        img.onload = () => {
            this.setState({
                imageFetchState: FetchState.FETCHED
            })
        };
    };

    private renderLoadingView = () => (
        <div className={ styles.cover }>
            <div className={ styles.coverContainer }>
                <Loader padding={ 0 } radius={ 20 } />
            </div>
        </div>
    );

    private onClickFloating = () => (
        sendGAEvent(
            GA_DOMAIN.NONE,
            GA_CATEGORY.BRIDGE_PAGE.FLOATING,
            GA_ACTION.LINK.APP_DOWNLOAD,
            ISSUE_BUTTON_TITLE
        )
    );

    private renderView = () => {
        const issueURL = generateOneLinkV2(
            BASE_ONE_LINK,
            params,
            CTA_LOCATION,
            CLOSE_PARAMETER
        );

        return (
            <div className={ styles.wrap }>
                <EventBackgroundImageView
                    className={ styles.image }
                    imageUrl={ BACKGROUND_IMAGE }
                />
                <EventFloatingButton
                    title={ ISSUE_BUTTON_TITLE }
                    issueUrl={ issueURL }
                    minVisibleThreshold = { 150 }
                    theme={ {
                        isRound: false,
                        color: FloatingButtonThemeColor.ROMAINE
                    } }
                    onClick={ this.onClickFloating }
                />
            </div>
        );
    };

    render() {
        const { imageFetchState } = this.state;

        return imageFetchState === FetchState.FETCHED ?
            this.renderView() :
            this.renderLoadingView()
    }
}
