import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { Amount } from 'core/entity/amount';
import { SpiderBankSummary } from 'core/entity/personal-finance/bank/summary';
import { BankAccountPrimaryData } from 'core/entity/personal-finance/primary-data/bank-account';

export class UserBankAccount extends Entity {
    id: string;
    type: UserBankAccount.Type;
    hidden: boolean;
    deletedStatus: Asset.DeletedStatus;
    closed: boolean;
    dormant: boolean;
    latestBalance: Amount;
    bank: SpiderBankSummary;
    primaryData: BankAccountPrimaryData;
    number?: string;
    nickname?: string;
    description?: string;
    createdAt?: Date;
    expiredAt?: Date;

    constructor(
        id: string,
        type: UserBankAccount.Type,
        hidden: boolean,
        deletedStatus: Asset.DeletedStatus,
        closed: boolean,
        dormant: boolean,
        latestBalance: Amount,
        bank: SpiderBankSummary,
        primaryData: BankAccountPrimaryData,
        number: string = null,
        nickname: string = null,
        description: string = null,
        createdAt: Date = null,
        expiredAt: Date = null
    ) {
        super();
        this.id = id;
        this.type = type;
        this.number = number;
        this.nickname = nickname;
        this.description = description;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
        this.closed = closed;
        this.dormant = dormant;
        this.latestBalance = latestBalance;
        this.createdAt = createdAt;
        this.expiredAt = expiredAt;
        this.bank = bank;
        this.primaryData = primaryData;
    }
}

export module UserBankAccount {
    export enum Type {
        CHECKING = 'checking',
        DEPOSIT = 'deposit',
        SAVING = 'saving',
        TRUST = 'trust'
    }
}
