import { UserProductRepository } from 'data/repository/personal-finance/product';
import { UserBankAccountRepository } from 'data/repository/personal-finance/bank-account';
import { UserTransactionRepository } from 'data/repository/personal-finance/transaction';
import { UserFundRepository } from 'data/repository/personal-finance/fund';
import { UserCardRepository } from 'data/repository/personal-finance/card';
import { UserCashAccountRepository } from 'data/repository/personal-finance/cash-account';
import { UserLoanRepository } from 'data/repository/personal-finance/loan';
import { UserSecuritiesFirmAccountRepository } from 'data/repository/personal-finance/securities-firm-account';
import { UserCardBillRepository } from 'data/repository/personal-finance/card-bill';
import { UserStockRepository } from 'data/repository/personal-finance/stock';
import { UserActualAssetRepository } from 'data/repository/personal-finance/actual-asset';
import { TransactionCategoryRepository } from 'data/repository/personal-finance/transaction-category';
import { SpiderCardCompanyRepository } from 'data/repository/personal-finance/card-company';
import { SpiderSecuritiesFirmRepository } from 'data/repository/personal-finance/securities-firm';
import { AdviceRepository } from 'data/repository/personal-finance/advice';
import { SpiderBankRepository } from 'data/repository/personal-finance/bank';
import { HttpProviderDependencies } from 'application/@context/http-providers';
import { StorageProviderDependencies } from 'application/@context/storage-providers';
import { CreditScoreRepository } from 'data/repository/personal-credit/score';
import { CreditTipRepository } from 'data/repository/personal-credit/tip';
import { CreditInquiryRecordStatusRepository } from 'data/repository/personal-credit/inquiry-record-status';
import { UserCreditScoreCardStatusRepository } from 'data/repository/personal-credit/card-status';
import { UserCreditScoreLoanStatusRepository } from 'data/repository/personal-credit/loan-status';
import { CreditJointSuretiesStatusFromKCBRepository } from 'data/repository/personal-credit/joint-sureties-status';
import { UserCreditScoreChangeRecordStatusRepository } from 'data/repository/personal-credit/change-record-status';
import { UserCreditScoreOverdueStatusRepository } from 'data/repository/personal-credit/overdue-status';
import { CreditMetadataRepository } from 'data/repository/personal-credit/metadata';
import { HealthInsurancePaymentDetailRepository } from 'data/repository/personal-finance/health-insurance/payment-details';
import { HealthInsuranceCertificateRepository } from 'data/repository/personal-finance/health-insurance/certificates';
import { HealthInsuranceSubmitRepository } from 'data/repository/personal-finance/health-insurance/submit';
import { UserFinancialDataRepository } from 'data/repository/personal-finance/financial-data';

export class PFMDomainRepositoryDependencies {
    userProducts: UserProductRepository;
    userCard: UserCardRepository;
    userCardBill: UserCardBillRepository;
    userTransaction: UserTransactionRepository;
    userCashAccount: UserCashAccountRepository;
    userActualAsset: UserActualAssetRepository;
    userBankAccount: UserBankAccountRepository;
    userLoan: UserLoanRepository;
    userSecuritiesFirmAccount: UserSecuritiesFirmAccountRepository;
    userFund: UserFundRepository;
    userStock: UserStockRepository;
    transactionCategory: TransactionCategoryRepository;
    spiderBank: SpiderBankRepository;
    spiderCardCompany: SpiderCardCompanyRepository;
    spiderSecuritiesFirm: SpiderSecuritiesFirmRepository;
    advice: AdviceRepository;
    userFinancialData: UserFinancialDataRepository;

    /* credit */
    creditScoreFromKCB: CreditScoreRepository;
    creditTip: CreditTipRepository;
    creditInquiryRecord: CreditInquiryRecordStatusRepository;
    creditCardsStatusFromKCB: UserCreditScoreCardStatusRepository;
    creditLoansStatusFromKCB: UserCreditScoreLoanStatusRepository;
    creditOverduesStatusFromKCB: UserCreditScoreOverdueStatusRepository;
    creditJointSuretiesStatusFromKCB: CreditJointSuretiesStatusFromKCBRepository;
    creditChangeRecord: UserCreditScoreChangeRecordStatusRepository;
    creditMetadata: CreditMetadataRepository;

    /* health-insurance */
    healthInsurancePaymentDetail: HealthInsurancePaymentDetailRepository;
    healthInsuranceCertificate: HealthInsuranceCertificateRepository;
    healthInsuranceSubmit: HealthInsuranceSubmitRepository;

    constructor(http: HttpProviderDependencies, storage: StorageProviderDependencies) {
        this.userProducts = new UserProductRepository(storage.accessToken, http.gateway.userProducts);
        this.userCard = new UserCardRepository(storage.accessToken, http.gateway.userCards, http.gateway.userCardCreditLimits, http.gateway.summarizedUserCardBills);
        this.userCardBill = new UserCardBillRepository(storage.accessToken, http.gateway.userCardBills);
        this.userTransaction = new UserTransactionRepository(storage.accessToken, http.gateway.userTransaction);
        this.userCashAccount = new UserCashAccountRepository(storage.accessToken, http.gateway.userCashAccounts);
        this.userActualAsset = new UserActualAssetRepository(storage.accessToken, http.gateway.userActualAssets);
        this.userBankAccount = new UserBankAccountRepository(storage.accessToken, http.gateway.userBankAccounts);
        this.userLoan = new UserLoanRepository(storage.accessToken, http.gateway.userLoans);
        this.userSecuritiesFirmAccount = new UserSecuritiesFirmAccountRepository(storage.accessToken, http.gateway.userSecuritiesFirmAccounts);
        this.userFund = new UserFundRepository(storage.accessToken, http.gateway.userFunds);
        this.userStock = new UserStockRepository(storage.accessToken, http.gateway.userStocks);
        this.transactionCategory = new TransactionCategoryRepository(storage.accessToken, http.gateway.transactionCategory);
        this.spiderBank = new SpiderBankRepository(http.gateway.spiderBanks);
        this.spiderCardCompany = new SpiderCardCompanyRepository(http.gateway.spiderCardCompanies);
        this.spiderSecuritiesFirm = new SpiderSecuritiesFirmRepository(http.gateway.spiderSecuritiesFirms);
        this.advice = new AdviceRepository(storage.accessToken, http.gateway.advice);
        this.userFinancialData = new UserFinancialDataRepository(storage.accessToken, http.gateway.userFinancialDataAssetSummaries);

        /* credit */
        this.creditScoreFromKCB = new CreditScoreRepository(storage.accessToken, http.gateway.creditScoreFromKCB);
        this.creditTip = new CreditTipRepository(http.gateway.creditTips);
        this.creditInquiryRecord = new CreditInquiryRecordStatusRepository(storage.accessToken, http.gateway.creditInquiryRecords);
        this.creditCardsStatusFromKCB = new UserCreditScoreCardStatusRepository(storage.accessToken, http.gateway.creditCardsStatusFromKCB);
        this.creditLoansStatusFromKCB = new UserCreditScoreLoanStatusRepository(storage.accessToken, http.gateway.creditLoansStatusFromKCB);
        this.creditOverduesStatusFromKCB = new UserCreditScoreOverdueStatusRepository(storage.accessToken, http.gateway.creditOverduesStatusFromKCB);
        this.creditJointSuretiesStatusFromKCB = new CreditJointSuretiesStatusFromKCBRepository(storage.accessToken, http.gateway.creditJointSuretiesStatusFromKCB);
        this.creditChangeRecord = new UserCreditScoreChangeRecordStatusRepository(storage.accessToken, http.gateway.creditChangeRecords);
        this.creditMetadata = new CreditMetadataRepository(http.gateway.creditMetadata);

        /* health-insurance */
        this.healthInsurancePaymentDetail = new HealthInsurancePaymentDetailRepository(storage.accessToken, http.gateway.healthInsurancePaymentDetail);
        this.healthInsuranceCertificate = new HealthInsuranceCertificateRepository(storage.accessToken, http.gateway.healthInsuranceCertificate);
        this.healthInsuranceSubmit = new HealthInsuranceSubmitRepository(storage.accessToken, http.gateway.healthInsuranceSubmit);
    }
}
