import { Entity } from 'core/entity';
import { List } from 'immutable';
import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';
import { LoanRegistrationType } from 'core/entity/loan/registration-type';
import { LoanOrganizationType } from 'core/entity/loan/organization/type';

export class LoanRecommendFilter extends Entity {
    repaymentPeriod?: LoanRecommendFilter.RepaymentPeriod;
    organizationTypes?: List<LoanOrganizationType>;
    interestTypes?: List<LoanInterestType>;
    repaymentTypes?: List<LoanRepaymentType>;
    registrationTypes?: List<LoanRegistrationType>;
    exemptReimbursementFees?: boolean;
    dayLoan?: boolean;
    generalCredit?: boolean;
    minusAccount?: boolean;
    inPromotion?: boolean;
    sorter?: LoanRecommendFilter.Sort;

    constructor(
        repaymentPeriod: LoanRecommendFilter.RepaymentPeriod = null,
        organizationTypes: List<LoanOrganizationType> = null,
        interestTypes: List<LoanInterestType> = null,
        repaymentTypes: List<LoanRepaymentType> = null,
        registrationTypes: List<LoanRegistrationType> = null,
        exemptReimbursementFees: boolean = null,
        dayLoan: boolean = null,
        generalCredit: boolean = null,
        minusAccount: boolean = null,
        inPromotion: boolean = null,
        sorter: LoanRecommendFilter.Sort = null
    ) {
        super();
        this.repaymentPeriod = repaymentPeriod;
        this.organizationTypes = organizationTypes;
        this.interestTypes = interestTypes;
        this.repaymentTypes = repaymentTypes;
        this.registrationTypes = registrationTypes;
        this.exemptReimbursementFees = exemptReimbursementFees;
        this.dayLoan = dayLoan;
        this.generalCredit = generalCredit;
        this.minusAccount = minusAccount;
        this.inPromotion = inPromotion;
        this.sorter = sorter;
    }
}

export module LoanRecommendFilter {
    export enum Sort {
        MINIMUM_INTEREST = '금리 낮은 순',
        MAXIMUM_LOAN_LIMIT = '한도 높은 순',
        MAXIMUM_REPAYMENT_PERIOD = '최장 기간 순'
    }

    export enum RepaymentPeriod {
        UNDER_ONE_YEAR = 1,
        UNDER_TWO_YEARS = 2,
        UNDER_THREE_YEARS = 3,
        UNDER_FOUR_YEARS = 4,
        UNDER_FIVE_YEARS = 5
    }
}
