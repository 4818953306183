import { List } from 'immutable';
import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { LoanCreditScoreAgencyMapper } from 'data/browser-storage/mapper/loan/credit-score-agency';
import { LoanOccupationalGroupMapper } from 'data/browser-storage/mapper/loan/occupational-group';
import { LoanInterviewHistoryMapper } from 'data/browser-storage/mapper/loan/interview-history';

export class LoanRecommendSpecMapper implements BrowserStorageMapper<LoanRecommendSpec> {
    toEntity(json: any): LoanRecommendSpec {
        return new LoanRecommendSpec(
            json.desiredAmount,
            json.creditScore,
            new LoanCreditScoreAgencyMapper().toEntity(json.creditScoreAgency),
            new LoanOccupationalGroupMapper().toEntity(json.occupationalGroup),
            json.annualIncome,
            List(json.histories.map((history: any) =>
                new LoanInterviewHistoryMapper().toEntity(history)
            )),
            List(json.primeInterestNodeIds)
        );
    }

    toJson(target: LoanRecommendSpec): any {
        return {
            desiredAmount: target.desiredAmount,
            creditScore: target.creditScore,
            creditScoreAgency: new LoanCreditScoreAgencyMapper().toJson(target.creditScoreAgency),
            occupationalGroup: new LoanOccupationalGroupMapper().toJson(target.occupationalGroup),
            annualIncome: target.annualIncome,
            histories: target.histories.map(history =>
                new LoanInterviewHistoryMapper().toJson(history)
            ).toArray(),
            primeInterestNodeIds: target.primeInterestNodeIds.toArray()
        };
    }

}
