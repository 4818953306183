import { TransactionProduct } from 'core/entity/personal-finance/transaction/product';
import { NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';

export class TransactionProductMapper implements NetworkResponseMapper<TransactionProduct> {
    fromJson(json: any): TransactionProduct {
        return new TransactionProduct(
            json.id,
            lets(json.type, it => {
                switch (it) {
                    case 'card':
                        return TransactionProduct.Type.CARD;
                    case 'bank_account':
                        return TransactionProduct.Type.BANK_ACCOUNT;
                    case 'cash_account':
                        return TransactionProduct.Type.CASH_ACCOUNT;
                    case 'loan':
                        return TransactionProduct.Type.LOAN;
                    default:
                        return null;
                }
            }),
            json.name
        )
    }

}
