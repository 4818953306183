import { Entity } from 'core/entity';

export class BanksaladStore extends Entity {
    id: number;
    name: string;
    imageUrl: string;

    constructor(
        id: number,
        name: string,
        imageUrl: string
    ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
    }
}
