import { Entity } from 'core/entity';

export class TransactionProductPrimaryData extends Entity {
    name: string;
    number: string;

    constructor(
        name: string,
        number: string
    ) {
        super();
        this.name = name;
        this.number = number;
    }

}
