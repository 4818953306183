import { Entity } from 'core/entity';
import { ComparableCardIssuingUrl } from 'core/entity/comparable-card/issuing-urls';
import { ComparableCardCompany } from 'core/entity/comparable-card/company';

export class ComparableCard extends Entity {
    id: number;
    name: string;
    imageUrl: string;
    annualCost: number;
    type: ComparableCard.Type;
    company: ComparableCardCompany;
    issuingUrl: ComparableCardIssuingUrl;

    constructor(
        id: number,
        name: string,
        imageUrl: string,
        annualCost: number,
        type: ComparableCard.Type,
        company: ComparableCardCompany,
        issuingUrl: ComparableCardIssuingUrl
    ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.annualCost = annualCost;
        this.type = type;
        this.company = company;
        this.issuingUrl = issuingUrl;
    }
}

export module ComparableCard {
    export enum Type {
        CREDIT,
        CHECK,
        HYBRID
    }
}
