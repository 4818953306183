import { ChargedTransaction } from 'core/entity/personal-finance/charged-transaction';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { ChargedTransactionInstallmentConfigurationMapper } from 'data/http/mapper/personal-finance/charged-transaction/installment-configuration';
import { parseDate, optional } from 'utils/index';

export class ChargedTransactionMapper implements NetworkResponseMapper<ChargedTransaction> {
    fromJson(json: any): ChargedTransaction {
        return new ChargedTransaction(
            json.title,
            new AmountMapper().fromJson(json.amount),
            parseDate(json.transacted_date),
            optional(json.installment, it =>
                new ChargedTransactionInstallmentConfigurationMapper().fromJson(it)
            )
        )
    }

}
