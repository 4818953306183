import { MetaHelmet } from 'presentation/components/meta-helmet';
import { OUTBOUND_LINK } from 'presentation/module/sitemap';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import React from 'react';

import styles from './styles.pcss';

export const ContentsContributionRoute = () => (
  <div>
    <MetaHelmet meta={ META_SET.CONTENTS_CONTRIBUTION }/>
    <section className={ styles.hero }>
      <div className={ styles.container }>
        <h3 className={ styles.title }>
                    당신의 이야기로 만들어 가는
          <strong>올바른 금융 매거진</strong>
        </h3>
        <p className={ styles.description }>
                    뱅크샐러드 매거진에서 기고 칼럼을 모집합니다
        </p>
        <a href={ OUTBOUND_LINK.MAIL_TO_BANKSALAD() } className={ styles.mail }>
          <span className={ styles.forTablet }>hello@banksalad.com - </span>이메일 지원하기
        </a>
      </div>
    </section>
    <section className={ styles.content }>
      <div className={ styles.container }>
        <h4 className={ styles.start }>뱅크샐러드가 금융 콘텐츠 <span className={ styles.mobileBlock }>매거진을 시작합니다</span></h4>
        <p className={ styles.text }>
                    금융은 우리의 삶에 큰 영향을 주지만, 많은 사람들이 이를 잘 활용하지 못하고 있습니다. 이는 금융이 지나치게 복잡하고 어렵기 때문입니다.<br/><br/>
                    복잡한 금융의 실타래를 풀기 위해, 뱅크샐러드는 소비자들에게 가장 필요한 정보를 쉬우면서 깊이있게 전달하는 금융 매거진을 만들어 나가려 합니다. 사람들이 뱅크샐러드의 콘텐츠를 통해 금융을 더 잘 이해하고, 더 잘 활용하고, 더 나은 삶을 준비할 수 있게 돕는 것이 우리의 목표입니다.
        </p>
      </div>
    </section>
    <section className={ styles.firstGrayContent }>
      <div className={ styles.container }>
        <h4 className={ styles.subTitle }>
                    금융 전문가분들의 기고를 기다립니다
        </h4>
        <p className={ styles.text }>
                    뱅크샐러드 매거진은 저희와 뜻이 같은 분들의 글을 모집하고 있습니다. 재테크 및 금융전반에 대해 깊이있는 통찰과 유용한 정보가 담긴 글이라면 어떤 주제라도 좋습니다. 여러분의 가치있는 글이 더 많은 사람들에게 전달되어 긍정적인 영향을 미칠 수 있도록 돕겠습니다.
        </p>
      </div>
    </section>
    <section className={ styles.grayContent }>
      <div className={ styles.container }>
        <h4 className={ styles.subTitle }>
                    뱅크샐러드 매거진 편집 철학
        </h4>
        <dl className={ styles.philosophy }>
          <dt>철저하게 소비자의 관점에서</dt>
          <dd>복잡하고 어렵지만, 꼭 알아야 하는 금융 정보를 소비자의 관점에서 쉽고 명확하게 알려드리겠습니다.</dd>
          <dt>한걸음 더 들어간 전문성있는 콘텐츠</dt>
          <dd>어디서 많이 본 듯한, 두루뭉실한 콘텐츠는 지양합니다. 데이터와 분석에 기반한, 깊이있는 콘텐츠들을 제공하겠습니다.</dd>
          <dt>삶 속에서 바로 활용할 수 있는 정보</dt>
          <dd>콘텐츠를 읽기 전과 후의 삶이 실제로 달라질 수 있는, 가장 구체적이고 가장 유용한 정보들에 집중하겠습니다.</dd>
        </dl>
      </div>
    </section>
    <section className={ styles.content }>
      <div className={ styles.container }>
        <h4 className={ styles.subTitle }>기고 방법은 간단합니다</h4>
        <ul className={ styles.steps }>
          <li className={ styles.step1 }>
            <span className={ styles.stepNumber }>step 1</span>
            <span className={ styles.stepTitle }>이메일 지원</span>
            <p className={ styles.stepDescription }>
                            직접 작성하신 글을 간단한 자기소개와 함께 아래 링크로 보내주세요.
            </p>
          </li>
          <li className={ styles.step2 }>
            <span className={ styles.stepNumber }>step 2</span>
            <span className={ styles.stepTitle }>검토 후 개별연락</span>
            <p className={ styles.stepDescription }>
                            담당자가 보내주신 내용을 검토한 뒤 3일 이내에 개별 연락 드립니다.
            </p>
          </li>
          <li className={ styles.step3 }>
            <span className={ styles.stepNumber }>step 3</span>
            <span className={ styles.stepTitle }>콘텐츠 발행</span>
            <p className={ styles.stepDescription }>
                            기고문 작성자의 최종 컨펌을 거친 후 글이 뱅크샐러드 매거진에 업로드됩니다.
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section className={ styles.lastContent }>
      <h4 className={ styles.lastTitle }>당신의 글이 금융을 바꿀 수 있습니다</h4>
      <a href={ OUTBOUND_LINK.MAIL_TO_BANKSALAD() } className={ styles.lastButton }>
                지금 바로 이메일 지원하기
      </a>
      <span className={ styles.lastEmail }>
                문의ㅣ<strong>hello@banksalad.com</strong>
      </span>
      <p className={ styles.refer }>
                뱅크샐러드의 편집방향에 따라 글 내용의 일부수정을 제안드릴 수 있습니다.<br/>
                기고문이 채택되신 분께는 소정의 원고료를 드립니다.
      </p>
    </section>
  </div>
);
