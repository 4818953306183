import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceExcessiveSpendingCafeData } from 'core/entity/personal-finance/advice/content-data/excessive-spending-cafe';
import { AdviceTransactionSnapshotMapper } from 'data/http/mapper/personal-finance/advice/transaction-snapshot';
import { optional, parseDate } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceExcessiveSpendingCafeDataMapper implements
    NetworkResponseMapper<AdviceExcessiveSpendingCafeData>
{
    fromJson(json: any): AdviceExcessiveSpendingCafeData {
        return new AdviceExcessiveSpendingCafeData(
            json.data.map(new AdviceTransactionSnapshotMapper().fromJson).toList(),
            parseDate(json.base_datetime),
            optional(json.message, new AdviceMessageMapper().fromJson)
        );
    }

}
