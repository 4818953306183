import { Entity } from 'core/entity';

export class UserDropSpec extends Entity {
    password: string;
    reason: UserDropSpec.Reason;
    payload: string;

    constructor(
        password: string,
        reason: UserDropSpec.Reason,
        payload: string
    ) {
        super();
        this.password = password;
        this.reason = reason;
        this.payload = payload;
    }
}

export module UserDropSpec {
    export enum Reason {
        INVALID_DATA = 'invalid_data',
        FEATURE_NOT_FOUND = 'feature_not_found',
        SECURITY = 'security',
        ETC = 'etc'
    }
}
