import React from 'react';

import { removeQueriesAndHashes } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    title: string;
    size?: number;
}

export const KakaoStoryShareButton = (props: Props) =>
    <button
        className={ styles.button }
        onClick={ () => {
            Kakao.Story.share({
                url: removeQueriesAndHashes(location.href),
                text: props.title
            });
        } }
        style={{
            width: `${props.size || 34}px`,
            height: `${props.size || 34}px`
        }}
    >
        카카오스토리 공유
    </button>;
