import { NetworkResponseMapper } from 'data/http/mapper';
import { CardShinhanCombo } from 'core/entity/card/shinhan-combo';
import { CardSelectiveGroupMapper } from 'data/http/mapper/card/selective-group';

export class CardShinhanComboMapper implements NetworkResponseMapper<CardShinhanCombo> {
    fromJson(json: any): CardShinhanCombo {
        return new CardShinhanCombo(
            new CardSelectiveGroupMapper().fromJson(json.combo1),
            new CardSelectiveGroupMapper().fromJson(json.combo2)
        )
    }
}
