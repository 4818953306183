import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { LoanApiProvider } from 'data/http/api/gateway/v2/loan';
import { LoanRecommendResultSummary } from 'core/entity/loan/recommend-result/summary';
import { LoanRecommendResult } from 'core/entity/loan/recommend-result';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import {
    LoanRecommendFilterStorageProvider,
    LoanRecommendSpecStorageProvider
} from 'data/browser-storage/api/loan';

export class LoanRecommendRepository implements LoanRecommendRepositoryType {
    private api: LoanApiProvider;
    private specStorage: LoanRecommendSpecStorageProvider;
    private filterStorage: LoanRecommendFilterStorageProvider;

    constructor(
        api: LoanApiProvider,
        specStorage: LoanRecommendSpecStorageProvider,
        filterStorage: LoanRecommendFilterStorageProvider
    ) {
        this.api = api;
        this.specStorage = specStorage;
        this.filterStorage = filterStorage;
    }

    recommend(spec: LoanRecommendSpec, filter: LoanRecommendFilter): Observable<List<LoanRecommendResultSummary>> {
        return this.api.recommend(spec, filter);
    }

    recommendSimply(spec: LoanRecommendSpec, filter: LoanRecommendFilter): Observable<LoanRecommendResultSummary> {
        return this.api.recommendSimply(spec, filter);
    }

    compute(slug: string, spec: LoanRecommendSpec, filter: LoanRecommendFilter): Observable<LoanRecommendResult> {
        return this.api.compute(slug, spec, filter);
    }

    getCachedSpec(): Observable<LoanRecommendSpec> {
        return this.specStorage.get();
    }

    cacheSpec(spec: LoanRecommendSpec): Observable<void> {
        return this.specStorage.set(spec, true);
    }

    clearSpec(): Observable<void> {
        return this.specStorage.clear();
    }

    cacheFilter(filter: LoanRecommendFilter): Observable<void> {
        return this.filterStorage.set(filter, true);
    }

    clearFilter(): Observable<void> {
        return this.filterStorage.clear();
    }

    getCachedFilter(): Observable<LoanRecommendFilter> {
        return this.filterStorage.get();
    }

    cacheDesiredAmount(spec: LoanRecommendSpec): Observable<void> {
        return this.specStorage.set(spec);
    }
}
