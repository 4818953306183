import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { Amount } from 'core/entity/amount';
import { InsuranceCompanySummary } from 'core/entity/personal-finance/insurance-company/summary';
import { InsurancePrimaryData } from 'core/entity/personal-finance/primary-data/insurance';
import { PaymentCycle } from 'core/entity/personal-finance/insurance/payment-cycle';

export class UserInsurance extends Entity {
    constructor(
        public id: string,
        public name: string,
        public number: string,
        public company: InsuranceCompanySummary,
        public primaryData: InsurancePrimaryData,
        public deletedStatus: Asset.DeletedStatus,
        public hidden: boolean,
        public dormant: boolean,
        public closed: boolean,
        public nickname: string = null,
        public cancellationRefund: Amount = null,
        public paymentAmount: Amount = null,
        public totalPaidAmount: Amount = null,
        public paymentCycle: PaymentCycle = null,
        public description: string = null,
        public createdAt: Date = null,
        public expiredAt: Date = null
    ) {
        super();
    }
}
