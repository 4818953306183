import { Entity } from 'core/entity';
import { PrimaryData } from 'core/entity/personal-finance/primary-data';
import { SpiderStockData } from 'core/entity/personal-finance/spider-data/stock';

export class StockPrimaryData extends Entity implements PrimaryData {
    spider?: SpiderStockData;

    constructor(
        spider: SpiderStockData = null
    ) {
        super();
        this.spider = spider;
    }
}
