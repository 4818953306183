import { NetworkResponseMapper } from 'data/http/mapper';
import { Alfred2CardIssuingConfiguration } from 'core/entity/alfred2/card-issuing-configuration';
import { optional } from 'application/utils';

export class Alfred2CardIssuingConfigurationMapper implements NetworkResponseMapper<Alfred2CardIssuingConfiguration> {
    fromJson(json: any): Alfred2CardIssuingConfiguration {
        return new Alfred2CardIssuingConfiguration(
            json.url,
            optional(json.phone_number)
        );
    }
}
