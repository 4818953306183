import React from 'react';

import { SignInTextEditorModel } from 'presentation/components/users/base/text-editor/models';
import { KEY_CODE } from 'presentation/module/key-code';
import { optional, uniqueKey } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    signInTextEditor: SignInTextEditorModel;
    value: string;
    onChange: (value: string) => void;
    error?: boolean;
    onKeyDown?: () => void;
}

export const SignInTextEditor = ({
    signInTextEditor,
    value,
    error,
    onChange,
    onKeyDown,
}: Props) => {
    const id = uniqueKey();
    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
    const onKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === KEY_CODE.ENTER)
            onKeyDown ? onKeyDown() : e.currentTarget.blur();
    };
    const labelClassName =
        optional(error, it => (
            value.isEmpty() ?
                styles.default :
                it ?
                    styles.checked :
                    styles.unChecked
        ));

    return (
        <label
            htmlFor={ id }
            className={ labelClassName }
        >
            <input
                id={ id }
                value={ value }
                placeholder={ signInTextEditor.placeholder }
                type={ signInTextEditor.type }
                onChange={ onChangeInput }
                onKeyDown={ onKeyDownInput }
                className={ styles.input }
            />
        </label>
    )
};
