import { Entity } from 'core/entity';
import { Content } from 'core/entity/content';
import { ContentAuthorSummary } from 'core/entity/content/author/summary';
import { ContentCardComparisonArticle } from 'core/entity/content/card-comparison/article';
import { ContentMetaInformation } from 'core/entity/content/meta-information';
import { ContentSummary } from 'core/entity/content/summary';
import { ContentTagSummary } from 'core/entity/content/tag/summary';
import { PromotedContentSet } from 'core/entity/promoted-content-set';
import { List } from 'immutable';

export class ContentCardComparison extends Entity implements Content {
    id: string;
    title: string;
    description: string;
    author: ContentAuthorSummary;
    thumbnailImageUrl: string;
    createdAt: Date;
    updatedAt: Date;
    published: boolean;
    featured: boolean;
    tags: List<ContentTagSummary>;
    meta: ContentMetaInformation;
    article: ContentCardComparisonArticle;
    hot: boolean;
    label: string;
    relatedContents: List<ContentSummary>;
    promotedContentSets: List<PromotedContentSet>;

    constructor(
      id: string,
      title: string,
      description: string,
      author: ContentAuthorSummary,
      thumbnailImageUrl: string,
      createdAt: Date,
      updatedAt: Date,
      published: boolean,
      featured: boolean,
      tags: List<ContentTagSummary>,
      meta: ContentMetaInformation,
      article: ContentCardComparisonArticle,
      hot: boolean,
      label: string,
      relatedContents: List<ContentSummary>,
      promotedContentSets: List<PromotedContentSet>
    ) {
      super();
      this.id = id;
      this.title = title;
      this.description = description;
      this.author = author;
      this.thumbnailImageUrl = thumbnailImageUrl;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.published = published;
      this.featured = featured;
      this.tags = tags;
      this.meta = meta;
      this.article = article;
      this.hot = hot;
      this.label = label;
      this.relatedContents = relatedContents;
      this.promotedContentSets = promotedContentSets;
    }
}
