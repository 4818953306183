import React from 'react';

import styles from './styles.pcss';

interface Props {
    message: string,
    children: any
}

interface State {
    left: number,
    top: number
}

export class ToolTip extends React.Component<Props, State> {
    state = {
        left: 0,
        top: 0
    };

    render() {
        const { children, message } = this.props;
        const { left, top } = this.state;
        const messageStyle = left + 50 > window.innerWidth ?
            styles.reversedMessage : styles.message;

        return (
            <span
                onMouseMove={ e => this.setState({ left: e.clientX, top: e.clientY }) }
                className={ styles.wrap }
            >
                { children }
                <span
                    style={{ left: `${left - 20}px`, top: `${top + 14}px` }}
                    className={ styles.arrow }
                />
                <div
                    style={{ left: `${left - 135}px`, top: `${top + 10}px` }}
                    dangerouslySetInnerHTML={{ __html: message }}
                    className={ messageStyle }
                />
            </span>
        )
    }
}
