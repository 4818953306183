import { Entity } from 'core/entity';

export class UserCreditScoreChangeRecord extends Entity {
    title: string;
    collectionAgency: string;
    type: UserCreditScoreChangeRecord.Type;
    changedAt: Date;

    constructor(
        title: string,
        collectionAgency: string,
        type: UserCreditScoreChangeRecord.Type,
        changedAt: Date
    ) {
        super();
        this.title = title;
        this.collectionAgency = collectionAgency;
        this.type = type;
        this.changedAt = changedAt;
    }
}

export module UserCreditScoreChangeRecord {
    export enum Type {
        GENERAL = 'general',
        OVERDUE = 'overdue'
    }
}
