import { Entity } from 'core/entity';
import { List } from 'immutable';
import { PersonalizedInsuranceRecommendResultSummary } from 'core/entity/personalized-recommend/insurance/recommend-result/summary';

export class PersonalizedInsuranceRecommendedResult extends Entity {
    data: List<PersonalizedInsuranceRecommendResultSummary>;
    meta: string;

    constructor(
        data: List<PersonalizedInsuranceRecommendResultSummary>,
        meta: string
    ) {
        super();
        this.data = data;
        this.meta = meta;
    }
}
