import { Service } from 'core/service';
import { PersonalizedFinanceKCBLoanRepositoryType } from 'core/repository/personalized-finance/loan';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { PersonalizedFinanceKCBLoan } from 'core/entity/personalized-finance/loan/kcb';
import { PersonalizedFinanceKCBLoanSpec } from 'core/entity/personalized-finance/loan/kcb/spec';

export class PersonalizedFinanceLoanService implements Service {
    private repository: PersonalizedFinanceKCBLoanRepositoryType;

    constructor(
        repository: PersonalizedFinanceKCBLoanRepositoryType
    ) {
        this.repository = repository;
    }

    getAll = (): Observable<List<PersonalizedFinanceKCBLoan>> => this.repository.getAll();

    update = (id: number, spec: PersonalizedFinanceKCBLoanSpec): Observable<List<PersonalizedFinanceKCBLoan>> => (
        this.repository.update(id, spec)
    );
}
