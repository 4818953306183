import React from 'react';

import { Card } from 'core/entity/card';
import { CardQuestionCardType } from 'presentation/components/survey-form/card-type/model';
import { lets } from 'utils/index';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_EVENT_VALUE, GA_DOMAIN } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    data: CardQuestionCardType,
    onChange?: (result: CardQuestionCardType) => void
}

export const CardTypeForm = (props: Props) => {
    const CREDIT_CARD = 'form-card-type-credit';
    const CHECK_CARD = 'form-card-type-check';
    const HYBRID_CARD = 'form-card-type-hybrid';
    const { types } = props.data;

    const toGALabel = (type: Card.Type) => {
        switch (type) {
            case Card.Type.CREDIT:
                return '신용';
            case Card.Type.CHECK:
                return '체크';
            case Card.Type.HYBRID:
                return '하이브리드';
        }
    };

    const onChange = (type: Card.Type) => {
        props.onChange && props.onChange(new CardQuestionCardType(
            lets(type, it => {
                const contained = types.contains(type);
                sendGAEvent(
                    GA_DOMAIN.CARD,
                    GA_CATEGORY.CARDS_QUESTIONS.CARD_TYPE,
                    GA_ACTION.CHECKBOX,
                    toGALabel(type),
                    contained ? GA_EVENT_VALUE.UNCHECKED : GA_EVENT_VALUE.CHECKED
                );
                return contained ?
                    types.filter(item => item !== it).toList() :
                    types.push(type)
            })
        ));
    };

    return (
        <ul className={ styles.wrap }>
            <li>
                <input
                    type="checkbox"
                    id={ CREDIT_CARD }
                    checked={ types.contains(Card.Type.CREDIT) }
                    onChange={ () => onChange(Card.Type.CREDIT) }
                />
                <label htmlFor={ CREDIT_CARD }>신용</label>
            </li>
            <li>
                <input
                    type="checkbox"
                    id={ CHECK_CARD }
                    checked={ types.contains(Card.Type.CHECK) }
                    onChange={ () => onChange(Card.Type.CHECK) }
                />
                <label htmlFor={ CHECK_CARD }>체크</label>
            </li>
            <li>
                <input
                    type="checkbox"
                    id={ HYBRID_CARD }
                    checked={ types.contains(Card.Type.HYBRID) }
                    onChange={ () => onChange(Card.Type.HYBRID) }
                />
                <label htmlFor={ HYBRID_CARD }>하이브리드</label>
            </li>
        </ul>
    );
};
