import { Scheduler } from "rxjs/Rx";
import { Observable } from "rxjs/Observable";

declare module "rxjs/Observable" {
    export interface Observable<T> {
        useOnAnimateFrame(): Observable<T>
    }
}

Observable.prototype.useOnAnimateFrame = function() {
    return this
        .subscribeOn(Scheduler.async)
        .observeOn(Scheduler.animationFrame);
};
