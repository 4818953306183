import { CardCompanyCFAConfiguration } from 'core/entity/legal-configuration/card-company/cfa';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardCompanyCFAOverdueMapper } from 'data/http/mapper/legal-configuration/card-company/cfa/overdue';

export class CardCompanyCFAConfigurationMapper implements NetworkResponseMapper<CardCompanyCFAConfiguration> {
    fromJson(json: any): CardCompanyCFAConfiguration {
        return new CardCompanyCFAConfiguration(
            new CardCompanyCFAOverdueMapper().fromJson(json.overdue)
        )
    }

}
