import { SummarizedUserCardBills } from 'core/entity/personal-finance/summarized-card-bills';
import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderCardCompanySummaryMapper } from 'data/http/mapper/personal-finance/card-company/summary';
import { SummarizedUserCardBillMapper } from 'data/http/mapper/personal-finance/summarized-user-card-bill';
import { AmountMapper } from 'data/http/mapper/amount';
import { List } from 'immutable';

export class SummarizedUserCardBillsMapper implements NetworkResponseMapper<SummarizedUserCardBills> {
    fromJson(json: any): SummarizedUserCardBills {
        return new SummarizedUserCardBills(
            new AmountMapper().fromJson(json.total_amount),
            List(json.bills.map((bill: any) =>
                new SummarizedUserCardBillMapper().fromJson(bill)
            )),
            List(json.missing_card_companies.map((company: any) =>
                new SpiderCardCompanySummaryMapper().fromJson(company)
            ))
        )
    }

}
