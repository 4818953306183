import { Entity } from 'core/entity';
import { LoanCompanyType } from 'core/entity/personal-finance/loan-company/type';

export class LoanCompanySummary extends Entity {
    id: string;
    type: LoanCompanyType;
    name: string;
    imageUrl: string;

    constructor(
        id: string,
        type: LoanCompanyType,
        name: string,
        imageUrl: string
    ) {
        super();
        this.id = id;
        this.type = type;
        this.name = name;
        this.imageUrl = imageUrl;
    }
}
