import { Entity } from 'core/entity';

export class SpiderSecuritiesFirm extends Entity {
    id: string;
    name: string;
    imageUrl: string;
    isDefault: boolean;
    priority: number;
    code?: string;

    constructor(
        id: string,
        name: string,
        imageUrl: string,
        isDefault: boolean,
        priority: number,
        code?: string
    ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.isDefault = isDefault;
        this.priority = priority;
        this.code = code;
    }
}
