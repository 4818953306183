import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { AdviceInvestmentValuationData } from 'core/entity/personal-finance/advice/content-data/investment-value';
import { lets, optional } from 'utils/index';
import { AdviceInvestmentAssetValuationSnapshotMapper } from 'data/http/mapper/personal-finance/advice/investment-asset-valuation-snapshot';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceInvestmentValuationDataMapper implements NetworkResponseMapper<AdviceInvestmentValuationData> {
    fromJson(json: any): AdviceInvestmentValuationData {
        return new AdviceInvestmentValuationData(
            lets(json.period, it => {
                switch (it) {
                    case 'weekly':
                        return AdviceInvestmentValuationData.Period.WEEKLY;
                    case 'monthly':
                        return AdviceInvestmentValuationData.Period.MONTHLY;
                    default:
                        throw new MappingError();
                }
            }),
            json.valuation_history.map(new AdviceInvestmentAssetValuationSnapshotMapper().fromJson).toList(),
            optional(json.message, new AdviceMessageMapper().fromJson)
        )
    }

}
