import { Entity } from 'core/entity';

export class HealthInsuranceSubmitResponse extends Entity {
    url: string;

    constructor(
        url: string
    ) {
        super();
        this.url = url;
    }
}
