import { LoanLegalConfiguration } from 'core/entity/legal-configuration/loan';
import { Loan } from 'core/entity/loan';
import { LoanComputationResult } from 'core/entity/loan/computation-result';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { CreditLoanIssueAlert } from 'presentation/components/alert/credit-loan-issue';
import { CreditLoanIssueAlertModel } from 'presentation/components/alert/credit-loan-issue/model';
import { CreditLoanInfoCalculator } from 'presentation/components/info/credit-loan/calculator';
import { CreditLoanInfoDetail } from 'presentation/components/info/credit-loan/detail';
import { CreditLoanInfoProfits } from 'presentation/components/info/credit-loan/profits';
import { LoanLegalTerms } from 'presentation/components/legal-configuration/loan';
import { CreditLoanAmount } from 'presentation/components/survey-form/credit-loan-amount/model';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, GA_EVENT_VALUE, sendGAEvent } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import React from 'react';
import { optional } from 'utils/index';

import styles from './styles.pcss';
enum Tab {
    PROFITS = '예상 금리',
    DETAILS = '상품 정보'
}

interface Props {
    loan: Loan;
    legalConfig?: LoanLegalConfiguration;
    profits?: LoanComputationResult;
    amount?: CreditLoanAmount;
    repaymentPeriod?: LoanRecommendFilter.RepaymentPeriod;
}

interface State {
    alertPromotion: boolean;
    tab: Tab;
}

export class CreditLoanInfo extends React.Component<Props, State> {
    state = {
      alertPromotion: false,
      tab: this.props.profits ?
        Tab.PROFITS : Tab.DETAILS
    };

    render() {
      const { loan, legalConfig, profits, amount, repaymentPeriod } = this.props;
      const { alertPromotion, tab } = this.state;
      const totalInterest = optional(profits, it =>
        it.interestConfiguration.isRangeInterest() ?
          it.interestConfiguration.getMaxInterest() :
          it.interestConfiguration.getInterest()
      );

      return (
        <div className={ styles.wrap }>
          <div className={ styles.container }>
            <section className={ styles.box }>
              { this.renderHead(loan) }
              { loan.isInPromotion() && this.renderEvent(loan) }
              { this.renderTabs() }
              { this.renderContents(loan, profits, tab) }
            </section>
            <section className={ styles.calculator }>
              <CreditLoanInfoCalculator
                amount={ amount && amount.value }
                periodMonths={ repaymentPeriod && repaymentPeriod * 12 }
                interestRate={ profits && totalInterest }
                options={ loan.options }
              />
            </section>
            <section className={ styles.buttons }>
              <a
                href={ BANKSALAD_SITEMAP.CREDIT_LOAN_PROFITS }
                className={ styles.back }
              >
                결과 리스트로
              </a>
              {
                loan.isInPromotion() ?
                  <button
                    onClick={ () => {
                      this.onToggleIssueAlert();
                      this.gaIssueButton(loan, GA_CATEGORY.LOAN_PROFITS.BOTTOM, '해당금융사로이동');
                    } }
                    className={ styles.issue }
                  >
                    해당 금융사로 이동
                  </button> :
                  <a
                    href={ loan.getIssueUrl() }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={ styles.issue }
                    onClick={ () => this.gaIssueButton(loan, GA_CATEGORY.LOAN_PROFITS.BOTTOM, '해당금융사로이동') }
                  >
                    해당 금융사로 이동
                  </a>
              }
            </section>
            <section>
              <LoanLegalTerms config={ legalConfig } />
            </section>
            {
              loan.isInPromotion() ?
                <button
                  onClick={ () => {
                    this.onToggleIssueAlert();
                    this.gaIssueButton(loan, GA_CATEGORY.LOAN_PROFITS.FIXED, '해당금융사로이동');
                  } }
                  className={ styles.fixedIssueButton }
                >
                    해당 금융사로 이동
                </button> :
                <a
                  href={ loan.getIssueUrl() }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={ styles.fixedIssueButton }
                  onClick={ () => this.gaIssueButton(loan, GA_CATEGORY.LOAN_PROFITS.FIXED, '해당금융사로이동') }
                >
                    해당 금융사로 이동
                </a>
            }
          </div>
          {
            alertPromotion &&
            <CreditLoanIssueAlert
              alertModel={
                new CreditLoanIssueAlertModel(
                  loan.desktopIssueUrl,
                  loan.mobileIssueUrl,
                  loan.slug,
                  loan.organization,
                  loan.interestPromotion
                )
              }
              onCancel={ this.onToggleIssueAlert }
            />
          }
        </div>
      );
    }

    private renderHead = (loan: Loan) =>
      <div className={ styles.head }>
        <h2 className={ styles.companyImage }>
          <img src={ loan.organization.imageUrl } alt={ loan.organization.name } />
        </h2>
        <h1 className={ styles.name }>{ loan.name }</h1>
        {
          loan.isInPromotion() ?
            <button
              onClick={ () => {
                this.onToggleIssueAlert();
                this.gaIssueButton(loan, GA_CATEGORY.LOAN_PROFITS.CARD_HEAD, '신청하기');
              } }
              className={ styles.headIssue }
            >
                        신청하기
            </button> :
            <a
              href={ loan.getIssueUrl() }
              target="_blank"
              rel="noopener noreferrer"
              className={ styles.headIssue }
              onClick={ () => this.gaIssueButton(loan, GA_CATEGORY.LOAN_PROFITS.CARD_HEAD, '신청하기') }
            >
                        신청하기
            </a>
        }
      </div>;

    private renderEvent = (loan: Loan) => {
      return (
        <button
          onClick={ () => {
            this.onToggleIssueAlert();
            this.gaIssueButton(loan, GA_CATEGORY.LOAN_PROFITS.PROMOTION, '신청하기');
          } }
          className={ styles.event }
        >
          <strong className={ styles.eventTitle }>
            <span className={ styles.eventTag }><strong>EVENT</strong> 뱅크샐러드 고객만을 위한 특별혜택</span>
          </strong>
          <span className={ styles.eventInfo }>
            뱅크샐러드 <b>제휴코드 { loan.interestPromotion.code }</b> 입력하고<br/>
            { loan.interestPromotion.description }
          </span>
        </button>
      );
    };

    private renderTabs = () => {
      const { profits } = this.props;
      const { tab } = this.state;
      const tabs = profits ? [Tab.PROFITS, Tab.DETAILS] : [Tab.DETAILS];

      return (
        <ul className={ styles.tabs }>
          {
            tabs.map((it, i) =>
              <li
                key={ `tabs-${i}` }
                style={{ width: `${100 / tabs.length}%` }}
              >
                <button
                  className={ tab === it ? styles.activeTabButton : styles.tabButton }
                  onClick={ this.onChangeTab(it) }
                >
                  { it.toString() }
                </button>
              </li>
            )
          }
        </ul>
      );
    };

    private renderContents = (
      loan: Loan,
      profits: LoanComputationResult,
      tab: Tab
    ) =>
      <div>
        {
          profits &&
            <section className={ tab === Tab.PROFITS ? styles.tabBody : styles.hiddenTabBody }>
              <CreditLoanInfoProfits
                loan={ loan }
                profits={ profits }
              />
            </section>
        }
        <section className={ tab === Tab.DETAILS ? styles.tabBody : styles.hiddenTabBody }>
          <CreditLoanInfoDetail
            loan={ loan }
            showBestBanner={ !profits }
          />
        </section>
      </div>;

    private onChangeTab = (tab: Tab) => () => {
      sendGAEvent(
        GA_DOMAIN.LOANS, 
        GA_CATEGORY.LOAN_PROFITS.CARD_TAB, 
        GA_ACTION.TAB, tab.toString()
      );
      this.setState({ tab });
    };

    private onToggleIssueAlert = () =>
      this.setState(prevState => ({
        alertPromotion: !prevState.alertPromotion
      }));

    private gaIssueButton = (loan: Loan, category: string, word: string) =>
      sendGAEvent(
        GA_DOMAIN.LOANS,
        category,
        loan.isInPromotion() ? GA_ACTION.POPUP_ISSUE : GA_ACTION.ISSUE,
        `${word}_${loan.organization.type.toString()}_${loan.organization.name}_${loan.slug}`,
        loan.isInPromotion() ? GA_EVENT_VALUE.IN_PROMOTION : GA_EVENT_VALUE.NOT_IN_PROMOTION
      );
}
