import { Entity } from 'core/entity';

export class PromotionUrl extends Entity {
    constructor(
        public backgroundImage: string,
        public targetLink: string,
    ) {
        super();
    }
}
