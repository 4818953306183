import { NetworkResponseMapper } from 'data/http/mapper';
import { List } from 'immutable';
import { SummarizedUserSecuritiesFirmProducts } from 'core/entity/personal-finance/summarized-products/securities-firm';
import { SpiderSecuritiesFirmSummaryMapper } from 'data/http/mapper/personal-finance/securities-firm/summary';
import { UserSecuritiesFirmAccountMapper } from 'data/http/mapper/personal-finance/securities-firm-account';
import { UserFundMapper } from 'data/http/mapper/personal-finance/fund';
import { UserStockMapper } from 'data/http/mapper/personal-finance/stock';

export class SummarizedUserSecuritiesFirmProductsMapper implements NetworkResponseMapper<SummarizedUserSecuritiesFirmProducts> {
    fromJson(json: any): SummarizedUserSecuritiesFirmProducts {
        return new SummarizedUserSecuritiesFirmProducts(
            new SpiderSecuritiesFirmSummaryMapper().fromJson(json.securities_firm),
            List(json.securities_firm_accounts.map(new UserSecuritiesFirmAccountMapper().fromJson)),
            List(json.securities_firm_funds.map(new UserFundMapper().fromJson)),
            List(json.securities_firm_stocks.map(new UserStockMapper().fromJson))
        )
    }
}
