import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1PrivacyAgreementsView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 개인정보 수집ㆍ이용 동의</h3>
      <p>
                주식회사 레이니스트(이하 “회사”)는 이용자에게 뱅크샐러드 서비스(이하 “서비스”)의 회원가입, 서비스 제공, 원활한 고객상담 등을 위하여 서비스를 제공받는 자(이하 “이용자”)로부터 다음과 같은 정보를 수집합니다.
      </p>
      <ol>
        <li>
          <b>1. 수집하는 개인정보의 항목</b>
          <ol>
            <li>
              <b>가. 필수 항목</b>
              <p>- 이름, 이메일 주소, Facebook ID 또는 Google ID 식별자, 이동전화번호</p>
            </li>
            <li>
              <b>나. 선택 수집 항목</b>
              <p>- 수입, 지출 등 금융 거래 관련 금융/카드사 SMS 수신 내역(이용자 동의 시 서비스가 단말기에서 직접 수집)</p>
              <p>- 사용하는 신용/체크카드 및 금융상품명, 결제 일자/결제 상호명/결제 금액/결제 수단 등 소비 내역, 성별, 연령, 소득 정보 등 통계학적 정보(서비스 가입 후 이용자가 선택적으로 입력)</p>
            </li>
          </ol>
        </li>
        <li>
          <b>2. 개인정보의 수집ㆍ이용 목적</b>
          <p>- 이름, 이메일 주소, SNS서비스 Facebook의 ID 또는 Google ID 식별자, 이동전화번호를 수집하여 서비스 제공을 위한 회원 가입 및 관리에 이용</p>
          <p>- 이용자의 수입, 지출 등 금융 거래 관련 금융/카드사 SMS 수신 내역을 수집하고 사용하는 금융상품명을 확인하여 지출 관리와 소비 코칭 서비스 제공</p>
          <p>- 성별, 연령, 소득 정보 등 통계학적 특성 분석에 기반한 새로운 맞춤형 서비스 제공 및 이용자의 거래 내역, 금융 정보를 조합, 분석하여 개인 맞춤형 금융 상품 추천 제공</p>
          <p>- 기타 새로운 정보의 소개 및 고지사항 전달, 본인 의사 확인, 서비스에서의 본인 식별 또는 인증 등 이용자 관리, 서비스 관련 문의 및 불만사항 처리 등</p>
        </li>
        <li>
          <b>3. 개인정보의 보유 및 이용 기간</b>
          <p>개인정보는 기본적으로 이용자의 서비스의 이용 기간에만 활용되며, 회원 탈퇴 시 지체 없이 파기합니다. 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.</p>
          <ol>
            <li>
              <b>가. 부정 이용 방지를 위한 보유</b>
              <p>반복적인 회원 탈퇴 및 재가입으로 인한 경제적 이익 취득 등 불법,편법 행위 차단을 위해 회원 탈퇴 후 1년 동안 해당 회원의 뱅크샐러드 ID(Facebook의 ID 또는 Google ID 식별자, 이메일 주소), 탈퇴 일자 등을 1년간 보유</p>
            </li>
            <li>
              <b>나. 관련 법령에 의한 보유</b>
              <p>상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정에 의하여 일정 기간 정보를 보유해야 할 필요가 있을 경우 법령이 지정한 기간 동안 보유</p>
            </li>
          </ol>
        </li>
        <li>
          <b>4. 정보주체의 권리 안내</b>
          <p>회사는 이용자의 개인정보 수집 및 이용에 대한 별도의 동의 획득 절차를 두고 있습니다. 이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며 동의를 거부할 경우 받는 별도의 불이익은 없습니다. 단 필수 수집 항목에 대한 수집을 거부할 경우 서비스 이용이 불가능하며, 선택 수집 항목에 대한 수집을 거부할 경우 서비스 이용 목적에 따른 서비스 제공에 제한이 따르게 됩니다.</p>
        </li>
        <li>
          <b>5. 상기에 기재되지 않은 개인정보 취급과 관련된 일반 사항은 회사의 개인정보취급방침에 따릅니다.</b>
        </li>
      </ol>
      <p>본인은 본 개인정보 수집/이용에 대한 동의 내용을 자세히 숙지 및 이해하고, 이에 동의합니다.</p>
    </div>
  </div>
);

