import { Entity } from 'core/entity';
import { PersonalizedLoanInterestPromotion } from 'core/entity/personalized-recommend/loan/interest-promotion';

export class PersonalizedLoanInterest extends Entity {
    constructor(
        public status: PersonalizedLoanInterest.Status,
        public interestWording: string,
        public wording: string,
        public additionalWording: string = null,
        public loanInterestPromotion: PersonalizedLoanInterestPromotion = null
    ) {
        super();
    }
}

export module PersonalizedLoanInterest {
    export enum Status {
        CONFIRMED = 'confirmed',
        ESTIMATED = 'estimated',
        NEED_CONSULTING = 'need-consulting'
    }
}
