import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceMonthlyCardProfitSummary } from 'core/entity/personal-finance/advice/monthly-card-profit-summary';
import { AdviceMonthlyCardProfitMapper } from 'data/http/mapper/personal-finance/advice/monthly-card-profit';
import { AdviceUserCardSummaryMapper } from 'data/http/mapper/personal-finance/advice/user-card-summary';
import { optional } from 'utils/index';

export class AdviceMonthlyCardProfitSummaryMapper implements NetworkResponseMapper<AdviceMonthlyCardProfitSummary> {
    fromJson(json: any): AdviceMonthlyCardProfitSummary {
        return new AdviceMonthlyCardProfitSummary(
            new AdviceUserCardSummaryMapper().fromJson(json.user_card),
            optional(json.user_profit, new AdviceMonthlyCardProfitMapper().fromJson),
            optional(json.maximized_profit, new AdviceMonthlyCardProfitMapper().fromJson)
        );
    }
}
