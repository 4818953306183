import { PrimaryData } from 'core/entity/personal-finance/primary-data';
import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderDataMapper } from 'data/http/mapper/personal-finance/spider-data';
import { optional } from 'utils/index';

export class PrimaryDataMapper implements NetworkResponseMapper<PrimaryData> {
    fromJson(json: any): PrimaryData {
        return new PrimaryData(
            optional(json.spider, it => new SpiderDataMapper().fromJson(it))
        )
    }

}
