import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { Loan } from 'core/entity/loan';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { LoanRecommendResultSummary } from 'core/entity/loan/recommend-result/summary';
import { LoanRecommendResult } from 'core/entity/loan/recommend-result';
import { HttpProvider } from 'data/http';
import { LoanMapper } from 'data/http/mapper/loan';
import { LoanRecommendSpecMapper } from 'data/http/mapper/loan/recommend-spec';
import { LoanRecommendResultSummaryMapper } from 'data/http/mapper/loan/recommend-result/summary';
import { LoanRecommendResultMapper } from 'data/http/mapper/loan/recommend-result';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanRecommendFilterMapper } from 'data/http/mapper/loan/recommend-filter';

export class LoanApiProvider extends HttpProvider {
    get(slug: string): Observable<Loan> {
        return this.requestGET(
            `api/v2/loans/${slug}`
        ).map((result: any) =>
            new LoanMapper().fromJson(result.data)
        );
    }

    recommendSimply(spec: LoanRecommendSpec, filter: LoanRecommendFilter): Observable<LoanRecommendResultSummary> {
        return this.requestPOST(
            'api/v2/loans/interview-recommend',
            {
                survey: new LoanRecommendSpecMapper().toJson(spec),
                filter: new LoanRecommendFilterMapper().toJson(filter)
            }
        ).map((result: any) =>
            new LoanRecommendResultSummaryMapper().fromJson(result.data)
        );
    }

    recommend(spec: LoanRecommendSpec, filter: LoanRecommendFilter): Observable<List<LoanRecommendResultSummary>> {
        return this.requestPOST(
            'api/v2/loans/recommend',
            {
                survey: new LoanRecommendSpecMapper().toJson(spec),
                filter: new LoanRecommendFilterMapper().toJson(filter)
            }
        ).map((result: any) =>
            List(result.data.map((item: any) =>
                new LoanRecommendResultSummaryMapper().fromJson(item)
            ))
        );
    }

    compute(slug: string, spec: LoanRecommendSpec, filter: LoanRecommendFilter): Observable<LoanRecommendResult> {
        return this.requestPOST(
            `api/v2/loans/${slug}/compute`,
            {
                survey: new LoanRecommendSpecMapper().toJson(spec),
                filter: new LoanRecommendFilterMapper().toJson(filter)
            }
        ).map((result: any) =>
            new LoanRecommendResultMapper().fromJson(result.data)
        );
    }
}
