import { List } from 'immutable';
import { CardCompany } from 'core/entity/card/company';
import { CardCompanyMapper } from 'data/http/mapper/card/company';
import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';

export class CardCompaniesApiProvider extends HttpProvider {
    get(contracted: boolean = null): Observable<List<CardCompany>> {
        return this.requestGET(
            'api/v1/cards/companies'
        ).map((result: any) =>
            List(result.map((item: any) =>
                new CardCompanyMapper().fromJson(item)
            ).filter((cardCompany: CardCompany) =>
                contracted === null || cardCompany.contracted === contracted
            ))
        );
    }
}
