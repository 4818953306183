import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanInterestPromotion } from 'core/entity/loan/interest-promotion';
import { parseDate, optional } from 'utils/index';
import { LoanInterestValueMapper } from 'data/http/mapper/loan/interest-value';

export class LoanInterestPromotionMapper implements NetworkResponseMapper<LoanInterestPromotion> {
    fromJson(json: any): LoanInterestPromotion {
        return new LoanInterestPromotion(
            json.code,
            new LoanInterestValueMapper().fromJson(json.interest),
            parseDate(json.start_date),
            optional(json.end_date, it => parseDate(it)),
            optional(json.description, it => it)
        )
    }
}
