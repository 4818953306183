import {
    NetworkRequestMapper,
    NetworkResponseMapper
} from 'data/http/mapper';
import { LoanCreditScoreAgency } from 'core/entity/loan/credit-score-agency';

export class LoanCreditScoreAgencyMapper implements
    NetworkRequestMapper<LoanCreditScoreAgency>,
    NetworkResponseMapper<LoanCreditScoreAgency>
{
    fromJson(json: any): LoanCreditScoreAgency {
        switch (json) {
            case 'kcb':
                return LoanCreditScoreAgency.KCB;
            case 'nice':
                return LoanCreditScoreAgency.NICE;
            case 'bank_custom':
                return LoanCreditScoreAgency.BANK_CUSTOM;
            case 'banksalad':
                return LoanCreditScoreAgency.BANKSALAD;
            default:
                return LoanCreditScoreAgency.KCB;
        }
    }

    toJson(target: LoanCreditScoreAgency): any {
        switch (target) {
            case LoanCreditScoreAgency.KCB:
                return 'kcb';
            case LoanCreditScoreAgency.NICE:
                return 'nice';
            case LoanCreditScoreAgency.BANK_CUSTOM:
                return 'bank_custom';
            case LoanCreditScoreAgency.BANKSALAD:
                return 'banksalad';
            default:
                return 'kcb';
        }
    }

}
