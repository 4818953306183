import { detect } from 'bowser';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { User } from 'core/entity/user';
import { AuthenticationType } from 'presentation/routes/users/view-model';

export const BANKSALAD_SITEMAP = {
  MAIN: '/',

  PFM_MY_FINANCE: '/me',
  PFM_TRANSACTIONS: '/me/transactions',
  PFM_SETTINGS: '/me/settings',
  PFM_SETTINGS_PRODUCTS: '/me/settings/products',
  PFM_SETTINGS_CATEGORIES: '/me/settings/categories',
  PFM_SETTINGS_PRIVACY: '/me/settings/privacy',
  PFM_SETTINGS_SECURITY_SERVICE: '/me/settings/security-service',
  PFM_SETTINGS_CUSTOMER_SERVICE: '/me/settings/customer-service',

  MY_CREDIT: '/me/credit',
  MY_CREDIT_INQUIRY_RECORDS: '/me/credit/inquiry-records',
  MY_CREDIT_CHANGE_RECORDS: '/me/credit/change-records',
  MY_CREDIT_STATUS: '/me/credit/status',
  MY_CREDIT_STATUS_CARDS: '/me/credit/status/cards',
  MY_CREDIT_STATUS_LOANS: '/me/credit/status/loans',
  MY_CREDIT_STATUS_OVERDUES: '/me/credit/status/overdue',
  MY_CREDIT_STATUS_JOINT_SURETIES: '/me/credit/status/joint-sureties',

  MY_PERSONALIZED_RECOMMEND_LOAN_GENERAL: '/me/credit-loans/general',
  MY_PERSONALIZED_RECOMMEND_LOAN_REFINANCE: '/me/credit-loans/refinance',

  SECTORS_CARD: '/sectors/card',
  SECTORS_BANK: '/sectors/bank',
  SECTORS_INVESTMENT: '/sectors/investment',
  SECTORS_LOAN: '/sectors/loan',
  SECTORS_ANNUITY: '/sectors/annuity',
  SECTORS_FINTECH: '/sectors/fintech',
  SECTORS_MAGAZINE: '/sectors/magazine',

  CARDS_PROMOTIONS_BEST_CREDIT_CARDS: '/cards/promotions/best-credit-cards',
  CARDS_HOW_IT_WORKS: '/cards/how-it-works',
  CARDS_QUESTIONS: '/cards/questions',
  CARDS_PROFITS: '/cards/profits',
  CARDS_THEMES: '/cards/themes',
  CARDS_PROMOTION_ANNUAL_FEE: '/cards/promotion/annual-fee',
  CARDS_EVENTS: '/cards/events',
  CARDS: '/cards',

  CONTENTS: '/contents',
  CONTENTS_AUTHORS: '/contents/authors',
  CONTENTS_CONTRIBUTION: '/contents/contribution',

  CREDIT_LOAN_QUESTIONS: '/credit-loans/questions',
  CREDIT_LOAN_PROFITS: '/credit-loans/profits',

  PAGE_NOT_FOUND: '/errors/page-not-found',
  ERROR: '/errors/page-on-error',

  POLICIES_TERMS_RECENT: 'https://policies.banksalad.com/뱅크샐러드/서비스-이용-약관',
  POLICIES_PRIVACY_POLICY_RECENT: 'https://policies.banksalad.com/뱅크샐러드/개인정보처리방침',
  POLICIES_PRIVACY_AGREEMENT_RECENT: '/policies/v4/privacy-agreement',
  POLICIES_SUBSCRIPTION_AGREEMENT_RECENT: '/policies/v1/subscription-agreement',
  POLICIES_KCB_PRIVACY: 'https://policies.banksalad.com/본인인증/KCB-고유-식별-정보-수집-동의',
  POLICIES_KCB_TERMS: 'https://policies.banksalad.com/본인인증/통신사-이용-약관-동의',
  POLICIES_KCB_AGREEMENT: 'https://policies.banksalad.com/본인인증/KCB-개인정보-수집-동의',
  POLICIES_KCB_PERSONALITY: 'https://policies.banksalad.com/본인인증/본인확인-서비스-이용-동의',
  POLICIES_KCB_ALL_CREDIT_RECENT: '/policies/v1/kcb-all-credit',
  POLICIES_KCB_THIRD_PARTY_RECENT: '/policies/v1/kcb-third-party',
  POLICIES_KCB_CREDIT_AGREEMENT_RECENT: '/policies/v1/kcb-credit-agreement',
  POLICIES_KCB_CREDIT_THIRD_PARTY_RECENT: '/policies/v1/kcb-credit-third-party',
  POLICIES_THIRD_PARTIES_KCB_CREDIT_GOAL_RECENT: '/policies/v1/third-parties/kcb/credit-goal',
  POLICIES_THIRD_PARTIES_EIGHT_PERCENT_SUBMIT_DOCUMENT_RECENT:
    '/policies/v1/third-parties/eight-percent/submit-document',
  POLICIES_PRIVACY_AGREEMENTS_SUBMIT_DOCUMENT_RECENT:
    '/policies/v1/privacy-agreements/submit-document',
  POLICIES_PRIVACY_AGREEMENTS_CREDIT_GOAL_RECENT: '/policies/v1/privacy-agreements/credit-goal',
  POLICIES_PRIVACY_AGREEMENTS_EXCEL_EXPORT: '/policies/v1/privacy-agreements/excel-export',
  POLICIES_ELECTRONIC_FINANCIAL_TRANSACTIONS:
    'https://policies.banksalad.com/송금/뱅샐머니-이용약관',

  LOGIN: '/users/login',

  SIGN_UP: '/users/sign-up',
  SIGN_UP_VIA_EMAIL: '/users/sign-up/email',
  SIGN_UP_VIA_FACEBOOK: '/users/sign-up/email',
  SIGN_UP_VIA_MOBILE_AUTHENTICATION: '/users/sign-up/mobile-authentication',
  SIGN_UP_POLICY: (type: AuthenticationType) => `/users/sign-up/policy?goto=${type}`,
  SUSPEND_ACCOUNT: '/users/suspend-account',
  SUSPEND_ACCOUNT_PASSWORD: '/users/suspend-account/password',
  SUSPEND_ACCOUNT_SUCCESS: '/users/suspend-account/success',
  SEARCH: '/search',
  SERVICE_CENTER: '/support',

  TOKEN_EXPIRED: '/info/token-expired',
  SUSPENDED: '/info/suspended',

  NOTICE: '/notices',

  CERT: 'https://app.banksalad.com',

  GOOGLE_PLAYSTORE: 'https://play.google.com/store/apps/details?id=com.rainist.banksalad2',
  APPLE_STORE: 'https://itunes.apple.com/app/apple-store/id1195804784',

  LOAN_IS_MY_SIDE: 'https://go.banksalad.com/loan-is-myside',

  FEE_GUIDANCE: 'https://policies.banksalad.com/송금/뱅샐머니-이용안내',
  BANKSALAD_MONEY_SUSPENSION: 'https://www.banksalad.com/users/suspend-account',

  PREQUALIFICATION_LOANS_CREDIT: 'https://www.banksalad.com/prequalification/loans/credit',
  PREQUALIFICATION_LOANS_EVENT_CASHBACK_CREDIT:
    'https://www.banksalad.com/prequalification/loans/event/cashback?loan-type=credit',
  PREQUALIFICATION_LOANS_EVENT_CASHBACK_MORTGAGE:
    'https://www.banksalad.com/prequalification/loans/event/cashback?loan-type=mortgage',
  CHART_LOANS_SORT_TYPE_AVERAGE_INTEREST_RATE_ASC:
    'https://www.banksalad.com/chart/loans?sortType=averageInterestRateAsc',
  PREQUALIFICATION_LOANS_MORTGAGE: 'https://www.banksalad.com/prequalification/loans/mortgage',
  CHART_CARDS_BEST_CREDIT_CARDS:
    'https://www.banksalad.com/chart/cards?tab=event',
  FREE_GENETIC_TESTING:
    'https://www.banksalad.com/health/dtc-genetic-testing?utm_medium=footer&param_media_type=owned&param_bridge_page_containing_link=bridge-health-promotion-dtc&utm_source=BS_web',
  FREE_ORAL_MICROBIOME_TESTING:
    'https://www.banksalad.com/health/oral-microbiome-testing?utm_medium=footer&param_media_type=owned&param_bridge_page_containing_link=bridge-health-oral-microbiome&utm_source=BS_web',

  BANKSALAD_SAFETY_CENTER: 'https://help.banksalad.com/고객안심센터',
};

export const BANKSALAD_SITEMAP_BY_ID = {
  CARD: (id: number) => `/cards/${id}`,
  CARD_PROFITS: (id: number) => `/cards/${id}/profits`,
  CARD_THEME: (id: string) => `/cards/themes/${id}`,
  CARD_EVENT: (id: string) => `/cards/events/${id}`,
  CARD_ISSUE: (id: number) => `/cards/${id}/issue`,
  CONTENT: (id: number | string) => `/contents/${id}`,
  CONTENTS_TAG: (id: string) => `/contents/tags/${id}`,
  AUTHOR: (id: string) => `/contents/authors/${id}`,
  USER: (id: number) => `/users/${id}`,
  SECTORS: (id: FinanceSectorID) => `/sectors/${id}`,
  NOTICE: (id: number) => `/notices/${id}`,
  SERVICE_CENTER_CATEGORIES: (id: number) => `/service-center/categories/${id}`,
  SERVICE_CENTER_ANSWERS: (id: number) => `/service-center/answers/${id}`,
};

export const OUTBOUND_LINK = {
  MAIL_TO_BANKSALAD: (title: string = null, body: string = null) =>
    `mailto:hello@banksalad.com?subject=${title}&body=${body}`,
  MAIL_TO_RAINIST: 'mailto:banksalad@banksalad.com',
  MAIL_TO_CM: 'mailto:cs@rainist.com',
  FACEBOOK_SHARE: (url: string) => `http://www.facebook.com/share.php?u=${url}`,
  TWITTER_SHARE: (title: string, url: string) =>
    `https://twitter.com/intent/tweet?text=${title}&url=${url}@banksalad&hashtags=뱅크샐러드,뱅샐`,
  NAVER_SHARE: (title: string, url: string) =>
    `http://share.naver.com/web/shareView.nhn?url=${encodeURI(
      encodeURIComponent(url)
    )}&title=${encodeURI(title)}`,
  BAND_SHARE: (title: string, url: string) =>
    `http://band.us/plugin/share?body=${encodeURI(`${title} ${url} #뱅크샐러드`)}&route=${encodeURI(
      encodeURIComponent('banksalad.com')
    )}`,
  KFB: 'http://www.kfb.or.kr/info/interest_loan.html?S=FAC&year=2017&month=07&opt_1=3&opt_2=&sort_name=bank_hname&sort_state=asc&goods=&m=graph&cols=',

  BANKSALAD_FACEBOOK: 'https://www.facebook.com/BanksaladTip',
  BANKSALAD_INSTAGRAM: 'https://www.instagram.com/banksalad',
  BANKSALAD_YOUTUBE: 'https://www.youtube.com/@banksalad',
  BANKSALAD_BLOG: 'http://blog.naver.com/banksalad',
  BANKSALAD_KAKAO_PLUS: 'http://plus.kakao.com/home/@뱅크샐러드',
  FACEBOOK_USER_GROUP: 'https://www.facebook.com/groups/banksalad',

  ADVICE_FEEDBACK: 'https://goo.gl/forms/Eut3VV88SszGdvd62',

  FOOTER_UTM: {
    CORP_BANKSALAD:
      'https://corp.banksalad.com?utm_source=BS_WEB&utm_medium=BS_WEB_footer&utm_campaign=BS_WEB_footer_corp',
    BANKSALAD_BLOG:
      'https://blog.banksalad.com?utm_source=BS_WEB&utm_medium=BS_WEB_footer&utm_campaign=BS_WEB_footer_blog',
    APP_STORE: 'https://go.onelink.me/xMM7/e0e4992e',
    APPLE_STORE: 'https://banksalad.page.link/qpGw',
    GOOGLE_PLAY_STORE: 'https://banksalad.page.link/ePNA',
    CERT: 'https://app.banksalad.com/?utm_source=BS_WEB_footer&utm_medium=BS_WEB_footer&utm_campaign=170404_APP_certification_page',
  },

  APPLE_STORE_UTM: {
    DEFAULT: 'https://itunes.apple.com/app/apple-store/id1195804784',
    MY_FINANCE_GOAL:
      'https://itunes.apple.com/app/apple-store/id1195804784?pt=1900806&ct=171214_owned_banksaladweb_mypage_goal&mt=8', // MY금융 > 목표
  },

  GOOGLE_PLAYSTORE_UTM: {
    DEFAULT: 'https://play.google.com/store/apps/details?id=com.rainist.banksalad2',
    MY_FINANCE_GOAL:
      'https://play.google.com/store/apps/details?id=com.rainist.banksalad2&referrer=utm_source%3Downed%26utm_medium%3Downed_banksaladweb_mypage%26utm_campaign%3D171214_owned_banksaladweb_mypage_goal', // MY금융 > 목표
  },
};

export const linkToInquiryByMail = (user: User) => {
  const browser = detect(navigator.userAgent);
  const mailTitle = `[의견보내기] ${user.name}님의 소중한 의견`;
  const mailBody = `화면을 캡쳐하여 보내주시면 오류 확인에 도움이 됩니다.%0A%0A%0A%0A\
[사용자 정보]%0A\
가입된 이메일 주소: ${user.account.email}%0A\
브라우저: ${browser.name} ${browser.version}%0A\
OS: ${(browser as any).osname} ${browser.osversion}%0A\
어떤 부분이 궁금하신지 알려주세요!`;

  return OUTBOUND_LINK.MAIL_TO_BANKSALAD(mailTitle, mailBody);
};
