import { ContentSummary } from 'core/entity/content/summary';
import { List } from 'immutable';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import React from 'react';

import styles from './styles.pcss';

interface Props {
    contents: List<ContentSummary>;
}

export const ContentsNewMagazines = ({ contents }: Props) =>
  <ul className={ styles.list }>
    {
      contents.map(content =>
        <li
          key={ content.id }
          className={ styles.item }
        >
          <a
            href={ BANKSALAD_SITEMAP_BY_ID.CONTENT(content.id) }
            onClick={ () => 
              sendGAEvent(
                GA_DOMAIN.NONE, 
                GA_CATEGORY.SECTORS.MAGAZINE.NEW, 
                GA_ACTION.LINK.CONTENTS_DETAIL, `${content.title}_${content.id}`
              ) 
            }
            className={ styles.link }
          >
            <div
              style={{
                backgroundImage: `url(${content.thumbnailImageUrl})`
              }}
              className={ styles.background }
            />
            <div className={ styles.info }>
              <strong className={ styles.author }>
                { content.author.profile.name }
              </strong>
              <h3 className={ styles.title }>
                { content.title }
              </h3>
            </div>
          </a>
        </li>
      )
    }
  </ul>;
