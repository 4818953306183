import { CardCompanyLegalConfiguration } from 'core/entity/legal-configuration/card-company';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardCompanyCFAConfigurationMapper } from 'data/http/mapper/legal-configuration/card-company/cfa';

export class CardCompanyLegalConfigurationMapper implements NetworkResponseMapper<CardCompanyLegalConfiguration> {
    fromJson(json: any): CardCompanyLegalConfiguration {
        return new CardCompanyLegalConfiguration(
            json.id,
            new CardCompanyCFAConfigurationMapper().fromJson(json.cfa),
            json.disabled_for_compliance
        )
    }

}
