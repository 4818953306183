import { LoanLegalConfiguration } from 'core/entity/legal-configuration/loan';
import { LoanLegalConfigurationRepositoryType } from 'core/repository/loan-legal-configuration';
import { LoanLegalConfigurationApiProvider } from 'data/http/api/gateway/v3/loans/legal-configuration';
import { Observable } from 'rxjs/Rx';

export class LoanLegalConfigurationRepository implements LoanLegalConfigurationRepositoryType {
    private api: LoanLegalConfigurationApiProvider;

    constructor(api: LoanLegalConfigurationApiProvider) {
        this.api = api;
    }

    getByLoanId(id: number): Observable<LoanLegalConfiguration> {
        return this.api.getByLoanId(id);
    }

}
