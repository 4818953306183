import { NetworkResponseMapper } from 'data/http/mapper';
import { List } from 'immutable';
import { ContentTagCategoryMapper } from 'data/http/mapper/content/tag/category';
import { ContentTag } from 'core/entity/content/tag';
import { ContentSummaryMapper } from 'data/http/mapper/content/summary';

export class ContentTagMapper implements NetworkResponseMapper<ContentTag> {
    fromJson(json: any): ContentTag {
        return new ContentTag(
            json.id,
            json.name,
            json.image_url,
            new ContentTagCategoryMapper().fromJson(json.category),
            json.featured,
            List(json.featured_contents).map(content => new ContentSummaryMapper().fromJson(content)).toList()
        )
    }
}
