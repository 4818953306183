import React from 'react';

import { Radio } from 'presentation/components/input/radio';
import { CheckBox } from 'presentation/components/input/check-box';
import { CreditLoanProfitsStateModel } from 'presentation/routes/credit-loans/profits/model';
import { LoanOrganizationType } from 'core/entity/loan/organization/type';
import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';
import {
    CREDIT_LOAN_INTEREST_TYPES_PRESET,
    CREDIT_LOAN_ORGANIZATION_TYPES_PRESET,
    CREDIT_LOAN_REPAYMENT_TYPES_PRESET
} from 'presentation/routes/credit-loans/profits/preset';
import { CreditLoanAdditionalInfoAlertType } from 'presentation/components/alert/credit-loan-additional-info/model';
import { LoanInterviewHistory } from 'core/entity/loan/interview-history';
import { List } from 'immutable';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { emptyList } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    state: CreditLoanProfitsStateModel;
    onToggleInfoAlert: (infoAlertType?: CreditLoanAdditionalInfoAlertType) => () => void;
    onChange: (state: CreditLoanProfitsStateModel) => void;
}

export class CreditLoansProfitsFilter extends React.Component<Props> {
    render() {
        const {
            state,
            onToggleInfoAlert
        } = this.props;

        return (
            <div className={ styles.filter }>
                <div className={ styles.wrap }>
                    <dl className={ styles.filterList }>
                        <dt>
                            신용대출 종류
                            <button
                                onClick={ onToggleInfoAlert(CreditLoanAdditionalInfoAlertType.LOAN_CATEGORY_TYPE) }
                                className={ styles.questionMark }
                            />
                        </dt>
                        <dd>
                            <ul className={ styles.typeFilter }>
                                <li className={ styles.typeFilterItem }>
                                    <Radio
                                        checked={ !state.minusAccount }
                                        text="일반대출"
                                        name="type"
                                        color="blueberry"
                                        gaCategory={ GA_CATEGORY.LOANS_PROFITS.FILTER_LOAN_TYPE }
                                        onChange={ this.onChangeGeneralCredit }
                                    />
                                </li>
                                <li className={ styles.typeFilterItem }>
                                    <Radio
                                        checked={ state.minusAccount }
                                        text="마이너스통장"
                                        name="type"
                                        color="blueberry"
                                        gaCategory={ GA_CATEGORY.LOANS_PROFITS.FILTER_LOAN_TYPE }
                                        onChange={ this.onChangeMinusAccount }
                                    />
                                </li>
                            </ul>
                        </dd>
                        <dt>
                            금융사
                            <button
                                onClick={ this.onChangeOrganizationType() }
                                className={ styles.selectAll }
                            >
                                전체선택
                            </button>
                        </dt>
                        <dd>
                            <ul className={ styles.corporationFilter }>
                                {
                                    CREDIT_LOAN_ORGANIZATION_TYPES_PRESET.map((types, i) => {
                                        const checked =
                                            types.filter(type => state.organizationTypes.contains(type)).size === types.size;

                                        return (
                                            <li key={ `types-${i}` } className={ styles.corporationFilterItem  }>
                                                <CheckBox
                                                    checked={ checked }
                                                    text={ types.join(' 및 ') }
                                                    gaCategory={ GA_CATEGORY.LOANS_PROFITS.FILTER_ORGANIZATION_TYPE }
                                                    onChange={ this.onChangeOrganizationType(types) }
                                                />
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </dd>
                        <dt className={ styles.etcFilterTerm }>기타필터</dt>
                        <dd>
                            <ul className={ styles.etcFilter }>
                                <li className={ styles.etcFilterItem  }>
                                    <CheckBox
                                        checked={ state.inPromotion }
                                        text="뱅크 샐러드 이벤트 상품만 보기"
                                        onChange={ this.onChangeInPromotion }
                                    />
                                    <span className={ styles.filterEvent }>EVENT</span>
                                </li>
                                <li className={ styles.etcFilterItem  }>
                                    <CheckBox
                                        checked={ state.dayLoan }
                                        text="당일 대출상품만 보기"
                                        gaCategory={ GA_CATEGORY.LOANS_PROFITS.FILTER_DAY_LOAN }
                                        onChange={ this.onChangeDayLoan }
                                    />
                                </li>
                                <li className={ styles.etcFilterItem  }>
                                    <CheckBox
                                        checked={ state.exemptReimbursementFees }
                                        text="중도상환수수료 면제상품만 보기"
                                        gaCategory={ GA_CATEGORY.LOANS_PROFITS.FILTER_EXEMPT_REIMBURSEMENT_FEES }
                                        onChange={ this.onChangeExemptReimbursementFees }
                                    />
                                </li>
                            </ul>
                        </dd>
                        <dt>
                            금리방식
                            <button
                                onClick={ onToggleInfoAlert(CreditLoanAdditionalInfoAlertType.INTEREST_TYPE) }
                                className={ styles.questionMark }
                            />
                        </dt>
                        <dd>
                            <ul className={ styles.interestFeeFilter }>
                                {
                                    CREDIT_LOAN_INTEREST_TYPES_PRESET.map(type =>
                                        <li key={ type } className={ styles.interestFeeFilterItem  }>
                                            <CheckBox
                                                checked={ state.interestTypes.contains(type) }
                                                text={ type }
                                                gaCategory={ GA_CATEGORY.LOANS_PROFITS.FILTER_INTEREST_TYPE }
                                                onChange={ this.onChangeInterestType(type) }
                                            />
                                        </li>
                                    )
                                }
                            </ul>
                        </dd>
                        <dt>
                            상환방법
                            <button
                                onClick={ onToggleInfoAlert(CreditLoanAdditionalInfoAlertType.REPAYMENT_TYPE) }
                                className={ styles.questionMark }
                            />
                        </dt>
                        <dd>
                            <ul className={ styles.redeemFilter }>
                                {
                                    CREDIT_LOAN_REPAYMENT_TYPES_PRESET.map(type => <li key={ type } className={ styles.redeemFilterItem  }>
                                        <CheckBox
                                            checked={ state.repaymentTypes.contains(type) }
                                            text={ type }
                                            gaCategory={ GA_CATEGORY.LOANS_PROFITS.REPAYMENT_TYPE }
                                            onChange={ this.onChangeRepaymentType(type) }
                                        />
                                    </li>
                                    )
                                }
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div className={ styles.wrap }>
                    <dl className={ styles.filterList }>
                        <dt>
                            <strong className={ styles.historyTitle }>우대금리 적용</strong>
                            <p className={ styles.historyDescription }>
                                아래 실적을 채울경우 받을 수 있는 가장 낮은 금리의
                                대출상품을 보여드립니다
                            </p>
                        </dt>
                        <dd>
                            <ul className={ styles.historyFilter }>
                                {
                                    state.displayHistories.map(history =>
                                        <li key={ history.id } className={ styles.historyFilterItem }>
                                            <CheckBox
                                                checked={ !!state.histories.find(it => it.id === history.id) }
                                                text={ history.name }
                                                gaCategory={ GA_CATEGORY.LOANS_PROFITS.HISTORIES }
                                                onChange={ this.onChangeHistory(history) }
                                            />
                                        </li>
                                    )
                                }
                            </ul>
                        </dd>
                    </dl>
                </div>
            </div>
        )
    }

    private onChangeGeneralCredit = () => this.props.onChange({
        ...this.props.state,
        minusAccount: false
    });

    private onChangeMinusAccount = () => this.props.onChange({
        ...this.props.state,
        minusAccount: true
    });

    private onChangeOrganizationType = (types?: List<LoanOrganizationType>) => () => {
        const flattenSelectedTypes = types ? types.flatten().toList() : emptyList();
        const originTypes = this.props.state.organizationTypes;
        const filteredTypes = this.props.state.organizationTypes.filter(type => !flattenSelectedTypes.contains(type)).toList();
        const organizationTypes: List<LoanOrganizationType> =
            !!types ?
                filteredTypes.size === originTypes.size ? originTypes.concat(flattenSelectedTypes).toList() : filteredTypes :
                CREDIT_LOAN_ORGANIZATION_TYPES_PRESET.flatten().toList();

        !!types || sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.FILTER_ORGANIZATION_TYPE, GA_ACTION.ALL, '전체선택');
        this.props.onChange({
            ...this.props.state,
            organizationTypes
        })
    };

    private onChangeDayLoan = (checkedState: boolean) => this.props.onChange({
        ...this.props.state,
        dayLoan: checkedState
    });

    private onChangeExemptReimbursementFees = (checkedState: boolean) => this.props.onChange({
        ...this.props.state,
        exemptReimbursementFees: checkedState
    });

    private onChangeInPromotion = (inPromotion: boolean) => this.props.onChange({
        ...this.props.state,
        inPromotion: inPromotion
    });

    private onChangeInterestType = (type: LoanInterestType) => () => {
        const index = this.props.state.interestTypes.indexOf(type);

        this.props.onChange({
            ...this.props.state,
            interestTypes: index !== -1 ?
                this.props.state.interestTypes.remove(index) :
                this.props.state.interestTypes.push(type)
        })
    };

    private onChangeRepaymentType = (type: LoanRepaymentType) => () => {
        const index = this.props.state.repaymentTypes.indexOf(type);

        this.props.onChange({
            ...this.props.state,
            repaymentTypes: index !== -1 ?
                this.props.state.repaymentTypes.remove(index) :
                this.props.state.repaymentTypes.push(type)
        })
    };

    private onChangeHistory = (history: LoanInterviewHistory) => () => {
        const index = this.props.state.histories.findIndex(it => it.id === history.id);

        this.props.onChange({
            ...this.props.state,
            histories: index !== -1 ?
                this.props.state.histories.remove(index) :
                this.props.state.histories.push(history)
        })
    }
}
