export const Color = {
    romaine50: '#F3FDFA',
    romaine100: '#DFFDF3',
    romaine200: '#92FAD7',
    romaine250: '#7CF5CD',
    romaine300: '#3AE8AE',
    romaine350: '#10DF99',
    romaine400: '#04C584',
    romaine500: '#009D75',
    romaine600: '#00735A',
    romaine700: '#00564E',
    romaine800: '#003B33',
    romaine900: '#001716',

    v2romaine50: '#EFFBF7',
    v2romaine100: '#E6F9F2',
    v2romaine200: '#CAF2E3',
    v2romaine250: '#ADEBD4',
    v2romaine300: '#6FDCB3',
    v2romaine350: '#32CD93',
    v2romaine400: '#2CB783',
    v2romaine500: '#149566',
    v2romaine600: '#1F7C5A',
    v2romaine700: '#125E43',
    v2romaine800: '#224F3F',
    v2romaine900: '#15372B',

    tomato350: '#FF9992',
    tomato400: '#FF7E75',
    tomato500: '#FE493D',
    tomato600: '#EE3123',
    tomato700: '#DB170A',
    tomato800: '#8C0A03',
    tomato900: '#3A0400',

    blueberry50: '#FAFDFD',
    blueberry100: '#F0F6F8',
    blueberry200: '#D6E3E6',
    blueberry300: '#A7C7CF',
    blueberry350: '#91B5BE',
    blueberry400: '#7B9FA8',
    blueberry500: '#5C818A',
    blueberry600: '#436068',
    blueberry700: '#34464B',
    blueberry800: '#222F32',
    blueberry900: '#131C1F',

    paprika250: '#FFE200',
    paprika300: '#FFBF00',
    paprika350: '#FF9900',
    paprika400: '#FD8700',
    paprika500: '#F56200',

    grape50: '#F4FEFF',
    grape100: '#E6FAFD',
    grape200: '#C1EAEE',
    grape250: '#B3E1E6',
    grape300: '#8AD0D6',
    grape350: '#72BEC5',
    grape400: '#5AA9B0',
    grape500: '#318B93',
    grape600: '#1C6C73',
    grape700: '#115055',
    grape800: '#0A3538',
    grape900: '#001C1F',

    sky50: '#F6FCFE',
    sky100: '#E8F8FE',
    sky200: '#BFEBFA',
    sky250: '#A6E2F8',
    sky300: '#76D5F4',
    sky350: '#4ACDF3',
    sky400: '#00B9EC',
    sky500: '#009EDE',
    sky600: '#0085BC',
    sky700: '#01638C',
    sky800: '#044966',
    sky900: '#003247',

    white900: '#fff',

    black50: '#FBFBFB',
    black100: '#F5F5F5',
    black200: '#E1E1E1',
    black250: '#D8D8D8',
    black300: '#C0C0C0',
    black350: '#ACACAC',
    black400: '#999999',
    black500: '#7B7B7B',
    black600: '#5B5C5C',
    black700: '#434444',
    black800: '#2B2B2B',
    black900: '#0B0C0C',
    
    rainist500: '#109CD8',
    event500: '#3BCCC5',
    facebook500: '#4267B2'
};
