import { Entity } from 'core/entity';
import { PromotionUrl } from 'core/entity/promotion/promotion-url';
import { PromotionDate } from 'core/entity/promotion/promotion-date';
import { PromotionButton } from 'core/entity/promotion/promotion-button';
import { PromotionTracker } from 'core/entity/promotion/promotion-tracker';
import { PromotionMeta } from 'core/entity/promotion/promotion-meta';

export class Promotion extends Entity {
    constructor(
        public isTokenRequired: boolean,
        public url: PromotionUrl,
        public date: PromotionDate,
        public button: PromotionButton,
        public tracker: PromotionTracker,
        public meta: PromotionMeta,
    ) {
        super();
    }
}
