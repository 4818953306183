import { Entity } from 'core/entity';
import { TransactionIconSummary } from 'core/entity/personal-finance/transaction/category-icon/summary';
import { TransactionCategoryParentSummary } from 'core/entity/personal-finance/transaction/category-parent/summary';
import { TransactionType } from 'core/entity/personal-finance/transaction/type';

export class TransactionCategorySummary extends Entity {
    id: string;
    name: string;
    transactionType: TransactionType;
    priority: number;
    isDefault: boolean;
    icon?: TransactionIconSummary;
    parent?: TransactionCategoryParentSummary;

    constructor(
        id: string,
        name: string,
        transactionType: TransactionType,
        priority: number,
        isDefault: boolean,
        icon: TransactionIconSummary = null,
        parent: TransactionCategoryParentSummary = null
    ) {
        super();
        this.id = id;
        this.name = name;
        this.transactionType = transactionType;
        this.priority = priority;
        this.isDefault = isDefault;
        this.icon = icon;
        this.parent = parent;
    }
}
