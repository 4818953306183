import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { Store } from 'core/entity/store';

export class StoreMapper implements NetworkResponseMapper<Store> {
    fromJson(json: any): Store {
        return new Store(
            json.id,
            json.name,
            json.image_path,
            List(json.valid_spendings)
        )
    }
}
