import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanRecommendResult } from 'core/entity/loan/recommend-result';
import { LoanMapper } from 'data/http/mapper/loan';
import { LoanComputationResultMapper } from 'data/http/mapper/loan/computation-result';

export class LoanRecommendResultMapper implements NetworkResponseMapper<LoanRecommendResult> {
    fromJson(json: any): LoanRecommendResult {
        return new LoanRecommendResult(
            new LoanMapper().fromJson(json.loan),
            new LoanComputationResultMapper().fromJson(json.computation)
        )
    }
}
