import { List } from 'immutable';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSubSector } from 'core/entity/finance-sector/sub';
import { FinanceSubSectorContentItem } from 'core/entity/finance-sector/item/content';
import { ContentColumnSummary } from 'core/entity/content/column/summary';
import { EMERGENCY_AUTHORS } from 'presentation/module/emergency-contents';
import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { FinanceSectorID } from 'core/entity/finance-sector/id';

export const EMERGENCY_MAGAZINE_SECTOR = new FinanceSector(
    FinanceSectorID.MAGAZINE,
    '매거진',
    new FinanceSubSector(
        '상품 추천',
        List([
            new FinanceSubSectorContentItem(
                '나에게 맞는 가계부 찾기',
                new ContentColumnSummary(
                    '102',
                    '나에게 맞는 가계부 찾기',
                    '2017년 1월 초, 설렘을 안고 시작했던 신년계획. 그 중 재테크 역시 다이어트, 취업, 금연, 금주 등 좋은 습관으로 만들고 싶은 목록에서 빠질 수 없는 단골손님입니다. 재테크라고 하면 흔히 돈을 모으거나 불리는 것 즉, 저축과 투자를 가장 먼저 떠올립니다. 하지만 그보다 실생활에서 발생하는 돈 관리부터 관심가질 필요가 있습니다.',
                    EMERGENCY_AUTHORS.KIM_NA_YUN,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent%252Fupload%252Fimages%252Fcoffee-1246511_1280_800x533.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '사회초년생 돈 관리 7단계',
                new ContentColumnSummary(
                    '247',
                    '사회초년생 돈 관리 7단계',
                    '첫 월급을 받은 새내기 직장인은 기쁜 만큼 혼란스럽다. 번 돈을 잘 관리해서 차도 사고 집도 사고 재산도 모으고 싶은 마음은 굴뚝같지만, 생각보다 적은 월급으로 무엇을 해야 할 지 막막하기 때문이다. 인터넷으로 검색을 해봐도 정확히 무엇을 해야 할지 알쏭달쏭하기만 하다.',
                    EMERGENCY_AUTHORS.KOREA_FINANCIAL_INVESTORS_PROTECTION_FOUNDATION,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fstairs-1627424_640.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '신혼부부 재무관리 8단계',
                new ContentColumnSummary(
                    '262',
                    '신혼부부 재무관리 8단계',
                    '결혼은 인생의 중대한 분기점이다. 결혼을 통해 부부는 거주와 생활 면에서 하나의 독립된 세대를 이루게 됨은 물론, 자녀를 낳고 기르는 ‘부모’가 되기도 한다.  이러한 변화는 미혼기와 다른 금전적인 부담을 초래한다. 그 중 특히 자녀 양육비 부담이 가장 큰 것으로 알려져 있다. 한국보건사회연구원(2013)은 자녀 1명을 대학 졸업까지 키우는 데 총 3억 896만원이 든다고 발표했다. 이런 상황에서 많은 부부가 결혼과 양육을 겁내는 것은 당연하다.',
                    EMERGENCY_AUTHORS.KOREA_FINANCIAL_INVESTORS_PROTECTION_FOUNDATION,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fwedding-2207211_640.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '돈 관리 습관, 바꾸기 어려운 이유',
                new ContentColumnSummary(
                    '202',
                    '돈 관리 습관, 바꾸기 어려운 이유',
                    'A와 B 두 사람이 모두 영화 값으로 1만원을 쓸까 말까 고민하고 있다고 생각해보자. 그런데 A와 B가 처한 상황은 조금 다르다.A는 어제 연극을 보기 위해 이미 3만원을 지출하였다. 한편 B는 어제 주차요금으로 3만원을 지출하였다. A, B 중 어떤 사람이 영화 티켓을 구입할 가능성이 높을까?',
                    EMERGENCY_AUTHORS.KOREA_FINANCIAL_INVESTORS_PROTECTION_FOUNDATION,
                    'https://cdn.banksalad.com/content/upload/images/content-upload-images-1215277087.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '강제저축 습관 만드는 4가지 방법',
                new ContentColumnSummary(
                    '126',
                    '강제저축 습관 만드는 4가지 방법',
                    '저축을 통해 돈을 잘 모으고 불리는 능력은 ‘재테크 수익률’에 의해서가 아니라, 마시멜로의 유혹을 잘 물리쳤던 네 살짜리 아이들처럼 ‘얼마나 소비의 유혹을 잘 이기느냐’에 달려있다. 소비의 유혹을 잘 참아낸 사람들이 결국 좋은 지출 습관을 길러 시간이 흐를수록 부를 쌓아나가게 된다.',
                    EMERGENCY_AUTHORS.LEE_CHUN,
                    'https://cdn.banksalad.com/content/upload/images/content-upload-images-1002087149.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '적정소비 실천법 : 나의 적정연비는?',
                new ContentColumnSummary(
                    '60',
                    '적정소비 실천법 : 나의 적정연비는?',
                    '"항상 왜 이렇게 항상 돈이 부족하고 쪼들리는 걸까요?" "마음껏 돈 써 본 적도 없고, 어디 함부로 돈 쓴 적도 없는데 돈이 모이질 않아요."',
                    EMERGENCY_AUTHORS.PARK_MI_JUNG,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-16840395246_9653b7184f_k_mini_800x485.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '문맹보다 무서운 금융문맹',
                new ContentCardComparisonSummary(
                    '110',
                    '문맹보다 무서운 금융문맹',
                    '“여러분~ 모두 부자되세요! 꼭이요!”  2002년 모 카드회사의 CF는 대한민국 새해 인사말을 바꾸어놓았다. 새해 복 많이 받고 건강하고 공부열심히 하라던 할머니의 새해 덕담은 그 이후 “공부 열심히해서 돈많이 벌고 부자되거라”가 되었고, 아버지의 친구들 역시 대박을 기원하며 “올해엔 돈 많이 벌어서 부자되라”는 인사말을 나누셨다.',
                    EMERGENCY_AUTHORS.LEE_KWON_BOK,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fchart-594212_1920.jpg',
                    new Date(),
                    true,
                    ''
                )
            ),
            new FinanceSubSectorContentItem(
                '우리는 왜 불완전 판매에 넘어가는걸까?',
                new ContentCardComparisonSummary(
                    '63',
                    '우리는 왜 불완전 판매에 넘어가는걸까?',
                    '잠깐 문제 하나를 풀어보겠습니다. “펀드에서 손실이 발생하면 누구의 책임일까요?” 1) 본인 2) 정부 3) 금융회사 4) 잘 모르겠다',
                    EMERGENCY_AUTHORS.SONG_SEUNG_YONG,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-pexels-photo-40120_mini.jpeg',
                    new Date(),
                    true,
                    ''
                )
            )
        ])
    ),
    new FinanceSubSector(
        '상품 리뷰',
        List([
            new FinanceSubSectorContentItem(
                '가스비 아끼는 꿀팁',
                new ContentColumnSummary(
                    '56',
                    '가스비 아끼는 꿀팁',
                    '갑작스레 찾아온 겨울, 날 선 추위에 뼈마디가 시립니다. 하지만 곧 닥쳐올 더 큰 시련이 있습니다. 바로 \'가스비\'입니다. 가스비는 여름에는 적게 나오지만, 겨울에는 난방 때문에 금액이 많이 나오기 마련입니다. 가스비, 어떻게 절약할 수 있을까요?',
                    EMERGENCY_AUTHORS.YOON_SUNG_AE,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload%252Fimages-%EA%B0%80%EC%8A%A4%EB%B9%84%EC%A0%88%EC%95%BD_mini_800x533.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '기름값 아끼는 꿀팁',
                new ContentColumnSummary(
                    '80',
                    '기름값 아끼는 꿀팁',
                    '비싼 기름 값 아껴서 소고기 사먹자!',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EA%B8%B0%EB%A6%84%EA%B0%92%EC%95%84%EB%81%BC%EB%8A%94+%EA%BF%80%ED%8C%81.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '전기세 아끼는 꿀팁',
                new ContentColumnSummary(
                    '261',
                    '전기세 아끼는 꿀팁',
                    '이번 칼럼은 에어컨 전기세 절약하는 법에 이어, 다른 전자제품들의 전기세 아끼는 방법에 대해 알아보겠습니다.',
                    EMERGENCY_AUTHORS.YOON_SUNG_AE,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fsocket-643720_640.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '유효기간 지난 상품권, 버려야할까?',
                new ContentColumnSummary(
                    '163',
                    '유효기간 지난 상품권, 버려야할까?',
                    '2016년 10월 식사권 2매를 선물로 받은 A씨. 유효기간이 2017년 3월까지였지만 A씨는 제대로 확인하지 못하고 잊고 지냈다. 그리고 2017년 4월 1일 일행과 식사권을 사용하려고 했더니 유효기간이 지났다며 사용할 수 없다는 답변을 받았는데...',
                    EMERGENCY_AUTHORS.PARK_JOO_HYUN,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2F2017-04-07-17-50-45.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '항공권 취소하면, 환불받을 수 있을까?',
                new ContentColumnSummary(
                    '229',
                    '항공권 취소하면, 환불받을 수 있을까?',
                    '직장인 A씨는 해외에서 여름휴가 보내기 위해 최근 온라인쇼핑몰에서 150만원을 내고 왕복 비행기표 2장을 샀다가 황당한 일을 당했습니다. 회사 일정 때문에 어쩔 수 없이 휴가를 미뤄야 해서 비행기표를 취소했는데요. 예매한 지 이틀밖에 되지 않았는데 항공사에서 1인당 30만원씩 총 60만원의 취소 수수료(위약금)를 뗀다네요.',
                    EMERGENCY_AUTHORS.JANG_EUN_SUK,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2FVirgo_%2812%29.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '스마트폰, 언제 사는 게 이득일까?',
                new ContentColumnSummary(
                    '296',
                    '스마트폰, 언제 사는 게 이득일까?',
                    '삼성전자의 갤럭시노트8는 일반판매에 앞서 다음달 7일부터 14일까지 사전예약을 받는다. 예약자에 한해 다음달 15일부터 20일까지 차례로 사전 개통한다. LG전자 V30의 경우에는 일반 판매에 앞서 다음달 14일부터 20일까지 예약판매를 진행한다. 사전 개통은 진행하지 않는다.',
                    EMERGENCY_AUTHORS.PARK_JOO_HYUN,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2F33347969190_3d54e6f279_b.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '주유소 혼유사고, 보상받으려면?',
                new ContentColumnSummary(
                    '306',
                    '주유소 혼유사고, 보상받으려면?',
                    '서울에 사는 A씨는 지난달 가족들과 함께 여름휴가를 가려다가 황당한 일을 겪었습니다. 공항으로 출발하기 전에 기름을 넣으려고 주유소에 들렀는데요. 경유차인 A씨의 차에 주유소 직원이 실수로 휘발유를 넣었던 거죠.',
                    EMERGENCY_AUTHORS.JANG_EUN_SUK,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fgas_station_oil_industry_oil_prices_oil_and_gas_diesel_petroleum_transportation_pump-658235.jpg%21d',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '해외여행시 신용카드 어떻게 사용할까?',
                new ContentColumnSummary(
                    '109',
                    '해외여행시 신용카드 어떻게 사용할까?',
                    '해외여행을 간 직장인 김 모씨. 방문한 아웃렛에서 마음에 드는 물건을 발견하고는 \'아,오늘 환율이 얼마니까 이 정도 가격이겠구나!\' 이리저리 가격을 따져본 후에 물건을 구매했다. 그런데 나중에 카드요금이 청구되었을 때, 결제된 날짜도 다르고 예상보다 더 많은 금액이 청구된 것을 알게 됐다.',
                    EMERGENCY_AUTHORS.PARK_JOO_HYUN,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent%252Fupload%252Fimages%252Ftravel-1410824_1280_800x535.jpg',
                    new Date()
                )
            )
        ])
    ),
    new FinanceSubSector(
        '상품 매거진',
        List([
            new FinanceSubSectorContentItem(
                '최소한의 경제적 자유를 얻는 3가지 방법',
                new ContentColumnSummary(
                    '45',
                    '최소한의 경제적 자유를 얻는 3가지 방법',
                    '의외로 많은 사람들이 경제적 판타지를 가지고 있습니다. ‘가진 돈이 많다면...’ 이란 상상을 하며, 갖고 싶은 것, 사고 싶은 것, 먹고 싶은 것, 가고 싶은 곳 등을 머리에 그립니다. 한마디로 부자가 되고 싶어하죠. 포털 사이트 검색 창에 ‘부자’라고 쳐보세요. 그러면 첫 번째 완성어로 바로 ‘부자되는 방법’이 뜨는데, 엔터를 쳐보면 아마 놀라실 겁니다.',
                    EMERGENCY_AUTHORS.CHA_KHAN_YANG,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Ffreedom-102409_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '경제적 자유를 얻으려면 당장 실행할 5가지',
                new ContentColumnSummary(
                    '142',
                    '경제적 자유를 얻으려면 당장 실행할 5가지',
                    '10년 이상의 시간동안 많은 곳에서 강의를 하고 다양한 사람들을 만나 상담하고 책을 집필하는 과정에서 느낀 것은, 경제적 자유를 누리는 사람들에게 중요한 것은 학벌,지식,출신 정보 등이 아니라는 겁니다. 사람은 두 가지 분류로 나눠질 뿐입니다. 실행하는 사람과 실행하지 않는 사람.',
                    EMERGENCY_AUTHORS.JO_JIN_HWAN,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fchart.PNG',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '내게 맞는 서민금융 지원제도는?',
                new ContentColumnSummary(
                    '129',
                    '내게 맞는 서민금융 지원제도는?',
                    '소득이 적거나 신용등급이 낮은 사람들이 은행에서 대출을 받기란 매우 어렵다. 그래서 이 은행 저 은행 알아보다가 결국 대부업체나 사금융을 이용하게 되는 경우가 많다. 그러나 대부업체와 사금융은 대출금리가 매우 높기 때문에 자칫 잘못하면 빚의 수렁에 빠질 수 있다.     그렇다면 소득이 적거나 신용등급이 낮은 사람들은 대출이 필요할 때 어디로 가야 할까?',
                    EMERGENCY_AUTHORS.KOREA_FINANCIAL_INVESTORS_PROTECTION_FOUNDATION,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fshutterstock_524615173.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '신혼부부 정부지원 100% 활용하기',
                new ContentColumnSummary(
                    '140',
                    '신혼부부 정부지원 100% 활용하기',
                    '“아이 분유랑 기저귀 값이 얼만데, 배우자 월급만으로는 돈이 부족해도 너무 부족해!” 자녀는 선물이지만, 신혼부부가 온전히 기쁨만으로 받아들이기는 어렵다. 자녀 몫의 지출이 늘어날 뿐만 아니라, 맞벌이라면 배우자가 일을 쉬어 소득이 크게 줄기 때문이다. 이런 팍팍한 상황을 돌파할 묘책은 없을까?',
                    EMERGENCY_AUTHORS.KOREA_FINANCIAL_INVESTORS_PROTECTION_FOUNDATION,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2F%EC%8B%A0%ED%98%BC%EB%B6%80%EB%B6%80%EC%99%80%EC%95%84%EA%B8%B0.JPG',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '전월세 보증금 아는 만큼 지킨다',
                new ContentColumnSummary(
                    '233',
                    '전월세 보증금 아는 만큼 지킨다',
                    'KB부동산 발표에 따르면 ‘17.5월 전국 주택가격의 평균은 약 3억 1,126만원이었다. 서울 주택가격은 전국 평균보다 훨씬 비싸서 5억 3,587만원이나 된다. 이를 주택 종류별로 살피면 아파트가 평균 6억 708만원, 연립이 평균 2억 6,214만원이었다.',
                    EMERGENCY_AUTHORS.KOREA_FINANCIAL_INVESTORS_PROTECTION_FOUNDATION,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fmaxpixel.freegreatpicture.com-Light-Night-Night-Scene-Park-Building-Apartment-1728413.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '금융상품, 필요할 때 현금화하기',
                new ContentColumnSummary(
                    '55',
                    '금융상품, 필요할 때 현금화하기',
                    '현재 유동자금은 300만원 밖에 없고, 펀드와 주식, ETF, 예금, ELS, 저축성 보험 등에 총 1억 5,000만 원이 골고루 분산투자 되어 있는 상황을 가정해보자. 이런 경우 새로운 대출 없이 1억원짜리 전셋집을 얻으려면 어떻게 해야할까? 예금이나 보험을 해지해야 할까? 아니면 투자상품을 매도해야 할까?',
                    EMERGENCY_AUTHORS.LEE_JAE_CHUL,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-27578577890_f39f89e6a9_k_mini_800x533.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '연말정산 기본개념 완벽 이해하기',
                new ContentColumnSummary(
                    '51',
                    '연말정산 기본개념 완벽 이해하기',
                    '연말정산이라는 단어는 누구나 알고 있지만, 그것이 정확히 무엇을 의미하는지를 아는 분은 가뭄에 콩나듯 만나게 됩니다. 연말정산을 대비하기 위해 어떤 금융 상품에 가입하고, 어떤 서류를 내야하는지를 아는 것보다도 연말정산이 무엇이고, 왜 하는지를 이해하는 것이 더 중요하기에 가장 기본적인 부분 몇가지를 짚어볼까 합니다.',
                    EMERGENCY_AUTHORS.BIN_NU,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent%252Fupload%252Fimages%252Ftax-468440_1920_800x533.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '연말정산, 놓치기 쉬운 공제항목은?',
                new ContentColumnSummary(
                    '103',
                    '연말정산, 놓치기 쉬운 공제항목은?',
                    '연말정산 간소화 서비스에서 조회가 되는데 꼼꼼하게 챙기지 않거나, 연말정산 간소화 서비스에 간혹 자료가 누락되는 등의 이유로 연말정산 환급을 놓치는 경우가 발생하고 있어 연말정산 대상자 분들께서는 주의하시고 환급받으실 수 있는 부분을 꼭 챙겨받으시길 바랍니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-pexels-photo-237675_mini.jpeg',
                    new Date()
                )
            )
        ])
    )
);
