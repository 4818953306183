import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1KCBThirdPartyView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h2>개인(신용)정보 제3자 제공에 관한 사항</h2>

      <h3>주식회사 레이니스트</h3>
            귀사의 뱅크샐러드 “모바일 어플리케이션 및 웹 사이트”(이하 “본건 서비스”) 이용과 관련하여 귀사가 본인의 개인(신용정보)를 제3자에게 제공하고자 하는 경우에는 「개인정보 보호법」, 「신용정보의 이용 및 보호에 관한 법률」 및 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에 따라 본인의 동의가 필요합니다. 이에 귀사가 다음과 같이 본인의 개인(신용)정보를 아래와 같이 제3자에게 제공하는 것에 동의합니다.

      <br/><br/>
      <table>
        <tr><td>
          <b>제공받는 자</b><br/>
                    서울신용평가㈜, 코리아크레딧뷰로(주) 등의 신용정보회사
        </td></tr>
        <tr><td>
          <b>제공항목</b><br/>
                    이름, 성별, 이동전화번호, 내외국인구분, 중복가입확인정보(DI), 암호화된 동일인 식별정보(CI), 아이핀 번호(아이핀 이용 시)
        </td></tr>
        <tr><td>
          <b>제공받는 자의 개인(신용)정보 이용 목적</b><br/>
                    본인 확인
        </td></tr>
        <tr><td>
          <b>보유·이용기간</b><br/>
                    제공 동의일로부터 개인(신용)정보를 제공받는 자의 목적을 달성할 때까지
        </td></tr>
      </table>

      <br/><br/>
      <table>
        <tr><td>
          <b>제공받는 자</b><br/>
                    코리아크레딧뷰로(주)
        </td></tr>
        <tr><td>
          <b>제공항목</b><br/>
          <ul>
            <li>개인식별정보</li>
            <li>카드결제내역</li>
            <li>계좌잔액</li>
            <li>입출금 내역</li>
            <li>소득, 보유 금융 상품 등의 고객 금융 활동 관련 정보</li>
          </ul>
        </td></tr>
        <tr><td>
          <b>제공받는 자의 개인(신용)정보 이용 목적</b><br/>
                    이용자 본인의 신용평가에 대한 가점 요소로의 활용 가능성에 대한 검증(이용자 본인의 신용평가에 불리하게 적용되지 않음)
        </td></tr>
        <tr><td>
          <b>보유·이용기간</b><br/>
                    제공 동의일로부터 개인(신용)정보를 제공받는 자의 목적을 달성할 때까지
        </td></tr>
      </table>

      <br/><br/>
            위 개인(신용) 정보 제 3자 제공에 관한 동의는 본건 서비스 이용을 위하여 필수적이므로, 위 사항에 동의하셔야만 본건 서비스 이용이 가능합니다.
    </div>
  </div>
);
