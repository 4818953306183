import { UserCardBill } from 'core/entity/personal-finance/card-bill';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { SpiderCardCompanySummaryMapper } from 'data/http/mapper/personal-finance/card-company/summary';
import { ChargedTransactionMapper } from 'data/http/mapper/personal-finance/charged-transaction';
import { PrimaryDataMapper } from 'data/http/mapper/personal-finance/primary-data';
import { List } from 'immutable';
import { parseDate, optional } from 'utils/index';

export class UserCardBillMapper implements NetworkResponseMapper<UserCardBill> {
    fromJson(json: any): UserCardBill {
        return new UserCardBill(
            json.id,
            new SpiderCardCompanySummaryMapper().fromJson(json.company),
            parseDate(json.due_date),
            new AmountMapper().fromJson(json.total_amount),
            List(json.transactions.map((it: any) =>
                new ChargedTransactionMapper().fromJson(it)
            )),
            optional(json.primary_data, it => new PrimaryDataMapper().fromJson(it))
        )
    }

}
