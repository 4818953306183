import { Entity } from 'core/entity';

export class CardSpending extends Entity {
    storeId: number;
    storeName: string;
    expense: number;

    constructor(
        storeId: number,
        storeName: string,
        expense: number
    ) {
        super();
        this.storeId = storeId;
        this.storeName = storeName;
        this.expense = expense;
    }
}
