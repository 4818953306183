import { NetworkRequestMapper } from 'data/http/mapper';
import { SuspendAccountSpec } from 'core/entity/user/suspend-account-spec';
import { SHA256 } from 'crypto-js';

export class SuspendAccountSpecMapper implements NetworkRequestMapper<SuspendAccountSpec> {
    toJson(target: SuspendAccountSpec): any {
        return {
            remittance_password: SHA256(target.password).toString(),
            phone_number: target.phoneNumber,
            recaptcha_token: target.recaptchaToken
        };
    }
}
