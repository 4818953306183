import { CardSummaryRepositoryType } from 'core/repository/card-summary';
import { CardSummary } from 'core/entity/card/summary';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { CardSummaryForCompareStorageProvider } from 'data/browser-storage/api/card';

export class CardSummaryForCompareRepository implements CardSummaryRepositoryType {
    private storage: CardSummaryForCompareStorageProvider;

    constructor(storage: CardSummaryForCompareStorageProvider) {
        this.storage = storage;
    }

    getAll(): Observable<List<CardSummary>> {
        return this.storage.get().map(it => List.of(it));
    }

    post(card: CardSummary): Observable<void> {
        return this.storage.set(card);
    }

    deleteAll(): Observable<void> {
        return this.storage.clear();
    }
}
