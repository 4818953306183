import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { NoticeAllRoute } from 'presentation/routes/notices/all';
import { NoticeRoute } from 'presentation/routes/notices/notice';


export const NoticesRoute = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/:id` } component={ NoticeRoute } />
        <Route path={ `${match.url}` } component={ NoticeAllRoute } />
    </Switch>
);
