import { Entity } from 'core/entity';

export class AdviceMetadata extends Entity {
    baseDatetime?: Date;
    createdAt?: Date;
    readAt?: Date;
    dataStartDatetime?: Date;
    dataEndDatetime?: Date;
    finalizedAt?: Date;

    constructor(
        baseDatetime: Date = null,
        createdAt: Date = null,
        readAt: Date = null,
        dataStartDatetime: Date = null,
        dataEndDatetime: Date = null,
        finalizedAt: Date = null
    ) {
        super();
        this.baseDatetime = baseDatetime;
        this.createdAt = createdAt;
        this.readAt = readAt;
        this.dataStartDatetime = dataStartDatetime;
        this.dataEndDatetime = dataEndDatetime;
        this.finalizedAt = finalizedAt;
    }
}
