import { NetworkRequestMapper } from 'data/http/mapper';
import { TransactionCategoryUpdateSpec } from 'core/entity/personal-finance/transaction/category/update-spec';
import { compactObject, optional } from 'utils/index';

export class TransactionCategoryUpdateSpecMapper implements NetworkRequestMapper<TransactionCategoryUpdateSpec> {
    toJson(target: TransactionCategoryUpdateSpec): any {
        return compactObject({
            name: optional(target.name),
            icon_id: optional(target.iconId),
            priority: optional(target.priority)
        });
    }
}
