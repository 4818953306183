import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { HttpProvider } from 'data/http';
import { SpiderBankSummary } from 'core/entity/personal-finance/bank/summary';
import { SpiderBankSummaryMapper } from 'data/http/mapper/personal-finance/bank/summary';

export class SpiderBanksApiProvider extends HttpProvider {
    getAll(onlyCodeConfigured: boolean): Observable<List<SpiderBankSummary>> {
        return this.requestGET(
            'api/v2/banks/spider',
            { 'only-code-configured': onlyCodeConfigured }
        ).map((result: any) =>
            List(result.data.map(new SpiderBankSummaryMapper().fromJson))
        );
    }
}
