import { HealthInsuranceCertificateRepositoryType } from 'core/repository/personal-finance/health-insurance/certificates';
import { Observable } from 'rxjs';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { HealthInsuranceCertificateApiProvider } from 'data/http/api/gateway/v2/me/health-insurance/certificates';
import { HealthInsuranceCertificateResponse } from 'core/entity/personal-finance/health-insurance/certificate/response';

export class HealthInsuranceCertificateRepository implements HealthInsuranceCertificateRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: HealthInsuranceCertificateApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: HealthInsuranceCertificateApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    get(): Observable<HealthInsuranceCertificateResponse> {
        return this.tokenStorage.get().flatMap(it => this.api.get(it));
    }
}
