import 'utils/extension';

import * as Sentry from '@sentry/browser';
import { Config } from 'application/config';
import { Application } from 'application/context.instance';
import { initAmplitude } from 'presentation/module/analytics/amplitude';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { EntryRoute } from 'presentation/routes';
import React from 'react';
import { render } from 'react-dom';
import { initDatadogRum } from 'utils/init-datadog-rum';

declare const module: any;

Sentry.init({
  dsn: Config.sentry.dsn,
  release: Config.sentry.release,
  environment: Config.sentry.environment,
  debug: Config.sentry.debug,
});
initDatadogRum({ service: 'banksalad-web' });
initAmplitude();

const renderEntry = () => {
  const rootElement = document.getElementById('wrap');
  render(<EntryRoute />, rootElement);

  if (module.hot) {
    module.hot.accept('presentation/routes', () => {
      const HotEntryRoute = require('presentation/routes').default;
      render(<HotEntryRoute />, rootElement);
    });
  }
};

Application.boot().subscribe(
  () => {
    if (Application.me && !Application.me.authenticated) {
      const signOut = Application.services.user
        .signOut()
        .useOnAnimateFrame()
        .subscribe(
          () => {
            signOut.unsubscribe();
            alert('본인인증을 진행해주세요');
            location.href = BANKSALAD_SITEMAP.LOGIN;
          },
          () => {
            signOut.unsubscribe();
            location.href = BANKSALAD_SITEMAP.MAIN;
          }
        );
    } else {
      renderEntry();
    }
  },
  () => {
    const signOut = Application.services.user
      .signOut()
      .useOnAnimateFrame()
      .subscribe(
        () => {
          signOut.unsubscribe();
          alert('로그인이 만료되어 자동으로 로그아웃 됩니다');
          location.href = BANKSALAD_SITEMAP.MAIN;
        },
        () => {
          signOut.unsubscribe();
          location.href = BANKSALAD_SITEMAP.MAIN;
        }
      );
  }
);
