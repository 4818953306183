import { Entity } from 'core/entity';
import { LoanCFAReview } from 'core/entity/legal-configuration/loan/cfa/review';
import { LoanCFADescription } from 'core/entity/legal-configuration/loan/cfa/description';

export class LoanCFAConfiguration extends Entity {
    review: LoanCFAReview;
    description: LoanCFADescription;

    constructor(
        review: LoanCFAReview,
        description: LoanCFADescription
    ) {
        super();
        this.review = review;
        this.description = description;
    }
}
