import { Entity } from 'core/entity';

export class CardSpecialBenefit extends Entity {
    title: string;
    // 서버에서 title만 존재할시 description은 null로 받아옴
    description?: string = null;

    constructor(
      title: string,
      description: string
    ) {
      super();
      this.title = title;
      this.description = description;
    }
}
