import { Alert } from 'presentation/components/alert';
import { CreditLoanIssueAlertModel } from 'presentation/components/alert/credit-loan-issue/model';
import {
  GA_ACTION, GA_CATEGORY, GA_DOMAIN,
  GA_EVENT_VALUE,     sendGAEvent
} from 'presentation/module/analytics/ga';
import { isMobile } from 'presentation/module/sugar';
import React from 'react';
import styles from './styles.pcss';

interface Props {
    alertModel: CreditLoanIssueAlertModel;
    onCancel: () => void;
}

export const CreditLoanIssueAlert = ({ alertModel, onCancel }: Props) => (
  <Alert
    onCancel={ onCancel }
  >
    <div className={ styles.wrap }>
      <img
        src={ alertModel.organization.imageUrl }
        alt={ `${alertModel.organization.name} 로고` }
        className={ styles.company }
      />
      <strong className={ styles.promotion }>
        {`뱅크샐러드 제휴코드 ${alertModel.promotion.code} 입력 하고 `}<br/>
        { alertModel.promotion.description }
      </strong>
      <p className={ styles.description }>
        본인 인증에 필요한 신분증을 준비해주세요
        대출신청 완료까지 5분 ~ 10분 정도 소요될 수 있습니다
      </p>
      <a
        href={ isMobile() ? alertModel.mobileIssueUrl : alertModel.desktopIssueUrl }
        target="_blank"
        rel="noopener noreferrer"
        className={ styles.link }
        onClick={ () =>
          sendGAEvent(
            GA_DOMAIN.LOANS,
            GA_CATEGORY.LOAN_ETC.PROMOTION_ALERT,
            GA_ACTION.ISSUE,
            `신청페이지로이동_${alertModel.organization.type.toString()}_${alertModel.organization.name}_${alertModel.loanSlug}`,
            GA_EVENT_VALUE.IN_PROMOTION
          )
        }
      >
                신청 페이지로 이동
      </a>
    </div>
  </Alert>
);
