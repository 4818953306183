import { Entity } from 'core/entity';
import { MobileAuthenticationPhoneConfiguration } from 'core/entity/user/mobile-authenticate-phone-configuration';

export class MobileAuthenticationCodeSpec extends Entity {
    name: string;
    ssn: string;
    phone: MobileAuthenticationPhoneConfiguration;

    constructor(
        name: string,
        ssn: string,
        phone: MobileAuthenticationPhoneConfiguration
    ) {
        super();
        this.name = name;
        this.ssn = ssn;
        this.phone = phone;
    }
}
