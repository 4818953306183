import { NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedInsuranceRecommendResultWording } from 'core/entity/personalized-recommend/insurance/recommend-result/wording';

export class PersonalizedInsuranceRecommendResultWordingMapper implements NetworkResponseMapper<PersonalizedInsuranceRecommendResultWording> {
    fromJson(json: any): PersonalizedInsuranceRecommendResultWording {
        return new PersonalizedInsuranceRecommendResultWording(
            json.guaranteed_price
        )
    }
}
