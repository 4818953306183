import { UserCreditScoreChangeRecordStatusRepositoryType } from 'core/repository/personal-credit/change-record-status';
import { Observable } from 'rxjs/Rx';
import { UserCreditScoreChangeRecordStatus } from 'core/entity/personal-credit/change-record-status';
import { UserCreditScoreChangeRecordStatusApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/change-records';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserCreditScoreChangeRecordStatusRepository implements UserCreditScoreChangeRecordStatusRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserCreditScoreChangeRecordStatusApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserCreditScoreChangeRecordStatusApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    get(): Observable<UserCreditScoreChangeRecordStatus> {
        return this.tokenStorage.get().flatMap(it =>
            this.api.get(it)
        )
    }

}
