import { Entity } from 'core/entity';
import { LoanInterestValue } from 'core/entity/loan/interest-value';

export class LoanInterestPromotion extends Entity {
    code: string;
    interest: LoanInterestValue;
    startDate: Date;
    endDate?: Date;
    description?: string;

    constructor(
        code: string,
        interest: LoanInterestValue,
        startDate: Date,
        endDate: Date = null,
        description: string = null
    ) {
        super();
        this.code = code;
        this.interest = interest;
        this.startDate = startDate;
        this.endDate = endDate;
        this.description = description;
    }
}
