import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV3PrivacyAgreementView } from 'presentation/routes/policies/v3/privacy-agreement/view';

export const PoliciesV3PrivacyAgreementRoute = () => (
    <>
        <MetaHelmet meta={ META_SET.PRIVACY_AGREEMENT }/>
        <PoliciesV3PrivacyAgreementView />
    </>
);
