import { UserLogTransaction } from 'core/entity/personal-finance/transaction/log';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { TransactionCategorySummaryMapper } from 'data/http/mapper/personal-finance/transaction/category/summary';
import { TransactionPrimaryDataMapper } from 'data/http/mapper/personal-finance/transaction/primary-data';
import { TransactionProductMapper } from 'data/http/mapper/personal-finance/transaction/product';
import { parseDate, optional } from 'utils/index';

export class UserLogTransactionMapper implements NetworkResponseMapper<UserLogTransaction> {
    fromJson(json: any): UserLogTransaction {
        return new UserLogTransaction(
            json.id,
            new AmountMapper().fromJson(json.amount),
            json.title,
            parseDate(json.transacted_at),
            new TransactionProductMapper().fromJson(json.product),
            new TransactionCategorySummaryMapper().fromJson(json.category),
            json.deleted,
            optional(json.primary_data, it => new TransactionPrimaryDataMapper().fromJson(it)),
            optional(json.description)
        )
    }

}
