import { BanksaladStore } from 'core/entity/personal-finance/banksalad-store';
import { NetworkResponseMapper } from 'data/http/mapper';

export class BanksaladStoreMapper implements NetworkResponseMapper<BanksaladStore> {
    fromJson(json: any): BanksaladStore {
        return new BanksaladStore(
            json.id,
            json.name,
            json.image_url
        )
    }

}
