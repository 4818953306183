import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV1MedicalCheckupAgreementView } from 'presentation/routes/policies/v1/medical-checkup-agreement/view';

export const PoliciesV1MedicalCheckupAgreementRoute = () => (
    <>
        <MetaHelmet meta={ META_SET.MEDICAL_CHECKUP_AGREEMENT }/>
        <PoliciesV1MedicalCheckupAgreementView />
    </>
);
