import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV13PrivacyPolicyRoute } from 'presentation/routes/policies/v13/privacy-policy';

export const PoliciesV13Route = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/privacy-policy`} component={ PoliciesV13PrivacyPolicyRoute } />
    </Switch>
);
