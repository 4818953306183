import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { PersonalizedLoanOrganization } from 'core/entity/personalized-recommend/loan/organization';

export class PersonalizedLoanOrganizationMapper implements NetworkResponseMapper<PersonalizedLoanOrganization> {
    fromJson(json: any): PersonalizedLoanOrganization {
        return new PersonalizedLoanOrganization(
            json.name,
            json.image_url,
            lets(json.type, it => {
                switch (it) {
                    case 'primary-bank':
                        return PersonalizedLoanOrganization.Type.PRIMARY_BANK;
                    case 'secondary-bank':
                        return PersonalizedLoanOrganization.Type.SECONDARY_BANK;
                    case 'card-company':
                        return PersonalizedLoanOrganization.Type.CARD_COMPANY;
                    case 'capital':
                        return PersonalizedLoanOrganization.Type.CAPITAL;
                    case 'insurance':
                        return PersonalizedLoanOrganization.Type.INSURANCE;
                    case 'p2p':
                        return PersonalizedLoanOrganization.Type.P2P;
                    default:
                        throw new MappingError();
                }
            }),
        );
    }
}
