import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanOrganizationType } from 'core/entity/loan/organization/type';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';
import { List } from 'immutable';

export const generateDefaultLoanRecommendFilter = () =>
    new LoanRecommendFilter(
        LoanRecommendFilter.RepaymentPeriod.UNDER_FIVE_YEARS,
        List.of(
            LoanOrganizationType.PRIMARY_BANK,
            LoanOrganizationType.SECONDARY_BANK,
            LoanOrganizationType.CAPITAL,
            LoanOrganizationType.CARD_COMPANY,
            LoanOrganizationType.INSURANCE,
            LoanOrganizationType.P2P
        ),
        List.of(
            LoanInterestType.FIXED_INTEREST,
            LoanInterestType.NON_FIXED_INTEREST
        ),
        List.of(
            LoanRepaymentType.AT_END,
            LoanRepaymentType.PROGRESSIVE,
            LoanRepaymentType.INITIAL_VALUE
        ),
        null,
        null,
        null,
        null,
        false,
        null,
        LoanRecommendFilter.Sort.MINIMUM_INTEREST
    );
