import { NetworkResponseMapper } from 'data/http/mapper';
import { PromotionMeta } from 'core/entity/promotion/promotion-meta';

export class PromotionMetaMapper implements NetworkResponseMapper<PromotionMeta> {
    fromJson(json: any): PromotionMeta {
        return new PromotionMeta(
            json.title,
            json.description,
            json.keywords,
            json.image,
        )
    }
}
