import React from 'react';

import styles from '../../../styles.pcss';

export const PoliciesV1PrivacyAgreementsSubmitDocumentView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 개인정보 수집ㆍ이용 동의</h3>
      <p>서류간소화 서비스 제공을 위하여 아래와 같이 개인정보 수집 및 이용 동의를 받고자 합니다.</p>
      <br/>
      <ol>
        <li>1. 개인정보 수집 및 이용 목적 : 서류간소화 서비스 제공</li>
        <li>
                2. 수집하는 개인정보 항목
          <ol>
            <li>
                    1) 건강보험 납부 확인서
              <p>- 필수 정보 : 이름, 생년월일, 납부자번호, 건강보험료, 장기요양보험료</p>
            </li>
            <li>
                    2) 건강보험 자격득실 확인서
              <p>- 필수 정보 : 이름, 생년월일, 성별, 직장명, 건강보험 자격취득일 및 자격상실일</p>
            </li>
          </ol>
        </li>
        <li>3) 개인정보 보유 및 이용 기간 : 회원 탈퇴 시</li>
      </ol>
      <br/>
      <p>개인정보 수집 및 이용 동의를 거부할 권리가 있으며, 동의 거부 시 서류간소화 서비스를 이용하실 수 없습니다.</p>
    </div>
  </div>
);

