import React from 'react';

import { sendGAEvent, GA_DOMAIN } from 'presentation/module/analytics/ga';

interface Props {
    id?: any;
    url?: string;
    checked: boolean;
    text: string;
    gaCategory?: string;
    onChange: (checkedState: boolean) => void;
}

interface State {
    checked: boolean;
}

export const HOCCheckBox = (WrappedComponent: React.ComponentClass<any>) =>
    class extends React.Component<Props, State> {
        state = {
            checked: this.props.checked
        };

        componentWillReceiveProps(nextProps: Props) {
            if(nextProps.checked !== null && this.state.checked !== nextProps.checked)
                this.setState({ checked: nextProps.checked });
        }

        render() {
            const {
                text,
                gaCategory,
                onChange
            } = this.props;
            const {
                checked
            } = this.state;

            return (
                <WrappedComponent
                    checkboxOnChange={ this.onChangeInput(checked, text, onChange, gaCategory) }
                    { ...this.props }
                    { ...this.state }
                />
            )
        }

        private onChangeInput = (
            checked: boolean,
            text: string,
            onChange: (checkedState: boolean) => void,
            gaCategory?: string
        ) => () => {
            this.setState({ checked: !checked }, () => {
                const {
                    checked
                } = this.state;

                checked && gaCategory && sendGAEvent(GA_DOMAIN.NONE, gaCategory, `조건필터_${text}`);
                onChange(checked);
            });
        };
    };
