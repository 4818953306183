import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { UserCardUpdateSpec } from 'core/entity/personal-finance/card/update-spec';
import { UserCardRepositoryType } from 'core/repository/personal-finance/card';
import { UserCard } from 'core/entity/personal-finance/card';

export class UpdateUserCard extends UseCase<UserCard> {
    id: string;
    spec: UserCardUpdateSpec;
    private repository: UserCardRepositoryType;

    constructor(repository: UserCardRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<UserCard> {
        return this.repository.update(this.id, this.spec);
    }

    protected validate(): boolean {
        return !!this.id && !!this.spec;
    }

}
