import { UseCase } from 'core/use-case';
import { FinanceSectorRepositoryType } from 'core/repository/finance-sector';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { Observable } from 'rxjs/Rx';

export class GetBankSector extends UseCase<FinanceSector> {
    private repository: FinanceSectorRepositoryType;

    constructor(repository: FinanceSectorRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<FinanceSector> {
        return this.repository.get(FinanceSectorID.BANK);
    }

    protected validate(): boolean {
        return true;
    }
}
