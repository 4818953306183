export interface NetworkRequestMapper<E> {
    toJson(target: E): any
}

export interface NetworkResponseMapper<E> {
    fromJson(json: any): E
}

export class MappingError extends Error {
    constructor() {
        super('Inappropriate mapping');
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
