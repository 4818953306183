import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceReducedSpendingTaxiData } from 'core/entity/personal-finance/advice/content-data/reduced-spending-taxi';
import { AdviceTransactionPeriodSnapshotMapper } from 'data/http/mapper/personal-finance/advice/transaction-period-snapshot';
import { optional, parseDate } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceReducedTaxiSpendingDataMapper implements
    NetworkResponseMapper<AdviceReducedSpendingTaxiData>
{
    fromJson(json: any): AdviceReducedSpendingTaxiData {
        return new AdviceReducedSpendingTaxiData(
            json.data.map(new AdviceTransactionPeriodSnapshotMapper().fromJson).toList(),
            parseDate(json.base_datetime),
            optional(json.message, new AdviceMessageMapper().fromJson)
        );
    }

}
