import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { lets } from 'utils/index';
import { InsurancePaymentPrice } from 'core/entity/insurance/payment-price';

export class InsurancePaymentPriceMapper implements NetworkResponseMapper<InsurancePaymentPrice> {
    fromJson(json: any): InsurancePaymentPrice {
        return new InsurancePaymentPrice(
            lets(json.type, it => {
                switch (it) {
                    case 'monthly':
                        return InsurancePaymentPrice.PaymentType.MONTHLY;
                    case 'single':
                        return InsurancePaymentPrice.PaymentType.SINGLE;
                    case 'yearly':
                        return InsurancePaymentPrice.PaymentType.YEARLY;
                    default:
                        throw new MappingError();
                }
            }),
            json.price
        )
    }
}
