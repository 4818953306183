import { Entity } from 'core/entity';

export class MedicalCheckupJudgement extends Entity {
    checkupItem: MedicalCheckupJudgement.Item;
    medicalExpense?: number;
    judgementResult?: MedicalCheckupJudgement.Result;

    constructor(
        checkupItem: MedicalCheckupJudgement.Item,
        medicalExpense: number = null,
        judgementResult: MedicalCheckupJudgement.Result = null
    ) {
        super();
        this.checkupItem = checkupItem;
        this.medicalExpense = medicalExpense;
        this.judgementResult = judgementResult;
    }
}

export module MedicalCheckupJudgement {
    export enum Result {
        WARNING = 'warning',
        CAUTION = 'caution',
        NORMAL = 'normal'
    }

    export enum Item {
        FAT = '비만',
        SIGHT = '시각이상',
        HEARING = '청각이상',
        HIGH_BLOOD_PRESSURE = '고혈압',
        KIDNEY = '신장질환',
        ANEMIA = '빈혈',
        DIABETES = '당뇨병',
        DYSLIPIDEMIA = '이상지질혈증',
        CHRONIC_KIDNEY = '만성신장질환',
        LIVER = '간장질환',
        TB_CHEST = '폐결핵/흉부질환',
        OSTEOPOROSIS = '골다공증',
        NOT_CATEGORIZED = '미분류'
    }
}
