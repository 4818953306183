import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';
import { Amount } from 'core/entity/amount';

export class AdviceWelcomeData extends Entity implements AdviceContentData {
    userCount: number;
    averageBenefitAmount: Amount;
    availableOrganizationCount: number;
    baseDatetime: Date;
    message?: AdviceMessage;

    constructor(
        userCount: number,
        averageBenefitAmount: Amount,
        availableOrganizationCount: number,
        baseDatetime: Date,
        message: AdviceMessage = null
    ) {
        super();
        this.userCount = userCount;
        this.averageBenefitAmount = averageBenefitAmount;
        this.availableOrganizationCount = availableOrganizationCount;
        this.baseDatetime = baseDatetime;
        this.message = message;
    }
}
