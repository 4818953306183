import { Entity } from 'core/entity';
import { List } from 'immutable';
import { AdviceTransactionPeriodSnapshot } from 'core/entity/personal-finance/advice/transaction-period-snapshot';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';

export class AdviceExcessiveSpendingAlertData extends Entity implements AdviceContentData {
    constructor(
        public data: List<AdviceTransactionPeriodSnapshot>,
        public baseDatetime: Date,
        public message: AdviceMessage = null
    ) {
        super();
    }
}
