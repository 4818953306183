import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV15PrivacyPolicyView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 개인정보처리방침</h3>
      <p>
        주식회사 뱅크샐러드(이하 ‘회사’)는 뱅크샐러드 서비스(이하 ‘서비스’)를 제공하면서 개인정보 보호법 및 관련 법령에 따라 이용자의 개인정보(개인신용정보 포함, 이하 동일함)를 안전하고 적법하게 처리합니다.
      </p>
      <ol>
        <li>
          <br/>
          <h4>본 개인정보처리방침의 목적</h4>
          <p>
            회사는 본 개인정보처리방침을 통해 이용자의 개인정보가 어떻게 처리되는지 투명하게 공개합니다. 이용자는 본 개인정보처리방침을 통하여 회사가 어떠한 개인정보를 수집하고, 수집한 개인정보를 어떻게 이용하며, 누구에게 이를 공유하고, 언제 파기하는지 등을 확인할 수 있습니다.
            <br/>
            <br/>
            또한, 이용자가 회사에 대하여 자신의 개인정보에 관한 열람, 정정, 삭제, 처리정지를 요청할 수 있는 방법을 설명합니다.
          </p>
        </li>
        <li>
          <br/>
          <h4>회사가 수집하는 개인정보</h4>
          <p>
            회사가 제공하는 서비스는 이용자의 금융자산이나 거래내역, 건강정보를 비롯한 다양한 정보를 수집하여 통합적으로 보여줌으로써, 이용자가 자산 뿐만 아니라 라이프 스타일을 관리하고, 나아가 자신의 정보를 효과적으로 통제하고 활용할 수 있도록 해줍니다.
            <br/>
            <br/>
            이용자는 회원가입을 하여야만 서비스를 이용할 수 있으며, 회원가입시 본인인증 절차를 거쳐야 합니다. 회사는 회원가입 및 회원관리를 위해서 이름, 생년월일, 성별, 휴대폰번호, 이동통신사, 동일인 식별 연계정보(CI), 중복가입확인정보(DI)를 수집합니다.
            <br/>
            <br/>
            또한, 회사는 이용자에게 약속한 서비스를 제공하기 위해 이용자의 동의를 받아 다음과 같은 개인정보를 수집합니다.
          </p>
          <ul>
            <li>
              <p>
                - <b>은행 자산 관련 정보</b>에는 예·적금, 출금, 펀드, 보험 관련 계좌정보(은행명, 계좌번호, 계좌구분, 계좌명, 예금주명 등), 해당 계좌의 자산 내역(잔액, 출금금액, 출금가능금액, 거래 후 잔액, 입출금 금액, 적용이율, 개설 및 만기일자, 월부금 등), 대출 관련 정보(마이너스통장 여부, 대출종류, 신규 및 만기일자, 대출금액, 이자 정보, 대출 잔액 정보 등), 이용자가 은행 자산을 거래한 내역정보(계좌적요, 별칭, 거래내용, 메모, 입금자, 송금 및 이체받는 자 등)가 포함됩니다.
              </p>
            </li>
            <li>
              <p>
                - <b>카드 보유 및 이용 정보</b>에는 신용·체크카드 정보(카드사, 카드명, 카드번호, 발급정보, 결제은행 및 결제계좌정보, 적립 포인트 정보, 카드 실적 정보 등), 결제예정내역 또는 사용내역(결제일, 결제금액, 할부이용정보, 포인트 정보, 할부거래의 경우 결제 후 잔금, 이용 가맹점명, 해외결제 여부, 수수료 정보 등), 이용한도 정보(총한도, 잔여한도, 일시불·할부·현금서비스 한도, 승인한도 정보 등), 청구 관련 정보(결제일자, 할부 여부, 출금예정일자, 청구 금액 및 할부 이용시 할부 잔액, 이용 가맹점, 해외결제 여부, 수수료, 캐시백 및 포인트 정보 등), 승인 관련 정보(이용 가맹점 정보, 결제일자, 할부개월, 승인금액, 승인일시 등), 카드대출 정보(카드론, 최소이용가능금액, 이자율, 수수료율, 만기일, 상환방법, 대출금액 등)가 포함됩니다.
              </p>
            </li>
            <li>
              <p>
                - <b>현금 및 소득 관련 정보</b>에는 현금영수증 정보(현금 사용일, 현금영수증 발행 가맹점 정보, 현금 사용액, 소득공제 여부 등)와 소득금액 정보(소득금액증명원 발급번호, 발급형태, 발급자의 인적사항, 증명구분, 발급일자, 기준 소득금액, 총 결정세액, 사업자번호, 주소, 법인명 등)가 포함됩니다.
              </p>
            </li>
            <li>
              <p>
                - <b>투자 자산 정보</b>에는 이용자가 투자한 계좌정보(증권사 또는 은행명, 계좌번호, 계좌명 등), 투자 현황 정보(원금, 투자상품, 매입금액정보, 대출관련정보, 평가금액, 평가손익, 매입가, 수익률, 예수금 및 예치금 등), P2P 상품에 대한 투자 정보(온라인투자연계투자업자, 직접투자·분산투자 방법, 투자상품명, 투자 완료시기, 투자금액, 투자계좌번호, 수익률 정보, 연체이자 정보, 투자상환정보, 가입경로 및 기존 가입여부 등)가 포함됩니다.
              </p>
            </li>
            <li>
              <p>
                - <b>보험 정보</b>에는 이용자가 계약한 보험의 현황 정보(증권번호, 보험상품명, 보험사명, 계약자, 수익자계약일, 만기일, 계약상태, 특약정보 등), 보험납입정보(가입금액, 총납입보헙료, 납입주기, 최종납입일 등), 보장정보(보장명칭, 지급사유 및 보장금액, 대출관련정보, 해약환급금 등), 수익자 정보(사망, 상해, 만기시 수익자, 수익금을 지급받을 계좌 정보 등), 변액보험정보(펀드명, 보유좌수, 적립금, 수익률 등)가 포함됩니다.
              </p>
            </li>
            <li>
              <p>
                - <b>연금 정보</b>에는 국민연금정보(가입자구분 정보, 연금종류, 예상연금액, 납부월수, 납부총액, 예상 납부월수, 예상 납부 보험료 총액, 연금개시일), 퇴직·개인연금정보(회사명, 상품유형, 상품명, 가입일자, 연금개시일, 적립금, 계좌번호, 납부총액, 예상연금액, 연금 기수령액, 연금 미수령액 등), 사학·공무원 연금정보(연금종류, 연금개시일, 예상연금액, 소득월액, 수급개시년월 등)가 포함됩니다.
              </p>
            </li>
            <li>
              <p>
                - <b>건강보험 및 건강검진 정보</b>에는 건강보험 납부 정보(이름, 발급번호, 가입자 구분, 사업장명 및 사업자등록번호, 납부자정보, 연말정산 고지 및 납부 보험료 금액, 장기요양보험료 고지 및 납부 금액 등), 자격득실 및 확인 정보(이름, 가입자구분, 사업자명, 자격득실일, 발급일자, 자격확인요청일, 급여정지 사유 등), 건강검진결과서 기재사항(신체계측정보, 검진항목, 검진결과, 검진일자, 검진소견 등)가 포함됩니다.
              </p>
            </li>
            <li>
              <p>
                - <b>신용도 관련 정보</b>에는 신용평가회사가 이용자에게 제공하는 신용점수, 신용점수 변동 여부 및 변동내역, 다른 금융기관에 의한 신용정보 조회내역, 카드보유 및 대출 현황, 연체현황, 연대보증 현황 등의 정보가 포함됩니다.
              </p>
            </li>
            <li>
              <p>
                - <b>부동산 및 자동차 관련 정보</b>에는 부동산 계약 및 시세 정보(부동산명, 동·호수를 제외한 주소, 매입가·전세가·보증금 및 월세, 계약체결일, 계약기간, 연결대출정보, 희망판매가, 현재시세, 이용자가 추가 입력한 부동산 정보 등), 자동차 정보(차량번호, 차종, 연식, 연비, 주행거리, 차대번호, 자동차 검사정보, 현재시세 등)가 포함됩니다.
              </p>
            </li>
          </ul>
          <br/>
          <p>
            나아가 회사는 서비스 제공을 위해 이용자로부터 추가적인 정보를 수집할 수 있습니다.
            <br/>
            <br/>
            - <b>대출 금리 비교 서비스인 심플론 서비스</b>를 이용하는 경우에는  직장정보(직장인·개인사업자 여부, 정규직·계약직·프리랜서 여부, 입사년월 등) 대출금리 비교 정보(대출거래조건, 가심사 결과 등)를 처리합니다.
            <br/>
            <br/>
            - <b>뱅샐머니 서비스</b>를 이용하는 경우에는 영문명, 금융기관명, 계좌정보(계좌번호, 계좌종류, 계좌 잔액, 거래내역정보 등), 이메일주소, 주소, 선불전자지급수단을 통한 결제 및 송금내역 등 금융거래정보등이 수집되며, <b>오픈뱅킹서비스</b>를 이용하는 경우에도 금융기관명, 계좌정보(계좌번호, 계좌종류, 계좌 잔액, 거래내역정보 등)등 금융거래정보 등을 처리합니다.
            <br/>
            <br/>
            - <b>사업자 기능</b>을 이용하는 경우 사업자의 정보에 해당하는 사업자번호, 사업장명(상호명), 대표자 생년월일, 카드매출입금은행, 카드 매출 입금 은행 계좌번호 등의 정보를 처리합니다.
            <br/>
            <br/>
            - <b>마이데이터 인증서 서비스</b>를 이용하는 경우 이름, 생년월일, 성별, 이동통신사, 휴대폰번호, 동일인 식별 연계정보(CI), 중복가입정보(DI), 계좌정보(금융사명, 계좌번호), 전자서명생성정보, 마이데이터 인증서 비밀번호를 처리합니다.
            <br/>
            <br/>
            - <b>예방접종 관리 서비스</b>를 이용하는 경우 예방접종 도우미 홈페이지 ID, 비밀번호, 예방접종내역 정보(접종종류, 접종명, 접종차수, 접종일자, 접종기관, 백신명, 제조사, 로트번호), 자녀 등 가족정보 등록시 해당 가족 구성원의 예방접종내역 정보(자녀 등 가족과의 관계, 접종종류, 접종명, 접종차수, 접종일자, 접종기관, 백신명, 제조사, 토트번호)를 처리합니다.
            <br/>
            <br/>
            - <b>웃음 습관 서비스</b>를 이용하는 경우에는 TrueDepth API를 이용합니다. 이 경우 얼굴 이미지는 웃음 습관 달성 판단을 위한 목적으로만 사용되며, 서버에 저장되지 않습니다.
            <br/>
            <br/>
            - <b>이벤트 참여 및 특정 이용자 대상 설문조사, 인터뷰</b>를 하는 경우 이름, 휴대폰번호, 성별, 나이를 처리합니다.
            <br/>
            <br/>
            - <b>이용자의 문의사항</b>을 처리하기 위해 문의내용에 포함된 개인정보를 처리할 수 있습니다.
            <br/>
            <br/>
          </p>
          <p>
            또한, 이용자가 서비스를 이용하는 과정에서 서비스를 이용하는 IP, 단말기 정보, 접속내역, OS 버전 등의 기기정보가 자동으로 생성되어 수집될 수 있습니다. 또한, 회사는 이용자의 서비스(서비스를 통해 연결된 제3자의 서비스를 포함함) 이용내역을 비롯한 행태정보를 수집합니다.
            <br/>
            <br/>
            이용자의 정보가 수집되는 과정에서 이용자의 금융기관 로그인 정보 등 접근매체(ID, 비밀번호, 공동인증서 등)가 사용되나, 이러한 정보는 별도의 고지가 없는 이상 회사의 서버에 전송되거나 저장되지 않고 오로지 이용자의 단말기에만 저장되어 안전하게 처리됩니다. 회사는 (주)코드에프가 제공하는 스크래핑 모듈 서비스를 이용하여 이용자가 자신의 정보를 서비스 내에서 편리하게 조회할 수 있도록 합니다.
            <br/>
            <br/>
            이용자는 관련 법령이 허용하는 범위에서 전송요구권을 행사하거나, 제3자 제공에 동의함으로써 제3자가 보유하고 있는 자신의 정보를 회사에 전송하거나, 제공하도록 할 수 있습니다. 이러한 경우 회사는 관련 법령에서 정한 방식으로 해당 정보를 수집하고, 처리합니다.
          </p>
        </li>
        <li>
          <br/>
          <h4>회사가 개인정보를 처리하는 목적</h4>
          <p>회사는 다음과 같은 목적을 위해 이용자의 개인정보를 처리합니다.</p>
          <ul>
            <li>
              <p>
                - <b>회사는 이용자에게 서비스를 제공하기 위해 이용자의 개인정보를 처리합니다.</b> 예를 들어, 이용자의 다양한 자산과 지출 내역을 분석하여 유용한 정보와 조언을 제공하며, 이용자가 자신의 신용도를 관리할 수 있도록 돕고, 건강을 관리하고 노후를 대비하는데 필요한 정보를 제공하며, 이용자에게 적합한 상품을 추천·광고하는 등의 서비스 내 다양한 맞춤형 기능들을 제공하기 위한 목적으로 이용자의 개인정보를 처리합니다. 또한 송금 서비스를 제공하거나, 이용자의 전자서명 및 확인을 위한 인증서 서비스를 제공하거나, 기타 유료 서비스를 제공하는 경우 그러한 서비스 제공 및 이용료 정산 등을 위해 필요한 목적으로 이용자의 개인정보를 처리합니다.
              </p>
            </li>
            <li>
              <p>
                - <b>회사는 이용자가 자신의 정보를 실질적으로 관리하고, 활용할 수 있는 기능을 제공하기 위해 이용자의 개인정보를 처리합니다.</b> 회사는 신용정보의 이용 및 보호에 관한 법률상 본인신용정보관리업 및 이에 부수되는 업무를 수행합니다. 또한, 이용자가 자신의 정보를 제3자에게 전송하거나, 직접 다운로드 받거나, 이를 거래할 수 있도록 하는 기능 등을 제공하기 위해 이용자의 개인정보를 처리합니다.
              </p>
            </li>
            <li>
              <p>
                - <b>회사는 서비스를 개선하고, 새로운 기능을 개발하기 위해 이용자의 개인정보를 처리합니다.</b> 예를 들어, 이용자의 서비스 이용기록, 접속빈도, 연동한 데이터 및 통계자료 등을 기반으로 새로운 기능을 개발하여 제공하거나, 기존의 기능들을 개선할 수 있습니다. 또한, 이용자가 제공하는 의견을 활용하여 서비스를 개선할 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                - <b>회사는 안전한 회원 관리 및 분쟁 해결을 위해 이용자의 개인정보를 처리합니다.</b> 예를 들어, 회사는 이용자 식별 및 본인 확인, 연령 확인, 타인 명의 또는 접근매체 사용 여부 확인, 회원탈퇴 의사 확인, 민원 처리, 분쟁 해결 등을 위해 이용자의 개인정보를 처리합니다.
              </p>
            </li>
            <li>
              <p>
                - <b>회사는 이용자에게 서비스 이용에 관한 다양한 정보를 제공하고, 기타 유용한 정보와 광고를 제공하기 위해 이용자의 개인정보를 처리합니다.</b> 예를 들어, 회사는 새로운 기능 추가나 기능 변경에 대한 안내, 서비스 이용 방식 변경에 대한 통지, 서비스 중단 및 장애 안내, 이벤트 소개, 상품이나 서비스 광고 등 이용자에게 필요한 유용한 정보를 제공하기 위하여 이용자의 개인정보를 처리합니다.
              </p>
            </li>
            <li>
              <p>
                - <b>회사는 안전한 서비스 이용 환경을 지키고, 회사와 이용자들의 정당한 이익을 보호하기 위해 이용자의 개인정보를 처리합니다.</b> 예를 들어, 관련 법령 및 이용약관을 위반하는 이용자에 대한 이용 제한 조치, 부정한 서비스 이용 행위를 탐지하고 예방하기 위한 조치, 서비스 보안 강화 및 개인정보 처리 방식의 개선 등을 위해 이용자의 개인정보를 처리합니다.
              </p>
            </li>
            <li>
              <p>
                - <b>회사는 서비스 자체를 홍보하는데 이용자의 개인정보를 활용할 수 있습니다.</b> 예를 들어, 서비스 홍보 자료 내에 특정 이용자의 의견 등 정보가 포함될 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                - 회사는 이용자의 개인정보를 가명화하여 관련 법령에서 정한 바에 따라 처리할 수 있습니다. 또한, 수집 목적과 합리적 관련성이 있는 범위에서 관련 법령에 따라 이용자의 추가적인 동의 없이 이용자의 개인정보를 이용하거나 제공할 수 있습니다. 나아가 이용자의 개인정보를 모두 익명처리한 정보의 집합물을 관련 법령에 따라 제공하거나 처리할 수 있습니다.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <br/>
          <h4>제3자에게 제공하는 정보</h4>
          <p>이용자가 제휴 서비스를 이용하는 경우, 회사는 필요한 범위에서 이용자의 동의를 받아 이용자의 개인정보를 제휴 서비스와 관련된 제3자에게 제공합니다. 이용자의 개인정보를 제3자에게 제공하는 경우는 다음과 같습니다.</p>
          <table>
            <tbody>
              <tr>
                <th className={ styles.width20 }>제공받는 자</th>
                <th>제공 목적</th>
                <th>제공 항목</th>
                <th>보유 및 이용 기간</th>
              </tr>
              <tr>
                <td rowSpan={2}>
                  <div>
                  코리아크레딧뷰로 주식회사
                  </div>
                </td>
                <td>
                  <div>
                  신용 조회 서비스 (신용점수 및 신용정보 등 확인) 제공
                  </div>
                </td>
                <td>
                  <div>
                  이름, 생년월일 성별, 휴대폰번호, 중복가입확인정보(DI)
                  </div>
                </td>
                <td>
                  <div>
                  동의 철회시  또는 회원탈퇴 시까지
                    <br/>
                  (관련 법령에 따라 보존할 필요가 있는 경우 해당 보존기간)
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  신용 관리 서비스 (비금융정보를 신용평가에 반영하여 신용점수를 올리는 서비스) 제공
                  </div>
                </td>
                <td>
                  <div>
                  이름, 생년월일, 건강보험 납부확인서 상의 정보, 국민연금 납부 확인서 상의 정보, 소득금액증명서 상의 정보
                  </div>
                </td>
                <td>
                  <div>
                  비금융거래 정보 신용평가 반영 목적 달성 시 까지
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  내 금리조회 서비스 제휴 금융사
                    <br/>
                  (SC제일은행, BNK경남은행, JB우리캐피탈, BNK캐피탈, 스마트저축은행 등 이용자가 직접 선택한 금융회사)
                  </div>
                </td>
                <td>
                  <div>
                  내 금리 조회 서비스 (제휴 금융사가 제공하는 대출금리 확인) 제공
                  </div>
                </td>
                <td>
                  <div>
                  이름, 휴대폰번호,  직업(직장인, 개인사업자, 무직), 직장명, 고용형태, 입사년월, 연소득
                  </div>
                </td>
                <td>
                  <div>
                  동의 철회시 또는 제공 목적 달성시까지 (단, 금융사고조사, 분쟁해결, 민원처리 및 관련 법령에 따라 보존할 필요가 있는 경우 해당 보존기간)
                  </div>
                </td>

              </tr>
              <tr>
                <td>
                  <div>
                  주식회사 어니스트펀드
                  </div>
                </td>
                <td>
                  <div>
                  투자자의 계정관리 및 계좌 인증, 민원응대, 중복가입 확인 등
                  </div>
                </td>
                <td>
                  <div>
                  이름, 생년월일, 성별, 휴대폰번호, 계좌번호, 동일인 식별 연계정보(CI), 희망투자금액 정보
                  </div>
                </td>
                <td>
                  <div>
                  제공목적 달성 시 까지 (관련법령에 따라 보존할 필요가 있는 경우 해당 보존기간)
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  금융결제원, 오픈뱅킹 공동업무 참여 금융기관
                  </div>
                </td>
                <td>
                  <div>
                  출금이체 서비스 제공을 위한 출금동의 확인, 출금이체 신규등록, 해지사실 통지 등
                  </div>
                </td>
                <td>
                  <div>
                  이름, 생년월일, 성별, 동일인 식별 연계정보(CI), 이메일, 계좌번호, 은행명
                  </div>
                </td>
                <td>
                  <div>
                  오픈뱅킹 서비스 해지 시까지 (관련 법령에 따라 보존할 필요가 있는 경우는 해당 보존기간)
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  마이데이터 인증서 서비스 이용기관
                  </div>
                </td>
                <td>
                  <div>
                  마이데이터 이용기관 서비스 신청 및 이용, 이용자 식별 및 전자서명 확인, 마이데이터 인증서 파일 (탈취·부정발급·부정사용·비인가 사용방지 등 확인 및 이에 대한 이용자 안내, 기타 마이데이터 이용기관 서비스 관련 각종 이용자 안내 및 통지)
                  </div>
                </td>
                <td>
                  <div>
                  마이데이터 인증서 인증결과, 동일인 연계 식별정보(CI)
                  </div>
                </td>
                <td>
                  <div>
                  마이데이터 이용기관 서비스 탈퇴 시 까지 (관련 법령에 따라 보존할 필요가 있는 경우 해당 보존기간)
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <br/>
          <p>
            한편, 요금정산을 위해 필요한 경우나 법령의 규정에 따라 개인정보를 제출하여야 하는 의무가 있는 경우, 통계작성·과학적 연구·공익적 기록 보존 등의 목적으로 이용자를 식별할 수 없는 형태로 정보를 제공하는 경우, 수집목적과 합리적 관련성이 있는 범위에서 관련 법령에 따라 정보를 제공하는 경우 등에는 이용자의 별도 동의 없이 개인정보를 제공할 수 있습니다.
          </p>
        </li>
        <li>
          <br/>
          <h4>
            개인정보의 처리위탁
          </h4>
          <p>
            회사는 이용자에게 서비스를 제공하는데 필요한 일부 업무의 처리를 제3자에게 위탁하고 있으며, 다음과 같이 일부 위탁 업무에는 개인정보의 처리가 수반됩니다.
          </p>
          <table>
            <tbody>
              <tr>
                <th>수탁사</th>
                <th>위탁 업무 내용</th>
              </tr>
              <tr>
                <td>
                  <div>
                  코리아크레딧뷰로 주식회사
                  </div>
                </td>
                <td>
                  <div>
                  본인확인업무
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  NHN 주식회사, (주)알리는사람들
                  </div>
                </td>
                <td>
                  <div>
                  SMS 발송
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  주식회사 케이티엠하우스
                  </div>
                </td>
                <td>
                  <div>
                  기프티콘 발송
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  인비즈넷주식회사
                  </div>
                </td>
                <td>
                  <div>
                  ARS 인증업무
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  GS 네오텍
                  </div>
                </td>
                <td>
                  <div>
                  고객센터 콜센터 시스템 운영 및 유지보수 업무
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  나이스디앤알(주), (주)오토클릭서비스, (주)씨엘엠앤에스
                  </div>
                </td>
                <td>
                  <div>
                  자동차 시세 정보 조회
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  아마존웹서비시즈코리아 유한책임회사
                  </div>
                </td>
                <td>
                  <div>
                  클라우드컴퓨팅 서비스 상의 정보처리 및 저장
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  더치트(주)
                  </div>
                </td>
                <td>
                  <div>
                  금융사기 방지를 위한 사기계좌 조회
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  이니텍(주)
                  </div>
                </td>
                <td>
                  <div>
                  마이데이터 인증서 발급 및 관리 업무
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  (주)쿠콘
                  </div>
                </td>
                <td>
                  <div>
                  계좌인증 업무
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  (주)넥스티아
                  </div>
                </td>
                <td>
                  <div>
                  마이데이터 인증서 관련 문자수신서비스(SMS MO 서비스) 업무
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <br/>
          <p>
            회사는 이용자의 개인정보 처리 업무 중 일부를 아래와 같이 국외 사업자에게 위탁하고 있습니다.
          </p>
          <table>
            <tbody>
              <tr>
                <th>
                이전 받는 자
                </th>
                <th className={ styles.width10 }>
                이전 되는 국가
                </th>
                <th>
                이전 일시/방법
                </th>
                <th>
                이전 항목
                </th>
                <th>
                이용 목적
                </th>
                <th>
                이용 기간
                </th>
              </tr>
              <tr>
                <td>
                  <div>
                  Zendesk
                  </div>
                </td>
                <td>
                  <div>
                  미국1)
                  </div>
                </td>
                <td rowSpan={7}>
                  <div>
                  서비스 이용 시점에 네트워크를 통한 전송
                  </div>
                </td>
                <td>
                  <div>
                  User ID,
                  단말기 종류, OS정보, 앱 버전 정보, 이메일주소,
                  문의시 작성되는 정보
                  </div>
                </td>
                <td rowSpan={2}>
                  <div>
                  고객 문의 수집 및 안내 관리
                  </div>
                </td>
                <td rowSpan={7}>
                  <div>
                  위탁 목적 달성시 까지
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  Zoho Corporation Pvt Ltd
                  </div>
                </td>
                <td>
                  <div>
                  미국2)
                  </div>
                </td>
                <td>
                  <div>
                  User ID,
                  단말기 종류, OS정보, 앱 버전 정보, 이메일주소
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  Amplitude
                  </div>
                </td>
                <td>
                  <div>
                  미국3)
                  </div>
                </td>
                <td>
                  <div>
                  User ID, 방문 일시, 서비스 이용 기록, 불량 이용기록, 기기정보 등
                  </div>
                </td>
                <td>
                  <div>
                  앱 사용 현황 분석
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  Google
                  Cloud
                  Platform
                  (Firebase)
                  </div>
                </td>
                <td>
                  <div>
                  미국4)
                  </div>
                </td>
                <td>
                  <div>
                  User ID, 방문 일시, 서비스 이용 기록, 불량 이용기록, 기기정보 등
                  </div>
                </td>
                <td>
                  <div>
                  앱 사용 이력 정보 관리
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  Braze
                  </div>
                </td>
                <td>
                  <div>
                  미국5)
                  </div>
                </td>
                <td>
                  <div>
                  User ID, 방문 일시, 서비스 이용 기록, 기기정보 등
                  </div>
                </td>
                <td>
                  <div>
                  고객 분석에 따른 메시징
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  Instabug
                  </div>
                </td>
                <td>
                  <div>
                  미국6)
                  </div>
                </td>
                <td rowSpan={2}>
                  <div>
                  User ID, 방문
                  일시, 서비스 이용 기록,
                  기기정보 등
                  </div>
                </td>
                <td rowSpan={2}>
                  <div>
                  크래시 수집 및 분석
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  Sentry
                  </div>
                </td>
                <td>
                  <div>
                  미국7)
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <i>* 주소 및 연락처</i>
          <ol>
            <li>
              1) 1019 Market St, San Francisco, CA 94103 USA (+1-888-670-4887)
            </li>
            <li>
              2) 4141 Hacienda Drive, Pleasanton, CA 94588 USA
            </li>
            <li>
              3) 631 Howard Street, San Francisco, CA 94127 USA (+1 -415-231-2353)
            </li>
            <li>
              4) 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA
            </li>
            <li>
              5) 330 West 34th Street 18th Floor, New York, NY 10001 USA
            </li>
            <li>
              6) 855 El Camino Real St. Suite 13A-111, Palo Alto, CA 94301 USA
            </li>
            <li>
              7) 45 Fremont Street 8th Floor, San Francisco, CA 94105 USA
            </li>
          </ol>
        </li>
        <li>
          <br/>
          <h4>
            개인정보 보유기간 및 파기
          </h4>
          <p>
            회사는 이용자가 회원탈퇴하는 경우 관련 법령에 따라 이용자의 개인정보를 파기합니다.
            <br/>
            <br/>
            회사는 관련 법령이나 이용약관을 위반하여 탈퇴 조치된 이용자의 최소한의 개인정보를 재가입 방지 등의 합리적인 목적을 위해 일정 기간 보유합니다. 그 외에도 부정이용방지, 탈퇴 후 분쟁 및 민원 처리 등을 위하여 이용자의 개인정보를 일정기간 동안 보유합니다. 회사가 내부 방침에 의해 보유하는 정보는 다음과 같습니다.
          </p>
          <table>
            <tbody>
              <tr>
                <th>
                보존 정보
                </th>
                <th>
                보존 이유
                </th>
                <th>
                보존기간
                </th>
              </tr>
              <tr>
                <td>
                  <div>
                  부정이용기록, 본인인증기록, 탈퇴기록,
                  고객응대 및 처리기록
                  </div>
                </td>
                <td>
                  <div>
                  서비스 남용 및 부정이용방지, 탈퇴 후 분쟁 및 민원처리, 가입제한 등 회원 관리, 관련 법령 준수
                  </div>
                </td>
                <td>
                  <div>
                  회원 탈퇴 후 5년
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  전자서명인증정보 및
                  관련 기록
                  (이름, CI, 휴대폰 번호, 금융사명, 계좌번호)
                  </div>
                </td>
                <td>
                  <div>
                  마이데이터 인증서 발급 및 관리, 마이데이터 인증서 발급과 이용에 관한 기록 보존, 마이데이터 인증서 파일 탈취·부정발급·부정사용·부정사용·비인가 사용 방지 등 확인서비스 관련 민원 및 고객문의 응대
                  </div>
                </td>
                <td>
                  <div>
                  마이데이터 인증서 효력이 소멸된 날부터 10년(단, 금융사명과 계좌정보는 수집일로부터 5년)
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            또한, 회사는 관련 법령이 요구하는 경우 일정한 기간 동안 이용자의 개인정보를 안전하게 보관합니다. 회사가 법령에 따라 개인정보를 보관해야 하는 경우는 다음과 같습니다.
          </p>
          <ul>
            <li>
              <p>
                - 전자상거래 등에서의 소비자보호에 관한 법률
                <ul>
                  <li>
                    - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년
                  </li>
                  <li>
                    - 계약 또는 청약철회 등에 관한 기록 : 5년
                  </li>
                  <li>
                    - 대금결제 및 재화등의 공급에 관한 기록 : 5년
                  </li>
                  <li>
                    - 표시/광고에 대한 기록 : 6개월
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                - 전자금융거래법
                <ul>
                  <li>
                    - 전자금융에 관한 기록 : 5년
                  </li>
                </ul>
              </p>
            </li>
            <li>
              <p>
                - 특정금융거래정보의 보고 및 이용 등에 관한 법률
                <ul>
                  <li>
                    - 고객확인정보 : 5년
                  </li>
                </ul>
              </p>
            </li>
          </ul>
          <br/>
          <p>
            회사는 개인정보를 파기해야 하는 경우 해당 개인정보를 재생할 수 없는 방법으로 파기하거나, 더 이상 특정 개인인 정보주체를 알아볼 수 없도록 해당 정보를 익명처리합니다. 회사가 관련 법령에 따라 일정 기간 보관하는 개인정보의 경우에도 해당 기간이 도과하면 위와 같은 방법으로 안전하게 파기하거나 익명처리합니다.
          </p>
        </li>
        <li>
          <br/>
          <h4>
            이용자와 법정대리인의 권리 행사
          </h4>
          <p>
            이용자는 회사가 보유한 이용자의 개인정보에 대하여 열람·정정·삭제·처리정지를 요구할 수 있습니다.
            <br/>
            <br/>
            이용자는 언제든지 서비스 내의 <b>‘MY &gt; 기본 정보 보기 &gt; 개인설정’</b>에서 일부 정보를 수정하거나, <b>앱 데이터 초기화</b>를 통해 자신의 정보를 삭제할 수 있습니다. 또한, <b>회원탈퇴</b>를 하는 경우에도 등록했던 모든 금융정보와 개인정보가 삭제됩니다. 다만, 법령에 따라 회사가 보유해야 하는 정보는 예외입니다. 이용자는 <b>‘MY &gt; 데이터 연동 관리’</b>를 통해서 언제든지 정보 보유 기관과 연동을 설정하거나, 연동을 해지할 수 있습니다.
            <br/>
            <br/>
            또한 이용자는 서비스를 이용하면서 수집된 자신의 정보를 <b>데이터 내보내기</b>를 통하여 편리하게 휴대전화 등 모바일 기기로 다운로드하여 확인하실 수 있습니다.
            <br/>
            <br/>
            서비스 이용을 위해 연동된 개인정보의 정정 또는 삭제는 해당 정보를 보유하고 있는 기관에 직접 하는 것이 효과적입니다. 다만, 회사는 기술적으로 가능한 경우 이러한 정보를 정정하거나 삭제할 수 있도록 이용자를 지원합니다.
            <br/>
            <br/>
            이용자의 법정대리인은 이용자를 대리하여 이용자의 개인정보 열람·정정·삭제·처리정지를 요구할 수 있습니다. 회사는 이에 필요한 서식을 마련하고, 법정대리인에게 해당 서식의 작성 및 대리권을 증명할 수 있는 자료의 제출을 요청할 수 있습니다. 이용자나 법정대리인은 서비스 애플리케이션 내 ‘고객센터’를 이용하거나 또는 아래 개인정보 보호업무 수행 부서로 연락하여 이러한 권리를 행사할 수 있습니다.
          </p>
        </li>
        <li>
          <br/>
          <h4>
            자동 정보 수집 장치의 설치, 운영 및 거부에 관한 사항
          </h4>
          <p>
            회사는 이용자에게 맞춤서비스를 제공하고 서비스 이용환경을 개선하기 위해 이용자의 서비스 이용 정보를 저장하고, 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
            <br/>
            <br/>
            이용자가 웹사이트에 방문할 경우 웹사이트는 이용자의 디바이스에 저장되어 있는 쿠키를 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공합니다. 쿠키는 이용자가 웹사이트를 방문할 때, 이용자의 편리성을 높이기 위해 사용됩니다.
            <br/>
            <br/>
            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저의 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우 이용자의 설정 정보를 저장해야 하는 서비스는 이용에 어려움이 있을 수 있습니다.
          </p>
        </li>
        <li>
          <br/>
          <h4>
            개인정보의 안전성 확보
          </h4>
          <p>
            회사는 이용자의 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보 조치를 취하고 있습니다. 회사는 개인정보 보호법, 신용정보의 이용 및 보호에 관한 법률, 전자금융거래법 등 관련 법령에서 요구하는 기술적·관리적 보호조치 의무를 준수하며, 이용자의 개인정보가 안전하게 보호될 수 있도록 최선의 노력을 기울이고 있습니다.
            <br/>
            <br/>
            나아가 회사는 개인정보 보호를 위한 내부 정책을 수립하여 시행하고 있으며, 서비스의 개발 및 운영 과정에서 개인정보가 충분히 보호되는지 상시 점검하고 있습니다. 또한, 회사의 모든 임직원이 이용자의 개인정보 보호를 최우선으로 생각할 수 있도록 전사적인 교육 및 관리실태 점검을 정기적으로 진행합니다. 회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 개인정보 접근권한 관리 및 암호화 등의 기술적 보호조치도 취하고 있습니다.
          </p>
        </li>
        <li>
          <br/>
          <h4>
            개인정보 관련 고충사항 처리
          </h4>
          <p>
            회사는 개인정보 처리를 비롯한 이용자 고충사항을 신속하게 처리하기 위하여 서비스 애플리케이션 내에 ‘고객센터’를 운영하고 있습니다. 이용자는 ‘고객센터’를 통해 궁금한 사항을 문의하며, 필요한 조치를 요청할 수 있습니다.
            <br/>
            <br/>
            또한, 회사는 개인정보 보호 업무를 수행하는 부서를 두고 있습니다. 고객센터를 통한 고충 처리가 충분하지 않을 경우 아래 부서로 연락을 취할 수 있습니다.
            <br/>
            <br/>
            개인정보 보호업무 수행 부서: 법무팀
            <br/>
            연락처: <a href='mailto:privacy@banksalad.com'>privacy@banksalad.com</a>
            <br/>
            <br/>
            기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의하시기 바랍니다.
            <br/>
            <ul>
              <li>
                - 개인정보침해 신고센터(<a href='https://privacy.kisa.or.kr' target='_blank' rel='nofollow'>https://privacy.kisa.or.kr</a>, 국번없이 118)
              </li>
              <li>
                - 대검찰청 사이버수사과 (<a href='https://spo.go.kr' target='_blank' rel='nofollow'>https://spo.go.kr</a>, 국번없이 1301)
              </li>
              <li>
                - 경찰청 사이버안전국 (<a href='https://cyberbureau.police.go.kr' target='_blank' rel='nofollow'>https://cyberbureau.police.go.kr</a>, 국번없이 182)
              </li>
            </ul>
          </p>
        </li>
      </ol>
      <br/>
      <br/>
      <p>
        최종개정일 : 2021. 6. 25.
      </p>
    </div>
  </div>
);
