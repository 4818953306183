import { LoanCFAReviewInProgress } from 'core/entity/legal-configuration/loan/cfa/review/in-progress';
import { NetworkResponseMapper } from 'data/http/mapper';

export class LoanCFAReviewInProgressMapper implements NetworkResponseMapper<LoanCFAReviewInProgress> {
    toJson(target: LoanCFAReviewInProgress): any {
        return {
            status: 'in-progress'
        }
    }

    fromJson(json: any): LoanCFAReviewInProgress {
        return new LoanCFAReviewInProgress();
    }

}
