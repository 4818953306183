import { TransactionUpdateResult } from 'core/entity/personal-finance/transaction/update-result';
import { NetworkResponseMapper } from 'data/http/mapper';
import { UserTransactionMapper } from 'data/http/mapper/personal-finance/transaction';
import { TransactionTypeMapper } from 'data/http/mapper/personal-finance/transaction/type';
import { List } from 'immutable';
import { optional } from 'utils/index';

export class TransactionUpdateResultMapper implements NetworkResponseMapper<TransactionUpdateResult> {
    fromJson(json: any): TransactionUpdateResult {
        return new TransactionUpdateResult(
            List(json.updated_transactions.map((it: any) =>
                new UserTransactionMapper().fromJson(it)
            )),
            optional(json.similar_transactions, it =>
                new TransactionUpdateResult.Similars(
                    List(it.transactions.map((id: string) => id)),
                    new TransactionUpdateResult.SimilarsUpdatingConfiguration(
                        it.category_id,
                        new TransactionTypeMapper().fromJson(it.transaction_type)
                    )
                )
            )
        )
    }

}
