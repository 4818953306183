import { Entity } from 'core/entity';
import { CardCompanyCFAOverdue } from 'core/entity/legal-configuration/card-company/cfa/overdue/index';

export class CardCompanyCFAConfiguration extends Entity {
    overdue: CardCompanyCFAOverdue;

    constructor(overdue: CardCompanyCFAOverdue) {
        super();
        this.overdue = overdue;
    }
}
