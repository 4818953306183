import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanOrganizationType } from 'core/entity/loan/organization/type';

export class LoanOrganizationTypeMapper implements BrowserStorageMapper<LoanOrganizationType> {
    toJson(entity: LoanOrganizationType): any {
        switch (entity) {
            case LoanOrganizationType.PRIMARY_BANK:
                return 'primary_bank';
            case LoanOrganizationType.SECONDARY_BANK:
                return 'secondary_bank';
            case LoanOrganizationType.CAPITAL:
                return 'capital';
            case LoanOrganizationType.CARD_COMPANY:
                return 'card_company';
            case LoanOrganizationType.INSURANCE:
                return 'insurance';
            case LoanOrganizationType.P2P:
                return 'p2p';
            case LoanOrganizationType.ETC:
            default:
                return 'etc';
        }
    }

    toEntity(value: any): LoanOrganizationType {
        switch (value) {
            case 'primary_bank':
                return LoanOrganizationType.PRIMARY_BANK;
            case 'secondary_bank':
                return LoanOrganizationType.SECONDARY_BANK;
            case 'capital':
                return LoanOrganizationType.CAPITAL;
            case 'card_company':
                return LoanOrganizationType.CARD_COMPANY;
            case 'insurance':
                return LoanOrganizationType.INSURANCE;
            case 'p2p':
                return LoanOrganizationType.P2P;
            case 'etc':
            default:
                return LoanOrganizationType.ETC;
        }
    }

}
