import { Entity } from 'core/entity';

export class InstallmentIncludedTransaction extends Entity {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }
}
