import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { lets, optional } from 'utils/index';
import { PersonalizedInsuranceRecommendResultSummary } from 'core/entity/personalized-recommend/insurance/recommend-result/summary';
import { PersonalizedInsuranceTagMapper } from 'data/http/mapper/personalized-recommend/insurance/tag';
import { MedicalCheckupJudgementItemMapper } from 'data/http/mapper/medical-checkup/judgement/item';
import { InsurancePromotionMapper } from 'data/http/mapper/insurance/promotion';
import { InsurancePaymentPriceMapper } from 'data/http/mapper/insurance/payment-price';
import { InsuranceCompanyMapper } from 'data/http/mapper/insurance/company';
import { PersonalizedInsuranceRecommendResultWordingMapper } from 'data/http/mapper/personalized-recommend/insurance/recommend-result/wording';

export class PersonalizedInsuranceRecommendResultSummaryMapper implements NetworkResponseMapper<PersonalizedInsuranceRecommendResultSummary> {
    fromJson(json: any): PersonalizedInsuranceRecommendResultSummary {
        return new PersonalizedInsuranceRecommendResultSummary(
            json.id,
            new InsuranceCompanyMapper().fromJson(json.company),
            lets(json.type, it => {
                switch (it) {
                    case 'accident':
                        return PersonalizedInsuranceRecommendResultSummary.Type.ACCIDENT;
                    case 'actual_expenses':
                        return PersonalizedInsuranceRecommendResultSummary.Type.ACTUAL_EXPENSES;
                    case 'cancer':
                        return PersonalizedInsuranceRecommendResultSummary.Type.CANCER;
                    case 'child':
                        return PersonalizedInsuranceRecommendResultSummary.Type.CHILD;
                    case 'critical_illness':
                        return PersonalizedInsuranceRecommendResultSummary.Type.CRITICAL_ILLNESS;
                    case 'driver':
                        return PersonalizedInsuranceRecommendResultSummary.Type.DRIVER;
                    case 'illness':
                        return PersonalizedInsuranceRecommendResultSummary.Type.ILLNESS;
                    case 'motor':
                        return PersonalizedInsuranceRecommendResultSummary.Type.MOTOR;
                    case 'pension':
                        return PersonalizedInsuranceRecommendResultSummary.Type.PENSION;
                    case 'pension_savings':
                        return PersonalizedInsuranceRecommendResultSummary.Type.PENSION_SAVINGS;
                    case 'savings':
                        return PersonalizedInsuranceRecommendResultSummary.Type.SAVINGS;
                    default:
                        throw new MappingError();
                }
            }),
            lets(json.kind, it => {
                switch (it) {
                    case 'maturity_refund_100':
                        return PersonalizedInsuranceRecommendResultSummary.Kind.MATURITY_REFUND_100;
                    case 'maturity_refund_50':
                        return PersonalizedInsuranceRecommendResultSummary.Kind.MATURITY_REFUND_50;
                    case 'pure_guarantee':
                        return PersonalizedInsuranceRecommendResultSummary.Kind.PURE_GUARANTEE;
                    case 'cancel_impossible_refund':
                        return PersonalizedInsuranceRecommendResultSummary.Kind.CANCEL_IMPOSSIBLE_REFUND;
                    case 'standard':
                        return PersonalizedInsuranceRecommendResultSummary.Kind.STANDARD;
                    default:
                        throw new MappingError();
                }
            }),
            lets(json.renewal_type, it => {
               switch (it) {
                   case 'renewal':
                       return PersonalizedInsuranceRecommendResultSummary.RenewalType.RENEWAL;
                   case 'non_renewal':
                       return PersonalizedInsuranceRecommendResultSummary.RenewalType.NON_RENEWAL;
                   default:
                       throw new MappingError();
               }
            }),
            json.name,
            json.insurance_period,
            json.payment_period,
            json.main_claim_volume,
            json.matched_items.map(new MedicalCheckupJudgementItemMapper().fromJson).toList(),
            json.tags.map(new PersonalizedInsuranceTagMapper().fromJson).toList(),
            new InsurancePaymentPriceMapper().fromJson(json.payment_price),
            json.total_guaranteed_price,
            json.expected_medical_expenses,
            json.calculation_criteria,
            json.max_guaranteed_price,
            new PersonalizedInsuranceRecommendResultWordingMapper().fromJson(json.wording),
            optional(json.promotion, new InsurancePromotionMapper().fromJson),
            optional(json.apply_url),
        )
    }
}
