import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { HttpProvider } from 'data/http';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';
import { SpiderCardCompanySummaryMapper } from 'data/http/mapper/personal-finance/card-company/summary';

export class SpiderCardCompaniesApiProvider extends HttpProvider {
    getAll(onlyCodeConfigured: boolean): Observable<List<SpiderCardCompanySummary>> {
        return this.requestGET(
            'api/v2/cards/companies/spider',
            { 'only-code-configured': onlyCodeConfigured }
        ).map((result: any) =>
            List(result.data.map(new SpiderCardCompanySummaryMapper().fromJson))
        );
    }
}
