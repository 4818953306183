import { Entity } from 'core/entity';

export class PromotionButton extends Entity {
    constructor(
        public title: string,
        public color: string,
        public isRound: boolean,
    ) {
        super();
    }
}
