import { SpiderData } from 'core/entity/personal-finance/spider-data';

export class SpiderBankAccountData extends SpiderData {
    constructor(
        name: string,
        public accountType: SpiderBankAccountData.AccountType,
        responseContained: boolean = null
    ) {
        super(name, responseContained);
    }
}

export module SpiderBankAccountData {
    export enum AccountType {
        CHECK = 'check',
        ETC = 'etc'
    }
}
