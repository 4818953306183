import { Card } from 'core/entity/card';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardAnnualCostMapper } from 'data/http/mapper/card/annual-cost';
import { CardAnnualCostPromotionMapper } from 'data/http/mapper/card/annual-cost-promotion';
import { CardCategoryBenefitMapper } from 'data/http/mapper/card/category-benefit';
import { CardCategorySpecialBenefitMapper } from 'data/http/mapper/card/category-special-benefit';
import { CardConditionalRestrictionMapper } from 'data/http/mapper/card/condition-restriction';
import { CardImageOrientationMapper } from 'data/http/mapper/card/image-orientation';
import { CardPremiumVoucherGroupMapper } from 'data/http/mapper/card/premium-voucher-group';
import { CardSelectiveGroupMapper } from 'data/http/mapper/card/selective-group';
import { CardShinhanComboMapper } from 'data/http/mapper/card/shinhan-combo';
import { CardTypeMapper } from 'data/http/mapper/card/type';
import { List } from 'immutable';
import { apply, optional } from 'utils/index';

export class CardMapper implements NetworkResponseMapper<Card> {
  fromJson(json: any): Card {
    return apply(new Card(
      json.id,
      json.name,
      json.image_path,
      new CardImageOrientationMapper().fromJson(json.image_orientation),
      json.issue_url_desktop,
      json.issue_url_mobile,
      json.issue_url_app,
      json.company_id,
      json.company_image_path,
      json.company_name,
      new CardTypeMapper().fromJson(json.card_type),
      List(json.default_benefits.map((benefit: any) =>
        new CardCategoryBenefitMapper().fromJson(benefit),
      )),
      List(json.special_benefit_categories),
      List(json.base_rests),
      List(json.cond_rests.map((rest: any) =>
        new CardConditionalRestrictionMapper().fromJson(rest),
      )),
      List(json.annual_costs.map((cost: any) =>
        new CardAnnualCostMapper().fromJson(cost),
      )),
      json.annual_cost_with_promotion,
      json.minimum_annual_cost,
      json.maximum_annual_cost,
      json.stopped,
      json.complicated,
    ), it => {
      it.issueUrlInterparkDesktop = json.issue_url_interpark_desktop;
      it.issueUrlInterparkMobile = json.issue_url_interpark_mobile;
      it.issuePhoneNumber = json.issue_phone_number;
      it.description = json.description;
      it.annualCostPromotion = optional(json.annual_cost_promotion, it =>
        new CardAnnualCostPromotionMapper().fromJson(it),
      );
      it.categorySpecialBenefits = optional(json.category_special_benefits, it =>
        List(it.map((benefit: any) =>
          new CardCategorySpecialBenefitMapper().fromJson(benefit),
        )),
      );
      it.selectiveBenefits = optional(json.selective_benefits, it =>
        List(it.map((group: any) =>
          new CardSelectiveGroupMapper().fromJson(group),
        ),
        ));
      it.premiumVouchers = optional(json.premium_vouchers, it =>
        List(it.map((group: any) =>
          new CardPremiumVoucherGroupMapper().fromJson(group),
        ),
        ));
      it.shinhanCombo = optional(json.shinhan_combo, it =>
        new CardShinhanComboMapper().fromJson(it),
      );
      it.representativeBenefitDescription = optional(json.representative_benefit_description);
      it.informationUrlDesktop = optional(json.information_url_desktop);
      it.informationUrlMobile = optional(json.information_url_mobile);
      it.informationUrlApp = optional(json.information_url_app);
    });
  }
}
