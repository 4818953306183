import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceUserCardSummary } from 'core/entity/personal-finance/advice/user-card-summary';

export class AdviceUserCardSummaryMapper implements NetworkResponseMapper<AdviceUserCardSummary> {
    fromJson(json: any): AdviceUserCardSummary {
        return new AdviceUserCardSummary(
            json.id,
            json.name
        );
    };
}
