import React from 'react';
import moment from 'moment';

import { SignInTextEditorModel } from 'presentation/components/users/base/text-editor/models';
import { SignInTextEditor } from 'presentation/components/users/base/text-editor';
import { LoginMobile } from 'presentation/components/users/base/checkbox-login-mobile';
import { LoginMobileAllCheckbox } from 'presentation/components/users/base/checkbox-login-mobile-all';
import { SIGN_IN_MOBILE_PHONE_COMPANIES } from 'presentation/components/users/mobile-authentication/presets';
import { MobileAuthenticationPhoneConfiguration } from 'core/entity/user/mobile-authenticate-phone-configuration';
import { SignInMobileTimer } from 'presentation/components/users/mobile-authentication/base/timer';
import { lets, removeCharactersExceptNumbers } from 'utils/index';
import { MobileAuthenticationCodeSpec } from 'core/entity/user/mobile-authenticate-code-spec';
import { FetchState } from 'presentation/view-model/fetch-state';
import { MobileAuthenticationCode } from 'core/entity/user/mobile-authenticate-code';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';
import { MobileAuthenticationSpec } from 'core/entity/user/mobile-authenticate-spec';
import { FlexibleDropDown } from 'presentation/components/flexible-drop-down';
import { Application } from 'application/context.instance';
import { SubscriptionBag } from 'presentation/module/extension';
import { FlexibleAlert } from 'presentation/components/flexible-alert';
import { toast } from 'presentation/module/sugar';
import { HttpStatusCode, NetworkError } from 'data/http';

import styles from './styles.pcss';

interface Props {
    isUnderSignUpMode: boolean;
}

interface State {
    name: string;
    birthDate: string;
    genderNumber: string;
    phoneNumber: string;
    mobileAuthenticationCode: MobileAuthenticationCode;
    verificationCode: string;
    phoneCompany: MobileAuthenticationPhoneConfiguration.Company;
    activeRequestAuthentication: boolean;
    alert: boolean;
    visibleAgeAlert: boolean;
    agreeOfThirdParty: boolean;
    agreeOfCreditAgreement: boolean;
    agreeOfPolicyPrivacy: boolean;
    agreeOfPolicyPersonality: boolean;
    fetchState: FetchState;
}

const ALERT_TITLE =
    '인증문자가 오지 않았어요!';
const ALERT_TEXT =
    '02-708-1007이 차단되어 있다면 인증문자가 도착하지 않습니다. 스팸차단 앱과 휴대폰 기기에서 모두 차단해제 후 다시 시도 해주세요';
const ALERT_ALL_INPUT_CHECKED = '필수 항목을 정확하게 입력하지 않았습니다';
const AGE_ALERT_TITLE = '14세 미만의 회원은 가입이 제한됩니다.';
const AGE_ALERT_TEXT = '국내법상 만 14세 미만의 회원은 보호자 동의를 통해서만 가입할 수 있습니다. 추후 지원 예정으로, 현재 가입이 어려운 점 양해 부탁 드립니다.';
const MINIMUM_AGE = 14;
const MINIMUM_VALIDATION_CODE_LENGTH = 6;

export class MobileAuthenticationView extends React.Component<Props, State> {
    state = {
        name: '',
        birthDate: '',
        genderNumber: '',
        mobileAuthenticationCode: null as MobileAuthenticationCode,
        verificationCode: '',
        phoneNumber: '',
        phoneCompany: null as MobileAuthenticationPhoneConfiguration.Company,
        activeRequestAuthentication: false,
        alert: false,
        visibleAgeAlert: false,
        agreeOfThirdParty: false,
        agreeOfCreditAgreement: false,
        agreeOfPolicyPrivacy: false,
        agreeOfPolicyPersonality: false,
        fetchState: FetchState.FETCHED
    };

    private subscriptionBag = new SubscriptionBag();

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const {
            name,
            birthDate,
            genderNumber,
            phoneNumber,
            phoneCompany,
            verificationCode,
            activeRequestAuthentication,
            alert,
            visibleAgeAlert,
            agreeOfThirdParty,
            agreeOfCreditAgreement,
            agreeOfPolicyPrivacy,
            agreeOfPolicyPersonality,
            fetchState
        } = this.state;

        return (
            <div className={ styles.wrap }>
                <ul className={ styles.list }>
                    <li className={ styles.editorContainer }>
                    <SignInTextEditor
                        value={ name }
                        signInTextEditor={ new SignInTextEditorModel(SignInTextEditorModel.TextEditorType.TEXT, '이름') }
                        onChange={ this.onChangeName }
                    />
                </li>
                    <li className={ styles.editorContainer }>
                        <div className={ styles.socialId }>
                            <div className={ styles.socialIdBirth }>
                            <SignInTextEditor
                                value={ birthDate }
                                signInTextEditor={ new SignInTextEditorModel(SignInTextEditorModel.TextEditorType.TEXT, '주민번호 앞 6자리') }
                                onChange={ this.onChangeBirthDate }
                            />
                        </div>
                            <div className={ styles.socialIdNumber }>
                                <div className={ styles.socialIdNumberContainer }>
                                <SignInTextEditor
                                    value={ genderNumber }
                                    signInTextEditor={ new SignInTextEditorModel(SignInTextEditorModel.TextEditorType.TEXT, '') }
                                    onChange={ this.onChangeGenderNumber }
                                />
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <h2 className={ styles.subTitle }>휴대폰 인증</h2>
                    <ul>
                        <li className={ styles.allCheck }>
                        <LoginMobileAllCheckbox
                            text="본인 확인을 위한 모든 약관에 동의"
                            checked={ this.isAllPoliciesChecked() }
                            onChange={ this.onToggleAllPolicies }
                        />
                    </li>
                        <li className={ styles.check }>
                        <LoginMobile
                            text="통신사 이용 약관"
                            url={ BANKSALAD_SITEMAP.POLICIES_KCB_TERMS }
                            checked={ agreeOfThirdParty }
                            onChange={ this.onChangeThirdParty }
                        />
                    </li>
                        <li className={ styles.check }>
                        <LoginMobile
                            text="KCB 개인정보 수집 이용 동의"
                            url={ BANKSALAD_SITEMAP.POLICIES_KCB_AGREEMENT }
                            checked={ agreeOfCreditAgreement }
                            onChange={ this.onChangeCreditAgreement }
                        />
                    </li>
                        <li className={ styles.check }>
                        <LoginMobile
                            text="KCB 고유식별 정보 처리 동의"
                            url={ BANKSALAD_SITEMAP.POLICIES_KCB_PRIVACY }
                            checked={ agreeOfPolicyPrivacy }
                            onChange={ this.onChangePolicyPrivacy }
                        />
                    </li>
                        <li className={ styles.check }>
                        <LoginMobile
                            text="휴대폰 본인확인 이용 약관"
                            url={ BANKSALAD_SITEMAP.POLICIES_KCB_PERSONALITY }
                            checked={ agreeOfPolicyPersonality }
                            onChange={ this.onChangePolicyPersonality }
                        />
                    </li>
                    </ul>
                </li>
                    <li className={ styles.phoneWrap }>
                        <div className={ styles.phoneCompanyContainer }>
                        <FlexibleDropDown
                            value={ phoneCompany }
                            data={ SIGN_IN_MOBILE_PHONE_COMPANIES }
                            onChange={ this.onChangePhoneCompany }
                            onRenderActive={ this.renderDropDownActive }
                            onRenderItem={ this.renderDropDownItem }
                        />
                    </div>
                        <div className={ styles.phoneNumber }>
                        <SignInTextEditor
                            value={ phoneNumber }
                            signInTextEditor={ new SignInTextEditorModel(SignInTextEditorModel.TextEditorType.TEXT, '휴대폰 11자리') }
                            onChange={ this.onChangePhoneNumber }
                        />
                    </div>
                    <button
                        onClick={ this.onClickRequestMobileAuthentication }
                        className={ activeRequestAuthentication ? styles.activeRequestAuthentication : styles.requestAuthentication }
                    >
                        { activeRequestAuthentication ? '인증재요청' : '인증요청' }
                    </button>
                </li>
                {
                    activeRequestAuthentication &&
                    <>
                        <li className={ styles.authenticationWrap }>
                            <div className={ styles.authenticationNumber }>
                                <SignInTextEditor
                                    value={ verificationCode }
                                    signInTextEditor={ new SignInTextEditorModel(SignInTextEditorModel.TextEditorType.TEXT, '인증번호 입력') }
                                    onChange={ this.onChangeVerificationCode }
                                />
                            </div>
                            {
                                fetchState === FetchState.FETCHED &&
                                <div className={ styles.timer }>
                                    <SignInMobileTimer/>
                                </div>
                            }
                        </li>
                        <li>
                            <button onClick={ this.onToggleAlert } className={ styles.alert }>인증문자가 오지 않았어요!</button>
                        </li>
                    </>
                }
                <li>
                    <button
                        onClick={ this.onSubmit }
                        className={ styles.submit }
                    >
                        인증하기
                    </button>
                </li>
                </ul>
                {
                    alert &&
                    <FlexibleAlert
                        title={ ALERT_TITLE }
                        onCancel={ this.onToggleAlert }
                    >
                        <p className={ styles.noticeAlert }>{ ALERT_TEXT }</p>
                    </FlexibleAlert>
                }
                {
                    visibleAgeAlert &&
                    <FlexibleAlert
                        title={ AGE_ALERT_TITLE }
                        onCancel={ this.onToggleAgeAlert }
                    >
                        <p className={ styles.noticeAlert }>{ AGE_ALERT_TEXT }</p>
                    </FlexibleAlert>
                }
            </div>
        )
    }

    private onChangeThirdParty = () =>
        this.setState(prevState => ({ agreeOfThirdParty: !prevState.agreeOfThirdParty }));

    private onChangeCreditAgreement = () =>
        this.setState(prevState => ({ agreeOfCreditAgreement: !prevState.agreeOfCreditAgreement }));

    private onChangePolicyPrivacy = () =>
        this.setState(prevState => ({ agreeOfPolicyPrivacy: !prevState.agreeOfPolicyPrivacy }));

    private onChangePolicyPersonality = () =>
        this.setState(prevState => ({ agreeOfPolicyPersonality: !prevState.agreeOfPolicyPersonality }));

    private isAllPoliciesChecked = () => {
        const {
            agreeOfThirdParty,
            agreeOfCreditAgreement,
            agreeOfPolicyPrivacy,
            agreeOfPolicyPersonality
        } = this.state;

        return (
            agreeOfThirdParty &&
            agreeOfCreditAgreement &&
            agreeOfPolicyPrivacy &&
            agreeOfPolicyPersonality
        );
    };

    private isAllFormsChecked = () => {
        const {
            name,
            birthDate,
            genderNumber,
            phoneNumber,
            phoneCompany
        } = this.state;

        return (
            this.isAllPoliciesChecked() &&
            !!name &&
            !!birthDate &&
            !!genderNumber &&
            phoneNumber &&
            !!phoneCompany
        );
    };

    private onToggleAllPolicies = () => {
        this.isAllPoliciesChecked() ?
            this.setState({
                agreeOfThirdParty: false,
                agreeOfCreditAgreement: false,
                agreeOfPolicyPrivacy: false,
                agreeOfPolicyPersonality: false
            }) :
            this.setState({
                agreeOfThirdParty: true,
                agreeOfCreditAgreement: true,
                agreeOfPolicyPrivacy: true,
                agreeOfPolicyPersonality: true
            });
    };

    private renderDropDownActive = (menu: MobileAuthenticationPhoneConfiguration.Company) => (
        menu ?
            <div className={ styles.activatedPhoneCompany }>{ this.presentCompanyName(menu) }</div> :
            <div className={ styles.deActivatedPhoneCompany }>통신사</div>
    );

    private renderDropDownItem = (menu: MobileAuthenticationPhoneConfiguration.Company) => (
        <div className={ styles.itemOfPhoneCompany }>{ this.presentCompanyName(menu) }</div>
    );

    private onChangeName = (name: string) => this.setState({ name });

    private onChangePhoneCompany = (phoneCompany: MobileAuthenticationPhoneConfiguration.Company) =>
        this.setState({ phoneCompany });

    private onChangeBirthDate = (birthDate: string) => {
        if (birthDate.length > 6)
            return;

        this.setState({ birthDate: removeCharactersExceptNumbers(birthDate) });
    };

    private onChangeGenderNumber = (genderNumber: string) => {
        if (genderNumber.length > 1)
            return;

        this.setState({genderNumber: removeCharactersExceptNumbers(genderNumber).slice(0, 1)});
    };

    private onChangePhoneNumber = (phoneNumber: string) => {
        if (phoneNumber.length > 11)
            return;

        this.setState({ phoneNumber: removeCharactersExceptNumbers(phoneNumber) });
    };

    private onChangeVerificationCode = (verificationCode: string) => (
        this.setState({ verificationCode })
    );

    private onToggleAlert = () => (
        this.setState(prevState => ({ alert: !prevState.alert }))
    );

    private onToggleAgeAlert = () => (
        this.setState(prevState => ({ visibleAgeAlert: !prevState.visibleAgeAlert }))
    );

    private presentCompanyName = (company: MobileAuthenticationPhoneConfiguration.Company) => {
        switch (company) {
            case MobileAuthenticationPhoneConfiguration.Company.SKT:
                return 'SKT';
            case MobileAuthenticationPhoneConfiguration.Company.KT:
                return 'KT';
            case MobileAuthenticationPhoneConfiguration.Company.LGU:
                return 'LG U+';
            case MobileAuthenticationPhoneConfiguration.Company.SUB_SKT:
                return 'SKT 알뜰폰';
            case MobileAuthenticationPhoneConfiguration.Company.SUB_KT:
                return 'KT 알뜰폰';
            case MobileAuthenticationPhoneConfiguration.Company.SUB_LGU:
                return 'LG U+ 알뜰폰';
        }
    };

    private onClickRequestMobileAuthentication = async () => {
        const {
            isUnderSignUpMode
        } = this.props;
        const {
            name,
            birthDate,
            genderNumber,
            phoneCompany,
            phoneNumber
        } = this.state;
        const spec = new MobileAuthenticationCodeSpec(
            name,
            birthDate + genderNumber,
            new MobileAuthenticationPhoneConfiguration(phoneNumber, phoneCompany)
        );

        if (!this.isAllFormsChecked())
            return toast(ALERT_ALL_INPUT_CHECKED);

        if (this.isUnder14Years())
            return this.onToggleAgeAlert();

        await this.setState({
            fetchState: FetchState.FETCHING
        });

        isUnderSignUpMode ?
            Application.services.user
                .requestMobileAuthenticationCodeOnSignUp(spec)
                .useOnAnimateFrame()
                .subscribe(
                    this.onSuccessMobileAuthenticationCode,
                    this.onErrorMobileAuthenticationCode
                )
                .unsubscribeBy(this.subscriptionBag) :
            Application.services.user
                .requestMobileAuthenticationCodeOnLogin(spec)
                .useOnAnimateFrame()
                .subscribe(
                    this.onSuccessMobileAuthenticationCode,
                    this.onErrorMobileAuthenticationCode
                )
                .unsubscribeBy(this.subscriptionBag);
    };

    private onSubmit = async () => {
        const {
            isUnderSignUpMode
        } = this.props;
        const {
            mobileAuthenticationCode,
            phoneNumber,
            verificationCode
        } = this.state;
        const checkedAllInputValues =
            !!mobileAuthenticationCode &&
            !!phoneNumber &&
            !!verificationCode && verificationCode.length === MINIMUM_VALIDATION_CODE_LENGTH;
        const spec = new MobileAuthenticationSpec(
            mobileAuthenticationCode.authenticationRequestId,
            phoneNumber,
            verificationCode
        );

        if (!checkedAllInputValues)
            return toast(ALERT_ALL_INPUT_CHECKED);

        isUnderSignUpMode ?
            Application.services.user
                .smsCodeVerify(spec)
                .useOnAnimateFrame()
                .subscribe(
                    this.onSuccessSubmit,
                    this.onErrorSubmit
                )
                .unsubscribeBy(this.subscriptionBag) :
            Application.services.user
                .signInWithMobileAuthentication(spec)
                .useOnAnimateFrame()
                .subscribe(
                    this.onSuccessSubmit,
                    this.onErrorSubmit
                )
                .unsubscribeBy(this.subscriptionBag)
    };

    private onSuccessSubmit = () => (
        location.href = BANKSALAD_SITEMAP.PFM_MY_FINANCE
    );

    private onErrorSubmit = (
        error: any
    ) => {
        if (error instanceof NetworkError)
            this.handleSubmitError(error);

        this.setState({
            fetchState: FetchState.ERROR
        });
    };

    private onSuccessMobileAuthenticationCode = (
        mobileAuthenticationCode: MobileAuthenticationCode
    ) => (
        this.setState({
            fetchState: FetchState.FETCHED,
            activeRequestAuthentication: true,
            mobileAuthenticationCode
        })
    );

    private onErrorMobileAuthenticationCode = (
        error: any
    ) => {
        if (error instanceof NetworkError)
            this.handleMobileAuthenticationError(error);

        this.setState({
            fetchState: FetchState.ERROR
        });
    };

    private handleMobileAuthenticationError = (error: NetworkError) => {
        const {
            statusCode,
            message
        } = error;

        toast(lets(statusCode, code => {
            switch (code) {
                case HttpStatusCode.BAD_REQUEST:
                    return '잘못된 요청을 시도하였습니다. 다시 시도해주세요.';
                case HttpStatusCode.FORBIDDEN:
                    return '하루 최대 본인 인증 시도 횟수를 초과하였습니다.';
                case HttpStatusCode.BAN_USER:
                    return !!message ?
                        message :
                        '비정상적인 인증시도 횟수로 인해 차단된 휴대폰 번호입니다. 차단 해제를 위해서는 hello@banksalad.com 로 문의해주세요.';
                case HttpStatusCode.NOT_FOUND:
                    return '다른 로그인 방법을 시도해보시거나, 앱을 통해 회원가입을 진행해주세요.';
                case HttpStatusCode.EXCESSIVE_REQUEST:
                    return !!message ?
                        message :
                        '비정상적인 인증시도 횟수로 인해 지금은 본인인증을 할 수 없습니다. 한시간 뒤에 다시 시도해주세요.';
                default:
                    return '입력한 정보를 다시 확인해주세요';
            }
        }));
    };

    private handleSubmitError = (error: NetworkError) => {
        toast(lets(error.statusCode, code => {
            switch (code) {
                case HttpStatusCode.CONFLICT:
                    return '본인인증한 계정이 이미 있습니다. 본인인증으로 로그인해주세요.';
                case HttpStatusCode.FORBIDDEN:
                    return '해당 요청에 대해 최대 SMS 코드 인증 횟수 초과';
                case HttpStatusCode.NOT_FOUND:
                    return '다른 로그인 방법을 시도해보시거나, 앱을 통해 회원가입을 진행해주세요.';
                case HttpStatusCode.TIME_OUT:
                    return 'SMS 코드 인증 시간 초과';
                case HttpStatusCode.UNPROCESSABLE_ENTITY:
                    return 'SMS 인증 코드 불일치';
                default:
                    return '입력한 정보를 다시 확인해주세요.';
            }
        }));
    };

    private getFullDate = (): string => {
        const {
            birthDate,
            genderNumber
        } = this.state;

        switch (genderNumber) {
            case '0':
            case '9':
                return '18' + birthDate;
            case '1':
            case '2':
            case '5':
            case '6':
                return '19' + birthDate;
            case '3':
            case '4':
            case '7':
            case '8':
                return '20' + birthDate;
        }
    };

    private isUnder14Years = () => (
        moment(this.getFullDate())
            .startOf('day')
            .isAfter(moment().add(-MINIMUM_AGE, 'years').startOf('day'))
    );
}
