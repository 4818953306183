import { List } from 'immutable';
import { NetworkResponseMapper } from 'data/http/mapper';
import { CardBenefit } from 'core/entity/card/benefit';
import { optional } from 'utils/index';

export class CardBenefitMapper implements NetworkResponseMapper<CardBenefit> {
    fromJson(json: any): CardBenefit {
        return new CardBenefit(
            json.benefit,
            List(json.conditions),
            List(json.restrictions),
            optional(json.description)
        )
    }
}
