import { NetworkResponseMapper } from 'data/http/mapper';
import { Amount } from 'core/entity/amount';
import { lets } from 'utils/index';
import { UserSecuritiesFirmAccountItemConfiguration } from 'core/entity/personal-finance/securities-firm-account/item-configuration';
import { List } from 'immutable';
import { UserSecuritiesFirmAccountFundItemMapper } from 'data/http/mapper/personal-finance/securities-firm-account/fund-item';
import { UserSecuritiesFirmAccountStockItemMapper } from 'data/http/mapper/personal-finance/securities-firm-account/stock-item';

export class UserSecuritiesFirmAccountItemConfigurationMapper implements NetworkResponseMapper<UserSecuritiesFirmAccountItemConfiguration> {
    fromJson(json: any): UserSecuritiesFirmAccountItemConfiguration {
        return new UserSecuritiesFirmAccountItemConfiguration(
            List(json.funds.map((it: any) =>
                new UserSecuritiesFirmAccountFundItemMapper().fromJson(it)
            )),
            List(json.stocks.map((it: any) =>
                new UserSecuritiesFirmAccountStockItemMapper().fromJson(it)
            ))
        )
    }

}
