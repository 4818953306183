import { BANKSALAD_SITEMAP, OUTBOUND_LINK } from 'presentation/module/sitemap';
import React from 'react';

import { FooterMenuLink } from './FooterMenuLink';

import styles from './styles.pcss';

enum MENU_TEXT {
  POPULAR_SERVICE = '많이 찾는 서비스',
  CREDIT_LOAN_INTEREST_RATE_COMPARISON_AT_ONCE = '신용대출 금리 비교 한 번에',
  CREDIT_LOAN_INTEREST_RATE_TOP_100 = '신용대출 금리 TOP100',
  MORTGAGE_LOAN_INTEREST_RATE_COMPARISON_AT_ONCE = '주택담보대출 금리 비교 한 번에',
  CREDIT_CARD_CASHBACK_EVENT = '신용카드 캐시백 이벤트',
  FREE_GENETIC_TESTING = '무료 유전자 검사',
  FREE_ORAL_MICROBIOME_TESTING = '무료 미생물 검사',

  BANKSALAD = '뱅크샐러드',
  CORP = '회사소개',
  BLOG = '블로그',
  INSTAGRAM = '인스타그램',
  YOUTUBE = '유튜브',
  SAFETY_CENTER = '고객안심센터',
}

export const FooterSiteMenu = () => (
  <section className={ styles.container }>
    <div className={ styles.sites }>
      <div className={ styles.menuTitle }>{MENU_TEXT.POPULAR_SERVICE}</div>
      <ul>
        <FooterMenuLink
          linkTo={ BANKSALAD_SITEMAP.PREQUALIFICATION_LOANS_CREDIT }
          text={ MENU_TEXT.CREDIT_LOAN_INTEREST_RATE_COMPARISON_AT_ONCE }
          target='_self'
          rightLabel={<span className={ styles.listLabel }>BEST</span>}
        />
        <FooterMenuLink
          linkTo={ BANKSALAD_SITEMAP.CHART_LOANS_SORT_TYPE_AVERAGE_INTEREST_RATE_ASC }
          text={ MENU_TEXT.CREDIT_LOAN_INTEREST_RATE_TOP_100 }
          target='_self'
        />
        <FooterMenuLink
          linkTo={ BANKSALAD_SITEMAP.PREQUALIFICATION_LOANS_MORTGAGE }
          text={ MENU_TEXT.MORTGAGE_LOAN_INTEREST_RATE_COMPARISON_AT_ONCE }
          target='_self'
        />
        <FooterMenuLink
          linkTo={ BANKSALAD_SITEMAP.CHART_CARDS_BEST_CREDIT_CARDS }
          text={ MENU_TEXT.CREDIT_CARD_CASHBACK_EVENT }
          target='_self'
        />
        <FooterMenuLink
          linkTo={ BANKSALAD_SITEMAP.FREE_GENETIC_TESTING }
          text={ MENU_TEXT.FREE_GENETIC_TESTING }
          target='_blank'
        />
        <FooterMenuLink
          linkTo={ BANKSALAD_SITEMAP.FREE_ORAL_MICROBIOME_TESTING }
          text={ MENU_TEXT.FREE_ORAL_MICROBIOME_TESTING }
          target='_blank'
        />
      </ul>
    </div>
    <div className={ styles.sites }>
      <div className={ styles.menuTitle }>{MENU_TEXT.BANKSALAD}</div>
      <ul>
        <FooterMenuLink
          linkTo={ OUTBOUND_LINK.FOOTER_UTM.CORP_BANKSALAD }
          text={ MENU_TEXT.CORP }
          target='_blank'
        />
        <FooterMenuLink
          linkTo={ OUTBOUND_LINK.FOOTER_UTM.BANKSALAD_BLOG }
          text={ MENU_TEXT.BLOG }
          target='_blank'
        />
        <FooterMenuLink
          linkTo={ OUTBOUND_LINK.BANKSALAD_INSTAGRAM }
          text={ MENU_TEXT.INSTAGRAM }
          target='_blank'
        />
        <FooterMenuLink
          linkTo={ OUTBOUND_LINK.BANKSALAD_YOUTUBE }
          text={ MENU_TEXT.YOUTUBE }
          target='_blank'
        />
        
        <FooterMenuLink
          linkTo={ BANKSALAD_SITEMAP.BANKSALAD_SAFETY_CENTER }
          text={ MENU_TEXT.SAFETY_CENTER }
          target='_blank'
        />
      </ul>
    </div>
  </section>
);
