import { Entity } from 'core/entity';

export class CardPriority extends Entity {
    criteria: CardPriority.Criteria;
    ascending: boolean;

    constructor(
        criteria: CardPriority.Criteria,
        ascending: boolean
    ) {
        super();
        this.criteria = criteria;
        this.ascending = ascending;
    }
}

export module CardPriority {
    export enum Criteria {
        ANNUAL_COST,
        PROMOTION_END_DATE,
        PROMOTION_CREATE_DATE,
        PROMOTION_AMOUNT,
        RATING,
        PROFIT,
        NONE
    }
}
