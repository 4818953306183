import { NetworkResponseMapper } from 'data/http/mapper';
import { LoanRecommendResultSummary } from 'core/entity/loan/recommend-result/summary';
import { LoanSummaryMapper } from 'data/http/mapper/loan/summary';
import { LoanComputationResultSummaryMapper } from 'data/http/mapper/loan/computation-result-summary';

export class LoanRecommendResultSummaryMapper implements NetworkResponseMapper<LoanRecommendResultSummary> {
    fromJson(json: any): LoanRecommendResultSummary {
        return new LoanRecommendResultSummary(
            new LoanSummaryMapper().fromJson(json.loan),
            new LoanComputationResultSummaryMapper().fromJson(json.computation)
        )
    }

}
