import { UseCase } from 'core/use-case';
import { StoreRepositoryType } from 'core/repository/store';
import { StoreGroup } from 'core/entity/store/group';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class GetStoreGroups extends UseCase<List<StoreGroup>> {
    categoryId?: number = null;
    numberOfStores?: number = null;
    private repository: StoreRepositoryType;

    constructor(repository: StoreRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<StoreGroup>> {
        return this.repository.getStoreGroups(this.categoryId, this.numberOfStores);
    }

    protected validate(): boolean {
        return (
            this.categoryId !== null &&
            this.numberOfStores !== null
        );
    }
}
