import { datadogRum, DefaultPrivacyLevel, RumInitConfiguration } from '@datadog/browser-rum';

const isNamespaceStaging = () => process.env.NEXT_PUBLIC_NAMESPACE_ENV === 'staging';
const isServerSide = () => typeof window === 'undefined';

export interface InitDatadogRumProps
  extends Omit<RumInitConfiguration, 'applicationId' | 'clientToken'> {
  // override datadog init option
  service: string;
  applicationId?: string;
  clientToken?: string;

  // custom options
  enableSessionReplayRecording?: boolean;
}

/**
 * @description
 * datadog RUM application을 초기화합니다.
 *
 * applicationId는 기본값으로 process.env.NEXT_PUBLIC_DATADOG_RUM_WEB_APPLICATION_ID 를 사용합니다.
 *
 * clientToken은 기본값으로 process.env.NEXT_PUBLIC_DATADOG_RUM_WEB_CLIENT_TOKEN 를 사용합니다.
 *
 * @see
 * https://docs.datadoghq.com/real_user_monitoring/browser/#npm
 */
export const initDatadogRum = ({
  enableSessionReplayRecording = false,

  applicationId = process.env.NEXT_PUBLIC_DATADOG_RUM_WEB_APPLICATION_ID,
  clientToken = process.env.NEXT_PUBLIC_DATADOG_RUM_WEB_CLIENT_TOKEN,
  env = isNamespaceStaging() ? 'staging' : 'production',
  site = 'datadoghq.com',
  sampleRate = 0.1,
  sessionReplaySampleRate = 0.1,
  trackInteractions = true,
  trackResources = true,
  trackLongTasks = true,
  defaultPrivacyLevel = DefaultPrivacyLevel.MASK,
  allowedTracingOrigins = [
    isNamespaceStaging() ? 'https://api.staging.banksalad.com' : 'https://api.banksalad.com',
    isNamespaceStaging() ? 'https://event.staging.banksalad.com' : 'https://event.banksalad.com',
  ],
  tracingSampleRate = 0.1,
  ...rest
}: InitDatadogRumProps) => {
  if (isServerSide() || typeof applicationId !== 'string' || typeof clientToken !== 'string') {
    return;
  }
  datadogRum.init({
    applicationId,
    clientToken,
    env,
    site,
    sampleRate,
    sessionReplaySampleRate,
    trackInteractions,
    trackResources,
    trackLongTasks,
    defaultPrivacyLevel,
    allowedTracingOrigins,
    tracingSampleRate,
    ...rest,
  });

  if (enableSessionReplayRecording) {
    datadogRum.startSessionReplayRecording();
  }
};
