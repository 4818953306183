import { NetworkResponseMapper } from 'data/http/mapper';
import { CardAnnualCost } from 'core/entity/card/annual-cost';
import { CardBrandMapper } from 'data/http/mapper/card/brand';

export class CardAnnualCostMapper implements NetworkResponseMapper<CardAnnualCost> {
    fromJson(json: any): CardAnnualCost {
        return new CardAnnualCost(
            new CardBrandMapper().fromJson(json.brand_name),
            json.price
        );
    }
}
