import React from 'react';
import { List } from 'immutable';
import { chunk } from 'lodash';

import { EventBus } from 'presentation/bus';
import { WindowResizeEvent } from 'presentation/bus/event/window-resize-event';
import { uniqueKey } from 'utils/index';
import { LandingContentSliderModel, LandingContentSliderType } from 'presentation/components/landing-content-slider/model';
import { RESPONSIVE } from 'presentation/components/responsive';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { SubscriptionBag } from 'presentation/module/extension';

import styles from './styles.pcss';
const SHOWCASE_SIZE = 4;

interface Props {
    themes: List<LandingContentSliderModel>,
    themeType: LandingContentSliderType,
    more?: LandingContentSliderModel
    gaCategory?: string
}

interface State {
    index: number,
    scrollable: boolean,
    mobile: boolean
}

export class LandingContentSlider extends React.Component<Props, State> {
    state = {
        index: 0,
        scrollable: window.innerWidth < RESPONSIVE.DESKTOP,
        mobile: window.innerWidth < RESPONSIVE.MOBILE
    };

    private subscriptionBag = new SubscriptionBag();

    componentDidMount() {
        EventBus.toObservable().subscribe(
            (event: Event) => {
                if (event instanceof WindowResizeEvent) {
                    this.setState({
                        scrollable: event.width < RESPONSIVE.DESKTOP,
                        mobile: event.width < RESPONSIVE.MOBILE
                    });
                }
            }
        ).unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy()
    }

    render() {
        const { themes } = this.props;
        const { index } = this.state;
        const moreSize = this.props.more ? 1 : 0;

        const prevDisabled = index <= 0;
        const nextDisabled = themes.size - SHOWCASE_SIZE + moreSize <= index;

        return themes.size > 0 && (
            <div>
                <div className={ styles.content }>
                    { this.renderThemes() }
                </div>
                {
                    !this.state.scrollable && [
                        <button
                            key={ uniqueKey() }
                            onClick={ () => !prevDisabled && this.move(-1) }
                            className={ !prevDisabled ? styles.prev : `${styles.prev} ${styles.disabled}` }
                        >
                            이전
                        </button>,
                        <button
                            key={ uniqueKey() }
                            onClick={ () => !nextDisabled && this.move(1) }
                            className={ !nextDisabled ? styles.next : `${styles.next} ${styles.disabled}` }
                        >
                            다음
                        </button>
                    ]
                }
            </div>
        )
    }

    private renderThemes() {
        const { themes, gaCategory } = this.props;
        const {
            scrollable,
            index,
            mobile
        } = this.state;
        const transform = !scrollable ?
            `translateX(${-25 * index}%)` :
            'translateX(0%)';
        const toThemeComponent = (themes: Array<LandingContentSliderModel>) => {
            return themes.map(theme =>
                <li key={ uniqueKey() }>
                    <a
                        href={ theme.link }
                        onClick={ () => gaCategory && sendGAEvent(GA_DOMAIN.NONE, gaCategory, this.getGAActionForContent(this.props.themeType), `${theme.title}_${theme.id}`) }
                    >
                        <div
                            style={{ backgroundImage: `url("${theme.imageUrl}")` }}
                            className={ styles.image }
                        />
                        <span className={ styles.text } >
                            { theme.title }
                        </span>
                    </a>
                </li>
            )
        };

        return !mobile ? (
            <ul style={{ transform }} className={ styles.themes }>
                { toThemeComponent(themes.toArray()) }
                { this.props.more && (
                    <li key={ uniqueKey() }>
                        <a
                            href={ this.props.more.link }
                            onClick={ () => gaCategory && sendGAEvent(GA_DOMAIN.NONE, gaCategory, this.getGAActionForMore(this.props.themeType), this.props.more.title) }
                        >
                            <div className={ styles.more }>
                                <span className={ styles.moreTitle }>{ this.props.more.title }</span>
                            </div>
                        </a>
                    </li>
                ) }
            </ul>
        ) : (
            <div>
                {
                    chunk(themes.toArray(), 4).map((group, i) =>
                        <ul
                            key={ uniqueKey() }
                            className={ i === 0 ? styles.themesGrid : styles.themesList }
                        >
                            { toThemeComponent(group) }
                        </ul>
                    )
                }
                { this.props.more && (
                    <a
                        href={ this.props.more.link }
                        onClick={ () => gaCategory && sendGAEvent(GA_DOMAIN.NONE, gaCategory, this.getGAActionForMore(this.props.themeType), this.props.more.title) }
                        className={ styles.moreButton }
                    >
                        { this.props.more.title }
                    </a>
                ) }
            </div>
        )
    }

    private getGAActionForContent = (landingThemeType: LandingContentSliderType) => {
        switch (landingThemeType) {
            case LandingContentSliderType.CARD_THEME:
                return GA_ACTION.LINK.CARDS_THEMES_DETAIL;
            case LandingContentSliderType.CONTENT:
                return GA_ACTION.LINK.CONTENTS_DETAIL;
            default:
                return '';
        }
    };

    private getGAActionForMore = (landingThemeType: LandingContentSliderType) => {
        switch (landingThemeType) {
            case LandingContentSliderType.CARD_THEME:
                return GA_ACTION.LINK.CARDS_THEMES;
            case LandingContentSliderType.CONTENT:
                return GA_ACTION.LINK.CONTENTS;
            default:
                return '';
        }
    };

    private move = (amount: number) => {
        const { themes } = this.props;
        const moreSize = this.props.more ? 1 : 0;

        this.setState((state) => ({
            index: Math.min(
                Math.max(state.index + amount, 0),
                themes.size - SHOWCASE_SIZE + moreSize
            )
        }));
    }
}
