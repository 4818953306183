import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCard } from 'core/entity/personal-finance/card';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { SpiderCardCompanySummaryMapper } from 'data/http/mapper/personal-finance/card-company/summary';
import { PrimaryDataMapper } from 'data/http/mapper/personal-finance/primary-data';
import { optional } from 'utils/index';

export class UserCardMapper implements NetworkResponseMapper<UserCard> {
    fromJson(json: any): UserCard {
        return new UserCard(
            json.id,
            json.number,
            json.hidden,
            new DeletedStatusMapper().fromJson(json.deleted_status),
            json.closed,
            json.dormant,
            new SpiderCardCompanySummaryMapper().fromJson(json.company),
            new PrimaryDataMapper().fromJson(json.primary_data),
            optional(json.nickname),
            optional(json.description)
        )
    }

}
