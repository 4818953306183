import { Service } from 'core/service';
import { Observable } from 'rxjs/Rx';
import { UserSecuritiesFirmAccountRepositoryType } from 'core/repository/personal-finance/securities-firm-account';
import { UserSecuritiesFirmAccount } from 'core/entity/personal-finance/securities-firm-account';
import { UserSecuritiesFirmAccountUpdateSpec } from 'core/entity/personal-finance/securities-firm-account/update-spec';

export class UserSecuritiesFirmAccountService implements Service {
    private repository: UserSecuritiesFirmAccountRepositoryType;

    constructor(repository: UserSecuritiesFirmAccountRepositoryType) {
        this.repository = repository;
    }

    update = (id: string, spec: UserSecuritiesFirmAccountUpdateSpec): Observable<UserSecuritiesFirmAccount> =>
        this.repository.update(id, spec);
}
