import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import { Amount } from 'core/entity/amount';
import { FundCompanySummary } from 'core/entity/personal-finance/fund-company/summary';
import { FundPrimaryData } from 'core/entity/personal-finance/primary-data/fund';

export class UserFund extends Entity {
    id: string;
    hidden: boolean;
    deletedStatus: Asset.DeletedStatus;
    closed: boolean;
    company: FundCompanySummary;
    primaryData: FundPrimaryData;
    nickname?: string;
    description?: string;
    principal: Amount;
    valuation?: Amount;
    quantity?: number;
    createdAt?: Date;
    expiredAt?: Date;

    constructor(
        id: string,
        hidden: boolean,
        deletedStatus: Asset.DeletedStatus,
        closed: boolean,
        company: FundCompanySummary,
        principal: Amount,
        primaryData: FundPrimaryData,
        nickname: string = null,
        description: string = null,
        valuation: Amount = null,
        quantity: number = null,
        createdAt: Date = null,
        expiredAt: Date = null
    ) {
        super();
        this.id = id;
        this.nickname = nickname;
        this.description = description;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
        this.closed = closed;
        this.company = company;
        this.principal = principal;
        this.valuation = valuation;
        this.quantity = quantity;
        this.createdAt = createdAt;
        this.expiredAt = expiredAt;
        this.primaryData = primaryData;
    }
}
