import { Question } from 'presentation/view-model/question';
import { toCurrency } from 'presentation/module/sugar';

export class CardQuestionMonthlySpending implements Question {
    topic = '월평균소비';
    essential = true;
    clearable = false;
    value: number;

    constructor(amount: number = 0) {
        this.value = amount;
    }

    toValue(): string {
        return `${toCurrency(this.value)}원`;
    }

    isValid(): boolean {
        return this.value > 0;
    }

    clear(): CardQuestionMonthlySpending {
        return new CardQuestionMonthlySpending();
    }
}
