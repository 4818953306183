import { ContentSummary } from 'core/entity/content/summary';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSubSectorContentSummary } from 'core/entity/finance-sector/content-summary';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { FinanceSubSectorItem } from 'core/entity/finance-sector/item';
import { FinanceSubSectorContentItem } from 'core/entity/finance-sector/item/content';
import { FinanceSubSectorContentItemSummary } from 'core/entity/finance-sector/item/content/summary';
import { FinanceSubSectorItemSummary } from 'core/entity/finance-sector/item/summary';
import { FinanceSectorSummary } from 'core/entity/finance-sector/summary';
import { List } from 'immutable';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { apply } from 'utils/index';

declare module 'core/entity/finance-sector/summary' {
    export interface FinanceSectorSummary {
        toLink(): string;
        generateLandingRecommends: () => List<FinanceSubSectorContentSummary>;
        generateLandingReviews: () => List<FinanceSubSectorContentSummary>;
        generateLandingMagazines: () => List<FinanceSubSectorContentSummary>;
    }
}

FinanceSectorSummary.prototype.toLink = function() {
  return this.id === FinanceSectorID.ME ?
    `/${FinanceSectorID.ME}` :
    BANKSALAD_SITEMAP_BY_ID.SECTORS(this.id);
};


FinanceSectorSummary.prototype.generateLandingRecommends = function() {
  return this.sub1 ? this.sub1.items
    .filter((it: FinanceSubSectorItemSummary) => it instanceof FinanceSubSectorContentItemSummary)
    .map((it: FinanceSubSectorContentItemSummary) =>
      apply(it.content, content => content.title = it.name),
    ).toList(): List([]);
};

FinanceSectorSummary.prototype.generateLandingReviews = function() {
  return this.sub2 ? this.sub2.items
    .filter((it: FinanceSubSectorItemSummary) => it instanceof FinanceSubSectorContentItemSummary)
    .map((it: FinanceSubSectorContentItemSummary) =>
      apply(it.content, content => content.title = it.name),
    ).toList(): List([]);
};

FinanceSectorSummary.prototype.generateLandingMagazines = function() {
  return this.sub3 ? this.sub3.items
    .filter((it: FinanceSubSectorItemSummary) => it instanceof FinanceSubSectorContentItemSummary)
    .map((it: FinanceSubSectorContentItemSummary) =>
      apply(it.content, content => content.title = it.name),
    ).toList(): List([]);
};

declare module 'core/entity/finance-sector' {
    export interface FinanceSector {
        generateLandingRecommends: () => List<ContentSummary>;
        generateLandingReviews: () => List<ContentSummary>;
        generateLandingMagazines: () => List<ContentSummary>;
    }
}

FinanceSector.prototype.generateLandingRecommends = function() {
  return this.sub1 ? this.sub1.items
    .filter((it: FinanceSubSectorItem) => it instanceof FinanceSubSectorContentItem)
    .map((it: FinanceSubSectorContentItem) =>
      apply(it.content, content => content.title = it.name),
    ).toList(): List([]);
};

FinanceSector.prototype.generateLandingReviews = function() {
  return this.sub2 ? this.sub2.items
    .filter((it: FinanceSubSectorItem) => it instanceof FinanceSubSectorContentItem)
    .map((it: FinanceSubSectorContentItem) =>
      apply(it.content, content => content.title = it.name),
    ).toList(): List([]);
};

FinanceSector.prototype.generateLandingMagazines = function() {
  return this.sub3 ? this.sub3.items
    .filter((it: FinanceSubSectorItem) => it instanceof FinanceSubSectorContentItem)
    .map((it: FinanceSubSectorContentItem) =>
      apply(it.content, content => content.title = it.name),
    ).toList(): List([]);
};
