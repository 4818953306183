import { HttpProvider } from 'data/http';
import { Observable } from 'rxjs/Rx';
import { AccessToken } from 'core/entity/user/access-token';
import { UserCreditScoreJointSuretyStatusMapper } from 'data/http/mapper/personal-credit/joint-sureties-status';
import { UserCreditScoreJointSuretyStatus } from 'core/entity/personal-credit/joint-surety-status';
import { parseDate } from 'utils/index';

export class UserCreditJointSuretiesStatusFromKCBApiProvider extends HttpProvider {
    get(accessToken: AccessToken): Observable<UserCreditScoreJointSuretyStatus> {
        return this.requestGET(
            'api/v2/me/credit-scores/KCB/joint-surety-status',
            null,
            { Authorization: accessToken.accessToken }
        ).map(result =>
            new UserCreditScoreJointSuretyStatusMapper(
                parseDate(result.updated_at),
                result.changed
            ).fromJson(result.data)
        )
    }

}
