import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { LoanRegistrationType } from 'core/entity/loan/registration-type';

export class LoanRegistrationTypeMapper implements BrowserStorageMapper<LoanRegistrationType> {
    toJson(entity: LoanRegistrationType): any {
        switch (entity) {
            case LoanRegistrationType.BRANCH_REGISTRABLE:
                return 'branch_registrable';
            case LoanRegistrationType.INTERNET_BANKING_REGISTRABLE:
                return 'internet_banking_registrable';
            case LoanRegistrationType.CALL_CENTER_REGISTRABLE:
                return 'call_center_registrable';
            case LoanRegistrationType.MOBILE_REGISTRABLE:
                return 'mobile_registrable';
            default:
                return null;
        }
    }

    toEntity(value: any): LoanRegistrationType {
        switch (value) {
            case 'branch_registrable':
                return LoanRegistrationType.BRANCH_REGISTRABLE;
            case 'internet_banking_registrable':
                return LoanRegistrationType.INTERNET_BANKING_REGISTRABLE;
            case 'call_center_registrable':
                return LoanRegistrationType.CALL_CENTER_REGISTRABLE;
            case 'mobile_registrable':
                return LoanRegistrationType.MOBILE_REGISTRABLE;
            default:
                return null;
        }
    }

}
