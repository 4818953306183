import { List } from 'immutable';
import { MobileAuthenticationPhoneConfiguration } from 'core/entity/user/mobile-authenticate-phone-configuration';

export const SIGN_IN_MOBILE_PHONE_COMPANIES = List([
    MobileAuthenticationPhoneConfiguration.Company.SKT,
    MobileAuthenticationPhoneConfiguration.Company.KT,
    MobileAuthenticationPhoneConfiguration.Company.LGU,
    MobileAuthenticationPhoneConfiguration.Company.SUB_SKT,
    MobileAuthenticationPhoneConfiguration.Company.SUB_KT,
    MobileAuthenticationPhoneConfiguration.Company.SUB_LGU,
]);
