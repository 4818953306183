import { NetworkRequestMapper } from 'data/http/mapper';
import { compactObject } from 'utils/index';
import { MobileAuthenticationCodeSpec } from 'core/entity/user/mobile-authenticate-code-spec';
import { MobileAuthenticationPhoneConfigurationMapper } from 'data/http/mapper/user/mobile-authentication-phone-configuration';

export class MobileAuthenticationCodeSpecMapper implements NetworkRequestMapper<MobileAuthenticationCodeSpec> {
    toJson(target: MobileAuthenticationCodeSpec): any {
        return compactObject({
            name: target.name,
            ssn: target.ssn,
            phone: new MobileAuthenticationPhoneConfigurationMapper().toJson(target.phone)
        })
    }
}
