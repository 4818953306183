import { UseCase } from 'core/use-case';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { Observable } from 'rxjs/Rx';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';

export class GetCachedLoanRecommendSpec extends UseCase<LoanRecommendSpec> {
    private repository: LoanRecommendRepositoryType;

    constructor(repository: LoanRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<LoanRecommendSpec> {
        return this.repository.getCachedSpec();
    }

    protected validate(): boolean {
        return true;
    }

}
