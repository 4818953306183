import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';
import { ChargedTransaction } from 'core/entity/personal-finance/charged-transaction';
import { PrimaryData } from 'core/entity/personal-finance/primary-data';
import { List } from 'immutable';

export class UserCardBill extends Entity {
    id: string;
    company: SpiderCardCompanySummary;
    dueDate: Date;
    totalAmount: Amount;
    transactions: List<ChargedTransaction>;
    primaryData?: PrimaryData;

    constructor(
        id: string,
        company: SpiderCardCompanySummary,
        dueDate: Date,
        totalAmount: Amount,
        transactions: List<ChargedTransaction>,
        primaryData: PrimaryData = null
    ) {
        super();
        this.id = id;
        this.company = company;
        this.dueDate = dueDate;
        this.totalAmount = totalAmount;
        this.transactions = transactions;
        this.primaryData = primaryData;
    }
}
