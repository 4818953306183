import { Entity } from 'core/entity';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { FinanceSubSectorSummary } from 'core/entity/finance-sector/sub/summary';

export class FinanceSectorSummary extends Entity {
    id: FinanceSectorID;
    name: string;
    sub1?: FinanceSubSectorSummary;
    sub2?: FinanceSubSectorSummary;
    sub3?: FinanceSubSectorSummary;
    sub4?: FinanceSubSectorSummary;
    externalLinkTarget?: '_self' | '_blank';
    externalLinkUrl?: string;

    constructor(
      id: FinanceSectorID,
      name: string,
      sub1?: FinanceSubSectorSummary,
      sub2?: FinanceSubSectorSummary,
      sub3?: FinanceSubSectorSummary,
      sub4?: FinanceSubSectorSummary,
      externalLinkTarget?: '_self' | '_blank',
      externalLinkUrl?: string,
    ) {
      super();
      this.id = id;
      this.name = name;
      this.sub1 = sub1;
      this.sub2 = sub2;
      this.sub3 = sub3;
      this.sub4 = sub4;
      this.externalLinkTarget = externalLinkTarget;
      this.externalLinkUrl = externalLinkUrl;
    }
}
