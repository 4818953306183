import { NetworkResponseMapper } from 'data/http/mapper';
import { UserActualAsset } from 'core/entity/personal-finance/actual-asset';
import { AmountMapper } from 'data/http/mapper/amount';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';

export class UserActualAssetMapper implements NetworkResponseMapper<UserActualAsset> {
    fromJson(json: any): UserActualAsset {
        return new UserActualAsset(
            json.id,
            json.nickname,
            json.description,
            json.hidden,
            new AmountMapper().fromJson(json.worth),
            new DeletedStatusMapper().fromJson(json.deleted_status)
        )
    }

}
