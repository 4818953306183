import { UseCase } from 'core/use-case';
import { CardRepositoryType } from 'core/repository/card';
import { Card } from 'core/entity/card';
import { Observable } from 'rxjs/Rx';

export class GetCard extends UseCase<Card> {
    id: number;
    private repository: CardRepositoryType;

    constructor(repository: CardRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<Card> {
        return this.repository.get(this.id);
    }

    protected validate(): boolean {
        return !!this.id;
    }
}
