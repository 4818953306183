import { UserDefaultProductConfiguration } from 'core/entity/personal-finance/summarized-products/default-product-configuration';

declare module 'core/entity/personal-finance/summarized-products/default-product-configuration' {
    export interface UserDefaultProductConfiguration {
        isEmpty(): boolean;
    }
}

UserDefaultProductConfiguration.prototype.isEmpty = function() {
    return !this.cashAccount
};
