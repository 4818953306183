import { MappingError, NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedLoanInterestPromotion } from 'core/entity/personalized-recommend/loan/interest-promotion';
import { lets, optional } from 'utils/index';

export class PersonalizedLoanInterestPromotionMapper implements NetworkResponseMapper<PersonalizedLoanInterestPromotion> {
    fromJson(json: any): PersonalizedLoanInterestPromotion {
        return new PersonalizedLoanInterestPromotion(
            json.code,
            json.description,
            json.interest_promotion_wording,
            optional(json.type, it => lets(it, type => {
                switch (type) {
                    case 'interest-discount':
                        return PersonalizedLoanInterestPromotion.Type.INTEREST_DISCOUNT;
                    case 'gift':
                        return PersonalizedLoanInterestPromotion.Type.GIFT;
                    default:
                        throw new MappingError();
                }
            }))
        );
    }
}
