import { Entity } from 'core/entity';
import { AdviceContentData } from 'core/entity/personal-finance/advice/content-data';
import { List } from 'immutable';
import { AdviceMessage } from 'core/entity/personal-finance/advice/message';
import { AmountPeriodSnapshot } from 'core/entity/personal-finance/advice/amount-period-snapshot';

export class AdviceLoanData extends Entity implements AdviceContentData {
    loanHistory: List<AmountPeriodSnapshot>;
    message?: AdviceMessage;

    constructor(
        loanHistory: List<AmountPeriodSnapshot>,
        message: AdviceMessage = null
    ) {
        super();
        this.loanHistory = loanHistory;
        this.message = message;
    }
}
