import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV1ThirdPartiesKcbCreditGoalRoute } from 'presentation/routes/policies/v1/third-parties/kcb/credit-goal';
import { PoliciesV1ThirdPartiesEightPercentRoute } from 'presentation/routes/policies/v1/third-parties/eight-percent/submit-document';

export const PoliciesV1ThirdPartiesRoute = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/kcb/credit-goal`} component={ PoliciesV1ThirdPartiesKcbCreditGoalRoute } />
        <Route path={ `${match.url}/eight-percent/submit-document`} component={ PoliciesV1ThirdPartiesEightPercentRoute } />
    </Switch>
);
