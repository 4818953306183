import { NetworkResponseMapper } from 'data/http/mapper';
import { parseDate } from 'utils/index';
import { HealthInsuranceCertificateResponse } from 'core/entity/personal-finance/health-insurance/certificate/response';

export class HealthInsuranceCertificateResponseMapper implements NetworkResponseMapper<HealthInsuranceCertificateResponse> {
    fromJson(json: any): HealthInsuranceCertificateResponse {
        return new HealthInsuranceCertificateResponse(
            parseDate(json.updated_at)
        );
    }
}
