import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { UserTokenExpiredInfoView } from 'presentation/routes/info/token-expired';
import { UserSuspendedInfoView } from 'presentation/routes/info/suspended';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';

export const InfoRoute = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/token-expired` } component={ UserTokenExpiredInfoView } />
        <Route path={ `${match.url}/suspended` } component={ UserSuspendedInfoView } />
        <Redirect to={ BANKSALAD_SITEMAP.PAGE_NOT_FOUND }/>
    </Switch>
);
