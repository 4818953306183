import { NetworkResponseMapper } from 'data/http/mapper';
import { ServiceCenterArticle } from 'core/entity/service-center/article';
import { parseDate } from 'utils/index';

export class ServiceCenterAnswerMapper implements NetworkResponseMapper<ServiceCenterArticle> {
    fromJson(json: any): ServiceCenterArticle {
        return new ServiceCenterArticle(
            json.article.title,
            json.users[0].name,
            json.article.body,
            parseDate(getProperDate(json.article)),
            json.url,
        )
    }
}

const getProperDate = (article: any) => (
	article.updated_at ? 
		article.updated_at : article.created_at
);
