import React from 'react';

import { uniqueKey } from 'utils/index';
import { sendGAEvent, GA_ACTION, GA_EVENT_VALUE, GA_DOMAIN } from 'presentation/module/analytics/ga';

import styles from './styles.pcss';

interface Props {
    checked: boolean,
    text: string,
    name?: string,
    color?: 'blueberry' | 'romaine',
    onChange: () => void,
    gaCategory?: string
}

export const Radio = (props: Props) => {
    const {
        checked,
        text,
        color,
        name,
        gaCategory,
        onChange,
    } = props;
    const key = uniqueKey();
    let input: HTMLInputElement;

    return (
        <div className={ `${styles.radioWrap} ${styles[color || 'blueberry']}` }>
            <input
                id={ key }
                ref={ node => input = node }
                type="radio"
                name={ name || key }
                checked={ checked }
                onChange={ () => {
                    gaCategory && sendGAEvent(GA_DOMAIN.NONE, gaCategory, GA_ACTION.RADIO, text, input.checked ? GA_EVENT_VALUE.CHECKED : GA_EVENT_VALUE.UNCHECKED);
                    onChange();
                }}
                className={ styles.input }
            />
            <label
                htmlFor={ key }
                className={ styles.label }
            >
                { text }
            </label>
        </div>
    )
};
