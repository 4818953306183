import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceExcessiveSpendingTaxiData } from 'core/entity/personal-finance/advice/content-data/excessive-spending-taxi';
import { AdviceTransactionSnapshotMapper } from 'data/http/mapper/personal-finance/advice/transaction-snapshot';
import { optional, parseDate } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceExcessiveSpendingTaxiDataMapper implements NetworkResponseMapper<AdviceExcessiveSpendingTaxiData> {
    fromJson(json: any): AdviceExcessiveSpendingTaxiData {
        return new AdviceExcessiveSpendingTaxiData(
            json.data.map(new AdviceTransactionSnapshotMapper().fromJson).toList(),
            parseDate(json.base_datetime),
            optional(json.message, new AdviceMessageMapper().fromJson)
        );
    }

}
