import React from 'react'
import { RouteComponentProps } from 'react-router-dom';

import { EventBackgroundImageView } from 'presentation/components/background-image'
import { FetchState } from 'presentation/view-model/fetch-state';
import { Loader } from 'presentation/components/loader'
import { EventFloatingButton } from 'presentation/components/floating-button';
import { generateOneLink } from '../../setUrl';

import styles from './styles.pcss';
const ISSUE_BUTTON_TITLE = '뱅크샐러드 다운받고 이벤트 참여하기';
const EVENT_CLOSING_DATE = new Date('2019-05-09');

const EVENT_TYPE = {
    A : '/app/mini-cancer-insurance/case-a',
    B : '/app/mini-cancer-insurance/case-b'
}

const EVENT_URLS = {
    [EVENT_TYPE.A] : {
        imageUrl: 'https://cdn.banksalad.com/web/appcampaign/mini-cancer-insurance/a.png?timestamp=1555648146935',
        baseUrl : 'https://go.onelink.me/xMM7/e99ae1f'
    },
    [EVENT_TYPE.B] : {
        imageUrl: 'https://cdn.banksalad.com/web/appcampaign/mini-cancer-insurance/b.png?timestamp=1555648146935',
        baseUrl : 'https://go.onelink.me/xMM7/185a73b5'
    },
}

const COMPONENT_TYPE = {
    LOADING : 'Loading',
    CONTENT : 'Content'
}

interface State {
    imageFetchState: FetchState;
}

export class EventCancerMiniInsuranceLanding extends React.Component<RouteComponentProps<any>, State> {
    state = {
        imageFetchState : FetchState.FETCHING
    }

    render () {
        return this.renderView();
    }

    private renderView = () => {
        const { location } = this.props;
        const { pathname } = location;
        const baseUrl = EVENT_URLS[pathname].baseUrl;
        const imgUrl = EVENT_URLS[pathname].imageUrl;
        const parsedLocation = pathname.split('/');
        const eventType = parsedLocation[parsedLocation.length - 1];

        const convertedUrl = generateOneLink(baseUrl,'minicancerinsu','190419', eventType);

        return (
            <>
            <div style={ this.getStyleAfterFetch(COMPONENT_TYPE.LOADING) }>
                { this.renderLoadingView() }
            </div>
            <div style={this.getStyleAfterFetch(COMPONENT_TYPE.CONTENT)} className={ styles.wrap }>
                <div className={ styles.bg }/>
                <div className={ styles.bg2 }/>
                <EventBackgroundImageView
                    imageUrl={ imgUrl }
                    onLoad={ this.onLoadImage }
                >
                    <EventFloatingButton
                        minVisibleThreshold = { 150 }
                        title={ ISSUE_BUTTON_TITLE }
                        issueUrl={ convertedUrl }
                        closingDate={ EVENT_CLOSING_DATE }
                    />
                </EventBackgroundImageView>
            </div>
            </>
        )
    };

    private renderLoadingView = () => (
        <div className={ styles.cover }>
            <div className={ styles.coverContainer }>
                <Loader padding={ 0 } radius={ 20 } />
            </div>
        </div>
    );

    private getStyleAfterFetch = (component: string) => {
        const { imageFetchState } = this.state;

        if (component === COMPONENT_TYPE.LOADING) {
            return imageFetchState === FetchState.FETCHING ? {} : {display: 'none'};
        }

        return imageFetchState === FetchState.FETCHING ? {display: 'none'} : {};
    };

    private onLoadImage = () => {
        this.setState({
            imageFetchState: FetchState.FETCHED
        })
    };
}
