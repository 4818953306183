import React from 'react';

import styles from './styles.pcss';

interface Props {
    children?: any;
    padding?: FetchEmptyPaddingSize;
}

export enum FetchEmptyPaddingSize {
    EXTRA_SMALL = 50,
    SMALL = 100,
    MEDIUM = 150,
    LARGE = 200,
}

export const FetchEmpty = (props: Props) => (
  <div
    style={{ padding: props.padding ? `${props.padding}px 0`: '0' }}
    className={ styles.wrap }
  >
    { props.children }
  </div>
);
