import { TransactionIconSummary } from 'core/entity/personal-finance/transaction/category-icon/summary';
import { NetworkResponseMapper } from 'data/http/mapper';
import { replaceStringPNGtoSVG } from 'utils/index';

export class TransactionIconSummaryMapper implements NetworkResponseMapper<TransactionIconSummary> {
    fromJson(json: any): TransactionIconSummary {
        return new TransactionIconSummary(
            json.id,
            replaceStringPNGtoSVG(json.image_url)
        )
    }

}
