import { Observable } from 'rxjs/Rx';
import { UserBankAccountRepositoryType } from 'core/repository/personal-finance/bank-account';
import { UserBankAccountsApiProvider } from 'data/http/api/gateway/v2/users/bank-accounts';
import { UserBankAccountUpdateSpec } from 'core/entity/personal-finance/bank-account/update-spec';
import { UserBankAccount } from 'core/entity/personal-finance/bank-account';
import { UserBankAccountCreateSpec } from 'core/entity/personal-finance/bank-account/create-spec';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';

export class UserBankAccountRepository implements UserBankAccountRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserBankAccountsApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserBankAccountsApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    create(spec: UserBankAccountCreateSpec): Observable<UserBankAccount> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.create(token, spec)
            )
    }

    update(id: string, spec: UserBankAccountUpdateSpec): Observable<UserBankAccount> {
        return this.tokenStorage.get()
            .flatMap(token =>
                this.api.update(token, id, spec)
            )
    }

}
