import { BrowserStorageMapper, BrowserStorageMappingError } from 'data/browser-storage/mapper';
import { CachedStateType } from 'core/entity/cached-state/type';

const SHOWN_BEST_CARD_RECOMMENDATION_BANNER = 'shown_best_card_recommendation_banner';

export class CachedStateTypeMapper implements BrowserStorageMapper<CachedStateType> {
    toJson(entity: CachedStateType): any {
        switch (entity) {
            case CachedStateType.SHOWN_BEST_CARD_RECOMMENDATION_BANNER:
                return SHOWN_BEST_CARD_RECOMMENDATION_BANNER;
            default:
                throw new BrowserStorageMappingError();
        }
    }

    toEntity(value: any): CachedStateType {
        switch (value) {
            case SHOWN_BEST_CARD_RECOMMENDATION_BANNER:
                return CachedStateType.SHOWN_BEST_CARD_RECOMMENDATION_BANNER;
            default:
                throw new BrowserStorageMappingError();
        }
    }

}
