import { Service } from 'core/service';
import { Observable } from 'rxjs/Observable';
import { PromotionRepositoryType } from 'core/repository/promotion';
import { Promotion } from 'core/entity/promotion';

export class PromotionService implements Service {
    private repository: PromotionRepositoryType;

    constructor(repository: PromotionRepositoryType) {
        this.repository = repository;
    }

    get = (url: string): Observable<Promotion> => this.repository.get(url);
}
