import { Observable } from 'rxjs/Rx';
import { AccessTokenStorageProvider } from 'data/browser-storage/api/access-token';
import { UserCreditScoreLoanStatusRepositoryType } from 'core/repository/personal-credit/loan-status';
import { UserCreditLoansStatusFromKCBApiProvider } from 'data/http/api/gateway/v2/me/credit-scores/kcb/loan-status';
import { UserCreditScoreLoanStatus } from 'core/entity/personal-credit/loan-status';

export class UserCreditScoreLoanStatusRepository implements UserCreditScoreLoanStatusRepositoryType {
    private tokenStorage: AccessTokenStorageProvider;
    private api: UserCreditLoansStatusFromKCBApiProvider;

    constructor(
        tokenStorage: AccessTokenStorageProvider,
        api: UserCreditLoansStatusFromKCBApiProvider
    ) {
        this.tokenStorage = tokenStorage;
        this.api = api;
    }

    get(): Observable<UserCreditScoreLoanStatus> {
        return this.tokenStorage.get().flatMap(it =>
            this.api.get(it)
        );
    }

}
