import { NetworkResponseMapper } from 'data/http/mapper';
import { StoreCategory } from 'core/entity/store/category';

export class StoreCategoryMapper implements NetworkResponseMapper<StoreCategory> {
    fromJson(json: any): StoreCategory {
        return new StoreCategory(
            json.id,
            json.name
        )
    }
}
