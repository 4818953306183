import { NetworkResponseMapper } from 'data/http/mapper';
import { ContentAuthorDescription } from 'core/entity/content/author/description';

export class ContentAuthorDescriptionMapper implements NetworkResponseMapper<ContentAuthorDescription> {
    fromJson(json: any): ContentAuthorDescription {
        return new ContentAuthorDescription(
            json.title,
            json.body
        )
    }
}
