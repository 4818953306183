import { UseCase } from 'core/use-case';
import { Observable } from 'rxjs/Rx';
import { LoanRecommendRepositoryType } from 'core/repository/loan-recommend';
import { LoanRecommendSpec } from 'core/entity/loan/recommend-spec';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanRecommendResultSummary } from 'core/entity/loan/recommend-result/summary';
import { List } from 'immutable';

export class RecommendLoan extends UseCase<List<LoanRecommendResultSummary>> {
    spec: LoanRecommendSpec;
    filter: LoanRecommendFilter;
    private repository: LoanRecommendRepositoryType;

    constructor(repository: LoanRecommendRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<LoanRecommendResultSummary>> {
        return Observable.zip(
            this.repository.cacheSpec(this.spec),
            this.repository.cacheFilter(this.filter)
        ).flatMap(
            () => this.repository.recommend(this.spec, this.filter)
        );
    }

    protected validate(): boolean {
        return !!this.spec && !!this.filter;
    }

}
