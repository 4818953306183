import { Entity } from 'core/entity';
import { CardCFAReview } from 'core/entity/legal-configuration/card/cfa/review';
import { CardCFADescription } from 'core/entity/legal-configuration/card/cfa/description';

export class CardCFAConfiguration extends Entity {
    review: CardCFAReview;
    description: CardCFADescription;

    constructor(
        review: CardCFAReview,
        description: CardCFADescription
    ) {
        super();
        this.review = review;
        this.description = description;
    }
}
