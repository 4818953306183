import { Entity } from 'core/entity';
import { Amount } from 'core/entity/amount';
import { SpiderCardCompanySummary } from 'core/entity/personal-finance/card-company/summary';

export class UserCardCreditLimit extends Entity {
    id: string;
    cardCompany: SpiderCardCompanySummary;
    totalLimit?: UserCardCreditLimit.Condition;
    loanLimit?: UserCardCreditLimit.Condition;

    constructor(
        id: string,
        cardCompany: SpiderCardCompanySummary,
        totalLimit: UserCardCreditLimit.Condition = null,
        loanLimit: UserCardCreditLimit.Condition = null
    ) {
        super();
        this.id = id;
        this.cardCompany = cardCompany;
        this.totalLimit = totalLimit;
        this.loanLimit = loanLimit;
    }
}

export module UserCardCreditLimit {
    export class Condition extends Entity {
        totalAmount: Amount;
        usedAmount: Amount;

        constructor(
            totalAmount: Amount,
            usedAmount: Amount
        ) {
            super();
            this.totalAmount = totalAmount;
            this.usedAmount = usedAmount;
        }
    }
}
