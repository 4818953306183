import { NetworkResponseMapper } from 'data/http/mapper';
import { UserLoan } from 'core/entity/personal-finance/loan';
import { LoanCompanySummaryMapper } from 'data/http/mapper/personal-finance/loan-company/summary';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { AmountMapper } from 'data/http/mapper/amount';
import { LoanPrimaryDataMapper } from 'data/http/mapper/personal-finance/primary-data/loan';
import { parseDate, optional } from 'utils/index';

export class UserLoanMapper implements NetworkResponseMapper<UserLoan> {
    fromJson(json: any): UserLoan {
        return new UserLoan(
            json.id,
            json.number,
            new LoanCompanySummaryMapper().fromJson(json.company),
            json.hidden,
            new DeletedStatusMapper().fromJson(json.deleted_status),
            json.closed,
            json.dormant,
            new AmountMapper().fromJson(json.latest_balance),
            new LoanPrimaryDataMapper().fromJson(json.primary_data),
            optional(json.principal, it => new AmountMapper().fromJson(it)),
            optional(json.interest_rate),
            optional(json.nickname),
            optional(json.description),
            optional(json.created_at, it => parseDate(it)),
            optional(json.expired_at, it => parseDate(it))
        )
    }

}
