import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceInstallmentConfigurationSnapshot } from 'core/entity/personal-finance/advice/installment-configuration-snapshot';

export class AdviceInstallmentConfigurationSnapshotMapper implements
    NetworkResponseMapper<AdviceInstallmentConfigurationSnapshot>
{
    fromJson(json: any): AdviceInstallmentConfigurationSnapshot {
        return new AdviceInstallmentConfigurationSnapshot(
            json.round,
            json.month
        )
    }
}
