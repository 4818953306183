import { Entity } from 'core/entity';
import { LoanOrganizationType } from 'core/entity/loan/organization/type';

export class LoanOrganization extends Entity {
    id: number;
    name: string;
    imageUrl: string;
    url: string;
    type: LoanOrganizationType;

    constructor(
        id: number,
        name: string,
        imageUrl: string,
        url: string,
        type: LoanOrganizationType
    ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.url = url;
        this.type = type;
    }
}
