import { Observable } from 'rxjs/Rx';
import { InsuranceApiProvider } from 'data/http/api/gateway/v2/insurance';
import { InsuranceRepositoryType } from 'core/repository/insurance';
import { Insurance } from 'core/entity/insurance';

export class InsuranceRepository implements InsuranceRepositoryType {
    private api: InsuranceApiProvider;

    constructor(
        api: InsuranceApiProvider
    ) {
        this.api = api;
    }

    get = (
        id: string
    ): Observable<Insurance> => {
        return this.api.get(id);
    };
}
