import { NetworkRequestMapper, NetworkResponseMapper } from 'data/http/mapper';
import { UserInsurance } from 'core/entity/personal-finance/insurance';
import { InsuranceCompanySummaryMapper } from 'data/http/mapper/personal-finance/insurance-company/summary';
import { InsurancePrimaryDataMapper } from 'data/http/mapper/personal-finance/primary-data/insurance';
import { DeletedStatusMapper } from 'data/http/mapper/personal-finance/deleted-status';
import { AmountMapper } from 'data/http/mapper/amount';
import { PaymentCycleMapper } from 'data/http/mapper/personal-finance/insurance/payment-cycle';
import { parseDate, optional, compactObject } from 'utils/index';

export class UserInsuranceMapper implements
    NetworkResponseMapper<UserInsurance>,
    NetworkRequestMapper<UserInsurance>
{
    fromJson(json: any): UserInsurance {
        return new UserInsurance(
            json.id,
            json.name,
            json.number,
            new InsuranceCompanySummaryMapper().fromJson(json.company),
            new InsurancePrimaryDataMapper().fromJson(json.primary_data),
            new DeletedStatusMapper().fromJson(json.deleted_status),
            json.hidden,
            json.dormant,
            json.closed,
            optional(json.nickname),
            optional(json.cancellation_refund, new AmountMapper().fromJson),
            optional(json.payment_amount, new AmountMapper().fromJson),
            optional(json.total_paid_amount, new AmountMapper().fromJson),
            optional(json.payment_cycle, new PaymentCycleMapper().fromJson),
            optional(json.description),
            optional(json.created_at, parseDate),
            optional(json.expired_at, parseDate)
        )
    }

    toJson(target: UserInsurance): any {
        return compactObject({
            id: target.id,
            name: target.name,
            number: target.number,
            company: new InsuranceCompanySummaryMapper().toJson(target.company),
            primary_data: new InsurancePrimaryDataMapper().toJson(target.primaryData),
            deleted_status: new DeletedStatusMapper().toJson(target.deletedStatus),
            hidden: target.hidden,
            dormant: target.dormant,
            closed: target.closed,
            nickname: optional(target.nickname),
            cancellation_refund: optional(target.cancellationRefund, new AmountMapper().toJson),
            payment_amount: optional(target.paymentAmount, new AmountMapper().toJson),
            total_paid_amount: optional(target.totalPaidAmount, new AmountMapper().toJson),
            payment_cycle: optional(target.paymentCycle, new PaymentCycleMapper().toJson),
            description: optional(target.description),
            created_at: optional(target.createdAt),
            expired_at: optional(target.expiredAt)
        })
    }

}
