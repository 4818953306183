import { NetworkResponseMapper } from 'data/http/mapper';
import { UserCreditScoreLoanStatus } from 'core/entity/personal-credit/loan-status';
import { UserCreditScoreLoanMapper } from 'data/http/mapper/personal-credit/loan';

export class UserCreditScoreLoanStatusMapper implements NetworkResponseMapper<UserCreditScoreLoanStatus> {
    private updatedAt: Date;
    private changed: boolean;

    constructor(
        updatedAt: Date,
        changed: boolean
    ) {
        this.updatedAt = updatedAt;
        this.changed = changed;
    }

    fromJson(json: any): UserCreditScoreLoanStatus {
        return new UserCreditScoreLoanStatus(
            json.loans.map(new UserCreditScoreLoanMapper().fromJson).toList(),
            this.updatedAt,
            this.changed
        )
    }

}
