import React from 'react';

import styles from './styles.pcss';

interface Props {
    children: any
}

export const MediaDesktop = (props: Props) => (
    <span className={ styles.desktop }>
        { props.children }
    </span>
);

export const MediaDesktopTablet = (props: Props) => (
    <span className={ styles.desktopTablet }>
        { props.children }
    </span>
);

export const MediaTablet = (props: Props) => (
    <span className={ styles.tablet }>
        { props.children }
    </span>
);

export const MediaMobile = (props: Props) => (
    <span className={ styles.mobile }>
        { props.children }
    </span>
);

export const MediaTabletMobile = (props: Props) => (
    <span className={ styles.tabletMobile }>
        { props.children }
    </span>
);
