import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';

export class UserCashAccountUpdateSpec extends Entity {
    nickname?: string;
    latestBalance?: number;
    description?: string;
    hidden?: boolean;
    deletedStatus?: Asset.DeletedStatus;

    constructor(
        nickname: string = null,
        latestBalance: number = null,
        description: string = null,
        hidden: boolean = null,
        deletedStatus: Asset.DeletedStatus = null
    ) {
        super();
        this.nickname = nickname;
        this.latestBalance = latestBalance;
        this.description = description;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
    }
}
