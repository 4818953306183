import { SummarizedUserProducts } from 'core/entity/personal-finance/summarized-products';
import { SummarizedUserCardCompanyProducts } from 'core/entity/personal-finance/summarized-products/card-company';
import { SummarizedUserBankProducts } from 'core/entity/personal-finance/summarized-products/bank';
import { SummarizedUserSecuritiesFirmProducts } from 'core/entity/personal-finance/summarized-products/securities-firm';

declare module 'core/entity/personal-finance/summarized-products' {
    export interface SummarizedUserProducts {
        isEmpty(): boolean;
    }
}

SummarizedUserProducts.prototype.isEmpty = function() {
    return (
        this.cardCompanies.filter((it: SummarizedUserCardCompanyProducts) => !it.isEmpty()).isEmpty() &&
        this.banks.filter((it: SummarizedUserBankProducts) => !it.isEmpty()).isEmpty() &&
        this.securitiesFirms.filter((it: SummarizedUserSecuritiesFirmProducts) => !it.isEmpty()).isEmpty() &&
        this.userConfiguration.isEmpty() &&
        (!this.defaultConfiguration || this.defaultConfiguration.isEmpty())
    )
};
