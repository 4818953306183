import { UserCardCreditLimit } from 'core/entity/personal-finance/card-credit-limit';
import { NetworkResponseMapper } from 'data/http/mapper';
import { AmountMapper } from 'data/http/mapper/amount';
import { SpiderCardCompanySummaryMapper } from 'data/http/mapper/personal-finance/card-company/summary';
import { optional } from 'utils/index';

export class UserCardCreditLimitMapper implements NetworkResponseMapper<UserCardCreditLimit> {
    fromJson(json: any): UserCardCreditLimit {
        return new UserCardCreditLimit(
            json.id,
            new SpiderCardCompanySummaryMapper().fromJson(json.company),
            optional(json.total_limit, it =>
                this.toConditionEntity(it)
            ),
            optional(json.loan_limit, it =>
                this.toConditionEntity(it)
            )
        )
    }

    private toConditionEntity = (json: any) =>
        new UserCardCreditLimit.Condition(
            new AmountMapper().fromJson(json.total_amount),
            new AmountMapper().fromJson(json.used_amount)
        )

}
