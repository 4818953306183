import { LoanComputationResultSummary } from 'core/entity/loan/computation-result-summary';
import { LoanInterestType } from 'core/entity/loan/interest-type';
import { LoanAverageInterest } from 'core/entity/loan/interest-value/average-interest';
import { LoanConfirmationInterest } from 'core/entity/loan/interest-value/confirmation-interest';
import { LoanRangeInterest } from 'core/entity/loan/interest-value/range-interest';
import { LoanOption } from 'core/entity/loan/option';
import { LoanRecommendFilter } from 'core/entity/loan/recommend-filter';
import { LoanRecommendResultSummary } from 'core/entity/loan/recommend-result/summary';
import { LoanRepaymentType } from 'core/entity/loan/repayment-type';
import { LoanSummary } from 'core/entity/loan/summary';
import { List } from 'immutable';
import { CreditLoanIssueAlert } from 'presentation/components/alert/credit-loan-issue';
import { CreditLoanIssueAlertModel } from 'presentation/components/alert/credit-loan-issue/model';
import { CreditLoanQualificationAlert } from 'presentation/components/alert/credit-loan-qulification';
import { CreditLoansPromotionProfitsBanner } from 'presentation/components/loan-recommendation/card/promotion-banner';
import { ToolTip } from 'presentation/components/tooltip';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, GA_EVENT_VALUE, sendGAEvent } from 'presentation/module/analytics/ga';
import { toCurrency } from 'presentation/module/sugar';
import { CreditLoanProfitsStateModel } from 'presentation/routes/credit-loans/profits/model';
import React from 'react';
import { lets, toDateString, uniqueKey } from 'utils/index';

import styles from './styles.pcss';

interface Props {
    profitsState: CreditLoanProfitsStateModel;
    loanResultSummary: LoanRecommendResultSummary;
}

interface State {
    alertPromotion: boolean;
    alertQualification: boolean;
}

const toolTipMessage =
    '예상한도는 상품의 최대 한도에서 입력된 신용등급과 소득을 기반으로, 뱅크샐러드에서 계산한 예상값 입니다. 정확한 금리와 한도 정보는 [신청하기] 버튼을 통해 해당 금융사 홈페이지에서 확인 가능합니다.';

export class CreditLoansProfitsCard extends React.Component<Props, State> {
    state = {
      alertPromotion: false,
      alertQualification: false
    };

    private uniqueKey = uniqueKey();

    render() {
      const {
        profitsState
      } = this.props;
      const {
        alertPromotion,
        alertQualification
      } = this.state;
      const {
        loan,
        computation
      } = this.props.loanResultSummary;

      const tags: List<string> = List([
        loan.isInPromotion() && '뱅크샐러드 이벤트',
        loan.dayLoan && '당일 대출',
        loan.exemptReimbursementFees && '중도상환수수료 면제'
      ].filter(item => item));

      return (
        <div className={ this.setCardWrapClass(profitsState.sorter) }>
          { this.renderHeader(loan, computation) }
          <div className={ styles.body }>
            { loan.isInPromotion() && <CreditLoansPromotionProfitsBanner loan={ loan } />}
            <div className={ styles.content }>
              <div className={ styles.info }>
                <dl>
                  <dt className={ styles.interestInfo }>예상 연 금리</dt>
                  <dd className={ styles.interestInfo }>
                    { this.renderInterest(loan, computation) }
                  </dd>
                  <dt>대출 기간</dt>
                  { this.renderPeriod(computation) }
                  { this.renderQualifications(loan) }
                  { this.renderTags(profitsState, tags) }
                </dl>
              </div>
              <div className={ styles.linkButtons }>
                <a
                  href={ `/credit-loans/${loan.slug}/profits` }
                  className={ styles.linkDetail }
                  onClick={ () => this.gaLinkDetailEvent(loan, GA_CATEGORY.LOANS_PROFITS.CARD, '상세보기') }
                >
                    상세보기
                </a>
                {
                  loan.isInPromotion() ?
                    <button
                      onClick={ () => {
                        this.onToggleIssueAlert();
                        this.gaIssueEvent(loan, GA_CATEGORY.LOANS_PROFITS.CARD, '신청하기');
                      } }
                      className={ styles.linkIssue }
                    >
                        신청하기
                    </button> :
                    <a
                      ref={ loan.getIssueUrl() }
                      rel="nofollow"
                      target="_blank"
                      className={ styles.linkIssue }
                      onClick={ () => this.gaIssueEvent(loan, GA_CATEGORY.LOANS_PROFITS.CARD, '신청하기') }
                    >
                        신청하기
                    </a>
                }
              </div>
            </div>
          </div>
          {
            alertPromotion &&
            <CreditLoanIssueAlert
              alertModel={
                new CreditLoanIssueAlertModel(
                  loan.desktopIssueUrl,
                  loan.mobileIssueUrl,
                  loan.slug,
                  loan.organization,
                  loan.interestPromotion
                )
              }
              onCancel={ this.onToggleIssueAlert }
            />
          }
          {
            alertQualification &&
            <CreditLoanQualificationAlert
              title={ loan.name }
              qualification={ loan.descriptions.qualifications }
              onCancel={ this.onToggleQualificationAlert }
            />
          }
        </div>
      );
    }

    private setCardWrapClass = (sort: LoanRecommendFilter.Sort) => lets(sort, it => {
      switch (it) {
      case LoanRecommendFilter.Sort.MAXIMUM_LOAN_LIMIT:
        return styles.maximumLoanLimit;
      case LoanRecommendFilter.Sort.MAXIMUM_REPAYMENT_PERIOD:
        return styles.maximumRepaymentPeriod;
      case LoanRecommendFilter.Sort.MINIMUM_INTEREST:
        return styles.minimumInterest;
      default:
        return styles.card;
      }
    });

    private onToggleQualificationAlert = () => {
      sendGAEvent(GA_DOMAIN.LOANS, GA_CATEGORY.LOANS_PROFITS.QUALIFICATION_ALERT, GA_ACTION.POPUP, '조건확인하기');
      this.setState(prevState => ({
        alertQualification: !prevState.alertQualification
      }));
    };

    private onToggleIssueAlert = () => this.setState(prevState => ({
      alertPromotion: !prevState.alertPromotion
    }));

    private renderTags = (profitsState: CreditLoanProfitsStateModel, tags: List<string>) => {
      const hasTag = (tag: string, profitsState: CreditLoanProfitsStateModel) => lets(tag, it => {
        switch (it) {
        case '당일 대출':
          return profitsState.dayLoan;
        case '중도상환수수료 면제':
          return profitsState.exemptReimbursementFees;
        case '뱅크샐러드 이벤트':
          return profitsState.inPromotion;
        default:
          return false;
        }
      });
      const tagText = tags.map((tag, i) =>
        <span key={ `tag-${i}` }>
          { i !== 0 && <span> | </span> }
          <span className={ hasTag(tag, profitsState) ? styles.tagSelected : null }>{ tag }</span>
        </span>
      );

      return (
        !tags.isEmpty() &&
        <>
          <dt>태그</dt>
          <dd>
            <span className={ styles.infoText }>
              { tagText }
            </span>
          </dd>
        </>
      );
    };

    private renderQualifications = (loan: LoanSummary) =>
      !!loan.descriptions.qualifications &&
        <>
          <dt>가입 대상</dt>
          <dd>
            <span 
              className={ styles.infoText } 
              dangerouslySetInnerHTML={{ __html: loan.descriptions.qualifications }}
            />
            <button
              onClick={ this.onToggleQualificationAlert }
              className={ styles.infoButton }
            >
                조건 확인하기
            </button>
          </dd>
        </>;

    private renderInterest = (loan: LoanSummary, computation: LoanComputationResultSummary) => {
      const totalInterest = computation.interestConfigurationSummary.isRangeInterest() ?
        <>
          <strong className={ styles.minInterest }>
            { `${computation.interestConfigurationSummary.getMinInterest().toFixed(2)}%` }
          </strong>
          <span className={ styles.maxInterest }>
            { `~${computation.interestConfigurationSummary.getMaxInterest().toFixed(2)}%`}
          </span>
        </> :
        <strong className={ styles.minInterest }>
          { `${computation.interestConfigurationSummary.getInterest().toFixed(2)}%` }
        </strong>;

      const originalInterest =
        computation.interestConfigurationSummary.getMinOriginInterest() === computation.interestConfigurationSummary.getMaxOriginInterest() ?
          `${computation.interestConfigurationSummary.getMaxOriginInterest().toFixed(2)}%` :
          `${computation.interestConfigurationSummary.getMinOriginInterest().toFixed(2)}~
                ${computation.interestConfigurationSummary.getMaxOriginInterest().toFixed(2)}%`;

      const interestType = lets(computation.interestConfigurationSummary.totalInterest, interest => {
        if (interest instanceof LoanAverageInterest)
          return '평균';
        else if (interest instanceof LoanConfirmationInterest)
          return '확정';
        else if (interest instanceof LoanRangeInterest)
          return '';
      });

      return (
        <ul className={ styles.interest }>
          <li>
            <strong className={ styles.highlightInterest }>{ interestType }</strong>
            <div className={ styles.interestRate }>{ totalInterest }</div>
          </li>
          {
            loan.isAvailablePromotionInterestValue() &&
            <li className={ styles.originalInterest }>
                이벤트 적용 전 <strong>{ originalInterest }</strong>
            </li>
          }
          <li className={ styles.calculatedInterestWrap }>
            { this.renderToolTip() }
            <span className={ styles.calculatedInterest }>
              { `예상한도  ${ toCurrency(computation.maximumAmountLimit / 10000) }만원`}
            </span>
          </li>
        </ul>
      );
    };

    private renderToolTip = () => (
      <ToolTip message={ toolTipMessage }>
        <span className={ styles.toolTip }>
          <span className="blind">예상한도 주의사항</span>
        </span>
      </ToolTip>
    );

    private renderPeriod = (computation: LoanComputationResultSummary) => (
      <dd>
        <ul className={ styles.periodList }>
          {
            computation.options.map((option, i) =>
              <li key={ `${this.uniqueKey}-option-${i}` }>
                <span className={ styles.infoDate }>
                  {
                    option.period.min === option.period.max ?
                      `${option.period.min}개월` :
                      `${option.period.min}~${option.period.max} 개월`
                  }
                  {
                    option.interestRateType === LoanInterestType.FIXED_INTEREST ?
                      ' (고정금리' : ' (변동금리'
                  }
                  {
                    lets(option.repaymentType, it => {
                      switch (it) {
                      case LoanRepaymentType.AT_END:
                        return ', 만기일시 상환)';
                      case LoanRepaymentType.PROGRESSIVE:
                        return ', 원리금 균등상환)';
                      case LoanRepaymentType.INITIAL_VALUE:
                        return ', 원금 균등상환)';
                      }
                    })
                  }
                </span>
              </li>
            )
          }
        </ul>
      </dd>
    );

    private renderHeader = (loan: LoanSummary, computation: LoanComputationResultSummary) => (
      <div className={ styles.header }>
        <img 
          src={ loan.organization.imageUrl } 
          alt={ `${loan.organization.name} 로고` } 
          className={ styles.companyImage } 
        />
        <div className={ styles.headerText }>
          <h1 className={ styles.title }>
            { this.renderInterestType(computation.options) }
            { loan.name }
            { loan.minusAccount && '(마이너스 통장)' }
          </h1>
          <span className={ styles.updateDate }>상품 정보 업데이트: { toDateString(loan.updatedAt) }</span>
        </div>
      </div>
    );

    private renderInterestType = (options: List<LoanOption>) => {
      const filteredOptions: List<LoanOption> = options.reduce((cur: List<LoanOption>, next: LoanOption) => {
        return cur.filter(option => option.interestRateType === next.interestRateType).isEmpty() ?
          cur.concat(next) : cur;
      }, List.of()).toList();

      return (
        filteredOptions.map((option, i) =>
          option.interestRateType === LoanInterestType.FIXED_INTEREST ?
            <span key={ `header-interest-${i}` } className={ styles.fixedInterestType }>
                고정금리
            </span> :
            <span key={ `header-interest-${i}` } className={ styles.variableInterestType }>
                변동금리
            </span>
        )
      );
    };

    private gaLinkDetailEvent = (loan: LoanSummary, category: string, word: string) =>
      sendGAEvent(
        GA_DOMAIN.LOANS,
        category,
        GA_ACTION.LINK.LOANS_DETAIL_PROFITS,
        `${word}_${loan.organization.type.toString()}_${loan.organization.name}_${loan.slug}`
      );

    private gaIssueEvent = (loan: LoanSummary, category: string, word: string) =>
      sendGAEvent(
        GA_DOMAIN.LOANS,
        category,
        loan.isInPromotion() ? GA_ACTION.POPUP_ISSUE : GA_ACTION.ISSUE,
        `${word}_${loan.organization.type.toString()}_${loan.organization.name}_${loan.slug}`,
        loan.isInPromotion() ? GA_EVENT_VALUE.IN_PROMOTION : GA_EVENT_VALUE.NOT_IN_PROMOTION
      )
}
