import { Entity } from 'core/entity';
import { User } from 'core/entity/user';
import { AccessToken } from 'core/entity/user/access-token';

export class AccessibleUser extends Entity {
    user: User;
    token: AccessToken;

    constructor(
        user: User,
        token: AccessToken
    ) {
        super();
        this.user = user;
        this.token = token;
    }
}
