import { NetworkResponseMapper } from 'data/http/mapper';
import { PersonalizedInsuranceRecommendResultSummaryMapper } from 'data/http/mapper/personalized-recommend/insurance/recommend-result/summary';
import { PersonalizedInsuranceRecommendedResult } from 'core/entity/personalized-recommend/insurance/recommend-result';

export class PersonalizedInsuranceRecommendResultMapper implements NetworkResponseMapper<PersonalizedInsuranceRecommendedResult> {
    fromJson(json: any): PersonalizedInsuranceRecommendedResult {
        return new PersonalizedInsuranceRecommendedResult(
            json.data.map((data: any) => new PersonalizedInsuranceRecommendResultSummaryMapper().fromJson(insuranceToSultanMapper(data))).toList(),
            json.meta
        )
    }
}

// insurance 서버에서 전달한 response를 sultan 서버용 response로 매핑
// insurance 명세: https://github.com/banksalad/idl/blob/c4c64aab3faa3312cb15adf7080d81f154325e39/protos/apis/v1/insurance/recommend.proto#L92
// sultan 명세: https://github.com/Rainist/banksalad-api-blueprint/blob/master/apiary.apib#L10828
const insuranceToSultanMapper = (data: any) => ({
    id: data.id,
    company: {
        name: data.company.name,
        image_url: data.company.image_url,
        image_url_v2: data.company.image_url,
        image_url_square: data.company.image_url_square,
        is_price_visible: true,
    },
    apply_url: data.apply_url,
    calculation_criteria: '',
    expected_medical_expenses: 0,
    insurance_period: data.insurance_period,
    issue_review_description: data.issue_review_description,
    kind: data.kind,
    main_claim_volume: '',
    matched_items: data.matched_items.map((item: string) => ({
        checkup_item: item,
    })),
    max_guaranteed_price: data.max_guaranteed_price,
    name: data.name || '',
    payment_period: data.payment_period,
    payment_price: data.payment_price,
    renewal_type: data.renewal_type,
    tags: data.tags.map((tag: string) => ({
        title: tag,
        bg_color: '',
    })),
    total_guaranteed_price: 0,
    promotion: {
        title: data.category || '',
        highlight_color: '',
    },
    type: data.type,
    wording: {
        garanteed_price: '',
    },
});
