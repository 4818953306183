import { NetworkResponseMapper } from 'data/http/mapper';
import { optional } from 'utils/index';
import { MedicalCheckupSummary } from 'core/entity/medical-checkup/summary';
import { MedicalCheckupSummaryResultMapper } from 'data/http/mapper/medical-checkup/summary/result';

export class MedicalCheckupSummaryMapper implements NetworkResponseMapper<MedicalCheckupSummary> {
    fromJson(json: any): MedicalCheckupSummary {
        return new MedicalCheckupSummary(
            json.updated_at,
            optional(json.data, new MedicalCheckupSummaryResultMapper().fromJson)
        )
    }
}
