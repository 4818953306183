import { SpiderStockData } from 'core/entity/personal-finance/spider-data/stock';
import { NetworkResponseMapper } from 'data/http/mapper';
import { optional } from 'utils/index';

export class SpiderStockDataMapper implements NetworkResponseMapper<SpiderStockData> {
    fromJson(json: any): SpiderStockData {
        return new SpiderStockData(
            json.name,
            optional(json.response_contained)
        )
    }

}
