import { Entity } from 'core/entity';

export class Alfred2CardAnnualCostPromotion extends Entity {
    title: string;
    cost: number;
    link: string;
    type: Alfred2CardAnnualCostPromotion.Type;

    constructor(
        title: string,
        cost: number,
        link: string,
        type: Alfred2CardAnnualCostPromotion.Type
    ) {
        super();
        this.title = title;
        this.cost = cost;
        this.link = link;
        this.type = type;
    }
}

export module Alfred2CardAnnualCostPromotion {
    export enum Type {
        DISCOUNT,
        CASHBACK,
        POINT,
        MILEAGE
    }
}
