import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { PoliciesV2PrivacyAgreementRoute } from 'presentation/routes/policies/v2/privacy-agreement';
import { PoliciesV2TermsRoute } from 'presentation/routes/policies/v2/terms';
import { PoliciesV2PrivacyPolicyRoute } from 'presentation/routes/policies/v2/privacy-policy';

export const PoliciesV2Route = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/privacy-policy`} component={ PoliciesV2PrivacyPolicyRoute } />
        <Route path={ `${match.url}/privacy-agreement`} component={ PoliciesV2PrivacyAgreementRoute } />
        <Route path={ `${match.url}/terms`} component={ PoliciesV2TermsRoute } />
    </Switch>
);
