import { Entity } from 'core/entity';

export class TransactionIconSummary extends Entity {
    id: string;
    imageUrl: string;

    constructor(
        id: string,
        imageUrl: string
    ) {
        super();
        this.id = id;
        this.imageUrl = imageUrl;
    }
}
