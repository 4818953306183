import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { FetchState } from 'presentation/view-model/fetch-state';
import { Header } from 'presentation/components/header';
import { FinanceSector } from 'core/entity/finance-sector';
import { Loader } from 'presentation/components/loader';
import { FetchError } from 'presentation/components/fetch-state/error';
import { SectorContentsRecommend } from 'presentation/components/sector-contents/recommend-type';
import { PIXEL, sendPixelEvent } from 'presentation/module/analytics/pixel';
import { CLIX, sendClixEvent } from 'presentation/module/analytics/clix';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN, sendGAEvent } from 'presentation/module/analytics/ga';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { MetaHelmet } from 'presentation/components/meta-helmet';
import { FinanceSectorID } from 'core/entity/finance-sector/id';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';
import { BANKSALAD_SITEMAP } from 'presentation/module/sitemap';

import styles from './styles.pcss';

interface Props extends RouteComponentProps<any> {
    /* explicitly empty */
}

interface State {
    fetchState: FetchState,
    sector?: FinanceSector
}

export class SectorsLoan extends React.Component<Props, State> {
    state = {
        fetchState: FetchState.ERROR,
        sector: null as FinanceSector
    };

    private subscriptionBag = new SubscriptionBag();

    constructor(props: Props) {
        super(props);
        sendPixelEvent(PIXEL.SECTORS_LOAN);
        sendClixEvent(CLIX.SECTORS_LOAN);
    }

    componentDidMount() {
        this.fetch();
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { fetchState, sector } = this.state;

        return (
            <div className={ styles.background }>
                <MetaHelmet meta={ META_SET.SECTORS_LOAN }/>
                <Header active={ FinanceSectorID.LOAN } />
                {
                    sector &&
                    fetchState === FetchState.FETCHED ? (
                        <div>
                            {
                                !sector.generateLandingRecommends().isEmpty() && (
                                    <section className={ styles.section }>
                                        <div className={ styles.container }>
                                            <h3 className={ styles.sectionTitle }>뱅크샐러드 Pick!</h3>
                                            <SectorContentsRecommend
                                                contents={ sector.generateLandingRecommends() }
                                                gaCategory={ GA_CATEGORY.SECTORS.PICK }
                                            />
                                        </div>
                                    </section>
                                )
                            }
                        </div>
                    ) : fetchState === FetchState.FETCHING ? (
                        <Loader padding={ 200 } radius={ 25 } />
                    ) : (
                        <FetchError padding={ 200 }>
                            일시적인 오류가 발생했습니다<br />
                            잠시 후 재시도 해주세요
                        </FetchError>
                    )
                }
            </div>
        )
    }

    private fetch = () => {
        this.setState({
            fetchState: FetchState.FETCHING
        }, () =>
            Application.useCases.getLoanSector
                .runOnAnimateFrame()
                .subscribe(
                    sector =>
                        this.setState({
                            fetchState: FetchState.FETCHED,
                            sector
                        }),
                    () =>
                        this.setState({
                            fetchState: FetchState.ERROR
                        })
                )
        );
    }

    private clearCache = () => {
        Application.useCases.clearLoanRecommendCaches
            .runOnAnimateFrame()
            .subscribe(() => {
                /* explicitly empty */
            })
            .unsubscribeBy(this.subscriptionBag)
    };

    private onHeroButtonClicked = () => {
        sendGAEvent(
            GA_DOMAIN.LOANS,
            GA_CATEGORY.SECTORS.HERO,
            GA_ACTION.LINK.LOANS_QUESTIONS,
            '나의BEST대출찾기'
        );
        this.clearCache();
    };
}
