import { NetworkResponseMapper } from 'data/http/mapper';
import { PromotionUrl } from 'core/entity/promotion/promotion-url';

export class PromotionUrlMapper implements NetworkResponseMapper<PromotionUrl> {
    fromJson(json: any): PromotionUrl {
        return new PromotionUrl(
            json.background_image,
            json.target_link
        )
    }
}
