import { Entity } from 'core/entity';
import { Asset } from 'core/entity/personal-finance/asset';
import DeletedStatus = Asset.DeletedStatus;

export class UserCardUpdateSpec extends Entity {
    nickname?: string;
    description?: string;
    hidden?: boolean;
    deletedStatus?: DeletedStatus;

    constructor(
        nickname: string = null,
        description: string = null,
        hidden: boolean = null,
        deletedStatus: DeletedStatus = null
    ) {
        super();
        this.nickname = nickname;
        this.description = description;
        this.hidden = hidden;
        this.deletedStatus = deletedStatus;
    }
}
