import { TransactionCategorySummary } from 'core/entity/personal-finance/transaction/category/summary';
import { NetworkResponseMapper } from 'data/http/mapper';
import { TransactionCategoryParentSummaryMapper } from 'data/http/mapper/personal-finance/transaction/category-parent/summary';
import { TransactionIconSummaryMapper } from 'data/http/mapper/personal-finance/transaction/icon/summary';
import { TransactionTypeMapper } from 'data/http/mapper/personal-finance/transaction/type';
import { optional } from 'utils/index';

export class TransactionCategorySummaryMapper implements NetworkResponseMapper<TransactionCategorySummary> {
    fromJson(json: any): TransactionCategorySummary {
        return new TransactionCategorySummary(
            json.id,
            json.name,
            new TransactionTypeMapper().fromJson(json.transaction_type),
            json.priority,
            json.default,
            optional(json.icon, it => new TransactionIconSummaryMapper().fromJson(it)),
            optional(json.parent, it => new TransactionCategoryParentSummaryMapper().fromJson(it))
        )
    }

}
