const blockCopy = (e: any) => {
    const cancel = (e: any) => {
        if (window.event) {
            window.event.cancelBubble = true;
            window.event.returnValue = false;
        }
        if (e && e.stopPropagation && e.preventDefault) {
            e.stopPropagation();
            e.preventDefault();
        }
        return false;
    };

    e = e || window.event;
    const t = e.srcElement || e.target;
    const tag = t.tagName;

    if (
        e &&
        tag==='HTML' ||
        tag==='INPUT' ||
        tag==='TEXTAREA' ||
        tag==='BUTTON' ||
        tag==='SELECT' ||
        tag==='OPTION' ||
        tag==='EMBED' ||
        tag==='OBJECT'
    ) {
        return;
    }

    if (e.type === 'keydown' || e.type === 'keyup') {
        if (
            (e.ctrlKey || e.metaKey) &&
            (
                e.keyCode == 65 ||
                e.keyCode == 97 ||
                e.keyCode == 67 ||
                e.keyCode == 99
            )
        ) {
            return cancel(e);
        }
    } else if(e.type == "contextmenu"){
        alert('마우스 우클릭이 불가합니다. 콘텐츠의 무단 사용을 방지하기 위함이니, 공유하기 기능을 이용해주세요 :)');
        return cancel(e);
    } else {
        return cancel(e);
    }
};

const addEvent = (target: any, event: string, method: (e: any) => void) => {
    if (window.addEventListener) {
        target.addEventListener(event, method, false);
    } else if ((window as any).attachEvent) {
        target.attachEvent(`on${event}`, method);
    } else {
        target[`on${event}`] = method;
    }
};

const removeEvent = (target: any, event: string, method: (e: any) => void) => {
    if (window.removeEventListener) {
        target.removeEventListener(event, method, false);
    } else if ((window as any).detachEvent) {
        target.detachEvent(`on${event}`, method);
    } else {
        target[`on${event}`] = null;
    }
};

export const allowCopy = () => {
    removeEvent(document.body, 'keydown', blockCopy);
    removeEvent(document.body, 'keyup', blockCopy);
    removeEvent(document.body, 'mouseup', blockCopy);
    removeEvent(document.body, 'mousedown', blockCopy);
    removeEvent(document.body, 'dragstart', blockCopy);
    removeEvent(document.body, 'selectstart', blockCopy);
    removeEvent(document.body, 'copy', blockCopy);
    removeEvent(document.body, 'contextmenu', blockCopy);
};

export const preventCopy = () => {
    addEvent(document.body, 'keydown', blockCopy);
    addEvent(document.body,'keyup', blockCopy);
    addEvent(document.body,'mouseup', blockCopy);
    addEvent(document.body,'mousedown', blockCopy);
    addEvent(document.body,'dragstart', blockCopy);
    addEvent(document.body,'selectstart', blockCopy);
    addEvent(document.body,'copy', blockCopy);
    addEvent(document.body,'contextmenu', blockCopy);
};
