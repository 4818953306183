import { BrowserStorageMapper } from 'data/browser-storage/mapper';
import { lets } from 'utils/index';
import { CardBrand } from 'core/entity/card/brand';

export class CardBrandMapper implements BrowserStorageMapper<CardBrand> {
    toEntity(json: any): CardBrand {
        return lets(json, it => {
            switch (it) {
                case 'local':
                    return new CardBrand(
                        CardBrand.Key.LOCAL,
                        '국내전용'
                    );
                case 'visa':
                    return new CardBrand(
                        CardBrand.Key.VISA,
                        'VISA'
                    );
                case 'master':
                    return new CardBrand(
                        CardBrand.Key.MASTER,
                        'MASTER'
                    );
                case 'maestro':
                    return new CardBrand(
                        CardBrand.Key.MAESTRO,
                        'MAESTRO'
                    );
                case 'amex':
                    return new CardBrand(
                        CardBrand.Key.AMEX,
                        'AMEX'
                    );
                case 'union-pay':
                    return new CardBrand(
                        CardBrand.Key.UNION_PAY,
                        'UNION PAY'
                    );
                case 'bc-global':
                    return new CardBrand(
                        CardBrand.Key.BC_GLOBAL,
                        'BC Global'
                    );
                case 'bc-local':
                    return new CardBrand(
                        CardBrand.Key.BC_LOCAL,
                        'BC'
                    );
                case 'urs':
                    return new CardBrand(
                        CardBrand.Key.URS,
                        'URS'
                    );
                case 'jcb':
                    return new CardBrand(
                        CardBrand.Key.JCB,
                        'JCB'
                    );
                case 'w-jcb':
                    return new CardBrand(
                        CardBrand.Key.W_JCB,
                        'W(JCB)'
                    );
                case 'k-world':
                    return new CardBrand(
                        CardBrand.Key.K_WORLD,
                        'K-World'
                    );
                case 'cheum':
                    return new CardBrand(
                        CardBrand.Key.CHAEUM,
                        '채움'
                    );
                case 's-and':
                    return new CardBrand(
                        CardBrand.Key.S_AND,
                        'S&'
                    );
                case 'diners':
                    return new CardBrand(
                        CardBrand.Key.DINERS,
                        'DINERS'
                    );
                case 'cirrus':
                    return new CardBrand(
                        CardBrand.Key.CIRRUS,
                        'CIRRUS'
                    );
                case 'l-global':
                    return new CardBrand(
                        CardBrand.Key.L_GLOBAL,
                        'L.GLOBAL'
                    );
                default:
                    return new CardBrand(
                        CardBrand.Key.LOCAL,
                        '국내전용'
                    );
            }
        })
    }

    toJson(target: CardBrand): any {
        return lets(target, it => {
            switch (it.id) {
                case CardBrand.Key.LOCAL:
                    return 'local';
                case CardBrand.Key.VISA:
                    return 'visa';
                case CardBrand.Key.MASTER:
                    return 'master';
                case CardBrand.Key.MAESTRO:
                    return 'maestro';
                case CardBrand.Key.AMEX:
                    return 'amex';
                case CardBrand.Key.UNION_PAY:
                    return 'union-pay';
                case CardBrand.Key.BC_LOCAL:
                    return 'bc-local';
                case CardBrand.Key.BC_GLOBAL:
                    return 'bc-global';
                case CardBrand.Key.URS:
                    return 'urs';
                case CardBrand.Key.JCB:
                    return 'jcb';
                case CardBrand.Key.W_JCB:
                    return 'w-jcb';
                case CardBrand.Key.K_WORLD:
                    return 'k-world';
                case CardBrand.Key.CHAEUM:
                    return 'cheum';
                case CardBrand.Key.S_AND:
                    return 's-and';
                case CardBrand.Key.DINERS:
                    return 'diners';
                case CardBrand.Key.CIRRUS:
                    return 'cirrus';
                case CardBrand.Key.L_GLOBAL:
                    return 'l-global';
                default:
                    return 'local';
            }
        })
    }

}
