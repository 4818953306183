import React from 'react';
import Rx from 'rxjs/Rx';
import { List } from 'immutable';

import { Alert } from 'presentation/components/alert';
import { emptyList, uniqueKey } from 'utils/index';
import { FetchState } from 'presentation/view-model/fetch-state';
import { Store } from 'core/entity/store';
import { apply, lets } from 'utils/index';
import { Loader } from 'presentation/components/loader';
import { CardQuestionSpending } from 'presentation/components/survey-form/card-spendings/model';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { InputCurrency } from 'presentation/components/input/currency';
import { SubscriptionBag } from 'presentation/module/extension';
import { Application } from 'application/context.instance';

import styles from './styles.pcss';
const AMOUNT_PRESET = [
    { text: '1만원', value: 10000 },
    { text: '2만원', value: 20000 },
    { text: '3만원', value: 30000 },
    { text: '5만원', value: 50000 },
    { text: '10만원', value: 100000 },
    { text: '15만원', value: 150000 },
    { text: '20만원', value: 200000 },
    { text: '30만원', value: 300000 },
    { text: '50만원', value: 500000 }
];

enum Page {
    SEARCH, SPENDING
}

interface Props {
    onAccept?: (spending: CardQuestionSpending) => void,
    onCancel?: () => void
}

interface State {
    page: Page,
    selectedStore?: Store,
    spendingAmount: number,
    stores: List<Store>,
    fetchState: FetchState
}

export class CardStoreAlert extends React.Component<Props, State> {
    state = {
        page: Page.SEARCH,
        selectedStore: null as Store,
        spendingAmount: 0,
        stores: emptyList(),
        fetchState: FetchState.FETCHED
    };

    private subscriptionBag = new SubscriptionBag();
    private inputStream = new Rx.Subject<string>();

    componentDidMount() {
        this.inputStream
            .debounceTime(300)
            .subscribeOn(Rx.Scheduler.async)
            .observeOn(Rx.Scheduler.animationFrame)
            .subscribe(
                text => this.fetch(text)
            )
            .unsubscribeBy(this.subscriptionBag);
    }

    componentWillUnmount() {
        this.subscriptionBag.destroy();
    }

    render() {
        const { page } = this.state;
        const forSearch = page === Page.SEARCH;

        return (
            <Alert
                title={ forSearch ? '업종/가맹점을 검색하세요' : '월 소비액을 입력하세요'  }
                acceptTitle={ forSearch ? '다음' : '확인' }
                cancelTitle={ forSearch ? '취소' : '이전' }
                onAccept={ () => this.onAccept() }
                onCancel={ () => this.onCancel() }
            >
                {
                    forSearch ?
                        this.renderSearch() :
                        this.renderSpending()
                }
            </Alert>
        )
    }

    private renderSearch() {
        const {
            fetchState,
            stores,
            selectedStore
        } = this.state;

        const renderState = () => {
            switch (fetchState) {
                case FetchState.FETCHING:
                    return <Loader padding={ 100 } radius={ 25 } />;
                case FetchState.ERROR:
                    return (
                        <div className={ styles.error }>
                            에러가 발생했습니다.<br/>
                            잠시 후 다시 시도해주세요.
                        </div>
                    );
                case FetchState.FETCHED:
                    return stores.isEmpty() ? (
                        <div className={ styles.empty }>
                            업종 또는 가맹점을 빠르게 찾고<br/>
                            소비패턴을 입력하세요!
                        </div>
                    ) : (
                        <ul className={ styles.results }>
                            {
                                stores.map(store => lets(uniqueKey(), key =>
                                    <li key={ key }>
                                        <input
                                            id={ key }
                                            type="radio"
                                            name="card-stores"
                                            defaultChecked={ store.equals(selectedStore) }
                                            onChange={ e => this.setState({ selectedStore: store }) }
                                        />
                                        <label htmlFor={ key }>{ store.name }</label>
                                    </li>
                                ))
                            }
                        </ul>
                    )
            }
        };

        return (
            <div>
                <div className={ styles.search }>
                    <input
                        type="text"
                        placeholder="검색어를 입력하세요"
                        onChange={ e => this.inputStream.next(e.target.value) }
                        onBlur={ e => sendGAEvent(
                            GA_DOMAIN.CARD,
                            GA_CATEGORY.CARDS_QUESTIONS.STORE_ALERT_SEARCH,
                            GA_ACTION.INPUT,
                            e.currentTarget.value
                        ) }
                    />
                </div>
                { renderState() }
            </div>
        );
    }

    private renderSpending() {
        const { selectedStore, spendingAmount } = this.state;
        const gaCategory = GA_CATEGORY.CARDS_QUESTIONS.STORE_ALERT_SPENDING(selectedStore.name);

        return selectedStore && (
            <div className={ styles.spending }>
                <span className={ styles.spendingStore }>{ selectedStore.name }</span>
                <div className={ styles.spendingInput }>
                    <InputCurrency
                        value={ spendingAmount }
                        color="#04C584"
                        fontSize={ 18 }
                        textAlign="right"
                        onChange={ result => this.setState({ spendingAmount: result }) }
                        onBlur={ e => sendGAEvent(
                            GA_DOMAIN.CARD,
                            gaCategory,
                            GA_ACTION.INPUT,
                            e.currentTarget.value
                        ) }
                    />
                </div>
                <ul className={ styles.spendingPresets }>
                    {
                        AMOUNT_PRESET.map(spending =>
                            <li key={ uniqueKey() }>
                                <button
                                    onClick={ () => {
                                        this.setState({ spendingAmount: spending.value });
                                        sendGAEvent(
                                            GA_DOMAIN.CARD,
                                            gaCategory,
                                            GA_ACTION.INPUT_SHORTCUT,
                                            spending.value.toString()
                                        );
                                    } }
                                >
                                    { spending.text }
                                </button>
                            </li>
                        )
                    }
                </ul>
            </div>
        )
    }

    private fetch(text: string) {
        if (text.length < 1) {
            this.setState({
                stores: emptyList()
            });
            return;
        }

        this.setState({
            fetchState: FetchState.FETCHING
        });

        apply(Application.useCases.searchStores, it => {
            it.query = text;
        }).runOnAnimateFrame().subscribe(
            stores =>
                this.setState({
                    stores,
                    fetchState: FetchState.FETCHED
                }),
            error =>
                this.setState({
                    fetchState: FetchState.ERROR
                })
        ).unsubscribeBy(this.subscriptionBag);
    }

    private onAccept() {
        const { onAccept } = this.props;
        const { page, selectedStore, spendingAmount } = this.state;

        switch (page) {
            case Page.SEARCH:
                sendGAEvent(
                    GA_DOMAIN.CARD,
                    GA_CATEGORY.CARDS_QUESTIONS.STORE_ALERT_BUTTONS,
                    GA_ACTION.NEXT,
                    '다음'
                );
                selectedStore ?
                    this.setState({ page: Page.SPENDING }) :
                    alert('찾으시는 업종/가맹점을 검색 후 선택해주세요');
                break;
            case Page.SPENDING:
                sendGAEvent(
                    GA_DOMAIN.CARD,
                    GA_CATEGORY.CARDS_QUESTIONS.STORE_ALERT_BUTTONS,
                    GA_ACTION.NEXT,
                    '확인'
                );
                selectedStore && onAccept && onAccept(
                    new CardQuestionSpending(
                        selectedStore.id,
                        selectedStore.name,
                        spendingAmount
                    )
                );
                break;
        }
    }

    private onCancel() {
        const { onCancel } = this.props;
        const { page } = this.state;

        switch (page) {
            case Page.SEARCH:
                sendGAEvent(
                    GA_DOMAIN.CARD,
                    GA_CATEGORY.CARDS_QUESTIONS.STORE_ALERT_BUTTONS,
                    GA_ACTION.PREV,
                    '취소'
                );
                onCancel && onCancel();
                break;
            case Page.SPENDING:
                sendGAEvent(
                    GA_DOMAIN.CARD,
                    GA_CATEGORY.CARDS_QUESTIONS.STORE_ALERT_BUTTONS,
                    GA_ACTION.PREV,
                    '이전'
                );
                this.setState({ page: Page.SEARCH });
                break;
        }
    }
}
