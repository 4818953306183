import { NetworkResponseMapper } from 'data/http/mapper';
import { CreditTip } from 'core/entity/personal-credit/tips';

export class CreditTipMapper implements NetworkResponseMapper<CreditTip> {
    fromJson(json: any): CreditTip {
        return new CreditTip(
            json.id,
            json.title,
            json.url,
            json.image_url
        );
    }

}
