import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceExcessiveSpendingAlertData } from 'core/entity/personal-finance/advice/content-data/excessive-spending-alert';
import { AdviceTransactionPeriodSnapshotMapper } from 'data/http/mapper/personal-finance/advice/transaction-period-snapshot';
import { optional, parseDate } from 'utils/index';
import { AdviceMessageMapper } from 'data/http/mapper/personal-finance/advice/message';

export class AdviceExcessiveSpendingAlertDataMapper implements
    NetworkResponseMapper<AdviceExcessiveSpendingAlertData>
{
    fromJson(json: any): AdviceExcessiveSpendingAlertData {
        return new AdviceExcessiveSpendingAlertData(
            json.data.map(new AdviceTransactionPeriodSnapshotMapper().fromJson).toList(),
            parseDate(json.base_datetime),
            optional(json.message, new AdviceMessageMapper().fromJson)
        );
    }

}
