import { Entity } from 'core/entity';

export class FinanceSubSectorContentSummary extends Entity {
    id: string;
    title: string;
    thumbnailImageUrl: string;
    type: FinanceSubSectorContentSummary.Type;

    constructor(
        id: string,
        title: string,
        thumbnailImageUrl: string,
        type: FinanceSubSectorContentSummary.Type
    ) {
        super();
        this.id = id;
        this.title = title;
        this.thumbnailImageUrl = thumbnailImageUrl;
        this.type = type;
    }
}

export module FinanceSubSectorContentSummary {
    export enum Type {
        COLUMN,
        CARD_NEWS,
        CARD_COMPARISON
    }
}
