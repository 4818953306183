import { Entity } from 'core/entity';
import { PersonalizedFinanceLoan } from 'core/entity/personalized-finance/loan';

export class PersonalizedFinanceKCBLoan extends Entity implements PersonalizedFinanceLoan {
    constructor(
        public id: number,
        public organizationName: string,
        public updatedAt: Date,
        public createdAt: Date,
        public expiredAt: Date,
        public principal: number,
        public balance: number,
        public interest: number = null,
        public organizationImageUrl: string = null,
        public organizationType: string = null,
        public penaltyExpiredAt: Date = null
    ) {
        super();
    }
}
