import React from 'react';

import styles from '../../../styles.pcss';

export const PoliciesV1PrivacyAgreementsCreditGoalView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 개인정보 수집ㆍ이용 동의</h3>
      <p>신용점수 올리기 서비스 제공을 위하여 아래와 같이 개인정보 수집 및 이용 동의를 받고자 합니다.</p>
      <br/>
      <ol>
        <li>1. 개인정보 수집 및 이용 목적 : 신용점수 올리기 서비스 제공</li>
        <li>
                2. 수집하는 개인정보 항목
          <ol>
            <li>
                    1) 건강보험 납부 내역서
              <p>- 필수 정보 : 이름, 생년월일, 납부자번호, 건강보험료, 장기요양보험료</p>
            </li>
            <li>
                    2) 국민연금 납부 내역서
              <p>- 필수 정보 : 이름, 발급번호, 발급일자, 검증번호, 생년월일, 총보험료 납부월수, 총보험료 납부금액, 총보험료 미납월수, 총보험료 미납금액, 총납부 불가월수, 총납부 불가금액, 반납금액, 추가납부금액, 보험료 납부기간, 기준 소득금액, 보험료 납부월수, 보험료 납부금액, 보험료 미납월수, 보험료 미납금액, 사업장명</p>
            </li>
            <li>
                    3) 소득금액 증명서
              <p>- 필수 정보 : 이름, 발급번호, 발급일자, 생년월일, 귀속연도, 법인명, 기준소득금액, 총결정세액</p>
            </li>
          </ol>
        </li>
        <li>3. 개인정보 보유 및 이용 기간 : 회원 탈퇴 시</li>
      </ol>
      <br/>
      <p>개인정보 수집 및 이용 동의를 거부할 권리가 있으며, 동의 거부 시 신용점수 올리기 서비스를 이용하실 수 없습니다.</p>
    </div>
  </div>
);

