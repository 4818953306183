import { CardMileageType } from "presentation/view-model/card/classification";

enum WebViewPresentType {
    PRESENT = 'present',
    PUSH = 'push'
}

enum WebViewExitButtonIconType {
    NONE = 'none',
    BACK = 'back',
    EXIT = 'exit'
}

enum AuthType {
    CREDIT_SCORE = 'credit-score',
    LOAN_NEGOTIATIONS = 'loan-negotiations',
    ASSETS = 'assets',
    NONE = 'none'
}

export enum AssetType {
    BANKS = 'banks'
}

type queryValueType =
    string | number | AssetType;

const queryString = (
    key: string,
    value?: queryValueType
) => value === null ? '' : `${key}=${value}`;

const deepLink = {
    WEB_VIEW: (
        title: string,
        url: string,
        presentType: WebViewPresentType,
        needCustomTab: boolean = false,
        tokenRequired: boolean = true,
        topBarVisible: boolean = true,
        exitButtonIcon: WebViewExitButtonIconType = WebViewExitButtonIconType.BACK
    ) => `banksalad://webview?title=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}&token-required=${tokenRequired}&ios-present-config=${presentType}&aos-need-custom-tab=${needCustomTab}&top-bar-visible=${topBarVisible}&exit-button-icon=${exitButtonIcon}`,

    AUTH: (type: AuthType, presentType: WebViewPresentType) =>
        type === AuthType.NONE ?
            `banksalad://me/auth?ios-present-config=${presentType.toString()}` :
            `banksalad://me/auth?callback=${type.toString()}&ios-present-config=${presentType.toString()}`
};

export const BANKSALAD_DEEP_LINK = {
    CARD: (id: string) => `banksalad://me/cards/${id}`,
    INSURANCE: (
        id: string,
        rank: number,
        isRecommendProduct: boolean,
    ) => deepLink.WEB_VIEW(
        '보험상세',
        `${location.protocol}//${location.host}/insurances/${id}?rank=${rank}&isRecommendProduct=${isRecommendProduct}`,
        WebViewPresentType.PUSH,
        false,
        false
    ),
    CONTENT: (title: string, url: string) => deepLink.WEB_VIEW(title, url, WebViewPresentType.PUSH, true),
    WEB_VIEW: (title: string, url: string) => deepLink.WEB_VIEW(title, url, WebViewPresentType.PUSH),
    WEB_VIEW_FULL: (title: string, url: string) => deepLink.WEB_VIEW(title, url, WebViewPresentType.PRESENT, false, false, false, WebViewExitButtonIconType.EXIT),
    ISSUE_LOAN: (url: string) => deepLink.WEB_VIEW('대출 협상', url, WebViewPresentType.PUSH, true),
    ISSUE_CARD: (url: string) => deepLink.WEB_VIEW('신청하기', url, WebViewPresentType.PRESENT, true),
    ISSUE_INSURANCE: (url: string) => deepLink.WEB_VIEW('신청하기', url, WebViewPresentType.PUSH, true),
    RECOMMENDED_CARDS: (
        userCardId: string = null
    ) => `banksalad://me/recommended-cards?${queryString('user-card-id', userCardId)}`,
    RECOMMENDED_CARDS_DETAIL: (
        userCardId: string,
        recommendCardId: string,
        startDate: Date,
        endDate: Date,
        userCardType: CardMileageType
    ) => `banksalad://me/recommended-cards/${recommendCardId}?user-card-id=${userCardId}&start-date=${startDate.format('YYYY-MM-DD')}&end-date=${endDate.format('YYYY-MM-DD')}&type=${userCardType.toString()}`,
    SUBMIT_DOCUMENT_INFO: (
        loanId: string,
        defaultApplyLink: string,
        thirdPartyAgreementUrl: string,
        onlySimplifySubmit: boolean,
        userGrade: number = null
    ) => deepLink.WEB_VIEW(
        '간편 서류제출 서비스',
        `${location.protocol}//${location.host}/me/credit-loans/submit-document?id=${loanId}&link=${encodeURIComponent(defaultApplyLink)}&third-party-agreement-url=${encodeURIComponent(thirdPartyAgreementUrl)}&only-simplify-submit=${onlySimplifySubmit}&${queryString('user-grade', userGrade)}`,
        WebViewPresentType.PRESENT
    ),
    SUBMIT_DOCUMENT: (
        loanId: string,
        defaultApplyLink: string,
        thirdPartyAgreementUrl: string,
        onlySimplifySubmit: boolean
    ) => (
        `banksalad://me/loan-negotiations/submit-document?loan-id=${loanId}&default-link=${encodeURIComponent(defaultApplyLink)}&third-party-agreement-url=${encodeURIComponent(thirdPartyAgreementUrl)}&only-simplify-submit=${onlySimplifySubmit}`
    ),

    BUDGET_SETTINGS: 'banksalad://me/budget/configuration',
    SYNC_CONFIGURATION: (assetType: AssetType = null) =>
        `banksalad://me/products/sync-configuration?${queryString('asset-type', assetType)}`,

    CREDIT_AGREEMENT_FROM_CREDIT_MANAGEMENT: deepLink.WEB_VIEW('이용약관 및 개인정보 동의', `${location.protocol}//${location.host}/me/credit/agreement/for-credit-score`, WebViewPresentType.PRESENT),
    CREDIT_AGREEMENT_FROM_LOAN_NEGOTIATIONS: deepLink.WEB_VIEW('이용약관 및 개인정보 동의', `${location.protocol}//${location.host}/me/credit/agreement/for-loan-negotiations`, WebViewPresentType.PRESENT),
    CREDIT_AGREEMENT_FROM_ASSETS: deepLink.WEB_VIEW('이용약관 및 개인정보 동의', `${location.protocol}//${location.host}/me/credit/agreement/for-assets`, WebViewPresentType.PRESENT),
    CREDIT_STATUS: deepLink.WEB_VIEW('내 신용평가 정보', `${location.protocol}//${location.host}/me/credit/status`, WebViewPresentType.PUSH),
    CREDIT_LOAN: 'banksalad://me/loan-negotiations',
    CREDIT_SCORE: 'banksalad://me/credit-score',
    CREDIT_GOAL: deepLink.WEB_VIEW('등급별 금융생활', `${location.protocol}//${location.host}/me/credit/goal`, WebViewPresentType.PUSH),
    CREDIT_SCORE_RAISE:'banksalad://me/credit-score/raise-credit-score',

    LOAD_MEDICAL_CHECKUP: 'banksalad://me/insurance-recommendation/submit-medical-checkup',
    MEDICAL_CHECKUPS: (
        id: number
    ) => deepLink.WEB_VIEW(
        '내 건강검진 결과',
        `${location.protocol}//${location.host}/me/medical-checkups/${id}`,
        WebViewPresentType.PRESENT
    ),

    REFRESH_CREDIT_SCORE: deepLink.AUTH(AuthType.CREDIT_SCORE, WebViewPresentType.PRESENT),
    REFRESH_LOAN_NEGOTIATIONS: deepLink.AUTH(AuthType.LOAN_NEGOTIATIONS, WebViewPresentType.PRESENT),
    AGREE_WITH_CREDIT_MANAGEMENT: deepLink.AUTH(AuthType.CREDIT_SCORE, WebViewPresentType.PUSH),
    AGREE_WITH_LOAN_NEGOTIATIONS: deepLink.AUTH(AuthType.LOAN_NEGOTIATIONS, WebViewPresentType.PUSH),
    AGREE_WITH_ASSETS: deepLink.AUTH(AuthType.ASSETS, WebViewPresentType.PUSH),
    AGREE_DEFAULT: deepLink.AUTH(AuthType.NONE, WebViewPresentType.PUSH),

    SHARE: (content: string) => `banksalad://share?content=${encodeURIComponent(content)}`,
    CM_CONTACT: 'banksalad://help',
};
