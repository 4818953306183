import { CardRecommendRepositoryType } from 'core/repository/card-recommend';
import { CardRecommendSpec } from 'core/entity/card/recommend-spec';
import { CardNonTemporalComputationResult } from 'core/entity/card/non-temporal-computation-result';
import { Page } from 'core/entity/page';
import { Observable } from 'rxjs/Rx';
import { List } from 'immutable';
import { CardsApiProvider } from 'data/http/api/gateway/v3/cards';
import { CardRecommendSpecStorageProvider } from 'data/browser-storage/api/card';

export class CardRecommendRepository implements CardRecommendRepositoryType {
    private api: CardsApiProvider;
    private storage: CardRecommendSpecStorageProvider;

    constructor(api: CardsApiProvider, storage: CardRecommendSpecStorageProvider) {
        this.api = api;
        this.storage = storage;
    }

    recommend(spec: CardRecommendSpec, page: Page): Observable<List<CardNonTemporalComputationResult>> {
        return this.api.recommend(spec, page);
    }

    compute(id: number, spec: CardRecommendSpec): Observable<CardNonTemporalComputationResult> {
        return this.api.compute(id, spec);
    }

    getCachedSpec(): Observable<CardRecommendSpec> {
        return this.storage.get();
    }

    cacheSpec(spec: CardRecommendSpec): Observable<void> {
        return this.storage.set(spec, true);
    }

    clearCachedSpec(): Observable<void> {
        return this.storage.clear();
    }
}
