import { Entity } from 'core/entity';
import { CardSelectiveGroup } from 'core/entity/card/selective-group';

export class CardShinhanCombo extends Entity {
    combo1: CardSelectiveGroup;
    combo2: CardSelectiveGroup;

    constructor(
        combo1: CardSelectiveGroup,
        combo2: CardSelectiveGroup
    ) {
        super();
        this.combo1 = combo1;
        this.combo2 = combo2;
    }
}
