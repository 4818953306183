export class LandingContentSliderModel {
    id: number;
    title: string;
    imageUrl: string;
    link: string;

    constructor(
        id: number,
        title: string,
        imageUrl: string,
        link: string
    ) {
        this.id = id;
        this.title = title;
        this.imageUrl = imageUrl;
        this.link = link;
    }
}

export enum LandingContentSliderType {
    CARD_THEME,
    CONTENT
}
