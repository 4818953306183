import { UserTransaction } from 'core/entity/personal-finance/transaction';
import { TransactionFilter } from 'core/entity/personal-finance/transaction/filter';
import { UserTransactionRepositoryType } from 'core/repository/personal-finance/transaction';
import { UseCase } from 'core/use-case';
import { List } from 'immutable';
import { Observable } from 'rxjs/Rx';

export class GetUserTransactions extends UseCase<List<UserTransaction>> {
    filter: TransactionFilter;
    private repository: UserTransactionRepositoryType;

    constructor(repository: UserTransactionRepositoryType) {
        super();
        this.repository = repository;
    }

    protected build(): Observable<List<UserTransaction>> {
        return this.repository.getAll(this.filter)
    }

    protected validate(): boolean {
        return !!this.filter;
    }
}
