import React from 'react';

import { MetaHelmet } from 'presentation/components/meta-helmet';
import { META_SET } from 'presentation/view-model/meta-set/preset';
import { PoliciesV13PrivacyPolicyView } from 'presentation/routes/policies/v13/privacy-policy/view';

export const PoliciesV13PrivacyPolicyRoute = () => (
    <>
        <MetaHelmet meta={ META_SET.PRIVACY_POLICY }/>
        <PoliciesV13PrivacyPolicyView />
    </>
);
