import { NetworkResponseMapper } from 'data/http/mapper';
import { SpiderCardCompanySummaryMapper } from 'data/http/mapper/personal-finance/card-company/summary';
import { List } from 'immutable';
import { UserCardMapper } from 'data/http/mapper/personal-finance/card';
import { SummarizedUserCardCompanyProducts } from 'core/entity/personal-finance/summarized-products/card-company';

export class SummarizedUserCardCompanyProductsMapper implements NetworkResponseMapper<SummarizedUserCardCompanyProducts> {
    fromJson(json: any): SummarizedUserCardCompanyProducts {
        return new SummarizedUserCardCompanyProducts(
            new SpiderCardCompanySummaryMapper().fromJson(json.company),
            List(json.cards.map(new UserCardMapper().fromJson))
        )
    }
}
