import { Entity } from 'core/entity';

export class TransactionProduct extends Entity {
    id: string;
    type: TransactionProduct.Type;
    name: string;

    constructor(
        id: string,
        type: TransactionProduct.Type,
        name: string
    ) {
        super();
        this.id = id;
        this.type = type;
        this.name = name;
    }
}

export module TransactionProduct {
    export enum Type {
        CARD = 'card',
        BANK_ACCOUNT = 'bank_account',
        CASH_ACCOUNT = 'cash_account',
        LOAN = 'loan'
    }
}
