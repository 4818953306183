import { Entity } from 'core/entity';
import { InstallmentIncludedTransaction } from 'core/entity/personal-finance/transaction/installment/transaction';
import { List } from 'immutable';

export class InstallmentConfiguration extends Entity {
    round: number;
    month: number;
    transactions: List<InstallmentIncludedTransaction>;

    constructor(
        round: number,
        month: number,
        transactions: List<InstallmentIncludedTransaction>
    ) {
        super();
        this.round = round;
        this.month = month;
        this.transactions = transactions;
    }
}
