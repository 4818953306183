import { NetworkResponseMapper } from 'data/http/mapper';
import { AdviceRecommendedExpenseAmount } from 'core/entity/personal-finance/advice/recommended-expense-amount';
import { AmountMapper } from 'data/http/mapper/amount';

export class AdviceRecommendedExpenseAmountMapper implements NetworkResponseMapper<AdviceRecommendedExpenseAmount> {
    fromJson(json: any): AdviceRecommendedExpenseAmount {
        return new AdviceRecommendedExpenseAmount(
            new AmountMapper().fromJson(json.amount),
            json.ratio
        )
    }

}
