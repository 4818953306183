import { List } from 'immutable';
import { FinanceSector } from 'core/entity/finance-sector';
import { FinanceSubSector } from 'core/entity/finance-sector/sub';
import { FinanceSubSectorContentItem } from 'core/entity/finance-sector/item/content';
import { ContentColumnSummary } from 'core/entity/content/column/summary';
import { EMERGENCY_AUTHORS } from 'presentation/module/emergency-contents';
import { ContentCardComparisonSummary } from 'core/entity/content/card-comparison/summary';
import { FinanceSectorID } from 'core/entity/finance-sector/id';

export const EMERGENCY_INVESTMENT_SECTOR = new FinanceSector(
    FinanceSectorID.INVESTMENT,
    '투자',
    new FinanceSubSector(
        '상품 추천',
        List([
            new FinanceSubSectorContentItem(
                '2017 BEST CMA',
                new ContentColumnSummary(
                    '307',
                    '2017 BEST CMA',
                    'CMA는 재테크에 관심을 갖기 시작한 분들이라면 한번은 들어 보신 금융상품입니다. CMA란 ‘증권사 및 종합금융회사에서 제공하는 수시입출금 통장’을 뜻합니다. 매일 이자가 쌓인다는 장점 때문에 많은 분들이 관심을 가지고 있는 상품이죠.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fmoney-2159310_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '투자 성향별 BEST 로보-어드바이저',
                new ContentColumnSummary(
                    '304',
                    '투자 성향별 BEST 로보-어드바이저',
                    '로보어드바이저는 사람이 아닌 컴퓨터 알고리즘이 개인의 성향과 상황에 따라 맞춤 투자 포트폴리오를 제공하는 서비스입니다. 대면 서비스보다 수수료 및 최소 투자금액이 대폭 줄어들어 고액자산가에게만 열려 있던 자산관리서비스를 대중화에 큰 기여를 했다는 평가를 받고 있습니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcode-2588957_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                'P2P 투자 서비스 비교',
                new ContentColumnSummary(
                    '272',
                    'P2P 투자 서비스 비교',
                    '생소했던 P2P 투자가 일반 투자자들에게도 확대되고 있습니다. 저금리 시대, 마땅한 투자처가 없는 상황에서 8%~15%에 달하는 매력적인 수익률 때문입니다. 다만 P2P금융협회에 정식으로 등록된 회원사만 47개 회사가 있을 정도로 다양한 P2P 투자 플랫폼이 생겨나, P2P 투자를 시작하시려는 분들은 혼란을 겪으실 수 있습니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fdigital-marketing-1725340_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '2017 BEST 증권계좌',
                new ContentColumnSummary(
                    '267',
                    '2017 BEST 증권계좌',
                    '주식 투자를 시작하고 싶지만 증권사 계좌를 어떻게 선택해야 할지 헤매고 있는 분, 의외로 매우 많습니다. 잡음에 현혹되지 않고 좋은 증권사를 선택하는 방법은 결국 여러분이 어떤 투자를 원하는지를 생각하는 것입니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fchart-1905225_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '주식매매를 위한 BEST HTS/MTS',
                new ContentColumnSummary(
                    '319',
                    '주식매매를 위한 BEST HTS/MTS',
                    '주식거래를 직접 하는 개인 투자자에게 홈트레이딩 시스템(HTS), 모바일 트레이딩 시스템(MTS)은 굉장히 중요합니다. 어떤 프로그램을 사용하는가에 따라 정보의 질과 양이 달라집니다. 당연히 수익에도 영향을 미치죠. 그렇다면 어떤 HTS와 MTS가 좋을까요? 뱅크샐러드는 이코노마드와 함께 각 증권사별 HTS와 MTS를 살펴봤습니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fdices-over-newspaper-2656028_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '증권거래에 좋은 CMA',
                new ContentColumnSummary(
                    '217',
                    '증권거래에 좋은 CMA',
                    'CMA 계좌를 개설하기 전 주식투자를 고려한다면, 4가지 선택 포인트가 있습니다. 이코노마드와 뱅크샐러드는 주식매매가 가능한 CMA 중 각 선택포인트에 따라 상위 2개씩의 증권사를 선정했습니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fstock.JPG',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '증권사 CMA 체크카드',
                new ContentCardComparisonSummary(
                    '15',
                    '증권사 CMA 체크카드',
                    '<b>많은</b> 분들이 직장 생활을 하다 보면, 여유자금을 어떻게 불릴까 고민 많이들 하시죠. 은행 예금으로 넣으면 안정적이긴 하나 금리가 높지도 않고 수시로 입출금 하기에 불편하기도 하고, 그렇다고 다른 투자 상품에 가입하자니 원금 손실의 우려가 높고... 그래서 처음 자금 운용을 시작하시는 분들은 흔히 <b>CMA</b>를 먼저 시작하곤 합니다. CMA는 입출금이 자유롭고, 급여이체나 인터넷뱅킹 등 은행 업무가 가능할뿐더러 금리도 은행 이자보다 높고 하루만 맡겨도 이자가 지급되는 여러 장점들이 있습니다. 또한 이 CMA 계좌를 연동하여 신용/체크카드를 활용할 수도 있는데요. CMA 계좌에서 수익도 보고, 카드를 활용해서 혜택도 잘 받으면 그 자체로 훌륭한 재테크 수단이 되겠죠. 여러분이 진정한 재테크 고수로 거듭나는 것을 도와드리기 위해 뱅크샐러드가 CMA 계좌 연동의 신용/체크카드를 엄선하였습니다. 증권사의 CMA 카드 추천! 시작합니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/favorite%2Fupload%2Fimages%2F15_CMA%EC%B6%94%EC%B2%9C.jpg',
                    new Date(),
                    true,
                    ''
                )
            )
        ])
    ),
    new FinanceSubSector(
        '상품 리뷰',
        List()
    ),
    new FinanceSubSector(
        '상품 매거진',
        List([
            new FinanceSubSectorContentItem(
                'CMA란 무엇인가?',
                new ContentColumnSummary(
                    '50',
                    'CMA란 무엇인가?',
                    '재테크에 쉽게 접근하지 못하고, 마음을 먹었다가도 금방 포기하게 되는 이유는 뭘까? 가장 큰 이유는 어려워서가 아닐까 싶다. 어려운 전문용어로 무장한 금융상품을 하나하나 벗겨보고, 쉽게 실천할 수 있는 재테크를 안내하고자 한다. 오늘은 그 첫 번째 시간으로 재테크의 기본, CMA통장에 대해서 알아보자.',
                    EMERGENCY_AUTHORS.KIM_HYUN_WOO,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcontent-upload-images-money-1428584_1920_mini_800x533.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '최고의 CMA를 고르는 법',
                new ContentColumnSummary(
                    '218',
                    '최고의 CMA를 고르는 법',
                    '재테크에 관심을 갖기 시작하신 분들이라면, 아마 CMA라는 이름을 한번쯤 들어보셨을 것입니다.  CMA는 간단히 말하면 \'증권사/종합금융회사에서 제공하는 수시입출금 통장\'이라고 이해하시면 됩니다. CMA에 가입을 해야겠다고 마음먹었다면, ‘CMA통장은 어떻게 선택해야 할까?’ 에 대한 고민이 따라오기 마련.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2FCash-Coins-Banking-Investment-Currency-Insurance-1523383.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '투자의 시작, 증권계좌 이해하기',
                new ContentColumnSummary(
                    '230',
                    '투자의 시작, 증권계좌 이해하기',
                    '\'계좌\'라는 말을 듣고 일반적으로 가장 먼저 떠올리게 되는 것은 \'은행 예금\' 입니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2F5264113065_539603d361.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '증권계좌 선택 시 고려할 포인트',
                new ContentColumnSummary(
                    '232',
                    '증권계좌 선택 시 고려할 포인트',
                    '증권계좌를 막상 개설하려 하면, 어느 곳을 선택해야 할지 고민입니다. 최근에는 인터넷에서만 거래하는 인터넷 전업 증권에서 대기업 증권까지 많은 증권사가 있기 때문에',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fpexels-photo-480237.jpeg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '주식매매 수수료 아끼는 6가지 방법',
                new ContentColumnSummary(
                    '205',
                    '주식매매 수수료 아끼는 6가지 방법',
                    '주식투자 초보자인 A씨는 최근 은행 금리가 너무 낮다고 생각하여 여유자금으로 주식투자를 시작했습니다. 처음에는 매매수수료가 아무 것도 아닌 것처럼 느껴졌지만, 투자금액과 매매횟수가 늘어나면서 수수료가 부담이 되기 시작했습니다.',
                    EMERGENCY_AUTHORS.BANKSALAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2FForex_-_14600958045.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                'P2P투자 완벽 개념정리',
                new ContentColumnSummary(
                    '286',
                    'P2P투자 완벽 개념정리',
                    '저금리 기조가 끝을 모르고 계속되고 있습니다. 은행 적금에 돈을 넣어도 물가상승률을 고려하면 실질 수익이 마이너스에 가깝죠. 이러한 상황에서 높은 수익을 기대할 수 있는 새로운 투자 상품이 금융 시장의 문을 두드리고 있습니다. 바로P2P투자상품인데요, P2P투자의 개념, 장점, 종류, 투자방법, 그리고 유의사항까지 모두 알려드리겠습니다!',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fperson-731479_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                'P2P 투자시 고려할 포인트',
                new ContentColumnSummary(
                    '284',
                    'P2P 투자시 고려할 포인트',
                    '저금리 시대에 수많은 금융상품들 사이에서 P2P금융 투자상품이 고수익을 제공하는 신흥강자로 떠오르고 있는데요. 현재 P2P 대출 플랫폼에서 제시하는 수익률은 대략 5%에서 15% 내외로, 고수익을 원하는 투자자들의 눈을 번쩍 뜨이게 하는 이율입니다. 하지만 안전한 은행 예금과는 다르게, 원금과 수익을 보장하는 것은 아니어서 위험도 따릅니다. 이코노마드와 뱅크샐러드가 수익률과 안정성 모두를 얻기 위해 P2P 투자 선택 시 고려해야 할 포인트들을 소개합니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fchoose-the-right-direction-1536336_1920.jpg',
                    new Date()
                )
            ),
            new FinanceSubSectorContentItem(
                '로보어드바이저 투자 TIP',
                new ContentColumnSummary(
                    '304',
                    '로보어드바이저 투자 TIP',
                    '로보어드바이저는 사람이 아닌 컴퓨터 알고리즘이 개인의 성향과 상황에 따라 맞춤 투자 포트폴리오를 제공하는 서비스입니다. 대면 서비스보다 수수료 및 최소 투자금액이 대폭 줄어들어 고액자산가에게만 열려 있던 자산관리서비스를 대중화에 큰 기여를 했다는 평가를 받고 있습니다.',
                    EMERGENCY_AUTHORS.ECONOMAD,
                    'https://cdn.banksalad.com/content%2Fupload%2Fimages%2Fcode-2588957_1920.jpg',
                    new Date()
                )
            )
        ])
    )
);
