import Axios from 'axios';
import { ContentAuthor } from 'core/entity/content/author';
import { ContentAuthorSummary } from 'core/entity/content/author/summary';
import { ContentAuthorMapper } from 'data/http/mapper/content/author';
import { Loader } from 'presentation/components/loader';
import { GA_ACTION, GA_CATEGORY, GA_DOMAIN } from 'presentation/module/analytics/ga';
import { sendGAEvent } from 'presentation/module/analytics/ga';
import { BANKSALAD_SITEMAP_BY_ID } from 'presentation/module/sitemap';
import { FetchState } from 'presentation/view-model/fetch-state';
import React, { useEffect, useState } from 'react';
import { isNamespaceProduction } from 'utils/env';

import styles from './styles.pcss';

interface Props {
  summary: ContentAuthorSummary;
}

interface State {
  selected: boolean;
  author: ContentAuthor;
  fetchState: FetchState;
}

export const  AuthorCard = ({ summary }: Props) => {
  const [state, setState] = useState({
    selected: false,
    author: null as ContentAuthor,
    fetchState: FetchState.FETCHING
  });
  const { selected, author, fetchState } = state;
  const onClick = () => setState({ ...state, selected: !selected });
  const renderAuthorDetail = () => (
    fetchState === FetchState.FETCHED ?
      <div className={ styles.more }>
        <strong className={ styles.title }>{ author.profile.description.title }</strong>
        <p className={ styles.desc }>
          { author.profile.description.body }
        </p>
        <div className={ styles.linkWrap }>
          <a
            href={ BANKSALAD_SITEMAP_BY_ID.AUTHOR(author.id) }
            onClick={ () => sendGAEvent(
              GA_DOMAIN.NONE, 
              GA_CATEGORY.CONTENTS_AUTHORS.CARD, 
              GA_ACTION.LINK.CONTENTS_AUTHORS_DETAIL, `이저자의글보기_${author.profile.name}`
            ) }
            className={ styles.moreLink }
          >
            이 저자의 글 보기
          </a>
        </div>
      </div> :
      <Loader padding={ 100 } radius={ 25 }/>
  );

  useEffect(() => {
    (async () => {
      const API_PATH = isNamespaceProduction() 
        ? `https://cdn.banksalad.com/app/banksalad-web/legacy-apis/authors/${summary.id}.json` 
        : `https://cdn.staging.banksalad.com/app/banksalad-web/legacy-apis/authors/${summary.id}.json`;
  
      const { data } = await Axios.get(API_PATH);
  
      setState({
        selected,
        author: new ContentAuthorMapper().fromJson(data.data),
        fetchState: FetchState.FETCHED,
      });
    })();
  }, []);

  return (
    <li className={ selected ? styles.checkedItem : styles.item }>
      <button
        onClick={ onClick }
        className={ styles.button }
      >
        <div className={ styles.header }>
          <img
            src={ summary.profile.imageUrl }
            alt=""
            className={ styles.image }
          />
          <div className={ styles.info }>
            <h3 className={ styles.name }>{ summary.profile.name }</h3>
            <h4 className={ styles.summary }>{ summary.profile.summary }</h4>
          </div>
        </div>
        { selected && renderAuthorDetail() }
      </button>
    </li>
  );
};
