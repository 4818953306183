import { List } from 'immutable';
import { Entity } from 'core/entity';

export class Store extends Entity {
    id: number;
    name: string;
    imageUrl: string;
    validSpendings: List<number>;

    constructor(
        id: number,
        name: string,
        imageUrl: string,
        validSpendings: List<number>
    ) {
        super();
        this.id = id;
        this.name = name;
        this.imageUrl = imageUrl;
        this.validSpendings = validSpendings;
    }
}
