import { Entity } from 'core/entity';
import { SpiderData } from 'core/entity/personal-finance/spider-data';

export class PrimaryData extends Entity {
    spider?: SpiderData;

    constructor(spider: SpiderData = null) {
        super();
        this.spider = spider;
    }
}
