import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';

import { EventFineDustInsuranceA } from 'presentation/routes/app/event/fine-dust-insurance/case-a';
import { EventFineDustInsuranceB } from 'presentation/routes/app/event/fine-dust-insurance/case-b';

export const FineDustRoute = ({ match }: RouteComponentProps<any>) => (
    <Switch>
        <Route path={ `${match.url}/case-a` } component={ EventFineDustInsuranceA } />
        <Route path={ `${match.url}/case-b` } component={ EventFineDustInsuranceB } />
    </Switch>
);

