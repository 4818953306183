import { NetworkResponseMapper } from 'data/http/mapper';
import { PromotionDate } from 'core/entity/promotion/promotion-date';

export class PromotionDateMapper implements NetworkResponseMapper<PromotionDate> {
    fromJson(json: any): PromotionDate {
        return new PromotionDate(
            new Date(json.start),
            new Date(json.end)
        )
    }
}
