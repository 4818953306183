import React from 'react';

import styles from '../../styles.pcss';

export const PoliciesV1TermsView = () => (
  <div className={ styles.content }>
    <div className={ styles.container }>
      <h3>뱅크샐러드 이용약관</h3>
      <b>-목차-</b>
      <ol>
        <li>
          <b>제1장 총칙</b>
          <ol>
            <li>제1조 (목적)</li>
            <li>제2조 (용어의 정의)</li>
            <li>제3조 (약관의 명시와 개정)</li>
            <li>제4조 (약관의 해석)</li>
          </ol>
        </li>
        <li>
          <b>제2장 서비스 이용계약</b>
          <ol>
            <li>제5조 (이용계약의 성립)</li>
            <li>제6조 (이용자 정보의 제공)</li>
            <li>제7조 (회원의 계정(ID) 및 비밀번호)</li>
            <li>제8조 (개인정보의 보호 및 관리)</li>
            <li>제9조 (이용자에 대한 통지)</li>
          </ol>
        </li>
        <li>
          <b>제3장 서비스 이용</b>
          <ol>
            <li>제10조 (서비스의 내용 및 변경)</li>
            <li>제11조 (서비스의 제공시간 및 중지)</li>
            <li>제12조 (정보의 제공 및 광고의 게재)</li>
          </ol>
        </li>
        <li>
          <b>제3장 서비스 이용</b>
          <ol>
            <li>제10조 (서비스의 내용 및 변경)</li>
            <li>제11조 (서비스의 제공시간 및 중지)</li>
            <li>제12조 (정보의 제공 및 광고의 게재)</li>
          </ol>
        </li>
        <li>
          <b>제4장 계약당사자의 의무</b>
          <ol>
            <li>제13조 (회사의 의무)</li>
            <li>제14조 (회원의 의무)</li>
            <li>제15조 (권리의 귀속)</li>
          </ol>
        </li>
        <li>
          <b>제5장 계약 해지 및 이용 제한</b>
          <ol>
            <li>제16조 (회원의 계약해제•해지 등)</li>
            <li>제17조 (이용제한 등)</li>
          </ol>
        </li>
        <li>
          <b>제 6장 손해배상 등</b>
          <ol>
            <li>제18조 (손해배상)</li>
            <li>제19조 (책임의 한계)</li>
            <li>제20조 (준거법 및 재판관할)</li>
            <li>제21조 (회사의 연락처)</li>
            <li>제22조 (부칙)</li>
          </ol>
        </li>
      </ol>
      <ol>
        <li>
          <b>제1장 총칙</b>
          <ol>
            <li>
              <b>제1조 (목적)</b>
              <p>본 약관은 주식회사 레이니스트 (이하 "회사"라 함)가 제공하는 뱅크샐러드 서비스(이하 "서비스")의 이용과 관련하여, 회사와
                        이용자와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
            </li>
            <li>
              <b>제2조 (용어의 정의)</b>
              <p>① 이 약관에서 사용하는 용어의 정의는 아래와 같습니다.</p>
              <p>- "서비스"라 함은 휴대전화를 통해 신용/체크카드 사용 또는 기타 지출 관련 정보를 분석하여 소비내역 관리 및 분석, 금융상품
                        추천 등의 정보를 제공하는 모바일 어플리케이션을 의미합니다.</p>
              <p>- "회원" 또는 "이용자" 라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를
                        이용하는 고객을 말합니다.</p>
              <p>- "회원ID" 또는 "이용자"D”라 함은 회원의 식별과 회원의 서비스 이용을 위하여 이용자가 입력 혹은 등록한 이메일 주소나
                        문자와 숫자의 조합으로 이루어진 고유한 정보를 말합니다.</p>
              <p>- "비밀번"”라 함은 회사의 서비스를 이용하려는 사람이 이용자ID를 부여 받은 자와 동일인임을 확인하고 회원의 권익을 보호하기
                        위하여 이용자가 선정한 문자 또는 문자와 숫자의 조합을 말합니다.</p>
              <p>- "소비내역정보"라 함은 서비스를 이용하기 위하여 이용자가 입력하는 정보와 각 카드사에서 제공하는 카드소비내역 SMS(MMS),
                        스마트폰 알림(Push 알림) 등 서비스의 소비내역 항목에 기재되는 회원의 개인정보를 말합니다.</p>
              <p>- "유료서비스"라 함은 "서비스" 중 "회사"가 유료로 제공하는 뱅크샐러드와 관련한 각종 부가 서비스를 의미합니다.</p>
              <p>이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관련법령에서 정하는 바에 의하며, 관련 법령에서 정하지 않는
                        것은 일반적인 관례에 의합니다.</p>
            </li>
            <li>
              <b>제3조 (약관의 명시와 개정)</b>
              <ol>
                <li>① 회사는 이 약관의 내용과 상호, 영업소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 이용자가 알 수 있도록 서비스
                        초기화면에 게시하거나 기타의 방법으로 이용자에게 공지합니다.
                </li>
                <li>② 회사는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등
                        관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                </li>
                <li>③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 개정약관의 적용일자 7일전부터 적용일자
                        전일까지 공지합니다. 단 회원의 권리, 의무에 중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터 공지하도록
                        합니다.
                </li>
                <li>④ 이용자는 변경된 약관에 대해 거부할 권리가 있습니다. 이용자는 변경된 약관이 공지된 지 15일이내에 거부의사를 표명할
                        수 있습니다. 이용자가 거부하는 경우 본 서비스 제공자인 회사는 15일의 기간을 정하여 이용자에게 사전통지 후 당해
                        이용자와의 계약을 해지할 수 있습니다. 만약, 이용자가 거부 의사를 표시하지 않거나, 전항에 따라 시행일 이후에 서비스를
                        이용하는 경우에는 동의한 것으로 간주합니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제4조 (약관의 해석)</b>
              <ol>
                <li>① 이용자가 회사와 개별계약을 체결하여 서비스를 이용하는 경우 회사는 개별 서비스에 대한 이용약관 또는 운영정책 등(이하
                        "운영정책 등")을 둘 수 있으며, 해당 내용이 본 약관과 상충되는 경우 개별서비스에 대한 운영정책 등이 우선합니다.
                </li>
                <li>② 이 약관에서 규정하지 않은 사항에 관해서는 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망
                        이용촉진 및 정보보호 등에 관한 법률 등의 관계법령에 따릅니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제2장 서비스 이용계약</b>
          <ol>
            <li>
              <b>제5조 (이용계약의 성립)</b>
              <ol>
                <li>1. 회사와 회원 사이의 서비스 이용계약(이하 "이용계약"이라 함)은 서비스를 이용하고자 하는 자(이하 "가입신청자"라
                        함)의 애플리케이션 설치, 회원가입 등의 이용신청 및 본 약관과 개인정보취급방침의 내용에 대한 동의에 대한 "회사"의
                        이용승낙으로 성립합니다.
                </li>
                <li>2. 본 약관 및 개인정보취급방침에 대한 동의의 의사표시는 "가입신청자"가 이용신청 당시 "개인 "보 수집ㆍ이용 동의” 및
                        "서비스 이용약관" 동의 버튼을 누르는 것으로 이루어지며, 이와 동시에 위 "가입신청자"가 본 약관 및 개인정보취급방침에
                        대하여 동의한 것으로 간주합니다.
                </li>
                <li>
                  <p>3. 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는
                            신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.</p>
                  <ol>
                    <li>① 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우</li>
                    <li>② 실명이 아니거나 타인의 명의를 이용한 가입신청의 경우</li>
                    <li>③ 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
                    <li>④ 만 14세 미만의 경우 서비스 가입을 제한하고 있습니다. 구글플레이 콘텐츠 등급을 통해 설치를 제한하고
                            있으며, 만 14세 미만의 서비스 사용을 사후 확인한 경우 이용계약을 해지할 수 있습니다.
                    </li>
                    <li>⑤ 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
                    </li>
                  </ol>
                </li>
                <li>4. 회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.</li>
                <li>5. 회사가 제2항, 제3항 및 제5항에 따라 회원가입신청을 승낙하지 아니하거나 유보한 경우, 회사는 원칙적으로 그 사실을
                        가입신청자에게 알리도록 합니다.
                </li>
                <li>6. 이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</li>
                <li>7. 회사는 이용자에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을
                        둘 수 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제6조 (이용자 정보의 제공)</b>
              <ol>
                <li>1. 회원으로 가입하여 서비스를 이용하고자 하는 이용자는 이메일, 연락처 등 정보를 제공하고, 휴대폰 또는 이메일 인증을
                        하여야 합니다.
                </li>
                <li>2. 이용자가 제1항에서 정한 인증을 거치지 않은 경우 서비스 이용이 제한될 수 있으며, 실명으로 등록하지 않은 이용자의
                        경우 일체의 권리를 주장할 수 없습니다.
                </li>
                <li>3. 타인의 명의를 도용하여 이용신청을 한 회원의 모든 ID는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제7조 (회원의 계정(ID) 및 비밀번호)</b>
              <ol>
                <li>1. 이용자는 서비스의 원활한 이용 및 회원의 정보보호, 서비스 이용안내 등의 편의를 위하여 이용자가 선정한 이메일 주소를
                        계정(ID)으로 사용합니다. 다만, 서비스는 회원의 계정(ID)이 반사회적이거나 미풍양속을 해치거나 또는 운영자로 오인할
                        우려가 있는 경우 등에는 해당 ID의 사용을 거부하거나 제한할 수 있습니다
                </li>
                <li>2. 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능여부 등 제반 이용자 관리 업무를 수행합니다.</li>
                <li>3. 이용자는 자신의 계정정보를 선량한 관리자로서의 주의의무를 다하여 관리 하여야 합니다. 이용자가 본인의 계정정보를
                        소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 이용자에게 책임이 있습니다.
                </li>
                <li>4. 이용자는 서비스가 정한 기준을 충족하는 범위 내에서 자유롭게 비밀번호를 정할 수 있으며, 정해진 비밀번호는 이용자가
                        원하는 경우 언제든지 변경이 가능합니다.
                </li>
                <li>5. 이용자는 서비스의 이용을 위하여 사용하는 비밀번호에 대한 보호 및 관리 책임을 부담합니다. 다만, 서비스는 보안 등을
                        이유로 이용자에게 정기적 또는 비정기적으로 비밀번호의 변경을 권고할 수 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제8조 (개인정보의 보호 및 관리)</b>
              <ol>
                <li>1. 서비스는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의
                        개인정보 보호 및 사용에 대해서는 서비스가 별도로 고지하는 개인정보취급방침에 따릅니다. 다만, 서비스가 제공하는 공식
                        서비스 애플리케이션 및 사이트 이외의 링크된 사이트에서는 회사의 개인정보취급방침이 적용되지 않습니다.
                </li>
                <li>2. 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서는 일체의 책임을 지지
                        않습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제9조 (이용자에 대한 통지)</b>
              <ol>
                <li>1. 서비스가 이용자에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 이용자가 지정한 이메일 주소로
                        통지합니다.
                </li>
                <li>2. 서비스는 회원 전체에 대한 통지의 경우 7일 서비스 공지사항 또는 회사 홈페이지에 게시함으로써 제1항의 통지에 갈음할
                        수 있습니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제3장 서비스 이용</b>
          <ol>
            <li>
              <b>제10조 (서비스의 내용 및 변경)</b>
              <ol>
                <li>
                  <p>1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.</p>
                  <p>- 휴대전화를 통해 신용/체크카드 사용 또는 기타 지출 관련 정보를 분석하여 소비내역 관리 및 분석, 금융상품 추천
                            등의 정보를 제공하는 모바일 어플리케이션</p>
                  <p>- 금융 상품 추천을 통해 상품 가입을 돕는 중개 서비스</p>
                </li>
                <li>2. 이용자는 회사가 제공하는 서비스를 이 약관, 운영정책 등 회사가 정한 규칙에 따라 이용할 수 있습니다.</li>
                <li>3. 회사가 이용자에게 제공하는 서비스에 대하여 회사는 제작, 변경, 유지, 보수에 관한 포괄적인 권한을 가집니다.
                </li>
                <li>4. 회사는 새로운 서비스 내용, 각종 버그 패치 등 서비스의 운영상 또는 기술상의 필요한 경우, 제공하고 있는 서비스의
                        전부 또는 일부를 상시적으로 수정, 추가, 폐지 등 변경할 수 있습니다. 변경될 서비스의 내용 및 제공일자 등에 대해서는
                        그 변경 7일 이상 전에 서비스 공지사항이나 회사가 운영하는 홈페이지, 해당 서비스 초기화면에 게시함으로써 이용자에 대한
                        통지에 갈음합니다. 다만, 회사가 통제할 수 없는 사유로 인한 서비스의 중단으로 인하여 사전 통지가 불가능한 경우에는 사후
                        공지할 수 있습니다.
                </li>
                <li>5. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 기획이나 운영상 또는 회사의 긴박한 상황 등 필요에
                        의해 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법령상 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지
                        않습니다.
                </li>
                <li>6. 회사는 유료서비스의 변경 내용이 중대하거나 이용자에게 불리한 경우 해당 유료서비스를 제공받는 이용자에게 제9조에서
                        정한 방법으로 이용자에게 통지합니다. 이 경우, 동의를 거절한 이용자에 대해서는 변경 전 서비스를 제공합니다. 다만, 변경
                        전 서비스의 제공이 불가능한 경우 해당 서비스의 제공을 중지하거나 중단할 수 있으며 그 경우 환불에 대해서는 제19조
                        제2항에 따라 진행합니다.
                </li>
                <li>
                  <p>7. 회사는 다음 각호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</p>
                  <ol>
                    <li>① 전시, 사변, 천재지변 또는 국가비상사태 등 불가항력적인 사유가 있는 경우</li>
                    <li>② 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</li>
                    <li>③ 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
                    <li>④ 기타 회사의 제반 사정으로 서비스를 할 수 없는 경우</li>
                  </ol>
                </li>
                <li>8. 회사는 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 중대한 과실로 인한 경우를 제외하고는 서비스의 변경 및
                        중지로 발생하는 문제에 대해서 책임을 부담하지 않습니다.
                </li>
                <li>9. 회사의 금융상품 관련 정보 제공서비스를 받은 후 해당 금융상품에 관한 계약을 체결할 것인지 여부는 오로지 계약
                        당사자인 회사와 이용자의 의사에 달려 있고, 계약상의 모든 권리, 의무는 당사자에게 있으므로 회사는 계약과 관련된 일체의
                        책임을 지지 않습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제11조 (서비스의 제공시간 및 중지)</b>
              <ol>
                <li>1. 회사는 이용자의 회원가입을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우, 회사의 필요에 따라 지정된
                        일자부터 서비스를 제공할 수 있습니다.
                </li>
                <li>2. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 서비스를 제공합니다. 다만, 정보통신설비 등
                        시스템 정기점검, 서버의 증설 및 교체, 각종 버그 패치, 새로운 서비스로의 교체 등 운영상 필요한 경우, 일정기간 동안
                        서비스를 일시 중지시킬 수 있습니다.
                </li>
                <li>3. 제2항 단서의 경우 회사는 그 내용 및 시간을 홈페이지나 서비스 내에 공지합니다. 다만, 회사가 사전에 통지할 수
                        없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
                </li>
                <li>4. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스 제공화면에 공시한 바에
                        따릅니다.
                </li>
                <li>5. 회사가 사업종목의 전환, 사업의 포기 등의 이유로 이용자가 신청한 유료서비스를 제공할 수 없게 되는 경우, 회사는
                        제9조 (이용자에 대한 통지)에서 정한 방법으로 이용자에게 통지하고 당초 회사에서 제시한 조건에 따라 이용자에게
                        보상합니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제12조 (정보의 제공 및 광고의 게재)</b>
              <ol>
                <li>1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 회사의 홈페이지 또는 앱의 공지사항에 게시하거나
                        전자우편 등을 이용하여 회원에게 제공할 수 있습니다. 다만, 회원은 관련 법령에 따른 거래관련 정보, 고객센터 답변 등을
                        제외한 정보의 전송에 대하여 언제든지 전자우편 등을 통하여 수신거절의 의사표시를 할 수 있습니다.
                </li>
                <li>2. 회사는 본 서비스 등을 유지하기 위하여 광고를 게재할 수 있으며, 회원은 서비스 이용 시 노출되는 광고게재에 대하여
                        동의합니다.
                </li>
                <li>3. 회사가 제공하는, 제3자가 주체인, 제2항의 광고에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과
                        손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.
                </li>
                <li>4. 회사는 적법하게 수집한 회원의 개인정보를 활용하여 제2항의 광고 등을 제공하는 경우 SMS(MMS), 스마트폰
                        알림(Push 알림), 메일주소 등을 활용하여 발송할 수 있으며, 이용자가 원하지 않는 경우에는 언제든지 수신을 거부할 수
                        있습니다.
                </li>
                <li>5. 회사는 상기 정보제공과 광고와 관련해서는 정보를 회사에 제공하는 플랫폼사업자, Google Play 등 앱스토어
                        사업자의 약관 및 회사의 약관에 준거하며 관련 법령 및 시행령의 규정을 준수합니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제4장 계약 당사자의 의무</b>
          <ol>
            <li>
              <b>제13조 (회사의 의무)</b>
              <ol>
                <li>1. 회사는 본 약관 및 관련법령에서 금지하는 행위 및 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 서비스의
                        제공을 위하여 최선을 다하여 노력합니다.
                </li>
                <li>2. 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 신용정보를 포함한 일체의 개인정보 보호를 위한 보안시스템을
                        갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.
                </li>
                <li>3. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 신속하게
                        처리하여야 합니다. 다만, 처리에 장기간이 소요되는 경우 이용자에게 게시판 또는 전자우편 등을 통하여 지체 사유를 안내하고
                        처리과정 및 처리결과를 전달합니다.
                </li>
                <li>4. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약관련 절차 및 내용 등에 있어 이용자에게 편의를
                        제공하도록 노력합니다.
                </li>
                <li>
                  <p>5. 회사가 유료서비스를 제공하는 경우, 회사는 다음의 사항을 해당 서비스의 이용 초기화면이나 FAQ 화면 등에
                            이용자가 알기 쉽게 표시합니다.</p>
                  <ol>
                    <li>① 유료서비스의 명칭 또는 제호</li>
                    <li>② 유료서비스의 내용, 이용방법, 이용료, 결제방법 기타 이용조건</li>
                    <li>③ 이용가능 기기 및 이용에 필요한 최소한의 기술사양</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <b>제14조 (이용자의 의무)</b>
              <ol>
                <li>
                  <p>1. 이용자는 회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당</p>
                  <ol>
                    <li>① 가입신청 또는 정보 변경을 목적으로 회사에 개인정보 등록 시 실명이 아닌 정보 또는 다른 사람의 정보를
                            사용하거나 허위 사실을 기재하는 행위
                    </li>
                    <li>② 타인으로 가장하거나 카드사에서 제공하지 않은 조작된 SMS정보를 이용하여 서비스의 분석 시스템에 혼란을
                            주거나, 다른 회원의 계정 및 비밀번호를 도용, 부정하게 사용하거나, 타인의 신용카드, 전화번호 등을
                            무단으로 도용하여 유료 콘텐츠 등을 구매하는 행위
                    </li>
                    <li>③ 회사가 제공하지 않는 서비스를 회사의 서비스에 이용하거나, 회사가 제공하는 서비스를 다른 서비스에 제공
                            또는 가능하게 하는 행위
                    </li>
                    <li>④ 회사의 서비스 및 제공된 애플리케이션을 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제, 유통,
                            조장하거나 상업적으로 이용, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
                    </li>
                    <li>⑤ 회사의 서비스 및 제공된 애플리케이션을 이용하여 자기 또는 타인에게 재산상의 이익을 발생시키는 행위
                    </li>
                    <li>⑥ 회사 및 제3자의 명예를 훼손하거나 업무를 방해하거나 회사 및 제3자에게 손해를 가하는 행위</li>
                    <li>⑦ 회사의 지적재산권, 제3자의 지적재산권, 초상권 등 기타 권리를 침해하거나 회사의 승인을 받지 않고 다른
                            회원의 개인정보를 수집, 저장, 유포, 게시하는 행위
                    </li>
                    <li>⑧ 제3자를 기망하여 이득을 취하거나 회사가 제공하는 서비스를 불건전하게 이용하거나 하여 제3자에게 피해를
                            주는 행위
                    </li>
                    <li>⑨ 관련 법령에 의하여 전송 또는 게시가 금지되는 정보 또는 컴퓨터 소프트웨어, 하드웨어, 전기통신장비의
                            정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스 기타 다른 컴퓨터 코드, 파일, 프로그램을
                            포함하고 있는 자료를 전송, 게시, 유포, 사용하는 행위
                    </li>
                    <li>⑩ 회사로부터 특별한 권리를 부여 받지 않고 애플리케이션을 변경하거나 애플리케이션에 다른 프로그램을 추가
                            또는 삽입하거나 서버를 해킹, 역설계, 소스코드나 애플리케이션 데이터의 유출 및 변경, 별도의 서버를
                            구축하거나 웹사이트의 일부분을 임의로 변경 또는 도용하여 회사를 사칭하는 행위
                    </li>
                    <li>⑪ 회사의 직원이나 운영자를 가장, 사칭하거나 또는 타인의 명의를 도용하여 정보를 등록하거나 글을 게시하거나
                            메일을 발송하는 행위
                    </li>
                    <li>⑫ 회사의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을 목적으로 서비스를 이용하는 행위
                    </li>
                    <li>⑬ 기타 공공질서 및 미풍양속을 위반하거나 회사의 서비스에 악영향을 끼칠 수 있는 불법적, 부당한 행위 및
                            법령에 위배되는 행위
                    </li>
                  </ol>
                </li>
                <li>2. 이용자는 회사 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을 확인하고 이를 준수할 의무가 있으며 기타 회사의
                        업무에 방해되는 행위를 하여서는 안 됩니다.
                </li>
                <li>3. 이용자의 계정에 관한 관리 책임은 이용자에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</li>
                <li>
                  <p>4. 회사는 제1항, 제2항 및 다음 각 호의 어느 하나에 해당하는 행위의 구체적인 유형을 운영 정책에서 정할 수
                            있으며, 이용자는 이를 준수할 의무가 있습니다.</p>
                  <ol>
                    <li>① 이용자의 계정명, 비밀번호의 정함에 대한 제한</li>
                    <li>② 이용자 본인 및 타인 계정의 등록에 대한 제한</li>
                  </ol>
                </li>
                <li>5. 기타 이용자의 서비스 이용에 대한 본질적인 권리를 침해하지 않는 범위 내에서 회사가 운영상 필요하다고 인정되는
                        사항
                </li>
              </ol>
            </li>
            <li>
              <b>제15조 (권리의 귀속)</b>
              <ol>
                <li>1. 서비스 및 서비스 내 회사가 제작한 콘텐츠 등에 대한 저작권 기타 지적재산권은 회사에 귀속됩니다. 단 제휴계약에 따라
                        제공된 저작물 등은 제외합니다
                </li>
                <li>2. 회사는 회사가 정한 이용조건에 따라 서비스와 관련하여 이용자에게 계정(ID), 게시물 등을 이용할 수 있는 이용권만을
                        부여하며, 이용자는 이를 양도, 판매, 담보 제공하는 등 일체의 처분행위를 할 수 없습니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제5장 계약 해지 및 이용 제한</b>
          <ol>
            <li>
              <b>제16조 (회원의 계약해제•해지 등)</b>
              <ol>
                <li>1. 이용자는 언제든지 '설정' 메뉴를 통하여 서비스 탈퇴 신청을 할 수 있으며, 서비스는 관련법령 등에서 정하는 바에
                        따라 이를 즉시 처리하여야 합니다.
                </li>
                <li>2. 이용자가 계약을 해지할 경우, 관련법령 및 회사의 개인정보보호정책에서 정한 바에 따라 회사가 회원정보를 보유하는
                        경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제17조 (이용제한 등)</b>
              <ol>
                <li>1. 회사는 이용자가 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 서비스 이용을 경고, 일시정지,
                        계약해지, 영구정지로 단계적으로 제한할 수 있습니다.
                </li>
                <li>2. 회사는 제1항의 규정에도 불구하고, 주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및 컴퓨터프로그램보호법을
                        위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹,
                        악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법령을 위반한 경우에는 즉시 계약을 해지 할 수 있습니다. 이에 따른
                        계약해지 시 서비스 이용을 통해 획득한 혜택 등은 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
                </li>
                <li>3. 회사가 위 제1항에 따라 회원의 서비스 이용을 제한하거나 계약을 해지하는 경우, 제한의 조건 및 세부내용은
                        이용제한정책 등에서 정한 바에 따르며, 회사는 제9조에서 정한 방법으로 통지합니다.
                </li>
                <li>4. 이용자는 회사의 이용제한 조치 등에 대하여 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 회원의 이의 사유가
                        정당하다고 인정되는 경우 회사는 즉시 회원의 서비스 이용을 재개하여야 합니다.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>제6장 손해배상 등</b>
          <ol>
            <li>
              <b>제18조 (손해배상)</b>
              <ol>
                <li>1. 회사가 제공하는 서비스의 하자 등에 의하여 이용자에게 피해가 발생한 경우, 이용자에 대한 피해보상의 기준, 범위,
                        방법 및 절차에 관한 사항은 '디지털콘텐츠이용자보호지침'에 따릅니다.
                </li>
                <li>2. 이용자가 이 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우 또는 이용자가 서비스의 이용과 관련하여 회사에
                        손해를 입힌 경우 이용자는 회사에 대하여 손해를 배상하여야 합니다.
                </li>
                <li>3. 이용자가 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 회사가 당해 이용자 외의 제3자로부터
                        손해배상청구 또는 소송 등 각종 이의제기를 받는 경우 당해 이용자는 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가
                        면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든 손해를 배상할 책임이 있습니다.
                </li>
              </ol>
            </li>
            <li>
              <b>제19조 (책임의 한계)</b>
              <ol>
                <li>1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
                        면제됩니다.
                </li>
                <li>2. 회사는 회원의 귀책사유로 인한 서비스 중지 또는 이용의 장애에 대하여는 책임을 지지 아니하며, 기간통신사업자가
                        전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 이용자에게 손해가 발생한 경우에는 책임이 면제됩니다.
                </li>
                <li>3. 회사는 이용자가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지
                        않습니다.
                </li>
                <li>4. 회사는 이용자 간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
                </li>
                <li>5. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이 없는 한 책임을 지지 않습니다.</li>
              </ol>
            </li>
            <li>
              <b>제20조 (준거법 및 재판관할)</b>
              <ol>
                <li>1. 회사와 이용자간에 제기된 소송은 대한민국의 법을 준거법으로 합니다.</li>
                <li>2. 회사와 이용자간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.</li>
              </ol>
            </li>
            <li>
              <b>제21조 (회사의 연락처)</b>
              <p>회사의 상호 및 주소 등은 다음과 같습니다.</p>
              <ol>
                <li>상호 : 주식회사 레이니스트</li>
                <li>대표자 : 김태훈</li>
                <li>주소 : 서울시 강남구 강남대로 556 논현빌딩 17층</li>
                <li>대표전화 : 02-3453-9300</li>
                <li>이메일 주소 : banksalad@banksalad.com</li>
              </ol>
            </li>
            <li>
              <b>제22조 (부칙)</b>
              <ol>
                <li>본 약관은 2015. 11. 30 부터 시행합니다.</li>
                <li>공고일자 : 2015. 11. 30</li>
                <li>시행일자 : 2015. 11. 30</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
);
