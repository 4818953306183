export enum FinanceSectorID {
  CARD = 'card',
  HEALTH_SELF_TESTING = 'health-self-testing',
  HEALTH_INSURANCE = 'health-insurance',
  BANK = 'bank',
  INVESTMENT = 'investment',
  LOAN = 'loan',
  REFINANCING = 'refinancing',
  ANNUITY = 'annuity',
  FINTECH = 'fintech',
  MAGAZINE = 'magazine',
  ME = 'me',
  ARTICLE = 'article',
  MORTGAGE = 'mortgage',
  JEONSE = 'jeonse',
  SAVING = 'saving',
  COUPON = 'coupon',
  CREDIT = 'credit',
  CAR_INSURANCE = 'car-insurance',
}
