import { Entity } from 'core/entity';

export class PromotionDate extends Entity {
    constructor(
        public start: Date,
        public end: Date,
    ) {
        super();
    }
}
