
export class MetaSet {
    title: string;
    description: string;
    keywords: string;
    image: string;
    viewport: string;

    constructor(
        title: string,
        description: string = '',
        keywords: string = '',
        image: string = 'https://cdn.banksalad.com/graphic/color/illustration/og-image/banksalad-web.png',
        viewport: string = 'width=device-width, initial-scale=1, maximum-scale=3.0, minimum-scale=1, user-scalable=no'
    ) {
        this.title = title;
        this.description = description;
        this.keywords = keywords;
        this.image = image;
        this.viewport = viewport;
    }
}
