export class DropDownModel {
    key: any;
    value: string;

    constructor(
        key: any,
        value: string
    ) {
        this.key = key;
        this.value = value;
    }
}
