import { Entity } from 'core/entity';

export class InsurancePaymentPrice extends Entity {
    paymentType: InsurancePaymentPrice.PaymentType;
    price: number;

    constructor(
        paymentType: InsurancePaymentPrice.PaymentType,
        price: number
    ) {
        super();
        this.paymentType = paymentType;
        this.price = price;
    }
}

export module InsurancePaymentPrice {
    export enum PaymentType {
        MONTHLY = '월 보험료',
        SINGLE = '총 보험료',
        YEARLY = '연 보험료'
    }
}
