import { AdviceTransactionPeriodSnapshot } from 'core/entity/personal-finance/advice/transaction-period-snapshot';

declare module 'core/entity/personal-finance/advice/transaction-period-snapshot' {
    export interface AdviceTransactionPeriodSnapshot {
        presentPeriod(): string;
    }
}

AdviceTransactionPeriodSnapshot.prototype.presentPeriod = function() {
    const formatStyle = 'M월 D일';
    return `${this.startDatetime.format(formatStyle)} ~ ${this.endDatetime.format(formatStyle)}`
};
