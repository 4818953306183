import { Observable } from 'rxjs/Rx';
import { ServiceCenterRepositoryType } from 'core/repository/service-center';
import { ZendeskQuestionApiProvider } from 'data/http/api/zendesk/category';
import { QuestionCategory } from 'core/entity/service-center/category';
import { List } from 'immutable';
import { ServiceCenterArticle } from 'core/entity/service-center/article';
import { ServiceCenterQuestion } from 'core/entity/service-center/question';

export class ServiceCenterRepository implements ServiceCenterRepositoryType {
    constructor(
        private api: ZendeskQuestionApiProvider,
    ) {}

    getCategories(): Observable<List<QuestionCategory>> { 
        return this.api.getCategories();
    }

    getQuestionsById(id: number): Observable<List<ServiceCenterQuestion>> {
        return this.api.getQuestionsById(id);
    }

    getArticleById(id: number): Observable<ServiceCenterArticle> {
        return this.api.getArticleById(id);
    }
}
